import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Heading, Fields } from './s'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { size } from 'utilities/object'

const EditLink = ({ taskLinks = [], setTaskLinks, link = {}, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        name,
        url
    } = link

    const isAdding = !!size(link)

    const addOrUpdate = body => {
        let links

        if(isAdding) {
            const index = taskLinks?.value?.findIndex(link => link.url === url)

            links = [
                ...taskLinks?.value?.slice(0, index),
                body,
                ...taskLinks?.value?.slice(index + 1, taskLinks.length)
            ]
        } else {
            links = [
                body,
                ...(!!taskLinks?.value?.length ? taskLinks.value : [])
            ]
        }

        setTaskLinks({
            value: links,
            changed: true
        })

        onDone()
    }

    salt = `${salt}:edit`

    const heading = formatMessage({
        id: isAdding ? 'group_links_edit_title' : 'group_links_add_title',
        defaultMessage: isAdding ? 'Edit link' : 'Add link'
    })

    return (
        <Form
            layout="vertical"
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <Heading>{heading}</Heading>
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="name"
                            controlProps={{ autoFocus: true }}
                            field={{
                                value: name,
                                required: true,
                                include: 'always'
                            }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_link',
                                defaultMessage: 'Link'
                            })}
                            name="url"
                            field={{
                                value: url,
                                required: true,
                                strict: false,
                                include: 'always'
                            }}
                            controlProps={{ type: 'url' }} />
                    </Fields>
                    <Actions className="compact">
                        <Plain
                            className="neutral"
                            onClick={onDone}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className="constructive"
                            disabled={!touched.length || !!size(errors)}
                            ref={trigger}>
                            <FormattedMessage
                                id={isAdding ? 'action_save' : 'action_add'}
                                defaultMessage={isAdding ? 'Save' : 'Add'} />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default EditLink