import React, { Component, createContext, useContext } from 'react'
import { get } from 'api'
import { useAuth } from 'contexts/auth'
import { getListRepresentationFromProfile } from 'utilities/person'
import { getChannel } from 'utilities/broadcaster'
import { requestAccess } from 'utilities/auth'
import { doNowOrWhenVisible } from 'utilities/visibility'
import { compact } from 'utilities/array'
import debounce from 'lodash.debounce'

export const MeContext = createContext()

class MeProvider extends Component {
    constructor(props) {
        super(props)

        const { auth } = props

        this.state = {
            me: null,
            fetching: false,
            authorized: auth?.status === 'authorized',

            getListMe: this.getListMe,
            updateMe: this.update,
            isItMyOwnId: this.isItMyOwnId
        }

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: true, trailing: false })

        this.syncer = getChannel('me')
    }

    componentDidMount() {
        if(this.state.authorized) {
            this.fetch()
        }

        this.syncer.onmessage = ({ data }) => void this.setState(data, () => {
            this.cancelScheduledRefetch()
            this.scheduleRefetch()
        })
    }

    componentDidUpdate(props, { authorized: wasAuthorized }) {
        const isAuthorized = this.props.auth?.status === 'authorized'

        if(!wasAuthorized && isAuthorized) {
            this.setState({ authorized: true }, this.fetch)
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.cancelScheduledRefetch()
        this.syncer.close()
    }

    fetch = async (force = false) => {
        const { ok: tokensOk } = await requestAccess({ bounce: false })
        if(!tokensOk) {
            return
        }

        const { fetching } = this.state
        if(fetching && !force) {
            return
        }

        this.cancelScheduledRefetch()

        if(force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { ok, response: me } = await get({
            path: '/users/me',
            signal: this.fetchController.signal
        })

        if(ok && me) {
            this.setState({
                me,
                fetching: false
            }, () => this.syncer.postMessage({ me }))
        }

        this.scheduleRefetch()
    }

    scheduleRefetch = async () => {
        const tenMinutesInMilliseconds = 10 * 60 * 1000
        this.refreshTimeout = global.setTimeout(() => {
            doNowOrWhenVisible(() => this.fetchDebounced(true))
        }, tenMinutesInMilliseconds)
    }

    cancelScheduledRefetch = () => {
        if(this.refreshTimeout) {
            global.clearTimeout(this.refreshTimeout)
        }
    }

    getListMe = () => getListRepresentationFromProfile(this.state.me, false)

    update = () => this.fetchDebounced(true)

    isItMyOwnId = id => !!id && compact([
        'me',
        this.state.me?.id
    ]).includes(id)

    render() {
        const { children = null } = this.props

        return (
            <MeContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </MeContext.Provider>
        )
    }
}


export default props => {
    const auth = useAuth()

    return (
        <MeProvider
            {...props}
            auth={auth} />
    )
}

export const useMe = () => useContext(MeContext)