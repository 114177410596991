import React, { useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useGroup } from 'contexts/group'
import { usePeople } from 'contexts/people'
import { size } from 'utilities/object'
import Form from 'components/form/controller'
import { ModalHeader } from 'modals/generic'
import EntityField from 'components/form/field/entity'
import OneOfField from 'components/form/field/one-of'
import PeopleField from 'components/form/field/people'
import Actions from 'components/form/actions'
import { ButtonSubmit } from 'components/button'

const People = ({ dismiss, onDone, users, salt }) => {
    const { formatMessage } = useIntl()

    const teamRef = useRef()
    const locationRef = useRef()
    const peopleRef = useRef()

    const { group } = useGroup()
    const { people: groupPeople } = usePeople()

    const [selectionType, setSelectionType] = useState(users?.selectionType ?? 'all')
    const [team, setTeam] = useState(
        users?.selectionType === 'team'
            ? (group ?? users?.team ?? null)
            : null
    )
    const [location, setLocation] = useState(
        users?.selectionType === 'location'
            ? (group ?? users?.location ?? null)
            : null
    )
    const [people, setPeople] = useState(users?.selectionType === 'people'
        ? (groupPeople ?? users?.people ?? [])
        : []
    )

    const update = body => {
        if(!!size(body)) {
            onDone(body)
        }
    }

    const peopleLimit = 25

    return (
        <Form
            layout="vertical"
            onSubmit={update}>
            {({ errors, trigger }) => (
                <>
                    <ModalHeader
                        heading={formatMessage({
                            id: 'people_action_pick',
                            defaultMessage: 'Pick people'
                        })}
                        dismiss={dismiss} />
                    <OneOfField
                        salt={salt}
                        className="compact"
                        label={formatMessage({
                            id: 'competence_export_assigned_to_label',
                            defaultMessage: 'Competencies assigned to'
                        })}
                        name="selectionType"
                        field={{
                            value: selectionType,
                            include: 'always',
                            options: [
                                {
                                    value: 'team',
                                    label: formatMessage({
                                        id: 'team_members_of',
                                        defaultMessage: 'Members of a team'
                                    }),
                                    controlRef: teamRef,
                                    content: (
                                        <EntityField
                                            salt={salt}
                                            className="compact"
                                            label={false}
                                            name="team"
                                            field={{
                                                value: team,
                                                include: 'always'
                                            }}
                                            picker={{
                                                heading: formatMessage({
                                                    id: 'teams_action_pick',
                                                    defaultMessage: 'Pick teams'
                                                }),
                                                outer: false
                                            }}
                                            entity={{
                                                type: 'team',
                                                path: '/groups',
                                                params: { types: ['team'] }
                                            }}
                                            onChange={({ team }) => setTeam(team)}
                                            ref={teamRef} />
                                    )
                                },
                                {
                                    value: 'location',
                                    label: formatMessage({
                                        id: 'location_members_of',
                                        defaultMessage: 'Members of a location'
                                    }),
                                    controlRef: locationRef,
                                    content: (
                                        <EntityField
                                            salt={salt}
                                            className="compact"
                                            label={false}
                                            name="location"
                                            field={{
                                                value: location,
                                                include: 'always'
                                            }}
                                            picker={{
                                                heading: formatMessage({
                                                    id: 'location_action_pick',
                                                    defaultMessage: 'Pick location'
                                                }),
                                                outer: false
                                            }}
                                            entity={{
                                                type: 'location',
                                                path: '/groups',
                                                params: { types: ['location'] }
                                            }}
                                            onChange={({ location }) => setLocation(location)}
                                            ref={locationRef} />
                                    )
                                },
                                {
                                    value: 'people',
                                    label: formatMessage({
                                        id: 'people_individuals',
                                        defaultMessage: 'Individual people'
                                    }),
                                    controlRef: peopleRef,
                                    content: (
                                        <PeopleField
                                            salt={`${salt}:selection`}
                                            label={false}
                                            name="people"
                                            field={{
                                                value: people,
                                                include: 'always'
                                            }}
                                            picker={{
                                                limitCaption: formatMessage({
                                                    id: 'people_select_limit',
                                                    defaultMessage: '{limit, plural, =0 {} =1 {} other {Select up to {limit} people}}'
                                                }, { limit: peopleLimit }),
                                                max: peopleLimit,
                                                outer: false
                                            }}
                                            onChange={({ people }) => setPeople(people)}
                                            onUnset={() => setPeople([])}
                                            ref={peopleRef} />
                                    )
                                }
                            ]
                        }}
                        onChange={({ selectionType }) => setSelectionType(selectionType)} />
                    <Actions>
                        <ButtonSubmit
                            className="constructive"
                            disabled={
                                !!size(errors) ||
                                (selectionType === 'team' && !team) ||
                                (selectionType === 'location' && !location) ||
                                (selectionType === 'people' && !people.length)
                            }
                            ref={trigger}>
                            <FormattedMessage
                                id="action_save"
                                defaultMessage="Save" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default People
