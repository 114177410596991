import React, { Component, createContext, useContext, createRef } from 'react'
import { get, post, patch, put, remove } from 'api'
import { useEnvironment } from 'contexts/environment'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import { useOrganization } from 'contexts/organization'
import { getPluralizedType, getTypeModule, getReferenceDate, getReferenceDateName } from 'pages/processes/utilities'
import { getListRepresentationFromProfile } from 'utilities/person'
import { getDate, safeTransform } from 'utilities/date-time'
import { addDays, differenceInDays, isAfter, endOfDay } from 'date-fns'
import { v4 as uuid } from 'uuid'
import { pick, omit, map, reduce } from 'utilities/object'
import { compact, pruneBy, first, last } from 'utilities/array'
import { capitalize } from 'utilities/string'
import { isofy } from 'utilities/date-time'
import { clamp } from 'utilities/math'
import isEqual from 'react-fast-compare'

const ProcessContext = createContext()

class ProcessProvider extends Component {
    constructor(props) {
        super(props)

        this.setIdentifiers(pick(props, 'id', 'concernsType', 'concernsId', 'templateIds', 'humaTemplateIds'))

        this.flash = createRef()

        this.state = {
            process: null,
            type: props.type,
            started: false,
            completed: false,
            deleted: false,

            fetchProcess: this.fetch,
            createProcess: this.create,
            updateProcess: this.update,
            updateProcessLocally: this.updateLocally,
            archiveProcess: this.archive,
            unarchiveProcess: this.unarchive,
            removeProcess: this.remove,

            ensureConcernsReferenceDate: this.ensureConcernsReferenceDate,

            addTask: this.addTask,
            updateTask: this.updateTask,
            assignTask: this.assignTask,
            toggleTaskCompleted: this.toggleTaskCompleted,
            removeTask: this.removeTask,
            populateTasks: this.populateTasks,

            flash: this.flash,
            clearFlash: this.clearFlash
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    componentDidUpdate({ id, concernsType, concernsId, templateIds, humaTemplateIds, type }, { process }) {
        const typeChanged = type !== this.props.type
        const idChanged = id !== this.props.id
        const concernsTypeChanged = concernsType !== this.props.concernsType
        const concernsIdChanged = concernsId !== this.props.concernsId
        // const templateIdsChanged = !isEqual(templateIds !== this.props.templateIds)
        const templateIdsChanged = templateIds?.map(id => id).join('+') !== this.props.templateIds?.map(id => id).join('+')
        const humaTemplateIdsChanged = humaTemplateIds?.map(id => id).join('+') !== this.props.humaTemplateIds?.map(id => id).join('+')
        const tasksChanged = !isEqual(process?.tasks, this.state.process?.tasks)

        const needsReplacing = typeChanged || idChanged || concernsTypeChanged || concernsIdChanged || templateIdsChanged || humaTemplateIdsChanged

        if(needsReplacing) {
            this.setState(
                typeChanged ? pick(this.props, 'type') : null,
                () => this.replace(pick(this.props, 'id', 'concernsType', 'concernsId', 'templateIds', 'humaTemplateIds'))
            )
        }

        if(!needsReplacing && tasksChanged) {
            this.setState(({ process }) => {
                if(!process) {
                    return null
                }

                return {
                    process: {
                        ...process,
                        stats: statify(process.tasks)
                    }
                }
            })
        }
    }

    fetch = async () => {
        const {
            dynamicAssignments,
            organization
        } = this.props

        const getConcerns = async process => {
            const concernsType = process?.concerns?.type ?? this.concernsType
            const concernsId = process?.concerns?.id ?? this.concernsId

            if(concernsType === 'organization') {
                return {
                    ok: true,
                    response: {
                        ...pick(organization, 'id', 'name'),
                        type: 'organization'
                    }
                }
            }

            const path = {
                user: `/users/${concernsId}`,
                group: `/groups/${concernsId}`,
                location: `/groups/${concernsId}`,
                team: `/groups/${concernsId}`
            }[concernsType]

            const { ok, response } = await get({ path })

            if(concernsType === 'user') {
                return {
                    ok,
                    response: this.ensureConcernsReferenceDate({ concerns: response }).concerns
                }
            }

            return { ok, response }
        }

        if(this.id) {
            const { response: process, ok: processOk } = await get({
                path: `/${getPluralizedType(this.state.type)}/${this.id}`
            })

            if(processOk) {
                const { response: concerns, ok: concernsOk } = await getConcerns(process)
                if(concernsOk) {
                    process.concerns = concerns
                }
            }

            !!processOk && this.setState({
                process: this.ensureConcernsReferenceDate(process)
            })
        } else if(this.concernsId && this.concernsType) {
            let [
                { ok: concernsOk, response: concerns },
                { ok: templatesOk, response: templates },
                ...dynamicAssignmentResponses
            ] = await Promise.all([
                getConcerns(),
                this.fetchTemplates(),
                ...map(dynamicAssignments, id => get({ path: `/users/${id}` }))
            ])

            const ok = concernsOk && templatesOk && dynamicAssignmentResponses.every(({ ok }) => ok)

            if(ok) {
                const dynamicAssignees = dynamicAssignmentResponses.reduce((accumulator, { response }, index) => ({
                    ...accumulator,
                    [Object.keys(dynamicAssignments)[index]]: response
                }), {})

                const tasks = this.props.fillTasks({
                    concerns,
                    templates,
                    type: this.state.type,
                    dynamicAssignees,
                    referenceDate: this.props.referenceDate
                })

                const stats = statify(tasks)

                this.setState({
                    process: this.ensureConcernsReferenceDate({
                        type: this.state.type,
                        title: this.props.title,
                        description: this.props.description,
                        referenceDate: this.props.referenceDate,
                        concerns,
                        tasks,
                        stats,
                        // template: pick(template, 'id', 'name')
                    })
                })
            }
        } else this.setState({ orphaned: true })
    }

    fetchTemplates = async () => {
        let ok = true
        let templates = []

        if(this.templateIds?.length || this.humaTemplateIds?.length) {
            const results = await Promise.all(compact([
                ...(this.templateIds.map(id =>
                    get({ path: `/${this.state.type}-templates/${id}` })
                ) ?? []),
                ...(this.humaTemplateIds.map(async id => {
                    const { ok, response } = await this.props.fetchFromS3(`/templates/${getTypeModule(this.state.type)}/${id}.json`)

                    if(ok) {
                        return {
                            ok,
                            response: {
                                ...response,
                                tasks: response.tasks.map(task => ({
                                    ...task,
                                    id: uuid()
                                }))
                            }
                        }
                    } else {
                        return { ok, response }
                    }
                }) ?? [])
            ]))

            ok = results.every(({ ok }) => ok)
            templates = results.map(({ response }) => response)
        }

        return { ok, response: templates }
    }

    create = async body => {
        body = {
            ...body,
            concernsId: this.state.process.concerns.id,
            ...pick(this.state.process, 'title', 'description', getReferenceDateName(this.state.type)),
            tasks: this.state.process.tasks.map(task => ({
                ...omit(task, 'id', 'local'),
                ...(('assignedGroup' in task) ? {
                    assignedGroup: task.assignedGroup?.id ?? task.assignedGroup
                } : null),
                ...(('assignedTo' in task) ? {
                    assignedTo: task.assignedTo?.id ?? task.assignedTo
                } : null),
                notify: true
            }))
        }

        const { ok, response: process } = await post({
            path: `/${getPluralizedType(this.state.type)}`,
            body
        })

        if(ok && process) {
            !!this.props.serviceOnboarding.onboarder && this.props.serviceOnboarding.updateOnboardingStatus({
                [`start${capitalize(this.state.type)}`]: 'completed'
            })

            this.setIdentifiers({ id: process.id })

            this.setState({
                process: this.ensureConcernsReferenceDate(process),
                started: true
            })
        }

        return { ok, response: process }
    }

    update = async body => {
        let ok = false
        let response = null

        if(this.id) {
            const { ok: processOk, response: process } = await patch({
                path: `/${getPluralizedType(this.state.type)}/${this.id}`,
                body
            })

            ok = processOk
            response = process

            if(ok && process) {
                this.setState(({ process: oldProcess }) => {
                    return {
                        process: this.ensureConcernsReferenceDate({
                            ...oldProcess,
                            ...process
                        })
                    }
                })
            }
        } else {
            ok = true
            response = this.state.process

            this.setState({
                process: this.ensureConcernsReferenceDate({
                    ...this.state.process,
                    ...body
                })
            })
        }

        return { ok, response }
    }

    updateLocally = update => void this.setState(({ process }) => ({
        process: {
            ...process,
            ...update
        }
    }))

    archive = async () => {
        const { ok } = await post({ path: `/${getPluralizedType(this.state.type)}/${this.id}/archive` })

        if(ok) {
            this.setState(({ process }) => ({
                process: {
                    ...process,
                    archived: true
                }
            }))
        }

        return { ok }
    }

    unarchive = async () => {
        const { ok } = await post({ path: `/${getPluralizedType(this.state.type)}/${this.id}/unarchive` })

        if(ok) {
            this.setState(({ process }) => ({
                process: {
                    ...process,
                    archived: false
                }
            }))
        }

        return { ok }
    }

    remove = async () => {
        const { ok } = await remove({
            path: `/${getPluralizedType(this.state.type)}/${this.id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    // Backend bypasses permissions and returns these dates. In order to keep process.concerns
    // as the source of truth, insert the dates there as if the current user always has access.
    ensureConcernsReferenceDate = process => {
        if(!['onboarding', 'offboarding'].includes(this.state.type)) {
            return process
        }

        const referenceDateName = getReferenceDateName(this.state.type)
        const date = { [referenceDateName]: process[referenceDateName] ?? this.props[referenceDateName] }

        return {
            ...process,
            ...date,
            concerns: {
                ...process.concerns,
                ...date
            }
        }
    }

    addTask = async ({ body }) => {
        let ok = false
        let task = null

        if(this.id) {
            const result = await post({
                path: `/${getPluralizedType(this.state.type)}/${this.id}/tasks`,
                body: {
                    ...body,
                    notify: true
                }
            })

            ok = result.ok
            task = result.response
        } else {
            ok = true
            task = {
                ...body,
                id: uuid(),
                local: true
            }
        }

        if(ok && task) {
            this.setState(({ process }) => {
                this.flash.current = task

                return {
                    process: {
                        ...process,
                        tasks: [
                            task,
                            ...process.tasks
                        ]
                    }
                }
            })
        }

        return { ok, response: task }
    }

    updateTask = async ({ body, id }) => {
        const taskToUpdate = this.state.process.tasks.find(task => task.id === id)

        let ok = false
        let task = null

        if(this.id && id && !body.local) {
            const wasAssignedTo = taskToUpdate?.assignedTo?.id ?? taskToUpdate?.assignedTo
            const assignedTo = body.assignedTo?.id ?? body.assignedTo

            body = {
                ...body,
                assignedTo,
                notify: !!(assignedTo && wasAssignedTo !== assignedTo)
            }

            const result = await patch({
                path: `/tasks/${id}`,
                body
            })

            ok = result.ok
            task = result.response
        } else if(id) {
            ok = true
            task = {
                ...taskToUpdate,
                ...body
            }
        }

        if(ok && task) {
            this.setState(({ process }) => {
                const index = process.tasks.findIndex(task => task.id === id)

                return {
                    process: {
                        ...process,
                        tasks: [
                            ...process.tasks.slice(0, index),
                            task,
                            ...process.tasks.slice(index + 1, process.tasks.length)
                        ]
                    }
                }
            })
        }

        return { ok, response: task }
    }

    assignTask = (id, assignedTo) => this.updateTask({
        body: { assignedTo },
        id
    })

    populateTasks = tasks => this.setState(({ process }) => ({
        process: {
            ...process,
            tasks: [
                ...process.tasks,
                ...tasks
            ]
        }
    }))

    // This should only be called from a started process,
    // however there are no safeguards implemented for this here
    toggleTaskCompleted = async taskId => {
        const index = this.state.process.tasks.findIndex(({ id }) => id === taskId)
        let task = this.state.process.tasks[index]

        const { response, ok } = await put({ path: `/tasks/${taskId}/togglecompleted` })

        if(ok) {
            task = {
                ...task,
                ...response
            }

            this.setState(({ process }) => {
                const tasks = [
                    ...process.tasks.slice(0, index),
                    task,
                    ...process.tasks.slice(index + 1, process.tasks.length)
                ]

                const stats = statify(tasks)

                return {
                    process: {
                        ...process,
                        tasks,
                        stats
                    },
                    completed: stats.completedTasks === stats.totalTasks
                }
            })
        }

        return { ok, response: task }
    }

    removeTask = async body => {
        let ok = false

        if(this.id && body.id && !body.local) {
            const result = await remove({
                path: `/tasks/${body.id}`,
                returnsData: false
            })

            ok = result.ok
        } else {
            ok = true
        }

        if(ok) {
            this.setState(({ process }) => ({
                process: {
                    ...process,
                    tasks: process.tasks.filter(task => task.id !== body.id)
                }
            }))
        }

        return { ok }
    }

    clearFlash = () => void this.setState(({ process }) => {
        this.flash.current = null

        return {
            process: {
                ...process,
                tasks: [...process.tasks]
            }
        }
    })

    setIdentifiers = identifiers => Object.entries(identifiers)
        .forEach(([key, value]) => this[key] = value)

    replace = identifiers => {
        this.setIdentifiers(identifiers)

        this.setState({
            process: null,
            started: null
        }, this.fetch)
    }

    render() {
        const { children = null } = this.props

        return (
            <ProcessContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ProcessContext.Provider>
        )
    }
}

export const useGetFillTasks = () => {
    const { getListMe } = useMe()

    const { checkFeature } = useAccess()
    const groupTasksAvailable = checkFeature('group-tasks')

    return ({ templates, concerns, type: processType, dynamicAssignees, referenceDate }) => {
        const tasks = templates?.flatMap(({ tasks }) => tasks)

        if(!tasks?.length) {
            return []
        }

        referenceDate = getReferenceDate({
            type: processType,
            concerns,
            referenceDate
        })

        const supervisor = concerns?.supervisor?.value ?? null

        return tasks.map(({ assignedTo, dueAtOffsetDays, ...task }) => {
            if(typeof assignedTo === 'string') {
                if(['team', 'location'].includes(concerns.type) && assignedTo === 'concerns') {
                    assignedTo = undefined

                    task = {
                        ...task,
                        assignedGroup: groupTasksAvailable ?
                            concerns :
                            null,
                        assignmentType: 'fixed'
                    }
                } else {
                    assignedTo = {
                        concerns: (concerns.type === 'user') ?
                            getListRepresentationFromProfile(concerns) :
                            null,
                        supervisor,
                        responsible: getListMe(),
                        ...reduce(dynamicAssignees, (accumulator, user, type) => ({
                            ...accumulator,
                            [type]: getListRepresentationFromProfile(user)
                        }), {})
                    }[assignedTo] ?? null
                }
            }

            const dueAt = (typeof dueAtOffsetDays === 'number') ?
                getDate(safeTransform(referenceDate, addDays, dueAtOffsetDays)) :
                dueAtOffsetDays

            return {
                ...task,
                assignedTo,
                dueAt
            }
        })
    }
}

export const statify = tasks => {
    if(!tasks?.length) {
        return {
            duration: 1,
            groups: 0,
            assignees: 0,
            completedTasks: 0,
            totalTasks: 0,
            unassignedTasks: 0,
            overdueTasks: 0
        }
    }

    const unassignedTasks = tasks?.filter(({ assignedGroup, assignedTo }) => !assignedGroup && !assignedTo)

    const assignedGroups = tasks
        ?.map(({ assignedGroup }) => assignedGroup)
        .filter(Boolean)

    const assignedUsers = tasks
        ?.map(({ assignedTo }) => assignedTo)
        .filter(Boolean)

    const sortedTasks = tasks
        ?.filter(({ dueAt }) => !!dueAt)
        ?.sort(({ dueAt: one }, { dueAt: two }) => isofy(one).getTime() - isofy(two).getTime())

    return {
        duration: sortedTasks?.length ?
            clamp(Math.abs(differenceInDays(isofy(first(sortedTasks).dueAt), isofy(last(sortedTasks).dueAt))), 1, Infinity) :
            1,
        groups: pruneBy(assignedGroups).length,
        assignees: pruneBy(assignedUsers).length,
        completedTasks: tasks.filter(({ completedAt }) => !!completedAt).length,
        totalTasks: tasks.length,
        unassignedTasks: unassignedTasks.length,
        overdueTasks: tasks
            .filter(({ dueAt, completedAt }) => !!dueAt && !completedAt)
            .filter(({ dueAt }) => safeTransform(new Date, isAfter, endOfDay(isofy(dueAt)))).length
    }
}

export const useProcess = () => useContext(ProcessContext)

export default props => {
    const { fetchFromS3 } = useEnvironment()
    const serviceOnboarding = useServiceOnboarding()
    const { organization } = useOrganization()
    const fillTasks = useGetFillTasks()

    return (
        <ProcessProvider
            {...props}
            fetchFromS3={fetchFromS3}
            serviceOnboarding={serviceOnboarding}
            organization={organization}
            fillTasks={fillTasks} />
    )
}