import React from 'react'
import View from './view'
import Edit from './edit'
import ManageDocumentation from './manage-documentation'

const ActingRecord = ({ mode = 'view', ...props }) => {
    const Record = {
        view: View,
        edit: Edit,
        documentation: ManageDocumentation
    }[mode]

    return (
        <Record
            {...props}
            provide={!!props.record?.attachments?.length}
            recordId={props.record?.id} />
    )
}

export default ActingRecord
