import styled from 'styled-components'
import { H2 } from 'components/typography/heading'

export const Heading = styled(H2)`
    margin-block-end: 24px;
`

export const Fields = styled.div`
    margin-block-start: 24px;
`
