import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import SymbolBase from 'components/symbol'
import Caption, { CaptionSmall, captionSmallCss } from 'components/typography/caption'
import { Naked } from 'components/button'
import { DisplayEmoji } from 'components/symbol'
import Link from 'components/link'
import { Units } from 'components/units'
import { IrremovableUnit } from 'components/units/s'

export const Symbol = styled(SymbolBase)`
    margin-block-end: 24px;
`

export const EditorContent = styled.div`
    display: flow-root;
`

export const Label = styled(Caption)`
    margin-block-end: 8px;
`

export const MetaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    margin-block-start: 16px;
`

export const Emoji = styled(DisplayEmoji)`
    ${belowPhone`
        span {
            font-size: 20px !important;
        }
    `}
`

const metaCss = css`
    line-height: 24px;

    svg {
        ${svgStroke('small')}

        margin-block-start: -.125em;
        margin-inline-end: 16px;

        color: var(--huma-color-foreground-subtle);
    }

    ${Emoji} {
        margin-inline-end: 16px;
    }
`

export const Meta = styled(CaptionSmall)`
    ${metaCss}
`

export const MetaLink = styled(Link)`
    display: block;
    ${captionSmallCss}
    ${metaCss}

    &:hover,
    &:focus {
        text-decoration: none;

        > span {
            text-decoration: underline;
        }
    }
`

export const Tag = styled(Naked)`
    transition: all .1s ease-in-out;

    text-align: start;
    color: var(--huma-color-foreground-subtle);

    &:hover {
        text-decoration: underline;
        color: var(--huma-color-foreground-constructive-bold);
    }
`

export const Access = styled(Units)`
    color: var(--huma-color-foreground-subtle);

    & .text ${IrremovableUnit} {
        padding-inline-start: 32px;
    }
`