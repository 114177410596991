// RFC2822
// export const emailAddressPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g

// https://stackoverflow.com/a/27000682/1209356
export const emailAddressPattern = /(?!(^[.-].*|[^@]*[.]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}/i

export const integerPattern = /[0-9]+/
export const decimalPattern = /[0-9]+([.,][0-9]+)?/

export const linkStrictPattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_\+.~#?!&/=]*)$/
export const linkLoosePattern = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_\+.~#?!&/=]*)$/

export const zeroToHundredInclusivePattern = /\b(0|[1-9]\d?|100)\b/

export const slug = /^[a-zA-Z0-9\-]+$/

export const uuid4Pattern = /[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-4[0-9A-Za-z]{3}-[89ABab][0-9A-Za-z]{3}-[0-9A-Za-z]{12}/

export const datePattern = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/g

export const httpOrHttpsPattern = /^https?:\/\//