import { useProcesses } from 'contexts/processes'
import { useProcess } from 'contexts/process'
import { usePossum } from 'hooks/possum'
import { getShortName, unpackStringField } from 'utilities/person'
import { getPeopleProfileUrl, getPeopleTeamUrl, getPeopleLocationUrl } from 'utilities/url'
import paths from 'app/paths'

export const useProcessContext = (context = 'multiple') => ({
    multiple: useProcesses,
    single: useProcess
})[context]()

export const useGetMeta = () => {
    const possessify = usePossum()

    return concerns => {
        if(!concerns) {
            return {}
        }

        const name = nameFormatters[concerns.type]?.(concerns) ?? null

        return {
            name,
            pname: possessify(name),
            to: urlGetters[concerns.type]?.(concerns) ?? null
        }
    }
}

const nameFormatters = {
    organization: organization => organization.name,
    user: user => getShortName(user),
    team: team => team.name,
    location: location => location.name
}

const urlGetters = {
    user: getPeopleProfileUrl,
    team: getPeopleTeamUrl,
    location: getPeopleLocationUrl
}

export const getPluralizedType = type => ({
    process: 'processes'
})[type] ?? `${type}s`

export const getTypeModule = type => ({
    process: 'processes'
})[type] ?? type

export const getTypePaths = type => paths[({
    process: 'processes'
})[type] ?? type]

export const getReferenceDateName = type => ({
    onboarding: 'firstDayOfWork',
    offboarding: 'lastDayOfWork',
    process: 'referenceDate'
})[type]

export const getReferenceDate = process => {
    const configuration = {
        name: getReferenceDateName(process.type),
        source: ['onboarding', 'offboarding'].includes(process.type) ?
            'concerns' :
            'process'
    }

    return (configuration.source === 'concerns') ?
        unpackStringField(process.concerns[configuration.name]) :
        process[configuration.name]
}

export const getTitleTranslationByType = type => ({
    onboarding: {
        id: 'noun_onboarding',
        defaultMessage: 'Onboarding'
    },
    offboarding: {
        id: 'noun_offboarding',
        defaultMessage: 'Offboarding'
    },
    process: {
        id: 'noun_processes',
        defaultMessage: 'Processes'
    }
})[type]

export const getOngoingTitleTranslationByType = type => ({
    onboarding: {
        id: 'employee_onboarding_heading_in_progress',
        defaultMessage: 'Onboarding in progress'
    },
    offboarding: {
        id: 'employee_offboarding_heading_in_progress',
        defaultMessage: 'Offboarding in progress'
    },
    process: {
        id: 'processes_heading_in_progress',
        defaultMessage: 'Process in progress'
    }
})[type]

export const getReferenceDateLabelTranslation = type => ({
    onboarding: {
        id: 'noun_first_workday',
        defaultMessage: 'First work day'
    },
    offboarding: {
        id: 'noun_last_workday',
        defaultMessage: 'Last work day'
    },
    process: {
        id: 'process_reference_date',
        defaultMessage: 'The reference date'
    }
})[type]

export const getConcernsLabelTranslation = type => ({
    onboarding: {
        id: 'task_assign_to_new',
        defaultMessage: 'New hire'
    },
    offboarding: {
        id: 'task_assign_to_departing_employee',
        defaultMessage: 'The departing employee'
    },
    process: {
        id: 'task_assign_to_process_subject',
        defaultMessage: 'The regarding employee, team or location'
    }
})[type]

export const getResponsibleLabelTranslation = type => ({
    onboarding: {
        id: 'task_assign_to_responsible_onboarding',
        defaultMessage: 'The one starting the onboarding'
    },
    offboarding: {
        id: 'task_assign_to_responsible_offboarding',
        defaultMessage: 'The one starting the offboarding'
    },
    process: {
        id: 'task_assign_to_responsible_process',
        defaultMessage: 'The one starting the process'
    }
})[type]

export const getEmptyMessageTranslation = type => ({
    onboarding: {
        id: 'onboarding_no_results',
        defaultMessage: '🤷 Sorry, no onboardings were found.'
    },
    offboarding: {
        id: 'offboarding_no_results',
        defaultMessage: '🤷 Sorry, no offboardings were found.'
    },
    process: {
        id: 'processes_no_results',
        defaultMessage: '🤷 Sorry, no processes were found.'
    }
})[type]

export const getEmojiByType = type => ({
    onboarding: '🎉',
    offboarding: '💐',
    process: '🎯'
})[type]

export const isCompleted = stats => {
    let { totalTasks = 0, completedTasks = 0 } = stats ?? {}

    if(isNaN(totalTasks)) {
        totalTasks = 0
    }

    if(isNaN(completedTasks)) {
        completedTasks = 0
    }

    if(totalTasks === 0) {
        return false
    }

    return completedTasks >= totalTasks
}