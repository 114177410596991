import React, { Component, createContext, useContext } from 'react'
import { get } from 'api'
import { getChannel } from 'utilities/broadcaster'

export const ConfigurationContext = createContext()

export default class ConfigurationProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()

        this.state = {
            configuration: null,
            fetching: false,

            fetch: this.fetch
        }

        this.syncer = getChannel('configuration')
    }

    componentDidMount() {
        this.fetch()
        this.syncer.onmessage = ({ data }) => void this.setState(data)
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.syncer.close()
    }

    fetch = async (force = false) => {
        const { fetching } = this.state
        if(fetching && !force) {
            return
        }

        if(force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { signal } = this.fetchController

        const { ok, response: configuration } = await get({
            path: '/config',
            signal
        })

        const state = {
            ...(ok ? { configuration } : null),
            fetching: false
        }

        this.setState(state, () => this.syncer.postMessage(state))
    }

    render() {
        const { children = null } = this.props

        return (
            <ConfigurationContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ConfigurationContext.Provider>
        )
    }
}

export const useConfiguration = () => useContext(ConfigurationContext)