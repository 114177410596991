import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { H2 } from 'components/typography/heading'
import { DefinitionList, DefinitionTerm, DefinitionDescription } from 'components/list'
import { paragraphCss } from 'components/typography/paragraph'
import { captionCss } from 'components/typography/caption'

export const Heading = styled(H2)`
    margin-block-end: 24px;
`

export const SummaryList = styled(DefinitionList)`
    gap: 0;

    margin-block-end: 24px;
    border-top: 1px solid var(--huma-color-border-default);

    ${belowPhone`
        grid-template-columns: auto 1fr;
    `}
`

export const SummaryTerm = styled(DefinitionTerm)`
    ${captionCss}

    border-bottom: 1px solid var(--huma-color-border-default);
    padding: 16px 0;
`

export const SummaryDescription = styled(DefinitionDescription)`
    ${paragraphCss}
    display: flex;
    justify-content: flex-end;

    border-bottom: 1px solid var(--huma-color-border-default);
    padding: 16px 0;
`