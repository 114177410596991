import React from 'react'
import { createPortal } from 'react-dom'
import { FormattedMessage } from 'react-intl'
import {
    SortableItem, DragColumn,
    ListItem
} from './s'
import { dashboardSections } from 'pages/dashboard'
import { Simple } from 'components/button'
import { EyeOff } from 'styled-icons/feather'
import { DragIndicator } from 'styled-icons/material'

const ConfigureDashboardSection = ({ section, hideSection, provided, snapshot }) => {
    const portal = document.getElementById('sortable')

    const { isDragging = false } = snapshot ?? {}

    const child = (
        <SortableItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            $isDragging={snapshot.isDragging}>
            <DragColumn {...provided.dragHandleProps}>
                <DragIndicator size={24} />
            </DragColumn>
            <span>
                <FormattedMessage {...dashboardSections[section].title} />
            </span>
            <Simple
                onClick={() => hideSection(section)}
                className="constructive"
                autoFocus={false}>
                <FormattedMessage
                    id="action_hide"
                    defaultMessage="Hide" />
            </Simple>
        </SortableItem>
    )

    if(!isDragging) {
        return child
    }

    return createPortal(child, portal)
}

export const HiddenSection = ({ section, showSection }) => (
    <ListItem>
        <EyeOff size={24} />
        <span>
            <FormattedMessage {...dashboardSections[section].title} />
        </span>
        <Simple
            onClick={() => showSection(section)}
            className="constructive"
            autoFocus={false}>
            <FormattedMessage
                id="action_show"
                defaultMessage="Show" />
        </Simple>
    </ListItem>
)

export default ConfigureDashboardSection
