import styled from 'styled-components'
import {
    svgStroke,
    belowLaptop
} from 'utilities/styled'
import { Naked, buttonStyles } from 'components/button'
import { tiptapContentCss } from 'components/tiptap/s'

export const Menu = styled.div`
    display: flex;
    column-gap: 16px;

    padding: 16px 88px;

    ${belowLaptop`
        padding-inline: 24px;
    `}
`

export const MenuColumns = styled.div`
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    column-gap: 16px;
`

export const MenuColumn = styled.div`
    position: relative;

    display: inline-flex;
    align-items: center;
    column-gap: 16px;

    svg {
        ${svgStroke()}
    }

    &.push {
        margin-inline-start: auto;
    }
`

export const CloseButton = styled(Naked)`
    ${buttonStyles.closeButtonCss()}

    position: static;
`

export const Explanation = styled.span`
    cursor: help;
`

export const Content = styled.div`
    margin: 0 auto;
    width: 600px;
    max-width: 100%;
    padding: 16px 32px 32px;

    ${tiptapContentCss}
`