import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MenuItemLink } from '../'

const moduleIdentifier = 'settings'

const SystemSettingsGeneral = props => (
    <MenuItemLink
        {...props}
        to="settings.base" end
        module={moduleIdentifier}
        access={true}
        upgradable={false}
        nested>
        <FormattedMessage
            id="settings_pane_general"
            defaultMessage="General" />
    </MenuItemLink>
)

export default SystemSettingsGeneral