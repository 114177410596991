import React from 'react'
import { FlexChildShrink } from 'components/flex'
import { Item, Details, Detail } from './s'
import { Label } from '../../s'
import PhoneLink from 'components/link/phone'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import ContextMenu from 'widgets/context-menu'

const EmergencyContact = ({ emergencyContact, editable, actions, salt }) => {
    const {
        relation,
        name,
        phone
    } = emergencyContact

    const phoneFormatted = phone.startsWith('+') ?
        formatPhoneNumberIntl(phone) :
        phone

    return (
        <Item>
            <Label className="horizontal">{relation}</Label>
            <Details>
                <Detail>{name}</Detail>
                <Detail>
                    <PhoneLink number={phoneFormatted} />
                </Detail>
            </Details>
            {!!editable && (
                <FlexChildShrink>
                    <ContextMenu
                        salt={salt}
                        context={emergencyContact}
                        actions={actions} />
                </FlexChildShrink>
            )}
        </Item>
    )
}

export default EmergencyContact
