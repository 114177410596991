import React, { Fragment } from 'react'
import { cls } from 'utilities/dom'
import { Layout, Label } from './s'
import { VariableHint } from '../s'

const SourceLabel = ({ name, displayName, label, asCaption = true, showVariableHint = true, children = null, ...props }) => {
    const Wrapper = children ?
        Layout :
        Fragment

    return (
        <Wrapper {...((Wrapper !== Fragment && props.className) ? { className: props.className } : null)}>
            <Label className={cls(['compact', asCaption && 'caption', props.className])}>
                {!!label && <span>{label}</span>}
                {showVariableHint && <VariableHint>${displayName ?? name}</VariableHint>}
            </Label>
            {children}
        </Wrapper>
    )
}

export default SourceLabel