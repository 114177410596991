import React, { useState, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { useCompetenceProfile } from 'contexts/competence-profile'
import { omit } from 'utilities/object'
import { ModalHeader } from 'modals/generic'
import Steps from 'components/steps'
import OneGeneral from './1-general'
import TwoTarget from './2-target'

const EditCompetenceProfile = ({ dismiss, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        profile,
        addProfile,
        updateProfile
    } = useCompetenceProfile()

    const [step, setStep] = useState(1)
    const [form, setForm] = useState(profile)
    const [loading, setLoading] = useState(false)

    const { id } = profile ?? {}

    const addOrUpdate = async body => {
        setLoading(true)

        body = omit(body, 'id')

        if(body?.targets) {
            body.targets = body.targets.filter(({ typeId }) => !!typeId)
        }

        const addOrUpdateProfile = id ?
            updateProfile :
            addProfile

        const { ok, response } = await addOrUpdateProfile(body)

        setLoading(false)

        if(ok) {
            onDone?.(response)
        }
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: id ?
                        'competence_profiles_action_edit' :
                        'competence_profiles_action_add',
                    defaultMessage: id ?
                        'Edit profile' :
                        'New profile'
                })}
                dismiss={dismiss} />
            <Steps
                steps={[
                    {
                        text: formatMessage({
                            id: 'competence_gap_target_profile_step_general',
                            defaultMessage: 'General'
                        })
                    },
                    {
                        text: formatMessage({
                            id: 'competence_gap_heading_target',
                            defaultMessage: 'Define competence target'
                        })
                    }
                ]}
                showAllLabels={false}
                at={step - 1}
                salt={salt} />
            {(step === 1) && (
                <OneGeneral
                    form={form}
                    setForm={setForm}
                    update={body => {
                        setForm(previous => ({
                            ...previous,
                            ...omit(body, 'description')
                        }))

                        setStep(2)
                    }}
                    salt={salt} />
            )}
            {(step === 2) && (
                <TwoTarget
                    loading={loading}
                    form={form}
                    back={body => {
                        setForm(previous => ({
                            ...previous,
                            ...body
                        }))

                        setStep(1)
                    }}
                    onDone={types => addOrUpdate({
                        ...form,
                        targets: types.map(({ id, category, target }) => ({
                            typeId: id,
                            category,
                            ...(Number.isInteger(target) ? { level: target } : null)
                        }))
                    })}
                    salt={salt} />
            )}
        </>
    )
}

export default EditCompetenceProfile
