import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { MenuItemLink } from '../'

const moduleIdentifier = 'subscription'

const SystemSettingsSubscription = props => {
    const {
        initialized,
        check
    } = useAccess()

    const access = initialized && check('organization:manage')

    return (
        <MenuItemLink
            {...props}
            to="settings.subscription.base"
            module={moduleIdentifier}
            access={access}
            upgradable={false}
            nested>
            <FormattedMessage
                id="settings_pane_subscription"
                defaultMessage="Subscription" />
        </MenuItemLink>
    )
}

export default SystemSettingsSubscription