import React, { useContext, createContext, Component } from 'react'
import { useEnvironment } from 'contexts/environment'
import { useAuth } from 'contexts/auth'
import { get, patch } from 'api'
import { getChannel } from 'utilities/broadcaster'
import { requestAccess } from 'utilities/auth'
import { getData } from 'utilities/jwt'
import { local } from 'utilities/storage'
import { pick, omit } from 'utilities/object'
import { doNowOrWhenVisible } from 'utilities/visibility'
import debounce from 'lodash.debounce'

export const AccessContext = createContext()

class AccessProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()
        this.fetchModulesController = new AbortController()
        this.fetchPermissionsController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: true, trailing: false })

        const { auth } = props

        this.state = {
            bundlesByTrack: {},
            currentLadder: null,
            currentBundle: null,
            track: null,
            bundle: null,
            plan: null,
            public: null,
            capabilities: [],
            featuresByBundleInCurrentTrack: {},
            featureConfig: [],
            permissions: [],
            organization: [],
            unit: [],
            user: [],
            fetching: false,
            authorized: auth?.status === 'authorized',
            initialized: false,

            refresh: () => this.fetchDebounced(true),
            refreshModules: this.fetchModules,
            refreshPermissions: this.fetchPermissions,

            check: this.checkPermission,

            checkModule: this.checkModule,
            getBundlesWithModule: this.getBundlesWithModule,
            checkModuleWithinQuota: this.checkModuleWithinQuota,
            toggleModule: this.toggleModule,

            checkFeature: this.checkFeature,
            getBundlesWithFeature: this.getBundlesWithFeature,
            checkFeatureWithinQuota: this.checkFeatureWithinQuota,

            getBundle: this.getBundle
        }

        this.syncer = getChannel('access')
    }

    componentDidMount() {
        this.fetch()

        this.syncer.onmessage = ({ data }) => void this.setState(data, () => {
            this.cancelScheduledRefetch()
            this.scheduleRefetch()
        })
    }

    componentDidUpdate(props, { authorized: wasAuthorized }) {
        const isAuthorized = this.props.auth?.status === 'authorized'

        if(!wasAuthorized && isAuthorized) {
            this.setState({ authorized: true }, () => this.fetch(true))
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.fetchModulesController.abort()
        this.fetchPermissionsController.abort()
        this.cancelScheduledRefetch()
        this.syncer.close()
    }

    fetch = async (force = false) => {
        const { ok: tokensOk, response: tokens } = await requestAccess({ bounce: false })
        if(!tokensOk || !tokens?.accessToken) {
            return
        }

        const { fetching } = this.state
        if(fetching && !force) {
            return
        }

        this.cancelScheduledRefetch()

        if(force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { signal } = this.fetchController

        const [
            { ok: planOk, response: plan },
            { ok: bundlesOk, response: bundles },
            { ok: plansMetaOk, response: plansMeta },
            { ok: permissionsOk, response: permissions },
            { ok: featureConfigOk, response: featureConfig }
        ] = await Promise.all([
            get({ path: '/organization/plan', signal }),
            get({ path: '/config/bundles', signal }),
            this.props.fetchFromS3('/plans/basic.json'),
            this.fetchPermissions({ update: false, signal }),
            this.fetchModules({ update: false, signal })
        ])

        let ok = false
        let state = null

        if(planOk && bundlesOk && plansMetaOk && permissionsOk && featureConfigOk) {
            ok = true

            const { track = 'standard' } = plan

            const bundlesByTrack = enrichBundles(bundles, plansMeta)
            const currentLadder = bundlesByTrack[track]
            const specialTracks = Object.keys(bundlesByTrack).filter(track => track !== 'standard')

            state = {
                bundlesByTrack,
                currentLadder,
                currentBundle: currentLadder.find(({ bundle }) => bundle === plan.bundle),
                currentPlan: plan,
                track,
                specialTracks,
                bundle: plan.bundle,
                plan: plan.code,
                public: plan.public ?? true,
                capabilities: plan.features,
                featuresByBundleInCurrentTrack: currentLadder
                    .filter(({ bundle }) => bundle !== plan.bundle)
                    .reduce((accumulator, { bundle, capabilities }) => ({
                        ...accumulator,
                        [bundle]: capabilities
                    }), {}),
                featureConfig,
                ...permissions,
                fetching: false,
                initialized: true
            }

            this.setState(state, () => this.syncer.postMessage(state))
        }

        this.scheduleRefetch()

        return { ok, response: state }
    }

    fetchModules = async (options = {}) => {
        const {
            update = true,
            signal = this.fetchModulesController.signal
        } = options

        this.setState({ fetching: true })

        const { ok, response: featureConfig } = await get({
            path: '/organization/modules',
            signal
        })

        if(ok && update) {
            const state = { featureConfig }
            this.setState(state, () => this.syncer.postMessage(state))
        }

        return { ok, response: featureConfig }
    }

    fetchPermissions = async (options = {}) => {
        const {
            update = true,
            signal = this.fetchModulesController.signal
        } = options

        const { organizationId } = getData(local.get('accessToken'))

        this.setState({ fetching: true })

        const [
            { ok: organizationOk, response: organizationAccess },
            { ok: organizationWideUnitAccessOk, response: organizationWideUnitAccess },
            { ok: unitOk, response: unitAccess },
            { ok: userOk, response: userAccess }
        ] = await Promise.all([
            get({ path: '/organization/permissions', signal }),
            get({ path: `/units/${organizationId}/permissions`, signal }),

            // These two endpoints, when fetched without a context (uuid),
            // will return the permissions the current user has anywhere,
            // via any mechanism, in the organization. This is useful for
            // determinining whether to show or hide certain UI elements,
            // before performing more specific checks once inside.
            get({ path: '/units/permissions', signal }),
            get({ path: '/users/permissions', signal })
        ])

        const ok = organizationOk && organizationWideUnitAccessOk && unitOk && userOk
        let response = null

        if(ok) {
            response = {
                permissions: [
                    ...organizationAccess,
                    ...unitAccess,
                    ...userAccess
                ],
                organization: organizationAccess,
                organizationWideUnit: organizationWideUnitAccess,
                unit: unitAccess,
                user: userAccess
            }

            if(update) {
                this.setState(response, () => this.syncer.postMessage(response))
            }
        }

        return { ok, response }
    }

    cancelScheduledRefetch = () => {
        if(this.refreshTimeout) {
            global.clearTimeout(this.refreshTimeout)
        }
    }

    scheduleRefetch = async () => {
        const oneHourInMilliseconds = 60 * 60 * 1000
        this.refreshTimeout = global.setTimeout(() => {
            doNowOrWhenVisible(() => this.fetchDebounced(true))
        }, oneHourInMilliseconds)
    }

    checkPermission = key => {
        if(!key || typeof key !== 'string') {
            return false
        }

        return !!this.state.permissions.includes(key)
    }

    checkFeaturePrefix = (prefix, currentBundle = true) => (key, capabilities = this.state.capabilities) => {
        const { featureConfig } = this.state

        if(!key || typeof key !== 'string') {
            return false
        }

        let features = capabilities.reduce((accumulator, { key }) => ({
            ...accumulator,
            [key]: true
        }), {})

        if(currentBundle) {
            const overrides = featureConfig.reduce((accumulator, { key, enabled, available, features = [] }) => ({
                ...accumulator,
                [key]: enabled && available,
                ...features.reduce((accumulator, { key, enabled, available }) => ({
                    ...accumulator,
                    [key]: enabled && available
                }), {})
            }), {})

            features = {
                ...features,
                ...overrides
            }
        }

        return !!features[`${prefix}:${key}`]
    }

    getBundlesWithFeaturePrefix = prefix => key => this.state.currentLadder.reduce((accumulator, { bundle, capabilities }) => {
        if(this.checkFeaturePrefix(prefix, false)(key, capabilities)) {
            accumulator.push(bundle)
        }

        return accumulator
    }, [])

    checkFeatureWithinQuotaPrefix = prefix => async key => {
        if(!key || typeof key !== 'string') {
            return [false]
        }

        key = `${prefix}:${key}`

        const { ok, response: usage } = await get({ path: `/usage/${key}` })

        if(!ok) {
            return [false]
        }

        const limit = usage.limit ?? Infinity

        return [
            usage.value < limit,
            pick(usage, 'limit', 'value')
        ]
    }

    checkModule = this.checkFeaturePrefix('module')
    getBundlesWithModule = this.getBundlesWithFeaturePrefix('module')
    checkModuleWithinQuota = this.checkFeatureWithinQuotaPrefix('module')

    checkFeature = this.checkFeaturePrefix('feature')
    getBundlesWithFeature = this.getBundlesWithFeaturePrefix('feature')
    checkFeatureWithinQuota = this.checkFeatureWithinQuotaPrefix('feature')

    toggleModule = async body => {
        const { ok, response: featureConfig } = await patch({
            path: '/organization/modules',
            body
        })

        if(ok && featureConfig) {
            this.setState({ featureConfig })
        }

        return { ok, response: featureConfig }
    }

    getBundle = (bundle, track = this.state.track) => this.state.bundlesByTrack?.[track]?.find(b => b.bundle === bundle)

    render() {
        const { children = null } = this.props

        return (
            <AccessContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AccessContext.Provider>
        )
    }
}

export const useAccess = () => useContext(AccessContext)

export default props => {
    const { fetchFromS3 } = useEnvironment()
    const auth = useAuth()

    return (
        <AccessProvider
            {...props}
            fetchFromS3={fetchFromS3}
            auth={auth} />
    )
}

export const getPlanFrequency = plan => {
    if(!!plan?.term) {
        return {
            annual: 'yearly',
            monthly: 'monthly'
        }[plan.term] ?? plan.term
    }

    return 'unknown'
}

const enrichBundles = (unenrichedBundles, meta) => Object.entries(unenrichedBundles)
    .reduce((accumulator, [track, bundles]) => {
        const ladder = bundles.map(({ bundle }) => bundle)

        const bundlesByTrack = {
            ...accumulator,
            [track]: bundles.flatMap(bundle => {
                if(!bundle.plans?.length) {
                    return [bundle]
                }

                const bundleMeta = meta[bundle.bundle] ?? {}
                const free = bundle.bundle === 'free' || bundle.bundle.endsWith('-free')

                return bundle.plans
                    .filter(plan => plan.public)
                    .map(plan => {
                        const accumulatedFeatures = bundles
                            .slice(0, ladder.indexOf(bundle.bundle) + 1)
                            .reduce((accumulator, { bundle }) => [
                                ...accumulator,
                                ...(meta[bundle]?.features ?? [])
                            ], [])

                        return {
                            track,
                            id: bundle.bundle,
                            ...omit(plan, 'features'),
                            ...omit(bundleMeta, 'features'),
                            ...omit(bundle, 'bundle', 'plans'),
                            capabilities: bundle.features,
                            features: {
                                tier: bundleMeta.features,
                                accumulated: accumulatedFeatures
                            },
                            free
                        }
                    })
            })
        }

        // Filter away tracks that only has a free bundle
        return Object.entries(bundlesByTrack)
            .reduce((accumulator, [track, bundles]) => {
                if(!bundles.find(({ free }) => !free)) {
                    return accumulator
                }

                return {
                    ...accumulator,
                    [track]: bundles
                }
            }, {})
    }, {})