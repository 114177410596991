import styled from 'styled-components'
import { H3 } from 'components/typography/heading'
import { Grid } from 'components/grid'
import { Plain as PlainBase } from 'components/button'

export const Heading = styled(H3)`
    margin-bottom: 16px;
`

export const NameAndAddressSummary = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 24px;
    justify-content: space-between;
    align-items: start;
    margin-block-end: 24px;
`

export const Plain = styled(PlainBase)`
    height: 24px;
    line-height: 24px;
`