import React from 'react'
import { useIntl } from 'react-intl'
import { Status } from './s'
import Label from './label'

const MeetingStatus = ({ status, label = null, ...props }) => {
    if(!status) {
        return null
    }

    return (
        <Status {...props}>
            <Label
                {...label}
                status={status} />
        </Status>
    )
}

const statusTexts = {
    draft: {
        id: 'meeting_status_draft',
        defaultMessage: 'Draft'
    },
    open: {
        id: 'meeting_status_open',
        defaultMessage: 'Open'
    },
    closed: {
        id: 'meeting_status_completed',
        defaultMessage: 'Completed'
    }
}

export const useMeetingStatusTextFormatter = () => {
    const { formatMessage } = useIntl()

    return status => {
        const message = statusTexts[status] ?? null

        return message ?
            formatMessage(message) :
            null
    }
}

export default MeetingStatus
