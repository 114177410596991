import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { size, map } from 'utilities/object'
import { compact } from 'utilities/array'
import { Rows, Row, ShowDetailsButton, StackMessage, Actions } from './s'
import { InlineMessage } from 'components/message'
import MessageContent from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import WordList from 'components/word-list'
import { Scrollable as Modal, ModalHeader } from 'modals/generic'
import { Plain } from 'components/button'

const Analysis = ({
    accepted = [], rejected = [], warnings = [], duplicates = [],
    newTypesAndVariants = {}, newVariantsOfExistingType = {},
    salt
}) => {
    const { formatMessage } = useIntl()

    const [reviewing, setReviewing] = useState(null)

    const total = [...accepted, ...rejected].length
    const successDetails = !!size(newTypesAndVariants) || !!size(newVariantsOfExistingType)

    return (
        <>
            <Rows>
                {!!rejected.length && (
                    <Row>
                        <InlineMessage
                            type="error"
                            className="compact"
                            message={formatMessage({
                                id: 'equipment_import_analysis_rejected_count',
                                defaultMessage: '{count} of {total} pieces of equipment can’t be added'
                            }, {
                                count: rejected.length,
                                total
                            })}>
                            <ShowDetailsButton
                                className="constructive"
                                onClick={() => setReviewing('rejected')}>
                                <FormattedMessage
                                    id="errors_action_show"
                                    defaultMessage="Show errors" />
                            </ShowDetailsButton>
                        </InlineMessage>
                    </Row>
                )}
                {!!warnings.length && (
                    <Row>
                        <InlineMessage
                            type="warning"
                            className="compact"
                            message={formatMessage(getDetailsHeading('warnings'), {
                                count: warnings.length
                            })}>
                            <ShowDetailsButton
                                className="constructive"
                                onClick={() => setReviewing('warnings')}>
                                <FormattedMessage
                                    id="action_show_details"
                                    defaultMessage="Show details" />
                            </ShowDetailsButton>
                        </InlineMessage>
                    </Row>
                )}
                {!!accepted.length && (
                    <Row>
                        <InlineMessage
                            type={!!rejected.length ? 'success' : 'info'}
                            className="compact"
                            message={compact([
                                formatMessage({
                                    id: 'equipment_import_analysis_accepted_count',
                                    defaultMessage: '{count} of {total} pieces of equipment can be added'
                                }, {
                                    count: accepted.length,
                                    total
                                }),
                                successDetails && formatMessage(getDetailsHeading('accepted'), {
                                    newTypesCount: size(newTypesAndVariants),
                                    newVariantsCount: [
                                        ...Object.values(newTypesAndVariants),
                                        ...Object.values(newVariantsOfExistingType)
                                    ].length,
                                })
                            ]).join([
                                formatMessage({
                                    id: 'sentence_period',
                                    defaultMessage: '.'
                                }),
                                formatMessage({
                                    id: 'sentence_separator',
                                    defaultMessage: ' '
                                })
                            ].join(''))}>
                            {!!duplicates.length && (
                                <CaptionSmall className="compact">
                                    <FormattedMessage
                                        id="import_analysis_duplicates_count"
                                        defaultMessage="{count, plural, =0 {No duplicates} one {One duplicate will be ignored} other {# duplicates will be ignored}}"
                                        values={{ count: duplicates.length }} />
                                </CaptionSmall>
                            )}
                            {successDetails && (
                                <ShowDetailsButton
                                    className="constructive"
                                    onClick={() => setReviewing('accepted')}>
                                    <FormattedMessage
                                        id="action_show_details"
                                        defaultMessage="Show details" />
                                </ShowDetailsButton>
                            )}
                        </InlineMessage>
                    </Row>
                )}
                {(!rejected.length && !accepted.length && !!duplicates.length) && (
                    <Row>
                        <InlineMessage
                            type="warning"
                            className="compact"
                            message={formatMessage({
                                id: 'equipment_import_analysis_accepted_none',
                                defaultMessage: 'None of the pieces of equipment can be added'
                            }, { count: duplicates.length })}>
                            <CaptionSmall className="compact">
                                <FormattedMessage
                                    id="import_analysis_all_duplicates"
                                    defaultMessage="They are all duplicates and will be ignored" />
                            </CaptionSmall>
                        </InlineMessage>
                    </Row>
                )}
            </Rows>
            <Modal
                show={!!reviewing}
                dismiss={() => setReviewing(null)}
                salt={`${salt}:review`}>
                {!!reviewing && (
                    <>
                        <ModalHeader
                            heading={formatMessage(getDetailsHeading(reviewing), {
                                newTypesCount: size(newTypesAndVariants),
                                newVariantsCount: [
                                    ...Object.values(newTypesAndVariants),
                                    ...Object.values(newVariantsOfExistingType)
                                ].length,
                                count: ({ rejected, warnings }[reviewing] ?? []).length
                            })}
                            dismiss={() => setReviewing(null)} />
                        {!!rejected?.length && (reviewing === 'rejected') &&
                            rejected.map(({ rowNum: row, cells, error = '' }) => (
                                <StackMessage
                                    type="error"
                                    message={formatMessage({
                                        id: 'import_analysis_error',
                                        defaultMessage: 'Row {row}: {message}'
                                    }, {
                                        row,
                                        message: cells?.length ?
                                            cells.map(({ columnName }) => columnName).join(', ') :
                                            error
                                    })}
                                    key={`${salt}:rejected:${row}`} />
                            ))
                        }
                        {!!warnings?.length && (reviewing === 'warnings') &&
                            warnings.map(({ rowNum: row, cells, error = '' }) => (
                                <StackMessage
                                    type="warning"
                                    message={formatMessage({
                                        id: 'import_analysis_error',
                                        defaultMessage: 'Row {row}: {message}'
                                    }, {
                                        row,
                                        message: cells?.length ?
                                            cells.map(({ columnName }) => columnName).join(', ') :
                                            error
                                    })}
                                    key={`${salt}:warnings:${row}`} />
                            ))
                        }
                        {successDetails && (reviewing === 'accepted') && (
                            <>
                                {!!size(newTypesAndVariants) && map(newTypesAndVariants, (variants, type, row) => (
                                    <StackMessage
                                        type="info"
                                        message={formatMessage({
                                            id: 'equipment_type_new',
                                            defaultMessage: 'New type: {type}'
                                        }, { type: <strong>{type}</strong> })}
                                        key={`${salt}:success:new:${row}`}>
                                        <MessageContent className="compact">
                                            <FormattedMessage
                                                id="equipment_variants_new_count"
                                                defaultMessage="{count, plural, =0 {} =1 {New variant: {variants}} other {New variants: {variants}}}"
                                                values={{
                                                    count: variants.length,
                                                    variants: (
                                                        <WordList
                                                            words={variants}
                                                            wordRenderer={word => <strong>{word}</strong>} />
                                                    )
                                                }} />
                                        </MessageContent>
                                    </StackMessage>
                                ))}
                                {!!size(newVariantsOfExistingType) && map(newVariantsOfExistingType, (variants, type, row) => (
                                    <StackMessage
                                        type="info"
                                        message={formatMessage({
                                            id: 'equipment_type_existing',
                                            defaultMessage: 'Existing type: {type}'
                                        }, { type: <strong>{type}</strong> })}
                                        key={`${salt}:success:existing:${row}`}>
                                        <MessageContent className="compact">
                                            <FormattedMessage
                                                id="equipment_variants_new_count"
                                                defaultMessage="{count, plural, =0 {} =1 {New variant: {variants}} other {New variants: {variants}}}"
                                                values={{
                                                    count: variants.length,
                                                    variants: (
                                                        <WordList
                                                            words={variants}
                                                            wordRenderer={word => <strong>{word}</strong>} />
                                                    )
                                                }} />
                                        </MessageContent>
                                    </StackMessage>
                                ))}
                            </>
                        )}
                        <Actions className="centered">
                            <Plain
                                className="neutral"
                                onClick={() => setReviewing(null)}>
                                <FormattedMessage
                                    id="action_done"
                                    defaultMessage="Done" />
                            </Plain>
                        </Actions>
                    </>
                )}
            </Modal>
        </>
    )
}

const getDetailsHeading = (reviewing) => ({
    rejected: {
        id: 'equipment_import_analysis_rejected_details_heading',
        defaultMessage: '{count, plural, =0 {} =1 {1 piece of equipment can’t be created} other {{count} pieces of equipment can’t be created}}'
    },
    warnings: {
        id: 'equipment_import_analysis_warnings_details_heading',
        defaultMessage: '{count, plural, =0 {} =1 {1 piece of equipment will be created but not assigned to anyone} other {{count} pieces of equipment will be created but not assigned to anyone}}'
    },
    accepted: {
        id: 'equipment_import_analysis_accepted_details_heading',
        defaultMessage: '{newTypesCount} new equipment type(s) and {newVariantsCount} new variant(s) will be created'
    }
})[reviewing]

export default Analysis
