import styled from 'styled-components'
import {
    svgStroke,
    belowTablet, fromTablet
} from 'utilities/styled'
import ImportantBase, { ImportantInner } from 'components/important'
import { H4 } from 'components/typography/heading'
import { Plain } from 'components/button'

export const Important = styled(ImportantBase)`
    margin-bottom: 24px;

    svg {
        ${svgStroke()}
    }

    & ${ImportantInner} {
        display: grid;
        grid-template-columns: minmax(0, 24px) 1fr minmax(0, 24px);
        grid-column-gap: 16px;
        justify-content: start;
        align-items: start;
    }
`

export const Heading = styled(H4)`
    margin-bottom: 8px;

    font-size: 16px;

    span {
        display: inline-block;

        font-weight: 400;

        ${fromTablet`
            margin-inline-start: 8px;
        `}
    }

    ${belowTablet`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    `}
`

export const DismissButton = styled(Plain)`
    height: 24px;
`