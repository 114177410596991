import React from 'react'
import { usePossum } from 'hooks/possum'
import { compact } from 'utilities/array'
import { getShortName } from 'utilities/person'
import Features from 'components/features'

const ProcessAccess = ({ concerns, salt }) => {
    const possessify = usePossum()

    return (
        <Features
            features={compact([
                {
                    id: 'process_features_item_access_admins',
                    defaultMessage: 'Administrators with general access to processes'
                },
                (concerns?.type === 'user') && {
                    id: 'process_features_item_access_supervisor',
                    defaultMessage: '{pname} supervisor, if the Supervisor role is configured to give access to processes',
                    values: { pname: possessify(getShortName(concerns)) }
                }
            ])}
            size={20}
            salt={salt} />
    )
}

export default ProcessAccess