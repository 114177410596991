import { useIntl } from 'react-intl'

export default ({ groups = [] }) => {
    const { formatMessage } = useIntl()
    const teamsCount = groups.filter(({ type }) => type === 'team').length
    const locationsCount = groups.length - teamsCount

    let output = {
        id: 'groups_count_teams_and_locations',
        defaultMessage: '{teams} and {locations}'
    }
    let values = {
        teams: formatMessage({
            id: 'teams_count',
            defaultMessage: '{count, plural, =0 {No teams} =1 {1 team} other {{count} teams}}'
        }, { count: teamsCount }),
        locations: formatMessage({
            id: 'locations_count',
            defaultMessage: '{count, plural, =0 {No locations} =1 {1 location} other {{count} locations}}'
        }, { count: locationsCount })
    }

    if(!!teamsCount && !locationsCount) {
        output = {
            id: 'teams_count',
            defaultMessage: '{count, plural, =0 {No teams} =1 {1 team} other {{count} teams}}'
        }
        values = { count: teamsCount }
    } else if(!teamsCount && !!locationsCount) {
        output = {
            id: 'locations_count',
            defaultMessage: '{count, plural, =0 {No locations} =1 {1 location} other {{count} locations}}'
        }
        values = { count: locationsCount }
    }

    return formatMessage(output, values)
}