import { decodeJwt } from 'jose'

export const getData = token => {
    if(!token) {
        return null
    }

    return decodeJwt(token)
}

export const getValue = token => (key, fallback = null) => {
    if(!token || !key) {
        return fallback
    }

    return getData(token)?.[key] ?? fallback
}

export const getExpiry = token => getValue(token)('exp', 0)

export const anonymize = token => token?.slice(-6) ?? ''