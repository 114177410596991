import React, { useState, useEffect, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import AbsenceTypesProvider from 'contexts/absence-types'
import AbsenceStatsProvider, { useAbsenceStats } from 'contexts/absence-stats'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { useMe } from 'contexts/me'
import { usePossum } from 'hooks/possum'
import { useNavigate } from 'react-router-dom'
import { get } from 'api'
import paths from 'app/paths'
import { isAfter, endOfDay } from 'date-fns'
import { getShortName } from 'utilities/person'
import { getDate } from 'utilities/date-time'
import {
    ImportantColumn, Important,
    Wrapper,
    PreviewButton, Symbol, Meta, Title, Arrow,
    Ghost
} from './s'
import { CaptionSmall } from 'components/typography/caption'
import { useTypeNameFormatter, Displayer as TypeName } from 'pages/absence/components/type-name'
import { Scrollable as Modal } from 'modals/generic'
import ActingEntry from 'pages/absence/modals/entry'
import AddToCalendar from 'components/add-to-calendar'
import Error from 'pages/absence/modals/error'

const OngoingAbsence = ({ ongoing }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const absenceAdmin = check('absence:manage')

    const { fetchStats } = useAbsenceStats()

    const {
        endEntry,
        removeEntry,
        error,
        resetError
    } = useAbsenceEntries()

    const { me } = useMe()

    const typeNameFormatter = useTypeNameFormatter()

    const possessify = usePossum()
    const navigate = useNavigate()

    const [acting, setActing] = useState(null)
    const [addingToCalendar, setAddingToCalendar] = useState(null)
    const [hasEstimated, setHasEstimated] = useState(false)
    const [dayCount, setDayCount] = useState(0)
    const [ending, setEnding] = useState(false)


    const now = new Date()

    const {
        type,
        start
    } = ongoing ?? {}

    const {
        name,
        symbol
    } = type ?? {}

    const salt = 'dashboard:absence'

    useEffect(() => {
        if(ongoing) {
            const estimateCount = async () => {
                const { ok, response } = await get({
                    path: `/absence/stats/${me.id}/estimate`,
                    params: {
                        type: type.id,
                        fromDate: getDate(start),
                        toDate: getDate(),
                        excludeEntryId: ongoing.id
                    }
                })

                if(ok) {
                    setDayCount(response.workdays)
                }

                setHasEstimated(true)
            }

            estimateCount()
        }
    }, [ongoing])

    const viewAction = useCallback(entry => {
        const { user } = entry

        if(user?.status?.active === false) {
            return null
        }

        return {
            salt: `${salt}:view`,
            label: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                entry,
                mode: 'view',
                provide: ['approve', 'edit'].includes(entry.permissionLevel)
            })
        }
    }, [salt])

    const navigateToUserAbsenceAction = useCallback(({ user }) => {
        if(user?.status?.active === false) {
            return null
        }

        return {
            salt: `${salt}:user:absence`,
            label: formatMessage({
                id: 'absence_action_navigate_me',
                defaultMessage: 'Go to your absence'
            }, {
                pname: possessify(getShortName(user))
            }),
            effect: 'neutral',
            element: 'link',
            link: {
                to: paths.absence.base
            }
        }
    }, [salt])

    const viewAbsenceCalendarAction = useCallback(({ start, end, user, id }) => {
        if(!end || user?.status?.active === false) {
            return null
        }

        return ({
            salt: `${salt}:absence-calendar`,
            label: formatMessage({
                id: 'absence_action_view_in_calendar',
                defaultMessage: 'View in absence calendar'
            }),
            effect: 'neutral',
            onClick: () => navigate(paths.absence.calendar, {
                state: {
                    start,
                    entryFocus: id
                },
                replace: true
            })
        })
    }, [salt])

    const addToCalendarAction = useCallback(({ start, end, type, user }) => {
        if(!end || user?.status?.active === false) {
            return null
        }

        return {
            salt: `${salt}:calendar`,
            label: formatMessage({
                id: 'absence_action_add_to_external_calendar',
                defaultMessage: 'Add to external calendar'
            }),
            effect: 'neutral',
            onClick: () => setAddingToCalendar({
                start,
                end,
                title: typeNameFormatter(type)
            })
        }
    }, [salt])

    const editAction = useCallback(entry => {
        const {
            permissionLevel,
            user,
            status,
            // end
        } = entry

        const editor = permissionLevel === 'edit'
        const approver = permissionLevel === 'approve'
        // const pending = status === 'pending'

        if((!editor && !approver) || user?.status?.active === false || status === 'canceled') {
            return null
        }

        const action = {
            salt: `${salt}:edit`,
            effect: 'neutral'
        }

        // if(!pending && (!!end && isAfter(now, end)) && !approver) {
        //     return {
        //         ...action,
        //         label: formatMessage({
        //             id: 'action_edit_locked',
        //             defaultMessage: 'Locked for editing'
        //         }),
        //         helptext: formatMessage({
        //             id: 'action_edit_locked_caption',
        //             defaultMessage: 'Talk to an administrator to make changes'
        //         }),
        //         disabled: true
        //     }
        // }

        return {
            ...action,
            label: formatMessage({
                id: 'action_edit_details',
                defaultMessage: 'Edit details'
            }),
            onClick: () => setActing({
                entry,
                mode: 'edit'
            })
        }
    }, [salt])

    const endNowAction = useCallback(entry => {
        const {
            end,
            start,
            permissionLevel,
            user
        } = entry

        if(!isAfter(now, start) || !!end || user?.status?.active === false) {
            return null
        }

        if(!['edit', 'approve'].includes(permissionLevel)) {
            return null
        }

        return {
            salt: `${salt}:end-now`,
            label: formatMessage({
                id: 'action_end_now',
                defaultMessage: 'End now'
            }),
            onClick: async () => {
                const result = await endEntry(entry)

                if(result.response?.errorCode?.includes('overlapping') && absenceAdmin) {
                    setActing({
                        entry: {
                            ...entry,
                            end: getDate()
                        },
                        overrideTouched: true,
                        mode: 'edit'
                    })
                } else {
                    await fetchStats()
                }

                return result
            },
            effect: 'neutral'
        }
    }, [salt])

    const removeAction = useCallback(({ permissionLevel, status, start, user, id }) => {
        const editor = permissionLevel === 'edit'
        const approver = permissionLevel === 'approve'
        const rejected = status === 'rejected'

        if((!editor && !approver) || user?.status?.active === false) {
            return null
        }

        const historical = isAfter(now, endOfDay(start))
        const disabled = historical && !approver && !rejected

        return {
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            prompt: {
                question: formatMessage({
                    id: 'absence_delete_prompt_question',
                    defaultMessage: 'Confirm removing this absence'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            },
            effect: 'destructive',
            onClick: async () => {
                const result = await removeEntry(id)
                setActing(null)

                return result
            },
            disabled
        }
    }, [salt])

    const actions = {
        view: viewAction,
        navigateToUserAbsence: navigateToUserAbsenceAction,
        viewAbsenceCalendar: viewAbsenceCalendarAction,
        addToCalendar: addToCalendarAction,
        edit: editAction,
        endNow: endNowAction,
        remove: removeAction
    }

    const end = async () => {
        setEnding(true)

        await endEntry(ongoing)

        setActing({
            entry: ongoing,
            mode: 'view',
            provide: ['approve', 'edit'].includes(ongoing.permissionLevel)
        })

        setEnding(false)
    }

    return (
        <>
            {(ongoing && !!hasEstimated) && (
                <ImportantColumn>
                    <Important className="passive">
                        <Wrapper>
                            <PreviewButton onClick={() => setActing({
                                entry: ongoing,
                                mode: 'view',
                                provide: ['approve', 'edit'].includes(ongoing.permissionLevel)
                            })}>
                                <Symbol
                                    symbol={symbol}
                                    className="hover-effect" />
                                <Meta>
                                    <Title className="compact interpoint-divider">
                                        <span>
                                            <FormattedMessage
                                                id="absence_status_ongoing"
                                                defaultMessage="Ongoing" />
                                        </span>
                                        <span>
                                            <TypeName name={name} />
                                        </span>
                                    </Title>
                                    <CaptionSmall className="compact">
                                        <FormattedMessage
                                            id="days_so_far_count"
                                            defaultMessage="{count, plural, =0 {} =1 {1 day so far} other {{count} days so far}}"
                                            values={{ count: dayCount }} />
                                    </CaptionSmall>
                                </Meta>
                                <Arrow size={24} />
                            </PreviewButton>
                            <Ghost
                                className={`constructive${ending ? ' loading' : ''}`}
                                disabled={ending}
                                onClick={end}>
                                <FormattedMessage
                                    id="action_end_now"
                                    defaultMessage="End now" />
                            </Ghost>
                        </Wrapper>
                    </Important>
                </ImportantColumn>
            )}
            <Modal
                show={!!acting}
                dismiss={() => setActing(null)}
                salt={`${salt}:act`}>
                <ActingEntry
                    {...acting}
                    actions={actions}
                    dismiss={() => setActing(null)}
                    onDone={() => setActing(null)}
                    salt={`${salt}:act`} />
            </Modal>
            <Modal
                salt={`${salt}:add-to-calendar`}
                show={!!addingToCalendar}
                dismiss={() => setAddingToCalendar(null)}>
                <AddToCalendar
                    {...addingToCalendar}
                    close={() => setAddingToCalendar(null)} />
            </Modal>
            <Modal
                show={!!error}
                dismiss={resetError}
                salt={`${salt}:error`}>
                <Error
                    error={error}
                    dismiss={resetError} />
            </Modal>
        </>
    )
}

export default props => (
    <AbsenceTypesProvider>
        <AbsenceStatsProvider userId="me">
            <OngoingAbsence {...props} />
        </AbsenceStatsProvider>
    </AbsenceTypesProvider>
)
