import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { typeToFieldMap, getPropsByType } from 'pages/settings/pages/custom-profile-fields/components/field'

const FieldPropsProvider = ({ field, editing, salt }) => {
    const { formatMessage } = useIntl()

    const Field = typeToFieldMap[field.type]
    const getProps = useCallback(getPropsByType(field.type), [field.type])

    const props = getProps({
        props: {
            salt,
            name: `custom[${field.name}]`,
            label: field.label,
            field: {
                value: field.value,
                editable: field.editable && editing
            },
            ...(field.placeholder ? {
                controlProps: {
                    placeholder: field.placeholder
                }
            } : null),
            ...(field.options ? {
                options: Object.entries(field?.options ?? {})
                    .map(([key, value]) => ({
                        key,
                        value: key,
                        text: value
                    })) ?? []
            } : null)
        },
        formatMessage
    })

    return (
        <Field
            {...props}
            key={`${salt}:${field.name}`} />
    )
}

export default FieldPropsProvider