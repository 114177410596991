import styled from 'styled-components'
import {
    svgStroke,
    darkMode
} from 'utilities/styled'
import { H3 } from 'components/typography/heading'
import TimelineBase from 'components/timeline'
import LinkBase from 'components/link'

export const Heading = styled(H3)`
    line-height: 40px;
    margin-bottom: 0;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: start;
`

export const Timeline = styled(TimelineBase)`
    align-self: normal;

    margin-top: 8px;
`

export const ObscuredTimeline = styled(TimelineBase)`
    cursor: pointer;

    .entry-dot,
    p {
        transition: filter .1s ease-in-out;
        filter: blur(4px);
    }

    &:hover :where(.entry-dot, p) {
        filter: blur(4px) brightness(.6);

        ${darkMode`
            filter: blur(4px) brightness(1.4);
        `}
    }
`

export const Empty = styled.p`
    margin: 0;
    padding: 8px 40px 8px 0;

    color: var(--huma-color-foreground-subtle);
`

export const Link = styled(LinkBase)`
    display: flex;
    align-items: center;
    column-gap: 8px;

    height: 40px;

    svg {
        ${svgStroke()}
    }
`