import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { ModalHeading } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import Form from 'components/form/controller'
import CheckboxesField from 'components/form/field/checkboxes'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'

const ReplaceWarning = ({ dismiss, updating, replaceTopic, salt }) => {
    const { formatMessage } = useIntl()

    return (
        <Form
            layout="vertical">
            {({ values }) => (
                <>
                    <ModalHeading>
                        <FormattedMessage
                            id="handbooks_topic_action_replace"
                            defaultMessage="Update topic from template" />
                    </ModalHeading>
                    <Paragraph>
                        <FormattedMessage
                            id="handbooks_topic_message_replace"
                            defaultMessage="Select what values you’d want to replace the current topic with from the template." />
                    </Paragraph>
                    <CheckboxesField
                        salt={salt}
                        className="compact"
                        name="values"
                        field={{
                            value: ['title', 'content', 'links', 'tags', 'symbol'],
                            include: 'always'
                        }}
                        label={false}
                        options={[
                            {
                                value: 'title',
                                id: 'title',
                                name: formatMessage({
                                    id: 'noun_title',
                                    defaultMessage: 'Title'
                                })
                            },
                            {
                                value: 'content',
                                id: 'content',
                                name: formatMessage({
                                    id: 'noun_content',
                                    defaultMessage: 'Content'
                                })
                            },
                            {
                                value: 'links',
                                id: 'links',
                                name: formatMessage({
                                    id: 'noun_links',
                                    defaultMessage: 'Links'
                                })
                            },
                            {
                                value: 'tags',
                                id: 'tags',
                                name: formatMessage({
                                    id: 'noun_tags',
                                    defaultMessage: 'Tags'
                                })
                            },
                            {
                                value: 'symbol',
                                id: 'symbol',
                                name: formatMessage({
                                    id: 'editor_block_emoji_name',
                                    defaultMessage: 'Symbol'
                                })
                            }
                        ]} />
                    <Actions>
                        <Plain onClick={dismiss}>
                            <FormattedMessage
                                id="action_cancel"
                                disabled={updating}
                                defaultMessage="Cancel" />
                        </Plain>
                        <Button
                            className={`constructive${updating ? ' loading' : ''}`}
                            disabled={updating || !values?.values?.length}
                            onClick={() => replaceTopic(values.values)}>
                            <FormattedMessage
                                id="handbooks_action_replace_and_edit"
                                defaultMessage="Replace and continue editing" />
                        </Button>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default ReplaceWarning
