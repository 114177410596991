import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import ClampBase from 'components/clamp'
import { CaptionSmall } from 'components/typography/caption'
import Link from 'components/link'

export const Clamp = styled(ClampBase)`
    margin-block-end: 8px;
`

export const InfoText = styled(CaptionSmall)`
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
`

export const ExternalLink = styled(Link)`
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 4px;

    svg {
        ${svgStroke('small')}
    }
`