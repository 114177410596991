import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { captionSmallCss } from 'components/typography/caption'

export const Trend = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    margin-block-end: 8px;

    ${captionSmallCss};

    svg {
        ${svgStroke('small')};
    }

    &.up svg {
        color: var(--huma-color-foreground-success);
    }

    &.down svg {
        color: var(--huma-color-foreground-error);
    }
`