import styled from 'styled-components'
import {
    svgStroke,
    fromPhone, belowPhone
} from 'utilities/styled'
import Link from 'components/link'
import AvatarBase from 'components/avatar'
import { CaptionSmall } from 'components/typography/caption'
import { DisplayEmoji } from 'components/symbol'
import { Plain } from 'components/button'

export const Wrapper = styled.div`
    display: grid;
    align-items: center;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }

    ${fromPhone`
        grid-template-columns: 6fr 1fr 1fr;

        padding-block: 8px;
    `}

    ${belowPhone`
        grid-template-rows: repeat(2, auto);
        grid-template-columns: auto 1fr;
        gap: 8px;

        padding-block: 16px;
    `}
`

export const UserLink = styled(Link)`
    display: inline-grid;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    ${fromPhone`
        grid-template-columns: 20px 1fr 1fr;
        column-gap: 8px;
    `}

    ${belowPhone`
        grid-row: 1 / 2;
        grid-template: 24px 20px / 40px auto;
        column-gap: 16px;
    `}

    > * {
        text-decoration: none;
    }
`

export const Avatar = styled(AvatarBase)`
    ${fromPhone`
        width: 20px;
    `}

    ${belowPhone`
        display: none;
    `}
`

export const MobileAvatar = styled(AvatarBase)`
    ${fromPhone`
        display: none;
    `}

    ${belowPhone`
        grid-row: 1 / -1;

        width: 40px;
        height: 40px;
    `}
`

export const Name = styled.div`
    ${UserLink}:hover &,
    ${UserLink}:focus & {
        text-decoration: underline;
    }
`

export const Type = CaptionSmall

export const Emoji = styled(DisplayEmoji)`
    &:not(:only-child) {
        margin-inline-end: 4px;
    }
`

export const GiftButton = styled(Plain)`
    ${belowPhone`
        grid-row: 2 / 3;
        grid-column: 1 / 2;

        margin-inline-start: 56px;
    `}

    svg {
        ${svgStroke('small')}
    }
`

export const DateCell = styled.div`
    display: flex;
    justify-content: end;

    font-size: 14px;
    line-height: 16px;
    color: var(--huma-color-foreground-subtle);

    ${fromPhone`
        grid-column: 3 / 4;
    `}

    ${belowPhone`
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        align-self: end;
    `}

    svg {
        ${svgStroke()}
    }
`