import React, { useState/*, useEffect*/ } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
// import { get } from 'api'
// import { compact, unique } from 'utilities/array'
import { pick, omit } from 'utilities/object'
import { uuid4Pattern } from 'utilities/regex'
import Form from 'components/form/controller'
import { /*LoaderWrap, */Heading, FileHelper, Message, InlineMessage } from './s'
// import Loader from 'components/loader'
import FileField from 'components/form/field/file'
import { CaptionSmall } from 'components/typography/caption'
import { ParagraphSmall as MessageContent } from 'components/typography/paragraph'
import { NeutralCode } from 'components/code/s'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'

const ImportHandbook = ({ onDone, salt }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const manageAccess = check('handbook:manage')

    // const [ready, setReady] = useState(false)
    // const [groupIds, setGroupIds] = useState(null)
    const [handbook, setHandbook] = useState(null)
    const [messages, setMessages] = useState(null)

//     useEffect(() => {
//         const fetch = async () => {
//             const results = await Promise.all([
//                 get({ path: '/teams' }),
//                 get({ path: '/locations' })
//             ])
//
//             if(results.every(({ ok }) => ok)) {
//                 setGroupIds(results.flatMap(({ response }) => response).map(({ id }) => id))
//                 setReady(true)
//             }
//         }
//
//         if(manageAccess && !groupIds) {
//             fetch()
//         }
//     }, [manageAccess, groupIds])

    if(!manageAccess) {
        return null
    }

    // if(!ready) {
    //     return (
    //         <LoaderWrap>
    //             <Loader />
    //         </LoaderWrap>
    //     )
    // }

    const onFileChange = async ({ file }) => {
        let handbook = null

        if(file) {
            try {
                const text = await file.text()
                handbook = JSON.parse(text)
            } catch(error) {} // eslint-disable-line no-empty
        }

        if(handbook) {
            handbook = pick(handbook, 'type', 'id', 'name', 'categories')

            if([
                handbook.type !== 'handbook',
                !handbook.id || !uuid4Pattern.test(handbook.id),
                !handbook.name || typeof handbook.name !== 'string',
                !handbook.categories || !Array.isArray(handbook.categories)
            ].some(Boolean)) {
                setMessages([{
                    type: 'error',
                    message: formatMessage({
                        id: 'handbook_template_import_error_invalid_data_format',
                        defaultMessage: 'The file you’ve chosen does not conform to the handbook format. Make sure it has <code>"type": "handbook"<\/code>, <code>id<\/code>, <code>type<\/code> and <code>categories<\/code>.'
                    }, { code: chunks => <NeutralCode className="bigger">{chunks}</NeutralCode> }),
                    key: `${salt}:error_invalid_data_format`
                }])

                return void setHandbook(null)
            }

            setMessages([
                {
                    type: 'success',
                    message: formatMessage({
                        id: 'handbook_import_success_summary',
                        defaultMessage: '{categoryCount, plural, =0 {The handbook, without any categories and topics, will be available.} =1 {The handbook, with <strong>1 category<\/strong> and a total of <strong>{topicCount} topics<\/strong>, will be available.} other {The handbook, with <strong>{categoryCount} categories<\/strong> and a total of <strong>{topicCount} topics<\/strong>, will be available.}}'
                    }, {
                        categoryCount: handbook.categories.length,
                        topicCount: handbook.categories.flatMap(({ topics }) => topics).length
                    }),
                    children: (
                        <MessageContent className="compact">
                            <FormattedMessage
                                id="handbook_import_success_summary_details"
                                defaultMessage="You can choose which categories and topics to include in the next step." />
                        </MessageContent>
                    ),
                    key: `${salt}:success_summary`
                }
            ])

            const sanitized = await sanitizeCategoryTopics(handbook/*, groupIds*/)

//             if(sanitized.brokenShareTypes.length) {
//                 let units
//
//                 if(sanitized.brokenShareTypes.length === 1) {
//                     units = formatMessage({
//                         id: `noun_${sanitized.brokenShareTypes[0]}`,
//                         defaultMessage: sanitized.brokenShareTypes[0]
//                     }).toLowerCase()
//                 } else {
//                     units = formatMessage({
//                         id: 'units_' + compact([
//                             sanitized.brokenShareTypes.includes('teams') && 'teams',
//                             sanitized.brokenShareTypes.includes('locations') && 'locations'
//                         ]).join('_'),
//                         defaultMessage: ''
//                     }).toLowerCase()
//                 }
//
//                 setMessages(messages => [
//                     ...(messages ?? []),
//                     {
//                         type: 'info',
//                         message: formatMessage({
//                             id: 'handbook_import_info_specific_shares_ignored',
//                             defaultMessage: 'Shares to {units} that we couldn’t find in your organization will be ignored'
//                         }, { units }),
//                         key: `${salt}:info_specific_shares_ignored`
//                     }
//                 ])
//             }

            setHandbook(sanitized.handbook)
        } else {
            setMessages(null)
            setHandbook(null)
        }
    }

    return (
        <>
            <Heading>
                <FormattedMessage
                    id="action_import_from_file"
                    defaultMessage="Import from file" />
            </Heading>
            <Form layout="vertical">
                <FileField
                    salt={salt}
                    label={false}
                    name="file"
                    accept={{ 'application/json': ['.json'] }}
                    multiple={false}
                    helper={(
                        <FileHelper>
                            <CaptionSmall className="compact">
                                <FormattedMessage
                                    id="handbook_import_upload_text"
                                    defaultMessage="Supports handbooks created in and exported from Huma." />
                                <FormattedMessage
                                    id="sentence_separator"
                                    defaultMessage=" " />
                                <FormattedMessage
                                    id="upload_size_maximum_megabytes"
                                    defaultMessage="Maximum file size: {megabytes}MB"
                                    values={{ megabytes: 10 }} />
                                <FormattedMessage
                                    id="sentence_period"
                                    defaultMessage="." />
                            </CaptionSmall>
                        </FileHelper>
                    )}
                    onChange={onFileChange} />
            </Form>
            {!!messages?.length && messages.map(message => {
                const MessageView = {
                    success: Message,
                    error: Message
                }[message.type] ?? InlineMessage

                return (
                    <MessageView
                        {...message}
                        key={message.key} />
                )
            })}
            <Actions className="compact">
                <Plain
                    onClick={() => onDone()}
                    className="neutral">
                    <FormattedMessage
                        id="action_cancel"
                        defaultMessage="Cancel" />
                </Plain>
                <Button
                    className="constructive"
                    disabled={[
                        !handbook,
                        messages?.find(({ type }) => type === 'error')
                    ].some(Boolean)}
                    onClick={() => onDone(handbook)}>
                    <FormattedMessage
                        id="action_preview"
                        defaultMessage="Preview" />
                </Button>
            </Actions>
        </>
    )
}

const sanitizeCategoryTopics = async (handbook/*, groupIds*/) => {
    // let unitIds = [...groupIds]
    // const brokenShareTypes = {}

    const objectFilter = item =>
        typeof item === 'object' &&
        !Array.isArray(item)

    handbook.categories = handbook.categories
        .filter(objectFilter)
        .map(category => ({
            ...category,
            topics: category.topics
                .filter(objectFilter)
                .map(topic => ({
                    ...omit(topic, 'highlight'),
                    shares: []
                }))

        }))

//     const groupShares = handbook.categories
//         .flatMap(({ topics }) => topics ?? [])
//         .flatMap(({ shares }) => shares ?? [])
//         .filter(({ type }) => ['team', 'location'].includes(type))
//
//     unique(groupShares
//         .filter(({ type }) => type === 'team')
//         .map(({ id }) => id))
//         .forEach(id => {
//             if(!unitIds.includes(id)) {
//                 brokenShareTypes.teams = true
//             }
//         })
//
//     unique(groupShares
//         .filter(({ type }) => type === 'location')
//         .map(({ id }) => id))
//         .forEach(id => {
//             if(!unitIds.includes(id)) {
//                 brokenShareTypes.locations = true
//             }
//         })

    return {
        handbook,
        // brokenShareTypes: Object.entries(brokenShareTypes)
        //     .filter(([, value]) => !!value)
        //     .map(([key]) => key)
    }
}

export default ImportHandbook