import React, { Component, createContext, useContext } from 'react'
import PubSub from 'pubsub-js'
import { get, post, patch } from 'api'

export const SubordinatesContext = createContext()

export default class SubordinatesProvider extends Component {
    constructor(props) {
        super(props)

        this.userId = props?.userId

        this.state = {
            subordinates: props?.subordinates ?? [],

            flash: this.flash,
            clearFlash: this.clearFlash,

            addSubordinates: this.add,
            transferSubordinates: this.transfer,
            removeSubordinate: this.remove,

            hasFetched: false,
            fetching: false
        }
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {
        const { userId } = this.props
        const { fetching } = this.state

        if(!userId || fetching) {
            return
        }

        this.setState({ fetching: true })

        const { ok, response: subordinates } = await get({
            path: `/users/${userId}/subordinates`
        })

        if(ok && subordinates) {
            this.setState({
                subordinates,
                hasFetched: true,
                fetching: false
            })
        } else {
            this.setState({
                hasFetched: true,
                fetching: false
            })
        }
    }

    add = async body => {
        const { userId } = this.props

        const { ok } = await post({
            path: `/users/${userId}/subordinates`,
            body
        })

        ok && PubSub.publish('person.refresh')

        return { ok }
    }

    transfer = async supervisorId => {
        const { subordinates = [] } = this.state

        const { ok, response } = await post({
            path: `/users/${supervisorId}/subordinates`,
            body: subordinates.map(({ id }) => id)
        })

        ok && PubSub.publish('person.refresh')

        return { ok, response }
    }

    remove = async userId => {
        const { ok } = await patch({
            path: `/users/${userId}`,
            body: {
                supervisor: null
            }
        })

        ok && PubSub.publish('person.refresh')

        return { ok }
    }

    clearFlash = () => this.setState(({ emergencyContacts }) => {
        this.flash.current = null

        return {
            emergencyContacts: [...emergencyContacts]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <SubordinatesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </SubordinatesContext.Provider>
        )
    }
}

export const useSubordinates = () => useContext(SubordinatesContext)