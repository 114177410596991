import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useStorage } from 'hooks/storage'
import { showAnnouncements } from '../../'
import { Item, IconWrapper, Title, ModalIcon, Count } from '../s'
import { HeaderWrapper, NewBadge } from './s'
import { Simple } from 'components/button'
import { Bullhorn as Icon } from 'components/feather'
import { ChevronRight } from 'styled-icons/feather'

const Announcements = ({ context = 'quick-guide' }) => {
    const { announcements: allAnnouncements } = useServiceOnboarding()

    const [dismissedNewlyAdded, setDismissedNewlyAdded] = useStorage({
        key: 'dismissed:newly-added',
        defaultValue: []
    })

    const announcements = allAnnouncements?.filter(({ meta }) => {
        if(meta.expires) {
            return meta.current
        }

        return meta.current || meta.past
    })

    if(!announcements?.length) {
        return null
    }

    if(context === 'header') {
        const newAnnouncements = announcements.filter(({ meta }) => !!meta.banner && !!meta.current)

        const [latestAnnounement] = newAnnouncements
        const key = `announcement:${latestAnnounement?.id}`

        return (
            <HeaderWrapper>
                {(!!newAnnouncements.length && !dismissedNewlyAdded.includes(key)) && (
                    <NewBadge>
                        <FormattedMessage
                            id="noun_new"
                            defaultMessage="New" />
                    </NewBadge>
                )}
                <Simple
                    onClick={() => {
                        showAnnouncements()

                        if(latestAnnounement) {
                            setTimeout(() => setDismissedNewlyAdded([
                                ...dismissedNewlyAdded,
                                key
                            ]), 1500)
                        }
                    }}
                    icon={Icon} />
            </HeaderWrapper>
        )
    }

    return (
        <Item
            onClick={showAnnouncements}
            className="with-count">
            <IconWrapper>
                <Icon size={24} />
            </IconWrapper>
            <Title>
                <FormattedMessage
                    id="noun_announcements"
                    defaultMessage="Announcements" />
            </Title>
            <Count number={announcements.length} />
            <ModalIcon>
                <ChevronRight size={24} />
            </ModalIcon>
        </Item>
    )
}

export default Announcements