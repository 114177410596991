import React from 'react'
import { OrderedList } from 'components/list'
import User from './user'

const Users = ({ users, salt, ...props }) => (
    <OrderedList>
        {users.map(user => (
            <User
                {...props}
                user={user}
                key={`${salt}:users:${user.id}`} />
        ))}
    </OrderedList>
)

export default Users