import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useAccess } from 'contexts/access'
import { useAppSignal } from 'hooks/app-signal'
import { useLocationQueryIntent } from 'hooks/location-intent'
import { refreshPayment } from 'utilities/payment'
import { filter, size, map } from 'utilities/object'
import { Scrollable as Modal } from 'modals/generic'
import { HeaderRow, Appear } from './s'
import Paragraph from 'components/typography/paragraph'
import { ModalHeader } from 'modals/generic'
import Link from 'components/link'
import { ArrowUpCircle as Icon } from 'styled-icons/feather'
import Upgrade from 'pages/settings/components/upgrade'
import ModuleFeature from 'components/tutorial/module-feature'
import FeedbackQuestions from 'app/layouts/private/help/feedback-questions'
import Manage from 'pages/settings/pages/subscription/main/manage'

const salt = 'value-proposition'

const Value = () => {
    const { formatMessage } = useIntl()

    const { tutorials } = useServiceOnboarding()
    const featured = filter(tutorials, ({ featured }) => featured) ?? {}

    const {
        initialized,

        check,
        getBundlesWithModule,
        getBundlesWithFeature
    } = useAccess()

    const upgrader = initialized && check('organization:manage')

    const [show, setShow] = useState(false)
    const [data, setData] = useState(null)
    const [managing, setManaging] = useState(null)

    const [loaded, setLoaded] = useState({})
    const updateLoaded = id => setLoaded(loaded => ({
        ...loaded,
        [id]: true
    }))

    useAppSignal({
        namespace: 'value.show',
        action: data => {
            setShow(!!data)
            setData(data ?? {})
        }
    })

    useLocationQueryIntent({
        intent: 'value',
        action: () => {
            setShow(true)
            setData({})
        }
    })

    const dismiss = () => {
        setShow(false)
        setData(null)
    }

    const {
        manager,
        module,
        feature,
        useUpgradeIcon = true
    } = data ?? {}

    const plansWithFeature = manager?.meta?.plansWithFeature ?? (
        module ?
            getBundlesWithModule(module) :
            feature ?
                getBundlesWithFeature(feature) :
                []
    )

    const upgradable = !!data && !!upgrader && (!!module || !!feature)

    return (
        <>
            <Modal
                show={show}
                dismiss={dismiss}
                className="large"
                enrichChildren={false}>
                <HeaderRow>
                    {!!Icon && <Icon size={24} />}
                    <div>
                        <ModalHeader
                            heading={formatMessage({
                                id: 'value_proposition_title',
                                defaultMessage: 'Release the full potential!'
                            })}
                            dismiss={dismiss}
                            closeButtonOffset={upgradable ? 0 : -8}
                            small compact />
                        <Paragraph className="caption small compact interpoint-divider">
                            <span>
                                <FormattedMessage
                                    id="value_proposition_caption"
                                    defaultMessage="Upgrade your Huma subscription today" />
                            </span>
                            <Link
                                to="path:settings.subscription.plans"
                                target="_blank"
                                className="constructive">
                                <FormattedMessage
                                    id="payment_subscription_compare_plans"
                                    defaultMessage="Compare plans" />
                            </Link>
                        </Paragraph>
                    </div>
                    {upgradable && (
                        <Upgrade
                            module={module}
                            feature={feature}
                            plans={plansWithFeature}
                            setManaging={manager?.setManaging ?? setManaging}
                            useIcon={useUpgradeIcon} />
                    )}
                </HeaderRow>
                {!!size(featured) && map(featured, (tutorial, id, index) => {
                    const animate = (show && !!loaded[id]) ? 'in' : 'out'

                    return (
                        <Appear
                            animate={animate}
                            key={`${salt}:${id}`}>
                            <ModuleFeature
                                id={id}
                                data={tutorial}
                                reversed={index % 2 !== 0}
                                onScreenshotLoaded={updateLoaded} />
                        </Appear>
                    )
                })}
                <FeedbackQuestions mode="support" />
            </Modal>
            {/* Support for self-contained Value modal when there’s no outer manager layer */}
            {!manager?.setManaging && (
                <Modal
                    show={!!managing}
                    dismiss={() => setManaging(null)}
                    salt={`${salt}:subscription:manage`}>
                    <Manage
                        meta={managing}
                        onDone={() => {
                            refreshPayment()
                            setManaging(null)
                            dismiss()
                        }}
                        salt={salt} />
                </Modal>
            )}
        </>
    )
}

export default Value