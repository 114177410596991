import React, { Fragment } from 'react'
import EquipmentPieceProvider, { useEquipmentPiece } from 'contexts/equipment-piece'
import Loader from 'components/loader'
import Hero from './hero'
import Details from './details'
import Documentation from './documentation'
import Actions from './actions'

const ViewEquipmentPiece = ({ provide, piece, onDone, actions, salt, ...props }) => {
    const {
        type,
        attachments,
        links
    } = piece ?? {}

    const hasDocumentation = (!!attachments?.length || !!links?.length)

    return (
        <>
            <Hero
                type={type}
                dismiss={onDone} />
            <Details
                actions={actions}
                piece={piece}
                {...props} />
            {hasDocumentation && (
                <Documentation
                    piece={piece}
                    provide={provide}
                    salt={salt} />
            )}
            <Actions
                actions={actions}
                context={piece}
                dismiss={onDone}
                salt={salt} />
        </>
    )
}

const PieceProvider = props => {
    const { piece } = useEquipmentPiece()

    if(!piece) {
        return <Loader />
    }

    return (
        <ViewEquipmentPiece
            {...props}
            piece={piece} />
    )
}

export default ({ pieceId = null, ...props }) => {
    const { provide = false } = props

    const EquipmentPieceWrapper = provide ?
        EquipmentPieceProvider :
        Fragment

    const wrapperProps = provide ? {
        id: pieceId,
        fetchOnMount: true
    } : null

    return (
        <EquipmentPieceWrapper {...wrapperProps}>
            {!!provide && <PieceProvider {...props} />}
            {!provide && <ViewEquipmentPiece {...props} />}
        </EquipmentPieceWrapper>
    )
}
