import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Naked } from 'components/button'
import Caption from 'components/typography/caption'
import { PlainTexts, TriggerTexts } from 'components/entities'

export const MetaRow = styled.div`
    padding: 4px 0;

    &.space {
        margin-block-start: 8px;
    }
`

export const DateTime = styled(Caption).attrs(() => ({ as: 'time' }))`
    display: block;

    white-space: nowrap;

    &::first-letter {
        text-transform: uppercase;
    }

    svg {
        ${svgStroke('small')}

        margin-inline-end: 16px;
        margin-block-start: -.125em;
    }
`

export const Entities = styled(TriggerTexts)`
    flex-wrap: wrap;

    min-height: inherit;

    &:empty {
        display: none;
    }
`

export const EntitiesPlain = styled(PlainTexts)`
    flex-wrap: wrap;

    min-height: inherit;

    &:empty {
        display: none;
    }
`

export const Tags = styled(Caption)`
    svg {
        ${svgStroke('small')}

        margin-inline-end: 16px;
        margin-block-start: -.125em;

        color: var(--huma-color-foreground-subtle);
    }
`

export const Tag = styled(Naked)`
    transition: all .1s ease-in-out;

    text-align: left;
    color: var(--huma-color-foreground-subtle);

    &:hover {
        text-decoration: underline;
        color: var(--huma-color-foreground-constructive-bold);
    }
`