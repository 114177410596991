import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useUpgradable } from 'hooks/upgradable'
import { unoptionize } from 'components/form/field/share'
import { ModalHeader } from 'modals/generic'
import ShareOrEveryoneField from 'components/form/field/share-or-everyone'
import Message from 'components/message'
import UpgradeCTA from 'components/upgrade-cta'

const BulkTopicsShares = ({ category, salt }) => {
    const { formatMessage } = useIntl()

    const groupsUpgradable = useUpgradable()({ feature: 'handbook-groups' })

    const [shares, setShares] = useState([{ type: 'organization' }])

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'noun_access',
                    defaultMessage: 'Access'
                })} />
            <ShareOrEveryoneField
                salt={salt}
                label={false}
                name="shares"
                field={{
                    value: shares,
                    which: (!shares?.length || shares?.find(({ type }) => type === 'organization')) ?
                        'everyone' :
                        'shares',
                    include: 'always',
                }}
                picker={{ types: ['team', 'location'] }}
                everyoneFirst={true}
                shareLabel={formatMessage({
                    id: 'groups_members_of',
                    defaultMessage: 'Members of teams or locations'
                })}
                {...(groupsUpgradable ? {
                    shareHidden: true,
                    shareUpgradable: groupsUpgradable,
                    shareContent: (
                        <UpgradeCTA
                            feature="handbook-groups"
                            useUpgradeIcon
                            useFeatureOrModuleIcon
                            useFeatureDescription
                            className="compact"
                            salt="handbook-groups" />
                    )
                } : null)}
                onChange={({ shares = [], which }) => setShares((which === 'everyone') ?
                    [{ type: 'organization' }] :
                    shares.map(unoptionize)
                )} />
            <Message
                type="info"
                className="compact"
                message={formatMessage({
                    id: 'handbooks_info_edit_topics_shares',
                    defaultMessage: '{count, plural, =0 {} =1 {Changing access for <strong>one topic<\/strong>} other {Changing access for <strong>{count} topics<\/strong>}}'
                }, { count: category.topics.length })} />
        </>
    )
}

export default BulkTopicsShares