import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAbsenceEntry } from 'contexts/absence-entry'
import { useEnrichedActions } from 'widgets/context-menu'
import {
    User as NavigateToUserAbsence,
    Sun as ViewAbsenceCalendar
} from 'styled-icons/feather'
import { ContextMenu, Caption } from './s'

const ViewEntryActions = ({ entry, fetchEntry = null, actions, dismiss, salt }) => {
    actions = useEnrichedActions({
        namedActions: actions,
        context: entry,
        onAfter: {
            ...(fetchEntry ? { endNow: () => fetchEntry() } : null),
            remove: dismiss,
            viewAbsenceCalendar: dismiss
        },
        icons: {
            navigateToUserAbsence: <NavigateToUserAbsence size={24} />,
            viewAbsenceCalendar: <ViewAbsenceCalendar size={24} />
        },
        include: ['edit', 'navigateToUserAbsence', 'viewAbsenceCalendar', 'addToCalendar', 'endNow', 'remove']
    })

    return (
        <>
            <ContextMenu
                prebound actions={actions}
                salt={salt} />
            {!!actions.remove?.disabled && (
                <Caption>
                    <FormattedMessage
                        id="absence_message_deleting_restricted"
                        defaultMessage="Only administrators can delete an ongoing or past absence." />
                </Caption>
            )}
        </>
    )
}

const EntryProvider = props => {
    const { fetchEntry } = useAbsenceEntry()

    return (
        <ViewEntryActions
            {...props}
            fetchEntry={fetchEntry} />
    )
}

export default ({ provide, ...props }) => (
    <>
        {!!provide && <EntryProvider {...props} />}
        {!provide && <ViewEntryActions {...props} />}
    </>
)