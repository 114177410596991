import React, { forwardRef } from 'react'
import { RiskLevel } from './s'
import Label from './label'

const DeviationRiskLevel = forwardRef(({ level, ...props }, ref) => {
    if(!level) {
        return null
    }

    return (
        <RiskLevel
            {...props}
            ref={ref}>
            <Label level={level} />
        </RiskLevel>
    )
})

export default DeviationRiskLevel
