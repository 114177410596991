import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useChildren } from 'contexts/children'
import { size } from 'utilities/object'
import { Fields } from './s'
import Form from 'components/form/controller'
import { H3 as Heading } from 'components/typography/heading'
import StringField from 'components/form/field/string'
import TimeField from 'components/form/field/time'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'

const EditChild = ({ child = {}, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        addChild,
        updateChild
    } = useChildren()

    const [constructing, setConstructing] = useState(false)

    const {
        id,
        name,
        birthDate
    } = child

    const addOrUpdate = async (body, { resetTouched }) => {
        setConstructing(true)

        const addOrUpdateChild = id ?
            updateChild :
            addChild

        const { ok } = await addOrUpdateChild(body, id)

        setConstructing(false)

        if(ok) {
            resetTouched()
            onDone && onDone()
        }
    }

    const heading = formatMessage({
        id: id ? 'child_action_update' : 'child_action_add',
        defaultMessage: id ? 'Update child' : 'Add child'
    })

    const submitButtonText = formatMessage({
        id: id ? 'action_update' : 'action_add',
        defaultMessage: id ? 'Update' : 'Add'
    })

    return (
        <Form
            layout="vertical"
            resetTouchedOnSubmit={true}
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <Heading>{heading}</Heading>
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="name"
                            controlProps={{ autoFocus: true }}
                            field={{
                                value: name,
                                required: true,
                                include: 'always'
                            }} />
                        <TimeField
                            salt={salt}
                            simple={true}
                            label={formatMessage({
                                id: 'person_label_birthdate',
                                defaultMessage: 'Date of birth'
                            })}
                            name="birthDate"
                            field={{
                                value: birthDate,
                                required: true,
                                include: 'always',
                                autoCompleteYear: true
                            }}
                            picker={{
                                future: false,
                                monthAsSelect: true
                            }} />
                    </Fields>
                    <Actions>
                        <Plain
                            onClick={onDone}
                            className="neutral"
                            disabled={constructing}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={!touched.length || !!size(errors) || constructing}
                            ref={trigger}>
                            {submitButtonText}
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default EditChild
