import React, { Component, createContext, useContext } from 'react'
import { useWorkSchedules } from 'contexts/work-schedules'
import { get, post } from 'api'

export const WorkScheduleContext = createContext()

class WorkScheduleProvider extends Component {
    constructor(props) {
        super(props)

        !!props?.id && this.setId(props.id, false)

        this.state = {
            schedule: null,
            deleted: false,

            fetch: this.fetch,
            assignPeople: this.assignPeople,
            reassignPeople: this.reassignPeople,
            getScheduleForUser: this.getScheduleForUser,
            setId: this.setId,

            fetching: false
        }
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate = ({ id, workSchedules }) => {
        const idChanged = id !== this.props.id
        const workSchedulesReady = !workSchedules?.hasFetched && this.props.workSchedules?.hasFetched

        if(idChanged) {
            this.setId(this.props.id, false)
        }

        if(idChanged || workSchedulesReady) {
            this.fetch()
        }
    }

    fetch = async () => {
        const { fetching } = this.state
        const { workSchedules } = this.props

        if(!this.id || !workSchedules?.hasFetched || fetching) {
            return
        }

        const schedule = workSchedules.schedules.find(({ id }) => id === this.id)

        this.setState({
            schedule,
            fetching: true
        })

        const { response: users, ok } = await get({
            path: `/work-schedules/${this.id}/users`
        })

        if(ok) {
            this.setState(({ schedule }) => ({
                schedule: {
                    ...schedule,
                    users
                },
                fetching: false
            }))
        } else {
            this.setState({ fetching: false })
        }
    }

    getScheduleForUser = async userId => {
        this.setState({ fetching: true })

        const { response, ok } = await get({
            path: `/users/${userId}/work-schedule`
        })

        const schedule = this.props.workSchedules.schedules.find(
            schedule => schedule.id === response.id
        )

        if(ok) {
            this.setState({
                schedule,
                fetching: false
            })
        }

        return { response: schedule, ok }
    }

    assignPeople = async userIds => {
        if(!Array.isArray(userIds)) {
            userIds = [userIds]
        }

        const { ok } = await post({
            path: `/work-schedules/${this.id}/assign`,
            body: { userIds },
            returnsData: false
        })

        if(ok) {
            await this.props.workSchedules.fetchSchedules()
            this.fetch()
        }

        return { ok }
    }

    reassignPeople = async (newScheduleId, userIds, modifyState = true) => {
        if(!Array.isArray(userIds)) {
            userIds = [userIds]
        }

        const { ok } = await post({
            path: `/work-schedules/${newScheduleId}/assign`,
            body: { userIds },
            returnsData: false
        })

        if(ok && modifyState) {
            this.setState(({ schedule }) => {
                const remainingUsers = schedule.users.filter(({ id }) => !userIds.includes(id))

                return {
                    schedule: {
                        ...schedule,
                        users: remainingUsers
                    }
                }
            })

            this.props.workSchedules.fetchSchedules()
        }

        return { ok }
    }

    setId = (id, fetch = true) => {
        this.id = id
        fetch && this.fetch()

        !id && this.setState({ users: [] })
    }

    render() {
        const { children = null } = this.props

        return (
            <WorkScheduleContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </WorkScheduleContext.Provider>
        )
    }
}

export const useWorkSchedule = () => useContext(WorkScheduleContext)

export default props => {
    const workSchedules = useWorkSchedules()

    return (
        <WorkScheduleProvider
            {...props}
            workSchedules={workSchedules} />
    )
}