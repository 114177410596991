import React, { Component, createContext, useContext, createRef } from 'react'
import { intersectionObserver } from 'utilities/dom'
import { local } from 'utilities/storage'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays, pick } from 'utilities/object'
import { get, post, patch, remove } from 'api'

export const EquipmentPiecesContext = createContext()

export default class EquipmentPiecesProvider extends Component {
    constructor(props) {
        super(props)

        !!props?.typeId && this.setTypeId(props.typeId, false)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults(props.sortByDefault)
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.state = {
            pieces: [],
            filter: props?.filter ?? {},
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetch: this.fetch,
            fetchPiece: this.fetchPiece,
            addPiece: this.add,
            updatePiece: this.update,
            updatePieceLocally: this.updateLocally,
            pushPiece: this.push,
            removePiece: this.remove,
            setPiecesFilter: this.setFilter,
            toggleSorting: this.toggleSorting,
            setTypeId: this.setTypeId,

            hasFetched: false,
            fetchError: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }

        PubSub.subscribe('equipmentPieces.refresh', () => {
            this.setState({
                pieces: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }, this.fetchDebounced)
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging }, { filter, sorting }) {
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe('equipmentPieces.refresh')
    }

    fetch = async (force = false) => {
        const {
            fetching,
            filter,
            sorting,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            fetchError: false,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = await get({
            path: '/equipment',
            params: {
                ...filter,
                ...nextPaging,
                ...(this.typeId ? { type: this.typeId } : null),
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ pieces: previousPieces }) => {
                const previousPiecesWithoutFetched = previousPieces.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const pieces = [
                    ...previousPiecesWithoutFetched,
                    ...response.items
                ]

                return {
                    pieces,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && pieces.length < response.total
                    },
                    hasFetched: true,
                    fetchError: false,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                fetchError: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    fetchPiece = async id => await get({ path: `/equipment/${id}` })

    add = async body => {
        const { ok, response: piece } = await post({
            path: '/equipment',
            body
        })

        if(ok && piece) {
            this.setState(({ pieces }) => ({
                pieces: [
                    piece,
                    ...pieces
                ]
            }))
        }

        return { ok, response: piece }
    }

    update = async (body, id, reassigned) => {
        const { ok, response: piece } = await patch({
            path: `/equipment/${id}`,
            body
        })

        if(ok && piece) {
            this.setState(({ pieces }) => {
                if(reassigned) {
                    return {
                        pieces: pieces.filter(piece => piece.id !== id)
                    }
                }

                const index = pieces.findIndex(piece => piece.id === id)

                return {
                    pieces: [
                        ...pieces.slice(0, index),
                        piece,
                        ...pieces.slice(index + 1, pieces.length)
                    ]
                }
            })
        }

        return { ok, response: piece }
    }

    updateLocally = (body, pieceId) => void this.setState(({ pieces }) => {
        const index = pieces.findIndex(({ id }) => id === pieceId)

        const piece = {
            ...pieces[index],
            ...body
        }

        this.flash.current = piece

        return {
            pieces: [
                ...pieces.slice(0, index),
                piece,
                ...pieces.slice(index + 1, pieces.length)
            ]
        }
    })

    push = piece => void this.setState(({ pieces }) => ({
        pieces: [
            ...pieces,
            piece
        ]
    }))

    remove = async id => {
        const { ok } = await remove({
            path: `/equipment/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ pieces, paging }) => ({
                pieces: pieces.filter(piece => piece.id !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setFilter = (filter = {}, sorting = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        sorting = {
            ...sortingDefaults(this.props.sortByDefault),
            ...sorting
        }

        this.setState(({ filter: previousFilter, sorting: previousSorting }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            const sortingChanged = !isEqual(sorting, previousSorting)

            if(!filterChanged && !sortingChanged) {
                return null
            }

            const state = {
                pieces: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }

            if(filterChanged) {
                state.filter = filter
            }

            if(sortingChanged) {
                state.sorting = sorting
                !!this.sortCacheKey && local.set(this.sortCacheKey, sorting)
            }

            return state
        })
    }

    toggleSorting = field => {
        const sortingOptions = getSortingOptions()

        if(field in sortingOptions) {
            this.setState(({ sorting }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc' : 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    pieces: [],
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }
            })
        }
    }

    setTypeId = (id, fetch = true) => {
        this.typeId = id
        fetch && this.fetch(true)
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ pieces }) => {
        this.flash.current = null

        return {
            pieces: [...pieces]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <EquipmentPiecesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EquipmentPiecesContext.Provider>
        )
    }
}

const getSortingOptions = () => ({
    type_name: 'asc',
    assigned_to: 'asc',
    to_date: 'asc',
    serial_number: 'asc',
    variant: 'asc'
})

const sortingDefaults = (sortByDefault = 'type_name') => {
    const sortingOptions = pick(getSortingOptions(), sortByDefault)

    return {
        by: Object.keys(sortingOptions)[0],
        direction: Object.values(sortingOptions)[0]
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useEquipmentPieces = () => useContext(EquipmentPiecesContext)