import styled, { keyframes } from 'styled-components'
import { darkMode } from 'utilities/styled'
import { Grid } from 'components/grid'

export const Wrapper = styled(Grid)`
    position: relative;
    isolation: isolate;

    grid-template-columns:
        [heading-start old-entry-start] max(100px, 20%)
        [new-entry-start] max-content
        [new-entry-end heading-end];
    row-gap: 4px;

    > p {
        grid-column: heading;
    }
`

const entryIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(6px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
`

const entryFadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const newPulsate = keyframes`
    0% {
        opacity: 1;
        scale: 1;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        scale: 1.3;
    }
`

export const Entry = styled.div`
    position: relative;

    border-radius: 24px;
    height: 24px;
    padding: 2px 8px;

    opacity: 0;
    transform: translateY(8px);
    animation: ${entryIn} .3s ease-out forwards;

    font-size: 14px;
    line-height: 20px;

    ${Wrapper}.delete & {
        grid-row: 2;

        transform: unset;
        animation-name: ${entryFadeIn};
    }

    &.old {
        grid-column: old-entry;

        width: 100%;
        background-color: var(--huma-color-surface-subtle);

        animation-delay: .1s;

        ${Wrapper}.delete :nth-child(1 of &) {
            grid-column-end: span 1;
        }

        ${Wrapper}.delete :nth-child(2 of &) {
            grid-column: 3;
        }

        ${Wrapper}.keep :nth-child(2 of &) {
            display: none;
        }
    }

    &.new {
        z-index: 1;

        grid-column: new-entry;

        background-color: var(--huma-color-surface-warning-minimal);

        animation-delay: .3s;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            inset: 0;

            border-radius: inherit;
            background-color: var(--huma-palette-yellow-80);

            opacity: 0;
            scale: 1;
            animation: ${newPulsate} .6s forwards;
            animation-delay: .5s;

            ${darkMode`
                background-color: var(--huma-palette-yellow-40);
            `}
        }
    }
`