import styled from 'styled-components'
import { messageColors, svgStroke } from 'utilities/styled'

export const SecondaryIconWrapper = styled.div`
    display: grid;
    place-items: center;

    width: 20px;

    ${messageColors}

    ${svgStroke('small')}
`