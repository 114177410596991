import styled from 'styled-components'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'
import { DefinitionDescription as DefinitionDescriptionBase } from 'components/list'
import { Plain as PlainBase } from 'components/button'

export const Wrapper = styled(Grid)`
    grid-auto-rows: auto;

    border-radius: 6px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);
`

export const Label = styled(Paragraph)`
    margin-block-end: 8px;
`

export const DefinitionDescription = styled(DefinitionDescriptionBase)`
    text-align: end;
`

export const Plain = styled(PlainBase)`
    justify-self: center;
`