import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCompetenceProfile } from 'contexts/competence-profile'
import { Message } from './s'
import Types from 'pages/competence/panes/gap/configuration/types/custom/types'
import EntitiesPicker from 'modals/entities-picker'
import Actions from 'components/form/actions'
import { Plain, BackButton, Button } from 'components/button'
import { Plus } from 'styled-icons/feather'

const TwoTarget = ({ loading, form, back, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        profile,
        error
    } = useCompetenceProfile()

    const { id } = profile ?? {}

    const [types, setTypes] = useState(form?.types ?? profile?.targets?.map(({ type, level }) => ({
        ...type,
        target: level
    })) ?? [])
    const [pickingTypes, setPickingTypes] = useState(false)

    return (
        <>
            {!!types.length && (
                <Types
                    types={types}
                    setTypes={setTypes}
                    salt={salt} />
            )}
            <Plain
                className="constructive with-icon"
                onClick={() => setPickingTypes(true)}>
                <Plus size={24} />
                <FormattedMessage
                    id="competence_action_add"
                    defaultMessage="Add competence" />
            </Plain>
            {(error?.errorType === 'duplicate') && (
                <Message
                    type="error"
                    message={formatMessage({
                        id: 'competence_profile_error_duplicate',
                                    defaultMessage: 'A profile with this name already exists'
                    })} />
            )}
            <EntitiesPicker
                show={pickingTypes}
                salt={salt}
                picked={types}
                type="competenceType"
                path="/competence-types"
                dismiss={() => setPickingTypes(false)}
                cancelAction={() => ({
                    label: formatMessage({
                        id: 'action_cancel',
                        defaultMessage: 'Cancel'
                    }),
                    effect: 'neutral',
                    onClick: () => setPickingTypes(false)
                })}
                doneAction={({ picked }) => ({
                    label: formatMessage({
                        id: 'action_continue',
                        defaultMessage: 'Continue'
                    }),
                    effect: 'constructive',
                    onClick: () => setTypes(previousTypes => picked.map(type => {
                        const previousType = previousTypes.find(({ id }) => id === type.id)

                        if(previousType) {
                            return previousType
                        }

                        return {
                            ...type,
                            ...(!['skill', 'language'].includes(type.category) ? { target: true } : null)
                        }
                    }))
                })} />
            <Actions className="spread">
                <BackButton
                    disabled={loading}
                    onClick={() => back({ types })}
                    text={formatMessage({
                        id: 'action_back',
                        defaultMessage: 'Back'
                    })} />
                <Button
                    className={`constructive${loading ? ' loading' : ''}`}
                    disabled={[
                        !types.length,
                        types.some(({ target, category }) => ['skill', 'language'].includes(category) && !target),
                        loading
                    ].some(Boolean)}
                    onClick={() => onDone(types)}>
                    <FormattedMessage
                        id={id ?
                            'action_update' :
                            'action_create'
                        }
                        defaultMessage={id ?
                            'Update' :
                            'Create'
                        } />
                </Button>
            </Actions>
        </>
    )
}

export default TwoTarget
