import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { getUrlFromPath } from 'utilities/url'
import { cls } from 'utilities/dom'
import { MenuItemLink } from '../'
import Tooltip from 'components/tooltip'
import { SecondaryIconWrapper } from './s'
import {
    CheckCircleFilled as Success,
    AlertTriangleFilled as Warning,
    AlertOctagonFilled as Error
} from 'components/feather'

const moduleIdentifier = 'integrations'

const IntegrationsItem = ({ integration, type, name, icon, ...props }) => {
    const {
        initialized,

        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule('integrations') && check('organization:manage')
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    const primaryIconColor = ['active', 'connected', 'configured'].includes(integration?.status)

    const className = cls([
        'icon-color-on-hover',
        primaryIconColor && 'icon-color'
    ])

    return (
        <MenuItemLink
            {...props}
            to={getUrlFromPath('path:integrations.integration.base')({ type })}
            className={className}
            icon={icon}
            {...(['active', 'connected', 'configured', 'authorization_failure'].includes(integration?.status) ? {
                secondaryIcon: props => (
                    <SecondaryIcon
                        {...props}
                        integration={integration} />
                )
            } : null)}
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}
            key={`navigation:integrations:${type}:${integration?.status}`}>
            {name}
        </MenuItemLink>
    )
}

const SecondaryIcon = ({ integration, ...props }) => {
    const { formatMessage } = useIntl()

    if(!integration?.status) {
        return null
    }

    const meta = getMeta(integration)

    const className = cls([
        meta.success && 'success',
        meta.warning && 'warning',
        meta.error && 'error'
    ])

    const [Wrapper, wrapperProps = null] = !!meta.tooltip ?
        [Tooltip, {
            content: formatMessage(meta.tooltip, {
                wrapper: c => c,
                name: integration?.name
            }),
            placement: 'right'
        }] :
        [Fragment]

    return (
        <Wrapper {...wrapperProps}>
            <SecondaryIconWrapper className={className}>
                {meta.success && <Success {...props} />}
                {meta.warning && <Warning {...props} size={18} />}
                {meta.error && <Error {...props} size={18} />}
            </SecondaryIconWrapper>
        </Wrapper>
    )
}

const getMeta = integration => {
    const meta = {
        success: ['active', 'connected'].includes(integration?.status),
        warning: integration?.status === 'configured',
        error: integration?.status === 'authorization_failure',
        syncable: integration?.scopes?.userProfile?.operations?.includes('sync')
    }

    if(integration?.status === 'active') {
        meta.tooltip = {
            id: 'integration_status_connected_active',
            defaultMessage: 'Connected and active'
        }
    }

    if(integration?.status === 'connected') {
        // When integrations without sync operations are only connected, it’s because they were deactivated
        // It is not reactivated by syncing, but by reconfiguring

        if(meta.syncable) {
            meta.tooltip = {
                id: 'integration_message_manually_sync',
                defaultMessage: 'You need to <wrapper>synchronize users</wrapper> manually to activate the integration'
            }

            meta.success = false
            meta.warning = true
        } else {
            meta.tooltip = {
                id: 'integration_message_configured',
                defaultMessage: 'This integration has been set up, but is not active.'
            }
        }
    }

    if(integration?.status === 'configured') {
        meta.tooltip = {
            id: 'integration_message_configured',
            defaultMessage: 'This integration has been set up, but is not active.'
        }
    }

    if(integration?.status === 'authorization_failure') {
        meta.tooltip = {
            id: 'integration_status_authorization_failure',
            defaultMessage: 'Something is wrong with your connection to {name}. Please reconnect and sync again.'
        }
    }

    return meta
}

export default IntegrationsItem