import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { useStorage } from 'hooks/storage'
import { sendAppSignal } from 'hooks/app-signal'
import { Important, Heading, DismissButton } from './s'
import { CaptionSmall } from 'components/typography/caption'
import { Button } from 'components/button'
import { getCategoryIcon } from 'utilities/categories'
import { X } from 'styled-icons/feather'

const CompetenceBanner = () => {
    const { locale } = useI18n()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const [dismissed, setDismissed] = useStorage({
        key: `dismissed:banner:competence:${locale}`,
        defaultValue: false
    })

    if(dismissed || !manageAccess) {
        return null
    }

    const Competence = getCategoryIcon('competence')

    return (
        <Important className="active">
            <Competence size={20} />
            <div>
                <Heading>
                    <FormattedMessage
                        id="competence_intro_heading"
                        defaultMessage="Competence – all knowledge in one place!" />
                </Heading>
                <CaptionSmall>
                    <FormattedMessage
                        id="competence_intro_caption"
                        defaultMessage="Qualifications has been renamed to Competence. Register all kind of competence such as skills, languages, certifications, education and work experiences." />
                </CaptionSmall>
                <Button
                    onClick={() => sendAppSignal('quick-guide.show', 'competence')}
                    className="constructive">
                    <FormattedMessage
                        id="action_see_how_it_works"
                        defaultMessage="See how it works" />
                </Button>
            </div>
            <DismissButton
                className="neutral"
                onClick={() => setDismissed(true)}>
                <X size={24} />
            </DismissButton>
        </Important>
    )
}

export default CompetenceBanner
