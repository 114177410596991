import React from 'react'
import { Page, View, Image, Text } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { getFullName, getJobTitle } from 'utilities/person'
import { styles } from 'components/tiptap/output/pdf/s'

const HandBookPdfFrontPage = ({ formatMessage, dateLocale, organization, me, handbook }) => (
    <Page
        wrap
        size="A4"
        style={styles.frontPage}>
        <View style={{
            ...styles.section,
            marginTop: -60
        }}>
            {(!organization.companyLogo && organization.name) && (
                <Text style={styles.title}>{organization.name}</Text>
            )}
            {organization.companyLogo && (
                <View
                    style={{
                        ...styles.logoWrapper,
                        marginBottom: 30,
                        marginLeft: 0
                    }}>
                    <Image
                        style={styles.logo}
                        src={organization.companyLogo['4:1-medium'].url} />
                </View>
            )}
            <Text style={{
                ...styles.h1,
                marginBottom: 0
            }}>
                {formatMessage({
                    id: 'noun_handbook',
                    defaultMessage: 'Handbook'
                })}
            </Text>
            <Text style={{
                ...styles.paragraph,
                fontSize: 10
            }}>
                {formatMessage({
                    id: 'created_using_name',
                    defaultMessage: 'Created using {name}',
                }, { name: 'Huma' })}
            </Text>
        </View>
        <View style={styles.pageFooter}>
            <Text style={{
                ...styles.pageFooterText,
                marginBottom: 0,
                color: '#595958'
            }}>
                {formatMessage({
                    id: 'handbooks_label_contact_person',
                    defaultMessage: 'Contact person(s)'
                })}
            </Text>
            <Text style={{
                ...styles.pageFooterText,
                marginBottom: 30
            }}>
                {handbook.contacts.map(person => {
                    const name = getFullName(person)
                    const position = getJobTitle(person)?.name

                    if(!position) {
                        return name
                    }

                    return `${name} (${position})`
                }).join(', ')}
            </Text>
            <Text style={{
                ...styles.pageFooterText,
                marginBottom: 0,
                color: '#595958'
            }}>
                {formatMessage({
                    id: 'status_updated',
                    defaultMessage: 'Updated'
                })}
            </Text>
            <Text style={styles.pageFooterText}>
                {formatMessage({
                    id: 'meta_when_by',
                    defaultMessage: '{date} by {user}'
                }, {
                    date: format(new Date(), 'PPP', { locale: dateLocale }),
                    user: getFullName(me)
                })}
            </Text>
        </View>
    </Page>
)

export default HandBookPdfFrontPage