import { useOrganization } from 'contexts/organization'
import { optionize } from 'components/form/field/share'

export const usePublicOption = (flat = false) => {
    const { organization } = useOrganization()

    return !organization ?
        null :
        flat ?
            { ...organization, type: 'organization' } :
            optionize('organization')(organization)
}
