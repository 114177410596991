import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { unoptionize } from 'components/form/field/share'
import { ModalHeader } from 'modals/generic'
import ShareOrEveryoneField from 'components/form/field/share-or-everyone'
import UpgradeCTA from 'components/upgrade-cta'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'

const EditAccess = ({ field, onDone, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const { checkFeature } = useAccess()
    const groupsAvailable = checkFeature('handbook-groups')

    const groupsUpgradable = useUpgradable()({ feature: 'handbook-groups' })

    const [which, setWhich] = useState((!field.current?.length || field.current?.find(({ type }) => type !== 'organization')) ?
        'shares' :
        'everyone'
    )

    const [value, setValue] = useState(field.current)

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'noun_access',
                    defaultMessage: 'Access'
                })}
                dismiss={dismiss} />
            <ShareOrEveryoneField
                salt={salt}
                label={false}
                name="shares"
                field={{
                    value,
                    which,
                    include: 'always'
                }}
                picker={{ types: ['team', 'location'] }}
                everyoneFirst={true}
                shareLabel={formatMessage({
                    id: 'groups_members_of',
                    defaultMessage: 'Members of teams or locations'
                })}
                {...(groupsUpgradable ? {
                    shareHidden: true,
                    shareUpgradable: groupsUpgradable,
                    shareContent: (
                        <UpgradeCTA
                            feature="handbook-groups"
                            useUpgradeIcon
                            useFeatureOrModuleIcon
                            useFeatureDescription
                            className="compact"
                            salt="handbook-groups" />
                    )
                } : null)}
                onChange={({ shares = [], which }) => {
                    setWhich(which)
                    setValue((which === 'everyone') ?
                        [{ type: 'organization' }] :
                        shares.map(unoptionize)
                    )
                }} />
                <Actions>
                    <Plain onClick={dismiss}>
                        <FormattedMessage
                            id="action_cancel"
                            defaultMessage="Cancel" />
                    </Plain>
                    <Button
                        className="constructive"
                        disabled={!value?.length || (!groupsAvailable && which === 'shares')}
                        onClick={() => onDone(value)}>
                        <FormattedMessage
                            id="action_save"
                            defaultMessage="Save" />
                    </Button>
                </Actions>
        </>
    )
}

export default EditAccess
