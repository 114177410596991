export const getUserAgent = () => {
    const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

    // Operating Systems
    const isIOS = (/iPhone|iPad|iPod/i.test(navigator.userAgent))
    const isAndroid = (/Android/i.test(navigator.userAgent))
    const isMacOS = (/mac/i.test(navigator.userAgent))
    const isWindows = (/windows/i.test(navigator.userAgent))

    // Browsers
    const isChrome = (/Chrome/i.test(navigator.userAgent))
    const isFirefox = (/Firefox/i.test(navigator.userAgent))
    const isSafari = (/Safari/i.test(navigator.userAgent)) && !isChrome
    const isEdge = (/Edg/i.test(navigator.userAgent))
    const isArc = getComputedStyle(document.documentElement).getPropertyValue('--arc-palette-background') !== ''

    return {
        isMobile,
        os: {
            isIOS,
            isAndroid,
            isMacOS,
            isWindows
        },
        browser: {
            isChrome,
            isFirefox,
            isSafari,
            isEdge,
            isArc
        }
    }
}