import styled from 'styled-components'
import { interpointDivider } from 'utilities/styled'
import { ListItem } from 'components/list'
import AvatarBase from 'components/avatar'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const UserItem = styled(ListItem)`
    &:not(:last-child) {
        margin: 0 0 32px;
    }
`

export const Avatar = styled(AvatarBase)`
    margin-right: 24px;
    border-radius: 100%;
    margin-block-start: -4px;
`

export const Name = styled(Paragraph).attrs({ as: 'h3' })`
    line-height: 1;
    margin-block-end: 4px;
`

export const Email = CaptionSmall

export const Meta = styled(CaptionSmall)`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-gap: 8px;
`

export const InvitedAt = styled.span`
    ${interpointDivider()}
`