import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useForm } from 'components/form/controller'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import { integerPattern } from 'utilities/regex'
import { Label } from 'components/form/field/s'
import { Field, Control } from './s'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import SelectField from 'components/form/field/select'
import RadioField from 'components/form/field/radio'
import TimeField from 'components/form/field/time'

export const typeToFieldMap = {
    text: StringField,
    textarea: TextField,
    enum: SelectField,
    number: StringField,
    decimal: StringField,
    boolean: RadioField,
    url: StringField,
    localdate: TimeField
}

export const getPropsByType = type => ({
    text: ({ props }) => ({
        ...(props ?? null),
        controlProps: {
            ...(props?.controlProps ?? null),
            maxLength: 2040
        }
    }),
    textarea: ({ props }) => ({
        ...(props ?? null),
        controlProps: {
            ...(props?.controlProps ?? null),
            maxLength: 10200
        }
    }),
    url: ({ props }) => ({
        ...(props ?? null),
        field: {
            ...(props?.field ?? null),
            strict: true
        },
        controlProps: {
            ...(props?.controlProps ?? null),
            type: 'url'
        }
    }),
    enum: ({ props, formatMessage }) => ({
        ...(props ?? null),
        field: {
            ...(props?.field ?? null),
            value: props?.field?.value,
            allowEmptyOption: false
        },
        options: compact([
            !!props?.controlProps?.placeholder && {
                key: 'placeholder',
                value: 'placeholder',
                text: props.controlProps.placeholder,
                disabled: true
            },
            {
                key: 'empty',
                value: '',
                text: formatMessage({
                    id: 'empty_not_chosen',
                    defaultMessage: 'Not chosen'
                })
            },
            ...(props?.options ?? [])
        ])
    }),
    boolean: ({ props, formatMessage }) => ({
        ...(props ?? null),
        field: {
            ...(props?.field ?? null),
            unsettable: true,
            type: 'boolean'
        },
        options: [
            {
                value: true,
                label: formatMessage({
                    id: 'boolean_true',
                    defaultMessage: 'Yes'
                })
            },
            {
                value: false,
                label: formatMessage({
                    id: 'boolean_false',
                    defaultMessage: 'No'
                })
            }
        ]
    }),
    number: ({ props }) => ({
        ...(props ?? null),
        controlProps: {
            ...(props?.controlProps ?? null),
            inputMode: 'numeric',
            type: 'number',
            step: 1,
            pattern: integerPattern
        }
    }),
    decimal: ({ props }) => ({
        ...(props ?? null),
        controlProps: {
            ...(props?.controlProps ?? null),
            inputMode: 'decimal',
            type: 'number',
            step: 0.01
        }
    }),
    localdate: ({ props }) => ({
        ...(props ?? null),
        picker: {
            ...(props?.picker ?? null),
            futureYearsAvailableInt: 100
        }
    })
})[type] ?? (({ props }) => props)

export const FieldPreview = ({ className, salt }) => {
    const { formatMessage } = useIntl()

    const { values } = useForm()
    if(!values?.type) {
        return null
    }

    const { type } = values

    const fieldProps = {
        label: values.label,
        ...(values.placeholder ? {
            controlProps: {
                placeholder: values.placeholder
            }
        } : null),
        options: !!values.options ?
            Object.entries(values.options)
                .map(([key, text]) => ({
                    key,
                    value: key,
                    text
                })) :
            null
    }

    const TypeField = typeToFieldMap[type]
    const props = getPropsByType(type)?.({
        props: {
            label: formatMessage({
                id: 'noun_label',
                defaultMessage: 'Label'
            }),
            ...fieldProps,
            salt,
            className: cls(fieldProps?.className, 'compact'),
            name: `${type}:preview`,
            field: {
                ...(fieldProps?.field ?? null),
                include: 'never'
            },
            key: compact([
                salt,
                'preview',
                values.label,
                values.placeholder,
                !!values.options && Object.values(values.options ?? {}).join('+')
            ]).join(':')
        },
        formatMessage
    }) ?? null

    return (
        <Field {...(className ? { className } : null)}>
            <Label>
                <FormattedMessage
                    id="noun_preview"
                    defaultMessage="Preview" />
            </Label>
            <Control>
                <TypeField {...props} />
            </Control>
        </Field>
    )
}