import styled from 'styled-components'
import { H4 } from 'components/typography/heading'

export const Wrapper = styled.div`
    .recharts-responsive-container {
        margin-inline: auto;
    }
`

export const Heading = styled(H4)`
    margin-bottom: 16px;

    font-size: 16px;
`