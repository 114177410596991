import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useHandbook } from 'contexts/handbook'
import { cls } from 'utilities/dom'
import { People, EditContactsButton } from './s'
import Important from 'components/important'
import { CaptionSmall } from 'components/typography/caption'
import Person from 'components/person'
import { Scrollable as Modal } from 'modals/generic'
import EditContacts from 'pages/handbook/modals/edit-contacts'

const HandbookContacts = ({ show = true, className, salt }) => {
    const {
        handbook,
        filter
    } = useHandbook()

    const { check } = useAccess()
    const manageAccess = check('handbook:manage')

    const [editing, setEditing] = useState(false)

    const { contacts = [] } = handbook ?? {}

    if((!contacts.length && !manageAccess) || !show) {
        return null
    }

    className = cls([
        className,
        'informative'
    ])

    return (
        <>
            <Important className={className}>
                <CaptionSmall>
                    <FormattedMessage
                        id="handbooks_message_contact"
                        defaultMessage="Reach out if you have any questions or feedback about the content." />
                </CaptionSmall>
                <People>
                    {contacts.map(contact => (
                        <Person
                            who={contact}
                            asLink={true}
                            size={40}
                            showPosition={true}
                            truncate={true}
                            key={contact.id} />
                    ))}
                    {(manageAccess && !filter?.viewAs) && (
                        <EditContactsButton
                            className="constructive"
                            onClick={() => setEditing(true)}>
                            <FormattedMessage
                                id="action_edit"
                                defaultMessage="Edit" />
                        </EditContactsButton>
                    )}
                </People>
            </Important>
            <Modal
                show={!!editing}
                dismiss={() => setEditing(false)}
                salt={`${salt}:edit`}>
                <EditContacts
                    onDone={() => setEditing(false)}
                    salt={`${salt}:edit`} />
            </Modal>
        </>
    )
}

export default HandbookContacts
