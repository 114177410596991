import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useAppSignal } from 'hooks/app-signal'
import { useLocationQueryIntent } from 'hooks/location-intent'
import { omit } from 'utilities/object'
import { Scrollable as Modal, ModalHeader } from 'modals/generic'
import Message from 'components/message'
import MessageContent from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import { Plain } from 'components/button'

const Announcements = () => {
    const { formatMessage } = useIntl()

    const { announcements: allAnnouncements } = useServiceOnboarding()

    const announcements = allAnnouncements?.filter(({ meta }) => {
        if(meta.expires) {
            return meta.current
        }

        return meta.current || meta.past
    })

    const [show, setShow] = useState(false)

    useAppSignal({
        namespace: 'announcements.show',
        action: () => setShow(true)
    })

    useLocationQueryIntent({
        intent: 'announcements',
        action: () => setShow(true)
    })

    if(!announcements?.length) {
        return null
    }

    const dismiss = () => setShow(false)

    return (
        <Modal
            show={show}
            dismiss={dismiss}
            enrichChildren={false}
            salt="help:announcements">
            <ModalHeader
                heading={formatMessage({
                    id: 'noun_announcements',
                    defaultMessage: 'Announcements'
                })}
                dismiss={dismiss} />
            {announcements.map(({ heading, ...announcement }, index) => {
                if(heading) {
                    announcement.children = (
                        <MessageContent className="compact">
                            {announcement.message}
                        </MessageContent>
                    )

                    announcement.message = heading
                    announcement.messageAsHeading = true
                }

                const key = `help:announcements:${announcement.id}`

                return(
                    <Message
                        {...omit(announcement, 'meta', 'dismiss')}
                        {...(index > 2) ? {
                            name: key,
                            defaultDismissed: true
                        } : null}
                        {...(index === announcements.length - 1) ? {
                            dismissedProps: {
                                className: 'spacious'
                            }
                        } : null}
                        key={key} />
                )
            })}
            <Actions className="compact centered">
                <Plain
                    className="neutral"
                    onClick={dismiss}>
                    <FormattedMessage
                        id="action_close"
                        defaultMessage="Close" />
                </Plain>
            </Actions>
        </Modal>
    )
}

export default Announcements