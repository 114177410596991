import { useEffect, memo } from 'react'
import rapid7InsightOps from 'r7insight_js'
import { useEnvironment } from 'contexts/environment'

const Rapid7InsightOps = () => {
    const { integrations } = useEnvironment()

    useEffect(() => {
        if(!!integrations?.rapid7LogToken) {
            rapid7InsightOps.init({
                token: integrations.rapid7LogToken,
                region: 'eu'
            })
        }
    }, [integrations?.rapid7LogToken])

    return null
}

export default memo(Rapid7InsightOps)