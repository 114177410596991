import styled from 'styled-components'
import { Grid } from 'components/grid'

export const DisplayColumns = styled(Grid)`
    grid-template-columns: 1fr;
    justify-content: stretch;
    align-items: center;

    height: 40px;

    &.replacable {
        grid-template-columns: minmax(auto, 1fr) min-content;
        column-gap: 16px;
    }
`