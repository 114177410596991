import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { InlineMessage as InlineMessageBase } from 'components/message'

const InlineMessage = styled(InlineMessageBase)`
    & p {
        line-height: 20px;
    }
`

export default ({ requireStart, requireEnd, start, end, ...props }) => {
    const { formatMessage } = useIntl()

    if(!requireStart && !requireEnd) {
        return null
    }

    const output = message => {
        return (
            <InlineMessage
                {...props}
                message={formatMessage(message)}
                type="error" />
        )
    }

    if(!!requireStart && !!requireEnd && !start && !end) {
        return output({
            id: 'message_start_end_dates_missing',
            defaultMessage: 'No start or end date'
        })
    }

    if(!!requireStart && !start) {
        return output({
            id: 'message_start_date_missing',
            defaultMessage: 'No start date'
        })
    }

    if(!!requireEnd && !end) {
        return output({
            id: 'message_end_date_missing',
            defaultMessage: 'No end date'
        })
    }

    return null
}