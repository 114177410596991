import { useRef } from 'react'

export const useAbortController = () => {
    const abortController = useRef(new AbortController())

    const resetAbortController = () => {
        if(!abortController.current) {
            return
        }

        abortController.current.abort()
        abortController.current = new AbortController()

        return abortController.current
    }

    return {
        abortController: abortController.current,
        resetAbortController
    }
}