import styled from 'styled-components'
import TimeFieldBase from 'components/form/field/time'
import { Helper as HelperBase } from 'components/form/field/s'
import { Plain } from 'components/button'

export const TimeField = styled(TimeFieldBase)``

export const Helper = styled(HelperBase)`
    transition: opacity .2s;
`

export const LevelDefinitionsButton = styled(Plain)`
    margin-block: -16px 16px;
`