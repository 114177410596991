import React, { Fragment } from 'react'
import AbsenceEntriesProvider, { useAbsenceEntries } from 'contexts/absence-entries'
import { isOngoing } from 'pages/absence/utilities'
import { getDate } from 'utilities/date-time'
import OngoingAbsence from './ongoing-absence'
import { MyProfileCompletion } from 'components/profile-completion'

const GreetingImportant = ({ entries = [], absenceAccess = false, onboarder, statuses }) => {
    const now = new Date()
    const [ongoing] = getOngoingEntries(entries, now)

    return (
        <>
            {!!absenceAccess && <OngoingAbsence ongoing={ongoing} />}
            {((!onboarder || !!statuses.completeProfile) && !ongoing) && (
                <MyProfileCompletion important="passive" />
            )}
        </>
    )
}

const AbsenceProvider = props => {
    const {
        entries,
        hasFetched
    } = useAbsenceEntries()

    if(!hasFetched) {
        return null
    }

    return (
        <GreetingImportant
            {...props}
            entries={entries} />
    )
}

const getOngoingEntries = (entries, now) => entries
    .filter(entry => isOngoing(entry, now) && !entry.end)

export default props => {
    const { absenceAccess } = props

    const AbsenceWrapper = absenceAccess ?
        AbsenceEntriesProvider :
        Fragment

    const absenceWrapperProps = absenceAccess ?
        {
            filter: {
                users: ['me'],
                statuses: ['approved'],
                fromDate: getDate()
            },
            paging: { limit: 7 }
        } :
        null

    return (
        <AbsenceWrapper {...absenceWrapperProps}>
            {!!absenceAccess && <AbsenceProvider {...props} />}
            {!absenceAccess && <GreetingImportant {...props} />}
        </AbsenceWrapper>
    )
}
