import styled, { keyframes } from 'styled-components'
import { paragraphCss } from 'components/typography/paragraph'
import BalloonBase from 'static/gfx/balloon.csvg'
import { Plain as PlainBase } from 'components/button'

export const Wrapper = styled.div`
    overflow: hidden;
    position: relative;

    border-radius: 6px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);
`

export const Jubilee = styled.div`
    ${paragraphCss}

    display: flex;
    column-gap: 8px;

    margin-inline-end: 32px;

    &:not(:only-of-type):not(:first-of-type) {
        margin-block-start: 16px;
        border-top: 1px solid var(--huma-color-border-default);
        padding-block-start: 16px;
    }
`

export const Balloons = styled.span`
    pointer-events: none;

    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
`

const float = keyframes`
    0%, 100% {
        transform: translateY(0) rotate(-4deg);
    }
    50% {
        transform: translate(-5px) rotate(4deg);
    }
`

export const Balloon = styled(BalloonBase)`
    position: absolute;

    animation: ${float} 4s ease-in-out infinite;
    transform-origin: bottom center;

    &:first-of-type {
        top: 8px;
        right: 10px;

        color: var(--huma-palette-purple-80);
    }

    &:last-of-type {
        top: 4px;
        right: -1px;

        animation-duration: 3.5s;

        color: var(--huma-palette-purple-50);
    }
`

export const Plain = styled(PlainBase)`
    margin-inline: auto;
`