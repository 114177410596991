import React, { useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { useOrganization } from 'contexts/organization'
import { useMe } from 'contexts/me'
import { LocalI18nProvider, useLocalI18n } from 'contexts/i18n'
import { getShortName, getEmailAddress } from 'utilities/person'
import { Centered } from './s'
import { Email, EmailHeader, EmailHeaderLine, EmailContent, Variable, DisabledConstructiveButton } from 'components/email-template'
import Paragraph, { ParagraphSmall } from 'components/typography/paragraph'
import Caption from 'components/typography/caption'
import Divider from 'components/divider'
import Link from 'components/link'

const InspectInvitationEmail = ({ users, useMessage, message, language, dataType, setData }) => {
    const { formatMessage } = useIntl()

    const { environment } = useEnvironment()
    const { organization } = useOrganization()
    const { me } = useMe()

    const {
        locale,
        setLocale
    } = useLocalI18n()

    useEffect(() => {
        setLocale({ locale: language })
    }, [language])

    useEffect(() => {
        setData(previous => ({
            ...previous,
            [dataType]: {
                ...previous[dataType],
                subject: formatMessage({
                    id: 'email_invitation_template_header_inviter',
                    defaultMessage: '{organization} has invited you to Huma'
                }, { organization: organization.name })
            }
        }))
    }, [locale])

    const { name: organizationName } = organization
    const singleInvitee = users?.length === 1

    const toEmailAddress = (singleInvitee && getEmailAddress(users[0])) ?
        <strong><Link className="constructive">{getEmailAddress(users[0])}</Link></strong> :
        <Variable>
            {formatMessage({
                id: 'noun_emailaddress',
                defaultMessage: 'Email address'
            }).toLocaleLowerCase(locale)}
        </Variable>

    const toShortName = (singleInvitee && getShortName(users[0])) ?
        <strong>{getShortName(users[0])}</strong> :
        <Variable>
            {formatMessage({
                id: 'noun_name',
                defaultMessage: 'Name'
            }).toLocaleLowerCase(locale)}
        </Variable>

    return (
        <Email>
            <EmailHeader>
                <EmailHeaderLine className="compact">
                    <FormattedMessage
                        id="email_invitation_template_header_inviter"
                        defaultMessage="{organization} has invited you to Huma"
                        values={{ organization: organizationName }} />
                </EmailHeaderLine>
                <EmailHeaderLine>
                    <FormattedMessage
                        id="email_invitation_template_header_to"
                        defaultMessage="To: {email}"
                        values={{ email: toEmailAddress }} />
                </EmailHeaderLine>
            </EmailHeader>
            <EmailContent>
                <Paragraph className="preserve-line-breaks">
                    <FormattedMessage
                        id="email_invitation_template_body_intro"
                        defaultMessage="Hi {name},\n{inviter} in {organization} has invited you to Huma"
                        values={{
                            name: toShortName,
                            inviter: getShortName(me),
                            organization: organizationName
                        }} />
                </Paragraph>
                {(useMessage && message.trim().length > 0) && (
                    <Paragraph className="preserve-line-breaks">{message}</Paragraph>
                )}
                <Divider />
                <Centered>
                    <Paragraph>
                        {organization.name}<br />
                        <FormattedMessage
                            id="email_invitation_template_button_join_url"
                            defaultMessage="{link} (Huma domain)"
                            values={{ link: <Link className="constructive">{organization.key}.{environment.tld}</Link> }} />
                    </Paragraph>
                    <DisabledConstructiveButton>
                        <FormattedMessage
                            id="email_invitation_template_button_join"
                            defaultMessage="Join now" />
                    </DisabledConstructiveButton>
                    <ParagraphSmall className="preserve-line-breaks">
                        <FormattedMessage
                            id="email_invitation_template_button_join_hint"
                            defaultMessage="Sign in using\n{email}"
                            values={{ email: toEmailAddress }} />
                    </ParagraphSmall>
                </Centered>
                <Divider />
                <Caption>
                    <FormattedMessage
                        id="email_invitation_template_footer_organization"
                        defaultMessage="{organization} is using the Huma HR system. In Huma you can find information about your colleagues, read up on the latest company announcements and more. All important documents and information about your employment will be securely stored in one place."
                        values={{ organization: organization.name }} />
                </Caption>
                <Caption>
                    <FormattedMessage
                        id="email_invitation_template_footer_inviter"
                        defaultMessage="Please contact {organization} ({email}) if you have any questions."
                        values={{
                            organization: organizationName,
                            email: <Link className="constructive">{getEmailAddress(me)}</Link>
                        }} />
                </Caption>
                <Divider />
                <Paragraph>
                    <FormattedMessage
                        id="email_invitation_template_footer_huma"
                        defaultMessage="Huma is easy to use on your phone, download the app on {appStore} or {googlePlay}."
                        values={{
                            appStore: <Link className="constructive">App Store</Link>,
                            googlePlay: <Link className="constructive">Google Play</Link>
                        }} />
                </Paragraph>
            </EmailContent>
        </Email>
    )
}

export default props => (
    <LocalI18nProvider>
        <InspectInvitationEmail {...props} />
    </LocalI18nProvider>
)
