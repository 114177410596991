import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { useAccess } from 'contexts/access'
import { useUserPermissions } from 'contexts/unit-permissions'
import { useMe } from 'contexts/me'
import { useInterval } from 'hooks/interval'
import {
    HeroActions, Heading,
    Wrapper,
    Caption,
    SkeletonListRow
} from './s'
import { Ghost } from 'components/button'
import Entry from 'lists/absence/entries/entry'
import { isFuture } from 'pages/absence/utilities'
import { SkeletonCell, SkeletonSymbolAndMeta } from 'components/skeleton'
import { clamp } from 'utilities/math'

const GroupedEntries = ({ user, actions, setActing, showWorkdaysAffected, salt }) => {
    const {
        entries,
        hasFetched,
        paging = {},
        fetching
    } = useAbsenceEntries()

    const { hasNextPage } = paging ?? {}

    const { check } = useAccess()
    const userPermissions = useUserPermissions()
    const { isItMyOwnId } = useMe()

    const absenceAdmin = [
        check('absence:manage'),
        userPermissions?.checkUnitPermission?.('user:absence:manage'),
        isItMyOwnId(user?.id)
    ].some(Boolean)

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 60 * 60 * 1000)

    const [skeletonLength, setSkeletonLength] = useState(2)

    const requestedEntries = getRequestedEntries(entries)
    const upcomingEntries = getUpcomingEntries(entries, now)

    useEffect(() => {
        if(hasNextPage && !!upcomingEntries?.length) {
            setSkeletonLength(clamp(upcomingEntries.length, 1, 10))
        }
    }, [hasNextPage, upcomingEntries?.length])

    return (
        <>
            <HeroActions>
                <Heading>
                    <FormattedMessage
                        id="absence_heading_upcoming"
                        defaultMessage="Upcoming" />
                </Heading>
                {(!!absenceAdmin && user.status?.active) && (
                    <Ghost
                        className="constructive"
                        onClick={() => setActing({
                            mode: 'edit',
                            user
                        })}>
                        <FormattedMessage
                            id="absence_action_register"
                            defaultMessage="Register absence" />
                    </Ghost>
                )}
            </HeroActions>
            <Wrapper>
                {fetching && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={1}
                        key={`list:absence:grouped-entries:${index}`}>
                        <SkeletonCell>
                            <SkeletonSymbolAndMeta
                                size={40}
                                showSecondLine={true} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {upcomingEntries.map(entry => (
                    <Entry
                        entry={entry}
                        actions={actions}
                        showWorkdaysAffected={showWorkdaysAffected}
                        key={`${salt}:absence-entry:${entry.id}`} />
                ))}
                {(!upcomingEntries?.length && hasFetched && !hasNextPage) && (
                    <Caption>
                        <FormattedMessage
                            id="absence_no_upcoming"
                            defaultMessage="No upcoming absence" />
                    </Caption>
                )}
            </Wrapper>
            {(hasFetched && (!!absenceAdmin || !user) && !!requestedEntries?.length) && (
                <>
                    <Heading>
                        <FormattedMessage
                            id="noun_requests"
                            defaultMessage="Requests" />
                    </Heading>
                    <Wrapper>
                        {requestedEntries.map(entry => (
                            <Entry
                                entry={entry}
                                actions={actions}
                                showWorkdaysAffected={showWorkdaysAffected}
                                key={`${salt}:absence-entry:${entry.id}`} />
                        ))}
                    </Wrapper>
                </>
            )}
        </>
    )
}

export default GroupedEntries

const getRequestedEntries = entries => entries
    .filter(({ status }) => !['approved', 'canceled'].includes(status))
    .sort(({ start: one }, { start: two }) => one.getTime() - two.getTime())

export const getUpcomingEntries = (entries, now) => entries
    .filter(({ status }) => ['approved', 'canceled'].includes(status))
    .filter(entry => isFuture(entry, now))
    .sort(({ start: one }, { start: two }) => one.getTime() - two.getTime())