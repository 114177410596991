import styled from 'styled-components'
import { UnorderedList, ListItem } from 'components/list'
import { InlineMessage as InlineMessageBase } from 'components/message'
import { Plain } from 'components/button'

export const Summary = styled(UnorderedList)`
    margin-block-end: 24px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    padding: 16px;
`

export const SummaryItem = styled(ListItem)`
    line-height: 24px;
    color: var(--huma-color-foreground-subtle);

    &:not(:last-of-type) {
        margin-block-end: 8px;
    }
`

export const ViewSummaryDetailsButton = styled(Plain)`
    margin-block-end: -10px;
`

export const InlineMessage = styled(InlineMessageBase)`
    > * > p {
        display: inline-flex;
        column-gap: 4px;

        color: var(--huma-color-foreground-subtle);
    }
`