import React from 'react'
import { getUserAgent } from 'utilities/user-agent'
import { iconMap as typeToIconMap } from 'components/group'
import { AvatarWrapper as IconWrapper, Deactivated } from 'components/person/s'
import Avatar from 'components/avatar'
import { Search } from 'styled-icons/feather'
import { getCategoryIcon } from 'utilities/categories'

const ActiveIcon = ({ activeItem, searchValid }) => {
    const { isMobile } = getUserAgent()

    if(isMobile) {
        return null
    }


    const {
        id,
        type,
        category,
        icon
    } = activeItem ?? {}

    if(!activeItem || !searchValid) {
        return <Search size={20} />
    }

    const isIntegration = id?.startsWith('navigate:integrations:')

    const group = [typeToIconMap[type]]

    const [Icon, props = null] = {
        user: [Avatar, { who: activeItem.user }],
        team: group,
        location: group
    }[type] ?? [icon ?? getCategoryIcon(category), {
        ...(isIntegration ? { className: 'integration' } : null)
    }]

    return (
        <IconWrapper>
            {(type === 'user' && activeItem.user?.status?.active === false) && <Deactivated size={12} />}
            <Icon
                {...props}
                size={20} />
        </IconWrapper>
    )
}

export default ActiveIcon