import React from 'react'
import { useParams } from 'react-router-dom'
import PersonProvider from 'contexts/person'
import SubordinatesProvider from 'contexts/subordinates'
import Hero from './hero'
import Content from './content'

const EditSubordinates = ({ onDone, dismiss, salt, ...props }) => (
    <>
        <Hero
            onDone={onDone}
            dismiss={dismiss} />
        <Content
            {...props}
            salt={salt} />
    </>
)

const ProfileSubordinates = props => {
    const { id } = useParams()

    return (
        <SubordinatesProvider userId={id ?? 'me'}>
            <EditSubordinates {...props} />
        </SubordinatesProvider>
    )
}

const OutsideSubordinates = props => {
    const { id } = props

    return (
        <PersonProvider id={id ?? 'me'}>
            <SubordinatesProvider userId={id ?? 'me'}>
                <EditSubordinates {...props} />
            </SubordinatesProvider>
        </PersonProvider>
    )
}

export default ({ outside = false, ...props }) => {
    const isOnProfile = !outside

    if(isOnProfile) {
        return <ProfileSubordinates {...props} />
    }

    return <OutsideSubordinates {...props} />
}
