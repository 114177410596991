import styled from 'styled-components'
import { interpointDivider } from 'utilities/styled'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'

export const Layout = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 8px;
    align-items: center;

    &:not(.no-layout) {
        grid-column: 1 / -1;
    }
`

export const Label = styled(Paragraph)`
    ${interpointDivider()}

    :not(${Layout}) &,
    &:not(.no-layout) {
        grid-column: 1 / -1;
    }
`