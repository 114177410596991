import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useMe } from 'contexts/me'
import { eachDayOfInterval, startOfWeek, endOfWeek, format } from 'date-fns'
import { capitalize } from 'utilities/string'
import Form from 'components/form/controller'
import SelectField from 'components/form/field/select'

const WeekStartsOnPicker = ({ className }) => {
    const { formatMessage } = useIntl()

    const {
        locale,
        dateLocale,
        setLocale
    } = useI18n()

    const { authorized } = useMe()

    if(!locale) {
        return null
    }

    const { weekStartsOn } = dateLocale.options

    const uiDateLocale = {
        ...dateLocale,
        options: {
            ...dateLocale.options,
            weekStartsOn: 0
        }
    }

    const now = new Date()
    const weekdays = eachDayOfInterval({
        start: startOfWeek(now, { locale: uiDateLocale }),
        end: endOfWeek(now, { locale: uiDateLocale })
    })
        .slice(0, 2)
        .map(weekday => capitalize(format(weekday, 'EEEE', { locale: uiDateLocale })))

    const update = ({ weekStartsOn }) => setLocale({
        locale,
        dateLocaleOverrides: {
            weekStartsOn: +weekStartsOn
        }
    }, { store: authorized })

    return (
        <Form>
            <SelectField
                {...(className ? { className } : null)}
                label={formatMessage({
                    id: 'locale_week_starts_on',
                    defaultMessage: 'Week starts on'
                })}
                name="weekStartsOn"
                field={{
                    value: `${weekStartsOn}`,
                    include: 'always',
                    allowEmptyOption: false
                }}
                options={weekdays.map((weekday, index) => ({
                    key: `${locale}:${weekday}`,
                    value: index,
                    text: weekday
                }))}
                onChange={update}
                salt="week-starts-on:picker"
                key={`week-starts-on:picker:${locale}:${weekStartsOn}`} />
        </Form>
    )
}

export default WeekStartsOnPicker