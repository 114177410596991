import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CloseButton } from 'components/button'
import { ModalHeading } from 'components/typography/heading'

const AddCompetenceRecordHero = ({ dismiss }) => (
    <>
        <CloseButton onClick={dismiss} />
        <ModalHeading>
            <FormattedMessage
                id="action_pick_category"
                defaultMessage="Pick category" />
        </ModalHeading>
    </>
)

export default AddCompetenceRecordHero
