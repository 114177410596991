import React from 'react'
import { FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { Heading } from './s'
import Paragraph from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'
import { getShortName } from 'utilities/person'

const OffboardingPrompt = ({ onDone }) => {
    const { person } = usePerson()

    return (
        <>
            <Heading>
                <FormattedMessage
                    id="person_offboarding_prompt_heading"
                    defaultMessage="Looks like {name} is leaving"
                    values={{ name: getShortName(person) }} />
            </Heading>
            <Paragraph className="compact">
                <FormattedMessage
                    id="person_offboarding_prompt_question"
                    defaultMessage="Do you want to set up the offboarding process right away?" />
            </Paragraph>
            <Actions>
                <Plain
                    onClick={() => onDone(false)}
                    className="neutral">
                    <FormattedMessage
                        id="action_cancel"
                        defaultMessage="Cancel" />
                </Plain>
                <Button
                    onClick={() => onDone(true)}
                    className="constructive">
                    <FormattedMessage
                        id="employee_offboarding_action_start_short"
                        defaultMessage="Start offboarding" />
                </Button>
            </Actions>
        </>
    )
}

export default OffboardingPrompt