import { memo } from 'react'
import { usePageView } from 'hooks/page-view'
import { usePage } from 'contexts/page'
import { omit } from 'utilities/object'

const Tracking = memo(() => {
    const {
        view,
        upgrade
    } = usePage()

    usePageView({
        ...((typeof view === 'string') ? { name: view } : null),
        ...(!!view?.name ? omit(view, 'dependencies') : null),
        ...(!!upgrade?.enabled ? { upsell: true } : null)
    }, view?.dependencies ?? [])

    return null
})

export default Tracking