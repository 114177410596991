export const monochrome = {
    five: { c: '#f5f4f3' },
    ten: { c: '#e3e2e1' },
    fifteen: { c: '#cccbca' },
    twenty: { c: '#848382' },
    twentyfive: { c: '#595958' },
    eighty: { c: '#242322' },

    shy: { c: '#fafaf9' },
    light: { c: '#e3e2e1' },

    base: { c: '#161513' },

    dark: { c: '#080705' },
    brave: { c: '#000' }
}

export const blue = {
    shy: { c: '#e5eeff' },
    light: { c: '#b8cbf2' },

    base: { c: '#2853ad' },

    dark: { c: '#234385' },
    brave: { c: '#192f5c' }
}

export const red = {
    shy: { c: '#f7d4db' },
    light: { c: '#ebb0bb' },

    base: { c: '#a32b42' },

    dark: { c: '#792031' },
    brave: { c: '#511521' }
}

export const green = {
    shy: { c: '#e9ecdf' },
    light: { c: '#d1d9b8' },

    base: { c: '#9aa86f' },

    dark: { c: '#5e6b34' },
    brave: { c: '#3d4620' }
}

export const yellow = {
    eighty: { c: '#ffd699' },

    shy: { c: '#fbf1db' },
    light: { c: '#ffc97a' },

    base: { c: '#ffb64b' },

    dark: { c: '#e29d38' },
    brave: { c: '#bc7c1e' }
}

export const purple = {
    shy: { c: '#fcd9ea' },
    light: { c: '#eebfd9' },

    ten: { c: '#da8eb1' },
    fifteen: { c: '#ab4975' },

    base: { c: '#632340' }
}

export const skin = {
    one: { c: '#fce9cf' },
    two: { c: '#e7ccab' },
    three: { c: '#e6c38d' },
    four: { c: '#c49968' },
    five: { c: '#82573a' },
    six: { c: '#5d3e20' },
    seven: { c: '#2c2012' }
}

export const partners = {
    schjodt: { c: '#57005f' }
}

export const territory = {
    NO: { c: red.shy.c },
    SE: { c: blue.shy.c },
    FI: { c: monochrome.five.c }
}

// Message color names
export const success = green
export const info = blue
export const warning = yellow
export const error = red
export const caution = monochrome
export const idea = monochrome

// Button consequence color names
export const neutral = monochrome
export const constructive = blue
export const destructive = red

// Misc
export const border = { ...monochrome.base, a: .1 }
export const shadow = border
export const white = { c: '#fff' }
export const transparent = { ...white, a: 0 }
