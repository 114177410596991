import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { flexColumnsCss, FlexChild } from 'components/flex'

export const Item = styled.div`
    ${flexColumnsCss}

    &:not(:last-child) {
        margin-block-end: 16px;
    }

    ${belowTablet`
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-columns: minmax(0, 1fr) 40px;
    `}
`

export const Details = styled(FlexChild)`
    padding-block: 8px;

    ${belowTablet`
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    `}
`

export const Detail = styled.p`
    line-height: 24px;
`

export const SecondaryDetail = styled(Detail)`
    color: var(--huma-color-foreground-subtle);
`
