import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { usePayment } from 'contexts/payment'
import { compact } from 'utilities/array'
import { omit, reduce } from 'utilities/object'
import { isInRange } from 'utilities/math'
import { parseISO, differenceInDays, isFuture, addDays, format } from 'date-fns'
import PubSub from 'pubsub-js'
import { getBundleNameFormatter } from 'components/payment/plan'

export const getScrambledNumber = lastFourDigits => !!lastFourDigits ?
    `•••• •••• •••• ${lastFourDigits}` : // eslint-disable-line no-irregular-whitespace
    ''

export const getExpiry = (month, year) => compact([
    month,
    year?.length === 4 ? year.slice(2) : year
]).join(' / ') ?? ''

export const useGetBundleChangeMeta = () => {
    const {
        bundlesByTrack,
        currentLadder,
        currentPlan
    } = useAccess()

    const { account } = usePayment()

    return useCallback(to => {
        if(!Object.keys(bundlesByTrack ?? {}).length || !account) {
            return
        }

        const {
            subscription,
            billing
        } = account

        let {
            track: fromTrack,
            bundle: fromBundle,
            public: isPublic = true,
            collectionMethod,
            trial
        } = subscription ?? {}

        const expired = !!trial?.expired

        if(expired) {
            fromTrack = currentLadder[0].track
            fromBundle = currentLadder[0].bundle
        }

        const toEnriched = {
            track: to.track,
            ...(bundlesByTrack[to.track]?.find(({ bundle }) => bundle === to.bundle) ?? null),
            ...((!isPublic && fromTrack === to.track && fromBundle === to.bundle) ? {
                ...omit(currentPlan, 'features'),
                plans: [currentPlan]
            } : null)
        }

        return {
            from: {
                track: fromTrack,
                ...(bundlesByTrack[fromTrack]?.find(({ bundle }) => bundle === fromBundle) ?? null)
            },
            to: toEnriched,
            change: getChangeIdentifier({
                bundlesByTrack,
                from: {
                    track: fromTrack,
                    bundle: fromBundle
                },
                to,
                hasBilling: !!billing,
                card: collectionMethod === 'automatic',
                invoice: collectionMethod === 'manual',
                trial: !!trial,
                expired
            }),
            swap: fromTrack !== to.track,
            trial: !!trial,
            expired
        }
    }, [bundlesByTrack, currentLadder, currentPlan, account])
}

const getChangeIdentifier = ({
    bundlesByTrack,
    from,
    to,
    hasBilling,
    card,
    invoice,
    expired,
    trial
}) => {
    const fromLadder = bundlesByTrack[from.track]
    const toLadder = bundlesByTrack[to.track]

    const fromBundle = fromLadder.find(({ bundle }) => bundle === from.bundle)
    const toBundle = toLadder.find(({ bundle }) => bundle === to.bundle)

    if(!toBundle || !fromBundle) {
        return 'error'
    }

    if(trial && !toBundle.free) {
        return 'purchase'
    }

    if(fromBundle.track !== 'standard' && toBundle.track === 'standard' && toBundle.free) {
        return 'cancel'
    }

    const fromIndex = fromLadder.findIndex(({ bundle }) => bundle === from.bundle)
    const toIndex = toLadder.findIndex(({ bundle }) => bundle === to.bundle)

    const sameTrack = fromBundle.track === toBundle.track
    const sameBundle = fromBundle.bundle === toBundle.bundle

    const specialTrack = sameTrack && fromBundle.track !== 'standard'

    if(sameTrack && sameBundle) {
        if(expired && !card) {
            return 'purchase'
        }

        return 'modify'
    }

    if(specialTrack && toBundle.free) {
        return 'cancel'
    }

    if(expired && toBundle.free) {
        return 'freeload'
    }

    if(!toBundle.free && (fromBundle.free || (!hasBilling && !invoice) || expired)) {
        return 'purchase'
    }

    if(sameTrack && fromIndex < toIndex) {
        return 'upgrade'
    }

    if(sameTrack && fromIndex > toIndex) {
        return 'downgrade'
    }
}

export const currencies = {
    NOK: 'code',
    SEK: 'code',
    DKK: 'code',
    ISK: 'code',
    EUR: 'symbol',
    USD: 'code'
}

export const getCurrencyFromLocale = locale => ({
    nb: 'NOK',
    sv: 'SEK'
}[locale] ?? 'EUR')

export const getCurrencyFromCountryCode = locale => ({
    NO: 'NOK',
    SE: 'SEK',
    DK: 'DKK',
    PL: 'PLN'
}[locale?.toUpperCase?.()] ?? 'EUR')

export const calculateTrialDaysDuration = subscription => {
    if(!subscription?.trial) {
        return Infinity
    }

    let {
        startDate,
        endDate
    } = subscription.trial

    if(!startDate || !endDate) {
        return Infinity
    }

    startDate = parseISO(startDate)
    endDate = parseISO(endDate)

    return isFuture(endDate) ?
        differenceInDays(endDate, startDate) :
        0
}

export const calculateTrialDaysLeft = subscription => {
    if(!subscription?.trial) {
        return Infinity
    }

    let trialEndDate = subscription.trial.endDate
    if(!trialEndDate) {
        return Infinity
    }

    trialEndDate = parseISO(trialEndDate)
    const now = new Date

    return isFuture(trialEndDate) ?
        differenceInDays(trialEndDate, now) :
        0
}

export const hasTrialEnded = subscription => {
    if(!subscription?.trial) {
        return false
    }

    const trialEndDate = subscription.trial.endDate
    if(!trialEndDate) {
        return false
    }

    return !isFuture(parseISO(trialEndDate))
}

export const refreshPayment = () => PubSub.publish('payment.refresh')

export const useGetScheduledChangeMeta = () => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()
    const { account } = usePayment()

    const bundleNameFormatter = getBundleNameFormatter(formatMessage)

    return (dateFormat = 'PPP') => {
        if(!account?.subscription) {
            return null
        }

        const differentTrack = account?.subscription?.track !== account?.subscription?.nextTerm?.track
        const differentBundle = account?.subscription?.bundle !== account?.subscription?.nextTerm?.bundle
        const differentTerm = account?.subscription?.chargePlan?.term !== account?.subscription?.nextTerm?.chargePlan?.term

        let downgrade = differentTrack || differentBundle
        let reduction = !differentTrack && !differentBundle && !differentTerm
        let termChange = !differentTrack && !differentBundle && differentTerm
        let continuation = false

        if(!account?.subscription?.nextTerm) {
            downgrade = false
            reduction = false
            termChange = false
            continuation = true
        }

        if(downgrade && termChange) {
            termChange = false
        }

        const [change] = compact([
            downgrade && 'downgrade',
            reduction && 'reduction',
            termChange && 'termChange',
            continuation && 'continuation'
        ])

        const currentTermEndsDate = !!account.subscription.currentTermEndsAt && new Date(account.subscription.currentTermEndsAt)
        const formatCurrentEndsAt = (currentEndFormat = dateFormat) => currentTermEndsDate ?
            format(currentTermEndsDate, currentEndFormat, { locale }) :
            '∞'

        const nextTermStartsDate = !!currentTermEndsDate && addDays(currentTermEndsDate, 1)
        const formatNextStartsAt = (nextStartFormat = dateFormat) => nextTermStartsDate ?
            format(nextTermStartsDate, nextStartFormat, { locale }) :
            '∞'

        const nextSource = account?.subscription?.nextTerm ?? account?.subscription

        return {
            account,
            downgrade,
            reduction,
            termChange,
            continuation,
            change,
            current: {
                track: account.subscription.track,
                bundle: account.subscription.bundle,
                name: bundleNameFormatter({ bundle: account.subscription }),
                term: account.subscription.chargePlan?.term?.toLowerCase() ?? 'annual',
                endsDate: currentTermEndsDate ?? null,
                endsAt: formatCurrentEndsAt(),
                formatEndsAt: formatCurrentEndsAt
            },
            next: {
                track: nextSource.track,
                bundle: nextSource.bundle,
                name: bundleNameFormatter({ bundle: nextSource }),
                term: nextSource.chargePlan?.term?.toLowerCase() ?? 'annual',
                startsDate: nextTermStartsDate ?? null,
                startsAt: formatNextStartsAt(),
                formatStartsAt: formatNextStartsAt
            }
        }
    }
}

// Converts big numbers into Infinity and guarantees currencies is always an object
const normalize = charge => {
    const reducer = (accumulator, tiers, currency) => ({
        ...accumulator,
        [currency]: tiers.map(tier => {
            if(tier.toQuantity === 999999999999999) {
                tier.toQuantity = Infinity
            }

            return tier
        })
    })

    if(!!Object.keys(charge.currencies ?? {}).length) {
        charge.currencies = reduce(charge.currencies, reducer)
    }

    if(!!charge.priceDetails?.length) {
        const { currency } = charge.priceDetails[0]
        charge.currencies = reduce({ [currency]: charge.priceDetails }, reducer)
    }

    if(!Object.keys(charge.currencies ?? {}).length) {
        charge.currencies = {}
    }

    return charge
}

// charge.model === 'Volume' -> quantity-based subscriptions
// charge.model === 'Flat' -> fixed-price subscriptions

const getDetailsFromCharge = ({ charge, currency, quantity }) => {
    charge = normalize(charge)
    currency = charge.priceDetails?.[0]?.currency ?? currency

    let price = charge.priceDetails?.[0]?.price ?? 0
    let listPrice = charge.priceDetails?.[0]?.listPrice ?? price
    let tier
    let minimum
    let range

    if(!!Object.keys(charge.currencies).length) {
        const source = reduce(charge.currencies, (accumulator, tiers, currency) => {
            const tier = tiers.find(({ fromQuantity, toQuantity }) => isInRange({
                min: fromQuantity,
                max: toQuantity,
                value: quantity
            })) ?? tiers[0]

            const minimum = charge.model === 'Volume' && tier.priceBase === 'Flat'

            return {
                ...accumulator,
                [currency]: {
                    price: tier.price,
                    listPrice: tier.listPrice ?? tier.price,
                    tier,
                    minimum,
                    range: [tier.fromQuantity, tier.toQuantity]
                }
            }
        })[currency] ?? {
            price: 0,
            listPrice: 0
        }

        tier = source.tier ?? null
        price = source.price
        listPrice = source.price
        minimum = source.minimum ?? false
        range = source.range ?? [0, Infinity]
    }

    const quantituous = !!charge.quantity && !minimum
    const discounted = quantituous && !!price && !!listPrice && price < listPrice
    const tiered = charge.model === 'Tiered'

    return {
        price,
        listPrice,
        tier,
        minimum,
        range,
        currency,
        charge,
        quantituous,
        discounted,
        tiered,
        pricePeriod: charge?.pricePeriod?.toLowerCase(),
        billingPeriod: charge?.billingPeriod?.toLowerCase()
    }
}

export const getBundleTermChargePlan = ({ bundle, term }) => (!term || bundle?.chargePlans?.length === 1) ?
    (bundle?.chargePlans?.[0] ?? {}) :
    (bundle.chargePlans?.find(p => p.term === term) ?? {})

export const useGetBundlePricing = () => {
    const {
        account,
        currency
    } = usePayment()

    return ({ bundle, term, quantity = Infinity }) => {
        if(bundle.free) {
            return {
                bundle: {
                    price: 0,
                    listPrice: 0,
                    currency
                }
            }
        }

        if(!bundle || !currency) {
            return null
        }

        let bundleDetails = null
        let subscriptionDetails = null
        let nextTermDetails = null

        const bundleChargePlan = getBundleTermChargePlan({ bundle, term })

        if(!!bundleChargePlan?.mainCharge) {
            bundleDetails = {
                name: 'bundle',
                ...getDetailsFromCharge({
                    charge: bundleChargePlan.mainCharge,
                    currency,
                    quantity
                }),
                grandfathered: false
            }
        }

        if(account?.subscription?.track === bundle.track && account?.subscription?.bundle === bundle.bundle && !account.subscription?.trial) {
            subscriptionDetails = {
                name: 'subscription',
                ...getDetailsFromCharge({
                    charge: account.subscription.chargePlan?.mainCharge,
                    currency,
                    quantity
                })
            }
        }

        if(account?.subscription?.nextTerm?.track === bundle.track && account?.subscription?.nextTerm?.bundle === bundle.bundle) {
            nextTermDetails = {
                name: 'nextTerm',
                ...getDetailsFromCharge({
                    charge: account.subscription.nextTerm.chargePlan?.mainCharge,
                    currency,
                    quantity
                })
            }
        }

        if(!!bundleDetails && !!subscriptionDetails) {
            subscriptionDetails.grandfathered = (!subscriptionDetails.discounted && subscriptionDetails.quantituous) ?
                subscriptionDetails.listPrice < bundleDetails.listPrice :
                false
        }

        if(!!bundleDetails && !!nextTermDetails) {
            nextTermDetails.grandfathered = (!nextTermDetails.discounted && nextTermDetails.quantituous) ?
                nextTermDetails.listPrice < bundleDetails.listPrice :
                false
        }

        return {
            bundle: bundleDetails,
            subscription: subscriptionDetails,
            nextTerm: nextTermDetails
        }
    }
}

export const useGetPriceFrequency = () => {
    const { formatMessage } = useIntl()

    return ({ term = 'annual', quantituous = true }) => {
        if(quantituous) {
            if(term === 'monthly') {
                return formatMessage({
                    id: 'payment_information_per_person_month',
                    defaultMessage: 'per person / month'
                })
            }

            return formatMessage({
                id: 'payment_information_per_person_year',
                defaultMessage: 'per person / year'
            })
        }

        if(term === 'monthly') {
            return formatMessage({
                id: 'payment_information_per_company_month',
                defaultMessage: 'per month'
            })
        }

        return formatMessage({
            id: 'payment_information_per_company_year',
            defaultMessage: 'per year'
        })
    }
}

export const useGetSeatsMeta = () => {
    const { organization } = useOrganization()
    const { account } = usePayment()

    const getBundlePricing = useGetBundlePricing()

    const get = (newUsersCount = 0) => {
        if(!organization || !account) {
            return {}
        }

        const quantity = account?.subscription?.chargePlan?.mainCharge?.quantity ?? 1
        const filled = organization.userCount ?? 1

        const price = getBundlePricing({
            bundle: account.subscription,
            term: account?.subscription?.chargePlan?.term?.toLowerCase() ?? 'annual',
            quantity
        }).subscription

        const billed = price.minimum ?
            price.range[1] :
            price.charge.quantity

        const available = Math.max(billed - filled, 0)

        return {
            filled,
            billed,
            contracted: account.subscription?.contractedSeats ?? 0,
            available,
            exceeding: Math.max(newUsersCount - available, 0)
        }
    }

    const ready = !!organization?.userCount && !!account?.subscription?.chargePlan?.mainCharge?.quantity

    return [get, ready]
}