import React, { useRef, Fragment } from 'react'
import { useAccess } from 'contexts/access'
import { size, omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { useGetErrorOrWarning } from 'pages/competence/utilities'
import { Button, EmptyNode } from './s'
import Tooltip from 'components/tooltip'
import ProgressCircle from 'components/progress-circle'
import CustomTooltip from './tooltip'
import {
    CheckCircleFilled as Success,
    AlertTriangleFilled as Warning,
    AlertOctagonFilled as Error,
    CrossCircleFilled as Caution
} from 'components/feather'
import { Plus } from 'styled-icons/feather'

const ValueCircle = ({ type, target, person, personData, record, setActingRecord }) => {
    const circleRef = useRef()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const getErrorOrWarning = useGetErrorOrWarning()

    const hasCompetence = personData.find(record => record.type.id === type.id)
    let value
    let valueColor

    if(!hasCompetence) {
        value = typeof target === 'boolean' ?
            false :
            0
    } else {
        value = typeof target === 'boolean' ?
            true :
            record?.level
    }

    if(!value) {
        valueColor = 'error'
    } else if(value === true) {
        valueColor = 'success'
    } else {
        if(value < target) {
            valueColor = 'warning'
        } else {
            valueColor = 'success'
        }
    }

    target = typeof target === 'boolean' ? 1 : target

    let progress = typeof value === 'boolean' ?
        + value * 100 :
        parseInt(value / 5 * 100)

    if(isNaN(progress)) {
        progress = 0
    }

    const Icon = typeToIconMap[valueColor] || Success

    const missingProgress = (!progress && !record)

    const [ContentNode, contentProps] = (manageAccess || !missingProgress) ?
        [Button, {
            onClick: () => setActingRecord({
                mode: missingProgress ?
                    'edit' :
                    'view',
                user: person,
                ...(missingProgress ? { type } : { record })
            })
        }] :
        [EmptyNode, null]

    // const buttonAction = (!progress && !record) ?
    //     manageAccess ?
    //         () => setActingRecord({
    //             mode: 'edit',
    //             user: person,
    //             type
    //         }) :
    //         null :
    //     () => setActingRecord({
    //         mode: 'view',
    //         record
    //     })

    const hasWarnings = record ? !!size(omit(getErrorOrWarning(record), 'expiring')) : false

    const getButtonClassName = () => {
        if(missingProgress) {
            return 'add'
        }

        if((!progress && record) || hasWarnings) {
            return 'warning'
        }

        if(!missingProgress && typeof value === 'boolean' && !hasWarnings) {
            return 'success'
        }

        if(!missingProgress && typeof value === 'number') {
            return 'progress'
        }

        return ''
    }

    const cautionClassName = cls([
        'state-none',
        !manageAccess && 'state-hover'
    ])

    return (
        <Tooltip
            content={(
                <CustomTooltip
                    type={type}
                    target={target}
                    value={value}
                    record={record}
                    person={person} />
            )}>
            <ContentNode
                {...contentProps}
                className={getButtonClassName()}>
                {(!progress && !record) && (
                    <>
                        <Caution
                            className={cautionClassName}
                            size={16} />
                        {manageAccess && (
                            <Plus
                                className="state-hover"
                                size={20} />
                        )}
                    </>
                )}
                {((!progress && record) || hasWarnings) && (
                    <Warning size={20} />
                )}
                {(!!progress && typeof value === 'boolean' && !hasWarnings) && (
                    <Success size={16} />
                )}
                {(!!progress && typeof value === 'number' && !hasWarnings) && (
                    <ProgressCircle
                        progress={progress}
                        size={32}
                        strokeWidth={2}
                        progressColor={valueColor}
                        ref={circleRef}>
                        {typeof value === 'number' && value}
                        {typeof value === 'boolean' && <Icon size={16} />}
                    </ProgressCircle>
                )}
            </ContentNode>
        </Tooltip>
    )
}

const typeToIconMap = {
    success: ({ size = 16, ...props }) => <Success {...props} size={size} />,
    warning: ({ size = 20, ...props }) => <Warning {...props} size={size} />,
    error: ({ size = 18, ...props }) => <Error {...props} size={size} />
}

export default ValueCircle