import { get } from 'utilities/object'

export const unique = (array = []) => [...new Set(array)]

export const without = (source = [], values = []) =>
    source.filter(value => !values.includes(value))

export const first = ([item]) => item
export const last = (array = []) => array[array.length - 1]

export const compact = (array = []) => array
    .filter(value => (!!value || value === 0))

export const prune = (array = []) => unique(compact(array))
export const pruneAdjacent = (array = []) => compact(array).filter((item, index) => item !== array[index - 1])

export const pruneBy = (array = [], by = 'id') => {
    const uniqueKeys = prune(array.map(item => get(item, by)))
    return uniqueKeys.map(key => array.find(item => !!get(item, by) && key === get(item, by)))
}

export const sum = (array = []) => array.reduce((sum, value) => sum + value, 0)

export const sumBy = (array = [], by = 'value') => array
    .reduce((sum, { [by]: value }) => sum + value, 0)

export const move = (array = [], from, to) => {
    if(!array.length || from > array.length - 1) {
        return array
    }

    array = [...array]
    const [item] = array.splice(from, 1)
    array.splice(to, 0, item)
    return array
}

export const sharesToRoundedPercentages = (array = []) => {
    const total = sum(array)

    return array.reduce(({ roundedPcts, accumulatedPct }, share, index) => {
        accumulatedPct = sum(array.slice(0, index + 1)) / total * 100
        const accumulatedRoundedPcts = sum(roundedPcts)

        roundedPcts.push(Math.round(accumulatedPct) - accumulatedRoundedPcts)

        return {
            roundedPcts,
            accumulatedPct
        }
    }, {
        roundedPcts: [],
        accumulatedPct: 0
    }).roundedPcts
}

export const shuffle = (array = []) => {
    array = [...array]

    for(let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
    }

    return array
}