import React, { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { darkMode } from 'utilities/styled'
import { FocusOn } from 'react-focus-on'
import { hideChat, showChat } from 'hooks/voiceflow'

const Veil = styled.div`
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background-color: rgb(from var(--huma-palette-neutral-10) r g b / .2);

    ${darkMode`
        background-color: rgb(from var(--huma-palette-neutral-10) r g b / .4);
    `}
`

const PositionedContainer = styled.div.attrs({
    role: 'prompt',
    'aria-labelledby': 'Prompt title', // TODO
    'aria-describedby': 'Prompt description' // TODO
})`
    width: 0;
    height: 0;
    position: fixed;
    top: 50%;
    left: 50%;
`

export const Prompt = ({ children = null, cancel, ...props }) => {
    useLayoutEffect(() => {
        document.body.classList.add('focus-on', 'blocking')

        // Delay it so that it triggers after context menus
        global.requestAnimationFrame(hideChat)

        return () => {
            if(document.querySelectorAll([
                '#prompts [data-focus-lock-disabled]',
                '#modals [data-focus-lock-disabled]'
            ].join(',')).length === 1) {
                document.body.classList.remove('focus-on', 'blocking')
                showChat()
            }
        }
    }, [])

    return (
        <FocusOn
            returnFocus={true}
            onEscapeKey={cancel ?? (() => {})}>
            <Veil />
            <PositionedContainer {...props}>
                {children}
            </PositionedContainer>
        </FocusOn>
    )
}

export const promptify = prompt => createPortal(
    prompt,
    document.getElementById('prompt')
)

export const Container = styled.div`
    position: absolute;

    border-radius: 8px;
    border: 1px solid var(--huma-color-border-default);
    width: 336px;
    padding: 24px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-low);

    transform: translate(-50%, -50%);

    color: var(--huma-color-foreground-default);
`
