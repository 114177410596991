import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Plain } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

const labelAndTypeCss = css`
    display: grid;
    grid-template-columns: minmax(auto, min-content) minmax(0, 1fr);
    justify-content: start;
    align-items: center;
    column-gap: 24px;

    max-width: 100%;

    > .content {
        ${svgStroke('small')}
    }
`

export const LabelAndType = styled.div`
    ${labelAndTypeCss}
`

export const LabelAndTypeClickable = styled(Plain)`
    ${labelAndTypeCss}
    text-align: start;
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(LabelAndTypeClickable)}

    &.small svg {
        ${svgStroke('small')}
    }

    svg {
        ${svgStroke}
    }
`

export const Label = styled(Paragraph)`
    line-height: 20px;
`

export const Caption = styled(CaptionSmall)`
    margin-inline-start: -4px;
`