import React, { memo } from 'react'
import { usePage } from 'contexts/page'
import ModuleTitleBase from 'components/module-title'
import Tutorial from 'components/tutorial'

const Upgrade = memo(() => {
    const { upgrade = {} } = usePage()

    const {
        enabled: upgradable = false,
        title,
        tutorial,
        content,

        // Only let children pass through when properly guarded from fetching data
        passthrough = false
    } = upgrade

    if(!upgradable || passthrough) {
        return null
    }

    return (
        <>

            {!!title && <ModuleTitle which={title} />}
            {!!tutorial && <Tutorial {...tutorial} />}
            {!!content && content}
        </>
    )
})

const ModuleTitle = ({ which, ...props }) => (
    <ModuleTitleBase
        {...props}
        {...((typeof which === 'string') ? { which } : which)}
        className="spacious" />
)

export default Upgrade