import styled from 'styled-components'
import { Container as ContainerBase } from 'pages/dashboard/s'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const Container = styled(ContainerBase)`
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-row-gap: 24px;
    min-height: 40px;
    padding-bottom: 16px;
    position: relative;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    grid-template-columns: 20px 2fr repeat(2, 1fr);
    column-gap: 8px;
    padding: 8px 0;
`