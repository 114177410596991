import styled from 'styled-components'
import { belowContainer } from 'utilities/styled'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'

const containerProps = {
    name: 'salary',
    breakpoint: 520
}

export const ContainerQuery = styled.div`
    container-type: inline-size;
    container-name: ${containerProps.name};

    align-self: normal;
`

export const Wrapper = styled(Grid)`
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    gap: 16px;

    ${belowContainer(containerProps)`
        grid-template-columns: 1fr;
    `}
`

export const Card = styled.div`
    border-radius: 8px;
    border: 1px solid var(--huma-color-border-default);
    padding: 16px;

    &.full-width {
        grid-column: 1 / -1;
    }

    &.up .value::before {
        content: "+";
        margin-inline-end: 4px;
    }
`

export const CardTitle = Paragraph

export const CardValue = styled(Paragraph)`
    font-size: 24px;
    font-weight: 500;
`