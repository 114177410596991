import React, { Component, createContext, useContext, createRef } from 'react'
import { useEnvironment } from 'contexts/environment'
import { useI18n } from 'contexts/i18n'
import { intersectionObserver } from 'utilities/dom'
import { local } from 'utilities/storage'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { v4 as uuid } from 'uuid'
import { size, pick } from 'utilities/object'
import { get, post, remove } from 'api'
import { getTypeModule } from 'pages/processes/utilities'

const ProcessTemplatesContext = createContext()

class ProcessTemplatesProvider extends Component {
    constructor(props) {
        super(props)

        const {
            type,
            eternal = true
        } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.humaIntersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults()
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.state = {
            templates: [],
            type,
            total: 0,
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetch: this.fetch,
            toggleSorting: this.toggleSorting,

            fetchTemplate: this.fetchTemplate,
            addTemplate: this.addTemplate,
            duplicateTemplate: this.duplicateTemplate,
            removeTemplate: this.removeTemplate,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false,

            fetchHumaTemplate: this.fetchHumaTemplate,
            fetchHumaTemplates: this.fetchHumaTemplates,

            humaTemplates: {
                templateLocales: ['en', 'nb', 'sv', 'fi'],
                templates: [],
                hasFetched: false,
                fetching: false,
                ...(eternal ? { intersecter: this.humaIntersectionObserver.ref } : null)
            }
        }
    }

    componentDidMount() {
        const {
            fetchOnMount = true,
            fetchHumaTemplatesOnMount = false,
            locale
        } = this.props

        fetchOnMount && this.fetchDebounced()
        fetchHumaTemplatesOnMount && this.fetchHumaTemplates(locale, true)
    }

    componentDidUpdate({ type, paging }, { sorting }) {
        const typeChanged = type !== this.props.type
        const pagingChanged = !isEqual(paging, this.props.paging)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(typeChanged) {
            state.type = this.props.type
        }

        if(typeChanged || pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(typeChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        this.humaIntersectionObserver.destroy()
    }

    fetch = async (force = false) => {
        const {
            fetching,
            sorting,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { type } = this.state

        const { response, ok } = await get({
            path: `/${type}-templates`,
            params: {
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ templates: previousTemplates }) => {
                const previousTemplatesWithoutFetched = previousTemplates.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const templates = [
                    ...previousTemplatesWithoutFetched,
                    ...response.items
                ]

                return {
                    templates,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && templates.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    fetchTemplate = id => {
        const { type } = this.state
        return get({ path: `/${type}-templates/${id}` })
    }

    fetchHumaTemplate = async id => {
        const { ok, response } = await this.props.fetchFromS3(`/templates/${getTypeModule(this.state.type)}/${id}.json`)

        if(ok) {
            return {
                ok,
                response: {
                    ...response,
                    tasks: response.tasks.map(task => ({
                        ...task,
                        id: uuid()
                    }))
                }
            }
        } else {
            return { ok, response }
        }
    }

    fetchHumaTemplates = async (outsideLocale = null, initialFetch = false) => {
        const {
            fetchFromS3,
            locale
        } = this.props

        const { type } = this.state

        if(initialFetch && type === 'process') {
            outsideLocale = null
        }

        const templateLocales = outsideLocale ?
            [outsideLocale]
            : this.state.humaTemplates.templateLocales

        this.setState({ fetchingLibraryTemplates: true })

        const results = await Promise.all(
            templateLocales.map(async templateLocale => {
                const { ok, response: templates } = await fetchFromS3(`/templates/${getTypeModule(type)}/${templateLocale}.json`)

                return ok && templates ? templates : []
            })
        )

        const templates = results.flat().sort(({ name: oneName }, { name: twoName }) => oneName.localeCompare(twoName, locale, { sensitivity: 'base' }))

        this.setState(({ humaTemplates }) => ({
            humaTemplates: {
                ...humaTemplates,
                templates: templates.map(template => ({
                    ...template,
                    ...(type !== 'process' ? { providedByHuma: true } : null)
                })),
                hasFetched: true,
                fetching: false
            }
        }))
    }

    addTemplate = async body => {
        const { type } = this.state

        const { ok, response: template } = await post({
            path: `/${type}-templates`,
            body
        })

        if(ok && template) {
            this.flash.current = template

            this.setState(({ templates, total }) => ({
                templates: [
                    template,
                    ...templates
                ],
                total: total + 1
            }))
        }

        return { ok, response: template }
    }

    duplicateTemplate = async (id, body) => {
        const { type } = this.state

        let ok = false
        let template = null

        if(body.providedByHuma || body.providedBy) {
            const { ok: fetchOk, response: fetchResponse } = await this.fetchHumaTemplate(id)

            if(fetchOk && fetchResponse) {
                const { ok: addOk, response: addResponse } = await post({
                    path: `/${type}-templates`,
                    body: {
                        ...fetchResponse,
                        ...body
                    }
                })

                ok = addOk
                template = {
                    ...addResponse,
                    ...pick(body, 'taskCount', 'timeSpanDays')
                }
            }
        } else {
            const { ok: duplicateOk, response: duplicateResponse } = await post({
                path: `/${type}-templates/${id}/duplicate`,
                body
            })

            ok = duplicateOk
            template = duplicateResponse
        }

        if(ok && template) {
            this.flash.current = template

            this.setState(({ templates, total }) => ({
                templates: [
                    template,
                    ...templates
                ],
                total: total + 1
            }))
        }

        return { ok, response: template }
    }

    removeTemplate = async id => {
        const { type } = this.state

        const { ok } = await remove({
            path: `/${type}-templates/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ templates, total, paging }) => ({
                templates: templates.filter(template => template.id !== id),
                total: total - 1,
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    toggleSorting = field => {
        if(field in sortingOptions) {
            this.setState(({ sorting }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc' : 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    templates: [],
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }
            })
        }
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ templates }) => {
        this.flash.current = null

        return {
            templates: [...templates]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <ProcessTemplatesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ProcessTemplatesContext.Provider>
        )
    }
}

const sortingOptions = {
    name: 'asc'
}

const sortingDefaults = () => ({
    by: Object.keys(sortingOptions)[0],
    direction: Object.values(sortingOptions)[0]
})

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useProcessTemplates = () => useContext(ProcessTemplatesContext)

export default props => {
    const { fetchFromS3 } = useEnvironment()
    const { locale } = useI18n()

    return (
        <ProcessTemplatesProvider
            {...props}
            fetchFromS3={fetchFromS3}
            locale={locale} />
    )
}