import React from 'react'
import { useIntl } from 'react-intl'
import Label from './label'

const SalaryStatus = ({ fromDate, toDate }) => (
    <Label
        fromDate={fromDate}
        toDate={toDate} />
)

const statusTexts = {
    current: {
        id: 'salary_status_current',
        defaultMessage: 'Current'
    },
    upcoming: {
        id: 'salary_status_upcoming',
        defaultMessage: 'Upcoming'
    }
}

export const useSalaryStatusTextFormatter = () => {
    const { formatMessage } = useIntl()

    return status => {
        const message = statusTexts[status] ?? null

        return message ?
            formatMessage(message) :
            null
    }
}

export default SalaryStatus