import { useState } from 'react'
import isEqual from 'react-fast-compare'
import { pick } from 'utilities/object'

export const useTriggerOnPropsChange = ({ props, defaults, keys = [], onChange }) => {
    const [previous, setPrevious] = useState(pick({
        ...defaults,
        ...props
    }, ...keys))

    const current = pick({
        ...defaults,
        ...props
    }, ...keys)

    if(!isEqual(previous, current)) {
        setPrevious(current)
        onChange?.(current)
    }
}