import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { useAbsenceEntries } from 'pages/absence/utilities'
import { Form, Actions } from './s'
import StringField from 'components/form/field/string'
import { Helper } from 'components/form/field/s'
import { Ghost, Button } from 'components/button'
import Key from 'components/key'

const ApprovalActions = ({ context = 'single', entry, type, alwaysShowHelper = false, onDone, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const [reviewing, setReviewing] = useState(null)
    const [comment, setComment] = useState('')
    const [showHelper, setShowHelper] = useState(alwaysShowHelper)

    const {
        updateEntryReview,
        total,
        setTotal
    } = useAbsenceEntries(context)

    const { me } = useMe()

    const {
        id,
        status
    } = entry

    useEffect(() => {
        setComment(entry.comment)

        return () => setComment('')
    }, [entry?.id])

    if(!['pending', 'approved', 'rejected'].includes(status)) {
        return null
    }

    const changeComment = async body => {
        setReviewing(true)

        const { ok } = await updateEntryReview({
            ...entry,
            type,
            ...body,
            reviewedBy: me,
            status
        }, id)

        setReviewing(null)

        ok && onDone?.()
    }

    const review = async status => {
        setReviewing(status)

        const { ok } = await updateEntryReview({
            ...entry,
            type,
            reviewedBy: me,
            status,
            comment
        }, id)

        if(['order', 'time', 'requests'].includes(context)) {
            if(status !== 'pending') {
                setTotal(total - 1)
            } else {
                setTotal(total + 1)
            }
        }

        setReviewing(null)

        ok && onDone?.(status)
    }

    const SetPending = () => (status !== 'pending') ? (
        <Ghost
            className={`constructive${(reviewing === 'pending') ? ' loading' : ''}`}
            disabled={reviewing}
            onClick={() => review('pending')}>
            <FormattedMessage
                id={`absence_approval_action_withdraw_${status}`}
                defaultMessage={status} />
        </Ghost>
    ) : null

    const Reject = () => (status !== 'rejected') ? (
        <Ghost
            className={`destructive${(reviewing === 'rejecting') ? ' loading' : ''}`}
            disabled={reviewing}
            onClick={() => review('rejected')}>
            <FormattedMessage
                id="action_reject"
                defaultMessage="Reject" />
        </Ghost>
    ) : null

    const Approve = () => (status !== 'approved') ? (
        <Button
            className={`constructive${(reviewing === 'approving') ? ' loading' : ''}`}
            disabled={reviewing}
            onClick={() => review('approved')}>
            <FormattedMessage
                id="action_approve"
                defaultMessage="Approve" />
        </Button>
    ) : null

    return (
        <Form
            {...props}
            layout="vertical"
            onSubmit={changeComment}>
            <StringField
                salt={salt}
                className="compact"
                label={false}
                name="comment"
                field={{ value: entry?.comment }}
                controlProps={{
                    placeholder: formatMessage({
                        id: 'absence_approval_placeholder_comment',
                        defaultMessage: 'Add a comment'
                    }),
                    autoFocus: !!(context === 'single'),
                    maxLength: 255
                }}
                onChange={({ comment }) => setComment(comment)}
                onFocus={() => {
                    if(!alwaysShowHelper) {
                        setShowHelper(true)
                    }
                }}
                onBlur={() => {
                    if(!alwaysShowHelper) {
                        setShowHelper(false)
                    }
                }} />
            {!!showHelper && (
                <Helper>
                    <FormattedMessage
                        id="absence_approval_helper_comment"
                        defaultMessage="Press <key>Return</key> to save changes in comment"
                        values={{
                            key: chunks => <Key>&crarr; {chunks}</Key>
                        }} />
                </Helper>
            )}
            <Actions>
                <SetPending />
                <Reject />
                <Approve />
            </Actions>
        </Form>
    )
}

export default ApprovalActions
