import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { format, startOfDay, addHours, isBefore, isWithinInterval, isAfter } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useInterval } from 'hooks/interval'
import { getShortName } from 'utilities/person'
import { capitalize } from 'utilities/string'
import { compact } from 'utilities/array'
import { getPeopleProfileMeUrl } from 'utilities/url'
import { Columns, AvatarColumn, SkeletonToday, SkeletonHeading, Today, Heading } from './s'
import { SkeletonCircle } from 'components/skeleton'
import Avatar from 'components/avatar'
import { FlexChild } from 'components/flex'
import Important from './important'

const DashboardGreeting = () => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()
    const { me } = useMe()

    const {
        onboarder,
        stepStatuses: onboardingStatuses
    } = useServiceOnboarding()

    const { checkModule } = useAccess()

    const { statuses } = useOrganization()
    const absenceConfigured = statuses?.hasSetUpAbsenceTypes || statuses?.hasSetUpWorkSchedule
    const absenceAccess = checkModule('absence') && absenceConfigured

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 60 * 15 * 1000)

    const today = startOfDay(now)
    const dawn = addHours(today, 5)
    const noon = addHours(today, 12)
    const dusk = addHours(today, 18)

    const [greeting] = compact([
        !!me?.jubileesToday?.find(({ type }) => type === 'birthday') && 'birthday',
        isBefore(now, dawn) && 'night',
        isWithinInterval(now, { start: dawn, end: noon }) && 'morning',
        isWithinInterval(now, { start: noon, end: dusk }) && 'afternoon',
        isAfter(now, dusk) && 'evening'
    ])

    if(!me) {
        return (
            <Columns>
                <AvatarColumn>
                    <SkeletonCircle size={56} />
                </AvatarColumn>
                <FlexChild>
                    <SkeletonToday
                        size={20}
                        length={12} />
                    <SkeletonHeading
                        size={24}
                        length={24} />
                </FlexChild>
            </Columns>
        )
    }

    return (
        <Columns>
            <AvatarColumn>
                <Avatar
                    who={me}
                    size={56}
                    link={true}
                    to={getPeopleProfileMeUrl()}
                    className="overflow" />
            </AvatarColumn>
            <FlexChild>
                <Today>
                    {capitalize(format(now, formatMessage({
                        id: 'date_fns_format_weekday_date_monthname',
                        defaultMessage: 'EEEE, MMMM do'
                    }), { locale }))}
                </Today>
                <Heading>
                    <FormattedMessage
                        {...greetings[greeting]}
                        values={{ name: getShortName(me) }} />
                </Heading>
            </FlexChild>
            <Important
                absenceAccess={absenceAccess}
                onboarder={onboarder}
                statuses={onboardingStatuses} />
        </Columns>
    )
}

export default DashboardGreeting

const greetings = {
    birthday: {
        id: 'dashboard_happy_birthday',
        defaultMessage: 'Happy birthday, {name}! 🥳'
    },
    night: {
        id: 'dashboard_good_night',
        defaultMessage: 'You night owl, {name}!'
    },
    morning: {
        id: 'dashboard_good_morning',
        defaultMessage: 'Good morning, {name}'
    },
    afternoon: {
        id: 'dashboard_good_afternoon',
        defaultMessage: 'Good afternoon, {name}'
    },
    evening: {
        id: 'dashboard_good_evening',
        defaultMessage: 'Good evening, {name}'
    }
}