import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { LabelAndType, LabelAndTypeClickable, Symbol, Label } from './s'
import Ellipsify from 'components/ellipsify'
import { CaptionSmall } from 'components/typography/caption'
import {
    Minus as Text,
    AlignLeft as Textarea,
    ChevronDown as Select,
    Link as Url,
    Hash as NumberIcon,
    Calendar as DateIcon
} from 'styled-icons/feather'
import { Contrast as BooleanIcon } from 'styled-icons/ionicons-solid'

export const typeToIconMap = {
    text: Text,
    textarea: Textarea,
    enum: Select,
    boolean: BooleanIcon,
    url: Url,
    number: NumberIcon,
    decimal: NumberIcon,
    localdate: DateIcon
}

export const typeToColorTokenMap = {
    text: 'symbol-green-1',
    textarea: 'symbol-green-1',
    enum: 'symbol-yellow-1',
    boolean: 'symbol-yellow-1',
    url: 'symbol-blue-1',
    number: 'symbol-purple-1',
    decimal: 'symbol-purple-1',
    localdate: 'symbol-skin-1'
}

export const typeToTitleTranslationMap = {
    text: {
        id: 'custom_fields_type_text_title',
        defaultMessage: 'Text'
    },
    textarea: {
        id: 'custom_fields_type_textarea_title',
        defaultMessage: 'Textarea'
    },
    enum: {
        id: 'custom_fields_type_select_title',
        defaultMessage: 'Dropdown'
    },
    boolean: {
        id: 'custom_fields_type_boolean_title',
        defaultMessage: 'Yes / No'
    },
    url: {
        id: 'custom_fields_type_url_title',
        defaultMessage: 'URL'
    },
    number: {
        id: 'custom_fields_type_integer_title',
        defaultMessage: 'Number'
    },
    decimal: {
        id: 'custom_fields_type_decimal_title',
        defaultMessage: 'Number with decimals'
    },
    localdate: {
        id: 'custom_fields_type_date_title',
        defaultMessage: 'Date'
    }
}

export const typeToDescriptionTranslationMap = {
    text: {
        id: 'custom_fields_type_text_description',
        defaultMessage: 'A single line of text'
    },
    textarea: {
        id: 'custom_fields_type_textarea_description',
        defaultMessage: 'Multiple lines of text'
    },
    enum: {
        id: 'custom_fields_type_select_description',
        defaultMessage: 'Two or more options to choose from'
    },
    boolean: {
        id: 'custom_fields_type_boolean_description',
        defaultMessage: 'Either true or false'
    },
    url: {
        id: 'custom_fields_type_url_description',
        defaultMessage: 'A link to a website'
    },
    number: {
        id: 'custom_fields_type_integer_description',
        defaultMessage: 'A whole number'
    },
    decimal: {
        id: 'custom_fields_type_decimal_description',
        defaultMessage: 'A number with decimal points'
    },
    localdate: {
        id: 'custom_fields_type_date_description',
        defaultMessage: 'A calendar date'
    }
}

export const getTypeDescriptionTranslation = field => {
    if(!field?.type) {
        return {}
    }

    if(field.type === 'enum' && !!field.options) {
        return {
            id: 'custom_fields_type_select_description_specific',
            defaultMessage: '{count} options to choose from',
            values: { count: size(field.options) }
        }
    }

    return typeToDescriptionTranslationMap[field.type] ?? {}
}

export const TypeSymbol = ({ type, size = 40, className }) => {
    const Icon = typeToIconMap[type]
    const colorToken = typeToColorTokenMap[type]
    const iconSize = size / 2

    className = cls([
        className,
        (iconSize <= 16) && 'small'
    ])

    return (
        <Symbol
            {...(className ? { className } : null)}
            symbol={{ colorToken }}
            icon={<Icon size={iconSize} />}
            size={size} />
    )
}

export const TypeTitle = field => <FormattedMessage {...typeToTitleTranslationMap[field.type]} />
export const TypeDescription = field => <FormattedMessage {...getTypeDescriptionTranslation(field)} />

export const Type = ({ field, size = 40, className, onClick }) => {
    const { formatMessage } = useIntl()
    const { values, ...translation } = getTypeDescriptionTranslation(field)

    const [Wrapper, wrapperProps] = onClick ?
        [LabelAndTypeClickable, { onClick }] :
        [LabelAndType, null]

    return (
        <Wrapper
            {...wrapperProps}
            {...(className ? { className } : null)}>
            <TypeSymbol
                {...field}
                size={size}
                className="hover-effect" />
            <div>
                <Label className="compact">
                    <Ellipsify text={field.label} />
                </Label>
                <CaptionSmall className="compact">
                    <Ellipsify text={formatMessage(translation, values)} />
                </CaptionSmall>
            </div>
        </Wrapper>
    )
}