import styled, { css } from 'styled-components'
import {
    svgStroke, flashKeyframes,
    belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Link as LinkBase } from 'react-router-dom'
import { InlineMessage as InlineMessageBase } from 'components/message'
import { ancestorHover } from 'components/avatar/s'

export const PersonWrapper = styled(Grid)`
    grid-template-columns: minmax(0, 2fr) repeat(2, 1fr);
    column-gap: 24px;

    width: 100%;
    padding-block: 24px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    &:has(> .actions) {
        grid-template-columns: minmax(0, 2fr) repeat(2, 1fr) 40px;
    }

    &:has(.meta) {
        grid-template-rows: 40px 1fr;
    }

    ${belowTablet`
        grid-template-columns: 40px 1fr;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 8px;
        column-gap: 16px;

        &:has(> .actions) {
            grid-template-columns: 40px 1fr 40px;
        }
    `}
`

const cellCss = css`
    display: grid;
    grid-template-rows: subgrid;
    align-items: center;

    ${belowTablet`
        grid-column: 2 / -1;
    `}
`

export const NameCell = styled.div`
    ${cellCss}

    grid-row: 1 / -1;

    ${belowTablet`
        grid-column-start: 1;

        ${PersonWrapper}:has(> .actions) & {
            grid-column-end: 3;
        }
    `}
`

export const Link = styled(LinkBase)`
    ${ancestorHover()}

    grid-row: inherit;
    display: grid;
    grid-template-rows: inherit;
    grid-template-columns: 40px 1fr;
    row-gap: 4px;
    column-gap: 16px;
    align-items: start;

    color: var(--huma-color-foreground-default);
    text-decoration: none;

    > :first-child {
        grid-row: 1;
        grid-column: 1 / -1;
    }

    > .meta {
        grid-row: 2;
        grid-column: 2;
    }

    &:where(:hover, :focus) p[class*="Name"] {
        text-decoration: underline;
    }
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-block-start: 4px;
`

export const ProgressCell = styled.div`
    ${cellCss}

    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 16px;

    & > :not(:last-child, :only-child) {
        position: relative;

        &::after {
            content: "·";
            position: absolute;
            right: -11px;

            color: var(--huma-color-foreground-subtle);
        }
    }
`

export const BaseDateCell = styled.div`
    ${cellCss}
`

export const ActionsCell = styled.div`
    justify-content: center;

    ${belowTablet`
        grid-row: 1 / 2;
        grid-column: 3 / 4;
    `}

    svg {
        ${svgStroke()}
    }
`