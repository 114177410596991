import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'processes'

const Processes = props => {
    const {
        initialized,

        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule(moduleIdentifier) && [
        'users:manage',
        'unit:processes:manage',
        'processes:templates:manage'
    ].some(check)

    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="processes.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}
            newlyAdded={true}>
            <FormattedMessage
                id="noun_processes"
                defaultMessage="Processes" />
        </MenuItemLink>
    )
}

export default Processes