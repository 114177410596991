import React from 'react'
import { useEnrichedActions } from 'widgets/context-menu'
import { ContextMenu } from './s'

const ViewEquipmentPieceActions = ({ actions, context, dismiss, salt }) => {
    actions = useEnrichedActions({
        namedActions: actions,
        context,
        onAfter: { remove: dismiss },
        include: ['edit', 'manageDocumentation', 'remove']
    })

    return (
        <ContextMenu
            prebound actions={actions}
            salt={salt} />
    )
}

export default ViewEquipmentPieceActions
