import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isBefore, isWithinInterval, isAfter, endOfDay } from 'date-fns'
import { isofy } from 'utilities/date-time'
import { Wrapper, Heading } from './s'
import Entry, { LoadingEntry } from './entry'

const EntryTotalBalance = ({ estimate, dateRange, type, status, absenceAdmin, permissionLevel, ...props }) => {
    if(!estimate?.details?.length) {
        return null
    }

    const [firstEstimate] = estimate.details
    const hasDifferentPolicies = estimate.details.some(({ policy }) => policy.id !== firstEstimate.policy.id)

    if(
        isPastPeriod({
            range: {
                to: firstEstimate?.effectiveTimeWindow
            }
        }) &&
        firstEstimate.policy.timeWindow.type !== 'rolling'
    ) {
        return null
    }

    const { requireApproval } = type

    let heading = {
        id: 'absence_total_balance_after_registering_title',
        defaultMessage: 'Total balance after registration'
    }

    if(status === 'approved' && (['edit', 'approve'].includes(permissionLevel) || absenceAdmin)) {
        heading = {
            id: 'absence_total_balance_title',
            defaultMessage: 'Total balance'
        }
    }

    if(
        (status !== 'approved' && requireApproval && permissionLevel !== 'approve' && !absenceAdmin) ||
        status === 'pending'
    ) {
        heading = {
            id: 'absence_total_balance_pending_title',
            defaultMessage: 'Total balance after approval'
        }
    }

    return (
        <Wrapper {...props}>
            <Heading className="compact">
                <FormattedMessage {...heading} />
            </Heading>
            {estimate.details.map((entry, index) => (
                <Entry
                    {...entry}
                    type={type}
                    index={index}
                    dateRange={dateRange}
                    entriesCount={estimate.details.length}
                    hasDifferentPolicies={hasDifferentPolicies}
                    key={`${entry.policy.id}:${entry.effectiveTimeWindow.from}::${entry.effectiveTimeWindow.to}`} />
            ))}
        </Wrapper>
    )
}

export const TotalBalanceLoader = ({ type, ...props }) => (
    <Wrapper {...props}>
        <Heading className="compact">
            <FormattedMessage
                id="absence_total_balance_title"
                defaultMessage="Total balance" />
        </Heading>
        <LoadingEntry type={type} />
    </Wrapper>
)

export const isPastPeriod = ({ range }) => isAfter(new Date(), endOfDay(isofy(range.to)))

export const isCurrentPeriod = ({ range }) => isWithinInterval(new Date(), {
    start: isofy(range.from),
    end: endOfDay(isofy(range.to))
})

export const isFuturePeriod = ({ range }) => isBefore(new Date(), isofy(range.from))

export default EntryTotalBalance