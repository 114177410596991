import styled from 'styled-components'
import { belowLaptop, fromLaptop } from 'utilities/styled'
import { Label as LabelBase } from 'components/form/field/s'
import { Grid } from 'components/grid'
import LinkBase from 'components/link'
import { Plain } from 'components/button'
import { CaptionSmall } from 'components/typography/caption'
import { DisplayEmoji } from 'components/symbol'
import CheckboxFieldBase from 'components/form/field/checkbox'
import { Control } from 'components/form/field/checkbox/edit/s'
import { PlainTexts } from 'components/entities'
import ArrayFieldBase from 'components/form/field/array'
import { ValueDisplay } from 'components/form/field/s'

export const Label = styled(LabelBase)`
    display: inline-block;
    margin-bottom: 8px;
`

export const Meta = styled.div`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    padding-inline: 24px;
`

export const MetaItem = styled(Grid)`
    gap: 16px;
    align-items: start;
    padding-block: 16px;

    ${fromLaptop`
        grid-template-columns: 1fr 2fr 1fr;

        &.action-less {
            grid-template-columns: 1fr 3fr;
        }
    `}

    ${belowLaptop`
        grid-template-columns: 1fr min-content;
        grid-template-rows: auto auto;

        &.action-less {
            grid-template-columns: 1fr;
        }
    `}

    ${Label} {
        margin: 0;
    }

    &.toggle ${Label} {
        padding-block: 4px;
    }

    &:not(:last-of-type) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    > button {
        justify-self: end;
    }
`

export const Value = styled.div`
    display: flex;
    align-items: start;
    gap: 8px;

    line-height: 24px;

    ${belowLaptop`
        grid-row: 2 / -1;
        grid-column: 1 / -1;
    `}

    &.vertical {
        flex-direction: column;
    }
`

export const Link = styled(LinkBase).attrs({ className: 'underline-reversed' })``

export const Helper = styled(CaptionSmall)`
    margin: 0;
`

export const CheckboxField = styled(CheckboxFieldBase)`
    & > ${Control} {
        margin-inline-start: 0;
    }
`

export const ChangeButton = styled(Plain)`
    width: min-content;
    margin-block: -8px;

    ${belowLaptop`
        grid-row: 1 / 2;
        grid-column: 2 / -1;
    `}
`

export const Emoji = styled(DisplayEmoji)`
    margin-inline-end: 6px;
`

export const EntitiesPlain = styled(PlainTexts)`
    flex-wrap: wrap;
    min-height: inherit;

    font-size: 16px;
    color: var(--huma-color-foreground-default);

    &:empty {
        display: none;
    }
`

export const ArrayField = styled(ArrayFieldBase)`
    ${ValueDisplay}.empty {
        height: unset;
        min-height: unset;
        padding-block: 0;
    }
`