import React from 'react'
import { useProcesses } from 'contexts/processes'
import Proccesses from './processes'
import Xboarding from './xboarding'

const Processes = props => {
    const { type } = useProcesses()

    if(type === 'process') {
        return <Proccesses {...props} />
    }

    return <Xboarding {...props} />
}

export default Processes