import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useLocationChange = callback => {
    const { pathname } = useLocation()

    useLayoutEffect(() => {
        callback(pathname)
    }, [pathname])

    return null
}