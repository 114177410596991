import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ArrayField from 'components/form/field/array'
import Message from 'components/message'

const BulkTopicsTags = ({ category, salt }) => {
    const { formatMessage } = useIntl()

    const [value, setValue] = useState([])

    return (
        <>
            <Message
                type="info"
                message={formatMessage({
                    id: 'handbooks_info_add_topics_tags',
                    defaultMessage: '{count, plural, =0 {} =1 {Adding tags to <strong>one topic<\/strong>} other {Adding tags to <strong>{count} topics<\/strong>}}'
                }, { count: category.topics.length })} />
            <ArrayField
                salt={salt}
                className="compact"
                label={formatMessage({
                    id: 'noun_tags',
                    defaultMessage: 'Tags'
                })}
                name="tags"
                field={{ value }}
                onChange={({ tags }) => setValue(tags)} />
        </>
    )
}

export default BulkTopicsTags
