import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useHistate } from 'hooks/histate'
import { reduce, omit, size, map } from 'utilities/object'
import { ModalHeading } from 'components/typography/heading'
import Form from 'components/form/controller'
import SymbolField from 'components/form/field/symbol'
import StringField from 'components/form/field/string'
// import TextField from 'components/form/field/text'
import EditorField from 'components/form/field/editor'
import UsefulLinks from 'components/useful-links'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { blankSymbol } from 'pages/handbook'

const EditHandbookCategory = ({ category = {}, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const {
        id,
        title,
        symbol,
        summary,
        links: initialLinks = [],
        index = 0
    } = category ?? {}

    const {
        addCategory,
        updateCategory
    } = useHandbook()

    const [updating, setUpdating] = useState(false)

    const fields = {
        summary: useHistate(summary ?? null),
        links: useHistate(initialLinks)
    }

    const addOrUpdate = async body => {
        setUpdating(true)

        body = {
            ...reduce(
                fields,
                (accumulator, { changed, current }, key) => ({
                    ...accumulator,
                    ...((!!id && changed || !id) ? {
                        [key]: current?.id ?? current
                    } : null)
                }),
                omit(body, ...Object.keys(fields))
            )
        }

        if(!size(body)) {
            return void dismiss()
        }

        let ok = false

        if(id) {
            const result = await updateCategory({ body, id })
            ok = result?.ok
        } else {
            const result = await addCategory({ body, index })
            ok = result?.ok
        }

        setUpdating(false)

        ok && dismiss?.()
    }

    return (
        <>
            <ModalHeading>
                <FormattedMessage
                    id={id
                        ? 'handbooks_category_action_edit'
                        : 'handbooks_category_action_add'
                    }
                    defaultMessage={id
                        ? 'Edit category'
                        : 'Add category'
                    } />
            </ModalHeading>
            <Form
                onSubmit={addOrUpdate}
                layout="vertical">
                {({ touched, errors, trigger }) => (
                    <>
                        <SymbolField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_icon',
                                defaultMessage: 'Icon'
                            })}
                            name="symbol"
                            field={{
                                value: symbol ?? blankSymbol,
                                include: 'always',
                                unsettable: false
                            }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="title"
                            field={{
                                value: title ?? '',
                                required: true,
                                include: 'touched'
                            }}
                            controlProps={{
                                autoFocus: !title,
                                placeholder: formatMessage({
                                    id: 'title_placeholder',
                                    defaultMessage: 'Add a title…'
                                })
                            }} />
                        <EditorField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_summary',
                                defaultMessage: 'Summary'
                            })}
                            name="summary"
                            field={{
                                value: fields.summary.current,
                                showCharacterCount: true
                            }}
                            controlProps={{
                                autoFocus: !!id && !!title,
                                max: 500
                            }}
                            editor={{
                                configure: {
                                    docType: 'summary'
                                },
                                preset: 'summary',
                                enabledExtensions: ['HardBreak']
                            }}
                            onChange={({ summary: doc }) => fields.summary.update(doc.content)} />
                        <UsefulLinks
                            links={fields.links.current}
                            mode="edit"
                            shouldAutoFocus={!!title}
                            onChange={fields.links.update}
                            salt={salt} />
                        <Message
                            className="compact preserve-line-breaks"
                            type="info"
                            message={formatMessage({
                                id: 'handbooks_category_message_tips',
                                defaultMessage: 'Keep the summary short and sweet. It’s supposed to act as an introduction to the category’s topics.'
                            })} />
                        <Actions>
                            <Plain
                                className="neutral"
                                onClick={dismiss}
                                disabled={updating}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${updating ? ' loading' : ''}`}
                                disabled={[
                                    (!touched.length && !map(fields, ({ changed }) => changed).some(Boolean)),
                                    !!size(errors),
                                    updating
                                ].some(Boolean)}
                                ref={trigger}>
                                <FormattedMessage
                                    id={id
                                        ? 'action_save'
                                        : 'action_add'
                                    }
                                    defaultMessage={id
                                        ? 'Save'
                                        : 'Add'
                                    } />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default EditHandbookCategory
