import React from 'react'
import { TooltipValue } from './s'
import { TooltipContainer, TooltipHeading, TooltipList, TooltipItem } from 'components/charts/s'

const CustomTooltip = ({ active, payload, label: heading, valueFormatter, salt }) => {
    if(!active) {
        return null
    }

    const items = payload.map(({ payload, value, dataKey, fill }) => ({
        value: valueFormatter(value ?
            value :
            payload[dataKey]),
        difference: !!payload.difference ?
            valueFormatter(payload.difference) :
            null,
        trend: payload.difference > 0 ?
            'up' :
            payload.difference < 0 ?
                'down' :
                null,
        color: fill ?
            fill :
            payload.fill
    }))

    return (
        <TooltipContainer>
            {!!heading && (
                <TooltipHeading>{heading}</TooltipHeading>
            )}
            <TooltipList>
                {items.map(({ value, difference, trend }) => (
                    <TooltipItem
                        className={trend}
                        key={`${salt}:tooltip:${value}:${difference}`}>
                        <TooltipValue>
                            {value}
                            {!!difference && <span>{difference}</span>}
                        </TooltipValue>
                    </TooltipItem>
                ))}
            </TooltipList>
        </TooltipContainer>
    )
}

export default CustomTooltip