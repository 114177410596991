import styled from 'styled-components'
import {
    svgStroke,
    belowLaptop
} from 'utilities/styled'
import { Benjamin as BenjaminBase } from 'components/button'
import { ContactSales as ContactSalesBase } from 'components/contact'
import LinkBase from 'components/link'
import { Grid } from 'components/grid'
import { buttonStyles } from 'components/button'

export const ContactSales = styled(ContactSalesBase)`
    margin-inline-end: 8px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    box-shadow: var(--huma-shadow-lowest);

    & > :not(hr) {
        margin-inline: 16px;
    }

    ${belowLaptop`
        margin-inline-end: 0;
        width: 92vw;
        max-width: 320px;
        padding: 0;
        box-shadow: none;

        & > :not(hr) {
            margin-inline: 24px;
        }
    `}
`

export const PromptLink = styled(LinkBase)`
    text-decoration: underline;
`

export const Benjamin = styled(BenjaminBase)`
    justify-content: center;

    margin-block-start: 8px;
    height: 32px;

    font-size: 14px;
`

export const Channels = styled(Grid)`
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    column-gap: 24px;

    svg {
        ${svgStroke()}

        margin-inline-end: 4px;
    }
`

export const ChannelLink = styled.a`
    ${buttonStyles.plainCss}

    justify-content: center;

    height: 24px;

    font-size: 14px;
    text-decoration: none;

    svg {
        ${svgStroke('small')}

        margin-inline-end: 8px;
    }
`