import styled from 'styled-components'
import { paragraphCss } from 'components/typography/paragraph'
import { CaptionSmall, captionCss } from 'components/typography/caption'
import { Value as LevelValue } from 'components/form/field/contractable-select/view/s'

export const Description = styled(CaptionSmall)`
    grid-area: description;
`

export const Wrapper = styled(LevelValue)`
    grid-template-rows: 24px;
    grid-template-columns: minmax(0, 1fr) min-content;
    grid-template-areas: "value number";
    column-gap: 24px;

    &:has(${Description}) {
        grid-template-rows: 24px 20px;
        grid-template-areas:
            "value number"
            "description number";
    }
`

export const Value = styled.p`
    &.in-grid-area {
        grid-area: value;
    }

    strong {
        ${paragraphCss}
    }

    span {
        ${captionCss}

        &::before {
            content: " ";
        }
    }
`

export const Number = styled.span`
    display: inline-grid;
    grid-area: number;
    justify-content: center;

    width: 24px;

    font-size: 20px;
    font-weight: 500;
`