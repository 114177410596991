import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { unique } from 'utilities/array'
import Message from 'components/message'
import EntityField from 'components/form/field/entity'

const BulkTopicsLanguage = ({ category, salt }) => {
    const { formatMessage } = useIntl()
    const { languages } = useI18n()

    const entities = Object.entries(languages)
        .map(([code, language]) => ({
            id: code,
            name: language
        }))

    const currentLanguages = unique(category?.topics?.map(({ language }) => language) ?? [])

    const picked = (currentLanguages.length === 1) ?
        entities.find(({ id }) => id === currentLanguages[0]) :
        null

    return (
        <>
            <Message
                type="info"
                message={formatMessage({
                    id: 'handbooks_info_edit_topics_language',
                    defaultMessage: '{count, plural, =0 {} =1 {Changing language for <strong>one topic<\/strong>} other {Changing language for <strong>{count} topics<\/strong>}}'
                }, { count: category.topics.length })} />
            <EntityField
                salt={salt}
                label={formatMessage({
                    id: 'noun_language',
                    defaultMessage: 'Language'
                })}
                name="language"
                field={{
                    value: picked,
                    required: true,
                    include: 'always'
                }}
                entity={{ type: 'language' }}
                picker={{
                    entities,
                    heading: formatMessage({
                        id: 'noun_language',
                        defaultMessage: 'Language'
                    }),
                    outer: false,
                    search: {
                        controlProps: {
                            placeholder: formatMessage({
                                id: 'action_language_find_options',
                                defaultMessage: 'Find language…'
                            })
                        }
                    },
                    type: 'language'
                }}
                controlProps={{ autoFocus: true }} />
        </>
    )
}

export default BulkTopicsLanguage