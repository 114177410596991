import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { MenuItemLink } from '../'

const moduleIdentifier = 'integrations'

const IntegrationsOverview = props => {
    const {
        initialized,

        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule('integrations') && check('organization:manage')

    return (
        <MenuItemLink
            {...props}
            to="integrations.base" end
            module={moduleIdentifier}
            access={access}
            upgradable={false}>
            <FormattedMessage
                id="noun_overview"
                defaultMessage="Overview" />
        </MenuItemLink>
    )
}

export default IntegrationsOverview