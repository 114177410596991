import { useEquipmentTypes } from 'contexts/equipment-types'
import { useEquipmentByType } from 'contexts/equipment-by-type'
import { useEquipmentType } from 'contexts/equipment-type'
import PubSub from 'pubsub-js'

export const useEquipmentContext = (context = 'types') => ({
    types: useEquipmentTypes,
    byType: useEquipmentByType,
    type: useEquipmentType
})[context]()

export const refreshEquipmentPieces = () => PubSub.publish('equipmentPieces.refresh')

export const refreshEquipmentByType = () => PubSub.publish('equipmentByType.refresh')