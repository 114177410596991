import React from 'react'
import { useAccess } from 'contexts/access'
import { Symbol, ProfileStatus, Heading, Description } from './s'
import { CloseButton } from 'components/button'
import TiptapOutput from 'components/tiptap/output'

const ViewCompetenceProfileHero = ({ profile, dismiss }) => {
    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const {
        symbol,
        name,
        description,
        public: isPublic = false
    } = profile ?? {}

    return (
        <div>
            <CloseButton onClick={dismiss} />
            <Symbol symbol={symbol} />
            {!!manageAccess && (
                <ProfileStatus status={isPublic ? 'public' : 'private'} />
            )}
            <Heading>{name}</Heading>
            {!!description && (
                <TiptapOutput
                    content={description}
                    element={Description} />
            )}
        </div>
    )
}

export default ViewCompetenceProfileHero