import styled from 'styled-components'
import { UnorderedList, ListItem } from 'components/list'
import { Plain } from 'components/button'
import SymbolBase from 'components/symbol'
import { siblingHover } from 'components/symbol/s'

export const CategoryList = styled(UnorderedList)`
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;

    padding-block: 16px;
`

export const Category = styled(ListItem)`
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr) min-content;
    align-items: center;
    column-gap: 16px;

    width: 100%;
`

export const PickButton = styled(Plain)`
    order: 3;
`

export const Meta = styled.div`
    display: flex;
    flex-direction: column;
`

export const Symbol = styled(SymbolBase)`
    ${siblingHover(PickButton)}
`