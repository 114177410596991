import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { capitalize } from 'utilities/string'
import { safeFormat } from 'utilities/date-time'
import {
    Clamp,
    Links, Chip, FavIcon, LinkName, LinkUrl,
    Label,
    ExpiryStatus
} from './s'
import ConcernsUnit from 'pages/tasks/components/concerns-unit'
import Ellipsify from 'components/ellipsify'
import Paragraph from 'components/typography/paragraph'
import { ExternalLink } from 'styled-icons/feather'

const ViewTaskDetails = ({ task }) => {
    const { dateLocale: locale } = useI18n()

    const {
        id,
        description,
        links = [],
        parent: group,
        dueAt,
        completedAt
    } = task

    return (
        <>
            {!!description && (
                <Clamp lines={4}>{description}</Clamp>
            )}
            {!!links?.length && (
                <Links>
                    {links.map(({ name, url }, index) => (
                        <Chip
                            key={`task:${id}:links:${name}:${index}`}
                            href={url}
                            target="_blank">
                            <FavIcon
                                size={24}
                                src={url} />
                            <LinkName className="compact">
                                <Ellipsify
                                    text={name}
                                    head={5} />
                            </LinkName>
                            <LinkUrl className="compact">
                                <Ellipsify
                                    text={url}
                                    head={9}
                                    tail={9} />
                            </LinkUrl>
                            <ExternalLink size={16} />
                        </Chip>
                    ))}
                </Links>
            )}
            {!!group && (
                <>
                    <Label>
                        <FormattedMessage
                            id="label_regarding"
                            defaultMessage="Regarding" />
                    </Label>
                    <Paragraph className="caption interpoint-divider">
                        <span>
                            <ConcernsUnit concerns={group.concerns} />
                        </span>
                        <span>
                            {group?.title}
                            {!group?.title && (
                                <FormattedMessage
                                    id={`noun_${group.type}`}
                                    defaultMessage={capitalize(group.type)} />
                            )}
                        </span>
                    </Paragraph>
                </>
            )}
            {!!dueAt && (
                <>
                    <Label>
                        <FormattedMessage
                            id="noun_due_date"
                            defaultMessage="Due date" />
                    </Label>
                    <Paragraph>
                        {safeFormat(dueAt, 'PPP', { locale })}
                    </Paragraph>
                    {!completedAt && <ExpiryStatus to={dueAt} />}
                </>
            )}
        </>
    )
}

export default ViewTaskDetails
