import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { size, map, omit } from 'utilities/object'
import { Summary, SummaryItem, ViewSummaryDetailsButton, InlineMessage } from './s'
import Form from 'components/form/controller'
import { ModalHeading } from 'components/typography/heading'
import Status from 'pages/handbook/components/status'
import CheckboxField from 'components/form/field/checkbox'
import TextField from 'components/form/field/text'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const PublishHandbook = ({ dismiss, onDone, setEditingOrder, salt }) => {
    const { formatMessage } = useIntl()

    const {
        handbook,
        updateHandbook,
        notify: sendNotification
    } = useHandbook()

    const [publishing, setPublishing] = useState(false)
    const [notify, setNotify] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')

    const { categories = [] } = handbook ?? {}

    const publish = async () => {
        setPublishing(true)

        let ok = true

        const { ok: updateOk } = await updateHandbook({ status: 'published' }, handbook.id)

        if(updateOk && notify) {
            const { ok: notifyOk } = await sendNotification(
                'handbook',
                notificationMessage,
                handbook.id
            )

            ok = notifyOk
        }

        setPublishing(false)

        ok && onDone?.(notify)
    }

    let groupedShares = categories
        .flatMap(({ topics }) => topics)
        .filter(({ status }) => status === 'published')
        .reduce((accumulator, topic) => {
            topic.shares.forEach(share => {
                const key = share.id ?? share.type

                if(accumulator[key]) {
                    accumulator[key].topicsCount++
                } else {
                    accumulator[key] = {
                        name: share.name ?? formatMessage({
                            id: 'noun_everyone',
                            defaultMessage: 'Everyone'
                        }),
                        topicsCount: 1
                    }
                }
            })

            return accumulator
        }, {})

    const draftsCount = categories
        .reduce((accumulator, category) => {
            const { topics = [] } = category

            return [
                ...accumulator,
                ...topics.filter(({ status }) => status === 'draft')
            ]
        }, [])
        .length

    const summary = ({ name, topicsCount }, index) => (
        <SummaryItem key={`${salt}:group:${name}:${index}`}>
            <FormattedMessage
                id="handbooks_topics_shared_with_count"
                defaultMessage="{count, plural, =0 {} =1 {1 topic shared with {groupName}} other {{count} topics shared with {groupName}}}"
                values={{
                    count: topicsCount,
                    groupName: name
                }} />
        </SummaryItem>
    )

    return (
        <Form
            layout="vertical"
            onSubmit={publish}>
            {({ errors, trigger }) => (
                <>
                    <ModalHeading>
                        <FormattedMessage
                            id="handbooks_action_publish"
                            defaultMessage="Publish handbook" />
                    </ModalHeading>
                    <Summary>
                        {groupedShares?.organization && summary(groupedShares.organization)}
                        {map(omit(groupedShares, 'organization'), summary)}
                        {!!draftsCount && (
                            <SummaryItem>
                                <InlineMessage
                                    className="compact"
                                    type="warning"
                                    message={formatMessage({
                                        id: 'handbooks_category_draft_topics',
                                        defaultMessage: '{count, plural, =0 {} =1 {1 topic in <status>Draft</status>} other {{count} topics in <status>Draft</status>}}'
                                    }, {
                                        count: draftsCount,
                                        status: chunks => <Status status="draft">{chunks}</Status>
                                    })}
                                    size="medium" />
                            </SummaryItem>
                        )}
                        {setEditingOrder && (
                            <SummaryItem>
                                <ViewSummaryDetailsButton
                                    className="constructive"
                                    onClick={() => setEditingOrder(true)}>
                                    <FormattedMessage
                                        id="action_view_details"
                                        defaultMessage="View details" />
                                </ViewSummaryDetailsButton>
                            </SummaryItem>
                        )}
                    </Summary>
                    <CheckboxField
                        salt={salt}
                        label={formatMessage({
                            id: 'notification_toggle_label',
                            defaultMessage: 'Send notification'
                        })}
                        name="notify"
                        field={{ value: notify }}
                        interaction="switch"
                        onChange={({ notify }) => setNotify(notify)} />
                    {!!notify && (
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'notification_message_label',
                                defaultMessage: 'Notification message'
                            })}
                            name="notificationMessage"
                            field={{
                                value: '',
                                required: true,
                                include: 'always',
                                showCharacterCount: true
                            }}
                            controlProps={{
                                placeholder: formatMessage({
                                    id: 'handbooks_placeholder_publish_notification',
                                    defaultMessage: 'The handbook is now published.'
                                }),
                                maxLength: 100
                            }}
                            onChange={({ notificationMessage }) => setNotificationMessage(notificationMessage)} />
                    )}
                    <Message
                        className="compact"
                        type="info"
                        message={formatMessage({
                            id: notify ?
                                'handbook_publish_message_notify' :
                                'handbook_publish_message',
                            defaultMessage: notify ?
                                'The handbook will be published and people will get access to the topics you have shared with them, as well as a notification with the message you have provided.' :
                                'The handbook will be published and people will get access to the topics you have shared with them.'
                        })} />
                    <Actions>
                        <Plain
                            onClick={dismiss}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${publishing ? ' loading' : ''}`}
                            disabled={publishing || size(errors)}
                            ref={trigger}>
                            <FormattedMessage
                                id={notify ?
                                    'action_publish_and_notify' :
                                    'action_publish'
                                }
                                defaultMessage={notify ?
                                    'Publish and notify' :
                                    'Publish'
                                } />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default PublishHandbook
