import styled from 'styled-components'
import { Rows as RowsBase, FlexChildShrink } from 'components/flex'
import { Plain } from 'components/button'
import MessageBase from 'components/message'
import ActionsBase from 'components/form/actions'

export const Rows = styled(RowsBase)`
    margin-block-start: 16px;
    border-radius: 8px;
    padding: 0 16px 16px;
    background-color: var(--huma-color-surface-minimal);

    &:empty {
        display: none;
    }
`

export const Row = styled(FlexChildShrink)`
    padding: 16px 0 0;
`

export const ShowDetailsButton = styled(Plain)`
    height: auto;
    font-size: 14px;
    line-height: 24px;
`

export const StackMessage = styled(MessageBase)`
    margin: 0 0 8px;
`

export const Actions = styled(ActionsBase)`
    padding: 8px 0 0;
`
