import React from 'react'
import loadable from '@loadable/component'
import { useStorage } from 'hooks/storage'
import { useHandbook } from 'contexts/handbook'
import paths from 'app/paths'
import { getPathWithoutBase } from 'components/tabs'
import { compact } from 'utilities/array'
import { Routes, Route, Outlet } from 'react-router-dom'
import { Handbook } from './s'
import StickyMenu from './sticky-menu'
import Hero from './pages/main/hero'
import Tutorial from 'components/tutorial'
import RouteAuthorization from 'app/route-authorization'
import DelayedRedirect from 'components/delayed-redirect'

import MainPage from './pages/main'
const CategoryPage = loadable(() => import(/* webpackPrefetch: true */ './pages/category'))
const SearchPage = loadable(() => import(/* webpackPrefetch: true */ './pages/search'))
const SetupPage = loadable(() => import(/* webpackPrefetch: true */ './pages/setup'))

const salt = 'handbook'

const HandbookRouting = ({ upgradable }) => {
    const { base } = paths.handbook
    const getRelativePath = getPathWithoutBase(base)

    const [expanded, setExpanded] = useStorage({
        key: `${salt}:categories:expanded`,
        defaultValue: true
    })

    const { handbook } = useHandbook()

    const pages = compact([
        {
            path: base,
            page: MainPage
        },
        {
            path: paths.handbook.category,
            page: CategoryPage
        },
        {
            path: paths.handbook.search,
            page: SearchPage
        }
    ])

    return (
        <Routes>
            <Route element={(
                <>
                    {!!handbook && <StickyMenu salt={salt} />}
                    <Handbook>
                        {!handbook && (
                            <>
                                <Hero
                                    upgradable={upgradable}
                                    salt={salt} />
                                <Tutorial
                                    which="handbook"
                                    {...(upgradable ? {
                                        upgrade: { module: 'handbook' }
                                    } : null)} />
                            </>
                        )}
                        {!!handbook && <Outlet />}
                    </Handbook>
                </>
            )}>
                {pages.map(({ path, page: Page }) => {
                    const key = `${salt}:page:${path.toLowerCase()}`

                    return (
                        <Route
                            path={getRelativePath(path)}
                            element={(
                                <RouteAuthorization module="handbook">
                                    <Page
                                        expanded={expanded}
                                        setExpanded={setExpanded}
                                        salt={salt}
                                        key={key} />
                                </RouteAuthorization>
                            )}
                            key={`${key}:route`} />
                    )
                })}
            </Route>
            <Route
                path={getRelativePath(paths.handbook.setup)}
                element={(
                    <RouteAuthorization module="handbook">
                        <SetupPage
                            expanded={expanded}
                            setExpanded={setExpanded}
                            salt={salt} />
                    </RouteAuthorization>
                )} />
            <Route
                path="*"
                element={<DelayedRedirect to={base} />} />
        </Routes>
    )
}

export default HandbookRouting