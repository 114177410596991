import React, { Component, createContext, createRef, useContext } from 'react'
import { get } from 'api'

export const SalaryRevisionGroupsContext = createContext()

export default class SalaryRevisionGroupsProvider extends Component {
    static contextType = SalaryRevisionGroupsContext

    constructor(props, context) {
        super(props, context)

        this.flash = createRef()

        this.state = {
            groups: [],
            total: 0,

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetchSalaryRevisionGroups: this.fetch,

            fetching: false,
            hasFetched: false
        }
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate(props, { filter }) {
        const filterChanged = filter !== this.state.filter

        if(filterChanged) {
            this.fetch()
        }
    }

    fetch = async () => {
        const {
            hasFetched,
            fetching,
            filter
        } = this.state

        if(hasFetched || fetching) {
            return
        }

        this.setState({ fetching: true })

        const { ok, response } = await get({
            path: '/salary/revision-groups',
            params: filter
        })

        if(ok && response) {
            this.setState({
                groups: response.items,
                total: response.total,
                fetching: false,
                hasFetched: true
            })
        } else {
            this.setState({
                fetching: false,
                hasFetched: true
            })
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <SalaryRevisionGroupsContext.Provider value={this.state}>
                {(typeof children === 'function') ? children(this.state) : children}
            </SalaryRevisionGroupsContext.Provider>
        )
    }
}

export const useSalaryRevisionGroups = () => useContext(SalaryRevisionGroupsContext)