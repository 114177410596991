import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useSalaries } from 'contexts/salaries'
import { format, isAfter, subDays } from 'date-fns'
import { isofy, safeTransform } from 'utilities/date-time'
import { Heading, Message } from './s'
import Timeline from 'components/timeline'
import SalaryRecord from '../../record'
import { Trash2 as Delete } from 'styled-icons/feather'

const SalaryConflicts = ({ adding, id, currentSalary, values = {} }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const {
        salaries = [],
        removeSalary,
        error,
        resetError
    } = useSalaries()

    const { id: currentId } = currentSalary ?? {}

    const removeAction = ({ id }) => ({
        salt: 'profile:salary:delete',
        icon: <Delete size={24} />,
        label: formatMessage({
            id: 'action_delete',
            defaultMessage: 'Delete'
        }),
        effect: 'destructive',
        onClick: () => {
            removeSalary(id)
            resetError()
        },
        prompt: {
            question: formatMessage({
                id: 'profile_salary_delete_prompt_question',
                defaultMessage: 'Confirm removing the salary'
            }),
            message: formatMessage({
                id: 'profile_salary_delete_prompt_message',
                defaultMessage: 'The salary data will be deleted. This can not be undone.'
            }),
            confirmText: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            })
        }
    })

    const actions = {
        remove: removeAction
    }

    // When there is an error
    if(!!error?.records?.length) {
        return (
            <>
                <Message
                    type="error"
                    message={formatMessage({
                        id: `salary_error_message_${error.errorType}`,
                        defaultMessage: error.errorMessage,
                    }, { count: error.records.length })} />
                <Heading>
                    <FormattedMessage
                        id="salary_label_conflicts"
                        defaultMessage="Conflicting salary records" />
                </Heading>
                <Timeline entries={error.records.map(salary => ({
                    ...salary,
                    actions,
                    content: <SalaryRecord {...salary} />
                }))} />
            </>
        )
    }

    const recordWithoutToDate = salaries.find(({ toDate }) => !toDate)

    // When the new salary record starts before the one with an open end date
    if(
        recordWithoutToDate &&
        (id !== recordWithoutToDate.id || adding) &&
        recordWithoutToDate.fromDate &&
        values.fromDate &&
        !values.toDate &&
        isAfter(isofy(values.fromDate), isofy(recordWithoutToDate.fromDate))
    ) {
        return (
            <Message
                type="info"
                message={formatMessage({
                    id: recordWithoutToDate.id === currentId ?
                        'salary_message_info_end_current_record' :
                        'salary_message_info_end_open_record',
                    defaultMessage: recordWithoutToDate.id === currentId ?
                        'This salary record will end the current salary record from {date}.' :
                        'This salary record will end the future open-ended salary record from {date}.',
                }, {
                    date: format(safeTransform(
                        values.fromDate,
                        subDays,
                        1
                    ), 'PP', { locale })
                })} />
        )
    }

    return null
}

export default SalaryConflicts
