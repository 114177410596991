import React, { useState, useEffect } from 'react'
import HandbookTemplateProvider, { useHandbookTemplate } from 'contexts/handbook-template'
import Hero from './hero'
import Details from './details'
import Form from 'components/form/controller'
import TemplateCategories from 'lists/handbook/template-categories'

const HandbookTemplatePreview = ({ dismiss, ...props }) => {
    const { template: handbookTemplate } = useHandbookTemplate()

    const [template, setTemplate] = useState({
        categories: []
    })

    useEffect(() => {
        if (!!handbookTemplate?.categories.length) {
            setTemplate({
                ...handbookTemplate,
                categories: handbookTemplate.categories.map(category => ({
                    ...category,
                    enabled: true,
                    expanded: false,
                    topics: category.topics.map(topic => ({
                        ...topic,
                        enabled: true,
                        expanded: false
                    }))
                }))
            })
        }

        return () => setTemplate({})
    }, [handbookTemplate?.categories.length])

    props = {
        ...props,
        template,
        setTemplate,
        dismiss
    }

    return (
        <>
            <Hero {...props} />
            <Details {...props} />
            <Form>
                <TemplateCategories {...props} />
            </Form>
        </>
    )
}

export default ({ context, ...template }) => {
    const templateProviderProps = template ?
        ('categories' in template) ?
            { template } :
            { id: template.id } :
        null

    return (
        <HandbookTemplateProvider {...templateProviderProps}>
            <HandbookTemplatePreview
                {...template}
                context={context} />
        </HandbookTemplateProvider>
    )
}
