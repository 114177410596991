import styled from 'styled-components'
import FilterBase from 'components/filter'
import { BasicRow, AdvancedRow } from 'components/filter/s'
import { Plain } from 'components/button'

export const Wrapper = styled.div`
    --__huma-component-back-width: 0;
    --__huma-component-filter-transition-duration: .2s;

    position: relative;

    display: grid;
    grid-template-rows:
        [filter-start basic back] auto
        [advanced] auto [filter-end];
    grid-template-columns:
        [filter-start back-start advanced-start] var(--__huma-component-back-width)
        [back-end basic-start] minmax(0, 1fr) [filter-end basic-end advanced-end];
    column-gap: 0;

    max-width: 360px;

    transition: all var(--__huma-component-filter-transition-duration) ease-in-out;

    &.show-back {
        --__huma-component-back-width: 40px;
        column-gap: 16px;
    }
`

export const BackButton = styled(Plain)`
    position: relative;
    isolation: isolate;
    z-index: 1;

    grid-area: back;

    width: 0;

    opacity: 0;
    transition: all var(--__huma-component-filter-transition-duration) ease-in-out;

    ${Wrapper}.show-back & {
        width: 40px;

        opacity: 1;
    }
`

export const Filter = styled(FilterBase)`
    display: grid;
    grid-template: subgrid / subgrid;
    grid-area: filter;

    margin: 0 !important;

    & > ${BasicRow} {
        grid-area: basic;
    }

    & > ${AdvancedRow} {
        grid-area: advanced;
    }

    ${Wrapper}.show-back & > ${BasicRow} {
        grid-column-start: 2;
    }
`