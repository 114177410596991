import React, { Component, createContext, createRef, useContext } from 'react'
import { usePerson } from 'contexts/person'
import { post, put, remove } from 'api'

const IdentificationsContext = createContext()

class IdentificationsProvider extends Component {
    constructor(props) {
        super(props)

        this.userId = props.userId
        this.flash = createRef()

        this.state = {
            identifications: props.person?.identifications?.value ?? [],
            ...this.getAccess(),

            flash: this.flash,
            clearFlash: this.clearFlash,

            addIdentification: this.add,
            updateIdentification: this.update,
            removeIdentification: this.remove,

            error: null,
            resetError: () => this.setError(null)
        }
    }

    componentDidUpdate(props, { viewable, editable }) {
        const access = this.getAccess()

        const viewableChanged = access?.viewable !== viewable
        const editableChanged = access?.editable !== editable

        if(viewableChanged || editableChanged) {
            this.setState(access)
        }
    }

    add = async body => {
        const { ok, response: identification } = await post({
            path: `/users/${this.userId}/identifications`,
            body
        })

        !ok && this.setError(identification)

        if(ok && identification) {
            this.flash.current = identification

            this.setState(({ identifications }) => ({
                identifications: [
                    identification,
                    ...identifications
                ]
            }))
        }

        return { ok, response: identification }
    }

    update = async (body, identificationId) => {
        const { ok, response: identification } = await put({
            path: `/users/${this.userId}/identifications/${identificationId}`,
            body
        })

        !ok && this.setError(identification)

        if(ok && identification) {
            this.setState(({ identifications }) => {
                const index = identifications.findIndex(({ id }) => id === identificationId)
                this.flash.current = identification

                return {
                    identifications: [
                        ...identifications.slice(0, index),
                        identification,
                        ...identifications.slice(index + 1, identifications.length)
                    ]
                }
            })
        }

        return { ok, response: identification }
    }

    remove = async identificationId => {
        const { ok } = await remove({
            path: `/users/${this.userId}/identifications/${identificationId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ identifications }) => {
                identifications = identifications.filter(({ id }) => id !== identificationId)
                return { identifications }
            })
        }

        return { ok }
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                errorCode,
                errorMessage,
                fields
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replace('identifier:', '').replaceAll('-', '_'),
                errorMessage,
                fields
            }
        }

        this.setState({ error })
    }

    clearFlash = () => this.setState(({ identifications }) => {
        this.flash.current = null

        return {
            identifications: [...identifications]
        }
    })

    getAccess = () => {
        const field = this.props.person?.identifications

        const {
            viewable = !!field,
            editable = false
        } = field ?? {}

        return {
            viewable,
            editable
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <IdentificationsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </IdentificationsContext.Provider>
        )
    }
}

export const useIdentifications = () => useContext(IdentificationsContext)

export default props => {
    const { person } = usePerson()

    return (
        <IdentificationsProvider
            {...props}
            person={person} />
    )
}