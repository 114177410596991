import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import {
    Hero,
    Menu, CloseButton,
    Heading
} from './s'
import { Button } from 'components/button'
import { X } from 'styled-icons/feather'

const EditOrderHero = ({ order, setOrder, updating, setUpdating, dismiss }) => {
    const { updateOrder } = useHandbook()

    const update = async () => {
        setUpdating(true)

        const { ok } = await updateOrder({
            categories: order.categories
        })

        if(ok) {
            setOrder(order => ({
                ...order,
                changed: false
            }))
        }

        setUpdating(false)
    }

    return (
        <Hero>
            <Menu>
                <CloseButton onClick={dismiss}>
                    <X size={24} />
                </CloseButton>
                <Button
                    className={`constructive${updating ? ' loading' : ''}`}
                    disabled={updating || !order.changed}
                    onClick={update}>
                    <FormattedMessage
                        id="action_update_order"
                        defaultMessage="Update order" />
                </Button>
            </Menu>
            <Heading>
                <FormattedMessage
                    id="action_edit_order"
                    defaultMessage="Edit order" />
            </Heading>
        </Hero>
    )
}

export default EditOrderHero
