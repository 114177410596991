export const splitName = (nameWithExtension = '') => {
    if(!nameWithExtension || typeof nameWithExtension !== 'string') {
        return null
    }

    let [, name, extension] = nameWithExtension.match(/^(.*?)(\.[^.]*)?$/)

    if(extension) {
        extension = extension.replace(/\./, '').toLowerCase()
    }

    return { name, extension }
}

export const normalizeName = (nameWithExtension = '') => {
    const { name, extension } = splitName(nameWithExtension)
    return `${name}.${extension}`
}

export const megabytesToBytes = mb => (mb * 1024 * 1024)
export const bytesToKilobytes = (b, decimals = 0) => (b / 1024).toFixed(decimals)
export const bytesToMegabytes = (b, decimals = 2) => (b / 1024 / 1024).toFixed(decimals)

export const image = [
    'apng',
    'bmp',
    'gif',
    'jpeg',
    'jpg',
    'png',
    'svg',
    'tiff',
    'webp',
    'heic'
]

export const audio = [
    'aac',
    'flac',
    'm4a',
    'mp3',
    'mp4',
    'oga',
    'wav',
    'wma'
]

export const video = [
    '3gp',
    'avi',
    'flv',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpeg',
    'mpg',
    'ogv',
    'webm',
    'wmv'
]

export const professional = [
    'ai',
    'eps',
    'indd',
    'ps',
    'psd'
]

export const presentation = [
    'key',
    'odp',
    'otp',
    'potx',
    'ppsx',
    'ppt',
    'pptx',
    'sldx'
]

export const spreadsheet = [
    'numbers',
    'ods',
    'ots',
    'xls',
    'xlsx',
    'xltx'
]

export const richtext = [
    'pages',
    'doc',
    'docx',
    'dotx',
    'odt',
    'ott'
]

export const archive = [
    '7z',
    'gzip',
    'rar',
    'zip'
]

export const plaintext = [
    'txt',
    'csv',
    'md'
]

export const extensionsByMime = {
    // Image types
    'image/apng': ['.apng'],
    'image/bmp': ['.bmp'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/svg+xml': ['.svg'],
    'image/tiff': ['.tiff'],
    'image/webp': ['.webp'],
    'image/heic': ['.heic'],

    // Audio types
    'audio/aac': ['.aac'],
    'audio/x-flac': ['.flac'],
    'audio/x-m4a': ['.m4a'],
    'audio/mp3': ['.mp3'],
    'audio/mp4': ['.mp4'],
    'audio/ogg': ['.oga'],
    'audio/wav': ['.wav'],
    'audio/x-ms-wma': ['.wma'],

    // Video types
    'video/3gpp': ['.3gp'],
    'video/x-msvideo': ['.avi'],
    'video/x-flv': ['.flv'],
    'video/x-m4v': ['.m4v'],
    'video/x-matroska': ['.mkv'],
    'video/quicktime': ['.mov'],
    'video/mp4': ['.mp4'],
    'video/mpeg': ['.mpg', '.mpeg'],
    'video/ogg': ['.ogv'],
    'video/webm': ['.webm'],
    'video/x-ms-wmv': ['.wmv'],

    // Professional types
    'application/postscript': ['.ai', '.eps', '.ps'],
    'application/illustrator': ['.ai'],
    'application/x-illustrator': ['.ai'],
    'application/vnd.adobe.illustrator': ['.ai'],
    'application/eps': ['.eps'],
    'application/indesign': ['.indd'],
    'application/x-indesign': ['.indd'],
    'application/vnd.adobe-indesign': ['.indd'],

    // Presentation types
    'application/vnd.apple.keynote': ['.key'],
    'application/vnd.oasis.opendocument.presentation': ['.odp'],
    'application/vnd.oasis.opendocument.presentation-template': ['.otp'],
    'application/vnd.openxmlformats-officedocument.presentationml.template': ['.potx'],
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.presentationml.slide': ['.sldx'],

    // Spreadsheet types
    'application/vnd.apple.numbers': ['.numbers'],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/vnd.oasis.opendocument.spreadsheet-template': ['.ots'],
    'application/vnd.ms-spreadsheetml': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': ['.xltx'],

    // Rich text types
    'application/vnd.apple.pages': ['.pages'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': ['.dotx'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.oasis.opendocument.text-template': ['.ott'],

    // Plain text types
    'text/plain': ['.txt'],
    'text/csv': ['.csv'],
    'text/markdown': ['.md'],
    'text/x-web-markdown': ['.md'],

    // Archive types
    'application/zip': ['.zip'],
    'application/gzip': ['.gzip'],
    'application/x-rar-compressed': ['.rar'],
    'application/x-7z-compressed': ['.7z'],

    // Miscellanous types
    'application/pdf': ['.pdf']
}