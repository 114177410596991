import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'

export const HeroColumns = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    ${belowTablet`
        grid-auto-flow: row;
        row-gap: 8px;
    `}
`