import { useState, useRef, useLayoutEffect } from 'react'

export const useReveal = (obscure = false, wait = 2500) => {
    const node = useRef()
    const timeout = useRef(null)
    const [reveal, setReveal] = useState(false)

    useLayoutEffect(() => {
        if(obscure) {
            node.current?.addEventListener('click', onClick)
            node.current?.addEventListener('mouseleave', onMouseLeave)
            node.current?.addEventListener('mouseenter', onMouseEnter)

            return () => {
                node.current?.removeEventListener('click', onClick)
                node.current?.removeEventListener('mouseleave', onMouseLeave)
                node.current?.removeEventListener('mouseenter', onMouseEnter)
            }
        }
    }, [obscure, node, wait])

    const onClick = () => setReveal(!reveal)

    const onMouseLeave = () => {
        timeout.current = global.setTimeout(() => {
            timeout.current = null
            setReveal(false)
        }, wait)
    }

    const onMouseEnter = () => {
        if(timeout.current) {
            global.clearTimeout(timeout.current)
        }
    }

    return [node, reveal]
}
