import React from 'react'
import styled from 'styled-components'
import { belowLaptop } from 'utilities/styled'
import { DragIndicator } from 'styled-icons/material-rounded'

const Wrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: 20px;
    left: -40px;

    display: grid;
    place-items: center;

    width: 40px;
    height: 32px;

    color: var(--huma-color-foreground-minimal);

    ${belowLaptop`
        left: -16px;
    `}
`

const DragHandle = () => (
    <Wrapper>
        <DragIndicator size={24} />
    </Wrapper>
)


export default DragHandle
