import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useLogin } from 'contexts/login'
import { usePageView } from 'hooks/page-view'
import { useTimeout } from 'hooks/timeout'
import { FlexChildShrink } from 'components/flex'
import { Step, Rows, Heading, Caption, Actions, LoaderWrap } from '../s'
import Form from 'components/form/controller'
import { Retry } from './s'
import VerificationCodeField from 'components/form/field/verification-code'
import Link from 'components/link'
import Loader from 'components/loader'
import { ButtonSubmit, BackButton } from 'components/button'
import { size } from 'utilities/object'
import { formatPhoneNumber } from 'react-phone-number-input'

const ThreeVerify = ({ show, ...props }) => {
    const { formatMessage } = useIntl()

    usePageView('login_verify_code', [show])

    const [revealRetry, setRevealRetry] = useState(false)
    useTimeout(() => setRevealRetry(true), 10000)

    const {
        otp,
        finalizing,

        finalizeWithInternalOtpProvider,
        username,
        tokenFailed,
        goToFirstStep,
        goToPreviousStep
    } = useLogin()

    const salt = 'login:3:verify'

    return (
        <Step
            {...props}
            show={show}>
            <Form
                layout="vertical"
                onSubmit={finalizeWithInternalOtpProvider}>
                {({ errors, trigger }) => (
                    <Rows>
                        {!!finalizing && (
                            <LoaderWrap>
                                <Loader />
                            </LoaderWrap>
                        )}
                        {(!!size(otp) && !finalizing) && (
                            <FlexChildShrink>
                                <Heading>
                                    <FormattedMessage
                                        id={`signup_three_heading_${otp.method}`}
                                        defaultMessage={`Check your ${otp.method}`} />
                                </Heading>
                                <Caption>
                                    <FormattedMessage
                                        id="signup_three_caption"
                                        defaultMessage={`Enter the code we just sent to {recipient}.`}
                                        values={{
                                            recipient: (
                                                <strong>
                                                    {otp.method === 'phone' ?
                                                        formatPhoneNumber(otp[otp.method]) :
                                                        otp[otp.method]
                                                    }
                                                </strong>
                                            )
                                        }} />
                                </Caption>
                                <VerificationCodeField
                                    salt={salt}
                                    label={false}
                                    name="code"
                                    controlProps={{ autoFocus: true }} />
                                <Retry {...(revealRetry ? { className: 'reveal' } : null)}>
                                    <FormattedMessage
                                        id="signup_three_retry"
                                        defaultMessage="Didn’t receive a code? {link}."
                                        values={{
                                            link: (
                                                <Link
                                                    onClick={goToPreviousStep}
                                                    className="constructive">
                                                    <FormattedMessage
                                                        id={`signup_three_retry_reenter_${otp.method}`}
                                                        defaultMessage={`Please re-enter your ${otp.method === 'phone' ? 'phone number' : 'email address'}`} />
                                                </Link>
                                            )
                                        }} />
                                </Retry>
                            </FlexChildShrink>
                        )}
                        {!!size(otp) && (
                            <FlexChildShrink>
                                <Actions className="spread">
                                    <BackButton
                                        onClick={goToPreviousStep}
                                        text={formatMessage({
                                            id: 'action_go_back',
                                            defaultMessage: 'Go back'
                                        })} />
                                    <ButtonSubmit
                                        className="constructive"
                                        disabled={!!size(errors) || finalizing}
                                        ref={trigger}>
                                        <FormattedMessage
                                            id="action_login"
                                            defaultMessage="Log in" />
                                    </ButtonSubmit>
                                </Actions>
                            </FlexChildShrink>
                        )}
                        {(!finalizing && !size(otp) && tokenFailed) && (
                            <>
                                <FlexChildShrink>
                                    <Heading>
                                        <FormattedMessage
                                            id="signup_three_heading_error_user_not_found"
                                            defaultMessage="Sorry, user not found" />
                                    </Heading>
                                    <Caption>
                                        <FormattedMessage
                                            id="signup_three_caption_error_user_not_found"
                                            defaultMessage="It doesn't look like the user {email} is a part of this organization. Please make sure that the email address and organization are provided correctly and then try again, or contact your Huma administrator."
                                            values={{
                                                email: (
                                                    <strong>{username}</strong>
                                                )
                                            }} />
                                    </Caption>
                                </FlexChildShrink>
                                <FlexChildShrink>
                                    <Actions className="start">
                                        <BackButton
                                            onClick={goToFirstStep}
                                            text={formatMessage({
                                                id: 'action_go_back',
                                                defaultMessage: 'Go back'
                                            })} />
                                    </Actions>
                                </FlexChildShrink>
                            </>
                        )}
                    </Rows>
                )}
            </Form>
        </Step>
    )
}

export default ThreeVerify