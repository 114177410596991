import React, { Component, createContext, useContext } from 'react'
import { get, patch } from 'api'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { useEnvironment } from 'contexts/environment'
import { unique, compact } from 'utilities/array'
import { reduce, pick, omit, size } from 'utilities/object'
import { identifyAndSend } from 'utilities/hubspot'
import { local } from 'utilities/storage'
import { isWithinIntervalPermissive, isAfterInterval } from 'utilities/date-time'
import Link from 'components/link'
import {
    Target as Survey,
    MessageCircle as Announcement,
} from 'styled-icons/feather'
import { NewReleases as Release } from 'styled-icons/material'

export const ServiceOnboardingContext = createContext()

class ServiceOnboardingProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stepStatuses: {},
            stepDefinitions: [],
            categories: [],
            steps: [],
            todos: [],
            progress: 0,
            seconds: 0,
            onboarder: false,
            completed: false, // Should only be set to true once, the first time all steps are skipped/completed

            updateOnboardingStatus: this.updateOnboardingStatus,
            resetOnboardingStatus: this.resetOnboardingStatus,
            checkOnboardingStepCompleted: this.checkOnboardingStepCompleted,
            skipAllTodoSteps: this.skipAllOnboardingTodoSteps,

            tutorials: {},

            originalAnnouncements: [],
            announcements: []
        }
    }

    componentDidMount() {
        !!this.props.access?.initialized && this.fetchSteps()

        this.fetchTutorials()

        if(!!this.props.access?.initialized && !!this.props.organization?.statuses) {
            this.fetchAnnouncements()
        }
    }

    componentDidUpdate({ access, organization, locale }) {
        const hadAccess = !!access?.initialized
        const hasAccess = !!this.props.access?.initialized

        const hadOrganizationStatuses = !!organization?.statuses
        const hasOrganizationStatuses = !!this.props.organization?.statuses

        if(hasAccess) {
            if(!hadAccess) {
                this.fetchSteps()
            }

            if(hasOrganizationStatuses) {
                if(!hadAccess || !hadOrganizationStatuses) {
                    this.fetchAnnouncements()
                }

                if(locale !== this.props.locale && !!this.state.announcements.length) {
                    this.refreshAnnouncements()
                }
            }
        }
    }

    fetchSteps = async () => {
        const [
            { ok: stepStatusesOk, response: stepStatuses },
            { ok: stepsOk, response: steps }
        ] = await Promise.all([
            get({ path: '/service-onboarding' }),
            this.props.fetchFromS3('/service-onboarding/steps.json')
        ])

        const ok = stepStatusesOk && stepsOk

        if(ok) {
            this.setState({
                ...getOnboardingState(steps, stepStatuses, this.props.access),
                stepDefinitions: steps,
                onboarder: true
            })//, this.resetOnboardingStatus)
        }
    }

    updateOnboardingStatus = async body => {
        if(!this.state.onboarder) {
            return {
                ok: false,
                response: null
            }
        }

        const recognizedKeysAsSteps = Object.entries(this.state.stepDefinitions?.recognizedKeys ?? {}).map(([key, scope]) => ({
            affects: [key],
            scope
        }))

        const steps = [
            ...this.state.steps,
            ...recognizedKeysAsSteps
        ]

        const allStatuses = steps.reduce((accumulator, { persistedBy, affects, status = null }) => {
            if(status === 'todo') {
                status = null
            }

            affects = compact([
                ...affects,
                persistedBy
            ])

            return {
                ...accumulator,
                ...affects.reduce((accumulator, action) => ({
                    ...accumulator,
                    [action]: status
                }), {})
            }
        }, {})

        const affected = pick(allStatuses, ...Object.keys(body))

        const user = steps.reduce((accumulator, { scope, persistedBy, affects }) => ({
            ...accumulator,
            ...reduce(affected, (accumulator, currentStatus, key) => {
                if(persistedBy !== key && !affects.includes(key)) {
                    return accumulator
                }

                const status = body[key]

                // If the status is null, we patch to both user and organization
                if(status === null) {
                    return {
                        ...accumulator,
                        [key]: status
                    }
                }

                scope = (scope === 'organization' && status === 'skipped') ?
                    'user' :
                    scope

                if(scope !== 'user' || currentStatus === status) {
                    return accumulator
                }

                return {
                    ...accumulator,
                    [key]: status
                }
            })
        }), {})

        const organization = steps.reduce((accumulator, { scope, persistedBy, affects }) => ({
            ...accumulator,
            ...reduce(affected, (accumulator, currentStatus, key) => {
                if(persistedBy !== key && !affects.includes(key)) {
                    return accumulator
                }

                const status = body[key]

                // If the status is null, we patch to both user and organization
                if(status === null) {
                    return {
                        ...accumulator,
                        [key]: status
                    }
                }

                if(scope !== 'organization' || status === 'skipped') {
                    return accumulator
                }

                if(currentStatus === status) {
                    return accumulator
                }

                return {
                    ...accumulator,
                    [key]: status
                }
            })
        }), {})

        if(!size(user) && !size(organization)) {
            return {
                ok: true,
                response: allStatuses
            }
        }

        let payload = {
            ...(!!size(user) ? { user } : null),
            ...(!!size(organization) ? { organization } : null)
        }

        let completing = false

        if(this.state.stepStatuses?.serviceOnboarding !== 'completed') {
            completing = !this.state.todos.filter(({ affects }) => {
                const userCompleted = affects.some(key => ['skipped', 'completed'].includes(user[key]))
                const organizationCompleted = affects.some(key => ['skipped', 'completed'].includes(organization[key]))

                return !userCompleted && !organizationCompleted
            }).length
        }

        if(completing) {
            if(payload.user) {
                payload.user.serviceOnboarding = 'completed'
            } else {
                payload.user = {
                    serviceOnboarding: 'completed'
                }
            }
        }

        const { ok, response } = await patch({
            path: '/service-onboarding',
            body: payload
        })

        if(ok && response) {
            this.setState(({ stepDefinitions }) => ({
                ...getOnboardingState(stepDefinitions, response, this.props.access),
                ...(completing ? { completed: true } : null)
            }))

            if(this.props.integrations.enableHubspotContactRegistration) {
                identifyAndSend({
                    ...reduce(body, (accumulator, value, key) => ({
                        ...accumulator,
                        [`huma_webclient_${key.toLowerCase()}`]: value
                    })),
                    salt: Date.now()
                })
            }
        }

        return { ok, response }
    }

    setOnboardingStatusForKeys = (keys = [], status) => this.updateOnboardingStatus(
        keys.reduce((accumulator, key) => ({
            ...accumulator,
            [key]: status
        }), {})
    )

    resetOnboardingStatus = async () => {
        const statusKeys = Object.keys(this.state.stepStatuses)
        return await this.setOnboardingStatusForKeys(statusKeys, null)
    }

    checkOnboardingStepCompleted = (key, strict = false) => {
        if(!(key in this.state.stepStatuses)) {
            return false
        }

        const status = this.state.stepStatuses[key]

        if(strict) {
            return status === 'completed'
        }

        return ['skipped', 'completed'].includes(status)
    }

    skipAllOnboardingTodoSteps = async () => {
        const statusKeys = this.state.steps
            .filter(({ status }) => status === 'todo')
            .flatMap(({ persistedBy, affects }) => persistedBy ?
                [persistedBy] :
                affects
            )

        return await this.setOnboardingStatusForKeys(statusKeys, 'skipped')
    }

    fetchTutorials = async () => {
        const { ok, response: tutorials } = await this.props.fetchFromS3('/service-onboarding/tutorials.json')
        !!ok && this.setState({ tutorials })
    }

    fetchAnnouncements = async () => {
        const { ok, response } = await this.props.fetchFromS3('/service-onboarding/announcements.json')

        if(ok) {
            this.setState({
                originalAnnouncements: response,
                announcements: this.enrichAnnouncements(response)
            })
        }
    }

    refreshAnnouncements = () => this.setState({
        announcements: this.enrichAnnouncements(this.state.originalAnnouncements)
    })

    enrichAnnouncements = announcements => enrichAnnouncements({
        announcements,
        dismissAnnouncement: this.dismissAnnouncement,
        ...this.props.access,
        statuses: this.props.organization.statuses,
        locale: this.props.locale,
        formatMessage: this.props.formatMessage
    })

    dismissAnnouncement = id => {
        local.set(getAnnouncementDismissedKey(id), true)

        this.setState(({ announcements }) => {
            const index = announcements.findIndex(announcement => announcement.id === id)

            return {
                announcements: [
                    ...announcements.slice(0, index),
                    {
                        ...announcements[index],
                        meta: {
                            ...(announcements[index]?.meta ?? null),
                            dismissed: true
                        }
                    },
                    ...announcements.slice(index + 1, announcements.length)
                ]
            }
        })
    }

    render() {
        const { children = null } = this.props

        return (
            <ServiceOnboardingContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ServiceOnboardingContext.Provider>
        )
    }
}

const getOnboardingState = (stepDefinitions, stepStatuses, { track, checkModule, checkFeature, check }) => {
    const statusLadder = ['todo', 'skipped', 'completed']

    const predefinedStatuses = stepDefinitions?.steps?.reduce((accumulator, { key, status }) => {
        if(!status || !statusLadder.includes(status)) {
            return accumulator
        }

        return {
            ...accumulator,
            [key]: status
        }
    }, {})

    stepStatuses = {
        ...predefinedStatuses,
        ...(stepStatuses?.user?.statuses ?? null),

        // The organization statuses object will sometimes contain the same keys as
        // the user statuses object. When this happens, the organization statuses have
        // always progressed further, ie. user: skipped but organization: completed.
        // This happens when one admin skips, and another completes organization-wide.
        // It is thus important that the organization statuses object is merged last.
        ...(stepStatuses?.organization?.statuses ?? null)
    }

    const checkAccess = ({ hidden, access }) => ![
        hidden,
        !!access?.clients?.length && !access?.clients?.includes('web'),
        !!access?.tracks?.length && !access?.tracks?.includes(track),
        !!access?.modules?.length && !access?.modules?.some(module => checkModule(module)),
        !!access?.features?.length && !access?.features?.some(feature => checkFeature(feature)),
        !!access?.module && !checkModule(access.module),
        !!access?.feature && !checkFeature(access.feature),
        !!access?.permissions?.length && !access?.permissions?.some(permission => check(permission))
    ].some(Boolean)

    const steps = stepDefinitions?.steps

        // Remove
        // - steps that are set as hidden
        // - steps not belonging to the current track
        // - steps belonging to modules and features not included in the current subscription
        // - steps the current user doesn’t have permission to access
        ?.filter(checkAccess)

        // Combine the current state from the API with the current content from S3
        ?.map(({ completedBy, actions, ...step }) => {
            let status = stepStatuses[step.key] ?? 'todo'
            let affects = [step.key]

            if(completedBy) {
                const {
                    keys,
                    method = 'or'
                } = completedBy

                const getStatusesByKeys = keys => keys
                    .map(key => stepStatuses[key] ?? 'todo')
                    .sort((one, two) => statusLadder.indexOf(one) - statusLadder.indexOf(two))

                if(method === 'or') {
                    [status] = unique(getStatusesByKeys([step.key, ...keys])).reverse()
                } else if(method === 'and') {
                    [status] = unique(getStatusesByKeys(keys))
                }

                affects = affects.concat(keys)
            }

            if(!!actions?.length) {
                actions = actions
                    .filter(checkAccess)
                    .map(action => omit(action, 'access'))
            }

            return {
                ...omit(step, 'access'),
                status,
                affects,
                ...(!!actions?.length ? { actions } : null)
            }
        })

        // Sort by status
        // ?.sort(({ status: one }, { status: two }) => statusLadder.indexOf(one) - statusLadder.indexOf(two))

    const stepsTodo = steps.filter(({ status }) => !['skipped', 'completed'].includes(status))

    const categories = reduce(stepDefinitions?.categories, (accumulator, category, id) => {
        const categorySteps = steps.filter(({ category }) => category === id)
        if(!categorySteps.length) {
            return accumulator
        }

        const categoryStepsTodo = categorySteps.filter(({ status }) => !['skipped', 'completed'].includes(status))

        return [
            ...accumulator,
            {
                ...category,
                id,
                steps: categorySteps,
                todos: categoryStepsTodo,
                progress: parseInt(100 - (categoryStepsTodo.length / categorySteps.length * 100), 10),
                seconds: categoryStepsTodo.reduce((accumulator, { seconds }) => accumulator + (seconds ?? 0), 0)
            }
        ]
    }, [])

    return {
        stepStatuses,
        categories,
        steps,
        todos: stepsTodo,
        progress: parseInt(100 - (stepsTodo.length / steps.length * 100), 10),
        seconds: stepsTodo.reduce((accumulator, { seconds }) => accumulator + (seconds ?? 0), 0)
    }
}

const getAnnouncementDismissedKey = id => `announcement:${id}:dismissed`

const enrichAnnouncements = ({
    announcements, dismissAnnouncement,
    track, checkModule, checkFeature, check, statuses,
    locale, formatMessage
}) => announcements
    // Remove
    // - announcements that are set as hidden
    // - when the current time is outside the announcement’s defined interval
    // - announcements not matching the current track
    // - announcements about modules and features not included in the current subscription
    // - announcements about modules and features the current user doesn’t have permission to access
    .filter(({ hidden, access }) => ![
        hidden,
        !!access?.tracks?.length && !access?.tracks?.includes(track),
        !!access?.modules?.length && !access?.modules?.some(module => checkModule(module)),
        !!access?.features?.length && !access?.features?.some(feature => checkFeature(feature)),
        !!access?.module && !checkModule(access.module),
        !!access?.feature && !checkFeature(access.feature),
        !!access?.permissions?.length && !access?.permissions?.some(permission => check(permission)),
        !!access?.statuses?.length && !access?.statuses?.every(status => statuses[status]),
        !!access?.locales?.length && !access?.locales?.includes(locale)
    ].some(Boolean))
    .map(announcement => {
        const {
            id,
            banner,
            type,
            content,
            icon,
            period,
            expires = true
        } = announcement

        const dismissed = !!local.get(getAnnouncementDismissedKey(id))

        const {
            heading,
            message,
            url
        } = {
            ...content.en,
            ...content[locale]
        }

        const formattedMessage = formatMessage({
            id: `announcement_${id}`,
            defaultMessage: message
        }, {
            link: chunks => (
                <Link
                    href={url}
                    target="_blank">
                    {chunks}
                </Link>
            )
        })

        const Icon = {
            survey: Survey,
            release: Release
        }[icon] ?? Announcement

        const enriched = {
            id,
            type: validateType(type),
            message: formattedMessage,
            heading,
            icon: Icon,
            meta: {
                banner,
                expires,
                current: isWithinIntervalPermissive(period),
                past: isAfterInterval(period),
                dismissed
            }
        }

        if(!!banner && !dismissed) {
            enriched.dismiss = () => dismissAnnouncement(id)
        }

        return enriched
    })

const validateType = type => {
    if(['error', 'warning', 'info', 'success'].includes(type)) {
        return type
    }

    return 'info'
}

export const useServiceOnboarding = () => useContext(ServiceOnboardingContext)

export default props => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()
    const access = useAccess()
    const organization = useOrganization()

    const {
        integrations,
        fetchFromS3
     } = useEnvironment()

    return (
        <ServiceOnboardingProvider
            {...props}
            formatMessage={formatMessage}
            locale={locale}
            access={access}
            organization={organization}
            fetchFromS3={fetchFromS3}
            integrations={integrations} />
    )
}