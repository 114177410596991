import { useLayoutEffect } from 'react'
import PubSub from 'pubsub-js'

export const useAppSignal = ({ namespace, action }) => useLayoutEffect(() => {
    let subscription

    if(namespace && typeof action === 'function') {
        subscription = PubSub.subscribe(namespace, (_, ...args) => action(...args))
    }

    return () => {
        !!subscription && PubSub.unsubscribe(subscription)
    }
}, [namespace, action])

export const sendAppSignal = PubSub.publish