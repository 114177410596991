import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { anyFieldsDisplayable, anyFieldsEditable, fieldDisplayable } from 'utilities/access'
import { omit, filter, size } from 'utilities/object'
import { SectionHero, isFieldImportant } from './'
import { Container, Subheading, Spacer, Helper, Error, FlushedSwitch } from './s'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import CountryField from 'components/form/field/country'
import { ButtonSubmit } from 'components/button'
import { unpackObjectField } from 'utilities/person'
import { electronicFormatIBAN, isValidIBAN, isValidBIC } from 'ibantools'

const FinancialSection = ({ lookingAtMyOwnProfile, layout, salt }) => {
    const { formatMessage } = useIntl()

    const {
        person,
        updatePerson
    } = usePerson()

    const {
        bankAccount,
        homeAddress
    } = person

    const homeAddressCountry = unpackObjectField(homeAddress ?? {})?.country

    const [editing, setEditing] = useState(false)
    const [saving, setSaving] = useState(false)
    const [customErrors, setCustomErrors] = useState(null)

    const getField = field => unpackObjectField(bankAccount ?? {})?.[field] ?? null

    const getCountryValue = () => {
        const country = getField('country')

        if(country) {
            return country
        }

        if(bankAccount?.editable && editing && homeAddressCountry) {
            return homeAddressCountry
        }

        return null
    }

    const getInternational = () => ({
        value: getField('type') === 'international',
        changed: false
    })

    const getNumber = () => ({
        value: getField('number'),
        changed: false
    })

    const getCountry = () => ({
        value: getCountryValue(),
        changed: false
    })

    const getIban = () => ({
        value: getField('iban'),
        changed: false
    })

    const getBic = () => ({
        value: getField('bic'),
        changed: false
    })

    const [international, setInternational] = useState(getInternational())
    const [number, setNumber] = useState(getNumber())
    const [country, setCountry] = useState(getCountry())
    const [iban, setIban] = useState(getIban())
    const [bic, setBic] = useState(getBic())

    const validations = {
        missing: {
            number: formatMessage({
                id: 'financial_error_missing_number',
                defaultMessage: 'Missing bank account number'
            }),
            country: formatMessage({
                id: 'financial_error_missing_country',
                defaultMessage: 'Missing country'
            }),
            iban: formatMessage({
                id: 'financial_error_missing_iban',
                defaultMessage: 'Missing IBAN'
            }),
            bic: formatMessage({
                id: 'financial_error_missing_bic',
                defaultMessage: 'Missing SWIFT/BIC'
            })
        },
        invalid: {
            iban: formatMessage({
                id: 'financial_error_iban_invalid',
                defaultMessage: 'Invalid IBAN'
            }),
            bic: formatMessage({
                id: 'financial_error_bic_invalid',
                defaultMessage: 'Invalid SWIFT/BIC'
            })
        }
    }

    useEffect(() => {
        setCountry(country => ({
            ...country,
            value: getCountryValue(),
            ...(!editing ? { changed: false } : null)
        }))

        if(!editing) {
            setCustomErrors(null)

            setInternational(getInternational())
            setNumber(getNumber())
            setCountry(getCountry())
            setIban(getIban())
            setBic(getBic())
        }
    }, [editing])

    useEffect(() => {
        setInternational(international => ({
            ...international,
            value: getField('type') === 'international'
        }))
    }, [getField('type')])

    const update = async body => {
        const type = international.value ? 'international' : 'national'

        let bankAccount = {
            ...getDefaultsForType(type),
            ...omit(body, 'international'),
            type
        }

        const missingFields = Object.keys(filter(bankAccount, value => !value))

        if(missingFields.length === 0 && type === 'international') {
            const validIban = isValidIBAN(electronicFormatIBAN(iban.value))
            const validBic = isValidBIC(bic.value)

            if(!validIban || !validBic) {
                setCustomErrors({
                    ...(!validIban ? { iban: validations.invalid.iban } : null),
                    ...(!validBic ? { bic: validations.invalid.bic } : null)
                })

                return
            }
        }

        if(missingFields.length === 1) {
            const [field] = missingFields

            // Only the Bank Country field is set. Don’t force the user to clear the
            // value – assume their intention is to clear the values and let them move on
            if(field === 'number') {
                bankAccount = null
                setCustomErrors(null)
            } else {
                setCustomErrors({ [field]: validations.missing[field] })
                return
            }
        }

        if(missingFields.length === 2) {
            bankAccount = null
            setCustomErrors(null)
        }

        setSaving(true)
        const { ok, response } = await updatePerson({ bankAccount })
        setSaving(false)

        if(ok) {
            if(!bankAccount) {
                setInternational(false)
            }

            setEditing(false)
        } else {
            if(!international.value && response?.errorCode === 'field:invalid-value') {
                if(country.value === 'NO') {
                    setCustomErrors({
                        number: formatMessage({
                            id: 'financial_error_integration_norwegian_accountnumber',
                            defaultMessage: 'Invalid Norwegian bank account number'
                        })
                    })
                }

                if(country.value === 'SE') {
                    setCustomErrors({
                        number: formatMessage({
                            id: 'financial_error_integration_swedish_accountnumber',
                            defaultMessage: 'Invalid Swedish bank account number'
                        })
                    })
                }
            } else if(international.value && response?.errorCode === 'field:invalid-value') {
                const [name] = (response?.fields ?? [])

                if(['iban', 'bic'].includes(name)) {
                    setCustomErrors({ [name]: validations.invalid[name] })
                }
            }
        }
    }

    const removeCustomError = key => setCustomErrors(errors => {
        if(errors) {
            return omit(errors, key)
        }

        return errors
    })

    const fields = [
        bankAccount
    ]

    const anyDisplayable = anyFieldsDisplayable(fields)
    if(!anyDisplayable) {
        return null
    }

    const anyEditable = anyFieldsEditable(fields)

    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    return (
        <Form
            layout={layout}
            onSubmit={update}>
            {({ errors, trigger }) => (
                <Container id="financial">
                    <SectionHero
                        editable={anyEditable}
                        editing={editing}
                        toggleEditing={setEditing}>
                        <Subheading>
                            <FormattedMessage
                                id="person_pane_about_section_financial"
                                defaultMessage="Bank details" />
                        </Subheading>
                        <Spacer />
                        {!!editing && (
                            <ButtonSubmit
                                className={`constructive${saving ? ' loading' : ''}`}
                                disabled={
                                    (!international.value && !number.changed && !country.changed) ||
                                    (international.value && !iban.changed && !bic.changed) ||
                                    !!size(errors) ||
                                    saving
                                }
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_save"
                                    defaultMessage="Save" />
                            </ButtonSubmit>
                        )}
                    </SectionHero>
                    {fieldDisplayable(bankAccount) && (
                        <>
                            {!international.value && (
                                <>
                                    <StringField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_financial_accountnumber',
                                            defaultMessage: 'Bank account number'
                                        })}
                                        name="number"
                                        field={{
                                            ...number,
                                            include: 'always',
                                            ...(('editable' in bankAccount) && {
                                                editable: editing
                                            })
                                        }}
                                        error={!!customErrors?.number}
                                        onChange={({ number: value }) => {
                                            removeCustomError('number')

                                            setNumber({
                                                value,
                                                changed: true
                                            })
                                        }}
                                        controlProps={{ autoFocus: true }}
                                        {...isImportant('bankAccount')} />
                                    <Helper animate={(!!editing && !customErrors?.number) ? 'reveal' : 'hide'}>
                                        {(country.value === 'NO') && (
                                            <FormattedMessage
                                                id="person_input_helper_financial_accountnumber_norwegian"
                                                defaultMessage="Must be a valid Norwegian bank account number." />
                                        )}
                                        {(country.value === 'SE') && (
                                            <FormattedMessage
                                                id="person_input_helper_financial_accountnumber_swedish"
                                                defaultMessage="Must be a valid Swedish bank account number." />
                                        )}
                                    </Helper>
                                    <Error animate={!!customErrors?.number ? 'reveal' : 'hide'}>
                                        {customErrors?.number}
                                    </Error>
                                    <CountryField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_financial_country',
                                            defaultMessage: 'Bank country'
                                        })}
                                        name="country"
                                        field={{
                                            ...country,
                                            include: 'always',
                                            ...(('editable' in bankAccount) && {
                                                editable: editing
                                            })
                                        }}
                                        onChange={({ country: value }) => {
                                            removeCustomError('country')

                                            setCountry({
                                                value,
                                                changed: true
                                            })
                                        }}
                                        {...isImportant('bankAccount')}
                                        key={`${salt}:name:${country.value ?? 'empty'}`} />
                                    <Helper animate={(!!editing && !country.value && !customErrors?.country) ? 'reveal' : 'hide'}>
                                        <FormattedMessage
                                            id="person_input_helper_financial_bankcountry"
                                            defaultMessage="Bank country must be set." />
                                    </Helper>
                                    <Error animate={!!customErrors?.country ? 'reveal' : 'hide'}>
                                        {customErrors?.country}
                                    </Error>
                                </>
                            )}
                            {!!international.value && (
                                <>
                                    <StringField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_financial_iban',
                                            defaultMessage: 'IBAN'
                                        })}
                                        name="iban"
                                        field={{
                                            ...iban,
                                            include: 'always',
                                            ...(('editable' in bankAccount) && {
                                                editable: editing
                                            })
                                        }}
                                        error={!!customErrors?.iban}
                                        onChange={({ iban: value }) => {
                                            removeCustomError('iban')

                                            setIban({
                                                value,
                                                changed: true
                                            })
                                        }}
                                        controlProps={{ autoFocus: true }}
                                        {...isImportant('bankAccount')} />
                                    <Helper animate={(!!editing && !customErrors?.iban) ? 'reveal' : 'hide'}>
                                        <FormattedMessage
                                            id="person_input_helper_financial_iban"
                                            defaultMessage="Used for international payments. Get your IBAN from your bank." />
                                    </Helper>
                                    <Error animate={!!customErrors?.iban ? 'reveal' : 'hide'}>
                                        {customErrors?.iban}
                                    </Error>
                                    <StringField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_financial_bic',
                                            defaultMessage: 'SWIFT/BIC'
                                        })}
                                        name="bic"
                                        field={{
                                            ...bic,
                                            include: 'always',
                                            ...(('editable' in bankAccount) && {
                                                editable: editing
                                            })
                                        }}
                                        error={!!customErrors?.bic}
                                        onChange={({ bic: value }) => {
                                            removeCustomError('bic')

                                            setBic({
                                                value,
                                                changed: true
                                            })
                                        }}
                                        {...isImportant('bankAccount')} />
                                    <Helper animate={(!!editing && !customErrors?.bic) ? 'reveal' : 'hide'}>
                                        <FormattedMessage
                                            id="person_input_helper_financial_bic"
                                            defaultMessage="Used for international payments. Get your SWIFT/BIC from your bank." />
                                    </Helper>
                                    <Error animate={!!customErrors?.bic ? 'reveal' : 'hide'}>
                                        {customErrors?.bic}
                                    </Error>
                                </>
                            )}
                            {(!!editing && !!bankAccount?.editable) && (
                                <FlushedSwitch
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_financial_iban_bic_switch',
                                        defaultMessage: 'Use IBAN and SWIFT/BIC'
                                    })}
                                    name="international"
                                    field={{ value: getField('type') === 'international' }}
                                    interaction="switch"
                                    onChange={({ international: value }) => {
                                        setCustomErrors(null)

                                        setInternational({
                                            value,
                                            changed: true
                                        })
                                    }} />
                            )}
                        </>
                    )}
                </Container>
            )}
        </Form>
    )
}

const getDefaultsForType = type => (type === 'national' ? {
    number: null,
    country: null
} : {
    iban: null,
    bic: null
})

export default FinancialSection