import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { HeroColumns } from './s'
import CompetenceBanner from './competence-banner'
import ModuleTitle from 'components/module-title'
import { Plain } from 'components/button'
import { DownloadCloud } from 'styled-icons/feather'
import { Scrollable as Modal } from 'modals/generic'
import ExportCompetence from 'pages/competence/modals/export'

const CompetenceHero = ({ upgradable, salt }) => {
    const [exporting, setExporting] = useState(false)

    const {
        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const exportAvailable = checkFeature('competence-export')
    const competenceAdmin = check('competence:manage')
    const exportUpgradable = checkUpgradable({ feature: 'competence-export' })

    return (
        <>
            {!upgradable && <CompetenceBanner />}
            <HeroColumns>
                <ModuleTitle which="competence" />
                {((exportAvailable && competenceAdmin) || exportUpgradable) && (
                    <>
                        <div>
                            <Plain
                                className="constructive"
                                icon={DownloadCloud}
                                onClick={() => setExporting(true)}
                                disabled={exporting}>
                                <FormattedMessage
                                    id="action_export"
                                    defaultMessage="Export" />
                            </Plain>
                        </div>
                        <Modal
                            show={exporting}
                            dismiss={() => setExporting(false)}
                            salt={`${salt}:export`}>
                            <ExportCompetence
                                upgradable={exportUpgradable}
                                onDone={() => setExporting(false)}
                                salt={`${salt}:export`} />
                        </Modal>
                    </>
                )}
            </HeroColumns>
        </>
    )
}

export default CompetenceHero