import React from 'react'
import { Wrapper } from './s'
import Type from './type'

const GapConfigurationTypes = ({ types = [], ...props }) => (
    <Wrapper>
        {types.map(type => (
            <Type
                {...props}
                types={types}
                type={type}
                key={`${props.salt}:type:${type?.id ?? 'deleted'}`} />
        ))}
    </Wrapper>
)

export default GapConfigurationTypes
