import styled from 'styled-components'

export const StatusDot = styled.span`
    position: relative;
    display: inline-block;

    width: 8px;
    height: 18px;

    &.recently-invited::before {
        border-color: var(--huma-color-foreground-success);
    }

    &.will-be-invited::before {
        border-color: var(--huma-color-foreground-warning);
    }

    &.never-invited::before {
        background-color: var(--huma-color-foreground-warning);
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        display: block;

        margin-block-start: -4px;
        border: 2px solid transparent;
        border-radius: 100%;
        width: 8px;
        height: 8px;
    }
`