import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import ChildrenProvider, { useChildren } from 'contexts/children'
import { usePerson } from 'contexts/person'
import { useParams } from 'react-router-dom'
import { parseISO } from 'date-fns'
import { Container, HeadingColumns, Spacer, ImportantMessage, Plain } from '../s'
import { isFieldImportant } from '../'
import { Heading, Empty } from './s'
import { Scrollable as Modal } from 'modals/generic'
import Child from './item'
import EditChild from './edit'
import {
    Edit,
    Trash2 as Delete,
    Plus
} from 'styled-icons/feather'

const Children = ({ lookingAtMyOwnProfile }) => {
    const { formatMessage } = useIntl()

    const {
        children = [],
        viewable = false,
        editable = false,

        removeChild
    } = useChildren()

    const {
        person,
        fetchPerson
    } = usePerson()

    const [sortedChildren, setSortedChildren] = useState(children)

    useEffect(() => {
        const childrenYoungestToOldest = children
            .sort(({ birthDate: one }, { birthDate: two }) => parseISO(one) - parseISO(two))
            .reverse()

        setSortedChildren(childrenYoungestToOldest)
    }, [children])

    const [adding, setAdding] = useState(false)
    const [editing, setEditing] = useState(undefined)

    if(!viewable) {
        return null
    }

    const reset = () => {
        setAdding(false)
        setEditing(undefined)
    }

    const deleteChild = async id => {
        const { ok } = await removeChild(id)

        ok && fetchPerson()
    }

    const salt = 'profile:about:children'

    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    const EmptyWrapper = !!isImportant('children') ?
        ImportantMessage :
        Empty

    const actions = [
        child => ({
            salt: `profile:child:${child.id}:edit`,
            icon: <Edit size={24} />,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setEditing(child)
        }),
        ({ id }) => ({
            salt: `profile:child:${id}:delete`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: () => deleteChild(id),
            prompt: {
                question: formatMessage({
                    id: 'child_action_delete_prompt_question',
                    defaultMessage: 'Confirm removing this child'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        })
    ]

    return (
        <Container id="children">
            <HeadingColumns>
                <Heading>
                    <FormattedMessage
                        id="person_pane_about_section_children"
                        defaultMessage="Children" />
                </Heading>
                <Spacer />
            </HeadingColumns>
            {!sortedChildren.length && (
                <EmptyWrapper>
                    <FormattedMessage
                        id="person_pane_about_section_children_empty"
                        defaultMessage="No children added" />
                </EmptyWrapper>
            )}
            {sortedChildren.map(child => (
                <Child
                    child={child}
                    editable={editable}
                    actions={actions}
                    salt={salt}
                    key={`${salt}:${child.id}`} />
            ))}
            {!!editable && (
                <Plain
                    onClick={() => setAdding(true)}
                    className="constructive"
                    icon={<Plus size={24} />}
                    disabled={adding}>
                    <FormattedMessage
                        id="action_add"
                        defaultMessage="Add" />
                </Plain>
            )}
            <Modal
                show={adding || editing}
                dismiss={reset}
                salt={salt}>
                <EditChild
                    child={editing}
                    salt={salt}
                    onDone={reset} />
            </Modal>
        </Container>
    )
}

export default props => {
    const { id } = useParams()

    return (
        <ChildrenProvider userId={id}>
            <Children {...props} />
        </ChildrenProvider>
    )
}