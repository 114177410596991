import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { cls } from 'utilities/dom'
import Tooltip from 'components/tooltip'
import { Status } from './s'
import Label from './label'

const HandbookStatus = ({ status, inline = false, tooltip = true, className, ...props }) => {
    const { formatMessage } = useIntl()

    if(!status) {
        return null
    }

    className = cls([
        inline && 'inline',
        className
    ])

    const [Wrapper, wrapperProps] = (tooltip && (status === 'inaccessible')) ?
        [Tooltip, {
            content: formatMessage({
                id: 'handbooks_topic_status_inaccessible_explanation',
                defaultMessage: 'Change the topic’s access or upgrade your subscription to make this topic available again'
            }),
            placement: 'bottom'
        }] :
        [Fragment, null]

    return (
        <Wrapper {...wrapperProps}>
            <Status className={className}>
                <Label
                    {...props}
                    status={status} />
            </Status>
        </Wrapper>
    )
}

// In order to distinguish between a topic’s published status, and that
// of the handbook itself, we’ve introduced a new wording for the former: Ready.
// This means all status components will have to support the status format
// `${handbook.status}_${topic.status}`.

const statusTexts = {
    draft: {
        id: 'handbooks_topic_status_draft',
        defaultMessage: 'Draft'
    },
    published: {
        id: 'handbooks_topic_status_published',
        defaultMessage: 'Published'
    },
    inaccessible: {
        id: 'handbooks_topic_status_inaccessible',
        defaultMessage: 'Inaccessible'
    },

    draft_published: {
        id: 'handbooks_topic_status_ready',
        defaultMessage: 'Ready'
    }
}

statusTexts.published_draft = statusTexts.draft
statusTexts.draft_draft = statusTexts.draft
statusTexts.published_published = statusTexts.published
statusTexts.published_inaccessible = statusTexts.inaccessible
statusTexts.draft_inaccessible = statusTexts.inaccessible

export const useHandbookStatusTextFormatter = () => {
    const { formatMessage } = useIntl()

    return status => {
        const message = statusTexts[status] ?? null

        return message ?
            formatMessage(message) :
            null
    }
}

export default HandbookStatus