export const categoryToColorTokenMap = {
    skill: 'symbol-neutral-1',
    language: 'symbol-blue-1',
    certification: 'symbol-yellow-1',
    education: 'symbol-red-1',
    experience: 'symbol-green-1'
}

export const categoryToEmojiMap = {
    skill: 'star',
    language: 'globe_with_meridians',
    certification: 'medal',
    education: 'mortar_board',
    experience: 'nerd_face'
}

export const categories = [
    {
        name: 'skill',
        symbol: {
            colorToken: categoryToColorTokenMap.skill,
            emoji: categoryToEmojiMap.skill
        }
    },
    {
        name: 'language',
        symbol: {
            colorToken: categoryToColorTokenMap.language,
            emoji: categoryToEmojiMap.language
        }
    },
    {
        name: 'certification',
        symbol: {
            colorToken: categoryToColorTokenMap.certification,
            emoji: categoryToEmojiMap.certification
        }
    },
    {
        name: 'education',
        symbol: {
            colorToken: categoryToColorTokenMap.education,
            emoji: categoryToEmojiMap.education
        }
    },
    {
        name: 'experience',
        symbol: {
            colorToken: categoryToColorTokenMap.experience,
            emoji: categoryToEmojiMap.experience
        }
    }
]

export const categoryToMetaMap = {
    skill: {
        name: {
            id: 'competence_category_skill',
            defaultMessage: 'Skills'
        },
        title: {
            id: 'competence_category_skill_title',
            defaultMessage: 'Skill'
        },
        description: {
            id: 'competence_category_skill_description',
            defaultMessage: 'Measures the ability to perform tasks and interact effectively. It includes communication, problem-solving, teamwork, leadership, and adaptability. As proficiency level increases, the person can handle more complex situations and contribute more to organizational success. Measuring skills can identify areas for development and assess suitability for roles or projects.'
        },
        count: {
            id: 'competence_category_skill_count',
            defaultMessage: '{count, plural, =0 {No skills} =1 {1 skill} other {{count} skills}}'
        }
    },
    language: {
        name: {
            id: 'competence_category_language',
            defaultMessage: 'Languages'
        },
        title: {
            id: 'competence_category_language_title',
            defaultMessage: 'Language'
        },
        description: {
            id: 'competence_category_language_description',
            defaultMessage: 'Measures the ability to communicate in a language. Includes proficiency in reading, writing, listening, and speaking. As the proficiency level increases, the person can understand and express more complex ideas, and communicate effectively with diverse groups. Measuring language proficiency can identify areas for development and assess suitability for roles requiring language skills. Important for cross-cultural communication and enhances collaboration among team members.'
        },
        count: {
            id: 'competence_category_language_count',
            defaultMessage: '{count, plural, =0 {No languages} =1 {1 language} other {{count} languages}}'
        }
    },
    certification: {
        name: {
            id: 'competence_category_certification',
            defaultMessage: 'Certifications'
        },
        title: {
            id: 'competence_category_certification_title',
            defaultMessage: 'Certification'
        },
        count: {
            id: 'competence_category_certification_count',
            defaultMessage: '{count, plural, =0 {No types} =1 {1 type} other {{count} types}}'
        }
    },
    education: {
        name: {
            id: 'competence_category_education',
            defaultMessage: 'Education'
        },
        title: {
            id: 'competence_category_education_title',
            defaultMessage: 'Education'
        },
        count: {
            id: 'competence_category_education_count',
            defaultMessage: '{count, plural, =0 {No degrees} =1 {1 degree} other {{count} degrees}}'
        }
    },
    experience: {
        name: {
            id: 'competence_category_experience',
            defaultMessage: 'Experiences'
        },
        title: {
            id: 'competence_category_experience_title',
            defaultMessage: 'Experience'
        },
        count: {
            id: 'competence_category_experience_count',
            defaultMessage: '{count, plural, =0 {No types} =1 {1 type} other {{count} types}}'
        }
    }
}

export const categoryColumnLabels = {
    status: {
        id: 'qualification_type_label_status',
        defaultMessage: 'Status'
    },
    level: {
        id: 'competence_type_label_level',
        defaultMessage: 'Level'
    },
    validFrom: {
        id: 'qualification_type_label_issued_date',
        defaultMessage: 'Issued date'
    },
    validTo: {
        id: 'qualification_type_label_expiry_date',
        defaultMessage: 'Expiry date'
    },
    period: {
        id: 'competence_type_label_period',
        defaultMessage: 'Period'
    },
    fieldOfStudy: {
        id: 'competence_type_label_field_of_study',
        defaultMessage: 'Field of study'
    },
    school: {
        id: 'competence_type_label_school',
        defaultMessage: 'School'
    }
}

export const categoryColumns = {
    skill: ['status', 'level'],
    language: ['status', 'level'],
    certification: ['status', 'validTo', 'validFrom'],
    education: ['status', 'period', 'fieldOfStudy', 'school'],
    experience: ['status', 'period']
}

export const categoryDefaultColumnsOrders = {
    skill: ['status', 'level'],
    language: ['status', 'level'],
    certification: ['status', 'validTo'],
    education: ['fieldOfStudy', 'school'],
    experience: ['status', 'period']
}

export const categoryToAddTextMap = {
    skill: {
        id: 'competence_skill_action_add',
        defaultMessage: 'Add skill'
    },
    language: {
        id: 'competence_language_action_add',
        defaultMessage: 'Add language'
    },
    certification: {
        id: 'competence_certification_action_add',
        defaultMessage: 'Add certification'
    },
    education: {
        id: 'competence_education_action_add',
        defaultMessage: 'Add education'
    },
    experience: {
        id: 'competence_experience_action_add',
        defaultMessage: 'Add experience'
    }
}

export const categoryLevelMap = {
    skill: {
        1: {
            title: {
                id: 'competence_skill_level_1',
                defaultMessage: 'Beginner'
            },
            description: {
                id: 'competence_skill_level_1_description',
                defaultMessage: 'Someone who is new to a particular skill and is still learning the basics. They may require a lot of guidance and support to perform even simple tasks. They have limited experience and knowledge of the skill, and their performance may be inconsistent.'
            }
        },
        2: {
            title: {
                id: 'competence_skill_level_2',
                defaultMessage: 'Elementary'
            },
            description: {
                id: 'competence_skill_level_2_description',
                defaultMessage: 'Someone who has a basic understanding of the skill but needs more experience and practice to improve. They can perform routine tasks with guidance and supervision. They may still make mistakes and require feedback to develop their skills further. They have limited experience and knowledge of the skill and may need more time to gain confidence.'
            }
        },
        3: {
            title: {
                id: 'competence_skill_level_3',
                defaultMessage: 'Intermediate'
            },
            description: {
                id: 'competence_skill_level_3_description',
                defaultMessage: 'Someone who has a good level of competence in the skill and can perform tasks independently with minimal assistance. They have developed their skills to a level where they can perform most tasks without supervision. They can apply their knowledge and experience to solve problems, but may still require support for complex tasks. They have a solid understanding of the skill and can continue to develop their expertise.'
            }
        },
        4: {
            title: {
                id: 'competence_skill_level_4',
                defaultMessage: 'Proficient'
            },
            description: {
                id: 'competence_skill_level_4_description',
                defaultMessage: 'Someone who has a deep understanding of the skill and can use it to solve complex problems, provide guidance, and mentor others. They can work independently and have significant experience in the field. They can apply their knowledge and experience to solve complex problems and can provide guidance to others. They may also mentor or coach colleagues to develop their skills. They have a high level of expertise and can contribute to the development of new ideas.'
            }
        },
        5: {
            title: {
                id: 'competence_skill_level_5',
                defaultMessage: 'Expert'
            },
            description: {
                id: 'competence_skill_level_5_description',
                defaultMessage: 'Someone who has mastery over the skill and can innovate, teach, and lead in the field. They are recognized as an authority in their field and have extensive experience and knowledge. They can apply their skills to solve the most complex problems and develop new ideas and approaches. They may be involved in research, development, and thought leadership in the field. They can teach and mentor others and are often sought out for their expertise. They have achieved the highest level of mastery in the skill.'
            }
        }
    },
    language: {
        1: {
            title: {
                id: 'competence_language_level_1',
                defaultMessage: 'Beginner'
            },
            subTitle: {
                id: 'competence_language_level_1_sub',
                defaultMessage: 'A1 – A2'
            },
            description: {
                id: 'competence_language_level_1_description',
                defaultMessage: 'This level is equivalent to <abbr>CEFR</abbr> levels A1 and A2. At this level, a person can understand and use basic expressions and phrases related to personal information, simple tasks, and immediate needs. \n\nThey can communicate in simple and routine tasks and describe their background, immediate environment, and matters related to daily life.'
            }
        },
        2: {
            title: {
                id: 'competence_language_level_2',
                defaultMessage: 'Elementary'
            },
            subTitle: {
                id: 'competence_language_level_2_sub',
                defaultMessage: 'B1'
            },
            description: {
                id: 'competence_language_level_2_description',
                defaultMessage: 'This level is equivalent to <abbr>CEFR</abbr> level B1. At this level, a person can understand the main points of clear standard input on familiar matters regularly encountered in work, school, leisure, etc. \n\nThey can produce simple connected text on topics that are familiar or of personal interest and describe experiences, events, and ambitions, as well as briefly give reasons and explanations for opinions and plans.'
            }
        },
        3: {
            title: {
                id: 'competence_language_level_3',
                defaultMessage: 'Intermediate'
            },
            subTitle: {
                id: 'competence_language_level_3_sub',
                defaultMessage: 'B2'
            },
            description: {
                id: 'competence_language_level_3_description',
                defaultMessage: 'This level is equivalent to <abbr>CEFR</abbr> level B2. At this level, a person can understand the main ideas of complex text on both concrete and abstract topics, including technical discussions in their field of specialization. \n\nThey can interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible without strain for either party, and can produce clear, detailed text on a wide range of subjects and explain a viewpoint on a topical issue giving the advantages and disadvantages of various options.'
            }
        },
        4: {
            title: {
                id: 'competence_language_level_4',
                defaultMessage: 'Proficient'
            },
            subTitle: {
                id: 'competence_language_level_4_sub',
                defaultMessage: 'C1'
            },
            description: {
                id: 'competence_language_level_4_description',
                defaultMessage: 'This level is equivalent to <abbr>CEFR</abbr> level C1. At this level, a person can understand a wide range of demanding, longer texts, and recognize implicit meaning. They can express themselves fluently and spontaneously without much obvious searching for expressions, and use language flexibly and effectively for social, academic, and professional purposes. \n\nThey can produce clear, well-structured, detailed text on complex subjects, showing controlled use of organizational patterns, connectors, and cohesive devices.'
            }
        },
        5: {
            title: {
                id: 'competence_language_level_5',
                defaultMessage: 'Expert'
            },
            subTitle: {
                id: 'competence_language_level_5_sub',
                defaultMessage: 'C2'
            },
            description: {
                id: 'competence_language_level_5_description',
                defaultMessage: 'This level is equivalent to <abbr>CEFR</abbr> level C2. At this level, a person can understand with ease virtually everything heard or read. They can summarize information from different spoken and written sources, reconstructing arguments and accounts in a coherent presentation. \n\nThey can express themselves spontaneously, very fluently and precisely, differentiating finer shades of meaning even in more complex situations.'
            }
        }
    }
}

export const educationLevels = [
    'bachelor',
    'master',
    'phd',
    'mba',
    'associate',
    'vocational',
    'highSchool',
    'other'
]
