import React, { useState, forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import CompetenceProfileProvider, { useCompetenceProfile } from 'contexts/competence-profile'
import {
    Wrapper,
    Symbol,
    Meta, Name,
    PickButton
} from './s'
import { Plain } from 'components/button'

const Profile = ({ profile, setActing, setTypes, onDone, forwardedRef }) => {
    const { fetchProfile } = useCompetenceProfile()

    const [loading, setLoading] = useState(false)

    return (
        <Wrapper ref={forwardedRef}>
            <Symbol
                symbol={profile.symbol}
                className="hover-effect" />
            <Name className="compact">{profile.name}</Name>
            <Meta>
                {!profile.public && (
                    <span>
                        <FormattedMessage
                            id="competence_profile_status_private"
                            defaultMessage="Private" />
                    </span>
                )}
                <Plain
                    className="constructive small"
                    onClick={() => setActing({
                        profile,
                        mode: 'view'
                    })}>
                    <FormattedMessage
                        id="action_preview"
                        defaultMessage="Preview" />
                </Plain>
            </Meta>
            <PickButton
                className={`constructive${loading ? ' loading' : ''}`}
                disabled={loading}
                onClick={async () => {
                    setLoading(true)

                    const { ok, response } = await fetchProfile()

                    setLoading(false)

                    if(ok) {
                        const types = response.targets.map(({ level, type }) => ({
                            ...type,
                            target: level
                        }))

                        setTypes(types)
                        onDone({
                            types,
                            profile
                        })
                    }
                }}>
                <FormattedMessage
                    id="action_pick"
                    defaultMessage="Pick" />
            </PickButton>
        </Wrapper>
    )
}

export default forwardRef((props, ref) =>
    <CompetenceProfileProvider
        id={props.profile.id}
        fetchOnMount={false}>
        <Profile
            {...props}
            forwardedRef={ref} />
    </CompetenceProfileProvider>
)