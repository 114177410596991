import styled, { keyframes } from 'styled-components'
import {
    svgStroke,
    belowTablet, belowDesktop, fromDesktop, print
} from 'utilities/styled'
import { Grid } from 'components/grid'
import Caption from 'components/typography/caption'
import { Scrollable } from 'modals/generic'
import { Plain, Button } from 'components/button'
import HandbookContacts from 'pages/handbook/components/contacts'

export const Layout = styled(Grid)`
    isolation: isolate;
    align-items: start;

    ${fromDesktop`
        grid-template-columns: minmax(0, 1fr) 30%;
        row-gap: 40px;
        column-gap: 56px;
    `}

    ${belowDesktop`
        display: flex;
        flex-direction: column-reverse;
        row-gap: 24px;
    `}

    ${print`
        display: block;
    `}
`

export const Actions = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;

    margin-bottom: 24px;

    ${belowDesktop`
        margin-bottom: 16px;
    `}
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;

    &:has(${Actions}) {
        ${fromDesktop`
            margin-top: -56px;
        `}
    }

    &.navigation-gap::after {
        ${fromDesktop`
            content: "";
            display: block;
            min-height: calc(${[
                '100vh',
                '- var(--huma-global-main-layout-header-height)',
                '- calc(var(--huma-global-content-padding-block-start) + var(--huma-global-content-padding-block-end))',
                '- var(--huma-global-sticky-menu-height)'
            ].join(' ')});
        `}

        ${print`
            display: none;
        `}
    }
`

export const Aside = styled.aside`
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    &.category {
        row-gap: 24px;
    }

    &:empty {
        display: none;
    }

    ${fromDesktop`
        position: sticky;
        top: calc(var(--huma-global-main-layout-header-height) + 144px);
    `}

    ${belowDesktop`
        max-width: 100%;
    `}

    ${print`
        display: none;
    `}
`

export const Heading = styled(Caption)`
    margin-bottom: 16px;

    &.tight {
        margin-bottom: 8px;
    }
`

export const LoadingContainer = styled.div`
    position: relative;
    min-height: 200px;
`

export const AutoFetchLoadingContainer = styled(Grid)`
    place-items: center;
    height: 96px;
    position: relative;
`

export const TopicModal = styled(Scrollable)`
    padding: 0;

    ${belowTablet`
        align-self: flex-start;

        margin: 88px 0 0;
        border-radius: 16px 16px 0 0;
        max-width: 100vw;
        width: 100vw;
        min-height: calc(100dvh - 88px);
    `}
`

export const AddButton = styled(Plain)`
    svg {
        ${svgStroke()}

        margin-inline-end: 16px;
    }

    ${print`
        display: none;
    `}
`

const inlineHover = keyframes`
    100% {
        opacity: 1;
    }
`

export const InlineButton = styled(Button)`
    position: absolute;
    z-index: 2;
    top: -7px;
    left: -9px;

    width: 20px;
    height: 20px;
    padding: 0;

    svg {
        ${svgStroke()}
    }
`

export const InlineTrigger = styled.div`
    cursor: pointer;

    position: relative;
    z-index: 1;
    isolation: isolate;

    margin-block: -4px;
    width: 100%;
    height: 8px;

    opacity: 0;

    &:hover,
    &:has(${InlineButton}:focus) {
        animation: ${inlineHover} .1s ease-in-out forwards;
    }

    &:hover {
        animation-delay: .2s;
    }

    &::after {
        content: "";

        position: absolute;
        top: 2px;
        left: 0;

        width: 100%;
        height: 2px;
        background-color: var(--huma-color-surface-constructive-bold);
    }
`

export const NotMobileHandbookContacts = styled(HandbookContacts)`
    ${belowDesktop`
        display: none;
    `}
`

export const MobileHandbookContacts = styled(HandbookContacts)`
    margin-top: 40px;
    max-width: 100%;
    width: 400px;

    ${fromDesktop`
        display: none;
    `}
`