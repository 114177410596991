import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useAccess } from 'contexts/access'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { Entities, DisplayColumns } from './s'
import { Plain } from 'components/button'
import EntitiesPicker from 'modals/entities-picker'
import { Plus } from 'styled-icons/feather'

const ManageUserTeamsAffiliation = ({ layout, salt }) => {
    const { formatMessage } = useIntl()
    const [editing, setEditing] = useState(false)

    const {
        check,
        refresh
    } = useAccess()

    const {
        person,

        setPersonAffiliation,
        fetchPerson
    } = usePerson()

    const { teams: { value: teams } } = person
    if(!teams) {
        return null
    }

    const editable = (check('groups:manage') || check('teams:manage')) && person?.status?.active === true
    if(!editable && !teams.length) {
        return null
    }

    const update = async (...args) => {
        await setPersonAffiliation(...args)
        refresh()
        fetchPerson()
    }

    return (
        <Field className={layout}>
            <Label>
                <FormattedMessage
                    id="noun_teams"
                    defaultMessage="Teams" />
            </Label>
            <Control>
                <DisplayColumns {...(!!editable ? { className: 'replacable' } : null)}>
                    {!teams?.length && (
                        <>
                            {!editable && (
                                <ValueDisplay className="empty">
                                    <FormattedMessage
                                        id="empty_not_set"
                                        defaultMessage="Not set" />
                                </ValueDisplay>
                            )}
                            {!!editable && (
                                <Plain
                                    className="constructive"
                                    icon={<Plus size={24} />}
                                    onClick={() => setEditing(true)}>
                                    <FormattedMessage
                                        id="teams_action_add"
                                        defaultMessage="Add teams" />
                                </Plain>
                            )}

                        </>
                    )}
                    {!!teams?.length && (
                        <>
                            <Entities
                                entities={teams}
                                preview={true}
                                salt={salt} />
                            {!!editable && (
                                <Plain
                                    className="constructive"
                                    onClick={() => setEditing(true)}>
                                    <FormattedMessage
                                        id="action_change"
                                        defaultMessage="Change" />
                                </Plain>
                            )}
                        </>
                    )}
                    <EntitiesPicker
                        salt={`${salt}:teams`}
                        show={editing}
                        type="team"
                        path="/groups"
                        params={{ types: ['team'] }}
                        creatable={true}
                        heading={formatMessage({
                            id: 'teams_action_pick',
                            defaultMessage: 'Pick teams'
                        })}
                        picked={teams}
                        dismiss={() => setEditing(false)}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: () => setEditing(false)
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_save',
                                defaultMessage: 'Save'
                            }),
                            effect: 'constructive',
                            onClick: () => update(picked, 'teams')
                        })} />
                </DisplayColumns>
            </Control>
        </Field>
    )
}

export default ManageUserTeamsAffiliation
