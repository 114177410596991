import React, { useState, useEffect, cloneElement, isValidElement } from 'react'
import loadable from '@loadable/component'
import { useLocation } from 'react-router-dom'
import { requestAccess } from 'utilities/auth'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { compact } from 'utilities/array'
import { PageErrorBoundary } from 'components/error-boundary'

const RestrictedPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/restricted'))
const DeniedPage = loadable(() => import(/* webpackPrefetch: true */ 'pages/denied'))

const standaloneUnrestrictedModules = [
    'dashboard'
]

const RouteAuthorization = ({
    module,
    restricted,
    permissions = [],
    permissionsOperator = 'or',
    upgradable: upgradableOverride,
    children
}) => {
    const location = useLocation()
    const upgradable = useUpgradable()({ module })

    const {
        initialized,
        track,

        check,
        checkModule
    } = useAccess()

    const [authenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        let mounted = true

        const authenticate = async () => {
            const { ok, response: tokens } = await requestAccess()
            mounted && setAuthenticated(ok && !!tokens?.accessToken)
        }

        authenticate()

        return () => mounted = false
    }, [])

    if(!initialized || !authenticated) {
        return null
    }

    if(!!module && typeof restricted === 'undefined') {
        restricted =
            !upgradable &&
            !(track !== 'standard' && standaloneUnrestrictedModules.includes(module)) &&
            !checkModule(module)
    }

    const permissionsChecker = permissionsOperator === 'or' ? 'some' : 'every'
    const permissed = permissions?.length ?
        permissions[permissionsChecker](check) :
        true

    const denied = !upgradable && !restricted && !permissed
    const passthrough = !restricted && !denied

    const [section] = compact(location.pathname.split('/'))

    return (
        <PageErrorBoundary key={`section:${section}`}>
            {!!restricted && <RestrictedPage module={module} />}
            {!!denied && <DeniedPage module={module} />}
            {(passthrough && isValidElement(children)) &&
                cloneElement(children, {
                    module,
                    upgradable: upgradableOverride ?? upgradable
                })
            }
            {(passthrough && !isValidElement(children)) && children}
        </PageErrorBoundary>
    )
}

export default RouteAuthorization