import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { useMe } from 'contexts/me'
import { useForm } from 'components/form/controller'
import { size } from 'utilities/object'
import { Heading, NameAndAddressSummary, Plain } from './s'
import StringField from 'components/form/field/string'
import PaymentCardField from 'components/form/field/payment-card'
import Paragraph from 'components/typography/paragraph'
import HiddenField from 'components/form/field/hidden'
import Message from 'components/message'
import Link from 'components/link'

const Billing = ({
    billing,
    mode = 'edit',
    meta,
    cardLast = false,
    instantChallengeFailed,
    handleRecurringChallenge,
    salt
}) => {
    const { formatMessage } = useIntl()

    const { environment } = useEnvironment()
    const { me } = useMe()

    const {
        givenName: myGivenName,
        familyName: myFamilyName
    } = me ?? {}

    const {
        firstName: billingGivenName,
        lastName: billingFamilyName,
        ...cardData
    } = billing ?? {}

    const givenName = billingGivenName ?? myGivenName?.value ?? ''
    const familyName = billingFamilyName ?? myFamilyName?.value ?? ''

    const purchase = meta.change === 'purchase'
    const modify = meta.change === 'modify'

    const cardWithoutValue = modify && (!handleRecurringChallenge || mode === 'edit')

    const [cardOnly, setCardOnly] = useState(!!size(billing ?? {}) && meta.change !== 'modify')

    const { submitted } = useForm()

    if(meta.to.free) {
        return null
    }

    const card = (
        <Card
            value={cardWithoutValue ? null : cardData}
            mode={mode}
            salt={salt} />
    )

    const nameGivenFamily = formatMessage({
        id: 'name_given_family',
        defaultMessage: '{given} {family}'
    }, {
        given: givenName,
        family: familyName
    })

    return (
        <>
            {!!purchase && (
                <Heading>
                    <FormattedMessage
                        id="payment_heading_payment_information"
                        defaultMessage="Payment information" />
                </Heading>
            )}
            {!cardLast && card}
            {!!cardOnly && (
                <NameAndAddressSummary>
                    <Paragraph className="compact">{nameGivenFamily}</Paragraph>
                    <Plain
                        className="constructive"
                        onClick={() => setCardOnly(false)}>
                        <FormattedMessage
                            id="action_change"
                            defaultMessage="Change" />
                    </Plain>
                    <HiddenField
                        salt={salt}
                        name="name"
                        field={{
                            value: nameGivenFamily,
                            required: true
                        }} />
                </NameAndAddressSummary>
            )}
            {!cardOnly && (
                <StringField
                    salt={salt}
                    label={formatMessage({
                        id: 'person_label_name_full',
                        defaultMessage: 'Full name'
                    })}
                    name="name"
                    field={{
                        value: nameGivenFamily,
                        required: true,
                        include: 'always'
                    }} />
            )}
            {!!cardLast && card}
            {(!!submitted && !!instantChallengeFailed) && (
                <Message
                    type="error"
                    message={formatMessage({
                        id: 'payment_subscription_payment_challenged_error_more',
                        defaultMessage: 'The authentication failed. Please try again. {link}'
                    }, {
                        link: (
                            <Link
                                href={`https://www.${environment.tld}/knowledge`}
                                target="_blank">
                                <FormattedMessage
                                    id="knowledge_base_action_navigate"
                                    defaultMessage="Read more in our Knowledge Base" />
                            </Link>
                        )
                    })} />
            )}
        </>
    )
}

export default Billing

const Card = ({ value, mode = 'edit', salt }) => (
    <PaymentCardField
        salt={salt}
        name="card"
        field={{
            ...(value ? { value } : null),
            required: true,
            include: 'always'
        }}
        mode={mode} />
)