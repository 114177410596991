import React, { useRef, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { UnitPermissionsProvider, useUnitPermissions } from 'contexts/unit-permissions'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import PersonField from 'components/form/field/person'
import TimeField from 'components/form/field/time'
import TemplateHelper from '../template-helper'
import DynamicAssignments from '../dynamic-assignments'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { compact } from 'utilities/array'
import { size, pick, omit } from 'utilities/object'
import { unpackStringField } from 'utilities/person'

const AddOffboardingPickExistingUser = ({
    templates, setTemplates,
    dynamicAssignmentTypes, setDynamicAssignmentTypes,
    proceed, modal, salt
}) => {
    const { formatMessage } = useIntl()

    const {
        person,

        replace,
        updatePerson
    } = usePerson()

    const { checkAll } = useUnitPermissions()

    const [access, { general: canFetchPeopleFilterless }] = checkAll({
        system: 'users:manage',
        unit: 'unit:employment-lifecycles:manage'
    })

    const [initialSupervisor] = useState(!!person?.supervisor)
    const [updating, setUpdating] = useState(false)
    const [triggered, setTriggered] = useState(false)

    const personRef = useRef()

    useEffect(() => {
        if(!!personRef?.current && !triggered) {
            personRef.current.click()
            setTriggered(true)
        }
    }, [personRef?.current, triggered])

    const update = async body => {
        setUpdating(true)

        const dynamicAssignments = pick(body, ...dynamicAssignmentTypes)
        body = omit(body, ...dynamicAssignmentTypes)

        let ok = false

        const lastDayOfWorkChanged = !!body.lastDayOfWork && body.lastDayOfWork !== unpackStringField(person.lastDayOfWork)
        const supervisorChanged = !!body.supervisor && body.supervisor !== unpackStringField(person.supervisor)?.id

        if(lastDayOfWorkChanged || supervisorChanged) {
            const changedFields = compact([
                lastDayOfWorkChanged && 'lastDayOfWork',
                supervisorChanged && 'supervisor'
            ])

            const updateFields = pick(body, ...changedFields)
            const result = await updatePerson(updateFields)
            ok = result.ok
        } else ok = true

        setUpdating(false)

        ok && proceed({
            concernsType: 'user',
            concernsId: person.id,
            templateIds: templates
                ?.filter(({ providedByHuma = false }) => !providedByHuma)
                .map(({ id }) => id) ?? [],
            humaTemplateIds: templates
                ?.filter(({ providedByHuma = false }) => !!providedByHuma)
                .map(({ id }) => id) ?? [],
            lastDayOfWork: body.lastDayOfWorkLocal ?? body.lastDayOfWork ?? null,
            dynamicAssignments
        })
    }

    const unitPermissionsFilter = { permission: 'unit:employment-lifecycles:manage' }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'employee_offboarding_action_add',
                    defaultMessage: 'New offboarding'
                })}
                dismiss={modal.dismiss} />
            <Form
                layout="vertical"
                onSubmit={update}>
                {({ touched, errors, trigger }) => (
                    <>
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'preposition_for',
                                defaultMessage: 'For'
                            })}
                            name="offboardee"
                            field={{
                                required: true,
                                include: 'always'
                            }}
                            picker={{
                                outer: false,
                                ...(!canFetchPeopleFilterless ? {
                                    message: {
                                        type: 'info',
                                        message: formatMessage({
                                            id: 'employment_lifecycle_pick_existing_user_unit_permissions_person_picker_disclaimer',
                                            defaultMessage: 'Only members of groups in which you can manage onboardings and offboardings are available.'
                                        })
                                    },

                                    // TODO: Reactivate this when the API supports combining permission
                                    // filters with other filters supported by UI components
                                    // filterOverrides: {
                                    //     teams: {
                                    //         query: {
                                    //             path: '/units',
                                    //             params: {
                                    //                 ...unitPermissionsFilter,
                                    //                 types: ['team']
                                    //             }
                                    //         }
                                    //     },
                                    //     locations: {
                                    //         query: {
                                    //             path: '/units',
                                    //             params: {
                                    //                 ...unitPermissionsFilter,
                                    //                 types: ['location']
                                    //             }
                                    //         }
                                    //     }
                                    // }
                                } : null)
                            }}
                            entity={{
                                params: {
                                    excludeIds: ['me'],
                                    ...(!canFetchPeopleFilterless ? unitPermissionsFilter : null)
                                }
                            }}
                            onChange={({ offboardee }) => replace(offboardee?.id)}
                            ref={personRef} />
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_supervisor',
                                defaultMessage: 'Supervisor'
                            })}
                            name="supervisor"
                            field={{
                                ...(person?.supervisor ?? null),
                                unsettable: !initialSupervisor
                            }}
                            picker={{ outer: false }}
                            entity={{
                                params: {
                                    notRecursivelySubordinateOf: person?.id
                                }
                            }}
                            key={`${salt}:supervisor:${person?.supervisor?.value?.id ?? 'empty'}`} />
                        <TimeField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_employment_date_end',
                                defaultMessage: 'Last day of work'
                            })}
                            name={`lastDayOfWork${!!person?.lastDayOfWork?.editable ? '' : 'Local'}`}
                            field={{
                                value: person?.lastDayOfWork?.value ?? null,
                                required: true,
                                include: 'always'
                            }}
                            picker={!!unpackStringField(person?.firstDayOfWork) && {
                                after: unpackStringField(person.firstDayOfWork)
                            }}
                            enabled={!!person?.lastDayOfWork}
                            key={`${salt}:lastDayOfWork:${person ? 'enabled' : 'disabled'}`} />
                        <TemplateHelper
                            templates={templates}
                            setTemplates={setTemplates}
                            salt={salt} />
                        <DynamicAssignments
                            templates={templates}
                            types={dynamicAssignmentTypes}
                            setTypes={setDynamicAssignmentTypes}
                            salt={salt} />
                        <Actions className="compact">
                            <Plain
                                onClick={modal.dismiss}
                                className="neutral"
                                disabled={updating}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${updating ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors) || updating || !person?.lastDayOfWork}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_continue"
                                    defaultMessage="Continue" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => (
    <UnitPermissionsProvider>
        <AddOffboardingPickExistingUser {...props} />
    </UnitPermissionsProvider>
)