import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { openChat } from 'hooks/voiceflow'
import { enrichEntry } from 'pages/absence/utilities'
import {
    Heading,
    Message,
    Entries, Entry
} from './s'
import { CloseButton } from 'components/button'
import Link from 'components/link'
import AbsenceDate from 'pages/absence/components/date'
import Paragraph from 'components/typography/paragraph'
import { ExternalLink } from 'styled-icons/feather'

const ErrorMessage = ({ error, dismiss }) => {
    const { formatMessage } = useIntl()

    if(!error) {
        return null
    }

    const {
        errorType,
        errorMessage,
        currentEntry,
        overlappingEntries
    } = error

    const { start, end } = currentEntry ?? {}

    const overlappingError = ['overlapping_time_range', 'overlapping_absence'].includes(errorType)

    return (
        <>
            <Heading>
                <FormattedMessage
                    id={`absence_error_heading_${errorType}`}
                    defaultMessage={errorMessage} />
            </Heading>
            <CloseButton onClick={dismiss} />
            {overlappingError && (
                <Message
                    {...(!overlappingEntries?.length ? { className: 'compact' } : null)}
                    type="warning"
                    message={formatMessage({
                        id: 'absence_message_overlapping_user',
                        defaultMessage: 'Only administrators can register and resolve overlapping absences.'
                    })} />
            )}
            {!overlappingError && (
                <Message
                    {...(!overlappingEntries?.length ? { className: 'compact' } : null)}
                    type="error"
                    message={formatMessage({
                        id: `absence_error_message_${errorType}`,
                        defaultMessage: 'An error occured. Please try again.'
                    }, {
                        feedback: chunks => (
                            <Link onClick={() => {
                                dismiss?.()
                                openChat()
                            }}>
                                {chunks} <ExternalLink size={16} />
                            </Link>
                        )
                    })} />
            )}
            {!!overlappingEntries?.length && (
                <>
                    <Paragraph>
                        <FormattedMessage
                            id="absence_information_existing_entries_range"
                            defaultMessage="Existing absence for {range}:"
                            values={{ range: (
                                <AbsenceDate
                                    as={Fragment}
                                    dateRangeWrapper={Fragment}
                                    start={start}
                                    end={end} />
                            ) }} />
                    </Paragraph>
                    <Entries>
                        {overlappingEntries?.map(entry => (
                            <Entry
                                entry={enrichEntry(entry)}
                                compact={true}
                                key={`absence:entries:entry:conflict:${entry.id}`} />
                        ))}
                    </Entries>
                </>
            )}
        </>
    )
}

export default ErrorMessage
