import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHandbookTemplates } from 'contexts/handbook-templates'
import { HandbookTemplateGrid, LoadingContainer } from './s'
import Empty from 'components/typography/caption'
import Loader from 'components/loader'
import Template from './template'

const Templates = ({ salt, ...props }) => {
    const {
        templates,
        hasFetched
    } = useHandbookTemplates()

    if(!hasFetched) {
        return <Loading />
    }

    return (
        <>
            {!!templates?.length && (
                <HandbookTemplateGrid>
                    {templates.map((template, index) => (
                        <Template
                            {...props}
                            template={template}
                            index={index}
                            salt={`${salt}:templates`}
                            key={`${salt}:templates:${template.id}`} />
                    ))}
                </HandbookTemplateGrid>
            )}
            {!templates?.length && (
                <Empty>
                    <FormattedMessage
                        id="handbooks_template_picker_empty"
                        defaultMessage="🤷 Sorry, no templates found." />
                </Empty>
            )}
        </>
    )
}

const Loading = () => (
    <LoadingContainer>
        <Loader />
    </LoadingContainer>
)

export default Templates
