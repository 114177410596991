import React from 'react'
import { FormattedMessage } from 'react-intl'
import SubordinatesProvider, { useSubordinates } from 'contexts/subordinates'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { Button, Name, Grants } from './group/s'
import { ArrowRight } from 'styled-icons/feather'
import { Subordinate } from 'components/feather'

const Subordinates = () => {
    const { subordinates } = useSubordinates()

    const navigate = useNavigate()

    if(!subordinates?.length) {
        return null
    }

    return (
        <Button onClick={() => navigate(paths.people.base, {
            state: {
                filter: {
                    supervisor: 'me'
                }
            }
        })}>
            <Subordinate size={24} />
            <Name className="compact">
                <FormattedMessage
                    id="people_filter_my_subordinates"
                    defaultMessage="My subordinates" />
            </Name>
            <Grants className="compact">
                <span>
                    <FormattedMessage
                        id="role_supervisor_name"
                        defaultMessage="Supervisor" />
                </span>
            </Grants>
            <ArrowRight
                className="arrow"
                size={24} />
        </Button>
    )
}

export default props => (
    <SubordinatesProvider userId="me">
        <Subordinates {...props} />
    </SubordinatesProvider>
)
