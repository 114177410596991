import styled from 'styled-components'
import LinkBase from 'components/link'
import { Heading as HeadingBase } from 'pages/dashboard/s'

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;
`

export const Heading = styled(HeadingBase)`
    margin: 0;
`

export const Link = styled(LinkBase)`
    display: inline-block;
    line-height: 24px;
    margin-top: 16px;
`