import React, {
    useRef, useState, useEffect,
    Children, cloneElement, isValidElement,
    Fragment
} from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { useAbsenceTypes } from 'contexts/absence-types'
import AbsenceStatsProvider, { useAbsenceStats } from 'contexts/absence-stats'
import { useAbsenceEntries, enrichEntry, isFuture, isOngoing } from 'pages/absence/utilities'
import AbsenceEntryProvider, { useAbsenceEntry } from 'contexts/absence-entry'
import AbsenceUserPeriodsProvider, { useAbsenceUserPeriods } from 'contexts/absence-user-periods'
import WorkScheduleProvider, { useWorkSchedule } from 'contexts/work-schedule'
import { useAccess } from 'contexts/access'
import { UserPermissionsProvider, useUserPermissions } from 'contexts/unit-permissions'
import { openChat } from 'hooks/voiceflow'
import {
    differenceInDays, startOfDay, endOfDay,
    isBefore, isAfter,
    subDays, addDays
} from 'date-fns'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { useDebounce } from 'hooks/debounce'
import { isofy, getDate } from 'utilities/date-time'
import { compact } from 'utilities/array'
import { size } from 'utilities/object'
import { percentToDecimal, decimalToPercent } from 'utilities/math'
import { integerPattern } from 'utilities/regex'
import {
    LoadingContainer,
    Heading,
    Fields,
    Person,
    CheckboxField,
    CountsAs, TotalBalance, TotalBalanceLoader, Message, Caption,
    AbsenceTypeList, AbsenceTypeListItem,
    Information, InfoMessage, OverlappingEntries
} from './s'
import { Entry } from 'pages/absence/modals/error/s'
import Loader from 'components/loader'
import { SkeletonStrings } from 'components/skeleton'
import Form from 'components/form/controller'
import EntityField from 'components/form/field/entity'
import PersonField from 'components/form/field/person'
import TimeField from 'components/form/field/time'
import TextField from 'components/form/field/text'
import StringField from 'components/form/field/string'
import OneOfField from 'components/form/field/one-of'
import GradeShortcuts from './grade-shortcuts'
import { Plain, ButtonSubmit } from 'components/button'
import Link from 'components/link'
import Paragraph, { ParagraphSmall } from 'components/typography/paragraph'
import OverlappingExample from './example'
import { InlineMessage } from 'components/message'
import Actions from 'components/form/actions'
import { DisplayEmoji as Emoji } from 'components/symbol'
import { ArrowRight, ExternalLink } from 'styled-icons/feather'

const EditEntry = ({ entry, type, setType, user, dismiss = null, onDone, callback, overrideTouched = false, showMarkedDates = false, context = 'order' }) => {
    const { formatMessage } = useIntl()

    const rangeRef = useRef()

    const {
        entries,
        addEntry,
        updateEntry
    } = useAbsenceEntries(context)

    const {
        estimateCount,
        error,
        resetError
    } = useAbsenceStats()

    const {
        periods,
        hasFetched: hasFetchedUserPeriods
    } = useAbsenceUserPeriods()

    const { getScheduleForUser } = useWorkSchedule()

    const { check } = useAccess()
    const userPermissions = useUserPermissions()

    const { isItMyOwnId } = useMe()

    const {
        requireEndDate = true,
        requireNote = false,
        // allowPartialDay = false,
        requireApproval = false,
        allowWorkRelatedCause = false,
        allowGraded = false,
        gradedAggregate = 'full'
    } = type ?? {}

    const {
        id,
        start: initialStart,
        end: initialEnd,
        workRelated,
        user: absentee,
        note,
        permissionLevel,
        status,
        grade = 1
    } = entry ?? {}

    const originalStart = useRef(initialStart)
    const originalEnd = useRef(initialEnd)

    const now = new Date()

    if(!dismiss) {
        dismiss = onDone
    }

    const absenceAdmin = [
        check('absence:manage'),
        userPermissions?.checkUnitPermission?.('user:absence:manage')
    ].some(Boolean)

    const editor = permissionLevel === 'edit'
    const approver = permissionLevel === 'approve'

    const [constructing, setConstructing] = useState(false)
    const [triggered, setTriggered] = useState(false)
    const [start, setStart] = useState(initialStart ?? null)
    const [end, setEnd] = useState(initialEnd ?? null)
    const [gradeValue, setGradeValue, { signal: gradeValueSignal }] = useDebounce(grade)
    const [gradePercentage, setGradePercentage, { signal: gradePercentageSignal }] = useDebounce(decimalToPercent(grade))
    const [noteText, setNoteText] = useState(note)

    const [dateRestrictions, setDateRestrictions] = useState({})
    const [hasSetDateRestrictions, setHasSetDateRestrictions] = useState(false)
    const [workScheduleCountryCode, setWorkScheduleCountryCode] = useState(null)

    const [estimate, setEstimate] = useState(null)
    const [estimating, setEstimating] = useState(false)

    const typeName = useTypeNameFormatter()(type)

    const ongoingOrPast = !!entry?.start && isAfter(now, endOfDay(start))
    const approved = status === 'approved'

    const isEditingLimited = [
        ongoingOrPast,
        !approver,
        approved
    ].every(Boolean)

    const estimateData = async () => {
        setEstimating(true)
        resetError()

        const { ok, response } = await estimateCount({
            userId: id ? absentee.id : user.id,
            fromDate: getDate(start),
            toDate: end ?
                getDate(end) :
                isFuture({ start }, now) ?
                    getDate(start) :
                    getDate(now),
            type: type.id,
            grade: gradeValue,
            ...(id && { excludeEntryId: id })
        })

        setEstimating(false)

        if(ok) {
            const { details, workdays } = response

            const estimate = {
                details: details
                    .sort(({ effectiveTimeWindow: one }, { effectiveTimeWindow: two }) =>
                        isofy(one.from).getTime() - isofy(two.from).getTime()
                    )
                    .reverse(),
                workdays
            }

            setEstimate(estimate)
        } else {
            setEstimate(null)
        }
    }

    useEffect(() => {
        if(user.id) {
            const getWorkSchedule = async () => {
                const { ok, response } = await getScheduleForUser(user.id)
                ok && setWorkScheduleCountryCode(response?.countryCode)
            }

            getWorkSchedule()
        }
    }, [user?.id])

    useEffect(() => {
        if(!!start) {
            estimateData()
        }
    }, [start, end, type, gradeValueSignal, gradePercentageSignal])

    useEffect(() => {
        if(hasFetchedUserPeriods && !hasSetDateRestrictions) {
            periods
                .reverse()
                .forEach((assignment, index) => {
                    const { fromDate, toDate } = assignment

                    // If a fromDate is set, and there is a next assignment
                    if(!!fromDate && periods[index + 1]) {
                        const nextAssignment = periods[index + 1]

                        // If the next assignment has a toDate, and the difference between the fromDate and the toDate is more than one day
                        if(nextAssignment?.toDate && differenceInDays(isofy(fromDate), isofy(nextAssignment.toDate)) > 1) {
                            // Add a blocked date range between the next assignment's toDate and the current assignment's fromDate
                            setDateRestrictions(previousDateRestrictions => ({
                                ...previousDateRestrictions,
                                blockedDateRanges: [
                                    ...(previousDateRestrictions?.blockedDateRanges ?? []),
                                    {
                                        start: addDays(isofy(nextAssignment.toDate), 1),
                                        end: subDays(isofy(fromDate), 1)
                                    }
                                ]
                            }))
                        }
                    }

                    const canApprove = !!absenceAdmin || !!approver

                    const ongoing = !!entry && isOngoing(entry, now)
                    const mine = !!entry && isItMyOwnId(user?.id)

                    // If it's my own absence and the entry is ongoing, set the restriction from today
                    const endRestricted = !!entry && [
                        mine,
                        !canApprove,
                        ongoing,
                        approved
                    ].every(Boolean)

                    const restrictedFromEnd = endRestricted && requireApproval
                    const restrictedFromToday = endRestricted && !requireApproval

                    const getAfter = ({ fromDate, toDate }) => {
                        if(!fromDate && !endRestricted) {
                            return null
                        }

                        if(restrictedFromToday) {
                            return startOfDay(subDays(now, 1))
                        }

                        if(!!toDate && restrictedFromEnd) {
                            return startOfDay(isofy(toDate))
                        }

                        return startOfDay(isofy(fromDate))
                    }

                    // If there is only one assignment, and both a fromDate and a toDate is set
                    if(periods.length === 1 && !!fromDate && !!toDate) {
                        setDateRestrictions(previousDateRestrictions => ({
                            ...previousDateRestrictions,
                            after: getAfter(assignment),
                            before: isofy(toDate)
                        }))
                    } else {
                        // If this is the first assignment, and a toDate is set
                        if(index === 0 && !!toDate) {
                            // Only allow dates before the toDate
                            setDateRestrictions(previousDateRestrictions => ({
                                ...previousDateRestrictions,
                                before: addDays(isofy(toDate), 1)
                            }))
                        }

                        // If this is the last assignment, and a fromDate is set
                        if(index === periods.length - 1 && !!fromDate) {
                            setDateRestrictions(previousDateRestrictions => ({
                                ...previousDateRestrictions,
                                after: getAfter(assignment)
                            }))
                        }

                        if(!fromDate && !toDate && endRestricted) {
                            setDateRestrictions(previousDateRestrictions => ({
                                ...previousDateRestrictions,
                                after: getAfter({ toDate: end })
                            }))
                        }
                    }
                })

            setHasSetDateRestrictions(true)
        }
    }, [periods, hasFetchedUserPeriods, hasSetDateRestrictions])

    useEffect(() => {
        if(!!rangeRef?.current && !triggered && !!hasSetDateRestrictions) {
            if(!entry?.id) {
                rangeRef.current.click()
            }

            setTriggered(true)
        }
    }, [rangeRef?.current, triggered, hasSetDateRestrictions])

    if(!user || !type) {
        return null
    }

    const addOrUpdate = async ({ range, ...body }, { resetTouched }) => {
        setConstructing(true)

        const [startDate, endDate] = range

        body = {
            ...body,
            startDate,
            endDate: endDate || null,
            userId: user.id,
            typeId: type.id,
            ...body?.grade ? { grade: percentToDecimal(body.grade) } : null,
        }

        const addOrUpdateEntry = id ?
            updateEntry :
            addEntry

        const { response, ok } = await addOrUpdateEntry({ body, entryId: id })

        setConstructing(false)

        if(ok) {
            resetTouched()
            onDone?.(id ? response : null)
            callback?.()
        }
    }

    const markedDateRanges = showMarkedDates && entries
        .filter(({ id: entryId }) => entryId !== id)
        .map(({ start, end, type }) => ({
            range: { start, end },
            symbol: type.symbol
        }))

    let title = {
        id: 'absence_action_register',
        defaultMessage: 'Register absence'
    }

    if(requireApproval && !absenceAdmin && !editor && !approver) {
        title = {
            id: 'absence_action_request',
            defaultMessage: 'Request absence'
        }
    } else if(id) {
        title = {
            id: 'absence_action_update',
            defaultMessage: 'Update absence'
        }
    }

    let submitText = {
        id: 'action_register',
        defaultMessage: 'Register'
    }

    if(requireApproval && !absenceAdmin && !editor && !approver) {
        submitText = {
            id: 'action_request',
            defaultMessage: 'Request'
        }
    } else if(id) {
        submitText = {
            id: 'action_save',
            defaultMessage: 'Save'
        }
    }

    const timePicker = {}
    let allowOpenEnded = !requireEndDate

    if(!!id && !!originalStart.current && isBefore(originalStart.current, startOfDay(now)) && !approver && !['pending', 'rejected'].includes(status)) {
        timePicker.after = subDays(originalEnd.current ?? originalStart.current, 1)
        timePicker.startDateLocked = true

        timePicker.message = {
            type: 'info',
            message: formatMessage({
                id: 'absence_message_editing_start_date_restricted',
                defaultMessage: 'Only administrators can change the start date of an ongoing or past absence.'
            })
        }

        allowOpenEnded = allowOpenEnded && !originalEnd?.current
    }

    const infoMessages = compact([
        (allowGraded && gradeValue < 1) && {
            id: (gradedAggregate === 'full') ?
                'absence_message_counts_as_grading_full' :
                'absence_message_counts_as_grading_partial',
            defaultMessage: (gradedAggregate === 'full') ?
                'Absence registered with less than 100 %, will still count as full days in Huma.' :
                'Absence registered with less than 100 %, will count as partial days in Huma and impact the balance accordingly.'
        },
        (type.privacy === 'private' && absenceAdmin) && {
            id: 'absence_message_register_private_absence',
            defaultMessage: 'This absence will be shown as ’{emoji} Away (private)’ for non-administrator users.',
            values: {
                emoji: (
                    <Emoji
                        emoji="clock5"
                        size={14} />
                )
            }
        }
    ])

    const salt = `absence:register:${type?.id}`

    return (
        <>
            <Heading>
                <FormattedMessage {...title} />
            </Heading>
            <Form
                layout="vertical"
                onSubmit={addOrUpdate}>
                {({ touched, errors, trigger, triggerChange }) => (
                    <>
                        <Fields>
                            <Person
                                size={24}
                                who={user ?? absentee} />
                            <EntityField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_absence_type',
                                    defaultMessage: 'Absence type'
                                })}
                                name="absenceType"
                                field={{
                                    value: type,
                                    required: true,
                                    unsettable: false
                                }}
                                toggler={{ mode: 'with-actions' }}
                                controlProps={{
                                    className: 'framed centered',
                                    autoFocus: !id
                                }}
                                entity={{
                                    type: 'absenceType',
                                    path: '/absence/types'
                                }}
                                picker={{
                                    heading: formatMessage({
                                        id: 'absence_type_action_pick',
                                        defaultMessage: 'Pick absence type'
                                    }),
                                    outer: false
                                }}
                                enabled={!isEditingLimited}
                                onChange={({ absenceType }) => setType(absenceType)} />
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'absence_type_label_time_period',
                                    defaultMessage: 'Time period'
                                })}
                                name="range"
                                field={{
                                    value: [start, end],
                                    range: true,
                                    allowOpenEnded,
                                    required: true,
                                    include: 'always',
                                    unsettable: false
                                }}
                                onChange={({ range }) => {
                                    const [
                                        start = null,
                                        end = null
                                    ] = (range ?? []).map(isofy)

                                    setStart(start)
                                    setEnd(end)
                                }}
                                picker={{
                                    ...timePicker,
                                    outer: false,
                                    markedDateRanges,
                                    ...dateRestrictions,
                                    blockedDateRangesMessage: formatMessage({
                                        id: 'absence_message_blocked_date_ranges',
                                        defaultMessage: 'One or more of the selected dates are not covered by a {type} policy.'
                                    }, {
                                        type: typeName
                                    }),
                                    holidaysCountryCode: workScheduleCountryCode
                                }}
                                controlProps={{ autoFocus: true }}
                                error={!!error}
                                ref={rangeRef} />
                            {!!allowGraded && (
                                <>
                                    <StringField
                                        salt={salt}
                                        className="compact"
                                        label={formatMessage({
                                            id: 'noun_percentage',
                                            defaultMessage: 'Percentage'
                                        })}
                                        name="grade"
                                        field={{
                                            value: decimalToPercent(gradeValue),
                                            required: !isEditingLimited,
                                            suffix: '%'
                                        }}
                                        controlProps={{
                                            type: 'number',
                                            min: 0,
                                            max: 100,
                                            step: 1,
                                            pattern: integerPattern
                                        }}
                                        enabled={!isEditingLimited}
                                        onChange={({ grade }) => setGradeValue(percentToDecimal(grade))}
                                        key={`${salt}:grade:${gradePercentage ?? 'undefined'}`} />
                                    <GradeShortcuts
                                        setGradePercentage={setGradePercentage}
                                        gradeValue={gradeValue}
                                        setGradeValue={setGradeValue}
                                        enabled={!isEditingLimited}
                                        triggerChange={triggerChange}
                                        salt={salt} />
                                </>
                            )}
                            <TextField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_notes',
                                    defaultMessage: 'Notes'
                                })}
                                name="note"
                                field={{
                                    value: note ?? null,
                                    required: requireNote
                                }}
                                controlProps={{
                                    placeholder: formatMessage({
                                        id: 'absence_placeholder_notes',
                                        defaultMessage: 'Add additional information'
                                    }),
                                    maxLength: 255
                                }}
                                onChange={({ note }) => setNoteText(note)} />
                            <Information
                                animate={!!noteText ? 'reveal' : 'hide'}>
                                <FormattedMessage
                                    id="absence_notes_warning_sensitive_information"
                                    defaultMessage="Do not provide sensitive personal information, such as details about any health conditions." />
                            </Information>
                            {!!allowWorkRelatedCause && (
                                <CheckboxField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'absence_label_work_related',
                                        defaultMessage: 'Work related'
                                    })}
                                    name="workRelated"
                                    field={{
                                        value: workRelated ?? false,
                                        optional: formatMessage({
                                            id: 'absence_label_work_related_explainer',
                                            defaultMessage: 'Is the absence an injury or illness caused or aggrevated by an event or exposure in the work environment?'
                                        })
                                    }}
                                    enabled={!isEditingLimited}
                                    interaction="switch" />
                            )}
                            {(!!start && !end && !requireEndDate) && (
                                <Message
                                    type="warning"
                                    message={formatMessage({
                                        id: 'absence_message_no_end_date',
                                        defaultMessage: 'This absence period will continue until you set an end date.'
                                    })} />
                            )}
                            {(!!start && estimate && !estimating) && (
                                <>
                                    <CountsAs>
                                        <Caption className="compact">
                                            <FormattedMessage
                                                id="absence_label_counts_as"
                                                defaultMessage="Counts as" />
                                        </Caption>
                                        <Paragraph className="compact">
                                            <FormattedMessage
                                                id="days_count"
                                                defaultMessage="{count, plural, =0 {0 days} =1 {1 day} other {{count} days}}"
                                                values={{ count: estimate.details?.reduce((aggregator, { newSum, currentSum, newCount, currentCount }) => {
                                                    const totalCount = gradedAggregate === 'full' ? newCount : newSum
                                                    const current = gradedAggregate === 'full' ? currentCount : currentSum

                                                    let result = aggregator + (totalCount - current)

                                                    return parseFloat(result.toFixed(2))
                                                }, 0) }} />
                                        </Paragraph>
                                    </CountsAs>
                                    {(
                                        status !== 'canceled' &&
                                        (
                                            (estimate?.details?.[0].policy?.timeWindow?.type === 'rolling' && !(isFuture({ start }, now) && !end)) ||
                                            estimate?.details?.[0].policy?.timeWindow?.type === 'fixed'
                                        )
                                    ) && (
                                        <TotalBalance
                                            estimate={estimate}
                                            dateRange={{ start, end }}
                                            status={status}
                                            absenceAdmin={absenceAdmin}
                                            permissionLevel={permissionLevel}
                                            type={type} />
                                    )}
                                </>
                            )}
                            {!!estimating && (
                                <>
                                    <CountsAs>
                                        <SkeletonStrings size={24} />
                                    </CountsAs>
                                    {(status !== 'canceled') && <TotalBalanceLoader type={type} />}
                                </>
                            )}
                            {(!!start && error && !estimating) && (
                                <>
                                    {errorTypesWithMessage.includes(error?.errorType) && (
                                        <Message
                                            type="error"
                                            message={formatMessage({
                                                id: `absence_error_message_${error?.errorType}`,
                                                defaultMessage: 'An error occured. Please try again.'
                                            }, {
                                                feedback: chunks => (
                                                    <Link onClick={() => {
                                                        onDone?.()
                                                        openChat()
                                                    }}>
                                                        {chunks} <ExternalLink size={16} />
                                                    </Link>
                                                )
                                            })} />
                                    )}
                                    {!errorTypesWithMessage.includes(error?.errorType) && (
                                        <>
                                            <Caption>
                                                <FormattedMessage
                                                    id={`absence_error_heading_${error.errorType}`}
                                                    defaultMessage={error.errorMessage} />
                                            </Caption>
                                            <OverlappingEntries>
                                                {error?.overlappingEntries?.map(entry => (
                                                    <Entry
                                                        entry={enrichEntry(entry)}
                                                        compact={true}
                                                        key={`absence:entries:entry:conflict:${entry.id}`} />
                                                ))}
                                            </OverlappingEntries>
                                            {absenceAdmin && (
                                                <OneOfField
                                                    salt={salt}
                                                    label={false}
                                                    name="overrideMode"
                                                    field={{
                                                        value: 'DELETE',
                                                        include: 'always',
                                                        required: true,
                                                        options: [
                                                            {
                                                                value: 'DELETE',
                                                                label: formatMessage({
                                                                    id: 'absence_label_overlapping_delete',
                                                                    defaultMessage: 'Replace overlapping absence'
                                                                }),
                                                                content: (
                                                                    <>
                                                                        <Paragraph>
                                                                            <FormattedMessage
                                                                                id="absence_message_overlapping_delete"
                                                                                defaultMessage="Conflicting dates will be replaced with the new entry. Any potential balance will be restored." />
                                                                        </Paragraph>
                                                                        <OverlappingExample type="delete" />
                                                                    </>
                                                                )
                                                            },
                                                            {
                                                                value: 'KEEP',
                                                                label: formatMessage({
                                                                    id: 'absence_label_overlapping_keep',
                                                                    defaultMessage: 'Add as overlapping absence'
                                                                }),
                                                                content: (
                                                                    <>
                                                                        <InlineMessage
                                                                            type="warning"
                                                                            message={formatMessage({
                                                                                id: 'absence_message_overlapping_keep',
                                                                                defaultMessage: 'Please note that this can result in more than 100 % absence.'
                                                                            })} />
                                                                        {error.overlappingEntries?.some(entry => entry.type.name === type.name) && (
                                                                            <InlineMessage
                                                                                type="info"
                                                                                message={formatMessage({
                                                                                    id: 'absence_message_overlapping_keep_same_type',
                                                                                    defaultMessage: 'Absences of the same type will be canceled.'
                                                                                })} />
                                                                        )}
                                                                        <OverlappingExample type="keep" />
                                                                    </>
                                                                )
                                                            }
                                                        ]
                                                    }} />
                                            )}
                                            {!absenceAdmin && (
                                                <Message
                                                    type="warning"
                                                    message={formatMessage({
                                                        id: 'absence_message_overlapping_user',
                                                        defaultMessage: 'Only administrators can register and resolve overlapping absences.'
                                                    })} />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {!!infoMessages?.length && (
                                <InfoMessage
                                    type="info"
                                    message={<FormattedMessage {...infoMessages[0]} />}>
                                    {infoMessages?.[1] && (
                                        <ParagraphSmall className="compact">
                                            <FormattedMessage {...infoMessages[1]} />
                                        </ParagraphSmall>
                                    )}
                                </InfoMessage>
                            )}
                        </Fields>
                        <Actions>
                            <Plain onClick={dismiss}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${(constructing) ? ' loading' : ''}`}
                                disabled={[
                                    (!touched.length && !overrideTouched),
                                    !!size(errors),
                                    constructing,
                                    estimating,
                                    (error && error.errorType !== 'overlapping_time_range'),
                                    (error?.errorType === 'overlapping_time_range' && !absenceAdmin)
                                ].some(Boolean)}
                                ref={trigger}>
                                <FormattedMessage {...submitText} />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

const Loading = () => (
    <LoadingContainer>
        <Loader />
    </LoadingContainer>
)

const TypeUserPicker = ({
    user, setUser,
    type, setType,
    dismiss = null,
    onDone
}) => {
    const { formatMessage } = useIntl()

    const userRef = useRef()

    const { isItMyOwnId } = useMe()

    const [userPredefined] = useState(!!user)
    const [triggered, setTriggered] = useState(false)

    if(!dismiss) {
        dismiss = onDone
    }

    useEffect(() => {
        if(!!userRef?.current && !triggered && !type) {
            userRef.current.click()
            setTriggered(true)
        }
    }, [userRef?.current, triggered])

    useEffect(() => {
        if(type) {
            setType(type)
        }
    }, [type])

    const { setUnitId } = useUserPermissions()

    const {
        fetchingTypes,
        setUserId,
        ...absenceStats
    } = useAbsenceStats()

    const stats = absenceStats.stats ?? absenceStats.types

    const salt = 'absence:register:type-user-picker'

    return (
        <>
            <Heading>
                <FormattedMessage
                    id="absence_action_register"
                    defaultMessage="Register absence" />
            </Heading>
            <Form layout="vertical">
                {!userPredefined && (
                    <PersonField
                        salt={salt}
                        label={formatMessage({
                            id: 'preposition_for',
                            defaultMessage: 'For'
                        })}
                        name="user"
                        onChange={({ user }) => {
                            setUnitId(user?.id)
                            setUserId(user?.id)
                            setUser(user)
                        }}
                        ref={userRef} />
                )}
                {(!!userPredefined && !isItMyOwnId(user?.id)) && (
                    <Person
                        size={24}
                        who={user} />
                )}
                {!type && (
                    <>
                        {(!!fetchingTypes || !stats?.length) && <Loading />}
                        {!!stats?.length && (
                            <AbsenceTypeList>
                                {stats.map(({ type }) => (
                                    <AbsenceTypeListItem
                                        type={type}
                                        {...(!!user ? {
                                            onClick: () => setType(type)
                                        } : null)}
                                        actionIcon={ArrowRight}
                                        key={type.id} />
                                ))}
                            </AbsenceTypeList>
                        )}
                    </>
                )}
                <Actions className="centered">
                    <Plain onClick={dismiss}>
                        <FormattedMessage
                            id="action_cancel"
                            defaultMessage="Cancel" />
                    </Plain>
                </Actions>
            </Form>
        </>
    )
}

const errorTypesWithMessage = ['policy_missing_in_time_range']

const UserPoliciesProvider = ({ children, ...props }) => (
    <AbsenceUserPeriodsProvider
        userId={props.user.id}
        typeId={props.type.id}>
        <WorkScheduleProvider>
            {Children.map(children, child => {
                if(isValidElement(child)) {
                    return cloneElement(child, props)
                }

                return child
            })}
        </WorkScheduleProvider>
    </AbsenceUserPeriodsProvider>
)

const TypeCountUserProvider = ({
    type: initialType,
    user: initialUser,
    children, ...props
}) => {
    const [type, setType] = useState(initialType)
    const [user, setUser] = useState(initialUser)

    if(!type || !user) {
        return (
            <TypeUserPicker
                {...props}
                type={type}
                setType={setType}
                user={user}
                setUser={setUser} />
        )
    }

    props = {
        ...props,
        user,
        type,
        setType
    }

    if(typeof children === 'function') {
        return children(props)
    }

    return Children.map(children, child => {
        if(isValidElement(child)) {
            return cloneElement(child, props)
        }

        return child
    })
}

const EntryProvider = props => {
    const { entry } = useAbsenceEntry()

    if(!entry) {
        return <Loading />
    }

    return (
        <EditEntry
            {...props}
            entry={entry} />
    )
}

const AbsenceProvider = ({ provideUserPermissions = true, provide = false, entryId = null, ...props }) => {
    const [UserPermissionsWrapper, userPermissionsProps = null] = provideUserPermissions ?
        [UserPermissionsProvider, props.user ?
            { id: props.user.id } :
            null
        ] :
        [Fragment]

    const AbsenceEntryWrapper = provide ?
        AbsenceEntryProvider :
        Fragment

    const absenceEntryProps = provide ? {
        id: entryId
    } : null

    return (
        <UserPermissionsWrapper {...userPermissionsProps}>
            <AbsenceEntryWrapper {...absenceEntryProps}>
                <TypeCountUserProvider {...props}>
                    {typeCountUserProps => (
                        <UserPoliciesProvider
                            {...props}
                            {...typeCountUserProps}>
                            {!!provide && <EntryProvider {...props} />}
                            {!provide && <EditEntry {...props} />}
                        </UserPoliciesProvider>
                    )}
                </TypeCountUserProvider>
            </AbsenceEntryWrapper>
        </UserPermissionsWrapper>
    )
}

export default ({ provide = false, ...props }) => {
    provide = provide && !props.entry

    const {
        hasFetched: hasFetchedAbsenceTypes,
        types
    } = useAbsenceTypes()

    const absenceStatsContext = useAbsenceStats()

    if(!hasFetchedAbsenceTypes) {
        return <Loading />
    }

    if(!props.type && !!props.entry?.type && types?.length) {
        if(typeof props.entry.type === 'string') {
            props.type = types.find(({ name }) => name === props.entry.type)
        } else {
            props.type = types.find(({ id }) => id === props.entry.type.id)
        }
    }

    if(!props.user && !!props.entry?.user) {
        props.user = props.entry.user
    }

    if(absenceStatsContext) {
        return (
            <AbsenceProvider
                provide={provide}
                {...props} />
        )
    }

    return (
        <AbsenceStatsProvider
            {...(props.user ? { userId: props.user.id } : null)}
            fetchTypesPreliminarily={true}>
            <AbsenceProvider
                provide={provide}
                {...props} />
        </AbsenceStatsProvider>
    )
}