import styled from 'styled-components'
import { svgStroke, buttonOutlineProps } from 'utilities/styled'

export const Button = styled.button`
    cursor: pointer;

    display: grid;
    place-items: center;

    border: unset;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    padding: 0;
    background: unset;

    svg {
        ${svgStroke('small')}
    }

    &:not(:active):focus-visible {
        outline-offset: ${buttonOutlineProps.offset}px;
        outline-width: ${buttonOutlineProps.width}px;
        outline-style: solid;
    }

    @media (prefers-reduced-motion: no-preference) {
        &:focus-visible {
            transition: outline-offset 75ms ease-out;
        }

        &:not(:active):focus-visible {
            transition-duration: 0.1s;
        }
    }

    &.add {
        outline-color: var(--huma-color-border-constructive-focus);

        color: var(--huma-color-foreground-subtle);

        .state-none:not(.state-hover) {
            display: block;
        }

        .state-hover:not(.state-none) {
            display: none;
        }

        &:where(:hover, :focus-visible) {
            background-color: var(--huma-color-surface-constructive-minimal);

            color: var(--huma-color-foreground-constructive-bold);

            .state-none:not(.state-hover) {
                display: none;
            }

            .state-hover:not(.state-none) {
                display: block;
            }
        }
    }

    &.warning {
        outline-color: var(--huma-color-border-warning-focus);

        color: var(--huma-color-foreground-warning);

        &:where(:hover, :focus-visible) {
            background-color: var(--huma-color-surface-warning-minimal);
        }
    }

    &.success {
        outline-color: var(--huma-color-border-success-focus);

        color: var(--huma-color-foreground-success);

        &:where(:hover, :focus-visible) {
            background-color: var(--huma-color-surface-success-minimal);
        }
    }

    &.progress {
        outline-color: var(--huma-color-border-default-focus);

        &:where(:hover, :focus-visible) {
            background-color: var(--huma-color-surface-minimal);
        }
    }
`

export const EmptyNode = styled.div`
    display: grid;
    place-items: center;

    border-radius: 8px;
    width: 40px;
    height: 40px;

    svg {
        ${svgStroke('small')}
    }
`