import { useEffect } from 'react'
import { useEnvironment } from 'contexts/environment'
import Bugsnag from '@bugsnag/browser'
import BugsnagReact from '@bugsnag/plugin-react'

const BugsnagReporter = () => {
    const {
        integrations,
        environment
    } = useEnvironment()

    useEffect(() => {
        if(integrations && environment) {
            const { bugsnagApiKey: apiKey } = integrations

            const {
                stage: releaseStage,
                commit
            } = environment

            if(!!apiKey && !!releaseStage && releaseStage !== 'local') {
                Bugsnag.start({
                    plugins: [new BugsnagReact()],
                    apiKey,
                    releaseStage,
                    ...(commit ? { appVersion: commit.slice(0, 8) } : null)
                })
            }
        }
    }, [integrations, environment])

    return null
}

export default BugsnagReporter