import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompetenceRecord } from 'contexts/competence-record'
import {
    Documentation,
    List,
    Item, File,
    ShowAllButton, ShowAllCount
} from './s'
import Link from './link'

const ViewCompetenceRecordDocumentation = ({ record, getPreviewUrl, getDownloadUrl, salt }) => {
    const [showingAll, setShowingAll] = useState(false)

    const {
        id: recordId,
        attachments = [],
        links = []
    } = record

    const documentationItems = [
        ...attachments ?? [],
        ...links ?? []
    ]

    const documentationCut = showingAll ? documentationItems.length : 3

    return (
        <Documentation>
            <List inline={true}>
                {documentationItems
                    .slice(0, documentationCut)
                    .map(documentation => {
                        if(documentation?.id) {
                            const file = {
                                meta: documentation,
                                displayProps: { size: 16 }
                            }

                            if(getPreviewUrl && getDownloadUrl) {
                                file.preview = { path: getPreviewUrl(recordId, documentation.id) }
                                file.download = { path: getDownloadUrl(recordId, documentation.id) }
                            }

                            return (
                                <Item key={`${salt}:attachment:${documentation.id}`}>
                                    <File {...file} />
                                </Item>
                            )
                        }

                        return (
                            <Item key={`${salt}:link:${documentation.url}:${documentation.name}`}>
                                <Link link={documentation} />
                            </Item>
                        )
                    }
                )}
            </List>
            {(documentationItems.length > 3 && !showingAll) && (
                <ShowAllButton
                    onClick={() => setShowingAll(true)}
                    className="constructive">
                    <span>
                        <FormattedMessage
                            id="action_show_all"
                            defaultMessage="Show all" />
                    </span>
                    <ShowAllCount>+ {documentationItems.length - 3}</ShowAllCount>
                </ShowAllButton>
            )}
        </Documentation>
    )
}

const PieceProvider = props => {
    const {
        getDocumentationPreviewUrl,
        getDocumentationDownloadUrl
    } = useCompetenceRecord()

    return (
        <ViewCompetenceRecordDocumentation
            {...props}
            getPreviewUrl={getDocumentationPreviewUrl}
            getDownloadUrl={getDocumentationDownloadUrl} />
    )
}

export default ({ provide, ...props }) => {
    if(provide) {
        return <PieceProvider {...props} />
    }

    return <ViewCompetenceRecordDocumentation {...props} />
}
