import styled from 'styled-components'

export const Wrapper = styled.div`
    display: grid;
    grid-template-rows: 0fr;
    grid-area: announcements / full-width;

    transition: grid-template-rows .5s cubic-bezier(0.34, 1.56, 0.64, 1);

    &.show {
        grid-template-rows: 1fr;
    }
`
export const Inner = styled.div`
    overflow: hidden;
`