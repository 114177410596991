import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { H2 } from 'components/typography/heading'
import { InlineButton } from 'components/button'
import { ListItem as ListItemBase } from 'components/list'

export const Heading = styled(H2)`
    margin-block-end: 24px;
    max-width: calc(100% - 40px);

    line-height: 1.25;
`

export const ListItem = styled(ListItemBase)`
    display: grid;
    grid-template-columns: 16px auto;
    column-gap: 4px;

    line-height: 1.5;
    color: var(--huma-color-foreground-subtle);

    &:not(:last-of-type) {
        margin-block-end: 16px;
    }

    svg {
        ${svgStroke()}

        margin-block-start: 3px;
    }

    &.true svg {
        color: var(--huma-color-foreground-success);
    }

    &.false svg {
        color: var(--huma-color-foreground-error);
    }
`

export const InspectStatusesButton = styled(InlineButton)`
    display: block;
    line-height: 24px;
    margin-block-start: 16px;
`