import styled from 'styled-components'
import { FlexChildGrow } from 'components/flex'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const Wrapper = styled.div`
    max-height: calc(60vh - 120px);
    overflow-y: auto;
    margin-inline: -32px;
    margin-bottom: 8px;
`

export const ContentRow = styled(FlexChildGrow)`
    position: relative;

    padding-inline: 32px;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    padding-block: 16px;
`