import styled, { css } from 'styled-components'
import {
    svgStroke, interpointDivider,
    belowTablet, fromTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import LinkBase from 'components/link'
import { CaptionSmall } from 'components/typography/caption'
import UpgradeBase from 'pages/settings/components/upgrade'

const moduleItemCss = css`
    grid-template-columns: 40px minmax(0, 1fr) min-content;
    column-gap: 16px;
    padding: 24px 0;

    &.has-quota:not(.quick-guide) {
        grid-template-columns: 40px minmax(0, 1fr) minmax(0, 2fr) min-content;

        ${belowTablet`
            grid-template-columns: 40px minmax(0, 1fr) min-content;
        `}
    }

    &.has-features {
        grid-auto-flow: row;
        row-gap: 8px;
    }

    &.disabled {
        color: var(--huma-color-foreground-subtle);
    }

    &:not(:last-of-type) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    ${belowTablet`
        row-gap: 8px;
    `}
`

export const ModuleItem = styled(Grid)`
    ${moduleItemCss}

    &.quick-guide {
        grid-template-columns: 40px minmax(0, 1fr) min-content 40px;
        grid-row: 1;
        grid-column: 1 / -1;
    }
`

export const FeatureItem = styled(Grid)`
    ${moduleItemCss}

    grid-template-columns: 16px minmax(0, 5fr) minmax(0, 3fr);
    padding: 16px 0;

    &.togglable,
    &.upgradable {
        grid-template-columns: 16px minmax(0, 1fr) minmax(0, auto);
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    ${belowTablet`
        grid-template-columns: 40px minmax(0, 1fr) min-content !important;
    `}
`

export const Name = styled.p`
    display: inline-flex;
    align-items: center;
    column-gap: 8px;

    margin: 0;
    padding: 2px 0;

    line-height: 20px;

    &.heading {
        font-size: 20px;
        font-weight: 500;
    }

    ${ModuleItem}.disabled & {
        text-decoration: line-through;
    }
`

export const NameMeta = styled.div`
    display: flex;
    flex-wrap: wrap;

    font-size: 14px;
    line-height: 20px;
    color: var(--huma-color-foreground-subtle);

    ${FeatureItem}.disabled & {
        color: var(--huma-color-foreground-minimal);
    }

    ${interpointDivider()}
`

export const Link = styled(LinkBase)``

export const QuotaCell = styled.div`
    display: flex;
    column-gap: 16px;
    grid-row: 1;
    line-height: 20px;

    ${ModuleItem}.quick-guide.has-quota.has-features & {
        grid-row: 2 / 3;
        grid-column: 2 / -1;
    }

    button {
        border: 0;
    }

    ${belowTablet`
        grid-row: 2 / 3;
        grid-column: 2 / span 2;
        flex-direction: column;
        padding-block-start: 8px;
    `}
`

export const Quota = styled(CaptionSmall)`
    width: 100%;

    ${ModuleItem}:not(.quick-guide) & {
        ${fromTablet`
            text-align: end;
        `}
    }
`

export const NameCell = styled(Grid)`
    grid-row: 1 / 2;
    grid-template-rows: minmax(24px, auto) auto;

    &:is(.no-meta) {
        grid-template-rows: minmax(24px, auto);
        align-items: center;
    }

    ${fromTablet`
        ${ModuleItem}:not(.quick-guide) > &:has(${NameMeta}) + ${QuotaCell} {
            padding-block-start: 24px;
            padding-inline-end: 24px;
        }

        ${FeatureItem}:not(.quick-guide) > &:has(${NameMeta}) + ${QuotaCell} {
            padding-block-start: 24px;
            padding-inline-start: 24px;
        }
    `}
`

export const ActionCell = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
    grid-row: 1 / 2;

    & > ${Link} {
        font-size: 14px;
        white-space: nowrap;
    }

    ${belowTablet`
        ${FeatureItem} & {
            align-self: start;
        }
    `}

    ${FeatureItem}.disabled & {
        color: var(--huma-color-foreground-subtle);
    }
`

export const Features = styled.div`
    grid-row: 2 / 3;
    grid-column: 2 / -1;

    ${ModuleItem}.quick-guide & {
        grid-column: 2 / 4;

        ${belowTablet`
            grid-row: 2;
            grid-column: 1 / -1;
        `}
    }

    ${ModuleItem}.quick-guide.has-quota.has-features & {
        grid-row: 3;
    }

    &.hidden {
        display: none;
    }

    ${belowTablet`
        grid-row: 3;
        grid-column: 1 / -1;
    `}
`

export const IconCell = styled.div`
    display: grid;
    grid-row: 1 / 2;
    place-items: center;
    width: 40px;
    aspect-ratio: 1;

    ${Features} & {
        align-self: start;

        width: 16px;
        padding-top: 3px;

        ${belowTablet`
            align-items: start;

            width: 40px;
        `}
    }

    svg {
        ${svgStroke()}
    }
`

export const Upgrade = styled(UpgradeBase)`
    .quick-guide & {
        ${belowTablet`
            /* Simulate icon only */
            justify-content: center;

            aspect-ratio: 1;
            padding-inline: 0;

            span {
                display: none;
            }
        `}
    }
`