import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCompetenceRecord } from 'contexts/competence-record'
import { pick, omit } from 'utilities/object'
import { Heading, Fields } from './s'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { size } from 'utilities/object'

const EditLink = ({ link = {}, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        record,
        updateRecord
    } = useCompetenceRecord()

    const [constructing, setConstructing] = useState(false)

    const {
        type,
        category
    } = record
    const { id: typeId } = type

    const {
        name,
        url
    } = link

    const isAdding = !!size(link)

    const addOrUpdate = async body => {
        setConstructing(true)
        let links

        const link = pick(body, 'name', 'url')

        if(isAdding) {
            const index = record.links.findIndex(link => link.url === url)

            links = [
                ...record.links.slice(0, index),
                link,
                ...record.links.slice(index + 1, record.links.length)
            ]
        } else {
            links = [
                link,
                ...(!!record?.links?.length ? record.links : [])
            ]
        }

        body = {
            typeId,
            category,
            ...omit(body, 'name', 'url'),
            links
        }

        const { ok } = await updateRecord(body, record?.id)

        setConstructing(false)

        if(ok) {
            onDone && onDone()
        }
    }

    salt = `${salt}:edit`

    const heading = formatMessage({
        id: isAdding ? 'group_links_edit_title' : 'group_links_add_title',
        defaultMessage: isAdding ? 'Edit link' : 'Add link'
    })

    return (
        <Form
            layout="vertical"
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <Heading>{heading}</Heading>
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="name"
                            controlProps={{ autoFocus: true }}
                            field={{
                                value: name,
                                required: true,
                                include: 'always'
                            }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_link',
                                defaultMessage: 'Link'
                            })}
                            name="url"
                            field={{
                                value: url,
                                required: true,
                                strict: false,
                                include: 'always'
                            }}
                            controlProps={{ type: 'url' }} />
                    </Fields>
                    <Actions>
                        <Plain
                            className="neutral"
                            onClick={onDone}
                            disabled={constructing}>
                            <FormattedMessage
                                id="action_ancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={!touched.length || !!size(errors) || constructing}
                            ref={trigger}>
                            <FormattedMessage
                                id={isAdding ? 'action_save' : 'action_add'}
                                defaultMessage={isAdding ? 'Save' : 'Add'} />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default EditLink