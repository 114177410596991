import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import PersonProvider, { usePerson } from 'contexts/person'
import { size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { ModalHeader } from 'modals/generic'
import Steps from 'components/steps'
import Paragraph from 'components/typography/paragraph'
import Form from 'components/form/controller'
import PhoneNumberField from 'components/form/field/phone-number'
import { Error } from './s'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const SetPhoneNumber = ({ user, onDone, modal }) => {
    const { formatMessage } = useIntl()

    const { updatePerson } = usePerson()

    const [saving, setSaving] = useState(false)
    const [customError, setCustomError] = useState(null)
    const [nonNorwegianNumberInfo, setNonNorwegianNumberInfo] = useState(false)

    const update = async (body, { resetTouched }) => {
        setSaving(true)
        const { ok, response } = await updatePerson(body)
        setSaving(false)

        if(ok) {
            resetTouched()

            if(body.phone.startsWith('+47')) {
                onDone(body.phone)
                modal.dismiss()
            } else {
                setNonNorwegianNumberInfo(formatMessage({
                    id: 'dashboard_jubilees_gift_phone_number_nonnorwegian_stored_info',
                    defaultMessage: 'The phone number you provided is not Norwegian, but it has been saved.'
                }))
            }
        } else {
            if(response?.errorCode === 'field:invalid-format') {
                setCustomError(formatMessage({
                    id: 'phone_error_invalid',
                    defaultMessage: 'Invalid phone number'
                }))
            } else if(response?.errorCode === 'identifier:duplicate') {
                setCustomError(formatMessage({
                    id: 'phone_error_already_exists',
                    defaultMessage: 'Phone number already in use'
                }))
            }
        }
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'person_action_send_gift',
                    defaultMessage: 'Send a gift'
                })}
                dismiss={modal.dismiss} />
            <Steps
                steps={[
                    {
                        text: formatMessage({
                            id: 'person_action_set_phone_number',
                            defaultMessage: 'Set phone number'
                        })
                    },
                    {
                        text: formatMessage({
                            id: 'person_action_send_gift',
                            defaultMessage: 'Send a gift'
                        })
                    }
                ]}
                at={0} />
            <Paragraph>
                <FormattedMessage
                    id="dashboard_jubilees_gift_phone_number_nonnorwegian_lead"
                    defaultMessage="You can currently only send gifts to users with Norwegian phone numbers." />
            </Paragraph>
            <Form
                layout="vertical"
                onSubmit={update}
                onChange={() => setCustomError(null)}>
                {({ values, touched, errors, trigger }) => (
                    <>
                        <PhoneNumberField
                            salt={`jubilee:person:${user?.id}`}
                            label={formatMessage({
                                id: 'person_label_phonenumber',
                                defaultMessage: 'Phone number'
                            })}
                            name="phone"
                            field={{ include: 'always' }}
                            controlProps={{
                                autoComplete: 'tel',
                                'data-1p-ignore': 'false'
                            }} />
                        <Error animate={!!customError ? 'reveal' : 'hide'}>
                            {customError}
                        </Error>
                        {!!nonNorwegianNumberInfo && (
                            <Message
                                type="info"
                                message={nonNorwegianNumberInfo} />
                        )}
                        <Actions className={cls('compact', nonNorwegianNumberInfo && 'centered')}>
                            {!nonNorwegianNumberInfo && (
                                <Plain
                                    onClick={modal.dismiss}
                                    disabled={saving}>
                                    <FormattedMessage
                                        id="action_cancel"
                                        defaultMessage="Cancel" />
                                </Plain>
                            )}
                            {!nonNorwegianNumberInfo && (
                                <ButtonSubmit
                                    className={`constructive${saving ? ' loading' : ''}`}
                                    disabled={!touched.length || !!size(errors) || saving}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id="action_proceed"
                                        defaultMessage="Proceed" />
                                </ButtonSubmit>
                            )}
                            {!!nonNorwegianNumberInfo && (
                                <Plain onClick={() => {
                                    onDone(values.phone)
                                    modal.dismiss()
                                }}>
                                    <FormattedMessage
                                        id="action_done"
                                        defaultMessage="Done" />
                                </Plain>
                            )}
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => (
    <PersonProvider
        id={props.user?.id}
        fetchOnMount={false}>
        <SetPhoneNumber {...props} />
    </PersonProvider>
)