import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useOrganization } from 'contexts/organization'
import { size } from 'utilities/object'
import { ModalHeading } from 'components/typography/heading'
import Form from 'components/form/controller'
import TextField from 'components/form/field/text'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const HandbookNotify = ({ what, id, dismiss, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const { notify } = useHandbook()

    const { organization } = useOrganization()

    const [updating, setUpdating] = useState(false)

    const sendNotification = async body => {
        setUpdating(true)

        const { ok } = await notify(
            what,
            body.notificationMessage,
            id
        )

        setUpdating(false)

        ok && onDone?.(what)
    }

    const placeholder = {
        handbook: {
            id: 'handbooks_placeholder_publish_notification',
            defaultMessage: 'The handbook is now published.'
        },
        category: {
            id: 'handbooks_placeholder_publish_category_notification',
            defaultMessage: 'Check out this category!'
        },
        topic: {
            id: 'handbooks_placeholder_publish_topic_notification',
            defaultMessage: 'Check out this topic!'
        }
    }[what]

    const infoMessage = {
        handbook: {
            id: 'handbooks_message_handbook_notification',
            defaultMessage: 'Everyone in {organization} will be notified, but only people who have access to at least one topic will be able to access the handbook.'
        },
        category: {
            id: 'handbooks_message_category_notification',
            defaultMessage: 'People with access to any of the topics in this category will be notified.'
        },
        topic: {
            id: 'handbooks_message_topic_notification',
            defaultMessage: 'People with access to this topic will be notified.'
        }
    }[what]

    return (
        <Form
            layout="vertical"
            onSubmit={sendNotification}>
            {({ errors }) => (
                <>
                    <ModalHeading>
                        <FormattedMessage
                            id="notification_toggle_label"
                            defaultMessage="Send notification" />
                    </ModalHeading>
                    <TextField
                        salt={salt}
                        label={formatMessage({
                            id: 'notification_message_label',
                            defaultMessage: 'Notification message'
                        })}
                        name="notificationMessage"
                        field={{
                            value: '',
                            required: true,
                            include: 'always',
                            showCharacterCount: true
                        }}
                        controlProps={{
                            placeholder: formatMessage(placeholder),
                            maxLength: 100,
                            autoFocus: true
                        }} />
                    <Message
                        className="compact"
                        type="info"
                        message={formatMessage(infoMessage, { organization: organization.name })} />
                    <Actions>
                        <Plain
                            onClick={dismiss}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${updating ? ' loading' : ''}`}
                            disabled={updating || size(errors)}>
                            <FormattedMessage
                                id="notification_toggle_label"
                                defaultMessage="Send notification" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default HandbookNotify
