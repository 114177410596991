import styled from 'styled-components'
import { fromTablet } from 'utilities/styled'
import { motion } from 'framer-motion'
import { paragraphSmallCss } from 'components/typography/paragraph'

export const Error = styled(motion.p).attrs(() => ({
    className: 'destructive',
    variants: {
        hide: {
            height: 0,
            marginBottom: 0,
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            marginBottom: 16,
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    position: relative;
    top: -16px;
    ${paragraphSmallCss}

    ${fromTablet`
        top: -8px;
        padding-left: 200px;
    `}
`