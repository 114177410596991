import styled from 'styled-components'
import { interpointDivider } from 'utilities/styled'
import { Grid } from 'components/grid'
import SymbolBase from 'components/symbol'
import { siblingHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { Plain } from 'components/button'
import { captionSmallCss } from 'components/typography/caption'

export const Wrapper = styled(Grid)`
    grid-template-rows: 24px 20px;
    grid-template-columns: 40px auto min-content;
    align-items: center;
    column-gap: 16px;

    padding-block: 16px;

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }
`

export const PickButton = styled(Plain)`
    grid-row: 1 / -1;
`

export const Symbol = styled(SymbolBase)`
    ${siblingHover(PickButton)}

    grid-row: 1 / -1;
`

export const Name = styled(Paragraph)`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
`

export const Meta = styled.div`
    display: flex;
    align-items: center;
    grid-column: 2 / 3;
    grid-row: 2 / 3;

    ${interpointDivider()}

    span {
        ${captionSmallCss}
    }
`