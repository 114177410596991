import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useInvitationData } from 'hooks/invitation-data'
import { size } from 'utilities/object'
import { capitalize } from 'utilities/string'
import { SavedMessage, SavedMessageText, Clamp } from './s'
import { H2 as Heading } from 'components/typography/heading'
import Form from 'components/form/controller'
import CheckboxField from 'components/form/field/checkbox'
import TextField from 'components/form/field/text'
import SelectField from 'components/form/field/select'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { supportedLocales } from './'
import Email from './email'

const InspectInvitationContent = ({ title = null, invitationData, setInvitationData, onDone, salt, ...props }) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    const { locales } = useI18n()

    const { savedData, setSavedData, defaultData } = useInvitationData()
    const [data, setData] = useState({
        local: invitationData?.dataType === 'saved' ? defaultData : invitationData,
        saved: savedData
    })
    const [dataType, setDataType] = useState(invitationData?.dataType ?? 'local')

    if(!title) {
        title = formatMessage({
            id: 'noun_invitation',
            defaultMessage: 'Invitation'
        })
    }

    const updateField = (field, value) => {
        setData(previous => ({
            ...previous,
            [dataType]: {
                ...previous[dataType],
                [field]: value
            }
        }))
    }

    const update = body => {
        setInvitationData({
            ...data[dataType],
            ...body,
            dataType
        })

        setSavedData({
            ...data[dataType],
            ...body
        })

        onDone()
    }

    return (
        <Form
            layout="vertical"
            onSubmit={update}>
            {({ errors, touched, trigger, triggerChange }) => (
                <>
                    <Heading>{title}</Heading>
                    <CheckboxField
                        salt={salt}
                        name="useMessage"
                        label={formatMessage({
                            id: 'people_invite_label_custom_message',
                            defaultMessage: 'Add custom message'
                        })}
                        field={{ value: data[dataType].useMessage }}
                        interaction="switch"
                        onChange={({ useMessage }) => updateField('useMessage', useMessage)}
                        key={`${salt}:useMessage:${dataType}`} />
                    {!!data[dataType].useMessage && (
                        <TextField
                            salt={salt}
                            name="message"
                            label={formatMessage({
                                id: 'people_invite_label_message',
                                defaultMessage: 'Message'
                            })}
                            field={{
                                value: data[dataType].message,
                                required: true
                            }}
                            onChange={({ message }) => updateField('message', message)}
                            key={`${salt}:message:${dataType}`} />
                    )}
                    {(!!savedData?.useMessage && !!savedData?.message && dataType === 'local') && (
                        <SavedMessage>
                            <Clamp className="compact">
                                <FormattedMessage
                                    id="people_invite_last_message"
                                    defaultMessage="Last message: {message}"
                                    values={{ message: <SavedMessageText>{savedData.message}</SavedMessageText> }} />
                            </Clamp>
                            <Plain
                                className="constructive compact"
                                onClick={() => {
                                    setDataType('saved')

                                    global.setTimeout(() => triggerChange('message'), 50)
                                }}>
                                <FormattedMessage
                                    id="people_invite_action_use_last_message"
                                    defaultMessage="Use this" />
                            </Plain>
                        </SavedMessage>
                    )}
                    <SelectField
                        salt={salt}
                        name="language"
                        label={formatMessage({
                            id: 'people_invite_label_template_language',
                            defaultMessage: 'Template language'
                        })}
                        field={{
                            value: data[dataType].language,
                            allowEmptyOption: false,
                            required: true
                        }}
                        options={supportedLocales.map(locale => ({
                            key: locale,
                            value: locale,
                            text: `${locales[locale].flag} ${capitalize(formatDisplayName(locale, { type: 'language' }))}`
                        }))}
                        onChange={({ language }) => updateField('language', language)}
                        key={`${salt}:language:${dataType}`} />
                    <Email
                        {...props}
                        {...data[dataType]}
                        dataType={dataType}
                        setData={setData} />
                    <Actions>
                        <Plain
                            onClick={onDone}
                            className="neutral">
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className="constructive"
                            disabled={!!size(errors) || !touched?.length}
                            ref={trigger}>
                            <FormattedMessage
                                id="action_save"
                                defaultMessage="Save" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default InspectInvitationContent
