import React, { Component, createContext, useContext } from 'react'
import { get, patch, post, remove } from 'api'
import { useI18n } from './i18n'
import { getPluralizedType } from 'pages/people/utilities'
import isEqual from 'react-fast-compare'
import { pick } from 'utilities/object'

const GroupContext = createContext()

class GroupProvider extends Component {
    constructor(props) {
        super(props)

        this.setIdentifiers(props)

        this.state = {
            group: null,
            filter: props?.filter ?? {},
            deleted: false,

            replace: this.replace,

            fetchGroup: this.fetch,
            updateGroup: this.update,
            removeGroup: this.remove,

            addMembers: this.addMembers,
            removeMember: this.removeMember,

            addGrant: this.addGrant,
            removeGrant: this.removeGrant,

            hasFetched: false,
            fetching: false
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetch()
        }
    }

    componentDidUpdate(props) {
        const identifiersChanged = !isEqual(pick(props, 'type', 'id'), pick(this.props, 'type', 'id'))

        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        if(identifiersChanged) {
            this.replace(this.props)
        }

        if(!identifiersChanged && fetchAccessGained) {
            this.fetch()
        }
    }

    setIdentifiers = props => {
        this.type = props.type
        this.id = props.id
    }

    fetch = async () => {
        if(!this.id) {
            return
        }

        this.setState({ fetching: true })

        const { response: group, ok } = await get({ path: `/groups/${this.id}` })

        !!ok && this.setState({
            group,
            hasFetched: true,
            fetching: false
        })
    }

    update = async body => {
        if(!this.type || !this.id) {
            return { ok: false }
        }

        const { ok, response } = await patch({
            path: `/groups/${this.id}`,
            body: {
                ...body,
                type: this.type
            }
        })

        if(ok && response) {
            this.setState(({ group }) => ({
                group: {
                    ...response,
                    grants: group.grants
                }
            }))
        }

        return { ok, response }
    }

    remove = async () => {
        const { ok } = await remove({
            path: `/groups/${this.id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    addMembers = async (members = []) => {
        const body = members.map(({ id: userId }) => ({ userId }))

        const { ok } = await post({
            path: `/groups/${this.id}/members`,
            body,
            returnsData: false
        })

        if(ok) {
            this.setState(({ group }) => {
                // Make sure the person’s list of groups-of-same-type is updated with this group
                const pluralizedType = getPluralizedType(this.type)
                members = [
                    ...group.members,
                    ...members.map(({ [pluralizedType]: groups, ...rest }) => {
                        groups = [
                            ...groups,
                            group
                        ].sort(({ name: one }, { name: two }) => {
                            return one.localeCompare(two, this.props.i18n.locale, { sensitivity: 'base' })
                        })

                        return {
                            ...rest,
                            [pluralizedType]: groups
                        }
                    })
                ]

                return ({
                    group: {
                        ...group,
                        members
                    }
                })
            })
        }

        return { ok }
    }

    removeMember = async ({ userId }) => {
        const { ok } = await remove({
            path: `/groups/${this.id}/members/${userId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ group }) => ({
                group: {
                    ...group,
                    members: group.members.filter(({ id }) => id !== userId)
                }
            }))
        }

        return { ok }
    }

    addGrant = async body => {
        const { ok, response: grants } = await post({
            path: `/groups/${this.id}/grants`,
            body
        })

        if(ok && grants) {
            this.setState(({ group }) => ({
                group: {
                    ...group,
                    grants
                }
            }))
        }

        return { ok }
    }

    removeGrant = async ({ userId, roleId }) => {
        const { ok } = await remove({
            path: `/groups/${this.id}/grant`,
            body: { userId, roleId },
            returnsData: false
        })

        if(ok) {
            this.setState(({ group }) => {
                const grant = group.grants.find(({ role }) => role.id === roleId)

                if(grant.users) {
                    grant.users = grant.users.filter(({ id }) => id !== userId)
                }

                return {
                    group: {
                        ...group,
                        grants: group.grants.filter(({ users }) => !!users.length)
                    }
                }
            })
        }

        return { ok }
    }

    replace = props => {
        this.setIdentifiers(props)
        this.setState({ group: null }, this.fetch)
    }

    render() {
        const { children = null } = this.props

        return (
            <GroupContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </GroupContext.Provider>
        )
    }
}

export const useGroup = () => useContext(GroupContext)

export default props => {
    const i18n = useI18n()

    return (
        <GroupProvider
            {...props}
            i18n={i18n} />
    )
}