import styled, { css } from 'styled-components'
import {
    svgStroke,
    bp, belowLaptop, fromLaptop, darkMode
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { cls } from 'utilities/dom'
import { NavLink } from 'react-router-dom'
import Paragraph from 'components/typography/paragraph'
import { flexChildShrinkCss } from 'components/flex'
import { aiGradientCss } from 'components/content-assistant/s'
import StatusLabel from 'components/status-label'

export const Revealer = styled(motion.div).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            opacity: 0
        },
        show: {
            height: 'auto',
            opacity: 1
        }
    },
    transition: {
        type: 'spring',
        mass: .5,
        stiffness: 100,
        velocity: 2
    },
    initial: 'hide',
    exit: 'hide'
}))`
    --__huma-component-nav-menu-item-icon-size: 56px;

    position: relative;
    overflow: hidden;
`

const menuItemLinkStyles = css`
    cursor: pointer;

    grid-template-columns: var(--__huma-component-nav-menu-item-icon-size) 1fr;

    padding-block: 12px;
    padding-inline-start: var(--__huma-component-nav-menu-item-icon-size);

    font-size: 16px;
    font-weight: 500;
    text-align: start;
    text-decoration: none;
    color: var(--huma-color-foreground-default);

    ${bp.from(1520)`
        border-radius: 24px 0 0 24px;
    `}

    ${belowLaptop`
        height: 56px;
        padding-block: 4px;

        line-height: 24px;

        --__huma-component-nav-menu-item-icon-size: 48px;
    `}

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 20px;
        display: block;

        border-radius: 100%;
        width: 4px;
        height: 4px;
        background-color: transparent;

        transform: translateY(-50%);
        transition: background-color .15s ease-in-out;

        ${belowLaptop`
            left: 24px;
        `}
    }

    &.has-secondary-icon {
        grid-template-columns: var(--__huma-component-nav-menu-item-icon-size) 1fr minmax(0, min-content);
        justify-content: space-between;

        svg.secondary.external,
        svg.secondary.upgrade {
            ${svgStroke()}

            color: var(--huma-color-foreground-default);
        }
    }

    &:not(.active):not(.reversed):hover {
        transform: translateX(3px);

        ${belowLaptop`
            transform: none;
        `}
    }

    &.reversed:not(.active):hover {
        transform: translateX(-3px);

        ${belowLaptop`
            transform: none;
        `}
    }

    &:focus {
        outline: none;
        background-color: rgb(from var(--huma-color-surface-default) r g b / calc(2/3));

        &::before {
            background-color: var(--huma-color-foreground-constructive-bold);
        }
    }

    &.icon-color-on-hover {
        &:not(.icon-color) svg.primary {
            filter: grayscale(1) brightness(.5);

            ${darkMode`
                filter: grayscale(1) brightness(2.5);
            `}
        }

        &:is(:hover, :focus-visible, .active) svg.primary {
            filter: unset !important;
        }
    }

    svg.primary {
        stroke-width: 2;
        color: inherit;
    }

    &.active.ai svg.primary path {
        ${aiGradientCss}
    }
`

const menuItemStyles = css`
    position: relative;
    display: grid;
    align-items: center;

    min-height: 48px;
    padding-inline: var(--__huma-component-nav-menu-item-icon-size) 24px;

    transition: .15s ease-in-out;
    transition-property: transform, color, background, filter;

    &:where(.padded) {
        padding-block: 12px;

        ${belowLaptop`
            padding-block: 4px;
        `}
    }

    ${belowLaptop`
        padding-inline: 48px 16px;
    `}
`

export const MenuItemLink = styled(NavLink).attrs(({ active, className }) => ({
    className: cls([
        className,
        !!active && 'active'
    ])
}))`
    ${menuItemStyles}
    ${menuItemLinkStyles}

    &.active {
        background-color: var(--huma-color-surface-default);

        color: var(--huma-color-foreground-default);

        &::before {
            background-color: var(--huma-color-foreground-default);
        }
    }
`

export const MenuItemLinkExternal = styled.a`
    ${menuItemStyles}
    ${menuItemLinkStyles}
`

export const MenuItem = styled.div`
    ${menuItemStyles}
`

export const MenuItemSeparator = styled.div`
    margin-block: 15px 16px;
    height: 1px;
    background-color: var(--huma-color-border-default);

    ${bp.from(1520)`
        margin-inline-start: 56px;
    `}
`

export const SubmenuTitleSeparator = styled(Paragraph)`
    padding: 32px 24px 16px 56px;

    ${belowLaptop`
        padding 24px 16px 8px 48px;
    `}
`

export const MenuItemText = styled.span`
    position: relative;
    display: block;

    max-width: 100%;

    ${fromLaptop`
        line-height: 24px;
    `}
`

export const MenuSeparator = styled.div`
    ${flexChildShrinkCss}
    height: 56px;

    &:is(:last-child) {
        display: none;
    }

    ${belowLaptop`
        display: none;
    `}
`

export const IconWrapper = styled.div`
    position: relative;
`

export const NewBadge = styled(StatusLabel).attrs({
    as: 'span',
    className: 'creative'
})`
    pointer-events: none;
    position: absolute;
    z-index: 1;
    inset-inline-start: 16px;
    inset-block-start: 0;

    height: 16px;
    padding-inline: 4px;

    transform: translateY(-50%);

    font-size: 10px;
    font-weight: 500;
`