import styled from 'styled-components'
import { belowContainer, profileContainer } from 'utilities/styled'
import { OrderedList, ListItem } from 'components/list'
import { linkCss } from 'components/link'

export const NavigationList = styled(OrderedList)`
    position: sticky;
    top: 104px;

    display: flex;
    flex-direction: column;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 2px;
        height: 40px;
        background-color: var(--huma-color-surface-inverted);

        transform: translateY(calc(var(--active-multiplier) * 40px));
        transition: transform 0.1s ease-in-out;
    }

    ${belowContainer(profileContainer)`
        display: none;
    `}
`

export const NavigationItem = ListItem

export const Link = styled.a`
    ${linkCss}

    display: inline-flex;
    align-items: center;

    font-size: 14px;

    border-left: 2px solid var(--huma-color-border-default);
    height: 40px;
    padding-inline: 16px;

    color: var(--huma-color-foreground-subtle);

    &.active {
        color: var(--huma-color-foreground-default);
    }
`