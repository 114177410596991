import styled, { css } from 'styled-components'
import { belowTablet, svgStroke } from 'utilities/styled'
import { buttonStyles } from 'components/button'
import { Grid } from 'components/grid'
import {
    ContactSales as ContactSalesBase,
    ContactSupport as ContactSupportBase
} from 'components/contact'

const contactCss = css`
    padding: 0;
    margin: 8px 24px 16px;

    ${belowTablet`
        margin: 8px 8px 16px;
    `}
`

export const ContactSales = styled(ContactSalesBase)`
    ${contactCss}
`

export const ContactSupport = styled(ContactSupportBase)`
    ${contactCss}
`

export const Channels = styled(Grid)`
    column-gap: 24px;
    grid-template-columns: repeat(2, auto);
    justify-content: start;

    svg {
        margin-inline-end: 4px;
        ${svgStroke()}
    }
`

export const ChannelLink = styled.a`
    ${buttonStyles.plainCss}

    justify-content: center;

    height: 24px;

    font-size: 14px;
    text-decoration: none;

    svg {
        ${svgStroke('small')}
        margin-inline-end: 8px;
    }
`