import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked } from 'components/drawable-panel'
import CategoriesBase from 'components/service-onboarding/categories'

export const Important = styled.div`
    border-radius: 8px;
    padding: 8px;
    background-color: var(--huma-color-surface-warning-minimal);

    &:empty {
        display: none;
    }

    ${belowPhone`
        width: 100%;
    `}
`

export const ImportantInner = styled(Grid)`
    position: relative;

    border-radius: 4px;
    padding: 24px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);

    ${belowPhone`
        padding: 16px;
    `}
`

export const DrawablePanel = styled(Naked)`
    display: grid;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    row-gap: 0;

    transition: row-gap .2s ease-in-out;

    &.expanded {
        row-gap: 24px;
    }
`

export const Header = styled(Grid)`
    grid-auto-flow: column;
    grid-template-columns: 24px max-content;
    align-items: center;
    gap: 16px;
`

export const Emoji = styled.div`
    margin-inline-start: -4px;

    font-size: 20px;
    line-height: 24px;
    text-align: center;
`

export const Categories = styled(CategoriesBase)`
    margin-block-end: 24px;
`

export const Centered = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
`
