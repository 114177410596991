import React, { useState } from 'react'
import { useAppSignal } from 'hooks/app-signal'
import JubileeGiftModal from 'modals/jubilee-gift'

const JubileeGift = () => {
    const [show, setShow] = useState(false)

    useAppSignal({
        namespace: 'jubilee-gift.show',
        action: who => setShow(who)
    })

    const dismiss = () => setShow(false)

    return (
        <JubileeGiftModal
            show={show}
            dismiss={dismiss} />
    )
}

export default JubileeGift
