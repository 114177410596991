import styled, { css } from 'styled-components'
import { Rows } from 'components/flex'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { Naked, buttonStyles } from 'components/button'

const wrapperCss = css`
    display: grid;
    ${({ theme: { hasAction } }) => css`
        grid-template-columns: 40px 1fr ${hasAction ? 'auto' : null};
    `}
    align-items: center;
    column-gap: 16px;

    &:not(.simple) {
        border-radius: 8px;
        border: 1px solid var(--huma-color-border-default);
        padding: 16px;

        text-align: start;
    }

    &.simple {
        grid-template-columns: minmax(auto, min-content) minmax(0, 1fr);
        justify-content: start;
        column-gap: 24px;

        max-width: 100%;
`

export const WrapperButton = styled(Naked)`
    ${wrapperCss}

    &:where(:hover, :focus-visible) {
        border-color: var(--huma-color-border-constructive-bold);
    }
`

export const WrapperBlock = styled.div`
    ${wrapperCss}
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(WrapperButton)}
`

export const Meta = Rows

export const Title = styled(Paragraph).attrs({ as: 'span' })``

export const AddButton = styled.div.attrs({ className: 'constructive' })`
    ${buttonStyles.plainCss}
    justify-content: center;

    ${WrapperButton}:where(:hover, :focus-visible) & {
        text-decoration: none;
        color: var(--huma-color-foreground-constructive-bold-active);
    }
`
