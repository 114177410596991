import React from 'react'
import NewsProvider, { useNews } from 'contexts/news'
import { useAccess } from 'contexts/access'
import { FormattedMessage } from 'react-intl'
import Articles from 'lists/news'
import { Header, Heading, Link } from './s'
import { DashboardTutorial } from 'components/tutorial'

const DashboardNews = ({ hideIfEmpty }) => {
    const {
        articles,
        hasFetched
    } = useNews()

    const { check } = useAccess()
    const newsManage = check('articles:manage')

    const hasArticles = (!!hasFetched && !!articles.length)

    if(!newsManage && !hasArticles) {
        return null
    }

    if(hideIfEmpty && hasFetched && !articles.length) {
        return null
    }

    return (
        <>
            {(!!articles?.length || !hasFetched) && (
                <Header>
                    <Heading>
                        <FormattedMessage
                            id="dashboard_news_heading"
                            defaultMessage="Stay up to date" />
                    </Heading>
                </Header>
            )}
            <Articles
                as="cards"
                modal={true} />
            {hasArticles && (
                <Link
                    to="path:news.base"
                    className="constructive">
                    <FormattedMessage
                        id="news_action_navigate"
                        defaultMessage="See more news" />
                </Link>
            )}
            {(!!hasFetched && !articles.length) && <DashboardTutorial which="stayUpToDate" />}
        </>
    )
}

export default props => (
    <NewsProvider
        eternal={false}
        manage={false}
        sharedWithMeBy={['organization', 'team', 'location']}
        paging={{ limit: 4 }}>
        <DashboardNews {...props} />
    </NewsProvider>
)