import React from 'react'
import { useHandbookStatusTextFormatter } from './'
import { StatusLabel } from './s'

const HandbookStatusLabel = ({ status, children }) => {
    const text = useHandbookStatusTextFormatter()(status)
    if(!text) {
        return null
    }

    const className = statusToClassName[status] ?? 'neutral'

    return (
        <StatusLabel className={className}>
            {children ?? text}
        </StatusLabel>
    )
}

const statusToClassName = {
    published: 'active',
    inaccessible: 'passive',

    draft_published: 'positive'
}

statusToClassName.published_published = statusToClassName.published
statusToClassName.published_inaccessible = statusToClassName.inaccessible
statusToClassName.draft_inaccessible = statusToClassName.inaccessible

export default HandbookStatusLabel