import React from 'react'
import { useIntl } from 'react-intl'
import { ProcessStatus as Status } from './s'
import Label from './label'

const ProcessStatus = ({ status, ...props }) => {
    if(!status) {
        return null
    }

    return (
        <Status {...props}>
            <Label status={status} />
        </Status>
    )
}

export const statusTexts = {
    draft: {
        id: 'employment_lifecycle_status_draft',
        defaultMessage: 'Draft'
    },
    active: {
        id: 'employment_lifecycle_status_active',
        defaultMessage: 'Active'
    },
    completed: {
        id: 'employment_lifecycle_status_completed',
        defaultMessage: 'Completed'
    },
    archived: {
        id: 'employment_lifecycle_status_archived',
        defaultMessage: 'Archived'
    }
}

export const useProcessStatusTextFormatter = () => {
    const { formatMessage } = useIntl()

    return status => {
        const message = statusTexts[status] ?? null

        return message ?
            formatMessage(message) :
            null
    }
}

export default ProcessStatus