import styled from 'styled-components'
import { Rows } from 'components/flex'
import FormBase from 'components/form/controller'

export const Form = styled(FormBase)`
    grid-column: 1 / 3;

    margin-top: 8px;
`

export const Actions = styled(Rows)`
    row-gap: 8px;
    margin-top: 8px;

    button {
        justify-content: center;
    }
`