import React from 'react'
import Page from 'contexts/page'
import EquipmentTypesProvider from 'contexts/equipment-types'
import Routing from './routing'

const EquipmentPage = ({ upgradable, ...props }) => (
    <Page
        view="equipment"
        title={{
            id: 'noun_equipment',
            defaultMessage: 'Equipment'
        }}
        upgrade={{
            enabled: upgradable,
            passthrough: true
        }}>
        <EquipmentTypesProvider fetchAccess={!upgradable}>
            <Routing
                {...props}
                upgradable={upgradable} />
        </EquipmentTypesProvider>
    </Page>
)

export const symbol = {
    colorToken: 'symbol-neutral-1',
    emoji: 'package'
}

export default EquipmentPage