import styled from 'styled-components'
import ImportantBase, { ImportantInner } from 'components/important'

export const Important = styled(ImportantBase)`
    margin-block-end: 24px;

    ${ImportantInner} {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr auto;
        row-gap: 4px;

        > :not(.status) {
            grid-column: 1 / 2;
        }
    }
`