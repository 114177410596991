import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTasks } from 'contexts/tasks'
import { useAccess } from 'contexts/access'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { cls } from 'utilities/dom'
import { HeroActions, Heading } from 'pages/dashboard/s'
import {
    Container, SubHeading,
    ShowAll, ShowAllCount, ShowAllButton,
    Link
} from './s'
import Tasks from 'lists/tasks'
import { DashboardTutorial } from 'components/tutorial'
import { Scrollable as Modal } from 'modals/generic'
import ActingTask from 'pages/tasks/modals/task'
import AddToCalendar from 'components/add-to-calendar'

const TodoTasks = ({ context = 'isolated' }) => {
    const { formatMessage } = useIntl()

    const {
        tasks,
        hasFetched,

        toggleTaskCompleted,
        removeTask,
        total,
        loading,
        paging = {},
        fetch,
        fetching
    } = useTasks()

    const { checkModule } = useAccess()
    const meetingsAccess = checkModule('performance')

    const navigate = useNavigate()

    const [acting, setActing] = useState(false)
    const [addingToCalendar, setAddingToCalendar] = useState(null)

    const { hasNextPage } = paging

    if(!hasFetched) {
        return null
    }

    const salt = 'dashboard:todos'

    const viewAction = task => ({
        salt: `${salt}:view`,
        label: formatMessage({
            id: 'action_view_details',
            defaultMessage: 'View details'
        }),
        effect: 'neutral',
        onClick: () => setActing({
            mode: 'view',
            task
        })
    })

    const editAction = task => ({
        salt: `${salt}:edit`,
        label: formatMessage({
            id: 'task_action_edit',
            defaultMessage: 'Edit task'
        }),
        effect: 'neutral',
        onClick: () => setActing({
            mode: 'edit',
            task
        })
    })

    const toggleCompletedAction = ({ id, completedAt }) => ({
        salt: `${salt}:toggle-completed`,
        label: formatMessage({
            id: completedAt ?
                'task_action_mark_uncompleted' :
                'task_action_mark_completed',
            defaultMessage: completedAt ?
                'Mark as uncompleted' :
                'Mark as completed'
        }),
        onClick: () => toggleTaskCompleted(id),
        effect: 'neutral'
    })

    const addToCalendarAction = ({ dueAt, title, description }) => ({
        salt: `${salt}:add-to-calendar`,
        label: formatMessage({
            id: 'action_add_to_calendar',
            defaultMessage: 'Add to calendar'
        }),
        effect: 'neutral',
        onClick: () => setAddingToCalendar({
            start: dueAt,
            end: dueAt,
            title,
            description
        })
    })

    const createMeetingAction = ({ parent }) => {
        if(!meetingsAccess) {
            return null
        }

        return ({
            salt: `${salt}:create-meeting`,
            label: formatMessage({
                id: 'meeting_action_add',
                defaultMessage: 'New meeting'
            }),
            effect: 'neutral',
            onClick: () => navigate(paths.meetings.base, {
                state: {
                    intent: 'create-event',
                    concerns: (parent?.concerns?.type === 'user') ?
                        parent?.concerns :
                        null
                }
            })
        })
    }

    const removeAction = ({ id }) => ({
        salt: `${salt}:delete`,
        label: formatMessage({
            id: 'action_delete',
            defaultMessage: 'Delete'
        }),
        effect: 'destructive',
        prompt: {
            question: formatMessage({
                id: 'task_action_delete_prompt_question',
                defaultMessage: 'Confirm removing this task'
            }),
            confirmText: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            })
        },
        onClick: () => removeTask(id)
    })

    const actions = {
        view: viewAction,
        edit: editAction,
        toggleCompleted: toggleCompletedAction,
        addToCalendar: addToCalendarAction,
        createMeeting: createMeetingAction,
        remove: removeAction
    }

    const className = cls([
        context === 'integrated' && 'integrated'
    ])

    return (
        <>
            {(context === 'isolated' && (!!tasks?.length || !hasFetched)) && (
                <HeroActions>
                    <Heading>
                        <FormattedMessage
                            id="tasks_heading_your"
                            defaultMessage="Your tasks" />
                    </Heading>
                </HeroActions>
            )}
            {!!hasFetched && (
                <>
                    {!!tasks.length && (
                        <>
                            <Container {...(!!className ? { className } : null)}>
                                {(context === 'integrated') && (
                                    <SubHeading className="compact">
                                        <FormattedMessage
                                            id="tasks_heading_your"
                                            defaultMessage="Your tasks" />
                                    </SubHeading>
                                )}
                                <Tasks
                                    actions={actions}
                                    context="dashboard" />
                                {!!hasNextPage && (
                                    <ShowAll>
                                        {!loading && (
                                            <>
                                                <ShowAllCount>
                                                    + <FormattedMessage
                                                        id="dashboard_tasks_count_remaining"
                                                        defaultMessage="{count, plural, =0 {} =1 {One more uncompleted task} other {{count} more uncompleted tasks}}"
                                                        values={{ count: total - tasks.length }} />
                                                </ShowAllCount>
                                                <ShowAllButton
                                                    onClick={fetch}
                                                    className="constructive"
                                                    disabled={fetching}>
                                                    <FormattedMessage
                                                        id="action_show_more"
                                                        defaultMessage="Show more" />
                                                </ShowAllButton>
                                            </>
                                        )}
                                    </ShowAll>
                                )}
                            </Container>
                            <Link
                                to="path:tasks.base"
                                className="constructive">
                                <FormattedMessage
                                    id="tasks_action_navigate_goto"
                                    defaultMessage="Go to tasks" />
                            </Link>
                        </>
                    )}
                    {!tasks.length && <DashboardTutorial which="tasks" />}
                </>
            )}
            <Modal
                show={!!acting}
                dismiss={() => setActing(null)}
                salt={salt}>
                <ActingTask
                    {...acting}
                    actions={actions}
                    onDone={reassigned => {
                        setActing(null)

                        if(reassigned?.assignedGroup || reassigned?.assignedTo) {
                            setActing({
                                mode: 'reassigned',
                                reassigned
                            })
                        }
                    }}
                    salt={`${salt}:act`} />
            </Modal>
            <Modal
                salt={`${salt}:add-to-calendar`}
                show={!!addingToCalendar}
                dismiss={() => setAddingToCalendar(null)}>
                <AddToCalendar
                    {...addingToCalendar}
                    close={() => setAddingToCalendar(null)} />
            </Modal>
        </>
    )
}

export default TodoTasks