import styled from 'styled-components'
import { UnorderedListSmall, ListItem } from 'components/typography/list'
import { CaptionSmall } from 'components/typography/caption'

export const OneLiner = styled(CaptionSmall)`
    &:last-child {
        margin-block-end: 0;
    }
`

export const Bullets = styled(UnorderedListSmall)`
    padding-inline-start: 24px;

    color: var(--huma-color-foreground-subtle);

    &:last-child {
        margin-block-end: 0;
    }
`

export const Bullet = ListItem