import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcessContext } from 'pages/processes/utilities'
import { cls } from 'utilities/dom'
import { UserStats, InlineMessage } from './s'
import { ModalHeader } from 'modals/generic'
import Person from 'components/person'
import Progress from 'components/progress'
import Message from 'components/message'
import Paragraph from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'

const ArchiveProcess = ({ process, suggestion = false, context = 'multiple', dismiss }) => {
    const { formatMessage } = useIntl()

    const { archiveProcess } = useProcessContext(context)

    const [loading, setLoading] = useState(false)
    const [isArchived, setIsArchived] = useState(false)

    const {
        type,
        user,
        stats = {}
    } = process

    const {
        completedTasks,
        totalTasks
    } = stats

    const archive = async () => {
        setLoading(true)
        const { ok } = await archiveProcess(process.id)
        setLoading(false)

        if(ok) {
            setIsArchived(true)
        }
    }

    const heading = formatMessage(getHeadingTranslation(type))
    const successMessage = formatMessage(getSuccessMessageTranslation(type))

    const isCompleted = completedTasks === totalTasks

    return (
        <>
            <ModalHeader
                heading={heading}
                dismiss={dismiss} />
            <UserStats>
                <Person
                    who={user}
                    truncate={true} />
                <Progress
                    completed={completedTasks}
                    total={totalTasks}
                    className="compact" />
            </UserStats>
            {!isArchived && (
                <>
                    {!isCompleted && (
                        <InlineMessage
                            type="warning"
                            size="medium"
                            message={formatMessage({
                                id: 'employment_lifecycle_message_tasks_uncompleted_count',
                                defaultMessage: '{count, plural, =0 {} =1 {One uncompleted task will remain uncompleted and will continue to be assigned and available to the assignee.} other {{count} uncompleted tasks will remain uncompleted and will continue to be assigned and available to the assignees.}}'
                            }, { count: totalTasks - completedTasks })} />
                    )}
                    {suggestion && (
                        <Message
                            type="success"
                            message={formatMessage({
                                id: 'processes_completed_archive_suggestion_message',
                                defaultMessage: 'Looks like all the tasks have been completed – well done! Do you want to archive the process?'
                            })} />
                    )}
                    {(isCompleted && !suggestion) && (
                        <Paragraph>
                            <FormattedMessage
                                id="employment_lifecycle_message_tasks_completed"
                                defaultMessage="All tasks have been completed!" />
                        </Paragraph>
                    )}
                    <Actions className="compact">
                        <Plain
                            onClick={dismiss}
                            className="neutral">
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <Button
                            onClick={archive}
                            disabled={loading}
                            className={cls([
                                'neutral',
                                loading && 'loading'
                            ])}>
                            <FormattedMessage
                                id="action_archive"
                                defaultMessage="Archive" />
                        </Button>
                    </Actions>
                </>
            )}
            {isArchived && (
                <Message
                    type="success"
                    className="compact"
                    message={successMessage} />
            )}
        </>
    )
}

const getHeadingTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_message_archive_heading',
        defaultMessage: 'Archive onboarding'
    },
    offboarding: {
        id: 'employee_offboarding_message_archive_heading',
        defaultMessage: 'Archive offboarding'
    },
    process: {
        id: 'process_message_archive_heading',
        defaultMessage: 'Archive process'
    }
})[type]

const getSuccessMessageTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_message_archive_success',
        defaultMessage: 'The onboarding has been archived.'
    },
    offboarding: {
        id: 'employee_offboarding_message_archive_success',
        defaultMessage: 'The offboarding has been archived.'
    },
    process: {
        id: 'process_message_archive_success',
        defaultMessage: 'The process has been archived.'
    }
})[type]

export default ({ process, ...props }) => {
    if(!process?.stats) {
        process.stats = {
            completedTasks: process.completedTaskCount,
            totalTasks: process.taskCount
        }
    }

    return (
        <ArchiveProcess
            process={process}
            {...props} />
    )
}