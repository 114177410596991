import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { MetaRow, DateTime, Entities, EntitiesPlain, Tags, Tag } from './s'
import { Clock as Time } from 'styled-icons/feather'
import RelativeTime from 'components/time-relative'
import Paragraph from 'components/typography/paragraph'
import Link from 'components/link'
import { Tag as TagIcon } from 'styled-icons/feather'

const ArticleMetaContent = ({
    article,
    editable = false,
    entityPreview = true,
    entityOnClick,
    publicOption,
    tagOnClick,
    showSettings = false,
    setShowSettings
}) => {
    const navigate = useNavigate()

    const {
        id,
        publishedDate,
        shares,
        tags
    } = article

    const salt = `news:article:${id}`

    const teams = shares?.filter(({ type }) => type === 'team') ?? []
    const locations = shares?.filter(({ type }) => type === 'location') ?? []

    const onClickTag = tag => {
        if(typeof tagOnClick === 'function') {
            tagOnClick(tag)
        } else {
            navigate(paths.news.base, { state: { tags: [tag] } })
        }
    }

    return (
        <>
            {!!publishedDate && (
                <MetaRow>
                    <DateTime
                        dateTime={publishedDate}
                        className="compact"
                        itemProp="pubdate">
                        <Time size={16} />
                        <RelativeTime date={publishedDate} />
                    </DateTime>
                </MetaRow>
            )}
            {!!teams.length && (
                <MetaRow>
                    <Entities
                        entities={teams}
                        onClick={entityOnClick}
                        preview={entityPreview}
                        showAggregateIcon={true}
                        salt={salt} />
                </MetaRow>
            )}
            {!!locations.length && (
                <MetaRow>
                    <Entities
                        entities={locations}
                        onClick={entityOnClick}
                        preview={entityPreview}
                        showAggregateIcon={true}
                        salt={salt} />
                </MetaRow>
            )}
            {((!teams.length && !locations.length) || !shares.length && publicOption) && (
                <MetaRow>
                    <EntitiesPlain
                        entities={[publicOption]}
                        showAggregateIcon={true}
                        salt={salt} />
                </MetaRow>
            )}
            {!!tags?.length && (
                <MetaRow>
                    <Tags className="compact">
                        <TagIcon size={16} />
                        {tags.map((tag, index) => (
                            <Fragment key={`${salt}:value:${tag}`}>
                                <Tag
                                    className="constructive"
                                    onClick={() => onClickTag(tag)}>
                                    {tag}
                                </Tag>
                                {(index + 1 !== tags.length) && <>, </>}
                            </Fragment>
                        ))}
                    </Tags>
                </MetaRow>
            )}
            {(!!editable && !!setShowSettings) && (
                <MetaRow className="space">
                    <Paragraph>
                        <Link
                            onClick={() => setShowSettings(true)}
                            className={showSettings ? 'neutral' : 'constructive'}>
                            <FormattedMessage
                                id="article_action_change_publishing_settings"
                                defaultMessage="Change publishing settings…" />
                        </Link>
                    </Paragraph>
                </MetaRow>
            )}
        </>
    )
}

export default ArticleMetaContent