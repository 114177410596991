import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { usePayment } from 'contexts/payment'
import { useGetBundlePricing, useGetPriceFrequency, getBundleTermChargePlan } from 'utilities/payment'
import { integerPattern } from 'utilities/regex'
import { Container, Fields, FieldErrorWrapper, SeatPledgeField, Error, TotalPrice, PriceRow } from './s'
import Tooltip, { Trigger } from 'components/tooltip'
import { Price } from 'components/payment'

const max = 9999

const SeatPledge = ({ organization, account, change, to, term, salt }) => {
    const { formatMessage } = useIntl()

    const getBundlePricing = useGetBundlePricing()
    const getPriceFrequency = useGetPriceFrequency()

    const billedSeats = account.subscription?.chargePlan?.mainCharge?.quantity ?? 0
    const activeUsers = organization.userCount ?? 1

    let min = (change === 'upgrade') ?
        Math.max(billedSeats, activeUsers) :
        activeUsers

    const [pledgedSeats, setPledgedSeats] = useState(min)
    const [error, setError] = useState(null)

    const errorMessage = formatMessage({
        id: (change === 'upgrade') ?
            'payment_error_pledge_below_minimum_upgrade' :
            'payment_error_pledge_below_minimum_purchase',
        defaultMessage: (change === 'upgrade') ?
            '{count, plural, =0 {} =1 {Can’t be fewer than your current <strong>1 billed seat<\/strong>} other {Can’t be fewer than your current <strong>{count} billed seats<\/strong>}}' :
            '{count, plural, =0 {} =1 {Can’t be fewer than your current <strong>1 active user<\/strong>} other {Can’t be fewer than your current <strong>{count} active users<\/strong>}}'
    }, { count: min })

    const quantity = Math.max(pledgedSeats, min)

    const price = getBundlePricing({
        bundle: to,
        term,
        quantity
    })

    const quantityPrice = getBundlePricing({
        bundle: to,
        term,
        quantity: Infinity
    })

    const multiplier = price.bundle.minimum ?
        1 :
        quantity

    // Assuming that prices are monthly, even for annual plans
    const monthlyPrice = Math.max(price.bundle.price * multiplier, 0)
    const yearlyPrice = monthlyPrice * 12

    const totalPrice = (term === 'annual') ?
        yearlyPrice :
        monthlyPrice

    const priceFrequency = getPriceFrequency({
        term: price.bundle.pricePeriod,
        quantituous: price.bundle.charge?.model !== 'Flat'
    })

    const validate = pledge => (!!pledge || pledge === 0) && pledge >= min

    return (
        <Container>
            <Fields>
                <FieldErrorWrapper {...(!!error ? { className: 'error-within' } : null)}>
                    <SeatPledgeField
                        salt={salt}
                        className="compact horizontal"
                        label={formatMessage({
                            id: 'payment_label_seat_pledge',
                            defaultMessage: 'Number of seats'
                        })}
                        name="pledgedUserCount"
                        field={{
                            value: pledgedSeats,
                            required: true,
                            include: 'always'
                        }}
                        controlProps={{
                            type: 'number',
                            inputMode: 'numeric',
                            min,
                            max,
                            step: 1,
                            pattern: integerPattern
                        }}
                        onChange={({ pledgedUserCount }) => {
                            setPledgedSeats(pledgedUserCount)

                            if(validate(pledgedUserCount)) {
                                setError(null)
                            }
                        }}
                        onBlur={({ pledgedUserCount }) => {
                            if(validate(pledgedUserCount)) {
                                return void setError(null)
                            }

                            setError(errorMessage)
                        }} />
                    <Error animate={!!error ? 'reveal' : 'hide'}>
                        {error}
                    </Error>
                </FieldErrorWrapper>
                {price.bundle.minimum && (
                    <PriceRow className="compact base">
                        <span>
                            <FormattedMessage
                                id="payment_base_cost"
                                defaultMessage="Base cost" />
                            <Tooltip content={formatMessage({
                                id: 'payment_base_cost_tooltip',
                                defaultMessage: 'The subscription’s minimum price. When exceeding {count} seats, you pay {cost} instead.'
                            }, {
                                count: price.bundle.tier.toQuantity,
                                cost: formatMessage({
                                    id: 'payment_information_cost_per_frequency',
                                    defaultMessage: '{cost} {frequency}'
                                }, {
                                    cost: <Price value={quantityPrice.bundle.price} />,
                                    frequency: priceFrequency
                                })
                            })}>
                                <Trigger />
                            </Tooltip>
                        </span>
                        <span>
                            <Price value={totalPrice} />
                        </span>
                    </PriceRow>
                )}
            </Fields>
            <TotalPrice>
                <PriceRow className="compact total">
                    <span>
                        <FormattedMessage
                            id={(term === 'annual') ?
                                'payment_label_total_yearly' :
                                'payment_label_total_monthly'
                            }
                            defaultMessage={(term === 'annual') ?
                                'Yearly total' :
                                'Monthly total'
                            } />
                    </span>
                    <span>
                        <Price value={totalPrice} />
                    </span>
                </PriceRow>
            </TotalPrice>
        </Container>
    )
}

export default props => {
    const { organization } = useOrganization()
    const { account } = usePayment()

    const termChargePlan = getBundleTermChargePlan({
        bundle: props.to,
        term: props.term
    })

    if(termChargePlan?.mainCharge?.unitCode !== 'users') {
        return null
    }

    if(!organization || !account || ['downgrade', 'modify', 'term', 'freeload', 'cancel'].includes(props.change)) {
        return null
    }

    return (
        <SeatPledge
            {...props}
            organization={organization}
            account={account} />
    )
}