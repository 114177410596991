import styled from 'styled-components'
import { TooltipValue as TooltipValueBase } from 'components/charts/s'

export const TooltipValue = styled(TooltipValueBase)`
    display: inline-flex;
    align-items: center;
    gap: 8px;

    .up & span::before {
        content: "(+";
        margin-inline-end: 4px;
    }

    .down & span::before {
        content: "(";
    }

    .up & span::after,
    .down & span::after {
        content: ")";
    }
`