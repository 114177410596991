import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import StatusLabelBase from 'components/status-label'

export const Status = styled(Grid).attrs({ as: 'span' })`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 4px;
    justify-content: start;
    align-items: center;

    color: var(--huma-color-foregroun);

    &.inline {
        display: inline-grid;
    }
`

export const StatusLabel = styled(StatusLabelBase).attrs({ as: 'span' })`
    align-self: unset;

    svg {
        ${svgStroke()}

        margin-inline-end: 8px;
    }
`