import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { compact } from 'utilities/array'
import Message from 'components/message'
import OneOfField from 'components/form/field/one-of'
import Status from 'pages/handbook/components/status'
import Paragraph from 'components/typography/paragraph'

const BulkTopicsStatus = ({ category, salt }) => {
    const { formatMessage } = useIntl()
    const { handbook } = useHandbook()

    return (
        <>
            <Message
                type="info"
                message={formatMessage({
                    id: 'handbooks_info_edit_topics_status',
                    defaultMessage: '{count, plural, =0 {} =1 {Changing status for <strong>one topic<\/strong>} other {Changing status for <strong>{count} topics<\/strong>}}'
                }, { count: category.topics.length })} />
            <OneOfField
                salt={salt}
                className="compact"
                label={formatMessage({
                    id: 'noun_status',
                    defaultMessage: 'Status'
                })}
                name="status"
                field={{
                    value: 'draft',
                    required: true,
                    include: 'always',
                    options: [
                        {
                            value: 'draft',
                            label: (
                                <Status
                                    status="draft"
                                    tooltip={false} />
                            )
                        },
                        {
                            value: 'published',
                            label: (
                                <Status
                                    status={compact([handbook?.status, 'published']).join('_')}
                                    tooltip={false} />
                            ),
                            ...((handbook?.status !== 'published') ? {
                                content: (
                                    <Paragraph className="compact">
                                        <FormattedMessage
                                            id="handbooks_info_edit_topics_status_ready_info"
                                            defaultMessage="{count, plural, =0 {} =1 {This topic will become available once the handbook is published.} other {These topics will become available once the handbook is published.}}"
                                            values={{ count: category.topics.length }} />
                                    </Paragraph>
                                )
                            } : null)
                        }
                    ]
                }} />
        </>
    )
}

export default BulkTopicsStatus