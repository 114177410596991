import React from 'react'
import { useIntl } from 'react-intl'
import { LabelAndType, LabelAndTypeClickable, Symbol, Label } from './s'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { categoryToMetaMap } from 'pages/competence/constants/category'
import { CaptionSmall } from 'components/typography/caption'
import Ellipsify from 'components/ellipsify'
import { symbol } from 'pages/competence'

export const Type = ({ field, size = 40, className, onClick }) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    const [Wrapper, wrapperProps] = onClick ?
        [LabelAndTypeClickable, { onClick }] :
        [LabelAndType, null]

    return (
        <Wrapper
            {...wrapperProps}
            {...(className ? { className } : null)}>
            <Symbol
                symbol={field?.symbol ?? symbol}
                size={size}
                className="hover-effect type-symbol" />
            <div>
                <Label className="compact">{categoryFormatter(field)}</Label>
                <CaptionSmall className="compact">
                    <Ellipsify text={formatMessage(categoryToMetaMap[field.category].title)} />
                </CaptionSmall>
            </div>
        </Wrapper>
    )
}