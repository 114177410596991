import React, { Component, createContext, createRef, useContext } from 'react'
import { get, post, remove } from 'api'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'

export const JobTitlesContext = createContext()

export default class JobTitlesProvider extends Component {
    constructor(props) {
        super(props)

        this.flash = createRef(null)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.state = {
            jobTitles: [],
            filter: props?.filter ?? {},
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetch: this.fetch,
            addJobTitle: this.add,
            removeJobTitle: this.remove,

            addJobTitleLocally: this.addLocally,
            updateJobTitleLocally: this.updateLocally,

            setJobTitlesFilter: this.setFilter,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        !!fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging }, { filter }) {
        const pagingChanged = !isEqual(paging, this.props?.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        if(filterChanged) {
            this.setState(size(state) ? state : null, () => {
                this.fetchDebounced(true)
            })
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
    }

    fetch = async (force = false) => {
        const {
            filter,
            paging,
            hasFetched,
            fetching,
            autoFetch,
            eternal
        } = this.state

        const { fetchExhaustively = false } = this.props

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const { ok, response } = fetchExhaustively ?
            await this.fetchExhaustively() :
            await get({
                path: '/job-titles/paged',
                params: {
                    ...filter,
                    ...nextPaging
                }
            })

        if(ok && response) {
            this.setState(({ jobTitles: previousJobTitles }) => {
                const previousJobTitlesFetched = previousJobTitles.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const jobTitles = [
                    ...previousJobTitlesFetched,
                    ...response.items
                ]

                return {
                    jobTitles,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        hasNextPage: response.items.length && jobTitles.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    fetchExhaustively = async (jobTitles = []) => {
        const { filter, paging } = this.state

        const { response, ok } = await get({
            path: '/job-titles/paged',
            params: {
                ...filter,
                offset: jobTitles.length,
                limit: paging.limit
            }
        })

        if(ok && response?.items?.length) {
            jobTitles.push(...response.items)

            if(jobTitles.length < response.total) {
                return await this.fetchExhaustively(jobTitles)
            }
        }

        return {
            ok,
            response: {
                ...response,
                items: jobTitles
            }
        }
    }

    add = async body => {
        const { ok, response } = await post({
            path: '/job-titles',
            body
        })

        if(ok && response) {
            this.flash.current = response

            this.setState(({ jobTitles }) => ({
                jobTitles: [
                    response,
                    ...jobTitles
                ]
            }))
        }

        return { ok, response }
    }

    remove = async jobTitleId => {
        const { ok } = await remove({
            path: `/job-titles/${jobTitleId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ jobTitles }) => {
                jobTitles = jobTitles.filter(({ id }) => id !== jobTitleId)
                return { jobTitles }
            })
        }

        return { ok }
    }

    addLocally = body => void this.setState(({ jobTitles }) => ({
        jobTitles: [
            body,
            ...jobTitles
        ]
    }))

    updateLocally = (body, id) => void this.setState(({ jobTitles }) => {
        const index = jobTitles.findIndex(jobTitle => jobTitle.id === id)

        if(index === -1) {
            return null
        }

        const jobTitle = {
            ...jobTitles[index],
            ...body
        }

        return {
            jobTitles: [
                ...jobTitles.slice(0, index),
                jobTitle,
                ...jobTitles.slice(index + 1, jobTitles.length)
            ]
        }
    })

    removeLocally = jobTitleId => void this.setState(({ jobTitles }) => ({
        jobTitles: jobTitles.filter(({ id }) => id !== jobTitleId)
    }))

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }), {})

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                jobTitles: [],
                filter,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    clearFlash = () => void this.setState(({ jobTitles }) => {
        this.flash.current = null

        return {
            jobTitles: [...jobTitles]
        }
    })

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <JobTitlesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </JobTitlesContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 50,
    ...overrides,
    hasNextPage: false
})

export const useJobTitles = () => useContext(JobTitlesContext)