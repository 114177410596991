import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import ProcessTemplatesProvider, { useProcessTemplates } from 'contexts/process-templates'
import { getTypeModule } from 'pages/processes/utilities'
import { map, pick } from 'utilities/object'
import { capitalize } from 'utilities/string'
import {
    Content, Actions,
    Label, TemplateList,
    ShowAll, ShowAllCount, ShowAllButton,
    Empty
} from './s'
import { Scrollable as Modal, ModalHeader } from 'modals/generic'
import UpgradeCTA from 'components/upgrade-cta'
import TemplateItem from 'pages/processes/components/template-item'
import Form from 'components/form/controller'
import SelectField from 'components/form/field/select'
import { Plain, Button } from 'components/button'
import Loader from 'components/loader'
import HumaTemplatesFilter from 'pages/processes/components/huma-templates-filter'

const templatesCut = 5

const TemplatePicker = ({ type, salt, templates: prepickedTemplates = [], multiple, ...props }) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    const {
        locale,
        locales
    } = useI18n()

    const {
        templates,
        total,
        fetch,
        fetching,
        hasFetched,
        loading,
        intersecter,
        fetchHumaTemplates,
        paging = {},
        autoFetch,
        humaTemplates: humaExternal
    } = useProcessTemplates()

    const {
        templateLocales,
        templates: externalTemplates,

        fetching: fetchingHumaTemplates,
        hasFetched: hasFetchedHumaTemplates,
        intersecter: intersecterHumaTemplates
    } = humaExternal

    const { hasNextPage } = paging

    let {
        dismiss = null,
        onDone,
        show = false,
        outer = true
    } = props

    const [locked, setLocked] = useState([])
    const [picked, setPicked] = useState(prepickedTemplates ?? [])
    const [search, setSearch] = useState('')
    const [filteredHumaTemplates, setFilteredHumaTemplates] = useState(externalTemplates)
    const [showAllHumaTemplates, setShowAllHumaTemplates] = useState(false)

    const humaTemplates = type === 'process' ? filteredHumaTemplates : externalTemplates

    const defaultLocaleValue = templateLocales.includes(locale) ?
        locale :
        templateLocales[0]

    useEffect(() => {
        props.picked && setPicked(props.picked)
        props.locked && setLocked(props.locked)

        return () => {
            fetchHumaTemplates(defaultLocaleValue)
            setLocked([])
            setPicked([])
        }
    }, [show, props.picked, props.locked])

    const togglePicked = template => {
        if(multiple) {
            if(!!picked.find(({ id }) => id === template.id)) {
                setPicked(picked.filter(({ id }) => id !== template.id))
            } else {
                setPicked([...picked, template])
            }
        } else {
            setPicked([template])
            onDone({ picked: [template] })
        }
    }

    if(!dismiss) {
        dismiss = onDone
    }

    return (
        <Modal
            show={show}
            outer={outer}
            dismiss={dismiss}
            salt={salt}>
            <>
                <ModalHeader
                    heading={formatMessage(getHeadingTranslation(type))}
                    dismiss={dismiss} />
                <Content>
                    {!multiple && (
                        <UpgradeCTA
                            feature={`${getTypeModule(type)}-multiple-templates`}
                            useUpgradeIcon
                            useFeatureOrModuleIcon
                            useFeatureTitle
                            useFeatureDescription
                            className="spacious"
                            salt={salt} />
                    )}
                    <Label required={true}>
                        <FormattedMessage
                            id="noun_your_templates"
                            defaultMessage="Your templates" />
                    </Label>
                    <TemplateList>
                        {templates?.map((template, index) => {
                            const last = index + 1 === templates.length

                            return (
                                <TemplateItem
                                    template={template}
                                    index={index}
                                    multiple={multiple}
                                    locked={locked}
                                    picked={picked}
                                    toggle={togglePicked}
                                    {...((!!intersecter && last) ? { ref: intersecter } : null)}
                                    salt={`${salt}:templates`}
                                    key={template.id} />
                            )
                        })}
                        {!!hasNextPage && (
                            <ShowAll>
                                {(!loading && !autoFetch) && (
                                    <>
                                        <ShowAllCount>
                                            + <FormattedMessage
                                                id="templates_count"
                                                defaultMessage="{count, plural, =0 {} =1 {1 template} other {{count} templates}}"
                                                values={{
                                                    count: total - templates.length
                                                }} />
                                        </ShowAllCount>
                                        <ShowAllButton
                                            onClick={fetch}
                                            className="constructive"
                                            disabled={fetching}>
                                            <FormattedMessage
                                                id="action_show_all"
                                                defaultMessage="Show all" />
                                        </ShowAllButton>
                                    </>
                                )}
                                {!!loading && <Loader />}
                            </ShowAll>
                        )}
                        {(!templates?.length && hasFetched) && (
                            <Empty>
                                <FormattedMessage
                                    id="employment_lifecycle_template_picker_empty"
                                    defaultMessage="🤷 Sorry, no templates found." />
                            </Empty>
                        )}
                    </TemplateList>
                    <Label
                        required={true}
                        optional={formatMessage({
                            id: 'template_library_description',
                            defaultMessage: 'Templates in the library are provided by Huma and our partners.'
                        })}>
                        <FormattedMessage
                            id="noun_template_library"
                            defaultMessage="Template library" />
                    </Label>
                    {(type !== 'process') && (
                        <Form>
                            <SelectField
                                label={false}
                                name="locale"
                                field={{
                                    value: defaultLocaleValue,
                                    allowEmptyOption: false
                                }}
                                options={[
                                    {
                                        value: '',
                                        text: formatMessage({
                                            id: 'all_plural',
                                            defaultMessage: 'All'
                                        })
                                    },
                                    ...map(pick(locales, ...templateLocales), ({ flag }, locale) => ({
                                        key: locale,
                                        value: locale,
                                        text: `${flag} ${capitalize(formatDisplayName(locale, { type: 'language' }))}`
                                    }))
                                ]}
                                onChange={({ locale }) => {
                                    setShowAllHumaTemplates(false)
                                    fetchHumaTemplates(locale)
                                }} />
                        </Form>
                    )}
                    {(type === 'process') && (
                        <HumaTemplatesFilter
                            search={search}
                            setSearch={setSearch}
                            setFiltered={setFilteredHumaTemplates}
                            salt={`${type}:templates`} />
                    )}
                    <TemplateList>
                        {humaTemplates
                            .slice(0, (showAllHumaTemplates ? Infinity : templatesCut))
                            .map((template, index) => {
                                const last = index + 1 === humaTemplates.length

                                return (
                                    <TemplateItem
                                        template={template}
                                        index={index}
                                        multiple={multiple}
                                        locked={locked}
                                        picked={picked}
                                        toggle={togglePicked}
                                        {...((!!intersecterHumaTemplates && last) ? { ref: intersecterHumaTemplates } : null)}
                                        salt={`${salt}:huma-templates`}
                                        key={template.id} />
                                )
                            }
                        )}
                        {fetchingHumaTemplates && !hasFetchedHumaTemplates && <Loader />}
                        {(humaTemplates?.length > templatesCut && !showAllHumaTemplates) && (
                            <ShowAll>
                                <ShowAllCount>
                                    + <FormattedMessage
                                        id="templates_count"
                                        defaultMessage="{count, plural, =0 {} =1 {1 template} other {{count} templates}}"
                                        values={{
                                            count: humaTemplates.length - templatesCut
                                        }} />
                                </ShowAllCount>
                                <ShowAllButton
                                    onClick={() => setShowAllHumaTemplates(true)}
                                    className="constructive">
                                    <FormattedMessage
                                        id="action_show_all"
                                        defaultMessage="Show all" />
                                </ShowAllButton>
                            </ShowAll>
                        )}
                        {(!humaTemplates?.length && hasFetchedHumaTemplates) && (
                            <Empty>
                                <FormattedMessage
                                    id="employment_lifecycle_template_picker_empty"
                                    defaultMessage="🤷 Sorry, no templates found." />
                            </Empty>
                        )}
                    </TemplateList>
                </Content>
                <Actions>
                    <Plain
                        onClick={dismiss}
                        className="neutral">
                        <FormattedMessage
                            id="action_cancel"
                            defaultMessage="Cancel" />
                    </Plain>
                    <Button
                        className="constructive"
                        onClick={() => onDone({ picked })}>
                        <FormattedMessage
                            id="action_continue"
                            defaultMessage="Continue" />
                    </Button>
                </Actions>
            </>
        </Modal>
    )
}

const getHeadingTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_templates_action_pick',
        defaultMessage: 'Pick onboarding template'
    },
    offboarding: {
        id: 'employee_offboarding_templates_action_pick',
        defaultMessage: 'Pick offboarding template'
    },
    process: {
        id: 'processes_templates_action_pick',
        defaultMessage: 'Pick process template'
    }
})[type]

export default ({ type, ...props }) => (
    <ProcessTemplatesProvider
        type={type}
        paging={{ limit: templatesCut - 1 }}>
        <TemplatePicker
            {...props}
            type={type} />
    </ProcessTemplatesProvider>
)