import { useRef, useState, useLayoutEffect } from 'react'
import { get, outget } from 'api'
import { splitName } from 'utilities/file'
import { FilePonyfill } from '@tanker/file-ponyfill'

export const useDownload = ({ path, params = {}, auto = false, external = false, keepAlive = false, generateName }) => {
    const trigger = useRef()

    const [ready, setReady] = useState(!!auto)
    const [made, setMade] = useState(false)
    const [raw, setRaw] = useState(null)
    const [file, setFile] = useState(null)
    const [objectUrl, setObjectUrl] = useState(null)
    const [name, setName] = useState(true)

    useLayoutEffect(() => {
        if(path && ready && !made) {
            setMade(true)

            const getFile = async () => {
                const method = external ? outget : get
                const args = external ? [
                    path,
                    {
                        params,
                        binary: true
                    }
                ] : [{
                    path,
                    params,
                    binary: true
                }]

                const { ok, response: contents, headers } = await method(...args)

                if(ok && contents && headers) {
                    let name = headers['content-disposition']
                        .split('filename=')[1]
                        .replace(/\"/g, '')

                    if(!!name && typeof generateName === 'function') {
                        name = generateName(splitName(name))
                    }

                    const type = headers['content-type']
                    const file = new FilePonyfill([contents], name, { type })

                    const objectUrl = global.URL.createObjectURL(file)

                    setRaw(contents)
                    setFile(file)
                    setObjectUrl(objectUrl)
                    setName(name)

                    if(!!trigger?.current || keepAlive) {
                        await new Promise(resolve => global.setTimeout(resolve, 50))

                        if(!!trigger?.current) {
                            return void trigger.current.click()
                        }

                        if(keepAlive) {
                            const link = document.createElement('a')

                            if(!!global.navigator.msSaveBlob) {
                                link.onClick = () => global.navigator.msSaveBlob(file, name)
                            }

                            if(!global.navigator.msSaveBlob) {
                                link.href = objectUrl
                                link.download = name
                            }

                            link.click()
                        }
                    }
                }
            }

            getFile()
        }

        return () => {
            objectUrl && global.URL.revokeObjectURL(objectUrl)
        }
    }, [path, ready, made, objectUrl])

    return {
        made,
        raw,
        file,
        objectUrl,
        name,
        trigger,
        download: () => setReady(true)
    }
}