import React, { useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcess } from 'contexts/process'
import { UnitPermissionsProvider, useUnitPermissions } from 'contexts/unit-permissions'
import { useOrganization } from 'contexts/organization'
import { get, patch } from 'api'
import { cls } from 'utilities/dom'
import { size, pick } from 'utilities/object'
import { compact } from 'utilities/array'
import { getListRepresentationFromProfile, unpackStringField } from 'utilities/person'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import OneOfField from 'components/form/field/one-of'
import PersonField from 'components/form/field/person'
import EntityField from 'components/form/field/entity'
import TimeField from 'components/form/field/time'
import { Helper } from 'components/form/field/s'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const EditProcess = ({ concerns, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const {
        process,
        updateProcess
    } = useProcess()

    const { checkAll } = useUnitPermissions()
    const { organization } = useOrganization()

    const userRef = useRef()
    const groupRef = useRef()

    const {
        title,
        description,
        referenceDate
    } = process

    const [person, setPerson] = useState(concerns?.type === 'user' ? concerns : null)
    const [supervisor, setSupervisor] = useState(person?.supervisor ?? null)
    const [concernsType, setConcernsType] = useState(['team', 'location'].includes(concerns?.type) ? 'group' : concerns?.type)
    const [group, setGroup] = useState(['team', 'location'].includes(concerns?.type) ? concerns : null)
    const [initialSupervisor] = useState(!!person?.supervisor)
    const [fetching, setFetching] = useState(false)
    const [updating, setUpdating] = useState(false)

    const [access, { general: fullAccess }] = checkAll({
        system: 'users:manage',
        unit: 'unit:processes:manage'
    })

    const fetchPerson = async id => {
        if(!id) {
            return
        }

        setFetching(true)
        const { ok, response } = await get({ path: `/users/${id}` })
        setFetching(false)

        if(ok) {
            setPerson(response)
        }
    }

    const update = async body => {
        setUpdating(true)

        let personOk = false

        const supervisorChanged = !!body.supervisor && body.supervisor !== unpackStringField(person?.supervisor)?.id && concernsType === 'user'
        if(supervisorChanged) {
            const updateFields = pick(body, 'supervisor')
            const result = await patch({
                path: `/users/${person.id}`,
                body: updateFields
            })
            personOk = result.ok
        } else personOk = true

        // Only applicable when editing a draft process
        if(('concernsType' in body || concernsType in body) && !process.id) {
            const concernsUnit = {
                user: person,
                group,
                organization: pick(organization, 'id', 'name')
            }[concernsType]

            body.concerns = {
                ...concernsUnit,
                ...(supervisorChanged ? {
                    supervisor: {
                        ...person?.supervisor,
                        value: getListRepresentationFromProfile(supervisor)
                    }
                } : null),
                type: concernsType === 'group' ? group.type : concernsType
            }

            delete body?.[concernsType]
        }

        delete body.supervisor

        const { ok: processOk } = await updateProcess(body)
        const ok = personOk && processOk

        setUpdating(false)

        ok && dismiss()
    }

    const unitPermissionsFilter = { permission: 'unit:processes:manage' }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'action_edit_details',
                    defaultMessage: 'Edit details'
                })}
                dismiss={dismiss} />
            <Form
                layout="vertical"
                json={{ disabled: true }}
                onSubmit={update}>
                {({ touched, errors, trigger }) => (
                    <>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_title',
                                defaultMessage: 'Title'
                            })}
                            name="title"
                            controlProps={{
                                autoFocus: true,
                                max: 255
                            }}
                            field={{
                                required: true,
                                include: 'touched',
                                value: title
                            }} />
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{
                                value: description,
                                include: 'touched'
                            }}
                            controlProps={{ maxLength: 2040 }} />
                        <Helper>
                            <FormattedMessage
                                id="processes_helper_description"
                                defaultMessage="This will be visible to everyone who has access to the process." />
                        </Helper>
                        <OneOfField
                            salt={salt}
                            label={formatMessage({
                                id: 'preposition_for',
                                defaultMessage: 'For'
                            })}
                            name="concernsType"
                            field={{
                                value: concernsType,
                                required: true,
                                include: 'always',
                                options: compact([
                                    {
                                        value: 'user',
                                        label: formatMessage({
                                            id: 'assign_to_specific',
                                            defaultMessage: 'A specific person'
                                        }),
                                        controlRef: userRef,
                                        content: (
                                            <PersonField
                                                salt={salt}
                                                label={false}
                                                name="user"
                                                field={{
                                                    value: person ? getListRepresentationFromProfile(person) : null,
                                                    required: concernsType === 'user',
                                                    include: 'touched'
                                                }}
                                                picker={{
                                                    outer: false,
                                                    ...(!fullAccess ? {
                                                        message: {
                                                            type: 'info',
                                                            message: formatMessage({
                                                                id: 'processes_pick_existing_user_unit_permissions_person_picker_disclaimer',
                                                                defaultMessage: 'Only members of groups in which you can manage processes are available.'
                                                            })
                                                        },

                                                        // TODO: Reactivate this when the API supports combining permission
                                                        // filters with other filters supported by UI components
                                                        // filterOverrides: {
                                                        //     teams: {
                                                        //         query: {
                                                        //             path: '/units',
                                                        //             params: {
                                                        //                 ...unitPermissionsFilter,
                                                        //                 types: ['team']
                                                        //             }
                                                        //         }
                                                        //     },
                                                        //     locations: {
                                                        //         query: {
                                                        //             path: '/units',
                                                        //             params: {
                                                        //                 ...unitPermissionsFilter,
                                                        //                 types: ['location']
                                                        //             }
                                                        //         }
                                                        //     }
                                                        // }
                                                    } : null)
                                                }}
                                                {...(!fullAccess ? {
                                                    entity: {
                                                        params: unitPermissionsFilter
                                                    }
                                                } : null)}
                                                enabled={!process.id}
                                                onChange={({ user }) => fetchPerson(user?.id)}
                                                whistle={person?.id ?? 'empty'}
                                                ref={userRef} />
                                        )
                                    },
                                    {
                                        value: 'group',
                                        label: formatMessage({
                                            id: 'task_assign_to_team_or_location',
                                            defaultMessage: 'A team or a location'
                                        }),
                                        controlRef: groupRef,
                                        content: (
                                            <EntityField
                                                salt={salt}
                                                label={false}
                                                name="group"
                                                field={{
                                                    value: group,
                                                    required: concernsType === 'group',
                                                    include: 'touched'
                                                }}
                                                picker={{
                                                    heading: formatMessage({
                                                        id: 'group_action_pick',
                                                        defaultMessage: 'Pick a group'
                                                    }),
                                                    ...(!fullAccess ? {
                                                        message: {
                                                            type: 'info',
                                                            message: formatMessage({
                                                                id: 'people_add_unit_permissions_groups_picker_disclaimer',
                                                                defaultMessage: 'Only groups in which you can manage users are available.'
                                                            })
                                                        }
                                                    } : null),
                                                    outer: false
                                                }}
                                                entity={{
                                                    type: 'group',
                                                    path: '/units',
                                                    params: {
                                                        types: ['team', 'location'],
                                                        ...(!fullAccess ? unitPermissionsFilter : null)
                                                    }
                                                }}
                                                enabled={!process.id}
                                                onChange={({ group }) => setGroup(group)}
                                                ref={groupRef} />
                                        )
                                    },
                                    fullAccess && {
                                        value: 'organization',
                                        label: formatMessage({
                                            id: 'noun_no_one',
                                            defaultMessage: 'No one'
                                        })
                                    }
                                ])
                            }}
                            enabled={!process.id}
                            onChange={({ concernsType }) => setConcernsType(concernsType)} />
                        {!!process.id && (
                            <Helper>
                                <FormattedMessage
                                    id="process_helper_uneditable"
                                    defaultMessage="This value can’t be edited because the process has already started." />
                            </Helper>
                        )}
                        {concernsType === 'user' && (
                            <>
                                <PersonField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_supervisor',
                                        defaultMessage: 'Supervisor'
                                    })}
                                    name="supervisor"
                                    field={{
                                        ...(person?.supervisor ?? {}),
                                        unsettable: !initialSupervisor
                                    }}
                                    picker={{ outer: false }}
                                    entity={{
                                        params: {
                                            notRecursivelySubordinateOf: person?.id
                                        }
                                    }}
                                    enabled={!!person && !process.id}
                                    whistle={person?.supervisor?.value?.id ?? 'empty'}
                                    onChange={({ supervisor }) => setSupervisor(supervisor)} />
                                {!!process.id && (
                                    <Helper>
                                        <FormattedMessage
                                            id="process_helper_uneditable"
                                            defaultMessage="This value can’t be edited because the process has already started." />
                                    </Helper>
                                )}
                            </>
                        )}
                        <TimeField
                            salt={salt}
                            label={formatMessage({
                                id: 'label_process_date_reference',
                                defaultMessage: 'Reference date'
                            })}
                            name="referenceDate"
                            field={{
                                value: referenceDate,
                                required: true,
                                include: 'touched'
                            }}
                            enabled={((concernsType === 'user' && !!person) || concernsType !== 'user') && !process.id}
                            key={`${salt}:referenceDate:${person ? 'enabled' : 'disabled'}`} />
                        {!!process.id && (
                            <Helper>
                                <FormattedMessage
                                    id={!!process.id ?
                                        'process_helper_uneditable'
                                        : 'processes_helper_reference_date'
                                    }
                                    defaultMessage={!!process.id ?
                                        'This value can’t be edited because the process has already started.'
                                        : 'The date used as a reference for the process tasks.'
                                    } />
                            </Helper>
                        )}
                        <Actions className="compact">
                            <Plain
                                onClick={dismiss}
                                className="neutral">
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={cls([
                                    'constructive',
                                    (updating || fetching) && 'loading'
                                ])}
                                disabled={updating || fetching || !touched.length || !!size(errors) || (concernsType === 'user' && !person)}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_save"
                                    defaultMessage="Save" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => (
    <UnitPermissionsProvider>
        <EditProcess {...props} />
    </UnitPermissionsProvider>
)