import React from 'react'
import Page, { useAugmentTitle } from 'contexts/page'
import { useAuth } from 'contexts/auth'
import SignupProvider, { useSignup } from 'contexts/signup'
import paths from 'app/paths'
import PublicLayout from 'app/layouts/public'
import { Navigate } from 'react-router-dom'
import OneDetails from './1-details'
import TwoValidate from './2-validate'

const SignupPage = () => {
    const {
        registrationEnabled,
        status,
        redirect
    } = useAuth()

    const { step } = useSignup()

    useAugmentTitle([
        {
            id: 'step_of_total',
            defaultMessage: 'Step {step} of {total}',
        },
        {
            step,
            total: 2
        }
    ])

    if(!registrationEnabled) {
        return <Navigate to={paths.root} replace />
    }

    if(status === 'authorized' && redirect) {
        return <Navigate to={redirect} replace />
    }

    return (
        <PublicLayout>
            <OneDetails show={step === 1} />
            <TwoValidate show={step === 2} />
        </PublicLayout>
    )
}

export default props => (
    <Page
        view="signup"
        title={{
            id: 'organization_action_add',
            defaultMessage: 'Create new organization'
        }}>
        <SignupProvider loadingDuration={10000}>
            <SignupPage {...props} />
        </SignupProvider>
    </Page>
)