import React from 'react'
import FieldPropsProvider from './field'
import { anyFieldsDisplayable, fieldDisplayable } from 'utilities/access'

const CustomFields = ({ fields, salt, ...props }) => {
    if(!fields.length) {
        return null
    }

    const anyDisplayable = anyFieldsDisplayable(fields)
    if(!anyDisplayable) {
        return null
    }

    return fields
        .filter(fieldDisplayable)
        .map(field => (
            <FieldPropsProvider
                {...props}
                field={field}
                salt={salt}
                key={`${salt}:${field.name}`} />
        ))
}

export const getCustomFieldsBySection = (profile, section) => Object.entries(profile?.custom ?? {})
    ?.map(([name, field]) => ({ ...field, name }))
    ?.filter(field => field.section === section) ?? []

export default CustomFields