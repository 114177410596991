import React from 'react'
import { useCaseStatus } from 'pages/deviation/utilities'
import { cls } from 'utilities/dom'
import { StatusLabel } from './s'

const CaseStatusLabel = ({ status, ...props }) => {
    let {
        className,
        label
    } = useCaseStatus()(status)

    if(!label) {
        return null
    }

    className = cls([
        className,
        props.className
    ])

    return (
        <StatusLabel className={className}>
            {label}
        </StatusLabel>
    )
}

export default CaseStatusLabel
