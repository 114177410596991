import { useScript } from 'hooks/script'
import { useEnvironment } from 'contexts/environment'
import { initialize as initializeHubspot } from 'utilities/hubspot'

const Hubspot = () => {
    const { integrations } = useEnvironment()

    useScript({
        id: 'hubspot',
        src: `//js.hs-scripts.com/${integrations?.hubspotAccountId}.js`,
        onLoad: ({ first }) => {
            first && initializeHubspot()
        },
        dependencies: [!!integrations?.hubspotAccountId]
    })

    return null
}

export default Hubspot