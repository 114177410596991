import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { size } from 'utilities/object'
import { compact } from 'utilities/array'
import { ListItem, InspectStatusesButton } from './s'
import Form from 'components/form/controller'
import OneOfField from 'components/form/field/one-of'
import { Helper } from 'components/form/field/s'
import { UnorderedList } from 'components/list'
import Status from 'pages/handbook/components/status'
import Actions from 'components/form/actions'
import { BackButton, ButtonSubmit } from 'components/button'
import { Check as True } from 'styled-icons/feather'

const TwoStatus = ({ form, onSubmit, enabledCategoriesAndTopics, adding, back, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const statusCounts = (form.type === 'json') ?
        enabledCategoriesAndTopics
            .flatMap(({ topics }) => topics)
            .reduce((accumulator, { status }) => ({
                ...accumulator,
                [status]: accumulator[status] + 1
            }), {
                draft: 0,
                published: 0
            }) :
        null

    return (
        <Form
            layout="vertical"
            onSubmit={onSubmit}>
            {({ errors, trigger }) => (
                <>
                    <OneOfField
                        salt={salt}
                        className="compact"
                        label={formatMessage({
                            id: 'noun_status',
                            defaultMessage: 'Status'
                        })}
                        name="status"
                        field={{
                            value: 'draft',
                            required: true,
                            include: 'always',
                            options: compact([
                                {
                                    value: 'draft',
                                    label: (
                                        <Status
                                            status="draft"
                                            tooltip={false} />
                                    ),
                                    content: (
                                        <UnorderedList>
                                            <ListItem className="true">
                                                <True size={16} />
                                                <FormattedMessage
                                                    id="handbooks_template_add_categories_topics_boolean_admins"
                                                    defaultMessage="Only people with admin access to the handbook will be able to see the content before it’s published." />
                                            </ListItem>
                                            <ListItem className="true">
                                                <True size={16} />
                                                <FormattedMessage
                                                    id="handbooks_template_add_categories_topics_boolean_content"
                                                    defaultMessage="Content can be published later." />
                                            </ListItem>
                                        </UnorderedList>
                                    )
                                },
                                {
                                    value: 'published',
                                    label: (
                                        <Status
                                            status="published"
                                            tooltip={false} />
                                    ),
                                    content: (
                                        <UnorderedList>
                                            <ListItem className="true">
                                                <True size={16} />
                                                <FormattedMessage
                                                    id="handbooks_template_add_categories_topics_boolean_published"
                                                    defaultMessage="The selected categories and topics will be available to those they are shared with." />
                                            </ListItem>
                                        </UnorderedList>
                                    )
                                },
                                (form.type === 'json' && !!statusCounts?.draft && !!statusCounts?.published) && {
                                    value: 'keep',
                                    label: formatMessage({
                                        id: 'handbooks_template_add_categories_topics_keep_statuses',
                                        defaultMessage: 'Keep statuses from import file'
                                    }),
                                    content: (
                                        <>
                                            <UnorderedList>
                                                <ListItem className="true">
                                                    <True size={16} />
                                                    <span>
                                                        <FormattedMessage
                                                            id="handbooks_template_add_categories_topics_keep_statuses_count"
                                                            defaultMessage="{count} topics in {status}"
                                                            values={{
                                                                count: statusCounts.draft,
                                                                status: (
                                                                    <Status
                                                                        status="draft"
                                                                        inline={true} />
                                                                )
                                                            }} />
                                                    </span>
                                                </ListItem>
                                                <ListItem className="true">
                                                    <True size={16} />
                                                    <span>
                                                        <FormattedMessage
                                                            id="handbooks_template_add_categories_topics_keep_statuses_count"
                                                            defaultMessage="{count} topics in {status}"
                                                            values={{
                                                                count: statusCounts.published,
                                                                status: (
                                                                    <Status
                                                                        status="published"
                                                                        inline={true} />
                                                                )
                                                            }} />
                                                    </span>
                                                </ListItem>
                                            </UnorderedList>
                                            <InspectStatusesButton
                                                className="constructive"
                                                onClick={dismiss}>
                                                <FormattedMessage
                                                    id="handbooks_template_add_categories_topics_keep_statuses_preview"
                                                    defaultMessage="Go back to “What to include” for details." />
                                            </InspectStatusesButton>
                                        </>
                                    )
                                }
                            ])
                        }} />
                    <Helper>
                        <FormattedMessage
                            id="handbooks_setup_step_status_helper"
                            defaultMessage="You can change status for each topic later." />
                    </Helper>
                    <Actions className="spread">
                        <BackButton onClick={back} />
                        <ButtonSubmit
                            className={`constructive${adding ? ' loading' : ''}`}
                            disabled={!!size(errors) || adding}
                            ref={trigger}>
                            <FormattedMessage
                                id="handbooks_template_action_add"
                                defaultMessage="Add to handbook" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default TwoStatus