import { createLogger } from 'utilities/rapid7-insight-ops'
import { getChannel } from 'utilities/broadcaster'
import { outpost, getUrl } from 'api'
import { local, session } from 'utilities/storage'
import { getExpiry, anonymize } from 'utilities/jwt'
import { sleep } from 'utilities/async'
import { v4 as uuid } from 'uuid'
import PubSub from 'pubsub-js'
import paths from 'app/paths'

const sessionLogger = createLogger('log', 'AUDIT:')

const state = {}

const syncer = getChannel('auth:refresh')

syncer.onmessage = ({ data }) => {
    const { name } = data

    if(name === 'locked') {
        state.locked = true
    } else {
        delete state.locked
    }
}

global.addEventListener('beforeunload', () => {
    if(state.refresh && !state.locked) {
        syncer.postMessage({ name: 'refreshed' })
    }

    syncer.close()
})

export const requestAccess = async (options = {}) => {
    const {
        retry = false,
        bounce = true
    } = options

    // When storing the tokens, we shave ten seconds off the expiry time so
    // most if not all edge cases where the token would expire in transit,
    // and cause an unnecessary 401, are avoided

    const {
        value: accessToken,
        expired: accessTokenExpired
    } = local.get('accessToken', { detailed: true })

    const {
        value: refreshToken,
        expired: refreshTokenExpired
    } = local.get('refresh_token', { detailed: true })

    const logContext = {
        source: 'web',
        event: 'auth-refresh',
        orgId: local.get('ids')?.organization,
        userId: local.get('ids')?.user,
        instance: session.get('instance'),
        accessToken: anonymize(accessToken),
        refreshToken: anonymize(refreshToken)
    }

    if(state.locked) {
        return await new Promise((resolve, reject) => {
            syncer.onmessage = ({ data }) => {
                const {
                    name,
                    tokens
                } = data

                if(name === 'refreshed') {
                    if(tokens) {
                        resolve({
                            ok: true,
                            response: tokens
                        })
                    } else {
                        reject({ ok: false })
                    }
                }
            }

            syncer.onmessageerror = () => reject({ ok: false })
        })
    }

    if(!state.refresh) {
        if(refreshTokenExpired) {
            if(bounce && !isNative()) {
                sessionLogger({
                    ...logContext,
                    error: 'refresh_token_expired',
                    authority: 'web'
                })

                bounceUser()
            }

            return { ok: false }
        }

        if(!accessTokenExpired && accessToken) {
            return {
                ok: true,
                response: {
                    accessToken: accessToken,
                    refreshToken
                }
            }
        }

        if(retry) {
            await sleep(5000)
        }

        if(!refreshToken) {
            return { ok: false }
        }

        // Establish lock
        syncer.postMessage({ name: 'locked' })

        const { release } = local.get('environment') ?? {}
        const requestId = uuid()

        state.refresh = outpost(getUrl('/auth/oauth/token'), {
            headers: {
                'Huma-Client': 'web',
                ...(release ? { 'Huma-Client-Version': release } : null),
                'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
                'x-request-id': requestId
            },
            body: {
                grant_type: 'refresh',
                refresh_token: refreshToken
            }
        })

        sessionLogger({
            ...logContext,
            requestId
        })
    }

    let result
    let handled = false
    let success = false
    let shouldRetry = false
    let tokens

    try {
        result = await state.refresh

        if(result?.status >= 200 && result?.status < 300) {
            handled = true
            success = true
        }

        if([0, 500, 503].includes(result?.status)) {
            handled = true
            shouldRetry = true
        }

        if(result?.status === 401) {
            handled = true
        }
    } catch(error) {
        if(!handled) {
            shouldRetry = true
        }
    }

    if(!success) {
        syncer.postMessage({ name: 'refreshed' })

        const error = result?.status === 401 ?
            'refresh_token_invalid' :
            result?.status

        const requestId = result?.headers?.['x-request-id']

        sessionLogger({
            ...logContext,
            error,
            authority: 'api',
            ...(requestId ? { requestId } : null),
            ...(result?.response ? { response: result.response } : null)
        })

        if(shouldRetry) {
            return await requestAccess({
                ...options,
                retry: true
            })
        }

        bounceUser()

        return { ok: false }
    } else {
        tokens = result.response

        syncer.postMessage({
            name: 'refreshed',
            tokens
        })

        sessionLogger({
            ...logContext,
            newAccessToken: anonymize(tokens.accessToken),
            newRefreshToken: anonymize(tokens.refresh_token),
            requestId: result.headers?.['x-request-id'] ?? ''
        })

        storeToken('accessToken', tokens.accessToken)
        storeToken('refresh_token', tokens.refresh_token)
    }

    delete state.refresh

    return {
        ok: true,
        response: tokens
    }
}

export const storeToken = (key, token) => {
    const expiry = (getExpiry(token) - 10) * 1000

    local.set(key, token, {
        expiry,
        expiryAsDate: true
    })
}

export const revokeAccess = (options = {}) => {
    const { bounce = true } = options

    local.remove('accessToken')
    local.remove('refresh_token')

    if(bounce && !isNative()) {
        bounceUser()
    }
}

const isNative = () => [
    'android',
    'android-new',
    'ios'
].includes(session.get('native')?.toLowerCase())

export const bounceUser = (path = paths.root) => {
    delete state.refresh

    PubSub.publish('auth.signout', {
        path,
        redirect: global.location.pathname
    })
}

export const safeReload = async (timeout = 10000) => {
    const reload = async () => {
        sessionLogger({
            source: 'web',
            event: 'bundle-refresh',
            orgId: local.get('ids')?.organization,
            userId: local.get('ids')?.user,
            instance: session.get('instance')
        })

        await sleep(250)
        global.location.reload()
    }

    if(!state.refresh) {
        reload()
    }

    let retries = 0
    const every = 100

    new Promise((resolve, reject) => {
        const interval = global.setInterval(() => {
            if(!state.refresh) {
                global.clearInterval(interval)
                reload()
                resolve()
            } else if((++retries * every) >= timeout) {
                global.clearInterval(interval)
                reject()
            }
        }, every)
    })
}