import React from 'react'
import { useAccess } from 'contexts/access'
import { useIntl } from 'react-intl'
import { SubscriptionName } from 'components/payment/subscription'
import Paragraph from 'components/typography/paragraph'

const Information = ({ change, warning = false }) => {
    const { formatMessage } = useIntl()
    const { currentBundle } = useAccess()

    if(warning) {
        change = `${change}_warning`
    }

    let translation
    let values = {}
    let information = null

    if(change in translations) {
        translation = translations[change]

        if(change === 'downgrade_warning') {
            values = {
                plan: (
                    <SubscriptionName
                        bundle={currentBundle}
                        prefix={false} />
                )
            }
        }

        information = formatMessage(translation, values)
    }

    if(!information) {
        return null
    }

    return <Paragraph>{information}</Paragraph>
}

export default Information

const translations = {
    downgrade_warning: {
        id: 'payment_plan_information_downgrade_warning',
        defaultMessage: 'You are about to downgrade your subscription plan, and will no longer have access to functionality in the {plan} plan.'
    },
    freeload_warning: {
        id: 'payment_plan_information_freeload_warning',
        defaultMessage: 'To stay on as a customer, you can always pick another subscription plan'
    }
}