import styled from 'styled-components'
import { InlineMessage as InlineMessageBase } from 'components/message'

export const UserStats = styled.div`
    display: flex;
    column-gap: 24px;

    margin-block-end: 16px;

    & > :not(:last-child) {
        position: relative;

        &::after {
            content: "·";
            position: absolute;
            right: -16px;

            width: 8px;

            color: var(--huma-color-foreground-subtle);
        }
    }
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-block-end: 16px;
`