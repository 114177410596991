import styled from 'styled-components'
import PersonFieldBase from 'components/form/field/person'
import { CaptionSmall } from 'components/typography/caption'

export const PersonField = styled(PersonFieldBase)`
    margin-bottom: 16px;
`

export const DocumentationExplainer = styled(CaptionSmall)`
    margin-bottom: 32px;
`