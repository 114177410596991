import styled from 'styled-components'
import { Plain } from 'components/button'

export const People = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`

export const EditContactsButton = styled(Plain)`
    margin-bottom: -8px;
`