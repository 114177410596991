import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { usePayment } from 'contexts/payment'
import { Rows, Heading, TrialCountdown } from './s'
import Kicker from 'components/typography/caption'
import { SubscriptionName } from 'components/payment/subscription'
import { TermDisplay } from 'components/payment'

const CurrentSubscriptionSummary = ({ showTerm = false }) => {
    const { currentBundle } = useAccess()
    const { account } = usePayment()

    const free = !!account.subscription?.free

    return (
        <Rows>
            <Kicker className="compact">
                <FormattedMessage
                    id="payment_plan_heading_current"
                    defaultMessage="Current plan" />
            </Kicker>
            <Heading>
                <span className="name">
                    <SubscriptionName
                        bundle={currentBundle}
                        inactive={free && currentBundle.track !== 'standard'} />
                </span>
                {!!showTerm && (
                    <TermDisplay
                        term={showTerm}
                        as="span" />
                )}
            </Heading>
            <TrialCountdown subscription={account.subscription} />
        </Rows>
    )
}

export default CurrentSubscriptionSummary