import React from 'react'
import { useIntl } from 'react-intl'
import { SickLeaveStatus } from './s'
import Label from './label'

const AbsenceSickLeaveStatus = ({ status, ...props }) => (
    <SickLeaveStatus {...props}>
        <Label status={status} />
    </SickLeaveStatus>
)

const statusTexts = {
    ongoing: {
        id: 'absence_follow_up_status_ongoing',
        defaultMessage: 'Ongoing'
    },
    onHold: {
        id: 'absence_follow_up_status_on_hold',
        defaultMessage: 'On hold'
    },
    upcoming: {
        id: 'absence_follow_up_status_upcoming',
        defaultMessage: 'Upcoming'
    },
    ended: {
        id: 'absence_follow_up_status_ended',
        defaultMessage: 'Ended'
    }
}

export const useSickLeaveStatusTextFormatter = () => {
    const { formatMessage } = useIntl()

    return status => {
        const message = statusTexts[status] ?? null

        return message ?
            formatMessage(message) :
            null
    }
}

export default AbsenceSickLeaveStatus
