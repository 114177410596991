import styled from 'styled-components'
import UsefulLinksBase from 'components/useful-links'
import ArrayFieldBase from 'components/form/field/array'

export const UsefulLinks = styled(UsefulLinksBase)`
    &:not(.compact) {
        margin-bottom: 32px;
    }
`

export const ArrayField = styled(ArrayFieldBase)`
    &:not(.compact) {
        margin-bottom: 32px;
    }
`