import { useLayoutEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

const useResolveItem = ({ items, fetch, onResolved, params, dependencies = [] }) => {
    // eslint-disable-next-line no-unused-vars
    const [_, setSearchParams] = useSearchParams()

    useLayoutEffect(() => {
        if(params?.id && dependencies.every(Boolean)) {
            const resolve = async () => {
                let item = items.find(({ id }) => id === params.id)

                if(!item) {
                    const { ok, response } = await fetch(params.id)

                    if(ok) {
                        item = response
                    }
                }

                item && onResolved(item, () => setSearchParams({}, { replace: true }))
            }

            resolve()
        }
    }, [JSON.stringify(params), dependencies])
}

export const useQueryResolveItem = props => {
    const [urlSearchParams] = useSearchParams()

    return useResolveItem({
        ...props,
        params: Object.fromEntries(urlSearchParams)
    })
}

export const useStateResolveItem = props => {
    const { state } = useLocation()

    return useResolveItem({
        ...props,
        params: state
    })
}