import styled from 'styled-components'
import { interpointDivider } from 'utilities/styled'
import { Grid } from 'components/grid'
import SymbolBase from 'components/symbol'
import { CaptionSmall } from 'components/typography/caption'
import RatingBase from 'components/rating'

export const Wrapper = styled(Grid)`
    grid-template-columns: 40px auto min-content;
    column-gap: 16px;
    align-items: start;

    padding-block: 16px;

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }
`

export const Symbol = styled(SymbolBase)``

export const Name = styled.div`
    line-height: 24px;

    &.deleted {
        text-decoration: line-through;
    }
`

export const Category = styled(CaptionSmall)`
    &.deleted {
        text-decoration: line-through;
    }

    &:not(:last-child) {
        margin-block-end: 8px;
    }
`

export const LevelTooltip = styled.p`
    ${interpointDivider()}
`

export const Rating = styled(RatingBase)`
    margin-block-start: 6px;
`