import styled from 'styled-components'
import LinkBase from 'components/link'

export const Link = styled(LinkBase)`
    display: inline-block;

    margin-top: 16px;

    line-height: 24px;
`
