import styled from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import { FlexChildShrink } from 'components/flex'
import ImportantBase from 'components/important'
import { Plain, Ghost as GhostBase } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { ArrowRight } from 'styled-icons/feather'

export const ImportantColumn = styled(FlexChildShrink)`
    ${belowPhone`
        flex-basis: 100%;
    `}
`

export const Important = styled(ImportantBase)`
    &:empty {
        display: none;
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const PreviewButton = styled(Plain)`
    display: inline-flex;
    align-items: center;
    gap: 16px;

    text-align: start;

    &:is(:hover, :focus) {
        text-decoration: none;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(PreviewButton)}
`

export const Meta = styled.div``

export const Title = styled(Paragraph)`
    ${PreviewButton}:is(:hover, :focus) & {
        text-decoration: underline;
    }
`

export const Arrow = styled(ArrowRight)`
    ${svgStroke()}

    margin-inline-start: auto;

    transition: transform 0.2s ease-in-out;

    ${PreviewButton}:is(:hover, :focus) & {
        transform: translateX(2px);
    }
`

export const Ghost = styled(GhostBase)`
    justify-content: center;
`