import styled from 'styled-components'
import { belowLaptop } from 'utilities/styled'
import { SortableItem } from './category/s'

export const Container = styled.div`
    overflow-y: auto;

    max-height: calc(80vh - 152px);
    padding: 24px 88px 0;

    ${belowLaptop`
        padding-inline: 24px;
    `}
`

export const DragDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Sortables = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;

    min-height: 96px;

    transition: height 0.2s ease-in-out;

    ${SortableItem} & {
        margin-inline-start: 40px;
    }
`