import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'equipment'

const Equipment = props => {
    const {
        initialized,
        checkModule
    } = useAccess()

    const access = initialized && checkModule(moduleIdentifier)
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="equipment.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}>
            <FormattedMessage
                id="noun_equipment"
                defaultMessage="Equipment" />
        </MenuItemLink>
    )
}

export default Equipment