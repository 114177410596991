import styled from 'styled-components'
import { ParagraphSmall } from 'components/typography/paragraph'

export const Retry = styled(ParagraphSmall)`
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease-in;

    &.reveal {
        opacity: 1;
        pointer-events: auto;
    }
`