import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useGetBundlePricing, useGetPriceFrequency } from 'utilities/payment'
import { Price as PriceFormatter, TermSelect } from 'components/payment'
import {
    ExpandableContainer, Expandable, HeaderLayout, HeaderRow,
    ContentLayout, Features, Price, PriceHeading, PriceSummary, PriceDetails
} from './s'
import Divider from 'components/divider'
import { H4 as Name } from 'components/typography/heading'
import { BundleName } from 'components/payment/plan'
import Caption from 'components/typography/caption'
import Paragraph from 'components/typography/paragraph'

const SelectedPlanInformationDetails = ({ bundle, term, setTerm, currency, showDiscount, detailed, salt }) => {
    const { formatMessage } = useIntl()
    const { bundlesByTrack } = useAccess()

    const [editable, setEditable] = useState(false)

    const toLevel = bundlesByTrack?.[bundle.track]?.findIndex(trackBundle => trackBundle.bundle === bundle.bundle)
    const until = bundlesByTrack?.[bundle.track]?.slice(0, toLevel + 1)
    let gaining = until.flatMap(({ features }) => features?.tier ?? [])

    if(!!bundle?.perks?.length) {
        gaining = gaining.concat(bundle.perks)
    }

    const price = useGetBundlePricing()({
        bundle,
        term
    })

    const getPriceFrequency = useGetPriceFrequency()

    const priceSource = !!showDiscount ?
        (price[showDiscount] ?? price.subscription) :
        price.bundle

    const listPrice = priceSource.discounted ? (
        <PriceFormatter
            value={priceSource.listPrice}
            currency={currency}
            old />
    ) : null

    const yourPrice = (
        <PriceFormatter
            value={priceSource.price}
            currency={currency}
            {...(!!listPrice ? { current: true } : null)} />
    )

    const quantituous = price.bundle.charge?.model !== 'Flat'

    const priceFrequency = getPriceFrequency({
        term: price.bundle.pricePeriod,
        quantituous
    })

    return (
        <ExpandableContainer>
            <HeaderLayout {...(editable ? { className: 'editable' } : null)}>
                <HeaderRow>
                    <div>
                        <Name className="compact">
                            <BundleName
                                bundle={bundle}
                                inactive={bundle.free && bundle.track !== 'standard'} />
                        </Name>
                        <Caption className="compact">
                            <FormattedMessage
                                id="payment_information_cost_per_frequency"
                                defaultMessage="{cost} {frequency}"
                                values={{
                                    cost: (
                                        <>
                                            {listPrice}
                                            {(!!listPrice && !!yourPrice) && formatMessage({
                                                id: 'sentence_separator',
                                                defaultMessage: ' '
                                            })}
                                            {yourPrice}
                                        </>
                                    ),
                                    frequency: priceFrequency
                                }} />
                        </Caption>
                    </div>
                </HeaderRow>
                {!bundle.free && (
                    <>
                        {!editable && <Divider {...(!detailed ? { className: 'not-end' } : null)} />}
                        <HeaderRow {...(!detailed ? { className: 'standalone' } : null)}>
                            <TermSelect
                                bundles={[bundle]}
                                value={term}
                                setValue={setTerm}
                                interaction="radio"
                                editable={editable}
                                setEditable={setEditable}
                                salt="manage:selected-product-information" />
                        </HeaderRow>
                    </>
                )}
                {(!editable && detailed) && <Divider className="not-end" />}
            </HeaderLayout>
            {detailed && (
                <Expandable
                    header={state => (
                        <HeaderRow className="standalone">
                            <Paragraph className="compact">
                                <FormattedMessage
                                    id={(state === 'expanded') ?
                                        'payment_information_action_hide_included_functionality' :
                                        'payment_information_action_show_included_functionality'
                                    }
                                    defaultMessage={(state === 'expanded') ?
                                        'Hide what’s included' :
                                        'Show what’s included'
                                    } />
                            </Paragraph>
                        </HeaderRow>
                    )}
                    trigger="header"
                    indicator="arrow">
                    <ContentLayout>
                        <Features
                            features={gaining}
                            size={16}
                            salt={`${salt}:features:gaining`} />
                        <Divider />
                        <Price>
                            <PriceHeading>
                                <FormattedMessage
                                    id="noun_price"
                                    defaultMessage="Price" />
                            </PriceHeading>
                            <PriceSummary>
                                <strong>
                                    {yourPrice}
                                </strong>
                                <PriceDetails>
                                    {priceFrequency}
                                </PriceDetails>
                            </PriceSummary>
                        </Price>
                    </ContentLayout>
                </Expandable>
            )}
        </ExpandableContainer>
    )
}

export default SelectedPlanInformationDetails