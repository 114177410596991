import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { capitalize } from 'utilities/string'
import { Wrapper, Label, Value } from './s'
import { Item } from 'components/statistics-group'
import { SkeletonStrings } from 'components/skeleton'

const HandbookTemplatePreviewDetails = ({ template }) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    const {
        categories = [],
        topicCount = 0,
        language,
        country: countryCode
    } = template ?? {}

    const country = countryCode?.toUpperCase()

    if (!categories?.length) {
        return (
            <Wrapper>
                <Item>
                    <SkeletonStrings length={10} />
                </Item>
                <Item>
                    <SkeletonStrings length={10} />
                </Item>
                <Item>
                    <SkeletonStrings length={10} />
                </Item>
                <Item>
                    <SkeletonStrings length={10} />
                </Item>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            {!!categories?.length && (
                <Item
                    value={categories.length}
                    label={formatMessage({
                        id: 'handbooks_template_categories_total',
                        defaultMessage: '{count, plural, =0 {} =1 {category in total} other {categories in total}}'
                    }, { count: categories.length })} />
            )}
            {!!topicCount && (
                <Item
                    value={topicCount}
                    label={formatMessage({
                        id: 'handbooks_template_topics_total',
                        defaultMessage: '{count, plural, =0 {} =1 {topic in total} other {topics in total}}'
                    }, { count: topicCount })} />
            )}
            {!!language && (
                <Item>
                    <Label className="compact">
                        <FormattedMessage
                            id="noun_language"
                            defaultMessage="Language" />
                    </Label>
                    <Value className="compact">
                        <span>{capitalize(formatDisplayName(language, { type: 'language' }))}</span>
                    </Value>
                </Item>
            )}
            {!!country && (
                <Item>
                    <Label className="compact">
                        <FormattedMessage
                            id="handbooks_template_label_legislation_based_on"
                            defaultMessage="Based on" />
                    </Label>
                    <Value className="compact">
                        <span>{getUnicodeFlagIcon(country)}</span>
                        <span>
                            <FormattedMessage
                                id="territory_legislation_of"
                                defaultMessage="Legislation of {territory}"
                                values={{ territory: formatDisplayName(country, { type: 'region' }) }} />
                        </span>
                    </Value>
                </Item>
            )}
        </Wrapper>
    )
}

export default HandbookTemplatePreviewDetails
