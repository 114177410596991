import React, { useState, Fragment } from 'react'
import JobTitlesProvider, { useJobTitles } from 'contexts/job-titles'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { size } from 'utilities/object'
import { Heading, Ghost } from './s'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import { AI } from 'components/feather'

const EditJobTitle = ({ jobTitle = {}, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        addJobTitle,
        updateJobTitle
    } = useJobTitles()

    const navigate = useNavigate()

    const [constructing, setConstructing] = useState(false)
    const [error, setError] = useState(null)

    const {
        id,
        name,
        description = ''
    } = jobTitle

    const addOrUpdate = async (body, { resetTouched }) => {
        setConstructing(true)

        const addOrUpdateJobTitle = id ?
            updateJobTitle :
            addJobTitle

        const { ok, response } = await addOrUpdateJobTitle(body, id)

        setConstructing(false)

        if(ok) {
            resetTouched()
            response && onDone(response)
        } else if(response?.errorCode === 'object:duplicate') {
            setError(formatMessage({
                id: 'jobtitles_error_duplicate_name',
                defaultMessage: 'A job title with the same name already exists.'
            }))
        } else if(response?.errorMessage) {
            setError(formatMessage({
                id: 'error_generic',
                defaultMessage: 'Something went wrong 🤷‍♀️ {message}'
            }, { message: response.errorMessage }))
        }
    }

    const heading = formatMessage({
        id: id ?
            'jobtitle_action_update' :
            'jobtitle_action_add',
        defaultMessage: id ?
            'Update job title' :
            'Add job title'
    })

    return (
        <Form
            layout="vertical"
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <Heading>{heading}</Heading>
                    <StringField
                        salt={salt}
                        label={formatMessage({
                            id: 'noun_title',
                            defaultMessage: 'Title'
                        })}
                        name="name"
                        field={{
                            value: name,
                            required: true
                        }}
                        onChange={() => setError(null)} />
                    <TextField
                        salt={salt}
                        label={formatMessage({
                            id: 'noun_description',
                            defaultMessage: 'Description'
                        })}
                        name="description"
                        field={{
                            value: description
                        }}
                        onChange={() => setError(null)} />
                    {!error && (
                        <Message
                            type="info"
                            message={formatMessage({
                                id: 'jobtitle_with_description_message',
                                defaultMessage: 'Handle job titles with under Settings for better management. Make sure all job titles have a description.'
                            })}>
                            <Paragraph>
                                <AI size={20} />
                                <FormattedMessage
                                    id="jobtitle_with_description_message_ai"
                                    defaultMessage="You can use AI to generate job description suggestions." />
                            </Paragraph>
                            <Ghost onClick={() => navigate(paths.settings.userprofile)}>
                                <FormattedMessage
                                    id="jobtitles_action_navigate"
                                    defaultMessage="Go to job titles" />
                            </Ghost>
                        </Message>
                    )}
                    {!!error && (
                        <Message
                            type="error"
                            message={error} />
                    )}
                    <Actions className="compact">
                        <Plain
                            onClick={() => onDone()}
                            className="neutral"
                            disabled={constructing}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={!touched.length || !!size(errors)}
                            ref={trigger}>
                            <FormattedMessage
                                id="action_save"
                                defaultMessage="Save" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default ({ provide = false, ...props }) => {
    const Wrapper = provide ?
        JobTitlesProvider :
        Fragment

    const wrapperProps = provide ?
        { fetchOnMount: false } :
        null

    return (
        <Wrapper {...wrapperProps}>
            <EditJobTitle {...props} />
        </Wrapper>
    )
}