import React from 'react'
import { usePerson } from 'contexts/person'
import { FormattedMessage } from 'react-intl'
import { Heading, SummaryList, SummaryTerm, SummaryDescription } from './s'
import Paragraph from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'
import { getFullName, getEmailAddress } from 'utilities/person'

const ConfirmEmailAddressChange = ({ newEmail, onDone }) => {
    const { person } = usePerson()

    const {
        invitedAt,
        lastLoggedInAt
    } = person

    return (
        <>
            <Heading>
                <FormattedMessage
                    id="person_emailaddress_change_confirm_heading"
                    defaultMessage="Confirm email address update" />
            </Heading>
            <Paragraph className="compact">
                <FormattedMessage
                    id="person_emailaddress_change_confirm_summary"
                    defaultMessage="You are about to change the email address of {name}"
                    values={{ name: getFullName(person) }} />
            </Paragraph>
            <SummaryList {...(!invitedAt && !lastLoggedInAt) ? { className: 'compact' } : null}>
                <SummaryTerm>
                    <FormattedMessage
                        id="noun_old"
                        defaultMessage="Old" />
                </SummaryTerm>
                <SummaryDescription>
                    {getEmailAddress(person)}
                </SummaryDescription>
                <SummaryTerm>
                    <FormattedMessage
                        id="noun_new"
                        defaultMessage="New" />
                </SummaryTerm>
                <SummaryDescription>
                    {newEmail}
                </SummaryDescription>
            </SummaryList>
            {(invitedAt || lastLoggedInAt) && (
                <Paragraph className="compact">
                    <FormattedMessage
                        id="person_emailaddress_change_confirm_information"
                        defaultMessage="A confirmation email has been sent to the new address. Huma will continue to use the old address until the new one is confirmed." />
                </Paragraph>
            )}
            <Actions>
                <Plain
                    onClick={() => onDone(false)}
                    className="neutral">
                    <FormattedMessage
                        id="action_cancel"
                        defaultMessage="Cancel" />
                </Plain>
                <Button
                    onClick={() => onDone(true)}
                    className="constructive">
                    <FormattedMessage
                        id="action_confirm_changes"
                        defaultMessage="Confirm changes" />
                </Button>
            </Actions>
        </>
    )
}

export default ConfirmEmailAddressChange
