import styled from 'styled-components'
import { Container as ContainerBase } from 'pages/dashboard/s'
import { CaptionSmall } from 'components/typography/caption'
import { Columns } from 'components/flex'
import { Plain } from 'components/button'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const Container = styled(ContainerBase)`
    position: relative;
    min-height: 40px;
    padding-block-end: 12px;
`

export const GroupHeading = styled(CaptionSmall)`
    margin-block-end: 8px;
`

export const ShowAll = styled(Columns)`
    position: relative;

    flex-wrap: wrap;
    column-gap: 4px;

    margin: 16px 0 8px;
    min-height: 20px;
`

export const ShowAllCount = styled.span`
    font-size: 14px;
    line-height: 20px;
    color: var(--huma-color-foreground-subtle);
`

export const ShowAllButton = styled(Plain)`
    border: none;
    height: auto;

    font-size: 14px;
    line-height: 20px;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    grid-template-columns: 20px 2fr repeat(2, 1fr);
    column-gap: 8px;

    padding-block: 8px;
`