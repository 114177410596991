import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePayment } from 'contexts/payment'
import Link from 'components/link'

export const useBillingBannerMessage = () => {
    const { formatMessage } = useIntl()

    const {
        account,
        challenged
    } = usePayment()

    const payingWithCard = account?.subscription?.collectionMethod === 'automatic'

    if(!challenged || !payingWithCard) {
        return null
    }

    const [{ status }] = account.challenges

    const link = (
        <Link to="path:settings.subscription.base">
            <FormattedMessage
                id="payment_subscription_payment_warning_link"
                defaultMessage="More information" />
        </Link>
    )

    if(status === 'requires_action') {
        return {
            type: 'warning',
            message: formatMessage({
                id: 'payment_subscription_payment_challenged_warning',
                defaultMessage: 'A 3-D Secure authentication is required to complete your latest payment. {link}'
            }, { link })
        }
    }

    if(status === 'requires_payment_method') {
        return {
            type: 'warning',
            message: formatMessage({
                id: 'payment_subscription_payment_failure_warning',
                defaultMessage: 'Your latest payment didn’t go through. Review your payment information. {link}'
            }, { link })
        }
    }

    return null
}

// This original version of the hook supports more
// scenarios with invoices, but is deactivated for now

// export const useBillingBannerMessage = () => {
//     const { formatMessage } = useIntl()
//
//     const {
//         account,
//         challenged
//     } = usePayment()
//
//     if(!challenged && !account?.isPastDue) {
//         return null
//     }
//
//     const payingWithInvoice = account.subscription?.collectionMethod === 'manual'
//     const payingWithCard = account.subscription?.collectionMethod === 'automatic'
//
//     const link = (
//         <Link to="path:settings.subscription.base">
//             <FormattedMessage
//                 id="payment_subscription_payment_warning_link"
//                 defaultMessage="More information" />
//         </Link>
//     )
//
//     if(payingWithInvoice) {
//         return {
//             type: 'warning',
//             message: formatMessage({
//                 id: 'payment_subscription_payment_outstanding_warning',
//                 defaultMessage: 'You have an outstanding payment. {link}'
//             }, { link })
//         }
//     }
//
//     if(payingWithCard && challenged) {
//         return {
//             type: 'warning',
//             message: formatMessage({
//                 id: 'payment_subscription_payment_challenged_warning',
//                 defaultMessage: 'A 3-D Secure authentication is required to complete your latest payment. {link}'
//             }, { link })
//         }
//     }
//
//     if(payingWithCard && !!account?.isPastDue) {
//         return {
//             type: 'warning',
//             message: formatMessage({
//                 id: 'payment_subscription_payment_failure_warning',
//                 defaultMessage: 'Your latest payment didn’t go through. Review your payment information. {link}'
//             }, { link })
//         }
//     }
//
//     return null
// }