import React, { useState, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { FromBreakpoint, BelowBreakpoint, Button, Shortcut } from './s'
import Modal from './modal'
import { Search } from 'styled-icons/feather'

const salt = 'command-bar'

const CommandBar = () => {
    const [show, setShow] = useState(false)

    return (
        <>
            <FromBreakpoint>
                <Button
                    icon={Search}
                    onClick={() => setShow(true)}>
                    <span>
                        <FormattedMessage
                            id="command_bar_search_label"
                            defaultMessage="Quick search" />
                    </span>
                    <Shortcut
                        shortcut={[
                            'mod',
                            'k'
                        ]}
                        className="fill"
                        salt="command-bar" />
                </Button>
            </FromBreakpoint>
            <BelowBreakpoint>
                <Button
                    icon={Search}
                    onClick={() => setShow(true)} />
            </BelowBreakpoint>
            <Modal
                show={show}
                setShow={setShow}
                salt={salt} />
        </>
    )
}

export default CommandBar
