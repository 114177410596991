import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
    Heading,
    UnorderedList, ListItem
} from './s'
import { CloseButton } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import Message from 'components/message'

const FollowUpDetails = ({ countryCode, dismiss }) => {
    const { formatMessage } = useIntl()

    return (
        <>
            <CloseButton onClick={dismiss} />
            <Heading>
                <FormattedMessage
                    id="absence_sick_leave_heading_follow_up_process"
                    defaultMessage="Sick leave follow-up process" />
            </Heading>
            <Paragraph>
                <FormattedMessage
                    id="absence_sick_leave_details_intro"
                    defaultMessage="When an employee is on sick leave, there are some things too keep in mind. <strong>Don’t worry though, we will send you reminders along the way</strong>." />
            </Paragraph>
            <UnorderedList>
                {steps[countryCode].map(step => (
                    <ListItem key={step.id}>
                        <FormattedMessage {...step} />
                    </ListItem>
                ))}
            </UnorderedList>
            <Message
                className="compact"
                type="info"
                message={formatMessage({
                    id: 'absence_sick_leave_message_ends',
                    defaultMessage: 'When the employee’s sick leave is over and ends the Sick leave entry in Huma , this process will end and you will not get any more reminders about this.'
                })} />
        </>
    )
}

export const steps = {
    NO: [
        {
            id: 'absence_sick_leave_details_step_check_up',
            defaultMessage: '<strong>The most important thing:</strong> make sure to check up on them regularly and keep updated on their current state.'
        },
        {
            id: 'absence_sick_leave_details_NO_step_before_week_4',
            defaultMessage: '<strong>Before the 4th week:</strong> Invite them to a meeting where you will discuss and document if there’s anything they can do while on sick leave, and if there’s anything that should be adjusted.'
        },
        {
            id: 'absence_sick_leave_details_NO_step_before_week_7',
            defaultMessage: '<strong>Before the 7th week:</strong> Invite them to Dialogue meeting 1, where you will discuss what can be done to prevent the sick leave to be unnecessarily long. You will also update the followup plan you created.'
        }
    ],
    SE: [
        {
            id: 'absence_sick_leave_details_step_check_up',
            defaultMessage: '<strong>The most important thing:</strong> make sure to check up on them regularly and keep updated on their current state.'
        },
        {
            id: 'absence_sick_leave_details_SE_step_on_day_15',
            defaultMessage: '<strong>On the 15th day:</strong> Register them as sick on Försäkringskassan'
        },
        {
            id: 'absence_sick_leave_details_SE_step_on_day_30',
            defaultMessage: '<strong>On the 30th day:</strong> If they are assumed to be sick for more than 60 days, a plan for return to the workplace should be made.'
        }
    ]
}

export default FollowUpDetails