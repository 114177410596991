import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import EntitiesPicker from 'modals/entities-picker'
import Types from './types'
import GraphPreview from './graph-preview'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'
import { Plus } from 'styled-icons/feather'

const TargetTypesCustom = ({ types = [], setTypes, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const [pickingTypes, setPickingTypes] = useState(false)

    return (
        <>
            {!!types.length && (
                <Types
                    types={types}
                    setTypes={setTypes}
                    salt={salt} />
            )}
            <Plain
                className="constructive with-icon"
                onClick={() => setPickingTypes(true)}>
                <Plus size={24} />
                <FormattedMessage
                    id="competence_action_add"
                    defaultMessage="Add competence" />
            </Plain>
            <GraphPreview types={types} />
            <EntitiesPicker
                show={pickingTypes}
                salt={salt}
                picked={types}
                type="competenceType"
                path="/competence-types"
                dismiss={() => setPickingTypes(false)}
                cancelAction={() => ({
                    label: formatMessage({
                        id: 'action_cancel',
                        defaultMessage: 'Cancel'
                    }),
                    effect: 'neutral',
                    onClick: () => setPickingTypes(false)
                })}
                doneAction={({ picked }) => ({
                    label: formatMessage({
                        id: 'action_continue',
                        defaultMessage: 'Continue'
                    }),
                    effect: 'constructive',
                    onClick: () => setTypes(previousTypes => picked.map(type => {
                        const previousType = previousTypes.find(({ id }) => id === type.id)

                        if(previousType) {
                            return previousType
                        }

                        return {
                            ...type,
                            ...(!['skill', 'language'].includes(type.category) ? { target: true } : null)
                        }
                    }))
                })} />
            <Actions>
                <Button
                    className="constructive"
                    disabled={!types.length || types.some(({ target, category }) => ['skill', 'language'].includes(category) && !target)}
                    onClick={() => onDone({ types })}>
                    <FormattedMessage
                        id="action_save"
                        defaultMessage="Save" />
                </Button>
            </Actions>
        </>
    )
}

export default TargetTypesCustom
