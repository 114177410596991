import styled, { keyframes } from 'styled-components'
import {
    svgStroke,
    fromTablet, belowTablet, iOSSafari
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { Container as ContainerBase } from 'utilities/modal'
import SearchBase from 'components/form/input/text'
import { H4 } from 'components/typography/heading'
import { UnorderedList } from 'components/list'
import { captionSmallCss } from 'components/typography/paragraph'
import { Static } from 'components/empty'

const bgSpin = keyframes`
    to {
        --border-angle: 1turn;
    }
`

export const Container = styled(motion(ContainerBase)).attrs({
    variants: {
        out: {
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .15,
            }
        },
        in: {
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .15
            }
        }
    }
})`
    --command-search-height: 64px;
    --border-angle: 0turn;
    --border-size: 2px;

    margin-block: var(--command-search-height) 0;
    width: 600px;
    padding: 8px;
    box-shadow:
        0 0 1px 0 rgb(--huma-palette-neutral-10),
        0 12px 24px 0 rgb(--huma-palette-neutral-10 / .3),
        0 0 32px 0 rgb(--huma-palette-neutral-10 / .2);
    overflow: clip;

    &::before,
    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;

        border-radius: calc(var(--border-radius) - var(--border-size));
    }

    &::after {
        margin: var(--border-size);
        width: auto;
        height: auto;
        background-color: var(--huma-color-surface-default);
    }

    &::before {
        background-image:
            conic-gradient(
                from var(--border-angle),
                transparent 20%,
                var(--huma-color-surface-brand-bold),
                var(--huma-color-surface-warning-bold)
            );
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        opacity: 0;

        animation: ${bgSpin} 3s infinite linear;
        transition: opacity .2s ease-in-out;

        @property --border-angle {
            syntax: "<angle>";
            inherits: true;
            initial-value: 0turn;
        }
    }

    &.loading {
        &::before {
            opacity: 1;
        }
    }

    ${belowTablet`
        --command-search-height: 40px;

        margin-block: 0;
        border-radius: 0;
        border: unset;
        max-width: 100vw;
        width: 100%;
        max-height: calc(100vh - env(keyboard-inset-height, 0px));
        padding-block-end: 0;
        transform: unset !important;

        hr {
            margin-block: 0;
            margin-inline: -8px;
            width: 100vw;
        }
    `}

    ${iOSSafari`
        max-height: var(--viewport-height);
    `}
`

export const SearchWrapper = styled.div`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    column-gap: 16px;
    align-items: center;

    padding-inline: 12px;

    svg {
        ${svgStroke()}

        &.integration {
            filter: grayscale(1) brightness(.5);
        }
    }

    ${belowTablet`
        column-gap: 8px;

        margin-block-end: 8px;
        padding-inline: 4px;
    `}
`

export const Search = styled(SearchBase)`
    border: none;
    outline: none;
    box-shadow: none !important;
    height: var(--command-search-height);
    padding: 0;

    font-size: 24px;

    ${belowTablet`
        font-size: 18px;
    `}
`

export const Shortcuts = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-inline: 8px;
`

export const ShortcutGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

export const ShortcutLabel = styled.span`
    ${captionSmallCss}
`

export const RecentHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 24px 8px 8px;

    ${belowTablet`
        margin: 16px 16px 0;
    `}
`

export const RecentHeading = styled(H4)`
    font-weight: 400;
    color: var(--huma-color-foreground-subtle);
`

export const Results = styled(UnorderedList)`
    --item-height: 48px;

    position: relative;

    overflow-y: auto;
    padding-block: 8px;
    max-height: calc(5 * var(--item-height) + 16px);

    ${fromTablet`
        margin-block: -8px;

        &:has(> li:nth-child(6)) {
            max-height: calc(5 * var(--item-height) + 32px);
        }
    `}

    ${belowTablet`
        max-height: calc(100vh - env(keyboard-inset-height, 0px) - 58px);

        &:not(:empty) {
            padding-block-end: 8px;
        }
    `}

    ${iOSSafari`
        max-height: calc(var(--viewport-height) - 56px);
    `}
`

export const Empty = styled(Static)`
    min-height: var(--item-height);
    padding-block: 0;
`