import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { useUpgradable } from 'hooks/upgradable'
import { useWhistleblowerPortalUrl } from 'pages/whistleblowing/utilities'
import { MenuItemLink } from './'

const moduleIdentifier = 'whistleblowing'

const Whistleblowing = props => {
    const {
        initialized,

        checkModule,
        check
    } = useAccess()

    const { statuses } = useOrganization()

    const enabled = checkModule(moduleIdentifier)
    const isSetUp = statuses?.hasSetUpWhistleblowing
    const access = initialized && enabled && ['organization:manage', 'whistleblowing:manage'].some(check)
    const upgradable = useUpgradable()({ module: moduleIdentifier })
    const whistleblowerPortalUrl = useWhistleblowerPortalUrl()

    if(initialized && !access && !upgradable && enabled && isSetUp) {
        return (
            <MenuItemLink
                {...props}
                href={whistleblowerPortalUrl}
                module={moduleIdentifier}>
                <FormattedMessage
                    id="noun_whistleblower_portal"
                    defaultMessage="Whistleblower portal" />
            </MenuItemLink>
        )
    }

    return (
        <MenuItemLink
            {...props}
            to="whistleblowing.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}>
            <FormattedMessage
                id="noun_whistleblowing"
                defaultMessage="Whistleblowing" />
        </MenuItemLink>
    )
}

export default Whistleblowing