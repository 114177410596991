import rapid7InsightOps from 'r7insight_js'

const contextify =
    level =>
    (...context) =>
    (...data) =>
    rapid7InsightOps?.[level]?.(...[...context, ...data])

const log = contextify('log')
const info = contextify('info')
const warn = contextify('warn')
const error = contextify('error')

export const createLogger = (level, ...context) => {
    const method = {
        log,
        info,
        warn,
        error
    }[level]

    return method(...context)
}