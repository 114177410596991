import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { getHandbookCategoryUrl } from 'utilities/url'
import { cls } from 'utilities/dom'
import {
    Category,
    Symbol, CategoryContent, Drawer,
    Heading, Summary, InlineMessage, AddButton
} from './s'
import TiptapOutput from 'components/tiptap/output'
import Topics from './topics'
import { ArrowRight, Plus } from 'styled-icons/feather'

const HandbookCategory = ({ category, setActingTopic, expanded, className }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const manageAccess = check('handbook:manage')

    const { filter } = useHandbook()

    const {
        id,
        title,
        symbol,
        topics = [],
        summary,
        updatedDate
    } = category

    const draftTopics = topics.filter(({ status }) => status === 'draft')
    const noPublishedTopics = draftTopics.length === topics.length

    className = cls([
        className,
        noPublishedTopics && 'fade',
        expanded && 'expanded'
    ])

    const animate = expanded ? 'expanded' : 'collapsed'
    const richSummaryAnchorAttributes = expanded ? null : { tabIndex: -1 }

    return (
        <Category
            className={className}
            id={`category-${id}`}>
            <Symbol
                symbol={symbol}
                className="hover-effect" />
            <CategoryContent>
                <Heading
                    to={getHandbookCategoryUrl(category)}
                    onClick={() => global.scrollTo(0, 0)}>
                    <span>{title}</span>
                    <ArrowRight size={24} />
                </Heading>
                <Drawer animate={animate}>
                    {!!summary && (
                        <TiptapOutput
                            content={summary}
                            element={Summary}
                            overrides={{ a: [null, richSummaryAnchorAttributes] }}
                            dependencies={[updatedDate, expanded]} />
                    )}
                    <Topics
                        topics={topics}
                        setActingTopic={setActingTopic}
                        expanded={expanded} />
                    {(noPublishedTopics && expanded && manageAccess && !filter?.viewAs) && (
                        <AddButton
                            className="constructive"
                            onClick={() => setActingTopic({
                                topic: { categoryId: category.id },
                                index: topics.length,
                                mode: 'edit'
                            })}>
                            <Plus size={24} />
                            <FormattedMessage
                                id="handbooks_topic_action_add"
                                defaultMessage="Add topic" />
                        </AddButton>
                    )}
                </Drawer>
            </CategoryContent>
            {noPublishedTopics && (
                <InlineMessage
                    className="compact"
                    type="warning"
                    message={formatMessage({
                        id: 'handbooks_category_message_no_published_topics',
                        defaultMessage: 'This category has no published topics and will not be visible to users without access.'
                    })} />
            )}
        </Category>
    )
}

export default HandbookCategory
