import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useCompetenceRecords } from 'contexts/competence-records'
import { useAccess } from 'contexts/access'
import { refreshCompetenceRecords } from 'pages/competence/utilities'
import { DataList, DataListItem, Label, RecordsGrid } from './s'
import ValueCircle from 'pages/competence/panes/gap/people-table/row/value-circle'
import { Scrollable as Modal } from 'modals/generic'
import ActingRecord from 'pages/competence/modals/record'

const GapDataList = ({ data, salt }) => {
    const { formatMessage } = useIntl()

    const {
        records = [],
        removeRecord
    } = useCompetenceRecords()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const [actingRecord, setActingRecord] = useState(null)

    // Record actions
    const viewRecordAction = record => ({
        salt: `${salt}:view:record`,
        label: formatMessage({
            id: 'action_view_details',
            defaultMessage: 'View details'
        }),
        effect: 'neutral',
        onClick: () => setActingRecord({
            mode: 'view',
            record
        })
    })

    const editRecordAction = record => {
        if(!manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:edit:record`,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setActingRecord({
                mode: 'edit',
                record
            })
        })
    }


    const manageRecordDocumentationAction = record => {
        if(!manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:manage-documentation:record`,
            label: formatMessage({
                id: 'action_manage_documentation',
                defaultMessage: 'Manage documentation'
            }),
            effect: 'neutral',
            onClick: () => setActingRecord({
                mode: 'documentation',
                record
            })
        })
    }

    const removeRecordAction = ({ id }) => {
        if(!manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:delete:record`,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: () => removeRecord(id),
            prompt: {
                question: formatMessage({
                    id: 'competence_action_delete_prompt_question',
                    defaultMessage: 'Confirm deleting competence'
                }),
                message: formatMessage({
                    id: 'competence_action_delete_prompt_message',
                    defaultMessage: 'The competence will be deleted. This can not be undone.'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        })
    }

    const actions = {
        view: viewRecordAction,
        edit: editRecordAction,
        manageDocumentation: manageRecordDocumentationAction,
        remove: removeRecordAction
    }

    if(!data?.length) {
        return null
    }

    const person = data.find(({ peopleWithSkill }) => peopleWithSkill.length)?.peopleWithSkill[0]?.concerns
    const personData = records.filter(record => record.concerns.id === person.id)

    return (
        <>
            <DataList>
                {data.map(({ subject, type, target, peopleWithSkill }) => {
                    const records = peopleWithSkill.filter(({ concerns }) => concerns.id === person.id)

                    return (
                        <DataListItem key={type.id}>
                            <Label className="compact">{subject}</Label>
                            {records.length > 1 && (
                                <RecordsGrid>
                                    {records.map(record => (
                                        <ValueCircle
                                            type={type}
                                            target={target}
                                            person={person}
                                            personData={personData}
                                            record={record}
                                            setActingRecord={setActingRecord}
                                            key={record.id} />
                                    ))}
                                </RecordsGrid>
                            )}
                            {records.length <= 1 && (
                                <ValueCircle
                                    type={type}
                                    target={target}
                                    person={person}
                                    personData={personData}
                                    record={records?.[0] ?? null}
                                    setActingRecord={setActingRecord} />
                            )}
                        </DataListItem>
                    )
                })}
            </DataList>
            <Modal
                show={!!actingRecord}
                dismiss={() => setActingRecord(null)}
                autoFocus={false}
                salt={`${salt}:act`}>
                <ActingRecord
                    {...actingRecord}
                    setActing={setActingRecord}
                    actions={actions}
                    dismiss={() => setActingRecord(null)}
                    onDone={() => {
                        setActingRecord(null)
                        refreshCompetenceRecords()
                    }}
                    salt={`${salt}:act:record`} />
            </Modal>
        </>
    )
}

export default GapDataList
