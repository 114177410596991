import { memo } from 'react'
import { usePage } from 'contexts/page'
import { useMeta } from 'hooks/meta'

const Meta = memo(() => {
    const {
        setMeta,
        titles,
        titlesCap
    } = usePage()

    const title = [...titles].slice(0, titlesCap)

    useMeta({
        title,
        description: title,
        fallback: false
    }, [setMeta])

    return null
})

export default Meta