import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useGetBundleChangeMeta } from 'utilities/payment'
import { IconWrapper } from './s'
import { BundleName } from 'components/payment/plan'
import { Benjamin } from 'components/button'
import { ArrowUpCircle as UpgradeIcon } from 'styled-icons/feather'

const Upgrade = ({
    module,
    feature,
    setManaging,
    useIcon = false,
    as: Trigger = Benjamin,
    salt, // eslint-disable-line no-unused-vars
    ...props
}) => {
    const {
        currentLadder,
        currentBundle,

        getBundlesWithModule,
        getBundlesWithFeature
    } = useAccess()

    const getBundleChangeMeta = useGetBundleChangeMeta()

    if((!module && !feature) || !currentBundle) {
        return null
    }

    const potentialBundles = module ?
        getBundlesWithModule(module) :
        getBundlesWithFeature(feature)

    const toBundle = currentLadder
        .filter(({ bundle }) => bundle !== currentBundle.bundle)
        .find(({ bundle }) => potentialBundles.includes(bundle))

    if(!toBundle) {
        return null
    }

    const isBenjamin = Trigger === Benjamin
    const defaultWithIcon = useIcon && isBenjamin
    const customWithIcon = useIcon && !isBenjamin

    return (
        <Trigger
            {...props}
            {...(defaultWithIcon ? {
                icon: <UpgradeIcon size={16} />
            } : null)}
            onClick={() => setManaging(getBundleChangeMeta({
                track: toBundle.track,
                bundle: toBundle.bundle
            }))}>
            {customWithIcon && (
                <IconWrapper>
                    <UpgradeIcon size={16} />
                </IconWrapper>
            )}
            {useIcon && (
                <span>
                    <FormattedMessage
                        id="payment_plan_cta_upgrade"
                        defaultMessage="Upgrade" />
                </span>
            )}
            {!useIcon && (
                <span>
                    <FormattedMessage
                        id="payment_plan_action_upgrade"
                        defaultMessage="Upgrade to {plan}"
                        values={{ plan: <BundleName bundle={toBundle} /> }} />
                </span>
            )}
        </Trigger>
    )
}

export default Upgrade