import React, { useState, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import {
    ResponsiveContainer,
    Tooltip,
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
    Legend
} from 'recharts'
import { useGroup } from 'contexts/group'
import { useSize } from 'hooks/viewport'
import { getFullName } from 'utilities/person'
import { size, omit } from 'utilities/object'
import { useGetErrorOrWarning } from 'pages/competence/utilities'
import { renderLegends, CustomTooltip } from './custom-chart'

const defaultPolarRadius = [0, 35, 70, 105, 140, 175]

const GapChart = ({ data, configuration = {}, people, totalPeople = 0, individualGraphs = [], salt }) => {
    const { formatMessage } = useIntl()

    const groupContext = useGroup()

    const width = useSize({ dimension: 'width' })

    const [polarRadius, setPolarRadius] = useState(defaultPolarRadius)

    useLayoutEffect(() => {
        if(width < 496) {
            const radius = defaultPolarRadius.map(value => value * (width / 496))

            setPolarRadius(radius)
        } else {
            if(polarRadius !== defaultPolarRadius) {
                setPolarRadius(defaultPolarRadius)
            }
        }
    }, [width])

    const getErrorOrWarning = useGetErrorOrWarning()

    const groupName = ['team', 'location'].includes(configuration?.users?.selectionType) ?
        groupContext?.group?.name :
        formatMessage({
            id: 'noun_group',
            defaultMessage: 'Group'
        })

    data = data.map(({ target, value, type, peopleWithSkill, ...rest }) => {
        const isBooleanType = typeof target === 'boolean'

        return {
            ...rest,
            target: isBooleanType ?
                target ? 5 : 0 :
                target,
            value: isBooleanType ?
                (value / totalPeople) * 5 :
                value,
            displayValue: isBooleanType ?
                (value / totalPeople) * totalPeople :
                value,
            ...individualGraphs
                ?.map(person => {
                    const personWithSkill = peopleWithSkill.find(({ concerns: { id } }) => id === person.id) ?? {}

                    const value = !size(omit(getErrorOrWarning(personWithSkill), 'expiring')) ?
                        (personWithSkill?.value ?? 0) :
                        0

                    return {
                        [person.id]: value,
                        [`${person.id}HasWarning`]: !!size(omit(getErrorOrWarning(personWithSkill), 'expiring'))
                    }
                })
                .reduce((accumulator, value) => ({
                    ...accumulator,
                    ...value
                }), {}),
            category: type.category
        }
    })

    return (
        <ResponsiveContainer
            width="100%"
            height={500}>
            <RadarChart data={data}>
                <Tooltip content={(
                    <CustomTooltip
                        totalPeople={totalPeople}
                        salt={salt} />
                )} />
                <PolarGrid
                    gridType="circle"
                    polarRadius={polarRadius} />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis
                    domain={[0, 5]}
                    axisLine={false}
                    tick={false}
                    tickCount={0}
                    tickLine={false} />
                {!individualGraphs?.length && (
                    <Radar
                        name={groupName}
                        dataKey="value"
                        stroke="var(--huma-color-border-constructive-bold)"
                        fill="var(--huma-color-foreground-constructive-minimal)"
                        fillOpacity={0.5}
                        isAnimationActive={false} />
                )}
                {!!individualGraphs?.length && individualGraphs.map(person => {
                    const name = getFullName(person)
                    const index = people.findIndex(({ id }) => id === person.id)
                    const color = getColor(index)

                    return (
                        <Radar
                            key={person.id}
                            name={name}
                            dataKey={person.id}
                            stroke={color}
                            strokeWidth={2}
                            fill={color}
                            fillOpacity={0.4}
                            isAnimationActive={false} />
                    )
                })}
                <Radar
                    name={formatMessage({
                        id: 'competence_gap_label_target',
                        defaultMessage: 'Target'
                    })}
                    dataKey="target"
                    stroke="var(--huma-color-border-bold)"
                    strokeDasharray="3 3"
                    strokeWidth={2}
                    fill="transparent"
                    isAnimationActive={false} />
                <Legend content={renderLegends} />
            </RadarChart>
        </ResponsiveContainer>
    )
}

export default GapChart

const colors = [
    'var(--huma-color-chart-categorical-1)',
    'var(--huma-color-chart-categorical-2)',
    'var(--huma-color-chart-categorical-3)',
    'var(--huma-color-chart-categorical-4)',
    'var(--huma-color-chart-categorical-5)',
    'var(--huma-color-chart-categorical-6)',
    'var(--huma-color-chart-categorical-7)',
    'var(--huma-color-chart-categorical-8)',
    'var(--huma-color-chart-categorical-9)',
    'var(--huma-color-chart-categorical-10)',
    'var(--huma-color-chart-categorical-11)',
    'var(--huma-color-chart-categorical-12)'
]

const getColor = index => colors[index % colors.length]