import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import EquipmentPieceProvider, { useEquipmentPiece } from 'contexts/equipment-piece'
import { Heading, LoadingContainer } from './s'
import Loader from 'components/loader'
import { Required } from 'components/form/field/s'
import Links from './links'
import Documents from './documents'
import Actions from 'components/form/actions'
import { Plain } from 'components/button'

const ManageAttachments = ({ onDone, ...props }) => {
    const { piece } = useEquipmentPiece()

    const [uploading, setUploading] = useState(false)

    if(!piece) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    const { type } = piece

    return (
        <>
            <Heading>
                <FormattedMessage
                    id="equipment_action_manage_documentation"
                    defaultMessage="Manage documentation" />
                {type?.requireDocumentation && <Required>*</Required>}
            </Heading>
            <Documents
                {...props}
                uploading={uploading}
                setUploading={setUploading} />
            <Links {...props} />
            <Actions className="centered">
                <Plain
                    onClick={onDone}
                    className="neutral"
                    disabled={uploading}>
                    <FormattedMessage
                        id="action_done"
                        defaultMessage="Done" />
                </Plain>
            </Actions>
        </>
    )
}

export default ({ piece, ...props }) => {
    const { id } = piece

    return (
        <EquipmentPieceProvider id={id}>
            <ManageAttachments {...props} />
        </EquipmentPieceProvider>
    )
}
