import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import StatusLabelBase from 'components/status-label'

export const SickLeaveStatus = styled(Grid)`
    grid-column: 2 / 3;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 4px;
    justify-content: start;
    align-items: center;
`

export const StatusLabel = styled(StatusLabelBase)`
    align-self: unset;

    svg {
        ${svgStroke()}

        margin-inline-end: 8px;
    }
`