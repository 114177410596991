import React, { forwardRef } from 'react'
import { CaseStatus } from './s'
import Label from './label'

const DeviationCaseStatus = forwardRef(({ status, ...props }, ref) => {
    if(!status) {
        return null
    }

    return (
        <CaseStatus
            {...props}
            ref={ref}>
            <Label status={status} />
        </CaseStatus>
    )
})

export default DeviationCaseStatus