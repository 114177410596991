import { useLayoutEffect } from 'react'
import { getUserAgent } from 'utilities/user-agent'
import { map } from 'utilities/object'
import PubSub from 'pubsub-js'

export const useKeyboardShortcuts = ({ keys, element = global, options = {} }) => {
    const { os: { isMacOS } } = getUserAgent()

    const onKeyDown = e => {
        const { key, metaKey, ctrlKey, shiftKey } = e
        const modifierPressed = (!!metaKey && !!isMacOS) || (!!ctrlKey && !isMacOS)

        if(modifierPressed) {
            map(keys, (args, character) => {
                const buttons = character.split('+')

                if(buttons.includes('shift') && !shiftKey) {
                    return
                }

                character = buttons.find(c => c !== 'shift')

                if(key.toLowerCase() === character) {
                    if(options.shouldPreventDefault) {
                        e.preventDefault()
                    }

                    PubSub.publish(...args)
                }
            })
        }
    }

    useLayoutEffect(() => {
        element.addEventListener('keydown', onKeyDown)

        return () => {
            element.removeEventListener('keydown', onKeyDown)
        }
    }, [])
}