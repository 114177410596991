import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowLaptop
} from 'utilities/styled'
import { Sortables as TopicsSortables, topicGridCss } from '../s'
import { Grid } from 'components/grid'

export const SortableItem = styled.div`
    position: relative;
    isolation: isolate;

    margin-block-end: 8px;
    border-radius: 8px;
    background-color: var(--huma-color-surface-elevated);
    box-shadow:
        0 2px 1px var(--huma-color-shadow),
        1px 0 1px var(--huma-color-shadow),
        -1px 0 1px var(--huma-color-shadow);

    transition: all 0.2s ease-in-out;

    line-height: 24px;

    ${TopicsSortables} > div:last-child > & {
        margin-block-end: 0;
    }

    ${p => !!p.$isDragging && css`
        box-shadow:
            0 3px 2px 0 var(--huma-color-shadow),
            1px 0 1px var(--huma-color-shadow),
            -1px 0 1px var(--huma-color-shadow);
    `}
`

export const Topic = styled(Grid)`
    ${topicGridCss}

    padding-block: 16px;

    line-height: 28px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

const cellCss = css`
    display: flex;
    align-self: center;

    line-height: 20px;

    svg {
        ${svgStroke()}
    }
`

export const Cell = styled.div`
    ${cellCss}
`

export const TitleCell = styled(Cell)`
    white-space: nowrap;
    overflow: hidden;

    column-gap: 24px;

    padding-inline-start: 24px;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const SharesCell = styled(Cell)`
    ${belowLaptop`
        display: none;
    `}
`