import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'
import ParagraphBase, { ParagraphSmall } from 'components/typography/paragraph'

export const HeroColumns = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    ${belowTablet`
        grid-auto-flow: row;
        row-gap: 8px;
    `}
`

export const LimitStatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${p => (p.$align === 'end') ? 'flex-end' : 'flex-start'};
    row-gap: 4px;
    text-align: ${p => (p.$align === 'end') ? 'end' : 'start'};

    ${belowTablet`
        align-items: flex-start;
    `}
`

export const TextColumns = styled(ParagraphSmall)`
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    gap: 16px;
    align-items: center;
`

export const Paragraph = styled(ParagraphBase)`
    ${LimitStatusWrapper} & {
        margin-block-start: 8px;
    }
`
