import React, { useEffect, useRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import { normalize } from 'utilities/string'
import { cls } from 'utilities/dom'
import { getFullName } from 'utilities/person'
import {
    ListItem,
    Button,
    Label, KeyHintInner, Highlighter
} from './s'
import Avatar from 'components/avatar'
import { iconMap as typeToIconMap } from 'components/group'
import { AvatarWrapper, Deactivated } from 'components/person/s'
import { ArrowRight as Navigate } from 'styled-icons/feather'
import { itemHeight } from './'

const Entity = ({ item, search = '', isActive, confirm, index }) => {
    const itemRef = useRef()

    useEffect(() => {
        if(!!isActive && itemRef.current) {
            const parent = itemRef.current.parentElement
            const { clientHeight: parentHeight } = parent
            const { offsetTop: itemOffset } = itemRef.current
            const buffer = itemHeight * 2

            if(itemOffset + itemHeight + buffer > parentHeight) {
                parent.scrollTo({
                    top: itemOffset - buffer,
                    behavior: 'smooth'
                })
            } else if(index === 0) {
                parent.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
        }
    }, [isActive, index])

    useEffect(() => {
        const onKeyDown = e => {
            const { key } = e

            if(key === 'Tab' && !!isActive) {
                // Scroll to top of list
                const parent = itemRef.current?.parentElement
                parent?.scrollTo({
                    top: 48 * index,
                    behavior: 'smooth'
                })
            }
        }

        document.addEventListener('keydown', onKeyDown)
        return () => document.removeEventListener('keydown', onKeyDown)
    }, [isActive, index])

    const { type } = item
    const entity = item[type]

    const Icon = ['team', 'location'].includes(type) ?
        typeToIconMap[type]
        : null

    return (
        <ListItem
            style={{ '--item-height': `${itemHeight}px` }}
            ref={itemRef}>
            <Button
                className={cls([
                    'neutral',
                    isActive && 'active',
                    'has-icon'
                ])}
                onClick={() => confirm(item)}>
                {['team', 'location'].includes(type) && (
                    <>
                        <Icon size={20} />
                        <Label>
                            <span className="label">
                                <Highlighter
                                    searchWords={[search]}
                                    textToHighlight={entity.name}
                                    sanitize={normalize}
                                    autoEscape />
                            </span>
                        </Label>
                    </>
                )}
                {(type === 'user') && (
                    <>
                        <AvatarWrapper>
                            {!entity.status?.active && <Deactivated size={12} />}
                            <Avatar
                                who={entity}
                                size={20} />
                        </AvatarWrapper>
                        <Label>
                            <span className="label">
                                <Highlighter
                                    searchWords={[search]}
                                    textToHighlight={getFullName(entity)}
                                    sanitize={normalize}
                                    autoEscape />
                            </span>
                        </Label>
                    </>
                )}
                {!!isActive && (
                    <AnimatePresence>
                        <KeyHintInner animate={isActive ? 'active' : 'inactive'}>
                            <Navigate size={16} />
                        </KeyHintInner>
                    </AnimatePresence>
                )}
            </Button>
        </ListItem>
    )
}

export default Entity