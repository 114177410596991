import React from 'react'
import { useIntl } from 'react-intl'
import { usePeople } from 'contexts/people'
import { useAccess } from 'contexts/access'
import { compact } from 'utilities/array'
import Filter from 'components/filter'

const PeopleFilter = ({ overrides, hideNames, namespace, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        filter,
        filterOverride,
        people: items,
        fetching,
        hasFetched,

        setPeopleFilter: setFilter
    } = usePeople()

    const { check } = useAccess()
    const manageAccess = check('users:manage')

    const basicFields = compact([
        !hideNames?.includes('search') && {
            type: 'search',
            name: 'search',
            controlProps: {
                placeholder: formatMessage({
                    id: 'employee_action_find',
                    defaultMessage: 'Find employee'
                })
            },
            ...(overrides?.search ?? null)
        }
    ])

    const advancedFields = compact([
        !hideNames?.includes('jobTitles') && {
            type: 'checkboxes',
            name: 'jobTitles',
            label: formatMessage({
                id: 'person_label_employment_jobtitle',
                defaultMessage: 'Job title'
            }),
            query: { path: '/job-titles' },
            empty: [],
            field: {
                defaultValue: []
            },
            ...(overrides?.jobTitles ?? null)
        },
        !hideNames?.includes('teams') && {
            type: 'checkboxes',
            name: 'teams',
            label: formatMessage({
                id: 'person_label_teams',
                defaultMessage: 'Teams'
            }),
            query: { path: '/teams' },
            empty: [],
            field: {
                defaultValue: []
            },
            ...(overrides?.teams ?? null)
        },
        !hideNames?.includes('locations') && {
            type: 'checkboxes',
            name: 'locations',
            label: formatMessage({
                id: 'person_label_locations',
                defaultMessage: 'Locations'
            }),
            query: { path: '/locations' },
            empty: [],
            field: {
                defaultValue: []
            },
            ...(overrides?.locations ?? null)
        },
        !hideNames?.includes('supervisor') && {
            type: 'supervisor',
            name: 'supervisor',
            label: formatMessage({
                id: 'person_label_supervisor',
                defaultMessage: 'Supervisor'
            }),
            ...(overrides?.supervisor ?? null)
        },
        (manageAccess && !hideNames?.includes('isActive')) && {
            type: 'radiobuttons',
            name: 'isActive',
            label: formatMessage({
                id: filter?.isActive !== 'false' ?
                    'noun_account_status_activated_plural' :
                    'noun_account_status_deactivated_plural',
                defaultMessage: filter?.isActive !== 'false' ?
                    'Active accounts' :
                    'Deactivated accounts'
            }),
            field: {
                defaultValue: '',
                resettable: true,
                options: [
                    {
                        id: 'activated',
                        value: '',
                        name: formatMessage({
                            id: 'noun_account_status_activated_plural',
                            defaultMessage: 'Active accounts'
                        }),
                        checked: filter?.isActive !== 'false',
                        className: 'centered'
                    },
                    {
                        id: 'deactivated',
                        value: 'false',
                        name: formatMessage({
                            id: 'noun_account_status_deactivated_plural',
                            defaultMessage: 'Deactivated accounts'
                        }),
                        checked: filter?.isActive === 'false',
                        className: 'centered'
                    }
                ]
            },
            ...(overrides?.isActive ?? null)
        }
    ])

    return (
        <Filter
            {...props}
            namespace={namespace}
            filter={filter}
            setFilter={filter => setFilter({ filter })}
            basicFields={basicFields}
            advancedFields={advancedFields}
            items={items}
            fetching={fetching}
            hasFetched={hasFetched}
            memoryFilterOptions={{
                ...(props.memoryFilterOptions ?? null),
                ...(filterOverride ? { enabled: false } : null)
            }} />
    )
}

export default PeopleFilter