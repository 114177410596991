import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'onboarding'

const Onboarding = props => {
    const {
        initialized,

        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule(moduleIdentifier) && [
        'users:manage',
        'unit:employment-lifecycles:manage',
        'employment-lifecycles:templates:manage'
    ].some(check)

    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="onboarding.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}>
            <FormattedMessage
                id="noun_onboarding"
                defaultMessage="Onboarding" />
        </MenuItemLink>
    )
}

export default Onboarding