import { useState, useCallback, useEffect } from 'react'

export const useDrag = (element = document.body) => {
    const [dragging, setDragging] = useState(false)

    const onDrag = useCallback(({ type, relatedTarget }) => {
        if(!relatedTarget) {
            // Safari... https://bugs.webkit.org/show_bug.cgi?id=66547
            return
        }

        const enter = type === 'dragenter'

        if(enter) {
            setDragging(true)
        } else if(!element.contains(relatedTarget)) {
            setDragging(false)
        }
    }, [element])

    useEffect(() => {
        if(!element) {
            return
        }

        element.addEventListener('dragenter', onDrag)
        element.addEventListener('dragleave', onDrag)

        return () => {
            element.removeEventListener('dragenter', onDrag)
            element.removeEventListener('dragleave', onDrag)
        }
    }, [element])

    return [dragging, setDragging]
}
