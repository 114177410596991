import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePageView } from 'hooks/page-view'
import { useAuth } from 'contexts/auth'
import { useLogin } from 'contexts/login'
import { useGetDomainWithoutOrganization } from 'utilities/url'
import { cls } from 'utilities/dom'
import { Step, Rows } from '../s'
import { FlexChild, FlexChildShrink } from 'components/flex'
import {
    Heading,
    Providers, SocialProviderButton, InternalProviderButton,
    Centerer
} from './s'
import Form from 'components/form/controller'
import HiddenField from 'components/form/field/hidden'
import RadioField from 'components/form/field/radio'
import StringField from 'components/form/field/string'
import PhoneNumberField from 'components/form/field/phone-number'
import Actions from 'components/form/actions'
import { ButtonSubmit, BackButton } from 'components/button'
import { size } from 'utilities/object'
import { xor } from 'utilities/operator'
import SignupQuestion from '../signup-question'
import { Google, Microsoft } from 'styled-icons/boxicons-logos'
import { Mail } from 'styled-icons/feather'

const TwoConnect = ({ emailVerification, show, ...props }) => {
    const { formatMessage } = useIntl()

    usePageView('login_connect', [show])

    const { native: { platform } } = useAuth()

    const {
        key,
        providers,
        otp,

        loading,

        connectWithSocialProvider,
        connectWithInternalProvider,
        goToPreviousStep
    } = useLogin()

    const noSocialProviders = !!Object.keys(providers).length && !providers?.social?.length
    const herokuReviewApp = global.location.hostname.endsWith('.herokuapp.com')

    const [showEmailForm, setShowEmailForm] = useState((noSocialProviders || herokuReviewApp) ? 'show' : false)

    useEffect(() => {
        // Differentiating between 'show' (string -> implicit) and true (boolean -> explicit)
        if(showEmailForm !== true) {
            setShowEmailForm((noSocialProviders || herokuReviewApp) ? 'show' : false)
        }
    }, [noSocialProviders, herokuReviewApp, showEmailForm])

    const smsAvailable = providers?.internal?.find?.(({ id }) => id.includes('sms'))
    const emailAvailable = providers?.internal?.find?.(({ id }) => id.includes('email'))

    const domainWithoutOrganization = useGetDomainWithoutOrganization()()

    const salt = 'login:2:connect'

    return (
        <Step
            {...props}
            show={show}>
            <Rows>
                <FlexChildShrink>
                    <Heading>
                        <FormattedMessage
                            id="login_two_heading"
                            defaultMessage="Log in to {domain}"
                            values={{ domain: `${key}.${domainWithoutOrganization}` }} />
                    </Heading>
                    {!showEmailForm && (
                        <>
                            <Providers>
                                {providers.social?.map?.(({ id, name }, index) => {
                                    const Icon = providerIconMap[id]

                                    return (
                                        <FlexChild key={`${salt}:${id}`}>
                                            <SocialProviderButton
                                                className="constructive"
                                                icon={Icon}
                                                onClick={() => connectWithSocialProvider(id)}
                                                autoFocus={index === 0}>
                                                {name}
                                            </SocialProviderButton>
                                        </FlexChild>
                                    )
                                })}
                                {!!emailAvailable && (
                                    <InternalProviderButton
                                        icon={Mail}
                                        onClick={() => setShowEmailForm(true)}>
                                        <FormattedMessage
                                            id="login_two_email"
                                            defaultMessage="Log in with email" />
                                    </InternalProviderButton>
                                )}
                            </Providers>
                            <BackButton
                                onClick={goToPreviousStep}
                                text={formatMessage({
                                    id: 'action_go_back',
                                    defaultMessage: 'Go back'
                                })} />
                        </>
                    )}
                    {!!showEmailForm && (
                        <Form
                            layout="vertical"
                            onSubmit={connectWithInternalProvider}>
                            {({ values, touched, errors, trigger }) => (
                                <>
                                    {xor(smsAvailable, emailAvailable) && (
                                        <HiddenField
                                            salt={salt}
                                            name="method"
                                            field={{ value: smsAvailable ? 'phone' : 'email' }} />
                                    )}
                                    {(!!smsAvailable && !!emailAvailable) && (
                                        <Centerer>
                                            <FlexChildShrink>
                                                <RadioField
                                                    salt={salt}
                                                    label={false}
                                                    name="method"
                                                    field={{
                                                        ...(!!otp?.method ? { value: otp.method } : null),
                                                        required: true,
                                                        include: 'always'
                                                    }}
                                                    options={[
                                                        {
                                                            value: 'phone',
                                                            label: formatMessage({
                                                                id: 'noun_sms',
                                                                defaultMessage: 'SMS'
                                                            })
                                                        },
                                                        {
                                                            value: 'email',
                                                            label: formatMessage({
                                                                id: 'noun_email',
                                                                defaultMessage: 'Email'
                                                            })
                                                        }
                                                    ]} />
                                            </FlexChildShrink>
                                        </Centerer>
                                    )}
                                    {(values?.method === 'email') && (
                                        <StringField
                                            className="compact"
                                            salt={salt}
                                            label={formatMessage({
                                                id: 'noun_emailaddress_your',
                                                defaultMessage: 'Your email address'
                                            })}
                                            name="email"
                                            field={{
                                                value: (!!emailVerification?.email && !emailVerification?.error) ?
                                                    emailVerification?.email :
                                                    '',
                                                required: true,
                                                include: 'always'
                                            }}
                                            controlProps={{
                                                type: 'email',
                                                autoComplete: 'email',
                                                'data-1p-ignore': 'false',
                                                autoFocus: true
                                            }} />
                                    )}
                                    {(values?.method === 'phone') && (
                                        <PhoneNumberField
                                            className="compact"
                                            salt={salt}
                                            label={formatMessage({
                                                id: 'noun_phonenumber_your',
                                                defaultMessage: 'Your phone number'
                                            })}
                                            name="phone"
                                            field={{ required: true }}
                                            controlProps={{
                                                autoComplete: 'tel',
                                                'data-1p-ignore': 'false',
                                                autoFocus: !providers?.social?.length
                                            }} />
                                    )}
                                    <Actions className="spread reverse">
                                        <ButtonSubmit
                                            className={cls([
                                                'constructive',
                                                loading && 'loading'
                                            ])}
                                            disabled={!!size(errors) || (!touched.length && !(values?.method === 'email' && !!values?.email)) || loading}
                                            ref={trigger}>
                                            <FormattedMessage
                                                id="action_get_code_unique"
                                                defaultMessage="Get unique code" />
                                        </ButtonSubmit>
                                        <BackButton
                                            onClick={() => noSocialProviders ?
                                                goToPreviousStep() :
                                                setShowEmailForm(false)
                                            }
                                            text={formatMessage({
                                                id: 'action_go_back',
                                                defaultMessage: 'Go back'
                                            })} />
                                    </Actions>
                                </>
                            )}
                        </Form>
                    )}
                </FlexChildShrink>
                {(platform === 'web') && <SignupQuestion />}
            </Rows>
        </Step>
    )
}

export default TwoConnect

const providerIconMap = {
    google: props => <Google {...props} size={16} />,
    microsoft: props => <Microsoft {...props} size={16} />
}