import { useRef, useEffect, useCallback } from 'react'

export const useFlash = flash => {
    const node = useRef()

    const highlight = useCallback(() => {
        node.current.addEventListener('animationend', tidy)
        node.current.classList.add('flash')
    }, [flash])

    const tidy = useCallback(() => {
        node.current.removeEventListener('animationend', tidy)
        node.current.classList.remove('flash')

        if(typeof flash === 'function') {
            flash()
        }
    }, [flash])

    useEffect(() => {
        flash && highlight()
    }, [flash])

    return node
}