import styled, { css } from 'styled-components'
import { Button, Ghost } from 'components/button'
import { H4 } from 'components/typography/heading'
import { Columns } from 'components/flex'
import { Grid } from 'components/grid'
import ActionsBase from 'components/form/actions'

export const Heading = styled(H4).attrs({ as: 'h1' })`
    margin-bottom: 40px;
`

export const Providers = styled.div`
    padding-bottom: 16px;
`

const providerButton = css`
    justify-content: center;

    margin-bottom: 16px;
    width: 100%;
`

export const SocialProviderButton = styled(Button)`
    ${providerButton}
`

export const InternalProviderButton = styled(Ghost)`
    ${providerButton}
`

export const Centerer = styled(Columns)`
    justify-content: center;
`

export const HalfColumns = styled(Grid)`
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
`

export const Actions = styled(ActionsBase)`
    margin-bottom: 16px;
    padding: 0;
`