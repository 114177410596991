import styled from 'styled-components'
import {
    svgStroke,
    belowTablet, belowLaptop, fromTablet
} from 'utilities/styled'
import GenericWidget from 'widgets/generic'
import { FlexChild } from 'components/flex'
import { H4 } from 'components/typography/heading'
import { Simple, CloseButton as CloseButtonBase } from 'components/button'
import { Grid } from 'components/grid'

export const HelpButton = styled(Simple)`
    height: 48px;

    ${belowLaptop`
        height: 40px;
    `}
`

export const Widget = styled(GenericWidget)`
    width: 352px;
    max-height: 90vh;
    padding: 32px 0 0;

    ${belowTablet`
        width: 100%;
        max-width: 100%;
        padding-block-start: 8px;
    `}
`

export const Heading = styled(H4)`
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    margin: 0;
    padding: 0 24px 8px;

    ${belowTablet`
        padding: 0 0 8px;
    `}

    svg {
        ${svgStroke()}
    }
`

export const WidgetContentScroller = styled(FlexChild)`
    position: relative;

    ${fromTablet`
        overflow-y: auto;
    `}
`

export const CloseButton = styled(CloseButtonBase)`
    ${belowTablet`
        display: none;
    `}
`

export const Important = styled.div`
    margin-block-start: 32px;
    border-radius: 8px;
    padding: 8px;
    background-color: var(--huma-color-surface-warning-minimal);

    &:empty {
        display: none;
    }
`

export const ImportantInner = styled(Grid)`
    position: relative;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 24px;

    border-radius: 4px;
    padding: 24px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);
`