import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import CompetenceProfilesProvider from 'contexts/competence-profiles'
import { ToggleSwitch } from './s'
import { ModalHeader } from 'modals/generic'
import Profile from './profile'
import Custom from './custom'

const TargetTypes = ({ dismiss, onDone, types, setTypes, salt }) => {
    const { formatMessage } = useIntl()

    const [custom, setCustom] = useState(false)

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'competence_gap_heading_target',
                    defaultMessage: 'Define competence target'
                })}
                dismiss={dismiss} />
            <ToggleSwitch
                controlled value={custom}
                no={formatMessage({
                    id: 'competence_gap_target_profile',
                    defaultMessage: 'Profile'
                })}
                yes={formatMessage({
                    id: 'competence_gap_target_custom',
                    defaultMessage: 'Custom'
                })}
                onChange={setCustom} />
            {!custom && (
                <Profile
                    setCustom={setCustom}
                    setTypes={setTypes}
                    onDone={onDone}
                    salt={salt} />
            )}
            {!!custom && (
                <Custom
                    types={types}
                    setTypes={setTypes}
                    onDone={onDone}
                    salt={salt} />
            )}
        </>
    )
}

export default props => (
    <CompetenceProfilesProvider autoFetch={true}>
        <TargetTypes {...props} />
    </CompetenceProfilesProvider>
)