import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import EntityProvider, { useEntity } from 'contexts/entity'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { getHandbookCategoryUrl } from 'utilities/url'
import { hashify } from 'pages/handbook/utilities'
import { Scrollable as Modal } from 'modals/generic'
import Notify from 'pages/handbook/modals/notify'
import Successful from 'pages/handbook/modals/successful'
import View from './view'
import Edit from './edit'

const ActingHandbookTopic = ({ mode = 'view', ...props }) => {
    const { formatMessage } = useIntl()

    const {
        handbook,

        filter,

        updateTopic,
        removeTopic,
        getCategoryById
    } = useHandbook()

    const {
        topic,
        deleted
    } = useEntity()

    const {
        check,
        checkFeature
    } = useAccess()

    const manageAccess = check('handbook:manage')
    const featuredAvailable = checkFeature('handbook-featured')

    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const [successfulMessage, setSuccessfulMessage] = useState(null)
    const [showingNotify, setShowingNotify] = useState(null)

    if(deleted) {
        return null
    }

    const {
        dismiss,
        onDone,
        salt
    } = props

    const changePinnedAction = ({ id, pinned }) => {
        if(!manageAccess || filter?.viewAs || !id || !featuredAvailable || mode === 'edit') {
            return null
        }

        return {
            salt: `${salt}:change-pinned`,
            label: formatMessage({
                id: pinned ?
                    'handbooks_topic_action_unpin' :
                    'handbooks_topic_action_pin',
                defaultMessage: pinned ?
                    'Unpin from featured topics' :
                    'Pin as featured topic'
            }),
            effect: 'neutral',
            onClick: async () => {
                setLoading(true)

                const { ok, response } = await updateTopic({
                    body: { pinned: !pinned },
                    id
                })

                if(ok && onDone) {
                    onDone(response)
                }

                setLoading(false)
            }
        }
    }

    const publishAction = ({ id, status }) => {
        if(!manageAccess || filter?.viewAs || !id || status !== 'draft' || mode === 'edit') {
            return null
        }

        return {
            salt: `${salt}:publish`,
            label: formatMessage({
                id: (handbook.status === 'published') ?
                    'action_publish' :
                    'action_mark_ready',
                defaultMessage: (handbook.status === 'published') ?
                    'Publish' :
                    'Mark as ready'
            }),
            effect: 'neutral',
            loading: loading === 'publish',
            onClick: async () => {
                setLoading('publishing')

                const { ok, response } = await updateTopic({
                    body: { status: 'published' },
                    id
                })

                setLoading(null)
                ok && onDone?.(response)
            }
        }
    }

    const notifyAction = ({ id, status }) => {
        if(!manageAccess || !id || filter?.viewAs || status !== 'published' || handbook?.status !== 'published' || mode !== 'view') {
            return null
        }

        return {
            salt: `${salt}:notify`,
            label: formatMessage({
                id: 'notification_toggle_label',
                defaultMessage: 'Send notification'
            }),
            effect: 'neutral',
            onClick: () => setShowingNotify({
                what: 'topic',
                id
            })
        }
    }

    const unpublishAction = ({ id, status }) => {
        if(!manageAccess || filter?.viewAs || !id || status !== 'published') {
            return null
        }

        return {
            salt: `${salt}:unpublish`,
            label: formatMessage({
                id: (handbook.status === 'published') ?
                    'action_unpublish' :
                    'action_mark_draft',
                defaultMessage: (handbook.status === 'published') ?
                    'Unpublish' :
                    'Mark as draft'
            }),
            effect: 'neutral',
            loading: loading === 'unpublishing',
            onClick: async () => {
                setLoading('unpublishing')

                const { ok, response } = await updateTopic({
                    body: { status: 'draft' },
                    id
                })

                setLoading(null)
                ok && onDone?.(response)
            }
        }
    }

    const copyLinkAction = ({ id, categoryId, status }) => {
        if(
            !id ||
            (
                mode === 'view' &&
                !manageAccess &&
                handbook.status === 'published' &&
                status === 'draft'
            )
        ) {
            return null
        }

        const clipboardText = `${global.location.origin}${getHandbookCategoryUrl({ id: categoryId })}#${hashify(id, 'topic')}`

        return {
            salt: `${salt}:copy-link`,
            label: formatMessage({
                id: 'action_copy_link',
                defaultMessage: 'Copy link'
            }),
            effect: 'neutral',
            onClick: () => global.navigator.clipboard.writeText(clipboardText)
        }
    }

    const deleteAction = ({ id }) => {
        if(!manageAccess || filter?.viewAs || !id) {
            return null
        }

        return {
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            prompt: {
                question: formatMessage({
                    id: 'handbook_topic_action_delete_prompt_question',
                    defaultMessage: 'Confirm removing this topic'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            },
            loading: deleting,
            onClick: async () => {
                setDeleting(true)

                const { ok } = await removeTopic(id)
                if(ok) {
                    onDone?.({
                        id,
                        deleted: true
                    })
                    dismiss?.()
                }

                setDeleting(false)
            }
        }
    }

    const Topic = {
        view: View,
        edit: Edit
    }[mode]

    if(topic?.categoryId) {
        props.category = getCategoryById(topic.categoryId)
    }

    return (
        <>
            <Topic
                {...props}
                topic={topic}
                id={topic?.id}
                actions={{
                    changePinned: changePinnedAction,
                    publish: publishAction,
                    unpublish: unpublishAction,
                    notify: notifyAction,
                    copyLink: copyLinkAction,
                    delete: deleteAction
                }}
                loading={!!loading} />
            <Modal
                show={showingNotify}
                dismiss={() => setShowingNotify(null)}
                salt={`${salt}:notify`}>
                <Notify
                    {...showingNotify}
                    dismiss={() => setShowingNotify(null)}
                    onDone={what => {
                        setShowingNotify(null)
                        setSuccessfulMessage({ what })
                    }}
                    salt={`${salt}:notify`} />
            </Modal>
            <Modal
                show={successfulMessage}
                dismiss={() => setSuccessfulMessage(null)}
                salt={`${salt}:completed-publishing`}>
                <Successful
                    {...successfulMessage}
                    dismiss={() => setSuccessfulMessage(null)}
                    salt={`${salt}:completed-publishing`} />
            </Modal>
        </>
    )
}

export default props => {
    const {
        topic,
        ...rest
    } = props

    return (
        <EntityProvider
            name="topic"
            topic={topic}
            subscribe={{
                created: 'handbook:topic:created',
                updated: 'handbook:topic:updated',
                deleted: 'handbook:topic:deleted'
            }}>
            <ActingHandbookTopic {...rest} />
        </EntityProvider>
    )
}