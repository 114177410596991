import { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { fromLaptop, belowDesktop, belowLaptop, iPhone11 } from 'utilities/styled'
import { motion } from 'framer-motion'
import { Grid } from 'components/grid'

export const PlainNavigations = Fragment

export const AnimatedNavigations = styled.div`
    position: relative;
    overflow-x: hidden;

    height: calc(100vh - var(--huma-global-main-layout-header-height));

    ${belowLaptop`
        width: 92vw;
        max-width: 320px;
    `}
`

const commonNavigationStyles = css`
    padding-block-end: 24px;
    user-select: none;

    ${fromLaptop`
        overflow-x: hidden;
        overflow-y: auto;

        max-height: calc(100vh - var(--huma-global-main-layout-header-height));
        padding-block-start: 40px;
    `}

    ${belowDesktop`
        padding-block-start: 24px;
    `}

    ${belowLaptop`
        order: 2;

        width: 92vw;
        max-width: 320px;
        padding-block-start: 16px;
        background: var(--huma-color-surface-minimal);
    `}

    ${iPhone11`
        padding-block-end: 30px;
    `}
`

export const PlainNavigation = styled.nav`
    ${commonNavigationStyles}
`

export const AnimatedNavigation = styled(motion.nav).attrs(attrs => ({
    ...attrs,
    variants: {
        out: {
            x: 64 * (attrs.className?.includes('primary') ? -1 : 1),
            opacity: 0,
            pointerEvents: 'none',
            overflow: 'hidden'
        },
        in: {
            x: 0,
            opacity: 1,
            pointerEvents: 'none',
            overflow: 'hidden',
            transitionEnd: {
                pointerEvents: 'auto',
                overflow: null
            }
        }
    },
    transition: {
        type: 'spring',
        mass: .5,
        stiffness: 100,
        velocity: 2
    },
    exit: 'out'
}))`
    ${commonNavigationStyles}
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

export const NavigationItems = styled(Grid)`
    grid-auto-rows: auto;
    grid-auto-flow: row;
`