import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { useConfiguration } from 'contexts/configuration'
import { usePerson } from 'contexts/person'
import { safeFormat } from 'utilities/date-time'
import { differenceInDays } from 'date-fns'
import { useInvitationData } from 'hooks/invitation-data'
import { Message } from './s'
import { ParagraphSmall } from 'components/typography/paragraph'
import Link from 'components/link'
import InviteUsersModal from 'modals/generic'
import InviteUsersContent from 'pages/people/panes/everyone/invite/invite-users-content'
import InspectInvitationModal from 'pages/people/panes/everyone/invite/inspect-invitation-modal'
import { getListRepresentationFromProfile } from 'utilities/person'

const Invite = ({ salt }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { check } = useAccess()
    const { configuration } = useConfiguration()
    const { person } = usePerson()

    const [inviting, setInviting] = useState(false)
    const [inspecting, setInspecting] = useState(false)
    const [invited, setInvited] = useState(false)

    const { invitationData, setInvitationData } = useInvitationData()

    const manageAccess = check('users:manage')

    if(!manageAccess || !person || invited) {
        return null
    }

    const {
        invitedAt,
        lastLoggedInAt
    } = person

    if(!!lastLoggedInAt) {
        return null
    }

    const recentlyInvited = !!invitedAt && differenceInDays(new Date(), new Date(invitedAt)) < configuration.invited_recently_days

    const users = [{
        ...getListRepresentationFromProfile(person),
        invitedAt,
        recentlyInvited
    }]

    return (
        <>
            <Message
                type="info"
                message={formatMessage({
                    id: !!invitedAt ?
                        'person_label_not_logged_in' :
                        'person_label_invitation_not_sent',
                    defaultMessage: !!invitedAt ?
                        'Not logged in yet' :
                        'Not invited yet'
                })}
                className="compact">
                {!!invitedAt && (
                    <ParagraphSmall className="compact">
                        <FormattedMessage
                            id="person_label_invitation_sent_on_date"
                            defaultMessage="Invitation sent {date}"
                            values={{ date: safeFormat(invitedAt, 'PPP', { locale }) }} />
                    </ParagraphSmall>
                )}
                <ParagraphSmall className="compact">
                    <Link
                        onClick={() => setInviting(true)}
                        className="constructive">
                        <FormattedMessage
                            id={recentlyInvited ?
                                'more_information' :
                                !!invitedAt ?
                                    'action_send_invitation_again_options' :
                                    'action_send_invitation_options'
                            }
                            defaultMessage={recentlyInvited ?
                                'More information' :
                                !!invitedAt ?
                                    'Send invitation again…' :
                                    'Send invitation…'
                            } />
                    </Link>
                </ParagraphSmall>
            </Message>
            <InviteUsersModal
                show={!!inviting}
                dismiss={() => setInviting(false)}
                salt={`${salt}:users`}>
                <InviteUsersContent
                    users={users}
                    inspectInvitationEmail={() => setInspecting(true)}
                    invitationData={invitationData}
                    onDone={() => {
                        setInviting(false)
                        setInvited(true)
                    }}
                    salt={`${salt}:users`} />
            </InviteUsersModal>
            <InspectInvitationModal
                users={users}
                show={!!inspecting}
                invitationData={invitationData}
                setInvitationData={setInvitationData}
                dismiss={() => setInspecting(false)}
                salt={`${salt}:invite`} />
        </>
    )
}

export default Invite