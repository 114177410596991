import React from 'react'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { Tasks, Documents, Meetings, SalaryRevisionGroups, Absence, Whistleblowing, Deviation } from './wrappers'
import TodoList from './todo-list'
import TasksList from './tasks'

const Todos = ({ hideIfEmpty, ...props }) => {
    const {
        access,
        hasFetchedDeviationCases, totalDeviationCases,
        hasFetchedWhistleblowingCases, totalWhistleblowingCases,
        hasFetchedSalaryRevisionGroups, totalSalaryRevisionGroups,
        hasFetchedAbsence, totalAbsence,
        hasFetchedMeetings, totalMeetings,
        hasFetchedDocuments, totalDocuments,
        hasFetchedTasks, totalTasks
    } = props

    if(
        hideIfEmpty &&
        (hasFetchedDeviationCases && !totalDeviationCases) &&
        (hasFetchedWhistleblowingCases && !totalWhistleblowingCases) &&
        (hasFetchedAbsence && !totalAbsence) &&
        (hasFetchedSalaryRevisionGroups && !totalSalaryRevisionGroups) &&
        (hasFetchedMeetings && !totalMeetings) &&
        (hasFetchedDocuments && !totalDocuments) &&
        (hasFetchedTasks && !totalTasks)
    ) {
        return null
    }

    if([
        access.deviation && hasFetchedDeviationCases && !!totalDeviationCases,
        access.whistleblowing && hasFetchedWhistleblowingCases && !!totalWhistleblowingCases,
        access.absence && hasFetchedAbsence && !!totalAbsence,
        access.salaryRevisionGroups && hasFetchedSalaryRevisionGroups && !!totalSalaryRevisionGroups,
        access.users,
        access.meetings && hasFetchedMeetings && !!totalMeetings,
        access.documents && hasFetchedDocuments && !!totalDocuments
    ].some(Boolean)) {
        return <TodoList {...props} />
    }

    return <TasksList />
}

const withProviders = (...providers) => WrappedComponent => props => {
    const {
        check,
        checkModule,
        checkFeature
    } = useAccess()

    const { statuses } = useOrganization()

    const access = {
        tasks: checkModule('tasks'),
        deviation: !!statuses?.hasSetUpDeviation && checkModule('deviation') && check('deviation:manage'),
        whistleblowing: !!statuses?.hasSetUpWhistleblowing && checkModule('whistleblowing') && check('whistleblowing:manage'),
        absence: !!statuses?.absence && checkModule('absence') && (check('absence:manage') || check('user:absence:manage')),
        salaryRevisionGroups: checkModule('salary') && checkFeature('salary-revision-reviewers'),
        users: check('users:manage'),
        meetings: checkModule('performance'),
        documents: checkModule('documents')
    }

    props = {
        ...props,
        access
    }

    return providers.reduceRight((accumulator, Provider) => (
        <Provider access={access}>
            {accumulator}
        </Provider>
    ), <WrappedComponent {...props} />)
}

export default withProviders(Tasks, Documents, Meetings, SalaryRevisionGroups, Absence, Whistleblowing, Deviation)(Todos)