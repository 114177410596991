import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Plain } from 'components/button'

export const AddButton = styled(Plain)`
    svg {
        ${svgStroke()}

        margin-inline-end: 16px;
    }
`

export const DragDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Sortables = styled.div`
    flex-grow: 1;
`