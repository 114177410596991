import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { map } from 'utilities/object'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { categoryToMetaMap, categoryLevelMap } from 'pages/competence/constants/category'
import {
    Wrapper,
    Symbol,
    Name, Category,
    LevelTooltip
} from './s'
import Form from 'components/form/controller'
import RatingField from 'components/form/field/rating'
import Tooltip, { Trigger } from 'components/tooltip'
import { Plain } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import Definitions from 'pages/competence/components/level/definitions'
import { symbol as fallbackSymbol } from 'pages/competence'

const Type = ({ type, types, setTypes, salt }) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    const [showingDefinitions, setShowingDefinitions] = useState(false)

    salt = `${salt}:type:${type?.id ?? 'deleted'}`

    if(!type?.id) {
        return (
            <Wrapper>
                <Symbol symbol={fallbackSymbol} />
                <div>
                    <Name className="deleted">
                        <FormattedMessage
                            id="competence_status_deleted"
                            defaultMessage="Deleted" />
                    </Name>
                    <Category className="compact interpoint-divider deleted">
                        <span>
                            <FormattedMessage
                                id="noun_category"
                                defaultMessage="Category" />
                        </span>
                    </Category>
                </div>
            </Wrapper>
        )
    }

    return (
        <>
            <Form
                onChange={({ target }) => {
                    if(!!target) {
                        setTypes(types.map(targettedType => {
                            if(targettedType.id === type.id) {
                                target = target === 'true' ?
                                    true :
                                    target === 'false' ?
                                        false :
                                        parseInt(target)

                                return {
                                    ...targettedType,
                                    target
                                }
                            }

                            return targettedType
                        }))
                    }
                }}>
                <Wrapper>
                    <Symbol symbol={type.symbol} />
                    <div>
                        <Name>{categoryFormatter(type)}</Name>
                        <Category className="compact interpoint-divider">
                            <span>
                                <FormattedMessage {...categoryToMetaMap[type.category].title} />
                            </span>
                            {['language', 'skill'].includes(type.category) && (
                                <>
                                    {!!type.target && (
                                        <>
                                            <span>{type.target}</span>
                                            <span>
                                                <FormattedMessage {...categoryLevelMap[type.category][type.target].title} />
                                                <Tooltip content={formatMessage({
                                                    id: 'noun_level_definitions',
                                                    defaultMessage: 'Level definitions'
                                                })}>
                                                    <Trigger onClick={() => setShowingDefinitions(true)} />
                                                </Tooltip>
                                            </span>
                                        </>
                                    )}
                                    {!type.target && (
                                        <span>
                                            <FormattedMessage
                                                id="competence_action_add_level"
                                                defaultMessage="Add level" />
                                            <Tooltip content={formatMessage({
                                                id: 'noun_level_definitions',
                                                defaultMessage: 'Level definitions'
                                            })}>
                                                <Trigger onClick={() => setShowingDefinitions(true)} />
                                            </Tooltip>
                                        </span>
                                    )}
                                </>
                            )}
                        </Category>
                        {['language', 'skill'].includes(type.category) && (
                            <RatingField
                                salt={salt}
                                className="compact"
                                name="target"
                                field={{ value: type.target }}
                                tooltips={map(categoryLevelMap[type.category], category => {
                                    const subTitleId = category.subTitle?.id
                                    const subTitle = subTitleId ? formatMessage({ id: subTitleId }) : null
                                    const hasSubTitle = subTitleId && subTitle !== subTitleId

                                    return (
                                        <LevelTooltip>
                                            <span>
                                                <FormattedMessage {...category.title} />
                                            </span>
                                            {hasSubTitle && (
                                                <span>
                                                    <FormattedMessage {...category.subTitle} />
                                                </span>
                                            )}
                                        </LevelTooltip>
                                    )

                                })} />
                        )}
                    </div>
                    <Plain
                        className="destructive"
                        onClick={() => setTypes(types.filter(({ id }) => id !== type.id))}>
                        <FormattedMessage
                            id="action_remove"
                            defaultMessage="Remove" />
                    </Plain>
                </Wrapper>
            </Form>
            <Modal
                className="medium"
                show={!!showingDefinitions}
                dismiss={() => setShowingDefinitions(false)}
                salt={`${salt}:definitions`}>
                <Definitions
                    type={type.category}
                    {...(type.target ? { focus: type.target } : null)}
                    dismiss={() => setShowingDefinitions(false)}
                    salt={`${salt}:definitions`} />
            </Modal>
        </>
    )
}

export default Type
