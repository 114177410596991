import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { compact } from 'utilities/array'
import { useCompetenceRecord } from 'contexts/competence-record'
import { DragDropWrapper, Sortables, AddButton } from './s'
import { Label } from 'components/form/field/s'
import { Scrollable as Modal } from 'modals/generic'
import EditLink from './edit-link'
import SortableLink from 'components/sortable-link'
import { Plus } from 'styled-icons/feather'

const AttachmentLinks = ({ salt }) => {
    const { formatMessage } = useIntl()

    const {
        record,
        updateRecord
    } = useCompetenceRecord()

    const {
        id,
        links,
        type,
        category
    } = record

    const { id: typeId } = type

    const [editingLink, setEditingLink] = useState(false)

    const removeLink = async link => {
        const {
            name,
            url
        } = link
        const links = record.links.filter(link => link.url !== url && link.name !== name)
        const body = {
            typeId,
            category,
            links
        }

        await updateRecord(body, id)
    }

    const updateLinks = async links => {
        const body = {
            typeId,
            category,
            links
        }

        await updateRecord(body, id)
    }

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed)

        return list
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if(!destination || destination.index === source.index) {
            return
        }

        const order = reorder(
            links,
            source.index,
            destination.index
        )

        updateLinks([...order])
    }

    const linkActions = compact([
        link => ({
            salt: `${salt}:edit`,
            label: formatMessage({
                id: 'action_edit_link',
                defaultMessage: 'Edit link'
            }),
            onClick: () => setEditingLink(link),
            effect: 'neutral'
        }),
        link => ({
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'action_delete_link',
                defaultMessage: 'Delete link'
            }),
            onClick: () => removeLink(link),
            effect: 'destructive'
        })
    ])

    return (
        <>
            <Label
                className="vertical"
                optional={formatMessage({
                    id: 'competence_documentation_optional',
                    defaultMessage: 'Documents and links are only visible to the user they belong to and competence administrators'
                })}>
                <FormattedMessage
                    id="noun_links"
                    defaultMessage="Links"
                />
            </Label>
            {!!links?.length && (
                <DragDropWrapper>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <Sortables
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {links.map((link, index) => {
                                        return (
                                            <Draggable
                                                key={`${salt}:draggable:${link.url}:${link.name}`}
                                                isDragDisabled={false}
                                                disableInteractiveElementBlocking={true}
                                                draggableId={`${salt}:draggable:${link.url}:${link.name}`}
                                                index={index}>
                                                {(provided, snapshot) => {
                                                    const props = {
                                                        link,
                                                        actions: linkActions,
                                                        count: links.length,
                                                        provided,
                                                        snapshot,
                                                        salt
                                                    }

                                                    return <SortableLink {...props} />
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </Sortables>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DragDropWrapper>
            )}
            <AddButton
                className="constructive"
                onClick={() => setEditingLink(true)}>
                <Plus size={24} />
                <FormattedMessage
                    id="action_add_link"
                    defaultMessage="Add link" />
            </AddButton>
            <Modal
                show={editingLink}
                dismiss={() => setEditingLink(null)}
                salt={salt}>
                <EditLink
                    salt={salt}
                    record={record}
                    link={editingLink}
                    onDone={() => setEditingLink(null)} />
            </Modal>
        </>
    )
}

export default AttachmentLinks
