import React from 'react'
import loadable from '@loadable/component'
import { useIntl } from 'react-intl'
import { getTypeModule, getTypePaths, getPluralizedType } from './utilities'
import { compact } from 'utilities/array'
import Hero from './hero'
import { Routes, Route } from 'react-router-dom'
import { TabsAndPanes, getPathWithoutBase } from 'components/tabs'
import RouteAuthorization from 'app/route-authorization'
import DelayedRedirect from 'components/delayed-redirect'

import ActiveProcessesPane from './panes/processes'
const ArchivedProcessesPane = loadable(() => import(/* webpackPrefetch: true */ './panes/archive'))
const TemplatesPane = loadable(() => import(/* webpackPrefetch: true */ './panes/templates'))

const ProcessPage = loadable(() => import(/* webpackPrefetch: true */ './pages/process'))
const TemplatePage = loadable(() => import(/* webpackPrefetch: true */ './pages/template'))

const salt = 'processes'

const ProcessesRouting = ({ type, access, upgradable }) => {
    const { formatMessage } = useIntl()

    const module = getTypeModule(type)
    const pluralizedType = getPluralizedType(type)
    const typePaths = getTypePaths(type)
    const { base } = typePaths
    const getRelativePath = getPathWithoutBase(typePaths.base)

    const panes = compact([
        (access.usersManage || access.unitProcessesManage) && {
            path: base,
            label: formatMessage({
                id: 'status_in_progress',
                defaultMessage: 'In progress'
            }),
            pane: ActiveProcessesPane,
            pepper: 'in-progress'
        },
        (access.usersManage || access.unitProcessesManage) && {
            path: typePaths.archive,
            label: formatMessage({
                id: 'employment_lifecycle_section_archive',
                defaultMessage: 'Archive'
            }),
            pane: ArchivedProcessesPane,
            pepper: 'archived'
        },
        access.processesTemplatesManage && {
            path: (!access.usersManage && !access.unitProcessesManage) ?
                base :
                typePaths.templates,
            label: formatMessage({
                id: 'noun_templates',
                defaultMessage: 'Templates'
            }),
            pane: TemplatesPane,
            pepper: 'templates'
        }
    ]).map(pane => ({
        ...pane,
        type,
        access,
        upgradable: pane.upgradable ?? upgradable
     }))

    return (
        <Routes>
            <Route element={(
                <>
                    <Hero
                        type={type}
                        upgradable={upgradable} />
                    <TabsAndPanes
                        panes={panes}
                        salt={`${salt}:${pluralizedType}`} />
                </>
            )}>
                {panes.map(({ path, pane: Pane, label, upgradable, pepper, ...props }) => {
                    const key = `${salt}:${pluralizedType}:${pepper}`

                    return (
                        <Route
                            path={getRelativePath(path)}
                            element={(
                                <RouteAuthorization
                                    module={module}
                                    upgradable={upgradable}>
                                    <Pane
                                        {...props}
                                        title={label}
                                        salt={key}
                                        key={key} />
                                </RouteAuthorization>
                            )}
                            key={`${key}:route`} />
                    )
                })}
            </Route>
            <Route
                path={getRelativePath(typePaths.new)}
                element={(
                    <RouteAuthorization module={module}>
                        <ProcessPage
                            type={type}
                            module={module} />
                    </RouteAuthorization>
                )} />
            <Route
                path={getRelativePath(typePaths[type])}
                element={(
                    <RouteAuthorization module={module}>
                        <ProcessPage
                            type={type}
                            module={module} />
                    </RouteAuthorization>
                )} />
            <Route
                path={getRelativePath(typePaths.template)}
                element={(
                    <RouteAuthorization module={module}>
                        <TemplatePage
                            type={type}
                            module={module} />
                    </RouteAuthorization>
                )} />
            <Route
                path="*"
                element={<DelayedRedirect to={typePaths.base} />} />
        </Routes>
    )
}

export default ProcessesRouting