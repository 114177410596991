import styled from 'styled-components'
import { Grid } from 'components/grid'
import { paragraphCss } from 'components/typography/paragraph'

export const Details = styled(Grid)`
    grid-template-columns: 1fr auto;
    row-gap: 24px;
    column-gap: 16px;
    justify-items: start;

    margin-block-end: 32px;

    > :first-child {
        grid-row: 1;
        grid-column: 2;
    }

    > :not(:first-child) {
        grid-column-start: 1;
    }

    > :nth-child(n + 3) {
        grid-column: 1 / -1;
    }
`

export const ClampedParagraph = styled.p`
    ${paragraphCss}

    margin-block-end: 8px;

    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
`