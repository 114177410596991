import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useSalaries } from 'contexts/salaries'
import { size } from 'utilities/object'
import { without } from 'utilities/array'
import { addDays, subDays } from 'date-fns'
import { isofy } from 'utilities/date-time'
import { prioritizedCurrencies, currencyToOption } from 'utilities/currency'
import { getFractionDigits } from 'pages/people/pages/profile/panes/about/salary/utilities'
import { unpackObjectField } from 'utilities/person'
import { getCurrencyFromCountryCode } from 'utilities/payment'
import { Fields, Columns } from './s'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import RadiobuttonsField from 'components/form/field/radiobuttons'
import { FlexChildGrow, FlexChildShrink } from 'components/flex'
import StringField from 'components/form/field/string'
import SelectField from 'components/form/field/select'
import TextField from 'components/form/field/text'
import TimeField from 'components/form/field/time'
import { Helper } from 'components/form/field/s'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'
import Conflicts from './conflicts'
import { periodUnitToPeriodShortMap } from '../'
import currencies from 'static/currencies.json'

const EditSalary = ({ mode = 'add', id, dismiss, onDone, salt, ...record }) => {
    const {
        formatMessage,
        formatNumber
    } = useIntl()

    const { person } = usePerson()

    const { bankAccount } = person

    const getBankAccountField = field => unpackObjectField(bankAccount ?? {})?.[field] ?? null

    const {
        salaries = [],
        addSalary,
        updateSalary,

        resetError
    } = useSalaries()

    const adding = mode === 'add'

    const salary = !adding ?
        (salaries?.find(({ id: salaryId }) => salaryId === id) ?? {}) :
        {}

    const {
        fromDate,
        toDate,
        periodUnit,
        amount,
        currency,
        note
    } = {
        ...salary,
        ...record
    } ?? {}

    const [loading, setLoading] = useState(false)

    useEffect(() => () => resetError(), [])

    const update = async salary => {
        setLoading(true)

        const addOrUpdateSalary = adding ?
            addSalary :
            updateSalary

        const { ok } = await addOrUpdateSalary(salary, id)

        setLoading(false)

        !!ok && onDone?.(salary)
    }

    const currentSalary = person.salary?.value
    const {
        periodUnit: currentPeriodUnit,
        amount: currentAmount,
        currency: currentCurrency,
        toDate: currentToDate
    } = currentSalary ?? {}

    const heading = formatMessage({
        id: adding ?
            'person_action_salary_add' :
            'person_action_salary_update',
        defaultMessage: adding ?
            'Add salary' :
            'Update salary'
    })

    return (
        <>
            <ModalHeader heading={heading} />
            <Form
                layout="vertical"
                onSubmit={update}>
                {({ values, touched, errors, trigger }) => {
                    const showDefaultHelper = (!!adding && !!currentAmount && !fromDate && !toDate && !['amount'].some(field => touched?.includes(field)))
                    const amountConverted = !['monthly', 'yearly'].includes(values.periodUnit) ?
                        values.amount :
                        values.periodUnit === 'monthly' ?
                            values.amount * 12 :
                            values.amount / 12

                    return (
                        <>
                            <Fields>
                                <RadiobuttonsField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'salary_label_frequency',
                                        defaultMessage: 'Interval'
                                    })}
                                    name="periodUnit"
                                    field={{
                                        value: adding ?
                                            currentPeriodUnit :
                                            periodUnit,
                                        include: 'always',
                                        required: true
                                    }}
                                    options={[
                                        {
                                            value: 'yearly',
                                            name: formatMessage({
                                                id: 'frequency_yearly',
                                                defaultMessage: 'Yearly'
                                            })
                                        },
                                        {
                                            value: 'monthly',
                                            name: formatMessage({
                                                id: 'frequency_monthly',
                                                defaultMessage: 'Monthly'
                                            })
                                        },
                                        {
                                            value: 'weekly',
                                            name: formatMessage({
                                                id: 'frequency_weekly',
                                                defaultMessage: 'Weekly'
                                            })
                                        },
                                        {
                                            value: 'hourly',
                                            name: formatMessage({
                                                id: 'frequency_hourly',
                                                defaultMessage: 'Hourly'
                                            })
                                        }
                                    ]}
                                    framed={true} />
                                <Columns>
                                    <FlexChildGrow>
                                        <StringField
                                            salt={salt}
                                            label={formatMessage({
                                                id: values.periodUnit ?
                                                    `salary_label_amount_${values.periodUnit}` :
                                                    'salary_label_amount',
                                                defaultMessage: 'Salary'
                                            })}
                                            name="amount"
                                            field={{
                                                value: (adding && !fromDate && !toDate) ?
                                                    currentAmount :
                                                    amount,
                                                include: (adding && !fromDate && !toDate) ?
                                                    'always' :
                                                    'touched',
                                                required: true
                                            }}
                                            controlProps={{
                                                type: 'number',
                                                inputMode: 'decimal',
                                                step: .01,
                                                autoFocus: adding
                                            }} />
                                    </FlexChildGrow>
                                    <FlexChildShrink>
                                        <SelectField
                                            salt={salt}
                                            label={false}
                                            name="currency"
                                            field={{
                                                value: adding ?
                                                    (currentCurrency ?? (getBankAccountField('country') ? getCurrencyFromCountryCode(getBankAccountField('country')) : null)) :
                                                    currency,
                                                required: true,
                                                include: adding ?
                                                    'always' :
                                                    'touched',
                                                allowEmptyOption: false
                                            }}
                                            options={[
                                                {
                                                    value: '',
                                                    text: formatMessage(({
                                                        id: 'noun_currency',
                                                        defaultMessage: 'Currency'
                                                    })),
                                                    key: 'placeholder',
                                                    disabled: true
                                                },
                                                ...prioritizedCurrencies.map(currencyToOption),
                                                {
                                                    value: '',
                                                    text: '',
                                                    key: 'separator',
                                                    disabled: true
                                                },
                                                ...without(currencies, prioritizedCurrencies).map(currencyToOption)
                                            ]} />
                                    </FlexChildShrink>
                                </Columns>
                                {showDefaultHelper && (
                                    <Helper>
                                        <FormattedMessage
                                            id="salary_helper_default_amount"
                                            defaultMessage="The current salary from the person’s profile is used as a default value." />
                                    </Helper>
                                )}
                                {(!showDefaultHelper && ['monthly', 'yearly'].includes(values.periodUnit) && values.amount) && (
                                    <Helper>
                                        <FormattedMessage
                                            id="salary_value_frequency"
                                            defaultMessage="{salary} per {periodUnit}"
                                            values={{
                                                salary: formatNumber(amountConverted, {
                                                    ...values.currency ? {
                                                        style: 'currency',
                                                        currency: values.currency,
                                                        currencyDisplay: currencies[values.currency] || 'code',
                                                    } : null,
                                                    minimumFractionDigits: getFractionDigits(amountConverted),
                                                    maximumFractionDigits: getFractionDigits(amountConverted)
                                                }),
                                                periodUnit: formatMessage(periodUnitToPeriodShortMap[values.periodUnit === 'monthly' ? 'yearly' : 'monthly'])
                                            }} />
                                    </Helper>
                                )}
                                <TextField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'salary_label_notes',
                                        defaultMessage: 'Notes'
                                    })}
                                    name="note"
                                    field={{ value: note ?? null }}
                                    controlProps={{
                                        placeholder: formatMessage({
                                            id: 'salary_placeholder_notes',
                                            defaultMessage: 'Additional information…'
                                        })
                                    }} />
                                <Helper>
                                    <FormattedMessage
                                        id="salary_message_notes"
                                        defaultMessage="Notes are visible to anyone who can see the salary field, including this person." />
                                </Helper>
                                <TimeField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'noun_effective_date',
                                        defaultMessage: 'Effective date'
                                    })}
                                    name="fromDate"
                                    field={{
                                        value: (adding && !fromDate) ?
                                            currentToDate ?
                                                addDays(isofy(currentToDate), 1) :
                                                new Date() :
                                            fromDate,
                                        required: true,
                                        include: 'always'
                                    }}
                                    picker={values.toDate && {
                                        before: addDays(isofy(values.toDate), 1)
                                    }}
                                    onChange={resetError} />
                                <Helper>
                                    <FormattedMessage
                                        id="salary_helper_effective_date"
                                        defaultMessage="The date the salary is effective from." />
                                </Helper>
                                <TimeField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'noun_end_date',
                                        defaultMessage: 'End date'
                                    })}
                                    name="toDate"
                                    field={{
                                        value: toDate,
                                        include: adding ?
                                            'always' :
                                            'touched'
                                    }}
                                    picker={values.fromDate && {
                                        after: subDays(isofy(values.fromDate), 1)
                                    }}
                                    onChange={resetError} />
                                <Helper>
                                    <FormattedMessage
                                        id="salary_helper_end_date"
                                        defaultMessage="The date the salary is effective to. Set an end date if this salary is only valid for a limited period of time." />
                                </Helper>
                                <Conflicts
                                    adding={adding}
                                    id={id}
                                    currentSalary={currentSalary}
                                    values={values} />
                            </Fields>
                            <Actions>
                                <Plain onClick={dismiss}>
                                    <FormattedMessage
                                        id="action_cancel"
                                        defaultMessage="Cancel" />
                                </Plain>
                                <ButtonSubmit
                                    className={`constructive${loading ? ' loading' : ''}`}
                                    disabled={!touched?.length || !!size(errors) || loading}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id={adding ?
                                            'action_add' :
                                            'action_update'
                                        }
                                        defaultMessage={adding ?
                                            'Add' :
                                            'Update'
                                        } />
                                </ButtonSubmit>
                            </Actions>
                        </>
                    )
                }}
            </Form>
        </>
    )
}

export default EditSalary
