import { useGroup } from 'contexts/group'
import { usePeople } from 'contexts/people'
import { useMe } from 'contexts/me'
import { usePerson } from 'contexts/person'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { useGetErrorOrWarning } from 'pages/competence/utilities'
import { size, omit } from 'utilities/object'

export const useActiveUsers = () => {
    const groupContext = useGroup()
    const peopleContext = usePeople()
    const meContext = useMe()
    const personContext = usePerson()

    const { fetching: fetchingGroup = false } = groupContext ?? {}

    const {
        people = [],
        fetching: fetchingPeople = false
    } = peopleContext ?? {}

    const { me = {} } = meContext ?? {}

    const { person = {} } = personContext ?? {}

    let activeUsers

    if(!!size(person)) {
        activeUsers = [person]
    } else if(!!people.length) {
        activeUsers = people
    } else {
        activeUsers = [me]
    }

    const fetching = fetchingGroup || fetchingPeople

    return [fetching, activeUsers]
}

export const useGapData = () => {
    const categoryFormatter = useCategoryFormatter()
    const getErrorOrWarning = useGetErrorOrWarning()

    return (records = [], configuration = {}, selectedPeople = [], individualGraphs = []) => {
        let data = (!!size(configuration) && !!selectedPeople?.length) ?
            records
                .reduce((accumulator, { type }) => {
                    const existingType = accumulator.find(record => type.id === record.type.id)

                    if(!existingType) {
                        accumulator.push({
                            subject: categoryFormatter(type),
                            type,
                            value: (
                                ['skill', 'language'].includes(type.category) ?
                                    // Average level
                                    records
                                        .filter(record => record.type.id === type.id && !size(omit(getErrorOrWarning(record), 'expiring')))
                                        .reduce((accumulator, { level }) => accumulator + level, 0) /
                                        (
                                            records
                                                .filter(record => record.type.id === type.id)
                                                .map(({ concerns }) => concerns)
                                                .filter((concern, index, array) => array.indexOf(concern) === index)
                                                .length
                                        ) :
                                    // Number of records
                                    records.filter(record => record.type.id === type.id && !size(omit(getErrorOrWarning(record), 'expiring'))).length
                            ) ?? 0,
                            target: configuration.types?.find(({ id }) => id === type.id)?.target,
                            peopleWithSkill: records
                                .filter(record => record.type.id === type.id)
                                .map(record => ({
                                    ...record,
                                    value: !isNaN(record?.level) ? record.level : 5
                                })),
                            fullMark: 5
                        })
                    }

                    return accumulator
                }, [])
                .map(record => {
                    if(['skill', 'language'].includes(record.type.category)) {
                        const peopleWithSkill = records.filter(({ type }) => type.id === record.type.id).length

                        const comparisonPeople = individualGraphs?.length || selectedPeople?.length

                        if(peopleWithSkill < comparisonPeople) {
                            record.value = record.value * (peopleWithSkill / comparisonPeople)
                        }
                    }

                    return record
                }) :
            []

        if(!!data?.length && data.length < configuration.types?.length) {
            const missingTypes = configuration.types?.filter(({ id }) => !data.find(record => record.type.id === id))

            missingTypes?.forEach(type => {
                data.push({
                    subject: categoryFormatter(type),
                    type,
                    value: 0,
                    target: configuration.types?.find(({ id }) => id === type.id)?.target,
                    peopleWithSkill: [],
                    fullMark: 5
                })
            })
        }

        return data
            .filter(type => !!type?.subject)
            .sort((a, b) => a.subject.localeCompare(b.subject))
    }
}

const getFilterBySelectionType = (selectionType, people, location, team) => {
    const locationId = location?.id ?? location
    const teamId = team?.id ?? team

    switch(selectionType) {
        case 'people':
            return { concerns: people.map(({ id }) => id) }
        case 'location':
            return { locations: [locationId] }
        case 'team':
            return { teams: [teamId] }
        default:
            return {}
    }
}

export const updateRecordsFilter = configuration => {
    const {
        types = [],
        users = {}
    } = configuration ?? {}

    const {
        people = [],
        location = {},
        team = {},
        selectionType
    } = users

    const filter = {
        types: types.map(({ id }) => id),
        ...getFilterBySelectionType(selectionType, people, location, team)
    }

    return filter
}