import styled from 'styled-components'
import { belowLaptop } from 'utilities/styled'
import { ParagraphSmall } from 'components/typography/paragraph'
import { Grid } from 'components/grid'

export const Header = styled(Grid)`
    grid-auto-flow: column;
    grid-template-columns: 24px max-content;
    align-items: center;
    gap: 32px;

    ${belowLaptop`
        gap: 24px;
    `}
`

export const Emoji = styled.div`
    font-size: 18px;
    line-height: 24px;
    text-align: center;
`

export const Heading = styled.h2`
    font-size: 16px;
    font-weight: 500;
`

export const SetupParagraph = styled(ParagraphSmall)`
    margin-inline-start: 56px;

    ${belowLaptop`
        margin-inline-start: 48px;
    `}
`