import styled, { css } from 'styled-components'
import { belowLaptop } from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid, GridColumnHeader as GridColumnHeaderBase } from 'components/grid'

export const topicGridCss = css`
    ${({ theme: { editHandbookOrder: { actions } } }) => css`
        grid-template-columns: ${compact([
            '4fr',
            '1fr',
            '1.5fr',
            actions && '48px'
        ]).join(' ')};
        column-gap: 8px;

        ${belowLaptop`
            grid-template-columns: ${compact([
                '4fr',
                '1fr',
                actions && '48px'
            ]).join(' ')};
        `}
    `}
`

export const Wrap = styled.div`
    width: 100%;
    padding-inline: 56px 24px;

    ${belowLaptop`
        padding-inline-start: 0;
    `}
`

export const Header = styled(Grid)`
    ${topicGridCss}

    margin-bottom: 8px;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${belowLaptop`
        display: none;
    `}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    padding-block: 16px;
`

export const Sortables = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;

    min-height: 72px;

    transition: height 0.2s ease-in-out;
    transform-origin: center center;

    ${belowLaptop`
        margin-inline-start: 0;
    `}

    /* &:empty {
        margin-inline-start: -40px;
        min-height: 60px;
        border-radius: 8px;
        border: 2px dashed var(--huma-color-border-default);
        background-color: var(--huma-color-surface-default);
    } */

    & > [data-rbd-placeholder-context-id] {
        border-radius: 8px;
        border: 2px dashed var(--huma-color-border-default);
        background-color: var(--huma-color-surface-default);
    }
`