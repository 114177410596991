import { compact } from 'utilities/array'
import { setGlobal } from 'utilities/global'

const getCookieByName = name => {
    const [cookie] = document.cookie
        .split('; ')
        .map(pair => pair.split(/=/))
        .filter(([key]) => key === name)

    return cookie || []
}

export const get = (name, fallback = null) => {
    if(!name) {
        return fallback
    }

    let [, value] = getCookieByName(name)

    if(value) {
        try {
            value = JSON.parse(value)
        } catch(err) {} // eslint-disable-line no-empty

        return value
    }

    return fallback
}

export const set = (name, value, expiry = '') => {
    if(!name || !value) {
        return
    }

    document.cookie = compact([
        [name, JSON.stringify(value)],
        expiry && ['expires', new Date(expiry).toUTCString()],
    ])
        .map(pair => pair.join('='))
        .join('; ')
}

export const remove = (name, includeLocation = false) => {
    if(!name) {
        return
    }

    if(!getCookieByName(name).length) {
        return
    }

    document.cookie = compact([
        [name, ''],
        ['expires', new Date(0).toUTCString()],
        includeLocation && ['domain', `.${global.location.hostname.split('.').slice(-2).join('.')}`],
        includeLocation && ['path', '/']
    ])
        .map(pair => pair.join('='))
        .join('; ')
}

setGlobal('cookie', {
    get,
    set,
    remove
})