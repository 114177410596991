import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import CompetenceRecordsProvider, { useCompetenceRecords } from 'contexts/competence-records'
import { useMe } from 'contexts/me'
import { useStorage } from 'hooks/storage'
import { useGapData, updateRecordsFilter } from 'pages/competence/utilities/gap'
import { size } from 'utilities/object'
import {
    DataWrapper, Loader,
    Tutorial,
    ResetWrapper
} from './s'
import GapConfiguration from 'pages/competence/panes/gap/configuration'
import Chart from 'pages/competence/panes/gap/chart'
import DataList from 'pages/competence/components/gap/data-list'
import { Plain } from 'components/button'
import Prompt from 'prompts/standard'

const MyGapContent = ({ salt }) => {
    const { formatMessage } = useIntl()

    const {
        records = [],
        fetching,
        setRecordsFilter
    } = useCompetenceRecords()

    const { me } = useMe()

    const people = [me]

    const defaultValue = {
        types: [],
        users: {
            people,
            selectionType: 'people',
            locked: true
        }
    }

    const [memoryConfiguration, setMemoryConfiguration] = useStorage({
        key: salt,
        defaultValue,
        type: 'session',
        merge: true
    })

    const [fetchedRecords, setFetchedRecords] = useState([])
    const [configuration, setConfiguration] = useState(memoryConfiguration)
    const [prompting, setPrompting] = useState(false)

    useEffect(() => {
        setFetchedRecords(records)
    }, [records])

    useEffect(() => {
        const {
            types = [],
            users = {}
        } = configuration ?? {}

        if(!!types?.length && users) {
            const filter = updateRecordsFilter(configuration)

            setRecordsFilter(filter)
        }
    }, [configuration])

    const reset = () => {
        setConfiguration(defaultValue)
        setMemoryConfiguration(defaultValue)
        setFetchedRecords([])
    }

    const getData = useGapData()
    const gapData = getData(fetchedRecords, configuration, people, people)

    return (
        <>
            <GapConfiguration
                configuration={configuration}
                setConfiguration={setConfiguration}
                setMemoryConfiguration={setMemoryConfiguration} />
            <DataWrapper {...(!!fetching || !!gapData?.length ? { className: 'active' } : null)}>
                <Loader active={fetching} />
                {!!gapData.length && (
                    <>
                        <Chart
                            data={gapData}
                            configuration={configuration}
                            people={people}
                            totalPeople={1}
                            individualGraphs={people} />
                        <DataList
                            data={gapData}
                            salt={salt} />
                    </>
                )}
            </DataWrapper>
            {(!gapData.length && !fetching) && (
                <Tutorial which="competenceGap" />
            )}
            {(!!size(configuration) && !!gapData?.length && !fetching) && (
                <>
                    <ResetWrapper>
                        <Plain
                            className="destructive"
                            onClick={() => setPrompting(true)}>
                            <FormattedMessage
                                id="competence_gap_action_reset"
                                defaultMessage="Reset the gap analysis" />
                        </Plain>
                    </ResetWrapper>
                    <Prompt
                        show={!!prompting}
                        salt={salt}
                        question={formatMessage({
                            id: 'competence_gap_reset_prompt_question',
                            defaultMessage: 'Are you sure you want to reset the gap analysis?'
                        })}
                        cancel={{ callback: () => setPrompting(false) }}
                        confirm={{
                            text: formatMessage({
                                id: 'action_reset',
                                defaultMessage: 'Reset'
                            }),
                            effect: 'destructive',
                            callback: reset
                        }} />
                </>
            )}
        </>
    )
}

export default props => (
    <CompetenceRecordsProvider
        fetchExhaustively={true}
        fetchOnMount={false}>
        <MyGapContent {...props} />
    </CompetenceRecordsProvider>
)
