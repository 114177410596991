import React, { memo, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useHandbook } from 'contexts/handbook'
import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import { useAccess } from 'contexts/access'
import { useAppSignal } from 'hooks/app-signal'
import debounce from 'lodash/debounce'
import queryString from 'query-string'
import { size } from 'utilities/object'
import { compact, pruneBy } from 'utilities/array'
import { cls } from 'utilities/dom'
import paths from 'app/paths'
import { Wrapper, BackButton, Filter } from './s'
import { ArrowLeft } from 'styled-icons/feather'
import Status, { useHandbookStatusTextFormatter } from 'pages/handbook/components/status'

const HandbookSearchFilter = memo(({ salt }) => {
    const { formatMessage } = useIntl()
    const { languages } = useI18n()

    const {
        handbook,
        search,

        setHandbookSearchFilter,
        resetHandbookSearchFilter
    } = useHandbook()

    const navigate = useNavigate()
    const location = useLocation()
    const atSearchPage = !!useMatch(paths.handbook.search)
    const atCategoryPage = !!useMatch(paths.handbook.category)

    const { check } = useAccess()
    const manageAccess = check('handbook:manage')

    const [updatedAt, setUpdatedAtImmediately] = useState(Date.now())

    const setUpdatedAt = useCallback(debounce(
        setUpdatedAtImmediately, 1000,
        { leading: true, trailing: false }
    ), [])

    // Reset the state when leaving the search page
    useEffect(() => {
        if(!atSearchPage) {
            resetHandbookSearchFilter()
            setUpdatedAt(Date.now())
        }
    }, [atSearchPage])

    const getLocationSearch = useCallback(() => {
        const { q: value } = { ...(queryString.parse(location.search) ?? null) }
        return [value, validate(value)]
    }, [location.search])

    const clearLocationState = useCallback(() => {
        if(!!size(location.state ?? {})) {
            navigate({
                ...location,
                state: undefined,
                replace: true
            })
        }
    }, [location.state])

    const updateLocationSearch = useCallback(({
        value = '',
        pathOnly = false,
        onlyAtSearchPage = false
    }) => {
        if(onlyAtSearchPage && !atSearchPage) {
            return
        }

        const replace = !!atSearchPage

        const query = {
            ...((validate(value) && !pathOnly) ? { q: value } : null)
        }

        navigate(
            queryString.stringifyUrl({
                url: paths.handbook.search,
                query
            }),
            {
                state: { reflective: true },
                replace
            }
        )
    }, [atSearchPage])

    useAppSignal({
        namespace: 'handbook:search:updated',
        action: (filter = {}) => updateLocationSearch({
            value: filter.search ?? ''
        })
    })

    useAppSignal({
        namespace: 'handbook:search:reset',
        action: () => updateLocationSearch({
            value: '',
            onlyAtSearchPage: true
        })
    })

    const appendFilterSearch = useCallback(value => {
        setHandbookSearchFilter({ search: value }, { merge: true })
    }, [])

    // Kick off a search when landing on the search page with a query
    useEffect(() => {
        if(!atSearchPage) {
            return
        }

        const [value, valid] = getLocationSearch()
        const tag = location.state?.origin === 'tag'
        const reflective = !!location.state?.reflective

        // Prevent topic tag clicks from triggering a search here
        if(tag || reflective || !valid) {
            return
        }

        appendFilterSearch(value)
        setUpdatedAt(Date.now())
    }, [atSearchPage, location.search, location.state])

    // Kick off a search when clicking a topic’s tag in the search results
    useEffect(() => {
        if(!atSearchPage) {
            return
        }

        const [value, valid] = getLocationSearch()
        const tag = location.state?.origin === 'tag'
        const reflective = !!location.state?.reflective

        // Vanilla location events don’t have a silent state
        if(!tag || reflective || !valid || value === search.filter.search) {
            return
        }

        clearLocationState()
        appendFilterSearch(value)
        setUpdatedAt(Date.now())
    }, [atSearchPage, location.search, location.state, search.filter])

    const statusValueLabelFormatter = useHandbookStatusTextFormatter()

    if(!handbook?.categories?.length) {
        return null
    }

    const languagesUsed = handbook?.categories?.reduce((accumulator, category) => {
        category.topics.forEach(topic => {
            if(!accumulator.includes(topic.language)) {
                accumulator.push(topic.language)
            }
        })

        return accumulator
    }, [])

    const searchClassName = cls([
        'compact',

        // 'loading|fetching' is claimed by StringField built-ins
        search.fetching && 'searching'
    ])

    const basicFields = [
        {
            type: 'search',
            className: searchClassName,
            label: false,
            name: 'search',
            field: {
                value: search.filter.search,
                unsettable: !search.fetching
            },
            controlProps: {
                placeholder: formatMessage({
                    id: 'handbook_action_search',
                    defaultMessage: 'Search the handbook'
                })
            },
            onUnset: () => {
                appendFilterSearch(null)
                setTimeout(() => setUpdatedAt(Date.now()), 500)
            },
            enabled: !(search.fetching && search.loading),
            loading: search.fetching
        }
    ]

    const topics = handbook?.categories?.flatMap(({ topics }) => topics) ?? []
    const hasDifferentTopicStatuses = pruneBy(topics, 'status').length > 1

    const getCompoundStatus = status => compact([handbook?.status, status]).join('_')

    const advancedFields = compact([
        (manageAccess && hasDifferentTopicStatuses) && {
            type: 'radiobuttons',
            name: 'status',
            label: formatMessage({
                id: 'noun_status',
                defaultMessage: 'Status'
            }),
            getValueLabel: status => {
                if(!status) {
                    return null
                }

                return statusValueLabelFormatter(getCompoundStatus(status))
            },
            field: {
                defaultValue: null,
                options: [
                    {
                        value: 'published',
                        name: (
                            <Status
                                status={getCompoundStatus('published')}
                                tooltip={false} />
                        )
                    },
                    {
                        value: 'draft',
                        name: (
                            <Status
                                status="draft"
                                tooltip={false} />
                        )
                    }
                ]
            }
        },
        ((languagesUsed?.length ?? 0) > 1) && {
            type: 'radiobuttons',
            name: 'language',
            label: formatMessage({
                id: 'noun_language',
                defaultMessage: 'Language'
            }),
            getValueLabel: language => languages[language],
            field: {
                defaultValue: null,
                options: languagesUsed?.map(language => ({
                    value: language,
                    name: languages[language]
                }))
            }
        }
    ])

    const navigateBack = () => {
        navigate(paths.handbook.base)
        global.scrollTo(0, 0)
    }

    return (
        <Wrapper {...((atSearchPage || atCategoryPage) ? { className: 'show-back' } : null)}>
            <BackButton
                onClick={navigateBack}
                icon={ArrowLeft} />
            <Filter
                filter={search.filter}
                setFilter={filter => setHandbookSearchFilter(filter)}
                memoryFilterOptions={{ enabled: false }}
                basicFields={basicFields}
                advancedFields={advancedFields}
                items={search.results}
                showerride={true}
                fetching={search.fetching}
                hasFetched={search.hasFetched}
                onReset={navigateBack}
                salt={salt}
                key={`${salt}:${updatedAt}`} />
        </Wrapper>
    )
})

const validate = value => {
    const length = value?.length ?? 0
    return length >= 2
}

export default HandbookSearchFilter