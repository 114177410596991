import styled from 'styled-components'
import { H2 } from 'components/typography/heading'

export const Heading = styled(H2)`
    margin-bottom: 24px;
`

export const Fields = styled.div`
    margin-top: 24px;
`
