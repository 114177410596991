import React from 'react'
import Meta from 'components/meta'

const ViewTaskMeta = ({ task }) => {
    const {
        parent: group,
        createdBy,
        createdAt,
        completedBy,
        completedAt
    } = task ?? {}

    if(!task) {
        return null
    }

    return (
        <>
            {(!group && !!createdBy) && (
                <Meta
                    type="created"
                    by={createdBy}
                    at={createdAt}
                    time="fixed" />
            )}
            {!!completedBy && (
                <Meta
                    type="completed"
                    by={completedBy}
                    at={completedAt}
                    time="fixed" />
            )}
        </>
    )
}

export default ViewTaskMeta
