import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import { get } from 'api'
import PubSub from 'pubsub-js'
import { useMe } from 'contexts/me'
import {
    getPeopleProfileUrl,
    getPeopleTeamUrl, getPeopleLocationUrl
} from 'utilities/url'
import { useDebounce } from 'hooks/debounce'
import { useStorage } from 'hooks/storage'
import { getUserAgent } from 'utilities/user-agent'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import { move, compact } from 'utilities/array'
import { size, has, omit, map } from 'utilities/object'
import { jarowinklerDistance } from 'utilities/string-metric'
import { isEntity, reduceEntity } from '../utilities'
import ActiveIcon from '../components/active-icon'
import {
    Container,
    SearchWrapper, Search,
    Shortcuts, ShortcutGroup, ShortcutLabel,
    RecentHeader, RecentHeading, Results, Empty
} from './s'
import Shortcut from 'components/tiptap/help/shortcut'
import { Simple, Plain } from 'components/button'
import Form from 'components/form/controller'
import Divider from 'components/divider'
import { Command, Entity } from './result'
import { ArrowLeft } from 'styled-icons/feather'
import { useAddEntityCommands, useFixedCommands, useProfileCommands, useGroupCommands } from './commands'

const CommandBarModal = ({ show, setShow, salt }) => {
    const { formatMessage } = useIntl()

    const input = useRef()

    const navigate = useNavigate()
    const location = useLocation()

    const { isMobile } = getUserAgent()

    const { isItMyOwnId } = useMe()

    const [recentActions, setRecentActions] = useStorage({
        key: `${salt}:actions`,
        defaultValue: []
    })

    const [hasFetched, setHasFetched] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(0)
    const [search, setSearch, { signal }] = useDebounce('')
    const [searchValid, setSearchValid] = useState(false)
    const [results, setResults] = useState([])
    const [matchedCommands, setMatchedCommands] = useState([])
    const [actionCommandKeyword, setActionCommandKeyword] = useState(null)
    const [combined, setCombined] = useState(recentActions ?? [])
    const [previousResults, setPreviousResults] = useState({})

    const addEntityCommands = useAddEntityCommands()
    const entityCommands = addEntityCommands(search)
    const getFixedCommands = useFixedCommands()
    const profileCommands = useProfileCommands()
    const groupCommands = useGroupCommands()

    const { query, potentialMatch } = parseInput(search)

    useEffect(() => {
        PubSub.subscribe('command-bar.focus', () => {
            setShow(true)
        })

        return () => PubSub.unsubscribe('command-bar.focus')
    }, [])

    useEffect(() => {
        if(input?.current && !!show) {
            // setTimeout(() => input.current.focus(), 50)
            input.current.focus()
        }
    }, [show, input])

    useEffect(() => {
        setSearchValid(search.length >= 2)
    }, [search])

    const fetchResults = async search => {
        setFetching(true)

        const { ok, response } = await get({
            path: '/units',
            params: {
                search,
                includeDeactivated: true,
                types: ['user', 'team', 'location']
            }
        })

        setFetching(false)
        setHasFetched(true)

        let results = []

        if(ok && !!response?.items?.length) {
            results = response.items.map(({ type, ...item }) => ({
                type,
                [type]: item
            }))
        }

        return results
    }

    const handleSearch = async () => {
        if(has(previousResults, search)) {
            setHasFetched(true)
            setResults(previousResults[search].results)
        } else {
            if(!potentialMatch) {
                setActionCommandKeyword(null)
                const results = await fetchResults(search)

                setResults(results)
            } else {
                const fakeProfileEntity = { id: 'fake' }
                const fakeGroupEntity = {
                    type: 'team',
                    team: { name: 'fake' }
                }

                const mappedProfileCommands = profileCommands
                    .map(user => user(fakeProfileEntity))
                    .filter(({ requirements = [] }) => requirements?.every(Boolean))

                const mappedGroupCommands = groupCommands
                    .map(group => group(fakeGroupEntity))
                    .filter(({ requirements = [] }) => requirements?.every(Boolean))

                const allCommands = [...mappedProfileCommands, ...mappedGroupCommands]

                const lowerCasePotentialMatch = potentialMatch.toLowerCase()

                const scoredCommands = allCommands.map(({ keywords = [] }, index) => {
                    let score = -Infinity

                    keywords.forEach(keyword => {
                        const lowerCaseKeyword = keyword.toLowerCase()
                        const similarity = jarowinklerDistance(lowerCaseKeyword, lowerCasePotentialMatch)

                        if(similarity > score) {
                            score = similarity
                        }
                    })

                    return { score, index }
                })

                const sortedCommands = scoredCommands.sort(({ score: a }, { score: b }) => b - a)
                const [bestMatch] = sortedCommands

                if(bestMatch && bestMatch.score > 0.8) {
                    // Fetch both with search and query
                    const [
                        queryResults,
                        searchResults
                    ] = await Promise.all([
                        fetchResults(query),
                        fetchResults(search)
                    ])

                    // If there are no matching users with the search query, but there are with the query
                    if(queryResults.length > 1 && !searchResults.length) {
                        setActionCommandKeyword(null)

                        setResults([])
                    } else {
                        const command = allCommands[bestMatch.index]

                        setActionCommandKeyword(command.keywords.find(keyword => keyword.toLowerCase().includes(potentialMatch.toLowerCase())))

                        setResults(queryResults)
                    }
                } else {
                    setActionCommandKeyword(null)

                    const results = await fetchResults(search)

                    setResults(results)
                }
            }
        }

        setLoading(false)
    }

    useEffect(() => {
        if(searchValid) {
            setLoading(true)
            handleSearch()
        } else {
            setResults([])
        }
    }, [signal])

    const searchFormatProps = { name: search }

    useEffect(() => {
        const enrichResults = () => {
            if(results.filter(({ type }) => type === 'user').length === 1) {
                const userIndex = results.findIndex(({ type }) => type === 'user')
                const user = results[userIndex]
                const isMe = isItMyOwnId(user.user.id)

                return compact([
                    ...results.slice(0, userIndex),
                    user,
                    ...(isMe ?
                        []
                        : profileCommands
                            .map(command => ({
                                ...command(user.user),
                                userId: user.user.id
                            }))
                            // If there's an action command keyword, only show the commands that match the keyword
                            .filter(({ keywords = [] }) => {
                                if(!actionCommandKeyword) {
                                    return true
                                }

                                return keywords.some(keyword => keyword.toLowerCase().includes(actionCommandKeyword.toLowerCase()))
                            })
                            .filter(({ requirements = [] }) => requirements?.every(Boolean))
                    ),
                    ...results.slice(userIndex + 1)
                ])
            } else if(results.filter(({ type }) => ['team', 'location'].includes(type)).length === 1) {
                const entity = results.find(({ type }) => ['team', 'location'].includes(type))
                const entityIndex = results.findIndex(({ type }) => ['team', 'location'].includes(type))

                return [
                    ...results.slice(0, entityIndex),
                    entity,
                    ...groupCommands
                        .map(command => ({
                            ...command(entity),
                            entityId: entity[entity.type].id
                        }))
                        // If there's an action command keyword, only show the commands that match the keyword
                        .filter(({ keywords = [] }) => {
                            if(!actionCommandKeyword) {
                                return true
                            }

                            return keywords.some(keyword => keyword.toLowerCase().includes(actionCommandKeyword.toLowerCase()))
                        })
                        .filter(({ requirements = [] }) => requirements?.every(Boolean)),
                    ...results.slice(entityIndex + 1)
                ]
            } else {
                return results
            }
        }

        const combined = searchValid ?
            [
                ...matchedCommands,
                ...enrichResults(),
                ...(!!hasFetched && searchValid && !results?.length ?
                    map(entityCommands, (value, key) => ({ ...value, id: key })).filter(({ requirements = [] }) => requirements?.every(Boolean))
                    : []
                )
            ]
            : recentActions.map(({ actionId, subActionId, prompt, ...recentAction }) => {
                const action = {
                    id: actionId,
                    isRecent: true
                }

                if(!!actionId) {
                    const isAddEntityCommand = actionId.startsWith('add-entity:')
                    const isSubCommand = !!subActionId

                    if(isAddEntityCommand) {
                        const entityCommands = addEntityCommands(prompt)

                        return {
                            ...entityCommands[actionId],
                            ...action
                        }
                    }

                    if(isSubCommand) {
                        // Remove the subActionId from the actionId (e.g. 'action:profile:edit' -> 'action:profile')
                        actionId = actionId.split(':').slice(0, 2).join(':')
                    }

                    const command = getFixedCommands(search)[actionId]
                    const subLabel = command?.matches?.find(({ id }) => id === subActionId)

                    return {
                        ...command,
                        ...((isSubCommand && subLabel) ?
                            {
                                subLabel,
                                matching: ['subLabel'],
                                ...(command?.link ?
                                    {
                                        link: {
                                            ...command.link,
                                            state: {
                                                ...command.link.state,
                                                [subLabel.variant]: subLabel.id
                                            }
                                        }
                                    }
                                    : null
                                )
                            }
                            : null
                        ),
                        ...action
                    }
                }

                return {
                    ...recentAction,
                    ...action
                }
            })

        setCombined(combined)

        if(actionCommandKeyword) {
            // Set the active index to the first command that matches the keyword
            const activeIndex = combined.findIndex(({ keywords = [] }) => keywords.some(keyword => keyword.toLowerCase().includes(actionCommandKeyword.toLowerCase())))

            setActive(activeIndex)
        }

        let newPreviousResults = previousResults ?? {}

        if(searchValid && !has(previousResults, search) && !!hasFetched) {
            if(size(previousResults) >= 5) {
                // Just keep the 4 most recent searches
                newPreviousResults = Object.entries(previousResults)
                    .sort(([, { setAt: a }], [, { setAt: b }]) => a - b)
                    .slice(1)
                    .reduce((accumulator, [search, results]) => ({
                        ...accumulator,
                        [search]: results
                    }), {})
            }

            newPreviousResults[search] = {
                results,
                setAt: Date.now()
            }

            setPreviousResults(newPreviousResults)
        }
    }, [matchedCommands, results, hasFetched])

    const reset = useCallback(() => {
        setSearch('')
        setResults([])
        setMatchedCommands([])
        setActive(0)
    }, [])

    const removeEntityCommands = useCallback(() => {
        setCombined(combined => combined.filter(({ userId, entityId }) => !userId && !entityId))
    }, [])

    const cyclePrevious = () => {
        if(active === 0) {
            setActive(() => {
                if(combined.find(({ userId, entityId }) => !!userId || !!entityId) && combined.filter(action => isEntity(action)).length > 1 && isEntity(combined[active]) && !combined[combined.length - 1]?.userId && !combined[combined.length - 1]?.entityId) {
                    const commandsLength = !!combined[active]?.userId ? profileCommands.length : groupCommands.length

                    removeEntityCommands()

                    return combined.length - commandsLength - 1
                }

                return combined.length - 1
            })
        } else {
            setActive(active => {
                if(combined.find(({ userId, entityId }) => !!userId || !!entityId) && combined.filter(action => isEntity(action)).length > 1 && isEntity(combined[active])) {
                    removeEntityCommands()
                }

                return active - 1
            })
        }
    }

    const cycleNext = () => {
        if(active === combined.length - 1) {
            setActive(() => {
                if((combined[active]?.userId || combined[active]?.entityId) && combined.filter(action => isEntity(action)).length > 1) {
                    removeEntityCommands()
                }

                return 0
            })
        } else {
            setActive(active => {
                if(combined.find(({ userId, entityId }) => !!userId || !!entityId) && (combined[active]?.userId || combined[active]?.entityId) && (!combined[active + 1]?.userId && !combined[active + 1]?.entityId) && combined.filter(action => isEntity(action)).length > 1) {
                    const commandsLength = !!combined[active]?.userId ? profileCommands.length : groupCommands.length

                    removeEntityCommands()

                    return active - commandsLength + 1
                }

                return active + 1
            })
        }
    }

    const updateRecentActions = item => {
        if(!item) {
            return
        }

        const previousActions = recentActions
        const itemIndex = previousActions?.findIndex(({ actionId, ...action }) => {
            if(isEntity(action)) {
                const { type: itemType } = item

                return item[itemType]?.id === action[action.type].id
            }

            return actionId === item.id
        })

        if(itemIndex > -1) {
            setRecentActions(move(previousActions, itemIndex, 0))
        } else if((!!item.link || !!isEntity(item)) && !item.id?.startsWith('profile:') && !item.id?.startsWith('location:') && !item.id?.startsWith('team:')) {
            if(previousActions.length >= 5) {
                previousActions.pop()
            }

            if(isEntity(item)) {
                item = reduceEntity(item)
            } else {
                item = {
                    actionId: item.id,
                    prompt: search,
                    ...(!!item?.displayMatch ? { subActionId: item.subLabel?.id } : null)
                }
            }

            setRecentActions([
                item,
                ...previousActions ?? []
            ])
        }
    }

    const confirm = item => {
        const {
            onClick,
            link,
            external = false,
            ignoreRecent = false
        } = item

        if(!ignoreRecent) {
            updateRecentActions(item)
        }
        setShow(false)
        reset()
        onClick?.()

        const match = !!link?.to ?
            matchPath(link.to, location.pathname)
            : null

        if(link?.href) {
            global.open(link.href, !!external ? '_blank' : '_self')
        } else if(!!link) {
            navigate(link.to, {
                state: {
                    ...link.state,
                    explicit: true
                },
                replace: !!match
            })
        }
    }

    const entityConfirm = item => {
        const { type } = item
        const entity = item[type]

        updateRecentActions(item)
        setShow(false)
        reset()

        const navigateTo = {
            user: getPeopleProfileUrl,
            team: getPeopleTeamUrl,
            location: getPeopleLocationUrl
        }[type]

        navigateTo && navigate(navigateTo(entity))
    }

    const activeIsEntity = isEntity(combined[active])

    const onKeyDown = e => {
        let { key, shiftKey } = e
        key = key.toLowerCase()

        if(navigationKeys.includes(key)) {
            if((key === 'tab' && show) || key !== 'tab') {
                e.preventDefault()
            }
        }

        if(key === 'enter') {
            const item = combined[active]

            const {
                onClick,
                link,
                type
            } = item

            const entity = item[type]

            if(onClick || link) {
                confirm(item)
            } else if(entity) {
                entityConfirm(item)
            }
        }

        if(key === 'arrowup') {
            if(!show) {
                setShow(true)
                setActive(0)
            } else {
                cyclePrevious()
            }
        }

        if(key === 'arrowdown') {
            if(!show) {
                setShow(true)
                setActive(0)
            } else {
                cycleNext()
            }
        }

        if(key === 'tab' && !shiftKey && activeIsEntity && results?.filter(result => isEntity(result)).length > 1) {
            const entity = combined[active]

            if((isItMyOwnId(entity.user?.id) || (!!combined[active + 1]?.userId || !!combined[active + 1]?.entityId)) || entity.user?.status?.active === false) {
                return
            }

            let commands = []

            if(entity.type === 'user') {
                commands = profileCommands
                    .map(command => ({
                        ...command(entity.user),
                        userId: entity.user.id
                    }))
                    .filter(({ requirements = [] }) => requirements?.every(Boolean))
            } else {
                commands = groupCommands
                    .map(command => {
                        const { type } = entity

                        return {
                            ...command(entity),
                            entityId: entity[type].id
                        }
                    })
                    .filter(({ requirements = [] }) => requirements?.every(Boolean))
            }

            setCombined([
                ...combined.slice(0, active),
                entity,
                ...commands,
                ...combined.slice(active + 1)
            ])
        }

        if(key === 'escape') {
            setShow(false)
            reset()
        }

        if(key !== 'escape') {
            input.current?.focus()
        }
    }

    const getStringOrRegexMatcher = searchPhrase => matchPhrase => {
        if(!!matchPhrase?.text) {
            matchPhrase = matchPhrase.text
        }

        searchPhrase = searchPhrase.toLowerCase()

        if(typeof matchPhrase === 'string') {
            matchPhrase = matchPhrase.toLowerCase()
            return matchPhrase.includes(searchPhrase) || searchPhrase.split(' ').every(part => matchPhrase.includes(part))
        }

        if(matchPhrase instanceof RegExp) {
            return matchPhrase.test(searchPhrase)
        }

        return false
    }

    const matchCommands = search => {
        if(search.length < 2) {
            return void setMatchedCommands([])
        }

        const formatLabel = label => {
            if(!label?.id) {
                return label
            }

            return formatMessage(label, {
                ...searchFormatProps,
                ...(!!label?.values ? label.values : null)
            })
        }

        const checkForStringOrRegexMatch = getStringOrRegexMatcher(search)

        const fixed = map(getFixedCommands(search), (value, key) => ({
            ...value,
            id: key
        }))
            .filter(({ matches = [], label, ignoreLabelMatch }) => matches?.some(match => {
                label = formatLabel(label)

                return (
                    (!ignoreLabelMatch && label.toLowerCase().includes(search)) ||
                    checkForStringOrRegexMatch(match)
                )
            }))
            .filter(({ requirements = [] }) => requirements?.every(Boolean))
            .reduce((accumulator, command) => {
                let {
                    matches = [],
                    label,
                    displayMatch = false,
                    ignoreLabelMatch = false
                } = command

                label = formatLabel(label)
                const labelMatch = label.toLowerCase().includes(search.toLowerCase())

                let subLabels = matches.filter(checkForStringOrRegexMatch)
                const matchesMatch = matches.some(checkForStringOrRegexMatch)

                const matching = []

                if(labelMatch && !ignoreLabelMatch) {
                    matching.push('label')
                }

                if(!!subLabels.length && !!displayMatch) {
                    subLabels = subLabels.map(subLabel => ({
                        ...command,
                        id: `${command.id}:${subLabel.id}`,
                        subLabel,
                        matching: ['subLabel'],
                        ...(command?.link ?
                            {
                                link: {
                                    ...command.link,
                                    state: {
                                        ...command.link.state,
                                        [subLabel.variant]: subLabel.id
                                    }
                                }
                            }
                            : null
                        )
                    }))
                }

                if(matchesMatch) {
                    matching.push('matches')
                }

                return [
                    ...accumulator,
                    {
                        ...omit(command, 'displayMatch'),
                        matching
                    },
                    ...((!!subLabels?.length && !!displayMatch) ? subLabels : [])
                ]
            }, [])
            .sort(({ matching: aMatching }, { matching: bMatching }) => {
                const a = aMatching.includes('label') || aMatching.includes('subLabel') ? -1 : 0
                const b = bMatching.includes('label') || bMatching.includes('subLabel') ? -1 : 0

                return a - b
            })
            // Prioritize labels where the search is at the beginning
            .sort(({ label: aLabel }, { label: bLabel }) => {
                aLabel = formatLabel(aLabel)
                bLabel = formatLabel(bLabel)

                const a = aLabel.toLowerCase().startsWith(search.toLowerCase()) ? -1 : 0
                const b = bLabel.toLowerCase().startsWith(search.toLowerCase()) ? -1 : 0

                return a - b
            })

        setMatchedCommands(fixed)
    }

    const getActiveIcon = useCallback(() => {
        const activeItem = combined[active]

        return (
            <ActiveIcon
                activeItem={activeItem}
                searchValid={searchValid} />
        )
    }, [active, combined])

    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    <Veil animate={animate} />
                    <Modal
                        className="fixed"
                        onOutsideClick={() => {
                            setShow(false)
                            reset()
                        }}
                        overlayVirtualKeyboard={true}
                        dismiss={() => setShow(false)}>
                        <Container
                            animate={animate}
                            {...(fetching || loading) ? { className: 'loading' } : null}>
                            <Form>
                                <SearchWrapper>
                                    {!!isMobile && (
                                        <Simple
                                            onClick={() => setShow(false)}
                                            icon={ArrowLeft} />
                                    )}
                                    {!isMobile && getActiveIcon()}
                                    <Search
                                        value={search}
                                        placeholder={formatMessage({
                                            id: 'action_what_do_you_want',
                                            defaultMessage: 'What do you want to do?'
                                        })}
                                        onFocus={() => setActive(0)}
                                        onChange={e => {
                                            matchCommands(e.currentTarget.value)
                                            setSearch(e.currentTarget.value)
                                            setHasFetched(false)
                                        }}
                                        onKeyDown={onKeyDown}
                                        ref={input} />
                                </SearchWrapper>
                                {[
                                    !!results?.length,
                                    !!matchedCommands?.length,
                                    (!!hasFetched && searchValid && !results?.length),
                                    !!recentActions?.length
                                ].some(Boolean) && (
                                    <>
                                        <Divider $size={8} />
                                        {!isMobile && (
                                            <>
                                                <Shortcuts>
                                                    <ShortcutGroup>
                                                        <Shortcut
                                                            shortcut={['arrowup']}
                                                            salt="command-bar:shortcuts" />
                                                        <Shortcut
                                                            shortcut={['arrowdown']}
                                                            salt="command-bar:shortcuts" />
                                                        <ShortcutLabel>
                                                            <FormattedMessage
                                                                id="action_navigate"
                                                                defaultMessage="Navigate" />
                                                        </ShortcutLabel>
                                                    </ShortcutGroup>
                                                    {(activeIsEntity && results?.filter(result => isEntity(result)).length > 1 && !combined[active + 1]?.userId && !combined[active + 1]?.entityId && !isItMyOwnId(combined[active].user?.id) && combined[active].user?.status?.active !== false) && (
                                                        <ShortcutGroup>
                                                            <Shortcut
                                                                shortcut={['tab']}
                                                                salt="command-bar:shortcuts" />
                                                            <ShortcutLabel>
                                                                <FormattedMessage
                                                                    id="action_expand"
                                                                    defaultMessage="Expand" />
                                                            </ShortcutLabel>
                                                        </ShortcutGroup>
                                                    )}
                                                    <ShortcutGroup>
                                                        <Shortcut
                                                            shortcut={['enter']}
                                                            salt="command-bar:shortcuts" />
                                                        <ShortcutLabel>
                                                            <FormattedMessage
                                                                id="action_select"
                                                                defaultMessage="Select" />
                                                        </ShortcutLabel>
                                                    </ShortcutGroup>
                                                </Shortcuts>
                                                <Divider $size={8} />
                                            </>
                                        )}
                                        {!searchValid && !!recentActions?.length && (
                                            <RecentHeader>
                                                <RecentHeading>
                                                    <FormattedMessage
                                                        id="command_bar_heading_recent"
                                                        defaultMessage="Recent actions" />
                                                </RecentHeading>
                                                <Plain
                                                    onClick={() => setRecentActions([])}
                                                    className="constructive">
                                                    <FormattedMessage
                                                        id="action_clear"
                                                        defaultMessage="Clear" />
                                                </Plain>
                                            </RecentHeader>
                                        )}
                                        <Results>
                                            {combined?.map((item, index) => {
                                                const {
                                                    type,
                                                    userId,
                                                    isRecent = false
                                                } = item ?? {}

                                                const isActive = (active === index) && !isMobile

                                                if(!isEntity(item)) {
                                                    const isMatchingActionCommand = item.keywords?.some(keyword => keyword.toLowerCase().includes(actionCommandKeyword?.toLowerCase()))

                                                    return (
                                                        <Command
                                                            item={item}
                                                            search={search}
                                                            {...((actionCommandKeyword && isMatchingActionCommand) ? { searchWords: [query, potentialMatch] } : null)}
                                                            isActive={isActive}
                                                            isRecent={isRecent}
                                                            confirm={confirm}
                                                            index={index}
                                                            key={`command:${type ? `${item.type}:${item[type].id}` : item.id}${userId ? `:${userId}` : ''}`} />
                                                    )
                                                }

                                                return (
                                                    <Entity
                                                        item={item}
                                                        search={search}
                                                        isActive={isActive}
                                                        confirm={entityConfirm}
                                                        index={index}
                                                        key={`command:${type}:${item[type].id}`} />
                                                )
                                            })}
                                        </Results>
                                    </>
                                )}
                                {(!!hasFetched && searchValid && !combined?.length && !recentActions?.length) && (
                                    <Results>
                                        <Empty>
                                            <FormattedMessage
                                                id="command_bar_empty"
                                                defaultMessage="🤷 Sorry, no results were found." />
                                        </Empty>
                                    </Results>
                                )}
                            </Form>
                        </Container>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

const parseInput = input => {
    const parts = input.trim().split(' ')
    let lastPart = ''

    if(parts.length > 1) {
        lastPart = parts.pop()
    }

    return {
        query: parts.join(' '),
        potentialMatch: lastPart
    }
}

const navigationKeys = [
    'arrowup',
    'arrowdown',
    'enter',
    'tab',
    'escape'
]

export default CommandBarModal
