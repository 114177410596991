// https://twitter.com/PossumEveryHour

import { useIntl } from 'react-intl'

export const usePossum = () => {
    const { locale } = useIntl()

    return getFormatter(locale) ?? (name => name)
}

const sSound = /(s|x|z)/i
const last = name => name.slice(-1)

const getFormatter = locale => ({
    en: name => [
        name,
        '’',
        !last(name).match(sSound) ? 's' : ''
    ].join(''),

    nb: name => [
        name,
        last(name).match(sSound) ? '’' : 's'
    ].join(''),

    sv: name => [
        name,
        last(name).match(sSound) ? '' : 's'
    ].join(''),

    da: name => {
        const number = /([0-9])/
        const point = /(\.)/

        return [
            name,
            last(name).match(number) ?
                '’s' :
                last(name).match(point) ?
                    's' :
                    last(name).match(sSound) ?
                        '’' :
                        's'
        ].join('')
    },

    nl: name => {
        const vowel = /([aeiouy])/

        return [
            name,
            last(name).match(vowel) ?
                '’s' :
                last(name).match(sSound) ?
                    '’' :
                    's'
        ].join('')
    }

})[locale]