import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import ProfilesList from './list'
import { Plain } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import ActingProfile from 'pages/competence/modals/profile'
import {
    Plus,
    Edit as Define
} from 'styled-icons/feather'

const TargetTypesProfile = ({ setCustom, setTypes, onDone, salt }) => {
    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const [creating, setCreating] = useState(false)

    return (
        <>
            <ProfilesList
                setTypes={setTypes}
                onDone={onDone}
                salt={salt} />
            {!!manageAccess && (
                <Plain
                    className="constructive with-icon"
                    onClick={() => setCreating(true)}>
                    <Plus size={24} />
                    <FormattedMessage
                        id="competence_profiles_action_add"
                        defaultMessage="New profile" />
                </Plain>
            )}
            <Plain
                className="constructive with-icon"
                onClick={() => setCustom(true)}>
                <Define size={24} />
                <FormattedMessage
                    id="competence_profiles_action_define"
                    defaultMessage="Define custom target" />
            </Plain>
            <Modal
                show={!!creating}
                dismiss={() => setCreating(false)}>
                <ActingProfile
                    provide={true}
                    mode="edit"
                    dismiss={() => setCreating(false)}
                    onDone={() => setCreating(false)}
                    salt={salt} />
            </Modal>
        </>
    )
}

export default TargetTypesProfile
