export const clamp = (value, min, max) => {
    if((min || min === 0) && value < min) {
        value = min
    }

    if((max || max === 0) && value > max) {
        value = max
    }

    return value
}

export const roundedToFixed = (value, precision = 0) => {
    const multiplier = Math.pow(10, precision)

    return +(Math.round(value * multiplier) / multiplier).toFixed(precision)
}

export const percentageToAngle = percentage => (percentage / 100) * 360

export const percentToDecimal = percentage => {
    if(typeof percentage !== 'number') {
        return 0
    }

    if(percentage < 0) {
        percentage = 0
    }

    if(percentage > 100) {
        percentage = 100
    }

    return percentage / 100
}

export const decimalToPercent = decimal => {
    if(typeof decimal !== 'number') {
        return 0
    }

    if(decimal < 0) {
        decimal = 0
    }

    if(decimal > 1) {
        decimal = 1
    }

    return decimal * 100
}

export const isNumeric = value => !isNaN(value - parseFloat(value))

export const isInRange = ({ min, max, value, inclusive = true }) => {
    const invalidArguments = !![min, max, value]
        .map(n => typeof n)
        .filter(n => n !== 'number')
        .length

    if(invalidArguments) {
        return false
    }

    if(inclusive === true) {
        return min <= value && value <= max
    }

    return min < value && value < max
}