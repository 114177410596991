import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useHandbook } from 'contexts/handbook'
import { Scrollable as Modal } from 'modals/generic'
import EntityPicker from 'modals/entity-picker'
import Shares from './shares'

const EditMeta = ({ show, fields, editing, dismiss, salt }) => {
    const { formatMessage } = useIntl()
    const { languages } = useI18n()
    const { handbook } = useHandbook()

    if(['category', 'language'].includes(editing)) {
        const picker = {
            category: () => {
                const entities = handbook.categories.map(({ id, title, symbol }) => ({
                    id,
                    name: title,
                    symbol
                }))

                const picked = fields.category.current ?
                    entities.find(({ id }) => id === fields.category.current.id) :
                    null

                return {
                    type: 'category',
                    entities,
                    picked,
                    heading: formatMessage({
                        id: 'noun_category',
                        defaultMessage: 'Category'
                    }),
                    search: {
                        controlProps: {
                            placeholder: formatMessage({
                                id: 'action_category_find_options',
                                defaultMessage: 'Find category…'
                            })
                        }
                    },
                    doneAction: ({ picked }) => ({
                        label: formatMessage({
                            id: 'action_done',
                            defaultMessage: 'Done'
                        }),
                        effect: 'constructive',
                        onClick: () => fields.category.update(handbook.categories.find(({ id }) => id === picked.id))
                    })
                }
            },
            language: () => {
                const entities = Object.entries(languages)
                    .map(([code, language]) => ({
                        id: code,
                        name: language
                    }))

                const picked = fields.language.current ?
                    entities.find(({ id }) => id === fields.language.current) :
                    null

                return {
                    type: 'language',
                    entities,
                    picked,
                    heading: formatMessage({
                        id: 'noun_language',
                        defaultMessage: 'Language'
                    }),
                    search: {
                        controlProps: {
                            placeholder: formatMessage({
                                id: 'action_language_find_options',
                                defaultMessage: 'Find language…'
                            })
                        }
                    },
                    doneAction: ({ picked }) => ({
                        label: formatMessage({
                            id: 'action_done',
                            defaultMessage: 'Done'
                        }),
                        effect: 'constructive',
                        onClick: () => fields.language.update(picked.id)
                    })
                }
            }
        }[editing]?.()

        return (
            <EntityPicker
                {...picker}
                outer={false}
                show={show}
                dismiss={dismiss}
                cancelAction={() => ({
                    label: formatMessage({
                        id: 'action_cancel',
                        defaultMessage: 'Cancel'
                    }),
                    effect: 'neutral',
                    onClick: dismiss
                })} />
        )
    }

    const Handling = {
        shares: Shares
    }[editing]

    if(!Handling) {
        return null
    }

    return (
        <Modal
            show={show}
            dismiss={dismiss}>
            <Handling
                field={fields[editing]}
                onDone={value => {
                    fields[editing].update(value)
                    dismiss()
                }}
                dismiss={dismiss}
                salt={salt} />
        </Modal>
    )
}

export default EditMeta