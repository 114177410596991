import Bugsnag from '@bugsnag/browser'
import { formatComponentStack } from '@bugsnag/plugin-react'

export const notifyReactError = (error, info) => {
    try {
        const handledState = {
            severity: 'error',
            unhandled: true,
            severityReason: {
                type: 'unhandledException'
            }
        }

        const event = Bugsnag.Event.create(error, true, handledState, 'Error boundary', 1)

        if(info?.componentStack) {
            info.componentStack = formatComponentStack(info.componentStack)
        }

        event.addMetadata('react', info)
        Bugsnag.notify(event)
    } catch(e) {} // eslint-disable-line no-empty
}