import styled from 'styled-components'

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
`

export const DragDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
`