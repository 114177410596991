import styled, { css } from 'styled-components'
import { belowTablet, fromPhone, belowPhone } from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import AbsenceDateBase from 'pages/absence/components/date'
import StatusLabelBase from 'components/status-label'
import { Important as ImportantBase } from 'pages/people/pages/profile/panes/about/important/s'

export const Wrapper = styled(Grid)`
    ${({ theme: { absence: { actions, status } } }) => css`
        ${fromPhone`
            grid-template-columns: ${compact([
                'minmax(0, 2fr)',
                status && 'minmax(100px, max-content)',
                actions && '40px'
            ]).join(' ')};
        `}

        ${belowPhone`
            grid-template-areas:
                "entry actions"
                "status actions";
            row-gap: 8px;
        `}
    `}
    column-gap: 16px;
    align-items: start;

    border-radius: 6px;
    padding: 16px;
    box-shadow: var(--huma-shadow-lower);
    background: var(--huma-color-surface-default);
`

const entryDetailsCss = css`
    display: grid;
    grid-template-columns: 40px auto;
    column-gap: 16px;
    align-items: start;

    ${belowTablet`
        grid-column: 1 / 2
    `}

    ${belowPhone`
        grid-area: entry;
    `}

    height: auto;

    text-align: start;
`

export const EntryDetailsClickable = styled(Plain)`
    ${entryDetailsCss}

    &:not([disabled]) {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
`

export const EntryDetailsFragment = styled.div`
    ${entryDetailsCss}
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(EntryDetailsClickable)}
`

export const AbsenceDate = styled(AbsenceDateBase)`
    ${EntryDetailsClickable}:hover &,
    ${EntryDetailsClickable}:focus & {
        text-decoration: underline;
    }
`

export const Meta = styled(Grid)`
    grid-auto-flow: row;
    grid-auto-rows: auto;

    white-space: break-spaces;
`

export const StatusLabel = styled(StatusLabelBase)`
    align-self: center;

    ${fromPhone`
        justify-self: end;
    `}

    ${belowPhone`
        grid-area: status;
        justify-self: baseline;

        margin-inline-start: 56px;
    `}
`

export const Actions = styled.div`
    ${belowPhone`
        grid-area: actions;
        display: flex;
        justify-content: flex-end;
    `}
`

export const Important = styled(ImportantBase)`
    margin: 0 0 40px;
`