import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { useOrganization } from 'contexts/organization'
import { useHandbook } from 'contexts/handbook'
import HandbookTemplatesProvider, { useHandbookTemplates } from 'contexts/handbook-templates'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import {
    LoadingContainer,
    Hero, CloseButton,
    Subheading,
    FilterRow,
    ContentRow
} from './s'
import Loader from 'components/loader'
import Filter from './filter'
import { H1 as Heading } from 'components/typography/heading'
import Import from 'pages/handbook/components/import'
import Templates from './templates'
import { CaptionSmall } from 'components/typography/caption'
import Link from 'components/link'
import { Plain } from 'components/button'
import { X } from 'styled-icons/feather'

const HandbookTemplatePicker = ({ salt, ...props }) => {
    const { formatMessage } = useIntl()

    const { environment } = useEnvironment()
    const { organization } = useOrganization()

    const {
        addHandbook,
        updateHandbook
    } = useHandbook()

    const { hasFetched } = useHandbookTemplates()

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    let {
        onDone,
        heading,
        dismiss,
        context,
        target
    } = props

    const [adding, setAdding] = useState(false)

    if(!heading) {
        heading = formatMessage({
            id: 'handbooks_heading_template_picker',
            defaultMessage: 'Select one of our templates'
        })
    }

    const pick = template => onDone({ picked: template })

    const pickEmpty = async () => {
        setAdding(true)

        const { ok: addOk, response: handbook } = await addHandbook({
            contacts: ['me']
        })

        if(addOk) {
            !!onboarder && updateOnboardingStatus({ setupHandbook: 'completed' })

            const { ok } = await updateHandbook({
                shares: [
                    {
                        type: 'organization',
                        id: organization.id
                    }
                ]
            }, handbook.id)

            if(ok) {
                onDone({ picked: null })
            }
        }

        setAdding(false)
    }

    return (
        <>
            <Hero>
                <CloseButton onClick={dismiss}>
                    <X size={24} />
                </CloseButton>
                {(context === 'setup') && (
                    <Plain
                        className={`constructive${adding ? ' loading' : ''}`}
                        onClick={pickEmpty}
                        disabled={adding}>
                        <FormattedMessage
                            id="action_start_from_scratch"
                            defaultMessage="Start from scratch" />
                    </Plain>
                )}
                {(context !== 'setup') && (
                    <Import
                        show={target === 'import'}
                        pick={pick}
                        salt={salt} />
                )}
            </Hero>
            <Heading>{heading}</Heading>
            <Subheading>
                <FormattedMessage
                    id="handbooks_setup_step_templates_description"
                    defaultMessage="You can always add more templates later" />
            </Subheading>
            {!props?.params && (
                <FilterRow>
                    <Filter salt={salt} />
                </FilterRow>
            )}
            {!hasFetched && (
                <LoadingContainer>
                    <Loader />
                </LoadingContainer>
            )}
            {hasFetched && (
                <ContentRow>
                    <Templates
                        pick={pick}
                        adding={adding}
                        salt={salt} />
                </ContentRow>
            )}
            <CaptionSmall className="compact preserve-line-breaks">
                <FormattedMessage
                    id="handbooks_setup_step_templates_information"
                    defaultMessage="We create templates in collaboration with HR professionals to make life easier for you.\n<link>Read more about how it works</link>."
                    values={{
                        link: chunks => (
                            <Link
                                href={`https://www.${environment.tld}/knowledge/handbooks`}
                                target="_blank"
                                className="constructive">
                                {chunks}
                            </Link>
                        )
                    }} />
            </CaptionSmall>
        </>
    )
}

export default props => (
    <HandbookTemplatesProvider
        filter={props?.params}
        autoFetch={true}>
        <HandbookTemplatePicker {...props} />
    </HandbookTemplatesProvider>
)
