import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'
import { ParagraphSmall } from 'components/typography/paragraph'

export const Important = styled.div`
    margin: 8px 16px 16px;
    border-radius: 8px;
    padding: 8px;
    background-color: var(--huma-color-surface-warning-minimal);;

    &:empty {
        display: none;
    }

    ${belowTablet`
        margin: 8px 0 16px;
    `}
`

export const ImportantInner = styled.div`
    position: relative;

    border-radius: 4px;
    padding: 12px 16px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);
`

export const Header = styled(Grid)`
    grid-auto-flow: column;
    grid-template-columns: 24px max-content;
    align-items: center;
    gap: 16px;
`

export const Emoji = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
`

export const Paragraph = styled(ParagraphSmall)`
    margin-inline-start: 40px;
`