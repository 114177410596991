import React, { useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { UnitPermissionsProvider, useUnitPermissions } from 'contexts/unit-permissions'
import { useOrganization } from 'contexts/organization'
import { size, pick, omit } from 'utilities/object'
import { compact } from 'utilities/array'
import { unpackStringField } from 'utilities/person'
import { cls } from 'utilities/dom'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import { Helper, Label } from 'components/form/field/s'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import OneOfField from 'components/form/field/one-of'
import PersonField from 'components/form/field/person'
import EntityField from 'components/form/field/entity'
import TimeField from 'components/form/field/time'
import TemplateHelper from '../template-helper'
import DynamicAssignments from '../dynamic-assignments'
import ProcessAccess from 'pages/processes/components/process-access'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const AddProcessPickExistingUnit = ({
    templates, setTemplates,
    dynamicAssignmentTypes, setDynamicAssignmentTypes,
    proceed, modal, salt
}) => {
    const { formatMessage } = useIntl()

    const {
        person,

        replace,
        updatePerson
    } = usePerson()

    const { checkAll } = useUnitPermissions()
    const { organization } = useOrganization()

    const userRef = useRef()
    const groupRef = useRef()

    const [concernsType, setConcernsType] = useState('user')
    const [group, setGroup] = useState(null)
    const [initialSupervisor] = useState(!!person?.supervisor)
    const [updating, setUpdating] = useState(false)

    const [access, { general: fullAccess }] = checkAll({
        system: 'users:manage',
        unit: 'unit:processes:manage'
    })

    const update = async body => {
        setUpdating(true)

        const dynamicAssignments = pick(body, ...dynamicAssignmentTypes)
        body = omit(body, ...dynamicAssignmentTypes)

        let ok = false

        const supervisorChanged = !!body.supervisor && body.supervisor !== unpackStringField(person.supervisor)?.id
        if(supervisorChanged) {
            const updateFields = pick(body, 'supervisor')
            const result = await updatePerson(updateFields)
            ok = result.ok
        } else ok = true

        setUpdating(false)

        const concernsId = {
            user: person?.id,
            group: group?.id,
            organization: organization?.id
        }[concernsType]

        ok && proceed({
            concernsType,
            concernsId,
            title: body.title,
            description: body.description ?? null,
            templateIds: templates
                ?.filter(({ providedBy = null }) => !providedBy)
                .map(({ id }) => id) ?? [],
            humaTemplateIds: templates
                ?.filter(({ providedBy = null }) => !!providedBy)
                .map(({ id }) => id) ?? [],
            referenceDate: body.referenceDate ?? null,
            dynamicAssignments
        })
    }

    const unitPermissionsFilter = { permission: 'unit:processes:manage' }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'processes_action_add',
                    defaultMessage: 'New process'
                })}
                dismiss={modal.dismiss} />
            <Form
                layout="vertical"
                onSubmit={update}>
                {({ errors, trigger }) => (
                    <>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_title',
                                defaultMessage: 'Title'
                            })}
                            name="title"
                            field={{
                                required: true,
                                include: 'always'
                            }}
                            controlProps={{
                                autoFocus: true,
                                max: 255
                            }} />
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{ include: 'touched' }}
                            controlProps={{ maxLength: 2040 }} />
                        <Helper>
                            <FormattedMessage
                                id="processes_helper_description"
                                defaultMessage="This will be visible to everyone who has access to the process." />
                        </Helper>
                        <OneOfField
                            salt={salt}
                            label={formatMessage({
                                id: 'preposition_for',
                                defaultMessage: 'For'
                            })}
                            name="concernsType"
                            field={{
                                value: 'user',
                                required: true,
                                include: 'always',
                                options: compact([
                                    {
                                        value: 'user',
                                        label: formatMessage({
                                            id: 'assign_to_specific',
                                            defaultMessage: 'A specific person'
                                        }),
                                        controlRef: userRef,
                                        content: (
                                            <PersonField
                                                salt={salt}
                                                label={false}
                                                name="user"
                                                field={{
                                                    value: person,
                                                    required: concernsType === 'user',
                                                    include: 'always'
                                                }}
                                                picker={{
                                                    outer: false,
                                                    ...(!fullAccess ? {
                                                        message: {
                                                            type: 'info',
                                                            message: formatMessage({
                                                                id: 'processes_pick_existing_user_unit_permissions_person_picker_disclaimer',
                                                                defaultMessage: 'Only members of groups in which you can manage processes are available.'
                                                            })
                                                        },

                                                        // TODO: Reactivate this when the API supports combining permission
                                                        // filters with other filters supported by UI components
                                                        // filterOverrides: {
                                                        //     teams: {
                                                        //         query: {
                                                        //             path: '/units',
                                                        //             params: {
                                                        //                 ...unitPermissionsFilter,
                                                        //                 types: ['team']
                                                        //             }
                                                        //         }
                                                        //     },
                                                        //     locations: {
                                                        //         query: {
                                                        //             path: '/units',
                                                        //             params: {
                                                        //                 ...unitPermissionsFilter,
                                                        //                 types: ['location']
                                                        //             }
                                                        //         }
                                                        //     }
                                                        // }
                                                    } : null)
                                                }}
                                                {...(!fullAccess ? {
                                                    entity: {
                                                        params: unitPermissionsFilter
                                                    }
                                                } : null)}
                                                onChange={({ user }) => replace(user?.id)}
                                                ref={userRef} />
                                        )
                                    },
                                    {
                                        value: 'group',
                                        label: formatMessage({
                                            id: 'task_assign_to_team_or_location',
                                            defaultMessage: 'A team or location'
                                        }),
                                        controlRef: groupRef,
                                        content: (
                                            <EntityField
                                                salt={salt}
                                                label={false}
                                                name="group"
                                                field={{
                                                    required: concernsType === 'group',
                                                    include: 'always'
                                                }}
                                                picker={{
                                                    heading: formatMessage({
                                                        id: 'group_action_pick',
                                                        defaultMessage: 'Pick a group'
                                                    }),
                                                    ...(!fullAccess ? {
                                                        message: {
                                                            type: 'info',
                                                            message: formatMessage({
                                                                id: 'people_add_unit_permissions_groups_picker_disclaimer',
                                                                defaultMessage: 'Only groups in which you can manage users are available.'
                                                            })
                                                        }
                                                    } : null),
                                                    outer: false
                                                }}
                                                entity={{
                                                    type: 'group',
                                                    path: '/units',
                                                    params: {
                                                        types: ['team', 'location'],
                                                        ...(!fullAccess ? unitPermissionsFilter : null)
                                                    }
                                                }}
                                                onChange={({ group }) => setGroup(group)}
                                                ref={groupRef} />
                                        )
                                    },
                                    fullAccess && {
                                        value: 'organization',
                                        label: formatMessage({
                                            id: 'noun_no_one',
                                            defaultMessage: 'No one'
                                        })
                                    }
                                ])
                            }}
                            onChange={({ concernsType }) => setConcernsType(concernsType)} />
                        {concernsType === 'user' && (
                            <PersonField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_supervisor',
                                    defaultMessage: 'Supervisor'
                                })}
                                name="supervisor"
                                field={{
                                    ...(person?.supervisor ?? {}),
                                    unsettable: !initialSupervisor
                                }}
                                picker={{ outer: false }}
                                entity={{
                                    params: {
                                        notRecursivelySubordinateOf: person?.id
                                    }
                                }}
                                key={`${salt}:supervisor:${person?.supervisor?.value?.id ?? 'empty'}`} />
                        )}
                        <TimeField
                            salt={salt}
                            label={formatMessage({
                                id: 'label_process_date_reference',
                                defaultMessage: 'Reference date'
                            })}
                            name="referenceDate"
                            field={{
                                required: true,
                                include: 'always'
                            }} />
                        <Helper>
                            <FormattedMessage
                                id="processes_helper_reference_date"
                                defaultMessage="The date used as a reference for the process tasks." />
                        </Helper>
                        <TemplateHelper
                            templates={templates}
                            setTemplates={setTemplates}
                            salt={salt} />
                        <DynamicAssignments
                            templates={templates}
                            types={dynamicAssignmentTypes}
                            setTypes={setDynamicAssignmentTypes}
                            salt={salt} />
                        <Label as="p">
                            <FormattedMessage
                                id="noun_access"
                                defaultMessage="Access" />
                        </Label>
                        <ProcessAccess
                            concerns={person}
                            salt={salt} />
                        <Actions>
                            <Plain
                                onClick={modal.dismiss}
                                className="neutral"
                                disabled={updating}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={cls([
                                    'constructive',
                                    updating && 'loading'
                                ])}
                                disabled={!!size(errors) || updating || (concernsType === 'user' && !person)}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_continue"
                                    defaultMessage="Continue" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => (
    <UnitPermissionsProvider>
        <AddProcessPickExistingUnit {...props} />
    </UnitPermissionsProvider>
)