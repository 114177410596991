import React, { useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { size } from 'utilities/object'
import { compact } from 'utilities/array'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import OneOfField from 'components/form/field/one-of'
import CheckboxField from 'components/form/field/checkbox'
import EntityField from 'components/form/field/entity'
import WordList from 'components/word-list'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const HandbookDownloadAsPdf = ({ modal, salt }) => {
    const { formatMessage } = useIntl()

    const groupRef = useRef()

    const { downloadAsPdf } = useHandbook()

    const [downloading, setDownloading] = useState(false)
    const [hasError, setHasError] = useState(false)

    const [include, setInclude] = useState('organization')
    const [group, setGroup] = useState(null)

    const download = async (body, dryRun = false) => {
        if(!dryRun) {
            setDownloading(true)
        }

        let filter = {}

        if(include === 'group') {
            if(!!group) {
                filter.include = group.id
            }

            filter.includeSharedWithEveryone = body.includeSharedWithEveryone
        }

        if(include === 'organization') {
            filter.include = 'organization'
        }

        const { ok } = await downloadAsPdf({ filter, dryRun })

        setDownloading(false)

        if(dryRun) {
            setHasError(!ok)
        } else if(ok) {
            modal.dismiss()
        }
    }

    return (
        <Form
            layout="vertical"
            onChange={body => download(body, true)}
            onSubmit={body => download(body, false)}>
            {({ values, errors, trigger }) => (
                <>
                    <ModalHeader
                        heading={formatMessage({
                            id: 'action_download_as_pdf',
                            defaultMessage: 'Download as PDF'
                        })}
                        dismiss={modal.dismiss}>
                    </ModalHeader>
                    <OneOfField
                        salt={salt}
                        label={false}
                        name="include"
                        field={{
                            value: include,
                            include: 'always',
                            options: [
                                {
                                    value: 'organization',
                                    label: formatMessage({
                                        id: 'handbooks_download_label_organization',
                                        defaultMessage: 'Only download content shared with everyone'
                                    })
                                },
                                {
                                    value: 'group',
                                    label: formatMessage({
                                        id: 'handbooks_download_label_group',
                                        defaultMessage: 'Content shared with a team or location'
                                    }),
                                    controlRef: groupRef,
                                    content: (
                                        <>
                                            <EntityField
                                                salt={salt}
                                                label={false}
                                                name="group"
                                                field={{ value: group }}
                                                picker={{
                                                    heading: formatMessage({
                                                        id: 'group_action_pick',
                                                        defaultMessage: 'Pick group'
                                                    }),
                                                    outer: false
                                                }}
                                                entity={{
                                                    type: 'group',
                                                    path: '/groups'
                                                }}
                                                onChange={({ group }) => setGroup(group)}
                                                ref={groupRef} />
                                            <CheckboxField
                                                salt={salt}
                                                label={formatMessage({
                                                    id: 'handbooks_preview_label_include_content',
                                                    defaultMessage: 'Include content shared with everyone'
                                                })}
                                                name="includeSharedWithEveryone"
                                                field={{
                                                    value: true,
                                                    include: 'always'
                                                }} />
                                            {group && (
                                                <Message
                                                    type="info"
                                                    message={formatMessage({
                                                        id: 'handbooks_download_message_group',
                                                        defaultMessage: 'Content shared with {audience} will be included'
                                                    }, {
                                                        audience: (
                                                            <WordList
                                                                words={compact([
                                                                    group?.name,
                                                                    values?.includeSharedWithEveryone && formatMessage({
                                                                        id: 'noun_everyone',
                                                                        defaultMessage: 'Everyone'
                                                                    })
                                                                ])}
                                                                wordRenderer={name => <strong>{name}</strong>} />
                                                        )
                                                    })} />
                                            )}
                                        </>
                                    )
                                },
                                {
                                    value: 'all',
                                    label: formatMessage({
                                        id: 'handbooks_download_label_all',
                                        defaultMessage: 'Include all content'
                                    }),
                                    content: (
                                        <Message
                                            type="info"
                                            message={formatMessage({
                                                id: 'handbooks_download_message_all',
                                                defaultMessage: 'You, as an administrator, have access to all categories and topics'
                                            })} />
                                    )
                                }
                            ]
                        }}
                        onChange={({ include }) => setInclude(include)} />
                    <Message
                        type="info"
                        {...(!hasError ? { className: 'compact' } : null)}
                        message={formatMessage({
                            id: 'handbooks_download_message_unpublished_topics',
                            defaultMessage: 'Unpublished topics will not be included'
                        })} />
                    {hasError && (
                        <Message
                            type="error"
                            className="compact"
                            message={formatMessage({
                                id: 'handbooks_download_message_no_content',
                                defaultMessage: 'No content available for download with the selected options'
                            })} />
                    )}
                    <Actions>
                        <Plain
                            onClick={modal.dismiss}
                            disabled={downloading}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${downloading ? ' loading' : ''}`}
                            disabled={[
                                !!size(errors) ||
                                hasError ||
                                include === 'group' && !group ||
                                downloading
                            ].some(Boolean)}
                            ref={trigger}>
                            <FormattedMessage
                                id="action_download"
                                defaultMessage="Download" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default HandbookDownloadAsPdf
