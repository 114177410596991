import styled from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { Grid } from 'components/grid'

export const HeaderRow = styled(Grid)`
    position: relative;
    grid-template-columns: 40px minmax(0, 1fr) min-content;
    gap: 16px;

    margin-block-end: 16px;
    padding: 0 64px 32px 0;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -32px;

        width: calc(100% + 64px);
        height: 1px;
        background-color: var(--huma-color-surface-default);
    }

    ${belowPhone`
        grid-template-columns: 40px minmax(0, 1fr);
        grid-template-rows: auto auto;
        justify-items: start;

        padding-block-end: 16px;

        > button {
            grid-column: 2;
            grid-row: 2;
        }

        button.close {
            transform: translate(8px, -8px);
        }
    `}

    svg {
        ${svgStroke()}
    }
`


export const Appear = styled(motion.div).attrs(() => ({
    variants: {
        out: {
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        in: {
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .3
            }
        }
    },
    initial: 'out'
}))`
    &:not(:last-child) {
        margin-block-end: 80px;
    }
`