import styled, { css } from 'styled-components'
import {
    svgStroke,
    fromPhone, belowPhone
} from 'utilities/styled'
import LinkBase from 'components/link'
import { Plain } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

const clickableCss = css`
    --indent: 24px;

    position: relative;

    display: grid;
    column-gap: 24px;
    align-items: center;

    margin-inline: calc(var(--indent) * -1);
    width: calc(100% + var(--indent) * 2);
    height: 72px;
    padding: 12px var(--indent);

    text-align: start;

    ${fromPhone`
        grid-template-columns: 24px 3fr 5fr 24px;

        &:first-child {
            margin-block-start: -24px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-child {
            margin-block-end: -24px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    `}

    ${belowPhone`
        grid-template-columns: 24px auto 24px;
        grid-template-rows: 24px 20px;

        svg {
            grid-row: 1 / 3;
        }
    `}

    &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        inset-inline: var(--indent);

        height: 1px;
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    svg {
        ${svgStroke()}
    }

    & .arrow {
        transition: transform .1s ease-in-out;
    }

    &:focus-visible::after,
    &:has(+ :focus-visible)::after {
        opacity: 0;
    }

    &:focus-visible,
    &:hover {
        text-decoration: none;

        .arrow {
            transform: translateX(2px);
        }
    }
`

export const Link = styled(LinkBase)`
    ${clickableCss}
`

export const Button = styled(Plain)`
    ${clickableCss}
`

export const Name = styled(Paragraph)`
    overflow: hidden;

    ${belowPhone`
        grid-row: 1 / 2;
        grid-column: 2 / 3;
    `}

    span {
        text-decoration: inherit;
    }

    ${Link}:is(:hover, :focus-visible) &,
    ${Button}:is(:hover, :focus-visible) & {
        text-decoration: underline;
    }
`

export const Grants = styled(CaptionSmall)`
    ${belowPhone`
        grid-row: 2 / 3;
        grid-column: 2 / 3;
    `}
`