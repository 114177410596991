import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import PersonBase from 'components/person'
import { Columns, FlexChildShrink } from 'components/flex'
import ViewString from 'components/form/field/string/view'
import ViewContractableSelectBase from 'components/form/field/contractable-select/view'
import { Plain } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import ExpiryStatusBase from 'components/expiry-status'
import { InlineMessage as InlineMessageBase } from 'components/message'

export const Wrapper = styled.div`
    margin-block-end: 24px;

    &:last-child {
        margin-block-end: 0;
    }

    & > :last-child {
        margin-block-end: 0;
    }
`

export const Person = styled(PersonBase)`
    margin-block-end: 24px;
`

export const Note = styled(Paragraph)`
    display: inline-block;

    margin-block-end: 24px;
    border-radius: 0 16px 16px 16px;
    padding: 8px 16px;
    background-color: var(--huma-color-surface-minimal);

    ${Person} + & {
        margin-block-start: -8px;
    }
`

export const StringValue = styled(ViewString)`
    margin-block-end: 16px;
`

export const ViewContractableSelect = styled(ViewContractableSelectBase)`
    margin-block-end: 16px;

    & > :only-child {
        margin-block-end: 0;
    }
`

export const LevelDefinitionsButton = styled(Plain)`
    margin-block: -8px 16px;
`

export const DateColumns = styled(Columns)`
    align-items: center;

    margin-block-end: 16px;

    svg {
        ${svgStroke()}
    }
`

export const DateValue = styled(Paragraph)`
    &.empty {
        color: var(--huma-color-foreground-minimal);

        @media (var(--contrast-boost)) {
            color: var(--huma-color-foreground-subtle);
        }
    }
`

export const ArrowColumn = styled(FlexChildShrink)`
    margin-right: 24px;
`

export const Label = styled(Paragraph)`
    color: var(--huma-color-foreground-subtle);
`

export const ExpiryStatus = styled(ExpiryStatusBase)`
    align-items: center;

    & p {
        line-height: 20px;
    }
`

export const InlineMessage = styled(InlineMessageBase)`
    & p {
        line-height: 20px;
    }
`