import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import { Sheriff } from 'components/typography/heading'
import { ParagraphSmall } from 'components/typography/paragraph'
import { ArrowLeft as ArrowLeftBase } from 'styled-icons/feather'

const wave = keyframes`
	0%, 16%, 25%, 100% {
		transform: initial;
	}

	8% {
		transform: translateY(-.15em);
	}
`

export const LoaderHeading = styled(Sheriff)`
    position: relative;
    text-align: center;

    > span {
        display: inline-block;
        animation: ${wave} 3s linear infinite;

        &:nth-child(2) {
			animation-delay: .2s;
            margin-inline: -.05em;
		}

		&:nth-child(3) {
			animation-delay: .4s;
		}
    }
`

export const LoaderImage = styled(motion.img).attrs(() => ({
    variants: {
        out: {
            opacity: 0
        },
        in: {
            opacity: 1
        },
        transition: {
            duration: .2
        }
    },
    initial: 'out'
}))`
    display: block;
    width: 100%;
    aspect-ratio: 1;

    &.dark {
        display: none;
    }

    html[data-theme="dark"] & {
        &.dark {
            display: block;
        }

        &.light {
            display: none;
        }
    }
`

export const Retry = styled(ParagraphSmall)`
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease-in;

    &.reveal {
        opacity: 1;
        pointer-events: auto;
    }
`

export const ArrowLeft = styled(ArrowLeftBase)`
    margin-right: 16px;
    stroke-width: 2;
`