import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Tooltip from 'components/tooltip'
import { Buttons, ImportButton } from './s'
import { Ghost } from 'components/button'
import { UploadCloud as ImportIcon } from 'styled-icons/feather'
import { Scrollable as Modal } from 'modals/generic'
import Import from 'pages/equipment/modals/import'

const AddOrImportEquipment = ({ add, adding, showImport = false, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const [importing, setImporting] = useState(false)

    return (
        <Buttons>
            {!!showImport && (
                <>
                    <Tooltip content={formatMessage({
                        id: 'action_import_from_file',
                        defaultMessage: 'Import from file'
                    })}>
                        <ImportButton
                            onClick={() => setImporting(true)}
                            disabled={adding || importing}>
                            <ImportIcon size={24} />
                        </ImportButton>
                    </Tooltip>
                    <Modal
                        show={importing}
                        dismiss={() => setImporting(false)}
                        salt={`${salt}:import`}>
                        <Import
                            salt={`${salt}:import`}
                            onDone={importing => {
                                setImporting(false)
                                onDone?.({ importing: !!importing })
                            }} />
                    </Modal>
                </>
            )}
            <Ghost
                onClick={add}
                className="constructive"
                disabled={adding || importing}>
                <FormattedMessage
                    id="equipment_action_add"
                    defaultMessage="Add equipment" />
            </Ghost>
        </Buttons>
    )
}

export default AddOrImportEquipment