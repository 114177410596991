import React, { useState, useEffect } from 'react'
import { useHandbook } from 'contexts/handbook'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import { Container } from './s'
import Hero from './hero'
import Content from './content'

const EditOrder = ({ className, salt, ...props }) => {
    const { handbook } = useHandbook()
    const { categories = [] } = handbook ?? {}

    const [order, setOrder] = useState({})
    const [updating, setUpdating] = useState(false)

    const {
        show = false,
        outer = true,
        dismiss,
        ...content
    } = props

    useEffect(() => {
        if(!!show) {
            setOrder(order => ({
                ...order,
                categories: [
                    ...order.categories ?? [],
                    ...(!!categories?.length
                        ? categories.map(category => ({
                            ...category,
                            topics: category.topics.map(topic => ({
                                ...topic,
                                enabled: true
                            })),
                            enabled: true
                        }))
                        : []
                    )
                ],
                changed: false
            }))
        }

        if(!show) {
            setOrder({})
        }
    }, [show])

    salt = `modal:edit-order:${salt}`
    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={salt}>
                        <Container
                            {...(className ? { className } : null)}
                            animate={animate}>
                            <Hero
                                order={order}
                                setOrder={setOrder}
                                updating={updating}
                                setUpdating={setUpdating}
                                dismiss={dismiss}
                                salt={salt} />
                            <Content
                                {...content}
                                order={order}
                                setOrder={setOrder}
                                updating={updating}
                                dismiss={dismiss}
                                salt={salt} />
                        </Container>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default EditOrder
