import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import EquipmentTypeProvider, { useEquipmentType } from 'contexts/equipment-type'
import { useEquipmentContext } from 'pages/equipment/utilities'
import { size } from 'utilities/object'
import Loader from 'components/loader'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const AddEquipmentTypeVariant = ({ wait, context = 'types', onDone, salt }) => {
    const { formatMessage } = useIntl()

    const typeContext = useEquipmentType()
    const { updateType } = useEquipmentContext(context)

    const [working, setWorking] = useState(false)

    if(!typeContext?.type && wait) {
        return <Loader />
    }

    const {
        id,
        variants
    } = typeContext?.type ?? {}

    const addVariant = async ({ variant }, { resetTouched }) => {
        setWorking(true)

        const { ok } = await updateType({
            variants: [
                ...(variants ?? []),
                variant
            ]
        }, id)

        setWorking(false)

        if(ok) {
            resetTouched()
            onDone?.(variant)
        }
    }

    salt = `${salt}:edit`

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'equipment_variant_action_add',
                    defaultMessage: 'Add variant'
                })}
                dismiss={() => onDone?.()} />
            <Form
                onSubmit={addVariant}
                layout="vertical">
                {({ touched, errors, trigger }) => (
                    <>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="variant"
                            field={{
                                required: true,
                                include: 'always'
                            }} />
                        <Actions className="compact">
                            <Plain
                                className="neutral"
                                onClick={() => onDone?.()}
                                disabled={working}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${working ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors)}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_add"
                                    defaultMessage="Add" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => {
    const equipmentTypeContext = useEquipmentType()

    if(equipmentTypeContext || !props.type) {
        return <AddEquipmentTypeVariant {...props} />
    }

    return (
        <EquipmentTypeProvider id={props.type.id}>
            <AddEquipmentTypeVariant
                {...props}
                wait={true} />
        </EquipmentTypeProvider>
    )
}