import React, { Component, createContext, useContext } from 'react'
import { get } from 'api'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import PubSub from 'pubsub-js'
import { v4 as uuid } from 'uuid'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'

export const EntitiesContext = createContext()

export default class EntitiesProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.pagingDefaults = pagingDefaults(props?.paging)

        this.uuid = uuid()

        this.state = {
            entities: props.entities ?? [],
            filtered: null,
            fixed: !!props.entities,
            path: props.path,
            params: props.filter ?? {},
            filter: props.filter ?? {},
            filterFetchConverter: null,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            fetchEntities: this.fetchDebounced,
            setEntitiesFilter: this.setFilter,
            setFilterFetchConverter: this.setFilterFetchConverter,
            setLocalSearch: this.setLocalSearch,
            toggleSorting: this.toggleSorting,

            hasFetched: false,
            autoFetch: props.autoFetch,
            fetching: false,
            loading: false
        }

        this.subscription = PubSub.subscribe('entities.refresh', (_, uuid) => {
            if(this.uuid !== uuid) {
                this.setState({
                    entities: [],
                    filtered: null,
                    hasFetched: false
                }, this.fetchDebounced)
            }
        })
    }

    componentDidMount() {
        this.fetchDebounced()
    }

    componentDidUpdate({ path, paging, entities }, { filter }) {
        const pathChanged = path !== this.props.path
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const state = {}

        if(pathChanged) {
            state.path = this.props.path
        }

        if(pagingChanged || pathChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        if(entities !== this.props.entities) {
            state.entities = this.props.entities
            state.fixed = !!this.props.entities
        }

        this.setState(size(state) ? state : null, () => {
            if(pathChanged || filterChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe(this.subscription)
    }

    fetch = async (force = false) => {
        const { entityMapper = e => e } = this.props

        const {
            path,
            fixed,
            fetching,
            filter,
            paging,
            eternal,
            autoFetch,
            hasFetched
        } = this.state

        if((fixed || fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextFilter = this.state.filterFetchConverter?.(filter) ?? filter

        const nextPaging = {
            offset: hasFetched ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const { ok, response } = await get({
            path,
            params: {
                ...nextFilter,
                ...nextPaging
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ entities: previousEntities, paging }) => {
                const entities = [
                    ...previousEntities,
                    ...(response.items ?? response ?? []).map(entityMapper)
                ]

                return {
                    entities,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        hasNextPage: !!response.items?.length && entities.length < (response?.total ?? -1)
                    },
                    hasFetched: true,
                    autoFetch: this.props.autoFetch || (!!previousEntities.length && hasFetched),
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                entities: [],
                filtered: null,
                filter: nextFilter,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    setFilterFetchConverter = converter => void this.setState({ filterFetchConverter: converter })

    setLocalSearch = search => {
        if(!search) {
            return void this.setState({ filtered: null })
        }

        const { entities } = this.state

        search = search.toLowerCase()

        const filterEntities = entities => entities
            .map(entity => {
                const { name, entities: subEntities = [] } = entity
                const nameMatch = name.toLowerCase().includes(search)

                if(!subEntities.length) {
                    return nameMatch ? entity : null
                }

                const filteredSubEntities = filterEntities(subEntities)
                const subEntitiesMatch = !!filteredSubEntities.length

                if(nameMatch || subEntitiesMatch) {
                    return {
                        ...entity,
                        entities: filteredSubEntities
                    }
                }

                return null
            })
            .filter(Boolean)

        const filtered = filterEntities(entities)

        this.setState({ filtered })
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal || loading || !autoFetch || !paging?.hasNextPage) {
            return
        }

        this.fetchDebounced()
    }

    render() {
        const { children = null } = this.props

        return (
            <EntitiesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EntitiesContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useEntities = () => useContext(EntitiesContext)