import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { get } from 'api'
import { useI18n } from 'contexts/i18n'
import { useMe } from 'contexts/me'
import { getFullName } from 'utilities/person'
import { getAbsenceTypeUrl, getPeopleProfileUrl } from 'utilities/url'
import { isFuture, isPast } from 'pages/absence/utilities'
import { Important } from './s'
import Paragraph from 'components/typography/paragraph'
import StatusLabel from './status'
import { Plain } from 'components/button'
import { InlineMessage } from 'components/message'
import Link from 'components/link'
import { Scrollable as Modal } from 'modals/generic'
import Details, { steps } from './details'
import { safeFormat } from 'utilities/date-time'

const EntryFollowUp = ({ user, type, start, end, followUp, absenceAdmin }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { isItMyOwnId } = useMe()

    const [showingDetails, setShowingDetails] = useState(null)
    const [countryCode, setCountryCode] = useState(null)

    const { responsible } = followUp ?? {}

    const now = new Date()

    useEffect(() => {
        const fetchWorkSchedule = async () => {
            const { ok, response } = await get({
                path: `/users/${user.id}/work-schedule`
            })

            if(ok && response) {
                setCountryCode(Object.keys(steps).includes(response.countryCode) ? response.countryCode : null)
            }
        }

        fetchWorkSchedule()
    }, [])

    const isUpcoming = isFuture({ start }, now)
    const hasEnded = isPast({ start, end }, now)

    const getStatus = () => {
        if(!responsible || responsible?.id === user?.id) {
            return 'onHold'
        }

        if(isUpcoming) {
            return 'upcoming'
        }

        if(hasEnded) {
            return 'ended'
        }

        return 'ongoing'
    }

    const status = getStatus()

    return (
        <>
            <Important className="passive">
                <Paragraph className="compact">
                    <FormattedMessage
                        id="absence_heading_sick_leave_follow_up"
                        defaultMessage="Sick leave follow-up" />
                </Paragraph>
                <StatusLabel
                    className="status"
                    status={status} />
                {responsible && (
                    <Paragraph className="caption small compact">
                        <FormattedMessage
                            id="absence_follow_up_label_responsible"
                            defaultMessage="Responsible for follow-up: {user}"
                            values={{
                                user: (
                                    <Link
                                        to={getPeopleProfileUrl(responsible)}
                                        target="_blank">
                                        {getFullName(responsible)}
                                    </Link>
                                )
                            }} />
                    </Paragraph>
                )}
                {isUpcoming && (
                    <Paragraph className="caption small compact">
                        <FormattedMessage
                            id="absence_follow_up_label_upcoming"
                            defaultMessage="Starting {date}"
                            values={{ date: safeFormat(start, 'PP', { locale }) }} />
                    </Paragraph>
                )}
                {hasEnded && (
                    <Paragraph className="caption small compact">
                        <FormattedMessage
                            id="absence_follow_up_label_ended"
                            defaultMessage="Ended {date}"
                            values={{ date: safeFormat(end, 'PP', { locale }) }} />
                    </Paragraph>
                )}
                {(type.name === 'sick' && countryCode && !isItMyOwnId(user.id) && absenceAdmin) && (
                    <Plain
                        className="constructive"
                        size="small"
                        onClick={() => setShowingDetails({ countryCode })}>
                        <FormattedMessage
                            id="action_view_details"
                            defaultMessage="View details" />
                    </Plain>
                )}
                {(responsible?.id === user?.id && !!absenceAdmin) && (
                    <InlineMessage
                        type="warning"
                        message={formatMessage({
                            id: 'absence_follow_up_label_responsible_self',
                            defaultMessage: 'It is not possible to manage your own sick leave process'
                        })}>
                        <Paragraph className="caption small compact">
                            <FormattedMessage
                                id="absence_follow_up_message_responsible_self"
                                defaultMessage="Manage the responsible in <link>absence type settings</link>"
                                values={{
                                    link: chunks => (
                                        <Link
                                            to={getAbsenceTypeUrl(type)}
                                            target="_blank"
                                            className="constructive">
                                            {chunks}
                                        </Link>
                                    )
                                }} />
                        </Paragraph>
                    </InlineMessage>
                )}
                {(!responsible && !!absenceAdmin) && (
                    <InlineMessage
                        type="warning"
                        message={formatMessage({
                            id: 'absence_follow_up_label_no_responsible',
                            defaultMessage: 'No responsible for follow-up'
                        })}>
                        <Paragraph className="caption small compact">
                            <FormattedMessage
                                id="absence_follow_up_message_no_responsible"
                                defaultMessage="Set up a responsible in <link>absence type settings</link>"
                                values={{
                                    link: chunks => (
                                        <Link
                                            to={getAbsenceTypeUrl(type)}
                                            target="_blank"
                                            className="constructive">
                                            {chunks}
                                        </Link>
                                    )
                                }} />
                        </Paragraph>
                    </InlineMessage>
                )}
            </Important>
            <Modal
                show={!!showingDetails}
                dismiss={() => setShowingDetails(null)}>
                <Details
                    {...showingDetails}
                    dismiss={() => setShowingDetails(null)} />
            </Modal>
        </>
    )
}

export default EntryFollowUp
