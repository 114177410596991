import styled from 'styled-components'
import { Naked } from 'components/drawable-panel'
import { Grid } from 'components/grid'
import { Columns, FlexChild } from 'components/flex'
import FeaturesBase from 'components/features'

export const ExpandableContainer = styled.div`
    padding-inline: 16px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
`

export const Expandable = styled(Naked)`
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    row-gap: 0;
    transition: row-gap .2s ease-in-out;

    &.expanded {
        row-gap: 8px;
    }
`

export const HeaderLayout = styled(Grid)`
    grid-auto-flow: row;
    grid-auto-rows: auto;
    align-items: stretch;
    row-gap: 0;
    padding-block-start: 16px;

    &.editable {
        row-gap: 16px;
    }
`

export const HeaderRow = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: minmax(min-content, 1fr);
    justify-content: space-between;
    align-items: center;

    &:only-child {
        padding-block-end: 16px;
    }

    &.toggler {
        cursor: pointer;
    }

    &.standalone {
        padding-block: 16px;
    }
`

export const ContentLayout = styled.div`
    padding-block-end: 16px;
`

export const Features = styled(FeaturesBase)`
    font-size: 14px;
`

export const Price = styled(Columns)`
    line-height: 20px;
`

export const PriceHeading = FlexChild

export const PriceSummary = styled(FlexChild)`
    text-align: right;
`

export const PriceDetails = styled.div`
    font-size: 14px;
`