import {
    transparent,
    white,
    shadow,
    monochrome
} from './colors'

export default {
    color: monochrome.base,
    background: transparent,
    shadow: transparent,

    hover: {
        color: monochrome.base,
        background: white,
        shadow: transparent
    },

    focus: {
        color: monochrome.base,
        background: transparent,
        shadow: transparent
    },

    current: {
        color: monochrome.base,
        background: white,
        shadow
    }
}
