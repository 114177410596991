import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { darkMode } from 'utilities/styled'
import { useTouch } from 'hooks/viewport'
import {
    svgStroke, buttonOutlineProps,
    bp, belowTablet, belowLaptop
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { FocusOn } from 'react-focus-on'

const PanelBase = styled.div.attrs({
    role: 'dialog'
})`
    pointer-events: none;
    display: grid;
    grid-template: 1fr / 1fr;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    transform: translateZ(0);

    ${belowTablet`
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    `}

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
    }
`

export const SidePanelBase = styled(PanelBase).attrs(attributes => ({
    ...attributes,
    'aria-labelledby': 'Side Panel title', // TODO
    'aria-describedby': 'Side Panel description' // TODO
}))``

const BottomPanelBase = styled.div.attrs(attributes => ({
    ...attributes,
    'aria-labelledby': 'Bottom Panel title', // TODO
    'aria-describedby': 'Bottom Panel description' // TODO
}))`
    display: grid;
    grid-template: subgrid / subgrid;
    grid-area: inherit;
`

const panelFactory = Panel => ({
    autoFocus = false,
    returnFocus = true,
    dismiss = () => {},
    children = null,
    ...props
}) => {
    const touch = useTouch()

    return (
        <FocusOn
            enabled={false}
            autoFocus={touch ? false : autoFocus}
            returnFocus={returnFocus}
            onEscapeKey={dismiss}>
            <Panel
                {...props}
                onClick={e => e.stopPropagation()}>
                {children}
            </Panel>
        </FocusOn>
    )
}

export const SidePanel = panelFactory(SidePanelBase)
export const BottomPanel = panelFactory(BottomPanelBase)

export const sidepanelify = panel => createPortal(
    panel,
    document.getElementById('side-panel')
)

export const bottompanelify = panel => createPortal(
    panel,
    document.getElementById('bottom-panel')
)

export const Wrapper = AnimatePresence

export const SideContainer = styled(motion.div).attrs(({ collapsible = false }) => ({
    variants: {
        out: {
            x: collapsible ? -24 : 0,
            pointerEvents: 'none'
        },
        in: {
            x: '-100%',
            pointerEvents: 'auto'
        }
    },
    transition: {
        duration: .15,
        ease: 'easeInOut'
    },
    initial: 'out',
    exit: 'out',

    onChange: e => {
        e.stopPropagation()
    }
}))`
    position: fixed;
    top: var(--huma-global-main-layout-header-height);
    right: calc(var(--side-panel-width) * -1);

    width: var(--side-panel-width);
    height: calc(100dvh - var(--huma-global-main-layout-header-height));
    background-color: var(--huma-color-surface-default);
    box-shadow:
        inset 0 1px 1px 0 rgb(0 0 0 / .1),
        var(--huma-shadow-lowest);

    ${darkMode`
        box-shadow: inset 1px 0 0 var(--huma-color-border-default)
    `}

    ${bp.below(600)`
        right: -100%;

        width: 100%;
    `}
`

export const SideContainerInner = styled.div`
    overflow-y: auto;
    overflow-x: hidden;

    width: 100%;
    height: 100%;
    padding: var(--side-panel-padding);
    padding-block-end: 92px; /* Hubspot chat icon */

    ${belowLaptop`
        --side-panel-padding: 24px;
    `}
`

export const BottomContainer = styled(motion.div).attrs(() => ({
    variants: {
        out: {
            y: '100%'
        },
        in: {
            y: 0
        }
    },
    transition: {
        duration: .15,
        ease: 'easeInOut'
    },
    initial: 'out',
    exit: 'out',

    onChange: e => {
        e.stopPropagation()
    }
}))`
    position: sticky;
    bottom: 0;

    align-self: end;
    grid-column: inherit;

`

export const BottomContainerInner = styled(Grid)`
    width: 100%;
    max-width: var(--huma-global-main-layout-width);
`

export const ToggleButton = styled.button`
    cursor: pointer;
    pointer-events: auto;

    position: absolute;
    z-index: 1;
    isolation: isolate;
    top: 120px;
    left: -16px;

    display: grid;
    place-items: center;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: var(--huma-color-surface-default);

    &:hover {
        border-color: var(--huma-color-border-default-focus);
        background-color: var(--huma-color-surface-minimal);
    }

    &:not(:active):focus-visible {
        outline: ${buttonOutlineProps.width}px solid var(--huma-color-border-default-focus);
        outline-offset: ${buttonOutlineProps.offset}px;
    }

    &:active {
        outline: none;
        border-color: var(--huma-color-border-bold);
        background-color: var(--huma-color-surface-subtle);
    }

    @media (prefers-reduced-motion: no-preference) {
        &:focus-visible {
            transition: outline-offset 75ms ease-out;
            transition-duration: 0.1s;
        }
    }

    svg {
        ${svgStroke('small')}

        color: var(--huma-color-foreground-default);
        transform: translateX(1px);

        ${SideContainer}:not(.open) & {
            rotate: .5turn;
        }
    }
`