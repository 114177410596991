import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useProcessTemplates } from 'contexts/process-templates'
import { useStorage } from 'hooks/storage'
import { map, pick, omit, size } from 'utilities/object'
import { capitalize } from 'utilities/string'
import { jarowinklerDistance } from 'utilities/string-metric'
import {
    FilterWrapper,
    SearchWrapper, SearchField, SearchIcon, Count,
    Expandable, ExpandableLayout, FilterHeader
} from './s'
import Form from 'components/form/controller'
import Actions from 'components/form/actions'
import Paragraph from 'components/typography/paragraph'
import { Filter as ToggleFilterButton, Plain } from 'components/button'
import { Filter as FilterIcon } from 'styled-icons/fluentui-system-regular'
import SelectField from 'components/form/field/select'
import CountryField from 'components/form/field/country-picker'

const getSearchMatch = (search, match) => {
    search = search.toLowerCase()
    match = match.toLowerCase()

    return match.includes(search)
}

const HumaTemplatesFilter = ({ search, setSearch, setFiltered, salt }) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    const {
        locale,
        locales
    } = useI18n()

    const {
        humaTemplates: humaExternal,
        fetchHumaTemplates
    } = useProcessTemplates()

    const {
        templates: humaTemplates = [],
        templateLocales = [],

        hasFetchedLibraryTemplates: hasFetched
    } = humaExternal

    const [memoryFilter, setMemoryFilter] = useStorage({
        key: `${salt}:huma:filter`,
        defaultValue: {},
        type: 'session'
    })

    const [isExpanded, setIsExpanded] = useState(false)
    const [activeFilter, setActiveFilter] = useState(memoryFilter)

    useEffect(() => {
        if(!humaTemplates.length) {
            return
        }

        let filtered = humaTemplates

        if(!!search.length) {
            // First filter by search match,
            // then sort by Jaro-Winkler distance
            filtered = filtered
                .filter(template => getSearchMatch(search, template.name))
                .sort(({ name: a }, { name: b }) => {
                    const scoreA = jarowinklerDistance(search, a)
                    const scoreB = jarowinklerDistance(search, b)

                    // Prioritize in the following order:
                    // 1. Starts with
                    // 2. Jaro-Winkler distance
                    if(a.startsWith(search)) {
                        return -1
                    }

                    if(b.startsWith(search)) {
                        return 1
                    }

                    return scoreB - scoreA
                })
        }

        // It's possible that the providedBy filter has options that are not available for the current locale
        // In that case, we should remove them from the active filter
        if(activeFilter?.providedBy) {
            const availableProviders = humaTemplates.reduce((providers, { providedBy }) => {
                if(!providers.some(({ key }) => key === providedBy.id)) {
                    providers.push({
                        key: providedBy.id,
                        value: providedBy.id
                    })
                }

                return providers
            }, [])

            if(!availableProviders.some(({ key }) => key === activeFilter.providedBy)) {
                setActiveFilter({
                    ...activeFilter,
                    providedBy: null
                })
                setMemoryFilter({
                    ...activeFilter,
                    providedBy: null
                })
            }
        }

        const filterWithoutLocale = omit(activeFilter, 'locale')

        if(size(filterWithoutLocale)) {
            filtered = filtered.filter(template => {
                let matches = true

                for(const [what, value] of Object.entries(filterWithoutLocale)) {
                    if(what === 'providedBy') {
                        if(value && template.providedBy.id !== value) {
                            matches = false
                            break
                        }

                        continue
                    }

                    if(value && template[what] !== value) {
                        matches = false
                        break
                    }
                }

                return matches
            })
        }

        setFiltered(filtered)
    }, [search, activeFilter, humaTemplates])

    useEffect(() => {
        fetchHumaTemplates(memoryFilter?.locale ?? null)
    }, [])

    const setFilter = (what, value) => {
        const isValidValue = value !== '' && value !== null

        const updatedFilter = {
            ...activeFilter,
            ...(isValidValue ? { [what]: value } : null)
        }

        if(!isValidValue) {
            delete updatedFilter[what]
        }

        setActiveFilter(updatedFilter)
        setMemoryFilter(updatedFilter)
    }

    const resetAll = () => {
        fetchHumaTemplates()
        setActiveFilter({})
        setMemoryFilter({})
    }

    const filtersAppliedCount = size(activeFilter)

    return (
        <Form layout="vertical">
            {({ reset }) => (
                <>
                    <FilterWrapper>
                        <SearchWrapper>
                            <SearchField
                                salt={salt}
                                className="compact"
                                label={false}
                                name="search"
                                controlProps={{
                                    placeholder: formatMessage({
                                        id: 'template_action_find_by_title',
                                        defaultMessage: 'Find template by title'
                                    }),
                                    autoFocus: true
                                }}
                                enabled={hasFetched}
                                onChange={({ search }) => setSearch(search)} />
                            <SearchIcon size={16} />
                        </SearchWrapper>
                        <ToggleFilterButton
                            {...(isExpanded ? { className: 'active' } : null)}
                            onClick={() => setIsExpanded(isExpanded => !isExpanded)}>
                            {!!filtersAppliedCount && <Count>{filtersAppliedCount}</Count>}
                            {!filtersAppliedCount && <FilterIcon size={16} />}
                            <FormattedMessage
                                id="action_filter"
                                defaultMessage="Filter" />
                        </ToggleFilterButton>
                    </FilterWrapper>
                    <Expandable {...(isExpanded ? { className: 'expanded' } : { inert: 'true' })}>
                        <ExpandableLayout>
                            <FilterHeader>
                                <Paragraph className="caption compact">
                                    <FormattedMessage
                                        id="noun_filters"
                                        defaultMessage="Filters" />
                                </Paragraph>
                                <Plain
                                    className="destructive"
                                    onClick={() => {
                                        reset()
                                        resetAll()
                                    }}
                                    disabled={!size(activeFilter)}>
                                    <FormattedMessage
                                        id="action_reset"
                                        defaultMessage="Reset" />
                                </Plain>
                            </FilterHeader>
                            <SelectField
                                label={formatMessage({
                                    id: 'noun_language',
                                    defaultMessage: 'Language'
                                })}
                                name="locale"
                                field={{
                                    value: activeFilter?.locale ?? '',
                                    allowEmptyOption: false
                                }}
                                options={[
                                    {
                                        value: '',
                                        text: formatMessage({
                                            id: 'all_plural',
                                            defaultMessage: 'All'
                                        })
                                    },
                                    ...map(pick(locales, ...templateLocales), ({ flag }, locale) => ({
                                        key: locale,
                                        value: locale,
                                        text: `${flag} ${capitalize(formatDisplayName(locale, { type: 'language' }))}`
                                    })).sort(({ value: one }, { value: two }) => {
                                        if(one === locale) {
                                            return -1
                                        }

                                        if(two === locale) {
                                            return 1
                                        }

                                        return 0
                                    })
                                ]}
                                onChange={({ locale }) => {
                                    fetchHumaTemplates(locale)
                                    setFilter('locale', locale)
                                }}
                                className="compact" />
                            <CountryField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_legislation',
                                    defaultMessage: 'Legislation'
                                })}
                                name="legislation"
                                field={{ value: activeFilter?.legislation ?? '' }}
                                onChange={({ legislation }) => setFilter('legislation', legislation)}
                                className="compact" />
                            <SelectField
                                salt={salt}
                                label={formatMessage({
                                    id: 'provided_by',
                                    defaultMessage: 'Provided by'
                                })}
                                name="providedBy"
                                field={{
                                    value: activeFilter?.providedBy ?? 'all',
                                    allowEmptyOption: false
                                }}
                                options={[
                                    {
                                        key: 'all',
                                        value: 'all',
                                        text: formatMessage({
                                            id: 'all_plural',
                                            defaultMessage: 'All'
                                        })
                                    },
                                    ...humaTemplates.reduce((providers, { providedBy }) => {
                                        if(!providers.some(({ key }) => key === providedBy.id)) {
                                            providers.push({
                                                key: providedBy.id,
                                                value: providedBy.id,
                                                text: providedBy.name
                                            })
                                        }

                                        return providers
                                    }, [])
                                ]}
                                onChange={({ providedBy }) => {
                                    if(providedBy === 'all') {
                                        setFilter('providedBy', null)
                                        return
                                    }

                                    setFilter('providedBy', providedBy)
                                }}
                                className="compact" />
                            <Actions className="centered compact">
                                <Plain
                                    className="neutral"
                                    onClick={() => setIsExpanded(false)}>
                                    <FormattedMessage
                                        id="action_close"
                                        defaultMessage="Close" />
                                </Plain>
                            </Actions>
                        </ExpandableLayout>
                    </Expandable>
                </>
            )}
        </Form>
    )
}

export default HumaTemplatesFilter