import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { usePublicOption } from 'hooks/public-option'
import { cls } from 'utilities/dom'
import {
    Label,
    Meta, MetaItem, Value, Helper, CheckboxField, ChangeButton, Emoji,
    EntitiesPlain
} from './s'
import DrawablePanel from 'components/drawable-panel'
import UpgradeCTA from 'components/upgrade-cta'
import EditMeta from './edit'

const TopicMeta = ({ fields, salt }) => {
    const { formatMessage } = useIntl()
    const { languages } = useI18n()

    const { checkFeature } = useAccess()
    const checkUpgradable = useUpgradable()

    const groupsAvailable = checkFeature('handbook-groups')
    const groupsUpgradable = checkUpgradable({ feature: 'handbook-groups' })

    const featuredAvailable = checkFeature('handbook-featured')
    const featuredUpgradable = checkUpgradable({ feature: 'handbook-featured' })

    const [editing, setEditing] = useState(null)

    const publicOption = usePublicOption(true)
    if(!publicOption) {
        return null
    }

    const {
        category,
        shares,
        language,
        pinned
    } = fields

    return (
        <DrawablePanel
            heading={formatMessage({
                id: 'noun_settings',
                defaultMessage: 'Settings'
            })}
            open={true}>
            <Meta>
                <MetaItem>
                    <Label required={true}>
                        <FormattedMessage
                            id="noun_category"
                            defaultMessage="Category" />
                    </Label>
                    <Value>
                        <Emoji
                            emoji={category.current.symbol.emoji}
                            size={20} />
                        <span>{category.current.title}</span>
                    </Value>
                    <ChangeButton
                        className="constructive"
                        onClick={() => setEditing('category')}>
                        <FormattedMessage
                            id="action_change"
                            defaultMessage="Change" />
                    </ChangeButton>
                </MetaItem>
                <MetaItem>
                    <Label required={true}>
                        <FormattedMessage
                            id="noun_access"
                            defaultMessage="Access" />
                    </Label>
                    <Value {...(!groupsAvailable ? { className: 'vertical' } : null)}>
                        <EntitiesPlain
                            entities={shares.current?.length ?
                                shares.current :
                                [publicOption]
                            }
                            showAggregateIcon={true}
                            salt={salt} />
                    </Value>
                    <ChangeButton
                        className="constructive reversed"
                        onClick={() => setEditing('shares')}
                        {...(groupsUpgradable ? { upgradable: true } : null)}>
                        <FormattedMessage
                            id="action_change"
                            defaultMessage="Change" />
                    </ChangeButton>
                </MetaItem>
                <MetaItem>
                    <Label required={true}>
                        <FormattedMessage
                            id="noun_language"
                            defaultMessage="Language" />
                    </Label>
                    <Value className="vertical">
                        <span>{languages?.[language.current] ?? ''}</span>
                        <Helper>
                            <FormattedMessage
                                id="handbooks_helper_language"
                                defaultMessage="Defining the language of a topic will make it easier for users to find what they are looking for." />
                        </Helper>
                    </Value>
                    <ChangeButton
                        className="constructive"
                        onClick={() => setEditing('language')}>
                        <FormattedMessage
                            id="action_change"
                            defaultMessage="Change" />
                    </ChangeButton>
                </MetaItem>
                <MetaItem className={cls([
                    'toggle',
                    featuredUpgradable && 'action-less'
                ])}>
                    <Label>
                        <FormattedMessage
                            id="handbooks_label_pinned_topic"
                            defaultMessage="Pinned topic" />
                    </Label>
                    <Value className="vertical">
                        {featuredAvailable && (
                            <CheckboxField
                                salt={salt}
                                className="compact"
                                label={false}
                                name="pinned"
                                field={{ value: pinned.current }}
                                onChange={({ pinned: value }) => pinned.update(value)}
                                interaction="switch" />
                        )}
                        {featuredUpgradable && (
                            <UpgradeCTA
                                feature="handbook-featured"
                                useUpgradeIcon
                                useFeatureOrModuleIcon
                                useFeatureDescription
                                className="compact"
                                salt="handbook-featured" />
                        )}
                    </Value>
                </MetaItem>
            </Meta>
            <EditMeta
                show={!!editing}
                dismiss={() => setEditing(null)}
                fields={fields}
                editing={editing}
                salt={salt} />
        </DrawablePanel>
    )
}

export default TopicMeta
