import React from 'react'
import { useIntl } from 'react-intl'
import { BundleName } from 'components/payment/plan'
import { ModalHeader } from 'modals/generic'
import { InlineMessage } from 'components/message'

const Title = ({
    change, swap, to, warning = false,
    handleRecurringChallenge = false, renewPayment = false,
    payingWithCard, payingWithInvoice,
    dismiss
}) => {
    const { formatMessage } = useIntl()

    let translation
    let values = {}

    if(warning && ['downgrade', 'freeload'].includes(change)) {
        change = `${change}_warning`
    }

    if(change === 'modify' && payingWithCard) {
        change = 'modify_card'
    }

    if(change === 'modify' && payingWithInvoice) {
        change = 'modify_invoice'
    }

    if(handleRecurringChallenge) {
        translation = translations.handleRecurringChallenge
    } else if(swap && !change) {
        translation = translations.swap
    } else if(change in translations) {
        translation = translations[change]

        if(warning && ['downgrade', 'freeload'].includes(change)) {
            translation = translations[`${change}_warning`]
        }

        if(change === 'purchase') {
            values = { plan: <BundleName bundle={to} /> }
        }
    }

    if(!translation) {
        return null
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage(translation, values)}
                dismiss={dismiss}
                {...((handleRecurringChallenge && renewPayment) ? { className: 'compact' } : null)} />
            {(handleRecurringChallenge && renewPayment) && (
                <InlineMessage
                    type="warning"
                    className="spacious"
                    message={formatMessage({
                        id: 'payment_plan_caption_handle_recurring_challenge_renew',
                        defaultMessage: 'You have to provide your payment information anew in order to continue'
                    })} />
            )}
        </>
    )
}

export default Title

const translations = {
    purchase: {
        id: 'payment_plan_heading_purchase',
        defaultMessage: 'Buy {plan}'
    },
    upgrade: {
        id: 'payment_plan_heading_upgrade',
        defaultMessage: 'Upgrade'
    },
    downgrade: {
        id: 'payment_plan_heading_downgrade',
        defaultMessage: 'Downgrade'
    },
    modify_card: {
        id: 'payment_plan_heading_modify_card',
        defaultMessage: 'Replace payment card'
    },
    modify_invoice: {
        id: 'payment_plan_heading_modify_invoice',
        defaultMessage: 'Update payment information'
    },
    term: {
        id: 'payment_plan_heading_switch_term',
        defaultMessage: 'Change billing period'
    },
    swap: {
        id: 'subscription_action_switch',
        defaultMessage: 'Switch subscription'
    },
    handleRecurringChallenge: {
        id: 'payment_plan_heading_handle_recurring_challenge',
        defaultMessage: 'Verify your payment method'
    },
    downgrade_warning: {
        id: 'payment_plan_heading_downgrade_warning',
        defaultMessage: 'You’ll lose functionality'
    },
    freeload_warning: {
        id: 'payment_plan_heading_freeload_warning',
        defaultMessage: 'You can downgrade after the trial period'
    },
    cancel: {
        id: 'subscription_action_cancel',
        defaultMessage: 'Cancel subscription'
    }
}