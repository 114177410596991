import React, { useState } from 'react'
import Page, { useAugmentTitle } from 'contexts/page'
import LoginProvider, { useLogin } from 'contexts/login'
import PublicLayout from 'app/layouts/public'
import EmailVerification from 'components/email-verification'
import SurveyRespond from 'components/survey-respond'
import OneHumaDomain from './1-huma-domain'
import TwoConnect from './2-connect'
import ThreeVerify from './3-verify'
import TwoForgot from './2-forgot'
import ThreeForgot from './3-forgot'

const LoginPage = () => {
    const {
        step,
        key,
        forgot
    } = useLogin()

    useAugmentTitle([
        {
            id: 'step_of_total',
            defaultMessage: 'Step {step} of {total}',
        },
        {
            step,
            total: 3
        }
    ])

    const [emailVerificationResult, setEmailVerificationResult] = useState(null)

    return (
        <PublicLayout>
            {(!forgot && step < 3) && (
                <>
                    <EmailVerification onResolve={setEmailVerificationResult} />
                    <SurveyRespond />
                </>
            )}
            <OneHumaDomain show={step === 1} />
            <TwoForgot show={!!forgot && step === 2} />
            <ThreeForgot show={!!forgot && step === 3} />
            <TwoConnect
                show={!!key && step === 2}
                emailVerification={emailVerificationResult} />
            <ThreeVerify show={!!key && step === 3} />
        </PublicLayout>
    )
}

export default props => (
    <Page
        view="login"
        title={{
            id: 'action_login',
            defaultMessage: 'Log in'
        }}>
        <LoginProvider>
            <LoginPage {...props} />
        </LoginProvider>
    </Page>
)