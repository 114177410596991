import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { flexColumnsCss, FlexChild } from 'components/flex'
import ObscuredBase from 'components/obscured'

export const Item = styled.div`
    ${flexColumnsCss}

    &:not(:last-child) {
        margin-block-end: 16px;
    }

    ${belowTablet`
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-columns: minmax(0, 1fr) 40px;
    `}
`

export const Details = styled(FlexChild)`
    ${belowTablet`
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    `}
`

export const Country = styled.span`
    font-size: 14px;
    line-height: 20px;

    .revealed & {
        color: var(--huma-color-foreground-subtle);
    }
`

export const Obscured = styled(ObscuredBase)`
    height: auto;
`