import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useTheme, ThemeProvider } from 'styled-components'
import { format } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import AbsenceEntriesProvider, { useAbsenceEntries } from 'contexts/absence-entries'
import { Wrapper, Meta, CaptionSmall } from './s'
import { DisplayEmoji } from 'components/symbol'
import { isOngoing } from 'pages/absence/utilities'
import Paragraph from 'components/typography/paragraph'
import { Displayer as TypeName } from 'pages/absence/components/type-name'
import { ArrowRight as Arrow } from 'styled-icons/feather'

const Absence = () => {
    const { dateLocale: locale } = useI18n()

    let theme = useTheme()

    const { entries } = useAbsenceEntries()
    const now = new Date()

    const [ongoing] = getOngoingEntries(entries, now)

    if(!ongoing) {
        return null
    }

    const {
        type,
        end
    } = ongoing ?? {}

    const {
        name,
        symbol
    } = type

    theme = {
        ...theme,
        absence: {
            hasAction: false
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <Meta>
                    <Paragraph className="compact">
                        <FormattedMessage
                            id="absence_status_currently_away"
                            defaultMessage="Currently away" />
                    </Paragraph>
                    {(!!name || !!end) && (
                        <CaptionSmall className="compact">
                            <DisplayEmoji
                                emoji={symbol.emoji}
                                size={16} />
                            {!!name && <TypeName {...type} />}
                            <Arrow size={16} />
                            {!!end && format(end, 'PP', { locale })}
                            {!end && (
                                <FormattedMessage
                                    id="absence_message_missing_end_date"
                                    defaultMessage="No end date" />
                            )}
                        </CaptionSmall>
                    )}
                </Meta>
            </Wrapper>
        </ThemeProvider>
    )
}

const getOngoingEntries = (entries, now) => entries
    .filter(entry => isOngoing(entry, now))

export default ({ salt, ...props }) => {
    const { id } = useParams()

    return (
        <AbsenceEntriesProvider
            filter={{
                statuses: ['approved'],
                users: [id]
            }}
            paging={{ limit: 10 }}
            key={`${salt}:absence-entries:${id}`}>
            <Absence {...props} />
        </AbsenceEntriesProvider>
    )
}
