import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Rows } from './s'
import Kicker from 'components/typography/caption'
import Details from './details'

const SelectedPlanInformation = ({ change, swap, to, term, setTerm, currency, showDiscount = false, salt }) => {
    const detailed = swap || ['purchase', 'upgrade', 'downgrade'].includes(change)

    return (
        <Rows>
            {(detailed || ['term', 'cancel'].includes(change)) && (
                <Kicker className="compact">
                    <FormattedMessage
                        id="payment_plan_heading_selected"
                        defaultMessage="Selected plan" />
                </Kicker>
            )}
            <Details
                bundle={to}
                term={term}
                {...((!['upgrade', 'downgrade', 'term'].includes(change) && !swap) ? { setTerm } : null)}
                currency={currency}
                showDiscount={showDiscount}
                detailed={detailed}
                salt={`${salt}:details`} />
        </Rows>
    )
}

export default SelectedPlanInformation