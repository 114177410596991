import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ThemeProvider, useTheme } from 'styled-components'
import { useAccess } from 'contexts/access'
import { useUserPermissions } from 'contexts/unit-permissions'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import {
    WrapperButton, WrapperBlock,
    Symbol, Meta, Title, AddButton
} from './s'

const AbsenceType = ({ type, ...props }) => {
    let theme = useTheme()

    const { check } = useAccess()
    const userPermissions = useUserPermissions()
    const absenceAdmin = check('absence:manage') || userPermissions?.checkUnitPermission?.('user:absence:manage')

    const name = useTypeNameFormatter()(type)
    const hasAction = !!props.onClick

    const { requireApproval } = type

    theme = {
        ...theme,
        hasAction
    }

    const Wrapper = hasAction ?
        WrapperButton :
        WrapperBlock

    return (
        <ThemeProvider theme={theme}>
            <Wrapper {...props}>
                <Symbol
                    symbol={type.symbol}
                    className="hover-effect" />
                <Meta>
                    <Title className="compact">{name}</Title>
                </Meta>
                {hasAction && (
                    <AddButton>
                        <FormattedMessage
                            id={(requireApproval && !absenceAdmin) ?
                                'action_request' :
                                'action_register'
                            }
                            defaultMessage={(requireApproval && !absenceAdmin) ?
                                'Request' :
                                'Register'
                            } />
                    </AddButton>
                )}
            </Wrapper>
        </ThemeProvider>
    )
}

export default AbsenceType