import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrapper, Entry } from './s'
import Paragraph from 'components/typography/paragraph'

const OverlappingExample = ({ type = 'keep' }) => (
    <Wrapper className={type}>
        <Paragraph className="caption small compact">
            <FormattedMessage
                id="noun_example"
                defaultMessage="Example" />
        </Paragraph>
        <Entry className="old">
            <FormattedMessage
                id="absence_entry_example_old"
                defaultMessage="Registration" />
        </Entry>
        <Entry className="new">
            <FormattedMessage
                id="absence_entry_example_new"
                defaultMessage="New registration" />
        </Entry>
        <Entry className="old" />
    </Wrapper>
)

export default OverlappingExample