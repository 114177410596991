import { useState, useRef, useEffect, useCallback } from 'react'

export const useAnimation = ({ className, setup: initialSetup, teardown: initialTeardown }) => {
    const [active, setActive] = useState(false)
    const node = useRef()

    const setup = useCallback(() => {
        if(className) {
            node.current.classList.add(className)
        } else {
            initialSetup(node.current)
        }
    }, [className, node.current])

    const teardown = useCallback(() => {
        if(className) {
            node.current.classList.remove(className)
        } else {
            initialTeardown(node.current)
        }
    }, [className, node.current])

    const activate = useCallback(() => {
        node.current.addEventListener('animationend', tidy)
        setup()
    }, [active, setup])

    const tidy = useCallback(() => {
        node.current.removeEventListener('animationend', tidy)
        teardown()
        setActive(false)
    }, [active, teardown])

    useEffect(() => {
        active && activate()
    }, [active, setup, teardown])

    return [() => setActive(true), node]
}