import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { safeFormat, isofy } from 'utilities/date-time'
import { getFractionDigits } from 'pages/people/pages/profile/panes/about/salary/utilities'
import { Card, CardTitle, CardValue } from './s'
import Trend from '../trend'

const TotalSinceStart = ({ initialRecord, finalRecord, currencyDisplay }) => {
    const { dateLocale: locale } = useI18n()

    const from = isofy(initialRecord.fromDate)

    const totalDifference = finalRecord.amount - initialRecord.amount

    if(!initialRecord || !finalRecord || initialRecord?.id === finalRecord?.id || totalDifference === 0) {
        return null
    }

    const totalChange = (totalDifference / initialRecord.amount) * 100

    const increaseTrend = totalChange > 0 ? 'up' : 'down'

    return (
        <Card className={increaseTrend}>
            <CardTitle className="compact">
                <FormattedMessage
                    id="label_since_date"
                    defaultMessage="Since {date}"
                    values={{
                        date: safeFormat(from, 'PP', { locale })
                    }} />
            </CardTitle>
            <Trend
                percentage={totalChange}
                trend={increaseTrend} />
            <CardValue className="compact">
                <span className="value">
                    <FormattedNumber
                        value={totalDifference}
                        style="currency"
                        currency={initialRecord.currency}
                        currencyDisplay={currencyDisplay}
                        minimumFractionDigits={getFractionDigits(totalDifference)}
                        maximumFractionDigits={getFractionDigits(totalDifference)} />
                </span>
            </CardValue>
        </Card>
    )
}

export default TotalSinceStart
