import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useUpgradable } from 'hooks/upgradable'
import { size } from 'utilities/object'
import { ListItem } from './s'
import Form from 'components/form/controller'
import ShareOrEveryoneField from 'components/form/field/share-or-everyone'
import { unoptionize } from 'components/form/field/share'
import { UnorderedList } from 'components/list'
import UpgradeCTA from 'components/upgrade-cta'
import { Helper } from 'components/form/field/s'
import Actions from 'components/form/actions'
import { ButtonSubmit } from 'components/button'
import { Check as True } from 'styled-icons/feather'

const OneAccess = ({ form, onSubmit, adding, salt }) => {
    const { formatMessage } = useIntl()

    const { handbook } = useHandbook()

    const groupsUpgradable = useUpgradable()({ feature: 'handbook-groups' })

    const [shares, setShares] = useState(form?.shares ?? [{ type: 'organization' }])

    const { status = 'draft' } = handbook ?? {}

    return (
        <Form
            layout="vertical"
            onSubmit={body => onSubmit({
                body,
                form: { shares }
            })}>
            {({ errors, trigger }) => (
                <>
                    <ShareOrEveryoneField
                        salt={salt}
                        label={formatMessage({
                            id: 'handbooks_label_default_access',
                            defaultMessage: 'Default access'
                        })}
                        name="shares"
                        field={{
                            value: shares,
                            which: (!shares?.length || shares?.find(({ type }) => type === 'organization')) ?
                                'everyone' :
                                'shares',
                            include: 'always'
                        }}
                        picker={{ types: ['team', 'location'] }}
                        everyoneFirst={true}
                        shareLabel={formatMessage({
                            id: 'groups_members_of',
                            defaultMessage: 'Members of teams or locations'
                        })}
                        {...(groupsUpgradable ? {
                            shareHidden: true,
                            shareUpgradable: groupsUpgradable,
                            shareContent: (
                                <UpgradeCTA
                                    feature="handbook-groups"
                                    useUpgradeIcon
                                    useFeatureOrModuleIcon
                                    useFeatureDescription
                                    className="compact"
                                    salt="handbook-groups" />
                            )
                        } : null)}
                        onChange={({ shares = [], which }) => setShares((which === 'everyone') ?
                            [{ type: 'organization' }] :
                            shares.map(unoptionize)
                        )} />
                    <Helper>
                        <FormattedMessage
                            id="handbooks_setup_step_shares_helper"
                            defaultMessage="You can change access for each topic later." />
                    </Helper>
                    <UnorderedList>
                        <ListItem className="true">
                            <True size={16} />
                            <FormattedMessage
                                id="handbooks_template_add_categories_topics_boolean_admins"
                                defaultMessage="Only people with admin access to the handbook will be able to see the content before it’s published." />
                        </ListItem>
                        <ListItem className="true">
                            <True size={16} />
                            <FormattedMessage
                                id="handbooks_template_add_categories_topics_boolean_access"
                                defaultMessage="Once the handbook is published, the content will be visible to everyone selected above." />
                        </ListItem>
                    </UnorderedList>
                    <Actions>
                        <ButtonSubmit
                            className={`constructive${adding ? ' loading' : ''}`}
                            disabled={[
                                !!size(errors),
                                !shares?.length,
                                adding
                            ].some(Boolean)}
                            ref={trigger}>
                            <FormattedMessage
                                id={(status === 'draft') ?
                                    'handbooks_template_action_add' :
                                    'action_continue'
                                }
                                defaultMessage={(status === 'draft') ?
                                    'Add to handbook' :
                                    'Continue'
                                } />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default OneAccess
