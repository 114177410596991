const noop = () => {}

export const getChannel = name => {
    if(!global.BroadcastChannel) {
        return {
            close: noop,
            postMessage: noop
        }
    }

    return new global.BroadcastChannel(`huma:${name}`)
}