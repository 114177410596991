import styled from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import SymbolBase from 'components/symbol'
import { H2 } from 'components/typography/heading'
import { Plain } from 'components/button'
import PersonBase from 'components/person'
import { Columns, FlexChildShrink } from 'components/flex'
import { InlineMessage as InlineMessageBase } from 'components/message'
import Paragraph from 'components/typography/paragraph'
import { Grid } from 'components/grid'
import ContextMenuBase from 'widgets/context-menu/flat'
import { CaptionSmall } from 'components/typography/caption'
import MetaBase from 'components/meta'

export const Symbol = styled(SymbolBase)`
    margin-block-end: 16px;
    width: 56px;
    height: 56px;

    font-size: 26px;
`

export const Hero = styled.div`
    padding-block-end: 24px;

    ${belowTablet`
        padding-block-end: 16px;
    `}
`

export const Heading = styled(H2)`
    margin-block-end: 16px;
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

export const Person = styled(PersonBase)`
    margin-block-end: 24px;
`

export const Note = styled(Paragraph)`
    align-self: flex-start;

    margin-block-end: 24px;
    border-radius: 0 16px 16px 16px;
    padding: 8px 16px;
    background-color: var(--huma-color-surface-minimal);

    ${Person} + & {
        margin-top: -8px;
    }
`

export const DateColumns = styled(Columns)`
    align-items: center;

    margin-block-end: 24px;

    svg {
        ${svgStroke()}
    }
`

export const ArrowColumn = styled(FlexChildShrink)`
    margin-inline-end: 24px;
`

export const Expired = styled.span`
    color: var(--huma-color-foreground-destructive);
`

export const Label = styled(Paragraph)`
    color: var(--huma-color-foreground-subtle);
`

export const Section = styled(Grid)`
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-template-columns: 1fr auto;

    border-top: 1px solid var(--huma-color-border-default);
    padding-block: 16px;

    > div {
        display: flex;
        column-gap: 16px;
    }
`

export const CompactButton = styled(Plain)`
    height: auto;
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-block-end: 24px;
`

export const ContextMenu = styled(ContextMenuBase)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    overflow: hidden;
`

export const Caption = styled(CaptionSmall)`
    margin: 8px 0 0;
`

export const Meta = styled(MetaBase)`
    margin-block-start: 24px;
`