import React from 'react'
import View from './view'
import Edit from './edit'
import ManageDocumentation from './manage-documentation'

const ActingPiece = ({ mode = 'view', ...props }) => {
    const Piece = {
        view: View,
        edit: Edit,
        documentation: ManageDocumentation
    }[mode]

    return (
        <Piece
            {...props}
            provide={!!props.piece?.attachments?.length}
            provideType={!!props?.provideType}
            pieceId={props.piece?.id} />
    )
}

export default ActingPiece
