import React from 'react'
import { useLocation } from 'react-router-dom'
import { compact } from 'utilities/array'
import { PageErrorBoundary } from 'components/error-boundary'

export default ({ children }) => {
    const location = useLocation()

    const [section] = compact(location.pathname.split('/'))

    return (
        <PageErrorBoundary key={`section:${section}`}>
            {children}
        </PageErrorBoundary>
    )
}