import styled from 'styled-components'
import Caption from 'components/typography/caption'
import { h3Css } from 'components/typography/heading'

export const Heading = styled(Caption)`
    margin-block-end: 16px;

    .no-news & {
        ${h3Css}

        color: var(--huma-color-foreground-default);
    }
`