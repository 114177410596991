import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { anyFieldsDisplayable } from 'utilities/access'
import { size } from 'utilities/object'
import { getFullName } from 'utilities/person'
import { isFieldImportant } from 'pages/people/pages/profile/panes/about'
import { Hero, For, PickerMeta, Message } from './s'
import { H2 as Heading } from 'components/typography/heading'
import Person from 'components/person'
import Form from 'components/form/controller'
import PersonField from 'components/form/field/person'
import SupervisorResponsibility from './supervisor-responsibility'
import Error from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const EditSupervisor = ({ onDone, dismiss, lookingAtMyOwnProfile, salt }) => {
    const { formatMessage } = useIntl()

    const {
        person,
        updatePerson
    } = usePerson()

    const {
        supervisor
    } = person

    const fields = [
        supervisor
    ]

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    const [initialSupervisor, updateInitialSupervisor] = useState(supervisor?.value)
    const [pickedSupervisor, setPickedSupervisor] = useState(null)

    const [saving, setSaving] = useState(false)
    const [errors, setErrors] = useState({})

    const update = async (update, { reset }) => {
        setSaving(true)
        const { ok, response } = await updatePerson(update)
        setSaving(false)

        if(ok) {
            updateInitialSupervisor(person.supervisor?.value ?? null)
            reset()
            onDone()

            !!onboarder && updateOnboardingStatus({ assignSupervisor: 'completed' })
        } else {
            if(response?.errorCode === 'field:conflict' && response?.fields?.includes('supervisor')) {
                setErrors({
                    supervisor: formatMessage({
                        id: 'supervisor_circular_error',
                        defaultMessage: 'Picking {person} would cause a circular chain of supervisors, which Huma doesn’t allow. Pick another person or change the hierarchy.'
                    }, { person: getFullName(pickedSupervisor) })
                })
            }
        }
    }

    const anyDisplayable = anyFieldsDisplayable(fields)
    if(!anyDisplayable) {
        return null
    }

    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    return (
        <>
            <Hero>
                <Heading>
                    <FormattedMessage
                        id={!!initialSupervisor ?
                            'supervisor_action_change' :
                            'supervisor_action_assign'
                        }
                        defaultMessage={!!initialSupervisor ?
                            'Change supervisor' :
                            'Assign supervisor'
                        } />
                </Heading>
                <For className="compact">
                    <FormattedMessage
                        id="for_name"
                        defaultMessage="for {name}"
                        values={{
                            name: <span>{getFullName(person)}</span>
                        }} />
                </For>
            </Hero>
            <Form
                onSubmit={update}
                layout="vertical">
                {({ touched, trigger }) => (
                    <>
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_supervisor',
                                defaultMessage: 'Supervisor'
                            })}
                            name="supervisor"
                            field={{
                                ...supervisor,
                                ...(!!supervisor?.editable && {
                                    editable: true
                                })
                            }}
                            toggler={{
                                onClick: () => setErrors({})
                            }}
                            picker={{
                                heading: formatMessage({
                                    id: 'supervisor_action_pick',
                                    defaultMessage: 'Pick supervisor'
                                }),
                                heroContent: (
                                    <PickerMeta>
                                        <Person who={person} />
                                        <Message
                                            type="info"
                                            title={formatMessage({
                                                id: 'supervisor_circular_explainer_title',
                                                defaultMessage: 'No circular chains of supervisors'
                                            })}
                                            message={formatMessage({
                                                id: 'supervisor_circular_explainer',
                                                defaultMessage: 'Huma doesn’t allow circular chains of supervisors. People supervised by {person} – directly or indirectly – are therefore not available here.'
                                            }, { person: getFullName(person) })}
                                            name={`${salt}:supervisor:circular`} />
                                    </PickerMeta>
                                )
                            }}
                            entity={{
                                params: {
                                    notRecursivelySubordinateOf: person?.id
                                }
                            }}
                            controlProps={{ autoFocus: true }}
                            enabled={!saving}
                            onChange={({ supervisor }) => setPickedSupervisor(supervisor)}
                            {...isImportant('supervisor')} />
                        <SupervisorResponsibility />
                        {('supervisor' in errors) && (
                            <Error
                                type="error"
                                message={errors?.supervisor} />
                        )}
                        <Actions className="compact">
                            <Plain onClick={dismiss ?? onDone}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${saving ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors) || saving}
                                ref={trigger}>
                                <FormattedMessage
                                    id={!!initialSupervisor ?
                                        'action_change' :
                                        'action_assign'
                                    }
                                    defaultMessage={!!initialSupervisor ?
                                        'Change' :
                                        'Assign'
                                    } />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default EditSupervisor