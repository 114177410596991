import React, { useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { capitalize } from 'utilities/string'
import { getShortName } from 'utilities/person'
import { getPeopleProfileUrl, getPeopleProfileDocumentsUrl } from 'utilities/url'
import { Message, ActionsList, Action } from './s'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import Link from 'components/link'
import Actions from 'components/form/actions'
import { Plain } from 'components/button'
import { User, File, ArrowRight } from 'styled-icons/feather'

const Success = ({ user, type, dismiss }) => {
    const { formatMessage } = useIntl()

    const { environment } = useEnvironment()

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    useEffect(() => {
        !!onboarder && updateOnboardingStatus({ [`start${capitalize(type)}`]: 'completed' })
    }, [onboarder])

    const successMessageTranslation = getSuccessMessageTranslation(type)

    return (
        <>
            <Message
                type="success"
                message={formatMessage(successMessageTranslation, {
                    name: getShortName(user),

                    // TODO: Where should this link go?
                    link: chunks => (
                        <Link
                            href={`https://www.${environment.tld}/`}
                            target="_blank">
                            {chunks}
                        </Link>
                    )
                })} />
            {(type === 'onboarding') && (
                <>
                    <Paragraph className="compact">
                        <FormattedMessage
                            id="employee_onboarding_success_caption"
                            defaultMessage="Things to do next:" />
                    </Paragraph>
                    <ActionsList>
                        <Action to={getPeopleProfileUrl(user)}>
                            <User size={24} />
                            <div>
                                <Paragraph className="compact">
                                    <FormattedMessage
                                        id="employee_onboarding_action_update_profile"
                                        defaultMessage="Update {name}’s profile"
                                        values={{ name: getShortName(user) }} />
                                </Paragraph>
                            </div>
                            <ArrowRight size={24} />
                        </Action>
                        <Action to={getPeopleProfileDocumentsUrl(user)}>
                            <File size={24} />
                            <div>
                                <Paragraph className="compact">
                                    <FormattedMessage
                                        id="employee_onboarding_action_upload_documents"
                                        defaultMessage="Upload documents" />
                                </Paragraph>
                                <CaptionSmall className="compact">
                                    <FormattedMessage
                                        id="employee_onboarding_action_helper_upload_documents"
                                        defaultMessage="E.g. employee contract" />
                                </CaptionSmall>
                            </div>
                            <ArrowRight size={24} />
                        </Action>
                    </ActionsList>
                </>
            )}
            <Actions className="centered">
                <Plain onClick={dismiss}>
                    <FormattedMessage
                        id="action_done"
                        defaultMessage="Done" />
                </Plain>
            </Actions>
        </>
    )
}

const getSuccessMessageTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_message_onboarding_started',
        defaultMessage: '{name}’s onboarding has started and you will find the progress in the overview.\n\n💡 We have a lot of tips and tricks regarding onboarding on <link>humahr.com<\/link>'
    },
    offboarding: {
        id: 'employee_offboarding_message_onboarding_started',
        defaultMessage: '{name}’s offboarding has started and you will find the progress in the overview.\n\n💡 We have a lot of tips and tricks regarding offboarding on <link>humahr.com<\/link>'
    },
    process: {
        id: 'process_message_start_success',
        defaultMessage: '{name}’s process has started and you can track its progress in the overview.\n\n💡 We have a lot of tips and tricks regarding processes on <link>humahr.com<\/link>'
    }
})[type]

export default Success