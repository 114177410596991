import React from 'react'
import { StyledIconBase } from '@styled-icons/styled-icon'
import TwentyfourSevenOfficeLogo from 'static/gfx/integrations/icon/24sevenoffice.csvg'
import AltinnLogo from 'static/gfx/integrations/icon/altinn.csvg'
import EntraIdLogo from 'static/gfx/integrations/icon/entra-id.csvg'
import CronaLonLogo from 'static/gfx/integrations/icon/crona-lon.csvg'
import FortnoxLogo from 'static/gfx/integrations/icon/fortnox.csvg'
import NetvisorLogo from 'static/gfx/integrations/icon/netvisor.csvg'
import NuuLogo from 'static/gfx/integrations/icon/nuu.csvg'
import PowerOfficeLogo from 'static/gfx/integrations/icon/poweroffice.csvg'
import SlackLogo from 'static/gfx/integrations/icon/slack.csvg'
import TeamtailorLogo from 'static/gfx/integrations/icon/teamtailor.csvg'
import TripletexLogo from 'static/gfx/integrations/icon/tripletex.csvg'
import VismaLogo from 'static/gfx/integrations/icon/visma.csvg'
import WebhookLogo from 'static/gfx/integrations/icon/webhook.csvg'
import XledgerLogo from 'static/gfx/integrations/icon/xledger.csvg'

export const Custom = ({ svg, size = 24, className, ...props }) => {
    const { props: svgProps } = svg()
    const { children, ...attrs } = svgProps

    return (
        <StyledIconBase
            width={size}
            height={size}
            iconVerticalAlign="middle"
            {...attrs}
            {...props}
            {...(className ? { className } : null)}>
            {children}
        </StyledIconBase>
    )
}

export const TwentyfourSevenOfficeIcon = props => (
    <Custom
        {...props}
        svg={TwentyfourSevenOfficeLogo} />
)

export const AltinnIcon = props => (
    <Custom
        {...props}
        svg={AltinnLogo} />
)

export const EntraIdIcon = props => (
    <Custom
        {...props}
        svg={EntraIdLogo} />
)

export const CronaLonIcon = props => (
    <Custom
        {...props}
        svg={CronaLonLogo} />
)

export const FortnoxIcon = props => (
    <Custom
        {...props}
        svg={FortnoxLogo} />
)

export const NetvisorIcon = props => (
    <Custom
        {...props}
        svg={NetvisorLogo} />
)

export const NuuIcon = props => (
    <Custom
        {...props}
        svg={NuuLogo} />
)

export const PowerOfficeIcon = props => (
    <Custom
        {...props}
        svg={PowerOfficeLogo} />
)

export const SlackIcon = props => (
    <Custom
        {...props}
        svg={SlackLogo} />
)

export const TeamtailorIcon = props => (
    <Custom
        {...props}
        svg={TeamtailorLogo} />
)

export const TripletexIcon = props => (
    <Custom
        {...props}
        svg={TripletexLogo} />
)

export const VismaIcon = props => (
    <Custom
        {...props}
        svg={VismaLogo} />
)

export const WebhookIcon = props => (
    <Custom
        {...props}
        svg={WebhookLogo} />
)

export const XledgerIcon = props => (
    <Custom
        {...props}
        svg={XledgerLogo} />
)