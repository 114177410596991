import React from 'react'
import { Symbol, Heading } from './s'
import { CloseButton } from 'components/button'

const ViewEquipmentPieceHero = ({ type, dismiss }) => (
    <>
        <Symbol symbol={type.symbol} />
        <Heading>
            {type.name}
        </Heading>
        <CloseButton onClick={dismiss} />
    </>
)

export default ViewEquipmentPieceHero
