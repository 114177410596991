import styled from 'styled-components'
import { Columns, FlexChildShrink } from 'components/flex'
import { Ghost } from 'components/button'

export const Pills = styled(Columns)`
    justify-content: flex-start;
    flex-wrap: wrap;

    margin-block: 8px 16px;
    width: calc(100% + 8px);
`

export const PillCoating = styled(FlexChildShrink)`
    margin-inline-end: 4px;
    margin-bottom: 4px;
`

export const Pill = styled(Ghost)`
    border-radius: 4px;
    border-color: transparent;
    height: 28px;
    padding: 2px 8px 0;
    background-color: rgb(from var(--huma-color-surface-neutral-bold) r g b / .02);

    font-size: 14px;
    color: var(--huma-color-foreground-subtle);

    &:where(:hover, :focus) {
        border-color: var(--huma-color-border-subtle-hover);
        background-color: rgb(from var(--huma-color-surface-neutral-bold) r g b / .01);
        box-shadow: none;

        color: var(--huma-color-foreground-default);
    }

    &.active {
        border-color: var(--huma-color-border-default-focus);

        color: var(--huma-color-foreground-default);
    }
`