import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'contexts/auth'
import { useEnvironment } from 'contexts/environment'
import {
    Main, Blob, ContentContainer, Content,
    Footer, FooterStart, FooterEnd,
    LocalePicker
} from './s'
import Link from 'components/link'

const PublicLayout = ({ children }) => {
    const { native: { platform } } = useAuth()
    const { environment } = useEnvironment()

    useEffect(() => {
        document.documentElement.setAttribute('data-authorized', 'no')
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffb64b')

        if(platform !== 'web') {
            document.documentElement.setAttribute('data-native', platform)
        }

        return () => document.documentElement.removeAttribute('data-native')
    }, [])

    return (
        <Main>
            <Blob />
            <ContentContainer>
                <Content>
                    {children}
                </Content>
            </ContentContainer>
            <Footer>
                <FooterStart className={platform}>
                    {(platform === 'web') && (
                        <LocalePicker className="compact" />
                    )}
                </FooterStart>
                <FooterEnd>
                    <Link
                        href={`https://www.${environment.tld}/knowledge`}
                        target="_blank">
                        <FormattedMessage
                            id="noun_help"
                            defaultMessage="Help" />
                    </Link>
                </FooterEnd>
            </Footer>
        </Main>
    )
}

export default PublicLayout