import React from 'react'
import { useArticle } from 'contexts/article'
import { usePublicOption } from 'hooks/public-option'
import Content from './content'

const ArticleMeta = ({ showSettings, setShowSettings }) => {
    const {
        article,
        editable
    } = useArticle()

    const publicOption = usePublicOption(true)
    if(!publicOption) {
        return null
    }

    return (
        <Content
            article={article}
            editable={editable}
            publicOption={publicOption}
            showSettings={showSettings}
            setShowSettings={setShowSettings} />
    )
}

export default ArticleMeta