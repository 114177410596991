import styled from 'styled-components'
import { Grid } from 'components/grid'
import { TemplateList } from 'pages/processes/components/template-picker/s'
import { captionSmallCss } from 'components/typography/paragraph'
import MetaBase from 'components/meta'

export const Wrapper = styled(Grid)`
    grid-template-columns: minmax(0, 1fr) min-content;
    row-gap: 8px;
    column-gap: 16px;
    align-items: center;

    ${TemplateList} > & {
        padding: 14px 0;

        &:not(:last-of-type) {
            box-shadow: 0 1px var(--huma-color-border-default);
        }
    }

    *:not(${TemplateList}):not(.flat) > & {
        border: 1px solid var(--huma-color-border-default);
        border-radius: 8px;
        padding: 10px 16px;
    }
`

export const Meta = styled(MetaBase)`
    grid-row: 2;
    grid-column: 1 / 3;

    margin-inline-start: 56px;
`

export const Subheading = styled.span`
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    ${captionSmallCss}
`