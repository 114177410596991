import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { getFullName } from 'utilities/person'
import { cls } from 'utilities/dom'
import { UserItem, Avatar, Name, Email, Meta, InvitedAt } from './s'
import { Columns, FlexChildGrow, FlexChildShrink } from 'components/flex'
import Ellipsify from 'components/ellipsify'
import { StatusDot } from 'pages/people/panes/everyone/invite/invite-users-content/status-dot'
import RelativeTime from 'components/time-relative'

const User = ({ user, reason }) => {
    const { formatMessage } = useIntl()

    const name = getFullName(user)

    const statusDotClassName = cls([
        !user.invitedAt && 'never-invited',
        (!!user.invitedAt && reason === 'invited') && 'recently-invited',
        (!!user.invitedAt && reason === 'invitee') && 'will-be-invited'
    ])

    return (
        <UserItem>
            <Columns>
                <FlexChildShrink>
                    <Avatar
                        who={user}
                        size={40} />
                </FlexChildShrink>
                <FlexChildGrow>
                    <Name>{name}</Name>
                    <Email
                        title={user.email}
                        className="compact">
                        <Ellipsify
                            text={user.email}
                            tail={10} />
                    </Email>
                    <Meta className="compact">
                        <StatusDot className={statusDotClassName} />
                        {!user.invitedAt && (
                            <FormattedMessage
                                id="people_everyone_invite_invitee_status_awaiting_invite"
                                defaultMessage="Waiting to be invited" />
                        )}
                        {!!user.invitedAt && (
                            <InvitedAt>
                                <span>
                                    <FormattedMessage
                                        id="people_everyone_invite_invitee_status_invited"
                                        defaultMessage="Invited" />
                                </span>
                                <span>
                                    <RelativeTime
                                        date={user.invitedAt}
                                        format={formatMessage({
                                            id: 'date_fns_format_full_friendly',
                                            defaultMessage: `PPP 'at' p`
                                        })} />
                                </span>
                            </InvitedAt>
                        )}
                    </Meta>
                </FlexChildGrow>
            </Columns>
        </UserItem>
    )
}

export default User