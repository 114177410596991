import React from 'react'
import { FormattedMessage } from 'react-intl'
import { decimalToPercent } from 'utilities/math'
import { Pills, PillCoating, Pill } from './s'

const GradeShortcuts = ({ setGradePercentage, gradeValue, setGradeValue, enabled, triggerChange, salt }) => {
    const shortcuts = [
        1,
        0.75,
        0.5,
        0.25
    ]

    return (
        <Pills>
            {shortcuts.map(shortcut => {
                const active = shortcut === gradeValue

                return (
                    <PillCoating key={`${salt}:shortcut:${shortcut}`}>
                        <Pill
                            className={`neutral${active ? ' active' : ''}`}
                            disabled={!enabled}
                            onClick={() => {
                                setGradeValue(shortcut)
                                setGradePercentage(decimalToPercent(shortcut))
                                global.setTimeout(() => triggerChange('grade'), 50) // Let grade remount due to key change
                            }}>
                            <FormattedMessage
                                id="value_percent"
                                defaultMessage="{percent} %"
                                values={{ percent: decimalToPercent(shortcut) }} />
                        </Pill>
                    </PillCoating>
                )
            })}
        </Pills>
    )
}

export default GradeShortcuts
