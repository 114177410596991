import { useState } from 'react'
import { local, session } from 'utilities/storage'

export const useStorage = ({
    key,
    defaultValue,
    type = 'local',
    merge = false,
    preferDefaultValue = false,
    enabled = true
}) => {
    const storage = (type === 'local') ?
        local :
        session

    if(typeof preferDefaultValue === 'function') {
        preferDefaultValue = preferDefaultValue()
    }

    const [storedValue, setStoredValue] = useState(() => {
        if(!enabled) {
            return defaultValue
        }

        if(merge) {
            return {
                ...(preferDefaultValue ? null : defaultValue),
                ...storage.get(key),
                ...(preferDefaultValue ? defaultValue : null)
            }
        }

        return preferDefaultValue ?
            defaultValue :
            (storage.get(key) ?? defaultValue)
    })

    if(!enabled) {
        return [
            storedValue,
            setStoredValue
        ]
    }

    const setValue = value => {
        if(typeof value === 'function') {
            value = value(storage.get(key))
        }

        setStoredValue(value)
        storage.set(key, value)
    }

    return [
        storedValue,
        setValue
    ]
}