import React, { createContext, Component, useContext } from 'react'
import { requestAccess } from 'utilities/auth'
import { get } from 'api'
import { useAccess } from 'contexts/access'
import { pick } from 'utilities/object'
import debounce from 'lodash.debounce'

const UnitPermissionsContext = createContext()
const UserPermissionsContext = createContext()

class PermissionsProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500 })

        this.state = {
            type: getAllowedUnitType(props),
            id: props.id,
            permissions: [],

            fetchUnitPermissions: this.fetch,
            checkUnitPermission: this.check,
            checkSpecificUnitPermission: this.checkSpecificUnitPermission,
            checkOrganizationUnitPermission: this.checkOrganizationUnitPermission,
            checkAll: this.checkAll,
            setUnitId: this.setId,

            fetching: false,
            hasFetched: false
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetchDebounced()
        }
    }

    componentDidUpdate(props) {
        const idChanged = props.id !== this.props.id

        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        this.setState(idChanged ? { id: this.props.id } : null, () => {
            if((idChanged && fetchAccess) || fetchAccessGained) {
                this.fetchDebounced({ force: true })
            }
        })
    }

    fetch = async (options = {}) => {
        const {
            unit = pick(this.state, 'type', 'id'),
            force = false,
            persist = true
        } = options

        const { ok: tokensOk, response: tokens } = await requestAccess({ bounce: false })
        if(!tokensOk || !tokens?.accessToken) {
            return
        }

        if(persist && this.state.fetching && !force) {
            return
        }

        if(!unit?.type || !unit?.id) {
            this.setState({ permissions: [] })
            return { ok: false, response: [] }
        }

        if(persist && force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        persist && this.setState({ fetching: true })

        const { ok, response: permissions } = await get({
            path: `/${getAllowedUnitType(unit)}s/${unit.id}/permissions`,
            ...(persist ? { signal: this.fetchController.signal } : null)
        })

        persist && this.setState({
            permissions: ok ? permissions : [],
            fetching: false,
            hasFetched: true
        })

        return { ok, response: permissions ?? [] }
    }

    check = key => {
        if(!key || typeof key !== 'string' || !this.state.permissions?.length) {
            return false
        }

        return !!this.state.permissions.includes(key)
    }

    checkSpecificUnitPermission = async ({ key, unit, onDone }) => {
        if(!key || typeof key !== 'string' || !key.startsWith('unit:') || !unit?.type || !unit?.id) {
            onDone?.(false)
            return false
        }

        const { ok, response: permissions } = await this.fetch({ unit, persist: false })

        const result = ok && !!permissions?.includes(key)
        onDone?.(result)

        return result
    }

    checkOrganizationUnitPermission = key => {
        if(!key || typeof key !== 'string' || !key.startsWith('unit:')) {
            return false
        }

        return this.props.access.organizationWideUnit.includes(key)
    }

    checkAll = ({ system, unit }) => {
        const systemAccess = this.props.access.check(system)
        const organizationUnitAccess = this.checkOrganizationUnitPermission(unit)
        const specificUnitAccess = this.check(unit)

        return [systemAccess || organizationUnitAccess || specificUnitAccess, {
            general: systemAccess || organizationUnitAccess,
            individual: specificUnitAccess
        }]
    }

    setId = (id, fetch = true) => {
        if(!id) {
            return void this.setState({
                permissions: [],
                hasFetched: false
            })
        }

        this.setState({
            id,
            hasFetched: false
        }, () => {
            fetch && this.fetch({ force: true })
        })
    }

    render() {
        const Context = (this.props.type === 'user') ?
            UserPermissionsContext :
            UnitPermissionsContext

        const { children = null } = this.props

        return (
            <Context.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </Context.Provider>
        )
    }
}

const getAllowedUnitType = unit => ({
    user: 'user',
})[unit?.type] ?? 'unit'

export const UnitPermissionsProvider = props => {
    const access = useAccess()

    return (
        <PermissionsProvider
            {...props}
            access={access} />
    )
}

export const UserPermissionsProvider = props => {
    const access = useAccess()

    return (
        <PermissionsProvider
            {...props}
            access={access}
            type="user" />
    )
}

export const useUnitPermissions = () => useContext(UnitPermissionsContext)
export const useUserPermissions = () => useContext(UserPermissionsContext)