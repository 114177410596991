import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { usePerson } from 'contexts/person'
import { useData } from 'contexts/data'
import { safeFormat } from 'utilities/date-time'
import { Wrapper, Label, DefinitionDescription, Plain } from './s'
import { DefinitionList, DefinitionTerm } from 'components/list'
import Divider from 'components/divider'

const StartProcess = ({ type, date, withinQuota }) => {
    const { dateLocale: locale } = useI18n()

    const { person } = usePerson()
    const { setData } = useData()

    if(type === 'onboarding') {
        return (
            <Wrapper>
                <DefinitionList>
                    <DefinitionTerm>
                        <FormattedMessage
                            id="person_label_employment_date_start"
                            defaultMessage="First day of work" />
                    </DefinitionTerm>
                    <DefinitionDescription>
                        {!date && (
                            <FormattedMessage
                                id="empty_not_set"
                                defaultMessage="Not set" />
                        )}
                        {!!date && safeFormat(date, 'PP', { locale })}
                    </DefinitionDescription>
                </DefinitionList>
                {!!withinQuota && (
                    <>
                        <Divider />
                        <Plain
                            className="constructive compact"
                            onClick={() => setData({
                                process: {
                                    onboarding: {
                                        type: 'predefined',
                                        concernsId: person.id
                                    }
                                }
                            })}>
                            <FormattedMessage
                                id="employee_onboarding_action_start"
                                defaultMessage="Start onboarding" />
                        </Plain>
                    </>
                )}
            </Wrapper>
        )
    }

    if(type === 'offboarding') {
        return (
            <Wrapper>
                <Label>
                    <FormattedMessage
                        id="person_status_resigned"
                        defaultMessage="Has resigned" />
                </Label>
                <DefinitionList>
                    <DefinitionTerm>
                        <FormattedMessage
                            id="person_label_employment_date_end"
                            defaultMessage="Last day of work" />
                    </DefinitionTerm>
                    <DefinitionDescription>
                        {safeFormat(date, 'PP', { locale })}
                    </DefinitionDescription>
                </DefinitionList>
                {!!withinQuota && (
                    <>
                        <Divider />
                        <Plain
                            className="constructive compact"
                            onClick={() => setData({
                                process: {
                                    offboarding: {
                                        type: 'predefined',
                                        concernsId: person.id
                                    }
                                }
                            })}>
                            <FormattedMessage
                                id="employee_offboarding_action_start_short"
                                defaultMessage="Start offboarding" />
                        </Plain>
                    </>
                )}
            </Wrapper>
        )
    }

    return null
}

export default StartProcess