import React, { useState, useEffect, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useAccess } from 'contexts/access'
import { useI18n } from 'contexts/i18n'
import { post } from 'api'
import { sendAppSignal } from 'hooks/app-signal'
import { anyFieldsDisplayable, anyFieldsEditable, fieldDisplayable } from 'utilities/access'
import { size, omit } from 'utilities/object'
import { getEmailAddress } from 'utilities/person'
import { cls } from 'utilities/dom'
import { SectionHero, isFieldImportant } from '../'
import { Container, Heading, Spacer, Error, Message } from '../s'
import { FieldHelper } from './s'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import PhoneNumberField from 'components/form/field/phone-number'
import TextField from 'components/form/field/text'
import ArrayField from 'components/form/field/array'
import TimeField from 'components/form/field/time'
import { Ghost, ButtonSubmit, InlineButton } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import ConfirmEmailAddressChange from './confirm-email-address-change'
import CustomFields, { getCustomFieldsBySection } from '../custom-fields'

const IntroSection = ({ lookingAtMyOwnProfile, layout, salt }) => {
    const { formatMessage } = useIntl()

    const {
        person,
        updatePerson
    } = usePerson()

    const { checkFeature } = useAccess()
    const jubileesAvailable = checkFeature('jubilees')

    const { settings } = useI18n()
    const { showBirthday } = settings

    const getCustomFields = useCallback(
        () => getCustomFieldsBySection(person, 'intro'),
        [Object.values(person?.custom ?? {})?.map(({ value }) => value).join('+'), person.status.active]
    )

    const [editing, setEditing] = useState(false)
    const [confirmation, setConfirmation] = useState(null)
    const [saving, setSaving] = useState(false)
    const [customErrors, setCustomErrors] = useState(null)
    const [resendingVerificationEmail, setResendingVerificationEmail] = useState(false)
    const [hasResentVerificationEmail, setHasResentVerificationEmail] = useState(false)

    useEffect(() => {
        if(!editing) {
            setCustomErrors(null)
        }
    }, [editing])

    const update = async (_, { nested: body, resetTouched }) => {
        if(body?.email && body.email.toLowerCase() !== getEmailAddress(person).toLowerCase()) {
            const confirmed = await new Promise(resolve => {
                setConfirmation({
                    email: body.email,
                    resolve: response => {
                        resolve(response)
                        setConfirmation(null)
                    }
                })
            })

            if(!confirmed) {
                return
            }
        }

        setSaving(true)
        const { ok, response } = await updatePerson(body)
        setSaving(false)

        if(ok) {
            resetTouched()
            setEditing(false)
        } else {
            if(response?.errorCode === 'field:invalid-format') {
                const invalidFormatErrors = {}

                if(response?.fields?.includes('email')) {
                    invalidFormatErrors.email = formatMessage({
                        id: 'email_error_invalid',
                        defaultMessage: 'Invalid email address'
                    })
                }

                if(response?.fields?.includes('phone')) {
                    invalidFormatErrors.phone = formatMessage({
                        id: 'phone_error_invalid',
                        defaultMessage: 'Invalid phone number'
                    })
                }

                setCustomErrors(invalidFormatErrors)
            } else if(response?.errorCode === 'identifier:duplicate') {
                const duplicateErrors = {}

                if(response?.fields?.includes('email')) {
                    duplicateErrors.email = formatMessage({
                        id: 'email_error_already_exists',
                        defaultMessage: 'Email address already in use'
                    })
                }

                if(response?.fields?.includes('phone')) {
                    duplicateErrors.phone = formatMessage({
                        id: 'phone_error_already_exists',
                        defaultMessage: 'Phone number already in use'
                    })
                }

                setCustomErrors(duplicateErrors)
            }
        }
    }

    const resendVerificationEmail = async () => {
        setResendingVerificationEmail(true)

        const { ok } = await post({
            path: `/users/${person.id}/resend-verify-email`,
            returnsData: false
        })

        setResendingVerificationEmail(false)

        if(ok) {
            setHasResentVerificationEmail(true)
        }
    }

    const removeCustomError = key => setCustomErrors(errors => {
        if(errors) {
            return omit(errors, key)
        }

        return errors
    })

    const {
        email,
        phone,
        interests,
        funfacts,
        birthDate
    } = person

    const customFields = getCustomFields()

    const fields = [
        email,
        phone,
        interests,
        funfacts,
        birthDate,
        ...customFields
    ]

    const anyDisplayable = anyFieldsDisplayable(fields)
    if(!anyDisplayable) {
        return null
    }

    const anyEditable = anyFieldsEditable(fields)

    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    const birthdayFieldHelper = !(showBirthday === true || showBirthday === false) ?
        {
            id: 'jubilees_message_birthday_hidden',
            defaultMessage: 'Only administrators can see your birthday by default. You can change this under <button>User account settings</button>.'
        }
        : !!showBirthday ?
            {
                id: 'jubilees_birthday_option_visible_description',
                defaultMessage: 'Your birthday is visible to everyone on the dashboard.'
            }
            : {
                id: 'jubilees_birthday_option_hidden_description',
                defaultMessage: 'Your birthday is only visible to you and administrators.'
            }

    return (
        <>
            <Form
                layout={layout}
                onSubmit={update}>
                {({ touched, errors, trigger }) => (
                    <Container id="intro">
                        <SectionHero
                            editable={anyEditable}
                            editing={editing}
                            toggleEditing={setEditing}>
                            <Heading>
                                <FormattedMessage
                                    id="person_pane_about_section_intro"
                                    defaultMessage="Intro" />
                            </Heading>
                            <Spacer />
                            {!!editing && (
                                <ButtonSubmit
                                    className={`constructive${saving ? ' loading' : ''}`}
                                    disabled={!touched.length || !!size(errors) || saving}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id="action_save"
                                        defaultMessage="Save" />
                                </ButtonSubmit>
                            )}
                        </SectionHero>
                        {fieldDisplayable(email) && (
                            <>
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_emailaddress',
                                        defaultMessage: 'Email address'
                                    })}
                                    name="email"
                                    field={{
                                        ...email,
                                        ...(!!email?.editable && {
                                            editable: editing
                                        })
                                    }}
                                    controlProps={{
                                        type: 'email',
                                        autoFocus: true,
                                        autoComplete: 'email',
                                        'data-1p-ignore': 'false'
                                    }}
                                    onChange={() => removeCustomError('email')}
                                    {...isImportant('email')} />
                                <Error animate={!!customErrors?.email ? 'reveal' : 'hide'}>
                                    {customErrors?.email}
                                </Error>
                                {(!!email?.editable && !!email?.requestedValue) && (
                                    <>
                                        <Message
                                            type="warning"
                                            className={layout}
                                            message={formatMessage({
                                                id: 'person_emailaddress_change_information',
                                                defaultMessage: 'A confirmation email has been sent to the new address <strong>{new}</strong>. We will continue to use the old address <strong>{old}</strong> until the new one is confirmed.'
                                            }, {
                                                old: email.value,
                                                new: email.requestedValue
                                            })}>
                                            {!hasResentVerificationEmail && (
                                                <Ghost
                                                    className={cls('constructive', resendingVerificationEmail && 'loading')}
                                                    onClick={resendVerificationEmail}>
                                                    <FormattedMessage
                                                        id="person_emailaddress_action_resend_confirm"
                                                        defaultMessage="Resend confirmation email" />
                                                </Ghost>
                                            )}
                                        </Message>
                                        {hasResentVerificationEmail && (
                                            <Message
                                                type="success"
                                                className={layout}
                                                message={formatMessage({
                                                    id: 'person_emailaddress_change_information_resent',
                                                    defaultMessage: 'A new confirmation email has been sent to <strong>{email}</strong>.'
                                                }, { email: email.requestedValue })} />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {fieldDisplayable(phone) && (
                            <>
                                <PhoneNumberField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_phonenumber',
                                        defaultMessage: 'Phone number'
                                    })}
                                    name="phone"
                                    field={{
                                        ...phone,
                                        ...(!!phone?.editable && {
                                            editable: editing,
                                            errorBeforeTouched: true
                                        })
                                    }}
                                    controlProps={{
                                        autoComplete: 'tel',
                                        'data-1p-ignore': 'false'
                                    }}
                                    {...isImportant('phone')} />
                                <Error animate={!!customErrors?.phone ? 'reveal' : 'hide'}>
                                    {customErrors?.phone}
                                </Error>
                            </>
                        )}
                        {fieldDisplayable(interests) && (
                            <ArrayField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_interests',
                                    defaultMessage: 'Interests'
                                })}
                                name="interests"
                                field={{
                                    ...interests,
                                    ...(!!interests?.editable && {
                                        editable: editing
                                    })
                                }}
                                {...isImportant('interests')} />
                        )}
                        {fieldDisplayable(funfacts) && (
                            <TextField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_funfacts',
                                    defaultMessage: 'Fun facts'
                                })}
                                name="funfacts"
                                field={{
                                    ...funfacts,
                                    ...(!!funfacts?.editable && {
                                        editable: editing
                                    })
                                }}
                                {...isImportant('funfacts')} />
                        )}
                        {fieldDisplayable(birthDate) && (
                            <>
                                <TimeField
                                    simple={true}
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_birthdate',
                                        defaultMessage: 'Date of birth'
                                    })}
                                    name="birthDate"
                                    field={{
                                        ...birthDate,
                                        ...(!!birthDate?.editable && {
                                            editable: editing
                                        }),
                                        autoCompleteYear: true,
                                        unsettable: true
                                    }}
                                    picker={{
                                        future: false,
                                        blockedDateRanges: [{ on: new Date() }],
                                        monthAsSelect: true
                                    }}
                                    view={{
                                        yearsDiff: true
                                    }}
                                    {...isImportant('birthDate')} />
                                {(lookingAtMyOwnProfile && editing && jubileesAvailable) && (
                                    <FieldHelper>
                                        <FormattedMessage
                                            {...birthdayFieldHelper}
                                            values={{
                                                button: chunks => (
                                                    <InlineButton
                                                        className="constructive"
                                                        onClick={() => sendAppSignal('account-settings.show')}>
                                                        {chunks}
                                                    </InlineButton>
                                                )
                                            }} />
                                    </FieldHelper>
                                )}
                            </>
                        )}
                        <CustomFields
                            fields={customFields}
                            editing={editing}
                            salt={salt} />
                    </Container>
                )}
            </Form>
            <Modal
                show={!!confirmation}
                dismiss={() => confirmation.resolve(false)}
                salt={`${salt}:confirm-new-email-address`}>
                <ConfirmEmailAddressChange
                    newEmail={confirmation?.email}
                    onDone={(confirmed = false) => confirmation.resolve(confirmed)} />
            </Modal>
        </>
    )
}

export default IntroSection