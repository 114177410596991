import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { size } from 'utilities/object'
import Form from 'components/form/controller'
import { ModalHeading } from 'components/typography/heading'
import CheckboxField from 'components/form/field/checkbox'
import TextField from 'components/form/field/text'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const TopicPrompt = ({ method, id, dismiss, onDone, addOrUpdate, salt }) => {
    const { formatMessage } = useIntl()

    const { notify: sendNotification } = useHandbook()

    const [constructing, setConstructing] = useState(false)
    const [notify, setNotify] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')

    const publishOrUpdate = async () => {
        setConstructing(true)

        let ok = true

        const { response: addOrUpdateResponse, ok: addOrUpdateOk } = await addOrUpdate({
            ...(method === 'publish' ? { status: 'published' } : null),
            notify
        })

        if(notify && addOrUpdateOk) {
            if(!id) {
                id = addOrUpdateResponse?.id
            }

            const { ok: notifyOk } = await sendNotification(
                'topic',
                notificationMessage,
                id
            )

            ok = notifyOk
        }

        setConstructing(false)

        ok && onDone?.(notify)
    }

    const heading = {
        update: {
            id: 'handbooks_topic_action_update',
            defaultMessage: 'Update topic'
        },
        publish: {
            id: 'handbooks_topic_action_publish',
            defaultMessage: 'Publish topic'
        }
    }[method]

    const submitText = {
        update: {
            false: {
                id: 'action_update',
                defaultMessage: 'Update'
            },
            true: {
                id: 'action_update_and_notify',
                defaultMessage: 'Update and notify'
            }
        },
        publish: {
            false: {
                id: 'action_publish',
                defaultMessage: 'Publish'
            },
            true: {
                id: 'action_publish_and_notify',
                defaultMessage: 'Publish and notify'
            }
        }
    }[method][notify]

    return (
        <Form
            layout="vertical"
            onSubmit={publishOrUpdate}>
            {({ errors, trigger }) => (
                <>
                    <ModalHeading>
                        <FormattedMessage {...heading} />
                    </ModalHeading>
                    <CheckboxField
                        {...(!notify ? { className: 'compact' } : null)}
                        salt={salt}
                        label={formatMessage({
                            id: 'notification_toggle_label',
                            defaultMessage: 'Send notification'
                        })}
                        name="notify"
                        field={{ value: notify }}
                        interaction="switch"
                        onChange={({ notify }) => setNotify(notify)} />
                    {!!notify && (
                        <>
                            <TextField
                                salt={salt}
                                label={formatMessage({
                                    id: 'notification_message_label',
                                    defaultMessage: 'Notification message'
                                })}
                                name="notificationMessage"
                                field={{
                                    value: '',
                                    required: true,
                                    include: 'always',
                                    showCharacterCount: true
                                }}
                                controlProps={{
                                    placeholder: formatMessage({
                                        id: 'handbooks_placeholder_publish_topic_notification',
                                        defaultMessage: 'Check out this topic!'
                                    }),
                                    maxLength: 100
                                }}
                                onChange={({ notificationMessage }) => setNotificationMessage(notificationMessage)} />
                            <Message
                                className="compact"
                                type="info"
                                message={formatMessage({
                                    id: 'handbooks_message_topic_notification',
                                    defaultMessage: 'People with access to this topic will be notified.'
                                })} />
                        </>
                    )}
                    <Actions>
                        <Plain
                            onClick={dismiss}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={constructing || size(errors)}
                            ref={trigger}>
                            <FormattedMessage {...submitText} />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default TopicPrompt
