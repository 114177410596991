import React from 'react'
import { FormattedMessage } from 'react-intl'
import Divider from 'components/divider'
import { CenteredParagraph, Link } from './s'

const LoginQuestion = () => (
    <>
        <Divider />
        <CenteredParagraph className="compact">
            <span>
                <FormattedMessage
                    id="signup_one_huma_domain_login_question"
                    defaultMessage="Are you already using Huma?" />
            </span>
            <span>
                <Link
                    to="path:root"
                    className="constructive">
                    <FormattedMessage
                        id="action_login"
                        defaultMessage="Log in" />
                </Link>
            </span>
        </CenteredParagraph>
    </>
)

export default LoginQuestion
