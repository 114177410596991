import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcesses } from 'contexts/processes'
import { getReferenceDateName, getReferenceDateLabelTranslation, getEmptyMessageTranslation } from 'pages/processes/utilities'
import { areFiltersApplied } from 'pages/processes/components/processes-filter'
import {
    Wrap, Header,
    GridColumnHeaderSortable, GridColumnHeader, GridColumnActionsHeader,
    SkeletonCell, SkeletonProgressCell,
    LoadingContainer, Tutorial
} from './s'
import { SkeletonListRow, SkeletonAvatarAndFullName, SkeletonCircle, SkeletonStrings } from 'components/skeleton'
import Process from './process'
import Loader from 'components/loader'
import Paragraph from 'components/typography/paragraph'
import Empty from 'components/empty'
import { Ghost } from 'components/button'
import { clamp } from 'utilities/math'
import { getActionsArray } from 'widgets/context-menu'

const Xboarding = ({ actions = {}, header = false, className }) => {
    const { formatMessage } = useIntl()

    const {
        type,
        processes,
        intersecter,
        loading,
        sorting = {},
        toggleSorting,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        filter = {},
        flash,
        clearFlash
    } = useProcesses()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const hasActions = !!getActionsArray(actions).length
    const { hasNextPage } = paging

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(processes.length, 1, 10))
        }
    }, [hasFetched, processes?.length])

    const filtersApplied = areFiltersApplied(filter)

    return (
        <Wrap {...(className ? { className } : null)}>
            {(header || fetching) && (
                <Header>
                    <GridColumnHeaderSortable
                        field="name"
                        sort={sorting}
                        toggle={toggleSorting}
                        text={formatMessage({
                            id: 'noun_name',
                            defaultMessage: 'Name'
                        })} />
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_progress"
                            defaultMessage="Progress" />
                    </GridColumnHeader>
                    <GridColumnHeaderSortable
                        field={getReferenceDateName(type)}
                        sort={sorting}
                        toggle={toggleSorting}
                        text={formatMessage(getReferenceDateLabelTranslation(type))} />
                    {!!hasActions && <GridColumnActionsHeader />}
                </Header>
            )}
            {(!processes.length && hasFetched) && (
                <>
                    {!filtersApplied && <Tutorial which={type} />}
                    {filtersApplied && (
                        <Empty which={type}>
                            <Paragraph className="caption compact">
                                <FormattedMessage {...getEmptyMessageTranslation(type)} />
                            </Paragraph>
                        </Empty>
                    )}
                </>
            )}
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={3}
                    hasActions={!!hasActions}
                    key={`list:processes:processes:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonAvatarAndFullName
                            size={40}
                            showSecondLine={index % 2 === 0} />
                    </SkeletonCell>
                    <SkeletonProgressCell>
                        <SkeletonCircle size={24} />
                        <SkeletonStrings
                            size={16}
                            length={10} />
                    </SkeletonProgressCell>
                    <SkeletonCell>
                        <SkeletonStrings
                            size={16}
                            length={12} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {processes.map((process, index) => {
                const last = index + 1 === processes.length

                return (
                    <Process
                        process={process}
                        actions={actions}
                        {...(last ? { ref: intersecter } : null)}
                        flash={flash?.current === process ? clearFlash : null}
                        key={process.id} />
                )
            })}
            {!!hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </Wrap>
    )
}

export default Xboarding