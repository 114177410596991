import styled from 'styled-components'
import {
    svgStroke,
    fromPhone
} from 'utilities/styled'
import LinkBase from 'components/link'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const LoadingContainer = styled.div`
    position: relative;
    height: 72px;
`

export const Link = styled(LinkBase)`
    --indent: 24px;

    position: relative;

    display: grid;
    grid-template-columns: 24px 1fr 24px;
    column-gap: 24px;
    align-items: center;

    margin-inline: calc(var(--indent) * -1);
    height: 72px;
    padding: 12px var(--indent);

    transition: border-color 0.1s ease-in-out;

    line-height: 24px;

    ${fromPhone`
        &:first-of-type {
            margin-block-start: -24px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-of-type {
            margin-block-end: -24px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    `}

    &:not(:last-of-type)::after {
        content: "";
        position: absolute;
        bottom: 0;
        inset-inline: var(--indent);

        height: 1px;
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    svg {
        ${svgStroke()}
    }

    & .arrow {
        transition: transform .1s ease-in-out;
    }

    &:focus-visible::after,
    &:has(+ &:focus-visible)::after {
        opacity: 0;
    }

    &:is(:hover, :focus-visible) {
        text-decoration: none;

        span {
            text-decoration: underline;
        }

        .arrow {
            transform: translateX(2px);
        }
    }
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    grid-template-columns: 24px 1fr 24px;
    padding: 0;
    box-shadow: none;
`