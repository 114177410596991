import React, { useState, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcess } from 'contexts/process'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { compact } from 'utilities/array'
import { size, pick } from 'utilities/object'
import { getDate } from 'utilities/date-time'
import Form from 'components/form/controller'
import { ModalHeader } from 'modals/generic'
import { Fields } from './s'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import Links from 'pages/tasks/modals/task/edit/links'
import Fieldset from 'components/form/fieldset'
import ShareField, { unoptionize } from 'components/form/field/share'
import PersonField from 'components/form/field/person'
import UpgradeCTA from 'components/upgrade-cta'
import TimeField from 'components/form/field/time'
import Paragraph from 'components/typography/paragraph'
import ConcernsUnit from 'pages/tasks/components/concerns-unit'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'

const EditTask = ({ task, assign, when, dismiss, modal, salt }) => {
    const { formatMessage } = useIntl()

    const {
        process,
        type,

        addTask,
        updateTask
    } = useProcess()

    const { isItMyOwnId } = useMe()

    const { checkFeature } = useAccess()
    const groupTasksAvailable = checkFeature('group-tasks')
    const groupTasksUpgradable = useUpgradable()({ feature: 'group-tasks' })

    const {
        id,
        title,
        description,
        links = [],
        assignedGroup,
        assignedTo,
        dueAt = (typeof when === 'string') ? when : null
    } = task ?? {}

    const [constructing, setConstructing] = useState(false)

    const [primaryAssignment, setPrimaryAssignment] = useState({
        value:
            assignedGroup ??
            (assignedTo ? { ...assignedTo, type: 'user' } : null),
        changed: false
    })

    const [secondaryAssignment, setSecondaryAssignment] = useState({
        value: assignedGroup ? assignedTo : null,
        changed: false
    })

    const [taskLinks, setTaskLinks] = useState({
        value: links,
        changed: false
    })

    const mode = task?.id ? 'update' : 'create'
    const include = (mode === 'update') ?
        'touched' :
        'always'

    const primaryAssignmentIsGroup = ['team', 'location'].includes(primaryAssignment.value?.type)

    const addOrUpdate = async (body, { reset }) => {
        setConstructing(true)

        delete body.primaryAssignment
        delete body.secondaryAssignment

        const draft = {}
        const started = {}

        if(
            mode === 'create' ||
            primaryAssignment.changed ||
            secondaryAssignment.changed
        ) {
            if(groupTasksAvailable) {
                const field = primaryAssignmentIsGroup ?
                    'assignedGroup' :
                    'assignedTo'

                if(primaryAssignment.changed || (mode === 'create')) {
                    draft[field] = primaryAssignment.value ?? null
                    started[field] = primaryAssignment.value?.id ?? null
                }

                if(secondaryAssignment.changed || (mode === 'create')) {
                    if(primaryAssignmentIsGroup) {
                        draft.assignedTo = secondaryAssignment.value ?? null
                        started.assignedTo = secondaryAssignment.value?.id ?? null
                    } else {
                        draft.assignedGroup = null
                        started.assignedGroup = null
                    }
                }
            } else if(primaryAssignment.changed || (mode === 'create')) {
                draft.assignedTo = primaryAssignment.value ?? null
                started.assignedTo = primaryAssignment.value?.id ?? null
            }
        }

        body.links = taskLinks.value

        const addOrUpdateTask = (mode === 'update') ?
            updateTask :
            addTask

        const { ok } = await addOrUpdateTask({
            body: {
                ...body,
                ...(!!process?.id ? started : draft),
                ...((mode === 'create') ? { availableAt: getDate() } : null)
            },
            id
        })

        setConstructing(false)

        if(ok) {
            reset()
            dismiss?.()
        }
    }

    const heading = formatMessage({
        id: id ?
            'task_action_update' :
            'task_action_add',
        defaultMessage: id ?
            'Update task' :
            'Add task'
    })

    const partOfMyProcess = isItMyOwnId(process?.concerns.id)

    const showLimited = !groupTasksAvailable && !groupTasksUpgradable
    const showPossibilities = groupTasksAvailable || groupTasksUpgradable

    const assignLabel = formatMessage({
        id: 'task_label_assign',
        defaultMessage: 'Assign'
    })

    const [ShareFieldset, shareFieldsetProps] = showPossibilities ?
        [Fieldset, {
            label: {
                children: assignLabel
            }
        }] : [Fragment, null]

    const shareFieldLabel = showLimited ?
        assignLabel :
        formatMessage({
            id: showPossibilities ?
                'organization_unit_person_or_team_or_location' :
                'organization_unit_person',
            defaultMessage: showPossibilities ?
                'A person, team or location…' :
                'A person…'
        })

    return (
        <Form
            layout="vertical"
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <ModalHeader
                        heading={heading}
                        dismiss={dismiss} />
                    <Paragraph className="caption">
                        <FormattedMessage
                            {...getProcessCaptionTranslation(type, partOfMyProcess)}
                            values={{
                                user: <ConcernsUnit concerns={process?.concerns} />,
                                title: process?.title
                            }} />
                    </Paragraph>
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_title',
                                defaultMessage: 'Title'
                            })}
                            name="title"
                            field={{
                                value: title,
                                required: true,
                                include
                            }}
                            controlProps={{
                                autoFocus: !assign,
                                maxLength: 255
                            }} />
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{
                                value: description,
                                include
                            }}
                            controlProps={{ maxLength: 2040 }} />
                        <Links
                            taskLinks={taskLinks}
                            setTaskLinks={setTaskLinks}
                            salt={salt} />
                        <ShareFieldset {...shareFieldsetProps}>
                            <ShareField
                                salt={salt}
                                label={shareFieldLabel}
                                name="primaryAssignment"
                                field={{
                                    value: compact([primaryAssignment.value]),
                                    include,
                                    required: !!process?.id
                                }}
                                controlProps={{ autoFocus: assign }}
                                picker={{
                                    types: [
                                        'user',
                                        ...(groupTasksAvailable ? ['team', 'location'] : [])
                                    ],
                                    single: true
                                }}
                                onChange={({ primaryAssignment }) => {
                                    primaryAssignment = !!primaryAssignment?.[0] ?
                                        unoptionize(primaryAssignment[0]) :
                                        null

                                    if(!primaryAssignment && secondaryAssignment.value) {
                                        primaryAssignment = { ...secondaryAssignment.value, type: 'user' }
                                    }

                                    setPrimaryAssignment({
                                        value: primaryAssignment,
                                        changed: true
                                    })

                                    setSecondaryAssignment({
                                        value: null,
                                        changed: true
                                    })
                                }}
                                key={`${salt}:${modal?.showing}:primaryAssignment:${primaryAssignment.value?.id ?? 'empty'}`} />
                            {(groupTasksAvailable && primaryAssignmentIsGroup) && (
                                <PersonField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'group_person_action_assign_to',
                                        defaultMessage: 'Assign to a person in {name}'
                                    }, primaryAssignment.value)}
                                    name="secondaryAssignment"
                                    field={{
                                        value: secondaryAssignment.value,
                                        include
                                    }}
                                    picker={{
                                        mode: 'group',
                                        ...pick(primaryAssignment.value, 'type', 'id')
                                    }}
                                    onChange={({ secondaryAssignment }) => setSecondaryAssignment({
                                        value: secondaryAssignment,
                                        changed: true
                                    })}
                                    key={`${salt}:secondaryAssignment:${secondaryAssignment.value?.id ?? 'empty'}`} />
                            )}
                            {groupTasksUpgradable && (
                                <UpgradeCTA
                                    feature="group-tasks"
                                    useUpgradeIcon
                                    useFeatureOrModuleIcon
                                    useFeatureTitle
                                    useFeatureDescription
                                    className="compact"
                                    salt="group-tasks" />
                            )}
                        </ShareFieldset>
                        <TimeField
                            salt={salt}
                            label={formatMessage({
                                id: 'task_action_deadline_assign',
                                defaultMessage: 'The task should be completed'
                            })}
                            name="dueAt"
                            field={{
                                value: dueAt,
                                include
                            }}
                            picker={{ past: false }} />
                    </Fields>
                    <Actions className="compact">
                        <Plain
                            onClick={dismiss}
                            className="neutral"
                            disabled={constructing}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={(
                                !touched.length &&
                                !primaryAssignment.changed &&
                                !secondaryAssignment.changed &&
                                !taskLinks.changed
                            ) || (
                                !!size(errors) ||
                                constructing
                            )}
                            ref={trigger}>
                            {heading}
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export const getProcessCaptionTranslation = (type, partOfMyProcess) => {
    if(type === 'onboarding') {
        return {
            id: partOfMyProcess ?
                'task_onboarding_regarding_me_title' :
                'task_onboarding_regarding_user_title',
            defaultMessage: partOfMyProcess ?
                'Regarding your onboarding' :
                'Regarding {user} · Onboarding'
        }
    }

    if(type === 'offboarding') {
        return {
            id: partOfMyProcess ?
                'task_offboarding_regarding_me_title' :
                'task_offboarding_regarding_user_title',
            defaultMessage: partOfMyProcess ?
                'Regarding your offboarding' :
                'Regarding {user} · Offboarding'
        }
    }

    if(type === 'process') {
        return {
            id: partOfMyProcess ?
                'task_process_regarding_me_title' :
                'task_process_regarding_user_title',
            defaultMessage: partOfMyProcess ?
                'Regarding your process' :
                `Regarding {user} · {title}`
        }
    }
}

export default EditTask