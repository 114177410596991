import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { H2 } from 'components/typography/heading'
import MessageBase from 'components/message'
import { Grid } from 'components/grid'
import EntryBase from 'lists/absence/entries/entry'

export const Heading = styled(H2)`
    margin-block-end: 16px;
    max-width: calc(100% - 40px);

    line-height: 32px;
`

export const Message = styled(MessageBase)`
    svg {
        ${svgStroke()}
    }
`

export const Entries = Grid

export const Entry = styled(EntryBase)`
    display: block;

    &:last-of-type {
        padding-block-end: 0;
    }
`