import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { useInterval } from 'hooks/interval'
import { sendAppSignal } from 'hooks/app-signal'
import { get } from 'api'
import { getDate } from 'utilities/date-time'
import { showJubileeGift } from 'pages/people/utilities'
import { format, subDays, addDays } from 'date-fns'
import { HeroActions, Heading } from 'pages/dashboard/s'
import { Ghost } from './s'
import Paragraph from 'components/typography/paragraph'
import Content from './content'
import PeoplePicker from 'modals/people-picker'
import { Scrollable as Modal } from 'modals/generic'
import Error from './error'
import { Gift } from 'styled-icons/feather'
import { InlineButton } from 'components/button'
import { cls } from 'utilities/dom'

const perPage = 50
const salt = 'dashboard:jubilees'

const DashboardJubilees = ({ hideIfEmpty }) => {
    const { formatMessage } = useIntl()
    const {
        dateLocale: locale,
        settings
    } = useI18n()

    const { check } = useAccess()
    const usersManageAccess = check('users:manage')

    const [jubilees, setJubilees] = useState([])
    const [hasFetched, setHasFetched] = useState(false)
    const [selectingPeople, setSelectingPeople] = useState(false)
    const [pickedPeople, setPickedPeople] = useState([])
    const [errorPeople, setErrorPeople] = useState([])
    const [now, setNow] = useState(new Date)

    useInterval(() => setNow(new Date), 60 * 60 * 1000)

    useEffect(() => {
        const fetch = async page => {
            const offset = perPage * page

            const { ok, response } = await get({
                path: '/users/jubilees',
                params: {
                    fromDate: getDate(subDays(now, 7)),
                    toDate: getDate(addDays(now, 30)),
                    limit: perPage,
                    offset
                }
            })

            if(ok) {
                const accumulatedJubilees = [
                    ...jubilees,
                    ...response.items
                ]

                setJubilees(accumulatedJubilees)

                page++

                if(accumulatedJubilees < response.total && offset < response.total) {
                    fetch(page)
                } else {
                    setHasFetched(true)
                }
            } else {
                setHasFetched(true)
            }
        }

        fetch(0)
    }, [])

    const empty = (hasFetched && !jubilees?.length)
    if(hideIfEmpty && empty) {
        return null
    }

    return (
        <>
            <HeroActions>
                <Heading>
                    <FormattedMessage
                        id="dashboard_jubilees_heading"
                        defaultMessage="Jubilees" />
                </Heading>
                {(!!jubilees?.length && usersManageAccess) && (
                    <Ghost
                        className="constructive"
                        icon={Gift}
                        onClick={() => setSelectingPeople(true)}>
                        <FormattedMessage
                            id="person_action_send_gift"
                            defaultMessage="Send a gift" />
                    </Ghost>
                )}
            </HeroActions>
            {empty && (
                <>
                    <Paragraph className={cls([
                        'caption small',
                        !!settings?.showBirthday && 'compact'
                    ])}>
                        <FormattedMessage
                            id="dashboard_jubilees_empty"
                            defaultMessage="No jubilees between {startDate} and {endDate}."
                            values={{
                                startDate: format(subDays(now, 7), 'PPP', { locale }),
                                endDate: format(addDays(now, 30), 'PPP', { locale })
                            }} />
                    </Paragraph>
                    {!(settings?.showBirthday === false || settings?.showBirthday === true) && (
                        <Paragraph className="caption small compact">
                            <FormattedMessage
                                id="jubilees_message_birthday_hidden"
                                defaultMessage="Only administrators can see your birthday by default. You can change this under <button>User account settings</button>."
                                values={{
                                    button: chunks => (
                                        <InlineButton
                                            className="constructive"
                                            onClick={() => sendAppSignal('account-settings.show')}>
                                            {chunks}
                                        </InlineButton>
                                    )
                                }} />
                        </Paragraph>
                    )}
                </>
            )}
            <Content
                jubilees={jubilees}
                now={now}
                hasFetched={hasFetched}
                salt={salt} />
            <PeoplePicker
                show={selectingPeople}
                salt={salt}
                dismiss={() => setSelectingPeople(false)}
                cancelAction={() => ({
                    label: formatMessage({
                        id: 'action_cancel',
                        defaultMessage: 'Cancel'
                    }),
                    effect: 'neutral',
                    onClick: () => setSelectingPeople(false)
                })}
                doneAction={({ picked }) => ({
                    label: formatMessage({
                        id: 'action_continue',
                        defaultMessage: 'Continue'
                    }),
                    effect: 'constructive',
                    onClick: () => {
                        const invalidPhoneNumbers = picked.filter(({ phone }) => !phone || !phone?.startsWith('+47'))

                        if(!!invalidPhoneNumbers?.length) {
                            setErrorPeople(invalidPhoneNumbers)
                            setPickedPeople(picked.filter(({ phone }) => phone && phone.startsWith('+47')))
                        } else {
                            showJubileeGift(picked)
                            setSelectingPeople(false)
                        }
                    }
                })} />
            <Modal
                show={!!errorPeople?.length}
                dismiss={() => setErrorPeople([])}>
                <Error
                    errorPeople={errorPeople}
                    pickedPeople={pickedPeople}
                    dismiss={() => {
                        setSelectingPeople(false)
                        setErrorPeople([])
                    }}
                    onDone={() => {
                        setSelectingPeople(false)
                        showJubileeGift(pickedPeople.filter(({ phone }) => phone && phone.startsWith('+47')))
                        setErrorPeople([])
                    }} />
            </Modal>
        </>
    )
}

export default DashboardJubilees