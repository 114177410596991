import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Paragraph } from './s'
import { ModalHeading } from 'components/typography/heading'
import PersonList from 'components/person-list'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'

const JubileesError = ({ errorPeople = [], pickedPeople = [], dismiss, onDone }) => {
    const allErrorenous = !pickedPeople?.length

    return (
        <>
            <ModalHeading>
                <FormattedMessage
                    id="dashboard_jubilees_gift_error_heading"
                    defaultMessage="Users missing Norwegian phone number" />
            </ModalHeading>
            <Paragraph {...!!allErrorenous ? { className: 'compact' } : null}>
                <FormattedMessage
                    id={allErrorenous ?
                        'dashboard_jubilees_gift_error_all_errorenous' :
                        'dashboard_jubilees_gift_error_some_errorenous'
                    }
                    defaultMessage={allErrorenous ?
                        'All users you selected are missing a Norwegian phone number. Please add a valid phone number to send them a gift.' :
                        'Some of the users you selected are missing a Norwegian phone number. Please add a valid phone number to send them a gift.'
                    } />
            </Paragraph>
            {!allErrorenous && <PersonList people={errorPeople} />}
            <Actions {...!!allErrorenous ? { className: 'centered' } : null}>
                <Plain
                    className="constructive"
                    onClick={dismiss}>
                    <FormattedMessage
                        id={!!allErrorenous ?
                            'action_close' :
                            'action_cancel'
                        }
                        defaultMessage={!!allErrorenous ?
                            'Close' :
                            'Cancel'
                        } />
                </Plain>
                {!allErrorenous && (
                    <Button
                        className="constructive"
                        onClick={onDone}>
                        <FormattedMessage
                            id="action_continue"
                            defaultMessage="Continue" />
                    </Button>
                )}
            </Actions>
        </>
    )
}

export default JubileesError
