import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useIdentifications } from 'contexts/identifications'
import { usePerson } from 'contexts/person'
import { size } from 'utilities/object'
import { Fields, ErrorParagraph } from './s'
import Form from 'components/form/controller'
import { H3 as Heading } from 'components/typography/heading'
import RadiobuttonsField from 'components/form/field/radiobuttons'
import CountryField from 'components/form/field/country'
import StringField from 'components/form/field/string'
import Link from 'components/link'
import { Inline as Code } from 'components/code'
import { useError } from './error'
import Message from 'components/message'
import Tooltip, { Trigger } from 'components/tooltip'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'

const EditIdentification = ({ identification = {}, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        addIdentification,
        updateIdentification,
        resetError
    } = useIdentifications()

    const {
        person,
        fetchPerson
    } = usePerson()

    const {
        id,
        type,
        country,
        value
    } = identification

    const [constructing, setConstructing] = useState(false)
    const [identificationType, setIdentificationType] = useState(type ?? 'national')

    const getError = useError()

    const addOrUpdate = async (body, { resetTouched }) => {
        setConstructing(true)

        const addOrUpdateIdentification = id ?
            updateIdentification :
            addIdentification

        const { ok } = await addOrUpdateIdentification(body, id)

        setConstructing(false)

        if(ok) {
            resetError()
            resetTouched()
            fetchPerson()
            onDone && onDone()
        }
    }

    const heading = formatMessage({
        id: id ?
            'identification_action_update' :
            'identification_action_add',
        defaultMessage: id ?
            'Update identification' :
            'Add identification'
    })

    const submitButtonText = formatMessage({
        id: id ?
            'action_update' :
            'action_add',
        defaultMessage: id ?
            'Update' :
            'Add'
    })

    const error = getError(identificationType)

    return (
        <Form
            layout="vertical"
            resetTouchedOnSubmit={true}
            onChange={resetError}
            onSubmit={addOrUpdate}>
            {({ values, touched, errors, trigger }) => {
                const nationalIdFormatInfo = getNationalIdInfoFormatMessage(values?.country)
                const showNationalIdInfo = values?.type === 'national' && !!nationalIdFormatInfo
                const nationalIdFormatTooltip = getNationalIdTooltipFormatMessage(values.country)
                const showNationalIdTooltip = values?.type === 'national' && !!nationalIdFormatTooltip

                return (
                    <>
                        <Heading>{heading}</Heading>
                        <Fields>
                            <RadiobuttonsField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_type',
                                    defaultMessage: 'Type',
                                })}
                                name="type"
                                field={{
                                    value: identificationType,
                                    include: 'always'
                                }}
                                options={[
                                    {
                                        value: 'national',
                                        name: formatMessage({
                                            id: 'person_label_identifications_national',
                                            defaultMessage: 'National identification number'
                                        })
                                    },
                                    {
                                        value: 'passport',
                                        name: formatMessage({
                                            id: 'person_label_identifications_passport',
                                            defaultMessage: 'Passport number'
                                        })
                                    }
                                ]}
                                framed={true}
                                onChange={({ type }) => setIdentificationType(type)} />
                            <CountryField
                                salt={salt}
                                label={formatMessage({
                                    id: 'address_label_country',
                                    defaultMessage: 'Country'
                                })}
                                name="country"
                                field={{
                                    value: country ?? person?.nationality?.value,
                                    required: true,
                                    include: 'always'
                                }}
                                controlProps={{
                                    autoComplete: 'country-name',
                                    'data-1p-ignore': 'false'
                                }} />
                            <StringField
                                salt={salt}
                                label={formatMessage(getIdentificationTypeLabel(values?.type ?? 'national'))}
                                name="value"
                                field={{
                                    value,
                                    required: true,
                                    include: 'always'
                                }}
                                {...(showNationalIdInfo ? {
                                    controlProps: {
                                        placeholder: formatMessage(getNationalIdPlaceholder(values.country))
                                    }
                                } : null)} />
                            {error && (
                                <ErrorParagraph className="small destructive">
                                    {error}
                                </ErrorParagraph>
                            )}
                            {showNationalIdInfo && (
                                <Message
                                    type="info"
                                    className="compact"
                                    textClassName="preserve-line-breaks"
                                    message={formatMessage(nationalIdFormatInfo, {
                                        link: chunks => (
                                            <Link
                                                href={formatMessage(getNationalIdFormatLink(values.country))}
                                                target="_blank"
                                                className="constructive">
                                                {chunks}
                                            </Link>
                                        ),
                                        ...(showNationalIdTooltip ? {
                                            tooltip: (
                                                <Tooltip
                                                    wrapperClassName="preserve-line-breaks"
                                                    content={formatMessage(nationalIdFormatTooltip, {
                                                        code: chunks => <Code>{chunks}</Code>
                                                    })}>
                                                    <Trigger />
                                                </Tooltip>
                                            )
                                        } : null)
                                    })} />
                            )}
                        </Fields>
                        <Actions>
                            <Plain
                                onClick={onDone}
                                className="neutral"
                                disabled={constructing}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${constructing ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors) || constructing}
                                ref={trigger}>
                                {submitButtonText}
                            </ButtonSubmit>
                        </Actions>
                    </>
                )
            }}
        </Form>
    )
}

export const getIdentificationTypeLabel = type => ({
    national: {
        id: 'person_label_identifications_national',
        defaultMessage: 'National identification number'
    },
    passport: {
        id: 'person_label_identifications_passport',
        defaultMessage: 'Passport number'
    }
})[type]

const getNationalIdPlaceholder = countryCode => ({
    NO: {
        id: 'person_placeholder_identifications_national_format_NO',
        defaultMessage: 'DDMMYYXXXXX'
    },
    SE: {
        id: 'person_placeholder_identifications_national_format_SE',
        defaultMessage: 'YYMMDD-XXXX'
    },
    FI: {
        id: 'person_placeholder_identifications_national_format_FI',
        defaultMessage: 'DDMMYYCZZZQ'
    },
    DK: {
        id: 'person_placeholder_identifications_national_format_DK',
        defaultMessage: 'DDMMYY-XXXX'
    }
})[countryCode]

const getNationalIdTooltipFormatMessage = countryCode => ({
    FI: {
        id: 'person_tooltip_identifications_national_FI',
        defaultMessage: '<code>+</code> for people born between 1800–1899.\n\n<code>-</code>, or one of the letters <code>U, V, W, X, Y</code> for people born between 1900–1999.\n\n<code>A, B, C, D, E, F</code> for people born between 2000–2099'
    }
})[countryCode] || null

const getNationalIdInfoFormatMessage = countryCode => ({
    NO: {
        id: 'person_message_identifications_national_NO',
        defaultMessage: 'Read more about Norwegian identification number on <link>the Norwegian Tax Agency’s website<\/link>'
    },
    SE: {
        id: 'person_message_identifications_national_SE',
        defaultMessage: 'Read more about personal identity numbers and coordination numbers on <link>the Swedish Tax Agency’s (Skatteverket) website<\/link>'
    },
    FI: {
        id: 'person_message_identifications_national_FI',
        defaultMessage: 'In the example above, ”C” indicates the century of birth. {tooltip}\n\nRead more about Finnish personal identity codes on <link>the Finnish Digital and Population Data Services Agency’s website<\/link>'
    },
    DK: {
        id: 'person_message_identifications_national_DK',
        defaultMessage: 'Read more about Danish CPR numbers on <link>the Danish Civil Registration System’s website<\/link>'
    }
})[countryCode]

const getNationalIdFormatLink = countryCode => ({
    NO: {
        id: 'person_link_identifications_national_NO',
        defaultMessage: 'https:\/\/www.skatteetaten.no\/en\/person\/foreign\/norwegian-identification-number'
    },
    SE: {
        id: 'person_link_identifications_national_SE',
        defaultMessage: 'https:\/\/www.skatteverket.se\/servicelankar\/otherlanguages\/inenglish\/individualsandemployees\/livinginsweden\/personalidentitynumberandcoordinationnumber.4.2cf1b5cd163796a5c8b4295.html'
    },
    FI: {
        id: 'person_link_identifications_national_FI',
        defaultMessage: 'https:\/\/dvv.fi\/en\/personal-identity-code'
    },
    DK: {
        id: 'person_link_identifications_national_DK',
        defaultMessage: 'https://cpr.dk/cpr-systemet/opbygning-af-cpr-nummeret'
    }
})[countryCode]

export default EditIdentification