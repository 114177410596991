import React from 'react'
import { useIntl } from 'react-intl'
import Label from './label'

const CompetenceProfileStatus = ({ status, ...props }) => {
    if(!status) {
        return null
    }

    return (
        <span {...props}>
            <Label status={status} />
        </span>
    )
}

const statusTexts = {
    private: {
        id: 'competence_profile_status_private',
        defaultMessage: 'Private'
    },
    public: {
        id: 'competence_profile_status_public',
        defaultMessage: 'Public'
    }
}

export const useCompetenceProfileStatusTextFormatter = () => {
    const { formatMessage } = useIntl()

    return status => {
        const message = statusTexts[status] ?? null

        return message ?
            formatMessage(message) :
            null
    }
}

export default CompetenceProfileStatus