import { useState, useLayoutEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { size } from 'utilities/object'

export const useSurveyResponse = () => {
    const [data, setData] = useState(null)
    const [urlSearchParams, setUrlSearchParams] = useSearchParams()

    const searchParams = Object.fromEntries(urlSearchParams)

    useLayoutEffect(() => {
        if(size(searchParams)) {
            const surveyResponse = searchParams['survey-response']

            if(surveyResponse) {
                setData(JSON.parse(surveyResponse))
                setUrlSearchParams({}, { replace: true })
            }
        }
    }, [JSON.stringify(searchParams)])

    return data
}