import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Scrollable as Modal } from 'modals/generic'
import { ModalHeader } from 'modals/generic'
import AccountSettingsGeneralContent from 'pages/people/pages/profile/pages/settings/panes/general/content'
import Actions from 'components/form/actions'
import { Button } from 'components/button'

const AccountSettings = ({ showAccountSettings, setShowAccountSettings, salt }) => {
    const { formatMessage } = useIntl()

    return (
        <Modal
            show={!!showAccountSettings}
            dismiss={() => setShowAccountSettings(false)}
            enrichChildren={false}>
            <ModalHeader
                heading={formatMessage({
                    id: 'noun_settings_account',
                    defaultMessage: 'User account settings'
                })} />
            <AccountSettingsGeneralContent
                dismiss={() => setShowAccountSettings(false)}
                salt={salt} />
            <Actions>
                <Button
                    className="constructive"
                    onClick={() => setShowAccountSettings(false)}>
                    <FormattedMessage
                        id="action_done"
                        defaultMessage="Done" />
                </Button>
            </Actions>
        </Modal>
    )
}

export default AccountSettings