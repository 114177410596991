import { each } from 'utilities/object'
import { setGlobal } from 'utilities/global'

const s = type => ({
    local: global.localStorage,
    session: global.sessionStorage
})[type]

const salt = 'huma:'
const salted = key => `${salt}${key}`

const extractValueAndExpiry = item => {
    const [value, expiry] = !!item ?
        JSON.parse(item) :
        [null, null]

    return {
        value,
        expiry,
        expired: !!expiry && Date.now() > expiry
    }
}

// Expiry in minutes
const set = type => (key, value, options = {}) => {
    if(!key) {
        return
    }

    let {
        expiry = Infinity,
        expiryAsDate = false
    } = options

    if(typeof value === 'undefined' || value === null) {
        s(type).removeItem(key)
        return null
    } else {
        if(expiry === Infinity) {
            expiry = null
        } else if(expiry) {
            if(!expiryAsDate) {
                expiry = Date.now() + (expiry * 1000 * 60)
            }
        }

        const valueWithExpiry = [value]
        if(expiry) {
            valueWithExpiry.push(expiry)
        }

        return s(type).setItem(key, JSON.stringify(valueWithExpiry))
    }
}

const get = type => (key, options) => {
    if(!key) {
        return
    }

    const item = s(type).getItem(key) ?? null
    const { value, expired } = extractValueAndExpiry(item)

    if(value && expired) {
        s(type).removeItem(key)

        return (options?.detailed) ?
            { value, expired } :
            null
    }

    return (options?.detailed) ?
        { value, expired } :
        value
}

const remove = type => key => {
    if(!key) {
        return
    }

    const item = s(type).getItem(key) ?? null
    const { value } = extractValueAndExpiry(item)
    s(type).removeItem(key)
    return value
}

const prune = type => each(s(type), (v, key) => {
    key.startsWith(salt) && get(type)(key)
})

const flush = type => s(type).clear()

export const local = {
    set: (key, value, options) => set('local')(salted(key), value, options),
    get: (key, options) => get('local')(salted(key), options),
    remove: key => remove('local')(salted(key)),

    setNamespaced: namespace => (key, value, options) => set('local')(salted(`${namespace}:${key}`), value, options),
    getNamespaced: namespace => (key, options) => get('local')(salted(`${namespace}:${key}`), options),
    removeNamespaced: namespace => key => remove('local')(salted(`${namespace}:${key}`)),

    prune: () => prune('local'),
    flush: () => flush('local')
}

export const session = {
    set: (key, value, options) => set('session')(salted(key), value, options),
    get: (key, options) => get('session')(salted(key), options),
    remove: key => remove('session')(salted(key)),

    setNamespaced: namespace => (key, value, options) => set('session')(salted(`${namespace}:${key}`), value, options),
    getNamespaced: namespace => (key, options) => get('session')(salted(`${namespace}:${key}`), options),
    removeNamespaced: namespace => key => remove('session')(salted(`${namespace}:${key}`)),

    prune: () => prune('session'),
    flush: () => flush('session')
}

setGlobal('storage', {
    local,
    session
})