import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { CaptionSmall as CaptionSmallBase } from 'components/typography/caption'

export const Entry = styled.div`
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    gap: 16px;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);

    &.with-header {
        grid-auto-flow: row;
        grid-auto-rows: auto;
    }
`

export const Header = styled.div`
    display: flex;
    column-gap: 8px;
    grid-column: 1 / -1;

    margin: -16px -16px 0;
    padding: 8px;

    font-size: 14px;
    line-height: 1.25;

    svg {
        ${svgStroke()}
    }

    &.info {
        background-color: var(--huma-color-surface-info-minimal);

        svg {
            color: var(--huma-color-foreground-info-bold);
        }
    }

    &.warning {
        background-color: var(--huma-color-surface-warning-minimal);

        svg {
            color: var(--huma-color-foreground-warning);
        }
    }

    &.error {
        background-color: var(--huma-color-surface-destructive-minimal);

        svg {
            color: var(--huma-color-foreground-error);
        }
    }
`

export const Type = styled.div``

export const Details = styled.div``

export const CaptionSmall = styled(CaptionSmallBase)`
    position: relative;
    isolation: isolate;

    ${Details} & {
        display: inline-block;
    }
`