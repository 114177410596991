import styled from 'styled-components'
import PersonBase from 'components/person'
import { UnorderedList, ListItem as ListItemBase } from 'components/list'
import ParagraphBase from 'components/typography/paragraph'
import { InlineMessage } from 'components/message'

export const PickerMeta = styled.div`
    padding-top: 8px;
`

export const Person = styled(PersonBase)`
    margin-bottom: 8px;
`

export const List = styled(UnorderedList)`
    margin: 0 0 16px 16px;
    list-style-type: disc;
`

export const ListItem = styled(ListItemBase)`
    &:last-child :last-child {
        margin-bottom: 0;
    }
`

export const Paragraph = styled(ParagraphBase)`
    margin-bottom: 24px;

    &:not(:only-child) {
        margin-bottom: 8px;
    }
`

export const Message = styled(InlineMessage)`
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 24px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
    }

    ${PickerMeta} & {
        margin-bottom: 0;
    }
`