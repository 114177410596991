import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AnimatePresence } from 'framer-motion'
import Page from 'contexts/page'
import { useOrganization } from 'contexts/organization'
import { useAccess } from 'contexts/access'
import { salt as configureSalt } from 'app/layouts/private/my-actions/configure-dashboard'
import { useAppSignal, sendAppSignal } from 'hooks/app-signal'
import { local } from 'utilities/storage'
import { cls } from 'utilities/dom'
import { Rows, Row, Ghost } from './s'
import Greeting from './greeting'
// import KnowYourColleagues from './know-your-colleagues'
import ServiceOnboarding from './service-onboarding'
import News from './news'
import Handbook from './handbook'
import Todos from './todos'
import Absence from './absence'
import Jubilees from './jubilees'
import People from './people'
// import Insights from './insights'
import { Edit as ConfigureDashboard } from 'styled-icons/feather'

const DashboardPage = () => {
    const { statuses } = useOrganization()

    const {
        checkModule,
        checkFeature
    } = useAccess()

    const newsAvailable = checkModule('news')

    const handbookAvailable = [
        checkModule('handbook'),
        checkFeature('handbook-featured'),
        statuses?.handbook
    ].every(Boolean)

    const tasksAvailable = checkModule('tasks')

    const absenceAvailable = [
        checkModule('absence'),
        statuses?.hasSetUpWorkSchedule,
        statuses?.absence
    ].every(Boolean)

    const jubileesAvailable = checkFeature('jubilees')
    const peopleAvailable = checkModule('people')

    const access = {
        news: newsAvailable,
        handbook: handbookAvailable,
        todos: tasksAvailable,
        absence: absenceAvailable,
        jubilees: jubileesAvailable,
        people: peopleAvailable
    }

    const [sectionsOrdered, setSectionsOrdered] = useState(
        (local.get(`${configureSalt}:order`) ?? Object.keys(access))
            .filter(section => !!access[section])
    )

    const [configuringDashboard, setConfiguringDashboard] = useState(false)

    useAppSignal({
        namespace: 'dashboard:order',
        action: setSectionsOrdered
    })

    useAppSignal({
        namespace: 'dashboard:configure.toggle',
        action: setConfiguringDashboard
    })

    const hideIfEmpty = false//onboarder && progress < 100

    return (
        <Rows>
            <Row initial="animate">
                <Greeting />
            </Row>
            {/* <Row>
                <KnowYourColleagues />
            </Row> */}
            <ServiceOnboarding />
            <AnimatePresence initial={false}>
                {sectionsOrdered.map(section => {
                    const Section = dashboardSections[section]?.component
                    if(!Section) {
                        return null
                    }

                    const className = cls([
                        configuringDashboard && 'configuring',
                        // sectionsOrdered doesn't contain news:
                        !sectionsOrdered.includes('news') && 'no-news'
                    ])

                    return (
                        <Row
                            className={className}
                            key={section}>
                            <Section hideIfEmpty={hideIfEmpty} />
                        </Row>
                    )
                })}
                {/* <Row>
                    <Insights />
                </Row> */}
            </AnimatePresence>
            <Ghost
                onClick={() => sendAppSignal('dashboard:configure.toggle', true)}
                className="constructive"
                icon={ConfigureDashboard}>
                <FormattedMessage
                    id="action_configure_dashboard"
                    defaultMessage="Configure dashboard" />
            </Ghost>
        </Rows>
    )
}

export const dashboardSections = {
    news: {
        title: {
            id: 'dashboard_news_heading',
            defaultMessage: 'Stay up to date'
        },
        component: News
    },
    handbook: {
        title: {
            id: 'handbooks_heading_pinned_topics',
            defaultMessage: 'Featured topics'
        },
        component: Handbook
    },
    todos: {
        title: {
            id: 'dashboard_todo_heading',
            defaultMessage: 'To-do list'
        },
        component: Todos
    },
    absence: {
        title: {
            id: 'dashboard_absence_heading',
            defaultMessage: 'Who’s away?'
        },
        component: Absence
    },
    jubilees: {
        title: {
            id: 'dashboard_jubilees_heading',
            defaultMessage: 'Jubilees'
        },
        component: Jubilees
    },
    people: {
        title: {
            id: 'dashboard_groups_heading',
            defaultMessage: 'Your teams and locations'
        },
        component: People
    }
}

export default ({ upgradable, ...props }) => (
    <Page
        view="dashboard"
        title={{
            id: 'noun_dashboard',
            defaultMessage: 'Dashboard'
        }}
        upgrade={{
            enabled: upgradable,
            passthrough: true
        }}>
        <DashboardPage {...props} />
    </Page>
)