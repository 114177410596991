import styled from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import { cls } from 'utilities/dom'
import Paragraph from 'components/typography/paragraph'
import { Naked } from 'components/button'
import BetaBase from 'components/beta'
import Badge from 'components/badge'

export const Item = styled(Naked)`
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 24px;
    justify-content: start;
    align-items: center;

    width: 100%;
    padding: 12px 24px;

    transition: background-color .2s ease-out;

    text-align: start;

    &.with-count {
        grid-template-columns: auto 1fr auto auto;
    }

    &.has-beta {
        grid-template-columns: auto 1fr min-content auto;
    }

    ${belowTablet`
        padding-inline: 0;
    `}

    &:where(:hover, :focus-visible) {
        background-color: var(--huma-color-surface-elevated-hover);
    }

    &:focus-visible {
        outline: 2px solid var(--huma-color-border-default-focus);
        outline-offset: 0;
    }
`

export const IconWrapper = styled.div`
    svg {
        ${svgStroke()}
    }
`

export const Title = styled(Paragraph).attrs(attrs => ({
    ...attrs,
    as: 'span',
    className: cls(['compact', attrs.className])
}))`
    display: block;
`

export const Beta = styled(BetaBase)`
    ${belowTablet`
        grid-area: unset;
    `}
`

export const ModalIcon = styled.div`
    svg {
        ${svgStroke('small')}
    }
`

export const Count = styled(Badge)`
    margin-inline-end: -12px;
    background-color: var(--huma-color-surface-elevated-hover);

    color: var(--huma-color-foreground-default);
`