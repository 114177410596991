import { useRef } from 'react'

export const useMutableBox = value => {
    const ref = useRef()

    if(!ref.current) {
        ref.current = (typeof value === 'function') ? value() : value
    }

    return ref.current
}