import React from 'react'
import { useIntl } from 'react-intl'
import { useEquipmentPieces } from 'contexts/equipment-pieces'
import { advancedApplied } from 'components/filter/utilities'
import Filter from 'components/filter'

const EquipmentPiecesFilter = props => {
    const { formatMessage } = useIntl()

    const {
        filter,
        setPiecesFilter: setFilter,
        pieces: items,
        fetching,
        hasFetched
    } = useEquipmentPieces()

    const advancedFields = [
        {
            type: 'checkboxes',
            name: 'status',
            label: formatMessage({
                id: 'noun_status',
                defaultMessage: 'Status'
            }),
            field: {
                options: [
                    {
                        id: 'missing_attachments',
                        name: formatMessage({
                            id: 'equipment_label_missing_documentation',
                            defaultMessage: 'Missing documentation'
                        })
                    },
                    {
                        id: 'missing_serialnumber',
                        name: formatMessage({
                            id: 'equipment_label_missing_serial_number',
                            defaultMessage: 'Missing serial number'
                        })
                    },
                    {
                        id: 'expired',
                        name: formatMessage({
                            id: 'equipment_label_expired',
                            defaultMessage: 'Expired'
                        })
                    },
                    {
                        id: 'expiring_soon',
                        name: formatMessage({
                            id: 'equipment_label_expiring_soon',
                            defaultMessage: 'Expiring soon'
                        })
                    }
                ]
            }
        }
    ]

    return (
        <Filter
            {...props}
            filter={filter}
            setFilter={setFilter}
            advancedFields={advancedFields}
            areAdvancedFiltersApplied={areAdvancedFiltersApplied}
            items={items}
            fetching={fetching}
            hasFetched={hasFetched} />
    )
}

const advancedNames = ['status']

export const areAdvancedFiltersApplied = advancedApplied(advancedNames)

export default EquipmentPiecesFilter