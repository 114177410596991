import styled, { css } from 'styled-components'
import { belowLaptop } from 'utilities/styled'
import { Grid } from 'components/grid'
import SymbolBase from 'components/symbol'
import { CaptionSmall } from 'components/typography/caption'

export const SortableItem = styled.div`
    position: relative;
    isolation: isolate;

    display: flex;
    flex-wrap: wrap;
    align-items: start;
    column-gap: 16px;

    margin-block-end: 24px;
    border-radius: 8px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);
    box-shadow: inset 0 0 1px var(--huma-color-border-default);

    line-height: 24px;

    ${belowLaptop`
        position: relative;
    `}

    ${p => !!p.$isDragging && css`
        box-shadow:
            var(--huma-shadow-lowest),
            inset 0 0 0 1px var(--huma-color-border-subtle);
    `}
`

export const Symbol = styled(SymbolBase)`
    grid-row: 1 / -1;
`

export const Header = styled(Grid)`
    grid-template-rows: auto auto;
    grid-template-columns: 40px 1fr;
    column-gap: 16px;
    flex-basis: 100%;

    margin-block-end: 24px;
`

export const Heading = styled.h2`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    &.strike {
        text-decoration: line-through;
        color: rgb(from var(--huma-color-foreground-default) r g b / 0.5);
    }
`

export const Summary = styled(CaptionSmall)`
    display: inline-block;
    grid-row: 2;
    grid-column: 2 / -1;
`