import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { cls } from 'utilities/dom'
import { ImportButton } from './s'
import { UploadCloud } from 'styled-icons/feather'
import { Scrollable as Modal } from 'modals/generic'
import ImportHandbookContent from 'pages/handbook/modals/import'

const ImportHandbook = ({ show: showByDefault = false, pick, className, salt }) => {
    const [importing, setImporting] = useState(showByDefault)

    className = cls([
        className,
        'constructive'
    ])

    return (
        <>
            <div>
                <ImportButton
                    className={className}
                    onClick={() => setImporting(true)}
                    disabled={importing}>
                    <UploadCloud size={16} />
                    <FormattedMessage
                        id="action_import"
                        defaultMessage="Import" />
                </ImportButton>
            </div>
            <Modal
                show={importing}
                dismiss={() => setImporting(false)}
                salt={salt}>
                <ImportHandbookContent
                    onDone={imported => {
                        if(imported) {
                            pick({
                                ...imported,
                                type: 'json'
                            })
                        }

                        setImporting(false)
                    }} />
            </Modal>
        </>
    )
}

export default ImportHandbook