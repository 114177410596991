import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useTasks } from 'contexts/tasks'
import { useMe } from 'contexts/me'
import { unpackField } from 'utilities/person'
import { cls } from 'utilities/dom'
import { ModalHeader } from 'modals/generic'
import { Entities, Person } from './s'
import Paragraph from 'components/typography/paragraph'
import { InlineButton } from 'components/button'

const ViewTaskHero = ({ task, setTask, dismiss }) => {
    const [claiming, setClaiming] = useState(false)

    const { assignTask } = useTasks()
    const { me } = useMe()

    const {
        id,
        title,
        assignedGroup,
        assignedTo
    } = task

    const claimTask = async () => {
        setClaiming(true)
        const { ok, response } = await assignTask(task.id, me.id)
        setClaiming(false)

        ok && setTask(response)
    }

    let memberOfAssignedGroup = false
    if(assignedGroup) {
        const groupIds = (unpackField(me[`${assignedGroup.type}s`]) ?? [])
            .map(({ id }) => id)

        memberOfAssignedGroup = groupIds.includes(assignedGroup.id)
    }

    return (
        <>
            <ModalHeader
                heading={title}
                dismiss={dismiss} />
            {(assignedGroup || assignedTo) && (
                <Paragraph
                    as="div"
                    className="interpoint-divider">
                    {!!assignedGroup && (
                        <Entities
                            entities={[assignedGroup]}
                            preview={true}
                            showAggregateIcon={true}
                            className="compact"
                            salt={`${id}:${assignedGroup.type}s:group`} />
                    )}
                    {!!assignedTo && (
                        <Person
                            who={assignedTo}
                            asLink={true}
                            size={24}
                            className="compact" />
                    )}
                    {(!assignedTo && memberOfAssignedGroup) && (
                        <InlineButton
                            onClick={claimTask}
                            className={cls([
                                'constructive',
                                claiming && 'loading'
                            ])}>
                            <FormattedMessage
                                id="action_assign_to_me"
                                defaultMessage="Assign to me" />
                        </InlineButton>
                    )}
                </Paragraph>
            )}
        </>
    )
}

export default ViewTaskHero