import React from 'react'
import CompetenceProfileProvider, { useCompetenceProfile } from 'contexts/competence-profile'
import View from './view'
import Edit from './edit'
import Loader from 'components/loader'

const ActingProfile = ({ mode = 'view', ...props }) => {
    const { hasFetched } = useCompetenceProfile()

    if(!hasFetched && !!props.profile?.id) {
        return <Loader />
    }

    const Profile = {
        view: View,
        edit: Edit
    }[mode]

    return <Profile {...props} />
}

export default props => {
    const wrapperProps = {
        ...(!!props.profile?.id ? { id: props.profile.id } : null),
        fetchOnMount: !!props.profile?.id
    }

    return (
        <CompetenceProfileProvider {...wrapperProps}>
            <ActingProfile {...props} />
        </CompetenceProfileProvider>
    )
}
