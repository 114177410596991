import React, { Component, createContext, useContext } from 'react'
import { useI18n } from 'contexts/i18n'
import { useAuth } from 'contexts/auth'
import { getChannel } from 'utilities/broadcaster'
import { requestAccess } from 'utilities/auth'
import { get } from 'api'
import debounce from 'lodash.debounce'

export const IntegrationsContext = createContext()

class IntegrationsProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500 })

        this.state = {
            integrations: [],

            fetch: this.fetch,
            updateIntegration: this.updateIntegration,

            getUserIntegrations: this.getUserIntegrations,
            getActiveIntegrations: this.getActiveIntegrations,
            getActiveUserIntegrations: this.getActiveUserIntegrations,
            checkHasActiveConnectionToIntegrations: this.checkHasActiveConnectionToIntegrations,
            checkHasActiveConnectionToAnyIntegration: this.checkHasActiveConnectionToAnyIntegration,

            authorized: props.auth?.status === 'authorized',
            fetching: false,
            hasFetched: !!props?.integrations?.length
        }

        this.syncer = getChannel('integrations')
    }

    componentDidMount() {
        this.fetchDebounced()
        this.syncer.onmessage = ({ data }) => void this.setState(data)
    }

    componentDidUpdate(props, { authorized: wasAuthorized }) {
        const isAuthorized = this.props.auth?.status === 'authorized'

        if(!wasAuthorized && isAuthorized) {
            this.setState({ authorized: true }, () => this.fetch(true))
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.syncer.close()
    }

    fetch = async (force = false) => {
        const { ok: tokensOk, response: tokens } = await requestAccess({ bounce: false })
        if(!tokensOk || !tokens?.accessToken) {
            return
        }

        if(this.state.fetching && !force) {
            return
        }

        if(force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { ok, response } = await get({
            path: '/integrations',
            signal: this.fetchController.signal
        })

        if(ok) {
            const state = {
                integrations: response.items,
                fetching: false,
                hasFetched: true
            }

            this.setState(state, () => this.syncer.postMessage(state))
        }
    }

    updateIntegration = (type, data) => void this.setState(({ integrations }) => {
        let shouldResort = false

        integrations = [...integrations].map(integration => {
            if(integration.type === type) {
                if('status' in data && integration.status !== data.status) {
                    shouldResort = true
                }

                return {
                    ...integration,
                    ...data
                }
            }

            return integration
        })

        if(shouldResort) {
            integrations = integrations.sort((one, two) => {
                const oneInUse = one.status !== 'not_configured'
                const twoInUse = two.status !== 'not_configured'

                if(oneInUse === twoInUse) {
                    return one.type.localeCompare(two.type, this.props.i18n.locale, { sensitivity: 'base' })
                }

                return oneInUse ?
                    -1 :
                    1
            })
        }

        return { integrations }
    }, () => this.syncer.postMessage({ integrations: this.state.integrations }))

    getUserIntegrations = (filter = i => i) => this.state.integrations
        ?.filter(({ scopes }) => !!scopes?.userProfile)
        ?.filter(filter) ?? []

    getActiveIntegrations = (filter = i => i) => this.state.integrations
        ?.filter(({ status }) => status === 'active')
        ?.filter(filter) ?? []

    getActiveUserIntegrations = (filter = i => i) => this.state.integrations
        ?.filter(({ status, scopes }) => (status === 'active') && !!scopes?.userProfile)
        ?.filter(filter) ?? []

    checkHasActiveConnectionToIntegrations = (...identifiers) => {
        return identifiers.reduce((accumulator, identifier) => {
            const integration = this.state.integrations?.find(({ type }) => type === identifier)

            return {
                ...accumulator,
                [identifier]: !!integration?.status ?
                    integration.status === 'active' :
                    false
            }
        }, {})
    }

    checkHasActiveConnectionToAnyIntegration = () => !!this.getActiveIntegrations().length

    render() {
        const { children = null } = this.props

        return (
            <IntegrationsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </IntegrationsContext.Provider>
        )
    }
}

export const useIntegrations = () => useContext(IntegrationsContext)

export default props => {
    const i18n = useI18n()
    const auth = useAuth()

    return (
        <IntegrationsProvider
            {...props}
            i18n={i18n}
            auth={auth} />
    )
}