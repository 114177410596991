import styled from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import { Scrollable } from 'modals/generic'
import { Grid } from 'components/grid'
import FeedbackQuestionsBase from 'app/layouts/private/help/feedback-questions'
import { ParagraphSmall } from 'components/typography/paragraph'
import LinkBase from 'components/link'

export const Modal = styled(Scrollable)`
    padding: 0;
`

export const Header = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--huma-color-border-default);
    padding: 20px 32px;

    ${belowTablet`
        padding: 12px 24px;
    `}

    svg {
        ${svgStroke()}
    }
`

export const HeaderColumns = styled(Grid)`
    grid-auto-flow: column;
    grid-template-columns: 24px max-content;
    align-items: center;
    gap: 16px;
`

export const Emoji = styled.div`
    margin-inline-start: -4px;

    font-size: 20px;
    line-height: 24px;
    text-align: center;
`

export const Content = styled.div`
    padding: 24px 32px;

    &:last-child {
        padding-block-end: 40px;
    }

    ${belowTablet`
        padding: 16px 24px;

        &:last-child {
            padding-block-end: 24px;
        }
    `}
`

export const FeedbackQuestions = styled(FeedbackQuestionsBase)`
    margin: 0 0 40px;

    ${belowTablet`
        margin: 0 0 24px;
    `}
`

export const Footer = styled(Grid)`
    justify-content: center;

    padding: 0 32px 24px;

    text-align: center;

    ${belowTablet`
        padding: 0 24px 16px;
    `}
`

export const Paragraph = styled(ParagraphSmall)`
    max-width: 384px;

    ${belowTablet`
        max-width: auto;
    `}
`

export const Link = styled(LinkBase)`
    text-decoration: underline;
`

export const ConfettiCanvas = styled.canvas`
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    border-radius: 8px;
    pointer-events: none;
`