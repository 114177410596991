import { useEffect } from 'react'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { size } from 'utilities/object'

export const hashify = (id, type) => `${type}-${id}`

export const usePinnedTopics = (options = {}) => {
    const { fetch = false } = options

    const {
        handbook,
        fetchPinnedTopics
    } = useHandbook()

    const {
        check,
        checkFeature
    } = useAccess()

    const manageAccess = check('handbook:manage')
    const pinnedAccess = checkFeature('handbook-featured')

    useEffect(() => {
        if(fetch && pinnedAccess) {
            fetchPinnedTopics()
        }
    }, [pinnedAccess, fetch])

    const { pinnedTopics: pinnedTopicsObject = {} } = handbook ?? {}

    if(!pinnedAccess || !size(pinnedTopicsObject)) {
        return []
    }

    const pinnedTopics = Object.values(pinnedTopicsObject)
        .filter(({ status }) => status !== 'draft' || manageAccess)
        .sort(({ updatedDate: a }, { updatedDate: b }) => new Date(b) - new Date(a))

    return { pinnedTopics }
}

export const getEnabledCategoriesAndTopics = categories => categories
    .filter(({ enabled }) => enabled)
    .map(({ topics, ...category }) => ({
        ...category,
        topics: topics.filter(({ enabled }) => enabled)
    }))