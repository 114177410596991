import styled from 'styled-components'
import { fromLaptop, belowLaptop } from 'utilities/styled'
import { Ghost as ButtonBase } from 'components/button'
import { simpleCss } from 'components/button/s'
import ShortcutBase from 'components/tiptap/help/shortcut'

export const FromBreakpoint = styled.div`
    flex: 1;

    ${belowLaptop`
        display: none;
    `}
`

export const BelowBreakpoint = styled.div`
    ${fromLaptop`
        display: none;
    `}
`

export const Button = styled(ButtonBase)`
    ${fromLaptop`
        border: 1px solid var(--huma-color-border-default);
        width: min(220px, 60%);
        padding-inline: 8px;

        @supports (font-size-adjust: 0.47) {
            > span:first-of-type {
                font-size-adjust: 0.47; /* x-height / font-size */
            }
        }

        @supports not (font-size-adjust: 0.47) {
            > span:first-of-type {
                display: block;

                margin-block-end: -1px;
            }
        }

        &:where(:hover, :focus) {
            border-color: var(--huma-color-border-default-focus);
        }
    `}

    ${belowLaptop`
        ${simpleCss}
    `}
`

export const Shortcut = styled(ShortcutBase)`
    margin-inline-start: auto;
`