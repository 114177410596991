import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { ModalHeading } from 'components/typography/heading'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain } from 'components/button'

const Successful = ({ what, dismiss }) => {
    const { formatMessage } = useIntl()

    if(!what) {
        return null
    }

    const heading = {
        publish: {
            id: 'handbooks_action_publish',
            defaultMessage: 'Publish handbook'
        },
        handbook: {
            id: 'notification_toggle_label',
            defaultMessage: 'Send notification'
        },
        category: {
            id: 'notification_toggle_label',
            defaultMessage: 'Send notification'
        },
        topic: {
            id: 'notification_toggle_label',
            defaultMessage: 'Send notification'
        }
    }[what]

    const message = {
        publish: {
            id: 'handbooks_published_message_notified',
            defaultMessage: 'You have successfully published the handbook and people have been notified.'
        },
        handbook: {
            id: 'handbooks_notification_message',
            defaultMessage: 'Notification sent.'
        },
        category: {
            id: 'handbooks_notification_message',
            defaultMessage: 'Notification sent.'
        },
        topic: {
            id: 'handbooks_notification_message',
            defaultMessage: 'Notification sent.'
        }
    }[what]

    return (
        <>
            <ModalHeading>
                <FormattedMessage {...heading} />
            </ModalHeading>
            <Message
                className="compact"
                type="success"
                message={formatMessage(message)} />
            <Actions className="centered">
                <Plain onClick={dismiss}>
                    <FormattedMessage
                        id="action_done"
                        defaultMessage="Done" />
                </Plain>
            </Actions>
        </>
    )
}

export default Successful
