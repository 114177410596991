import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import EquipmentTypeProvider, { useEquipmentType } from 'contexts/equipment-type'
import { useEquipmentContext } from 'pages/equipment/utilities'
import { useI18n } from 'contexts/i18n'
import { size } from 'utilities/object'
import Loader from 'components/loader'
import Form from 'components/form/controller'
import { Heading } from './s'
import SymbolField from 'components/form/field/symbol'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import ArrayField from 'components/form/field/array'
import CheckboxField from 'components/form/field/checkbox'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { symbol as fallbackSymbol } from 'pages/equipment'

const EditEquipmentType = ({ wait, context = 'types', dismiss = null, onDone, salt }) => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()

    const typeContext = useEquipmentType()

    const {
        addType,
        updateType
    } = useEquipmentContext(context)

    const [working, setWorking] = useState(false)

    if(!typeContext?.type && wait) {
        return <Loader />
    }

    const {
        id,
        name,
        symbol,
        description,
        variants,
        requireFromDate = false,
        requireToDate = false,
        requireDocumentation = false,
        requireSerialNumber = false
    } = typeContext?.type ?? {}

    const addOrUpdate = async (body, { resetTouched }) => {
        setWorking(true)

        const addOrUpdateType = id ?
            updateType :
            addType

        const { ok, response } = await addOrUpdateType(body, id)

        setWorking(false)

        if(ok) {
            resetTouched()
            onDone?.(response)
        }
    }

    const variantOptions = !!variants?.length ? variants.sort((one, two) => {
        return one.localeCompare(two, locale, { sensitivity: 'base' })
    }) : []

    salt = `${salt}:edit`

    return (
        <>
            <Heading>
                <FormattedMessage
                    id={id ?
                        'equipment_type_action_update' :
                        'equipment_type_action_add'
                    }
                    defaultMessage={id ?
                        'Update equipment type' :
                        'Add equipment type'
                    } />
            </Heading>
            <Form
                onSubmit={addOrUpdate}
                layout="vertical">
                {({ values, touched, errors, trigger }) => (
                    <>
                        <SymbolField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_icon',
                                defaultMessage: 'Icon'
                            })}
                            name="symbol"
                            field={{
                                value: symbol ?? fallbackSymbol,
                                include: id ? 'touched' : 'always',
                                unsettable: false
                            }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="name"
                            field={{
                                value: name ?? '',
                                required: true,
                                include: id ? 'touched' : 'always'
                            }}
                            controlProps={{
                                placeholder: formatMessage({
                                    id: 'equipment_placeholder_name',
                                    defaultMessage: 'E.g. ’iPhone’ or ’Key card’'
                                })
                            }} />
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{
                                value: description ?? '',
                                include: id ? 'touched' : 'always'
                            }}
                            controlProps={{ maxLength: 2040 }}  />
                        <ArrayField
                            salt={salt}
                            label={formatMessage({
                                id: 'equipment_type_label_variants',
                                defaultMessage: 'Variants'
                            })}
                            name="variants"
                            field={{
                                value: variantOptions,
                                include: id ? 'touched' : 'always'
                            }} />
                        <CheckboxField
                            salt={salt}
                            label={formatMessage({
                                id: 'equipment_type_create_start_date_required',
                                defaultMessage: 'Require start date'
                            })}
                            name="requireFromDate"
                            field={{
                                value: requireFromDate,
                                include: id ? 'touched' : 'always'
                            }}
                            interaction="switch" />
                        <CheckboxField
                            salt={salt}
                            label={formatMessage({
                                id: 'equipment_type_create_end_date_required',
                                defaultMessage: 'Require end date'
                            })}
                            name="requireToDate"
                            field={{
                                value: requireToDate,
                                include: id ? 'touched' : 'always'
                            }}
                            interaction="switch" />
                        <CheckboxField
                            salt={salt}
                            label={formatMessage({
                                id: 'equipment_type_create_documentation_required',
                                defaultMessage: 'Require documentation'
                            })}
                            name="requireDocumentation"
                            field={{
                                value: requireDocumentation,
                                include: id ? 'touched' : 'always'
                            }}
                            interaction="switch" />
                        <CheckboxField
                            salt={salt}
                            label={formatMessage({
                                id: 'equipment_type_create_serial_number_required',
                                defaultMessage: 'Require serial number'
                            })}
                            name="requireSerialNumber"
                            field={{
                                value: requireSerialNumber,
                                include: id ? 'touched' : 'always'
                            }}
                            interaction="switch" />
                        {(!!id && !variants?.every(variant => values?.variants?.includes(variant))) && (
                            <Message
                                type="info"
                                message={formatMessage({
                                    id: 'equipment_type_create_info_variants',
                                    defaultMessage: 'Deleting a variant will not affect existing pieces of equipment.'
                                })} />
                        )}
                        <Actions>
                            <Plain
                                className="neutral"
                                onClick={() => {
                                    dismiss ? dismiss() : onDone()
                                }}
                                disabled={working}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${working ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors)}
                                ref={trigger}>
                                <FormattedMessage
                                    id={id ?
                                        'action_save' :
                                        'action_add'
                                    }
                                    defaultMessage={id ?
                                        'Save' :
                                        'Add'
                                    } />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => {
    const equipmentTypeContext = useEquipmentType()

    if(equipmentTypeContext || !props.type) {
        return <EditEquipmentType {...props} />
    }
    return (
        <EquipmentTypeProvider id={props.type.id}>
            <EditEquipmentType
                {...props}
                wait={true} />
        </EquipmentTypeProvider>
    )
}