import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const IconWrapper = styled.div`
    margin-inline-end: 8px;
    border-radius: 50%;

    svg {
        ${svgStroke()}
    }
`