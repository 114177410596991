import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import SymbolBase from 'components/symbol'
import Paragraph from 'components/typography/paragraph'
import { Plain } from 'components/button'

export const ConfigurationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 8px;
    border: 1px solid var(--huma-color-border-default);
    padding: 16px;
`

export const Types = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    line-height: 20px;
`

export const TypesIcon = styled.div`
    box-sizing: content-box;

    border-radius: 50%;
    border: 2px dashed var(--huma-color-border-bold);
    width: 16px;
    height: 16px;
`

export const Group = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    column-gap: 16px;

    line-height: 20px;

    ${svgStroke()}

    &.grid {
        display: grid;
        grid-template-rows: 24px 20px;
        grid-template-columns: 40px auto min-content;
    }
`

export const Symbol = styled(SymbolBase)`
    grid-row: 1 / -1;
`

export const Name = styled(Paragraph)`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
`

export const PreviewButton = styled(Plain)`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
`