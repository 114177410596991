import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Rows } from 'components/flex'
import { OrderedList, ListItem } from 'components/list'
import FileBase from 'components/file'
import { Columns as FileColumns } from 'components/file/display/s'
import { Naked } from 'components/button'

export const Documentation = styled(Rows)`
    overflow: hidden;

    margin-block-end: 24px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
`

export const List = styled(OrderedList)`
    min-width: 120px;
`

export const Item = styled(ListItem)`
    &:not(:last-child) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }

    svg {
        ${svgStroke()}
    }
`

export const File = styled(FileBase)`
    margin-block-end: 0;
    transition: background-color .2s ease;

    ${FileColumns} {
        grid-template-columns: minmax(0, auto) 16px;
    }

    &:hover {
        background-color: var(--huma-color-surface-constructive-minimal);

        color: var(--huma-color-foreground-default);

        &.file > ${FileColumns} {
            box-shadow: none;

            color: var(--huma-color-foreground-default);
        }
    }

    &.file > ${FileColumns} {
        box-shadow: none;
    }
`

export const ShowAllButton = styled(Naked)`
    display: grid;
    grid-template-columns: 1fr auto;

    border-top: 1px solid var(--huma-color-border-default);
    height: auto;
    padding: 16px;

    transition: background-color .2s ease;

    text-align: start;

    &:where(:hover, :focus) {
        background-color: var(--huma-color-surface-minimal);
    }
`

export const ShowAllCount = styled.span`
    color: var(--huma-color-foreground-subtle);
`