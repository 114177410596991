import React, { Component, createContext, useContext } from 'react'
import { get, post, patch, remove } from 'api'
import PubSub from 'pubsub-js'
import {
    parseISO,
    addYears, subYears, differenceInYears,
    addDays, subDays,
    isWithinInterval
} from 'date-fns'
import { getDate } from 'utilities/date-time'
import { invertSign } from 'utilities/number'
import { useAbsenceStats } from 'contexts/absence-stats'
import {
    enrichEntry, getEffectiveTimeWindow,
    isPast, isFuture
} from 'pages/absence/utilities'

const AbsenceUserPeriodsContext = createContext()

class AbsenceUserPeriodsProvider extends Component {
    constructor(props) {
        super(props)

        !!props?.userId && this.setUserId(props.userId, false)
        !!props?.typeId && this.setTypeId(props.typeId, false)

        this.fetchController = new AbortController()

        this.state = {
            periods: [],

            fetchPeriods: this.fetch,
            setUserId: this.setUserId,
            setTypeId: this.setTypeId,

            updateAssignment: this.updateAssignment,
            removeAssignment: this.removeAssignment,

            makeAdjustment: this.makeAdjustment,
            removeAdjustment: this.removeAdjustment,

            error: null,
            resetError: () => this.setError(null),

            fetching: false,
            hasFetched: false,

            stats: {
                filter: props.stats?.filter ?? {},
                entries: [],

                updateEntry: this.updateStatsEntry,

                fetching: false,
                hasFetched: false
            }
        }

        this.refreshSubscription = PubSub.subscribe('absenceUserPeriods.refresh', () => {
            this.setState({
                periods: [],
                fetching: false,
                hasFetched: false,
                stats: {
                    ...this.state.stats,
                    entries: [],
                    fetching: false,
                    hasFetched: false
                }
            }, () => this.fetch(true))
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props

        fetchOnMount && this.fetch()
    }

    componentDidUpdate({ userId, type }) {
        const userIdChanged = userId !== this.props.userId
        const typeChanged = type !== this.props.type

        if(userIdChanged) {
            this.setUserId(this.props.userId, false)
        }

        if(typeChanged) {
            this.setTypeId(this.props.typeId, false)
        }

        if(userIdChanged || typeChanged) {
            this.fetch(true)
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        PubSub.unsubscribe(this.refreshSubscription)
    }

    fetch = async (force = false) => {
        const { fetchStats = false } = this.props

        const {
            fetching,
            hasFetched,
            stats
        } = this.state

        const {
            filter,
            fetching: fetchingStats,
            hasFetched: hasFetchedStats
        } = stats

        if(!this.userId || fetching || fetchingStats || (!force && hasFetched && hasFetchedStats)) {
            return
        }

        if(force || fetching || fetchingStats) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            stats: {
                ...stats,
                fetching: true
            }
        })

        const { ok, response: periods } = await get({
            path: `/absence/policies/users/${this.userId}`,
            params: { type: this.typeId }
        })

        if(ok && periods) {
            if(fetchStats) {
                const { ok, response } = await get({
                    path: `/absence/stats/${this.userId}`,
                    params: {
                        ...(this.typeId ? { type: this.typeId } : null),
                        ...filter
                    },
                    signal: this.fetchController.signal
                })

                this.setState({
                    stats: {
                        ...stats,
                        ...(ok && response ? {
                            entries: expandStats(response, periods)
                        } : null),
                        fetching: false,
                        hasFetched: true
                    }
                })
            }

            this.setState({
                periods: periods.map(period => {
                    const effectiveTimeWindow = getEffectiveTimeWindow(period)

                    return {
                        ...period,
                        effectiveTimeWindow
                    }
                }),
                fetching: false,
                hasFetched: true
            })
        }

        return { ok, response: periods }
    }

    updateAssignment = async (body, assignmentId) => {
        const { ok, response } = await patch({
            path: `/absence/policies/assignments/${assignmentId}`,
            body
        })

        if(!ok) {
            this.setError(response)
        }

        return { ok, response }
    }

    removeAssignment = async id => {
        const { ok } = await remove({
            path: `/absence/policies/assignments/${id}`,
            returnsData: false
        })

        return { ok }
    }

    makeAdjustment = async body => {
        const { ok, response } = await post({
            path: `/absence/adjustments/${this.userId}`,
            body
        })

        if(ok) {
            this.setState(({ stats }) => {
                const { entries: previousEntries } = stats

                const {
                    fromPeriodStartDate = null,
                    toPeriodStartDate,
                    type
                } = body

                let entries

                if(type === 'manual') {
                    entries = previousEntries.map(entry => {
                        const {
                            policy,
                            effectiveTimeWindow
                        } = entry

                        if(entry.type.id !== body.absenceTypeId || !effectiveTimeWindow) {
                            return entry
                        }

                        if(effectiveTimeWindow.from === toPeriodStartDate) {
                            return {
                                ...entry,
                                policy: {
                                    ...policy,
                                    quota: policy.quota + body.days,
                                    adjustments: [
                                        ...(policy?.adjustments ?? []),
                                        response
                                    ]
                                }
                            }
                        }

                        return entry
                    })
                }

                if(type === 'transfer') {
                    entries = previousEntries.map(entry => {
                        const {
                            policy,
                            effectiveTimeWindow
                        } = entry

                        if(entry.type.id !== body.absenceTypeId || !effectiveTimeWindow) {
                            return entry
                        }

                        if(effectiveTimeWindow.from === fromPeriodStartDate) {
                            return {
                                ...entry,
                                policy: {
                                    ...policy,
                                    quota: policy.quota - body.days,
                                    adjustments: [
                                        ...(policy?.adjustments ?? []),
                                        response
                                    ]
                                }
                            }
                        }

                        if(effectiveTimeWindow.from === toPeriodStartDate) {
                            return {
                                ...entry,
                                policy: {
                                    ...policy,
                                    quota: policy.quota + body.days,
                                    adjustments: [
                                        ...(policy?.adjustments ?? []),
                                        response
                                    ]
                                }
                            }
                        }

                        return entry
                    })
                }

                return {
                    stats: {
                        ...stats,
                        entries
                    }
                }
            })
        }

        !!this.props?.userStats && this.props.userStats.makeAdjustmentLocally(body, response)

        return { ok, response }
    }

    removeAdjustment = async adjustmentId => {
        const { ok } = await remove({
            path: `/absence/adjustments/${this.userId}/${adjustmentId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ stats }) => {
                const { entries: previousEntries } = stats

                const adjustment = previousEntries
                    .flatMap(entry => entry.policy?.adjustments ?? [])
                    .find(({ id }) => id === adjustmentId)

                const entries = previousEntries.map(entry => {
                    let { days } = adjustment

                    const {
                        policy,
                        effectiveTimeWindow,
                        hasFetched
                    } = entry

                    if(policy?.adjustments?.find(({ id }) => id === adjustmentId)) {
                        if(
                            adjustment.type === 'transfer' &&
                            !!hasFetched &&
                            adjustment.fromPeriodStartDate === effectiveTimeWindow.from
                        ) {
                            days = invertSign(days)
                        }

                        return {
                            ...entry,
                            policy: {
                                ...policy,
                                quota: policy.quota - days,
                                adjustments: policy.adjustments.filter(({ id }) => id !== adjustmentId)
                            }
                        }
                    }

                    return entry
                })

                return {
                    stats: {
                        ...stats,
                        entries
                    }
                }
            })
        }

        !!this.props?.userStats && this.props.userStats.removeAdjustmentLocally(adjustmentId)

        return { ok }
    }

    updateStatsEntry = async (body, index) => {
        this.setState(({ stats }) => ({
            stats: {
                ...stats,
                entries: stats.entries.map((entry, i) => {
                    if(i === index) {
                        if(body.effectiveTimeWindow.from !== entry.effectiveTimeWindow.from || body.effectiveTimeWindow.to !== entry.effectiveTimeWindow.to) {
                            return {
                                ...entry,
                                hasFetched: true
                            }
                        }

                        return {
                            ...entry,
                            ...body,
                            policy: {
                                ...entry.policy,
                                ...body.policy
                            },
                            hasFetched: true
                        }
                    }

                    return entry
                })
            }
        }))
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                values,
                errorCode,
                errorMessage
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replaceAll('-', '_'),
                errorMessage,
                entries: values.map(enrichEntry)
            }
        }

        this.setState({ error })
    }

    setUserId = (id, fetch = true) => {
        this.userId = id
        fetch && this.fetch(true)

        !id && this.setState({ periods: [] })
    }

    setTypeId = (id, fetch = true) => {
        this.typeId = id
        fetch && this.fetch(true)
    }

    render() {
        const { children = null } = this.props

        return (
            <AbsenceUserPeriodsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AbsenceUserPeriodsContext.Provider>
        )
    }
}

const getCurrentPeriod = (periods = []) => {
    if(periods.length === 0) return null

    if(periods.length === 1) return periods[0]

    const now = new Date()

    const currentPeriod = periods.find(period => {
        const {
            fromDate: start,
            toDate: end
        } = period

        if(!start && end) {
            return isFuture({ end }, now)
        }

        if(start && !end) {
            return isPast({ start }, now)
        }

        return isPast({ start }, now) && isFuture({ end }, now)
    })

    return currentPeriod ?? periods[0]
}

const isSameTimeWindow = (a, b) => a.month === b.month && a.day === b.day

const expandStats = (stats, periods) => {
    periods = periods.sort(({ fromDate: aFromDate }, { fromDate: bFromDate }) => {
        if(!aFromDate && !bFromDate) {
            return 0
        }

        if(!aFromDate) {
            return 1
        }

        if(!bFromDate) {
            return -1
        }

        return new Date(aFromDate) - new Date(bFromDate)
    })

    const currentPeriod = getCurrentPeriod(periods)
    const currentPeriodIndex = periods.findIndex(period => period.id === currentPeriod?.id)
    const [todaysStat] = stats

    const now = new Date()

    delete currentPeriod.id
    delete currentPeriod.typeId

    let newStats = []

    const {
        effectiveTimeWindow,
        policy
    } = todaysStat

    periodsLoop:
        for(let index = 0; index < periods.length; index++) {
            const period = periods[index]

            const fromDateFormatted = period.fromDate ? parseISO(period.fromDate) : null
            const toDateFormatted = period.toDate ? parseISO(period.toDate) : null

            if(!fromDateFormatted) {
                if(!!isSameTimeWindow(period.policy.timeWindow, policy.timeWindow)) {
                    if(!!toDateFormatted) {
                        const {
                            day,
                            month
                        } = period.policy.timeWindow

                        const lastFromDate = getDate(new Date(toDateFormatted.getFullYear(), month - 1, day))
                        const lastToDate = getDate(period.toDate)

                        newStats.push({
                            ...period,
                            ...((period.toDate === effectiveTimeWindow.to || isWithinInterval(toDateFormatted, {
                                start: new Date(effectiveTimeWindow.from),
                                end: new Date(effectiveTimeWindow.to)
                            })) ? {
                                ...todaysStat,
                                policy: {
                                    ...period.policy,
                                    ...todaysStat.policy
                                },
                                hasFetched: true
                            } : {
                                hasFetched: false
                            }),
                            effectiveTimeWindow: {
                                from: lastFromDate,
                                to: lastToDate
                            },
                            type: todaysStat.type
                        })

                        for(let j = 0; j < 5; j++) {
                            const from = getDate(subYears(new Date(lastFromDate), j + 1))
                            const to = getDate(subYears(subDays(new Date(lastFromDate), 1), j))

                            if(new Date(periods[index + 1]?.fromDate)?.getFullYear() === new Date(to).getFullYear()) {
                                continue periodsLoop
                            }

                            newStats.unshift({
                                ...period,
                                ...((from >= effectiveTimeWindow.from && to <= effectiveTimeWindow.to) ? {
                                    ...todaysStat,
                                    policy: {
                                        ...period.policy,
                                        ...todaysStat.policy
                                    },
                                    hasFetched: true
                                } : {
                                    hasFetched: false,
                                }),
                                effectiveTimeWindow: {
                                    from,
                                    to
                                },
                                type: todaysStat.type
                            })
                        }
                    } else {
                        for(let j = index; j < 5; j++) {
                            newStats.unshift({
                                ...period,
                                effectiveTimeWindow: {
                                    from: getDate(subYears(new Date(effectiveTimeWindow.from), j + 1)),
                                    to: getDate(subYears(new Date(effectiveTimeWindow.to), j + 1))
                                },
                                type: todaysStat.type,
                                hasFetched: false
                            })
                        }
                    }
                } else {
                    newStats.push({
                        ...period,
                        effectiveTimeWindow: {
                            from: getDate(subYears(addDays(new Date(period.toDate), 1), 1)),
                            to: period.toDate
                        },
                        type: todaysStat.type,
                        hasFetched: false,
                        navigable: false
                    })
                }
            }

            if(
                periods[index - 1]?.toDate &&
                period.fromDate &&
                !!period.policy?.allowTransfer &&
                !isSameTimeWindow(periods[index - 1].policy.timeWindow, period.policy.timeWindow) &&
                getDate() < getDate(period.fromDate)
            ) {
                newStats.push({
                    ...period,
                    effectiveTimeWindow: {
                        from: period.fromDate,
                        to: period.toDate ?
                            getDate(toDateFormatted) :
                            getDate(subDays(addYears(fromDateFormatted, 1), 1))
                    },
                    type: todaysStat.type,
                    hasFetched: false,
                    navigable: false
                })

                break periodsLoop
            }

            if(!fromDateFormatted && !toDateFormatted) {
                newStats.push({
                    ...period,
                    ...todaysStat,
                    policy: {
                        ...period.policy,
                        ...todaysStat.policy
                    },
                    effectiveTimeWindow,
                    type: todaysStat.type,
                    hasFetched: true
                })
            }

            if(!!fromDateFormatted && !!toDateFormatted && isSameTimeWindow(period.policy.timeWindow, policy.timeWindow)) {
                const years = differenceInYears(toDateFormatted, fromDateFormatted)

                const {
                    day,
                    month
                } = period.policy.timeWindow

                newStats.push({
                    ...period,
                    ...((period.fromDate === effectiveTimeWindow.from && period.toDate === effectiveTimeWindow.to) ? {
                        ...todaysStat,
                        policy: {
                            ...period.policy,
                            ...todaysStat.policy
                        },
                        hasFetched: true
                    } : {
                        hasFetched: false
                    }),
                    effectiveTimeWindow: {
                        from: period.fromDate,
                        to: getDate(subDays(
                                new Date(
                                    fromDateFormatted.getFullYear() + 1,
                                    month - 1,
                                    day
                                ),
                                1
                            ))
                    },
                    type: todaysStat.type
                })

                for(let j = 0; j < years; j++) {
                    const from = getDate(addYears(fromDateFormatted, j + 1))
                    const to = getDate(subDays(
                        new Date(
                            new Date(from).getFullYear() + 1,
                            month - 1,
                            day
                        ),
                        1
                    ))

                    if(
                        new Date(newStats[newStats.length - 1].toDate).getFullYear() === new Date(to).getFullYear() &&
                        !isWithinInterval(
                            now,
                            {
                                start: new Date(from),
                                end: new Date(period.toDate < to ? period.toDate : to)
                            }
                        )
                    ) {
                        continue
                    }

                    if(!!period.policy?.allowTransfer) {
                        newStats.push({
                            ...period,
                            ...((from >= effectiveTimeWindow.from && to <= effectiveTimeWindow.to) ? {
                                ...todaysStat,
                                policy: {
                                    ...period.policy,
                                    ...todaysStat.policy
                                },
                                hasFetched: true
                            } : {
                                hasFetched: false
                            }),
                            effectiveTimeWindow: {
                                from,
                                to: period.toDate < to ? period.toDate : to
                            },
                            type: todaysStat.type
                        })
                    }
                }
            }

            if(!toDateFormatted && isSameTimeWindow(period.policy.timeWindow, policy.timeWindow)) {
                if(!!fromDateFormatted) {
                    const {
                        day,
                        month
                    } = period.policy.timeWindow

                    const fromDateMonth = fromDateFormatted.getMonth() + 1
                    const fromDateDay = fromDateFormatted.getDate()

                    const firstFromDate = (day === fromDateDay && month === fromDateMonth) ?
                        getDate(period.fromDate) :
                        getDate(new Date(
                            fromDateFormatted.getFullYear() - 1,
                            month - 1,
                            day
                        ))

                    const firstToDate = getDate(subDays(addYears(new Date(firstFromDate), 1), 1))

                    if(period.fromDate >= firstFromDate && period.fromDate <= firstToDate) {
                        newStats.push({
                            ...period,
                            ...(isWithinInterval(now, {
                                start: new Date(firstFromDate),
                                end: new Date(firstToDate)
                            }) ? {
                                ...todaysStat,
                                policy: {
                                    ...period.policy,
                                    ...todaysStat.policy
                                },
                                hasFetched: true
                            } : {
                                hasFetched: false
                            }),
                            effectiveTimeWindow: {
                                from: firstFromDate,
                                to: firstToDate
                            },
                            type: todaysStat.type
                        })
                    }

                    const years = differenceInYears(new Date(effectiveTimeWindow.to), new Date(firstToDate)) + 5

                    for(let j = 0; j < years; j++) {
                        const from = getDate(addYears(addDays(new Date(firstToDate), 1), j))
                        const to = getDate(addYears(new Date(firstToDate), j + 1))

                        newStats.push({
                            ...period,
                            ...((from >= effectiveTimeWindow.from && to <= effectiveTimeWindow.to) ? {
                                ...todaysStat,
                                policy: {
                                    ...period.policy,
                                    ...todaysStat.policy
                                },
                                hasFetched: true
                            } : {
                                hasFetched: false,
                            }),
                            effectiveTimeWindow: {
                                from,
                                to
                            },
                            type: todaysStat.type
                        })
                    }
                } else {
                    for(let j = index; j < 5; j++) {
                        newStats.push({
                            ...period,
                            effectiveTimeWindow: {
                                from: getDate(addYears(new Date(effectiveTimeWindow.from), j + 1)),
                                to: getDate(addYears(new Date(effectiveTimeWindow.to), j + 1))
                            },
                            type: todaysStat.type,
                            hasFetched: false
                        })
                    }
                }
            }

            if(!isSameTimeWindow(period.policy.timeWindow, policy.timeWindow) && index !== currentPeriodIndex) {
                period.navigable = false
            }
        }

    // Add today’s stat if it doesn’t exist
    if(newStats.find(({ effectiveTimeWindow: { from, to } }) => {
        if(from > to) {
            return false
        }

        const withinInterval = isWithinInterval(now, {
            start: new Date(from),
            end: new Date(to)
        })

        return !withinInterval && from === effectiveTimeWindow.from && to === effectiveTimeWindow.to
    })) {
        const todaysStatIndex = newStats.findIndex(({ effectiveTimeWindow: { from } }) => effectiveTimeWindow.from < from)

        newStats.splice(todaysStatIndex, 0, {
            ...todaysStat,
            effectiveTimeWindow,
            type: todaysStat.type,
            hasFetched: true
        })
    }

    // Remove potential duplicates
    newStats = newStats.filter(({ effectiveTimeWindow: { from, to } }, index) => {
        const duplicate = newStats[index + 1]?.effectiveTimeWindow?.from === from || newStats[index + 1]?.effectiveTimeWindow?.to === to

        return !duplicate
    })

    return newStats
}

export default props => {
    const userStats = useAbsenceStats()

    return (
        <AbsenceUserPeriodsProvider
            {...props}
            userStats={userStats} />
    )
}

export const useAbsenceUserPeriods = () => useContext(AbsenceUserPeriodsContext)
