import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { safeFormat } from 'utilities/date-time'
import { currencies } from 'utilities/payment'
import { compact } from 'utilities/array'
import { getFractionDigits } from 'pages/people/pages/profile/panes/about/salary/utilities'
import {
    Wrapper,
    Date, Salary, Trend, Note, Meta
} from './s'
import SalaryStatus from './status'
import { periodUnitToPeriodShortMap } from '../'
import { Plain } from 'components/button'
import Tooltip from 'components/tooltip'

const SalaryRecord = ({ fromDate, toDate, periodUnit, amount, currency, note, nextRecord, fake = false }) => {
    const {
        formatNumber,
        formatMessage
    } = useIntl()
    const { dateLocale: locale } = useI18n()

    if(!amount) {
        return null
    }

    const from = fromDate ?
        safeFormat(fromDate, 'PP', { locale }) :
        ''
    const to = toDate ?
        safeFormat(toDate, 'PP', { locale }) :
        ''

    const currencyDisplay = currencies[currency] || 'code'

    const dateClassName = compact([
        (!!toDate && fromDate > toDate) && 'error'
    ])

    const calculatePercentageDifference = (oldValue, newValue) => (((newValue - oldValue) / oldValue) * 100).toFixed(2)

    const difference = (nextRecord && !nextRecord?.empty && nextRecord.currency === currency && nextRecord.periodUnit === periodUnit) ?
        calculatePercentageDifference(nextRecord.amount, amount) :
        null

    const amountConverted = !['monthly', 'yearly'].includes(periodUnit) ?
        amount :
        periodUnit === 'monthly' ?
            amount * 12 :
            amount / 12

    const [SalaryWrapper, salaryWrapperProps] = (['monthly', 'yearly'].includes(periodUnit) && !fake) ?
        [Tooltip, {
            content: formatMessage({
                id: 'salary_value_frequency',
                defaultMessage: '{salary} per {periodUnit}'
            }, {
                salary: formatNumber(amountConverted, {
                    style: 'currency',
                    currency,
                    currencyDisplay,
                    minimumFractionDigits: getFractionDigits(amountConverted),
                    maximumFractionDigits: getFractionDigits(amountConverted)
                }),
                periodUnit: formatMessage(periodUnitToPeriodShortMap?.[periodUnit === 'monthly' ? 'yearly' : 'monthly'])
            }),
            placement: 'top-start',
            delay: [500, 250]
        }] :
        [Fragment, null]

    return (
        <Wrapper>
            {(!!fromDate || !!toDate) && (
                <Date className={dateClassName}>
                    <FormattedMessage
                        id="date_range"
                        defaultMessage="{from} - {to}"
                        values={{
                            from: from && <span>{from}</span>,
                            to: to && <span>{to}</span>
                        }} />
                </Date>
            )}
            <SalaryWrapper {...salaryWrapperProps}>
                <Salary>
                    <FormattedMessage
                        id="salary_value_frequency"
                        defaultMessage="{salary} per {periodUnit}"
                        values={{
                            salary: formatNumber(amount, {
                                style: 'currency',
                                currency,
                                currencyDisplay,
                                minimumFractionDigits: getFractionDigits(amount),
                                maximumFractionDigits: getFractionDigits(amount)
                            }),
                            periodUnit: formatMessage(periodUnitToPeriodShortMap?.[periodUnit] ?? periodUnitToPeriodShortMap.default)
                        }} />
                </Salary>
            </SalaryWrapper>
            {difference && (
                <Trend
                    percentage={difference}
                    trend={difference > 0 ? 'up' : 'down'} />
            )}
            {!!note && <Note>{note}</Note>}
            <SalaryStatus
                fromDate={fromDate}
                toDate={toDate} />
        </Wrapper>
    )
}

export const NoRecord = ({ fromDate, toDate, setActing, editable }) => {
    const { dateLocale: locale } = useI18n()

    const from = fromDate ? safeFormat(fromDate, 'PP', { locale }) : ''
    const to = toDate ? safeFormat(toDate, 'PP', { locale }) : ''

    return (
        <Wrapper>
            {(!!fromDate || !!toDate) && (
                <Date>
                    <FormattedMessage
                        id="date_range"
                        defaultMessage="{from} - {to}"
                        values={{ from, to }} />
                </Date>
            )}
            <Meta>
                <span>
                    <FormattedMessage
                        id="salary_timeline_empty"
                        defaultMessage="No salary record" />
                </span>
                {editable && (
                    <Plain
                        className="constructive compact"
                        onClick={() => setActing({
                            mode: 'add',
                            fromDate,
                            toDate
                        })}>
                        <FormattedMessage
                            id="person_action_salary_add"
                            defaultMessage="Add salary" />
                    </Plain>
                )}
            </Meta>
        </Wrapper>
    )
}

export default SalaryRecord
