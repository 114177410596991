import React, { useRef, useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useAccess } from 'contexts/access'
import { useAppSignal } from 'hooks/app-signal'
import { openChat } from 'hooks/voiceflow'
import { Modal as BigModal, Header, HeaderColumns, Emoji, Content, FeedbackQuestions, Footer, Paragraph, Link, ConfettiCanvas } from './s'
import { H3 as Heading } from 'components/typography/heading'
import { Naked, Plain } from 'components/button'
import { X } from 'styled-icons/feather'
import Categories from 'components/service-onboarding/categories'
import Modal, { ModalHeader } from 'modals/generic'
import BodyText from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import confetti from 'canvas-confetti'

const ServiceOnboarding = () => {
    const { formatMessage } = useIntl()

    const canvas = useRef()

    const [show, setShow] = useState(false)
    const [showCompleted, setShowCompleted] = useState(false)
    const [skippingAllTodos, setSkippingAllTodos] = useState(false)
    const [skippedAllTodos, setSkippedAllTodos] = useState(false)

    const {
        progress,
        completed,

        skipAllTodoSteps
    } = useServiceOnboarding()

    const { check } = useAccess()

    useAppSignal({
        namespace: 'service-onboarding.show',
        action: () => setShow(true)
    })

    useEffect(() => {
        let showTimeout
        let confettiTimeout

        if(completed && !skippedAllTodos) {
            showTimeout = global.setTimeout(() => setShowCompleted(true), 1000)

            confettiTimeout = global.setTimeout(() => {
                canvas.current.confetti = canvas.current.confetti || confetti.create(canvas.current, { resize: true })

                const fire = (particleRatio, opts) => canvas.current.confetti({
                    origin: { y: 0.7 },
                    ...opts,
                    particleCount: Math.floor(200 * particleRatio)
                })

                fire(.25, {
                    spread: 26,
                    startVelocity: 55
                })

                fire(.2, { spread: 60 })

                fire(.35, {
                    spread: 100,
                    decay: .91,
                    scalar: .8
                })
                fire(.1, {
                    spread: 120,
                    startVelocity: 25,
                    decay: .92,
                    scalar: 1.2
                })

                fire(0.1, {
                    spread: 120,
                    startVelocity: 45
                })
            }, 1750)
        }

        return () => {
            !!showTimeout && global.clearTimeout(showTimeout)
            !!confettiTimeout && global.clearTimeout(confettiTimeout)
        }
    }, [completed, skippedAllTodos])

    const skipAllTodos = async () => {
        if(skippingAllTodos) {
            return
        }

        setSkippingAllTodos(true)
        const { ok } = await skipAllTodoSteps()
        setSkippingAllTodos(false)

        if(ok) {
            setSkippedAllTodos(true)
            setShow(false)
        }
    }

    const admin = check('organization:manage')

    return (
        <>
            <BigModal
                className="large"
                show={show}
                dismiss={() => setShow(false)}>
                {!!show && (
                    <>
                        <Header>
                            <HeaderColumns>
                                <Emoji>⚡️</Emoji>
                                <Heading className="compact">
                                    <FormattedMessage
                                        id="service_onboarding_progress_heading"
                                        defaultMessage="Get started with Huma" />
                                </Heading>
                            </HeaderColumns>
                            <Naked onClick={() => setShow(false)}>
                                <X size={24} />
                            </Naked>
                        </Header>
                        <Content>
                            <FeedbackQuestions onClick={() => setShow(false)} />
                            <Categories
                                mode="free"
                                onBeforeAction={() => setShow(false)}
                                salt="help-center" />
                        </Content>
                        {(progress < 100) && (
                            <Footer>
                                <Paragraph className="compact">
                                    <FormattedMessage
                                        id="service_onboarding_setup_tasks_action_hide"
                                        defaultMessage="Finished setting up? Then you can <link>hide the setup tasks</link>. You can find them again in the Help center."
                                        values={{
                                            link: chunks => (
                                                <Link
                                                    className="neutral"
                                                    onClick={skipAllTodos}>
                                                    {chunks}
                                                </Link>
                                            )
                                        }} />
                                </Paragraph>
                            </Footer>
                        )}
                    </>
                )}
            </BigModal>
            <Modal
                show={showCompleted}
                dismiss={() => setShowCompleted(false)}>
                {!!showCompleted && (
                    <>
                        <ConfettiCanvas ref={canvas} />
                        <ModalHeader
                            heading={formatMessage({
                                id: 'service_onboarding_completed_heading',
                                defaultMessage: 'Well done!'
                            })}
                            headingClassName="serif center" />
                        <BodyText className="center balance-text-rows">
                            <FormattedMessage
                                id="service_onboarding_completed_caption"
                                defaultMessage=" You should now be well-prepared and ready to start using Huma on your own. 🎉" />
                        </BodyText>
                        <BodyText className="center balance-text-rows">
                            <FormattedMessage
                                id={admin ?
                                    'service_onboarding_completed_message_admin' :
                                    'service_onboarding_completed_message_user'
                                }
                                defaultMessage={admin ?
                                    'If you need any help going forward, you can visit our Help center, or <link>start a chat with our support team<\/link>.' :
                                    'If you need any help going forward, you can visit our Help center.'
                                }
                                values={{
                                    link: chunks => (
                                        <Link
                                            className="neutral"
                                            onClick={openChat}>
                                            {chunks}
                                        </Link>
                                    )
                                }} />
                        </BodyText>
                        <Actions className="compact centered">
                            <Plain onClick={() => setShowCompleted(false)}>
                                <FormattedMessage
                                    id="action_close"
                                    defaultMessage="Close" />
                            </Plain>
                        </Actions>
                    </>
                )}
            </Modal>
        </>
    )
}

export default ServiceOnboarding