import React from 'react'
import { FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useOrganization } from 'contexts/organization'
import { useAccess } from 'contexts/access'
import { usePossum } from 'hooks/possum'
import { showJubileeGift } from 'pages/people/utilities'
import { getShortName, getListRepresentationFromProfile } from 'utilities/person'
import { Wrapper, Jubilee, Balloons, Balloon, Plain } from './s'
import { DisplayEmoji as Emoji } from 'components/symbol'
import Divider from 'components/divider'
import { Gift } from 'styled-icons/feather'

const Jubilees = () => {
    const { person } = usePerson()
    const { organization } = useOrganization()
    const { check } = useAccess()
    const usersManageAccess = check('users:manage')

    const possessify = usePossum()

    const { jubileesToday: jubilees = [] } = person

    if(!jubilees?.length) {
        return null
    }

    const hasNorwegianPhoneNumber = person?.phone?.value?.startsWith('+47')

    return (
        <Wrapper>
            {jubilees.map(({ type, years }) => (
                <Jubilee key={`profile:${person.id}:jubilee:${type}`}>
                    <Emoji
                        emoji={typeToEmojiCode[type]}
                        size={16} />
                    <span>
                        <FormattedMessage
                            id={(type === 'workAnniversary') ?
                                (years === 0) ?
                                    'profile_jubilees_work_anniversary_count_zero' :
                                    'profile_jubilees_work_anniversary_count' :
                                'profile_jubilees_birthday_count'
                            }
                            defaultMessage={(type === 'workAnniversary') ?
                                (years === 0) ?
                                    'It’s {pname} first work day at {organization}!' :
                                    '{years, plural, =0 {} =1 {{name} is celebrating their one year anniversary at {organization}!} other {{name} is celebrating their {years} years anniversary at {organization}!}}' :
                                '{years, plural, =0 {} =1 {} other {{name} is turning {years} years young today!}}'
                            }
                            values={{
                                years,
                                name: getShortName(person),
                                pname: possessify(getShortName(person)),
                                organization: organization.name
                            }} />
                    </span>
                </Jubilee>
            ))}
            {(!!hasNorwegianPhoneNumber && usersManageAccess) && (
                <>
                    <Divider />
                    <Plain
                        className="constructive compact"
                        icon={Gift}
                        onClick={() => showJubileeGift({
                            ...getListRepresentationFromProfile(person),
                            ...(jubilees.find(({ type }) => type === 'birthday') ? { birthday: jubilees.find(({ type }) => type === 'birthday').originalDate } : null)
                        })}>
                        <FormattedMessage
                            id="person_action_send_gift"
                            defaultMessage="Send a gift" />
                    </Plain>
                </>
            )}
            <Balloons>
                <Balloon />
                <Balloon />
            </Balloons>
        </Wrapper>
    )
}

const typeToEmojiCode = {
    birthday: 'balloon',
    workAnniversary: 'tada'
}

export default Jubilees