export const categoryToI18nMap = {
    data_privacy: 'deviation_category_data_privacy',
    environment: 'deviation_category_environment',
    improvement_suggestion: 'deviation_category_improvement_suggestion',
    material_damage: 'deviation_category_material_damage',
    near_miss: 'deviation_category_near_miss',
    personal_injury: 'deviation_category_personal_injury',
    quality_deviation: 'deviation_category_quality_deviation',
    violation_of_external_rules: 'deviation_category_violation_of_external_rules',
    violation_of_internal_rules: 'deviation_category_violation_of_internal_rules',
    other: 'deviation_category_other'
}

export const riskLevelLabels = {
    critical: {
        id: 'deviation_risk_level_critical',
        defaultMessage: 'Critical'
    },
    high: {
        id: 'deviation_risk_level_high',
        defaultMessage: 'High'
    },
    medium: {
        id: 'deviation_risk_level_medium',
        defaultMessage: 'Medium'
    },
    low: {
        id: 'deviation_risk_level_low',
        defaultMessage: 'Low'
    }
}

export const riskLevelClassNames = {
    critical: 'failed',
    high: 'failed',
    medium: 'passive',
    low: 'neutral'
}

export const caseLevelLabels = {
    not_started: {
        id: 'deviation_case_status_not_started',
        defaultMessage: 'Not started'
    },
    in_progress: {
        id: 'deviation_case_status_in_progress',
        defaultMessage: 'In progress'
    },
    on_hold: {
        id: 'deviation_case_status_on_hold',
        defaultMessage: 'On hold'
    },
    resolved: {
        id: 'deviation_case_status_completed',
        defaultMessage: 'Resolved'
    },
    dismissed: {
        id: 'deviation_case_status_dismissed',
        defaultMessage: 'Dismissed'
    }
}

export const resolvedCaseStatuses = ['resolved', 'dismissed']

export const caseLevelDescriptions = {
    not_started: {
        id: 'deviation_case_status_not_started_description',
        defaultMessage: 'The report has been registered, but no case handler has acknowledged it yet.'
    },
    in_progress: {
        id: 'deviation_case_status_in_progress_description',
        defaultMessage: 'A case handler has seen the report, and is working on it.'
    },
    on_hold: {
        id: 'deviation_case_status_on_hold_description',
        defaultMessage: 'The report is on hold.'
    },
    resolved: {
        id: 'deviation_case_status_completed_description',
        defaultMessage: 'The case has been resolved.'
    },
    dismissed: {
        id: 'deviation_case_status_dismissed_description',
        defaultMessage: 'The case has been dismissed.'
    }
}

export const caseLevelClassNames = {
    not_started: 'passive',
    in_progress: 'positive',
    on_hold: 'passive',
    resolved: 'active',
    dismissed: 'neutral'
}
