import { useRef, useLayoutEffect } from 'react'
import { useSegment } from 'contexts/segment'
import { omit } from 'utilities/object'
import { local } from 'utilities/storage'

export const usePageView = (data, dependencies = []) => {
    const viewed = useRef(false)
    const segment = useSegment()

    useLayoutEffect(() => {
        if(!viewed.current && (!dependencies.length || (dependencies.length && dependencies.every(Boolean)))) {
            viewed.current = true

            const hasProperties = typeof data === 'object'
            const name = hasProperties ? data.name : data
            const organizationId = local.get('ids')?.organization

            const properties = {
                ...(hasProperties ? omit(data, 'name') : null),
                ...(organizationId ? { organizationId } : null),
                client: 'web'
            }

            name && segment.page(name, properties)
        }
    }, [data, ...dependencies, viewed.current])
}