import React from 'react'
import { useMeetingStatusTextFormatter } from './'
import { cls } from 'utilities/dom'
import { StatusLabel } from './s'

const MeetingStatusLabel = ({ status, className }) => {
    const formatter = useMeetingStatusTextFormatter()
    const text = formatter(status)

    if(!text) {
        return null
    }

    const statusClassName = {
        draft: 'neutral',
        open: 'positive',
        closed: 'active'
    }[status] ?? 'neutral'

    className = cls([
        statusClassName,
        className
    ])

    return (
        <StatusLabel className={className}>
            {text}
        </StatusLabel>
    )
}

export default MeetingStatusLabel
