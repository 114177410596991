import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Templates } from './s'
import { Field, Label } from 'components/form/field/s'
import Toggler from 'components/form/input/toggler'

const ProcessTemplateItems = ({ type, multiple, templates, setTemplates, picking, openPicker, salt }) => {
    const toggler = useRef()

    const unset = () => setTemplates([])

    return (
        <Field>
            <Label htmlFor={salt}>
                <FormattedMessage {...getLabelTranslation(type, multiple)} />
            </Label>
            <Toggler
                id={salt}
                onClick={openPicker}
                type="processTemplate"
                active={picking}
                unset={!!templates?.length && unset}
                ref={toggler}>
                {!!templates?.length && (
                    <Templates
                        overrideType="processTemplate"
                        entities={templates}
                        salt={salt} />
                )}
            </Toggler>
        </Field>
    )
}

const getLabelTranslation = (type, multiple) => {
    if(type === 'onboarding') {
        if(multiple) {
            return {
                id: 'employee_onboarding_templates',
                defaultMessage: 'Onboarding templates'
            }
        }

        return {
            id: 'employee_onboarding_template',
            defaultMessage: 'Onboarding template'
        }
    }

    if(type === 'offboarding') {
        if(multiple) {
            return {
                id: 'employee_offboarding_templates',
                defaultMessage: 'Offboarding templates'
            }
        }

        return {
            id: 'employee_offboarding_template',
            defaultMessage: 'Offboarding template'
        }
    }

    if(type === 'process') {
        if(multiple) {
            return {
                id: 'processes_templates',
                defaultMessage: 'Process templates'
            }
        }

        return {
            id: 'processes_template',
            defaultMessage: 'Process template'
        }
    }

    return null
}

export default ProcessTemplateItems