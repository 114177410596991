import styled from 'styled-components'
import {
    svgStroke,
    belowDesktop
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { linkCss } from 'components/link'
import { AlertTriangleFilled } from 'components/feather'

export const Navigations = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;

    transition: height .2s ease-in-out;

    ${belowDesktop`
        display: none;
    `}
`

export const NavigationCategories = styled(Grid)``

export const Navigation = styled.nav`
    width: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 2px;
        height: 100%;
        background-color: var(--huma-color-border-default);
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 2px;
        height: var(--activeHashHeight, 64px);
        background-color: var(--huma-color-surface-inverted);

        transform: translateY(var(--offsetHeight, 0));
        transition: all 0.1s ease-in-out;
        will-change: transform, height;
    }
`

export const Link = styled.a`
    ${linkCss}

    position: relative;
    z-index: 1;
    isolation: isolate;
    display: inline-flex;
    align-items: center;
    column-gap: 8px;

    min-height: 40px;
    padding-inline: 16px;

    font-size: 14px;

    color: var(--huma-color-foreground-subtle);

    &.active {
        color: var(--huma-color-foreground-default);
    }
`

export const Warning = styled(AlertTriangleFilled)`
    ${svgStroke()}

    color: var(--huma-color-foreground-warning);
`