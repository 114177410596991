import styled from 'styled-components'
import { fromPhone } from 'utilities/styled'
import HeroActionsBase from 'components/hero-actions'
import { H3 } from 'components/typography/heading'
import CaptionBase from 'components/typography/caption'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const HeroActions = styled(HeroActionsBase)`
    ${fromPhone`
        align-items: center;
    `}

    margin-bottom: 16px;
`

export const Heading = styled(H3)`
    margin-bottom: 16px;

    ${HeroActions} & {
        margin-bottom: 0;
    }
`

export const Wrapper = styled.div`
    &:not(:empty) {
        margin-bottom: 40px;
    }
`

export const Caption = styled(CaptionBase)`
    margin-bottom: 40px;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    padding: 16px 0;

    &:first-of-type {
        padding-top: 0;
        box-shadow: none;
    }
`