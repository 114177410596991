import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import LinkBase from 'components/link'
import { ArrowRight as ArrowRightBase } from 'styled-icons/feather'
import { InlineMessage as InlineMessageBase } from 'components/message'
import Paragraph from 'components/typography/paragraph'
import { DefinitionDescription as DefinitionDescriptionBase } from 'components/list'
import { Plain as PlainBase } from 'components/button'

export const Wrapper = styled(Grid)`
    grid-auto-rows: auto;

    border-radius: 6px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);

    &.with-stats {
        row-gap: 16px;
    }
`

export const Link = styled(LinkBase)`
    display: flex;
    justify-content: space-between;
`

export const ArrowRight = styled(ArrowRightBase)`
    ${svgStroke()}

    transition: transform .1s ease-in-out;

    ${Link}:hover & {
        transform: translateX(4px);
    }
`

export const ProgressWrapper = styled.div`
    width: clamp(150px, 100%, 350px);
`

export const Buffer = styled.div`
    position: relative;
    overflow: hidden;

    margin-bottom: 8px;
    border-radius: 32px;
    width: inherit;
    height: 8px;
    background-color: var(--huma-color-surface-subtle);
`

export const ProgressBar = styled.div`
    position: absolute;

    border-radius: 32px;
    width: ${p => p.$value ?? 0}%;
    min-width: 5px;
    height: 8px;
    background-color: var(--huma-color-surface-brand-bold);

    transform-origin: top left;
    transition: width .25s cubic-bezier(.4, 0, .6, 1) 0s;
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-bottom: 0;
`

export const Label = styled(Paragraph)`
    margin-bottom: 8px;
`

export const DefinitionDescription = styled(DefinitionDescriptionBase)`
    text-align: end;
`

export const Plain = styled(PlainBase)`
    justify-self: center;
`