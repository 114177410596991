import React from 'react'
import { HeroColumns } from './s'
import ModuleTitle from 'components/module-title'
import { FlexChildShrink } from 'components/flex'
import LimitStatus from './limit-status'

const ProcessHero = ({ type, upgradable }) => (
    <HeroColumns>
        <ModuleTitle which={type} />
        {!upgradable && (
            <FlexChildShrink>
                <LimitStatus type={type} />
            </FlexChildShrink>
        )}
    </HeroColumns>
)

export default ProcessHero