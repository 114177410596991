import React from 'react'
import {
    isBefore, isAfter, isSameDay,
    endOfDay, startOfDay
} from 'date-fns'
import { isofy } from 'utilities/date-time'
import { isFuture } from 'pages/absence/utilities'
import { useSalaryStatusTextFormatter } from './'
import { cls } from 'utilities/dom'
import { StatusLabel } from './s'

const SalaryStatusLabel = ({ fromDate, toDate, className }) => {
    const formatter = useSalaryStatusTextFormatter()

    let status

    const now = new Date()

    fromDate = isofy(fromDate)
    toDate = isofy(toDate)

    if(
        (
            (!fromDate && !toDate)
        ) || (
            (!fromDate || isBefore(fromDate, endOfDay(now))) &&
            (!toDate || isAfter(toDate, startOfDay(now)))
        ) || (
            isSameDay(fromDate, now) &&
            isSameDay(toDate, now)
        )
    ) {
        status = 'current'
    } else if(isFuture({ start: fromDate }, now)) {
        status = 'upcoming'
    }

    const text = formatter(status)

    if(!text) {
        return null
    }

    const statusClassName = {
        current: 'active',
        upcoming: 'passive'
    }[status] ?? 'neutral'

    className = cls([
        statusClassName,
        className
    ])

    return (
        <StatusLabel className={className}>
            {text}
        </StatusLabel>
    )
}

export default SalaryStatusLabel