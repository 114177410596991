import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { size } from 'utilities/object'
import { ModalHeading } from 'components/typography/heading'
import Form from 'components/form/controller'
import ShareField from 'components/form/field/share'
import { Helper } from 'components/form/field/s'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'

const EditHandbook = ({ onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        handbook,
        updateHandbook
    } = useHandbook()

    const { contacts = [] } = handbook ?? {}

    const [updating, setUpdating] = useState(false)

    const update = async body => {
        if(!!body?.contacts?.length) {
            body.contacts = body.contacts.map(({ id }) => id)
        }

        setUpdating(true)

        const { ok } = await updateHandbook(body, handbook.id)

        setUpdating(false)

        ok && onDone?.()
    }

    return (
        <>
            <ModalHeading>
                <FormattedMessage
                    id="handbooks_action_edit_contacts"
                    defaultMessage="Edit contacts" />
            </ModalHeading>
            <Form
                onSubmit={update}
                layout="vertical">
                {({ touched, errors, trigger }) => (
                    <>
                        <ShareField
                            salt={salt}
                            className="compact"
                            label={formatMessage({
                                id: 'handbooks_label_contact_person',
                                defaultMessage: 'Contact person(s)'
                            })}
                            name="contacts"
                            field={{
                                value: contacts,
                                include: 'touched'
                            }}
                            picker={{
                                types: ['user']
                            }} />
                        <Helper>
                            <FormattedMessage
                                id="handbooks_helper_contact_person"
                                defaultMessage="Who should be the contact person(s) for the handbook content?" />
                        </Helper>
                        <Actions>
                            <Plain
                                className="neutral"
                                disabled={updating}
                                onClick={onDone}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                disabled={!touched.length || size(errors) || updating}
                                className={`constructive${updating ? ' loading' : ''}`}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_save"
                                    defaultMessage="Save" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default EditHandbook
