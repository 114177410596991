import { useState, useLayoutEffect } from 'react'
import { useAccess } from 'contexts/access'

export const useWithinQuota = ({ prefix = 'module', key, dependencies = [] }) => {
    const {
        plan,
        checkModuleWithinQuota,
        checkFeatureWithinQuota
    } = useAccess()

    const [withinQuota, setWithinQuota] = useState([false, {}, { checking: false }])

    const checkWithinQuota = (prefix === 'module') ?
        checkModuleWithinQuota :
        checkFeatureWithinQuota

    useLayoutEffect(() => {
        const check = async () => {
            setWithinQuota(([withinQuota, quota, state]) => [withinQuota, quota, {
                ...state,
                checking: true
            }])

            const [withinQuota, quota = {}] = await checkWithinQuota(key)

            setWithinQuota(([,, state]) => [withinQuota, quota, {
                ...state,
                checking: false
            }])
        }

        if(dependencies.every(Boolean)) {
            check()
        }
    }, [prefix, key, plan, ...dependencies])

    return withinQuota
}