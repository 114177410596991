import React from 'react'
import { useIntl } from 'react-intl'
import { useMeetingEvents } from 'contexts/meeting-events'
import { compact } from 'utilities/array'
import Filter from 'components/filter'
import StatusLabel from 'pages/meetings/components/status'
import { advancedApplied } from 'components/filter/utilities'

const MeetingEventsFilter = ({ context = 'all', salt, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        filter,
        setEventsFilter: setFilter,
        events: items,
        fetching,
        hasFetched
    } = useMeetingEvents()

    const advancedFields = compact([
        (context === 'all') && {
            type: 'shareUser',
            name: 'concerns',
            label: formatMessage({
                id: 'label_regarding',
                defaultMessage: 'Regarding'
            }),
            quickSelectMe: true
        },
        {
            type: 'shareUser',
            name: 'host',
            label: formatMessage({
                id: 'noun_facilitator',
                defaultMessage: 'Host'
            }),
            quickSelectMe: true
        },
        (context === 'round') && {
            type: 'checkboxes',
            name: 'status',
            label: formatMessage({
                id: 'noun_status',
                defaultMessage: 'Status'
            }),
            field: {
                options: [
                    {
                        id: 'draft',
                        name: <StatusLabel status="draft" />
                    },
                    {
                        id: 'open',
                        name: <StatusLabel status="open" />
                    },
                    {
                        id: 'closed',
                        name: <StatusLabel status="closed" />
                    }
                ]
            }
        }
    ])

    return (
        <Filter
            {...props}
            filter={filter}
            setFilter={setFilter}
            advancedFields={advancedFields}
            items={items}
            fetching={fetching}
            hasFetched={hasFetched}
            salt={`meeting-events:${context}:${salt}`} />
    )
}

const advancedNames = ['concerns', 'host', 'status']
const groupedAdvancedNames = ['concerns', 'host']

export const areAdvancedFiltersApplied = advancedApplied(advancedNames)
export const areGroupedAdvancedFiltersApplied = advancedApplied(groupedAdvancedNames)

export const areFiltersApplied = filter => areAdvancedFiltersApplied(filter)
export const areGroupedFiltersApplied = filter => areGroupedAdvancedFiltersApplied(filter)

export default MeetingEventsFilter
