import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { KnowledgeBaseLink, Icon, Title, ExternalIcon } from './s'
import { Book } from 'styled-icons/feather'
import { ExternalLink } from 'styled-icons/feather'

const KnowledgeBase = ({ onClick }) => {
    const { environment } = useEnvironment()

    return (
        <KnowledgeBaseLink
            href={`https://www.${environment.tld}/knowledge`}
            target="_blank"
            onClick={onClick}>
            <Icon>
                <Book size={24} />
            </Icon>
            <Title className="compact">
                <FormattedMessage
                    id="noun_help_articles"
                    defaultMessage="Help articles" />
            </Title>
            <ExternalIcon>
                <ExternalLink size={16} />
            </ExternalIcon>
        </KnowledgeBaseLink>
    )
}

export default KnowledgeBase