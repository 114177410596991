import styled from 'styled-components'
import { fontSerif } from 's'
import SymbolBase from 'components/symbol'
import Caption from 'components/typography/caption'
import { H2 } from 'components/typography/heading'
import ContextMenuBase from 'widgets/context-menu/flat'

export const Symbol = styled(SymbolBase)`
    margin-block-end: 16px;
`

export const Category = styled(Caption)`
    margin-block-end: 4px;
`

export const Heading = styled(H2)`
    margin-block-end: 4px;

    ${fontSerif}
    line-height: 30px;
`

export const ContextMenu = styled(ContextMenuBase)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    overflow: hidden;
`