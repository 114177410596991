import styled from 'styled-components'
import {
    interpointDivider,
    belowLaptop
} from 'utilities/styled'
import { CategoryContent } from 'pages/handbook/components/category/s'
import { HashLink } from 'react-router-hash-link'
import { buttonStyles } from 'components/button'
import { DisplayEmoji } from 'components/symbol'
import HandbookTopicStatus from 'pages/handbook/components/status'

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &:not(:last-child) {
        margin-block-end: 16px;
    }

    ${CategoryContent} & {
        ${belowLaptop`
            max-width: calc(100vw - 104px);
        `}
    }
`

export const Topic = styled(HashLink)`
    ${buttonStyles.plainCss}

    display: inline-flex;
    justify-content: center;
    column-gap: 8px;

    border-radius: 40px;
    border: 0;
    max-width: 100%;
    height: 40px;
    padding: 10px 16px;

    transition: all .1s ease-in-out;
    will-change: background, color, box-shadow;

    line-height: 20px;
    text-decoration: none;

    &:not(.group):not(.pinned-draft) {
        background-color: var(--huma-color-surface-minimal);

        ${Wrapper}.highlighted & {
            box-shadow: 0 0 0 1px inset var(--huma-color-border-default);
        }

        &:where(:hover, :focus) {
            background-color: var(--huma-color-surface-constructive-minimal);
        }
    }

    &.group,
    &.pinned-draft {
        box-shadow: 0 0 0 1px inset var(--huma-color-border-default);

        line-height: 24px;
        color: var(--huma-color-foreground-subtle);

        &:where(:hover, :focus) {
            box-shadow: 0 0 0 1px inset var(--huma-color-border-constructive-bold);
        }
    }

    &.pinned-draft > * {
        opacity: .5;
    }

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${CategoryContent} & {
        max-width: 240px;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        text-decoration: none;
        color: var(--huma-color-foreground-on-constructive-minimal);
    }

    &:not(:disabled):active {
        text-decoration: none;
    }
`

export const Emoji = styled(DisplayEmoji)`
    line-height: 18px;
`

export const Label = styled.span`
    margin: 0;

    white-space: nowrap;
    font-size: 14px;
    line-height: inherit;
    color: inherit;

    ${Topic}.group & {
        ${interpointDivider()}
    }
`

export const Status = styled(HandbookTopicStatus)`
    display: inline-grid;
    column-gap: unset;

    line-height: 24px;
`