import styled from 'styled-components'
import { Plain } from 'components/button'
import OneOfFieldBase from 'components/form/field/one-of'
import ShareFieldBase from 'components/form/field/share'

export const ChangeButton = styled(Plain).attrs({ className: 'constructive' })`
    display: inline;

    margin-inline-start: 4px;
    height: auto;

    font-size: 14px;
`

export const OneOfField = styled(OneOfFieldBase)`
    margin-bottom: 0;
`

export const ShareField = styled(ShareFieldBase)`
    padding: 0;
`