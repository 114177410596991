import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useOrganization } from 'contexts/organization'
import { useTheme } from 'contexts/theme'
import { useGetCompanyUrl } from 'utilities/url'
import queryString from 'query-string'

export const statuses = [
    'new',
    'seen',
    'acknowledged',
    'closed'
]

const classNames = {
    new: 'passive',
    seen: 'neutral',
    acknowledged: 'positive',
    closed: 'active'
}

const labels = {
    new: {
        id: 'whistleblowing_case_status_new',
        defaultMessage: 'Unseen'
    },
    seen: {
        id: 'whistleblowing_case_status_seen',
        defaultMessage: 'Seen'
    },
    acknowledged: {
        id: 'whistleblowing_case_status_acknowledged',
        defaultMessage: 'Open'
    },
    closed: {
        id: 'whistleblowing_case_status_closed',
        defaultMessage: 'Closed'
    }
}

const descriptions = {
    new: {
        id: 'whistleblowing_case_status_new_description',
        defaultMessage: 'The report has been registered, but no case handler has seen it yet.'
    },
    seen: {
        id: 'whistleblowing_case_status_seen_description',
        defaultMessage: 'A case handler has seen the report, but it’s not actively being worked on yet.'
    },
    acknowledged: {
        id: 'whistleblowing_case_status_acknowledged_description',
        defaultMessage: 'The report has been acknowledged and a case handler is working on it.'
    },
    closed: {
        id: 'whistleblowing_case_status_closed_description',
        defaultMessage: 'The case has been closed.'
    }
}

export const useCaseStatus = () => {
    const { formatMessage } = useIntl()

    return status => {
        if(!status) {
            return null
        }

        status = status.toLowerCase()

        const label = labels[status] ?? null
        const description = descriptions[status] ?? null

        return {
            name: status,
            className: classNames[status] ?? 'neutral',
            label: label ?
                formatMessage(label) :
                null,
            description: description ?
                formatMessage(description) :
                null
        }
    }
}

export const getCaseWarningOrError = kase => {
    if(!['new', 'seen'].includes(kase.status)) {
        return null
    }

    if(!('ackDueInDays' in kase) && !kase.overdue) {
        return null
    }

    // If status is still not acknowledged after 7 days
    if(kase.overdue) {
        return {
            type: 'error',
            message: {
                id: 'whistleblowing_case_message_overdue',
                defaultMessage: 'Past the deadline for acknowledgment'
            }
        }
    }

    // If there are 3 days or less left to acknowledge the case
    if(kase.ackDueInDays <= 2) {
        return {
            type: 'warning',
            message: {
                id: (kase.ackDueInDays === 0) ?
                    'whistleblowing_case_message_unacknowledged_last_chance' :
                    'whistleblowing_case_message_unacknowledged',
                defaultMessage: (kase.ackDueInDays === 0) ?
                    'Acknowledge today' :
                    'Acknowledge within {days} days',
                values: {
                    days: kase.ackDueInDays + 1
                }
            }
        }
    }

    return null
}

export const useWhistleblowerPortalUrl = (options = {}) => {
    const {
        withLocale = true,
        withTheme = true,
        subPath = ''
    } = options

    const { organization } = useOrganization()
    const { locale } = useI18n()
    const { name: theme } = useTheme()

    const portalUrl = useGetCompanyUrl()('whistleblowing', `/${organization?.id}${subPath}`, true)

    const url = queryString.stringifyUrl({
        url: portalUrl,
        query: {
            ...(withTheme && { theme }),
            ...(withLocale && { locale })
        }
    })

    return url
}