import React, { useState, useEffect } from 'react'
import AbsenceStatsProvider, { useAbsenceStats } from 'contexts/absence-stats'
import { isFuture } from 'pages/absence/utilities'
import { isofy, getDate } from 'utilities/date-time'
import TotalBalance from 'pages/absence/components/total-balance'
import { LoadingContainer } from './s'
import Loader from 'components/loader'

const Estimate = ({ start, end, grade, id, user, type, absenceAdmin, permissionLevel, status }) => {
    const {
        estimateCount,
        resetError
    } = useAbsenceStats()

    const [estimate, setEstimate] = useState(null)
    const [estimating, setEstimating] = useState(false)

    const now = new Date()

    useEffect(() => {
        if(!!start) {
            const estimate = async () => {
                setEstimating(true)
                resetError()

                const { ok, response } = await estimateCount({
                    userId: user.id,
                    fromDate: getDate(start),
                    toDate: end ? getDate(end) : getDate(start),
                    type: type.id,
                    grade,
                    ...(id && { excludeEntryId: id })
                })

                setEstimating(false)

                if(ok) {
                    const { details, workdays } = response

                    const estimate = {
                        details: details
                            .sort(({ effectiveTimeWindow: one }, { effectiveTimeWindow: two }) =>
                                isofy(one.from).getTime() - isofy(two.from).getTime()
                            )
                            .reverse(),
                        workdays
                    }

                    setEstimate(estimate)
                } else {
                    setEstimate(null)
                }
            }

            estimate()
        }
    }, [start, end])

    if(estimating) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    if(
        (!!start && estimate && !estimating) &&
        (
            (estimate?.details?.[0].policy?.timeWindow?.type === 'rolling' && !(isFuture({ start }, now) && !end)) ||
            estimate?.details?.[0].policy?.timeWindow?.type === 'fixed'
        )
    ) {
        return (
            <TotalBalance
                className="fill"
                estimate={estimate}
                dateRange={{ start, end }}
                status={status}
                absenceAdmin={absenceAdmin}
                permissionLevel={permissionLevel}
                type={type} />
        )
    }

    return null
}

export default props => (
    <AbsenceStatsProvider
        {...(props.user ? { userId: props.user.id } : null)}
        fetchTypesPreliminarily={true}>
        <Estimate {...props} />
    </AbsenceStatsProvider>
)