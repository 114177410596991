import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import {
    Important,
    Status, Header, HeaderEnd, ChangeButton, Note, Message
} from './s'
import StatusLabel from 'pages/absence/components/approval-status'
import ApprovalActions from 'pages/absence/components/approval-actions'
import Caption from 'components/typography/caption'

const EntryStatus = ({ type, absenceAdmin, salt, ...entry }) => {
    const { formatMessage } = useIntl()

    const { isItMyOwnId } = useMe()

    const {
        user,
        status,
        comment,
        reviewedBy
    } = entry

    const { requireApproval } = type ?? {}

    const [changing, setChanging] = useState(false)

    useEffect(() => {
        if(absenceAdmin && status === 'pending') {
            setChanging(true)
        }

        return () => setChanging(false)
    }, [])

    if(!entry || !status || !requireApproval || (!isItMyOwnId(user?.id) && !absenceAdmin)) {
        return null
    }

    return (
        <Status>
            <Header>
                <Caption className="compact">
                    <FormattedMessage
                        id="noun_status"
                        defaultMessage="Status" />
                </Caption>
                <HeaderEnd>
                    <StatusLabel status={status} />
                    {(absenceAdmin && !['pending', 'canceled'].includes(status)) && (
                        <ChangeButton
                            className="constructive"
                            onClick={() => setChanging(!changing)}>
                            <FormattedMessage
                                id={changing ? 'action_cancel' : 'action_change'}
                                defaultMessage={changing ? 'Cancel' : 'Change'} />
                        </ChangeButton>
                    )}
                </HeaderEnd>
            </Header>
            {!!changing && (
                <Important className="neutral">
                    <ApprovalActions
                        context="single"
                        entry={entry}
                        type={type}
                        alwaysShowHelper={true}
                        onDone={status => (status !== 'pending') && setChanging(false)}
                        salt={salt} />
                </Important>
            )}
            {!changing && (
                <>
                    {!!comment && (
                        <Note
                            user={reviewedBy}
                            from="end">
                            {comment}
                        </Note>
                    )}
                    {(status === 'pending' && isItMyOwnId(user.id)) && (
                        <Message
                            type="info"
                            message={formatMessage({
                                id: 'absence_approval_message_awaiting',
                                defaultMessage: 'Awaiting review…'
                            })} />
                    )}
                </>
            )}
        </Status>
    )
}

export default EntryStatus
