import { useRef, useState, useEffect } from 'react'

export const useClipboard = ({ text, timeout = 3000 }) => {
    const ref = useRef()
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        if(copied) {
            global.setTimeout(() => {
                setCopied(false)
                ref.current?.blur()
            }, timeout)
        }
    }, [copied])

    const copy = () => {
        if(global.navigator?.clipboard) {
            global.navigator.clipboard.writeText(text)
            return void setCopied(true)
        }

        const element = document.createElement('textarea')
        element.value = text
        document.body.appendChild(element)
        element.select()
        document.execCommand('copy')
        document.body.removeChild(element)

        setCopied(true)
    }

    return {
        ref,
        copy,
        copied
    }
}