import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { ListItem as ListItemBase } from 'components/list'

const itemCss = css`
    display: grid;
    grid-template-columns: 40px 1fr auto;
    align-items: center;
    column-gap: 16px;

    margin-block-end: -1px;
    border-bottom: 1px solid var(--huma-color-border-default);
    height: 56px;

    &:first-child {
        border-top: 1px solid var(--huma-color-border-default));
    }

    > svg {
        ${svgStroke()}

        margin-inline-start: 8px;
    }
`

export const SortableItem = styled(ListItemBase)`
    ${itemCss}

    background-color: var(--huma-color-surface-default);

    ${p => !!p.$isDragging && css`
        box-shadow: var(--huma-shadow-lowest);
    `}
`

export const DragColumn = styled.div`
    color: var(--huma-color-foreground-minimal);
`

export const ListItem = styled(ListItemBase)`
    ${itemCss}

    color: var(--huma-color-foreground-minimal);
`