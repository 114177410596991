import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { H4 } from 'components/typography/heading'
import TutorialBase from 'components/tutorial'

export const Wrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    row-gap: 40px;

    min-height: 80px;
`

export const Category = styled.section``

export const Header = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 16px;

    margin-bottom: 24px;

    &::after {
        content: "";

        border-top: 1px solid var(--huma-color-border-default);
        width: 100%;
    }
`

export const Heading = styled(H4)`
    font-size: 16px;
`

export const Tutorial = styled(TutorialBase)`
    margin-top: 80px;

    ${belowPhone`
        margin-top: 40px;
    `}
`