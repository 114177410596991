import React from 'react'
import { FormattedNumber } from 'react-intl'
import { cls } from 'utilities/dom'
import { getFractionDigits } from 'pages/people/pages/profile/panes/about/salary/utilities'
import { Trend } from './s'
import { TrendingUp, TrendingDown } from 'styled-icons/feather'

const StatisticsTrend = ({ percentage, trend, size = 16, className, ...props }) => {
    if(!Number(percentage) || !trend) {
        return null
    }

    const TrendIcon = ({ trend }) => {
        if(trend === 'up') {
            return <TrendingUp size={size} />
        }

        return <TrendingDown size={size} />
    }

    className = cls([
        className,
        trend
    ])

    return (
        <Trend
            {...props}
            className={className}>
            <TrendIcon trend={trend} />
            <span className="value">
                <FormattedNumber
                    value={percentage / 100}
                    style="percent"
                    minimumFractionDigits={getFractionDigits(percentage / 100)}
                    maximumFractionDigits={getFractionDigits(percentage / 100)} />
            </span>
        </Trend>
    )
}

export default StatisticsTrend
