import styled from 'styled-components'
import { H3 } from 'components/typography/heading'
import MessageBase, { InlineMessage as InlineMessageBase } from 'components/message'

export const LoaderWrap = styled.div`
    min-height: 200px;
    position: relative;
`

export const Heading = styled(H3)`
    margin-bottom: 24px;
`

export const FileHelper = styled.div`
    padding: 0 16px;
    text-align: center;
`

export const Message = styled(MessageBase)`
    margin-bottom: 24px;
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-bottom: 24px;
`