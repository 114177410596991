import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { Container, Heading, Jubilees, Overflow, Avatars, Button } from './s'
import Jubilee from './jubilee'
import { Scrollable as Modal } from 'modals/generic'
import SetPhoneNumber from './set-phone-number'

const JubileeGroup = ({ heading, jubilees, showAll, capAt = Infinity, salt, ...props }) => {
    const { check } = useAccess()

    const [phoneNumber, setPhoneNumber] = useState(null)

    const visibleJubilees = (capAt === Infinity || jubilees.length === capAt + 1) ?
        jubilees :
        jubilees.slice(0, capAt)

    const overflowingJubilees = (visibleJubilees.length !== jubilees.length) ?
        jubilees.slice(capAt) :
        null

    return (
        <Container>
            <Heading>{heading}</Heading>
            {!!visibleJubilees?.length && (
                <Jubilees>
                    {visibleJubilees.map(jubilee => (
                        <Jubilee
                            {...props}
                            jubilee={jubilee}
                            setPhoneNumber={setPhoneNumber}
                            settingPhoneNumber={phoneNumber?.user?.id === jubilee.user.id}
                            key={`${salt}:${jubilee.user.id}:${jubilee.type}`} />
                    ))}
                </Jubilees>
            )}
            {!!overflowingJubilees?.length && (
                <Overflow>
                    <Button onClick={showAll}>
                        <Avatars
                            people={overflowingJubilees.map(({ user }) => user)}
                            size={16}
                            max={avatarCount}
                            sortPeople={false}
                            salt={salt}
                            itemSalts={overflowingJubilees.map(({ type }) => type)} />
                        <span className="link">
                            <FormattedMessage
                                id="action_show_all"
                                defaultMessage="Show all" />
                        </span>
                    </Button>
                </Overflow>
            )}
            {check('user:phone:write') && (
                <Modal
                    show={!!phoneNumber}
                    dismiss={() => setPhoneNumber(null)}>
                    <SetPhoneNumber {...phoneNumber} />
                </Modal>
            )}
        </Container>
    )
}

const avatarCount = 3

export default JubileeGroup