import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { cls } from 'utilities/dom'
import { capitalize } from 'utilities/string'
import Paragraph from 'components/typography/paragraph'
import { Language } from 'components/feather'

const LanguageLegislation = ({ language, legislation, wrap = true, className }) => {
    const { formatDisplayName } = useIntl()

    if(!language && !legislation) {
        return null
    }

    language = !!language ?
        capitalize(formatDisplayName(language, { type: 'language' })) :
        null

    legislation = !!legislation ?
        formatDisplayName(legislation, { type: 'region' }) :
        null

    const [Wrapper, wrapperProps] = wrap ?
        [Paragraph, {
            className: cls([
                className,
                'caption small compact interpoint-divider'
            ])
        }]
        : [Fragment, null]

    return (
        <Wrapper {...wrapperProps}>
            <span>
                <Language size={16} />
                {language}
            </span>
            {!!legislation && (
                <span>
                    {`§ ${legislation}`}
                </span>
            )}
        </Wrapper>
    )
}

export default LanguageLegislation