import React, { useLayoutEffect } from 'react'
import { cls } from 'utilities/dom'
import {
    sidepanelify, Wrapper,
    SidePanel, SidePanelBase as CollapsibleSidePanel, SideContainer,
    ToggleButton,
    SideContainerInner
} from 'utilities/panel'
import { sendAppSignal } from 'hooks/app-signal'
import { ChevronRight as Collapse } from 'styled-icons/feather'

const GenericSidePanel = ({ show = false, automatic = false, collapsible = false, className, children, salt, ...props }) => {
    useLayoutEffect(() => {
        if(automatic) {
            sendAppSignal('side-panel.toggle', show)
            return () => sendAppSignal('side-panel.toggle', false)
        }
    }, [show, automatic])

    const {
        dismiss,
        toggle,
        ...panel
    } = props

    className = cls([
        className,
        collapsible && 'collapsible',
        show && 'open'
    ])

    const animate = show ? 'in' : 'out'

    if(collapsible) {
        return sidepanelify(
            <CollapsibleSidePanel
                {...panel}
                dismiss={dismiss}
                key={`panel:side:${salt}`}>
                <SideContainer
                    {...(className ? { className } : null)}
                    collapsible
                    animate={animate}>
                    <ToggleButton onClick={() => toggle(!show)}>
                        <Collapse size={20} />
                    </ToggleButton>
                    <SideContainerInner {...(!show ? { inert: 'true' } : null)}>
                        {children}
                    </SideContainerInner>
                </SideContainer>
            </CollapsibleSidePanel>
        )
    }

    return sidepanelify(
        <Wrapper>
            {!!show && (
                <SidePanel
                    {...panel}
                    dismiss={dismiss}
                    key={`panel:side:${salt}`}>
                    <SideContainer
                        {...(className ? { className } : null)}
                        animate={animate}>
                        <SideContainerInner {...(!show ? { inert: true } : null)}>
                            {children}
                        </SideContainerInner>
                    </SideContainer>
                </SidePanel>
            )}
        </Wrapper>
    )
}

export default GenericSidePanel