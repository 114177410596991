import styled from 'styled-components'
import Caption from 'components/typography/caption'
import MessageBase from 'components/message'

export const Heading = styled(Caption)`
    margin-bottom: 16px;
`

export const Message = styled(MessageBase)`
    margin-bottom: 24px;
`