import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Wrapper, Item } from './s'
import Tooltip, { Trigger } from 'components/tooltip'

const StatisticsContent = ({ article, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        status,
        statistics
    } = article

    if(status !== 'published' || !statistics) {
        return null
    }

    const {
        audienceCount,
        audienceReadCount,
        totalReadCount
    } = statistics

    const readPercentage = Math.round(audienceReadCount / audienceCount * 100)

    return (
        <Wrapper {...props}>
            <Item
                value={formatMessage({
                    id: 'value_percent',
                    defaultMessage: '{percent} %'
                }, { percent: readPercentage })}
                label={formatMessage({
                    id: 'news_statistics_read_percentage_label',
                    defaultMessage: 'of the audience has read'
                })} />
            <Item
                value={audienceCount}
                label={formatMessage({
                    id: 'news_statistics_audience_count_label',
                    defaultMessage: 'in the audience when published'
                })} />
            <Item
                value={totalReadCount}
                label={(
                    <>
                        <FormattedMessage
                            id="news_statistics_read_count_label"
                            defaultMessage="{count, plural, =0 {people read the article} =1 {person read the article} other {people read the article}}"
                            values={{ count: totalReadCount }} />
                        <Tooltip
                            content={formatMessage({
                                id: 'news_statistics_read_count_tooltip',
                                defaultMessage: 'Total unique users who have read the article'
                            })}
                            placement="bottom-end">
                            <Trigger lineHeight={18} />
                        </Tooltip>
                    </>
                )} />
        </Wrapper>
    )
}

export default StatisticsContent