import React, { Component, createContext, useContext } from 'react'
import { useCompetenceProfiles } from 'contexts/competence-profiles'
import { get, post, patch, remove } from 'api'
import PubSub from 'pubsub-js'

export const CompetenceProfileContext = createContext()

class CompetenceProfileProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)

        this.state = {
            profile: null,
            deleted: false,

            replace: this.replace,

            fetchProfile: this.fetch,
            addProfile: this.add,
            updateProfile: this.update,
            togglePrivacy: this.togglePrivacy,
            removeProfile: this.remove,

            error: null,
            resetError: () => this.setError(null),

            fetching: false,
            hasFetched: false
        }

        this.refreshSubscription = PubSub.subscribe('competenceProfile.refresh', (_, id) => {
            let affected = true

            this.setState(({ profile }) => {
                if(!profile?.id || profile?.id !== id) {
                    affected = false
                    return null
                }

                return {
                    profile: null,
                    hasFetched: false
                }
            }, () => {
                affected && this.fetch()
            })
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    componentDidUpdate({ id }) {
        const idChanged = id !== this.props.id

        if(idChanged) {
            this.replace(this.props.id)
        }
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.refreshSubscription)
    }

    setId = id => this.id = id

    fetch = async () => {
        if(!this.id) {
            return
        }

        this.setState({ fetching: true })

        const { response: profile, ok } = await get({ path: `/competence-profiles/${this.id}` })

        !!ok && this.setState({
            profile,
            hasFetched: true,
            fetching: false
        })

        return { ok, response: profile }
    }

    add = async body => {
        const { response, ok } = await post({
            path: '/competence-profiles',
            body
        })

        if(response) {
            if(ok) {
                this.props.profiles?.pushProfile(response)
            } else {
                this.setError({
                    ...response,
                    profile: body
                })
            }
        }

        return { ok, response }
    }

    update = async (body) => {
        const { ok, response: profile } = await patch({
            path: `/competence-profiles/${this.id}`,
            body
        })

        if(profile) {
            if(ok) {
                this.setState({ profile })
                this.props.profiles?.updateProfileLocally(body, this.id)
            } else {
                this.setError({
                    ...profile,
                    profile: body
                })
            }
        }

        return { ok, response: profile }
    }

    togglePrivacy = async ({ public: isPublic }) => {
        const { ok, profile } = await this.update({ public: !isPublic })

        return { ok, response: profile }
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                errorCode,
                errorMessage,
                interpolations = []
            } = data

            error = {
                errorType: errorCode.replace('object:', '').replaceAll('-', '_'),
                errorMessage,
                interpolations
            }
        }

        this.setState({ error })
    }

    remove = async () => {
        const { ok } = await remove({
            path: `/competence-profiles/${this.id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    replace = id => {
        if(id !== this.id) {
            this.setId(id)
            this.setState({ profile: null }, this.fetch)
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <CompetenceProfileContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </CompetenceProfileContext.Provider>
        )
    }
}

export default props => {
    const profiles = useCompetenceProfiles()

    return (
        <CompetenceProfileProvider
            {...props}
            profiles={profiles} />
    )
}

export const useCompetenceProfile = () => useContext(CompetenceProfileContext)