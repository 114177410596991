import { compact } from 'utilities/array'
import { size } from 'utilities/object'

export const fieldViewable = field => !!field
export const anyFieldsViewable = (fields = []) => !!compact(fields).length

export const fieldFilled = field => {
    const value = field?.value

    if(!!value && typeof value === 'object') {
        return Array.isArray(value) ?
            !!value.length :
            !!size(value)
    }

    return ['number', 'boolean'].includes(typeof value) ?
        true :
        !!value
}

export const anyFieldsFilled = (fields = []) => {
    const fieldValuesAsBooleans = fields.map(fieldFilled)
    return !!compact(fieldValuesAsBooleans).length
}

export const fieldEditable = field => field?.editable ?? false

export const anyFieldsEditable = (fields = []) => {
    const fieldEditabilityAsBooleans = fields.map(fieldEditable)
    return !!compact(fieldEditabilityAsBooleans).length
}

export const fieldDisplayable = (field, requireFilled = true) => {
    const editable = fieldEditable(field)
    if(editable) {
        return true
    }

    const viewable = fieldViewable(field)
    const filled = fieldFilled(field)

    if(!viewable || (requireFilled && !filled)) {
        return false
    }

    return true
}

export const anyFieldsDisplayable = (fields = [], requireFilled = true) => !!compact(fields.map(field => fieldDisplayable(field, requireFilled))).length