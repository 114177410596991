import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { post } from 'api'
import { bytesToMegabytes } from 'utilities/file'
import { ModalHeader } from 'modals/generic'
import { Instructions, File, AnalyzeMessage } from './s'
import Form from 'components/form/controller'
import Caption from 'components/typography/caption'
import Analysis from './analysis'
import FileField from 'components/form/field/file'
import WordList from 'components/word-list'
import Actions from 'components/form/actions'
import { tenMegabytesAsBytes } from 'components/form/input/file'
import { ButtonSubmit, Plain } from 'components/button'

const Import = ({ onDone, salt }) => {
    const { formatMessage } = useIntl()

    const [analyzing, setAnalyzing] = useState(false)
    const [analysis, setAnalysis] = useState(null)
    const [error, setError] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [importing, setImporting] = useState(false)

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    const analyze = async body => {
        if(analyzing) {
            return
        }

        setAnalysis(null)
        setError(null)

        if(!body.get('file')) {
            return
        }

        setAnalyzing(true)

        const { ok, status, response } = await post({
            path: '/equipment/import/analyze',
            body
        })

        setAnalyzing(false)

        if(ok) {
            setAnalysis(response)
        } else if([400, 415].includes(status)) {
            setError(response)
        }
    }

    const upload = async body => {
        if(uploading) {
            return
        }

        setUploading(true)

        const { ok } = await post({
            path: '/equipment/import/upload',
            body,
            returnsData: false
        })

        setUploading(false)

        if(ok) {
            setImporting(true)
            !!onboarder && updateOnboardingStatus({ importEquipment: 'completed' })
        }
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'equipment_import_heading',
                    defaultMessage: 'Import equipment'
                })}
                dismiss={() => onDone()} />
            <Instructions
                instructions={[
                    {
                        text: (
                            <FormattedMessage
                                id="equipment_import_instruction_download_and_fill"
                                defaultMessage="Download and fill our import file template:" />
                        ),
                        content: (
                            <File
                                path="/equipment/import/template/xlsx"
                                download={true}
                                meta={{ fileName: 'huma-equipment-import-template.xlsx' }} />
                        )
                    },
                    {
                        text: formatMessage({
                            id: 'equipment_import_instruction_upload',
                            defaultMessage: 'Upload the filled import file'
                        })
                    }
                ]}
                salt={salt} />
            <Form
                layout="vertical"
                onSubmit={!!analysis ? upload : analyze}
                submitOnChange={!!analysis ? false : true}>
                {({ touched, trigger }) => (
                    <>
                        <FileField
                            salt={salt}
                            className="compact"
                            label={false}
                            name="file"
                            field={{ required: true }}
                            accept={{
                                'application/excel': [],
                                'application/x-excel': [],
                                'application/x-msexcel': [],
                                'application/vnd.ms-excel': [],
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx']
                            }}
                            helper={formatMessage({
                                id: 'employees_import_file_helper',
                                defaultMessage: 'XLSX file, smaller than {megabytes} MB'
                            }, {
                                megabytes: bytesToMegabytes(tenMegabytesAsBytes, 0)
                            })}
                            multiple={false}
                            loading={analyzing}
                            onClear={() => {
                                setAnalysis(null)
                                setError(null)
                                setUploading(false)
                            }} />
                        {(!!importing && !!analysis?.accepted?.length) && (
                            <AnalyzeMessage
                                message={formatMessage({
                                    id: 'equipment_import_pieces_added_count',
                                    defaultMessage: '{count, plural, =0 {} =1 {1 piece of equipment is being imported in the background} other {{count} pieces of equipment are being imported in the background}}'
                                }, { count: analysis.accepted.length })}
                                type="info">
                                <Caption className="compact">
                                    <FormattedMessage
                                        id="import_refresh_instruction"
                                        defaultMessage="You can safely go about your business and check back in a little while." />
                                </Caption>
                            </AnalyzeMessage>
                        )}
                        {(!!analysis && !uploading && !importing) && (
                            <Analysis
                                {...analysis}
                                salt={salt} />
                        )}
                        {!!error && (
                            <AnalyzeMessage
                                message={formatMessage({
                                    id: `employees_import_error_${error.errorCode.replace(/[:-]/g, '_')}`,
                                    defaultMessage: error.errorMessage
                                }, {
                                    count: error?.fields?.length ?? 0,
                                    fields: (
                                        <WordList
                                            words={error.fields}
                                            key={`${salt}:employees_import_error_${error.errorCode.replace(/[:-]/g, '_')}`} />
                                    )
                                })}
                                type="error" />
                        )}
                        <Actions
                            {...((uploading || importing) ? { className: 'centered' } : null)}
                            salt={salt}>
                            <Plain
                                onClick={() => onDone?.(importing)}
                                className="neutral"
                                disabled={!touched || analyzing || uploading}>
                                <FormattedMessage
                                    id={!!importing ?
                                        'action_close' :
                                        'action_cancel'
                                    }
                                    defaultMessage={!!importing ?
                                        'Close' :
                                        'Cancel'
                                    } />
                            </Plain>
                            {(!uploading && !importing) && (
                                <ButtonSubmit
                                    className="constructive"
                                    disabled={!touched || !analysis || !analysis?.accepted?.length}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id="equipment_action_add_count"
                                        defaultMessage="{count, plural, =0 {Add} =1 {Add 1 piece of equipment} other {Add {count} pieces of equipment}}"
                                        values={{ count: analysis?.accepted?.length ?? 0 }} />
                                </ButtonSubmit>
                            )}
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default Import
