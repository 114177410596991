import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCompetenceProfile } from 'contexts/competence-profile'
import { useHistate } from 'hooks/histate'
import { size } from 'utilities/object'
import Form from 'components/form/controller'
import SymbolField from 'components/form/field/symbol'
import StringField from 'components/form/field/string'
import EditorField from 'components/form/field/editor'
import CheckboxField from 'components/form/field/checkbox'
import { Helper } from 'components/form/field/s'
import Actions from 'components/form/actions'
import { ButtonSubmit } from 'components/button'

const fallbackSymbol = {
    emoji: 'page_with_curl',
    colorToken: 'symbol-neutral-1'
}

const OneGeneral = ({ form, setForm, update, salt }) => {
    const { formatMessage } = useIntl()

    const {
        error,
        resetError
    } = useCompetenceProfile()

    const {
        id,
        symbol = fallbackSymbol,
        name = '',
        description = {},
        public: isPublic = false
    } = form ?? {}

    const fields = {
        description: useHistate(description ?? null)
    }

    return (
        <Form
            layout="vertical"
            onSubmit={update}>
            {({ touched, errors, trigger }) => (
                <>
                    <SymbolField
                        salt={salt}
                        label={false}
                        name="symbol"
                        field={{
                            value: symbol,
                            include: id ? 'touched' : 'always',
                            unsettable: false
                        }} />
                    <StringField
                        salt={salt}
                        label={formatMessage({
                            id: 'noun_name',
                            defaultMessage: 'Name'
                        })}
                        name="name"
                        field={{
                            value: name,
                            required: true,
                            include: id ? 'touched' : 'always'
                        }}
                        {...((error?.errorType === 'duplicate') ? {
                            error: formatMessage({
                                id: 'competence_profile_error_duplicate',
                                defaultMessage: 'A profile with this name already exists'
                            })
                        } : null)}
                        onChange={() => {
                            if(error?.errorType === 'duplicate') {
                                resetError()
                            }
                        }} />
                    <EditorField
                        salt={salt}
                        label={formatMessage({
                            id: 'noun_description',
                            defaultMessage: 'Description'
                        })}
                        name="description"
                        field={{
                            value: fields.description.current,
                            showCharacterCount: true
                        }}
                        editor={{
                            configure: {
                                docType: 'summary'
                            },
                            preset: 'summary',
                            enabledExtensions: ['HardBreak']
                        }}
                        controlProps={{ max: 1024 }}
                        onChange={({ description: doc }) => {
                            fields.description.update(doc.content)

                            setForm(previous => ({
                                ...previous,
                                description: doc.content
                            }))
                        }} />
                    <CheckboxField
                        salt={salt}
                        className="compact"
                        label={formatMessage({
                            id: 'action_privacy_show',
                            defaultMessage: 'Make public'
                        })}
                        name="public"
                        field={{ value: !!isPublic }}
                        interaction="switch" />
                    <Helper>
                        <FormattedMessage
                            id="competence_profile_helper_privacy"
                            defaultMessage="Non-public profiles are only visible to competence administrators. Public profiles are visible to everyone." />
                    </Helper>
                    <Actions>
                        <ButtonSubmit
                            className="constructive"
                            disabled={!touched || !!size(errors)}
                            ref={trigger}>
                            <FormattedMessage
                                id="action_next"
                                defaultMessage="Next" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default OneGeneral
