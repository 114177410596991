import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MenuItemLink } from './'

const moduleIdentifier = 'back'

const NestedBack = props => (
    <MenuItemLink
        {...props}
        to="root"
        module={moduleIdentifier}
        access
        upgradable={false}
        nested
        className="reversed xscender">
        <FormattedMessage
            id="action_back"
            defaultMessage="Back" />
    </MenuItemLink>
)

export default NestedBack