import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Wrapper, Graph } from './s'

const GapLoader = ({ active, ...props }) => (
    <AnimatePresence>
        <Wrapper
            {...props}
            animate={active ? 'show' : 'hide'}>
            <Graph>
                <div />
                <div />
                <div />
                <div />
                <div />
            </Graph>
        </Wrapper>
    </AnimatePresence>
)

export default GapLoader
