import { useIntl } from 'react-intl'
import { useIdentifications } from 'contexts/identifications'
import { getIdentificationTypeLabel } from './'

export const useError = () => {
    const { formatMessage } = useIntl()

    const { error } = useIdentifications()

    return type => {
        if(!error) {
            return null
        }

        const {
            errorType,
            fields
        } = error

        let validationError

        if(errorType === 'invalid_value') {
            validationError = getValidationError(errorType)
        }

        if(errorType === 'duplicate') {
            if(fields.includes('value')) {
                validationError = getValidationError(`duplicate_${type}_value`)
            } else {
                validationError = getValidationError('duplicate_type')
            }
        }

        if(!validationError) {
            validationError = {
                id: 'employees_import_error_field_invalid_format',
                defaultMessage: 'Invalid field format'
            }
        }

        return formatMessage(validationError, { type: getIdentificationTypeLabel(type) })
    }
}

const getValidationError = type => ({
    'invalid_value': {
        id: 'person_label_identifications_invalid_national',
        defaultMessage: 'Invalid National identification number'
    },
    'duplicate_national_value': {
        id: 'person_label_identifications_duplicate_national_value',
        defaultMessage: 'The National identification number already exists'
    },
    'duplicate_passport_value': {
        id: 'person_label_identifications_duplicate_passport_value',
        defaultMessage: 'The Passport number already exists'
    },
    'duplicate_type': {
        id: 'person_label_identifications_duplicate_type',
        defaultMessage: 'You already have an identification of this type'
    }
})[type]