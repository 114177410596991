import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { parseISO, differenceInYears, format } from 'date-fns'
import { Item, Details, Detail, SecondaryDetail } from './s'
import { Label } from '../../s'
import { FlexChildShrink } from 'components/flex'
import ContextMenu from 'widgets/context-menu'

const Child = ({ child, editable, actions, salt }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const {
        name,
        birthDate
    } = child

    const birth = parseISO(birthDate)
    const now = new Date()
    const years = differenceInYears(now, birth)

    const age = formatMessage({
        id: 'age_years',
        defaultMessage: `{years, plural, =0 {0 years} one {1 year} other {# years}}`
    }, { years })

    return (
        <Item>
            <Label className="horizontal">{age}</Label>
            <Details>
                <Detail>{name}</Detail>
                <SecondaryDetail>{format(birth, 'PPP', { locale })}</SecondaryDetail>
            </Details>
            {!!editable && (
                <FlexChildShrink>
                    <ContextMenu
                        salt={salt}
                        context={child}
                        actions={actions} />
                </FlexChildShrink>
            )}
        </Item>
    )
}

export default Child
