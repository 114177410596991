import React, { useState, useEffect } from 'react'
import { useCompetenceProfiles } from 'contexts/competence-profiles'
import { clamp } from 'utilities/math'
import { SkeletonListRow } from './s'
import { OrderedList } from 'components/list'
import { SkeletonCell, SkeletonSymbolAndMeta } from 'components/skeleton'
import Profile from './profile'

const Profiles = ({ searchingDebounce = false, salt, ...props }) => {
    const {
        profiles = [],
        intersecter,
        loading,
        fetching,
        paging = {},
        hasFetched
    } = useCompetenceProfiles()

    const { hasNextPage } = paging

    const [skeletonLength, setSkeletonLength] = useState(3)

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(profiles.length, 1, 5))
        }
    }, [hasFetched, profiles?.length])

    const autoFetching = (!!intersecter && !!hasNextPage && !!loading)

    return (
        <OrderedList>
            {profiles.map((profile, index) => {
                const last = index + 1 === profiles.length

                return (
                    <Profile
                        {...props}
                        profile={profile}
                        salt={`${salt}:profiles`}
                        {...((!!intersecter && last) ? { ref: intersecter } : null)}
                        key={`${salt}:profiles:${profile.id}`} />
                )
            })}
            {[
                autoFetching,
                (!!fetching && !hasFetched),
                searchingDebounce
            ].some(Boolean) && [...Array(autoFetching ? 1 : skeletonLength)].map((_, index) => (
                <SkeletonListRow key={`list:competence:profiles:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonSymbolAndMeta
                            size={40}
                            showSecondLine={true} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
        </OrderedList>
    )
}

export default Profiles
