import { pick } from 'utilities/object'
import { capitalize } from 'utilities/string'
import { sendAppSignal } from 'hooks/app-signal'

export const enrichInvitation = (invitation, includePrefix = false) => {
    let invitationFields = pick(invitation, 'language', 'subject')

    if(includePrefix) {
        // Add 'invite' prefix to all invitation fields, and make them camelCase
        invitationFields = Object.keys(invitationFields).reduce((accumulator, key) => {
            accumulator[`invite${capitalize(key)}`] = invitationFields[key]

            return accumulator
        }, {})
    }

    return {
        ...(invitation.useMessage ? {
            [includePrefix ? 'inviteMessage' : 'message']: invitation.message,
        } : null),
        ...invitationFields
    }
}

export const showJubileeGift = who => global.setTimeout(() => sendAppSignal('jubilee-gift.show', who), 500)

export const getPluralizedType = type => ({
    team: 'teams',
    location: 'locations'
})[type] ?? `${type}s`