import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useFlash } from 'hooks/flash'
import { getReferenceDateName, getTitleTranslationByType } from 'pages/processes/utilities'
import { getOnboardingOnboardingUrl, getOffboardingOffboardingUrl, getProcessesProcessUrl } from 'utilities/url'
import { parseISO, format } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import {
    UnitWrapper,
    Link, NameCell, ProgressNumbers, InlineMessage,
    RegardingCell, BaseDateCell, ActionsCell
} from './s'
import Progress from 'components/progress'
import Person from 'components/person'
import Paragraph from 'components/typography/paragraph'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'
import { InlineButton } from 'components/button'
import { PlainTexts } from 'components/entities'

const ProcessItem = forwardRef(({ process, actions = {}, flash }, ref) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const flasher = useFlash(flash)

    const {
        id,
        type,
        archived = false,
        completedTaskCount: completedTasks,
        taskCount: totalTasks,
        overdueTaskCount: overdueTasks,
        tasksUnassigned: unassignedTasks,
        [getReferenceDateName(process.type)]: referenceDate,
        concerns
    } = process

    const getUrl = {
        onboarding: getOnboardingOnboardingUrl,
        offboarding: getOffboardingOffboardingUrl,
        process: getProcessesProcessUrl
    }[process.type]

    const title = process?.title ?? formatMessage(getTitleTranslationByType(type))

    let progress = parseInt(completedTasks / totalTasks * 100)

    if(isNaN(progress)) {
        progress = 0
    } else if(progress > 100) {
        progress = 100
    }

    const archiveAction = actions?.archive?.(process)

    const salt = `processes:${process.type}:process:${id}`

    return (
        <UnitWrapper ref={mergeRefs([ref, flasher])}>
            <NameCell {...((!!overdueTasks || !!unassignedTasks) ? { className: 'has-warning' } : null)}>
                <Link
                    to={getUrl({ id })}
                    onClickCapture={e => {
                        if(e.target.getAttribute('role') === 'button') {
                            e.preventDefault()
                        }
                    }}>
                    <Progress
                        completed={completedTasks}
                        total={totalTasks}
                        size={32}
                        mode="compact"
                        className="compact" />
                    <div className="meta">
                        <Paragraph className="ellipsify compact">{title}</Paragraph>
                        <Paragraph className="caption small compact">
                            <ProgressNumbers>
                                <span {...(progress === 100) ? { className: 'completed' } : null}>
                                    <FormattedMessage
                                        id="value_percent"
                                        defaultMessage="{percent} %"
                                        values={{ percent: progress }} />
                                </span>
                                <span>({completedTasks} / {totalTasks})</span> {/* eslint-disable-line no-irregular-whitespace */}
                            </ProgressNumbers>
                            {(completedTasks === totalTasks && totalTasks > 0 && !archived && archiveAction) && (
                                <InlineButton
                                    onClick={archiveAction?.onClick}
                                    className="constructive small">
                                    <FormattedMessage
                                        id="action_archive"
                                        defaultMessage="Archive" />
                                </InlineButton>
                            )}
                        </Paragraph>
                    </div>
                </Link>
            </NameCell>
            <RegardingCell>
                {concerns?.type === 'user' && (
                    <Person
                        who={concerns}
                        truncate={true} />
                )}
                {['team', 'location'].includes(concerns?.type) && (
                    <PlainTexts
                        entities={[concerns]}
                        showAggregateIcon={true}
                        className="compact" />
                )}
            </RegardingCell>
            <BaseDateCell>
                <Paragraph className="caption small compact">
                    {!referenceDate && '–'}
                    {!!referenceDate && format(parseISO(referenceDate), 'PPP', { locale })}
                </Paragraph>
            </BaseDateCell>
            {!!getActionsArray(actions)?.length && (
                <ActionsCell className="actions">
                    <ContextMenu
                        salt={salt}
                        context={process}
                        actions={actions} />
                </ActionsCell>
            )}
            {!!overdueTasks && (
                <InlineMessage
                    message={formatMessage({
                        id: 'employee_onboarding_tasks_overdue_count',
                        defaultMessage: '{count, plural, =0 {} =1 {1 task overdue} other {{count} tasks overdue}}'
                    }, { count: overdueTasks })}
                    type="warning"
                    className="compact" />
            )}
            {!!unassignedTasks && (
                <InlineMessage
                    message={formatMessage({
                        id: 'employee_onboarding_tasks_unassigned_count',
                        defaultMessage: '{count, plural, =0 {} =1 {1 task unassigned} other {{count} tasks unassigned}}'
                    }, { count: unassignedTasks })}
                    type="error"
                    className="compact" />
            )}
        </UnitWrapper>
    )
})

export default ProcessItem