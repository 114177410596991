import { useEffect, useLayoutEffect, useRef } from 'react'

export const useInterval = (callback, every) => {
    const savedCallback = useRef()
    const intervalId = useRef()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useLayoutEffect(() => {
        const tick = () => savedCallback.current()

        if(every || every === 0) {
            intervalId.current = global.setInterval(tick, every)
            return () => global.clearInterval(intervalId.current)
        }
    }, [every])

    return intervalId.current ?
        () => global.clearInterval(intervalId.current) :
        () => {}
}
