import styled from 'styled-components'
import { Columns as ColumnsBase } from 'components/flex'

export const Fields = styled.div`
    margin-top: 24px;
`

export const Columns = styled(ColumnsBase)`
    > div:not(:first-child) {
        margin-left: 24px;
    }
`
