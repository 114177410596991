import React from 'react'
import { useAppSignal } from 'hooks/app-signal'

const SidePanel = ({ setIsOpen = (() => {}) }) => {
    useAppSignal({
        namespace: 'side-panel.toggle',
        action: setIsOpen
    })

    return <div id="side-panel" />
}

export default SidePanel