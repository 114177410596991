import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Menu, CloseButton, Heading, Subheading } from './s'
import { Button } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import AddItems from 'pages/handbook/modals/template/add-items'
import { X } from 'styled-icons/feather'
import { SkeletonStrings } from 'components/skeleton'

const HandbookTemplatePreviewHero = ({ template, dismiss, context, salt }) => {
    const { formatMessage } = useIntl()

    const [addingItems, setAddingItems] = useState(false)

    if (!template) {
        return <SkeletonStrings size={40} />
    }

    const enabledCategoriesCount = template.categories.filter(category => category.enabled)?.length
    const enabledTopicsCount = template.categories
        .filter(({ enabled }) => enabled)
        .flatMap(({ topics }) => topics)
        .filter(({ enabled }) => enabled)
        .length

    return (
        <>
            <Menu>
                <CloseButton onClick={dismiss}>
                    <X size={24} />
                </CloseButton>
                <Button
                    className="constructive"
                    autoFocus={true}
                    disabled={!enabledCategoriesCount}
                    onClick={() => setAddingItems(true)}>
                    <FormattedMessage
                        id="handbooks_template_action_add_categories_topics"
                        defaultMessage="Add {categories} and {topics}"
                        values={{
                            categories: formatMessage({
                                id: 'category_count',
                                defaultMessage: '{count, plural, =0 {0 categories} =1 {1 category} other {{count} categories}}'
                            }, {
                                count: enabledCategoriesCount
                            }),
                            topics: formatMessage({
                                id: 'topic_count',
                                defaultMessage: '{count, plural, =0 {0 topics} =1 {1 topic} other {{count} topics}}'
                            }, {
                                count: enabledTopicsCount
                            })
                        }} />
                </Button>
            </Menu>
            <Heading>
                <FormattedMessage
                    id="handbooks_setup_step_content_heading"
                    defaultMessage="Choose what categories and topics to include" />
            </Heading>
            <Subheading>
                <FormattedMessage
                    id="handbooks_setup_step_content_description"
                    defaultMessage="You can also add, edit or remove content later." />
            </Subheading>
            <Modal
                show={addingItems}
                dismiss={() => setAddingItems(false)}>
                <AddItems
                    template={template}
                    context={context}
                    dismiss={() => setAddingItems(false)}
                    onDone={() => {
                        dismiss()
                        setAddingItems(false)}
                    }
                    salt={salt} />
            </Modal>
        </>
    )
}

export default HandbookTemplatePreviewHero
