import React from 'react'
import {
    Wrapper,
    FavIcon, Name, Url, ExternalLink
} from './s'
import Ellipsify from 'components/ellipsify'

const Link = ({ link }) => {
    const {
        url,
        name
    } = link

    return (
        <Wrapper
            href={url}
            target="_blank">
            <FavIcon
                size={24}
                src={url} />
            <Name className="compact">
                <Ellipsify
                    text={name}
                    head={5} />
            </Name>
            <Url className="compact">
                <Ellipsify
                    text={url}
                    head={9}
                    tail={9} />
            </Url>
            <ExternalLink size={16} />
        </Wrapper>
    )
}

export default Link
