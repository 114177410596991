import React, { Children, cloneElement, isValidElement } from 'react'
import TasksProvider from 'contexts/tasks'
import DocumentsProvider from 'contexts/documents'
import MeetingEventsProvider from 'contexts/meeting-events'
import SalaryRevisionGroupsProvider from 'contexts/salary-revision-groups'
import AbsenceEntriesProvider from 'contexts/absence-entries'
import WhistleblowingCasesProvider from 'contexts/whistleblowing-cases'
import DeviationCasesProvider from 'contexts/deviation-cases'
import { subYears } from 'date-fns'
import { getDate } from 'utilities/date-time'

const childrenMapper = (children, props) => Children.map(children, child => {
    if(isValidElement(child)) {
        return cloneElement(child, props)
    }

    return child
})

export const Tasks = ({ children, access, ...props }) => {
    if(!access.tasks) {
        return childrenMapper(children, {
            ...props,
            hasFetchedTasks: true
        })
    }

    return (
        <TasksProvider
            eternal={false}
            filter={{
                completed: 'false',
                assignedTo: 'me'
            }}
            paging={{ limit: 3 }}>
            {({ hasFetched: hasFetchedTasks, total: totalTasks }) => childrenMapper(children, {
                ...props,
                hasFetchedTasks,
                totalTasks
            })}
        </TasksProvider>
    )
}

export const Documents = ({ children, access, ...props }) => {
    if(!access.documents) {
        return childrenMapper(children, {
            ...props,
            hasFetchedDocuments: true
        })
    }

    return (
        <DocumentsProvider
            filter={{
                types: ['file'],
                awaitsSignatureFrom: 'me'
            }}
            paging={{ limit: 1 }}>
            {({ hasFetched: hasFetchedDocuments, total: totalDocuments }) => childrenMapper(children, {
                ...props,
                hasFetchedDocuments,
                totalDocuments
            })}
        </DocumentsProvider>
    )
}

export const Meetings = ({ children, access, ...props }) => {
    if(!access.meetings) {
        return childrenMapper(children, {
            ...props,
            hasFetchedMeetings: true
        })
    }

    return (
        <MeetingEventsProvider
            filter={{
                attendee: 'me',
                status: ['draft', 'open']
            }}
            paging={{ limit: 1 }}>
            {({ hasFetched: hasFetchedMeetings, total: totalMeetings }) => childrenMapper(children, {
                ...props,
                hasFetchedMeetings,
                totalMeetings
            })}
        </MeetingEventsProvider>
    )
}

export const SalaryRevisionGroups = ({ children, access, ...props }) => {
    if(!access.salaryRevisionGroups) {
        return childrenMapper(children, {
            ...props,
            hasFetchedSalaryRevisionGroups: true
        })
    }

    return (
        <SalaryRevisionGroupsProvider>
            {({ hasFetched: hasFetchedSalaryRevisionGroups, total: totalSalaryRevisionGroups }) => childrenMapper(children, {
                ...props,
                hasFetchedSalaryRevisionGroups,
                totalSalaryRevisionGroups
            })}
        </SalaryRevisionGroupsProvider>
    )
}

export const Absence = ({ children, access, ...props }) => {
    if(!access.absence) {
        return childrenMapper(children, {
            ...props,
            hasFetchedAbsence: true
        })
    }

    return (
        <AbsenceEntriesProvider
            filter={{
                fromDate: getDate(subYears(new Date(), 1)),
                permissionLevel: 'approve',
                statuses: ['pending']
            }}
            paging={{ limit: 1 }}>
            {({ hasFetched: hasFetchedAbsence, total: totalAbsence }) => childrenMapper(children, {
                ...props,
                hasFetchedAbsence,
                totalAbsence
            })}
        </AbsenceEntriesProvider>
    )
}

export const Whistleblowing = ({ children, access, ...props }) => {
    if(!access.whistleblowing) {
        return childrenMapper(children, {
            ...props,
            hasFetchedWhistleblowingCases: true
        })
    }

    return (
        <WhistleblowingCasesProvider
            filter={{ statuses: ['new', 'seen'] }}
            paging={{ limit: 1 }}>
            {({ hasFetched: hasFetchedWhistleblowingCases, total: totalWhistleblowingCases }) => childrenMapper(children, {
                ...props,
                hasFetchedWhistleblowingCases,
                totalWhistleblowingCases
            })}
        </WhistleblowingCasesProvider>
    )
}

export const Deviation = ({ children, access, ...props }) => {
    if(!access.deviation) {
        return childrenMapper(children, {
            ...props,
            hasFetchedDeviationCases: true
        })
    }

    return (
        <DeviationCasesProvider
            filter={{ statuses: ['not_started'] }}
            paging={{ limit: 1 }}>
            {({ hasFetched: hasFetchedDeviationCases, total: totalDeviationCases }) => childrenMapper(children, {
                ...props,
                hasFetchedDeviationCases,
                totalDeviationCases
            })}
        </DeviationCasesProvider>
    )
}