import styled from 'styled-components'
import { print, screen } from 'utilities/styled'
import { fontSerif } from 's'
import SymbolBase from 'components/symbol'
import CompetenceProfileStatus from 'pages/competence/components/profile-status'
import { H2 } from 'components/typography/heading'
import { tiptapContentCss } from 'components/tiptap/s'

export const Symbol = styled(SymbolBase)`
    margin-bottom: 16px;
    width: 56px;
    height: 56px;

    font-size: 26px;
`

export const ProfileStatus = styled(CompetenceProfileStatus)`
    display: block;

    margin-bottom: 16px;
`

export const Heading = styled(H2)`
    margin-bottom: 16px;

    line-height: 30px;

    ${fontSerif}

    &:nth-last-child(2) {
        margin-bottom: 0;
    }
`

export const Description = styled.div`
    ${tiptapContentCss}

    > :last-child {
        margin-bottom: 0 !important;
    }

    ${screen`
        a {
            overflow-wrap: break-word;
        }

        .highlight,
        .match {
            all: unset;
        }
    `}

    ${print`
        .rough-annotation {
            display: none;
        }
    `}
`