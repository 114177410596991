import styled from 'styled-components'

export const DateRangeWrapper = styled.span`
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;

    line-height: 20px;
    text-decoration: inherit;
`

export const Division = styled.p`
    margin: 0;
`

export const Constructive = styled.span`
    color: --huma-color-foreground-constructive-bold;
`