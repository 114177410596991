import styled from 'styled-components'
import { Ghost as GhostBase } from 'components/button'
import CheckboxFieldBase from 'components/form/field/checkbox'

export const Ghost = styled(GhostBase)`
    align-self: flex-start;
`

export const CheckboxField = styled(CheckboxFieldBase)`
    margin-block: 16px 0;
`