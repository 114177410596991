import React from 'react'
import Page from 'contexts/page'
import HandbookProvider, { useHandbook } from 'contexts/handbook'
import Loader from 'components/loader'
import Routing from './routing'

const HandbookPage = props => {
    const {
        fetching,
        hasFetched
    } = useHandbook()

    if(!hasFetched && fetching) {
        return <Loader />
    }

    return <Routing {...props} />
}

export const blankSymbol = {
    emoji: 'memo',
    colorToken: 'symbol-green-1'
}

export default ({ upgradable, ...props }) => (
    <Page
        view="handbook"
        trackView={false}
        title={{
            id: 'noun_handbook',
            defaultMessage: 'Handbook'
        }}
        upgrade={{
            enabled: upgradable,
            passthrough: true
        }}>
        <HandbookProvider fetchAccess={!upgradable}>
            <HandbookPage
                {...props}
                upgradable={upgradable} />
        </HandbookProvider>
    </Page>
)