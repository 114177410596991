import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { enrichInvitation } from 'pages/people/utilities'
import { post } from 'api'
import { Header, Content, Footer } from './s'
import { ModalHeader } from 'modals/generic'
import { ToggleSwitch } from './s'
import Paragraph from 'components/typography/paragraph'
import Message from 'components/message'
import { getShortName } from 'utilities/person'
import Link from 'components/link'
import Users from './users'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'

const InvitePeople = ({ users, inspectInvitationEmail, invitationData, onDone, salt, modal }) => {
    const { formatMessage } = useIntl()

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    const invitees = users.filter(user => !user.recentlyInvited)
    const invited = users.filter(user => user.recentlyInvited)
    const togglable = users.length > 1 && !!invitees.length && invitees.length < users.length
    const submissible = !!invitees.length

    const [showRecentlyInvited, setShowRecentlyInvited] = useState(!togglable && !submissible)
    const [inviting, setInviting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const invite = async () => {
        setError(false)
        setInviting(true)

        const invitation = enrichInvitation(invitationData)

        const path = users.length === 1 ?
            `/users/${users[0].id}/invite` :
            '/users/invite'

        const { ok } = await post({
            path,
            ...(invitation ? { body: invitation } : null),
            returnsData: false
        })

        setInviting(false)

        if(ok) {
            setSuccess(true)
            !!onboarder && updateOnboardingStatus({ inviteUninvitedUsers: 'completed' })
        } else {
            setError(true)
        }
    }

    const heading = formatMessage({
        id: (users.length === 1) ?
            'person_invite_heading' :
            'people_everyone_invite_heading',
        defaultMessage: (users.length === 1) ?
            'Invite user' :
            'Invite users'
    })

    return (
        <>
            <Header>
                <ModalHeader
                    heading={heading}
                    dismiss={modal.dismiss} />
                {!success && (
                    <>
                        <HeaderCaption
                            users={users}
                            togglable={togglable}
                            submissible={submissible}
                            inspectInvitationEmail={inspectInvitationEmail} />
                        {togglable && (
                            <ToggleSwitch
                                value={showRecentlyInvited}
                                no={heading}
                                yes={formatMessage({
                                    id: 'people_everyone_invite_recently_invited',
                                    defaultMessage: 'Recently invited'
                                })}
                                onChange={setShowRecentlyInvited} />
                        )}
                        {showRecentlyInvited && <RecentlyInvitedTabSummary invited={invited} />}
                        {!showRecentlyInvited && (
                            <InviteUsersTabSummary
                                invitees={invitees}
                                inspectInvitationEmail={inspectInvitationEmail}
                            />
                        )}
                    </>
                )}
            </Header>
            {!success && (
                <Content>
                    <Users
                        users={showRecentlyInvited ?
                            invited :
                            invitees
                        }
                        reason={showRecentlyInvited ?
                            'invited' :
                            'invitee'
                        }
                        salt={salt} />
                </Content>
            )}
            {!!error && (
                <Message
                    type="error"
                    className="compact"
                    message={formatMessage({
                        id: (invitees.length === 1) ?
                            'person_invite_error' :
                            'people_everyone_invite_error',
                        defaultMessage: (invitees.length === 1) ?
                            'There was an error inviting {name}. Please try again.' :
                            'There was an error inviting users. Please try again.'
                    })} />
            )}
            {!!success && (
                <Message
                    type="success"
                    className="compact"
                    message={formatMessage({
                        id: (invitees.length === 1) ?
                            'person_invite_success' :
                            'people_everyone_invite_success',
                        defaultMessage: (invitees.length === 1) ?
                            'An invitation has been sent to {name}' :
                            'An invitation has been sent to {count} persons'
                    }, {
                        name: getShortName(invitees[0]),
                        count: invitees.length
                    })} />
            )}
            {(submissible && !showRecentlyInvited) && (
                <Footer>
                    <Actions {...(success ? { className: 'centered' } : null)}>
                        <Plain
                            onClick={success ?
                                onDone :
                                modal.dismiss
                            }
                            className="neutral"
                            disabled={inviting}>
                            <FormattedMessage
                                id={success ?
                                    'action_done' :
                                    'action_cancel'
                                }
                                defaultMessage={success ?
                                    'Done' :
                                    'Cancel'
                                } />
                        </Plain>
                        {!success && (
                            <Button
                                className={`constructive${inviting ? ' loading' : ''}`}
                                onClick={invite}
                                disabled={inviting}>
                                <FormattedMessage
                                    id="action_send_invitation"
                                    defaultMessage="Send invitation" />
                            </Button>
                        )}
                    </Actions>
                </Footer>
            )}
        </>
    )
}

const HeaderCaption = ({ users, togglable, submissible, inspectInvitationEmail }) => {
    if(!togglable || !submissible) {
        return null
    }

    const trigger = chunks => (submissible && !togglable) ? (
        <Link
            onClick={inspectInvitationEmail}
            className="constructive">
            {chunks}
        </Link>
    ) : null

    return (
        <Paragraph {...!togglable ? { className: 'compact' } : null}>
            <FormattedMessage
                id={(users.length === 1) ?
                    'person_invite_summary' :
                    'people_everyone_invite_summary'
                }
                defaultMessage={(users.length === 1) ?
                    '{name} has not gotten started with Huma yet.<trigger>See and edit the invitation</trigger>' :
                    '{count} users have not gotten started with Huma yet. <trigger>See and edit the invitation</trigger>'
                }
                values={{
                    name: getShortName(users[0]),
                    count: users.length,
                    trigger
                }} />
        </Paragraph>
    )
}

const RecentlyInvitedTabSummary = ({ invited }) => (
    <Paragraph className="compact preserve-line-breaks">
        <FormattedMessage
            id="people_everyone_invite_summary_recently_invited"
            defaultMessage="{count, plural, =0 {} =1 {1 user has recently been invited.\nThey will not receive another invite now.} other {{count} users have recently been invited.\nThey will not receive another invite now.}}"
            values={{ count: invited.length }} />
    </Paragraph>
)

const InviteUsersTabSummary = ({ invitees, inspectInvitationEmail }) => (
    <Paragraph>
        <FormattedMessage
            id="people_everyone_invite_summary_invitees"
            defaultMessage="{count, plural, =0 {} =1 {1 user can be invited. <trigger>See and edit the invitation<\/trigger>} other {{count} users can be invited. <trigger>See and edit the invitation<\/trigger>}}"
            values={{
                count: invitees.length,
                trigger: chunks => (
                    <>
                        <br />
                        <Link
                            onClick={inspectInvitationEmail}
                            className="constructive">
                            {chunks}
                        </Link>
                    </>
                )
            }} />
    </Paragraph>
)

export default InvitePeople