import React, { Component, createContext, useContext } from 'react'
import { useAccess } from 'contexts/access'
import { useUserPermissions } from 'contexts/unit-permissions'
import { useMe } from 'contexts/me'
import { get } from 'api'
import PubSub from 'pubsub-js'
import isEqual from 'react-fast-compare'
import { invertSign } from 'utilities/number'

const AbsenceStatsContext = createContext()

class AbsenceStatsProvider extends Component {
    constructor(props) {
        super(props)

        !!props?.userId && this.setUserId(props.userId, false)
        !!props?.typeId && this.setTypeId(props.typeId, false)

        this.fetchController = new AbortController()

        this.state = {
            stats: null,
            types: null,
            filter: props.filter ?? {},

            fetchStats: this.fetch,
            makeAdjustmentLocally: this.makeAdjustmentLocally,
            removeAdjustmentLocally: this.removeAdjustmentLocally,
            estimateCount: this.estimateCount,
            setUserId: this.setUserId,
            setTypeId: this.setTypeId,

            removeAdjustment: this.removeAdjustment,

            error: null,
            resetError: () => this.setError(null),

            fetching: false,
            fetchingTypes: false,

            hasFetched: false
        }

        this.refreshSubscription = PubSub.subscribe('absenceStats.refresh', () => {
            this.setState({
                stats: null,
                types: null,

                hasFetched: false
            }, this.fetch)
        })
    }

    componentDidMount() {
        const {
            fetchOnMount = true,
            fetchTypesPreliminarily = false
        } = this.props

        fetchOnMount && this.fetch()
        fetchTypesPreliminarily && this.fetchPreliminaryTypes()
    }

    componentDidUpdate({ userId, type }, { filter }) {
        const userIdChanged = userId !== this.props.userId
        const typeChanged = type !== this.props.type
        const filterChanged = !isEqual(filter, this.state.filter)

        if (userIdChanged) {
            this.setUserId(this.props.userId, false)
        }

        if (typeChanged) {
            this.setTypeId(this.props.typeId, false)
        }

        if (userIdChanged || typeChanged) {
            this.fetch(true)
        }

        if (filterChanged) {
            this.fetch(true)
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        PubSub.unsubscribe(this.refreshSubscription)
    }

    fetch = async (force = false) => {
        const {
            filter,
            fetching,
            hasFetched
        } = this.state

        if(!this.userId || fetching || (!force && hasFetched)) {
            return
        }

        const absenceAdmin = this.props.systemAccess.check('absence:manage')
        const absenceManager = this.props.userPermissions?.checkUnitPermission('user:absence:manage')
        const ownAbsence = this.props.me.isItMyOwnId(this.userId)

        if(!absenceAdmin && !absenceManager && !ownAbsence) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { ok, response } = await get({
            path: `/absence/stats/${this.userId}`,
            params: {
                ...(this.typeId ? { type: this.typeId } : null),
                ...filter
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState({
                stats: response,
                fetching: false,
                hasFetched: true
            })
        } else {
            this.setState({
                fetching: false,
                hasFetched: true
            })
        }

        return { ok, response }
    }

    fetchPreliminaryTypes = async () => {
        this.setState({ fetchingTypes: true })

        const { ok, response } = await get({ path: '/absence/types' })

        if (ok && response) {
            this.setState({
                types: response.items.map(type => ({ type })),
                fetchingTypes: false
            })
        } else {
            this.setState({ fetchingTypes: false })
        }
    }

    makeAdjustmentLocally = (body, adjustment) => this.setState(({ stats: previousStats }) => {
        const {
            fromPeriodStartDate = null,
            toPeriodStartDate,
            type
        } = body

        let stats

        if(type === 'manual') {
            stats = previousStats.map(stat => {
                const {
                    policy,
                    effectiveTimeWindow
                } = stat

                if(stat.type.id !== body.absenceTypeId || !effectiveTimeWindow) {
                    return stat
                }

                if(effectiveTimeWindow.from === toPeriodStartDate) {
                    return {
                        ...stat,
                        policy: {
                            ...policy,
                            quota: policy.quota + body.days,
                            adjustments: [
                                ...(policy?.adjustments ?? []),
                                adjustment
                            ]
                        }
                    }
                }

                return stat
            })
        }

        if(type === 'transfer') {
            stats = previousStats.map(stat => {
                const {
                    policy,
                    effectiveTimeWindow
                } = stat

                if(stat.type.id !== body.absenceTypeId || !effectiveTimeWindow) {
                    return stat
                }

                if(effectiveTimeWindow.from === fromPeriodStartDate) {
                    return {
                        ...stat,
                        policy: {
                            ...policy,
                            quota: policy.quota - body.days,
                            adjustments: [
                                ...(policy?.adjustments ?? []),
                                adjustment
                            ]
                        }
                    }
                }

                if(effectiveTimeWindow.from === toPeriodStartDate) {
                    return {
                        ...stat,
                        policy: {
                            ...policy,
                            quota: policy.quota + body.days,
                            adjustments: [
                                ...(policy?.adjustments ?? []),
                                adjustment
                            ]
                        }
                    }
                }

                return stat
            })
        }


        return { stats }
    })

    removeAdjustmentLocally = adjustmentId => this.setState(({ stats: previousStats }) => {
        const adjustment = previousStats
            .flatMap(stat => stat.policy?.adjustments ?? [])
            .find(({ id }) => id === adjustmentId)

        if(!adjustment) {
            return
        }

        const stats = previousStats.map(stat => {
            let { days } = adjustment

            const {
                policy,
                effectiveTimeWindow
            } = stat

            if(policy?.adjustments?.find(({ id }) => id === adjustmentId)) {
                if(adjustment.type === 'transfer' && adjustment.fromPeriodStartDate === effectiveTimeWindow.from) {
                    days = invertSign(days)
                }

                return {
                    ...stat,
                    policy: {
                        ...policy,
                        quota: policy.quota - days,
                        adjustments: policy.adjustments.filter(({ id }) => id !== adjustmentId)
                    }
                }
            }

            return stat
        })

        return { stats }
    })

    estimateCount = async ({ userId, fromDate, toDate, grade = 1, type, excludeEntryId }) => {
        if (!userId || !type) {
            return
        }

        const { ok, response } = await get({
            path: `/absence/stats/${userId}/estimate`,
            params: {
                type,
                fromDate,
                toDate,
                grade,
                excludeEntryId
            }
        })

        !ok && this.setError(response)

        return { ok, response }
    }

    setError = data => {
        let error = null

        if (data) {
            const {
                values,
                errorCode,
                errorMessage
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replaceAll('-', '_'),
                errorMessage,
                overlappingEntries: values?.map(value => ({
                    ...value,
                    user: {}
                }))
            }
        }

        this.setState({ error })
    }

    setUserId = (id, fetch = true) => {
        this.userId = id
        fetch && this.fetch(true)

        !id && this.setState({ stats: null })
    }

    setTypeId = (id, fetch = true) => {
        this.typeId = id
        fetch && this.fetch(true)
    }

    render() {
        const { children = null } = this.props

        return (
            <AbsenceStatsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AbsenceStatsContext.Provider>
        )
    }
}

export default props => {
    const systemAccess = useAccess()
    const userPermissions = useUserPermissions()
    const me = useMe()

    return (
        <AbsenceStatsProvider
            {...props}
            systemAccess={systemAccess}
            userPermissions={userPermissions}
            me={me} />
    )
}

export const useAbsenceStats = () => useContext(AbsenceStatsContext)