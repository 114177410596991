import React from 'react'
import { useAppSignal } from 'hooks/app-signal'

const BottomPanel = ({ setIsOpen }) => {
    useAppSignal({
        namespace: 'bottom-panel.toggle',
        action: setIsOpen
    })

    return <div id="bottom-panel" />
}

export default BottomPanel