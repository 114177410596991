import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useConfiguration } from 'contexts/configuration'
import { MenuItemLink } from './'

const moduleIdentifier = 'activity-log'

const ActivityLog = props => {
    const {
        initialized,
        check
    } = useAccess()

    const { configuration } = useConfiguration()

    const access = !!configuration?.audit && initialized && check('organization:manage')

    return (
        <MenuItemLink
            {...props}
            to="activityLog"
            module={moduleIdentifier}
            access={access}
            upgradable={false}>
            <FormattedMessage
                id="noun_activity_log"
                defaultMessage="Activity log" />
        </MenuItemLink>
    )
}

export default ActivityLog