import React, { useState, useEffect, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { useMe } from 'contexts/me'
import { useStorage } from 'hooks/storage'
import { get } from 'api'
import { compact as compactArray } from 'utilities/array'
import { pick, size, compact, omit } from 'utilities/object'
import { ChangeButton, OneOfField, ShareField } from './s'
import { CaptionSmall } from 'components/typography/caption'
import WordList from 'components/word-list'
import { Scrollable as Modal } from 'modals/generic'
import { H2 as Heading } from 'components/typography/heading'
import Form from 'components/form/controller'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const Filter = ({ salt }) => {
    const { formatMessage } = useIntl()

    const groupsRef = useRef()

    const { me } = useMe()
    const { organization } = useOrganization()

    const [acting, setActing] = useState(false)
    const [groups, setGroups] = useState([])
    const [applying, setApplying] = useState(false)

    const {
        entries,
        hasFetched,
        loading,

        filter,
        setEntriesFilter
    } = useAbsenceEntries()

    const [memoryFilter, setMemoryFilter] = useStorage({
        key: salt,
        defaultValue: filter,
        type: 'session'
    })

    const advancedFilterFields = [
        'teams',
        'locations'
    ]

    const [advancedFilter, setAdvancedFilter] = useState(
        pick(memoryFilter, ...advancedFilterFields)
    )

    useEffect(() => {
        const fetch = async () => {
            const results = await Promise.all([
                get({ path: '/teams' }),
                get({ path: '/locations' })
            ])

            const [
                { ok: teamsOk, response: teams },
                { ok: locationsOk, response: locations }
            ] = results

            setGroups(compactArray([
                ...((teamsOk && teams?.length) ? teams.map(team => ({ ...team, type: 'team' })) : []),
                ...((locationsOk && locations?.length) ? locations.map(location => ({ ...location, type: 'location' })) : [])
            ]))
        }

        fetch()
    }, [])

    const updateAdvancedFilter = update => {
        setApplying(true)

        const {
            filterType,
            groups
        } = update

        if(!!groups?.length && filterType !== 'all') {
            const groupedFilter = groups.reduce((accumulator, { type, id }) => {
                const pluralizedType = `${type}s`
                const existingGroup = pick(accumulator, pluralizedType)

                if(!!size(existingGroup)) {
                    existingGroup[pluralizedType].push(id)
                    return accumulator
                }

                return {
                    ...accumulator,
                    [pluralizedType]: [id]
                }
            }, {})

            update.locations = groupedFilter?.locations ?? []
            update.teams = groupedFilter.teams ?? []

            setAdvancedFilter(pick(update, 'locations', 'teams'))
        } else {
            setAdvancedFilter({
                teams: [],
                locations: []
            })
        }

        setApplying(false)
        setActing(false)
    }

    useEffect(() => {
        setEntriesFilter({
            ...filter,
            ...advancedFilter
        })

        setMemoryFilter({
            ...filter,
            ...advancedFilter
        })
    }, [JSON.stringify(advancedFilter)])

    if(!organization || !me) {
        return null
    }

    const filtersApplied = !!Object.values(compact(omit(memoryFilter, 'fromDate', 'toDate', 'statuses'))).flatMap(a => a).length

    const groupsValue = filtersApplied ?
        groups.filter(({ id }) => memoryFilter?.locations?.includes(id) || memoryFilter?.teams?.includes(id)) :
        compactArray([
            ...me?.locations?.value ?? null,
            ...me?.teams?.value ?? null
        ])

    const noEntries = (hasFetched && !loading && !entries?.length)

    return (
        <>
            <CaptionSmall className="compact">
                <FormattedMessage
                    id={(filtersApplied && !!groups?.length) ?
                        `dashboard_absence_filter_info_groups${noEntries ? '_empty' : ''}` :
                        `dashboard_absence_filter_info_all${noEntries ? '_empty' : ''}`
                    }
                    defaultMessage={(filtersApplied && !!groups?.length) ?
                        'Showing absence for members of {groups} who are away today and in the next 7 days.' :
                        'Showing absence for everyone in {organization} who are away today and in the next 7 days.'
                    }
                    values={{
                        groups: <WordList words={groupsValue.map(({ name }) => name)} />,
                        organization: organization.name
                    }} />
                <ChangeButton
                    onClick={() => setActing(true)}>
                    <FormattedMessage
                        id="action_change"
                        defaultMessage="Change" />
                </ChangeButton>
            </CaptionSmall>
            <Modal
                show={!!acting}
                dismiss={() => setActing(false)}>
                <Heading>
                    <FormattedMessage
                        id="dashboard_absence_heading"
                        defaultMessage="Who’s away?" />
                </Heading>
                <Form
                    layout="vertical"
                    onSubmit={updateAdvancedFilter}>
                    {({ values, touched, errors, trigger }) => (
                        <>
                            <OneOfField
                                salt={salt}
                                label={formatMessage({
                                    id: 'label_include_who',
                                    defaultMessage: 'Who should be included?'
                                })}
                                name="filterType"
                                field={{
                                    value: (!!memoryFilter?.teams?.length || !!memoryFilter?.locations?.length) ? 'groups' : 'all',
                                    include: 'always',
                                    options: [
                                        {
                                            value: 'all',
                                            label: formatMessage({
                                                id: 'organization_everyone_in',
                                                defaultMessage: 'Everyone in {name}'
                                            }, organization),
                                            content: null
                                        },
                                        {
                                            value: 'groups',
                                            label: formatMessage({
                                                id: 'groups_members_of',
                                                defaultMessage: 'Members of teams or locations'
                                            }),
                                            controlRef: groupsRef,
                                            content: !!groups?.length && (
                                                <ShareField
                                                    salt={salt}
                                                    className="compact"
                                                    label={false}
                                                    name="groups"
                                                    field={{
                                                        value: groupsValue,
                                                        include: 'always'
                                                    }}
                                                    picker={{
                                                        types: ['team', 'location']
                                                    }}
                                                    key={`${salt}:${JSON.stringify(groups)}`}
                                                    ref={groupsRef} />
                                            )
                                        }
                                    ]
                                }} />
                            <Actions>
                                <Plain
                                    disabled={applying}
                                    onClick={() => setActing(false)}>
                                    <FormattedMessage
                                        id="action_cancel"
                                        defaultMessage="Cancel" />
                                </Plain>
                                <ButtonSubmit
                                    className={`constructive${applying ? ' loading' : ''}`}
                                    disabled={!touched?.length || !!size(errors) || (values?.filterType === 'groups' && !values?.groups?.length)}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id="action_save"
                                        defaultMessage="Save" />
                                </ButtonSubmit>
                            </Actions>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    )
}

export default Filter
