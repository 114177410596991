import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { sendAppSignal } from 'hooks/app-signal'
import { Header, Emoji, Heading, SetupParagraph } from './s'
import Link from 'components/link'
import { MenuItem, MenuItemSeparator } from '../'

const ServiceOnboarding = ({ dividerAbove = false }) => {
    const { onboarder, progress } = useServiceOnboarding()

    const show = !!onboarder && progress < 100
    const animate = show ? 'show' : 'hide'

    return (
        <>
            {dividerAbove && <MenuItemSeparator animate={animate} />}
            <MenuItem
                show={show}
                identifier="service-onboarding"
                className="padded">
                <Header>
                    <Emoji>⚡️</Emoji>
                    <Heading
                        className="compact"
                        as="h2">
                        <FormattedMessage
                            id="service_onboarding_progress_heading"
                            defaultMessage="Get started with Huma" />
                    </Heading>
                </Header>
                <SetupParagraph className="compact">
                    <Link
                        className="constructive"
                        onClick={() => sendAppSignal('service-onboarding.show')}>
                        <FormattedMessage
                            id="service_onboarding_setup_tasks_action_open"
                            defaultMessage="Open setup tasks" />
                    </Link>
                </SetupParagraph>
            </MenuItem>
            <MenuItemSeparator animate={animate} />
        </>
    )
}

export default ServiceOnboarding