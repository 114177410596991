import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { capitalize } from 'utilities/string'
import {
    TemplateItem,
    PickButton,
    Symbol,
    Meta, Name,
    Action
} from './s'
import { ArrowRight } from 'styled-icons/feather'

const Template = forwardRef(({ template, pick, adding, index }, ref) => {
    const { formatDisplayName } = useIntl()

    const { territories } = useI18n()

    const {
        name,
        topicCount,
        language,
        symbol,
        country
    } = template ?? {}

    return (
        <TemplateItem ref={ref}>
            {!!pick && (
                <PickButton
                    onClick={() => pick({
                        ...template,
                        type: 'template'
                    })}
                    disabled={adding}
                    {...(index === 0 ? { autoFocus: true } : null)} />
            )}
            <Symbol
                symbol={symbol}
                className="hover-effect" />
            <Meta>
                <Name>{name}</Name>
                <span>
                    {language && (
                        <span>{capitalize(formatDisplayName(language, { type: 'language' }))}</span>
                    )}
                    <span>
                    <FormattedMessage
                        id="topic_count"
                        defaultMessage="{count, plural, =0 {No topics} =1 {1 topic} other {{count} topics}}"
                        values={{ count: topicCount }} />
                    </span>
                </span>
                {(country in territories) && (
                    <span>
                        <FormattedMessage
                            id="territory_legislation_of_based_on"
                            defaultMessage="Based on legislation of {territory}"
                            values={{ territory: territories[country] }} />
                    </span>
                )}
            </Meta>
            <Action>
                <span>
                    <FormattedMessage
                        id="action_preview"
                        defaultMessage="Preview" />
                </span>
                <ArrowRight size={24} />
            </Action>
        </TemplateItem>
    )
})

export default Template
