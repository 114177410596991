import React from 'react'

export default props => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="22"
        viewBox="0 0 26 22"
        fill="none">
        <path d="M1.5 12L8 18.5L24.5 2" />
    </svg>
)