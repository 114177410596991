const getMatchingCharacters = (s1, s2) => {
    const matchingCharacters = []
    const matchDistance = Math.floor(Math.max(s1.length, s2.length) / 2) - 1

    for(let i = 0; i < s1.length; i++) {
        const start = Math.max(0, i - matchDistance)
        const end = Math.min(i + matchDistance + 1, s2.length)

        for(let j = start; j < end; j++) {
            if(s1.charAt(i) === s2.charAt(j)) {
                matchingCharacters.push(s1.charAt(i))
                break
            }
        }
    }

    return matchingCharacters
}

const getTransposition = (s1, s2, matchingCharacters) => {
    let transpositions = 0
    let k = 0 // Index of matching characters

    for(let i = 0; i < s1.length; i++) {
        if(matchingCharacters.includes(s1[i])) {
            if(s1[i] !== matchingCharacters[k]) {
                transpositions++
            }

            k++
        }
    }

    return transpositions
}

const getPrefixLength = (s1, s2) => {
    let prefixLength = 0

    for(let i = 0; i < Math.min(s1.length, s2.length); i++) {
        if(s1[i] === s2[i]) {
            prefixLength++
        } else {
            break
        }
    }

    return prefixLength
}

export const jarowinklerDistance = (s1, s2) => {
    const m = getMatchingCharacters(s1, s2) // Matching characters

    if(!m.length) {
        return 0
    }

    const t = getTransposition(s1, s2, m) // Transpositions
    const j = (m.length / s1.length + m.length / s2.length + (m.length - t / 2) / m.length) / 3 // Jaro distance

    const p = 0.1 // Scaling factor
    const l = Math.min(getPrefixLength(s1, s2), 4) // Prefix length

    return j + l * p * (1 - j)
}