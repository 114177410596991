import styled from 'styled-components'
import { Grid } from 'components/grid'
import { Naked, buttonStyles } from 'components/button'
import { H1 } from 'components/typography/heading'
import Caption from 'components/typography/caption'

export const Menu = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: space-between;

    margin-bottom: 16px;
`

export const CloseButton = styled(Naked)`
    ${buttonStyles.closeButtonCss()}

    position: static;
`

export const Heading = styled(H1)`
    margin-top: 16px;

    line-height: 40px;
`

export const Subheading = styled(Caption)`
    margin-top: -16px;
`