import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { useAuth } from 'contexts/auth'
import { useTheme } from 'contexts/theme'
import { useWhistleblowerPortalUrl } from 'pages/whistleblowing/utilities'
import { post } from 'api'
import paths from 'app/paths'
import ContextMenu from 'widgets/context-menu'
import { useAppSignal } from 'hooks/app-signal'
import { getPeopleProfileUrl } from 'utilities/url'
import MyName from 'components/my/name'
import { AccountToggler, NameWrapperLoading, MyAvatar, NameWrapper, SunMoon } from './s'
import SunMoonIcon from './sun-moon'
import { SkeletonCircle } from 'components/skeleton'
import { ArrowSortedDown as ToggleArrow } from 'styled-icons/typicons'
import {
    User as Profile,
    Settings as AccountSettingsIcon,
    Bell as NotificationSettings,
    Edit as Dashboard,
    Power as LogOut
} from 'styled-icons/feather'
import { Whistleblowing } from 'components/feather'
import AccountSettings from './account-settings'
import ConfigureDashboard from './configure-dashboard'
import { sendAppSignal } from 'hooks/app-signal'

const MyActions = () => {
    const { formatMessage } = useIntl()

    const { statuses } = useOrganization()
    const whistleblowerPortalUrl = useWhistleblowerPortalUrl()

    const { me } = useMe()
    const { checkModule } = useAccess()
    const { onSignOut } = useAuth()

    const {
        name: theme,
        setTheme
    } = useTheme()

    const [showAccountSettings, setShowAccountSettings] = useState(false)

    const peopleAccess = checkModule('people')
    const whistleblowingEnabled = checkModule('whistleblowing')
    const whisleblowingSetUp = !!statuses?.hasSetUpWhistleblowing

    const salt = 'navigation:my-actions'

    useAppSignal({
        namespace: 'account-settings.show',
        action: () => setShowAccountSettings(true)
    })

    const actions = [
        () => {
            if(!peopleAccess) {
                return null
            }

            return {
                salt: `${salt}:profile`,
                icon: <Profile size={24} />,
                label: formatMessage({
                    id: 'action_your_profile',
                    defaultMessage: 'Your profile'
                }),
                effect: 'neutral',
                element: 'link',
                link: {
                    to: getPeopleProfileUrl({ id: 'me' })
                }
            }
        },
        () => {
            if(!peopleAccess) {
                return null
            }

            return {
                salt: `${salt}:account-settings`,
                icon: <AccountSettingsIcon size={24} />,
                label: formatMessage({
                    id: 'noun_settings_account',
                    defaultMessage: 'User account settings'
                }),
                effect: 'neutral',
                onClick: () => setShowAccountSettings(true)
            }
        },
        () => {
            if(!peopleAccess) {
                return null
            }

            return {
                salt: `${salt}:notification-settings`,
                icon: <NotificationSettings size={24} />,
                label: formatMessage({
                    id: 'noun_settings_notifications',
                    defaultMessage: 'Notification settings'
                }),
                effect: 'neutral',
                element: 'link',
                link: {
                    to: paths.people.profile.settings.notifications
                }
            }
        },
        () => ({
            salt: `${salt}:switch-theme`,
            icon: (
                <SunMoon className={theme}>
                    <SunMoonIcon />
                </SunMoon>
            ),
            label: formatMessage({
                id: (theme === 'dark') ?
                    'settings_theme_action_light'
                    : 'settings_theme_action_dark',
                defaultMessage: (theme === 'dark') ?
                    'Switch to light theme'
                    : 'Switch to dark theme'
            }),
            effect: 'neutral',
            onClick: () => setTheme((theme === 'dark') ? 'light' : 'dark')
        }),
        () => ({
            salt: `${salt}:configure-dashboard`,
            icon: <Dashboard size={24} />,
            label: formatMessage({
                id: 'action_configure_dashboard',
                defaultMessage: 'Configure dashboard'
            }),
            effect: 'neutral',
            onClick: () => sendAppSignal('dashboard:configure.toggle', true)
        }),
        () => {
            if(!whistleblowingEnabled || !whisleblowingSetUp) {
                return null
            }

            return {
                salt: `${salt}:whistleblowing`,
                icon: <Whistleblowing size={24} />,
                label: formatMessage({
                    id: 'noun_whistleblower_portal',
                    defaultMessage: 'Whistleblower portal'
                }),
                effect: 'neutral',
                element: 'a',
                link: {
                    href: whistleblowerPortalUrl,
                    target: '_blank'
                },
                external: true
            }
        },
        () => ({
            salt: `${salt}:logout`,
            icon: <LogOut size={24} />,
            label: formatMessage({
                id: 'action_logout',
                defaultMessage: 'Log out'
            }),
            effect: 'neutral',
            onClick: () => {
                post({
                    path: '/auth/oauth/revoke',
                    returnsData: false
                })

                onSignOut({ userInitiated: true })
            }
        })
    ]

    return (
        <>
            <ContextMenu
                salt={salt}
                context={me}
                actions={actions}
                trigger={(
                    <AccountToggler
                        id="account-actions-toggle"
                        {...(!!me ? { className: 'loaded' } : null)}>
                        {!me && (
                            <>
                                <SkeletonCircle
                                    as="div"
                                    size={24} />
                                <NameWrapperLoading
                                    size={20}
                                    length={8} />
                            </>
                        )}
                        {!!me && (
                            <>
                                <MyAvatar size={24} />
                                <NameWrapper>
                                    <MyName />
                                </NameWrapper>
                            </>
                        )}
                        <ToggleArrow size={16} />
                    </AccountToggler>
                )}
                placement="bottom-end" />
            <AccountSettings
                showAccountSettings={showAccountSettings}
                setShowAccountSettings={setShowAccountSettings}
                salt={salt} />
            <ConfigureDashboard />
        </>
    )
}

export default MyActions