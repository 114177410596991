import styled, { css } from 'styled-components'
import { belowLaptop } from 'utilities/styled'
import { Simple } from 'components/button'
import { SkeletonStrings } from 'components/skeleton'
import MyAvatarBase from 'components/my/avatar'
// import { SunMoon as SunMoonBase } from 'components/feather'

export const AccountToggler = styled(Simple)`
    display: grid;
    grid-template-columns: 24px 1fr 16px;
    column-gap: 16px;
    align-items: center;
    min-height: 40px;

    &.loaded svg {
        opacity: 1;
    }

    svg {
        opacity: .2;
        transition: opacity .2s ease-in;
    }

    ${belowLaptop`
        grid-template-columns: min-content;
        width: 40px;
        height: 40px;
        padding: 0 8px;

        svg {
            display: none;
        }
    `}
`

const nameCss = css`
    ${belowLaptop`
        display: none;
    `}
`

export const NameWrapperLoading = styled(SkeletonStrings)`
    ${nameCss}
`

export const MyAvatar = styled(MyAvatarBase)`
    width: 24px;
    height: 24px;
`

export const NameWrapper = styled.div`
    ${nameCss}
`

export const SunMoon = styled.span`
    .sun,
    .sun-beams,
    .moon {
        transform-origin: center center;

        @media (prefers-reduced-motion: reduce) {
            transition-duration: 0s !important;
            transition-delay: 0s !important;
        }
    }

    .sun, .moon {
        transition: transform .5s cubic-bezier(.5, -.3, .1, 1.3);
    }

    .sun-beams {
        transition:
            opacity .5s cubic-bezier(.25, 0, .3, 1),
            transform .5s cubic-bezier(.25, 0, 1, 1);
    }

    &.light,
    button:hover &.dark {
        .sun {
            transform: translateY(0);
        }

        .moon {
            transform: translateY(24px);
        }

        .sun-beams {
            opacity: 1;
            transform: rotateZ(0deg);
        }
    }

    &.dark,
    button:hover &.light {
        .sun {
            transform: translateY(24px);
        }

        .moon {
            transform: translateY(0);
        }

        .sun-beams {
            opacity: 0;

            @media (prefers-reduced-motion: no-preference) {
                transform: rotateZ(-25deg);
            }
        }
    }

    button:hover &.dark {
        .sun-beams {
            transition-delay: .15s;
        }
    }

    button:hover &.light {
        .sun, .moon {
            transition-delay: .15s;
        }
    }
`