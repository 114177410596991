import React from 'react'
import Hero from './hero'
import Content from './content'

const AddCompetenceRecord = ({ dismiss, ...props }) => (
    <>
        <Hero dismiss={dismiss} />
        <Content {...props} />
    </>
)

export default AddCompetenceRecord
