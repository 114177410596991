import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcessContext } from 'pages/processes/utilities'
import { cls } from 'utilities/dom'
import { UserStats } from './s'
import { ModalHeader } from 'modals/generic'
import Person from 'components/person'
import Progress from 'components/progress'
import Message from 'components/message'
import Paragraph from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'

const UnarchiveProcess = ({ process, context = 'multiple', dismiss }) => {
    const { formatMessage } = useIntl()

    const { unarchiveProcess } = useProcessContext(context)

    const [loading, setLoading] = useState(false)
    const [isUnarchived, setIsUnarchived] = useState(false)

    const {
        type,
        user,
        stats = {}
    } = process

    const {
        completedTasks,
        totalTasks
    } = stats

    const unarchive = async () => {
        setLoading(true)
        const { ok } = await unarchiveProcess(process.id)
        setLoading(false)

        if(ok) {
            setIsUnarchived(true)
        }
    }

    const heading = formatMessage(getHeadingTranslation(type))
    const successMessage = formatMessage(getSuccessMessageTranslation(type))

    return (
        <>
            <ModalHeader
                heading={heading}
                dismiss={dismiss} />
            <UserStats>
                <Person
                    who={user}
                    truncate={true} />
                <Progress
                    completed={completedTasks}
                    total={totalTasks}
                    className="compact" />
            </UserStats>
            {!isUnarchived && (
                <>
                    <Paragraph>
                        <FormattedMessage
                            id="employment_lifecycle_message_unarchive_info"
                            defaultMessage="The process will be set back to active." />
                    </Paragraph>
                    <Actions className="compact">
                        <Plain
                            onClick={dismiss}
                            className="neutral">
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <Button
                            onClick={unarchive}
                            disabled={loading}
                            className={cls([
                                'neutral',
                                loading && 'loading'
                            ])}>
                            <FormattedMessage
                                id="action_unarchive"
                                defaultMessage="Unarchive" />
                        </Button>
                    </Actions>
                </>
            )}
            {isUnarchived && (
                <Message
                    type="success"
                    className="compact"
                    message={successMessage} />
            )}
        </>
    )
}

const getHeadingTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_action_unarchive',
        defaultMessage: 'Reactivate onboarding'
    },
    offboarding: {
        id: 'employee_offboarding_action_unarchive',
        defaultMessage: 'Reactivate offboarding'
    },
    process: {
        id: 'process_action_reactivate',
        defaultMessage: 'Reactivate process'
    }
})[type]

const getSuccessMessageTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_message_unarchive_success',
        defaultMessage: 'The onboarding has been reactivated.'
    },
    offboarding: {
        id: 'employee_offboarding_message_unarchive_success',
        defaultMessage: 'The offboarding has been reactivated.'
    },
    process: {
        id: 'process_message_reactivate_success',
        defaultMessage: 'The process has been reactivated.'
    }
})[type]

export default ({ process, ...props }) => {
    if(!process?.stats) {
        process.stats = {
            completedTasks: process.completedTaskCount,
            totalTasks: process.taskCount
        }
    }

    return (
        <UnarchiveProcess
            process={process}
            {...props} />
    )
}