import React from 'react'
import { usePerson } from 'contexts/person'
import { useData } from 'contexts/data'
import { size } from 'utilities/object'
import { Important } from './s'
import Invite from './invite'
import Jubilees from './jubilees'
import Absence from './absence'
import Process from './process'
import ProfileCompletion from 'components/profile-completion'
import LastDayOfWork from './last-day-of-work'

const AboutImportant = ({ access, lookingAtMyOwnProfile, setLoading, salt }) => {
    const { person } = usePerson()
    const { data } = useData()

    const { status } = person ?? {}
    const isDeactivated = status?.active === false

    const showOffboarding = !!access.offboarding.available && !access.offboarding.upgradable
    const onboardingOngoing = !!size(data?.onboarding?.stats) || false
    const offboardingStartable = data?.offboarding?.startable ?? false
    const offboardingOngoing = !!size(data?.offboarding?.stats) || false

    let showOnboarding = !!access.onboarding.available && !access.onboarding.upgradable
    if(showOffboarding && !onboardingOngoing && offboardingStartable) {
        showOnboarding = false
    }

    const showProcess = !(showOnboarding && onboardingOngoing) && !(showOffboarding && offboardingOngoing) &&
        !!access.processes.available && !access.processes.upgradable &&
        !!size(data?.process?.stats)

    return (
        <Important>
            {!isDeactivated && (
                <>
                    <Invite />
                    <Jubilees />
                    {(!!access.absence.available && !access.absence.upgradable) && <Absence salt={salt} />}
                    {!lookingAtMyOwnProfile && (
                        <>
                            {showOnboarding && (
                                <Process
                                    {...(data?.onboarding ?? null)}
                                    salt={salt} />
                            )}
                            {showOffboarding && (
                                <Process
                                    {...(data?.offboarding ?? null)}
                                    salt={salt} />
                            )}
                            {showProcess && (
                                <Process
                                    {...(data?.process ?? null)}
                                    salt={salt} />
                            )}
                        </>
                    )}
                    {!!person && (
                        <ProfileCompletion
                            person={person}
                            lookingAtProfile={true} />
                    )}
                </>
            )}
            <LastDayOfWork
                lookingAtMyOwnProfile={lookingAtMyOwnProfile}
                setLoading={setLoading}
                access={access}
                salt={salt} />
        </Important>
    )
}

export default AboutImportant