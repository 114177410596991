import React from 'react'
import { createPortal } from 'react-dom'
import { FormattedMessage } from 'react-intl'
import {
    SortableItem, Header, Heading,
    Summary, Symbol
} from './s'
import DragHandle from '../drag-handle'
import Topics from './topics'

const EditOrderContentCategory = ({
    category,
    provided, snapshot,
    salt, ...props
}) => {
    const portal = document.getElementById('sortable')

    const { isDragging = false } = snapshot ?? {}

    const {
        id,
        title,
        symbol,
        topics = [],
        enabled = true
    } = category

    const draftsCount = topics.filter(({ status }) => status === 'draft').length

    const child = (
        <SortableItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            $isDragging={isDragging}>
            <DragHandle />
            <Header>
                <Symbol
                    symbol={symbol}
                    className="hover-effect" />
                <Heading {...(!enabled ? { className: 'strike' } : null)}>
                    {title}
                </Heading>
                <Summary className="compact interpoint-divider">
                    <span>
                        <FormattedMessage
                            id="topic_count"
                            defaultMessage="{count, plural, =0 {0 topics} =1 {1 topic} other {{count} topics}}"
                            values={{ count: topics.length }} />
                    </span>
                    {!!draftsCount && (
                        <span>
                            <FormattedMessage
                                id="draft_count"
                                defaultMessage="{count, plural, =0 {0 drafts} =1 {1 draft} other {{count} drafts}}"
                                values={{ count: draftsCount }} />
                        </span>
                    )}
                </Summary>
            </Header>
            <Topics
                {...props}
                category={category}
                salt={`${salt}:${id}`} />
        </SortableItem>
    )

    if(!isDragging) {
        return child
    }

    return createPortal(child, portal)
}

export default EditOrderContentCategory
