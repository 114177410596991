import { useI18n } from 'contexts/i18n'

export const useFormattedNumber = ({ number, locale, options = {} }) => {
    const i18n = useI18n()
    locale = locale ?? i18n?.locale

    if((!number && number !== 0) || isNaN(number)) {
        return number
    }

    return Intl?.NumberFormat(locale, options)?.format(number) ?? number
}

export const parseHumanDecimals = number => {
    if((!number && number !== 0) || isNaN(number)) {
        return [number, false]
    }

    number = `${number}`

    if(!number.includes('.') && !number.includes(',')) {
        return [Number(number), true]
    }

    const parts = number
        .split(/([.,])/g)
        .filter(part => !['.', ','].includes(part))

    const [decimals, ...integer] = parts.reverse()

    return [
        Number(`${integer.reverse().join('')}.${decimals}`),
        true
    ]
}

export const extractNumber = string => {
    if(!string || typeof string !== 'string') {
        return null
    }

    return string.match(/\d+/g)?.join('')
}

export const getRandomInteger = (min = 0, max = 99) => {
    min = Math.ceil(min)
    max = Math.floor(max)

    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const invertSign = number => {
    if((!number && number !== 0) || isNaN(number)) {
        return null
    }

    if(number === 0) return 0

    return number * -1
}