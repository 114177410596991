import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { usePayment } from 'contexts/payment'
import { useSegment } from 'contexts/segment'
import Form from 'components/form/controller'

// Building blocks
import Title from './title'
import CurrentSubscriptionSummary from './current-subscription-summary'
import SelectedPlanInformation from './selected-plan-information'
import SeatPledge from './seat-pledge'
import Billing from './billing'
import AccountAddress from './account-address'
import Information from './information'
import DowngradePrompt from './downgrade-prompt'
import DowngradeSchedulingWarning from './downgrade-scheduling-warning'
import Message from 'components/message'
import Actions from './actions'

const ManageSubscription = ({ meta = {}, onDone, modal, salt }) => {
    const { formatMessage } = useIntl()

    const {
        account,
        currency,
        instantChallengeActive,
        instantChallengeFailed,

        updateBilling,
        updateSubscription,
        resetInstantChallenge
    } = usePayment()

    const segment = useSegment()

    useEffect(() => {
        return () => resetInstantChallenge()
    }, [])

    const {
        change,
        swap,
        expired,
        handleRecurringChallenge = false,
        renewPayment = false
    } = meta

    const purchase = change === 'purchase'
    const upgrade = change === 'upgrade'
    const modify = change === 'modify'
    const termChange = change === 'term'
    const downgrade = change === 'downgrade'
    const freeload = change === 'freeload'
    const cancel = change === 'cancel'

    const hasSubscription = !!account.subscription
    const currentSubscriptionTerm = account.subscription?.chargePlan?.term?.toLowerCase()

    const [term, setTerm] = useState(((upgrade || downgrade || swap) ?
        currentSubscriptionTerm :
        (meta.term ?? currentSubscriptionTerm)
    ) ?? 'annual')

    const [warning, setWarning] = useState(!expired && (downgrade || freeload))
    const [updating, setUpdating] = useState(false)

    const action = modify ?
        updateBilling :
        (swap || purchase || upgrade || downgrade || termChange || freeload || cancel) ?
            updateSubscription :
            null

    const update = async body => {
        setUpdating(true)

        const { ok } = await action({
            ...body,
            ...(!!meta.to?.code ? { code: meta.to.code } : null),
            ...(handleRecurringChallenge ? { handleRecurringChallenge } : null),
            ...(renewPayment ? { renewPayment } : null),
            term
        })

        setUpdating(false)

        if(ok) {
            if(purchase || upgrade || downgrade || freeload || cancel) {
                const changeDescriptor = cancel ?
                    'cancel' :
                    swap ?
                        'switch' :
                        (downgrade || freeload) ?
                            'downgrade' :
                            upgrade ?
                                'upgrade' :
                                'purchase'

                segment.track('Plan change', {
                    from: meta.from.name,
                    to: meta.to.name,
                    change: changeDescriptor
                })
            }

            onDone()
        }
    }

    const payingWithCard = account?.subscription?.collectionMethod === 'automatic'
    const payingWithInvoice = account?.subscription?.collectionMethod === 'manual'

    const showCurrent = swap || (!warning && hasSubscription && !purchase && !modify)
    const showSelected = swap || (!warning && (purchase || upgrade || downgrade || termChange || cancel || (modify && expired)))
    const showBilling = !warning && ((handleRecurringChallenge && renewPayment) || (purchase || modify || ((swap || upgrade || downgrade) && !payingWithCard && !payingWithInvoice)))
    const showDowngradePrompt = (!!warning && downgrade)

    let billingMode = 'edit'
    if(handleRecurringChallenge && !renewPayment) {
        billingMode = 'view'
    }

    return (
        <Form
            layout="vertical"
            onSubmit={update}>
            {({ errors, trigger }) => (
                <>
                    <Title
                        {...meta}
                        warning={warning}
                        handleRecurringChallenge={handleRecurringChallenge}
                        renewPayment={renewPayment}
                        payingWithCard={payingWithCard}
                        payingWithInvoice={payingWithInvoice}
                        dismiss={modal.dismiss} />
                    {showCurrent && (
                        <CurrentSubscriptionSummary
                            {...meta}
                            {...(termChange ? { showTerm: currentSubscriptionTerm } : null)}
                            salt={salt} />
                    )}
                    {showSelected && (
                        <SelectedPlanInformation
                            {...meta}
                            term={term}
                            setTerm={setTerm}
                            currency={currency}
                            salt={salt} />
                    )}
                    {!showDowngradePrompt && (
                        <SeatPledge
                            {...meta}
                            term={term}
                            salt={salt} />
                    )}
                    {showBilling && (
                        <Billing
                            subscription={account.subscription}
                            billing={account.billing}
                            mode={billingMode}
                            meta={meta}
                            instantChallengeFailed={instantChallengeFailed}
                            handleRecurringChallenge={handleRecurringChallenge}
                            dismiss={modal.dismiss}
                            salt={salt} />
                    )}
                    {!showDowngradePrompt && (
                        <AccountAddress
                            {...meta}
                            dismiss={modal.dismiss}
                            salt={salt} />
                    )}
                    <Information
                        {...meta}
                        warning={warning}
                        salt={salt} />
                    {showDowngradePrompt && (
                        <DowngradePrompt
                            {...meta}
                            salt={salt} />
                    )}
                    {(!instantChallengeActive && !handleRecurringChallenge) && (
                        <DowngradeSchedulingWarning
                            {...meta}
                            downgrade={downgrade}
                            cancel={cancel} />
                    )}
                    {(instantChallengeActive && !instantChallengeFailed) && (
                        <Message
                            type="info"
                            message={formatMessage({
                                id: 'payment_subscription_3dsecure_loading',
                                defaultMessage: 'A 3DSecure verification is required for this purchase to go through. We’re currently loading your bank’s interface, which might take a little while, so just sit tight!'
                            })} />
                    )}
                    {(handleRecurringChallenge && !instantChallengeActive) && (
                        <Message
                            type="info"
                            message={formatMessage({
                                id: 'payment_plan_warning_handle_recurring_challenge',
                                defaultMessage: 'Your bank will attempt to authenticate your payment information when you proceed. The bank’s interface might take a little while to load in.'
                            })} />
                    )}
                    <Actions
                        {...meta}
                        disabled={!action}
                        warning={warning}
                        handleRecurringChallenge={handleRecurringChallenge}
                        errors={errors}
                        trigger={trigger}
                        loading={updating || (instantChallengeActive && !instantChallengeFailed)}
                        dismiss={() => {
                            modal.dismiss()
                            resetInstantChallenge(instantChallengeActive)
                        }}
                        advance={() => setWarning(false)}
                        salt={salt} />
                </>
            )}
        </Form>
    )
}

export default ManageSubscription