import React, { useState, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTasks } from 'contexts/tasks'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { compact } from 'utilities/array'
import { pick, size } from 'utilities/object'
import { getDate } from 'utilities/date-time'
import { getProcessCaptionTranslation } from 'pages/processes/pages/process/modals/task/edit'
import Form from 'components/form/controller'
import { ModalHeader } from 'modals/generic'
import { Fields } from './s'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import Links from './links'
import Fieldset from 'components/form/fieldset'
import ShareField, { unoptionize } from 'components/form/field/share'
import PersonField from 'components/form/field/person'
import UpgradeCTA from 'components/upgrade-cta'
import TimeField from 'components/form/field/time'
import ProcessCaption from 'components/typography/paragraph'
import ConcernsUnit from 'pages/tasks/components/concerns-unit'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const EditTask = ({ task, group, person, onDone, modal, disappearsFromViewOverride, salt }) => {
    const { formatMessage } = useIntl()

    const {
        addTask,
        updateTask
    } = useTasks()

    const {
        me,
        isItMyOwnId
    } = useMe()

    const { checkFeature } = useAccess()
    const groupTasksAvailable = checkFeature('group-tasks')
    const groupTasksUpgradable = useUpgradable()({ feature: 'group-tasks' })

    const [constructing, setConstructing] = useState(false)

    const {
        id,
        title,
        description,
        links = [],
        assignedGroup,
        assignedTo,
        dueAt,
        parent
    } = task ?? {}

    const mode = task?.id ? 'update' : 'create'

    const getPrimaryAssignmentValue = () => {
        if(mode === 'create') {
            if(group) {
                return group
            }

            if(person) {
                return person
            }
        }

        if(assignedGroup) {
            return assignedGroup
        }

        if(assignedTo) {
            return { ...assignedTo, type: 'user' }
        }

        if(mode === 'create') {
            return { ...me, type: 'user' }
        }

        return null
    }

    const [primaryAssignment, setPrimaryAssignment] = useState({
        value: getPrimaryAssignmentValue(),
        changed: false
    })

    const [secondaryAssignment, setSecondaryAssignment] = useState({
        value: assignedGroup ? assignedTo : null,
        changed: false
    })

    const [taskLinks, setTaskLinks] = useState({
        value: links,
        changed: false
    })

    const include = (mode === 'update') ?
        'touched' :
        'always'

    const primaryAssignmentIsGroup = ['team', 'location'].includes(primaryAssignment.value?.type)

    const addOrUpdate = async (body, { reset }) => {
        setConstructing(true)

        delete body.primaryAssignment
        delete body.secondaryAssignment

        if(
            mode === 'create' ||
            primaryAssignment.changed ||
            secondaryAssignment.changed
        ) {
            if(groupTasksAvailable) {
                const field = primaryAssignmentIsGroup ?
                    'assignedGroup' :
                    'assignedTo'

                if(primaryAssignment.changed || (mode === 'create')) {
                    body[field] = primaryAssignment.value?.id ?? null
                }

                if(secondaryAssignment.changed || (mode === 'create')) {
                    if(primaryAssignmentIsGroup) {
                        body.assignedTo = secondaryAssignment.value?.id ?? null
                    } else {
                        body.assignedGroup = null
                    }
                }
            } else if(primaryAssignment.changed || (mode === 'create')) {
                body.assignedGroup = null
                body.assignedTo = primaryAssignment.value?.id ?? null
            }
        }

        body.links = taskLinks.value

        const addOrUpdateTask = (mode === 'update') ?
            updateTask :
            addTask

        const disappearsFromViewDefault = task => {
            if(group) {
                return !task.assignedGroup || task.assignedGroup.id !== group.id
            }

            if(person) {
                return !task.assignedTo || task.assignedTo.id !== person.id
            }

            if(!task.assignedGroup && !task.assignedTo) {
                return false
            }

            const assignedToOneOfMyGroups = task.assignedGroup?.members.map(({ id }) => id).includes(me.id)
            const assignedDirectlyToMe = task.assignedTo && isItMyOwnId(task.assignedTo.id)

            if(task.assignedGroup) {
                // A task assigned to a group is considered reassigned if
                // – It’s assigned to a specific person other than myself
                // – It’s assigned to a group I’m not a member of
                return task.assignedTo ?
                    !assignedDirectlyToMe :
                    !assignedToOneOfMyGroups
            }

            return !assignedDirectlyToMe
        }

        const disappearsFromView = disappearsFromViewOverride ?? disappearsFromViewDefault

        const { ok, response: task } = await addOrUpdateTask({
            body: {
                ...body,
                ...((mode === 'create') ? { availableAt: getDate() } : null)
            },
            id,
            disappearsFromView
        })

        setConstructing(false)

        if(ok && task) {
            reset()
            onDone?.(disappearsFromView(task) ? pick(task, 'assignedGroup', 'assignedTo') : null)
        }
    }

    const heading = formatMessage({
        id: (mode === 'update') ?
            'task_action_update' :
            'task_action_add',
        defaultMessage: (mode === 'update') ?
            'Update task' :
            'Add task'
    })

    const showLimited = !groupTasksAvailable && !groupTasksUpgradable
    const showPossibilities = groupTasksAvailable || groupTasksUpgradable

    const assignLabel = formatMessage({
        id: 'task_label_assign',
        defaultMessage: 'Assign'
    })

    const [ShareFieldset, shareFieldsetProps] = showPossibilities ?
        [Fieldset, {
            label: {
                children: assignLabel
            }
        }] : [Fragment, null]

    const shareFieldLabel = showLimited ?
        assignLabel :
        formatMessage({
            id: showPossibilities ?
                'organization_unit_person_or_team_or_location' :
                'organization_unit_person',
            defaultMessage: showPossibilities ?
                'A person, team or location…' :
                'A person…'
        })

    return (
        <Form
            layout="vertical"
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <ModalHeader
                        heading={heading}
                        dismiss={modal.dismiss} />
                    {!!parent && (
                        <ProcessCaption className="caption">
                            <FormattedMessage
                                {...getParentCaptionTranslation(parent, isItMyOwnId)}
                                values={{
                                    user: <ConcernsUnit concerns={parent.concerns} />,
                                    title: parent?.title
                                }} />
                        </ProcessCaption>
                    )}
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_title',
                                defaultMessage: 'Title'
                            })}
                            name="title"
                            field={{
                                value: title,
                                required: true,
                                include
                            }}
                            controlProps={{
                                autoFocus: true,
                                maxLength: 255
                            }} />
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{
                                value: description,
                                include
                            }}
                            controlProps={{ maxLength: 2040 }} />
                        <Links
                            taskLinks={taskLinks}
                            setTaskLinks={setTaskLinks}
                            salt={salt} />
                        <ShareFieldset {...shareFieldsetProps}>
                            <ShareField
                                salt={salt}
                                label={shareFieldLabel}
                                name="primaryAssignment"
                                field={{
                                    value: compact([primaryAssignment.value]),
                                    required: true,
                                    include
                                }}
                                picker={{
                                    types: [
                                        'user',
                                        ...(groupTasksAvailable ? ['team', 'location'] : [])
                                    ],
                                    single: true
                                }}
                                onChange={({ primaryAssignment }) => {
                                    primaryAssignment = !!primaryAssignment?.[0] ?
                                        unoptionize(primaryAssignment[0]) :
                                        null

                                    if(!primaryAssignment && secondaryAssignment.value) {
                                        primaryAssignment = { ...secondaryAssignment.value, type: 'user' }
                                    }

                                    setPrimaryAssignment({
                                        value: primaryAssignment,
                                        changed: true
                                    })

                                    setSecondaryAssignment({
                                        value: null,
                                        changed: true
                                    })
                                }}
                                key={`${salt}:${modal?.showing}:primaryAssignment:${primaryAssignment.value?.id ?? 'empty'}`} />
                            {(groupTasksAvailable && primaryAssignmentIsGroup) && (
                                <PersonField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'group_person_action_pick',
                                        defaultMessage: 'Pick a person in {name}'
                                    }, primaryAssignment.value)}
                                    name="secondaryAssignment"
                                    field={{
                                        value: secondaryAssignment.value,
                                        include
                                    }}
                                    picker={{
                                        mode: 'group',
                                        ...pick(primaryAssignment.value, 'type', 'id')
                                    }}
                                    onChange={({ secondaryAssignment }) => {
                                        if(!groupTasksAvailable && !secondaryAssignment) {
                                            setPrimaryAssignment({
                                                value: null,
                                                changed: true
                                            })
                                        }

                                        setSecondaryAssignment({
                                            value: secondaryAssignment,
                                            changed: true
                                        })
                                    }}
                                    key={`${salt}:secondaryAssignment:${secondaryAssignment.value?.id ?? 'empty'}`} />
                            )}
                            {groupTasksUpgradable && (
                                <UpgradeCTA
                                    feature="group-tasks"
                                    useUpgradeIcon
                                    useFeatureOrModuleIcon
                                    useFeatureTitle
                                    useFeatureDescription
                                    className="compact"
                                    salt="group-tasks" />
                            )}
                        </ShareFieldset>
                        <TimeField
                            salt={salt}
                            label={formatMessage({
                                id: 'task_action_deadline_assign',
                                defaultMessage: 'The task should be completed'
                            })}
                            name="dueAt"
                            field={{
                                value: dueAt,
                                include
                            }}
                            picker={{ past: false }} />
                    </Fields>
                    <Actions className="compact">
                        <Plain
                            onClick={modal.dismiss}
                            className="neutral"
                            disabled={constructing}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={(
                                !touched.length &&
                                !primaryAssignment.changed &&
                                !secondaryAssignment.changed &&
                                !taskLinks.changed
                            ) || (
                                !parent &&
                                !primaryAssignment.value
                            ) || !!size(errors) || constructing}
                            ref={trigger}>
                            {heading}
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

const supportedParentTypes = [
    'onboarding',
    'offboarding',
    'process',
    'meeting'
]

export const getParentCaptionTranslation = (parent, isItMyOwnId) => {
    if(!parent || !supportedParentTypes.includes(parent.type)) {
        return null
    }

    const concerningMe = isItMyOwnId(parent.concerns?.id)

    if(parent.type === 'meeting') {
        return {
            id: concerningMe ?
                'task_meeting_regarding_me_title' :
                'task_meeting_regarding_user_title',
            defaultMessage: concerningMe ?
                'Regarding your meeting' :
                `Regarding {user} · ${parent?.title ? '{title}' : 'Meeting'}`
        }
    }

    return getProcessCaptionTranslation(parent.type, concerningMe)
}

export default EditTask