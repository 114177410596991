import React from 'react'
import { Meta } from './s'

const ViewEntryMeta = entry => {
    let {
        registeredBy,
        registeredAt,
        status,
        reviewedBy,
        reviewedAt,
        integrationName,
    } = entry

    if(!registeredBy && !registeredAt) {
        return null
    }

    return (
        <>
            {(!!reviewedBy && ['approved', 'rejected'].includes(status)) && (
                <Meta
                    type={status}
                    by={reviewedBy}
                    at={reviewedAt}
                    time="fixed" />
            )}
            <Meta
                type={status !== 'canceled' ? 'registered' : 'canceled'}
                by={integrationName ?? registeredBy}
                at={registeredAt}
                time="fixed" />
        </>
    )
}

export default ViewEntryMeta
