import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import {
    Wrapper as WrapperBase,
    Item as ItemBase
} from 'components/statistics-group'

export const Wrapper = styled(WrapperBase)`
    margin-bottom: 40px;

    ${belowTablet`
        margin-top: 16px;
        margin-bottom: 24px;
    `}
`

export const Item = ItemBase