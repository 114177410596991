import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { usePublicOption } from 'hooks/public-option'
import { Container, DragDropWrapper, Sortables } from './s'
import Category from './category'

const EditOrderContent = ({ order, setOrder, updating, salt }) => {
    const publicOption = usePublicOption(true)
    if (!publicOption) {
        return null
    }

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed)

        return list
    }

    const onDragEnd = result => {
        const { source, destination, type } = result

        const sourceIndex = source.index

        if(!destination) {
            return
        }

        const destinationIndex = destination.index

        if (type === 'category') {
            const newCategories = reorder(
                order.categories,
                sourceIndex,
                destinationIndex
            )

            setOrder(order => ({
                ...order,
                categories: newCategories,
                changed: true
            }))
        } else if (type === 'topic') {
            const categoryTopicMap = order.categories.reduce((accumulator, category) => {
                accumulator[`category-${category.id}`] = category.topics

                return accumulator
            }, {})

            const sourceParentId = source.droppableId
            const destinationParentId = destination.droppableId

            const sourceTopics = categoryTopicMap[sourceParentId]
            const destinationTopics = categoryTopicMap[destinationParentId]

            let newCategories = [...order.categories]

            if (sourceParentId === destinationParentId) {
                // Topics are being moved within the same category
                const reorderedTopics = reorder(
                    sourceTopics,
                    sourceIndex,
                    destinationIndex
                )

                newCategories = newCategories.map(category => {
                    if (`category-${category.id}` === sourceParentId) {
                        category.topics = reorderedTopics
                    }

                    return category
                })
            } else {
                // Topics are being moved between categories
                let newSourceTopics = [...sourceTopics]
                const [removed] = newSourceTopics.splice(sourceIndex, 1)

                let newDestinationTopics = [...destinationTopics]
                newDestinationTopics.splice(destinationIndex, 0, removed)

                newCategories = newCategories.map(category => {
                    if (`category-${category.id}` === sourceParentId) {
                        category.topics = newSourceTopics
                    } else if (`category-${category.id}` === destinationParentId) {
                        category.topics = newDestinationTopics
                    }

                    return category
                })
            }

            setOrder(order => ({
                ...order,
                categories: newCategories,
                changed: true
            }))
        }
    }

    if(!order?.categories?.length) {
        return null
    }

    return (
        <Container>
            <DragDropWrapper>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="droppable"
                        type="category">
                        {provided => (
                            <Sortables
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {order.categories.map((category, index) => (
                                    <Draggable
                                        key={category.id}
                                        draggableId={`category-${category.id}`}
                                        isDragDisabled={updating}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div>
                                                <Category
                                                    category={category}
                                                    order={order}
                                                    setOrder={setOrder}
                                                    provided={provided}
                                                    snapshot={snapshot}
                                                    publicOption={publicOption}
                                                    index={index}
                                                    salt={salt}
                                                    key={`${salt}:${category.id}`} />
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Sortables>
                        )}
                    </Droppable>
                </DragDropContext>
            </DragDropWrapper>
        </Container>
    )
}

export default EditOrderContent
