import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { Item, Details, Country, Obscured } from './s'
import { Label } from '../../s'
import { FlexChildShrink } from 'components/flex'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ContextMenu from 'widgets/context-menu'

const Identification = ({ identification, editable, actions, salt }) => {
    const { territories } = useI18n()

    let {
        type,
        value,
        country
    } = identification

    const flag = country ? getUnicodeFlagIcon(country) : null

    if(country in territories) {
        country = territories[country]
    }

    const obscured = (
        <>
            <div>{value}</div>
            <Country>{flag} {country}</Country>
        </>
    )

    return (
        <Item>
            <Label className="horizontal">
                <FormattedMessage {...typeToTranslation(type)} />
            </Label>
            <Details>
                <Obscured text={obscured} />
            </Details>
            {!!editable && (
                <FlexChildShrink>
                    <ContextMenu
                        salt={salt}
                        context={identification}
                        actions={actions} />
                </FlexChildShrink>
            )}
        </Item>
    )
}

export const typeToTranslation = type => ({
    national: {
        id: 'person_label_identifications_national',
        defaultMessage: 'National identification number'
    },
    passport: {
        id: 'person_label_identifications_passport',
        defaultMessage: 'Passport number'
    }
})[type]

export default Identification