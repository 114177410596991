import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { categoryLevelMap } from 'pages/competence/constants/category'
import { Wrapper, Value, Description, Number } from './s'

export const useLevelValueRenderer = () => {
    const { formatMessage } = useIntl()

    return ({ category, value, valueDescription }) => {
        const hasValue = !!value || value === 0

        const subTitleId = categoryLevelMap[category][value]?.subTitle?.id
        const subTitle = subTitleId ? formatMessage({ id: subTitleId }) : null
        const hasSubTitle = subTitleId && subTitle !== subTitleId

        return (
            <Wrapper {...(!hasValue ? { className: 'empty' } : null)}>
                <Value className="in-grid-area">
                    {hasValue && (
                        <>
                            <strong>
                                <FormattedMessage {...categoryLevelMap[category][value].title} />
                            </strong>
                            {hasSubTitle && <span>({subTitle})</span>}
                        </>
                    )}
                    {!hasValue && (
                        <FormattedMessage
                            id="empty_not_picked"
                            defaultMessage="Not picked" />
                    )}
                </Value>
                {valueDescription && <Description className="compact">{valueDescription}</Description>}
                {hasValue && <Number>{value}</Number>}
            </Wrapper>
        )
    }
}