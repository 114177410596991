import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import Link from 'components/link'
import FavIconBase from 'components/favicon'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import { ExternalLink as ExternalLinkBase } from 'styled-icons/feather'

export const Wrapper = styled(Link)`
    display: grid;
    grid-template-rows: 24px 20px;
    grid-template-columns: 40px 1fr auto;
    align-items: center;

    padding: 8px 16px;

    transition: background-color 0.2s ease;

    &:where(:hover, :focus) {
        background-color: var(--huma-color-surface-constructive-minimal);

        text-decoration: none;
    }
`

export const FavIcon = styled(FavIconBase)`
    grid-row: 1 / 3;
`

export const Name = styled(Paragraph)`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
`

export const Url = styled(CaptionSmall)`
    grid-row: 2 / 3;
    grid-column: 2 / 3;
`

export const ExternalLink = styled(ExternalLinkBase)`
    grid-row: 1 / 3;
    grid-column: 3 / 4;

    ${svgStroke()}
`