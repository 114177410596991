import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useCompetenceProfiles } from 'contexts/competence-profiles'
import { refreshCompetenceProfile } from 'pages/competence/utilities'
import { useDebounce } from 'hooks/debounce'
import { Wrapper, ContentRow } from './s'
import Search from './search'
import Profiles from './profiles'
import { Scrollable as Modal } from 'modals/generic'
import ActingProfile from 'pages/competence/modals/profile'
import { DashedCircle } from 'components/feather'
import {
    Eye, EyeOff,
    Edit,
    Trash2 as Delete
} from 'styled-icons/feather'

const ProfilesList = ({ setTypes, onDone, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const {
        profiles = [],
        hasFetched,
        setProfilesFilter,
        togglePrivacy,
        removeProfile
    } = useCompetenceProfiles()

    const [acting, setActing] = useState(null)
    const [initialFetchResults, setInitialFetchResults] = useState({
        profiles: [],
        hasFetched: false
    })

    const [searchingDebounce, setSearchingDebounce] = useDebounce(false, 200)

    useEffect(() => {
        if(hasFetched) {
            setSearchingDebounce(false)

            if(!initialFetchResults.hasFetched) {
                setInitialFetchResults({ profiles, hasFetched })
            }
        }
    }, [hasFetched])

    const viewAction = profile => ({
        salt: `${salt}:view`,
        icon: <DashedCircle size={24} />,
        label: formatMessage({
            id: 'action_view_details',
            defaultMessage: 'View details'
        }),
        effect: 'neutral',
        onClick: () => setActing({
            mode: 'view',
            profile
        })
    })

    const togglePrivacyAction = profile => {
        if(!manageAccess) {
            return null
        }

        return {
            salt: `${salt}:toggle_privacy`,
            icon: profile.public ?
                <EyeOff size={24} /> :
                <Eye size={24} />,
            label: formatMessage({
                id: profile.public ?
                    'action_privacy_hide' :
                    'action_privacy_show',
                defaultMessage: profile.public ?
                    'Make private' :
                    'Make public'
            }),
            effect: 'neutral',
            onClick: async () => {
                await togglePrivacy(profile)
                refreshCompetenceProfile(profile.id)
            }
        }
    }

    const editAction = profile => {
        if(!manageAccess) {
            return null
        }

        return {
            salt: `${salt}:edit`,
            icon: <Edit size={24} />,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                mode: 'edit',
                profile
            })
        }
    }

    const removeAction = ({ id }) => {
        if(!manageAccess) {
            return null
        }

        return {
            salt: `${salt}:delete`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: () => removeProfile(id),
            prompt: {
                question: formatMessage({
                    id: 'competence_profiles_action_delete_prompt_question',
                    defaultMessage: 'Confirm deleting competence profile'
                }),
                message: formatMessage({
                    id: 'competence_profiles_action_delete_prompt_message',
                    defaultMessage: 'This competence profile will be deleted. This can not be undone.'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        }
    }

    const actions = {
        view: viewAction,
        togglePrivacy: togglePrivacyAction,
        edit: editAction,
        remove: removeAction
    }

    return (
        <>
            {initialFetchResults.hasFetched && !!initialFetchResults.profiles.length && (
                <Search
                    setSearch={search => {
                        setSearchingDebounce(true)
                        setProfilesFilter({ search })
                    }}
                    salt={salt} />
            )}
            <Wrapper {...props}>
                <ContentRow>
                    <Profiles
                        setTypes={setTypes}
                        setActing={setActing}
                        searchingDebounce={searchingDebounce}
                        onDone={onDone}
                        salt={salt} />
                </ContentRow>
            </Wrapper>
            <Modal
                show={!!acting}
                dismiss={() => setActing(null)}>
                <ActingProfile
                    {...acting}
                    actions={actions}
                    dismiss={() => setActing(null)}
                    onDone={() => {
                        setProfilesFilter({ search: '' })
                        setActing(null)
                    }} />
            </Modal>
        </>
    )
}

export default ProfilesList