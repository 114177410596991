import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { TriggerBlobs } from 'components/entities'
import { Grid } from 'components/grid'

export const Entities = styled(TriggerBlobs)`
    flex-wrap: wrap;
    min-height: inherit;

    &:empty {
        display: none;
    }

    ${belowTablet`
        max-width: calc(100vw - 48px);
    `}
`

export const DisplayColumns = styled(Grid)`
    grid-template-columns: 1fr;
    justify-content: stretch;
    align-items: top;

    min-height: 40px;

    &.replacable {
        grid-template-columns: minmax(0, 1fr) min-content;
        column-gap: 16px;
    }
`