import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
    ResponsiveContainer,
    RadarChart, Radar, PolarGrid, PolarRadiusAxis
} from 'recharts'
import { Wrapper, Heading } from './s'
import Divider from 'components/divider'
import Message from 'components/message'

const GraphPreview = ({ types = [] }) => {
    const { formatMessage } = useIntl()

    if(!types?.length) {
        return null
    }

    types = types
        .filter(type => !!type?.id)
        .map(({ id, target }) => ({
            subject: id,
            target: !target ?
                0 :
                target === true ? 5 : target
        }))
        .sort((a, b) => a.subject.localeCompare(b.subject))

    return (
        <>
            <Divider />
            <Wrapper>
                <Heading>
                    <FormattedMessage
                        id="competence_gap_heading_graph_preview"
                        defaultMessage="Graph preview" />
                </Heading>
                <Message
                    type="info"
                    className="compact"
                    message={formatMessage({
                        id: 'competence_gap_message_recommendation',
                        defaultMessage: 'To get a clearer gap analysis, we recommend to pick at least 3 competence types. Below you can see a preview of the radar chart, with the target values you have defined.'
                    })} />
                <ResponsiveContainer
                    width="100%"
                    height={200}>
                    <RadarChart data={types}>
                        <PolarRadiusAxis
                            domain={[0, 5]}
                            axisLine={false}
                            tick={false}
                            tickCount={0}
                            tickLine={false} />
                        <PolarGrid
                            gridType="circle"
                            polarRadius={[0, 15, 30, 45, 60, 75]} />
                        <Radar
                            name={formatMessage({
                                id: 'competence_gap_label_target',
                                defaultMessage: 'Target'
                            })}
                            dataKey="target"
                            stroke="var(--huma-color-border-bold)"
                            strokeDasharray="3 3"
                            strokeWidth={2}
                            fill="transparent" />
                    </RadarChart>
                </ResponsiveContainer>
            </Wrapper>
        </>
    )
}

export default GraphPreview
