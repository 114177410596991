import React, { Component, createContext, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useOrganization } from 'contexts/organization'
import { get, post, patch } from 'api'
import debounce from 'lodash.debounce'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {
    format, eachDayOfInterval,
    startOfWeek, endOfWeek,
    isSunday, isSameDay, addDays
} from 'date-fns'
import { weekDaysToNumbersMap } from 'utilities/date-time'
import { first, last, prune } from 'utilities/array'

export const WorkSchedulesContext = createContext()

class WorkSchedulesProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()
        this.fetchHolidaysController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.state = {
            schedules: [],
            holidays: {},

            fetchSchedules: this.fetch,
            addSchedule: this.add,
            updateSchedule: this.update,
            setDefaultSchedule: this.setDefault,
            removeSchedule: this.remove,

            fetchHolidays: this.fetchHolidays,

            error: null,
            resetError: () => this.setError(null),

            hasFetched: false,
            fetching: false,
            fetchingHolidays: false
        }
    }

    componentDidMount() {
        if(this.props?.statuses?.hasSetUpWorkSchedule) {
            const {
                fetchAccess = true,
                fetchOnMount = true
            } = this.props

            if(fetchAccess && fetchOnMount) {
                this.fetchDebounced()
            }
        }
    }

    componentDidUpdate(props) {
        if(this.props?.statuses?.hasSetUpWorkSchedule) {
            const { fetchAccess: previousFetchAccess = true } = props

            const {
                fetchAccess = true,
                fetchOnMount = true
            } = this.props

            if(!previousFetchAccess && fetchAccess && fetchOnMount) {
                this.fetchDebounced()
            }
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.fetchHolidaysController.abort()
    }

    fetch = async () => {
        if(this.state.fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { ok: holidayTerritoriesOk, response: holidayTerritories } = await this.props.i18n.fetchHolidayTerritories()

        const [
            { ok: schedulesOk, response: schedules },
            { ok: myScheduleOk, response: mySchedule }
        ] = await Promise.all([
            get({
                path: '/work-schedules',
                signal: this.fetchController.signal
            }),
            get({
                path: '/users/me/work-schedule',
                signal: this.fetchController.signal
            })
        ])

        const ok = holidayTerritoriesOk && schedulesOk

        if(ok && schedules && holidayTerritories) {
            this.setState({
                schedules: this.sortSchedules(
                    this.enrichSchedules(schedules, myScheduleOk && mySchedule)
                ),
                hasFetched: true,
                fetching: false
            })
        } else {
            this.setState({
                hasFetched: true,
                fetching: false
            })
        }

        return { ok: schedulesOk, response: schedules }
    }

    add = async body => {
        const { ok, response } = await post({
            path: '/work-schedules',
            body
        })

        if(response) {
            if(ok) {
                this.setState(({ schedules }) => ({
                    schedules: this.sortSchedules([
                        ...schedules.map(schedule => ({
                            ...schedule,
                            default: body.default ? false : schedule.default
                        })),
                        ...this.enrichSchedules([response])
                    ])
                }))
            } else {
                this.setError(response)
            }
        }

        return { ok, response }
    }

    update = async (body, id) => {
        let ok = false
        let schedule = null

        const { ok: updateOk, response } = await patch({
            path: `/work-schedules/${id}`,
            body
        })

        if(updateOk) {
            const {
                ok: fetchOk,
                response: schedules
            } = await this.fetch()

            if(fetchOk && schedules) {
                ok = true
                schedule = schedules.find(schedule => schedule.id === id)

                this.setState(({ schedules }) => ({
                    schedules: this.sortSchedules(schedules.map(schedule => ({
                        ...schedule,
                        default: (schedule.id === id && body.default) ? body.default : schedule.default
                    })))
                }))
            }
        }

        if(!ok) {
            this.setError(response)
        }

        return { ok, response: schedule }
    }

    setDefault = async id => await this.update({ default: true }, id)

    remove = async id => {
        const { ok } = await post({
            path: `/work-schedules/${id}/archive`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ schedules }) => ({
                schedules: schedules.filter(schedule => schedule.id !== id)
            }))
        }

        return { ok }
    }

    fetchHolidays = async (countryCodes, years = []) => {
        countryCodes = prune(countryCodes)
        years = prune(years)

        if(years.length > 1) {
            const sortedYears = years.sort()

            years = new Array(sortedYears[sortedYears.length - 1] - sortedYears[0] + 1)
                .fill()
                .map((_, index) => sortedYears[0] + index)
        }

        const status = countryCodes.reduce((accumulator, countryCode) => ({
            ...accumulator,
            ...years.reduce((accumulator, year) => ({
                ...accumulator,
                [`${countryCode}:${year}`]: !!this.state.holidays[countryCode]?.[year]
            }), {})
        }), {})

        if(Object.values(status).every(Boolean)) {
            return
        }

        if(this.state.fetchingHolidays) {
            this.fetchHolidaysController.abort()
            this.fetchHolidaysController = new AbortController()
        }

        this.setState({ fetchingHolidays: true })

        const countriesAndYearsToFetch = Object.entries(status)
            .filter(([, cached]) => !cached)
            .map(([countryCodeAndYear]) => {
                const [countryCode, year] = countryCodeAndYear.split(':')
                return { countryCode, year }
            })

        const results = await Promise.all(countriesAndYearsToFetch.map(({ countryCode, year }) => get({
            path: `/holidays/${countryCode.toLowerCase()}`,
            params: {
                fromDate: `${year}-01-01`,
                toDate: `${year}-12-31`
            },
            signal: this.fetchHolidaysController.signal
        })))

        this.setState(({ holidays }) => ({
            holidays: {
                ...holidays,
                ...countriesAndYearsToFetch.reduce((accumulator, { countryCode, year }, index) => ({
                    ...accumulator,
                    [countryCode]: {
                        ...(holidays[countryCode] ?? null), // Pre-existing holidays
                        ...(accumulator?.[countryCode] ?? null), // Newly fetched holidays for other newly fetched years
                        [year]: results[index].ok ? results[index].response : null
                    }
                }), {})
            },
            fetchingHolidays: false
        }))
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                schedules,
                errorCode,
                errorMessage
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replaceAll('-', '_'),
                errorMessage,
                schedules
            }
        }

        this.setState({ error })
    }

    sortSchedules = schedules => schedules
        .sort(({ default: one = false }, { default: two = false }) => two - one)

    enrichSchedules = (schedules, mySchedule) => {
        const {
            dateLocale: locale,
            territories,
            holidayTerritories
        } = this.props.i18n

        const { formatMessage } = this.props.intl

        const enrichedSchedules = schedules.map(schedule => {
            const {
                countryCode,
                weekWorkDays
            } = schedule

            let territory = null

            if(!!countryCode) {
                const regionCountry = !territories?.[countryCode] ?
                    holidayTerritories.find(({ regions = [] }) => regions.find(({ code }) => code === countryCode))
                    : null

                territory = {
                    type: !regionCountry ? 'country' : 'region',
                    id: countryCode,
                    name: territories?.[countryCode] ??
                        holidayTerritories
                            .filter(({ regions = [] }) => !!regions.length)
                            .map(({ regions }) => regions)
                            .flat()
                            .find(({ code }) => code === countryCode)?.name,
                    emoji: getUnicodeFlagIcon(countryCode ?? regionCountry?.code) ?? '🌐',
                    ...(regionCountry ? { country: regionCountry } : null)
                }
            }

            const now = new Date()
            let workdays = eachDayOfInterval({
                start: startOfWeek(now, { locale }),
                end: endOfWeek(now, { locale })
            })

            const weekStartsOnSunday = isSunday(workdays[0])

            workdays = workdays.map((day, index) => ({
                day,
                workdayIndex: weekStartsOnSunday
                    ? index
                    : (index === 6 ? 0 : index + 1)
            }))

            const weekDays = weekWorkDays
                .map(weekDay => weekDaysToNumbersMap[weekDay])
                .sort((one, two) => one - two)

            const workdayIndices = (!weekStartsOnSunday && weekDays.includes(0)) ?
                [...weekDays.filter(day => day !== 0), 0] :
                weekDays

            const workdayGroups = workdayIndices.reduce((accumulator, workdayIndex, index) => {
                const workday = workdays.find(({ workdayIndex: dayIndex }) => dayIndex === workdayIndex)

                if(index === 0) {
                    return [[workday]]
                }

                const previousWorkday = workdays.find(({ workdayIndex: dayIndex }) => dayIndex === workdayIndices[index - 1])

                if(isSameDay(addDays(previousWorkday.day, 1), workday.day)) {
                    const lastGroup = accumulator[accumulator.length - 1]

                    return [
                        ...accumulator.slice(0, -1),
                        [...lastGroup, workday]
                    ]
                }

                return [
                    ...accumulator,
                    [workday]
                ]
            }, [])

            const workdayGroupNames = workdayGroups.map(group => {
                const { day: firstDay } = first(group)
                const { day: lastDay } = last(group)

                if(group.length === 1) {
                    return format(firstDay, 'EEEEEE', { locale })
                }

                return formatMessage({
                    id: 'range',
                    defaultMessage: '{from} – {to}'
                }, {
                    from: format(firstDay, 'EEEEEE', { locale }),
                    to: format(lastDay, 'EEEEEE', { locale })
                })
            })

            const workdayNamesFormatted = new Intl.ListFormat(locale, {
                type: 'unit',
                style: 'short'
            }).format(workdayGroupNames)

            const current = !!mySchedule?.countryCode && !!schedule?.countryCode && mySchedule?.countryCode === schedule?.countryCode

            return {
                ...schedule,
                territory,
                workdayNamesFormatted,
                ...(current ? { current: true } : null)
            }
        })

        return enrichedSchedules
    }

    render() {
        const { children = null } = this.props

        return (
            <WorkSchedulesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </WorkSchedulesContext.Provider>
        )
    }
}

export const useWorkSchedules = () => useContext(WorkSchedulesContext)

export default props => {
    const intl = useIntl()
    const i18n = useI18n()
    const { statuses } = useOrganization()

    return (
        <WorkSchedulesProvider
            {...props}
            intl={intl}
            i18n={i18n}
            statuses={statuses} />
    )
}