import { useEffect } from 'react'

// Use this hook in components where it is important to namespace behavior
export const useSalty = salt => {
    const dev = process.env.NODE_ENV === 'development'

    useEffect(() => {
        if(dev) {
            if(!salt) {
                console.warn('Salt is not defined. This can lead to unpredictable behavior and/or side effects, like shared memory between components.')
            }

            if(typeof salt === 'string' && salt.includes('undefined')) {
                console.warn(`Parts of the salt (‘${salt}’) is undefined. This can lead to unpredictable behavior and/or side effects, like shared memory between components.`)
            }
        }
    }, [salt, dev])
}