import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Search } from 'styled-icons/feather'
import { Count as CountBase } from 'components/filter/advanced/s'
import StringField from 'components/form/field/string'

export const FilterWrapper = styled(Grid)`
    grid-template-columns: minmax(0, 360px) min-content;
    align-items: center;
    column-gap: 16px;

    margin-block-end: 16px;
`

export const SearchWrapper = styled(Grid)`
    grid-template-columns: 40px 1fr;
    align-items: center;
    column-gap: 16px;

    > * {
        grid-row: 1;
    }
`

export const SearchIcon = styled(Search)`
    pointer-events: none;

    grid-column: 1 / 2;
    justify-self: center;

    ${svgStroke()}
`

export const Count = styled(CountBase)`
    position: absolute;
    top: 50%;
    right: 10px;

    margin: 0;

    transform: translateY(-50%);
`

export const SearchField = styled(StringField)`
    grid-column: 1 / -1;

    input {
        padding-inline-start: 40px;
    }
`

export const Expandable = styled(Grid)`
    grid-template-rows: 0fr;
    transition: grid-template-rows .2s ease-in;

    &.expanded {
        grid-template-rows: 1fr;
    }
`

export const ExpandableLayout = styled(Grid)`
    overflow-x: auto;
    row-gap: 16px;

    border: 1px solid transparent;
    border-radius: 8px;
    padding: 0 16px;

    transition: all .2s ease-in;

    ${Expandable}.expanded & {
        margin-block-end: 16px;
        border: 1px solid var(--huma-color-border-default);
        padding: 16px;
    }
`

export const FilterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`