import React, { useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import JsonData from 'form-serialize'
import { useOrganization } from 'contexts/organization'
import { useAccess } from 'contexts/access'
import { compact } from 'utilities/array'
import { local } from 'utilities/storage'
import { sendAppSignal, useAppSignal } from 'hooks/app-signal'
import { Scrollable as Modal } from 'modals/generic'
import { Form, DragDropWrapper } from './s'
import { ModalHeader } from 'modals/generic'
import Paragraph from 'components/typography/paragraph'
import { H4 } from 'components/typography/heading'
import { Plain, Button } from 'components/button'
import { UnorderedList } from 'components/list'
import Actions from 'components/form/actions'
import Section, { HiddenSection } from './section'

export const salt = 'dashboard:configure'

const ConfigureDashboard = ({ access: defaultSections }) => {
    const { formatMessage } = useIntl()

    const form = useRef()

    const [acting, setActing] = useState(false)

    useAppSignal({
        namespace: 'dashboard:configure.toggle',
        action: setActing
    })

    const [sectionsOrdered, setSectionsOrdered] = useState(local.get(`${salt}:order`) ?? defaultSections)
    const [isDefaultOrder, setIsDefaultOrder] = useState(isEqual(sectionsOrdered, defaultSections))

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed)

        return list
    }

    const updateConfiguration = order => {
        order = [...order]

        local.set(`${salt}:order`, order)
        setSectionsOrdered(order)
        sendAppSignal('dashboard:order', order)
        setIsDefaultOrder(isEqual(order, defaultSections))
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if(!destination || destination.index === source.index) {
            return
        }

        const order = reorder(
            sectionsOrdered,
            source.index,
            destination.index
        )

        updateConfiguration(order)
    }

    const changeSectionOrder = () => {
        // https://github.com/defunctzombie/form-serialize#options
        const options = {
            hash: true,
            empty: true
        }

        const data = JsonData(form.current, options)
        const order = Object.values(data)

        if(!isEqual([...order].sort(), [...sectionsOrdered].sort())) {
            updateConfiguration(order)
        }
    }

    const showSection = id => {
        updateConfiguration([
            ...sectionsOrdered,
            defaultSections.find(section => section === id)
        ])
    }

    const hideSection = id => {
        updateConfiguration(sectionsOrdered.filter(sectionId => sectionId !== id))
    }

    const changeSectionOrderDebounced = debounce(changeSectionOrder, 100, {
        leading: false,
        trailing: true,
        maxWait: 300
    })

    const reset = () => {
        updateConfiguration(defaultSections)
    }

    const hiddenSections = defaultSections?.filter(section => !sectionsOrdered?.includes(section))

    return (
        <Modal
            show={!!acting}
            dismiss={() => sendAppSignal('dashboard:configure.toggle', false)}>
            <Form
                onChange={changeSectionOrderDebounced}
                ref={form}>
                <ModalHeader
                    heading={formatMessage({
                        id: 'action_configure_dashboard',
                        defaultMessage: 'Configure dashboard'
                    })}
                    compact={true} />
                <Paragraph className="compact">
                    <FormattedMessage
                        id="dashboard_configure_helper"
                        defaultMessage="Rearrange sections or hide the ones you’re not using." />
                </Paragraph>
                <H4>
                    <FormattedMessage
                        id="dashboard_configure_section_visible"
                        defaultMessage="Visible" />
                </H4>
                <DragDropWrapper>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {sectionsOrdered.map((section, index) => (
                                        <Draggable
                                            key={`${salt}:section:visible:${section}`}
                                            draggableId={`section:${section}`}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div>
                                                    <Section
                                                        section={section}
                                                        hideSection={hideSection}
                                                        provided={provided}
                                                        snapshot={snapshot} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DragDropWrapper>
                {!!hiddenSections?.length && (
                    <>
                        <H4>
                            <FormattedMessage
                                id="dashboard_configure_section_hidden"
                                defaultMessage="Hidden" />
                        </H4>
                        <UnorderedList>
                            {hiddenSections.map(section => (
                                <HiddenSection
                                    section={section}
                                    showSection={showSection}
                                    key={`${salt}:section:hidden:${section}`} />
                            ))}
                        </UnorderedList>
                    </>
                )}
                {!isDefaultOrder && (
                    <Plain
                        onClick={reset}
                        className="constructive">
                        <FormattedMessage
                            id="action_reset_defaults"
                            defaultMessage="Reset to defaults" />
                    </Plain>
                )}
            </Form>
            <Actions className="stretch">
                <Button
                    className="constructive"
                    onClick={() => sendAppSignal('dashboard:configure.toggle', false)}>
                    <FormattedMessage
                        id="action_done"
                        defaultMessage="Done" />
                </Button>
            </Actions>
        </Modal>
    )
}

export default props => {
    const { statuses } = useOrganization()

    const {
        initialized,
        checkModule,
        checkFeature
    } = useAccess()

    const newsAvailable = checkModule('news')

    const handbookAvailable = [
        checkModule('handbook'),
        checkFeature('handbook-featured'),
        statuses?.handbook
    ].every(Boolean)

    const tasksAvailable = checkModule('tasks') // TODO: Weird?

    const absenceAvailable = [
        checkModule('absence'),
        statuses?.hasSetUpWorkSchedule,
        statuses?.absence
    ].every(Boolean)

    const jubileesAvailable = checkFeature('jubilees')
    const peopleAvailable = checkModule('people')

    const access = initialized && compact([
        newsAvailable && 'news',
        handbookAvailable && 'handbook',
        tasksAvailable && 'todos',
        absenceAvailable && 'absence',
        jubileesAvailable && 'jubilees',
        peopleAvailable && 'people'
    ])

    if(!access) {
        return null
    }

    return (
        <ConfigureDashboard
            {...props}
            access={access} />
    )
}