import styled, { keyframes } from 'styled-components'
import { belowTablet, belowPhone } from 'utilities/styled'
import CheckBase from './check'
import AvatarBase from 'components/avatar'
import Paragraph from 'components/typography/paragraph'

export const ProgressDetailsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 24px;

    ${belowTablet`
        column-gap: 16px;
    `}
`

export const ProgressCircleWrapper = styled.div`
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;

    > * {
        isolation: isolate;
        grid-area: 1 / 1;
    }
`

export const Avatar = styled(AvatarBase)`
    transition: filter .2s ease-in-out;
`

const completedIn = keyframes`
    to {
        transform: scale(1);
        opacity: 1;
    }
`

export const Completed = styled.div`
    border-radius: 100%;
    width: 48px;
    height: 48px;
    background-color: var(--huma-color-surface-constructive-bold);

    transform: scale(0.8);
    opacity: 0;

    animation: ${completedIn} .2s ease-in-out forwards;

    & + div {
        filter: brightness(0.7) contrast(1.2);
    }
`

const checkIn = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`

export const Check = styled(CheckBase)`
    stroke: var(--huma-color-foreground-on-bold);
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    stroke-width: 4;

    animation: ${checkIn} .2s ease-in-out forwards;
`

export const TaskCount = styled(Paragraph).attrs({ className: 'caption compact' })`
    ${belowPhone`
        display: none;
    `}
`