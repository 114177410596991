import { useLayoutEffect } from 'react'
import { each, map } from 'utilities/object'
import { prune } from 'utilities/array'

export const useMeta = ({ title, fallback = true, ...meta }, dependencies = []) => {
    title = stringify(title)

    useLayoutEffect(() => {
        if(!dependencies.every(Boolean)) {
            if(fallback) {
                document.title = 'Huma'
            }

            return
        }

        if(title) {
            document.title = `${title} ∘ Huma`
        }

        // Remove unspecified (previous) meta
        const dynamicMetaElements = [...document.head.querySelectorAll('meta[data-dynamic="true"]')]
        dynamicMetaElements
            .filter(({ name }) => !(name in meta))
            .forEach(document.head.removeChild)

        // Update/insert specified meta
        each(meta, (content, name) => {
            let element = document.head.querySelector(`meta[name="${name}"]`)

            if(!element) {
                element = document.createElement('meta')
                element.setAttribute('name', name)
                document.head.appendChild(element)
            }

            content = stringify(content)

            if(name === 'description') {
                content = `${content} ∘ Huma HR`
            }

            element.setAttribute('content', content)
            element.setAttribute('data-dynamic', true)
        })
    }, [title, map(meta, v => stringify(v)).join('+'), dependencies])
}

const stringify = value => Array.isArray(value) ?
    prune(value).join(' • ') :
    value