import styled, { css, keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import { belowTablet } from 'utilities/styled'

export const Wrapper = styled(motion.div).attrs(() => ({
    variants: {
        hide: {
            opacity: 0
        },
        show: {
            opacity: 1
        }
    },
    transition: {
        type: 'spring',
        mass: .5,
        stiffness: 100,
        velocity: 2
    },
    initial: 'show',
    exit: 'hide'
}))`
    pointer-events: none;
    position: absolute;
    left: 50%;

    transform: translateX(-50%);
`

const pulsate = keyframes`
    0% {
        opacity: 0;
        scale: 0.1;
    }
    50% {
        opacity: 1;
        scale: 0.5;
    }
    100% {
        opacity: 0;
        scale: 1.1;
    }
`

export const Graph = styled.div`
    position: relative;

    margin-top: 74px;
    border-radius: 50%;
    width: 352px;
    aspect-ratio: 1 / 1;

    ${belowTablet`
        width: 252px;
    `}

    div {
        position: absolute;
        inset: 0;

        border-radius: 50%;
        border: 1px solid var(--huma-color-border-subtle);
    }

    ${Array.from({ length: 5 }).map((_, i) => css`
        div:nth-child(${i + 1}) {
            animation: ${pulsate} 3s ${i * .6}s linear infinite both;
        }
    `)}
`