import React, { useState, useEffect, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useAccess } from 'contexts/access'
import { useConfiguration } from 'contexts/configuration'
import { useWithinQuota } from 'hooks/within-quota'
import { useHistate } from 'hooks/histate'
import { fieldFilled, anyFieldsDisplayable, anyFieldsEditable, fieldDisplayable, fieldEditable } from 'utilities/access'
import { compact } from 'utilities/array'
import { size } from 'utilities/object'
import { unpackStringField } from 'utilities/person'
import { zeroToHundredInclusivePattern } from 'utilities/regex'
import { getJobTitleUrl } from 'utilities/url'
import { SectionHero, isFieldImportant } from '../'
import { Container, Heading, Spacer, IntegratedHelper, Error } from '../s'
import { Clamp, InfoText, ExternalLink } from './s'
import Form from 'components/form/controller'
import SelectField from 'components/form/field/select'
import EntityField from 'components/form/field/entity'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import OneOfField from 'components/form/field/one-of'
import TimeField from 'components/form/field/time'
import UneditableField from 'components/form/field/uneditable'
import YearsAndMonthsSince from 'components/time-years-and-months-since'
import { ButtonSubmit, InlineButton } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import OffboardingPrompt from './offboarding-prompt'
import AddOffboarding from 'pages/processes/panes/processes/add/offboarding'
import integrationData from 'pages/integrations/pages/integration/data'
import CustomFields, { getCustomFieldsBySection } from '../custom-fields'
import { InlineMessage } from 'components/message'
import { ExternalLink as ExternalLinkIcon } from 'styled-icons/feather'

const EmploymentSection = ({ lookingAtMyOwnProfile, layout, editingSection = null, setEditingSection, salt }) => {
    const { formatMessage } = useIntl()

    const {
        person,
        updatePerson
    } = usePerson()

    const getCustomFields = useCallback(
        () => getCustomFieldsBySection(person, 'employment'),
        [Object.values(person?.custom ?? {})?.map(({ value }) => value).join('+'), person.status.active]
    )

    const [editing, setEditing] = useState(false)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)

    const {
        id,
        jobTitle,
        jobDescription,
        employmentType,
        employmentPercentage,
        employmentStartDate, // Employment start date
        firstDayOfWork, // First day of work
        probationEndDate, // Probation end date
        terminationNoticeDate, // Termination notice date
        lastDayOfWork, // Last day of work
        employmentEndDate, // Employment end date
        employmentId
    } = person

    const localEmploymentStartDate = useHistate(unpackStringField(employmentStartDate) ?? null)
    const localFirstDayOfWork = useHistate(unpackStringField(firstDayOfWork) ?? null)
    const localProbationEndDate = useHistate(unpackStringField(probationEndDate) ?? null)
    const localTerminationNoticeDate = useHistate(unpackStringField(terminationNoticeDate) ?? null)
    const localLastDayOfWork = useHistate(unpackStringField(lastDayOfWork) ?? null)
    const localEmploymentEndDate = useHistate(unpackStringField(employmentEndDate) ?? null)
    const localJobTitle = useHistate(unpackStringField(jobTitle) ?? null)

    const localEmploymentDescriptionValue = useHistate(fieldFilled(jobDescription) ? 'individual' : 'inherited')

    const [offboardingPrompt, setOffboardingPrompt] = useState(false)
    const [offboarding, setOffboarding] = useState(null)

    const {
        checkModule,
        check
    } = useAccess()

    const { configuration } = useConfiguration()

    const offboardingAccess = checkModule('offboarding') && check('users:manage')

    const [offboardingWithinQuota] = useWithinQuota({
        key: 'offboarding',
        dependencies: [offboardingAccess]
    })

    useEffect(() => {
        if(editingSection === 'employment') {
            setEditing(true)
        }
    }, [editingSection])

    const update = async (_, { nested: body, resetTouched }) => {
        if(body.employmentDescription === 'inherited') {
            body.jobDescription = null
        }

        delete body.employmentDescription

        if(!!body.employmentEndDate && (!body.employmentStartDate && !localEmploymentStartDate.current)) {
            return void setError({
                employmentStartDate: formatMessage({
                    id: 'person_error_employment_date_end_required',
                    defaultMessage: 'You need to enter an employment start date if an end date is entered'
                })
            })
        }

        setSaving(true)
        const { ok, response } = await updatePerson(body)
        setSaving(false)

        if(ok) {
            resetTouched()

            if('employmentStartDate' in body) {
                localEmploymentStartDate.update(body.employmentStartDate)
                localEmploymentStartDate.reinitialize()
            }

            if('firstDayOfWork' in body) {
                localFirstDayOfWork.update(body.firstDayOfWork)
                localFirstDayOfWork.reinitialize()
            }

            if('probationEndDate' in body) {
                localProbationEndDate.update(body.probationEndDate)
                localProbationEndDate.reinitialize()
            }

            if('terminationNoticeDate' in body) {
                localTerminationNoticeDate.update(body.terminationNoticeDate)
                localTerminationNoticeDate.reinitialize()
            }

            if('lastDayOfWork' in body) {
                localLastDayOfWork.update(body.lastDayOfWork)
                localLastDayOfWork.reinitialize()
            }

            if('employmentEndDate' in body) {
                localEmploymentEndDate.update(body.employmentEndDate)
                localEmploymentEndDate.reinitialize()
            }

            if('jobTitle' in body) {
                localJobTitle.reinitialize()
                localEmploymentDescriptionValue.reinitialize()
            }

            if('jobDescription' in body) {
                if(!body.jobDescription?.length) {
                    localEmploymentDescriptionValue.update('inherited')
                }

                localEmploymentDescriptionValue.reinitialize()
            }

            if(offboardingAccess && offboardingWithinQuota && !localLastDayOfWork.initial && !!localLastDayOfWork.current) {
                setTimeout(() => setOffboardingPrompt(true), 1000)
            }

            return void setEditing(false)
        }

        if(response?.errorCode === 'field:invalid-format' && response?.fields?.includes('employmentStartDate')) {
            setError({
                employmentStartDate: formatMessage({
                    id: 'person_error_employment_date_future',
                    defaultMessage: 'The employment start date can’t be in the future'
                })
            })
        }

        if(response?.errorCode === 'identifier:duplicate' && response?.fields?.includes('employmentId')) {
            setError({
                employmentId: formatMessage({
                    id: 'person_employment_id_duplicate',
                    defaultMessage: 'Employment ID already in use'
                })
            })
        }
    }

    useEffect(() => {
        if(!editing) {
            setError(null)

            localEmploymentStartDate.reset()
            localFirstDayOfWork.reset()
            localProbationEndDate.reset()
            localTerminationNoticeDate.reset()
            localLastDayOfWork.reset()
            localEmploymentEndDate.reset()
            localJobTitle.reset()
            localEmploymentDescriptionValue.reset()
        }
    }, [editing])

    const customFields = getCustomFields()

    const fields = [
        jobTitle,
        jobDescription,
        employmentType,
        employmentPercentage,
        employmentStartDate,
        firstDayOfWork,
        probationEndDate,
        terminationNoticeDate,
        employmentEndDate,
        lastDayOfWork,
        employmentId,
        ...customFields
    ]

    // Employment start date restrictions
    const getHireDateRestrictions = useCallback(() => {
        const restrictions = {}
        return restrictions
    }, [])

    // First day of work restrictions
    const getFirstDayOfWorkRestrictions = useCallback(() => {
        const restrictions = {}

        const before = getEarliestBeforeDate([
            localLastDayOfWork.current                  // Last day of work
        ])

        if(before) {
            restrictions.before = before
        }

        return restrictions
    }, [localLastDayOfWork.current])

    // Probation end date restrictions
    const getProbationEndDateRestrictions = useCallback(() => {
        const restrictions = {}

        const after = getLatestAfterDate([
            localFirstDayOfWork.current                // First day of work
        ])

        if(after) {
            restrictions.after = after
        }

        return restrictions
    }, [localFirstDayOfWork.current])

    // Termination notice date restrictions
    const getTerminationNoticeDateRestrictions = useCallback(() => {
        const restrictions = { future: false }

        return restrictions
    }, [])

    // Last day of work restrictions
    const getLastDayOfWorkRestrictions = useCallback(() => {
        const restrictions = {}

        const after = getLatestAfterDate([
            localFirstDayOfWork.current                // First day of work
        ])

        if(after) {
            restrictions.after = after
        }

        return restrictions
    }, [localFirstDayOfWork.current])

    // Employment end date restrictions
    const getTerminationDateRestrictions = useCallback(() => {
        const restrictions = {}
        return restrictions
    }, [])

    const anyDisplayable = anyFieldsDisplayable(fields)
    if(!anyDisplayable) {
        return null
    }

    const anyEditable = anyFieldsEditable(fields)
    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    const manageJobTitleAccess = check('users:manage')

    return (
        <>
            <Form
                layout={layout}
                onSubmit={update}>
                {({ touched, errors, trigger, triggerChange }) => (
                    <Container id="employment">
                        <SectionHero
                            editable={anyEditable}
                            editing={editing}
                            toggleEditing={() => setEditing(editing => {
                                if(editing) {
                                    setEditingSection?.(null)
                                }

                                return !editing
                            })}>
                            <Heading>
                                <FormattedMessage
                                    id="person_pane_about_section_employment"
                                    defaultMessage="Employment" />
                            </Heading>
                            <Spacer />
                            {!!editing && (
                                <ButtonSubmit
                                    className={`constructive${saving ? ' loading' : ''}`}
                                    disabled={!touched.length || !!size(errors) || saving}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id="action_save"
                                        defaultMessage="Save" />
                                </ButtonSubmit>
                            )}
                        </SectionHero>
                        {fieldDisplayable(jobTitle) && (
                            <EntityField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_jobtitle',
                                    defaultMessage: 'Job title'
                                })}
                                name="jobTitle"
                                field={{
                                    ...jobTitle,
                                    ...(!!jobTitle?.editable && {
                                        editable: editing
                                    })
                                }}
                                picker={{
                                    heading: formatMessage({
                                        id: 'jobtitle_action_pick',
                                        defaultMessage: 'Pick job title'
                                    }),
                                    creatable: true,
                                    search: {
                                        controlProps: {
                                            placeholder: formatMessage({
                                                id: 'jobtitle_action_find',
                                                defaultMessage: 'Find job title'
                                            })
                                        }
                                    }
                                }}
                                entity={{
                                    type: 'jobTitle',
                                    path: '/job-titles/paged'
                                }}
                                controlProps={{ autoFocus: true }}
                                onChange={({ jobTitle }) => localJobTitle.update(jobTitle)}
                                {...isImportant('jobTitle')} />
                        )}
                        {(fieldDisplayable(jobDescription) || fieldDisplayable(jobTitle)) && (
                            <>
                                {(!editing || (!fieldEditable(jobTitle) && !fieldEditable(jobDescription))) && (
                                    <TextField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_employment_jobdescription',
                                            defaultMessage: 'Job description'
                                        })}
                                        name="jobDescriptionDisplay"
                                        field={{
                                            value: fieldFilled(jobDescription) ?
                                                jobDescription.value :
                                                (jobTitle?.value?.description ?? null),
                                            editable: false,
                                            include: 'never',
                                            clamp: true
                                        }} />
                                )}
                                {(!!editing && (fieldEditable(jobTitle) || fieldEditable(jobDescription))) && (
                                    <OneOfField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_employment_jobdescription',
                                            defaultMessage: 'Job description'
                                        })}
                                        name="employmentDescription"
                                        field={{
                                            value: localEmploymentDescriptionValue.current,
                                            include: 'touched',
                                            options: [
                                                {
                                                    value: 'inherited',
                                                    label: formatMessage({
                                                        id: 'person_label_employment_jobdescription_default',
                                                        defaultMessage: 'Use default description'
                                                    }),
                                                    content: (
                                                        <>
                                                            {(!localJobTitle?.current && fieldEditable(jobTitle)) && (
                                                                <InlineMessage
                                                                    type="info"
                                                                    message={formatMessage({
                                                                        id: 'person_message_jobdescription_default_missing_jobtitle',
                                                                        defaultMessage: 'Please select a job title'
                                                                    })} />
                                                            )}
                                                            {!!localJobTitle?.current && (
                                                                <>
                                                                    {!localJobTitle.current?.description && (
                                                                        <>
                                                                            {manageJobTitleAccess && (
                                                                                <InlineMessage
                                                                                    type="info"
                                                                                    message={formatMessage({
                                                                                        id: 'person_message_jobdescription_default_missing_description',
                                                                                        defaultMessage: 'Description not added. <link>Add it to the job title</link> or <button>use an individual description</button>.',
                                                                                    }, {
                                                                                        link: chunks => (
                                                                                            <ExternalLink
                                                                                                to={getJobTitleUrl(localJobTitle.current)}
                                                                                                target="_blank"
                                                                                                className="constructive">
                                                                                                {chunks}
                                                                                                <ExternalLinkIcon size={14} />
                                                                                            </ExternalLink>
                                                                                        ),
                                                                                        button: chunks => (
                                                                                            <InlineButton
                                                                                                className="constructive"
                                                                                                onClick={() => localEmploymentDescriptionValue.update('individual')}>
                                                                                                {chunks}
                                                                                            </InlineButton>
                                                                                        )
                                                                                    })} />
                                                                            )}
                                                                            {!manageJobTitleAccess && (
                                                                                <InfoText>
                                                                                    <FormattedMessage
                                                                                        id="person_message_jobdescription_default_missing_description_short"
                                                                                        defaultMessage="Description not added." />
                                                                                </InfoText>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {!!localJobTitle.current?.description && (
                                                                        <>
                                                                            <Clamp>{localJobTitle.current.description}</Clamp>
                                                                            {manageJobTitleAccess && (
                                                                                <InfoText>
                                                                                    <FormattedMessage
                                                                                        id="person_message_jobdescription_default_description_managed"
                                                                                        defaultMessage="Description is managed on the job title." />
                                                                                    <ExternalLink
                                                                                        to={getJobTitleUrl(localJobTitle.current)}
                                                                                        target="_blank"
                                                                                        className="constructive">
                                                                                        <FormattedMessage
                                                                                            id="action_edit"
                                                                                            defaultMessage="Edit" />
                                                                                        <ExternalLinkIcon size={14} />
                                                                                    </ExternalLink>
                                                                                </InfoText>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                },
                                                {
                                                    value: 'individual',
                                                    label: formatMessage({
                                                        id: 'person_label_employment_jobdescription_individual',
                                                        defaultMessage: 'Use individual description'
                                                    }),
                                                    disabled: !fieldEditable(jobDescription),
                                                    content: (
                                                        <TextField
                                                            salt={salt}
                                                            label={false}
                                                            name="jobDescription"
                                                            field={{
                                                                ...jobDescription,
                                                                ...(!!jobDescription?.editable && {
                                                                    editable: editing
                                                                }),
                                                                clamp: true
                                                            }}
                                                            contentAssistant={{
                                                                field: 'jobDescription',
                                                                criterion: {
                                                                    unavailable: !localJobTitle?.current,
                                                                    unavailableTooltip: {
                                                                        id: 'assistant_tooltip_jobdescription_unavailable',
                                                                        defaultMessage: 'You need a job title before you can generate a job description'
                                                                    }
                                                                },
                                                                values: {
                                                                    jobTitle: localJobTitle?.current?.name
                                                                }
                                                            }}
                                                            {...isImportant('jobDescription')} />
                                                    )
                                                }
                                            ]
                                        }}
                                        onChange={({ employmentDescription }) => {
                                            localEmploymentDescriptionValue.update(employmentDescription)
                                            global.setTimeout(() => triggerChange('employmentDescription'), 50) // Let employmentDescription remount due to key change
                                        }}
                                        key={`${salt}:employmentDescription.${localEmploymentDescriptionValue.current}`} />
                                )}
                            </>
                        )}
                        {fieldDisplayable(employmentType) && (
                            <SelectField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_type',
                                    defaultMessage: 'Employment type'
                                })}
                                name="employmentType"
                                field={{
                                    ...employmentType,
                                    ...(!!employmentType?.editable && {
                                        editable: editing
                                    })
                                }}
                                options={configuration?.enums.employmentType.map(type => ({
                                    key: type,
                                    value: type,
                                    text: getEmploymentTypeLabel(type, formatMessage)
                                }))}
                                {...isImportant('employmentType')} />
                        )}
                        {fieldDisplayable(employmentPercentage) && (
                            <StringField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_percentage',
                                    defaultMessage: 'Employment percentage'
                                })}
                                name="employmentPercentage"
                                field={{
                                    ...employmentPercentage,
                                    ...(!!employmentPercentage?.editable && {
                                        editable: editing
                                    })
                                }}
                                controlProps={{
                                    type: 'number',
                                    min: 0,
                                    max: 100,
                                    pattern: zeroToHundredInclusivePattern
                                }}
                                {...isImportant('employmentPercentage')} />
                        )}
                        {fieldDisplayable(employmentId) && (
                            <>
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_employment_id',
                                        defaultMessage: 'Employment ID'
                                    })}
                                    name="employmentId"
                                    field={{
                                        ...employmentId,
                                        ...(!!employmentId?.editable && {
                                            editable: editing
                                        })
                                    }}
                                    {...isImportant('employmentId')}
                                    controlContent={employmentId?.integrations && Object.entries(employmentId.integrations)
                                    .map(([key, value]) => Object.entries(value).map(([typeKey, typeValue]) => (
                                        <IntegratedHelper
                                            animate={editing ? 'reveal' : 'hide'}
                                            key={`integration:${key}:${typeKey}:${typeValue}`}>
                                            <FormattedMessage
                                                id={`person_helper_integration_${typeKey}_${typeValue}`}
                                                defaultMessage={typeValue}
                                                values={{ service: integrationData[key]?.()?.name ?? key }} />
                                        </IntegratedHelper>
                                    )))} />
                                <Error animate={!!error?.employmentId ? 'reveal' : 'hide'}>
                                    {error?.employmentId}
                                </Error>
                            </>
                        )}
                        {fieldDisplayable(employmentStartDate) && (
                            <>
                                <TimeField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_employment_contract_date_start',
                                        defaultMessage: 'Employment start date'
                                    })}
                                    name="employmentStartDate"
                                    field={{
                                        ...employmentStartDate,
                                        ...(!!employmentStartDate?.editable && {
                                            editable: editing
                                        })
                                    }}
                                    picker={getHireDateRestrictions()}
                                    onChange={({ employmentStartDate }) => {
                                        setError(null)
                                        localEmploymentStartDate.update(employmentStartDate)
                                    }}
                                    {...isImportant('employmentStartDate')}
                                    controlContent={(
                                        <IntegratedHelper animate={editing ? 'reveal' : 'hide'}>
                                            <FormattedMessage
                                                id="person_helper_employment_hire_date"
                                                defaultMessage="The start date as specified in the employment contract." />
                                        </IntegratedHelper>
                                    )} />
                                <Error animate={!!error?.employmentStartDate ? 'reveal' : 'hide'}>
                                    {error?.employmentStartDate}
                                </Error>
                                {!!localEmploymentStartDate.current && (
                                    <UneditableField
                                        label={formatMessage({
                                            id: 'person_label_employment_seniority',
                                            defaultMessage: 'Seniority'
                                        })}
                                        field={{
                                            value: (
                                                <YearsAndMonthsSince
                                                    start={localEmploymentStartDate.current}
                                                    end={localEmploymentEndDate.current}
                                                    key={`seniority:${localEmploymentStartDate.current}:${localEmploymentEndDate.current}`} />
                                            )
                                        }} />
                                )}
                            </>
                        )}
                        {fieldDisplayable(firstDayOfWork) && (
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_date_start',
                                    defaultMessage: 'First day of work'
                                })}
                                name="firstDayOfWork"
                                field={{
                                    ...firstDayOfWork,
                                    ...(!!firstDayOfWork?.editable && {
                                        editable: editing
                                    })
                                }}
                                picker={getFirstDayOfWorkRestrictions()}
                                onChange={({ firstDayOfWork }) => localFirstDayOfWork.update(firstDayOfWork)}
                                {...isImportant('firstDayOfWork')} />
                        )}
                        {fieldDisplayable(probationEndDate) && (
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_probation_date_end',
                                    defaultMessage: 'Probation end date'
                                })}
                                name="probationEndDate"
                                field={{
                                    ...probationEndDate,
                                    ...(!!probationEndDate?.editable && {
                                        editable: editing
                                    })
                                }}
                                picker={getProbationEndDateRestrictions()}
                                onChange={({ probationEndDate }) => localProbationEndDate.update(probationEndDate)}
                                {...isImportant('probationEndDate')} />
                        )}
                        {fieldDisplayable(terminationNoticeDate) && (
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_termination_notice_date',
                                    defaultMessage: 'Termination notice date'
                                })}
                                name="terminationNoticeDate"
                                field={{
                                    ...terminationNoticeDate,
                                    ...(!!terminationNoticeDate?.editable && {
                                        editable: editing
                                    })
                                }}
                                picker={getTerminationNoticeDateRestrictions()}
                                {...isImportant('terminationNoticeDate')}
                                controlContent={(
                                    <IntegratedHelper animate={editing ? 'reveal' : 'hide'}>
                                        <FormattedMessage
                                            id="person_helper_employment_termination_notice_date"
                                            defaultMessage="The date as specified in the termination notice." />
                                    </IntegratedHelper>
                                )} />
                        )}
                        {fieldDisplayable(lastDayOfWork) && (
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_date_end',
                                    defaultMessage: 'Last day of work'
                                })}
                                name="lastDayOfWork"
                                field={{
                                    ...lastDayOfWork,
                                    ...(!!lastDayOfWork?.editable && {
                                        editable: editing
                                    })
                                }}
                                picker={getLastDayOfWorkRestrictions()}
                                {...isImportant('lastDayOfWork')}
                                onChange={({ lastDayOfWork }) => localLastDayOfWork.update(lastDayOfWork)} />
                        )}
                        {fieldDisplayable(employmentEndDate) && (
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'person_label_employment_termination_date',
                                    defaultMessage: 'Termination date'
                                })}
                                name="employmentEndDate"
                                field={{
                                    ...employmentEndDate,
                                    ...(!!employmentEndDate?.editable && {
                                        editable: editing
                                    })
                                }}
                                picker={getTerminationDateRestrictions()}
                                {...isImportant('employmentEndDate')}
                                onChange={({ employmentEndDate }) => localEmploymentEndDate.update(employmentEndDate)}
                                controlContent={(
                                    <IntegratedHelper animate={editing ? 'reveal' : 'hide'}>
                                        <FormattedMessage
                                            id="person_helper_employment_termination_date"
                                            defaultMessage="The date as specified in the termination notice." />
                                    </IntegratedHelper>
                                )} />
                        )}
                        <CustomFields
                            fields={customFields}
                            editing={editing}
                            salt={salt} />
                    </Container>
                )}
            </Form>
            <Modal
                show={!!offboardingPrompt}
                dismiss={() => setOffboardingPrompt(false)}
                salt={`${salt}:offboarding-prompt`}>
                <OffboardingPrompt onDone={offboard => {
                    setOffboardingPrompt(false)

                    if(offboard) {
                        setOffboarding({
                            type: 'predefined',
                            concernsId: id
                        })
                    }
                }} />
            </Modal>
            <Modal
                show={!!offboarding}
                dismiss={() => setOffboarding(null)}
                salt={`${salt}:offboard`}>
                <AddOffboarding
                    {...offboarding}
                    onDone={() => setOffboarding(null)}
                    salt={`${salt}:offboard`} />
            </Modal>
        </>
    )
}

const getEarliestBeforeDate = dates => compact(dates).sort()[0]
const getLatestAfterDate = dates => compact(dates).sort().reverse()[0]

const getEmploymentTypeLabel = (type, formatMessage) => ({
    Permanent: formatMessage({
        id: 'person_label_employment_type_permanent',
        defaultMessage: 'Permanent'
    }),
    Temporary: formatMessage({
        id: 'person_label_employment_type_temporary',
        defaultMessage: 'Temporary'
    }),
    Casual: formatMessage({
        id: 'person_label_employment_type_casual',
        defaultMessage: 'Casual'
    }),
    Trainee: formatMessage({
        id: 'person_label_employment_type_trainee',
        defaultMessage: 'Trainee'
    }),
    Consultant: formatMessage({
        id: 'person_label_employment_type_consultant',
        defaultMessage: 'Consultant'
    })
})[type]

export default EmploymentSection