import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useLogin } from 'contexts/login'
import { usePageView } from 'hooks/page-view'
import { useTimeout } from 'hooks/timeout'
import { Step, Rows, Fields, Heading, Actions, Caption } from '../s'
import { FlexChildShrink } from 'components/flex'
import { Retry } from './s'
import Link from 'components/link'
import { BackButton } from 'components/button'
import { formatPhoneNumber } from 'react-phone-number-input'

const ThreeForgot = ({ show, ...props }) => {
    const { formatMessage } = useIntl()

    usePageView('login_forgotten_huma_domain_sent', [show])

    const [revealRetry, setRevealRetry] = useState(false)
    useTimeout(() => setRevealRetry(true), 10000)

    const {
        recipient,

        goToFirstStep,
        goToPreviousStep
    } = useLogin()

    return (
        <Step
            {...props}
            show={show}>
            <Rows>
                <Fields>
                    <Heading>
                        <FormattedMessage
                            id={`login_three_forgot_heading_${recipient.method}`}
                            defaultMessage={`Check your ${recipient.method}`} />
                    </Heading>
                    <Caption>
                        <FormattedMessage
                            id="login_three_forgot_caption"
                            defaultMessage="We’ve sent a link to {recipient}. Click it to get back on track."
                            values={{
                                recipient: (
                                    <strong>
                                        {recipient.method === 'phone' ?
                                            formatPhoneNumber(recipient[recipient.method]) :
                                            recipient[recipient.method]
                                        }
                                    </strong>
                                )
                            }} />
                    </Caption>
                    <Retry {...(revealRetry ? { className: 'reveal' } : null)}>
                        <FormattedMessage
                            id="login_three_forgot_retry"
                            defaultMessage="Didn’t receive a link? {link}."
                            values={{
                                link: (
                                    <Link
                                        onClick={goToPreviousStep}
                                        className="constructive">
                                        <FormattedMessage
                                            id={`login_three_forgot_retry_reenter_${recipient.method}`}
                                            defaultMessage={`Please re-enter your ${recipient.method === 'phone' ? 'phone number' : 'email address'}`} />
                                    </Link>
                                )
                            }} />
                    </Retry>
                </Fields>
                <FlexChildShrink>
                    <Actions className="start">
                        <BackButton
                            onClick={goToFirstStep}
                            text={formatMessage({
                                id: 'action_go_back',
                                defaultMessage: 'Go back'
                            })} />
                    </Actions>
                </FlexChildShrink>
            </Rows>
        </Step>
    )
}

export default ThreeForgot
