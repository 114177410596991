import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'salary'

const Salary = props => {
    const {
        initialized,
        checkModule
    } = useAccess()

    const { me } = useMe()

    const access = initialized && checkModule(moduleIdentifier) && !!me?.salary
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="salary.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}>
            <FormattedMessage
                id="noun_salary"
                defaultMessage="Salary" />
        </MenuItemLink>
    )
}

export default Salary