import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { decimalToPercent } from 'utilities/math'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { isOngoing, getDaysCount } from 'pages/absence/utilities'
import PercentageLabel from 'components/percentage-label'
import {
    Wrapper,
    EntryDetailsClickable, EntryDetailsFragment, Symbol, Meta, AbsenceDate,
    StatusLabel,
    Actions
} from './s'
import { CaptionSmall } from 'components/typography/caption'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const Entry = ({ entry, actions = {}, showWorkdaysAffected = false, salt }) => {
    const theme = useTheme()

    const now = new Date()

    const {
        user,
        type,
        start,
        end,
        grade = 1
    } = entry

    const { allowGraded = false } = type ?? {}

    const days = getDaysCount(entry)

    const name = useTypeNameFormatter()(type)

    const view = actions?.view?.(entry)

    const dateProps = {
        user,
        start,
        end
    }

    const EntryDetails = view ?
        EntryDetailsClickable :
        EntryDetailsFragment

    const entryDetailsProps = view ? {
        onClick: () => view.onClick()
    } : null

    const hasStatus = isOngoing({ start, end }, now)
    const boundActions = getBoundActions(actions, entry)
    const hasActions = !!boundActions?.length

    theme.absence = {
        actions: hasActions,
        status: hasStatus
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <EntryDetails {...entryDetailsProps}>
                    <Symbol
                        symbol={type.symbol}
                        {...(grade < 1 ? { percentage: decimalToPercent(grade) } : null)}
                        className="hover-effect" />
                    <Meta>
                        <AbsenceDate
                            {...entryDetailsProps}
                            {...dateProps} />
                        <CaptionSmall className="compact interpoint-divider">
                            <span>{name}</span>
                            {(!!allowGraded && grade < 1) && (
                                <span>
                                    <PercentageLabel decimal={grade} />
                                </span>
                            )}
                            {(!!showWorkdaysAffected && !!days) && (
                                <span>
                                    <FormattedMessage
                                        id="absence_count_workdays"
                                        defaultMessage="{count, plural, =0 {} =1 {1 workweek day} other {{count} workweek days}}"
                                        values={{ count: days }} />
                                </span>
                            )}
                        </CaptionSmall>
                    </Meta>
                </EntryDetails>
                {hasStatus && (
                    <StatusLabel className="positive">
                        <FormattedMessage
                            id="absence_status_ongoing"
                            defaultMessage="Ongoing" />
                    </StatusLabel>
                )}
                {hasActions && (
                    <Actions>
                        <ContextMenu
                            prebound actions={boundActions}
                            salt={salt} />
                    </Actions>
                )}
            </Wrapper>
        </ThemeProvider>
    )
}

export default Entry
