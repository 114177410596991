import React, { Component, createContext, createRef, useContext } from 'react'
import { createPortal } from 'react-dom'
import { intersectionObserver } from 'utilities/dom'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { useMe } from 'contexts/me'
import { local } from 'utilities/storage'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { asyncForEach } from 'sequential-async-foreach'
import { SmartProvidedPDFContent } from 'components/tiptap/output/pdf'
import { saveAs } from 'file-saver'
import { size, pick, reduce, withoutEmptyArrays, each } from 'utilities/object'
import { compact as compactArray } from 'utilities/array'
import { get, post, remove, patch } from 'api'
import PubSub from 'pubsub-js'

const DocumentsContext = createContext()

class DocumentsProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults()
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.state = {
            dokuments: props?.dokuments ?? [],
            selectable: props?.selectable ?? false,
            selection: [],
            lastSelected: [],
            total: 0,
            fixed: !!props.dokuments,
            filter: props.filter ?? {},
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetchDocument: this.fetchDocument,
            uploadDocument: this.uploadDocument,
            updateDocument: this.updateDocument,
            updateDocumentLocally: this.updateDocumentLocally,
            removeDocument: this.removeDocument,

            getDocumentPreviewUrl: this.getDocumentPreviewUrl,
            getDocumentDownloadUrl: this.getDocumentDownloadUrl,

            addSigning: this.addSigning,
            remindSigners: this.remindSigners,
            postponeSigningDeadline: this.postponeSigningDeadline,
            withdrawSigning: this.withdrawSigning,
            restartSigning: this.restartSigning,
            removeSigning: this.removeSigning,

            getSignedDocumentPreviewUrl: this.getSignedDocumentPreviewUrl,
            getSignedDocumentDownloadUrl: this.getSignedDocumentDownloadUrl,

            addSmartDocument: this.addSmartDocument,

            fetch: this.fetch,
            getNumberOfDocumentsOnlyOwnedByMe: this.getNumberOfDocumentsOnlyOwnedByMe,
            bulkExtendDocumentOwnership: this.bulkExtendDocumentOwnership,
            setDocumentsFilter: this.setFilter,
            toggleSorting: this.toggleSorting,

            selectDocument: this.select,
            selectMultipleDocuments: this.selectMultiple,
            selectAllDocuments: this.selectAll,
            deselectDocument: this.deselect,
            deselectAllDocuments: this.deselectAll,
            getViewableSelection: this.getViewableSelection,
            getEditableSelection: this.getEditableSelection,
            setLastSelectedIds: this.setLastSelectedDocumentIds,

            updateNamesForSelection: this.updateNamesForSelection,
            updateConcernsForSelection: this.updateConcernsForSelection,
            updateCategoryForSelection: this.updateCategoryForSelection,
            updateAccessForSelection: this.updateAccessForSelection,
            downloadSelection: this.downloadSelection,
            deleteSelection: this.deleteSelection,

            numberOfDocumentsOnlyOwnedByMe: 0,
            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false,

            pdfPortal: null
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetchDebounced()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { fetchAccess: previousFetchAccess = true } = prevProps
        const { fetchAccess = true } = this.props

        const fetchAccessGained = !previousFetchAccess && fetchAccess
        const pagingChanged = !isEqual(prevProps.paging, this.props?.paging)
        const selectableChanged = prevProps.selectable !== this.props.selectable
        const filterChanged = !isEqual(prevState.filter, this.state.filter)
        const sortingChanged = !isEqual(prevState.sorting, this.state.sorting)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        if(selectableChanged) {
            state.selectable = this.props.selectable
        }

        if(prevProps.dokuments !== this.props.dokuments) {
            state.dokuments = this.props.dokuments
            state.fixed = !!this.props.dokuments
        }

        this.setState(size(state) ? state : null, () => {
            if(fetchAccessGained, filterChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
    }

    fetch = async (force = false) => {
        const {
            fixed,
            fetching,
            filter,
            sorting,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fixed || fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = await get({
            path: '/documents',
            params: {
                ...getFetchFilter(filter),
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ dokuments: previousDokuments }) => {
                const previousDokumentsWithoutFetched = previousDokuments.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const dokuments = [
                    ...previousDokumentsWithoutFetched,
                    ...response.items
                ]

                return {
                    dokuments,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && dokuments.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                fetching: false,
                loading: false
            })
        }
    }

    getNumberOfDocumentsOnlyOwnedByMe = async () => {
        const { ok, response } = await get({
            path: '/documents',
            params: {
                types: compactArray([
                    'file',
                    this.props.smartDocumentsAvailable && 'smart'
                ]),
                onlyOwnerId: 'me',
                limit: 0
            }
        })

        if(ok && response) {
            this.setState({ numberOfDocumentsOnlyOwnedByMe: response.total })
        }
    }

    bulkExtendDocumentOwnership = async () => {
        const result = await patch({
            path: '/documents/bulk',
            body: {
                owners: ['organization', 'me']
            },
            returnsData: false
        })

        if(result.ok) {
            this.setState({ numberOfDocumentsOnlyOwnedByMe: 0 })
        }

        return result
    }

    fetchDocument = id => get({ path: `/documents/${id}` })

    uploadDocument = async body => {
        const { ok, response: dokument } = await post({
            path: '/documents/upload',
            body
        })

        if(ok && dokument) {
            this.flash.current = dokument

            this.setState(({ dokuments, total, numberOfDocumentsOnlyOwnedByMe }) => ({
                dokuments: [
                    dokument,
                    ...dokuments
                ],
                total: total + 1,
                numberOfDocumentsOnlyOwnedByMe: numberOfDocumentsOnlyOwnedByMe + 1
            }))
        }

        return { ok, response: dokument }
    }

    updateDocument = async (body, id, options = {}, type = 'file') => {
        const dokument = this.state.dokuments.find(dokument => dokument.id === id)

        const isOnlyOwner = dokument.owners.length === 1 && this.props.isItMyOwnId(dokument.owners[0].id)
        const lostDirectOwnership = !body.owners?.some(({ id }) => this.props.isItMyOwnId(id))

        let retainedAccess = true
        let lostOwnership = false

        body.type = dokument?.type || type

        if(dokument.versionHash) {
            body.versionHash = dokument.versionHash
        }

        const { ok: updateOk, response } = await patch({
            path: `/documents/${id}`,
            body
        })

        options?.onProgress?.(updateOk)

        if(updateOk) {
            if(lostDirectOwnership) {
                const updateResult = await this.fetchDocument(id)
                retainedAccess = updateResult.ok

                lostOwnership = !retainedAccess || (
                    !updateResult?.response?.permissions?.includes('unit:documents:manage') &&
                    !updateResult?.response?.owners?.some(({ id }) => this.props.isItMyOwnId(id))
                )
            }

            if(!lostDirectOwnership || retainedAccess) {
                this.flash.current = response
                this.updateDocumentLocally(response, id)
            }

            if(lostDirectOwnership) {
                this.setState(({ dokuments, selection, numberOfDocumentsOnlyOwnedByMe }) => ({
                    ...(!retainedAccess ? {
                        dokuments: dokuments.filter(dokument => dokument.id !== id),
                        selection: selection.filter(dokument => dokument.id !== id),
                    } : null),
                    ...((isOnlyOwner && lostOwnership) ? {
                        numberOfDocumentsOnlyOwnedByMe: numberOfDocumentsOnlyOwnedByMe - 1
                    } : null)
                }))

                return {
                    ok: updateOk,
                    response,
                    meta: {
                        retainedAccess,
                        lostOwnership
                    }
                }
            }
        }

        return {
            ok: updateOk,
            response
        }
    }

    removeDocument = async (id, options = {}) => {
        const dokument = this.state.dokuments.find(dokument => dokument.id === id)
        const isOnlyOwner = dokument.owners.length === 1 && this.props.isItMyOwnId(dokument.owners[0].id)

        const { ok } = await remove({
            path: `/documents/${id}`,
            body: pick(dokument, 'versionHash'),
            returnsData: false
        })

        options?.onProgress?.(ok)

        if(ok) {
            this.setState(({ dokuments, selection, numberOfDocumentsOnlyOwnedByMe, paging }) => ({
                dokuments: dokuments.filter(dokument => dokument.id !== id),
                selection: selection.filter(dokument => dokument.id !== id),
                ...(isOnlyOwner ? { numberOfDocumentsOnlyOwnedByMe: numberOfDocumentsOnlyOwnedByMe - 1 } : null),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    getDocumentPreviewUrl = id => `/documents/${id}/preview`
    getDocumentDownloadUrl = id => `/documents/${id}/download`

    updateDocumentLocally = (dokument, id) => {
        PubSub.publish(`document:updated:${id}`, dokument)

        this.setState(({ dokuments, selection }) => {
            const state = {}

            if(dokuments.find(dokument => dokument.id === id)) {
                state.dokuments = dokuments.with(dokuments.findIndex(dokument => dokument.id === id), dokument)
            }

            if(selection.find(dokument => dokument.id === id)) {
                state.selection = selection.with(selection.findIndex(dokument => dokument.id === id), dokument)
            }

            return state
        })
    }

    resolveDocumentById = (id, dokuments = this.state.dokuments) =>
        dokuments.find(dokument => dokument.id === id)

    addSigning = async (body, id) => {
        const { ok, response } = await post({
            path: `/documents/${id}/signing`,
            body
        })

        if(ok) {
            this.updateDocumentLocally({
                ...this.resolveDocumentById(id),
                signing: response
            }, id)
        }

        return { ok, response }
    }

    remindSigners = async id => {
        const { ok, response } = await post({ path: `/documents/${id}/signing/remind` })

        if(ok) {
            this.updateDocumentLocally({
                ...this.resolveDocumentById(id),
                signing: response
            }, id)
        }

        return { ok, response }
    }

    postponeSigningDeadline = async (days, id) => {
        const { ok, response } = await post({
            path: `/documents/${id}/signing/prolong`,
            body: { days }
        })

        if(ok) {
            this.updateDocumentLocally({
                ...this.resolveDocumentById(id),
                signing: response
            }, id)
        }

        return { ok, response }
    }

    withdrawSigning = async id => {
        const { ok, response } = await post({ path: `/documents/${id}/signing/withdraw` })

        if(ok) {
            this.updateDocumentLocally({
                ...this.resolveDocumentById(id),
                signing: response
            }, id)
        }

        return { ok, response }
    }

    restartSigning = async (body, id) => {
        const { ok, response } = await patch({
            path: `/documents/${id}/signing`,
            body
        })

        if(ok) {
            this.updateDocumentLocally({
                ...this.resolveDocumentById(id),
                signing: response
            }, id)
        }

        return { ok, response }
    }

    removeSigning = async id => {
        const { ok } = await remove({
            path: `/documents/${id}/signing`,
            returnsData: false
        })

        if(ok) {
            const dokument = this.resolveDocumentById(id)
            delete dokument.signing

            this.updateDocumentLocally(dokument, id)
        }

        return { ok }
    }

    getSignedDocumentPreviewUrl = id => `/documents/${id}/signing/preview`
    getSignedDocumentDownloadUrl = id => `/documents/${id}/signing/download`

    addSmartDocument = async body => {
        const { ok, response: dokument } = await post({
            path: '/documents',
            body: {
                ...body,
                type: 'smart'
            }
        })

        if(ok && dokument) {
            this.flash.current = dokument

            this.setState(({ dokuments, total, numberOfDocumentsOnlyOwnedByMe }) => ({
                dokuments: [
                    dokument,
                    ...dokuments
                ],
                total: total + 1,
                numberOfDocumentsOnlyOwnedByMe: numberOfDocumentsOnlyOwnedByMe + 1
            }))
        }

        return { ok, response: dokument }
    }

    setFilter = (filter = {}, options = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                dokuments: [],
                selection: [],
                lastSelected: [],
                filter: nextFilter,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        }, () => {
            if(options?.external) {
                each(filter, (value, name) => {
                    PubSub.publish('filter:updated', {
                        context: 'documents',
                        name,
                        value
                    })
                })
            }
        })
    }

    toggleSorting = field => {
        if(field in sortingOptions) {
            this.setState(({ sorting }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc' : 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    dokuments: [],
                    selection: [],
                    lastSelected: [],
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }
            })
        }
    }

    select = id => void this.setState(({ dokuments, selection, lastSelected }) => ({
        selection: [
            ...selection,
            dokuments.find(dokument => dokument.id === id)
        ],
        lastSelected: [
            ...lastSelected,
            id
        ]
    }))

    selectMultiple = (ids, id) => void this.setState(({ dokuments, selection, lastSelected }) => ({
        selection: [
            ...selection,
            ...dokuments.filter(dokument => ids.includes(dokument.id))
        ],
        lastSelected: [
            ...lastSelected,
            id
        ]
    }))

    selectAll = () => void this.setState(({ dokuments }) => ({
        selection: [...dokuments],
        lastSelected: []
    }))

    deselect = id => void this.setState(({ selection, lastSelected }) => ({
        selection: selection.filter(dokument => dokument.id !== id),
        lastSelected: lastSelected.filter(lastSelectedId => lastSelectedId !== id)
    }))

    deselectAll = () => void this.setState({
        selection: [],
        lastSelected: []
    })

    getViewableSelection = () => this.state.selection.filter(dokument =>
        !dokument.owners?.some(({ type, id }) => type === 'user' && this.props.isItMyOwnId(id)) &&
        !dokument.permissions?.includes('unit:documents:manage')
    )

    getEditableSelection = () => this.state.selection
        .filter(dokument =>
            dokument.owners?.some(({ type, id }) => type === 'user' && this.props.isItMyOwnId(id)) ||
            dokument.permissions?.includes('unit:documents:manage')
        )
        .sort(({ id: oneId }, { id: twoId }) => {
            const oneIndex = this.state.dokuments.findIndex(dok => dok.id === oneId)
            if(oneIndex === -1) {
                return 1
            }

            const twoIndex = this.state.dokuments.findIndex(dok => dok.id === twoId)
            if(twoIndex === -1) {
                return -1
            }

            return oneIndex - twoIndex
        })

    selectionSkipUnchanged = (dokument, onProgress) => {
        onProgress('skipped')

        return Promise.resolve({
            ok: true,
            response: dokument,
            skipped: true
        })
    }

    updateNamesForSelection = ({ names }, { onProgress }) => {
        const resolved = Object.entries(names).map(([id, name]) => ({
            dokument: this.state.selection.find(dokument => dokument.id === id),
            name
        }))

        return Promise.allSettled(resolved.map(({ dokument, name }) => {
            if(name === dokument.name) {
                return this.selectionSkipUnchanged(dokument, onProgress)
            }

            return this.updateDocument({ name }, dokument.id, {
                onProgress: success => onProgress(!!success ?
                    'succeeded' :
                    'failed'
                )
            })
        }))
    }

    updateConcernsForSelection = ({ concerns }, { onProgress }) => {
        return Promise.allSettled(this.getEditableSelection().map(dokument => {
            if(!concerns && !dokument.concerns || concerns === dokument.concerns?.id) {
                return this.selectionSkipUnchanged(dokument, onProgress)
            }

            return this.updateDocument({ concerns }, dokument.id, {
                onProgress: success => onProgress(!!success ?
                    'succeeded' :
                    'failed'
                )
            })
        }))
    }

    updateCategoryForSelection = ({ category }, { onProgress }) => {
        if(category === 'none') {
            category = null
        }

        return Promise.allSettled(this.getEditableSelection().map(dokument => {
            if(!category && !dokument.category || category === dokument.category) {
                return this.selectionSkipUnchanged(dokument, onProgress)
            }

            return this.updateDocument({ category }, dokument.id, {
                onProgress: success => onProgress(!!success ?
                    'succeeded' :
                    'failed'
                )
            })
        }))
    }

    unsetSharesForSelection = onProgress => {
        return Promise.allSettled(this.getEditableSelection().map(dokument => {
            if(!dokument.shares?.length) {
                return this.selectionSkipUnchanged(dokument, onProgress)
            }

            return this.updateDocument({ shares: null }, dokument.id, {
                onProgress: success => onProgress(!!success ?
                    'succeeded' :
                    'failed'
                )
            })
        }))
    }

    updateAccessForSelection = ({ shares, owners, removed, replace }, { onProgress }) => {
        const sortedShareIds = sortUnits(shares)
        const removedShareIds = sortUnits(removed.shares)
        const sortedOwnerIds = sortUnits(owners)
        const removedOwnerIds = sortUnits(removed.owners)

        return Promise.allSettled(this.getEditableSelection().map(dokument => {
            let sortedCurrentShareIds = sortUnits(dokument.shares)
            let sortedCurrentOwnerIds = sortUnits(dokument.owners)

            const sharesUnchanged = isEqual(sortedShareIds, sortedCurrentShareIds)
            const ownersUnchanged = isEqual(sortedOwnerIds, sortedCurrentOwnerIds)

            if(sharesUnchanged && ownersUnchanged) {
                return this.selectionSkipUnchanged(dokument, onProgress)
            }

            let body = {}

            if(replace) {
                if(!sharesUnchanged) {
                    body.shares = shares
                }

                if(!ownersUnchanged) {
                    body.owners = owners
                }
            }

            if(!replace) {
                if(!sharesUnchanged) {
                    body.shares = [
                        ...sortUnits(dokument.shares).filter(id => !sortedShareIds.includes(id)),
                        ...sortedShareIds
                    ].filter(id => !removedShareIds.includes(id))
                }

                if(!ownersUnchanged) {
                    body.owners = [
                        ...sortUnits(dokument.owners).filter(id => !sortedOwnerIds.includes(id)),
                        ...sortedOwnerIds
                    ].filter(id => !removedOwnerIds.includes(id))
                }
            }

            return this.updateDocument(body, dokument.id, {
                onProgress: success => onProgress(!!success ?
                    'succeeded' :
                    'failed'
                )
            })
        }))
    }

    downloadSelection = async (body, { onProgress }) => {
        const results = []

        await asyncForEach(this.state.selection, async ({ type = 'file', id, name, extension, signing }) => {
            let ok = false
            let response = null
            let fileName = `${name}.${extension}`

            if(type === 'file') {
                const getDownloadUrl = (signing?.status === 'closed') ?
                    this.getSignedDocumentDownloadUrl :
                    this.getDocumentDownloadUrl

                const result = await get({
                    path: getDownloadUrl(id),
                    binary: true
                })

                ok = result.ok
                response = result.response
            }

            if(type === 'smart') {
                const result = await new Promise((resolve, reject) => void this.setState({
                    pdfPortal: createPortal((
                        <SmartProvidedPDFContent
                            id={id}
                            resolve={blob => resolve({ ok: true, response: blob })}
                            reject={() => reject({ ok: false })}
                            key={`pdf:document:smart:${id}`} />
                    ), document.getElementById('off-screen'))
                }))

                this.setState({ pdfPortal: null })

                ok = result.ok
                response = result.response
                fileName = `${name}.pdf`
            }

            results.push({
                ok,
                response,
                status: ok ? 'fulfilled' : 'rejected' // Mock Promise.allSettled
            })

            if(ok && response) {
                saveAs(response, fileName)
                return void onProgress('succeeded')
            }

            onProgress('failed')
        })

        return results
    }

    deleteSelection = (body, { onProgress }) => {
        return Promise.allSettled(this.getEditableSelection().map(async dokument => {
            return this.removeDocument(dokument.id, {
                onProgress: success => onProgress(!!success ?
                    'succeeded' :
                    'failed'
                )
            })
        }))
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ dokuments }) => {
        this.flash.current = null

        return {
            dokuments: [...dokuments]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <DocumentsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
                {this.state.pdfPortal}
            </DocumentsContext.Provider>
        )
    }
}

const sortingOptions = {
    uploadedAt: 'desc',
    name: 'asc'
}

const sortingDefaults = () => ({
    by: Object.keys(sortingOptions)[0],
    direction: Object.values(sortingOptions)[0]
})

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

const getFetchFilter = filter => {
    filter = { ...filter }

    if(!!filter?.access?.length) {
        filter.access = [...filter.access.map(({ id }) => id)]
    }

    return filter
}

const sortUnits = units => units
    .map(unit => {
        if(typeof unit === 'string') {
            return unit
        }

        return unit?.id ?? unit?.type
    }).toSorted()

export const useDocuments = () => useContext(DocumentsContext)

export default props => {
    const { checkFeature } = useAccess()
    const smartDocumentsAvailable = checkFeature('smart-documents')

    const { organization } = useOrganization()
    const { isItMyOwnId } = useMe()

    return (
        <DocumentsProvider
            {...props}
            filter={{
                types: compactArray([
                    'file',
                    smartDocumentsAvailable && 'smart'
                ]),
                ...(props.filter ?? null)
            }}
            smartDocumentsAvailable={smartDocumentsAvailable}
            organization={organization}
            isItMyOwnId={isItMyOwnId} />
    )
}