import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { isofy } from 'utilities/date-time'
import { getFractionDigits } from 'pages/people/pages/profile/panes/about/salary/utilities'
import { Card, CardTitle, CardValue } from './s'
import Trend from '../trend'

const AverageIncreaseTrend = ({ initialRecord, finalRecord, currencyDisplay }) => {
    const from = isofy(initialRecord.fromDate)
    const to = finalRecord.fromDate ? isofy(finalRecord.fromDate) : new Date()

    const fromYear = from.getFullYear()
    const toYear = to.getFullYear()

    const totalDifference = finalRecord.amount - initialRecord.amount
    const averageAnnualChange = totalDifference / (toYear - fromYear)

    if(fromYear >= toYear || !initialRecord || !finalRecord || initialRecord?.id === finalRecord?.id || averageAnnualChange === 0) {
        return null
    }

    const averageAnnualChangePercentage = averageAnnualChange / initialRecord.amount * 100

    const increaseTrend = averageAnnualChange > 0 ? 'up' : 'down'

    return (
        <Card className={increaseTrend}>
            <CardTitle className="compact">
                <FormattedMessage
                    id="salary_statistics_average"
                    defaultMessage="Average annual change" />
            </CardTitle>
            <Trend
                percentage={averageAnnualChangePercentage}
                trend={increaseTrend} />
            <CardValue className="compact">
                <span className="value">
                    <FormattedNumber
                        value={averageAnnualChange}
                        style="currency"
                        currency={initialRecord.currency}
                        currencyDisplay={currencyDisplay}
                        minimumFractionDigits={getFractionDigits(averageAnnualChange)}
                        maximumFractionDigits={getFractionDigits(averageAnnualChange)} />
                </span>
            </CardValue>
        </Card>
    )
}

export default AverageIncreaseTrend
