import React from 'react'
import { usePerson } from 'contexts/person'
import { size } from 'utilities/object'
import Ongoing from './ongoing'
import Start from './start'

const Process = ({ type, withinQuota, fetching, date, startable, stats, id, hasFetched, salt }) => {
    const { person } = usePerson()

    if(!person || fetching || !hasFetched) {
        return null
    }

    if(!!size(stats)) {
        return (
            <Ongoing
                type={type}
                id={id}
                stats={stats} />
        )
    }

    if(startable) {
        return (
            <Start
                type={type}
                date={date}
                withinQuota={withinQuota}
                salt={salt} />
        )
    }

    return null
}

export default Process