import React, { Component, createContext, useContext } from 'react'

const DataContext = createContext()

class DataProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            setData: this.setData,
            unsetData: this.unsetData
        }
    }

    setData = update => void this.setState(({ data }) => ({
        data: {
            ...data,
            ...update
        }
    }))

    unsetData = () => void this.setState({ data: null })

    render() {
        const { children = null } = this.props

        return (
            <DataContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </DataContext.Provider>
        )
    }
}

export const useData = () => useContext(DataContext)

export default DataProvider