import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { UserPermissionsProvider, useUserPermissions } from 'contexts/unit-permissions'
import { getFullName } from 'utilities/person'
import { getPeopleProfileUrl } from 'utilities/url'
import { showJubileeGift } from 'pages/people/utilities'
import {
    Wrapper,
    UserLink, Avatar, MobileAvatar, Name,
    Type, Emoji,
    GiftButton, DateCell
} from './s'
import { dateFormatter } from 'components/date-range'
import { Gift } from 'styled-icons/feather'

const Jubilee = ({ jubilee, isToday = false, organization, setPhoneNumber, settingPhoneNumber }) => {
    const { formatMessage } = useIntl()

    const {
        dateLocale,
        dateLocaleName
    } = useI18n()

    const { check: checkAccess } = useAccess()
    const { checkUnitPermission } = useUserPermissions()

    const usersManageAccess = checkAccess('users:manage')

    const [phone, setPhone] = useState(jubilee.user?.phone ?? null)
    const [nonNorwegianPhoneSet, setNonNorwegianPhoneSet] = useState(false)

    const {
        user,
        type,
        years,
        date,
        originalDate
    } = jubilee

    const emoji = {
        birthday: 'balloon',
        workAnniversary: 'tada'
    }[type]

    const label = formatMessage({
        id: (type === 'workAnniversary') ?
            (years === 0) ?
                'jubilees_work_anniversary_count_zero' :
                'jubilees_work_anniversary_count' :
            'age_years',
        defaultMessage: (type === 'workAnniversary') ?
            (years === 0) ?
                'First work day at {organization}' :
                '{years, plural, =0 {} =1 {One year at {organization}} other {{years} years at {organization}}}' :
            '{years, plural, =0 {# years} =1 {# year} other {# years}}'
    }, {
        years,
        organization: organization?.name
    })

    const dateFormatted = isToday ?
        null :
        dateFormatter(formatMessage, dateLocale)({
            date,
            fallbackFormatter: date => date.toLocaleDateString(dateLocaleName, {
                month: 'short',
                day: 'numeric',
            })
        })

    const hasNorwegianPhoneNumber = phone?.startsWith('+47')
    const canSetPhoneNumber = checkUnitPermission('user:phone:write')

    return (
        <Wrapper>
            <UserLink to={getPeopleProfileUrl(user)}>
                <Avatar
                    who={user}
                    size={20} />
                <MobileAvatar
                    who={user}
                    size={40} />
                <Name>
                    {getFullName(user)}
                </Name>
                <Type className="compact">
                    <Emoji
                        emoji={emoji}
                        size={16} />
                    <span>{label}</span>
                </Type>
            </UserLink>
            {(!!hasNorwegianPhoneNumber && usersManageAccess) && (
                <GiftButton
                    className="constructive"
                    size="small"
                    icon={Gift}
                    onClick={() => showJubileeGift({
                        ...user,
                        phone,
                        ...(type === 'birthday' ? { birthday: originalDate } : null)
                    })}>
                    <FormattedMessage
                        id="person_action_send_gift"
                        defaultMessage="Send a gift" />
                </GiftButton>
            )}
            {(!phone && canSetPhoneNumber && !nonNorwegianPhoneSet && usersManageAccess) && (
                <GiftButton
                    className="constructive"
                    size="small"
                    icon={Gift}
                    onClick={() => setPhoneNumber({
                        user,
                        onDone: phone => {
                            setPhone(phone)

                            if(!phone.startsWith('+47')) {
                                return void setNonNorwegianPhoneSet(true)
                            }

                            showJubileeGift({
                                ...user,
                                phone,
                                ...(type === 'birthday' ? { birthday: originalDate } : null)
                            })
                        }
                    })}
                    disabled={settingPhoneNumber}>
                    <FormattedMessage
                        id="person_action_send_gift"
                        defaultMessage="Send a gift" />
                </GiftButton>
            )}
            <DateCell>
                {dateFormatted}
            </DateCell>
        </Wrapper>
    )
}

export default props => (
    <UserPermissionsProvider id={props.jubilee?.user?.id}>
        <Jubilee {...props} />
    </UserPermissionsProvider>
)