import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcesses } from 'contexts/processes'
import { useAccess } from 'contexts/access'
import { useWithinQuota } from 'hooks/within-quota'
import { useLocationQueryIntent, useLocationStateIntent } from 'hooks/location-intent'
import { getTypeModule } from 'pages/processes/utilities'
import ContextMenu from 'widgets/context-menu'
import { Ghost } from 'components/button'
import { UserPlus, UserCheck } from 'styled-icons/feather'
import { Scrollable as Modal } from 'modals/generic'
import Onboarding from './onboarding'
import Offboarding from './offboarding'
import Process from './process'

const AddProcess = () => {
    const { formatMessage } = useIntl()

    const { type } = useProcesses()

    const [adding, setAdding] = useState(null)
    const [context, setContext] = useState(null)
    const [acting, setActing] = useState(false)

    const { check } = useAccess()

    const access = {
        usersManage: check('users:manage'),
        unitProcessesManage:
            (['onboarding', 'offboarding'].includes(type) && check('unit:employment-lifecycles:manage')) ||
            (type === 'process' && check('unit:processes:manage')),
        unitUsersManage: check('unit:users:manage')
    }

    const [withinQuota] = useWithinQuota({
        key: getTypeModule(type),
        dependencies: [type]
    })

    useLocationStateIntent({
        intent: 'start',
        action: ({ id: concernsId }) => {
            setAdding('predefined')
            setContext({ concernsId })
        },
        requirements: [access.usersManage || access.unitProcessesManage, withinQuota]
    })

    useLocationQueryIntent({
        intent: 'add',
        action: ({ mode = 'add' }) => {
            if(type !== 'onboarding') {
                mode = 'pick'
            }

            setAdding(mode)
        },
        requirements: [access.usersManage || access.unitProcessesManage, withinQuota]
    })

    if(!(access.usersManage || access.unitProcessesManage) || !withinQuota) {
        return null
    }

    const salt = `processes:${type}`

    const onboardingAddNewHireAction = () => ({
        salt: `${salt}:add`,
        icon: <UserPlus size={24} />,
        label: formatMessage({
            id: 'employee_onboarding_action_add_new_hire',
            defaultMessage: 'Add new hire'
        }),
        onClick: () => setAdding('add'),
        effect: 'neutral'
    })

    const onboardingPickExistingUserAction = () => ({
        salt: `${salt}:pick`,
        icon: <UserCheck size={24} />,
        label: formatMessage({
            id: 'employee_onboarding_action_pick_existing_user',
            defaultMessage: 'Pick an existing user'
        }),
        onClick: () => setAdding('pick'),
        effect: 'neutral'
    })

    const canCreateUsers = access.usersManage || access.unitUsersManage

    const actions = ((type === 'onboarding') && canCreateUsers) ? {
        add: onboardingAddNewHireAction,
        pick: onboardingPickExistingUserAction
    } : null

    const buttonProps = {
        onboarding: {
            onClick: () => canCreateUsers ?
                setActing(true) :
                setAdding('pick'),
            children: (
                <FormattedMessage
                    id={canCreateUsers ?
                        'employee_onboarding_action_add_options' :
                        'employee_onboarding_action_add'
                    }
                    defaultMessage={canCreateUsers ?
                        'New onboarding…' :
                        'New onboarding'
                    } />
            )
        },
        offboarding: {
            onClick: () => setAdding('pick'),
            children: (
                <FormattedMessage
                    id="employee_offboarding_action_add"
                    defaultMessage="New offboarding" />
            )
        },
        process: {
            onClick: () => setAdding('pick'),
            children: (
                <FormattedMessage
                    id="processes_action_add"
                    defaultMessage="New process" />
            )
        }
    }[type]

    const Content = {
        onboarding: Onboarding,
        offboarding: Offboarding,
        process: Process
    }[type]

    return (
        <>
            {!actions && (
                <Ghost
                    {...buttonProps}
                    className="constructive"
                    disabled={!!acting} />
            )}
            {!!actions && (
                <ContextMenu
                    salt={`${salt}:add`}
                    actions={actions}
                    trigger={(
                        <Ghost
                            {...buttonProps}
                            className="constructive"
                            disabled={!!acting} />
                    )} />
            )}
            <Modal
                show={!!adding}
                dismiss={() => {
                    setAdding(null)
                    setContext(null)
                }}
                salt={`${salt}:add`}>
                <Content
                    {...context}
                    type={adding}
                    salt={`${salt}:add`} />
            </Modal>
        </>
    )
}

export default AddProcess