import React from 'react'
import loadable from '@loadable/component'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import paths from 'app/paths'
import { compact } from 'utilities/array'
import Hero from './hero'
import { Routes, Route } from 'react-router-dom'
import { TabsAndPanes, getPathWithoutBase } from 'components/tabs'
import RouteAuthorization from 'app/route-authorization'
import DelayedRedirect from 'components/delayed-redirect'

import MePane from './panes/me'
const TypesPane = loadable(() => import(/* webpackPrefetch: true */ './panes/types'))

const TypePage = loadable(() => import(/* webpackPrefetch: true */ './pages/type'))

const salt = 'equipment'

const EquipmentRouting = ({ upgradable }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const adminAccess = check('equipment:manage')

    const { base } = paths.equipment
    const getRelativePath = getPathWithoutBase(base)

    const panes = compact([
        {
            path: base,
            label: formatMessage({
                id: 'equipment_section_your_equipment',
                defaultMessage: 'Your equipment'
            }),
            pane: MePane
        },
        adminAccess && {
            path: paths.equipment.types,
            label: formatMessage({
                id: 'equipment_section_organization',
                defaultMessage: 'All equipment'
            }),
            pane: TypesPane
        }
    ]).map(pane => ({
        ...pane,
        upgradable: pane.upgradable ?? upgradable
     }))

    return (
        <Routes>
            <Route element={(
                <>
                    <Hero
                        upgradable={upgradable}
                        salt={salt} />
                    <TabsAndPanes
                        panes={panes}
                        salt={salt} />
                </>
            )}>
                {panes.map(({ path, pane: Pane, label, upgradable, itemSalt, ...props }) => {
                    const key = `${salt}:pane:${itemSalt ?? label.toLowerCase()}`

                    return (
                        <Route
                            path={getRelativePath(path)}
                            element={(
                                <RouteAuthorization
                                    module="equipment"
                                    upgradable={upgradable}>
                                    <Pane
                                        {...props}
                                        title={label}
                                        salt={salt}
                                        key={key} />
                                </RouteAuthorization>
                            )}
                            key={`${key}:route`} />
                    )
                })}
            </Route>
            <Route
                path={getRelativePath(paths.equipment.type)}
                element={(
                    <RouteAuthorization module="equipment">
                        <TypePage />
                    </RouteAuthorization>
                )} />
            <Route
                path="*"
                element={<DelayedRedirect to={base} />} />
        </Routes>
    )
}

export default EquipmentRouting