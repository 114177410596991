import React from 'react'
import { useIntl } from 'react-intl'
import { useCompetenceRecords } from 'contexts/competence-records'
import { advancedApplied } from 'components/filter/utilities'
import { categories, categoryToMetaMap } from 'pages/competence/constants/category'
import Filter from 'components/filter'

const CompetenceRecordsFilter = props => {
    const { formatMessage } = useIntl()

    const {
        filter,
        setRecordsFilter: setFilter,
        records: items,
        fetching,
        hasFetched
    } = useCompetenceRecords()

    const advancedFields = [
        {
            type: 'checkboxes',
            name: 'categories',
            label: formatMessage({
                id: 'noun_categories',
                defaultMessage: 'Categories'
            }),
            field: {
                options: categories.map(({ name }) => ({
                    id: name,
                    name: formatMessage(categoryToMetaMap[name].name)
                }))
            }
        },
        {
            type: 'checkboxes',
            name: 'status',
            label: formatMessage({
                id: 'noun_status',
                defaultMessage: 'Status'
            }),
            field: {
                options: [
                    {
                        id: 'missing_attachment',
                        name: formatMessage({
                            id: 'qualification_status_missing_documentation',
                            defaultMessage: 'Missing documentation'
                        })
                    },
                    {
                        id: 'missing_valid_to',
                        name: formatMessage({
                            id: 'competence_status_missing_valid_to',
                            defaultMessage: 'Missing expiry date'
                        })
                    },
                    {
                        id: 'expired',
                        name: formatMessage({
                            id: 'qualification_status_expired',
                            defaultMessage: 'Expired'
                        })
                    },
                    {
                        id: 'expiring_soon',
                        name: formatMessage({
                            id: 'qualification_status_expiring_soon',
                            defaultMessage: 'Expiring soon'
                        })
                    },
                    {
                        id: 'missing_required_fields',
                        name: formatMessage({
                            id: 'competence_status_missing_required_fields',
                            defaultMessage: 'Missing required fields'
                        })
                    }
                ]
            }
        }
    ]

    return (
        <Filter
            {...props}
            filter={filter}
            setFilter={setFilter}
            advancedFields={advancedFields}
            items={items}
            fetching={fetching}
            hasFetched={hasFetched} />
    )
}

const advancedNames = ['categories', 'status']

export const areAdvancedFiltersApplied = advancedApplied(advancedNames)

export default CompetenceRecordsFilter