import React from 'react'
import { useIntl } from 'react-intl'
import paths from 'app/paths'
import { usePayment } from 'contexts/payment'
import { useNavigate } from 'react-router-dom'
import { sendAppSignal } from 'hooks/app-signal'
import { ContactSales, PromptLink, Channels, ChannelLink } from './s'
import { TrialCountdown } from 'components/payment/subscription'
import Continue from './continue'
import { /*Phone, */Mail as Email } from 'styled-icons/feather'

const TrialStatus = () => {
    const {
        locale,
        formatMessage
    } = useIntl()

    const {
        account,
        sales
    } = usePayment()

    const navigate = useNavigate()

    const prompt = formatMessage({
        id: 'prompt_contact_sales_or_help_center',
        defaultMessage: 'Contact our experts or <link>open the Help Center</link>'
    }, {
        link: chunks => (
            <PromptLink
                className="neutral"
                onClick={() => sendAppSignal('help.show')}>
                {chunks}
            </PromptLink>
        )
    })

    const salesData = {
        ...(sales?.nb ?? null),
        ...(sales?.[locale] ?? null)
    }

    const cta = (
        <Channels>
            {/* <ChannelLink
                className="constructive"
                href={`tel:${salesData.phone}`}
                onClick={onClick}>
                <Phone size={16} /> {salesData.phone}
            </ChannelLink> */}
            <ChannelLink
                className="constructive"
                href={`mailto:${salesData.email}`}>
                <Email size={16} />
                {salesData.email}
            </ChannelLink>
        </Channels>
    )

    if(!account?.subscription?.trial) {
        return (
            <ContactSales
                prompt={prompt}
                cta={cta}
                salt="service-onboarding" />
        )
    }

    return (
        <ContactSales
            prompt={prompt}
            cta={cta}
            salt="trial:status">
            <TrialCountdown
                subscription={account.subscription}
                onClick={() => navigate(paths.settings.subscription.base)}
                showBar={false} />
            <Continue subscription={account.subscription} />
        </ContactSales>
    )
}

export default TrialStatus