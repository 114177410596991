import styled from 'styled-components'
import MessageBase from 'components/message'
import LinkBase from 'components/link'
import Caption from 'components/typography/caption'

export const Hero = styled.div`
    > :last-child {
        margin-block-end: 16px;
    }
`

export const For = styled(Caption)`
    span {
        color: var(--huma-color-foreground-default);
    }
`

export const PickerMeta = styled.div`
    padding-block-start: 8px;
`

export const Message = styled(MessageBase)`
    margin-block: 16px 0;
`

export const Link = styled(LinkBase)`
    text-decoration: underline;
`