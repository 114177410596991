import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { MenuItemLink } from './'

const moduleIdentifier = 'dashboard'

const Dashboard = props => {
    const {
        initialized,
        checkModule
    } = useAccess()

    const access = initialized && checkModule(moduleIdentifier)

    return (
        <MenuItemLink
            {...props}
            to="root" end
            module={moduleIdentifier}
            access={access}
            upgradable={false}>
            <FormattedMessage
                id="noun_dashboard"
                defaultMessage="Dashboard" />
        </MenuItemLink>
    )
}

export default Dashboard