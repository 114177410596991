import React from 'react'
import { useAccess } from 'contexts/access'
import { Wrap } from './s'
import Language from './language'
import WeekStartsOn from './week-starts-on'
import BirthdayJubileeVisibility from './birthday-jubilee-visibility'

const GeneralPaneContent = ({ salt }) => {
    const { checkFeature } = useAccess()
    const jubileesAvailable = checkFeature('jubilees')

    return (
        <Wrap>
            <Language salt={salt} />
            <WeekStartsOn
                {...(!jubileesAvailable ? { className: 'compact' } : null)}
                salt={salt} />
            {jubileesAvailable && (
                <BirthdayJubileeVisibility
                    className="compact"
                    salt={salt} />
            )}
        </Wrap>
    )
}

export default GeneralPaneContent