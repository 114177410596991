import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { endOfDay } from 'date-fns'
import { useAccess } from 'contexts/access'
import { isofy } from 'utilities/date-time'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { useGetErrorOrWarning } from 'pages/competence/utilities'
import { size } from 'utilities/object'
import { getFullName } from 'utilities/person'
import {
    Wrapper, Row,
    Name, DefinitionList, DefinitionTerm, DefinitionDescription,
    InlineMessage
} from './s'
import { CaptionSmall } from 'components/typography/caption'
import ProgressCircle from 'components/progress-circle'
import ExpiryStatus from 'components/expiry-status'

const CustomTooltip = ({ type, target, value, record, person }) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const getErrorOrWarning = useGetErrorOrWarning()

    const {
        attachments = [],
        links = [],
        startDate,
        endDate,
        validFrom,
        validTo
    } = record ?? {}

    const {
        category,
        documentationRequired = false,
        validToRequired = false
    } = type

    let fromDate = category === 'certification' ? validFrom : startDate
    let toDate = category === 'certification' ? validTo : endDate

    fromDate = fromDate ? isofy(fromDate) : null
    toDate = toDate ? endOfDay(isofy(toDate)) : null

    const missingDocumentation = (!attachments?.length && !links?.length && documentationRequired)

    return (
        <Wrapper>
            <Row {...(
                !(record && ['skill', 'language'].includes(category)) ?
                    { className: 'no-border' } :
                    null
            )}>
                <Name className="compact">{categoryFormatter(type)}</Name>
                {(record && ['skill', 'language'].includes(category)) && (
                    <DefinitionList>
                        <DefinitionTerm>
                            <FormattedMessage
                                id="competence_gap_label_current_level"
                                defaultMessage="Current level" />
                        </DefinitionTerm>
                        <DefinitionDescription>
                            {value ?? '-'}
                        </DefinitionDescription>
                        <DefinitionTerm>
                            <FormattedMessage
                                id="competence_gap_label_target"
                                defaultMessage="Target" />
                        </DefinitionTerm>
                        <DefinitionDescription>
                            {target}
                        </DefinitionDescription>
                    </DefinitionList>
                )}
            </Row>
            <Row>
                {record && (
                    <>
                        {(['skill', 'language'].includes(category) && value && target > value) && (
                            <DefinitionList>
                                <DefinitionTerm>
                                    <ProgressCircle
                                        progress={value / target * 100}
                                        size={16}
                                        strokeWidth={2} />
                                    <span>
                                        <FormattedMessage
                                            id="competence_gap_label_gap"
                                            defaultMessage="Gap" />
                                    </span>
                                </DefinitionTerm>
                                <DefinitionDescription>
                                    {target - value}
                                </DefinitionDescription>
                            </DefinitionList>
                        )}
                        {(
                            (
                                !['skill', 'language'].includes(category) ||
                                (['skill', 'language'].includes(category) && target <= value)
                            ) &&
                            !size(getErrorOrWarning(record))
                        ) && (
                            <InlineMessage
                                type="success"
                                className="compact"
                                message={formatMessage({
                                    id: 'competence_status_no_gap',
                                    defaultMessage: 'No gap'
                                })} />
                        )}
                        {(['skill', 'language'].includes(category) && !value) && (
                            <InlineMessage
                                type="warning"
                                className="compact"
                                message={formatMessage({
                                    id: 'competence_status_missing_level',
                                    defaultMessage: 'Missing level'
                                })} />
                        )}
                        {(!toDate && validToRequired) && (
                            <InlineMessage
                                message={formatMessage({
                                    id: 'competence_error_missing_expiry_date',
                                    defaultMessage: 'No expiry date'
                                })}
                                type="error" />
                        )}
                        {(category === 'certification') && (
                            <ExpiryStatus
                                from={fromDate}
                                to={toDate}
                                showActive={false}
                                className="compact"
                                context="competence" />
                        )}
                        {missingDocumentation && (
                            <InlineMessage
                                type="error"
                                className="compact"
                                message={formatMessage({
                                    id: 'qualification_missing_documentation',
                                    defaultMessage: 'Missing documentation'
                                })}
                                size="small" />
                        )}
                    </>
                )}
                {!record && (
                    <InlineMessage
                        type="caution"
                        className="compact"
                        message={formatMessage({
                            id: 'competence_status_not_added',
                            defaultMessage: 'Not added yet'
                        })} />
                )}
            </Row>
            {(record || manageAccess) && (
                <Row>
                    <CaptionSmall className="compact">
                        <FormattedMessage
                            id={record ?
                                'competence_action_view_instructions' :
                                'competence_action_add_to_instructions'
                            }
                            defaultMessage={record ?
                                'Click to view details' :
                                'Click to add ”{competence}” to {name}'
                            }
                            values={{
                                competence: categoryFormatter(type),
                                name: getFullName(person)
                            }} />
                    </CaptionSmall>
                </Row>
            )}
        </Wrapper>
    )
}

export default CustomTooltip
