import styled from 'styled-components'
import InstructionsBase from 'components/instructions'
import FileBase from 'components/file'
import Message from 'components/message'

export const Instructions = styled(InstructionsBase)`
    border-bottom: none;
    margin: 0;

    & > :first-child {
        padding-top: 0;
        border-top: none;
    }
`

export const File = styled(FileBase)`
    margin: 16px 0 0;
`

export const AnalyzeMessage = styled(Message)`
    margin: 16px 0 0;
`