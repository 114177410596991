import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompetenceRecords } from 'contexts/competence-records'
import { size, omit } from 'utilities/object'
import { categories, categoryToMetaMap } from 'pages/competence/constants/category'
import {
    Wrapper,
    Category, Header, Heading,
    Tutorial
} from './s'
import Loader from 'components/loader'
import Empty from 'components/empty'
import Caption from 'components/typography/caption'
import CompetenceRecords from 'lists/competence/records'

const CompetenceCategoryGroups = ({ actions }) => {
    const {
        records = [],
        hasFetched,
        fetching,
        filter = {}
    } = useCompetenceRecords()

    const recordsByCategory = records.reduce((accumulator, record) => {
        const category = record.type.category

        if(accumulator[category]) {
            accumulator[category].push(record)
            return accumulator
        }

        accumulator[category] = [record]
        return accumulator
    }, {})

    const filteredCategories = categories.filter(({ name }) => !!recordsByCategory?.[name]?.length)

    if(!hasFetched) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        )
    }

    const filtersApplied = !!size(omit(filter, 'concerns'))

    return (
        <Wrapper>
            {filteredCategories.map(({ name }) => (
                <Category
                    id={name}
                    key={`competence:category:${name}`}>
                    <Header>
                        <Heading>
                            <FormattedMessage {...categoryToMetaMap[name].name} />
                        </Heading>
                    </Header>
                    <CompetenceRecords
                        records={recordsByCategory?.[name] ?? []}
                        actions={actions} />
                </Category>
            ))}
            {(!filteredCategories?.length && hasFetched && !fetching && !filtersApplied) && (
                <Tutorial which="competence" />
            )}
            {(!filteredCategories.length && hasFetched && !fetching && !!filtersApplied) && (
                <Empty which="competence">
                    <Caption className="compact">
                        <FormattedMessage
                            id="competence_empty_filter_message"
                            defaultMessage="🤷 Sorry, no competences found." />
                    </Caption>
                </Empty>
            )}
        </Wrapper>
    )
}

export default CompetenceCategoryGroups
