import { useState, useEffect } from 'react'
import { useEnvironment } from 'contexts/environment'
import { useSegment } from 'contexts/segment'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { useMe } from 'contexts/me'
import { getAnonymousId } from 'utilities/person'
import { identifyAndSend } from 'utilities/hubspot'
import { capitalize } from 'utilities/string'

export const useIdentification = () => {
    const { integrations } = useEnvironment()
    const segment = useSegment()
    const { currentBundle } = useAccess()
    const { organization } = useOrganization()
    const { me } = useMe()

    const [identified, setIdentified] = useState(false)

    useEffect(() => {
        if(!identified && integrations && currentBundle && organization && me) {
            setIdentified(true)

            if(integrations.enableHubspotContactRegistration) {
                identifyAndSend({
                    id: organization.id,
                    huma_webclient_organization_name: organization.name,
                    huma_webclient_user_count: organization.userCount,
                    huma_webclient_plan: capitalize(currentBundle.bundle),
                    salt: Date.now()
                })
            }

            segment.identify(getAnonymousId(me))
            segment.group(organization.id)
        }

    }, [identified, integrations, currentBundle, organization, me])
}