import styled from 'styled-components'
import { Button } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import { captionCss } from 'components/typography/caption'

export const Email = styled.div`
    margin-block-start: 12px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-low);
`

export const EmailHeader = styled.div`
    border-bottom: 1px solid var(--huma-color-border-default);
    padding: 24px;
`

export const EmailContent = styled.div`
    padding: 24px 24px 0;
`

export const Centered = styled.div`
    text-align: center;

    button {
        margin-inline: auto;
    }
`

export const Variable = styled.span`
    display: inline-block;

    background-color: var(--huma-color-surface-success-minimal);

    ${EmailHeader} & {
        border-radius: 10px;
        height: 20px;
        padding-inline: 6px;
    }

    ${EmailContent} & {
        border-radius: 12px;
        height: 24px;
        padding-inline: 8px;
    }
`

export const SavedMessage = styled.div`
    margin-block-end: 16px;
`

export const Clamp = styled(Paragraph)`
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`

export const SavedMessageText = styled.span`
    ${captionCss}
`

export const DisabledConstructiveButton = styled(Button).attrs(() => ({
    autoFocus: false,
    tabIndex: -1,
    className: 'constructive'
}))`
    pointer-events: none;

    margin-block-end: 24px;
`