import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import HandbookProvider from 'contexts/handbook'
import { useAppSignal } from 'hooks/app-signal'
import { usePinnedTopics } from 'pages/handbook/utilities'
import { Link } from './s'
import { HeroActions, Heading } from 'pages/dashboard/s'
import Topics from 'pages/handbook/components/pinned-topics'

const DashboardHandbook = () => {
    const { pinnedTopics = [] } = usePinnedTopics({ fetch: true })

    const [configuringDashboard, setConfiguringDashboard] = useState(false)

    useAppSignal({
        namespace: 'dashboard:configure.toggle',
        action: setConfiguringDashboard
    })

    if(!pinnedTopics.length) {
        if(configuringDashboard) {
            return (
                <HeroActions>
                    <Heading>
                        <FormattedMessage
                            id="handbooks_heading_pinned_topics"
                            defaultMessage="Featured topics" />
                    </Heading>
                </HeroActions>
            )
        }

        return null
    }

    const salt = 'dashboard:handbook-pinned-topics'

    return (
        <>
            <Topics salt={salt} />
            <Link
                to="path:handbook.base"
                className="constructive">
                <FormattedMessage
                    id="handbooks_action_navigate"
                    defaultMessage="Go to Handbook" />
            </Link>
        </>
    )
}

export default props => (
    <HandbookProvider fetchOnMount={false}>
        <DashboardHandbook {...props} />
    </HandbookProvider>
)