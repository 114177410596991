import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { getShortName } from 'utilities/person'
import { Wrapper, Strong } from './s'
import { CaptionSmall } from 'components/typography/caption'
import { Plain } from 'components/button'

const name = ({ type, value }) => {
    switch(type) {
        case 'person': return getShortName(value)
        case 'group': return value?.name
        default: return ''
    }
}

const PreviewingStatus = ({ setPreviewing }) => {
    const { viewingAs } = useHandbook()

    if(!viewingAs) {
        return null
    }

    return (
        <Wrapper>
            <CaptionSmall className="compact">
                <FormattedMessage
                    id={`handbooks_preview_status_${viewingAs.type}`}
                    defaultMessage="Viewing all content"
                    values={{
                        name: name(viewingAs),
                        strong: chunks => <Strong>{chunks}</Strong>
                    }} />
            </CaptionSmall>
            <Plain
                className="constructive"
                size="small"
                onClick={() => setPreviewing(true)}>
                <FormattedMessage
                    id="action_change"
                    defaultMessage="Change" />
            </Plain>
        </Wrapper>
    )
}

export default PreviewingStatus
