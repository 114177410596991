export const doNowOrWhenVisible = callback => {
    if(document.visibilityState === 'visible') {
        callback()
        return
    }

    const handleVisibilityChange = () => {
        if(document.visibilityState === 'visible') {
            callback()
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
}