import React from 'react'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useIntegrations } from 'contexts/integrations'
import { Rows, Features } from './s'
import Message from 'components/message'

const DowngradePrompt = ({ from, to, salt }) => {
    const { formatMessage } = useIntl()

    const {
        bundlesByTrack,
        getBundle
    } = useAccess()

    const freeBundle = getBundle('free', 'standard')

    const { checkHasActiveConnectionToAnyIntegration } = useIntegrations()
    const isConnectedToAnyIntegration = checkHasActiveConnectionToAnyIntegration()

    const fromLevel = bundlesByTrack?.[from.track]?.findIndex(bundle => bundle.bundle === from.bundle)
    const toLevel = bundlesByTrack?.[from.track]?.findIndex(bundle => bundle.bundle === to.bundle)
    const range = bundlesByTrack?.[from.track].slice(toLevel + 1, fromLevel + 1)
    let losing = range.flatMap(({ features }) => features?.tier ?? [])

    if(!!from?.perks?.length) {
        losing = losing.concat(from.perks)
    }

    return (
        <>
            <Rows>
                <Features
                    features={losing}
                    bad={true}
                    size={16}
                    salt={`${salt}:features:losing`} />
            </Rows>
            {(isConnectedToAnyIntegration && to === freeBundle) && (
                <Message
                    type="warning"
                    className="compact"
                    message={formatMessage({
                        id: 'payment_plan_warning_losing_integration',
                        defaultMessage: 'Any active integrations will be disconnected and deactivated'
                    })} />
            )}
        </>
    )
}

export default DowngradePrompt