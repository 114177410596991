import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getPeopleLocationUrl, getPeopleTeamUrl } from 'utilities/url'
import {
    Link,
    Name, Grants
} from './s'
import { Team } from 'components/feather'
import {
    MapPin as Location,
    ArrowRight
} from 'styled-icons/feather'
import Ellipsify from 'components/ellipsify'

const Group = ({ group }) => {
    const {
        id,
        type,
        name
    } = group

    const getGroupUrl = typeMap[type].getGroupUrl
    const Icon = typeMap[type].Icon

    return (
        <Link to={getGroupUrl({ id })}>
            <Icon size={24} />
            <Name className="compact">
                <Ellipsify
                    text={name}
                    head={5} />
            </Name>
            <Grants className="compact interpoint-divider">
                <span>
                    <FormattedMessage
                        id="noun_member"
                        defaultMessage="Member" />
                </span>
            </Grants>
            <ArrowRight
                className="arrow"
                size={24} />
        </Link>
    )
}

export default Group

const typeMap = {
    team: {
        getGroupUrl: getPeopleTeamUrl,
        Icon: props => <Team {...props} />
    },
    location: {
        getGroupUrl: getPeopleLocationUrl,
        Icon: props => <Location {...props} />
    }
}
