import { compact, first, last } from 'utilities/array'
import { has, reduce, pick } from 'utilities/object'
import { isofy } from 'utilities/date-time'
import { capitalize } from 'utilities/string'
import { emailAddressPattern } from 'utilities/regex'
import { isLeapYear, isSameYear } from 'date-fns'
import sha256 from 'crypto-js/sha256'

export const getAnonymousId = person => person?.id ?
    sha256(`${person.id}taco-tequila-talk-target-tournament-thursday`).toString() :
    null

export const unpackField = (field, fallback = true) => {
    if(has(field, 'value')) {
        return field?.value ?? null
    }

    return (fallback || (!!field && typeof field !== 'object')) ?
        field :
        null
}

export const unpackStringField = field => {
    if(!field) {
        return field
    }

    return ((typeof field === 'string') ?
        field :
        field.value) ?? null
}

export const unpackObjectField = field => field?.value ?? field

export const getCoverImageUrl = person => person?.coverImageUrl ?
    unpackStringField(person.coverImageUrl) :
    null

export const getAvatarUrl = person => {
    let url = null

    if(person?.avatarUrl) {
        url = unpackField(person.avatarUrl)
    }

    if(person?.avatarImage) {
        url = unpackField(person.avatarImage)?.url
    }

    return url
}

export const getGivenName = person => person?.givenName ?
    unpackStringField(person.givenName) :
    null

export const getPreferredName = person => person?.preferredName ?
    unpackStringField(person.preferredName) :
    null

export const getFamilyName = person => person?.familyName ?
    unpackStringField(person.familyName) :
    null

export const getFullName = (person = {}, options = {}) => {
    const {
        fallback = true,
        includePreferredName = false
    } = options

    const fullNameParts = compact([
        getGivenName(person),
        includePreferredName && getPreferredName(person),
        getFamilyName(person)
    ])

    if(fullNameParts.length) {
        return fullNameParts.join(' ')
    }

    if(!fallback) {
        return null
    }

    return getEmailAddress(person) || getPhoneNumber(person) || person?.id
}

export const getFullNameReversed = (person = {}) => {
    const fullNameParts = compact([
        getFamilyName(person),
        getGivenName(person)
    ])

    if(fullNameParts.length) {
        return fullNameParts.join(' ')
    }

    return ''
}

export const getShortName = (person = {}) =>
    getPreferredName(person) ||
    getGivenName(person) ||
    getEmailAddress(person) ||
    getPhoneNumber(person) ||
    person?.id

export const getJobTitle = person => person?.jobTitle ?
    unpackObjectField(person.jobTitle) :
    null

export const getJobDescription = person => {
    if(!person) {
        return null
    }

    const jobTitle = getJobTitle(person)
    const jobDescription = unpackStringField(person.jobDescription)

    return jobTitle?.description ?? jobDescription ?? null
}

export const getPhoneNumber = person => person?.phone ?
    unpackStringField(person.phone) :
    null

export const getEmailAddress = (person, which = 'email') => person?.[which] ?
    unpackStringField(person[which]) :
    null

export const parseRawInput = rawPrompt => {
    if(typeof rawPrompt !== 'string' || !rawPrompt?.split(' ')?.length) {
        return null
    }

    rawPrompt = rawPrompt.split(' ')
    let email

    // Check if any of the parts is an email address
    const emailIndex = rawPrompt.findIndex(part => emailAddressPattern.test(part))

    if(emailIndex > -1) {
        email = rawPrompt[emailIndex]
        rawPrompt.splice(emailIndex, 1)
    }

    // Count the rest as name parts
    let [familyName, ...givenNamesReversed] = rawPrompt.reverse().map(capitalize)
    let givenName

    if(!!rawPrompt.length) {
        // If no given names were found, assume the first part is the given name
        if(!givenNamesReversed?.length) {
            givenName = familyName
            familyName = null
        } else {
            givenName = givenNamesReversed.reverse().join(' ')
        }
    }

    return { givenName, familyName, email }
}

export const getListRepresentationFromProfile = (person, fallback = true) => {
    const paddedFields = [
        'id',
        'givenName', 'preferredName', 'familyName',
        'email', 'phone',
        'avatarUrl',
        'jobTitle',
        'teams', 'locations'
    ]

    const unpaddedFields = ['status']

    const unpacked = reduce(person, (accumulator, field, key) => ({
        ...accumulator,
        [key]: unpackField(field, fallback)
    }), {})

    return {
        ...pick(unpacked, ...paddedFields),
        ...pick(person, ...unpaddedFields),
        type: 'user'
    }
}

const isLeapYearsDay = date => (date.getMonth() === 1 && date.getDate() === 29)
const isReplacementLeapYearsDay = date => (date.getMonth() === 1 && date.getDate() === 28)

const isSameDay = (then, now) => {
    const sameMonth = then.getMonth() === now.getMonth()
    const sameDay = then.getDate() === now.getDate()

    return sameMonth && sameDay
}

export const isJubilee = then => {
    if(!then) {
        return false
    }

    then = isofy(then)
    const leapYearThen = isLeapYear(then)

    const now = new Date()
    const leapYearNow = isLeapYear(now)

    if(isSameYear(then, now)) {
        return false
    }

    if(!leapYearThen || (leapYearThen === leapYearNow)) {
        return isSameDay(then, now)
    }

    if(leapYearThen && !leapYearNow) {
        if(isLeapYearsDay(then) && isReplacementLeapYearsDay(now)) {
            return true
        }

        return isSameDay(then, now)
    }

    return false
}


const combinations = [
    ['var(--huma-color-person-4)', 'var(--huma-color-person-2)'],
    ['var(--huma-color-person-4)', 'var(--huma-color-person-3)'],
    ['var(--huma-color-person-7)', 'var(--huma-color-person-5)'],
    ['var(--huma-color-person-6)', 'var(--huma-color-person-9)'],
    ['var(--huma-color-person-4)', 'var(--huma-color-person-7)'],
    ['var(--huma-color-person-8)', 'var(--huma-color-person-5)'],
    ['var(--huma-color-person-1)', 'var(--huma-color-person-5)'],
    ['var(--huma-color-person-6)', 'var(--huma-color-person-9)'],
    ['var(--huma-color-person-3)', 'var(--huma-color-person-6)'],
    ['var(--huma-color-person-9)', 'var(--huma-color-person-5)'],
    ['var(--huma-color-person-6)', 'var(--huma-color-person-7)'],
    ['var(--huma-color-person-1)', 'var(--huma-color-person-9)'],
    ['var(--huma-color-person-1)', 'var(--huma-color-person-4)'],
    ['var(--huma-color-person-9)', 'var(--huma-color-person-6)'],
    ['var(--huma-color-person-7)', 'var(--huma-color-person-6)'],
    ['var(--huma-color-person-9)', 'var(--huma-color-person-7)'],
    ['var(--huma-color-person-3)', 'var(--huma-color-person-5)'],
    ['var(--huma-color-person-8)', 'var(--huma-color-person-6)']
]

const anonymousColors = ['var(--huma-color-person-anonymous-surface)', 'var(--huma-color-person-anonymous-foreground)']

export const getColors = uuid => {
    if(['anonymous', 'unspecified'].includes(uuid)) {
        const [background, color] = anonymousColors
        return { background, color }
    }

    const numericString = uuid
        .split('-')[0]
        .split('')
        .map(alphanumeric => {
            let number = parseInt(alphanumeric, 10)

            if(isNaN(number)) {
                number = alphanumeric.charCodeAt(0)
            }

            return number
        })
        .join('')
        .substring(0, 15)

    const index = parseInt(numericString, 10) % combinations.length
    const [background, color] = combinations[index]

    return { background, color }
}

export const getAvatarFallback = person => {
    if(!person?.id) {
        return {}
    }

    const { background, color } = getColors(person.id)

    const names = getFullName(person).split(' ')
    let initials

    if(person.id === 'anonymous') {
        initials = first(names).charAt(0)
    } else {
        initials = `${first(names).charAt(0)}${last(names).charAt(0)}`
    }

    initials = initials.toUpperCase()

    return {
        background,
        color,
        initials
    }
}