import { useState, useLayoutEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { size } from 'utilities/object'

export const useEmailVerification = () => {
    const [data, setData] = useState(null)
    const [urlSearchParams, setUrlSearchParams] = useSearchParams()

    const searchParams = Object.fromEntries(urlSearchParams)

    useLayoutEffect(() => {
        if(size(searchParams)) {
            const emailVerification = searchParams['email-verification']

            if(emailVerification) {
                setData(JSON.parse(emailVerification))
                setUrlSearchParams({}, { replace: true })
            }
        }
    }, [JSON.stringify(searchParams)])

    return data
}