import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePageView } from 'hooks/page-view'
import { useLogin } from 'contexts/login'
import { size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { FlexChildShrink } from 'components/flex'
import { Step, Rows, Fields, Heading, Actions } from '../s'
import Form from 'components/form/controller'
import HiddenField from 'components/form/field/hidden'
import StringField from 'components/form/field/string'
import { ButtonSubmit, BackButton } from 'components/button'

const TwoForgot = ({ show, ...props }) => {
    const { formatMessage } = useIntl()

    usePageView('login_forgot_huma_domain', [show])

    const {
        findKey,
        loading,
        goToPreviousStep
    } = useLogin()

    const salt = 'login:2:forgot'

    return (
        <Step
            {...props}
            show={show}>
            <Form
                layout="vertical"
                onSubmit={findKey}>
                {({ touched, errors, trigger }) => (
                    <Rows>
                        <Fields>
                            <Heading>
                                <FormattedMessage
                                    id="login_two_forgot_heading"
                                    defaultMessage="Can’t remember your Huma domain?" />
                            </Heading>
                            <HiddenField
                                salt={salt}
                                name="method"
                                field={{ value: 'email' }} />
                            <StringField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_emailaddress_your',
                                    defaultMessage: 'Your email address'
                                })}
                                name="email"
                                field={{ required: true }}
                                controlProps={{
                                    type: 'email',
                                    autoComplete: 'email',
                                    'data-1p-ignore': 'false',
                                    autoFocus: true
                                }} />
                        </Fields>
                        <FlexChildShrink>
                            <Actions className="spread">
                                <BackButton
                                    onClick={goToPreviousStep}
                                    text={formatMessage({
                                        id: 'action_go_back',
                                        defaultMessage: 'Go back'
                                    })} />
                                <ButtonSubmit
                                    className={cls([
                                        'constructive',
                                        loading && 'loading'
                                    ])}
                                    disabled={!touched.length || !!size(errors) || loading}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id="action_send_information"
                                        defaultMessage="Send information" />
                                </ButtonSubmit>
                            </Actions>
                        </FlexChildShrink>
                    </Rows>
                )}
            </Form>
        </Step>
    )
}

export default TwoForgot
