import styled from 'styled-components'
import { fromTablet } from 'utilities/styled'
import Paragraph, { captionSmallCss } from 'components/typography/paragraph'

export const FieldHelper = styled(Paragraph)`
    ${captionSmallCss}

    margin-block: -8px 24px;

    ${fromTablet`
        margin-inline-start: 200px;
    `}
`