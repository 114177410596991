import React from 'react'
import { useEnrichedActions } from 'widgets/context-menu'
import { ContextMenu, ChecklistActionButton, Checklistbox } from './s'

const ViewTaskActions = ({ actions, context, onToggleCompleted, dismiss, salt }) => {
    const { completedAt } = context

    actions = useEnrichedActions({
        namedActions: actions,
        context,
        onAfter: {
            toggleCompleted: onToggleCompleted,
            remove: dismiss
        },
        include: ['edit', 'toggleCompleted', 'addToCalendar', 'createMeeting', 'remove']
    })

    if(actions.toggleCompleted) {
        actions.toggleCompleted.as = ChecklistActionButton

        actions.toggleCompleted.icon = (
            <Checklistbox
                checked={!!completedAt}
                readOnly={true} />
        )
    }

    return (
        <ContextMenu
            prebound actions={actions}
            salt={salt} />
    )
}

export default ViewTaskActions
