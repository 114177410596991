import React, { useRef, useState, useEffect, Fragment } from 'react'
import { useI18n } from 'contexts/i18n'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEquipmentPieces } from 'contexts/equipment-pieces'
import EquipmentTypeProvider, { useEquipmentType } from 'contexts/equipment-type'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import PubSub from 'pubsub-js'
import { size, omit } from 'utilities/object'
import { ModalHeader } from 'modals/generic'
import Loader from 'components/loader'
import Form from 'components/form/controller'
import EntityField from 'components/form/field/entity'
import { PersonField, DocumentationExplainer } from './s'
import TextField from 'components/form/field/text'
import TimeField from 'components/form/field/time'
import StringField from 'components/form/field/string'
import CheckboxField from 'components/form/field/checkbox'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const EditEquipmentPiece = ({
    piece,
    type: outsideType = null,
    context = 'types',
    user,
    onDone,
    focus = null,
    setActing,
    modal,
    salt
}) => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()

    const typeRef = useRef()
    const assignedToRef = useRef()
    const fromDateRef = useRef()
    const toDateRef = useRef()

    const {
        filter,

        addPiece,
        updatePiece
    } = useEquipmentPieces()

    const {
        me,
        isItMyOwnId
    } = useMe()

    const { check } = useAccess()
    const manageAccess = check('equipment:manage')

    const [triggered, setTriggered] = useState(false)
    const [type, setType] = useState(piece?.type ?? outsideType)
    const [variants, setVariants] = useState(piece?.type?.variants ?? outsideType?.variants ?? [])

    const [variant, setVariant] = useState(!!piece?.variant ?
        variants?.find(({ id }) => id === piece.variant) :
        null
    )

    const [working, setWorking] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})

    useEffect(() => {
        const variants = variantsEnricher(type, locale)(type?.variants)

        setVariants(variants)
        setVariant(variants?.find(({ id }) => id === piece?.variant) ?? null)
    }, [type, piece, locale])

    const {
        id,
        assignedTo,
        fromDate,
        toDate,
        note,
        serialNumber
    } = piece ?? {}

    const focusToRefMap = {
        assignedTo: assignedToRef,
        fromDate: fromDateRef,
        toDate: toDateRef
    }

    useEffect(() => {
        if(!triggered) {
            if(!piece?.id && !focus && !outsideType && !!typeRef?.current) {
                typeRef.current.click()
            }

            if(!!focus && !!focusToRefMap[focus]?.current) {
                focusToRefMap[focus].current.click()
            }

            setTriggered(true)
        }
    }, [typeRef?.current, outsideType, focusToRefMap[focus]?.current, triggered])

    const {
        requireFromDate = false,
        requireToDate = false,
        requireDocumentation = false,
        requireSerialNumber = false
    } = type ?? {}

    const addOrUpdate = async (body, { resetTouched }) => {
        setWorking(true)

        let reassigned = false
        if(id && !!body.assignedTo && !!filter.assignedToIds?.length) {
            const assignedToIds = filter.assignedToIds.map(id => (id === 'me') ? me.id : id)
            reassigned = !assignedToIds.includes(body.assignedTo)
        }

        const addOrUpdatePiece = id ?
            updatePiece :
            addPiece

        const { ok, response } = await addOrUpdatePiece({
            ...body,
            ...(('variant' in body) ? { variant: body.variant || null } : null)
        }, id, reassigned)

        setWorking(false)

        if(ok) {
            resetTouched()

            if(!id) {
                setActing({
                    mode: 'documentation',
                    piece: response
                })
            } else {
                onDone?.()
            }
        } else {
            if(response?.errorCode === 'identifier:duplicate' && response?.fields?.includes('serialNumber')) {
                setValidationErrors({
                    duplicate: formatMessage({
                        id: 'equipment_error_duplicate_identifier',
                        defaultMessage: 'A piece of equipment of the type ‘{type}’ with the same serial number already exists.'
                    }, { type: type.name })
                })
            }
        }
    }

    salt = `${salt}:edit`

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: id ?
                        'equipment_action_update' :
                        'equipment_action_add',
                    defaultMessage: id ?
                        'Update equipment' :
                        'Add equipment'
                })}
                dismiss={modal.dismiss} />
            <Form
                layout="vertical"
                onSubmit={addOrUpdate}>
                {({ values, touched, errors, trigger }) => {
                    let userId = null

                    if(user) {
                        userId = user.id
                    } else if(assignedTo) {
                        userId = assignedTo.id
                    } else if(values?.assignedTo) {
                        userId = values.assignedTo
                    }

                    return (
                        <>
                            <EntityField
                                salt={salt}
                                label={formatMessage({
                                    id: 'equipment_label_type',
                                    defaultMessage: 'Equipment type'
                                })}
                                name="typeId"
                                field={{
                                    value: type,
                                    required: (!outsideType && !id), // Basically, it appears required when it’s enabled
                                    include: id ? 'touched' : 'always'
                                }}
                                controlProps={{ autoFocus: true }}
                                picker={{
                                    heading: formatMessage({
                                        id: 'equipment_type_action_pick',
                                        defaultMessage: 'Pick equipment type'
                                    }),
                                    outer: false,
                                    creatable: true,
                                    creator: { context },
                                    search: {
                                        controlProps: {
                                            placeholder: formatMessage({
                                                id: 'equipment_type_placeholder_find_type',
                                                defaultMessage: 'Find equipment type'
                                            })
                                        }
                                    }
                                }}
                                enabled={(!outsideType && !id)}
                                entity={{
                                    type: 'equipmentType',
                                    path: '/equipment-types'
                                }}
                                onChange={({ typeId: type }) => {
                                    let typeChanged = false

                                    setType(previousType => {
                                        if(previousType?.id !== type?.id) {
                                            typeChanged = true
                                        }

                                        return type
                                    })

                                    if(typeChanged) {
                                        setVariants(variantsEnricher(type, locale)(type?.variants))
                                        setVariant(null)
                                    }
                                }}
                                ref={typeRef} />
                            {(!!variants?.length || manageAccess) && (
                                <EntityField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'equipment_label_variant',
                                        defaultMessage: 'Variant'
                                    })}
                                    name="variant"
                                    field={{
                                        value: variant,
                                        include: id ?
                                            'touched' :
                                            !!variants?.length ?
                                                'always' :
                                                'never'
                                    }}
                                    picker={{
                                        entities: variants ?? [],
                                        heading: formatMessage({
                                            id: 'equipment_variant_action_pick',
                                            defaultMessage: 'Pick variant'
                                        }),
                                        outer: false,
                                        creatable: true,
                                        creator: {
                                            type,
                                            context,
                                            transform: variant => variantsEnricher(type, locale)([variant])[0],
                                            onCreated: variant => {
                                                setVariants(variants => [
                                                    ...variants,
                                                    variant
                                                ])

                                                PubSub.publish('equipmentType.refresh')
                                            }
                                        }
                                    }}
                                    entity={{ type: 'equipmentTypeVariant' }}
                                    onChange={({ variant }) => setVariant(variant)}
                                    whistle={variant?.id ?? 'empty'} />
                            )}
                            <PersonField
                                salt={salt}
                                label={formatMessage({
                                    id: 'label_assigned_to',
                                    defaultMessage: 'Assigned to'
                                })}
                                name="assignedTo"
                                field={{
                                    value: user ?? assignedTo,
                                    include: id ? 'touched' : 'always'
                                }}
                                enabled={!(!id && user)}
                                picker={{ outer: false }}
                                ref={assignedToRef} />
                            <StringField
                                salt={salt}
                                label={formatMessage({
                                    id: 'equipment_label_serial_number',
                                    defaultMessage: 'Serial number'
                                })}
                                name="serialNumber"
                                field={{
                                    value: serialNumber,
                                    softRequired: requireSerialNumber,
                                    include: id ? 'touched' : 'always'
                                }}
                                controlProps={{ className: 'monospace' }}
                                onChange={() => setValidationErrors(errors => omit(errors, 'duplicate'))} />
                            {(!!values?.assignedTo && !isItMyOwnId(userId)) && (
                                <CheckboxField
                                    salt={salt}
                                    label={formatMessage({
                                        id: id ?
                                            'notification_toggle_label_update' :
                                            'notification_toggle_label_add',
                                        defaultMessage: id ?
                                            'Send notification when updating' :
                                            'Send notification when adding'
                                    })}
                                    name="notify"
                                    field={{
                                        value: !id,
                                        include: 'always'
                                    }}
                                    interaction="switch" />
                            )}
                            <TextField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_notes',
                                    defaultMessage: 'Notes'
                                })}
                                name="note"
                                field={{
                                    value: note ?? '',
                                    include: id ? 'touched' : 'always'
                                }}
                                controlProps={{
                                    placeholder: formatMessage({
                                        id: 'equipment_placeholder_notes',
                                        defaultMessage: 'E.g. price or service history'
                                    })
                                }} />
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'equipment_label_start_date',
                                    defaultMessage: 'Start date'
                                })}
                                name="fromDate"
                                field={{
                                    value: fromDate,
                                    softRequired: requireFromDate,
                                    include: id ? 'touched' : 'always'
                                }}
                                picker={{
                                    futureYearsAvailableInt: 100,
                                    ...(!!values?.toDate ? {
                                        before: values.toDate
                                    } : null)
                                }}
                                ref={fromDateRef} />
                            <TimeField
                                salt={salt}
                                label={formatMessage({
                                    id: 'equipment_label_end_date',
                                    defaultMessage: 'End date'
                                })}
                                name="toDate"
                                field={{
                                    value: toDate,
                                    softRequired: requireToDate,
                                    include: id ? 'touched' : 'always'
                                }}
                                picker={{
                                    futureYearsAvailableInt: 100,
                                    ...(!!values?.fromDate ? {
                                        after: values.fromDate
                                    } : null)
                                }}
                                ref={toDateRef} />
                            {(!id && requireDocumentation) && (
                                <DocumentationExplainer>
                                    <FormattedMessage
                                        id="message_adding_documentation_after_save"
                                        defaultMessage="Documentation can be added after saving." />
                                </DocumentationExplainer>
                            )}
                            {('duplicate' in validationErrors) && (
                                <Message
                                    type="error"
                                    message={validationErrors?.duplicate} />
                            )}
                            <Actions className="compact">
                                <Plain
                                    className="neutral"
                                    onClick={modal.dismiss}
                                    disabled={working}>
                                    <FormattedMessage
                                        id="action_cancel"
                                        defaultMessage="Cancel" />
                                </Plain>
                                <ButtonSubmit
                                    className={`constructive${working ? ' loading' : ''}`}
                                    disabled={!touched.length || !!size(errors) || !!size(validationErrors)}
                                    ref={trigger}>
                                    <FormattedMessage
                                        id={id ?
                                            'action_save' :
                                            'action_add'
                                        }
                                        defaultMessage={id ?
                                            'Save' :
                                            'Add'
                                        } />
                                </ButtonSubmit>
                            </Actions>
                        </>
                    )
                }}
            </Form>
        </>
    )
}

const variantsEnricher = (type, locale) => variants => (variants ?? [])
    .sort((one, two) => one.localeCompare(two, locale, { sensitivity: 'base' }))
    .map(variant => ({
        id: variant,
        name: variant,
        symbol: type?.symbol ?? null
    }))

const TypeProvider = props => {
    const { type } = useEquipmentType()

    if(!type) {
        return <Loader />
    }

    return (
        <EditEquipmentPiece
            {...props}
            type={type} />
    )
}

export default ({ provideType = false, ...props }) => {
    const { id } = props?.type ?? {}

    const EquipmentTypeWrapper = provideType ?
        EquipmentTypeProvider :
        Fragment

    const wrapperProps = provideType ? {
        id
    } : null

    return (
        <EquipmentTypeWrapper {...wrapperProps}>
            {(!!provideType && !!id) && <TypeProvider {...props} />}
            {(!provideType || !id) && <EditEquipmentPiece {...props} />}
        </EquipmentTypeWrapper>
    )
}
