import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { usePayment } from 'contexts/payment'
import { addDays, format } from 'date-fns'
import Message from 'components/message'
import { BundleName } from 'components/payment/plan'

// Only downgrades are scheduled – upgrades come into effect immediately
const DowngradeSchedulingWarning = ({ from, to, downgrade, cancel }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { account } = usePayment()

    if(!downgrade && !cancel) {
        return null
    }

    const currentTermEndsAt = !!account?.subscription?.currentTermEndsAt ?
        format(addDays(new Date(account.subscription.currentTermEndsAt), 1), 'PPP', { locale }) :
        null

    if(!currentTermEndsAt) {
        return null
    }

    return (
        <Message
            type="warning"
            className="preserve-line-breaks"
            message={[
                formatMessage({
                    id: downgrade ?
                        'payment_subscription_scheduled_downgrade_warning' :
                        'payment_subscription_scheduled_cancellation_warning',
                    defaultMessage: downgrade ?
                        'The downgrade to {product} will come into effect on {date}.' :
                        'The cancellation of {product} will come into effect on {date}.',
                }, {
                    product: (
                        <strong>
                            <BundleName bundle={to} />
                        </strong>
                    ),
                    date: <strong>{currentTermEndsAt}</strong>
                }),
                '\n',
                formatMessage({
                    id: 'payment_subscription_scheduled_downgrade_information_keep_functionality',
                    defaultMessage: 'You will remain on {product} until then.',
                }, {
                    product: (
                        <strong>
                            <BundleName bundle={from} />
                        </strong>
                    )
                })
            ]} />
    )
}

export default DowngradeSchedulingWarning