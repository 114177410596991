import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMatch } from 'react-router-dom'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import paths from 'app/paths'
import { cls } from 'utilities/dom'
import { MenuItemLink } from '../'

const feature = 'content-assistant'

const SystemSettingsJobTitles = props => {
    const atJobTitlePage = useMatch(paths.settings.jobTitle.base)

    const {
        initialized,

        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const access = initialized && checkFeature(feature) && check('users:manage')
    const upgradable = checkUpgradable({ feature })

    return (
        <MenuItemLink
            {...props}
            to="settings.userprofile" end
            feature={feature}
            access={access}
            upgradable={upgradable}
            itemUpgradable={false}
            className={cls([
                'ai',
                atJobTitlePage && 'active'
            ])}
            nested>
            <FormattedMessage
                id="noun_jobtitles"
                defaultMessage="Job titles" />
        </MenuItemLink>
    )
}

export default SystemSettingsJobTitles