import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { subMonths } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { useTimePeriodHeading } from 'pages/absence/utilities'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { safeFormat } from 'utilities/date-time'
import {
    Entry,
    Header,
    Type,
    Details, CaptionSmall
} from './s'
import Symbol from 'components/symbol'
import Paragraph from 'components/typography/paragraph'
import Tooltip, { Trigger } from 'components/tooltip'
import {
    AlertTriangleFilled as Warning,
    InfoCircleFilled as Info
} from 'components/feather'
import { SkeletonStrings } from 'components/skeleton'

const EstimationEntry = ({ type, dateRange, index, entriesCount, hasDifferentPolicies, ...entry }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const {
        policy,
        assignedRange,
        effectiveTimeWindow,
        pendingCount,
        newSum,
        newCount
    } = entry

    const { gradedAggregate = 'full' } = type ?? {}

    const { timeWindow } = policy

    const typeName = useTypeNameFormatter()(type)

    const totalCount = gradedAggregate === 'full' ? newCount : newSum

    const overdraft = Number.isInteger(policy?.quota) ?
        totalCount > policy.quota :
        false

    const newPolicy = entriesCount > 1 && index === 0 && assignedRange?.from && hasDifferentPolicies

    const timeFormatter = useTimePeriodHeading()
    const timeHeading = timeFormatter({ ...timeWindow, ...effectiveTimeWindow })

    const hasTooltip = !!(timeWindow.type === 'rolling' && dateRange?.end)

    return (
        <Entry {...((overdraft || newPolicy) ? { className: 'with-header' } : null)}>
            {overdraft && (
                <Header className="warning">
                    <Warning size={16} />
                    <span>
                        <FormattedMessage
                            id="absence_quota_status_overdraft"
                            defaultMessage="Overdraft" />
                    </span>
                </Header>
            )}
            {newPolicy && (
                <Header className="info">
                    <Info size={16} />
                    <span>
                        <FormattedMessage
                            id="absence_policy_new_from_date"
                            defaultMessage="New policy from {date}"
                            values={{
                                date: safeFormat(
                                    timeWindow.type === 'rolling' ?
                                        effectiveTimeWindow.from :
                                        assignedRange.from,
                                    'PPP',
                                    { locale }
                                )
                            }} />
                    </span>
                </Header>
            )}
            <Type>
                <Symbol
                    symbol={type.symbol}
                    title={typeName} />
            </Type>
            <Details>
                <CaptionSmall className="compact">
                    {timeHeading}
                    {hasTooltip && (
                        <Tooltip
                            content={formatMessage({
                                id: 'date_range',
                                defaultMessage: '{from} – {to}'
                            }, {
                                from: safeFormat(subMonths(dateRange.end, timeWindow.months), 'PP', { locale }),
                                to: safeFormat(dateRange.end, 'PP', { locale })
                            })}
                            placement="top-end">
                            <Trigger lineHeight={18} />
                        </Tooltip>
                    )}
                </CaptionSmall>
                <Paragraph className="compact">
                    <FormattedMessage
                        id={Number.isInteger(policy?.quota) ?
                            'absence_quota_days_taken_count' :
                            'days_taken_count'
                        }
                        defaultMessage={Number.isInteger(policy?.quota) ?
                            '{count} of {total} taken' :
                            '{count, plural, =0 {0 days taken} =1 {1 day taken} other {{count} days taken}}'
                        }
                        values={{
                            count: totalCount,
                            total: formatMessage({
                                id: 'days_count',
                                defaultMessage: '{count, plural, =0 {0 days} =1 {1 day} other {{count} days}}'
                            }, {
                                count: policy?.quota
                            })
                        }} />

                </Paragraph>
                {!!pendingCount && (
                    <CaptionSmall className="compact">
                        <FormattedMessage
                            id="absence_excluded_pending_days_count"
                            defaultMessage="{count, plural, =0 {} =1 {Excluding 1 pending day} other {Excluding {count} pending days}}"
                            values={{ count: pendingCount }} />
                    </CaptionSmall>
                )}
            </Details>
        </Entry>
    )
}

export const LoadingEntry = ({ type }) => {
    const { formatMessage } = useIntl()

    return (
        <Entry>
            <Type>
                <Symbol
                    symbol={type.symbol}
                    title={formatMessage({
                        id: 'message_loading',
                        defaultMessage: 'Loading…'
                    })} />
            </Type>
            <Details>
                <SkeletonStrings size={20} />
                <SkeletonStrings size={24} />
            </Details>
        </Entry>
    )
}

export default EstimationEntry
