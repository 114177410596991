import styled from 'styled-components'
import { Wrapper as WrapperBase } from 'components/statistics-group'
import { ParagraphSmall } from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const Wrapper = styled(WrapperBase)`
    margin-bottom: 0;
`

export const Label = CaptionSmall

export const Value = styled(ParagraphSmall)`
    display: inline-flex;
    column-gap: 4px;
`