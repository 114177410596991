import { memo } from 'react'
import { usePage } from 'contexts/page'

const GateKeeper = memo(({ children }) => {
    const { upgrade = {} } = usePage()

    const {
        enabled: upgradable = false,

        // Only let children pass through when properly guarded from fetching data
        passthrough = false
    } = upgrade

    if(upgradable && !passthrough) {
        return null
    }

    return children
})

export default GateKeeper