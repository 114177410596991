import React, { Component, createContext, useContext } from 'react'
import { get, put, patch, post, remove } from 'api'
import { useAuth } from 'contexts/auth'
import { useAccess } from 'contexts/access'
import { useEnvironment } from 'contexts/environment'
import { useConfiguration } from 'contexts/configuration'
import Stripe from 'stripe'
import { loadStripe } from '@stripe/stripe-js'
import PubSub from 'pubsub-js'
import debounce from 'lodash.debounce'
import { local } from 'utilities/storage'
import { getChannel } from 'utilities/broadcaster'
import { size, omit, pick } from 'utilities/object'
import { createLogger } from 'utilities/rapid7-insight-ops'

const paymentLogger = createLogger('log', 'AUDIT:')

const PaymentContext = createContext()

class PaymentProvider extends Component {
    constructor(props) {
        super(props)

        const {
            auth,
            access
        } = props

        this.getAccountDebounced = debounce(this.getAccount, 1000, {
            leading: true,
            trailing: false
        })

        this.state = {
            sales: {},
            support: {},
            account: undefined,
            currency: undefined,
            authorized: auth?.status === 'authorized',
            manager: access.initialized && access.check('organization:manage'),
            syncing: false,

            fetchPlansFull: this.fetchPlansFull,

            getAccount: this.getAccountDebounced,
            updateAccount: this.updateAccount,

            // setupCanceled: false,
            // setupFailed: false,
            updateBilling: this.updateBilling,
            removeBilling: this.removeBilling,

            updateSubscription: this.updateSubscription,
            cancelScheduledChange: this.cancelScheduledChange,

            challenged: false,

            instantChallengeActive: false,
            instantChallengeFailed: false,
            resetInstantChallenge: this.resetInstantChallenge,

            recurringChallengeFailed: false,
            handleRecurringChallenge: this.handleRecurringChallenge
        }

        PubSub.subscribe('payment.refresh', () => {
            props.access.refresh()
            PubSub.publish('organization.refresh', true)
            this.getAccountDebounced()
        })

        this.syncer = getChannel('payment')
    }

    componentDidMount() {
        global.addEventListener('beforeunload', this.log3dsChallengeAbandoned)

        this.fetchContacts()
        this.syncer.onmessage = ({ data }) => void this.setState(data)
    }

    componentDidUpdate(props, { manager: wasManager }) {
        const { access } = this.props
        const isManager = access.initialized && access.check('organization:manage')

        if(!wasManager && isManager) {
            this.setState({ manager: true }, () => this.getAccountDebounced(true))
        }
    }

    componentWillUnmount() {
        this.log3dsChallengeAbandoned()

        global.removeEventListener('beforeunload', this.log3dsChallengeAbandoned)
        PubSub.unsubscribe('payment')
        this.syncer.close()
    }

    fetchPlansFull = () => this.props.fetchFromS3('/plans/full.json')

    fetchContacts = async () => {
        const urls = {
            sales: '/sales.json',
            support: '/support.json'
        }

        const results = await Promise.all(Object.values(urls)
            .map(url => this.props.fetchFromS3(url))
        )

        const state = results.reduce((accumulator, result, index) => {
            if(!result.ok) {
                return accumulator
            }

            return {
                ...accumulator,
                [Object.keys(urls)[index]]: result.response
            }
        }, {})

        if(size(state)) {
            this.setState(state, () => this.syncer.postMessage(state))
        }
    }

    updateAccountLocally = (account = {}) => {
        const currency = account?.subscription?.currency ?? account?.currency

        const state = {
            account: {
                ...account,
                subscription: {
                    ...account.subscription,
                    free: !!account.subscription?.free && !account.subscription?.trial,
                }
            },
            ...(currency ? { currency } : null),
            challenged: !!account.challenges?.length,
            syncing: false
        }

        this.setState(state, () => {
            this.storeAccount()
            this.syncer.postMessage(state)
        })
    }

    getAccount = async (force = false) => {
        const { syncing } = this.state
        if(syncing && !force) {
            return { ok: false }
        }

        let cachedAccount = local.get('account')
        if(cachedAccount && !force) {
            const currency = cachedAccount?.subscription?.currency ?? cachedAccount?.currency

            this.setState({
                account: cachedAccount,
                ...(currency ? { currency } : null)
            })

            return { ok: true, response: cachedAccount }
        }

        this.setState({ syncing: true })

        const { ok, response: account } = await get({ path: '/organization/account' })

        if(ok && account) {
            this.updateAccountLocally(account)
        }

        return { ok, response: account }
    }

    updateAccount = async body => {
        const { ok, response: account } = await patch({
            path: '/organization/account',
            body
        })

        if(ok && account) {
            this.updateAccountLocally(account)
        }

        return { ok, response: account }
    }

    getStripeToken = async payload => {
        const stripe = new Stripe(this.props.configuration?.stripe_public_key)
        const [month, year] = payload.card.expiry.match(/\d+/g) ?? []

        const result = await stripe.tokens.create({
            card: {
                number: (payload.card.number.match(/\d+/g) ?? []).join(''),
                exp_month: month,
                exp_year: year,
                cvc: payload.card.cvc,
                name: payload.name
            }
        })

        if(result.error) {
            return {
                ok: false,
                response: result.error
            }
        }

        return {
            ok: true,
            response: result.id
        }
    }

    getClientSecret = async payload => {
        const stripeResult = await this.getStripeToken(payload)

        if(!stripeResult.ok) {
            return stripeResult
        }

        const billingIntentResult = await post({
            path: '/organization/account/billing/intent',
            body: { temporaryToken: stripeResult.response }
        })

        if(!billingIntentResult.ok) {
            return billingIntentResult
        }

        return {
            ok: true,
            response: billingIntentResult.response.clientSecret
        }
    }

    getRecurringChallengeClientSecret = async ({ renewPayment = false, ...payload }) => {
        const collectBody = {}

        if(renewPayment || !!payload.card) {
            const stripeResult = await this.getStripeToken(payload)

            if(!stripeResult.ok) {
                return stripeResult
            }

            collectBody.token = stripeResult.response
        }

        const [{ id }] = this.state.account.challenges

        const invoiceCollectionResult = await post({
            path: `/organization/account/invoices/${id}/collect`,
            body: collectBody
        })

        if(!invoiceCollectionResult.ok) {
            return invoiceCollectionResult
        }

        return {
            ok: true,
            response: invoiceCollectionResult.response.client_secret
        }
    }

    handleUpdate = async ({ body, result, persister }) => {
        let state = {}

        // Resolve client secret
        let clientSecret = null

        const clientSecretRetriever = body.handleRecurringChallenge ?
            this.getRecurringChallengeClientSecret :
            this.getClientSecret

        const clientSecretResult = await clientSecretRetriever(body)

        if(!clientSecretResult.ok) {
            return clientSecretResult
        }

        clientSecret = clientSecretResult.response

        // Load Stripe
        const stripe = await loadStripe(this.props.configuration?.stripe_public_key)

        if(body.handleRecurringChallenge) {
            // Resolve payment intent
            const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

            // Handle next action
            let currentAction = null

            // Authentication (Usually 3DSecure)
            if(paymentIntent?.status === 'requires_action') {
                currentAction = await stripe.handleNextAction({ clientSecret })

                if(!!currentAction?.error) {
                    state.recurringChallengeFailed = true
                }
            }

            if(!!currentAction?.error) {
                result.response = currentAction.error
                // TODO: Communicate to user that the challenge failed? this.state.setupFailed
            }

            if(currentAction?.setupIntent?.status === 'canceled') {
                // TODO: Communicate to user that the challenge was canceled? this.state.setupCanceled
            }

            // Close the circle with the API if succeeded
            if([paymentIntent?.status, currentAction?.paymentIntent?.status].includes('succeeded')) {
                result = await persister({
                    paymentMethod: paymentIntent?.payment_method ?? currentAction.paymentIntent.payment_method
                })
            }
        } else {
            // Resolve setup intent
            const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret)

            // Handle next action
            let currentAction = null

            // Authentication (Usually 3DSecure)
            if(setupIntent?.status === 'requires_action') {
                this.setState({ instantChallengeActive: true })

                currentAction = await stripe.handleNextAction({ clientSecret })

                if(currentAction.setupIntent?.status === 'succeeded') {
                    this.setState({ instantChallengeActive: false })
                }

                if(!!currentAction?.error) {
                    state.instantChallengeFailed = true
                }
            }

            if(!!currentAction?.error) {
                result.response = currentAction.error
                // TODO: Communicate to user that the challenge failed? this.state.setupFailed
            }

            if(currentAction?.setupIntent?.status === 'canceled') {
                // TODO: Communicate to user that the challenge was canceled? this.state.setupCanceled
            }

            // Close the circle with the API if succeeded
            if([setupIntent?.status, currentAction?.setupIntent?.status].includes('succeeded')) {
                result = await persister({
                    paymentMethod: setupIntent?.payment_method ?? currentAction.setupIntent.payment_method
                })
            }
        }

        return { result, state }
    }

    persistBilling = () => payment => put({
        path: '/organization/account/billing',
        body: payment
    })

    // eslint-disable-next-line no-unused-vars
    updateBilling = async ({ address, term, ...body }) => {
        if(!!address) {
            this.updateAccount({ address })
        }

        this.setState({
            // setupCanceled: false,
            // setupFailed: false,
            instantChallengeFailed: false,
            recurringChallengeFailed: false
        })

        const { result, state } = await this.handleUpdate({
            body,
            result: {
                ok: false,
                response: null
            },
            persister: this.persistBilling()
        })

        if(result.ok) {
            this.props.access.refresh()
            this.getAccount(true)
        } else if(!!size(state)) {
            this.setState(state)
        }

        return result
    }

    removeBilling = async () => {
        const { ok } = await remove({
            path: '/organization/account/billing',
            returnsData: false
        })

        if(ok) {
            this.setState(({ account }) => ({
                account: omit(account, 'billing')
            }))
        }

        return { ok }
    }

    persistSubscription = (subscription = {}) => (payment = {}) => put({
        path: '/organization/account/subscription',
        body: {
            ...subscription,
            ...payment
        }
    })

    updateSubscription = async ({ address, code, term, pledgedUserCount, ...body }) => {
        if(!!address) {
            this.updateAccount({ address })
        }

        this.setState({
            // setupCanceled: false,
            // setupFailed: false,
            instantChallengeFailed: false,
            recurringChallengeFailed: false
        })

        const addBilling = !!body?.card

        let result = {
            ok: true,
            response: null
        }

        let state = {}

        const persister = this.persistSubscription({
            code,
            term,
            ...(pledgedUserCount ? { pledgedUserCount } : null)
        })

        if(addBilling && code !== 'free') {
            const updateResult = await this.handleUpdate({
                body,
                result: {
                    ok: false,
                    response: null
                },
                persister
            })

            result = updateResult.result
            state = updateResult.state
        } else {
            result = await persister()
        }

        if(result.ok) {
            PubSub.publish('organization.refresh', true)
            this.props.access.refresh()
            this.getAccount(true)
        } else if(!!size(state)) {
            this.setState(state)
        }

        return result
    }

    cancelScheduledChange = async () => {
        const { ok } = await put({
            path: '/organization/account/subscription',
            body: pick(this.state.account.subscription, 'code')
        })

        ok && this.getAccount(true)

        return { ok }
    }

    resetInstantChallenge = (cancel = false) => {
        cancel && this.log3dsChallengeAbandoned(true)

        this.setState({
            instantChallengeActive: false,
            instantChallengeFailed: false
        })
    }

    log3dsChallengeAbandoned = (force = false) => {
        if(!this.state.instantChallengeActive || !force) {
            return
        }

        paymentLogger({
            source: 'web',
            event: 'payment-3ds-challenge-abandoned'
        })
    }

    storeAccount = () => {
        const oneDayAsMinutes = 60 * 24
        local.set('account', this.state.account, { expiry: oneDayAsMinutes })
    }

    render() {
        const { children = null } = this.props

        return (
            <PaymentContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </PaymentContext.Provider>
        )
    }
}

export default props => {
    const auth = useAuth()
    const access = useAccess()
    const { fetchFromS3 } = useEnvironment()
    const { configuration } = useConfiguration()

    return (
        <PaymentProvider
            {...props}
            auth={auth}
            access={access}
            fetchFromS3={fetchFromS3}
            configuration={configuration} />
    )
}

export const usePayment = () => useContext(PaymentContext)