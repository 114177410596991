import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { useAppSignal } from 'hooks/app-signal'
import { useLocationQueryIntent } from 'hooks/location-intent'
import { refreshPayment } from 'utilities/payment'
import { getCategoryIcon, enrichByName, quickGuideToNameMap } from 'utilities/categories'
import {
    Header, HeadingColumns, Module, Feature, CloseButton,
    Tutorial, FeedbackQuestions
} from './s'
import { H4 as Heading } from 'components/typography/heading'
import { Scrollable as Modal } from 'modals/generic'
import Manage from 'pages/settings/pages/subscription/main/manage'

const QuickGuide = () => {
    const {
        featureConfig,
        check
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const manageAccess = check('organization:manage')

    const [flat, setFlat] = useState(null)
    const [show, setShow] = useState(null)
    const [managing, setManaging] = useState(false)

    useAppSignal({
        namespace: 'quick-guide.show',
        action: which => setShow(which)
    })

    useLocationQueryIntent({
        intent: 'tutorial',
        action: ({ which }) => setShow(which)
    })

    const dismiss = () => setShow(null)

    useEffect(() => {
        if(!!featureConfig?.length) {
            setFlat(featureConfig.reduce(
                (accumulator, { key, features = [], ...module }) => {
                    const { type, name } = enrichByName(key)

                    return {
                        ...accumulator,
                        [name]: {
                            ...module,
                            type,
                            name,
                            key,
                            features
                        },
                        ...features.reduce((accumulator, { key, ...feature }) => {
                            const { type, name } = enrichByName(key)

                            return {
                                ...accumulator,
                                [name]: {
                                    ...feature,
                                    type,
                                    name,
                                    key
                                }
                            }
                        }, {})
                    }
                },
                {}
            ))
        }
    }, [JSON.stringify(featureConfig)])

    const currentModuleOrFeature = show ?
        (flat?.[quickGuideToNameMap(show)] ?? null) :
        null

    const Icon = getCategoryIcon(show)
    const salt = `quick-guide:${show}`

    const ModuleOrFeature = !currentModuleOrFeature ?
        null :
        (currentModuleOrFeature?.type === 'module') ?
            Module :
            Feature

    const upgradable = !!currentModuleOrFeature && checkUpgradable({ [currentModuleOrFeature.type]: currentModuleOrFeature.name })

    return (
        <>
            <Modal
                className="large"
                show={!!show}
                dismiss={dismiss}>
                <Header>
                    {(!!currentModuleOrFeature && !!manageAccess) && (
                        <ModuleOrFeature
                            {...currentModuleOrFeature}
                            item={(currentModuleOrFeature.type === 'module') ?
                                currentModuleOrFeature.key :
                                currentModuleOrFeature
                            }
                            setManaging={setManaging}
                            context="quick-guide"
                            iconSize={24}
                            key={`${currentModuleOrFeature.key}:available:${currentModuleOrFeature.available}:enabled:${currentModuleOrFeature.enabled}`} />
                    )}
                    {(!currentModuleOrFeature || !manageAccess) && (
                        <HeadingColumns>
                            {!!Icon && <Icon size={24} />}
                            <Heading>
                                <FormattedMessage
                                    id={`noun_${show}`}
                                    defaultMessage={show} />
                            </Heading>
                        </HeadingColumns>
                    )}
                    <CloseButton onClick={dismiss} />
                </Header>
                <Tutorial
                    which={show}
                    {...((!!currentModuleOrFeature && upgradable && !manageAccess) ? {
                        upgrade: {
                            [currentModuleOrFeature.type]: currentModuleOrFeature.name,
                            useFeatureOrModuleIcon: false
                        }
                    } : null)} />
                <FeedbackQuestions
                    onClick={() => setShow(false)}
                    salt={salt} />
            </Modal>
            <Modal
                show={!!managing}
                dismiss={() => setManaging(null)}
                salt={`${salt}:subscription:manage`}>
                <Manage
                    meta={managing}
                    onDone={() => {
                        refreshPayment()
                        setManaging(null)
                    }}
                    salt={salt} />
            </Modal>
        </>
    )
}

export default QuickGuide