import styled from 'styled-components'
import { interpointDivider } from 'utilities/styled'
import { Grid } from 'components/grid'
import { h4Css } from 'components/typography/heading'
import { TrialCountdown as TrialCountdownBase } from 'components/payment/subscription'

export const Rows = styled(Grid)`
    grid-auto-rows: auto;
    row-gap: 8px;

    margin-block-end: 24px;
`

export const Heading = styled.h3`
    ${h4Css}
    ${interpointDivider()}
    font-size: 16px;

    > :not(.name) {
        font-weight: 400;
        color: var(--huma-color-foreground-subtle);
    }
`

export const TrialCountdown = styled(TrialCountdownBase)`
    margin-block-start: 16px;
`