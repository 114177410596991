import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCompetenceRecord } from 'contexts/competence-record'
import { extensionsByMime } from 'utilities/file'
import { Label } from 'components/form/field/s'
import File from 'components/file'
import Form from 'components/form/controller'
import FileField from 'components/form/field/file'

const AttachmentDocuments = ({ salt, uploading, setUploading }) => {
    const { formatMessage } = useIntl()
    const {
        record,
        uploadDocumentation,
        removeDocumentation
    } = useCompetenceRecord()

    const [uploaded, setUploaded] = useState(null)

    const {
        id,
        type,
        attachments = []
    } = record

    const upload = async (body, { reset }) => {
        if(uploading) {
            return void reset()
        }

        setUploading(true)
        const { ok, response } = await uploadDocumentation(body, id)
        setUploading(false)

        if(ok) {
            setUploaded(response)
            reset()
        }
    }

    const deleteDocumentation = id => attachmentId => removeDocumentation(id, attachmentId)

    return (
        <>
            <Label
                htmlFor={`${salt}:file`}
                optional={formatMessage({
                    id: 'competence_documentation_optional',
                    defaultMessage: 'Documents and links are only visible to the user they belong to and competence administrators'
                })}>
                <FormattedMessage
                    id="qualification_type_label_documents"
                    defaultMessage="Documents" />
            </Label>
            {attachments?.map(attachment => (
                <File
                    meta={attachment}
                    remove={{
                        onClick: deleteDocumentation(id),
                        prompt: {
                            question: formatMessage({
                                id: 'action_delete_documentation_prompt_question',
                                defaultMessage: 'Confirm deleting documentation'
                            }),
                            confirmText: formatMessage({
                                id: 'action_delete_documentation_prompt_confirm',
                                defaultMessage: 'Delete'
                            })
                        }
                    }}
                    key={`${salt}:attachment:${attachment.id}`} />
            ))}
            <Form
                layout="vertical"
                submitOnChange={true}
                onSubmit={upload}>
                <FileField
                    key={uploaded?.id ?? null}
                    salt={`${salt}:file`}
                    label={false}
                    name="file"
                    text={formatMessage({
                        id: 'documentation_upload_instructions',
                        defaultMessage: 'Drag and drop or click here to upload'
                    })}
                    field={{ required: type?.requireDocumentation }}
                    accept={extensionsByMime}
                    multiple={false}
                    loading={uploading} />
            </Form>
        </>
    )
}

export default AttachmentDocuments
