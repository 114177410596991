import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { format, isFuture } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { isofy } from 'utilities/date-time'
import { cls } from 'utilities/dom'
import { useCheckViewLevelAccess } from 'pages/competence/utilities'
import { useLevelValueRenderer } from 'pages/competence/components/level/value'
import {
    Wrapper,
    Person,
    Note,
    StringValue, ViewContractableSelect, LevelDefinitionsButton,
    DateColumns, DateValue, ArrowColumn, Label,
    ExpiryStatus, InlineMessage
} from './s'
import { FlexChild } from 'components/flex'
import { Scrollable as Modal } from 'modals/generic'
import LevelDefinitions from 'pages/competence/components/level/definitions'
import { ArrowRight } from 'styled-icons/feather'

const ViewCompetenceRecordDetails = ({ record, salt }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const {
        concerns,
        note,
        startDate,
        endDate,
        validFrom,
        validTo,
        level,
        isNativeLanguage = false,
        fieldOfStudy,
        school,
        attachments,
        links,
        type
    } = record

    const {
        category,
        validToRequired,
        documentationRequired
    } = type

    const [showingDefinitions, setShowingDefinitions] = useState(false)

    const viewLevelAccess = useCheckViewLevelAccess()(record)

    const levelValue = useLevelValueRenderer()

    const fromDate = category === 'certification' ? isofy(validFrom) : isofy(startDate)
    const toDate = category === 'certification' ? isofy(validTo) : isofy(endDate)

    const dateFormat = ['education', 'experience'].includes(category) ?
        formatMessage({
            id: 'date_format_full_dayless',
            defaultMessage: 'MMM yyyy'
        }) :
        'PP'

    const toDateFormatted = toDate ? format(toDate, dateFormat, { locale }) : null
    const hasExpired = (!!toDate && !isFuture(toDate))

    const missingDocumentation = !!documentationRequired && !attachments?.length && !links?.length

    const startDateClassName = cls([
        !fromDate && 'empty'
    ])

    const endDateClassName = cls([
        !toDate && 'empty',
        (hasExpired && category === 'certification') && 'error'
    ])

    return (
        <Wrapper>
            <Person
                size={24}
                who={concerns} />
            {category === 'education' && (
                <>
                    <StringValue
                        label={formatMessage({
                            id: 'competence_type_label_field_of_study',
                            defaultMessage: 'Field of study'
                        })}
                        field={{ value: fieldOfStudy }} />
                    <StringValue
                        label={formatMessage({
                            id: 'competence_type_label_school',
                            defaultMessage: 'School'
                        })}
                        field={{ value: school }} />
                </>
            )}
            {['certification', 'education', 'experience'].includes(category) && (
                <DateColumns>
                    <FlexChild>
                        <Label className="compact">
                            <FormattedMessage
                                id={category === 'certification' ?
                                    'qualification_type_label_issued_date' :
                                    'noun_start_date'
                                }
                                defaultMessage={category === 'certification' ?
                                    'Issued date' :
                                    'Start date'
                                } />
                        </Label>
                        <DateValue className={cls([
                            startDateClassName,
                            'compact'
                        ])}>
                            {!fromDate && (
                                <FormattedMessage
                                    id="empty_not_entered"
                                    defaultMessage="Not entered" />
                            )}
                            {fromDate && format(fromDate, dateFormat, { locale })}
                        </DateValue>
                    </FlexChild>
                    <ArrowColumn>
                        <ArrowRight size={24} />
                    </ArrowColumn>
                    <FlexChild>
                        <Label className="compact">
                            <FormattedMessage
                                id={category === 'certification' ?
                                    'qualification_type_label_expiry_date' :
                                    'noun_end_date'
                                }
                                defaultMessage={category === 'certification' ?
                                    'Expiry date' :
                                    'End date'
                                } />
                        </Label>
                        <DateValue className={cls([
                            endDateClassName,
                            'compact'
                        ])}>
                            {hasExpired && toDateFormatted}
                            {(!hasExpired && !toDate) && (
                                <FormattedMessage
                                    id="empty_not_entered"
                                    defaultMessage="Not entered" />
                            )}
                            {(!hasExpired && toDate) && toDateFormatted}
                        </DateValue>
                    </FlexChild>
                </DateColumns>
            )}
            {(['skill', 'language'].includes(category) && !!viewLevelAccess) && (
                <>
                    <ViewContractableSelect
                        salt={salt}
                        label={formatMessage({
                            id: 'competence_label_level',
                            defaultMessage: 'Level'
                        })}
                        field={{
                            value: level,
                            valueRenderer: levelValue,
                            valueDescription: (category === 'language' && !!isNativeLanguage) ?
                                formatMessage({
                                    id: 'competence_language_native',
                                    defaultMessage: 'Native / multilingual'
                                }) :
                                null,
                            category
                        }} />
                    <LevelDefinitionsButton
                        className="constructive small compact"
                        onClick={() => setShowingDefinitions(true)}>
                        <FormattedMessage
                            id="noun_level_definitions"
                            defaultMessage="Level definitions" />
                    </LevelDefinitionsButton>
                    <Modal
                        className="medium"
                        show={!!showingDefinitions}
                        dismiss={() => setShowingDefinitions(false)}
                        salt={`${salt}:${level}:definitions`}>
                        <LevelDefinitions
                            type={category}
                            focus={level}
                            dismiss={() => setShowingDefinitions(false)}
                            salt={`${salt}:${level}:definitions`} />
                    </Modal>
                </>
            )}
            {note && <Note>{note}</Note>}
            {(!!toDate && category === 'certification') && (
                <ExpiryStatus
                    from={fromDate}
                    to={toDate}
                    context="competence" />
            )}
            {(!toDate && validToRequired) && (
                <InlineMessage
                    message={formatMessage({
                        id: 'competence_error_missing_expiry_date',
                        defaultMessage: 'No expiry date'
                    })}
                    type="error" />
            )}
            {missingDocumentation && (
                <InlineMessage
                    message={formatMessage({
                        id: 'qualification_missing_documentation',
                        defaultMessage: 'Missing documentation'
                    })}
                    type="error" />
            )}
        </Wrapper>
    )
}

export default ViewCompetenceRecordDetails