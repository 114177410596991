import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import ModalBase from 'modals/generic'
import { H2, H4 } from 'components/typography/heading'
import { FlexChildShrink, FlexChild } from 'components/flex'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'
import Caption from 'components/typography/caption'
import ActionsBase from 'components/form/actions'

export const Modal = styled(ModalBase)`
    padding: 32px 0 0;

    ${belowTablet`
        padding: 24px 0 0;
    `}
`

export const Header = styled(FlexChildShrink)`
    padding: 0 32px;

    ${belowTablet`
        padding: 0 24px;
    `}
`

export const Heading = styled(H2)`
    &:only-child {
        margin-bottom: 24px;

        ${belowTablet`
            margin-bottom: 16px;
        `}
    }
`

export const Scroller = styled(FlexChild)`
    min-height: 240px;
    padding: 0 32px;
    overflow-y: auto;
    transform: translateZ(0);

    ${belowTablet`
        padding: 0 24px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    `}
`

export const Realm = styled.div`
    &:not(:last-child) {
        margin-bottom: 32px;

        ${belowTablet`
            margin-bottom: 24px;
        `}
    }
`

export const RealmHeading = styled(H4)`
    padding: 16px 0;
    line-height: 24px;
    margin: 0;
`

export const Permission = styled(Grid).attrs(() => ({ as: 'li' }))`
    grid-template-columns: 1fr min-content;
    grid-auto-rows: auto;
    column-gap: 24px;
    justify-content: stretch;
    padding: 16px 0;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 1px;
        background-color: var(--huma-color-surface-subtle);
    }
`

export const Name = Paragraph

export const AccessLevel = styled(Caption)`
    white-space: nowrap;
`

export const Description = styled(Caption)`
    grid-row: 2;
    grid-column: span 2;
`

export const Actions = styled(ActionsBase)`
    padding: 24px 32px;
    border-top: 2px solid var(--huma-color-border-default);

    ${belowTablet`
        padding: 16px 24px;
    `}
`