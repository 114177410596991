import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { usePerson } from 'contexts/person'
import { usePossum } from 'hooks/possum'
import { unpackStringField, getShortName } from 'utilities/person'
import { getDate, safeFormat } from 'utilities/date-time'
import { Wrapper, Label, DefinitionDescription, Plain } from './s'
import { DefinitionList, DefinitionTerm } from 'components/list'
import Divider from 'components/divider'
import { Scrollable as Modal } from 'modals/generic'
import DeactivateProfile from 'pages/people/pages/profile/hero/deactivate'

const LastDayOfWork = ({ lookingAtMyOwnProfile, access, setLoading, salt }) => {
    const { dateLocale: locale } = useI18n()

    const {
        person,
        fetchPerson
    } = usePerson()

    const [deactivating, setDeactivating] = useState(false)

    const {
        status,
        lastDayOfWork
    } = person ?? {}

    const isDeactivated = status?.active === false
    const lastDayOfWorkValue = unpackStringField(lastDayOfWork)
    const shortName = getShortName(person)

    const possessify = usePossum()

    const today = getDate()

    if(!lastDayOfWorkValue || lastDayOfWorkValue > today) {
        return null
    }

    return (
        <Wrapper>
            <Label>
                <FormattedMessage
                    id="person_label_employment_ended"
                    defaultMessage="Employment has ended" />
            </Label>
            <DefinitionList>
                <DefinitionTerm>
                    <FormattedMessage
                        id="person_label_employment_date_end"
                        defaultMessage="Last day of work" />
                </DefinitionTerm>
                <DefinitionDescription>
                    {safeFormat(lastDayOfWorkValue, 'PP', { locale })}
                </DefinitionDescription>
            </DefinitionList>
            {(!isDeactivated && !!access.people.available && !lookingAtMyOwnProfile) && (
                <>
                    <Divider />
                    <Plain
                        className="constructive compact allow-wrap"
                        onClick={() => setDeactivating(true)}>
                        <FormattedMessage
                            id="person_action_deactivate_options"
                            defaultMessage="Deactivate {pname} account…"
                            values={{ pname: possessify(shortName) }} />
                    </Plain>
                    <Modal
                        show={deactivating}
                        dismiss={() => setDeactivating(false)}
                        salt={salt}>
                        <DeactivateProfile
                            lookingAtMyOwnProfile={lookingAtMyOwnProfile}
                            onAfterDeactivate={async () => {
                                setLoading('deactivating')
                                setDeactivating(false)
                                setLoading(null)

                                await fetchPerson()
                            }}
                            salt={`${salt}:deactivate`} />
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default LastDayOfWork