import { useEffect, useState } from 'react'
import { numbers } from 'utilities/styled'

export const useMediaQuery = query => {
    const getMatches = query => global.matchMedia(query).matches

    const [matches, setMatches] = useState(getMatches(query))

    const handleChange = () => setMatches(getMatches(query))

    useEffect(() => {
        const matchMedia = global.matchMedia(query)

        handleChange()
        matchMedia.addEventListener('change', handleChange)

        return () => matchMedia.removeEventListener('change', handleChange)
    }, [query])

    return matches
}

export const useBelowDesktop = () => useMediaQuery(`(max-width: ${numbers.desktop}px)`)
export const useBelowLaptop = () => useMediaQuery(`(max-width: ${numbers.laptop}px)`)
export const useBelowTablet = () => useMediaQuery(`(max-width: ${numbers.tablet}px)`)
export const useBelowPhone = () => useMediaQuery(`(max-width: ${numbers.phone}px)`)

export const useFromDesktop = () => useMediaQuery(`(min-width: ${numbers.desktop}px)`)
export const useFromLaptop = () => useMediaQuery(`(min-width: ${numbers.laptop}px)`)
export const useFromTablet = () => useMediaQuery(`(min-width: ${numbers.tablet}px)`)
export const useFromPhone = () => useMediaQuery(`(min-width: ${numbers.phone}px)`)