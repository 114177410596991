import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { isSameDay } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { useMe } from 'contexts/me'
import { safeFormat } from 'utilities/date-time'
import { decimalToPercent } from 'utilities/math'
import { cls } from 'utilities/dom'
import { getDaysCount } from 'pages/absence/utilities'
import {
    Person, DateColumns, ArrowColumn, Label,
    Section, InlineMessage, Note
} from './s'
import { FlexChild } from 'components/flex'
import Paragraph from 'components/typography/paragraph'
import { Plain } from 'components/button'
import Status from './status'
import FollowUp from './follow-up'
import Estimate from './estimate'
import { ArrowRight } from 'styled-icons/feather'

const ViewEntryDetails = ({ edit, type, absenceAdmin, ...entry }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const {
        id,
        start,
        end,
        grade = 1,
        user,
        workRelated,
        permissionLevel,
        followUp,
        status,
        note
    } = entry

    const { allowGraded = false } = type ?? {}

    const days = getDaysCount({
        ...entry,
        type
    })

    const sameDay = (!!start && !!end && isSameDay(start, end))

    const { isItMyOwnId } = useMe()
    const adminAccess = absenceAdmin || (permissionLevel === 'approve')
    const manageAccess = adminAccess || (permissionLevel === 'edit') || isItMyOwnId(user.id)

    const salt = `absence:details:${entry.id}`

    return (
        <>
            <Person
                size={24}
                who={user} />
            {(!!note && !!manageAccess) && <Note>{note}</Note>}
            <DateColumns>
                <FlexChild>
                    <Label className={cls([
                        'compact',
                        ['rejected', 'canceled'].includes(status) && 'strike'
                    ])}>
                        {safeFormat(start, 'EEEE', { locale })}
                    </Label>
                    <Paragraph className={cls([
                        'compact',
                        ['rejected', 'canceled'].includes(status) && 'strike'
                    ])}>
                        {safeFormat(start, 'PP', { locale })}
                    </Paragraph>
                </FlexChild>
                {!sameDay && (
                    <>
                        <ArrowColumn>
                            <ArrowRight size={24} />
                        </ArrowColumn>
                        <FlexChild>
                            <Label className={cls([
                                'compact',
                                (!!end && ['rejected', 'canceled'].includes(status)) && 'strike'
                            ])}>
                                {!!end && safeFormat(end, 'EEEE', { locale })}
                            </Label>
                            <Paragraph className={cls([
                                'compact',
                                (!!end && ['rejected', 'canceled'].includes(status)) && 'strike'
                            ])}>
                                {!!end && safeFormat(end, 'PP', { locale })}
                                {(!end && !!manageAccess) && (
                                    <Plain
                                        className="constructive"
                                        onClick={edit}>
                                        <FormattedMessage
                                            id="absence_action_set_end_date"
                                            defaultMessage="Set end date"
                                        />
                                    </Plain>
                                )}
                                {(!end && !manageAccess) && (
                                    <FormattedMessage
                                        id="message_end_date_missing"
                                        defaultMessage="No end date" />
                                )}
                            </Paragraph>
                        </FlexChild>
                    </>
                )}
            </DateColumns>
            {!!allowGraded && (
                <Section>
                    <Paragraph className="caption compact">
                        <FormattedMessage
                            id="noun_percentage"
                            defaultMessage="Percentage" />
                    </Paragraph>
                    <div>
                        <Paragraph className={cls([
                            'compact',
                            ['rejected', 'canceled'].includes(status) && 'strike'
                        ])}>
                            <FormattedMessage
                                id="value_percent"
                                defaultMessage="{percent} %"
                                values={{ percent: decimalToPercent(grade) }} />
                        </Paragraph>
                    </div>
                </Section>
            )}
            {(!!days && !!manageAccess) && (
                <Section>
                    <Paragraph className="caption compact">
                        <FormattedMessage
                            id="absence_label_counts_as"
                            defaultMessage="Counts as" />
                    </Paragraph>
                    <div>
                        <Paragraph className={cls([
                            'compact',
                            ['rejected', 'canceled'].includes(status) && 'strike'
                        ])}>
                            <FormattedMessage
                                id="days_count"
                                defaultMessage="{count, plural, =0 {0 days} =1 {1 day} other {{count} days}}"
                                values={{ count: days }} />
                        </Paragraph>
                    </div>
                    {(status !== 'canceled') && (
                        <Estimate
                            start={start}
                            end={end}
                            grade={grade}
                            id={id}
                            user={user}
                            status={status}
                            absenceAdmin={absenceAdmin}
                            permissionLevel={permissionLevel}
                            type={type} />
                    )}
                </Section>
            )}
            <Status
                {...entry}
                type={type}
                absenceAdmin={adminAccess}
                salt={salt} />
            {(followUp && !!type?.followUp?.enabled && type?.name === 'sick') && (
                <FollowUp
                    user={user}
                    type={type}
                    start={start}
                    end={end}
                    followUp={followUp}
                    absenceAdmin={adminAccess} />
            )}
            {!!workRelated && (
                <InlineMessage
                    message={formatMessage({
                        id: 'absence_message_work_related',
                        defaultMessage: 'The cause of this absence is work related'
                    })}
                    type="info"
                    size="medium" />
            )}
        </>
    )
}

export default ViewEntryDetails
