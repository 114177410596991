import { useState, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useConfiguration } from 'contexts/configuration'
import { useOrganization } from 'contexts/organization'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import { getFullName, getShortName } from 'utilities/person'
import { pick } from 'utilities/object'
import { pruneBy } from 'utilities/array'
import PubSub from 'pubsub-js'

export const getCategoryTranslation = category => ({
    none: {
        id: 'document_label_category_none',
        defaultMessage: 'General document'
    },
    employmentContract: {
        id: 'document_label_category_employmentContract',
        defaultMessage: 'Employment contract'
    },
    salaryBonus: {
        id: 'document_label_category_salaryBonus',
        defaultMessage: 'Earned income'
    },
    agreementsDeclarations: {
        id: 'document_label_category_agreementsDeclarations',
        defaultMessage: 'Agreement / declaration'
    },
    policyInstructions: {
        id: 'document_label_category_policyInstructions',
        defaultMessage: 'Policy / instructions'
    },
    careerDevelopment: {
        id: 'document_label_category_careerDevelopment',
        defaultMessage: 'Career / professional development'
    },
    deviationAlert: {
        id: 'document_label_category_deviationAlert',
        defaultMessage: 'Deviation alert'
    },
})[category] ?? null

export const useCategories = () => {
    const { formatMessage } = useIntl()

    const { configuration } = useConfiguration()
    const [categories, setCategories] = useState([])

    useLayoutEffect(() => {
        setCategories([
            {
                value: 'none',
                text: formatMessage(getCategoryTranslation('none'))
            },
            ...(configuration?.enums.documentCategory ?? []).map(value => ({
                value,
                text: formatMessage(getCategoryTranslation(value))
            }))
        ])
    }, [])

    return {
        categories,
        getCategoryLabel: value => categories.find(category => category.value === value)?.text
    }
}

export const useCheckDocumentPermission = () => {
    const { isItMyOwnId } = useMe()

    const {
        check,
        checkModule,
        checkFeature
    } = useAccess()

    return (dokument, availability = false) => {
        if(!dokument) {
            return false
        }

        // Module and feature check only necessary if the consuming component doesn’t already check for it.
        // It’s left out by default to allow for things like marking context menu actions as upgradable.
        const documentsModule = availability ? checkModule('documents') : true
        const signingFeature = availability ? checkFeature('digital-signing') : true

        const owner = dokument.owners?.some(({ type, id }) => type === 'user' && isItMyOwnId(id))
        const indirect = dokument.permissions?.includes('unit:documents:manage')

        const editable = documentsModule && (owner || indirect)
        const signable = signingFeature && editable && (check('digital-signing:manage')/* || dokument.permissions?.includes('unit:document-signing:manage')*/)

        return {
            editable,
            signable
        }
    }
}

export const accessUnitTypes = ['organization', 'team', 'location', 'user']

export const useEnrichUnit = () => {
    const { organization } = useOrganization()

    const organizationUnit = {
        ...pick(organization, 'id', 'name'),
        type: 'organization'
    }

    return unit => {
        if(unit.type === 'organization') {
            return organizationUnit
        }

        if(unit.type === 'user') {
            unit.name = getFullName(unit)
        }

        return unit
    }
}

export const useCombineAccessUnits = () => {
    const enrichUnit = useEnrichUnit()

    return dokument => {
        if(!dokument) {
            return []
        }

        const shares = (dokument?.shares ?? []).map(enrichUnit)
        const owners = (dokument?.owners ?? []).map(enrichUnit)

        const sharedOnly = shares.filter(share => !owners.find(owner => share.id === owner.id))
        const ownersOnly = owners.filter(owner => !shares.find(share => owner.id === share.id))

        const sharedAndOwners = pruneBy([...shares, ...owners].filter(unit =>
            !sharedOnly.find(share => unit.id === share.id) &&
            !ownersOnly.find(owner => unit.id === owner.id)
        ), 'id')

        return [
            ...sharedOnly.map(unit => ({
                unit,
                access: {
                    share: true,
                    owner: false
                }
            })),
            ...ownersOnly.map(unit => ({
                unit,
                access: {
                    share: false,
                    owner: true
                }
            })),
            ...sharedAndOwners.map(unit => ({
                unit,
                access: {
                    share: true,
                    owner: true
                }
            }))
        ]
    }
}

export const useFormatGenericAccess = () => {
    const {
        formatMessage,
        formatList
    } = useIntl()

    const { isItMyOwnId } = useMe()

    return access => {
        if(!access) {
            return null
        }

        const { shares, owners } = access

        const accesses = pruneBy([...owners, ...shares])
        const publicAccess = accesses.find(({ type }) => type === 'organization')
        const userShares = accesses.filter(({ type }) => type === 'user')
        const members = shares.filter(({ type }) => ['team', 'location'].includes(type))
        const admins = owners.filter(({ type }) => ['team', 'location'].includes(type))

        const peopleText = !!userShares.length ?
            (userShares.length === 1) ?
                isItMyOwnId(userShares[0].id) ?
                    formatMessage({
                        id: 'noun_you',
                        defaultMessage: 'You'
                    })
                    : getShortName(userShares[0])
                : formatMessage({
                    id: 'people_count',
                    defaultMessage: "{count, plural, =1 {1 person} other {{count} people}}"
                }, { count: userShares.length })
            : null

        const membersText = !!members.length ?
            formatMessage({
                id: 'document_shared_group_members_formatted',
                defaultMessage: 'members of {formatted}'
            }, { formatted: formatList(members.map(({ name }) => name.trim()), { conjunction: 'and' }) })
            : null

        const adminsText = !!admins.length ?
            formatMessage({
                id: 'document_access_group_admins_formatted',
                defaultMessage: 'admins of {formatted}'
            }, { formatted: formatList(admins.map(({ name }) => name.trim()), { conjunction: 'and' }) })
            : null

        const finalUnits = [publicAccess?.name, peopleText, membersText, adminsText].filter(Boolean)

        // Adjust final unit count to match the plural grammar
        let unitCount

        if(finalUnits.length === 1) {
            switch(finalUnits[0]) {
                case publicAccess?.name:
                    unitCount = 1
                    break
                case peopleText:
                    unitCount = userShares.length === 1 ? 1 : userShares.length
                    break
                default:
                    unitCount = 2
                    break
            }
        } else {
            unitCount = finalUnits.length
        }

        return formatMessage({
            id: 'access_formatted_generic',
            defaultMessage: "{access_formatted_generic, plural, =0 {} =1 {{formatted} has access} other {{formatted} have access}}"
        }, {
            access_formatted_generic: unitCount,
            formatted: finalUnits.length < 3 ?
                formatList(finalUnits, { conjunction: 'and' })
                : formatMessage({
                    id: 'people_count',
                    defaultMessage: "{count, plural, =0 {No people} =1 {1 person} other {{count} people}}"
                }, { count: finalUnits.length }
            )
        })
    }
}

const identify = ({ unit }) => unit.id

export const splitAccessUnits = (accessUnits, includeRemoved = false) => ({
    shares: accessUnits
        .filter(({ access }) => access.share)
        .map(identify),
    owners: accessUnits
        .filter(({ access }) => access.owner)
        .map(identify),
    ...(includeRemoved ? {
        removed: {
            shares: accessUnits
                .filter(({ access }) => !access.share)
                .map(identify),
            owners: accessUnits
                .filter(({ access }) => !access.owner)
                .map(identify)
        }
    } : null)
})

export const removeNoneCategoryFromPayload = form => {
    if(form instanceof FormData && form.get('category') === 'none') {
        form.delete('category')
    } else if(form?.category === 'none') {
        delete form.category
    }

    return form
}

export const refreshSmartTemplates = () => PubSub.publish('smartTemplates.refresh')