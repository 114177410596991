const paths = {
    root: '/',

    login: '/login', // But we use root most of the time
    signup: '/signup',
    relinquish: '/relinquish',
    oauth: '/oauth',

    corporation: {
        base: '/corporation',
        insights: '/corporation/insights'
    },

    people: {
        base: '/people',
        profile: {
            base: '/people/:id',
            salary: '/people/:id/salary',
            absence: '/people/:id/absence',
            documents: '/people/:id/documents',
            meetings: '/people/:id/meetings',
            competence: '/people/:id/competence',
            equipment: '/people/:id/equipment',
            tasks: '/people/:id/tasks',

            settings: {
                base: '/people/me/settings',
                notifications: '/people/me/settings/notifications'
            }
        },

        teams: '/people/teams',
        team: {
            base: '/people/teams/:id',
            absence: '/people/teams/:id/absence',
            documents: '/people/teams/:id/documents',
            news: '/people/teams/:id/news',
            competence: '/people/teams/:id/competence',
            gap: '/people/teams/:id/gap',
            equipment: '/people/teams/:id/equipment',
            tasks: '/people/teams/:id/tasks',
            insights: '/people/teams/:id/insights'
        },

        locations: '/people/locations',
        location: {
            base: '/people/locations/:id',
            absence: '/people/locations/:id/absence',
            documents: '/people/locations/:id/documents',
            news: '/people/locations/:id/news',
            competence: '/people/locations/:id/competence',
            gap: '/people/locations/:id/gap',
            equipment: '/people/locations/:id/equipment',
            tasks: '/people/locations/:id/tasks',
            insights: '/people/locations/:id/insights'
        },

        organizationalChart: '/people/chart'
    },

    salary: {
        base: '/salary',
        overview: '/salary/overview',
        revisions: '/salary/revisions',
        revision: {
            base: '/salary/revisions/:id',
            setup: '/salary/revisions/:id/setup',
            group: '/salary/revisions/:id/:group'
        }
    },

    handbook: {
        base: '/handbook',
        category: '/handbook/:id',
        search: '/handbook/search',
        setup: '/handbook/setup'
    },

    absence: {
        base: '/absence',
        all: '/absence/all',
        calendar: '/absence/calendar',
        settings: '/absence/settings',
        setup: '/absence/setup',
        type: '/absence/types/:id',
        policy: {
            base: '/absence/policies/:id',
            new: '/absence/policies/:type/new',
            users: '/absence/policies/:id/users'
        }
    },

    meetings: {
        base: '/meetings',
        meeting: '/meetings/:id',
        rounds: {
            base: '/meetings/rounds',
            new: '/meetings/rounds/new',
            round: '/meetings/rounds/:id'
        },
        templates: {
            base: '/meetings/templates',
            template: '/meetings/templates/:id'
        }
    },

    news: {
        base: '/news',
        article: '/news/:id',
        edit: '/news/:id/edit',

        drafts: {
            base: '/news/drafts',
            draft: '/news/drafts/:id',
            edit: '/news/drafts/:id/edit'
        }
    },

    onboarding: {
        base: '/onboarding',
        new: '/onboarding/new',
        onboarding: '/onboarding/:id',
        archive: '/onboarding/archive',
        templates: '/onboarding/templates',
        template: '/onboarding/templates/:id'
    },

    offboarding: {
        base: '/offboarding',
        new: '/offboarding/new',
        archive: '/offboarding/archive',
        offboarding: '/offboarding/:id',
        templates: '/offboarding/templates',
        template: '/offboarding/templates/:id'
    },

    processes: {
        base: '/processes',
        new: '/processes/new',
        archive: '/processes/archive',
        process: '/processes/:id',
        templates: '/processes/templates',
        template: '/processes/templates/:id'
    },

    documents: {
        base: '/documents',
        document: '/documents/:id',
        templates: '/documents/templates',
        template: '/documents/templates/:id',
    },

    competence: {
        base: '/competence',
        types: '/competence/types',
        gap: '/competence/gap',
        profiles: '/competence/profiles',
        type: '/competence/types/:id'
    },

    equipment: {
        base: '/equipment',
        types: '/equipment/types',
        type: '/equipment/types/:id'
    },

    tasks: {
        base: '/tasks',
        delegated: '/tasks/delegated'
    },

    deviation: {
        base: '/deviation',
        case: {
            base: '/deviation/:id',
            new: '/deviation/new'
        },
        settings: '/deviation/settings'
    },

    insights: '/insights',

    employeeSatisfaction: {
        base: '/employee-satisfaction',
        run: '/employee-satisfaction/:id'
    },

    whistleblowing: {
        base: '/whistleblowing',
        archive: '/whistleblowing/archive',
        case: '/whistleblowing/:id',
        settings: '/whistleblowing/settings'
    },

    activityLog: '/activity-log',

    integrations: {
        base: '/integrations',
        integration: {
            base: '/integrations/:type',
            details: '/integrations/:type/details',
            log: '/integrations/:type/log'
        }
    },

    settings: {
        base: '/settings',

        modules: '/settings/modules',

        subscription: {
            base: '/settings/subscription',
            plans: '/settings/subscription/plans',
            invoices: '/settings/subscription/invoices'
        },

        customprofilefields: '/settings/custom-profile-fields',
        userprofile: '/settings/user-profile',
        jobTitle: {
            base: '/settings/job-title/:id',
            new: '/settings/job-title/new',
        },

        userroles: {
            base: '/settings/user-roles',

            role: {
                base: '/settings/user-roles/:id',
                grants: '/settings/user-roles/:id/grants'
            },

            standard: {
                base: '/settings/user-roles/standard',
                role: '/settings/user-roles/standard/:name'
            }
        },

        systemroles: {
            base: '/settings/system-roles',

            role: {
                base: '/settings/system-roles/:id',
                grants: '/settings/system-roles/:id/grants'
            }
        },


        domainverification: '/settings/domain-verification'
    }
}

export default paths