import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { useProcesses } from 'contexts/processes'
import { getTypeModule } from 'pages/processes/utilities'
import PersonField from 'components/form/field/person'
import { prune } from 'utilities/array'
import { get } from 'api'

const AddProcessDynamicAssignments = ({ templates, types, setTypes, salt }) => {
    const { formatMessage } = useIntl()

    const { fetchFromS3 } = useEnvironment()

    const { type } = useProcesses()

    const labels = {
        buddy: formatMessage({
            id: 'task_assign_to_buddy_onboarding',
            defaultMessage: 'Onboarding buddy'
        })
    }

    useEffect(() => {
        if(!templates?.length) {
            setTypes([])
        }

        const fetch = async () => {
            const results = await Promise.all(templates.map(({ id, providedByHuma = false, providedBy = null }) => {
                if(providedByHuma || providedBy) {
                    return fetchFromS3(`/templates/${getTypeModule(type)}/${id}.json`)
                }

                return get({ path: `/${type}-templates/${id}` })
            }))

            if(results.every(({ ok }) => ok)) {
                const templates = results.map(({ response }) => response)

                const types = templates
                    ?.flatMap(({ tasks }) => tasks)
                    ?.map(({ assignedTo }) => assignedTo)
                    ?.filter(assignedTo => typeof assignedTo === 'string')
                    ?.filter(assignedTo => !ignoredTypes.includes(assignedTo))

                setTypes(prune(types))
            }
        }

        if(templates?.length) {
            fetch()
        }
    }, [templates.map(({ id }) => id).join('+')])

    if(!types.length) {
        return null
    }

    return types.map(type => (
        <PersonField
            salt={salt}
            label={labels[type]}
            name={type}
            field={{
                required: true,
                include: 'always'
            }}
            picker={{ outer: false }}
            key={`${salt}:dynamic-assignee:${type}`} />
    ))
}

const ignoredTypes = ['concerns', 'responsible', 'supervisor']

export default AddProcessDynamicAssignments