import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useAccess } from 'contexts/access'
import { MenuItemLink, MenuItemSeparator } from './'

const moduleIdentifier = 'corporation'

const Corporation = props => {
    const { organization } = useOrganization()

    const {
        initialized,
        check
    } = useAccess()

    const access = !!organization?.subsidiaryCount && initialized && check('subsidiaries:manage')

    return (
        <>
            <MenuItemLink
                {...props}
                to="corporation.base"
                module={moduleIdentifier}
                access={access}
                upgradable={false}
                className="xscender">
                <FormattedMessage
                    id="noun_corporation"
                    defaultMessage="Corporation" />
            </MenuItemLink>
            <MenuItemSeparator animate={access ? 'show' : 'hide'} />
        </>
    )
}

export default Corporation