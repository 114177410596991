import React from 'react'
import View from './view'
import Edit from './edit'
import Reassigned from './reassigned'

const ActingTask = ({ mode = 'view', ...props }) => {
    const Task = {
        view: View,
        edit: Edit,
        reassigned: Reassigned
    }[mode]

    return (
        <Task {...props} />
            // provide={!!props.task}
            // taskId={props.task?.id} />
    )
}

export default ActingTask