import { useState, useRef, useCallback, useEffect } from 'react'

export const useDragHandle = (topStop = 80, bottomStop = 60) => {
    const layout = useRef()
    const resizeHandle = useRef()
    const isResizing = useRef(false)

    const [contentHeight, setContentHeight] = useState(80)

    const handleMouseMove = useCallback(event => {
        event.preventDefault()
        event.stopPropagation()

        if(isResizing.current) {
            const { clientY } = event.touches ? event.touches[0] : event
            const layoutRect = layout?.current?.getBoundingClientRect?.()

            if(!layoutRect) return

            const topStopPercentage = (topStop / layoutRect.height) * 100
            const bottomStopPercentage = (bottomStop / layoutRect.height) * 100

            const newContentHeight = Math.max(
                topStopPercentage,
                Math.min(100 - bottomStopPercentage, ((clientY - layoutRect.top) / layoutRect.height) * 100)
            )

            setContentHeight(newContentHeight)
        }
    }, [layout])

    const handleMouseUp = useCallback(() => {
        isResizing.current = false
        layout?.current.classList.remove('no-select')
        resizeHandle?.current.classList.remove('active')

        global.removeEventListener('mousemove', handleMouseMove)
        global.removeEventListener('mouseup', handleMouseUp)
        global.removeEventListener('touchmove', handleMouseMove, { passive: false })
        global.removeEventListener('touchend', handleMouseUp)
    }, [])

    const handleMouseDown = useCallback(() => {
        isResizing.current = true
        layout?.current.classList.add('no-select')
        resizeHandle?.current.classList.add('active')

        global.addEventListener('mousemove', handleMouseMove)
        global.addEventListener('mouseup', handleMouseUp)
        global.addEventListener('touchmove', handleMouseMove)
        global.addEventListener('touchend', handleMouseUp)
    }, [handleMouseMove, handleMouseUp])

    useEffect(() => {
        if(resizeHandle?.current) {
            resizeHandle.current.addEventListener('mousedown', handleMouseDown)
            resizeHandle.current.addEventListener('touchstart', handleMouseDown, { passive: false })
        }

        return () => {
            global.removeEventListener('mousemove', handleMouseMove)
            global.removeEventListener('mouseup', handleMouseUp)
            global.removeEventListener('touchmove', handleMouseMove)
            global.removeEventListener('touchend', handleMouseUp)

            const resizeHandleRef = resizeHandle

            if(resizeHandleRef.current) {
                resizeHandleRef.current.removeEventListener('mousedown', handleMouseDown)
                resizeHandleRef.current.removeEventListener('touchstart', handleMouseDown)
            }
        }
    }, [handleMouseMove, handleMouseUp])

    return {
        layout,
        resizeHandle,
        contentHeight,
        handleMouseDown
    }
}