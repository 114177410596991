import React from 'react'
import { useIntl } from 'react-intl'
import { useGetSeatsMeta } from 'utilities/payment'
import Message from 'components/message'

const AddPersonPriceCostIncrease = () => {
    const { formatMessage } = useIntl()

    const [getSeatsMeta, ready] = useGetSeatsMeta()
    if(!ready) {
        return null
    }

    const { exceeding: exceedingSeatsCount } = getSeatsMeta(1)
    if(!exceedingSeatsCount) {
        return null
    }

    return (
        <Message
            type="info"
            title={formatMessage({
                id: 'payment_subscription_add_user_price_increase_warning_title',
                defaultMessage: 'Cost increase when adding more users'
            })}
            message={formatMessage({
                id: 'payment_subscription_add_user_price_increase_warning',
                defaultMessage: 'Your subscription is currently maxed out. If you add another user, you will get a cost increase on your subscription that will last until end of term.'
            })}
            name="add:person:cost-increase:warning"
            defaultDismissed
            dismissedProps={{ className: 'spacious' }} />
    )
}

export default AddPersonPriceCostIncrease