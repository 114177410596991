import React, { Fragment } from 'react'
import { useGetMeta } from 'pages/processes/utilities'
import Link from 'components/link'

const ConcernsUnit = ({ concerns }) => {
    const getMeta = useGetMeta()
    const { name, to } = getMeta(concerns)

    if(!name && !to) {
        return null
    }

    const [ConcernsUnit, concernsUnitProps] = !!name ?
        [Link, {
            to,
            target: '_blank',
            children: name
        }]
        : [Fragment, { children: name }]

    return <ConcernsUnit {...concernsUnitProps} />
}

export default ConcernsUnit