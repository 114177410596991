import React, { useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import EquipmentPiecesProvider from 'contexts/equipment-pieces'
import { useOrganization } from 'contexts/organization'
import { get } from 'api'
import { size } from 'utilities/object'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import UpgradeModalContent from 'modals/upgrade/content'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import EntitiesField from 'components/form/field/entities'
import OneOfField from 'components/form/field/one-of'
import PeopleField from 'components/form/field/people'
import CheckboxField from 'components/form/field/checkbox'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const ExportEquipment = ({ onDone, salt }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const teamsRef = useRef()
    const locationsRef = useRef()
    const peopleRef = useRef()

    const { organization } = useOrganization()

    const [selectionType, setSelectionType] = useState([])
    const [teams, setTeams] = useState([])
    const [locations, setLocations] = useState([])
    const [people, setPeople] = useState([])
    const [generating, setGenerating] = useState(false)

    const generate = async ({ types, selectionType, teams, locations, people, ...params }) => {
        setGenerating(true)

        types = (types ?? []).map(({ id }) => id)

        params = {
            ...params,
            ...(types.length ? { types } : null)
        }

        if(selectionType === 'teams' && teams?.length) {
            params.teams = teams.map(({ id }) => id)
        }

        if(selectionType === 'locations' && locations?.length) {
            params.locations = locations.map(({ id }) => id)
        }

        if(selectionType === 'people' && people?.length) {
            params.users = people.map(({ id }) => id)
        }

        const { ok, response } = await get({
            path: '/equipment/export/download',
            params,
            binary: true
        })

        setGenerating(false)

        if(ok && response) {
            const timestamp = format(new Date(), formatMessage({
                id: 'date_fns_format_full_technical',
                defaultMessage: `y-MM-dd 'at' HH:mm`
            }), { locale })

            saveAs(response, `Huma Equipment Export ${timestamp}.xlsx`)
        }
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'equipment_action_export',
                    defaultMessage: 'Export equipment'
                })}
                dismiss={onDone} />
            <Form
                layout="vertical"
                onSubmit={generate}>
                {({ errors, trigger }) => (
                    <>
                        <EntitiesField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_equipment_types',
                                defaultMessage: 'Equipment types'
                            })}
                            name="types"
                            field={{
                                placeholder: formatMessage({
                                    id: 'equipment_export_equipment_types_placeholder',
                                    defaultMessage: 'Pick equipment types or leave empty for all'
                                })
                            }}
                            entity={{
                                type: 'equipmentType',
                                path: '/equipment-types'
                            }}
                            picker={{
                                heading: formatMessage({
                                    id: 'equipment_types_action_pick',
                                    defaultMessage: 'Pick equipment types'
                                }),
                                outer: false
                            }}
                            controlProps={{ autoFocus: true }} />
                        <OneOfField
                            salt={salt}
                            label={formatMessage({
                                id: 'equipment_export_assigned_to_label',
                                defaultMessage: 'Equipment assigned to'
                            })}
                            name="selectionType"
                            field={{
                                value: 'all',
                                include: 'always',
                                options: [
                                    {
                                        value: 'all',
                                        label: formatMessage({
                                            id: 'organization_everyone_in',
                                            defaultMessage: 'Everyone in {name}'
                                        }, organization),
                                        content: null
                                    },
                                    {
                                        value: 'teams',
                                        label: formatMessage({
                                            id: 'teams_members_of',
                                            defaultMessage: 'Members of teams'
                                        }),
                                        controlRef: teamsRef,
                                        content: (
                                            <EntitiesField
                                                salt={salt}
                                                className="compact"
                                                label={false}
                                                name="teams"
                                                field={{ value: teams }}
                                                picker={{
                                                    heading: formatMessage({
                                                        id: 'teams_action_pick',
                                                        defaultMessage: 'Pick teams'
                                                    }),
                                                    outer: false
                                                }}
                                                entity={{
                                                    type: 'team',
                                                    path: '/groups',
                                                    params: { types: ['team'] }
                                                }}
                                                onChange={({ teams }) => setTeams(teams)}
                                                ref={teamsRef} />
                                        )
                                    },
                                    {
                                        value: 'locations',
                                        label: formatMessage({
                                            id: 'locations_members_of',
                                            defaultMessage: 'Members of locations'
                                        }),
                                        controlRef: locationsRef,
                                        content: (
                                            <EntitiesField
                                                salt={salt}
                                                className="compact"
                                                label={false}
                                                name="locations"
                                                field={{ value: locations }}
                                                picker={{
                                                    heading: formatMessage({
                                                        id: 'locations_action_pick',
                                                        defaultMessage: 'Pick locations'
                                                    }),
                                                    outer: false
                                                }}
                                                entity={{
                                                    type: 'location',
                                                    path: '/groups',
                                                    params: { types: ['location'] }
                                                }}
                                                onChange={({ locations }) => setLocations(locations)}
                                                ref={locationsRef} />
                                        )
                                    },
                                    {
                                        value: 'people',
                                        label: formatMessage({
                                            id: 'people_individuals',
                                            defaultMessage: 'Individual people'
                                        }),
                                        controlRef: peopleRef,
                                        content: (
                                            <PeopleField
                                                salt={`${salt}:selection`}
                                                label={false}
                                                name="people"
                                                field={{ value: people }}
                                                picker={{ outer: false }}
                                                onChange={({ people }) => setPeople(people)}
                                                ref={peopleRef} />
                                        )
                                    }
                                ]
                            }}
                            onChange={({ selectionType }) => setSelectionType(selectionType)} />
                        <CheckboxField
                            salt={salt}
                            label={formatMessage({
                                id: 'equipment_export_include_unassigned_label',
                                defaultMessage: 'Include unassigned equipment'
                            })}
                            name="includeUnassigned"
                            field={{
                                value: true,
                                include: 'always'
                            }}
                            interaction="switch" />
                        <Actions className="compact">
                            <Plain
                                onClick={onDone}
                                disabled={generating}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${generating ? ' loading' : ''}`}
                                disabled={
                                    !!size(errors) ||
                                    (selectionType === 'teams' && !teams.length) ||
                                    (selectionType === 'locations' && !locations.length) ||
                                    (selectionType === 'people' && !people.length)
                                }
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_generate_report"
                                    defaultMessage="Generate export" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}


export default ({ upgradable, ...props }) => {
    const { formatMessage } = useIntl()

    if(upgradable) {
        return (
            <UpgradeModalContent
                heading={formatMessage({
                    id: 'equipment_action_export',
                    defaultMessage: 'Export equipment'
                })}
                dismiss={props.onDone}
                upgrade={{
                    feature: 'equipment-import-export',
                    useFeatureIcon: true,
                    useModuleOrFeatureDescription: true
                }}
                salt={props.salt} />
        )
    }

    return (
        <EquipmentPiecesProvider fetchOnMount={false}>
            <ExportEquipment {...props} />
        </EquipmentPiecesProvider>
    )
}