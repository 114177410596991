import styled from 'styled-components'
import { Grid } from 'components/grid'
import { HalfNakedButton } from 'components/button'

export const Buttons = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    column-gap: 16px;
`

export const ImportButton = styled(HalfNakedButton)`
    padding: 0 8px;

    color: var(--huma-color-foreground-constructive-bold);

    &:where(:hover, :focus) {
        color: var(--huma-color-foreground-constructive-bold-active);
    }
`