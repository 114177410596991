import React from 'react'
import { useProcessStatusTextFormatter } from './'
import { cls } from 'utilities/dom'
import { StatusLabel } from './s'

const ProcessStatusLabel = ({ status, className }) => {
    const formatter = useProcessStatusTextFormatter()
    const text = formatter(status)

    if(!text) {
        return null
    }

    className = cls([
        processStatusToClassName(status),
        className
    ])

    return (
        <StatusLabel className={className}>
            {text}
        </StatusLabel>
    )
}

export const processStatusToClassName = status => ({
    draft: 'neutral',
    active: 'active',
    completed: 'active',
    archived: 'positive'
})[status] ?? 'neutral'

export default ProcessStatusLabel