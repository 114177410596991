import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid } from 'components/grid'
import { CaptionSmall as CaptionSmallBase } from 'components/typography/caption'

export const Wrapper = styled(Grid)`
    ${({ theme: { absence: { hasAction } } }) => css`
        grid-template-columns: ${compact(['auto', hasAction && '40px']).join(' ')};
    `}
    column-gap: 16px;
    align-items: center;

    border-radius: 6px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);
`

export const Meta = styled(Grid)`
    grid-auto-rows: auto;
    grid-auto-flow: row;
`

export const CaptionSmall = styled(CaptionSmallBase)`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: start;
    align-items: center;
    column-gap: 4px;

    svg {
        ${svgStroke('small')}
    }
`