import React from 'react'
import Supervisor from './supervisor'
import Subordinates from './subordinates'

const EditHierachical = ({ what = 'supervisor', ...props }) => {
    const Field = {
        supervisor: Supervisor,
        subordinates: Subordinates
    }[what]

    return <Field {...props} />
}

export default EditHierachical