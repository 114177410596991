import * as colors from './colors'
import nav from './nav'
import message from './message'
import * as button from './button'

export default {
    color: colors.monochrome.base,
    background: colors.monochrome.five,
    shadow: colors.shadow,

    content: {
        color: colors.monochrome.base,
        background: colors.white,
        shadow: colors.shadow
    },

    widget: {
        color: colors.monochrome.base,
        background: colors.white,
        border: colors.border,
        shadow: colors.shadow
    },

    nav,
    message,
    button,
    colors
}
