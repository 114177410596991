import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompetenceTypes } from 'contexts/competence-types'
import { categories, categoryToMetaMap } from 'pages/competence/constants/category'
import { CategoryList, Category, Symbol, Meta, PickButton } from './s'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

const PickCategory = ({ onDone, salt }) => {
    const { types } = useCompetenceTypes()

    return (
        <CategoryList>
            {categories?.map(({ name, symbol }) => {
                const count = types?.filter(type => type.category === name)?.length ?? 0

                return (
                    <Category key={`${salt}:category:${name}`}>
                        <PickButton
                            className="constructive"
                            onClick={() => onDone(name)}>
                            <FormattedMessage
                                id="action_pick"
                                defaultMessage="Pick" />
                        </PickButton>
                        <Symbol
                            className="hover-effect"
                            symbol={symbol} />
                        <Meta>
                            <Paragraph className="compact">
                                <FormattedMessage {...categoryToMetaMap[name].name} />
                            </Paragraph>
                            <CaptionSmall className="compact">
                                <FormattedMessage
                                    {...categoryToMetaMap[name].count}
                                    values={{ count }} />
                            </CaptionSmall>
                        </Meta>
                    </Category>
                )
            })}
        </CategoryList>
    )
}

export default PickCategory
