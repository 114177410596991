import React, {
    Component, createContext, useContext, Children,
    useEffect, memo
} from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import isEqual from 'react-fast-compare'
import { size } from 'utilities/object'
import { prune, pruneAdjacent, last } from 'utilities/array'
import Tracking from './tracking'
import Meta from './meta'
import Upgrade from './upgrade'
import GateKeeper from './gate-keeper'

export const PageContext = createContext()

class PageProvider extends Component {
    constructor(props) {
        super(props)

        this.warn(props)

        const {
            trackView = true,
            setMeta = true,
        } = props

        this.state = {
            view: props.view,
            upgrade: props.upgrade,
            locale: props.locale,

            trackView,
            setMeta,

            parents: [
                ...(props.parent?.parents ?? []),
                ...(props.parent ? [props.parent] : [])
            ],

            titleSource: props.title,
            title: null,

            augmentSource: null,
            augment: null,

            titles: [],
            titlesCap: props.titlesCap ?? props.parent?.titlesCap ?? 2,

            translateTitle: this.translateTitle,
            setTitle: this.setTitle,
            augmentTitle: this.augmentTitle
        }
    }

    componentDidMount() {
        this.setTitle()
    }

    shouldComponentUpdate(nextProps, nextState) {
        const equalProps = isEqual(
            withoutFunctions(this.props),
            withoutFunctions(nextProps)
        )

        const equalState = isEqual(
            withoutFunctions(this.state),
            withoutFunctions(nextState)
        )

        return !equalProps || !equalState
    }

    componentDidUpdate({ view, upgrade, locale, parent, title, titlesCap }) {
        const state = {}

        if(!isEqual(view, this.props.view)) {
            state.view = this.props.view
        }

        if(!isEqual(upgrade, this.props.upgrade)) {
            state.upgrade = this.props.upgrade
        }

        if(locale !== this.props.locale) {
            state.locale = this.props.locale
        }

        if(!isEqual(withoutFunctions(parent ?? {}), withoutFunctions(this.props.parent ?? {}))) {
            state.parents = [
                ...(this.props.parent?.parents ?? []),
                ...(this.props.parent ? [this.props.parent] : [])
            ]
        }

        if(!isEqual(title, this.props.title)) {
            state.titleSource = this.props.title
        }

        if(titlesCap !== this.props.titlesCap) {
            state.titlesCap = this.props.titlesCap
        }

        if(!!size(state)) {
            this.setState(state, () => {
                if(state.locale || state.parents || !!state.titleSource) {
                    this.setTitle()
                }
            })
        }
    }

    translateTitle = (titleSource = this.state.titleSourceSource) => {
        if(!titleSource) {
            return null
        }

        let title = titleSource

        if(typeof titleSource !== 'string') {
            if(Array.isArray(titleSource)) {
                title = this.props.formatMessage(...title)
            } else {
                title = this.props.formatMessage(title)
            }
        }

        return title?.replace(/\u00AD/g, '')
    }

    setTitle = (titleSource = this.state.titleSource) => {
        const title = this.translateTitle(titleSource)

        this.setState(({ augment, parents }) => ({
            titleSource,
            title: augment ?? title,
            titles: pruneAdjacent([
                augment,
                title,
                ...(last(parents)?.titles || [])
            ].flat(2))
        }))
    }

    augmentTitle = augmentSource => {
        const augment = this.translateTitle(augmentSource)

        this.setState(({ title, augment: previousAugment, titles }) => {
            if(!augment || title === augment) {
                return null
            }

            const augmented = prune([augment, ...[titles.slice(!!previousAugment ? 1 : 0)]])

            return {
                title: augment,
                augmentSource,
                augment,
                titles: prune([
                    augmented,
                    titles.slice(1)
                ].flat(2))
            }
        })
    }

    warn = ({ upgrade = {}, children }) => {
        if(process.env.NODE_ENV !== 'development') {
            return
        }

        if(upgrade.enabled && !upgrade.passthrough && (!upgrade.tutorial && !upgrade.content)) {
            return void console.warn(`Page at ${global.location.pathname} is upgradable but has no tutorial or custom content defined, nor is passthrough set to true, which means that nothing useful will be rendered.`)
        }

        if(upgrade.enabled && upgrade.passthrough && (upgrade.tutorial || upgrade.content)) {
            return void console.warn(`Page at ${global.location.pathname} is upgradable but set to passthrough, which means that the tutorial and/or content are superfluous.`)
        }

        if(!upgrade.enabled && !Children.toArray(children).length) {
            return void console.warn(`Page at ${global.location.pathname} is not upgradable and has no [output from] children, which means that it will render nothing.`)
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <PageContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </PageContext.Provider>
        )
    }
}

const withoutFunctions = data => Object.entries(data)
    .reduce((data, [key, value]) => {
        if(typeof value !== 'function') {
            data[key] = value
        }

        return data
    }, {})

export const usePage = () => useContext(PageContext)

export const useSetTitle = title => {
    const {
        title: previousTitle,
        setTitle
    } = usePage()

    useEffect(() => {
        if(!!title && title !== previousTitle) {
            setTitle(title)
        }
    }, [title, setTitle])
}

export const useAugmentTitle = augment => {
    const {
        augment: previousAugment,
        augmentTitle
    } = usePage()

    useEffect(() => {
        if(!!augment && augment !== previousAugment) {
            augmentTitle(augment)
        }
    }, [augment, augmentTitle])
}

export default memo(({ children, ...props }) => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()

    const parent = usePage()

    return (
        <PageProvider
            {...props}
            parent={parent}
            formatMessage={formatMessage}
            locale={locale}>
            <Tracking />
            <Meta />
            <Upgrade />
            <GateKeeper>{children}</GateKeeper>
        </PageProvider>
    )
})