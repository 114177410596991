import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { usePossum } from 'hooks/possum'
import { getFullName, getShortName } from 'utilities/person'
import { getPeopleProfileTasksUrl, getGroupUrl } from 'utilities/url'
import paths from 'app/paths'
import Message from 'components/message'
import LinkRow from 'components/typography/paragraph'
import Actions from 'components/form/actions'
import Link from 'components/link'
import { Plain } from 'components/button'

const TaskReassigned = ({ reassigned, onDone }) => {
    const { formatMessage } = useIntl()

    const { isItMyOwnId } = useMe()
    const possessify = usePossum()

    const {
        assignedGroup,
        assignedTo
    } = reassigned

    const assignedToMyself = assignedTo && isItMyOwnId(assignedTo.id)

    const message = formatMessage({
        id: (assignedGroup && assignedTo) ?
            assignedToMyself ?
                'task_success_reassigned_group_and_self' :
                'task_success_reassigned_group_and_person' :
                assignedGroup ?
                    'task_success_reassigned_group' :
                    assignedToMyself ?
                        'task_success_reassigned_self' :
                        'task_success_reassigned_person',
        defaultMessage: (assignedGroup && assignedTo) ?
            assignedToMyself ?
                'This task has been assigned to you in {group}.' :
                'This task has been assigned to {person} in {group}.' :
                assignedGroup ?
                    'This task has been assigned to {group} and can be found there.' :
                    assignedToMyself ?
                        'This task has been assigned to you and can be found in your tasks.' :
                        'This task has been assigned to {person} and can be found on their profile.'
    }, {
        group: assignedGroup?.name ?? '',
        person: assignedTo ? getFullName(assignedTo) : ''
    })

    return (
        <>
            <Message
                type="success"
                className="compact"
                message={message}>
                {!!assignedGroup && (
                    <LinkRow className="compact">
                        <Link
                            to={getGroupUrl({ ...assignedGroup, pane: 'tasks' })}
                            target="_blank">
                            <FormattedMessage
                                id="tasks_action_see_group_tasks"
                                defaultMessage="See tasks in {name}"
                                values={assignedGroup} />
                        </Link>
                    </LinkRow>
                )}
                {!!assignedTo && (
                    <LinkRow className="compact">
                        <Link
                            to={assignedToMyself ?
                                paths.tasks.base :
                                getPeopleProfileTasksUrl(assignedTo)
                            }
                            target="_blank">
                            <FormattedMessage
                                id={assignedToMyself ?
                                    'tasks_action_see_yours' :
                                    'tasks_action_see_person_tasks_possessive'
                                }
                                defaultMessage={assignedToMyself ?
                                    'See your tasks' :
                                    'See {pname} tasks'}
                                values={{ pname: possessify(getShortName(assignedTo)) }} />
                        </Link>
                    </LinkRow>
                )}
            </Message>
            <Actions className="centered">
                <Plain onClick={() => onDone()}>
                    <FormattedMessage
                        id="action_close"
                        defaultMessage="Close" />
                </Plain>
            </Actions>
        </>
    )
}

export default TaskReassigned
