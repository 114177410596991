import React from 'react'
import { createPortal } from 'react-dom'
import { useHandbook } from 'contexts/handbook'
import { compact } from 'utilities/array'
import {
    SortableItem, Topic,
    Cell, TitleCell, SharesCell
} from './s'
import DragHandle from '../../../drag-handle'
import { DisplayEmoji as Emoji } from 'components/symbol'
import Status from 'pages/handbook/components/status'
import { Units as Shares } from 'components/units'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const OrderTopic = ({
    topic, actions = {},
    provided, snapshot,
    salt
}) => {
    const { handbook } = useHandbook()

    const portal = document.getElementById('sortable')

    const { isDragging = false } = snapshot ?? {}

    const {
        title,
        symbol,
        shares = []
    } = topic

    const boundActions = getBoundActions(actions, topic)

    const child = (
        <SortableItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            $isDragging={isDragging}>
            <DragHandle />
            <Topic>
                <TitleCell>
                    <Emoji
                        emoji={symbol.emoji}
                        size={20} />
                    <span>{title}</span>
                </TitleCell>
                <Cell>
                    <Status
                        status={compact([handbook?.status, topic?.status ?? 'draft']).join('_')}
                        tooltip={false} />
                </Cell>
                <SharesCell>
                    <Shares
                        units={shares}
                        publicWhenEmpty={true}
                        inline={true}
                        max={1} />
                </SharesCell>
                {!!boundActions?.length && (
                    <Cell>
                        <ContextMenu
                            prebound actions={boundActions}
                            appendTo={document.body}
                            salt={salt} />
                    </Cell>
                )}
            </Topic>
        </SortableItem>
    )

    if(!isDragging) {
        return child
    }

    return createPortal(child, portal)
}

export default OrderTopic
