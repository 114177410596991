import React from 'react'
import { renderToString } from 'react-dom/server'
import { OneLiner, Bullets, Bullet } from './s'

const lineBreak = /\r?\n|\r/g

const Description = ({ description, forceList = false, salt }) => {
    if(!description?.length) {
        return null
    }

    description = renderToString(description)

    const lines = description.split(lineBreak)
    if(lines.length > 1 || forceList) {
        return (
            <Bullets>
                {description
                    .split(lineBreak)
                    .map((item, index) => (
                    <Bullet
                        dangerouslySetInnerHTML={{ __html: item }}
                        key={`${salt}:description:${index}`} />
                ))}
            </Bullets>
        )
    }

    return <OneLiner dangerouslySetInnerHTML={{ __html: description }} />
}

export default Description