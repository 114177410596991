import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useHandbook } from 'contexts/handbook'
import { cls } from 'utilities/dom'
import { Hero, HeroColumns, SetupButton, ActionColumn, Modal } from './s'
import ModuleTitle from 'components/module-title'
import { ButtonArrow } from 'components/button'
import TemplatePicker from 'pages/handbook/components/template-picker'
import PreviewTemplate from 'pages/handbook/modals/template/preview'

const HandbookMainPageHero = ({ upgradable, salt }) => {
    const {
        handbook,
        hasFetched
    } = useHandbook()

    const { check } = useAccess()
    const manageAccess = check('handbook:manage')

    const [settingUp, setSettingUp] = useState(null)
    const [previewing, setPreviewing] = useState(null)

    const hasHandbook = !!handbook && hasFetched

    const className = cls([
        upgradable && 'compact'
    ])

    return (
        <Hero {...(className ? { className } : null)}>
            <HeroColumns {...(upgradable ? { className: 'compact' } : null)}>
                <ModuleTitle
                    which="handbook"
                    {...(upgradable ? { className: 'spacious' } : null)} />
                {(!hasHandbook && !upgradable && manageAccess) && (
                    <ActionColumn>
                        <SetupButton
                            to="path:handbook.setup"
                            className="constructive">
                            <FormattedMessage
                                id="handbooks_action_set_up"
                                defaultMessage="Set up the handbook" />
                        </SetupButton>
                        <ButtonArrow />
                    </ActionColumn>
                )}
            </HeroColumns>
            <Modal
                show={!!settingUp}
                dismiss={() => setSettingUp(null)}
                className="large"
                fixed={true}
                salt={`${salt}:add-from-template`}>
                <TemplatePicker
                    {...settingUp}
                    dismiss={() => setSettingUp(null)}
                    onDone={({ picked }) => {
                        if(picked) {
                            setPreviewing({
                                ...picked,
                                context: settingUp.context
                            })
                        }

                        setSettingUp(null)
                    }}
                    salt={`${salt}:add-from-template`} />
            </Modal>
            <Modal
                className="large"
                show={previewing}
                dismiss={() => setPreviewing(null)}>
                <PreviewTemplate
                    {...previewing}
                    dismiss={() => setPreviewing(null)}
                    salt={`${salt}:preview`} />
            </Modal>
        </Hero>
    )
}

export default HandbookMainPageHero