import React, { useState, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Page from 'contexts/page'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { useAppSignal } from 'hooks/app-signal'
import { useLocationStateIntent } from 'hooks/location-intent'
import Hero from './hero'
import {
    Layout, Content, Aside, LoadingContainer, Actions,
    InlineTrigger, InlineButton, AddButton,
    NotMobileHandbookContacts, MobileHandbookContacts
} from '../s'
import Loader from 'components/loader'
import Message from 'components/message'
import Link from 'components/link'
import { Plain } from 'components/button'
import Category from 'pages/handbook/components/category'
import Empty from 'components/typography/caption'
import { Plus } from 'styled-icons/feather'
import PinnedTopics from 'pages/handbook/components/pinned-topics'
import { Scrollable as Modal } from 'modals/generic'
import EditHandbookCategory from 'pages/handbook/modals/category/edit'
import { TopicModal } from 'pages/handbook/pages/s'
import ActingHandbookTopic from 'pages/handbook/modals/topic'
import Successful from 'pages/handbook/modals/successful'
import TemplatePicker from 'pages/handbook/components/template-picker'
import PreviewTemplate from 'pages/handbook/modals/template/preview'
import EditOrder from 'pages/handbook/modals/edit-order'

const HandbookMainPage = ({ salt, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        handbook,

        filter,
        fetchHandbook,
        targetTopicCountAfterImport
    } = useHandbook()

    const { categories = [] } = handbook ?? {}

    const [editingCategory, setEditingCategory] = useState(null)
    const [actingTopic, setActingTopic] = useState(null)
    const [successfulMessage, setSuccessfulMessage] = useState(null)
    const [addingFromTemplate, setAddingFromTemplate] = useState(null)
    const [previewing, setPreviewing] = useState(null)
    const [editingOrder, setEditingOrder] = useState(false)

    const { hasFetched } = useHandbook()

    const { check } = useAccess()
    const manageAccess = check('handbook:manage')

    useAppSignal({
        namespace: 'handbook:add-category',
        action: () => setEditingCategory({ index: categories.length })
    })

    useLocationStateIntent({
        intent: 'handbook:add-category',
        action: () => setEditingCategory({ index: categories.length })
    })

    useAppSignal({
        namespace: 'handbook:add-from-template',
        action: (options = null) => setAddingFromTemplate({
            context: 'template',
            ...options
        })
    })

    useLocationStateIntent({
        intent: 'handbook:add-from-template',
        action: () => setAddingFromTemplate({ context: 'template' })
    })

    if(!hasFetched) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    const {
        expanded,
        setExpanded
    } = props

    const isEverythingImported = (!!handbook && targetTopicCountAfterImport !== null) ?
        targetTopicCountAfterImport === (categories?.flatMap(({ topics = [] }) => topics).length ?? 0) :
        true

    return (
        <>
            <Hero salt={salt} />
            <Layout>
                <Content>
                    {(!!handbook?.categories?.length) && (
                        <Actions>
                            <Plain
                                className="constructive"
                                onClick={() => setExpanded(() => !expanded)}>
                                <FormattedMessage
                                    id={expanded ?
                                        'handbooks_categories_action_collapse' :
                                        'handbooks_categories_action_expand'
                                    }
                                    defaultMessage={expanded ?
                                        'Collapse categories' :
                                        'Expand categories'
                                    } />
                            </Plain>
                            {(manageAccess && !filter?.viewAs) && (
                                <Plain
                                    className="constructive"
                                    onClick={() => setEditingOrder(true)}>
                                    <FormattedMessage
                                        id="action_edit_order"
                                        defaultMessage="Edit order" />
                                </Plain>
                            )}
                        </Actions>
                    )}
                    {categories.map((category, index) => (
                        <Fragment key={category.id}>
                            {(manageAccess && !filter?.viewAs) && (
                                <InlineTrigger onClick={() => setEditingCategory({ index })}>
                                    <InlineButton
                                        className="constructive"
                                        onClick={() => setEditingCategory({ index })}>
                                        <Plus size={24} />
                                    </InlineButton>
                                </InlineTrigger>
                            )}
                            <Category
                                {...props}
                                category={category}
                                setActingTopic={setActingTopic}
                                key={category.id} />
                        </Fragment>
                    ))}
                    {(!!isEverythingImported && !categories.length) && (
                        <Empty>
                            <FormattedMessage
                                id="handbooks_empty"
                                defaultMessage="No categories or topics have been added yet." />
                        </Empty>
                    )}
                    {!isEverythingImported && (
                        <>
                            <Message
                                type="info"
                                message={formatMessage({
                                    id: !categories?.length ?
                                        'handbooks_import_refresh_instruction_without_categories' :
                                        'handbooks_import_refresh_instruction_with_categories',
                                    defaultMessage: !categories?.length ?
                                        'The handbook template is being imported in the background. You can safely go about your business and check back in a little while. If you don’t see everything you’ve imported just yet, you can <link>reload the handbook</link>.' :
                                        'The handbook template is being imported in the background. If you don’t see everything you’ve imported just yet, you can <link>reload the handbook</link>.'
                                }, {
                                    link: chunks => <Link onClick={fetchHandbook}>{chunks}</Link>
                                })} />
                            {!categories?.length && <Loading />}
                        </>
                    )}
                    {(manageAccess && !filter?.viewAs) && (
                        <>
                            <AddButton
                                className="constructive"
                                onClick={() => setEditingCategory({
                                    index: categories.length
                                })}>
                                <Plus size={24} />
                                <FormattedMessage
                                    id="handbooks_category_action_add"
                                    defaultMessage="Add category" />
                            </AddButton>
                            <AddButton
                                className="constructive"
                                disabled={!isEverythingImported}
                                onClick={() => setAddingFromTemplate({ context: 'template' })}>
                                <Plus size={24} />
                                <FormattedMessage
                                    id="action_add_from_template"
                                    defaultMessage="Add from template" />
                            </AddButton>
                        </>
                    )}
                </Content>
                <Aside>
                    <PinnedTopics salt={salt} />
                    <NotMobileHandbookContacts salt={salt} />
                </Aside>
            </Layout>
            <MobileHandbookContacts salt={salt} />
            <Modal
                show={editingCategory}
                dismiss={() => setEditingCategory(null)}
                salt={`${salt}:edit`}>
                <EditHandbookCategory
                    category={editingCategory}
                    dismiss={() => setEditingCategory(null)}
                    salt={`${salt}:edit`} />
            </Modal>
            <Modal
                show={!!addingFromTemplate}
                dismiss={() => setAddingFromTemplate(null)}
                className="large"
                fixed={true}
                salt={`${salt}:add-from-template`}>
                <TemplatePicker
                    {...addingFromTemplate}
                    dismiss={() => setAddingFromTemplate(null)}
                    onDone={({ picked }) => {
                        if (picked) {
                            setPreviewing({
                                ...picked,
                                context: addingFromTemplate.context
                            })
                        }
                        setAddingFromTemplate(null)
                    }}
                    salt={`${salt}:add-from-template`} />
            </Modal>
            <TopicModal
                className="large"
                show={!!actingTopic}
                dismiss={() => setActingTopic(null)}
                salt={`${salt}:acting`}>
                <ActingHandbookTopic
                    {...actingTopic}
                    view={() => setActingTopic(topic => ({
                        ...topic,
                        mode: 'view'
                    }))}
                    edit={() => setActingTopic(topic => ({
                        ...topic,
                        mode: 'edit'
                    }))}
                    dismiss={() => setActingTopic(null)}
                    onDone={response => {
                        if(!!response?.notify) {
                            setSuccessfulMessage({ what: 'topic' })
                        }
                    }}
                    salt={`${salt}:acting`} />
            </TopicModal>
            <Modal
                show={successfulMessage}
                dismiss={() => setSuccessfulMessage(null)}
                salt={`${salt}:successful`}>
                <Successful
                    {...successfulMessage}
                    dismiss={() => setSuccessfulMessage(null)}
                    salt={`${salt}:successful`} />
            </Modal>
            <Modal
                className="large"
                show={!!previewing}
                dismiss={() => setPreviewing(null)}>
                <PreviewTemplate
                    {...previewing}
                    dismiss={() => setPreviewing(null)}
                    salt={`${salt}:preview`} />
            </Modal>
            <EditOrder
                show={editingOrder}
                dismiss={() => setEditingOrder(false)}
                salt={salt} />
        </>
    )
}

const Loading = () => (
    <LoadingContainer>
        <Loader />
    </LoadingContainer>
)

export default ({ upgradable, ...props }) => (
    <Page
        view="handbook"
        title={{
            id: 'noun_handbook',
            defaultMessage: 'Handbook'
        }}
        upgrade={{
            enabled: upgradable,
            title: 'handbook',
            tutorial: {
                which: 'handbook',
                upgrade: { module: 'handbook' }
            }
        }}>
        <HandbookMainPage {...props} />
    </Page>
)