import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'components/form/controller'
import { size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { validate } from 'modals/address-filler'
import { BundleName } from 'components/payment/plan'
import Buttons from 'components/form/actions'
import { Plain, ButtonSubmit, BenjaminSubmit, Button } from 'components/button'

const Actions = ({ change, swap, from, to, warning = false, ...props }) => {
    const {
        values,
        errors,
        trigger
    } = useForm()

    const {
        disabled,
        loading,
        dismiss,
        advance,
        handleRecurringChallenge
    } = props

    let translation = {
        id: !!from?.code ?
            'action_update' :
            'action_save',
        defaultMessage: !!from?.code ?
            'Update' :
            'Save'
    }

    if(handleRecurringChallenge || change === 'term') {
        translation = translations.confirm
    } else if(swap && !change) {
        translation = translations.swap
    } else if(change in translations) {
        translation = translations[change]

        if(['purchase', 'upgrade', 'downgrade'].includes(change)) {
            translation.values = { plan: <BundleName bundle={to} /> }
        }
    }

    const Submit = ['purchase', 'upgrade'].includes(change) ?
        BenjaminSubmit :
        ButtonSubmit

    const className = cls([
        'compact',
        (change === 'freeload') && 'centered'
    ])

    return (
        <Buttons {...(className ? { className } : null)}>
            <Plain
                onClick={dismiss}
                className="neutral">
                <FormattedMessage
                    id={(change === 'freeload') ?
                        'action_close' :
                        'action_cancel'
                    }
                    defaultMessage={(change === 'freeload') ?
                        'Close' :
                        'Cancel'
                    } />
            </Plain>
            {(change !== 'freeload') && (
                <>
                    {!!warning && (
                        <Button
                            className="neutral"
                            onClick={advance}>
                            <FormattedMessage
                                id="action_continue"
                                defaultMessage="Continue" />
                        </Button>
                    )}
                    {(!warning && !!translation) && (
                        <Submit
                            className={`${['modify', 'term'].includes(change) ? 'constructive' : 'neutral'}${loading ? ' loading' : ''}`}
                            disabled={!!size(errors) || (!!values.address && !validate(values.address)) || disabled || loading}
                            ref={trigger}>
                            <FormattedMessage {...translation} />
                        </Submit>
                    )}
                </>
            )}
        </Buttons>
    )
}

export default Actions

const translations = {
    purchase: {
        id: 'payment_plan_action_purchase',
        defaultMessage: 'Buy {plan}'
    },
    upgrade: {
        id: 'payment_plan_action_upgrade',
        defaultMessage: 'Upgrade to {plan}'
    },
    modify: {
        id: 'action_update',
        defaultMessage: 'Update'
    },
    swap: {
        id: 'subscription_action_switch',
        defaultMessage: 'Switch subscription'
    },
    downgrade: {
        id: 'payment_plan_action_downgrade',
        defaultMessage: 'Downgrade to {plan}'
    },
    cancel: {
        id: 'subscription_action_cancel',
        defaultMessage: 'Cancel subscription'
    },
    confirm: {
        id: 'action_confirm',
        defaultMessage: 'Confirm'
    }
}