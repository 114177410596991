import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import paths from 'app/paths'
import { useTimeout } from 'hooks/timeout'
import { Container } from './s'
import Loader from 'components/loader'

const RelinquishPage = () => {
    const [terminated, setTerminated] = useState(false)
    const terminate = () => setTerminated(true)
    useTimeout(terminate, 5000)

    useEffect(() => {
        global.addEventListener('focus', terminate)
        return () => global.removeEventListener('focus', terminate)
    }, [])

    if(terminated) {
        return <Navigate to={paths.root} replace />
    }

    return (
        <Container>
            <Loader />
        </Container>
    )
}

export default RelinquishPage
