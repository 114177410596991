import React, { useState, useRef, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCompetenceRecords } from 'contexts/competence-records'
import { useMe } from 'contexts/me'
import { useOrganization } from 'contexts/organization'
import { size, omit } from 'utilities/object'
import { getShortName } from 'utilities/person'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { useLevelOptionRenderer } from 'pages/competence/components/level/option'
import { categoryToAddTextMap } from 'pages/competence/constants/category'
import { TimeField, Helper, LevelDefinitionsButton } from './s'
import { H2 as Heading } from 'components/typography/heading'
import Form from 'components/form/controller'
import Message from 'components/message'
import WordList from 'components/word-list'
import EntityField from 'components/form/field/entity'
import PeopleField from 'components/form/field/people'
import PersonField from 'components/form/field/person'
import StringField from 'components/form/field/string'
import ContractableSelectField from 'components/form/field/contractable-select'
import { Scrollable as Modal } from 'modals/generic'
import LevelDefinitions from 'pages/competence/components/level/definitions'
import { Value as LevelLabel } from 'pages/competence/components/level/value/s'
import TextField from 'components/form/field/text'
import CheckboxField from 'components/form/field/checkbox'
import Divider from 'components/divider'
import ExpiryStatus from 'components/expiry-status'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const EditCompetenceRecord = ({
    record,
    type: outsideType = null,
    category: outsideCategory = null,
    context = 'types',
    user: outsideUser,
    dismiss,
    onDone,
    setActing: setActingRecord,
    setActingType,
    salt
}) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    const typeRef = useRef()
    const concernsRef = useRef()

    const {
        addRecord,
        updateRecord
    } = useCompetenceRecords()

    const { isItMyOwnId } = useMe()

    const { organization } = useOrganization()

    const getLevelOption = useLevelOptionRenderer()

    const [triggered, setTriggered] = useState(false)
    const [type, setType] = useState(record?.type ?? outsideType)
    const [users, setUsers] = useState(outsideUser ? [outsideUser] : [])
    const [working, setWorking] = useState(false)
    const [resetAt, setResetAt] = useState(Date.now())
    const [previouslyAdded, setPreviouslyAdded] = useState(null)
    const [showingDefinitions, setShowingDefinitions] = useState(false)

    const {
        id,
        attachments = [],
        note,
        concerns,

        // Certification
        validFrom,
        validTo,

        // Education
        school,
        fieldOfStudy,

        // … and Experience
        startDate,
        endDate,

        // Language
        isNativeLanguage,

        // … and Skill
        level
    } = record ?? {}

    useEffect(() => {
        if (!!typeRef?.current && !triggered) {
            if (!record?.id) {
                typeRef.current.click()
            }

            setTriggered(true)
        }
    }, [typeRef?.current, triggered])

    const {
        documentationRequired = false,
        startDateRequired = false,
        endDateRequired = false,
        validFromRequired = false,
        validToRequired = false,
        fieldOfStudyRequired = false,
        schoolRequired = false,
        levelRequired = false
    } = type ?? {}

    const category = type?.category ?? outsideCategory

    const addOrUpdate = async (body, { resetTouched, reset }) => {
        if(working) {
            return
        }

        setWorking(true)

        body = {
            ...body,
            ...(!id ? { concernsIds: users?.map(({ id }) => id) } : null),
            ...(!!id ? { concerns: concerns.id } : null),
            category
        }

        const addOrUpdateRecord = id ?
            updateRecord :
            addRecord

        const { ok, response } = await addOrUpdateRecord(omit(body, 'addAnother'), id)

        setWorking(false)

        if(ok) {
            resetTouched()

            if(!id && !!body?.addAnother) {
                reset()
                setResetAt(Date.now())

                setPreviouslyAdded({
                    name: categoryFormatter(type),
                    users
                })

                setUsers(outsideUser ? [outsideUser] : [])

                if(outsideType) {
                    concernsRef.current.click()
                } else {
                    typeRef.current.click()
                }

                return
            }

            if(!id && documentationRequired && response.length === 1) {
                return void setActingRecord({
                    mode: 'documentation',
                    record: response[0]
                })
            }

            if(!id && documentationRequired && response.length > 1) {
                setActingType?.({
                    mode: 'view',
                    type: response[0].type
                })
            }

            if(!!id && documentationRequired && !attachments?.length) {
                return void setActingRecord({
                    mode: 'documentation',
                    record: response
                })
            }

            onDone?.()
        }
    }

    const levelsCount = type?.max ?? 5

    salt = `${salt}:edit`

    return (
        <>
            {!!previouslyAdded && (
                <Message
                    type="success"
                    message={formatMessage({
                        id: 'competence_message_record_added_multiple',
                        defaultMessage: '”{name}” was added as a competence for {users}.'
                    }, {
                        name: previouslyAdded.name,
                        users: <WordList words={previouslyAdded.users.map(getShortName)} />
                    })} />
            )}
            <Heading>
                <FormattedMessage
                    id={id ?
                        'competence_action_update' :
                        'competence_action_add'
                    }
                    defaultMessage={id ?
                        'Update competence' :
                        'Add competence'
                    } />
            </Heading>
            <Form
                layout="vertical"
                onSubmit={addOrUpdate}>
                {({ touched, errors, trigger }) => (
                    <>
                        <EntityField
                            salt={salt}
                            label={formatMessage({
                                id: 'competence_type_label_type',
                                defaultMessage: 'Competence type'
                            })}
                            name="typeId"
                            field={{
                                value: {
                                    ...omit(type ?? {}, 'description', 'documentationRequired'),
                                    category
                                },
                                required: true,
                                include: 'always',
                                unsettable: false
                            }}
                            toggler={{ mode: 'with-actions' }}
                            controlProps={{
                                className: 'framed centered',
                                autoFocus: true
                            }}
                            picker={{
                                heading: formatMessage({
                                    id: 'competence_type_action_pick',
                                    defaultMessage: 'Pick competence type'
                                }),
                                creatable: !outsideType,
                                creator: {
                                    context,
                                    category,
                                    heading: formatMessage(categoryToAddTextMap[category])
                                },
                                ...(category !== 'language' ? {
                                    search: {
                                        controlProps: {
                                            placeholder: formatMessage({
                                                id: 'competence_type_placeholder_find_type',
                                                defaultMessage: 'Find competence type'
                                            })
                                        }
                                    }
                                } : null),
                                refreshOnDismiss: true
                            }}
                            entity={{
                                type: 'competenceType',
                                path: '/competence-types',
                                params: { category },
                                mapper: competenceType => ({
                                    ...competenceType,
                                    category
                                })
                            }}
                            enabled={!outsideType}
                            onChange={({ typeId: type }) => setType(type)}
                            key={`${salt}:typeId:${resetAt}`}
                            ref={typeRef} />
                        {(!id && !outsideUser) && (
                            <PeopleField
                                salt={salt}
                                label={formatMessage({
                                    id: 'qualification_type_label_belongs_to',
                                    defaultMessage: 'Belongs to'
                                })}
                                name="concernsIds"
                                field={{
                                    value: users,
                                    include: 'always',
                                    required: true
                                }}
                                onChange={({ concernsIds: concerns }) => setUsers(concerns)}
                                key={`${salt}:concernsIds:${resetAt}`}
                                ref={concernsRef} />
                        )}
                        {(!!id || outsideUser) && (
                            <PersonField
                                salt={salt}
                                label={formatMessage({
                                    id: 'qualification_type_label_belongs_to',
                                    defaultMessage: 'Belongs to'
                                })}
                                name="concernsId"
                                field={{
                                    value: outsideUser ?? concerns,
                                    include: 'touched',
                                    editable: !outsideUser,
                                    required: !id // If it's an update, then it's not required
                                }}
                                enabled={!id}
                                ref={concernsRef} />
                        )}
                        {category === 'education' && (
                            <>
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'competence_type_label_field_of_study',
                                        defaultMessage: 'Field of study'
                                    })}
                                    name="fieldOfStudy"
                                    field={{
                                        value: fieldOfStudy,
                                        softRequired: fieldOfStudyRequired
                                    }} />
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'competence_type_label_school',
                                        defaultMessage: 'School'
                                    })}
                                    name="school"
                                    field={{
                                        value: school,
                                        softRequired: schoolRequired
                                    }} />
                            </>
                        )}
                        {['education', 'experience'].includes(category) && (
                            <>
                                <TimeField
                                    salt={salt}
                                    simple={true}
                                    label={formatMessage({
                                        id: 'noun_start_date',
                                        defaultMessage: 'Start date'
                                    })}
                                    name="startDate"
                                    field={{
                                        value: startDate,
                                        softRequired: startDateRequired,
                                        include: id ? 'touched' : 'always',
                                        unsettable: true,
                                        showDay: false
                                    }}
                                    picker={{ monthAsSelect: true }} />
                                {!errors?.startDate && (
                                    <Helper>
                                        <FormattedMessage
                                            id="competence_start_date_helper"
                                            defaultMessage="Haven’t started yet? Add an expected start date." />
                                    </Helper>
                                )}
                                <TimeField
                                    salt={salt}
                                    simple={true}
                                    label={formatMessage({
                                        id: 'noun_end_date',
                                        defaultMessage: 'End date'
                                    })}
                                    name="endDate"
                                    field={{
                                        value: endDate,
                                        softRequired: endDateRequired,
                                        include: id ? 'touched' : 'always',
                                        unsettable: true,
                                        showDay: false
                                    }}
                                    picker={{
                                        monthAsSelect: true,
                                        futureYearsAvailableInt: 100
                                    }} />
                                {!errors?.endDate && (
                                    <Helper>
                                        <FormattedMessage
                                            id="competence_end_date_helper"
                                            defaultMessage="Not finished yet? Add an expected end date or leave empty." />
                                    </Helper>
                                )}
                            </>
                        )}
                        {['skill', 'language'].includes(category) && (
                            <>
                                <ContractableSelectField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'competence_label_level',
                                        defaultMessage: 'Level'
                                    })}
                                    name="level"
                                    field={{
                                        value: level,
                                        softRequired: levelRequired,
                                        optional: formatMessage({
                                            id: 'competence_level_optional',
                                            defaultMessage: 'The level will only be visible to the user it belongs to and competence administrators.'
                                        }),
                                        optionRenderer: getLevelOption,
                                        category
                                    }}
                                    options={[...Array(levelsCount).keys()].map(index => {
                                        const level = index + 1

                                        const subTitleId = `competence_${category}_level_${level}_sub`
                                        const subTitle = formatMessage({ id: subTitleId })
                                        const hasSubTitle = subTitle !== subTitleId

                                        return {
                                            value: level,
                                            label: (
                                                <LevelLabel key={`${salt}:language-level:${level}`}>
                                                    <strong>
                                                        <FormattedMessage
                                                            id={`competence_${category}_level_${level}`}
                                                            defaultMessage={category} />
                                                    </strong>
                                                    {hasSubTitle && <span>{'('}{subTitle}{')'}</span>}
                                                </LevelLabel>
                                            )
                                        }
                                    })} />
                                <LevelDefinitionsButton
                                    className="constructive small"
                                    onClick={() => setShowingDefinitions(true)}>
                                    <FormattedMessage
                                        id="noun_level_definitions"
                                        defaultMessage="Level definitions" />
                                </LevelDefinitionsButton>
                                <Modal
                                    className="medium"
                                    show={!!showingDefinitions}
                                    dismiss={() => setShowingDefinitions(false)}
                                    salt={`${salt}:${level ?? 'no-level'}:definitions`}>
                                    <LevelDefinitions
                                        type={category}
                                        focus={level}
                                        dismiss={() => setShowingDefinitions(false)}
                                        salt={`${salt}:${level ?? 'no-level'}:definitions`} />
                                </Modal>
                            </>
                        )}
                        {category === 'certification' && (
                            <>
                                <TimeField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'qualification_type_label_issued_date',
                                        defaultMessage: 'Issued date'
                                    })}
                                    name="validFrom"
                                    field={{
                                        value: validFrom,
                                        softRequired: validFromRequired,
                                        include: id ? 'touched' : 'always'
                                    }} />
                                {(!errors?.validFrom && !concerns) && (
                                    <Helper>
                                        <FormattedMessage
                                            id="competence_issued_date_helper_peers"
                                            defaultMessage="Are they planning on taking a certification? Add an expected issued date." />
                                    </Helper>
                                )}
                                {(!errors?.validFrom && !!concerns) && (
                                    <Helper>
                                        <FormattedMessage
                                            id={isItMyOwnId(concerns.id) ?
                                                'competence_issued_date_helper' :
                                                'competence_issued_date_helper_peer'
                                            }
                                            defaultMessage={isItMyOwnId(concerns.id) ?
                                                'Are you planning on taking a certification? Add an expected issued date.' :
                                                'Is {name} planning on taking a certification? Add an expected issued date.'
                                            }
                                            values={{ name: getShortName(concerns) }} />
                                    </Helper>
                                )}
                                <TimeField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'qualification_type_label_expiry_date',
                                        defaultMessage: 'Expiry date'
                                    })}
                                    name="validTo"
                                    field={{
                                        value: validTo,
                                        softRequired: validToRequired,
                                        include: id ? 'touched' : 'always'
                                    }}
                                    picker={{ futureYearsAvailableInt: 100 }} />
                                {!errors?.validTo && (
                                    <Helper>
                                        <FormattedMessage
                                            id="competence_expiry_date_helper"
                                            defaultMessage="Has it expired or does it need to be renewed? Add an expected expiry date or leave empty." />
                                    </Helper>
                                )}
                            </>
                        )}
                        <TextField
                            salt={salt}
                            // If none of the components before Actions are visible, then add 'compact' className
                            {...![
                                (validTo && category === 'certification'),
                                category === 'language',
                                !id
                            ].some(Boolean)
                                ? { className: 'compact' }
                                : null
                            }
                            label={formatMessage({
                                id: 'noun_notes',
                                defaultMessage: 'Notes'
                            })}
                            name="note"
                            field={{
                                value: note ?? '',
                                include: id ? 'touched' : 'always',
                                optional: formatMessage({
                                    id: 'competence_notes_optional',
                                    defaultMessage: 'Notes will be visible to everyone in {organization}.'
                                }, { organization: organization?.name })
                            }}
                            controlProps={{
                                placeholder: formatMessage({
                                    id: 'competence_placeholder_notes',
                                    defaultMessage: 'Add additional information'
                                })
                            }} />
                        {(validTo && category === 'certification') && (
                            <ExpiryStatus
                                to={validTo}
                                showActive={false}
                                context="competence" />
                        )}
                        {category === 'language' && (
                            <CheckboxField
                                {...(!!id ? { className: 'compact' } : null)}
                                salt={salt}
                                label={formatMessage({
                                    id: 'competence_language_native',
                                    defaultMessage: 'Native / multilingual'
                                })}
                                name="isNativeLanguage"
                                field={{ value: isNativeLanguage ?? false }}
                                interaction="switch" />
                        )}
                        {!id && (
                            <>
                                <Divider />
                                {users?.length > 1 && (
                                    <Message
                                        type="info"
                                        message={formatMessage({
                                            id: 'competence_message_documentation_multiple_people',
                                            defaultMessage: 'Documentation needs to be added separately when competence is created for multiple people at once.'
                                        })} />
                                )}
                                {(!!users?.length && !users?.some(({ id }) => isItMyOwnId(id))) && (
                                    <CheckboxField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'notification_toggle_label',
                                            defaultMessage: 'Send notification'
                                        })}
                                        name="notify"
                                        field={{ value: false }}
                                        interaction="switch" />
                                )}
                                <CheckboxField
                                    salt={salt}
                                    className="compact"
                                    label={formatMessage({
                                        id: 'competence_label_add_another',
                                        defaultMessage: 'Add another competence'
                                    })}
                                    name="addAnother"
                                    field={{
                                        value: false,
                                        optional: formatMessage({
                                            id: 'competence_label_add_another_helper',
                                            defaultMessage: 'After adding this competence, you can simply add another one of the same type.'
                                        })
                                    }}
                                    interaction="switch" />
                            </>
                        )}
                        <Actions>
                            <Plain
                                className="neutral"
                                onClick={dismiss}
                                disabled={working}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${working ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors)}
                                ref={trigger}>
                                <FormattedMessage
                                    id={id ?
                                        'action_save' :
                                        'action_add'
                                    }
                                    defaultMessage={id ?
                                        'Save' :
                                        'Add'
                                    } />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default EditCompetenceRecord