import React from 'react'
import { useArticle } from 'contexts/article'
import Content from './content'

const Statistics = props => {
    const { article } = useArticle()

    return (
        <Content
            {...props}
            article={article} />
    )
}

export default Statistics