import styled from 'styled-components'
import { belowLaptop } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked, buttonStyles } from 'components/button'
import { H1 } from 'components/typography/heading'

export const Hero = styled.div`
    position: sticky;
    z-index: 1;
    isolation: isolate;
    top: 0;

    box-shadow: 0 1px var(--huma-color-border-default);
    margin-inline: 88px;

    ${belowLaptop`
        margin-inline: 24px;
    `}
`

export const Menu = styled(Grid)`
    position: relative;

    grid-template-columns: repeat(2, auto);
    justify-content: space-between;

    margin-bottom: 24px;
    padding-block: 16px;
`

export const CloseButton = styled(Naked)`
    ${buttonStyles.closeButtonCss()}

    position: static;
`

export const Heading = styled(H1)`
    margin-bottom: 24px;
`