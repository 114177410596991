import React, { Component, createContext, useContext } from 'react'
import { intersectionObserver } from 'utilities/dom'
import { get, getUrl, outpost, outpatch } from 'api'
import { local } from 'utilities/storage'
import { pick } from 'utilities/object'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { size } from 'utilities/object'

export const SurveyContext = createContext()

export default class SurveyProvider extends Component {
    constructor(props) {
        super(props)

        const {
            type,
            eternal = true
        } = props

        this.setIds(pick(props, 'id'))

        this.fetchController = new AbortController()
        this.fetchCommentsDebounced = debounce(this.fetchComments, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults()
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.state = {
            survey: null,
            type,
            fixed: !!props.survey,
            deleted: false,

            respond: this.respond,
            updateResponse: this.updateResponse,
            fetchComments: this.fetchComments,
            toggleSorting: this.toggleSorting,

            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            hasFetched: false,
            hasFetchedComments: false,
            autoFetchComments: false,
            fetchingComments: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    componentDidUpdate({ paging, type, id }, { sorting }) {
        const typeChanged = type !== this.props.type
        const idChanged = id !== this.props.id
        const pagingChanged = !isEqual(paging, this.props?.paging)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const needsReplacing = typeChanged || idChanged
        if(needsReplacing) {
            this.setState(
                typeChanged ? pick(this.props, 'type') : null,
                () => this.replace(pick(this.props, 'id'))
            )
        } else {
            const state = {}

            if(pagingChanged) {
                this.pagingDefaults = pagingDefaults(this.props?.paging)
                state.paging = this.pagingDefaults()
            }

            this.setState(size(state) ? state : null, () => {
                if(sortingChanged) {
                    this.fetchCommentsDebounced(this.state.survey.id)
                }
            })
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
    }

    setIds = ids => Object.entries(ids).forEach(([key, value]) => this[key] = value)

    fetch = async () => {
        let ok = false
        let survey = null

        if(this.id) {
            const result = await get({ path: `/surveys/runs/${this.id}` })
            ok = result.ok
            survey = result.response

            if(ok && survey) {
                this.setState({
                    survey,
                    hasFetched: true
                })
            } else {
                this.setState({ hasFetched: true })
            }

            this.fetchCommentsDebounced(this.id)
        }

        return { response: survey, ok }
    }

    respond = async (body, jwt) => await outpost(getUrl('/surveys/runs/responses'), {
        body: {
            ...body,
            type: this.state.type
        },
        headers: { 'huma-limited-auth': jwt }
    })

    updateResponse = async (body, responseId, jwt) => await outpatch(
        getUrl(`/surveys/runs/responses/${responseId}`),
        {
            body: {
                ...body,
                type: this.state.type
            },
            headers: { 'huma-limited-auth': jwt },
            returnsData: false
        }
    )

    fetchComments = async runId => {
        const {
            fetchingComments,
            sorting,
            paging,
            eternal,
            autoFetchComments,
            hasFetchedComments
        } = this.state

        runId = runId ?? this.id

        if(fetchingComments || (hasFetchedComments && !eternal) || !runId) {
            return
        }

        if(fetchingComments) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetchingComments: true,
            ...(autoFetchComments ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetchedComments ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const { ok, response } = await get({
            path: `/surveys/runs/${runId}/responses`,
            params: {
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ survey }) => {
                const previousComments = survey?.comments ?? []
                const comments = [
                    ...previousComments,
                    ...response.items
                ]

                return {
                    survey: {
                        ...survey,
                        comments,
                        totalComments: response.total
                    },
                    paging: {
                        ...paging,
                        ...nextPaging,
                        hasNextPage: response.items.length && comments.length < response.total
                    },
                    hasFetchedComments: true,
                    autoFetchComments: !!previousComments.length && hasFetchedComments,
                    fetchingComments: false,
                }
            })
        } else {
            this.setState({
                hasFetchedComments: true,
                totalComments: 0,
                autoFetchComments: false,
                fetchingComments: false
            })
        }
    }

    replace = ids => {
        this.setIds(ids)
        this.setState({ survey: null }, this.fetch)
    }

    toggleSorting = field => {
        if(field in sortingOptions) {
            this.setState(({ sorting, survey }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc': 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    survey: {
                        ...survey,
                        comments: []
                    },
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetchedComments: false,
                    autoFetchComments: false
                }
            })
        }
    }

    onIntersect = () => {
        const {
            eternal,
            fetchingComments,
            paging,
            autoFetchComments,

        } = this.state

        if(!eternal || fetchingComments || !autoFetchComments || !paging?.hasNextPage) {
            return
        }

        this.fetchCommentsDebounced(this.id)
    }

    render() {
        const { children = null } = this.props

        return (
            <SurveyContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </SurveyContext.Provider>
        )
    }
}

const sortingOptions = {
    score: 'asc'
}

const sortingDefaults = () => ({
    by: Object.keys(sortingOptions)[0],
    direction: Object.values(sortingOptions)[0]
})

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useSurvey = () => useContext(SurveyContext)