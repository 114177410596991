import React, { useState, Fragment } from 'react'
import { useIntl } from 'react-intl'
import Page from 'contexts/page'
import EquipmentPiecesProvider, { useEquipmentPieces } from 'contexts/equipment-pieces'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { useLocationStateIntent } from 'hooks/location-intent'
import { useQueryResolveItem } from 'hooks/resolve-item'
import HeroActions from 'components/hero-actions'
import Filter, { areAdvancedFiltersApplied } from 'pages/equipment/components/pieces-filter'
import AddOrImport from 'pages/equipment/components/add-or-import'
import { ButtonArrow } from 'components/button'
import EquipmentPieces from 'lists/equipment/pieces'
import { Scrollable as Modal } from 'modals/generic'
import ActingPiece from 'pages/equipment/modals/piece'

const MyEquipmentPane = ({ salt }) => {
    const { formatMessage } = useIntl()

    const { me } = useMe()

    const { check } = useAccess()
    const adminAccess = check('equipment:manage')

    const {
        pieces,
        hasFetched,
        filter = {},

        fetch,
        fetchPiece,
        removePiece,
        setPiecesFilter: resetPiecesFilter
    } = useEquipmentPieces()

    const [acting, setActing] = useState(null)

    useLocationStateIntent({
        intent: 'add-equipment',
        action: () => setActing({ mode: 'edit' }),
        requirements: [!!adminAccess]
    })

    useQueryResolveItem({
        items: pieces,
        fetch: fetchPiece,
        onResolved: (piece, clearQuery) => {
            piece && viewAction(piece).onClick()
            clearQuery()
        }
    })

    salt = `${salt}:me`

    const viewAction = piece => ({
        salt: `${salt}:view`,
        label: formatMessage({
            id: 'action_view_details',
            defaultMessage: 'View details'
        }),
        effect: 'neutral',
        onClick: () => setActing({
            mode: 'view',
            piece
        })
    })

    const editAction = piece => {
        if(!adminAccess) {
            return null
        }

        return {
            salt: `${salt}:edit`,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                mode: 'edit',
                piece
            })
        }
    }

    const manageDocumentationAction = piece => {
        if(!adminAccess) {
            return null
        }

        return {
            salt: `${salt}:manage-documentation`,
            label: formatMessage({
                id: 'equipment_action_manage_documentation',
                defaultMessage: 'Manage documentation'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                mode: 'documentation',
                piece
            })
        }
    }

    const removeAction = ({ id }) => {
        if(!adminAccess) {
            return null
        }

        return {
            salt: 'equipment:delete',
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: async () => {
                const result = await removePiece(id)
                setActing(null)

                return result
            },
            prompt: {
                question: formatMessage({
                    id: 'equipment_action_delete_prompt_question',
                    defaultMessage: 'Confirm removing this equipment'
                }),
                message: formatMessage({
                    id: 'equipment_action_delete_prompt_message',
                    defaultMessage: 'The equipment will be deleted. This can not be undone.'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        }
    }

    const actions = {
        view: viewAction,
        edit: editAction,
        manageDocumentation: manageDocumentationAction,
        remove: removeAction
    }

    const filtersApplied = areAdvancedFiltersApplied(filter)

    return (
        <>
            {!!adminAccess && (
                <HeroActions>
                    <Filter
                        advancedValues={{ assignedToIds: ['me'] }}
                        salt={salt} />
                    <Fragment>
                        <AddOrImport
                            add={() => setActing({ mode: 'edit' })}
                            adding={!!acting}
                            onDone={(options = {}) => {
                                const { importing = false } = options

                                if(importing) {
                                    resetPiecesFilter(undefined)
                                    fetch(true)
                                }
                            }}
                            salt={`${salt}:add-or-import`} />
                        {(!pieces?.length && hasFetched && !filtersApplied) && (
                            <ButtonArrow />
                        )}
                    </Fragment>
                </HeroActions>
            )}
            <EquipmentPieces
                header={(!!pieces?.length && hasFetched)}
                actions={actions} />
            <Modal
                show={!!acting}
                dismiss={() => setActing(null)}
                autoFocus={false}
                salt={`${salt}:act`}>
                <ActingPiece
                    {...acting}
                    user={me}
                    actions={actions}
                    setActing={setActing}
                    onDone={() => setActing(null)}
                    salt={`${salt}:act`} />
            </Modal>
        </>
    )
}

export default ({ title, upgradable, ...props }) => (
    <Page
        view="equipment_me"
        title={title}
        upgrade={{
            enabled: upgradable,
            tutorial: {
                which: 'equipment',
                upgrade: { module: 'equipment' }
            }
        }}>
        <EquipmentPiecesProvider filter={{ assignedToIds: ['me'] }}>
            <MyEquipmentPane {...props} />
        </EquipmentPiecesProvider>
    </Page>
)