import styled from 'styled-components'
import { belowContainer, profileContainer } from 'utilities/styled'
import { Grid } from 'components/grid'

export const Important = styled(Grid)`
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 8px;

    margin-block-end: 32px;
    border-radius: 8px;
    padding: 8px;
    background: var(--huma-color-surface-warning-minimal);

    ${belowContainer(profileContainer)`
        margin-bottom: 0;
    `}

    &:empty {
        display: none;
    }
`
