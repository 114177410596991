import React, {
    useState, useEffect, useLayoutEffect, useRef,
    Fragment
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompetenceRecords } from 'contexts/competence-records'
import { useDebounce } from 'hooks/debounce'
import { onClink } from 'utilities/event'
import { compact } from 'utilities/array'
import { size } from 'utilities/object'
import { useGetErrorOrWarning } from 'pages/competence/utilities'
import { categories, categoryToMetaMap } from 'pages/competence/constants/category'
import { Navigations, Navigation, NavigationCategories, Link, Warning } from './s'
import Tooltip, { useSingleton } from 'components/tooltip'

const CategoriesNavigation = ({
    $scroller = document.documentElement,
    offset = 104,
    salt
}) => {
    const navigationRef = useRef()
    const categoriesRef = useRef()

    const [source, target] = useSingleton()

    const [hasLoaded, setHasLoaded] = useState(false)
    const [activeHash, setActiveHash] = useState(null)
    const [activeHashHeight, setActiveHashHeight] = useState(40)
    const [clickedNavigation, setClickedNavigation] = useDebounce(false, 800)
    const [offsetHeight, setOffsetHeight] = useState(0)

    const {
        records,
        hasFetched
    } = useCompetenceRecords()

    useIntersectionObserver(setActiveHash, clickedNavigation, navigationRef, hasLoaded)

    useEffect(() => {
        const target = document.querySelector(`[name="${activeHash}"]`)

        if(activeHash && target) {
            getOffset(activeHash)
        }
    }, [activeHash])

    useLayoutEffect(() => {
        if(!!hasFetched && !!records?.length && !hasLoaded) {
            setHasLoaded(true)
        }
    }, [hasFetched, records, hasLoaded])

    const getOffset = hash => {
        const { current } = categoriesRef
        let offset = 0

        Array.from(current?.children)?.every(({ tagName, offsetHeight, name }) => {
            if(tagName === 'LI') {
                return true
            }

            if(hash === name) {
                setActiveHashHeight(offsetHeight)
                return false
            }

            offset += offsetHeight
            return true
        })

        setOffsetHeight(offset)
    }

    const getErrorOrWarning = useGetErrorOrWarning()

    if(!hasFetched || !records?.length) {
        return null
    }

    const scrollTo = id => onClink(() => {
        setClickedNavigation(true)

        const $target = document.querySelector(`#${id}`)

        const scrollPosition = ($scroller === document.documentElement) ?
            global.scrollY :
            $scroller.scrollTop

        $scroller.scrollTo({
            top: $target.getBoundingClientRect().top + scrollPosition - offset,
            left: 0,
            behavior: 'smooth'
        })

        setActiveHash(id)
        setTimeout(() => setClickedNavigation(false), 700)
    })

    salt = `${salt}:navigation`

    return (
        <Navigations>
            <Tooltip
                singleton={source}
                placement="top-start"
                offset={[8, 0]}
                delay={[500, 250]} />
            <Navigation
                style={{
                    '--activeHashHeight': `${activeHashHeight}px`,
                    '--offsetHeight': `${offsetHeight}px`
                }}
                ref={navigationRef}>
                <NavigationCategories ref={categoriesRef}>
                    {categories
                        .filter(({ name }) => records.some(({ category }) => category === name))
                        .map(({ name }) => {
                            const recordsByCategory = records.filter(({ category }) => category === name)

                            const warnings = recordsByCategory
                                .map(record => getErrorOrWarning(record))
                                .filter(record => !!size(record))

                            const documentationWarnings = warnings.filter(({ documentation }) => !!documentation)
                            const expiredWarnings = warnings.filter(({ expired }) => !!expired)
                            const expiringWarnings = warnings.filter(({ expiring }) => !!expiring)
                            const missingValidToWarnings = warnings.filter(({ missingValidTo }) => !!missingValidTo)
                            const missingLevelWarnings = warnings.filter(({ missingLevel }) => !!missingLevel)

                            const [LinkWrapper, linkWrapperProps] = !!warnings?.length ?
                                [Tooltip, {
                                    content: (
                                        <>
                                            {compact([
                                                !!expiredWarnings?.length && (
                                                    <Fragment key={`${salt}expired:${name}`}>
                                                        <span>
                                                            <FormattedMessage
                                                                id="item_status_expired_count"
                                                                defaultMessage="{count, plural, =0 {} =1 {One has expired} other {{count} have expired}}"
                                                                values={{ count: expiredWarnings.length }} />
                                                        </span>
                                                        <br />
                                                    </Fragment>
                                                ),
                                                !!expiringWarnings?.length && (
                                                    <Fragment key={`${salt}expiring:${name}`}>
                                                        <span>
                                                            <FormattedMessage
                                                                id="item_status_expiring_count"
                                                                defaultMessage="{count, plural, =0 {} =1 {One expiring soon} other {{count} expiring soon}}"
                                                                values={{ count: expiringWarnings.length }} />
                                                        </span>
                                                        <br />
                                                    </Fragment>
                                                ),
                                                !!missingValidToWarnings?.length && (
                                                    <Fragment key={`${salt}missingValidTo:${name}`}>
                                                        <span>
                                                            <FormattedMessage
                                                                id="certifications_missing_expiry_day_count"
                                                                defaultMessage="{count, plural, =0 {} =1 {One is missing expiry date} other {{count} are missing expiry date}}"
                                                                values={{ count: missingValidToWarnings.length }} />
                                                        </span>
                                                        <br />
                                                    </Fragment>
                                                ),
                                                !!documentationWarnings?.length && (
                                                    <Fragment key={`${salt}documentation:${name}`}>
                                                        <span>
                                                            <FormattedMessage
                                                                id="certifications_missing_documentation_count"
                                                                defaultMessage="{count, plural, =0 {} =1 {One missing documentation} other {{count} missing documentation}}"
                                                                values={{ count: documentationWarnings.length }} />
                                                        </span>
                                                        <br />
                                                    </Fragment>
                                                ),
                                                !!missingLevelWarnings?.length && (
                                                    <span key={`${salt}missingLevel:${name}`}>
                                                        <FormattedMessage
                                                            id="competence_missing_level_count"
                                                            defaultMessage="{count, plural, =0 {} =1 {One is missing level} other {{count} are missing level}}"
                                                            values={{ count: missingLevelWarnings.length }} />
                                                    </span>
                                                )
                                            ])}
                                        </>
                                    ),
                                    placement: 'top-start',
                                    singleton: target
                                }] :
                                [Fragment, {}]

                            return (
                                <LinkWrapper
                                    {...linkWrapperProps}
                                    key={`${salt}:category:${name}`}>
                                    <Link
                                        className={activeHash === name ? 'active' : ''}
                                        to={`#${name}`}
                                        name={name}
                                        onClick={scrollTo(name)}>
                                        {!!warnings?.length && <Warning size={16} />}
                                        <span>
                                            <FormattedMessage {...categoryToMetaMap[name].name} />
                                        </span>
                                    </Link>
                                </LinkWrapper>
                            )
                        })
                    }
                </NavigationCategories>
            </Navigation>
        </Navigations>
    )
}

const useIntersectionObserver = (setActiveHash, clickedNavigation, navigationRef, hasLoaded) => {
    const sectionElementsRef = useRef({})

    useLayoutEffect(() => {
        if(!clickedNavigation && hasLoaded) {
            const sectionElements = [...document.querySelectorAll('section[id]')]

            const callback = headings => {
                sectionElementsRef.current = headings.reduce((map, sectionElement) => {
                    map[sectionElement.target.id] = sectionElement

                    return map
                }, sectionElementsRef.current)

                const visibleHeadings = []

                Object.keys(sectionElementsRef.current).forEach(key => {
                    const sectionElement = sectionElementsRef.current[key]

                    if(sectionElement.isIntersecting) {
                        visibleHeadings.push(sectionElement)
                    }
                })

                const getIndexFromId = id => sectionElements.findIndex(heading => heading.id === id)

                if(visibleHeadings.length === 1) {
                    setActiveHash(visibleHeadings[0].target.id)
                } else if(visibleHeadings.length > 1) {
                    const sortedVisibleHeadings = visibleHeadings.sort(
                        (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
                    )

                    setActiveHash(sortedVisibleHeadings[0].target.id)
                }
            }

            const observer = new IntersectionObserver(callback, {
                rootMargin: '-104px 0px 0px 0px'
            })

            sectionElements.forEach(element => observer.observe(element))

            return () => observer.disconnect()
        }
    }, [setActiveHash, clickedNavigation, navigationRef, hasLoaded])
}

export default CategoriesNavigation