import styled from 'styled-components'
import { Grid } from 'components/grid'

export const Wrapper = styled(Grid)`
    grid-template-columns: 24px minmax(0, 1fr);
    align-items: center;
    column-gap: 24px;
`

export const LevelNumber = styled.span`
    display: inline-flex;
    justify-content: center;
    flex-basis: 24px;

    font-size: 20px;
    font-weight: 500;
`