import {
    white, shadow,
    neutral, constructive, destructive, partners,
    yellow, purple
} from './colors'

const distance = {
    short: '1px 4px',
    long: '10px 24px'
}

export const button = {
    color: white,
    background: neutral.base,
    border: neutral.base,

    hover: {
        color: white,
        background: neutral.dark,
        border: neutral.dark
    },

    focus: {
        color: white,
        background: neutral.base,
        border: neutral.base,
        outline: neutral.fifteen
    },

    active: {
        color: white,
        background: neutral.brave,
        border: neutral.brave
    },

    constructive: {
        color: white,
        background: constructive.base,
        border: constructive.base,

        hover: {
            color: white,
            background: constructive.dark,
            border: constructive.dark
        },

        focus: {
            color: white,
            background: constructive.base,
            border: constructive.base,
            outline: constructive.light
        },

        active: {
            color: white,
            background: constructive.brave,
            border: constructive.brave
        }
    },

    destructive: {
        color: white,
        background: destructive.base,
        border: destructive.base,

        hover: {
            color: white,
            background: destructive.dark,
            border: destructive.dark
        },

        focus: {
            color: white,
            background: destructive.base,
            border: destructive.base,
            outline: destructive.light
        },

        active: {
            color: white,
            background: destructive.brave,
            border: destructive.brave
        }
    },

    disabled: {
        color: neutral.fifteen,
        background: neutral.five,
        border: neutral.five
    }
}

export const buttOnDark = {
    color: white,
    background: { ...white, a: .1 },

    hover: {
        color: white,
        background: { ...white, a: .2 },
    },

    focus: {
        color: white,
        background: { ...white, a: .1 },
        outline: white
    },

    active: {
        color: white,
        background: { ...white, a: .3 }
    },

    constructive: {
        color: constructive.base,
        background: white,

        hover: {
            color: constructive.base,
            background: { ...white, a: .9 }
        },

        focus: {
            color: constructive.base,
            background: { ...white, a: .9 },
            outline: constructive.light
        },

        active: {
            color: constructive.base,
            background: { ...white, a: .9 }
        }
    },

    destructive: {
        color: white,
        background: { ...destructive.base, a: .75 },

        hover: {
            color: white,
            background: destructive.base
        },

        focus: {
            color: white,
            background: destructive.base,
            outline: destructive.shy
        },

        active: {
            color: white,
            background: destructive.base
        }
    },

    disabled: {
        color: { ...white, a: .3 },
        background: { ...white, a: .05 }
    }
}

export const benjamin = {
    color: purple.base,
    background: yellow.base,
    border: yellow.base,

    hover: {
        color: purple.base,
        background: yellow.dark,
        border: yellow.dark
    },

    focus: {
        color: purple.base,
        background: yellow.base,
        border: yellow.base,
        outline: yellow.light
    },

    active: {
        color: purple.base,
        background: yellow.brave,
        border: yellow.brave
    },

    disabled: {
        color: neutral.fifteen,
        background: neutral.five,
        border: neutral.five
    }
}

export const huma = {
    color: yellow.base,
    background: purple.base,
    border: purple.base,

    hover: {
        color: yellow.dark,
        background: purple.base,
        border: purple.base,
        shadow: yellow.light
    },

    focus: {
        color: yellow.dark,
        background: purple.base,
        border: purple.base,
        outline: purple.light
    },

    active: {
        color: yellow.dark,
        background: purple.base,
        border: yellow.dark,
        shadow: purple.base
    },

    disabled: {
        color: neutral.fifteen,
        background: neutral.five,
        border: neutral.five
    }
}

export const ghost = {
    color: neutral.base,
    border: neutral.base,

    hover: {
        color: neutral.base,
        background: neutral.five,
        border: neutral.base
    },

    focus: {
        color: neutral.base,
        border: neutral.base,
        outline: neutral.fifteen
    },

    active: {
        color: neutral.base,
        background: neutral.light,
        border: neutral.base
    },

    constructive: {
        color: constructive.base,
        border: constructive.base,

        hover: {
            color: constructive.base,
            background: constructive.shy,
            border: constructive.base
        },

        focus: {
            color: constructive.base,
            border: constructive.base,
            outline: constructive.light
        },

        active: {
            color: constructive.base,
            background: constructive.light,
            border: constructive.base
        }
    },

    destructive: {
        color: destructive.base,
        border: destructive.base,

        hover: {
            color: destructive.base,
            background: destructive.shy,
            border: destructive.base
        },

        focus: {
            color: destructive.base,
            border: destructive.base,
            outline: destructive.light
        },

        active: {
            color: destructive.base,
            background: destructive.light,
            border: destructive.base
        }
    },

    disabled: {
        color: neutral.fifteen,
        background: neutral.five,
        border: neutral.five
    }
}

export const simple = {
    color: neutral.base,

    hover: {
        color: neutral.base,
        background: neutral.five
    },

    focus: {
        color: neutral.base,
        outline: neutral.fifteen
    },

    active: {
        color: neutral.brave,
        background: neutral.light
    },

    constructive: {
        color: constructive.base,

        hover: {
            color: constructive.base,
            background: constructive.shy
        },

        focus: {
            color: constructive.base,
            outline: constructive.light
        },

        active: {
            color: constructive.base,
            background: constructive.light
        }
    },

    destructive: {
        color: destructive.base,

        hover: {
            color: destructive.base,
            background: destructive.shy
        },

        focus: {
            color: destructive.base,
            outline: destructive.light
        },

        active: {
            color: destructive.base,
            background: destructive.light
        }
    },

    disabled: {
        color: neutral.fifteen,
        background: neutral.five
    }
}

export const plain = {
    color: neutral.base,

    hover: {
        color: neutral.base
    },

    focus: {
        color: neutral.base,
        outline: neutral.fifteen
    },

    active: {
        color: neutral.dark
    },

    constructive: {
        color: constructive.base,

        hover: {
            color: constructive.base
        },

        focus: {
            color: constructive.base,
            outline: constructive.light
        },

        active: {
            color: constructive.dark
        }
    },

    destructive: {
        color: destructive.base,

        hover: {
            color: destructive.base
        },

        focus: {
            color: destructive.base,
            outline: destructive.light
        },

        active: {
            color: destructive.dark
        }
    },

    disabled: {
        color: neutral.fifteen
    }
}

export const plainOnDark = {
    color: { ...white, a: .85 },

    hover: {
        color: { ...white, a: .85 }
    },

    focus: {
        color: { ...white, a: .85 },
        outline: { ...white, a: .15 }
    },

    active: {
        color: white
    }
}

plainOnDark.constructive = plainOnDark.destructive = plainOnDark

plainOnDark.disabled = {
    color: { ...white, a: .15 }
}

export const raised = {
    color: neutral.base,
    background: white,
    shadow,

    hover: {
        color: neutral.base,
        background: white,
        shadow,
        distance: distance.long
    },

    focus: {
        color: neutral.base,
        background: white,
        shadow,
        outline: neutral.fifteen
    },

    active: {
        color: neutral.base,
        background: white,
        shadow,
        distance: distance.short
    },

    constructive: {
        color: constructive.base,
        background: white,
        shadow,

        hover: {
            color: constructive.base,
            background: white,
            shadow,
            distance: distance.long
        },

        focus: {
            color: constructive.base,
            background: white,
            shadow,
            outline: constructive.light
        },

        active: {
            color: constructive.base,
            background: white,
            shadow,
            distance: distance.short
        }
    },

    destructive: {
        color: destructive.base,
        background: white,
        shadow,

        hover: {
            color: destructive.base,
            background: white,
            shadow,
            distance: distance.long
        },

        focus: {
            color: destructive.base,
            background: white,
            shadow,
            outline: destructive.light
        },

        active: {
            color: destructive.base,
            background: white,
            shadow,
            distance: distance.short
        }
    },

    disabled: {
        color: neutral.fifteen,
        background: neutral.five,
        border: neutral.five
    }
}

export const partner = {
    color: white,
    background: partners.schjodt
}

export const filter = {
    color: neutral.base,
    background: neutral.five,

    hover: {
        color: neutral.base,
        background: neutral.light
    },

    focus: {
        color: neutral.base,
        background: neutral.five,
        outline: constructive.light
    },

    active: {
        color: neutral.base,
        background: constructive.shy
    },

    disabled: {
        color: neutral.twentyfive,
        background: neutral.five
    }
}
