import styled from 'styled-components'
import { fontSerif } from 's'
import { interpointDivider, svgStroke } from 'utilities/styled'
import { GridCell } from 'components/grid'
import { H2 } from 'components/typography/heading'
import { Naked } from 'components/button'
import SymbolBase from 'components/symbol'
import { siblingHover } from 'components/symbol/s'
import { captionSmallCss } from 'components/typography/caption'

export const PickButton = styled(Naked)`
    position: absolute;
    inset: 0;

    width: 100%;
    height: auto;
`

export const Symbol = styled(SymbolBase)`
    pointer-events: none;

    ${siblingHover(PickButton)}
`

export const TemplateItem = styled(GridCell)`
    position: relative;

    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    gap: 16px;

    border: 2px solid var(--huma-color-surface-minimal);
    border-radius: 8px;
    padding: 24px;
`

export const Name = styled(H2)`
    ${fontSerif}

    margin: 0;
`

export const Meta = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    > span {
        ${interpointDivider()}
        ${captionSmallCss}
        white-space: nowrap;
    }
`

export const Action = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        color: var(--huma-color-foreground-constructive-bold);
    }

    svg {
        ${svgStroke()}

        transition: transform .1s ease-in-out;
    }

    ${PickButton}:hover ~ &,
    ${PickButton}:focus ~ & {
        span {
            text-decoration: underline;
        }

        svg {
            transform: translateX(4px);
        }
    }
`