import styled from 'styled-components'
import { svgStroke, belowTablet } from 'utilities/styled'
import CheckboxFieldBase from 'components/form/field/checkbox'
import MessageBase from 'components/message'
import  { OrderedList } from 'components/list'
import Link from 'components/link'

export const StackableMessage = styled(MessageBase)`
    margin-block-end: 16px;

    &.space-above {
        margin-block-start: 24px;
    }
`

export const CheckboxField = styled(CheckboxFieldBase)`
    margin-block-end: 0;
`

export const Message = styled(MessageBase)`
    margin-block-end: 24px;
    white-space: pre-line;
`

export const ActionsList = styled(OrderedList)`
    margin: 0 -32px;
`

export const Action = styled(Link)`
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 24px;
    justify-content: start;
    align-items: center;

    width: 100%;
    padding: 12px 32px;

    transition: background-color .2s ease-out;

    text-align: start;

    ${belowTablet`
        padding: 12px 0;
    `}

    &:not(:last-of-type)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 32px;

        width: calc(100% - 64px);
        height: 1px;
        background-color: var(--huma-color-border-default-focus);
    }

    svg {
        ${svgStroke()}
    }

    &:where(:hover, :focus),
    &:where(:hover, :focus) p {
        text-decoration: none;
        color: var(--huma-color-foreground-constructive-bold);
    }
`