import { useState, useEffect } from 'react'
import isEqual from 'react-fast-compare'

export const useHistate = (initialValue, onUpdate) => {
    const [detailed, setDetailed] = useState({
        initial: initialValue,
        current: initialValue,
        changed: false
    })

    useEffect(() => {
        onUpdate?.()
    }, [!!onUpdate, detailed.changed])

    const hasChanged = (initial = detailed.initial, current = detailed.current) => {
        if([
            Array.isArray(initial) && Array.isArray(current),
            !!initial && typeof initial === 'object' && !!current && typeof current === 'object'
        ].some(Boolean)) {
            return !isEqual(initial, current)
        }

        return initial !== current
    }

    const update = value => setDetailed(({ initial }) => ({
        initial,
        current: value,
        changed: hasChanged(initial, value)
    }))

    const reinitialize = () => setDetailed(({ current }) => ({
        initial: current,
        current,
        changed: false
    }))

    return {
        ...detailed,
        hasChanged,
        update,
        reset: () => update(detailed.initial),
        reinitialize
    }
}