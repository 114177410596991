import React, { useEffect } from 'react'
import Hubspot from './app/hubspot'
import Rapid7InsightOps from './app/rapid7-insight-ops'
import Bugsnag from './app/bugsnag'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { local, session } from 'utilities/storage'
import EnvironmentProvider from 'contexts/environment'
import ConfigurationProvider from 'contexts/configuration'
import IntegrationsProvider from 'contexts/integrations'
import SegmentProvider from 'contexts/segment'
import I18nProvider from 'contexts/i18n'
import SearchParamHandler from './app/search-param-handler'
import AuthProvider from 'contexts/auth'
import AccessProvider from 'contexts/access'
import PaymentProvider from 'contexts/payment'
import OrganizationProvider from 'contexts/organization'
import MeProvider from 'contexts/me'
import ThemeProvider from 'contexts/theme'
import { GlobalStyles } from './s'
import App from './app'
import { createRoot } from 'react-dom/client'
import OAuth from 'components/oauth'
import Loader from 'components/loader'

const router = createBrowserRouter([
    {
        path: '/oauth',
        element: <OAuth />
    },
    {
        path: '*',
        element: (
            <>
                <Rapid7InsightOps />
                <Bugsnag />
                <I18nProvider>
                    <>
                        <SearchParamHandler />
                        <AuthProvider>
                            <AccessProvider>
                                <PaymentProvider>
                                    <IntegrationsProvider>
                                        <MeProvider>
                                            <OrganizationProvider>
                                                <ThemeProvider>
                                                    <GlobalStyles />
                                                    <App />
                                                </ThemeProvider>
                                            </OrganizationProvider>
                                        </MeProvider>
                                    </IntegrationsProvider>
                                </PaymentProvider>
                            </AccessProvider>
                        </AuthProvider>
                    </>
                </I18nProvider>
            </>
        )
    }
])

const AppWithContext = () => {
    useEffect(() => {
        startupCleanup()

        if(global.location.hash) {
            session.set(
                'location-hash',
                global.location.hash,
                { expiry: 0.5 } // Half a minute
            )
        }

        global.addEventListener('hashchange', () => {
            session.set(
                'location-hash',
                global.location.hash,
                { expiry: 0.5 } // Half a minute
            )
        })

        return () => {
            global.removeEventListener('hashchange', () => {
                session.remove('location-hash')
            })
        }
    }, [])

    return (
        <EnvironmentProvider>
            <>
                <Hubspot />
                <ConfigurationProvider>
                    <SegmentProvider>
                        <RouterProvider
                            router={router}
                            fallbackElement={<Loader className="l" />} />
                    </SegmentProvider>
                </ConfigurationProvider>
            </>
        </EnvironmentProvider>
    )
}

// TODO: Empty out this function, eventually…
const startupCleanup = () => {
    const onboardingSortingKey = 'list:onboarding:events:sort'
    const onboardingSorting = local.get(onboardingSortingKey)
    if(onboardingSorting?.by === 'employmentStartDate') {
        local.set(onboardingSortingKey, {
            ...onboardingSorting,
            by: 'firstDayOfWork'
        })
    }

    const offboardingSortingKey = 'list:offboarding:events:sort'
    const offboardingSorting = local.get(offboardingSortingKey)
    if(offboardingSorting?.by === 'employmentEndDate') {
        local.set(offboardingSortingKey, {
            ...offboardingSorting,
            by: 'lastDayOfWork'
        })
    }
}

const main = document.getElementById('main')
const root = createRoot(main)
root.render(<AppWithContext />)