import styled, { css } from 'styled-components'
import {
    buttonOutlineProps,
    belowTablet, fromTablet,
    belowContainer, fromContainer, profileContainer
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { H2, H3 } from 'components/typography/heading'
import { paragraphCss, paragraphSmallCss } from 'components/typography/paragraph'
import { captionSmallCss } from 'components/typography/caption'
import { Grid } from 'components/grid'
import CheckboxField from 'components/form/field/checkbox'
import { Label as LabelBase, Control } from 'components/form/field/checkbox/edit/s'
import { Plain as PlainBase } from 'components/button'
import MessageBase from 'components/message'

export const ContainerQuery = styled.div`
    container-type: inline-size;
    container-name: ${profileContainer.name};
`

export const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 40px;
    grid-column-gap: 56px;

    ${fromContainer(profileContainer)`
        grid-template-columns: minmax(0, 1fr) 328px;
        align-items: start;

        &::after {
            content: "";
            display: block;
            min-height: calc(${[
                '100dvh',
                'var(--huma-global-main-layout-header-height)',
                'calc(var(--huma-global-content-padding-block-start) + var(--huma-global-content-padding-block-end))'
            ].join(' - ')});
        }
    `}

    ${belowTablet`
        gap: 24px;
    `}
`

export const Container = styled.section`
    scroll-margin-top: 104px;
    margin: 0 0 56px;

    &.with-border {
        padding-top: 36px;
        box-shadow: inset 0 1px var(--huma-color-border-default);
    }

    & > :last-child {
        margin-bottom: 0;
    }
`

export const Side = styled.div`
    height: 100%;

    > ${Container}:last-child {
        margin: 0;
    }
`

export const Main = styled.div`
    ${belowContainer(profileContainer)`
        display: grid;
        grid-template-columns: minmax(0, 100%);
        grid-auto-flow: row;
        grid-auto-rows: auto;
        order: 2;
    `}
`

export const HeadingColumns = styled(Grid)`
    overflow: clip;
    overflow-clip-margin: ${buttonOutlineProps.width + buttonOutlineProps.offset}px;
    position: relative;

    grid-template-columns: fit-content(100%) auto;
    grid-column-gap: 24px;
    align-items: center;
    justify-content: stretch;

    margin: 0 0 8px;

    &.editable,
    &.editing {
        grid-template-columns: fit-content(50%) auto minmax(auto, min-content);
    }

    &.editable.editing {
        grid-template-columns: fit-content(50%) auto minmax(auto, min-content) minmax(auto, min-content);
    }
`

const headingCss = css`
    margin: 0;
    height: 40px;
`

export const Heading = styled(H2)`
    ${headingCss}
`

export const Subheading = styled(H3)`
    ${headingCss}
`

export const Spacer = styled.div`
    height: 1px;
    background-color: var(--huma-color-border-default);
`

export const Helper = styled(motion.p).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            marginBottom: 0,
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            marginBottom: 16,
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    ${captionSmallCss}

    position: relative;
    top: -16px;

    ${fromTablet`
        top: -8px;

        padding-inline-start: 200px;

        line-height: 16px;
    `}
`

export const IntegratedHelper = styled(motion.p).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            marginTop: 0,
            marginBottom: 0,
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            marginTop: '8px',
            marginBottom: '8px',
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    ${captionSmallCss}

    position: relative;

    ${fromTablet`
        line-height: 16px;
    `}
`

export const Information = styled(motion.p).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            marginBottom: 0,
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            marginBottom: 16,
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    ${paragraphCss}
    position: relative;
    top: -16px;

    &.horizontal {
        top: -8px;
        border-bottom: 8px solid transparent;
    }
`

export const Error = styled(motion.p).attrs(() => ({
    className: 'destructive',
    variants: {
        hide: {
            height: 0,
            marginBottom: 0,
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            marginBottom: 16,
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    position: relative;
    top: -16px;
    ${paragraphSmallCss}

    ${fromTablet`
        top: -8px;
        padding-inline-start: 200px;
    `}
`

export const Message = styled(MessageBase)`
    position: relative;
    top: -8px;

    margin-block-end: 16px;

    &.horizontal {
        margin-inline-start: 200px;
    }

    &.vertical {
        top: -16px;
    }
`

export const Label = styled(LabelBase)`
    ${belowTablet`
        width: auto;
    `}
`

export const FlushedSwitch = styled(CheckboxField)`
    align-items: center;

    & > ${LabelBase} {
        order: 2;
        padding: 0;
        min-height: 32px;
    }

    & > ${Control} {
        order: 1;
        margin-inline-start: 0;
        margin-inline-end: 24px;
    }

    ${fromTablet`
        padding-left: 200px;
    `}
`

export const ImportantMessage = styled.p`
    display: inline-block;

    border-radius: 4px;
    padding-block: 8px;
    background-color: var(--huma-color-surface-highlight-subtle);
    box-shadow:
        4px 0 0 var(--huma-color-surface-highlight-subtle),
        -4px 0 0 var(--huma-color-surface-highlight-subtle);
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    color: var(--huma-color-foreground-default);
`

export const Plain = styled(PlainBase)`
    p + & {
        margin-block-start: 16px;
    }
`