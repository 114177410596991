import React from 'react'
import { Container } from '../s'
import Hierarchical from './hierarchical'
import ManageUserLocationsAffiliation from './locations'
import ManageUserTeamsAffiliation from './teams'

const AffiliationSection = props => (
    <Container className="with-border">
        <Hierarchical {...props}  />
        <ManageUserTeamsAffiliation {...props} />
        <ManageUserLocationsAffiliation {...props} />
    </Container>
)

export default AffiliationSection