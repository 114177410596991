import styled from 'styled-components'
import { CaptionSmall, captionSmallCss } from 'components/typography/caption'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'
import AvatarsBase from 'components/avatars'

export const Container = styled.div``

export const Heading = styled(CaptionSmall)`
    margin: 0;
`

export const Jubilees = styled(Grid)`
    grid-auto-flow: row;
    grid-auto-rows: auto;

    &:empty {
        display: none;
    }
`

export const Overflow = styled.div`
    margin-block-start: 8px;
`

export const Button = styled(Naked)`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 8px;
    justify-content: start;
    align-items: center;

    &:where(:hover, :focus) {
        text-decoration: none;

        span.link {
            text-decoration: underline;
        }
    }

    &:not(:last-of-type) {
        margin-block-end: 24px;
    }

    span {
        ${captionSmallCss}

        &.link {
            color: var(--huma-color-foreground-constructive-bold);
        }
    }
`

export const Avatars = styled(AvatarsBase)`
    display: inline-flex;
`