import styled from 'styled-components'
import {
    svgStroke,
    belowTablet, fromTablet
} from 'utilities/styled'

export const Wrapper = styled.div`
    display: grid;
    gap: 12px 24px;

    ${fromTablet`
        grid-template-columns: 1fr auto 1fr;
    `}

    ${belowTablet`
        grid-template-rows: repeat(3, auto);
    `}
`

export const Arrows = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    ${belowTablet`
        rotate: 90deg;
    `}

    svg {
        ${svgStroke()}

        &:first-of-type {
            margin-inline-end: -5px;
        }

        &:last-of-type {
            margin-inline-start: -4px;
        }
    }
`
