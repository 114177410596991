import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { NewBadge as NewBadgeBase } from 'app/layouts/private/navigation/items/s'

export const HeaderWrapper = styled.div`
    position: relative;
    isolation: isolate;

    width: 40px;
    height: 40px;

    ${belowPhone`
        display: none;
    `}
`

export const NewBadge = styled(NewBadgeBase)`
    inset-inline-start: 50%;
    inset-block-start: 0;

    transform: unset;
`