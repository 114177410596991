import React, { useState, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Page from 'contexts/page'
import CompetenceRecordsProvider, { useCompetenceRecords } from 'contexts/competence-records'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { useLocationStateIntent } from 'hooks/location-intent'
import { useQueryResolveItem } from 'hooks/resolve-item'
import { HeroActions, Layout, Content, Aside } from './s'
import Gap from './gap'
import Filter, { areAdvancedFiltersApplied } from 'pages/competence/components/records-filter'
import { Ghost, ButtonArrow } from 'components/button'
import TypeGroups from 'pages/competence/components/category-groups'
import Navigation from 'pages/competence/components/navigation'
import { Scrollable as Modal } from 'modals/generic'
import ActingRecord from 'pages/competence/modals/record'
import PickCategory from 'pages/competence/modals/pick-category'

const MyCompetencePane = ({ salt }) => {
    const { formatMessage } = useIntl()

    const { me } = useMe()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const [acting, setActing] = useState(null)
    const [pickingCategory, setPickingCategory] = useState(false)

    const {
        records,
        hasFetched,
        filter = {},

        fetchRecord,
        removeRecord
    } = useCompetenceRecords()

    useLocationStateIntent({
        intent: 'add-competence',
        action: () => setPickingCategory(true),
        requirements: [!!manageAccess]
    })

    useQueryResolveItem({
        items: records,
        fetch: fetchRecord,
        onResolved: (record, clearQuery) => {
            record && viewAction(record).onClick()
            clearQuery()
        }
    })

    const reset = () => setActing(null)

    const viewAction = record => ({
        salt: `${salt}:view`,
        label: formatMessage({
            id: 'action_view_details',
            defaultMessage: 'View details'
        }),
        effect: 'neutral',
        onClick: () => setActing({
            mode: 'view',
            record
        })
    })

    const editAction = record => {
        if(!manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:edit`,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                mode: 'edit',
                record
            })
        })
    }


    const manageDocumentationAction = record => {
        if(!manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:manage-documentation`,
            label: formatMessage({
                id: 'action_manage_documentation',
                defaultMessage: 'Manage documentation'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                mode: 'documentation',
                record
            })
        })
    }

    const removeAction = ({ id }) => {
        if(!manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: () => removeRecord(id),
            prompt: {
                question: formatMessage({
                    id: 'competence_action_delete_prompt_question',
                    defaultMessage: 'Confirm deleting competence'
                }),
                message: formatMessage({
                    id: 'competence_action_delete_prompt_message',
                    defaultMessage: 'The competence will be deleted. This can not be undone.'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        })
    }

    const actions = {
        view: viewAction,
        edit: editAction,
        manageDocumentation: manageDocumentationAction,
        remove: removeAction
    }

    const filtersApplied = areAdvancedFiltersApplied(filter)

    return (
        <>
            <Gap salt={`${salt}:me:gap`} />
            <HeroActions>
                <Filter
                    advancedValues={{ concerns: 'me' }}
                    salt={salt} />
                {!!manageAccess && (
                    <Fragment>
                        <Ghost
                            onClick={() => setPickingCategory(true)}
                            className="constructive"
                            disabled={!!acting}>
                            <FormattedMessage
                                id="competence_action_add"
                                defaultMessage="Add competence" />
                        </Ghost>
                        {(!records?.length && hasFetched && !filtersApplied) && (
                            <ButtonArrow />
                        )}
                    </Fragment>
                )}
            </HeroActions>
            <Layout>
                <Content>
                    <TypeGroups actions={actions} />
                </Content>
                <Aside>
                    <Navigation />
                </Aside>
            </Layout>
            <Modal
                show={!!acting}
                dismiss={reset}
                autoFocus={false}
                salt={`${salt}:act`}>
                <ActingRecord
                    {...acting}
                    user={me}
                    actions={actions}
                    setActing={setActing}
                    dismiss={reset}
                    onDone={reset}
                    salt={`${salt}:act`} />
            </Modal>
            <Modal
                show={!!pickingCategory}
                dismiss={() => setPickingCategory(false)}
                salt={`${salt}:pick:category`}>
                <PickCategory
                    dismiss={() => setPickingCategory(false)}
                    onDone={category => {
                        setPickingCategory(false)
                        setActing({
                            mode: 'edit',
                            category
                        })
                    }}
                    salt={`${salt}:pick:category`} />
            </Modal>
        </>
    )
}

export default ({ title, upgradable, ...props }) => (
    <Page
        view="competence_me"
        title={title}
        upgrade={{
            enabled: upgradable,
            tutorial: {
                which: 'competence',
                upgrade: { module: 'competence' }
            }
        }}>
        <CompetenceRecordsProvider
            filter={{ concerns: 'me' }}
            fetchExhaustively={true}>
            <MyCompetencePane {...props} />
        </CompetenceRecordsProvider>
    </Page>
)