import React, { useState, useRef } from 'react'
import { useIntl, FormattedMessage, FormattedList } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useHandbook } from 'contexts/handbook'
import { size } from 'utilities/object'
import { usePossum } from 'hooks/possum'
import { getShortName } from 'utilities/person'
import Form from 'components/form/controller'
import { ModalHeading } from 'components/typography/heading'
import OneOfField from 'components/form/field/one-of'
import CheckboxField from 'components/form/field/checkbox'
import PersonField from 'components/form/field/person'
import EntityField from 'components/form/field/entity'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const PreviewHandbook = ({ onDone, salt }) => {
    const { formatMessage } = useIntl()

    const personRef = useRef()
    const groupRef = useRef()

    const {
        filter,
        setHandbookFilter,
        viewingAs,
        setViewingAs
    } = useHandbook()

    const { organization } = useOrganization()

    const [previewing, setPreviewing] = useState(false)

    const [viewAs, setViewAs] = useState(viewingAs?.type ?? 'all')
    const [person, setPerson] = useState(viewingAs?.type === 'person' ? viewingAs.value : null)
    const [group, setGroup] = useState(viewingAs?.type === 'group' ? viewingAs.value : null)

    const possessify = usePossum()

    const getGroupName = group => <strong key={`handbook:preview:${group.id}`}>{group.name}</strong>

    const getPersonShares = () => {
        if(!person?.teams?.length && !person?.locations?.length) {
            return null
        }

        const { teams, locations } = person

        const pname = possessify(getShortName(person))

        if(!teams?.length) {
            return formatMessage({
                id: 'handbooks_preview_person_shares_locations',
                defaultMessage: '{count, plural, =0 {} =1 {{locations} ({pname} location)} other {{locations} ({pname} locations)}}'
            }, {
                count: locations.length,
                pname,
                locations: (
                    <FormattedList
                        type="conjunction"
                        value={locations.map(getGroupName)} />
                )
            })
        }

        if(!locations?.length) {
            return formatMessage({
                id: 'handbooks_preview_person_shares_teams',
                defaultMessage: '{count, plural, =0 {} =1 {{teams} ({pname} team)} other {{teams} ({pname} teams)}}'
            }, {
                count: teams.length,
                pname,
                teams: (
                    <FormattedList
                        type="conjunction"
                        value={teams.map(getGroupName)} />
                )
            })
        }

        return formatMessage({
            id: 'handbooks_preview_person_shares_teams_and_locations',
            defaultMessage: '{teams} ({pname} teams) and {locations} ({pname} locations)'
        }, {
            pname,
            teams: (
                <FormattedList
                    type="conjunction"
                    value={teams.map(getGroupName)} />
            ),
            locations: (
                <FormattedList
                    type="conjunction"
                    value={locations.map(getGroupName)} />
            )
        })
    }

    const preview = async body => {
        setPreviewing(true)

        setViewingAs({
            type: viewAs ?? 'all',
            ...(viewAs === 'person' ? { value: person } : null),
            ...(viewAs === 'group' ? { value: group } : null)
        })

        if(viewAs === 'person') {
            setHandbookFilter({
                ...filter,
                viewAs: person.id,
                includeSharedWithEveryone: body.includeContentPerson
            })
        }

        if(viewAs === 'group') {
            setHandbookFilter({
                ...filter,
                viewAs: group.id,
                includeSharedWithEveryone: body.includeContentGroup
            })
        }

        if(viewAs === 'organization') {
            setHandbookFilter({
                ...filter,
                viewAs: organization.id
            })
        }

        if(viewAs === 'all') {
            setHandbookFilter({
                ...filter,
                viewAs: null
            })
        }

        setPreviewing(false)

        onDone()
    }

    return (
        <Form
            layout="vertical"
            onSubmit={preview}>
            {({ errors, trigger }) => (
                <>
                    <ModalHeading>
                        <FormattedMessage
                            id="handbooks_action_preview"
                            defaultMessage="Preview handbook" />
                    </ModalHeading>
                    <OneOfField
                        className="compact"
                        salt={salt}
                        label={false}
                        name="viewAs"
                        field={{
                            value: viewAs,
                            include: 'always',
                            options: [
                                {
                                    value: 'person',
                                    label: formatMessage({
                                        id: 'handbooks_preview_label_person',
                                        defaultMessage: 'As a person'
                                    }),
                                    controlRef: personRef,
                                    content: (
                                        <>
                                            <PersonField
                                                salt={salt}
                                                label={false}
                                                name="person"
                                                field={{ value: person }}
                                                picker={{ outer: false }}
                                                onChange={({ person }) => setPerson(person)}
                                                ref={personRef} />
                                            <CheckboxField
                                                salt={salt}
                                                label={formatMessage({
                                                    id: 'handbooks_preview_label_include_content',
                                                    defaultMessage: 'Include content shared with everyone'
                                                })}
                                                name="includeContentPerson"
                                                field={{
                                                    value: filter?.includeSharedWithEveryone ?? true,
                                                    include: 'always'
                                                }} />
                                            {(!!person?.teams?.length || !!person?.locations?.length) && (
                                                <Message
                                                    type="info"
                                                    message={formatMessage({
                                                        id: 'handbooks_preview_message_person',
                                                        defaultMessage: 'Will display content that is shared with {shares}'
                                                    }, { shares: getPersonShares() })} />
                                            )}
                                        </>
                                    )
                                },
                                {
                                    value: 'group',
                                    label: formatMessage({
                                        id: 'handbooks_preview_label_group',
                                        defaultMessage: 'As a member of a team or location'
                                    }),
                                    controlRef: groupRef,
                                    content: (
                                        <>
                                            <EntityField
                                                salt={salt}
                                                label={false}
                                                name="group"
                                                field={{ value: group }}
                                                picker={{
                                                    heading: formatMessage({
                                                        id: 'group_action_pick',
                                                        defaultMessage: 'Pick group'
                                                    }),
                                                    outer: false
                                                }}
                                                entity={{
                                                    type: 'group',
                                                    path: '/groups'
                                                }}
                                                onChange={({ group }) => setGroup(group)}
                                                ref={groupRef} />
                                            <CheckboxField
                                                salt={salt}
                                                label={formatMessage({
                                                    id: 'handbooks_preview_label_include_content',
                                                    defaultMessage: 'Include content shared with everyone'
                                                })}
                                                name="includeContentGroup"
                                                field={{
                                                    value: filter?.includeSharedWithEveryone ?? true,
                                                    include: 'always'
                                                }} />
                                            {group && (
                                                <Message
                                                    type="info"
                                                    message={formatMessage({
                                                        id: 'handbooks_preview_message_group',
                                                        defaultMessage: 'Will display content that is shared with the members of {group}'
                                                    }, { group: getGroupName(group) })} />
                                            )}
                                        </>
                                    )
                                },
                                {
                                    value: 'organization',
                                    label: formatMessage({
                                        id: 'handbooks_preview_label_organization',
                                        defaultMessage: 'Only display categories and topics not shared with a group'
                                    })
                                },
                                {
                                    value: 'all',
                                    label: formatMessage({
                                        id: 'handbooks_preview_label_all',
                                        defaultMessage: 'Display all categories and topics'
                                    }),
                                    content: (
                                        <Message
                                            type="info"
                                            message={formatMessage({
                                                id: 'handbooks_preview_message_all',
                                                defaultMessage: 'You, as an administrator, can see all categories and topics'
                                            })} />
                                    )
                                }
                            ]
                        }}
                        onChange={({ viewAs }) => setViewAs(viewAs)} />
                    <Actions>
                        <Plain
                            onClick={onDone}
                            disabled={previewing}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${previewing ? ' loading' : ''}`}
                            disabled={[
                                !!size(errors) ||
                                viewAs === 'person' && !person ||
                                viewAs === 'group' && !group ||
                                previewing
                            ].some(Boolean)}
                            ref={trigger}>
                            <FormattedMessage
                                id="action_preview"
                                defaultMessage="Preview" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default PreviewHandbook
