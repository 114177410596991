import React from 'react'
import { useIntl } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { prune } from 'utilities/array'
import { onClink } from 'utilities/event'
import { getHandbookCategoryUrl } from 'utilities/url'
import { hashify } from 'pages/handbook/utilities'
import {
    Wrapper,
    Topic, Emoji, Label, Status
} from './s'
import Ellipsify from 'components/ellipsify'

const CategoryTopics = ({ topics = [], setActingTopic, expanded, ...props }) => {
    const { formatMessage } = useIntl()

    const { handbook } = useHandbook()

    const { checkFeature } = useAccess()
    const groupsAvailable = checkFeature('handbook-groups')

    const { categories = [] } = handbook ?? {}

    if(!topics.length) {
        return null
    }

    const categoryCount = prune(topics.map(({ categoryId }) => categoryId)).length
    const firstCategory = categories.find(({ id }) => id === topics[0]?.categoryId)

    const publishedTopics = topics.filter(({ status, shares = [] }) => {
        const published = !!(status === 'published')

        if(!!groupsAvailable) {
            return published
        }

        return published && shares.every(({ type }) => type === 'organization')
    })

    const draftTopics = topics.filter(({ status, shares = [] }) => {
        const draft = !!(status === 'draft')

        if(!!groupsAvailable) {
            return draft
        }

        return draft && shares.every(({ type }) => type === 'organization')
    })

    const inaccessibleTopics = !!groupsAvailable ?
        [] :
        topics.filter(({ shares = [] }) => shares.some(({ type }) => ['team', 'location'].includes(type)))


    const click = topic => onClink(() => setActingTopic({
        topic,
        mode: 'view'
    }))

    return (
        <Wrapper {...props}>
            {publishedTopics.map(topic => {
                const category = categories.find(({ id }) => id === topic.categoryId)

                return (
                    <Topic
                        to={`${getHandbookCategoryUrl(category)}#${hashify(topic.id, 'topic')}`}
                        {...(setActingTopic ? { onClick: click(topic) } : null)}
                        {...((expanded === false) ? { tabIndex: -1 } : null)}
                        key={topic.id}>
                        <Emoji
                            emoji={topic.symbol.emoji}
                            size={14} />
                        <Label>
                            <Ellipsify
                                text={topic.title}
                                className="no-underline" />
                        </Label>
                    </Topic>
                )
            })}
            {(!!inaccessibleTopics?.length && (categoryCount === 1)) && (
                <Topic
                    to={`${getHandbookCategoryUrl(firstCategory)}#${hashify(inaccessibleTopics[0].id, 'topic')}`}
                    {...((expanded === false) ? { tabIndex: -1 } : null)}
                    className="group">
                    <Label>
                        <Status status="inaccessible" />
                        <span>
                            <Ellipsify text={formatMessage({
                                id: 'topic_count',
                                defaultMessage: '{count, plural, =0 {0 topics} =1 {1 topic} other {{count} topics}}'
                            }, { count: inaccessibleTopics.length })} />
                        </span>
                    </Label>
                </Topic>
            )}
            {(!!draftTopics?.length && (categoryCount === 1)) && (
                <Topic
                    to={`${getHandbookCategoryUrl(firstCategory)}#${hashify(draftTopics[0].id, 'topic')}`}
                    {...((expanded === false) ? { tabIndex: -1 } : null)}
                    className="group">
                    <Label>
                        <Status status="draft" />
                        <span>
                            <Ellipsify text={formatMessage({
                                id: 'topic_count',
                                defaultMessage: '{count, plural, =0 {0 topics} =1 {1 topic} other {{count} topics}}'
                            }, { count: draftTopics.length })} />
                        </span>
                    </Label>
                </Topic>
            )}
        </Wrapper>
    )
}

export default CategoryTopics
