import styled from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import LinkBase from 'components/link'
import Paragraph from 'components/typography/paragraph'

export const KnowledgeBaseLink = styled(LinkBase)`
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 24px;
    justify-items: start;
    align-items: center;

    width: 100%;
    padding: 12px 24px;

    transition: background-color .2s ease-out;

    text-align: start;
    text-decoration: none;

    ${belowTablet`
        padding: 16px 0;
    `}

    &:where(:hover, :focus-visible) {
        background-color: var(--huma-color-surface-elevated-hover);

        transition: background-color .1s ease-in;

        text-decoration: none;

        &::before {
            transition: opacity .2s ease-out;
            opacity: 0;
        }
    }

    &:focus-visible {
        border-radius: 0;
    }
`

export const Icon = styled.div`
    svg {
        ${svgStroke()}
    }
`

export const Title = styled(Paragraph).attrs(() => ({ as: 'span' }))`
    display: block;
`

export const ExternalIcon = styled.div`
    display: grid;
    place-items: center;

    width: 24px;

    svg {
        ${svgStroke('small')}
    }
`