import styled from 'styled-components'
import {
    svgStroke,
    belowTablet, belowPhone
} from 'utilities/styled'
import { Tags } from 'components/tags/s'
import { Ghost } from 'components/button'
import ActionsBase from 'components/form/actions'
import { H2 } from 'components/typography/heading'
import { Plain } from 'components/button'
import { flexChildShrinkCss } from 'components/flex'
import CaptionBase from 'components/typography/caption'

export const Wrapper = styled.div`
    margin-block-end: 24px;
`

export const Links = styled(Tags)`
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    min-height: 40px;
`

export const Chip = styled(Ghost)`
    ${flexChildShrinkCss}
    display: grid;
    grid-template-columns: 32px calc(100% - 32px);
    align-items: center;

    margin: 0 8px 8px 0;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    height: 40px;
    padding: 8px 16px;

    line-height: 24px;

    -webkit-appearance: none;

    &:where(:hover, :focus) {
        border-color: var(--huma-color-border-default);

        text-decoration: none;
    }
`

export const Actions = styled(ActionsBase)`
    justify-content: flex-start;

    ${belowTablet`
        padding-block-start: 8px !important;
    `}
`

export const Heading = styled(H2)`
    margin-block-end: 24px;
`

export const Caption = styled(CaptionBase)`
    margin-block-end: 16px;
`

export const CloseButton = styled.a`
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;

    display: grid;
    place-items: center;

    border-radius: 8px;
    border-top-right-radius: 4px;
    width: 40px;
    height: 40px;
    padding: 0;

    transition: background-color 1s ease;

    text-decoration: none;
    color: var(--huma-color-foreground-default);

    ${belowPhone`
        top: 16px;
        right: 16px;

        border-top-right-radius: 12px;
    `}

    &:where(:hover, :focus) {
        transition: background-color .2s ease;
    }

    &:hover {
        background-color: var(--huma-color-surface-minimal);

        ${belowPhone`
            background-color: var(--huma-color-surface-default);
        `}
    }

    &:focus {
        background-color: var(--huma-color-surface-subtle);

        ${belowPhone`
            background-color: var(--huma-color-surface-default);
        `}
    }

    svg {
        ${svgStroke()}

        margin: 0;
    }
`

export const AddButton = styled(Plain)`
    svg {
        ${svgStroke()}

        margin-inline-end: 16px;
    }
`

export const Fields = styled.div`
    margin-block-start: 24px;
`


export const DragDropWrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;

    margin-block-start: 16px;
`

export const Sortables = styled.div`
    flex-grow: 1;
`