import React from 'react'
import View from './view'
import Edit from './edit'

const ActingEntry = ({ mode = 'view', ...props }) => {
    const Entry = {
        view: View,
        edit: Edit
    }[mode]

    return (
        <Entry
            {...props}
            entryId={props.entry?.id} />
    )
}

export default ActingEntry