import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { getEnabledCategoriesAndTopics } from 'pages/handbook/utilities'
import { Heading } from './s'
import { CloseButton } from 'components/button'
import Steps from 'components/steps'
import OneShares from './1-shares'
import TwoStatus from './2-status'

const HandbookTemplateAddItems = ({ template, context, dismiss, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        handbook,

        addHandbook,
        importTemplate
    } = useHandbook()

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    const { status = 'draft' } = handbook ?? {}

    const [step, setStep] = useState(1)
    const [form, setForm] = useState(template)
    const [adding, setAdding] = useState(false)

    const enabledCategoriesAndTopics = getEnabledCategoriesAndTopics(form.categories)

    const add = async body => {
        setAdding(true)

        let addOk = true
        let addResponse = handbook

        if(context === 'setup') {
            const { ok, response } = await addHandbook({
                contacts: ['me']
            })

            addOk = ok
            addResponse = response
        }

        if(addOk) {
            if(!!onboarder && context === 'setup') {
                updateOnboardingStatus({ setupHandbook: 'completed' })
            }

            if(form.type === 'template') {
                body.templateId = form.id

                if(!body.shares) {
                    body.shares = form.shares
                }

                body.categories = enabledCategoriesAndTopics
                    .map(({ id, topics }) => ({
                        id,
                        topics: topics.map(({ id }) => id)
                    }))

                if(!body.status) {
                    body.status = (status === 'draft') ?
                        'published' :
                        'draft'
                }
            }

            if(form.type === 'json') {
                body.categories = enabledCategoriesAndTopics

                if(status === 'draft') {
                    if(body.status !== 'keep') {
                        body.status = 'published'
                    }
                } else {
                    body.shares = form.shares
                }

                if(body.status === 'keep') {
                    delete body.status
                }
            }

            const { ok } = await importTemplate({
                body,
                handbookId: addResponse.id,
                type: form.type
            })

            ok && onDone?.()
        }

        setAdding(false)
    }

    const props = {
        form,
        onSubmit: add,
        enabledCategoriesAndTopics,
        adding,
        salt
    }

    return (
        <>
            <CloseButton onClick={dismiss} />
            <Heading>
                <FormattedMessage
                    id="handbooks_setup_step_shares_heading"
                    defaultMessage="Who should see what?" />
            </Heading>
            {status !== 'draft' && (
                <Steps
                    steps={[
                        {
                            text: formatMessage({
                                id: 'noun_access',
                                defaultMessage: 'Access'
                            })
                        },
                        {
                            text: formatMessage({
                                id: 'noun_status',
                                defaultMessage: 'Status'
                            })
                        }
                    ]}
                    at={step - 1}
                    salt={salt} />
            )}
            {(step === 1) && (
                <OneShares
                    {...props}
                    onSubmit={({ body, form }) => {
                        setForm(previousForm => ({
                            ...previousForm,
                            ...form
                        }))

                        if(status === 'published') {
                            return void setStep(2)
                        }

                        add({
                            ...body,
                            ...form
                        })
                    }} />
            )}
            {(step === 2) && (
                <TwoStatus
                    {...props}
                    back={() => setStep(1)}
                    dismiss={dismiss} />
            )}
        </>
    )
}

export default HandbookTemplateAddItems
