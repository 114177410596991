import React, { Fragment } from 'react'
import CompetenceRecordProvider, { useCompetenceRecord } from 'contexts/competence-record'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import Loader from 'components/loader'
import Hero from './hero'
import Details from './details'
import Documentation from './documentation'
import Actions from './actions'

const ViewCompetenceRecord = ({ provide, record, dismiss, actions, salt }) => {
    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const { isItMyOwnId } = useMe()
    const ownCompetence = isItMyOwnId(record.concerns?.id)

    const {
        type,
        attachments,
        links
    } = record ?? {}

    const hasDocumentation = (!!attachments?.length || !!links?.length)

    return (
        <>
            <Hero
                type={type}
                dismiss={dismiss} />
            <Details
                record={record}
                salt={salt} />
            {(hasDocumentation && (manageAccess || ownCompetence)) && (
                <Documentation
                    record={record}
                    provide={provide}
                    salt={salt} />
            )}
            <Actions
                actions={actions}
                context={record}
                dismiss={dismiss}
                salt={salt} />
        </>
    )
}

const RecordProvider = props => {
    const { record } = useCompetenceRecord()

    if(!record) {
        return <Loader />
    }

    return (
        <ViewCompetenceRecord
            {...props}
            record={record} />
    )
}

export default ({ recordId = null, ...props }) => {
    const { provide = false } = props

    const CompetenceRecordWrapper = provide ?
        CompetenceRecordProvider :
        Fragment

    const wrapperProps = provide ? {
        id: recordId,
        fetchOnMount: true
    } : null

    return (
        <CompetenceRecordWrapper {...wrapperProps}>
            {!!provide && <RecordProvider {...props} />}
            {!provide && <ViewCompetenceRecord {...props} />}
        </CompetenceRecordWrapper>
    )
}
