import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { cls } from 'utilities/dom'
import { decimalToPercent } from 'utilities/math'
import { Symbol, Heading } from './s'
import { CloseButton } from 'components/button'
import { Displayer as TypeName } from 'pages/absence/components/type-name'

const ViewEntryHero = ({ type, status, grade, user, permissionLevel, absenceAdmin, dismiss }) => {
    const { isItMyOwnId } = useMe()
    const statusAccess = (absenceAdmin || isItMyOwnId(user.id) || (permissionLevel === 'approve'))

    const headingClassName = cls(['rejected', 'canceled'].includes(status) && 'strike')

    const symbolClassName = cls([
        status === 'pending' && 'passive',
        status === 'canceled' && 'voided'
    ])

    return (
        <>
            <Symbol
                {...symbolClassName ? { className: symbolClassName } : null}
                {...(grade < 1 ? { percentage: decimalToPercent(grade) } : null)}
                symbol={type.symbol} />
            <Heading {...(headingClassName ? { className: headingClassName } : null)}>
                {(status === 'pending' && !!statusAccess) && (
                    <>
                        <FormattedMessage
                            id="absence_type_pending_request"
                            defaultMessage="Request:" />
                        <FormattedMessage
                            id="sentence_separator"
                            defaultMessage=" " />
                    </>
                )}
                <TypeName {...type} />
            </Heading>
            <CloseButton onClick={dismiss} />
        </>
    )
}

export default ViewEntryHero
