import styled from 'styled-components'
import Caption from 'components/typography/caption'
import PersonListBase from 'components/person-list'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const Hero = styled.div`
    > :last-child {
        margin-block-end: 16px;
    }
`

export const For = styled(Caption)`
    span {
        color: var(--huma-color-foreground-default);
    }
`

export const PersonList = styled(PersonListBase)`
    margin-block-start: 16px;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    padding: 16px 0;
    box-shadow: none;
`

export const NoSubordinates = styled(Caption)`
    margin-block-start: 16px;
`