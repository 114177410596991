import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { useI18n } from 'contexts/i18n'
import { isOngoing } from 'pages/absence/utilities'
import { safeFormat, isofy } from 'utilities/date-time'
import { currencies } from 'utilities/payment'
import { Card } from '../s'
import CustomTooltip from '../tooltip'
import {
    ResponsiveContainer,
    LineChart, CartesianGrid, XAxis, YAxis, Line,
    Tooltip
} from 'recharts'
import Dot from './dot'

const Chart = ({ records }) => {
    const { formatNumber } = useIntl()
    const { dateLocale: locale } = useI18n()
    const theme = useTheme()

    const [firstRecord] = records

    const now = new Date()

    const lineChartData = records
        .filter((record, index) => {
            const currentRecord = records.find(({ current }) => current)
            const nextRecord = records[index + 1]

            if(isofy(record.fromDate) > isofy(now) && currentRecord && (currentRecord.periodUnit !== record.periodUnit || currentRecord.currency !== record.currency)) {
                return false
            }

            if(isOngoing({
                start: record.fromDate,
                end: record.toDate
            }, now)) {
                return true
            }

            if(record.periodUnit !== nextRecord?.periodUnit && !!record.amount && !!nextRecord?.amount) {
                return false
            }

            if(record.currency !== nextRecord?.currency && !!record.amount && !!nextRecord?.amount) {
                return false
            }

            return true
        })
        .map((record, index) => {
            const previousRecordWithAmount = records.slice(0, index).reverse().find(record => !!record.amount)
            const hasAmount = !!record.amount

            return ({
                name: safeFormat(record.fromDate, 'PP', { locale }),
                fromDate: record.fromDate,
                toDate: record.toDate,
                value: hasAmount ? record.amount : null,
                difference: (index === 0 || !hasAmount) ?
                    0 :
                    record.amount - previousRecordWithAmount.amount
            })
        })

    const currencyDisplay = currencies[records[0].currency] || 'code'

    const minValue = Math.min(...lineChartData.filter(({ value }) => !!value).map(({ value }) => value))
    const maxValue = Math.max(...lineChartData.map(({ value }) => value))

    const interval = Math.ceil((maxValue - minValue) / 5)

    const generateTicks = () => {
        const ticks = []

        if(interval === 0) {
            return ticks
        }

        for(let index = minValue; index <= maxValue + interval; index += interval) {
            ticks.push(index)
        }

        return ticks
    }

    const valueFormatter = value => formatNumber(value, {
        style: 'currency',
        currency: records[0].currency,
        currencyDisplay: currencyDisplay,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

    return (
        <ThemeProvider theme={theme}>
            <Card className="full-width">
                <ResponsiveContainer
                    width="100%"
                    height={264}>
                    <LineChart
                        data={lineChartData}
                        margin={{
                            top: 8,
                            right: 8,
                            bottom: 0,
                            left: 0
                        }}>
                        <CartesianGrid
                            stroke="var(--huma-color-border-default)"
                            strokeDasharray="4 4"
                            vertical={false} />
                        <XAxis
                            dataKey="name"
                            tickLine={false}
                            tickSize={8} />
                        <YAxis
                            tickLine={false}
                            tickSize={4}
                            tickFormatter={value => {
                                // if(value >= 1000) {
                                //     return formatNumber(value, {
                                //         notation: 'compact',
                                //         minimumFractionDigits: 0,
                                //         maximumFractionDigits: 1
                                //     })
                                // }

                                return formatNumber(value, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })
                            }}
                            width={maxValue > 100000 ? maxValue.toString().length * 12 : 60}
                            ticks={generateTicks()}
                            domain={[
                                `dataMin - ${scale(firstRecord)}`,
                                `dataMax + ${scale(firstRecord)} + ${interval}`
                            ]} />
                        <Tooltip
                            content={(
                                <CustomTooltip valueFormatter={valueFormatter} />
                            )}
                            cursor={false} />
                        <Line
                            type="stepAfter"
                            dataKey="value"
                            fill="var(--huma-color-surface-constructive-bold)"
                            dot={({ key, ...props }) => (
                                <Dot
                                    {...props}
                                    key={key} />
                            )}
                            stroke="var(--huma-color-border-constructive-bold)"
                            strokeWidth={2}
                            connectNulls={false} />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
        </ThemeProvider>
    )
}

const scale = record => {
    const {
        currency: recordCurrency,
        periodUnit: recordPeriodUnit
    } = record

    const currency = dataDifference[recordCurrency] ?? dataDifference.default
    const periodUnit = currency[recordPeriodUnit] ?? currency.default

    return periodUnit
}

const dataDifference = {
    NOK: {
        yearly: 10000,
        monthly: 1000,
        weekly: 100,
        hourly: 100
    },
    SEK: {
        yearly: 10000,
        monthly: 1000,
        weekly: 100,
        hourly: 100
    },
    DKK: {
        yearly: 10000,
        monthly: 1000,
        weekly: 100,
        hourly: 100
    },
    ISK: {
        yearly: 10000,
        monthly: 1000,
        weekly: 100,
        hourly: 100
    },
    EUR: {
        yearly: 1000,
        monthly: 100,
        weekly: 10,
        hourly: 10
    },
    USD: {
        yearly: 1000,
        monthly: 100,
        weekly: 10,
        hourly: 10
    },
    default: {
        yearly: 1000,
        monthly: 100,
        weekly: 10,
        hourly: 10
    }
}

export default Chart
