import React, { useState, useEffect } from 'react'
import CompetenceProfileProvider, { useCompetenceProfile } from 'contexts/competence-profile'
import { size } from 'utilities/object'
import { Wrapper, Arrows } from './s'
import { ConfigurationTarget, ConfigurationPeople } from './configurations'
import { Scrollable as Modal } from 'modals/generic'
import Types from './types'
import Users from './users'
import { ArrowLeft, ArrowRight } from 'styled-icons/feather'

const GapConfiguration = ({ configuration = {}, setConfiguration, setMemoryConfiguration, salt }) => {
    const { replace } = useCompetenceProfile()

    const [configuringTypes, setConfiguringTypes] = useState(false)
    const [configuringUsers, setConfiguringUsers] = useState(false)

    const [types, setTypes] = useState(configuration?.types ?? [])
    const [users, setUsers] = useState(configuration?.users ?? {})

    useEffect(() => {
        setMemoryConfiguration(configuration)

        if(!configuration.types?.length) {
            setTypes([])
        }

        if(!configuration?.users) {
            setUsers({})
        }

        if(configuration?.profile) {
            replace(configuration.profile)
        }
    }, [configuration?.types, configuration?.users, configuration?.profile])

    return (
        <>
            <Wrapper>
                <ConfigurationTarget
                    types={types}
                    profileId={configuration?.profile ?? null}
                    setConfiguring={setConfiguringTypes}
                    salt={salt} />
                <Arrows>
                    <ArrowLeft size={24} />
                    <ArrowRight size={24} />
                </Arrows>
                <ConfigurationPeople
                    users={users}
                    setConfiguring={setConfiguringUsers} />
            </Wrapper>
            <Modal
                show={configuringTypes}
                dismiss={() => setConfiguringTypes(false)}
                salt={`${salt}:types`}>
                <Types
                    types={types}
                    setTypes={setTypes}
                    dismiss={() => setConfiguringTypes(false)}
                    onDone={({ types, profile = null }) => {
                        setConfiguration(prev => ({
                            ...prev,
                            types: types?.map(({ target, ...rest }) => ({
                                ...rest,
                                target: target ?? true
                            })),
                            profile: profile?.id ?? null
                        }))

                        setConfiguringTypes(false)

                        if(!size(users)) {
                            setConfiguringUsers(true)
                        }
                    }} />
            </Modal>
            <Modal
                show={configuringUsers}
                dismiss={() => setConfiguringUsers(false)}
                salt={`${salt}:users`}>
                <Users
                    users={users}
                    dismiss={() => setConfiguringUsers(false)}
                    onDone={users => {
                        setUsers(users)

                        setConfiguration(prev => ({
                            ...prev,
                            users
                        }))

                        setConfiguringUsers(false)

                        if(!types.length) {
                            setConfiguringTypes(true)
                        }
                    }} />
            </Modal>
        </>
    )
}

export default props => (
    <CompetenceProfileProvider>
        <GapConfiguration {...props} />
    </CompetenceProfileProvider>
)