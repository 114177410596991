import { getFullName } from 'utilities/person'

export const sort = (locale, format = getFullName) => (people = []) => {
    return people.sort((one, two) => {
        one = format(one)
        two = format(two)

        return one.localeCompare(two, locale, { sensitivity: 'base' })
    })
}
