import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import StatusLabel from 'components/status-label'

export const RiskLevel = styled(Grid).attrs({ as: 'span' })`
    justify-content: start;
    align-items: center;

    color: var(--huma-color-foreground-default);
`

export const LevelLabel = styled(StatusLabel).attrs({ as: 'span' })`
    column-gap: 8px;
    align-self: unset;

    svg {
        ${svgStroke('small')}
    }
`