import React, { useState, useEffect, Fragment } from 'react'
import { useIntl, FormattedMessage, FormattedList } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAugmentTitle } from 'contexts/page'
import { useAccess } from 'contexts/access'
import { useUserPermissions } from 'contexts/unit-permissions'
import { useProcess } from 'contexts/process'
import { usePossum } from 'hooks/possum'
import {
    useGetMeta,
    getTypePaths, getReferenceDate,
    getTitleTranslationByType, getReferenceDateLabelTranslation,
    isCompleted
} from 'pages/processes/utilities'
import { getShortName, getFullName, getJobTitle } from 'utilities/person'
import { safeFormat } from 'utilities/date-time'
import { compact } from 'utilities/array'
import { Details, ClampedParagraph } from './s'
import { Sticky, Menu, MenuColumns, MenuColumn, BackLink } from 'components/sticky-menu'
import ContextMenu from 'widgets/context-menu'
import ProcessStatus from 'pages/processes/components/process-status'
import { FlexChildShrink } from 'components/flex'
import ProgressDetails from './progress'
import ConcernsUnit from 'pages/tasks/components/concerns-unit'
import { Title } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import Clamp from 'components/clamp'
import Divider from 'components/divider'
import { DefinitionList, DefinitionTerm, DefinitionDescription } from 'components/list'
import ProcessAccess from 'pages/processes/components/process-access'
import { Button, Ghost } from 'components/button'
import StartProcess from './start'
import { Scrollable as Modal } from 'modals/generic'
import EditProcess from 'pages/processes/modals/edit-process'
import ArchiveProcess from 'pages/processes/modals/archive-process'
import UnarchiveProcess from 'pages/processes/modals/unarchive-process'
import PersonProvider from 'contexts/person'
import DeactivateAccount from 'pages/people/pages/profile/hero/deactivate'

const ProcessHeader = ({ addTask, salt }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { check } = useAccess()
    const { checkUnitPermission } = useUserPermissions()

    const {
        process,
        type,
        completed,

        removeProcess
    } = useProcess()

    const {
        concerns,
        archived = false,
        description
    } = process

    const augmentTitle = concerns.type === 'user' ?
        getFullName(concerns, { fallback: false })
        : concerns.name

    useAugmentTitle(augmentTitle)

    const getMeta = useGetMeta()

    const [startProcess, setStartProcess] = useState(false)
    const [editing, setEditing] = useState(false)
    const [archiving, setArchiving] = useState(null)
    const [unarchiving, setUnarchiving] = useState(null)
    const [deactivating, setDeactivating] = useState(null)

    useEffect(() => {
        if(!archived && completed) {
            setArchiving({
                process,
                suggestion: true
            })
        }
    }, [archived, completed])

    const possessify = usePossum()

    const canDeactivateUser =
        (check('users:manage') || checkUnitPermission('unit:users:manage')) &&
        !!process?.id && type === 'offboarding' && concerns?.status?.active === true

    const actions = compact([
        () => {
            if(type !== 'process') {
                return null
            }

            return {
                salt: `${salt}:edit`,
                label: formatMessage({
                    id: 'action_edit_details',
                    defaultMessage: 'Edit details'
                }),
                effect: 'neutral',
                onClick: () => setEditing(true)
            }
        },
        () => {
            if(!process?.id) {
                return null
            }

            const { pname, to } = getMeta(concerns)
            const translation = navigateTranslations[concerns.type]

            if(!translation || !to) {
                return null
            }

            return {
                salt: `${salt}:profile`,
                label: formatMessage(translation, { pname }),
                effect: 'neutral',
                element: 'link',
                link: { to }
            }
        },
        () => {
            if(!process?.id || !!archived) {
                return null
            }

            return {
                salt: `${salt}:archive`,
                label: formatMessage({
                    id: 'action_archive',
                    defaultMessage: 'Archive'
                }),
                effect: 'neutral',
                onClick: () => setArchiving({ process })
            }
        },
        () => {
            if(!process?.id || !archived) {
                return null
            }

            return {
                salt: `${salt}:unarchive`,
                label: formatMessage({
                    id: 'action_unarchive',
                    defaultMessage: 'Reactivate'
                }),
                effect: 'neutral',
                onClick: () => setUnarchiving(process)
            }
        },
        () => {
            if(!process?.id) {
                return null
            }

            return {
                salt: `${salt}:delete`,
                label: formatMessage(getDeleteActionTranslation(type)),
                onClick: () => removeProcess(),
                effect: 'destructive',
                prompt: {
                    question: formatMessage(getDeleteActionPromptQuestionTranslation(type)),
                    message: formatMessage(getDeleteActionPromptMessageTranslation(type)),
                    confirmText: formatMessage({
                        id: 'action_delete',
                        defaultMessage: 'Delete'
                    })
                }
            }
        },
        () => {
            if(!!process?.id) {
                return null
            }

            return {
                salt: `${salt}:cancel`,
                label: formatMessage(getDeleteActionTranslation(type)),
                effect: 'destructive',
                element: 'link',
                link: {
                    to: '..'
                }
            }
        },
        () => {
            if(!canDeactivateUser || type !== 'offboarding' || concerns?.type !== 'user') {
                return null
            }

            return {
                salt: `${salt}:delete-and-deactivate`,
                label: formatMessage({
                    id: 'employee_offboarding_action_delete_and_deactivate_user',
                    defaultMessage: 'Delete offboarding and deactivate {pname} user account'
                }, { pname: possessify(getShortName(concerns)) }),
                effect: 'destructive',
                onClick: () => setDeactivating({
                    processId: process?.id,
                    userId: concerns?.id
                })
            }
        }
    ])

    const status = !process?.id ?
        'draft'
        : archived ?
            'archived'
            : isCompleted(process?.stats) ?
                'completed'
                : 'active'

    const startAction = formatMessage(getStartActionTranslation(type))
    const title = process?.title ?? formatMessage(getTitleTranslationByType(type))
    const referenceDateLabel = formatMessage(getReferenceDateLabelTranslation(type))

    const typePaths = getTypePaths(type)
    const referenceDate = getReferenceDate(process)

    const position = concerns?.type === 'user' ? getJobTitle(concerns)?.name : null

    return (
        <>
            <Sticky className="tight">
                <Menu>
                    <FlexChildShrink>
                        <MenuColumns>
                            <MenuColumn>
                                <BackLink
                                    to={archived ?
                                        typePaths.archive :
                                        '..'
                                    }
                                    text={formatMessage({
                                        id: 'action_back',
                                        defaultMessage: 'Back'
                                    })}
                                    hideTextOnSmallDevices={true} />
                            </MenuColumn>
                            <MenuColumn>
                                <ProcessStatus status={status} />
                            </MenuColumn>
                        </MenuColumns>
                    </FlexChildShrink>
                    <FlexChildShrink>
                        <MenuColumns>
                            {!process?.id && (
                                <MenuColumn>
                                    <Button
                                        onClick={() => setStartProcess(true)}
                                        className="constructive"
                                        disabled={!!startProcess}>
                                        {startAction}
                                    </Button>
                                </MenuColumn>
                            )}
                            <MenuColumn>
                                <ContextMenu
                                    salt={salt}
                                    context={process}
                                    actions={actions} />
                            </MenuColumn>
                        </MenuColumns>
                    </FlexChildShrink>
                </Menu>
            </Sticky>
            <Details>
                <Ghost
                    onClick={addTask}
                    className="constructive">
                    <FormattedMessage
                        id="task_action_add"
                        defaultMessage="Add task" />
                </Ghost>
                <ProgressDetails concerns={concerns} />
                <div>
                    <Title className="compact">{title}</Title>
                    {concerns.type !== 'organization' && (
                        <Paragraph className="caption compact">
                            <FormattedList
                                value={compact([
                                    formatMessage({
                                        id: 'meta_for',
                                        defaultMessage: 'For {user}'
                                    }, { user: <ConcernsUnit concerns={concerns} /> }),
                                    position
                                ])}
                                style="short"
                                type="unit" />
                        </Paragraph>
                    )}
                </div>
                {description && (
                    <>
                        <Clamp
                            lines={3}
                            paragraphComponent={ClampedParagraph}
                            paragraphClassName="preamble"
                            className="compact">
                            {description}
                        </Clamp>
                        <Divider $size="0" />
                    </>
                )}
                <DefinitionList>
                    <DefinitionTerm>{referenceDateLabel}</DefinitionTerm>
                    <DefinitionDescription>
                        <Paragraph className="compact">{safeFormat(referenceDate, 'PPPP', { locale })}</Paragraph>
                    </DefinitionDescription>
                    {type === 'process' && (
                        <>
                            <DefinitionTerm>
                                <FormattedMessage
                                    id="noun_access"
                                    defaultMessage="Access" />
                            </DefinitionTerm>
                            <DefinitionDescription>
                                <ProcessAccess
                                    concerns={concerns}
                                    salt={salt} />
                            </DefinitionDescription>
                        </>
                    )}
                </DefinitionList>
            </Details>
            <StartProcess
                show={!!startProcess}
                type={type}
                dismiss={() => setStartProcess(false)}
                salt={`${salt}:start`} />
            <Modal
                show={!!editing}
                dismiss={() => setEditing(false)}
                salt={salt}>
                <EditProcess
                    concerns={concerns}
                    dismiss={() => setEditing(false)}
                    salt={`${salt}:edit`} />
            </Modal>
            <Modal
                show={!!archiving}
                dismiss={() => setArchiving(null)}
                salt={salt}>
                <ArchiveProcess
                    {...archiving}
                    context="single"
                    dismiss={() => setArchiving(null)} />
            </Modal>
            <Modal
                show={!!unarchiving}
                dismiss={() => setUnarchiving(null)}
                salt={salt}>
                <UnarchiveProcess
                    process={unarchiving}
                    context="single"
                    dismiss={() => setUnarchiving(null)} />
            </Modal>
            {canDeactivateUser && (
                <PersonProvider id={deactivating?.userId}>
                    <Modal
                        show={!!deactivating}
                        dismiss={() => setDeactivating(null)}
                        salt={salt}>
                        <DeactivateAccount
                            onBeforeDeactivate={() => removeProcess(deactivating?.processId)}
                            cancel={() => setDeactivating(null)}
                            salt={`${salt}:delete-and-deactivate-user`} />
                    </Modal>
                </PersonProvider>
            )}
        </>
    )
}

const getDeleteActionTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_action_delete',
        defaultMessage: 'Delete onboarding'
    },
    offboarding: {
        id: 'employee_offboarding_action_delete',
        defaultMessage: 'Delete offboarding'
    },
    process: {
        id: 'process_action_delete',
        defaultMessage: 'Delete process'
    }
})[type]

const navigateTranslations = {
    user: {
        id: 'action_navigate_user',
        defaultMessage: 'Go to {pname} profile'
    },
    team: {
        id: 'team_action_navigate',
        defaultMessage: 'Go to team'
    },
    location: {
        id: 'location_action_navigate',
        defaultMessage: 'Go to location'
    }
}

export const getDeleteActionPromptQuestionTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_person_delete_prompt_question',
        defaultMessage: 'Confirm removing this onboarding'
    },
    offboarding: {
        id: 'employee_offboarding_person_delete_prompt_question',
        defaultMessage: 'Confirm removing this offboarding'
    },
    process: {
        id: 'process_delete_prompt_question',
        defaultMessage: 'Confirm removing this process'
    }
})[type]

export const getDeleteActionPromptMessageTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_person_delete_prompt_message',
        defaultMessage: 'The onboarding and all related tasks will be deleted. This can not be undone.'
    },
    offboarding: {
        id: 'employee_offboarding_person_delete_prompt_message',
        defaultMessage: 'The offboarding and all related tasks will be deleted. This can not be undone.'
    },
    process: {
        id: 'process_delete_prompt_message',
        defaultMessage: 'The process and all related tasks will be deleted. This can not be undone.'
    }
})[type]

const getStartActionTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_action_start',
        defaultMessage: 'Start onboarding'
    },
    offboarding: {
        id: 'employee_offboarding_action_start',
        defaultMessage: 'Start offboarding'
    },
    process: {
        id: 'process_action_start',
        defaultMessage: 'Start process'
    }
})[type]

export default ProcessHeader