import React from 'react'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { Entries } from './s'
import Entry from './entry'

const GroupedEntries = ({ entries, salt, ...props }) => {
    const { intersecter } = useAbsenceEntries()

    return (
        <Entries>
            {entries.map((entry, index) => {
                const last = index + 1 === entries.length

                return (
                    <Entry
                        {...props}
                        entry={entry}
                        {...(last ? { ref: intersecter }: null)}
                        key={`${salt}:${entry.id}`} />
                )
            })}
        </Entries>
    )
}

export default GroupedEntries
