/**
 *      ℹ️ Available @formatjs/intl-pluralrules locales:
 *      node_modules/@formatjs/intl-pluralrules/locale-data/
 *
 *      ℹ️ Available @formatjs/intl-displaynames locales:
 *      node_modules/@formatjs/intl-displaynames/locale-data/
 *
 *      ℹ️ Available @formatjs/intl-datetimeformat locales:
 *      node_modules/@formatjs/intl-datetimeformat/locale-data/
 *
 *      ℹ️ Available @formatjs/intl-relativetimeformat locales:
 *      node_modules/@formatjs/intl-relativetimeformat/locale-data/
 *
 *      ℹ️ Available date-fns locales:
 *      https://github.com/date-fns/date-fns/tree/main/src/locale
 *
 *      ℹ️ Available react-phone-number-input locales:
 *      https://github.com/catamphetamine/react-phone-number-input/tree/master/locale
 *
 *      ℹ️ Supported Scrive locales:
 *      https://helpcenter.scrive.com/kb/guide/en/what-languages-are-supported-jSLEBhoCMH/Steps/1770941
 *      Remember to update `unsupportedScriveLocales` when adding an unsupported locale
 *
 *      ⚠️ When adding a new locale, remember to also specify it in the
 *      `dateFnsLocales` and `formatJsLocales` arrays in package.json
 *
 *      … and in src/components/time-relative.js, to ensure that each language’s ‘about’ word is removed
 */

import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill'
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'
import React, { Component, createContext, useContext } from 'react'
import { IntlProvider, createIntlCache, createIntl } from 'react-intl'
import { get, patch } from 'api'
import { local } from 'utilities/storage'
import { getChannel } from 'utilities/broadcaster'
import { pick, omit, size, compact } from 'utilities/object'
import { prune, compact as compactArray } from 'utilities/array'
import { capitalize } from 'utilities/string'
import { requestAccess } from 'utilities/auth'
import { setGlobal } from 'utilities/global'
import pkg from 'package'

// To get an updated list of date-fns locales, run:
// import * as dateLocales from 'date-fns/locale'
// console['log'](Object.keys(dateLocales))

const defaultLocale = 'en'
const defaultDateLocaleOverrides = {}

// const availableDateLocales = ['af', 'ar', 'arDZ', 'arEG', 'arMA', 'arSA', 'arTN', 'az', 'be', 'beTarask', 'bg', 'bn', 'bs', 'ca', 'cs', 'cy', 'da', 'de', 'deAT', 'el', 'enAU', 'enCA', 'enGB', 'enIE', 'enIN', 'enNZ', 'enUS', 'enZA', 'eo', 'es', 'et', 'eu', 'faIR', 'fi', 'fr', 'frCA', 'frCH', 'fy', 'gd', 'gl', 'gu', 'he', 'hi', 'hr', 'ht', 'hu', 'hy', 'id', 'is', 'it', 'itCH', 'ja', 'jaHira', 'ka', 'kk', 'km', 'kn', 'ko', 'lb', 'lt', 'lv', 'mk', 'mn', 'ms', 'mt', 'nb', 'nl', 'nlBE', 'nn', 'oc', 'pl', 'pt', 'ptBR', 'ro', 'ru', 'sk', 'sl', 'sq', 'sr', 'srLatn', 'sv', 'ta', 'te', 'th', 'tr', 'ug', 'uk', 'uz', 'uzCyrl', 'vi', 'zhCN', 'zhHK', 'zhTW']

const getDateLocaleFromLocale = locale => {
    // if(locale === 'en' || !availableDateLocales.includes(locale)) {
    if(locale === 'en' || !(locale in locales)) {
        return 'en-US'
    }

    return locale
}

const getMessagesLocale = locale => {
    if(!(locale in locales)) {
        return 'en'
    }

    return locale
}

const localeToScriveLocale = {
    nb: 'no'
}

const unsupportedScriveLocales = []

export const prioritizedCountryCodes = ['NO', 'SE', 'FI', 'DK']
export const prioritizedLanguageCodes = ['en', 'nb', 'sv', 'fi', 'da']

const localeToPrioritizedCountryCodes = locale => prune(({
    sv: ['SE', 'FI', ...prioritizedCountryCodes],
    da: ['DK', 'SE', ...prioritizedCountryCodes],
    fi: ['FI', 'SE', ...prioritizedCountryCodes],
    en: [...prioritizedCountryCodes, 'GB', 'US'],
    nl: ['NL', ...prioritizedCountryCodes, 'GB', 'US'],
    et: ['EE', 'FI', ...prioritizedCountryCodes],
    lv: ['LV', 'EE', ...prioritizedCountryCodes]
})[locale] ?? prioritizedCountryCodes)

const localeToPrioritizedLanguageCodes = locale => ({
    fi: ['fi', 'sv'],
    et: ['et', 'fi'],
})[locale] ?? [locale]

const localeToHtmlDirection = {
    // arabic: 'rtl'
}

const localeSettings = [
    'language',
    'weekStartsOn',
    'showBirthday'
]

const dateLocaleOverrideSettings = ['weekStartsOn']

const defaultRichTextElements = {
    strong: chunks => <strong>{chunks}</strong>,
    italic: chunks => <em>{chunks}</em>,
    underline: chunks => <u>{chunks}</u>
}

defaultRichTextElements.bold = defaultRichTextElements.strong
defaultRichTextElements.b = defaultRichTextElements.strong
defaultRichTextElements.em = defaultRichTextElements.italic
defaultRichTextElements.i = defaultRichTextElements.italic
defaultRichTextElements.u = defaultRichTextElements.underline

const intlCache = createIntlCache()

export const I18nContext = createContext()
export const LocalI18nContext = createContext()

export default class I18nProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            locales,
            authorized: false,

            settings: null,
            holidayTerritories: [],
            pluralRules: null,
            displayNames: true,
            dateTimeFormat: null,
            relativeTimeFormat: null,

            setLocale: this.setLocale,
            updateSettings: this.updateSettings,
            fetchSettings: this.fetchSettings,
            fetchHolidayTerritories: this.fetchHolidayTerritories,
            getIntl: this.getIntl
        }

        setGlobal('locale', { set: this.setLocale })

        this.syncer = getChannel('i18n')
    }

    componentDidMount() {
        this.fetchSettings()
        this.loadPluralRules()
        // this.loadDisplayNames()
        this.loadDateTimeFormat()
        this.loadRelativeTimeFormat()

        this.syncer.onmessage = ({ data }) => this.load(data)
    }

    componentDidUpdate(props, {
        settings: hadSettings,
        pluralRules: hadPluralRules,
        displayNames: hadDisplayNames,
        dateTimeFormat: hadDateTimeFormat,
        relativeTimeFormat: hadRelativeTimeFormat
    }) {
        const {
            authorized,
            settings,
            pluralRules: hasPluralRules,
            displayNames: hasDisplayNames,
            dateTimeFormat: hasDateTimeFormat,
            relativeTimeFormat: hasRelativeTimeFormat
        } = this.state

        const settingsChanged = hadSettings !== settings
        const pluralRulesChanged = hadPluralRules !== hasPluralRules
        const displayNamesChanged = hadDisplayNames !== hasDisplayNames
        const dateTimeFormatChanged = hadDateTimeFormat !== hasDateTimeFormat
        const relativeTimeFormatChanged = hadRelativeTimeFormat !== hasRelativeTimeFormat
        const loaded = (settings || !authorized) && hasPluralRules && hasDisplayNames && hasDateTimeFormat && hasRelativeTimeFormat

        if((settingsChanged || pluralRulesChanged || displayNamesChanged || dateTimeFormatChanged || relativeTimeFormatChanged) && loaded) {
            if(authorized) {
                const dateLocaleOverrides = pick(settings, ...dateLocaleOverrideSettings)

                this.load({
                    ...(!!settings?.language ? { locale: settings.language } : null),
                    ...(!!size(dateLocaleOverrides) ? { dateLocaleOverrides } : null)
                })
            } else {
                this.load()
            }
        }
    }

    fetchSettings = async () => {
        const { ok: tokensOk, response: tokens } = await requestAccess({ bounce: false })
        const authorized = tokensOk && !!tokens?.accessToken

        let settings = null

        if(authorized) {
            const { ok, response } = await get({ path: '/users/me/settings' })

            if(ok) {
                settings = {
                    ...compact(omit(response, 'showBirthday')),
                    ...pick(response, 'showBirthday')
                }
            }
        }

        this.setState({
            authorized,
            settings: authorized ?
                pick(settings, ...localeSettings) :
                null
        })
    }

    fetchHolidayTerritories = async () => {
        const { ok, response } = await get({ path: '/work-schedules/countries' })

        let holidayTerritories = []

        if(ok) {
            holidayTerritories = generateTerritories(response, this.state.locale, 'holidayTerritories').territories

            this.setState({ holidayTerritories })
        }

        return { ok, response: holidayTerritories }
    }

    updateSettings = async (body, updateState = false) => {
        body = pick(body, ...localeSettings)

        const { ok } = await patch({
            path: '/users/me/settings',
            body
        })

        if(ok && !!updateState) {
            this.setState(({ settings }) => ({
                settings: {
                    ...settings,
                    ...body
                }
            }))
        }
    }

    loadPluralRules = async () => {
        if(shouldPolyfillPluralRules()) {
            await import('@formatjs/intl-pluralrules/polyfill')

            if(Intl.PluralRules.polyfilled) {
                const imports = pkg.formatJsLocales
                    .map(locale => import(`@formatjs/intl-pluralrules/locale-data/${locale}`) )

                await Promise.all(imports)
            }
        }

        this.setState({ pluralRules: true })
    }

    loadDisplayNames = async () => {
        if(shouldPolyfillDisplayNames()) {
            await import('@formatjs/intl-displaynames/polyfill')

            if(Intl.DisplayNames.polyfilled) {
                const imports = pkg.formatJsLocales
                    .map(locale => import(`@formatjs/intl-displaynames/locale-data/${locale}`) )

                await Promise.all(imports)
            }
        }

        this.setState({ displayNames: true })
    }

    loadDateTimeFormat = async () => {
        if(shouldPolyfillDateTimeFormat()) {
            await import('@formatjs/intl-datetimeformat/polyfill')

            if(Intl.DateTimeFormat.polyfilled) {
                const imports = pkg.formatJsLocales
                    .map(locale => import(`@formatjs/intl-datetimeformat/locale-data/${locale}`))

                await Promise.all(imports)
            }
        }

        this.setState({ dateTimeFormat: true })
    }

    loadRelativeTimeFormat = async () => {
        if(shouldPolyfillRelativeTimeFormat()) {
            await import('@formatjs/intl-relativetimeformat/polyfill')

            if(Intl.RelativeTimeFormat.polyfilled) {
                const imports = pkg.formatJsLocales
                    .map(locale => import(`@formatjs/intl-relativetimeformat/locale-data/${locale}`))

                await Promise.all(imports)
            }
        }

        this.setState({ relativeTimeFormat: true })
    }

    load = async (config = {}) => {
        let {
            locale = local.get('locale') ?? defaultLocale,
            dateLocaleOverrides = local.get('dateLocaleOverrides') ?? defaultDateLocaleOverrides,
            options = {}
        } = config

        if(!(locale in locales)) {
            return
        }

        const state = await this.getIntlFromLocale({
            locale,
            dateLocaleOverrides
        })

        !!state && this.setState(state)

        if(!!options?.store) {
            this.updateSettings({
                language: locale,
                ...dateLocaleOverrides
            }, true)
        }

        this.setHtmlDirection(state.direction)
    }

    getIntlFromLocale = async ({ locale, dateLocaleOverrides }) => {
        const messagesLocale = getMessagesLocale(locale)
        const dateLocaleName = getDateLocaleFromLocale(locale)
        const direction = localeToHtmlDirection[locale] || 'ltr'

        const [
            { default: messages },
            { default: territoryCodes },
            { default: languageCodes },
            { default: dateLocale }
        ] = await Promise.all([
            import(`i18n/messages/compiled/${messagesLocale}.json`),
            import('i18n/territory-codes.json'),
            import('i18n/language-codes.json'),
            import(`date-fns/locale/${dateLocaleName}/index.js`)
        ])

        const intl = {
            defaultLocale,
            locale,
            messages,
            dateLocale: {
                ...dateLocale,
                options: {
                    ...dateLocale.options,
                    ...dateLocaleOverrides,

                    // TODO: Consider making this configurable at one point, as any
                    // future US users will be alienated by the consequences of it
                    firstWeekContainsDate: 4
                }
            },
            dateLocaleName,
            ...generateTerritories(territoryCodes.map(code => ({ code })), locale),
            ...generateLanguages(languageCodes, locale),
            direction
        }

        if(locale !== messagesLocale) {
            intl.locale = messagesLocale
        }

        return intl
    }

    getIntl = async ({ locale, ...options }) => {
        const data = await this.getIntlFromLocale({
            ...options,
            locale
        })

        return {
            ...data,
            intl: createIntl({
                locale,
                messages: data.messages
            }, intlCache)
        }
    }

    setLocale = async (config = {}, options = {}) => {
        const {
            locale,
            dateLocaleOverrides
        } = config

        if(!(locale in locales)) {
            return
        }

        this.load({
            locale,
            dateLocaleOverrides,
            options
        })

        this.syncer.postMessage({
            locale,
            dateLocaleOverrides
        })

        const thirtyDaysAsMinutes = 60 * 24 * 30
        local.set('locale', locale, { expiry: thirtyDaysAsMinutes })

        // Avoid overwriting overrides with default date-fns
        // dateLocale options for users who aren’t logged in
        if(!!options?.store) {
            local.set('dateLocaleOverrides', dateLocaleOverrides, { expiry: thirtyDaysAsMinutes })
        }
    }

    setHtmlDirection = direction => document.documentElement.setAttribute('dir', direction)

    render() {
        const { children = null } = this.props
        const { defaultLocale, locale, messages } = this.state

        if(!locale) {
            return null
        }

        return (
            <I18nContext.Provider value={this.state}>
                <IntlProvider
                    defaultLocale={defaultLocale}
                    locale={locale}
                    messages={messages}
                    defaultRichTextElements={defaultRichTextElements}
                    onError={() => {}}>
                    {(typeof children === 'function') && children(this.state)}
                    {(typeof children !== 'function') && children}
                </IntlProvider>
            </I18nContext.Provider>
        )
    }
}

export class LocalI18nProvider extends I18nProvider {
    constructor(props) {
        super(props)

        this.state = {
            locales,

            pluralRules: null,
            displayNames: true,
            dateTimeFormat: null,
            relativeTimeFormat: null,

            setLocale: this.setLocale
        }
    }

    componentDidUpdate(props, {
        pluralRules: hadPluralRules,
        displayNames: hadDisplayNames,
        dateTimeFormat: hadDateTimeFormat,
        relativeTimeFormat: hadRelativeTimeFormat
    }) {
        const {
            pluralRules: hasPluralRules,
            displayNames: hasDisplayNames,
            dateTimeFormat: hasDateTimeFormat,
            relativeTimeFormat: hasRelativeTimeFormat
        } = this.state

        const pluralRulesChanged = hadPluralRules !== hasPluralRules
        const displayNamesChanged = hadDisplayNames !== hasDisplayNames
        const dateTimeFormatChanged = hadDateTimeFormat !== hasDateTimeFormat
        const relativeTimeFormatChanged = hadRelativeTimeFormat !== hasRelativeTimeFormat

        if(pluralRulesChanged || displayNamesChanged || dateTimeFormatChanged || relativeTimeFormatChanged) {
            this.load()
        }
    }

    componentDidMount() {
        this.fetchSettings()
        this.loadPluralRules()
        this.loadDateTimeFormat()
        this.loadRelativeTimeFormat()
    }

    setLocale = async (config = {}) => {
        const {
            locale,
            dateLocaleOverrides
        } = config

        if(!(locale in locales)) {
            return
        }

        this.load({
            locale,
            dateLocaleOverrides
        })
    }

    setHtmlDirection = () => {}

    render() {
        const { children = null } = this.props
        const { defaultLocale, locale, messages } = this.state

        if(!locale) {
            return null
        }

        return (
            <LocalI18nContext.Provider value={this.state}>
                <IntlProvider
                    defaultLocale={defaultLocale}
                    locale={locale}
                    messages={messages}
                    defaultRichTextElements={defaultRichTextElements}
                    onError={() => {}}>
                    {(typeof children === 'function') && children(this.state)}
                    {(typeof children !== 'function') && children}
                </IntlProvider>
            </LocalI18nContext.Provider>
        )
    }
}

export const locales = {
    en: {
        name: 'English',
        flag: '🇺🇸'
    },
    nb: {
        name: 'Norsk',
        flag: '🇳🇴'
    },
    sv: {
        name: 'Svenska',
        flag: '🇸🇪'
    },
    fi: {
        name: 'Suomi',
        flag: '🇫🇮'
    },
    da: {
        name: 'Dansk',
        flag: '🇩🇰'
    },
    nl: {
        name: 'Nederlands',
        flag: '🇳🇱'
    },
    pl: {
        name: 'Polski',
        flag: '🇵🇱'
    },
    et: {
        name: 'Eesti',
        flag: '🇪🇪'
    },
    lv: {
        name: 'Latviešu',
        flag: '🇱🇻'
    }
}

const sortByValue = locale => ([, one], [, two]) => one.localeCompare(two, locale, { sensitivity: 'base' })

const excludeSubregionsForHolidayTerritories = [
    'NO',
    'SE',
    'FI',
    'DK'
]

export const generateTerritories = (territoryCodes, locale, mode = 'territories') => {
    const formatter = new Intl.DisplayNames([locale], { type: 'region' })
    const sorter = sortByValue(locale)
    const localePrioritizedCountryCodes = localeToPrioritizedCountryCodes(locale)

    const entries = territoryCodes.map(({ code }) => ([
        code,
        capitalize(formatter.of(code))
    ]))

    const localePrioritizedCountries = localePrioritizedCountryCodes
        .map(prioritizedCountryCode => entries.find(([code]) => code === prioritizedCountryCode))

    const unknown = entries.find(([code]) => code === 'ZZ')

    let sorted = compactArray([
        ...localePrioritizedCountries,
        ...entries
            .filter(([code]) => !localePrioritizedCountryCodes.includes(code) && code !== 'ZZ')
            .sort(sorter),
        unknown
    ])

    if(mode === 'holidayTerritories' && territoryCodes[0]?.regions) {
        const territories = sorted.map(([code, name]) => {
            const regions = territoryCodes.find(({ code: territoryCode }) => territoryCode === code)?.regions

            if(regions && !excludeSubregionsForHolidayTerritories.includes(code)) {
                return {
                    code,
                    name,
                    regions
                }
            }

            return { code, name }
        })

        return {
            territories,
            sortedCountryCodes: Object.fromEntries(sorted)
        }
    }

    const territories = Object.fromEntries(sorted)

    return {
        territories,
        sortedCountryCodes: Object.keys(territories)
    }
}

const generateLanguages = (languageCodes, locale) => {
    const formatter = new Intl.DisplayNames([locale], { type: 'language' })
    const sorter = sortByValue(locale)
    const localePrioritizedLanguageCodes = prune([
        ...localeToPrioritizedLanguageCodes(locale),
        ...prioritizedLanguageCodes.flatMap(localeToPrioritizedLanguageCodes)
    ])

    const entries = languageCodes
        .reduce((accumulator, code) => {
            const name = formatter.of(code)

            if(name === code || accumulator.values.includes(name)) {
                return accumulator
            }

            accumulator.values.push(name)
            accumulator.entries.push([code, capitalize(name)])

            return accumulator
        }, {
            values: [],
            entries: []
        }).entries

    const localePrioritizedLanguages = localePrioritizedLanguageCodes
        .map(prioritizedLanguageCode => entries.find(([code]) => code === prioritizedLanguageCode))

    const sorted = [
        ...localePrioritizedLanguages,
        ...entries
            .filter(([code]) => !localePrioritizedLanguageCodes.includes(code))
            .sort(sorter)
    ]

    const languages = Object.fromEntries(sorted)
    const sortedLanguageCodes = Object.keys(languages)

    const scriveLocales = sortedLanguageCodes
        .filter(locale => (locale in locales) && !unsupportedScriveLocales.includes(locale))
        .map(locale => ({
            locale: localeToScriveLocale[locale] ?? locale,
            name: locales[locale].name
        }))

    return {
        languages,
        sortedLanguageCodes,
        scriveLocales
    }
}

export const useI18n = () => useContext(I18nContext)
export const useLocalI18n = () => useContext(LocalI18nContext)