import React, { Component, createContext, useContext } from 'react'
import { useEquipmentPieces } from 'contexts/equipment-pieces'
import { get, post, patch, remove } from 'api'

export const EquipmentPieceContext = createContext()

class EquipmentPieceProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            piece: null,
            deleted: false,

            fetchPiece: this.fetch,
            addPiece: this.add,
            updatePiece: this.update,
            removePiece: this.remove,

            uploadDocumentation: this.upload,
            removeDocumentation: this.removeDocumentation,
            getDocumentationPreviewUrl: this.getDocumentationPreviewUrl,
            getDocumentationDownloadUrl: this.getDocumentationDownloadUrl
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    fetch = async (id = this.props.id) => {
        if(!id) {
            return
        }

        const { response: piece, ok } = await get({ path: `/equipment/${id}` })

        !!ok && this.setState({ piece })

        return { ok, response: piece }
    }

    add = async body => {
        const { response, ok } = await post({
            path: '/equipment',
            body
        })

        if(ok && response) {
            this.props.pieces.pushPiece(response)
        }

        return { ok, response }
    }

    update = async body => {
        const { id } = this.props

        const { ok, response: piece } = await patch({
            path: `/equipment/${id}`,
            body
        })

        if(ok && piece) {
            this.setState({ piece })
            this.props.pieces.updatePieceLocally(body, id)
        }

        return { ok, response: piece }
    }

    remove = async () => {
        const { id } = this.props

        const { ok } = await remove({
            path: `/equipment/${id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    upload = async (body, pieceId) => {
        const { ok, response } = await post({
            path: `/equipment/${pieceId}/attachments`,
            body
        })

        if(ok && response) {
            this.setState(({ piece }) => ({
                piece: {
                    ...piece,
                    attachments: [
                        ...piece.attachments,
                        response
                    ]
                }
            }))

            const { piece } = this.state

            this.props.pieces.updatePieceLocally(
                {
                    ...piece,
                    attachments: [
                        ...piece.attachments,
                        response
                    ]
                },
                pieceId
            )
        }

        return { ok, response }
    }

    removeDocumentation = async (pieceId, id) => {
        const { ok } = await remove({
            path: `/equipment/${pieceId}/attachments/${id}`,
            returnsData: false
        })

        this.setState(({ piece }) => ({
            piece: {
                ...piece,
                attachments: piece.attachments.filter(({ id: attachmentId }) => attachmentId !== id)
            }
        }))

        this.props.pieces.updatePieceLocally(
            {
                ...this.state.piece,
                attachments: this.state.piece.attachments.filter(({ id: attachmentId }) => attachmentId !== id)
            },
            pieceId
        )

        return { ok }
    }

    getDocumentationPreviewUrl = (id, attachmentId) => {
        return `/equipment/${id}/attachments/${attachmentId}/preview`
    }

    getDocumentationDownloadUrl = (id, attachmentId) => {
        return `/equipment/${id}/attachments/${attachmentId}/download`
    }

    render() {
        const { children = null } = this.props

        return (
            <EquipmentPieceContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EquipmentPieceContext.Provider>
        )
    }
}

export default props => {
    const pieces = useEquipmentPieces()

    return (
        <EquipmentPieceProvider
            {...props}
            pieces={pieces} />
    )
}

export const useEquipmentPiece = () => useContext(EquipmentPieceContext)