import { css, keyframes } from 'styled-components'
import { fontSans } from 's'

export const mute = (one, two) => `color-mix(in srgb, ${one} 75%, ${two})`

// Breakpoints

export const numbers = {
    full: 1520,
    desktop: 1200,
    laptop: 1024,
    tablet: 992,
    phone: 768
}

const getSizeFromBreakpoint = (breakpointValue, breakpoints = {}, below = false) => {
    if(breakpoints[breakpointValue]) {
        if(below) {
            return breakpoints[breakpointValue] - 1
        }

        return breakpoints[breakpointValue]
    } else if (parseInt(breakpointValue, 10)) {
        return breakpointValue
    } else {
        console.error('No valid breakpoint specified.')
        return '0'
    }
}

const generateMedia = (breakpoints = numbers) => {
    const below = breakpoint => (...args) => css`
        @media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints, true)}px) {
            ${css(...args)}
        }
    `

    const from = breakpoint => (...args) => css`
        @media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}px) {
            ${css(...args)}
        }
    `

    const between = (firstBreakpoint, secondBreakpoint) => (...args) => css`
        @media (min-width: ${getSizeFromBreakpoint(firstBreakpoint, breakpoints)}px) and
            (max-width: ${getSizeFromBreakpoint(secondBreakpoint, breakpoints, true)}px) {
                ${css(...args)}
            }
    `

    return {
        below,
        from,
        between
    }
}

export const bp = generateMedia({
    full: numbers.full,
    desktop: numbers.desktop,
    laptop: numbers.laptop,
    tablet: numbers.tablet,
    phone: numbers.phone
})

export const belowFull = bp.below('full')
export const belowDesktop = bp.below('desktop')
export const belowLaptop = bp.below('laptop')
export const belowTablet = bp.below('tablet')
export const belowPhone = bp.below('phone')

export const fromFull = bp.from('full')
export const fromDesktop = bp.from('desktop')
export const fromLaptop = bp.from('laptop')
export const fromTablet = bp.from('tablet')
export const fromPhone = bp.from('phone')

// Container queries

export const belowContainer = ({ name, breakpoint }) => (...args) => css`
    @container ${name} (width < ${breakpoint}px) {
        ${css(...args)}
    }
`

export const fromContainer = ({ name, breakpoint }) => (...args) => css`
    @container ${name} (width >= ${breakpoint}px) {
        ${css(...args)}
    }
`

export const profileContainer = {
    name: 'profile',
    breakpoint: 850
}

// Other

export const iPhone11 = styles => css`
    @media only screen
        and (min-device-width: 375px)
        and (max-device-width: 812px)
        and (-webkit-device-pixel-ratio: 3)
        and (orientation: portrait) {
            ${styles}
        }
`

export const Safari = styles => css`
    @supports (background: -webkit-named-image(i)) {
        ${styles}
    }
`

export const Safari10 = styles => css`
    @media not all and (min-resolution: .001dpcm) {
        @media {
            ${styles}
        }
    }
`

export const iOSSafari = styles => css`
    @supports (-webkit-touch-callout: none) {
        ${styles}
    }
`

export const retina = styles => css`
    @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            ${styles}
        }
`

export const nonRetina = styles => css`
    @media
        only screen and (-webkit-max-device-pixel-ratio: 1),
        only screen and (max--moz-device-pixel-ratio: 1),
        only screen and (-o-max-device-pixel-ratio: 1/1),
        only screen and (max-device-pixel-ratio: 1),
        only screen and (max-resolution: 156dpi),
        only screen and (max-resolution: 1dppx) {
            ${styles}
        }
`


export const svgStroke = (size = 'normal') => css`
    &:not([stroke-width]) {
        stroke-width: 2;

        @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            stroke-width: ${(size === 'small') ? 2 : 1.5};
        }
    }
`

export const print = styles => css`
    @media only print {
        ${styles}
    }
`

export const screen = styles => css`
    @media only screen {
        ${styles}
    }
`

export const darkMode = styles => css`
    html[data-theme="dark"] & {
        @media only screen {
            ${styles}
        }
    }
`

export const screenReaderOnly = css`
    position: absolute;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    overflow: hidden;
    white-space: nowrap;

    width: 1px;
    height: 1px;
`

export const hideScrollbar = css`
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
`

// Put a dividing interpoint (a ·) between the direct children of an element.
export const interpointDivider = (size = 4) => css`
    & > :not(:last-child) {
        position: relative;

        margin-inline-end: ${size * 3}px;

        &::after {
            content: "·";
            position: absolute;

            width: ${size * 2}px;
            transform: translateX(${size}px);

            ${fontSans}
            text-align: start;
            color: var(--huma-color-foreground-subtle);
        }
    }
`

export const flashKeyframes = keyframes`
    0% {
        background-color: var(--huma-color-surface-warning-minimal);
    }

    100% {
        background-color: transparent;
    }
`

export const messageColors = css`
    &.success {
        color: var(--huma-color-foreground-success);
    }

    &.info {
        color: var(--huma-color-foreground-info-bold);
    }

    &.warning {
        color: var(--huma-color-foreground-warning);
    }

    &.error {
        color: var(--huma-color-foreground-error);
    }

    &:where(.caution, .idea) {
        color: var(--huma-color-foreground-default);
    }
`

export const buttonOutlineProps = {
    width: 2,
    offset: 1
}

export const deactivated = css`
    filter: brightness(1.1) grayscale(1);
`

export const symbolHoverCss = css`
    filter: saturate(1.5) brightness(0.85) contrast(1.3);

    html[data-theme="dark"] & {
        filter: saturate(1.1) brightness(1.05) contrast(1.1);
    }
`
