const htmlToReactattributeMap = {
    ['accept-charset']: 'acceptCharset',
    accesskey: 'accessKey',
    allowfullscreen: 'allowFullScreen',
    autocomplete: 'autoComplete',
    autofocus: 'autoFocus',
    autoplay: 'autoPlay',
    charset: 'charSet',
    class: 'className',
    colspan: 'colSpan',
    contenteditable: 'contentEditable',
    datetime: 'dateTime',
    enctype: 'encType',
    for: 'htmlFor',
    formaction: 'formAction',
    hreflang: 'hrefLang',
    ['http-equiv']: 'httpEquiv',
    maxlength: 'maxLength',
    novalidate: 'noValidate',
    readonly: 'readOnly',
    rowspan: 'rowSpan',
    spellcheck: 'spellCheck',
    srcdoc: 'srcDoc',
    srcset: 'srcSet',
    tabindex: 'tabIndex',
    usemap: 'useMap',
    disablekbcontrols: 'disableKBcontrols',
    enableiframeapi: 'enableIFrameApi'
}

export const getReactAttribute = htmlAttribute => htmlToReactattributeMap[htmlAttribute.toLowerCase()] ?? htmlAttribute