import React from 'react'
import { useIntl } from 'react-intl'
import { FilterRow, Form, SearchIcon, SearchField } from './s'

const Search = ({ setSearch, salt }) => {
    const { formatMessage } = useIntl()

    return (
        <FilterRow>
            <Form>
                <SearchField
                    salt={salt}
                    className="compact"
                    label={false}
                    placeholder={formatMessage({
                        id: 'competence_gap_action_find_profile',
                        defaultMessage: 'Find profile'
                    })}
                    name="search"
                    field={{ unsettable: true }}
                    onChange={({ search }) => setSearch(search)} />
                <SearchIcon size={16} />
            </Form>
        </FilterRow>
    )
}

export default Search
