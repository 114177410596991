import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useInterval } from 'hooks/interval'
import { useMatch, useNavigate } from 'react-router-dom'
import { calculateTrialDaysLeft } from 'utilities/payment'
import paths from 'app/paths'
import { Benjamin } from './s'

const Continue = ({ subscription }) => {
    const [daysLeft, setDaysLeft] = useState(calculateTrialDaysLeft(subscription))
    useInterval(() => setDaysLeft(calculateTrialDaysLeft(subscription)), 60 * 60 * 1000)

    const match = useMatch(paths.settings.subscription.base)
    const navigate = useNavigate()

    if(daysLeft > 14 || !subscription?.public) {
        return null
    }

    return (
        <Benjamin onClick={() => navigate(paths.settings.subscription.base, {
            replace: !!match,
            state: { intent: 'purchase' }
        })}>
            <FormattedMessage
                id="payment_plan_action_continue_with_huma"
                defaultMessage="Continue with Huma" />
        </Benjamin>
    )
}

export default Continue