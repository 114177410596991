import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { useMe } from 'contexts/me'
import { useGetCompanyUrl } from 'utilities/url'
import { useScript } from 'hooks/script'
import { useI18n } from 'contexts/i18n'
import { useTheme } from 'contexts/theme'
import { throttle } from 'utilities/function'
import { getPreferredName, getFullName, getEmailAddress, getAnonymousId } from 'utilities/person'

export const useVoiceflow = () => {
    const { formatMessage } = useIntl()

    const {
        integrations,
        environment
    } = useEnvironment()

    const { organization: systemPermissions } = useAccess()

    const { locale } = useI18n()
    const { name: theme } = useTheme()

    const { organization } = useOrganization()
    const { me } = useMe()
    const getCompanyUrl = useGetCompanyUrl()

    const [initialized, setInitialized] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [configuration, setConfiguration] = useState(null)

    const description = formatMessage({
        id: 'voiceflow_assistant_description',
        defaultMessage: 'Hi! 👋 I’m Huma’s frontline support. I’m still learning. Need help? Just ask!'
    })

    useEffect(() => {
        if(!initialized && integrations && !!systemPermissions.length && organization && me) {
            setInitialized(true)

            if(integrations.enableVoiceflow) {
                const name = getPreferredName(me) || getFullName(me, { fallback: false })
                const email = getEmailAddress(me)

                setConfiguration(configuration => ({
                    ...configuration,

                    versionID: (environment.stage === 'prod') ?
                        'production' :
                        'development',
                    userID: getAnonymousId(me),

                    assistant: {
                        ...(configuration?.assistant ?? null),
                        description,
                        stylesheet: stylesheets[theme]
                    },

                    user: {
                        name,
                        email,
                        companyId: organization.id,
                        companyName: organization.name,
                        companyUrl: getCompanyUrl(organization.key)
                    },

                    launch: {
                        event: {
                            type: 'launch',
                            payload: {
                                user_name: name,
                                user_email: email,
                                flowLanguage: getSupportedLocale(locale)
                            }
                        }
                    },

                    autostart: false
                }))
            }
        }
    }, [initialized, integrations, systemPermissions.length, me, environment, locale, theme, organization])

    useScript({
        id: 'voiceflow',
        src: 'https://cdn.voiceflow.com/widget/bundle.mjs',
        target: document.body,
        onLoad: ({ first }) => {
            if(!first) {
                return
            }

            setConfiguration(configuration => ({
                ...configuration,
                verify: { projectID: integrations.voiceflowProjectId }
            }))
        },
        dependencies: [integrations.enableVoiceflow, !!systemPermissions.length]
    })

    useScript({
        id: 'voiceflow-kihjelp-extensions',
        src: 'https://swnevin.github.io/voiceflow-huma-assets/extensions.js',
        target: document.body,
        onLoad: ({ first }) => {
            if(!first) {
                return
            }

            setConfiguration(configuration => ({
                ...configuration,
                assistant: {
                    ...(configuration?.assistant ?? null),
                    extensions: global.voiceflowExtensions
                }
            }))
        },
        dependencies: [integrations.enableVoiceflow, !!systemPermissions.length]
    })

    useEffect(() => {
        if(!loaded && !!configuration?.verify?.projectID && !!configuration?.assistant?.extensions?.length && !!configuration?.launch) {
            setLoaded(true)
            global.voiceflow.chat.load(configuration)
        }
    }, [loaded, configuration])

    // React to user preferences changing
//     useEffect(() => {
//         loaded && setConfiguration(configuration => {
//             const flowLanguage = getSupportedLocale(locale)
//             if(configuration?.flowLanguage === flowLanguage) {
//                 return configuration
//             }
//
//             return updateVoiceflowConfiguration({
//                 ...configuration,
//                 assistant: {
//                     ...(configuration?.assistant ?? null),
//                     description
//                 },
//                 flowLanguage
//             })
//         })
//     }, [loaded, locale, description])
//
//     useEffect(() => {
//         loaded && setConfiguration(configuration => {
//             const stylesheet = stylesheets[theme]
//             if(configuration?.assistant?.stylesheet === stylesheet) {
//                 return configuration
//             }
//
//             return updateVoiceflowConfiguration({
//                 ...configuration,
//                 assistant: {
//                     ...(configuration?.assistant ?? null),
//                     stylesheet
//                 }
//             })
//         })
//     }, [loaded, theme])
}

const stylesheets = {
    light: 'https://swnevin.github.io/voiceflow-huma-assets/default.css',
    dark: 'https://swnevin.github.io/voiceflow-huma-assets/humaDark.css'
}

const getSupportedLocale = locale => {
    if(['en', 'nb', 'sv', 'fi'].includes(locale)) {
        return locale
    }

    return 'en'
}

// const updateVoiceflowConfiguration = configuration => {
//     global.setTimeout(() => {
//         global.voiceflow?.chat?.destroy()
//         global.voiceflow?.chat?.load(configuration)
//     }, 500)
//
//     return configuration
// }

export const openChat = () => global.voiceflow?.chat?.open()
export const closeChat = () => global.voiceflow?.chat?.close()

export const showChat = throttle(() => global.voiceflow?.chat?.show())
export const hideChat = throttle(() => global.voiceflow?.chat?.hide())