import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useMe } from 'contexts/me'
import { HeroActions, Heading, Container } from 'pages/dashboard/s'
import {
    Caption,
    ShowAll, ShowAllCount, ShowAllButton,
    Link
} from './s'
import Subordinates from './subordinates'
import Group from './group'
import MoreCount from './more-count'

const DashboardPeople = ({ hideIfEmpty }) => {
    const { locale } = useI18n()

    const { me } = useMe()

    const {
        teams = {},
        locations = {}
    } = me ?? {}

    const [showingAll, setShowingAll] = useState(false)

    if(!me) {
        return null
    }

    if(hideIfEmpty && !teams?.value?.length && !locations?.value?.length) {
        return null
    }

    const groups = [
        ...(teams?.value ?? []),
        ...(locations?.value ?? [])
    ].sort(({ name: one }, { name: two }) => {
        return one.localeCompare(two, locale, { sensitivity: 'base' })
    })

    const groupsCut = showingAll ? groups.length : 3

    return (
        <>
            <HeroActions>
                <Heading>
                    <FormattedMessage
                        id="dashboard_groups_heading"
                        defaultMessage="Your teams and locations" />
                </Heading>
            </HeroActions>
            {!!groups?.length && (
                <Container>
                    <Subordinates />
                    {groups
                        .slice(0, groupsCut)
                        .map(group => (
                            <Group
                                group={group}
                                key={`dashboard:group:${group.id}`} />
                        ))
                    }
                    {(groups.length > 3 && !showingAll) && (
                        <ShowAll>
                            <ShowAllCount>
                                + <MoreCount groups={groups.slice(groupsCut)} />
                            </ShowAllCount>
                            <ShowAllButton
                                onClick={() => setShowingAll(true)}
                                className="constructive">
                                <FormattedMessage
                                    id="action_show_all"
                                    defaultMessage="Show all" />
                            </ShowAllButton>
                        </ShowAll>
                    )}
                </Container>
            )}
            {!groups?.length && (
                <Caption>
                    <FormattedMessage
                        id="dashboard_groups_empty_message"
                        defaultMessage="You’re not a member of any teams or locations yet." />
                </Caption>
            )}
            <Link
                to="path:people.base"
                className="constructive">
                <FormattedMessage
                    id="people_action_navigate"
                    defaultMessage="Go to People" />
            </Link>
        </>
    )
}

export default DashboardPeople
