import React from 'react'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { FormattedMessage } from 'react-intl'
import { Important, ImportantInner, Header, Paragraph, Emoji } from './s'
import { H4 as Heading } from 'components/typography/heading'
// import Progress from 'components/service-onboarding/progress'
import Link from 'components/link'
import { sendAppSignal } from 'hooks/app-signal'

const ServiceOnboarding = ({ onClick }) => {
    const { onboarder } = useServiceOnboarding()
    if(!onboarder) {
        return null
    }

    return (
        <Important>
            <ImportantInner>
                {/* <Progress /> */}
                <Header>
                    <Emoji>⚡️</Emoji>
                    <Heading className="compact">
                        <FormattedMessage
                            id="service_onboarding_progress_heading"
                            defaultMessage="Get started with Huma" />
                    </Heading>
                </Header>
                <Paragraph className="compact">
                    <Link
                        className="constructive"
                        onClick={() => {
                            onClick()
                            sendAppSignal('service-onboarding.show')
                        }}>
                        <FormattedMessage
                            id="service_onboarding_setup_tasks_action_open"
                            defaultMessage="Open setup tasks" />
                    </Link>
                </Paragraph>
            </ImportantInner>
        </Important>
    )
}

export default ServiceOnboarding