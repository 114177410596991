import React from 'react'
import { FormattedMessage } from 'react-intl'
import { showQuickGuide } from '../'
import { getCategoryIcon } from 'utilities/categories'
import { Item, IconWrapper, Title, ModalIcon } from './s'
import { ChevronRight } from 'styled-icons/feather'

const Icon = getCategoryIcon('deviation')

const Deviation = () => (
    <Item onClick={showQuickGuide('deviation')}>
        <IconWrapper>
            <Icon size={24} />
        </IconWrapper>
        <Title>
            <FormattedMessage
                id="noun_deviation"
                defaultMessage="Deviation" />
        </Title>
        <ModalIcon>
            <ChevronRight size={24} />
        </ModalIcon>
    </Item>
)

export default Deviation
