import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import Paragraph from 'components/typography/paragraph'
import  { OrderedList } from 'components/list'

export const Wrapper = styled.div`
    position: relative;

    padding-block-end: 12px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;

        width: 100%;
        height: 1px;
        background-color: var(--huma-color-surface-default);
    }
`

export const Heading = styled(Paragraph)`
    padding-block-start: 24px;
    padding-inline: 24px;

    ${belowTablet`
        padding-inline: 0;
    `}
`

export const GuideList = styled(OrderedList)`
    padding-block-start: 12px;
`