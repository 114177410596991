import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { MenuItemLink } from '../'

const moduleIdentifier = 'accessroles'

const SystemSettingsUserRoles = props => {
    const {
        initialized,
        check
    } = useAccess()

    const access = initialized && check('roles:manage')

    return (
        <MenuItemLink
            {...props}
            to="settings.userroles.base"
            module={moduleIdentifier}
            access={access}
            upgradable={false}
            nested>
            <FormattedMessage
                id="roles_user_heading"
                defaultMessage="User roles" />
        </MenuItemLink>
    )
}

export default SystemSettingsUserRoles