import React from 'react'
import { useIntl } from 'react-intl'
import LocalePicker from 'components/locale-picker'

const Language = () => {
    const { formatMessage } = useIntl()

    return (
        <LocalePicker label={formatMessage({
            id: 'noun_language',
            defaultMessage: 'Language'
        })} />
    )
}

export default Language