import { useRef, useLayoutEffect } from 'react'
import { size, each } from 'utilities/object'

export const useScript = options => {
    const {
        src,
        content,
        id,
        async = true,
        defer = true,
        target = document.head,
        onLoad,
        dependencies = [],
        ...attributes
    } = options

    const loaded = useRef(false)

    const onFirstLoaded = () => {
        loaded.current = true
        onLoad?.({ first: true })
    }

    useLayoutEffect(() => {
        if((!src && !content) || !id || !dependencies.every(Boolean)) {
            return
        }

        if(document.querySelector(`script[data-id="${id}"]`)) {
            loaded.current = true
            onLoad?.({ first: false })

            return
        }

        const script = document.createElement('script')

        script.dataset.id = id
        script.async = async
        script.defer = defer

        if(size(attributes)) {
            each(attributes, (value, key) => script.setAttribute(key, value))
        }

        script.addEventListener('load', onFirstLoaded)

        src && (script.src = src)
        content && (script.innerHTML = content)

        target.appendChild(script)

        content && onFirstLoaded()
    }, [src, content, id, dependencies])

    return [loaded.current]
}
