import React, { useState, useEffect, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import SubordinatesProvider, { useSubordinates } from 'contexts/subordinates'
import { useOrganization } from 'contexts/organization'
import { usePerson } from 'contexts/person'
import { getShortName, getGivenName, getFullName } from 'utilities/person'
import { map, size } from 'utilities/object'
import { usePossum } from 'hooks/possum'
import { PickerMeta, Person, List, ListItem, Paragraph, Message } from './s'
import Loader from 'components/loader'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import PersonField from 'components/form/field/person'
import { Helper } from 'components/form/field/s'
import Error from 'components/message'
import { InlineButton } from 'components/button'
import Divider from 'components/divider'
import StringField from 'components/form/field/string'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import EditHierarchical from 'pages/people/pages/profile/panes/about/affiliation/hierarchical/edit'

const DeactivatePerson = ({ lookingAtMyOwnProfile, onBeforeDeactivate, onAfterDeactivate, modal, salt }) => {
    const { formatMessage } = useIntl()

    const [viewingSubordinates, setViewingSubordinates] = useState(null)
    const [deactivating, setDeactivating] = useState(false)
    const [onBeforeDeactivated, setOnBeforeDeactivated] = useState(false)
    const [pickedSupervisor, setPickedSupervisor] = useState(null)
    const [deactivationErrors, setDeactivationErrors] = useState({})

    const {
        person,
        deactivatePerson
    } = usePerson()

    const { organization } = useOrganization()

    const {
        supervisor,
        subordinatesCount = 0
    } = person ?? {}

    const subordinatesContext = useSubordinates()

    const { transferSubordinates } = subordinatesContext ?? {}

    const givenName = getGivenName(person)
    const shortName = getShortName(person)

    const possessify = usePossum()

    useEffect(() => {
        if(supervisor?.value) {
            setPickedSupervisor(supervisor?.value)
        }
    }, [supervisor?.value])

    const deactivateProfile = async () => {
        setDeactivating(true)

        if(typeof onBeforeDeactivate === 'function' && !onBeforeDeactivated) {
            const { ok: onBeforeDeactivated } = await onBeforeDeactivate()
            setOnBeforeDeactivated(onBeforeDeactivated)
        }

        if(pickedSupervisor && transferSubordinates) {
            const { ok, response } = await transferSubordinates(pickedSupervisor.id)

            if(!ok) {
                if(response?.errorCode === 'field:conflict' && response?.fields?.includes('supervisor')) {
                    setDeactivationErrors({
                        supervisor: formatMessage({
                            id: 'supervisor_circular_error',
                            defaultMessage: 'Picking {person} would cause a circular chain of supervisors, which Huma doesn’t allow. Pick another person or change the hierarchy.'
                        }, { person: getFullName(pickedSupervisor) })
                    })
                }
            }
        }

        const { ok } = await deactivatePerson()

        if(ok) {
            if(typeof onAfterDeactivate === 'function') {
                await onAfterDeactivate()
            }
        }

        setDeactivating(false)
    }

    const consequences = {
        access: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_access',
                defaultMessage: '{person} will be logged out and will not be able to in to {organization} again'
            }, {
                person: shortName,
                organization: organization.name
            })
        },
        adminAccess: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_admin_access',
                defaultMessage: 'Only administrators will be able to view the deactivated profile'
            })
        },
        fields: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_fields',
                defaultMessage: 'All profile fields will be locked and will not be editable'
            })
        },
        assignment: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_assignment',
                defaultMessage: 'New documents, tasks, meetings, equipment and competences will not be addable or assignable to {person}'
            }, { person: shortName })
        },
        absences: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_absences',
                defaultMessage: 'Absence can’t be added or edited for {person}'
            }, { person: shortName }),
            messages: [
                {
                    type: 'warning',
                    message: formatMessage({
                        id: 'person_action_deactivate_consequence_absences_warning',
                        defaultMessage: 'Make sure to export the user’s absence data before deactivating the account'
                    })
                }
            ]
        },
        tasks: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_tasks',
                defaultMessage: 'Uncompleted tasks will still be uncompleted'
            }),
            messages: [
                {
                    type: 'warning',
                    message: formatMessage({
                        id: 'person_action_deactivate_consequence_tasks_warning',
                        defaultMessage: 'Make sure the user’s tasks are completed or reassigned before deactivating the account'
                    })
                }
            ]
        },
        documents: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_documents',
                defaultMessage: 'Documents will still be available, but…'
            }),
            messages: [
                {
                    type: 'warning',
                    message: formatMessage({
                        id: 'person_action_deactivate_consequence_documents_warning',
                        defaultMessage: 'If {pname} documents should still be editable, {person} needs to change the owner before deactivating the account'
                    }, {
                        pname: possessify(shortName),
                        person: shortName
                    })
                },
                {
                    type: 'info',
                    message: formatMessage({
                        id: 'person_action_deactivate_consequence_documents_info',
                        defaultMessage: '{person} change the owner of their documents by editing them'
                    }, { person: shortName })
                }
            ]
        },
        meetings: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_meetings',
                defaultMessage: 'Meetings will still be available, but…'
            }),
            messages: [
                {
                    type: 'warning',
                    message: formatMessage({
                        id: 'person_action_deactivate_consequence_meetings_warning',
                        defaultMessage: 'If {pname} meetings should still be editable, {person} needs to change the host before deactivating the account'
                    }, {
                        pname: possessify(shortName),
                        person: shortName
                    })
                }
            ]
        },
        deviation: {
            text: formatMessage({
                id: 'person_action_deactivate_consequence_deviation',
                defaultMessage: 'Any Deviation cases {name} is handler for, they will be unassigned from'
            }, { name: shortName })
        }
    }

    const promptWord = (givenName ?? shortName ?? formatMessage({
        id: 'noun_user',
        defaultMessage: 'User'
    }))
        .trim()
        .replace(/\s+/g, ' ')
        .toLowerCase()

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'person_action_deactivate_prompt_question',
                    defaultMessage: 'Confirm deactivating {pname} account'
                }, { pname: possessify(shortName) })}
                dismiss={modal.dismiss} />
            <Form
                layout="vertical"
                onSubmit={deactivateProfile}>
                {({ touched, errors, trigger }) => (
                    <>
                        {!!subordinatesCount && (
                            <>
                                <PersonField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'subordinates_label_reassign',
                                        defaultMessage: 'Reassign subordinates to'
                                    })}
                                    name="supervisor"
                                    field={{
                                        ...supervisor,
                                        required: true,
                                        optional: formatMessage({
                                            id: 'subordinates_label_reassign_optional',
                                            defaultMessage: 'By default, subordinates will be reassigned to this user’s supervisor, if any'
                                        })
                                    }}
                                    picker={{
                                        heading: formatMessage({
                                            id: 'supervisor_action_pick',
                                            defaultMessage: 'Pick supervisor'
                                        }),
                                        heroContent: (
                                            <PickerMeta>
                                                <Person who={person} />
                                                <Message
                                                    type="info"
                                                    title={formatMessage({
                                                        id: 'supervisor_circular_explainer_title',
                                                        defaultMessage: 'No circular chains of supervisors'
                                                    })}
                                                    message={formatMessage({
                                                        id: 'supervisor_circular_explainer',
                                                        defaultMessage: 'Huma doesn’t allow circular chains of supervisors. People supervised by {person} – directly or indirectly – are therefore not available here.'
                                                    }, { person: getFullName(person) })}
                                                    name={`${salt}:supervisor:circular`} />
                                            </PickerMeta>
                                        )
                                    }}
                                    entity={{
                                        params: {
                                            notRecursivelySubordinateOf: person?.id
                                        }
                                    }}
                                    onChange={({ supervisor }) => setPickedSupervisor(supervisor)} />
                                    {('supervisor' in deactivationErrors) && (
                                        <Error
                                            type="error"
                                            message={deactivationErrors?.supervisor} />
                                    )}
                                    <Helper>
                                        <FormattedMessage
                                            id="subordinates_label_reassign_helper"
                                            defaultMessage="{count, plural, =0 {} =1 {{person} has <button>{count} subordinate</button>. Who should be their new supervisor?} other {{person} has <button>{count} subordinates</button>. Who should be their new supervisor?}}"
                                            values={{
                                                count: subordinatesCount,
                                                person: shortName,
                                                button: chunks => (
                                                    <InlineButton
                                                        className="constructive"
                                                        onClick={() => setViewingSubordinates({
                                                            what: 'subordinates',
                                                            mode: 'view'
                                                        })}>
                                                        {chunks}
                                                    </InlineButton>
                                                )
                                            }} />
                                    </Helper>
                                    <Divider />
                            </>
                        )}
                        <List>
                            {map(consequences, (consequence, key) => (
                                <ListItem key={key}>
                                    <Paragraph>
                                        {consequence.text}
                                    </Paragraph>
                                    {consequence.messages?.map((message, index) => (
                                        <Message
                                            {...message}
                                            key={index} />
                                    ))}
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                        <Paragraph>
                            <FormattedMessage
                                id="person_action_deactivate_prompt"
                                defaultMessage="Type ”{person}” to verify that you understand the consequences of deactivating their account"
                                values={{ person: promptWord }}
                                tagName="strong" />
                        </Paragraph>
                        <StringField
                            salt={salt}
                            className="compact"
                            label={false}
                            name="validate"
                            field={{
                                match: { value: promptWord },
                                required: true
                            }} />
                        <Actions>
                            <Plain onClick={modal.dismiss}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${deactivating ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors) || deactivating}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_deactivate"
                                    defaultMessage="Deactivate" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
            <Modal
                show={!!viewingSubordinates}
                dismiss={() => setViewingSubordinates(null)}>
                <EditHierarchical
                    {...viewingSubordinates}
                    onDone={() => setViewingSubordinates(null)}
                    lookingAtMyOwnProfile={lookingAtMyOwnProfile}
                    salt={salt} />
            </Modal>
        </>
    )
}

export default props => {
    const { id } = useParams()

    const { person } = usePerson()
    const { subordinatesCount = 0 } = person ?? {}

    const Wrapper = !!subordinatesCount ?
        SubordinatesProvider :
        Fragment

    const wrapperProps = !!subordinatesCount ? {
        userId: id
    } : null

    if(!person) {
        return <Loader />
    }

    return (
        <Wrapper {...wrapperProps}>
            <DeactivatePerson {...props} />
        </Wrapper>
    )
}
