import React, { useLayoutEffect, forwardRef } from 'react'
import { createPortal } from 'react-dom'
import { FormattedMessage } from 'react-intl'
import { motion, AnimatePresence } from 'framer-motion'
import styled, { css, useTheme, ThemeProvider } from 'styled-components'
import {
    svgStroke,
    fromTablet, belowTablet
} from 'utilities/styled'
import { FlexChildShrink } from 'components/flex'
import { Naked } from 'components/button'
import { X } from 'styled-icons/feather'
import { FocusOn } from 'react-focus-on'
import { useClickOutside } from 'hooks/click-outside'
import { useTouch } from 'hooks/viewport'
import { hideChat, showChat } from 'hooks/voiceflow'

const PositionedContainer = styled.div.attrs(() => ({
    role: 'dialog',
    'aria-labelledby': 'Widget title', // TODO
    'aria-describedby': 'Widget description', // TODO

    onChange: e => {
        e.stopPropagation()
    }
}))`
    ${({ theme: { widget: { x, y, edges } } }) => css`
        ${(y.where === 'inside' && y.to === 'down') && `top: ${edges.top}px;`}
        ${(y.where === 'inside' && y.to === 'up') && `top: ${edges.bottom}px;`}
        ${(y.where === 'outside' && y.to === 'down' && `top: ${edges.bottom}px;`)}
        ${(y.where === 'outside' && y.to === 'up' && `top: ${edges.top}px;`)}
        ${(x.where === 'inside' && x.to === 'left') && `left: ${edges.right}px;`}
        ${(x.where === 'inside' && x.to === 'right') && `left: ${edges.left}px;`}
        ${(x.where === 'outside' && x.to === 'left' && `left: ${edges.left}px;`)}
        ${(x.where === 'outside' && x.to === 'right' && `left: ${edges.right}px;`)}
    `}

    ${fromTablet`
        width: 0;
        height: 0;
        position: absolute;
    `}

    ${belowTablet`
        width: 100%;
        max-height: 80svh;
        min-height: 224px;
        padding: 0 24px;
        position: fixed;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        overscroll-behavior: contain;
        background-color: var(--huma-color-surface-elevated);
        border-width: 0;
        border-radius: 16px 16px 0 0;
        box-shadow: 0 -8px 16px 0 var(--huma-color-shadow);

        --huma-color-surface-default: var(--huma-color-surface-elevated);

        ${({ theme }) => css`
            padding-bottom: ${!!theme.widget?.scrollable ? '24px' : '0'};
            overflow-y: ${!!theme.widget?.scrollable ? 'auto' : 'hidden'};
        `}
    `}

    > *:last-of-type {
        ${({ theme: { widget: { x, y, edges, constrain } } }) => {
            let adjustment

            if(y.where === 'inside' && y.to === 'down') {
                adjustment = edges.top
            } else if(y.where === 'inside' && y.to === 'up') {
                adjustment = edges.bottom
            } else if(y.where === 'outside' && y.to === 'down') {
                adjustment = edges.bottom
            } else if(y.where === 'outside' && y.to === 'up') {
                adjustment = edges.top
            }

            const parentPadding = !!constrain ? 24 : 0
            const maxHeight = `calc(100vh - ${adjustment}px - ${parentPadding}px)`

            return css`
                ${belowTablet`
                    max-height: calc(80svh - 24px);
                `}

                ${fromTablet`
                    position: absolute;
                    ${(y.to === 'down') && `top: ${y.adjust ? `${y.adjust}px` : 0};`}
                    ${(y.to === 'up') && `bottom: ${y.adjust ? `${y.adjust}px` : 0};`}
                    ${(x.to === 'right') && `left: ${x.adjust ? `${x.adjust}px` : 0};`}
                    ${(x.to === 'left') && `right: ${x.adjust ? `${x.adjust}px` : 0};`}

                    ${!!constrain && css`
                        max-height: ${maxHeight};
                    `}
                `}
            `
        }}
    }
`

export const MobileHeader = styled(FlexChildShrink)`
    ${fromTablet`
        display: none;
    `}

    ${belowTablet`
        position: sticky;
        z-index: 1;
        top: 0;

        padding-block: 16px;
        background-color: var(--huma-color-surface-elevated);
    `}
`

const CloseButton = styled(Naked)`
    cursor: pointer;
    position: relative;

    height: 40px;
    padding-inline-start: 48px;

    transition: all .1s ease-in-out;

    color: var(--huma-color-foreground-default);

    svg {
        position: absolute;
        top: 8px;
        left: 0;

        ${svgStroke()}
    }
`

const PositionProvider = props => {
    const theme = useTheme()

    const {
        direction: { x, y },
        forwardedRef,
        clickOutside,
        closeButton = true,
        blocking,
        constrain,
        scrollable,
        children = null
    } = props

    const { action } = clickOutside

    const origin = props.origin?.current
    if(!origin) {
        return null
    }

    // DOMRect is not a spreadable object, which is why we jump through these hoops
    const { top, right, bottom, left } = origin.getBoundingClientRect?.() ?? {}
    const edges = { top, right, bottom, left }

    return (
        <ThemeProvider theme={{
            ...theme,
            widget: {
                ...theme.widget,
                x, y, edges,
                blocking,
                constrain,
                scrollable
            }
        }}>
            <PositionedContainer ref={forwardedRef}>
                {(!!action && closeButton) && (
                    <MobileHeader>
                        <CloseButton onClick={action}>
                            <X size={24} />
                            <FormattedMessage
                                id="action_close"
                                defaultMessage="Close" />
                        </CloseButton>
                    </MobileHeader>
                )}
                {children}
            </PositionedContainer>
        </ThemeProvider>
    )
}

export const Widget = forwardRef(({
    clickOutside,
    autoFocus = false,
    returnFocus = true,
    blocking = false,
    constrain = false,
    scrollable = true,
    focusOn = null,
    dismiss = () => {},
    ...props
}, ref) => {
    const touch = useTouch()
    const { inside, action } = clickOutside

    useClickOutside({
        callback: action,
        refs: [...inside, ref]
    })

    useLayoutEffect(() => {
        document.body.classList.add('blocking')
        hideChat()

        let teardown
        if(blocking) {
            document.body.classList.add('focus-on')

            teardown = () => {
                if(document.querySelectorAll([
                    '#modals [data-focus-lock-disabled]',
                    '#widget [data-focus-lock-disabled]',
                    '#prompts [data-focus-lock-disabled]'
                ].join(',')).length === 1) {
                    document.body.classList.remove('focus-on')
                }
            }
        }

        return () => {
            document.body.classList.remove('blocking')
            showChat()
            teardown?.()
        }
    }, [blocking])

    const positionProps = {
        clickOutside,
        blocking,
        constrain,
        scrollable,
        forwardedRef: ref
    }

    if(blocking) {
        return (
            <FocusOn
                autoFocus={touch ? false : autoFocus}
                returnFocus={returnFocus}
                onEscapeKey={dismiss}
                {...focusOn}>
                <PositionProvider
                    {...props}
                    {...positionProps} />
            </FocusOn>
        )
    }

    return (
        <PositionProvider
            {...props}
            {...positionProps} />
    )
})

export const widgetify = widget => createPortal(
    widget,
    document.getElementById('widget')
)

export const veilCss = css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
`

export const Wrapper = AnimatePresence

export const Veil = styled(motion.div).attrs(() => ({
    variants: {
        out: {
            opacity: 0,
            transition: {
                duration: .1
            }
        },
        in: {
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'out',
    exit: 'out'
}))`
    ${veilCss}
`

const containerBoxCss = css`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    box-shadow: var(--huma-shadow-low);

    background-color: var(--huma-color-surface-elevated);

    --huma-color-surface-default: var(--huma-color-surface-elevated);
`

export const containerCss = css`
    color: var(--huma-color-foreground-default);

    &.boxy {
        ${containerBoxCss}
    }

    ${fromTablet`
        ${containerBoxCss}
    `}
`
