import React, { Component, createContext, createRef, useContext, useState, useEffect } from 'react'
import { useEnvironment } from 'contexts/environment'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import CustomProfileFieldsProvider, { useCustomProfileFields } from 'contexts/custom-profile-fields'
import { useConfiguration } from 'contexts/configuration'
import { useOrganization } from 'contexts/organization'
import { usePayment } from 'contexts/payment'
import { useMe } from 'contexts/me'
import { useCategories } from 'pages/documents/utilities'
import { useGetVariableData } from 'components/tiptap/extensions/variable/node'
import {
    useGetFieldDefinitions, useGetValuesInterpolator,
    getContentWithoutInterpolatedProperties, getContentWithoutFalsyValues,
    getContentSources, renameContentSource, detachContentSource, attachContentSource
    } from 'components/tiptap/extensions/variable'
import { saveAs } from 'file-saver'
import { get, patch, remove } from 'api'
import isEqual from 'react-fast-compare'
import debounce from 'lodash.debounce'
import { each, pick, omit, size, reduce, filter, compact as compactObject } from 'utilities/object'
import { camelize, titleize } from 'utilities/string'

const SmartEntityContext = createContext()

// Vocabulary:
// - Source: A named and labeled data source, such as an location.office or a user.supervisor
// - Field: A field that exists on a source, such as an organization's name or a user's email address
// - Value: The value of a source, such as which user the user.supervisor, or which location the location.office, refers to

class SmartEntityProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)
        this.setChanged = debounce(this.setChanged, 250, { leading: false, trailing: true, maxWait: 1000 })

        this.customSourceFlash = createRef()

        const state = {
            entity: emptity,
            remoteEntity: emptity,
            i18n: props.i18n,
            changed: false,
            saving: false,
            deleted: false,
            hasFetched: false,
            instated: false,

            context: props.context,
            mode: props.mode ?? this.getDefaultMode(),
            setMode: this.setMode,

            registerEditorMeta: this.registerEditorMeta,
            getSelectedText: this.getSelectedText,
            insertVariable: this.insertVariable,

            fetch: this.fetch,
            instate: this.instate,
            setContent: this.setContent,
            setChanged: this.setChanged,
            resetChanges: this.resetChanges,
            getPersistableContent: this.getPersistableContent,
            update: this.update,
            updateContent: this.updateContent,
            remove: this.remove,

            recognizedSourceTypes: props.recognizedSourceTypes,
            addSource: this.addSource,
            editSource: this.editSource,
            attachSource: this.attachSource,
            updateSources: this.updateSources,
            removeSource: this.removeSource,
            getCustomSources: this.getCustomSources,
            getBuiltInSources: this.getBuiltInSources,
            getDetachedSources: this.getDetachedSources,
            getSource: this.getSource,

            getField: this.getField,

            values: props.values,
            setValue: this.setValue,
            getValue: this.getValue,
            removeValue: this.removeValue,

            customSourceFlash: this.customSourceFlash,
            clearCustomSourceFlash: this.clearCustomSourceFlash
        }

        state.fields = this.getFieldDefinitions(props, state)
        state.interpolate = props.getValuesInterpolator(state)
        state.getVariableData = props.getVariableData(state)
        this.state = state

        each(props.values, ({ value }, key) => {
            cache[key] = value
        })
    }

    componentDidMount() {
        const {
            fetchOnMount = true,
            fetchTemplateFromLibraryOnMount = false
        } = this.props

        fetchOnMount && this.fetch()
        fetchTemplateFromLibraryOnMount && this.fetchTemplateFromLibrary()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.id !== this.props.id) {
            return void this.replace()
        }
    }

    getDefaultMode = () => (this.props.context === 'document') ?
        'fill' :
        'build'

    setMode = mode => {
        if(!modes.includes(mode)) {
            return
        }

        // Documents can be viewed as templates, but templates can’t be viewed as documents
        if(this.props.context === 'template') {
            return
        }

        this.setState({ mode })
    }

    registerEditorMeta = (meta, index) => {
        if(!this.editors) {
            this.editors = []
        }

        if(index in this.editors) {
            this.editors = this.editors.with(index, meta)
        } else if(this.editors.length === index) {
            this.editors.push(meta)
        }

        this.editors.active = index
    }

    fetch = async () => {
        if(!this.id) {
            return
        }

        const { ok, response } = await get({ path: this.getEndpointPath() })
        ok && this.instate(response, true)
    }

    fetchTemplateFromLibrary = async () => {
        const {
            providedBy,
            fetchFromS3
        } = this.props

        if(!this.id || !providedBy?.id) {
            return
        }

        const { ok, response } = await fetchFromS3(`/templates/documents/${providedBy.id}/${this.id}.json`)
        ok && this.instate(response)
    }

    getPersistableContent = (content = this.state.entity.content) => (content ?? []).map(({ content, ...section }) => {
        content = getContentWithoutInterpolatedProperties('value')({ content }).content
        content = getContentWithoutFalsyValues({ content }).content

        return {
            ...section,
            content
        }
    })

    // name, category, description, legislation, language, shares: Separate form
    update = async (body, persistContentChanges = true) => {
        const contentChanges = this.getChanges()
        if(persistContentChanges && !!contentChanges.body?.content?.length) {
            contentChanges.body.content = this.getPersistableContent(contentChanges.body.content)
        }

        const { ok, response } = await patch({
            path: this.getEndpointPath(),
            body: {
                ...body,
                ...(persistContentChanges ? contentChanges.body : null),
                sourceTypes: null,
                type: 'smart',
                versionHash: this.state.remoteEntity.versionHash
            }
        })

        ok && this.instate(response, true)

        return { ok, response }
    }

    // content, sources: Coming from more dynamic interaction
    updateContent = async () => {
        this.setState({ saving: true })

        // The content changes are retrieved by the update function using its default arguments
        const { ok } = await this.update(null)

        this.setState(() => ({
            saving: false,
            ...(ok ? { changed: false } : null)
        }))
    }

    setContent = (content, options = {}) => {
        const {
            origin = 'context',
            initial = false
        } = options

        this.setState(({ entity }) => ({
            entity: {
                ...entity,
                content
            }
        }), () => {
            if(origin === 'context' && !!this.editors?.length) {
                this.state.entity.content.forEach(({ content }, index) => {
                    const updater = this.editors[index]?.updater

                    if(typeof updater === 'function') {
                        return void updater(content, 'context')
                    }
                })
            }

            !initial && this.setChanged()
        })
    }

    // Only use from detail views – otherwise use the Documents context
    remove = async () => {
        const { ok } = await remove({
            path: this.getEndpointPath(),
            returnsData: false
        })

        if(ok) {
            this.setState({
                entity: emptity,
                remoteEntity: emptity,
                changed: false,
                deleted: true
            })
        }

        return { ok }
    }

    instate = async (entity, reset = false) => {
        let { i18n } = this.props

        if(!entity) {
            return void this.setState(state => {
                const update = {
                    entity: emptity,
                    remoteEntity: emptity,
                    i18n,
                    changed: false,
                    hasFetched: true,
                    instated: true,
                    values: {}
                }

                update.fields = this.getFieldDefinitions(this.props, { ...state, ...update })
                update.interpolate = this.props.getValuesInterpolator({ ...state, ...update })
                update.getVariableData = this.props.getVariableData({ ...state, ...update })
                return update
            }, () => this.setContent(this.state.interpolate(this.state.entity.content).content))
        }

        i18n = {
            ...i18n,
            ...(await i18n.getIntl({ locale: entity.language }))
        }

        const customSources = this.getCustomSources(entity)

        entity.sources = [
            ...customSources,
            ...implicitSources.map(source => {
                const value = this.getValue(source)

                return {
                    ...source,
                    ...(value ? { id: value.id } : null)
                }
            })
        ]

        const implicitValues = {
            [universalValuesKey]: {
                value: cache[universalValuesKey] ?? null,
                source: {
                    type: 'universal',
                    name: 'universal'
                }
            },
            [organizationValueKey]: {
                value: cache[organizationValueKey] ?? null,
                source: {
                    type: 'organization',
                    name: 'organization'
                }
            },
            [userResponsibleValueKey]: {
                value: cache[userResponsibleValueKey] ?? null,
                source: {
                    type: 'user',
                    name: 'responsible'
                }
            }
        }

        let values = {}
        const valuePromises = []

        if(this.props.context === 'document') {
            if(customSources.length) {
                const filteredValues = filter(this.state.values, ({ value }) => customSources.find(source => !!source.id && source.id === value?.id))

                values = {
                    ...implicitValues,
                    ...customSources.reduce((values, source) => {
                        const key = `${source.type}.${source.name}`

                        if(!!source.id && values[key]?.value?.id === source.id) {
                            return values
                        }

                        if(source.id) {
                            valuePromises.push(this.setValueModifier({
                                key,
                                type: source.type,
                                name: source.name,
                                value: { id: source.id }
                            })({ ...this.state, entity }))
                        }

                        return {
                            ...values,
                            [key]: {
                                ...getValueFromSource(source),
                                source: pick(source, 'type', 'name')
                            }
                        }
                    }, filteredValues)
                }
            } else {
                values = implicitValues
            }
        }

        this.setState(state => {
            const update = {
                entity,
                remoteEntity: entity,
                i18n,
                changed: false,
                hasFetched: true,
                values
            }

            update.fields = this.getFieldDefinitions(this.props, { ...state, ...update })
            update.interpolate = this.props.getValuesInterpolator({ ...state, ...update })
            update.getVariableData = this.props.getVariableData({ ...state, ...update })

            if(!valuePromises.length) {
                update.instated = true
            }

            return update
        }, async () => {
            const setContent = () => this.setContent(this.state.interpolate(this.state.entity.content).content, { initial: true })

            if(valuePromises.length) {
                const results = await Promise.all(valuePromises)

                const update = results.reduce((accumulator, [update]) => ({
                    ...accumulator,
                    values: {
                        ...accumulator.values,
                        ...update.values
                    }
                }), this.state)

                update.interpolate = this.props.getValuesInterpolator({ ...this.state, ...update })
                update.getVariableData = this.props.getVariableData({ ...this.state, ...update })
                update.instated = true

                return void this.setState(update, () => {
                    reset && setContent()
                })
            }

            reset && setContent()
        })
    }

    addSourceModifier = source => (state, update = null) => {
        state = { ...state, ...update }

        if(!this.state.recognizedSourceTypes.includes(source.type)) {
            return [update, null]
        }

        if(!source.name && source.label) {
            source.name = camelize(source.label)
        }

        if(state.entity.sources?.find(({ type, name  }) => type === source.type && name === source.name)) {
            return [update, null]
        }

        if(!source.label && source.name) {
            source.label = this.getSource(source, state.remoteEntity)?.label ?? titleize(source.name)
        }

        return [{
            ...update,
            entity: {
                ...state.entity,
                sources: [
                    source,
                    ...this.getCustomSources(state.entity),
                    ...this.getBuiltInSources(state.entity)
                ]
            }
        }, source]
    }

    addSource = (source, after) => {
        let update = null
        let added = null

        this.setState(state => {
            [update, added] = this.addSourceModifier(source)(state, update)
            return update
        }, () => {
            if(update) {
                this.setChanged()

                this.customSourceFlash.current = added
                after?.(added)
            }
        })
    }

    editSourceModifier = (current, { type, name, label }) => (state, update = null) => {
        state = { ...state, ...update }

        if(!this.state.recognizedSourceTypes.includes(type)) {
            return [update, null]
        }

        const index = state.entity.sources.findIndex(({ type, name }) => type === current.type && name === current.name)
        if(!~index) {
            return [update, null]
        }

        const source = {
            ...current,
            type,
            name: name ?? camelize(label),
            label
        }

        return [{
            ...update,
            entity: {
                ...state.entity,
                sources: state.entity.sources.with(index, source)
            }
        }, source]
    }

    editSource = async ({ current, edit }, after) => {
        const currentValue = this.getValue(current)

        let [update, edited] = this.editSourceModifier({
            ...current,
            ...(!!currentValue?.id ? { id: currentValue.id } : null)
        }, edit)(this.state)

        let value = null
        let content = null

        if(edited && current.name !== edited.name) {
            if(this.props.context === 'document') {
                [update, value] = await this.setValueModifier({
                    key: `${edited.type}.${edited.name}`,
                    type: edited.type,
                    name: edited.name,
                    value: currentValue,
                    replaceKey: `${current.type}.${current.name}`
                })(this.state, update)
            }

            content = renameContentSource({
                content: this.state.entity.content,
                from: current,
                to: edited
            }).content

            update.interpolate = this.props.getValuesInterpolator({ ...this.state, ...update })
            update.getVariableData = this.props.getVariableData({ ...this.state, ...update })
        }

        this.setState(update, () => {
            if(update) {
                content && this.setContent(this.state.interpolate(content).content)
                this.setChanged()

                this.customSourceFlash.current = edited

                after?.({
                    source: edited,
                    value
                })
            }
        })
    }

    attachSource = async ({ key, type, name, label, replaceName, value }, after) => {
        let [update, addedSource] = this.addSourceModifier({
            type,
            name: replaceName ?? name,
            label,
            ...(value ? { id: value.id } : null)
        })(this.state)

        let resolvedValue = null
        let content = null

        if(addedSource) {
            if(this.props.context === 'document') {
                [update, resolvedValue] = await this.setValueModifier({
                    key,
                    type,
                    name,
                    value

                    // Will be necessary if we support this attach flow for documents:
                    // ...(replaceName ? { replaceKey: `${type}.${replaceName}` } : null)
                })(this.state, update)
            }

            content = attachContentSource({
                content: this.state.entity.content,
                source: {
                    ...addedSource,
                    name
                },
                replaceName,
                id: resolvedValue?.id ?? null
            }).content

            update.interpolate = this.props.getValuesInterpolator({ ...this.state, ...update })
            update.getVariableData = this.props.getVariableData({ ...this.state, ...update })
        }

        this.setState(update, () => {
            if(update) {
                content && this.setContent(this.state.interpolate(content).content)
                this.setChanged()

                this.customSourceFlash.current = addedSource

                after?.({
                    source: addedSource,
                    value: resolvedValue
                })
            }
        })
    }

    removeSourceModifier = ({ type, name }) => (state, update = null) => {
        state = { ...state, ...update }

        if(!this.state.recognizedSourceTypes.includes(type)) {
            return [update, null]
        }

        const sourceIndex = state.entity.sources.findIndex(source => source.type === type && source.name === name)
        if(!~sourceIndex) {
            return [update, null]
        }

        const removedSource = state.entity.sources[sourceIndex]

        return [{
            ...update,
            entity: {
                ...state.entity,
                sources: state.entity.sources.filter(source => !(source.type === type && source.name === name))
            }
        }, removedSource]
    }

    removeSource = ({ type, name }, after) => {
        let update = null
        let removedSource = null
        let content = null

        this.setState(state => {
            [update, removedSource] = this.removeSourceModifier({ type, name })(state)

            if(removedSource) {
                [update] = this.removeValueModifier({ type, name })(state, update)

                const entity = {
                    ...this.state.entity,
                    ...(update.entity ?? null)
                }

                content = detachContentSource({
                    content: entity.content,
                    source: removedSource
                }).content

                update.interpolate = this.props.getValuesInterpolator({ ...this.state, ...update })
                update.getVariableData = this.props.getVariableData({ ...this.state, ...update })
            }

            return update
        }, () => {
            if(update) {
                content && this.setContent(this.state.interpolate(content).content)
                this.setChanged()

                after?.(removedSource)
            }
        })
    }

    updateSources = updatedSources => void this.setState(({ entity }) => ({
        entity: {
            ...entity,
            sources: [
                ...updatedSources,
                ...this.getBuiltInSources(entity)
            ]
        }
    }), this.setChanged)

    getCustomSources = (entity = this.state.entity) => entity.sources.filter(({ implicit }) => !implicit)
    getBuiltInSources = (entity = this.state.entity) => entity.sources.filter(({ implicit }) => implicit)

    getDetachedSources = (entity = this.state.entity) => getContentSources(entity)
        .filter(({ detached }) => detached)

    getSource = ({ type, name }, entity = this.state.entity) => entity.sources.find(
        source => source.type === type && source.name === name
    ) ?? null

    getFieldDefinitions = (props = this.props, state = this.state) => this.props.getFieldDefinitions({
        i18n: state.i18n,
        types: state.recognizedSourceTypes, // Not really necessary now, but could be useful in the future (tiering)
        ...(!!props.customProfileFields?.length ? { user: pick(props, 'customProfileFields') } : null)
    })

    getField = ({ type, name }) => this.state.fields.find(field => field.type === type && field.name === name) ?? null

    setValueModifier = ({ key, type, name, value, replaceKey }) => async (state, update = null) => {
        if(!value) {
            return this.removeValueModifier({ type, name })(state, update)
        }

        const cacheHit = cache[value?.id]
        let resolved = cacheHit ?? null

        if(!cacheHit && !replaceKey) {
            resolved = await this.fetchValue({ type, value })
            cache[value.id] = resolved
        }

        state = { ...state, ...update }

        const previousValues = replaceKey ?
            omit(state.values, replaceKey) :
            state.values

        return [{
            ...update,
            values: {
                ...previousValues,
                [key]: {
                    ...previousValues[replaceKey ?? key],
                    source: { type, name },
                    value: resolved
                }
            }
        }, resolved]
    }

    updateSourceValueModifier = key => (state, update = null) => {
        state = { ...state, ...update }

        const sourceIndex = state.entity.sources?.findIndex(({ type, name }) => `${type}.${name}` === key)
        if(!~sourceIndex) {
            return [update, null]
        }

        let source = state.entity.sources[sourceIndex]
        const value = state.values[key]?.value?.id ?? null

        source = value ? {
            ...source,
            id: value
        } : omit(source, 'id')

        return [{
            ...update,
            entity: {
                ...state.entity,
                sources: state.entity.sources.with(sourceIndex, source)
            }
        }, source]
    }

    setValue = async ({ key, type, name, value, replaceKey }, after) => {
        let [update, resolved] = await this.setValueModifier({ key, type, name, value, replaceKey })(this.state)

        let content = null

        if(resolved) {
            [update] = this.updateSourceValueModifier(key)(this.state, update)

            content = getContentWithoutInterpolatedProperties('cursor')({
                content: this.state.entity.content,
                source: { type, name }
            }).content

            update.interpolate = this.props.getValuesInterpolator({ ...this.state, ...update })
            update.getVariableData = this.props.getVariableData({ ...this.state, ...update })
        }

        this.setState(update, () => {
            if(update) {
                content && this.setContent(this.state.interpolate(content).content)
                this.setChanged()

                after?.(resolved)
            }
        })

        return { ok: !!update, response: resolved }
    }

    fetchValue = async ({ type, value }) => {
        const path = {
            user: user => `/users/${user.id}`,
            team: team => `/teams/${team.id}`,
            location: location => `/locations/${location.id}`
        }[type]?.(value)

        let { ok, response: full } = await get({ path })

        if(!ok) {
            return null
        }

        return (type === 'user') ?
            transformUserValue(full) :
            full
    }

    getValue = ({ type, name }) => this.state.values[`${type}.${name}`]?.value ?? null

    removeValueModifier = ({ type, name }) => (state, update = null) => {
        state = { ...state, ...update }
        const key = `${type}.${name}`

        const value = state.values[key]
        if(!value) {
            return [update, null]
        }

        return [{
            ...update,
            values: omit(state.values, key)
        }, value]
    }

    removeValue = ({ type, name }, after) => {
        let update = null
        let removed = null
        let content = null

        this.setState(state => {
            [update, removed] = this.removeValueModifier({ type, name })(state, update)

            if(removed) {
                content = getContentWithoutInterpolatedProperties('cursor')({
                    content: this.state.entity.content,
                    source: { type, name }
                }).content

                update.interpolate = this.props.getValuesInterpolator({ ...state, ...update })
                update.getVariableData = this.props.getVariableData({ ...state, ...update })
            }

            return update
        }, () => {
            if(update) {
                content && this.setContent(this.state.interpolate(content).content)
                this.setChanged()
                after?.(removed)
            }
        })
    }

    getSelectedText = () => {
        const editor = this.editors[this.editors.active].editor
        const { from, to } = editor.selection

        return editor.state.doc.textBetween(from, to, ' ')
    }

    insertVariable = attrs => {
        const editor = this.editors[this.editors.active].editor

        const range = {
            from: editor.view.state.selection.from,
            to: editor.view.state.selection.to
        }

        const overrideSpace = editor.view.state.selection.$to.nodeAfter?.text?.startsWith(' ')
        if(overrideSpace) {
            range.to += 1
        }

        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: 'variable',
                    attrs
                },
                {
                    type: 'text',
                    text: ' '
                }
            ])
            .run()

        global.getSelection()?.collapseToEnd()
    }

    normalizeContentForComparison = content => {
        content = this.state.interpolate({ content }).content
        content = getContentWithoutInterpolatedProperties('value')({ content }).content
        return getContentWithoutFalsyValues({ content }).content
    }

    getChanges = () => {
        const local = {
            content: this.state.entity.content,
            sources: this.getCustomSources()
        }

        const remote = {
            content: this.state.remoteEntity.content,
            sources: this.getCustomSources(this.state.remoteEntity)
        }

        const changes = reduce(local, (accumulator, value, key) => {
            let remoteValue = remote[key]

            if(key === 'content') {
                value = this.normalizeContentForComparison(value)
                remoteValue = this.normalizeContentForComparison(remoteValue)
            }

            const different = !isEqual(value, remoteValue)
            if(!different) {
                return accumulator
            }

            // console['log'](`>>>>> ${key} is different`, {
            //     local: value,
            //     remote: remoteValue
            // })

            return {
                ...accumulator,
                [key]: value
            }
        })

        return {
            body: size(changes) ? changes : null,
            changed: !!size(changes)
        }
    }

    setChanged = () => void this.setState(pick(this.getChanges(), 'changed'))

    resetChanges = () => this.instate(this.state.remoteEntity, true)

    getEndpointPath = () => (this.props.context === 'document') ?
        `/documents/${this.id}` :
        `/documents/templates/${this.id}`

    clearCustomSourceFlash = () => void this.setState(({ entity }) => {
        this.customSourceFlash.current = null

        return {
            entity: {
                ...entity,
                sources: [...entity.sources]
            }
        }
    })

    setId = id => this.id = id

    replace = (id = this.props.id) => {
        this.setId(id)

        const state = {
            entity: emptity,
            remoteEntity: emptity,
            i18n: this.props.i18n,
            hasFetched: false,
            instated: false,
            changed: false,

            sources: this.getBuiltInSources(),
            values: {}
        }

        state.fields = this.getFieldDefinitions(this.props, state)
        state.interpolate = this.props.getValuesInterpolator(state)

        this.setState(state, () => {
            this.setContent(this.state.entity.content)
            this.fetch()
        })
    }

    render() {
        const { children = null } = this.props

        return (
            <SmartEntityContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </SmartEntityContext.Provider>
        )
    }
}

const modes = ['build', 'fill']

const implicitSources = [
    {
        type: 'organization',
        name: 'organization',
        label: {
            id: 'noun_organization',
            defaultMessage: 'Organization'
        }
    },
    {
        type: 'user',
        name: 'responsible',
        label: {
            id: 'noun_user_loggedin',
            defaultMessage: 'Logged-in user'
        }
    },
    {
        type: 'universal',
        name: 'universal',
        label: {
            id: 'noun_universal',
            defaultMessage: 'Universal'
        }
    }
].map(s => ({ ...s, implicit: true }))

export const universalValuesKey = 'universal.universal'
export const organizationValueKey = 'organization.organization'
export const userResponsibleValueKey = 'user.responsible'

const getValueFromSource = ({ type, name }) => ({
    value: null,
    source: {
        type,
        name
    }
})

export const useGetUniversalValue = () => {
    const { formatMessage } = useIntl()

    return value => ({
        value: {
            ...value,
            id: 'universal',
            name: formatMessage({
                id: 'noun_universal',
                defaultMessage: 'Universal'
            })
        },
        source: {
            type: 'universal',
            name: 'universal'
        }
    })
}

const getOrganizationValue = ({ organization, account }) => {
    const value = !!organization ? {
        ...pick(organization, 'id', 'name'),
        ...(!!account ? {
            account: pick(account, 'company', 'address', 'organizationNumber', 'vatNumber')
        } : null)
    } : null

    return {
        value,
        source: {
            type: 'organization',
            name: 'organization'
        }
    }
}

const getUserResponsibleValue = ({ me }) => {
    const value = transformUserValue(me ?? null)

    return {
        value,
        source: {
            type: 'user',
            name: 'responsible'
        }
    }
}

const transformUserValue = user => {
    if(!user) {
        return null
    }

    let { custom, ...rest } = user

    if(!size(custom ?? {})) {
        return rest
    }

    custom = reduce(custom, (accumulator, value, key) => ({
        ...accumulator,
        [`custom.${key}`]: value
    }))

    return {
        ...rest,
        ...custom
    }
}

export const useExportSmartEntity = () => {
    const { formatMessage } = useIntl()
    const { categories } = useCategories()
    const { configuration } = useConfiguration()

    return async (entity, type = 'template') => {
        if(!entity?.id) {
            return
        }

        if(!entity.content) {
            const { ok, response } = await get({
                path: (type === 'template') ?
                    `/documents/templates/${entity.id}` :
                    `/documents/${entity.id}`
            })

            if(!ok) {
                return
            }

            entity = response
        }

        entity = omit(entity,
            'type', 'id', 'versionHash', 'owners', 'shares', 'templateId', 'sourceTypes',
            'uploadedAt', 'uploadedBy', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'
        )

        if(!entity.category) {
            entity.category = categories[0].value
        }

        if(!!entity.sources) {
            if(!entity.sources.length) {
                delete entity.sources
            } else {
                entity.sources = entity.sources
                    .filter(({ type, implicit }) => configuration.enums.smartDocumentSourceType.includes(type) && !implicit)
                    .map(({ id, ...source }) => source) // eslint-disable-line no-unused-vars
            }
        }

        entity.content = getContentWithoutInterpolatedProperties('id', 'value', 'cursor')({ content: entity.content }).content
        entity.content = getContentWithoutFalsyValues({ content: entity.content }).content

        const templateBlob = new Blob(
            [JSON.stringify(compactObject(entity), null, 4)],
            { type: 'application/json' }
        )

        const templateTranslation = formatMessage({
            id: 'documents_smart_template_noun',
            defaultMessage: 'Smart template'
        }).toLowerCase()

        saveAs(templateBlob, `${entity.name} (${templateTranslation}).json`)
    }
}

const cache = {}

const emptity = {
    content: [{
        type: 'section',
        content: []
    }],
    sources: implicitSources
}

export const useSmartEntity = () => useContext(SmartEntityContext)

const ProviderContext = ({ awaitValueResolution = true, ...props }) => {
    const i18n = useI18n()
    const { fetchFromS3 } = useEnvironment()
    const { configuration } = useConfiguration()
    const { organization } = useOrganization()
    const { me } = useMe()

    const {
        account,
        getAccount
    } = usePayment()

    const {
        fields: customProfileFields,
        hasFetched: customProfileFieldsFetched
    } = useCustomProfileFields()

    const getFieldDefinitions = useGetFieldDefinitions()
    const getValuesInterpolator = useGetValuesInterpolator()
    const getVariableData = useGetVariableData()

    const getUniversalValue = useGetUniversalValue()

    const [values, setValues] = useState({
        [universalValuesKey]: getUniversalValue({
            currentDate: new Date().toISOString()
        })
    })

    const [resolved, setResolved] = useState(!awaitValueResolution)

    useEffect(() => { getAccount() }, [])

    useEffect(() => {
        setValues(values => ({
            ...values,
            [organizationValueKey]: getOrganizationValue({ organization, account }),
            [userResponsibleValueKey]: getUserResponsibleValue({ me })
        }))

        if(configuration && organization && account && me && customProfileFieldsFetched) {
            setResolved(true)
        }
    }, [configuration, organization, account, me, customProfileFieldsFetched])

    if(!resolved) {
        return null
    }

    return (
        <SmartEntityProvider
            {...props}
            i18n={i18n}
            fetchFromS3={fetchFromS3}
            values={values}
            recognizedSourceTypes={configuration.enums.smartDocumentSourceType}
            customProfileFields={customProfileFields}
            getFieldDefinitions={getFieldDefinitions}
            getValuesInterpolator={getValuesInterpolator}
            getVariableData={getVariableData} />
    )
}

export default props => (
    <CustomProfileFieldsProvider>
        <ProviderContext {...props} />
    </CustomProfileFieldsProvider>
)