import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useHandbookTemplates } from 'contexts/handbook-templates'
import { compact } from 'utilities/array'
import { pick, map } from 'utilities/object'
import { capitalize } from 'utilities/string'
import Filter from 'components/filter'

const legislationCountryCodes = ['NO', 'SE', 'FI']
const languageCodes = ['en', 'nb', 'sv', 'fi']

const HandbookTemplatesFilter = props => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()
    const { locale, territories } = useI18n()

    const {
        filter,
        setTemplatesFilter: setFilter,
        templates: items,
        fetching,
        hasFetched
    } = useHandbookTemplates()

    const legislations = pick(territories, ...legislationCountryCodes)

    const languages = languageCodes.includes(locale) ?
        compact([
            locale,
            ...languageCodes.filter(code => code !== locale)
        ]) :
        languageCodes

    const advancedFields = compact([
        {
            type: 'checkboxes',
            name: 'country',
            label: formatMessage({
                id: 'noun_legislation',
                defaultMessage: 'Legislation'
            }),
            field: {
                options: map(legislations, (value, key) => ({
                    id: key,
                    name: value
                }))
            },
            empty: []
        },
        {
            type: 'checkboxes',
            name: 'language',
            label: formatMessage({
                id: 'noun_language',
                defaultMessage: 'Language'
            }),
            field: {
                options: languages.map(language => ({
                    id: language,
                    name: capitalize(formatDisplayName(language, { type: 'language' }))
                }))
            },
            empty: []
        }
    ])

    return (
        <Filter
            {...props}
            filter={filter}
            setFilter={setFilter}
            advancedFields={advancedFields}
            items={items}
            fetching={fetching}
            hasFetched={hasFetched} />
    )
}

export default HandbookTemplatesFilter