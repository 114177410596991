import styled, { css } from 'styled-components'
import {
    svgStroke,
    bp, belowLaptop, belowDesktop, fromDesktop, print, darkMode
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'
import ImageLogoBase, { TextLogo as TextLogoBase } from 'components/logo'
import { GlobalContainer as GlobalMessage } from 'components/message/s'

export const HeaderWrap = styled.div`
    position: sticky;
    top: 0;
    z-index: 4;

    display: grid;
    grid-template: subgrid / subgrid;
    grid-area: header / full-width;

    box-shadow: var(--huma-shadow-lowest);
    height: var(--huma-global-main-layout-header-height);

    background-color: var(--huma-color-surface-default);

    ${darkMode`
        box-shadow: 0 1px var(--huma-color-border-default);
    `}

    ${belowLaptop`
        ${GlobalMessage} + & {
            top: 48px;
        }
    `}

    ${print`
        display: none;
    `}
`

export const Header = styled.div`
    position: relative;
    isolation: isolate;

    display: grid;
    grid-template: subgrid / subgrid;
    grid-area: header;
    align-items: center;
    justify-content: start;

    ${bp.between('laptop', 'full')`
        grid-column-start: full-width;
    `}

    ${belowLaptop`
        display: flex;

        max-width: 100vw;
    `}
`

export const HeaderStart = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: nav;

    margin-inline-start: 56px;

    ${bp.between('laptop', 'full')`
        grid-column-start: full-width;
    `}

    ${belowLaptop`
        margin-inline-start: -8px;

        &::after {
            display: none;
        }
    `}
`

export const MenuBars = styled.i`
    position: relative;
    top: -4px;
    display: inline-block;
    transform: translate3d(0, 0, 0);

    &,
    &::after,
    &::before {
        margin: 0;
        border-radius: 2px;
        width: 18px;
        height: 2px;

        transition: all .35s cubic-bezier(0.68, -0.55, 0.265, 1.65);

        background-color: var(--huma-color-border-bold);
    }

    &::after,
    &::before {
        content: "";
        position: absolute;
        right: 0;
        display: block;
    }

    &::before {
        transform: translate3d(0, -6px, 0);
    }

    &::after {
        transform: translate3d(0, 6px, 0);
    }
`

export const MenuTrigger = styled(Naked)`
    display: none;

    ${belowLaptop`
        display: block;

        margin-inline-end: 8px;
        width: 40px;
        height: 40px;
    `}

    & ${MenuBars} {
        ${p => !!p.$open && css`
            background-color: transparent;

            &::before {
                transform: translate3d(0, 0, 0) rotate(-45deg);
            }

            &::after {
                transform: translate3d(0, 0, 0) rotate(45deg);
            }
        `}
    }
`

export const ImageLogo = styled(ImageLogoBase)`
    ${bp.below(350)`
        max-width: calc(100vw - 208px);
    `}
`

export const TextLogo = styled(TextLogoBase)`
    line-height: 40px;

    ${belowLaptop`
        line-height: 1.5;
    `}

    ${bp.below(350)`
        max-width: calc(100vw - 208px);
    `}
`

export const HeaderMiddle = styled.div`
    display: flex;
    align-items: center;
    grid-column: search;

    ${bp.between('tablet', 'laptop')`
        margin-inline-end: 24px;
        width: auto;
    `}

    ${belowLaptop`
        width: auto;
        margin-inline-start: auto;
        margin-inline-end: 8px;
    `}
`

export const HeaderEnd = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    grid-column: global-actions;

    margin-inline-start: auto;

    ${belowLaptop`
        margin-inline-start: 0;
    `}

    svg {
        ${svgStroke()}
    }
`

export const Main = styled.div`
    position: relative;

    display: grid;
    grid-template: subgrid / subgrid;
    grid-area: content / full-width;

    ${print`
        padding: 0;
    `}
`

export const Aside = styled.aside`
    display: grid;
    grid-template: subgrid / subgrid;
    grid-area: nav;
    align-self: start;

    transform: translateX(0);

    ${bp.between('laptop', 'full')`
        grid-column-start: full-width;
    `}

    ${belowLaptop`
        position: fixed;
        z-index: 3;
        top: var(--huma-global-main-layout-header-height);
        left: -320px;
        overflow-y: auto;

        display: flex;
        flex-direction: column;

        width: auto;
        height: calc(100% - var(--huma-global-main-layout-header-height));
        box-shadow: ${p => !!p.$isVisible ? '0 16px 16px rgba(0, 0, 0, .1)' : 'none'};

        transition: transform .15s cubic-bezier(.17, .67, .83, .67);
        transform: ${p => !!p.$isVisible ? 'translateX(100%)' : 'translateX(0)'};

        background: var(--huma-color-surface-default);
    `}

    ${print`
        display: none;
    `}
`

export const AsideInside = styled(Grid)`
    position: sticky;
    top: var(--huma-global-main-layout-header-height);

    grid-column: full-width;
    align-self: start;

    ${belowLaptop`
        position: relative;
        top: 0;

        height: 100%;
        padding-block-end: 32px;

        -webkit-overflow-scrolling: touch;
    `}
`

export const Overlay = styled.div`
    opacity: 0;

    ${belowLaptop`
        pointer-events: ${p => !!p.$isVisible ? 'all' : 'none'};
        position: fixed;
        z-index: 3;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transition: opacity .35s;
        opacity: ${p => !!p.$isVisible ? '0.2' : 0};
        background-color: var(--huma-palette-neutral-10);
    `}
`

export const Content = styled.main`
    position: relative;
    isolation: isolate;

    grid-area: content;

    padding-block: var(--huma-global-content-padding-block-start) var(--huma-global-content-padding-block-end);
    padding-inline: var(--huma-global-content-padding-inline);
    background-color: var(--huma-color-surface-default);

    color: var(--huma-color-foreground-default);

    ${fromDesktop`
        margin-block: 24px 96px;
        border-radius: 8px;
        box-shadow: var(--huma-shadow-lowest);

        ${p => !!p.$isBottomPanelOpen && css`
            margin-block-end: 0;
            padding-block-end: 24px;
            border-radius-bottom-right: 0;
            border-radius-bottom-left: 0;
            border-bottom: 72px solid var(--huma-color-surface-default);
        `}
    `}

    ${bp.from(1520)`
        body:not(.side-panel-overlay) & {
            max-width: ${p => !!p.$isSidePanelOpen ? `var(--content-max-width)` : '100%'};

            transition: max-width .15s ease-in-out;
        }
    `}

    ${belowDesktop`
        &,
        & > * {
            grid-column-end: full-width;
        }
    `}

    ${belowLaptop`
        &,
        & > * {
            grid-column: full-width;
        }
    `}

    ${print`
        padding: 0;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    `}
`