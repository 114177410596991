import { differenceInDays } from 'date-fns'
import { isOngoing } from 'pages/absence/utilities'
import { isofy } from 'utilities/date-time'

export const getValidStatisticsRecords = records => records.filter((record, index) => {
    const now = new Date()

    const currentRecord = records.find(({ current }) => !!current)
    const nextRecord = records[index + 1]

    // Safeguard
    if(record.fromDate > record.toDate) {
        return false
    }

    // If the record is upcoming, but the period unit or currency is different from the current record, don't include it
    if(isofy(record.fromDate) > isofy(now) && currentRecord && (currentRecord?.periodUnit !== record.periodUnit || currentRecord?.currency !== record.currency)) {
        return false
    }

    if(isOngoing({
        start: record.fromDate,
        end: record.toDate
    }, now)) {
        return true
    }

    if(!nextRecord) {
        return false
    }

    if(record.periodUnit !== currentRecord?.periodUnit) {
        return false
    }

    if(record.currency !== currentRecord?.currency) {
        return false
    }

    if(differenceInDays(
        isofy(nextRecord.fromDate),
        isofy(record.toDate)
    ) !== 1) {
        return false
    }

    return true
})

export const getFractionDigits = amount => Number.isInteger(amount) ? 0 : 2