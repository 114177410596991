import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { size, pick, omit } from 'utilities/object'
import { unpackStringField, getListRepresentationFromProfile } from 'utilities/person'
import { cls } from 'utilities/dom'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import { Helper, Label } from 'components/form/field/s'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import PersonField from 'components/form/field/person'
import TimeField from 'components/form/field/time'
import TemplateHelper from '../template-helper'
import DynamicAssignments from '../dynamic-assignments'
import ProcessAccess from 'pages/processes/components/process-access'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const AddProcessPredefinedUser = ({
    templates, setTemplates,
    dynamicAssignmentTypes, setDynamicAssignmentTypes,
    proceed, modal, salt
}) => {
    const { formatMessage } = useIntl()

    const {
        person,
        updatePerson
    } = usePerson()

    const [initialSupervisor] = useState(!!person?.supervisor)
    const [updating, setUpdating] = useState(false)

    const update = async body => {
        setUpdating(true)

        const dynamicAssignments = pick(body, ...dynamicAssignmentTypes)
        body = omit(body, ...dynamicAssignmentTypes)

        let ok = false

        const supervisorChanged = !!body.supervisor && body.supervisor !== unpackStringField(person.supervisor)?.id
        if(supervisorChanged) {
            const updateFields = pick(body, 'supervisor')
            const result = await updatePerson(updateFields)
            ok = result.ok
        } else ok = true

        setUpdating(false)

        ok && proceed({
            concernsType: 'user',
            concernsId: person.id,
            title: body.title,
            description: body.description ?? null,
            templateIds: templates
                ?.filter(({ providedByHuma = false, providedBy = null }) => !providedByHuma && !providedBy)
                .map(({ id }) => id) ?? [],
            humaTemplateIds: templates
                ?.filter(({ providedByHuma = false, providedBy = null }) => !!providedByHuma || providedBy)
                .map(({ id }) => id) ?? [],
            referenceDate: body.referenceDate ?? null,
            dynamicAssignments
        })
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'processes_action_add',
                    defaultMessage: 'New process'
                })}
                dismiss={modal.dismiss} />
            <Form
                layout="vertical"
                onSubmit={update}>
                {({ errors, trigger }) => (
                    <>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_title',
                                defaultMessage: 'Title'
                            })}
                            name="title"
                            field={{
                                required: true,
                                include: 'always'
                            }}
                            controlProps={{
                                autoFocus: true,
                                max: 255
                            }} />
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{ include: 'touched' }}
                            controlProps={{ maxLength: 2040 }} />
                        <Helper>
                            <FormattedMessage
                                id="processes_helper_description"
                                defaultMessage="This will be visible to everyone who has access to the process." />
                        </Helper>
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'preposition_for',
                                defaultMessage: 'For'
                            })}
                            name="subject"
                            field={{
                                value: person ? getListRepresentationFromProfile(person) : null,
                                editable: false
                            }} />
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_supervisor',
                                defaultMessage: 'Supervisor'
                            })}
                            name="supervisor"
                            field={{
                                ...(person?.supervisor ?? {}),
                                unsettable: !initialSupervisor
                            }}
                            picker={{ outer: false }}
                            entity={{
                                params: {
                                    notRecursivelySubordinateOf: person?.id
                                }
                            }}
                            key={`${salt}:supervisor:${person?.supervisor?.value?.id ?? 'empty'}`} />
                        <TimeField
                            salt={salt}
                            label={formatMessage({
                                id: 'label_process_date_reference',
                                defaultMessage: 'Reference date'
                            })}
                            name="referenceDate"
                            field={{
                                required: true,
                                include: 'always'
                            }} />
                        <Helper>
                            <FormattedMessage
                                id="processes_helper_reference_date"
                                defaultMessage="The date used as a reference for the process tasks." />
                        </Helper>
                        <TemplateHelper
                            templates={templates}
                            setTemplates={setTemplates}
                            salt={salt} />
                        <DynamicAssignments
                            templates={templates}
                            types={dynamicAssignmentTypes}
                            setTypes={setDynamicAssignmentTypes}
                            salt={salt} />
                        <Label as="p">
                            <FormattedMessage
                                id="noun_access"
                                defaultMessage="Access" />
                        </Label>
                        <ProcessAccess
                            concerns={person}
                            salt={salt} />
                        <Actions>
                            <Plain
                                onClick={modal.dismiss}
                                className="neutral"
                                disabled={updating}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={cls([
                                    'constructive',
                                    updating && 'loading'
                                ])}
                                disabled={!!size(errors) || updating || !person}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_continue"
                                    defaultMessage="Continue" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default AddProcessPredefinedUser