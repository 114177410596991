import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEmergencyContacts } from 'contexts/emergency-contacts'
import { usePerson } from 'contexts/person'
import { size } from 'utilities/object'
import { Fields } from './s'
import Form from 'components/form/controller'
import { H3 as Heading } from 'components/typography/heading'
import StringField from 'components/form/field/string'
import PhoneNumberField from 'components/form/field/phone-number'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'

const EditEmergencyContact = ({ emergencyContact = {}, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        addEmergencyContact,
        updateEmergencyContact
    } = useEmergencyContacts()

    const { fetchPerson } = usePerson()

    const [constructing, setConstructing] = useState(false)

    const {
        id,
        relation,
        name,
        phone
    } = emergencyContact

    const addOrUpdate = async (body, { resetTouched }) => {
        setConstructing(true)

        const addOrUpdateEmergencyContact = id ?
            updateEmergencyContact :
            addEmergencyContact

        const { ok } = await addOrUpdateEmergencyContact(body, id)

        setConstructing(false)

        if(ok) {
            resetTouched()
            fetchPerson()
            onDone && onDone()
        }
    }

    const heading = formatMessage({
        id: id ?
            'emergencycontact_action_update' :
            'emergencycontact_action_add',
        defaultMessage: id ?
            'Update emergency contact' :
            'Add emergency contact'
    })

    const submitButtonText = formatMessage({
        id: id ?
            'action_update' :
            'action_add',
        defaultMessage: id ?
            'Update' :
            'Add'
    })

    return (
        <Form
            layout="vertical"
            resetTouchedOnSubmit={true}
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <Heading>{heading}</Heading>
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_relation',
                                defaultMessage: 'Relation'
                            })}
                            controlProps={{ autoFocus: true }}
                            name="relation"
                            field={{
                                value: relation,
                                required: true,
                                include: 'always'
                            }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="name"
                            field={{
                                value: name,
                                required: true,
                                include: 'always'
                            }} />
                        <PhoneNumberField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_phonenumber',
                                defaultMessage: 'Phone number'
                            })}
                            type="tel"
                            name="phone"
                            field={{
                                value: phone,
                                required: true,
                                include: 'always'
                            }} />
                    </Fields>
                    <Actions>
                        <Plain
                            onClick={onDone}
                            className="neutral"
                            disabled={constructing}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={!touched.length || !!size(errors) || constructing}
                            ref={trigger}>
                            {submitButtonText}
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default EditEmergencyContact
