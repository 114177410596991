import styled from 'styled-components'
import { Ghost as GhostBase } from 'components/button'
import LinkBase from 'components/link'

export const Ghost = styled(GhostBase)`
    align-self: flex-start;
`

export const Link = styled(LinkBase)`
    display: inline-block;
    line-height: 24px;
    margin-top: 16px;
`