import { useAccess } from 'contexts/access'

export const useRolePermissionVisibility = () => {
    const {
        featureConfig,

        checkModule,
        checkFeature
    } = useAccess()

    const modules = Object.values(featureConfig).map(({ key }) => key.replace('module:', ''))
    const features = Object.values(featureConfig).flatMap(({ features = [] }) => features.map(({ key }) => key.replace('feature:', '')))

    return realm => {
        const moduleOrFeature = realmToModuleOrFeature(realm)
        if(!modules.includes(moduleOrFeature) && !features.includes(moduleOrFeature)) {
            return true
        }

        return checkModule(moduleOrFeature) || checkFeature(moduleOrFeature)
    }
}

const realmToModuleOrFeature = realm => ({
    users: 'people',
    articles: 'news'
})[realm] ?? realm