import styled from 'styled-components'
import { Grid } from 'components/grid'
import StatusLabelBase from 'components/status-label'

export const ProfileStatus = styled(Grid).attrs({ as: 'span' })`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 4px;
    justify-content: start;
    align-items: center;
`

export const StatusLabel = styled(StatusLabelBase).attrs({ as: 'span' })`
    align-self: unset;
`