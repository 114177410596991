import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { format, addWeeks } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { clamp } from 'utilities/math'
import { useInterval } from 'hooks/interval'
import {
    Container, GroupHeading,
    ShowAll, ShowAllCount, ShowAllButton,
    SkeletonListRow
} from './s'
import Loader from 'components/loader'
import { getOngoingEntries } from 'pages/absence/components/ongoing-entry'
import { getUpcomingEntries } from 'pages/absence/components/grouped-entries'
import Entries from './entries'
import { SkeletonCell, SkeletonCircle, SkeletonStrings } from 'components/skeleton'

const AbsenceContent = ({ salt, ...props }) => {
    const { dateLocale: locale } = useI18n()

    const {
        entries,
        total,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched
    } = useAbsenceEntries()

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 60 * 60 * 1000)

    const { hasNextPage } = paging

    const todaysEntries = getOngoingEntries(entries, now)
    const upcomingEntries = getUpcomingEntries(entries, now)

    const [skeletonLength, setSkeletonLength] = useState(5)

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(entries.length, 1, 10))
        }
    }, [hasFetched, entries?.length])

    if(hasFetched && !entries?.length) {
        return null
    }

    return (
        <Container>
            {(!!todaysEntries?.length || !!upcomingEntries?.length) && (
                <div>
                    {!!todaysEntries?.length && (
                        <>
                            <GroupHeading>
                                <FormattedMessage
                                    id="today"
                                    defaultMessage="Today" />
                            </GroupHeading>
                            <Entries
                                entries={todaysEntries}
                                salt={`${salt}:today`}
                                {...props} />
                        </>
                    )}
                    {!!upcomingEntries?.length && (
                        <>
                            <GroupHeading>
                                <FormattedMessage
                                    id="upcoming_days_count"
                                    defaultMessage="{count, plural, =0 {} =1 {} other {Upcoming {count} days}}"
                                    values={{ count: 7 }} />
                            </GroupHeading>
                            <Entries
                                entries={upcomingEntries}
                                salt={`${salt}:upcoming`}
                                {...props} />
                        </>
                    )}
                </div>
            )}
            {!!hasNextPage && (
                <ShowAll>
                    {(!loading && !autoFetch) && (
                        <>
                            <ShowAllCount>
                                + <FormattedMessage
                                    id="dashboard_absence_count_people_remaining"
                                    defaultMessage="{count, plural, =0 {} =1 {One person away between today and {endDate}} other {{count} people away between today and {endDate}}}"
                                    values={{
                                        count: total - entries.length,
                                        endDate: format(addWeeks(now, 1), 'PP', { locale })
                                    }} />
                            </ShowAllCount>
                            <ShowAllButton
                                onClick={fetch}
                                className="constructive"
                                disabled={fetching}>
                                <FormattedMessage
                                    id="action_show_all"
                                    defaultMessage="Show all" />
                            </ShowAllButton>
                        </>
                    )}
                    {!!loading && <Loader />}
                </ShowAll>
            )}
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={4}
                    key={`dashboard:absence:entries:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonCircle size={20} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings
                            className="flip"
                            size={20} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
        </Container>
    )
}

export default AbsenceContent
