import styled from 'styled-components'
import ImportantBase from 'components/important'
import { Plain } from 'components/button'
import NoteBase from 'components/note'
import MessageBase from 'components/message'

export const Important = styled(ImportantBase)`
    margin-bottom: 24px;
`

export const Status = styled.div`
    margin-bottom: 24px;
    border-top: 1px solid var(--huma-color-border-default);
    padding-top: 16px;

    & > :last-child {
        margin-bottom: 0;
    }
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;
`

export const HeaderEnd = styled.div`
    display: flex;
    column-gap: 16px;
`

export const ChangeButton = styled(Plain).attrs({ className: 'constructive' })`
    height: 24px;
`

export const Note = styled(NoteBase)``

export const Message = styled(MessageBase)`
    ${Note} + & {
        margin-top: 16px;
    }
`