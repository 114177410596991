import React from 'react'
import { isofy } from 'utilities/date-time'

const ChartDot = ({ cx, cy, stroke, value, payload }) => {
    const now = new Date()

    const {
        fromDate,
        toDate
    } = payload

    const start = isofy(fromDate)
    const end = isofy(toDate)

    const dotProps = {
        cx,
        cy,
        r: 3,
        stroke,
        strokeWidth: 2
    }

    if(start > end || !value) {
        return null
    }

    if(isofy(start) > now) {
        return (
            <circle
                {...dotProps}
                fill="var(--huma-color-surface-default)" />
        )
    }

    return (
        <circle
            {...dotProps}
            fill={stroke} />
    )
}

export default ChartDot