import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { AnimatePresence } from 'framer-motion'
import { normalize } from 'utilities/string'
import { cls } from 'utilities/dom'
import { getCategoryIcon } from 'utilities/categories'
import {
    ListItem, Item,
    Button, Link, Anchor,
    Label, KeyHintInner, Highlighter
} from './s'
import {
    CornerDownLeft as Enter,
    ExternalLink,
    ArrowRight as Navigate
} from 'styled-icons/feather'
import { itemHeight } from './'

const Command = ({ item, search = '', searchWords = [], isActive, isRecent = false, confirm, index }) => {
    const { formatMessage } = useIntl()

    const itemRef = useRef()

    useEffect(() => {
        if(!!isActive && itemRef.current) {
            const parent = itemRef.current.parentElement
            const { clientHeight: parentHeight } = parent
            const { offsetTop: itemOffset } = itemRef.current
            const buffer = itemHeight * 2

            if(itemOffset + itemHeight + buffer > parentHeight) {
                parent.scrollTo({
                    top: itemOffset - buffer,
                    behavior: 'smooth'
                })
            } else if(index === 0) {
                parent.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
        }
    }, [isActive, index])

    let {
        id,
        category,
        label,
        subLabel = {},
        className = '',
        element = 'button',
        external = false,
        onClick,
        link,
        icon
    } = item ?? {}

    const isNavigate = id?.startsWith('navigate:')
    const isEntityCommand = id?.startsWith('profile:') || id?.startsWith('team:') || id?.startsWith('location:')
    const isIntegration = id?.startsWith('navigate:integrations:')

    const Icon = icon ?? getCategoryIcon(category)
    const iconProps = {
        ...(isIntegration ? { className: 'integration' } : null)
    }

    const Element = {
        a: Anchor,
        link: Link,
        button: Button
    }[element]

    const [Trigger, triggerProps] = (onClick || link) ?
        [Element, {
            onClick: () => confirm(item),
            ...(link?.href ? link : null)
        }]
        : [Item, null]

    const triggerClassName = cls([
        'neutral',
        isActive && 'active',
        (isEntityCommand && !isRecent) && 'entity-command',
        !!Icon && 'has-icon',
        className
    ])

    if(label?.id) {
        label = formatMessage(label, {
            ...(!!label?.values ? label.values : null),
            name: search
        })
    }

    if(isIntegration) {
        subLabel.text = formatMessage({
            id: 'noun_integration',
            defaultMessage: 'Integration'
        })
    }

    const KeyHint = external ?
        ExternalLink
        : (isNavigate || isEntityCommand) ?
            Navigate
            : Enter

    return (
        <ListItem
            className="command"
            style={{ '--item-height': `${itemHeight}px` }}
            ref={itemRef}>
            <Trigger
                {...triggerProps}
                className={triggerClassName}>
                {!!Icon && (
                    <Icon
                        {...iconProps}
                        size={20} />
                )}
                <Label>
                    <span className="label">
                        {(typeof label === 'string') && (
                            <Highlighter
                                searchWords={[search, ...searchWords]}
                                textToHighlight={label}
                                sanitize={string => normalize(string, { emoji: false })}
                                autoEscape />
                        )}
                        {typeof label !== 'string' && label}
                    </span>
                    {!!subLabel?.text && (
                        <span className="sub-label">
                            <Highlighter
                                searchWords={[search]}
                                textToHighlight={subLabel.text}
                                sanitize={normalize}
                                autoEscape />
                        </span>
                    )}
                </Label>
                {!!isActive && (
                    <AnimatePresence>
                        <KeyHintInner animate={isActive ? 'active' : 'inactive'}>
                            <KeyHint size={16} />
                        </KeyHintInner>
                    </AnimatePresence>
                )}
            </Trigger>
        </ListItem>
    )
}

export default Command