import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Label } from 'components/form/field/s'
import Target from './target'

const ViewCompetenceProfileTargets = ({ targets = [], ...props }) => (
    <div>
        <Label>
            <FormattedMessage
                id="competence_noun_targets"
                defaultMessage="Competence targets" />
        </Label>
        {targets.map(target => (
            <Target
                {...props}
                {...target}
                key={target.type?.id} />
        ))}
    </div>
)

export default ViewCompetenceProfileTargets
