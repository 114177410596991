import React, { useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppSignal } from 'hooks/app-signal'
import { Widget, Heading, WidgetContentScroller, CloseButton } from './s'
import { Simple } from 'components/button'
import { HelpCircle } from 'styled-icons/feather'
import ServiceOnboarding from './service-onboarding'
import FeedbackQuestions from './feedback-questions'
import QuickGuides from './quick-guides'

const Help = () => {
    const [show, setShow] = useState(false)

    const widget = useRef()
    const control = useRef()

    useAppSignal({
        namespace: 'help.show',
        action: () => setShow(true)
    })

    const salt = 'help'
    const close = () => setShow(false)

    return (
        <>
            <Simple
                onClick={() => setShow(!show)}
                icon={HelpCircle}
                ref={control} />
            <Widget
                show={show}
                salt={`${salt}:widget`}
                ref={widget}
                clickOutside={{
                    inside: [control],
                    action: close
                }}
                position={{
                    origin: control,
                    direction: {
                        x: {
                            where: 'inside',
                            to: 'left',
                            adjust: -16
                        },
                        y: {
                            where: 'outside',
                            to: 'down',
                            adjust: 8
                        }
                    }
                }}>
                <>
                    <Heading>
                        <span>
                            <FormattedMessage
                                id="help_center_title"
                                defaultMessage="Help center" />
                        </span>
                        <CloseButton onClick={close} />
                    </Heading>
                    <WidgetContentScroller>
                        <ServiceOnboarding onClick={close} />
                        <FeedbackQuestions
                            onClick={close}
                            salt={salt} />
                        <QuickGuides onClick={close} />
                    </WidgetContentScroller>
                </>
            </Widget>
        </>
    )
}

export default Help