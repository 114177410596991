import { useState, useLayoutEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { omit } from 'utilities/object'
import { session } from 'utilities/storage'

const useLocationIntent = ({ intent, action, requirements = [], params }) => {
    // eslint-disable-next-line no-unused-vars
    const [_, setUrlSearchParams] = useSearchParams()
    const [triggered, setTriggered] = useState(false)

    // Add explicit true to params to allow for multiple triggers
    const { explicit = false } = params ?? {}

    useLayoutEffect(() => {
        if(
            !!intent &&
            typeof action === 'function' &&
            (params?.intent === intent) &&
            requirements.every(Boolean) &&
            !triggered
        ) {
            action(omit(params, 'intent', 'explicit'))
            setUrlSearchParams({}, { replace: true })
            !explicit && setTriggered(true)
        }
    }, [intent, action, JSON.stringify(params), triggered, ...requirements])
}

export const useLocationStateIntent = props => {
    const { state } = useLocation()

    return useLocationIntent({
        ...props,
        params: state
    })
}

export const useLocationQueryIntent = props => {
    const [urlSearchParams] = useSearchParams()

    return useLocationIntent({
        ...props,
        params: Object.fromEntries(urlSearchParams)
    })
}

export const usePseudoLocationHashIntent = props => {
    const hash = session.get('location-hash') ?? '#'

    let [intent, ...id] = hash.replace('#', '').split('-')
    id = id.join('-')

    return useLocationIntent({
        ...props,
        requirements: [
            ...(props.requirements ?? []),
            !!intent,
            !!id
        ],
        params: {
            intent,
            id
        }
    })
}