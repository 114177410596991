import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCategoryFormatter } from 'pages/competence/components/category'
import Definitions from 'pages/competence/components/level/definitions'
import { categoryToMetaMap, categoryLevelMap } from 'pages/competence/constants/category'
import {
    Wrapper,
    Symbol,
    Name, Category, Rating
} from './s'
import Tooltip, { Trigger } from 'components/tooltip'
import { Scrollable as Modal } from 'modals/generic'
import { symbol as fallbackSymbol } from 'pages/competence'

const Target = ({ type, level, salt }) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    const [showingDefinitions, setShowingDefinitions] = useState(false)

    if(!type?.id) {
        return (
            <Wrapper>
                <Symbol symbol={fallbackSymbol} />
                <div>
                    <Name className="deleted">
                        <FormattedMessage
                            id="competence_status_deleted"
                            defaultMessage="Deleted" />
                    </Name>
                    <Category className="compact interpoint-divider deleted">
                        <span>
                            <FormattedMessage
                                id="noun_category"
                                defaultMessage="Category" />
                        </span>
                    </Category>
                </div>
            </Wrapper>
        )
    }

    return (
        <>
            <Wrapper>
                <Symbol symbol={type.symbol} />
                <div>
                    <Name>{categoryFormatter(type)}</Name>
                    <Category className="compact interpoint-divider">
                        <span>
                            <FormattedMessage {...categoryToMetaMap[type.category].title} />
                        </span>
                        {['language', 'skill'].includes(type.category) && (
                            <>
                                {!!level && (
                                    <span>
                                        <FormattedMessage {...categoryLevelMap[type.category][level].title} />
                                        <Tooltip content={formatMessage({
                                            id: 'noun_level_definitions',
                                            defaultMessage: 'Level definitions'
                                        })}>
                                            <Trigger onClick={() => setShowingDefinitions(true)} />
                                        </Tooltip>
                                    </span>
                                )}
                                {!level && (
                                    <span>
                                        <FormattedMessage
                                            id="competence_action_add_level"
                                            defaultMessage="Add level" />
                                        <Tooltip content={formatMessage({
                                            id: 'noun_level_definitions',
                                            defaultMessage: 'Level definitions'
                                        })}>
                                            <Trigger onClick={() => setShowingDefinitions(true)} />
                                        </Tooltip>
                                    </span>
                                )}
                            </>
                        )}
                    </Category>
                </div>
                {level && <Rating value={level} />}
            </Wrapper>
            <Modal
                className="medium"
                show={!!showingDefinitions}
                dismiss={() => setShowingDefinitions(false)}
                salt={`${salt}:definitions`}>
                <Definitions
                    type={type.category}
                    {...(level ? { focus: level } : null)}
                    dismiss={() => setShowingDefinitions(false)}
                    salt={`${salt}:definitions`} />
            </Modal>
        </>
    )
}

export default Target
