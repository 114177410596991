import { useState, useLayoutEffect } from 'react'
import { useMe } from 'contexts/me'
import { compact } from 'utilities/array'

export const useSharedIn = (by = []) => {
    const [organizationId, setOrganizationId] = useState([])
    const [teamIds, setTeamIds] = useState([])
    const [locationIds, setLocationIds] = useState([])
    const [ready, setReady] = useState(false)

    const { me } = useMe()

    useLayoutEffect(() => {
        if(me) {
            const organization = by.includes('organization')
            setOrganizationId(organization ? ['organization'] : [])

            const team = by.includes('team')
            setTeamIds(team ? me.teams?.value?.map(({ id }) => id) ?? [] : [])

            const location = by.includes('location')
            setLocationIds(location ? me.locations?.value?.map(({ id }) => id) ?? [] : [])

            setReady(true)
        }
    }, [by.join('+'), !!me])

    return {
        sharedIn: compact([...organizationId, ...teamIds, ...locationIds]),
        ready: ready
    }
}