import React from 'react'
import Page from 'contexts/page'
import { useAccess } from 'contexts/access'
import { getTitleTranslationByType } from 'pages/processes/utilities'
import Routing from './routing'

const ProcessPage = ({ type, upgradable }) => {
    const { check } = useAccess()

    const access = {
        usersManage: check('users:manage'), // Includes access to manage processes
        unitProcessesManage:
            (['onboarding', 'offboarding'].includes(type) && check('unit:employment-lifecycles:manage')) ||
            (type === 'process' && check('unit:processes:manage')),
        processesTemplatesManage:
            (['onboarding', 'offboarding'].includes(type) && check('employment-lifecycles:templates:manage')) ||
            (type === 'process' && check('processes:templates:manage')),
        unitUsersManage: check('unit:users:manage')
    }

    if(
        !access.usersManage &&
        !access.unitProcessesManage &&
        !access.processesTemplatesManage &&
        !upgradable
    ) {
        return null
    }

    return (
        <Page
            view={type}
            title={getTitleTranslationByType(type)}
            upgrade={{
                enabled: upgradable,
                passthrough: true
            }}>
            <Routing
                type={type}
                access={access}
                upgradable={upgradable} />
        </Page>
    )
}

export const fallbackSymbol = {
    onboarding: {
        colorToken: 'symbol-neutral-1',
        emoji: 'wave'
    },
    offboarding: {
        colorToken: 'symbol-neutral-1',
        emoji: 'bouquet'
    },
    process: {
        colorToken: 'symbol-neutral-1',
        emoji: 'repeat'
    }
}

export default ProcessPage