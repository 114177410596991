import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useAbsenceStats } from 'contexts/absence-stats'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { useInterval } from 'hooks/interval'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { isAfter, endOfDay } from 'date-fns'
import { getDate } from 'utilities/date-time'
import { isOngoing } from 'pages/absence/utilities'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { Important } from './s'
import Entry from './entry'

const OngoingEntry = ({ user, setActing, setAddingToCalendar, showWorkdaysAffected = false, salt }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const absenceAdmin = check('absence:manage')

    const {
        stats,
        fetchStats
    } = useAbsenceStats()

    const {
        entries,
        endEntry,
        removeEntry
    } = useAbsenceEntries()

    const typeNameFormatter = useTypeNameFormatter()

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 60 * 60 * 1000)

    const navigate = useNavigate()

    const ongoing = getOngoingEntries(entries, now)

    const viewAction = useCallback(entry => {
        if(user?.status?.active === false) {
            return null
        }

        const {
            type,
            count
        } = stats?.find(stats => stats.type.id === entry.type.id) ?? {}

        return {
            salt: `${salt}:view`,
            label: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            onClick: () => setActing({
                entry,
                type,
                user,
                count,
                mode: 'view',
                provide: ['approve', 'edit'].includes(entry.permissionLevel)
            }),
            effect: 'neutral'
        }
    }, [salt])

    const viewAbsenceCalendarAction = useCallback(({ start, end, id }) => {
        if(!end || user?.status?.active === false) {
            return null
        }

        return ({
            salt: `${salt}:absence-calendar`,
            label: formatMessage({
                id: 'absence_action_view_in_calendar',
                defaultMessage: 'View in absence calendar'
            }),
            effect: 'neutral',
            onClick: () => navigate(paths.absence.calendar, {
                state: {
                    start,
                    entryFocus: id
                },
                replace: true
            })
        })
    }, [salt])

    const addToCalendarAction = useCallback(({ start, end, type }) => {
        if(!end || user?.status?.active === false) {
            return null
        }

        return {
            salt: `${salt}:calendar`,
            label: formatMessage({
                id: 'absence_action_add_to_external_calendar',
                defaultMessage: 'Add to external calendar'
            }),
            effect: 'neutral',
            onClick: () => setAddingToCalendar({
                start,
                end,
                title: typeNameFormatter(type)
            })
        }
    }, [salt])

    const editAction = useCallback(entry => {
        const {
            user,
            permissionLevel,
            status,
            // end
        } = entry

        const editor = permissionLevel === 'edit'
        const approver = permissionLevel === 'approve'
        // const pending = status === 'pending'

        if((!editor && !approver) || user?.status?.active === false || status === 'canceled') {
            return null
        }

        const action = {
            salt: `${salt}:edit`,
            effect: 'neutral'
        }

        // if(!pending && (!!end && isAfter(now, end)) && !approver) {
        //     return {
        //         ...action,
        //         label: formatMessage({
        //             id: 'action_edit_locked',
        //             defaultMessage: 'Locked for editing'
        //         }),
        //         helptext: formatMessage({
        //             id: 'action_edit_locked_caption',
        //             defaultMessage: 'Talk to an administrator to make changes'
        //         }),
        //         disabled: true
        //     }
        // }

        const {
            type,
            count
        } = stats?.find(stats => stats.type.id === entry.type.id) ?? {}

        return {
            ...action,
            label: formatMessage({
                id: 'action_edit_details',
                defaultMessage: 'Edit details'
            }),
            onClick: () => setActing({
                entry,
                type,
                user,
                count,
                callback: fetchStats,
                mode: 'edit'
            })
        }
    }, [salt])

    const endNowAction = useCallback(entry => {
        const {
            start,
            end,
            permissionLevel
        } = entry

        if(!isAfter(now, start) || !!end || user?.status?.active === false) {
            return null
        }

        if(!['edit', 'approve'].includes(permissionLevel)) {
            return null
        }

        return {
            salt: `${salt}:end-now`,
            label: formatMessage({
                id: 'action_end_now',
                defaultMessage: 'End now'
            }),
            onClick: async () => {
                const result = await endEntry(entry)

                if(result.response?.errorCode?.includes('overlapping') && absenceAdmin) {
                    setActing({
                        entry: {
                            ...entry,
                            end: getDate()
                        },
                        overrideTouched: true,
                        mode: 'edit'
                    })
                } else {
                    await fetchStats()
                }

                return result
            },
            effect: 'neutral'
        }
    }, [salt])

    const removeAction = useCallback(({ permissionLevel, status, start, id }) => {
        const editor = permissionLevel === 'edit'
        const approver = permissionLevel === 'approve'
        const rejected = status === 'rejected'

        if((!editor && !approver) || user?.status?.active === false) {
            return null
        }

        const historical = isAfter(now, endOfDay(start))
        const disabled = historical && !approver && !rejected

        return {
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            prompt: {
                question: formatMessage({
                    id: 'absence_delete_prompt_question',
                    defaultMessage: 'Confirm removing this absence'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            },
            effect: 'destructive',
            onClick: async () => {
                const result = await removeEntry(id)
                setActing(null)
                fetchStats()

                return result
            },
            disabled
        }
    }, [salt])

    const actions = {
        view: viewAction,
        addToCalendar: addToCalendarAction,
        viewAbsenceCalendar: viewAbsenceCalendarAction,
        edit: editAction,
        endNow: endNowAction,
        remove: removeAction
    }

    if(!ongoing?.length) {
        return null
    }

    return (
        <Important>
            {ongoing.map(entry => (
                <Entry
                    entry={entry}
                    actions={actions}
                    showWorkdaysAffected={showWorkdaysAffected}
                    salt={salt}
                    key={`${salt}:ongoing:${entry.id}`} />
            ))}
        </Important>
    )
}

export const getOngoingEntries = (entries, now) => entries
    .filter(({ status }) => status === 'approved' || !status)
    .filter(entry => isOngoing(entry, now))
    .sort(({ start: one }, { start: two }) => one.getTime() - two.getTime())

export default OngoingEntry