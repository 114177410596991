import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { format, startOfYear, addYears } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { usePayment } from 'contexts/payment'
import { useAccess } from 'contexts/access'
import { useWithinQuota } from 'hooks/within-quota'
import { getTypeModule } from 'pages/processes/utilities'
import { LimitStatusWrapper, TextColumns, Paragraph } from './s'
import { ParagraphSmall } from 'components/typography/paragraph'
import StepsIndicator from 'components/steps-indicator'
import { Plain } from 'components/button'
import UpgradeModal from 'modals/feature-upgrade'
import { clamp } from 'utilities/math'
import { size } from 'utilities/object'
import { last } from 'utilities/array'

const salt = 'processes:limit-status'

const LimitStatus = ({ type }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { account } = usePayment()

    const {
        currentLadder,
        currentBundle,

        check
    } = useAccess()

    const billingAccess = check('organization:manage')

    const [, quota = {}] = useWithinQuota({
        key: getTypeModule(type),
        dependencies: [account?.subscription, type]
    })

    const [acting, setActing] = useState(false)

    if(!size(quota)) {
        return null
    }

    const {
        value: spent,
        limit: total
    } = quota

    const percentageThreshold = total < 5 ? 40 : 80
    const percentageSpent = 100 - (((total - spent) / total) * 100)
    if(percentageSpent < percentageThreshold || !currentLadder?.length || last(currentLadder) === currentBundle) {
        return null
    }

    const countTextTranslation = getCountTextTranslation(type)
    const spentTextTranslation = getSpentTextTranslation(type)

    const upgradeText = billingAccess ? {
        id: 'employee_onboarding_text_get_more_with_access',
        defaultMessage: 'This will renew on {date}. Upgrade now to get more.'
    } : {
        id: 'employee_onboarding_text_get_more_no_access',
        defaultMessage: 'Talk to an administrator to upgrade.'
    }

    const startOfNextYear = startOfYear(addYears(new Date(), 1))

    return (
        <>
            <LimitStatusWrapper $align="end">
                <TextColumns className="compact">
                    <span>
                        <FormattedMessage
                            {...spentTextTranslation}
                            values={{
                                used: spent,
                                total
                            }} />
                    </span>
                    <Plain
                        className="constructive small reversed"
                        onClick={() => setActing(true)}
                        upgradable>
                        <FormattedMessage
                            id="action_upgrade"
                            defaultMessage="Upgrade" />
                    </Plain>
                </TextColumns>
                <StepsIndicator
                    steps={total}
                    at={spent} />
            </LimitStatusWrapper>
            <UpgradeModal
                show={!!acting}
                dismiss={() => setActing(false)}
                module={type}
                heading={formatMessage(getUpgradeHeadingTranslation(type))}
                bundleDescriptions={currentLadder.reduce((accumulator, { bundle }) => ({
                    ...accumulator,
                    [bundle]: formatMessage(getUpgradeBundleDescriptionTranslation(type, bundle))
                }), {})}
                salt={salt}>
                <LimitStatusWrapper>
                    <ParagraphSmall className="compact">
                        <FormattedMessage
                            {...spentTextTranslation}
                            values={{
                                used: spent,
                                total
                            }} />
                    </ParagraphSmall>
                    <StepsIndicator
                        steps={total}
                        at={spent} />
                    <Paragraph className="compact">
                        <FormattedMessage
                            {...countTextTranslation}
                            values={{ count: clamp(total - spent, 0, Infinity) }} />
                        <FormattedMessage
                            id="sentence_separator"
                            defaultMessage=" " />
                        <FormattedMessage
                            {...upgradeText}
                            values={{ date: format(startOfNextYear, 'PPP', { locale }) }} />
                    </Paragraph>
                </LimitStatusWrapper>
            </UpgradeModal>
        </>
    )
}

const getCountTextTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_text_get_more_remainder',
        defaultMessage: `{count, plural, =0 {You have reached your maximum number of onboarding processes this year.} =1 {You have 1 onboarding process left this year.} other {You have {count} onboarding processes left this year.}}`
    },
    offboarding: {
        id: 'employee_offboarding_text_get_more_remainder',
        defaultMessage: `{count, plural, =0 {You have reached your maximum number of offboarding processes this year.} =1 {You have 1 offboarding process left this year.} other {You have {count} offboarding processes left this year.}}`
    },
    process: {
        id: 'processes_text_get_more_remainder',
        defaultMessage: `{count, plural, =0 {You have reached your maximum number of processes this year.} =1 {You have 1 process left this year.} other {You have {count} processes left this year.}}`
    }
})[type]

const getSpentTextTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_onboardings_used',
        defaultMessage: `{total, plural, =0 {} =1 {} other {{used} of {total} onboarding processes used}}`
    },
    offboarding: {
        id: 'employee_offboarding_offboardings_used',
        defaultMessage: `{total, plural, =0 {} =1 {} other {{used} of {total} offboarding processes used}}`
    },
    process: {
        id: 'processes_processes_used',
        defaultMessage: `{total, plural, =0 {} =1 {} other {{used} of {total} processes used}}`
    }
})[type]

const getUpgradeHeadingTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_heading_get_more',
        defaultMessage: 'Get more onboardings'
    },
    offboarding: {
        id: 'employee_offboarding_heading_get_more',
        defaultMessage: 'Get more offboardings'
    },
    process: {
        id: 'processes_heading_get_more',
        defaultMessage: 'Get more processes'
    }
})[type]

const getUpgradeBundleDescriptionTranslation = (type, bundle) => {
    if(type === 'onboarding') {
        if(bundle === 'enterprise') {
            return {
                id: 'payment_plan_employee_onboarding_description_enterprise',
                defaultMessage: 'Unlimited'
            }
        }

        if(bundle === 'business') {
            return {
                id: 'payment_plan_employee_onboarding_description_business',
                defaultMessage: '10 per year'
            }
        }

        return {
            id: 'payment_plan_employee_onboarding_description_free',
            defaultMessage: '3 per year'
        }
    }

    if(type === 'offboarding') {
        if(bundle === 'enterprise') {
            return {
                id: 'payment_plan_employee_offboarding_description_enterprise',
                defaultMessage: 'Unlimited'
            }
        }

        if(bundle === 'business') {
            return {
                id: 'payment_plan_employee_offboarding_description_business',
                defaultMessage: '10 per year'
            }
        }

        return {
            id: 'payment_plan_employee_offboarding_description_free',
            defaultMessage: '3 per year'
        }
    }

    if(type === 'process') {
        if(bundle === 'enterprise') {
            return {
                id: 'payment_plan_processes_description_enterprise',
                defaultMessage: 'Unlimited'
            }
        }

        if(bundle === 'business') {
            return {
                id: 'payment_plan_processes_description_business',
                defaultMessage: '10 per year'
            }
        }

        return {
            id: 'payment_plan_processes_description_free',
            defaultMessage: '3 per year'
        }
    }
}

export default LimitStatus