import React, { useState, Fragment } from 'react'
import GroupsProvider, { useGroups } from 'contexts/groups'
import { useAccess } from 'contexts/access'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useIntl, FormattedMessage } from 'react-intl'
import { compact } from 'utilities/array'
import Form from 'components/form/controller'
import ContractableSelectField from 'components/form/field/contractable-select'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import AddressField from 'components/form/field/address'
import PeopleField from 'components/form/field/people'
import { Heading, Columns } from './s'
import { FlexChildShrink } from 'components/flex'
import { Plain, ButtonSubmit } from 'components/button'
import { size } from 'utilities/object'

const AddGroup = ({ group: externalGroup = null, type: initialType = null, onDone, doneLabel, salt }) => {
    const { formatMessage } = useIntl()

    const { addGroup } = useGroups()

    const { check } = useAccess()
    const groupsManage = check('groups:manage')
    const teamsManage = groupsManage || check('teams:manage')
    const locationsManage = groupsManage || check('locations:manage')

    const typeOptions = compact([
        teamsManage && {
            value: 'team',
            label: formatMessage({
                id: 'noun_team',
                defaultMessage: 'Team'
            })
        },
        locationsManage && {
            value: 'location',
            label: formatMessage({
                id: 'noun_location',
                defaultMessage: 'Location'
            })
        }
    ])

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    const [type, setType] = useState(initialType ?? ((typeOptions.length === 1) ?
        typeOptions[0].value :
        null
    ))

    const [address, setAddress] = useState(null)
    const [adding, setAdding] = useState(false)

    const add = async body => {
        setAdding(true)

        const { ok, response } = await addGroup({
            ...body,
            type
        })

        setAdding(false)

        if(ok && response) {
            !!onboarder && updateOnboardingStatus({
                [getServiceOnboardingName(type)]: 'completed'
            })

            onDone(response)
        }
    }

    const headingTranslation = getHeadingTranslation(type) ?? {
        id: 'group_action_add',
        defaultMessage: 'Add group'
    }

    return (
        <Form
            layout="vertical"
            onSubmit={add}>
            {({ touched, errors, trigger }) => (
                <>
                    <Heading>
                        <FormattedMessage {...headingTranslation} />
                    </Heading>
                    {(!initialType && typeOptions.length > 1) && (
                        <ContractableSelectField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_type',
                                defaultMessage: 'Type'
                            })}
                            name="type"
                            field={{
                                value: type ?? '',
                                required: true,
                                include: 'always',
                                unsettable: false,
                                unsetOnPick: false
                            }}
                            options={typeOptions}
                            onChange={({ type }) => setType(type)} />
                    )}
                    {!!type && (
                        <>
                            <StringField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_name',
                                    defaultMessage: 'Name'
                                })}
                                name="name"
                                field={{
                                    value: externalGroup?.name,
                                    required: true,
                                    include: 'always'
                                }} />
                            {(type === 'location') && (
                                <AddressField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'noun_address',
                                        defaultMessage: 'Address'
                                    })}
                                    name="address"
                                    field={{
                                        value: address,
                                        include: 'always'
                                    }}
                                    onChange={({ address }) => setAddress(address)} />
                            )}
                            <TextField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_description',
                                    defaultMessage: 'Description'
                                })}
                                name="description"
                                field={{
                                    include: 'always'
                                }}
                                />
                            <PeopleField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_members',
                                    defaultMessage: 'Members'
                                })}
                                name="members" />
                        </>
                    )}
                    <Columns>
                        <FlexChildShrink>
                            <Plain
                                onClick={() => onDone()}
                                className="neutral"
                                disabled={adding}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                        </FlexChildShrink>
                        <FlexChildShrink>
                            <ButtonSubmit
                                className={`constructive${adding ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors) || adding}
                                ref={trigger}>
                                {!!doneLabel && doneLabel}
                                {!doneLabel && (
                                    <FormattedMessage
                                        id="action_continue"
                                        defaultMessage="Continue" />
                                )}
                            </ButtonSubmit>
                        </FlexChildShrink>
                    </Columns>
                </>
            )}
        </Form>
    )
}

const getServiceOnboardingName = type => ({
    team: 'addTeam',
    location: 'addLocation'
})[type]

const getHeadingTranslation = type => ({
    team: {
        id: 'team_action_add',
        defaultMessage: 'Add team'
    },
    location: {
        id: 'location_action_add',
        defaultMessage: 'Add location'
    }
})[type]

export default props => {
    const groupsContext = useGroups()

    const [Wrapper, wrapperProps = null] = !!groupsContext ?
        [Fragment] :
        [GroupsProvider, { fetchOnMount: false }]

    return (
        <Wrapper {...wrapperProps}>
            <AddGroup {...props} />
        </Wrapper>
    )
}