import styled from 'styled-components'
import { H3 } from 'components/typography/heading'

export const Heading = styled(H3)`
    margin-block-end: 0;

    line-height: 40px;
`

export const Empty = styled.p`
    margin: 0;
    padding: 8px 40px 8px 0;

    color: var(--huma-color-foreground-subtle);
`