import styled from 'styled-components'
import { belowPhone, fromTablet } from 'utilities/styled'
import ExpandableBase from 'components/expandable'
import { Grid } from 'components/grid'
import LoaderBase from 'pages/competence/panes/gap/loader'
import TutorialBase from 'components/tutorial'

export const Expandable = styled(ExpandableBase)`
    margin-block-end: 40px;
`

export const Heading = styled.span`
    display: inline-flex;
    align-items: center;
    column-gap: 24px;
`

export const DataWrapper = styled(Grid)`
    gap: 24px;

    ${fromTablet`
        grid-template-columns: 2fr 1fr;
    `}

    &.active {
        min-height: 500px;
    }
`

export const Loader = styled(LoaderBase)`
    left: 85px;

    transform: unset;
`

export const Tutorial = styled(TutorialBase)`
    margin-block-start: 32px;
`

export const ResetWrapper = styled.div`
    display: flex;
    justify-content: center;

    margin-block-start: 32px;

    ${belowPhone`
        display: none;
    `}
`