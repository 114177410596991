import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Expandable, Heading } from './s'
import Content from './content'
import { Target } from 'styled-icons/feather'

const MyGap = props => (
    <Expandable
        icon={<Target size={24} />}
        heading={(
            <Heading>
                <span>
                    <FormattedMessage
                        id="competence_section_gap_analysis"
                        defaultMessage="Gap analysis" />
                </span>
            </Heading>
        )}
        defer={true}>
        <Content {...props} />
    </Expandable>
)

export default MyGap
