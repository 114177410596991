import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'integrations'

const Integrations = props => {
    const {
        initialized,

        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule('integrations') && check('organization:manage')
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="integrations.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}
            className="xscender">
            <FormattedMessage
                id="noun_integrations"
                defaultMessage="Integrations" />
        </MenuItemLink>
    )
}

export default Integrations