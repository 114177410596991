import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { flexChildShrinkCss, FlexChildShrink } from 'components/flex'
import { h3Css } from 'components/typography/heading'
import { Logo as LogoBase } from 'components/svg'
import { Grid } from 'components/grid'
import Paragraph, { ParagraphSmall } from 'components/typography/paragraph'
import ActionsBase from 'components/form/actions'
import LinkBase from 'components/link'

export const StepAnimated = styled(motion.div).attrs(() => ({
    variants: {
        in: {
            opacity: 1,
            transition: {
                duration: 1
            }
        }
    }
}))`
    opacity: 0;
`

export const Step = ({ show, children }) => {
    if(!show) {
        return null
    }

    return (
        <StepAnimated animate="in">
            {children}
        </StepAnimated>
    )
}

export const LogoContainer = styled.div`
    margin-block-end: 24px;

    color: var(--huma-color-foreground-brand);
`

export const Logo = styled(LogoBase)`
    width: 80px;

    html[data-theme='dark'] & {
        filter: saturate(0) brightness(100);
    }
`

export const Subheading = styled(Paragraph)`
    margin-block-end: 8px;
`

export const HeadingColumns = styled(Grid)`
    grid-template-columns: repeat(2, minmax(min-content, auto));
    justify-content: space-between;
    align-items: center;
    margin-block-end: 32px;
`

export const Heading = styled.h1`
    ${h3Css}
    margin: 0;
`

export const Fields = styled(FlexChildShrink)`
    & > :last-child {
        margin-block-end: 0;
    }
`

export const Actions = styled(ActionsBase)`
    align-items: center;

    margin-top: 24px;
    padding: 0;
`

export const LoaderWrap = styled.div`
    ${flexChildShrinkCss}
    min-height: 200px;
    position: relative;

    html[data-theme="dark"] & img {
        display: none;
    }
`

export const CenteredParagraph = styled(ParagraphSmall)`
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const Link = styled(LinkBase)`
    display: inline-block;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
`