import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowTablet, fromPhone
} from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase,
    GridColumnHeaderSortable as GridColumnHeaderSortableBase
} from 'components/grid'
import { SkeletonCell as SkeletonCellBase } from 'components/skeleton'
import TutorialBase from 'components/tutorial'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    width: 100%;
    grid-template-columns: minmax(0, 2fr) repeat(2, 1fr) 40px;
    column-gap: 24px;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${belowTablet`
        grid-template-columns: repeat(4, auto);
        justify-content: start;
    `}
`

const gridColumnHeaderCss = css`
    align-items: center;
    display: flex;
    height: 72px;
    padding: 24px 0;

    ${belowTablet`
        padding: 16px 0;
    `}
`

export const GridColumnHeaderSortable = styled(GridColumnHeaderSortableBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    ${belowTablet`
        display: none;
    `}
`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
    justify-content: center;

    ${belowTablet`
        display: none;
    `}

    > svg {
        ${svgStroke()}
    }
`

export const SkeletonCell = styled(SkeletonCellBase)`
    justify-content: center;
`

export const SkeletonProgressCell = styled(SkeletonCellBase)`
    display: grid;
    grid-template-columns: 24px 1fr;
    column-gap: 16px;
    align-items: center;
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`

export const Tutorial = styled(TutorialBase)`
    ${fromPhone`
        margin-top: 64px;
    `}
`
