import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import {
    isBefore, isAfter, isWithinInterval,
    getYear, isPast as fnsIsPast,
    startOfDay, endOfDay,
    startOfMonth, endOfMonth, isThisMonth,
    startOfQuarter, endOfQuarter, isThisQuarter,
    startOfYear, endOfYear, isThisYear,
    subDays, addDays, subMonths
} from 'date-fns'
import { useAbsenceEntries as useAbcenceEntriesByOrder } from 'contexts/absence-entries'
import { useAbsenceEntriesByTime } from 'contexts/absence-entries-by-time'
import { useAbsenceEntry } from 'contexts/absence-entry'
import { safeFormat, isofy, getDate } from 'utilities/date-time'
import { compact } from 'utilities/array'
import PubSub from 'pubsub-js'

export const followUpEnabledTypeNames = ['sick']

export const absenceTypeTofollowUpType = type => ({
    sick: 'sickLeave'
})[type] ?? type

export const useAbsenceEntries = (context = 'order') => ({
    order: useAbcenceEntriesByOrder,
    time: useAbsenceEntriesByTime,
    single: useAbsenceEntry
})[context]()

export const isPast = ({ start, end }, now) => {
    start = isofy(start)
    end = isofy(end)

    const ongoing = isOngoing({ start, end }, now)
    const upcoming = isFuture({ start }, now)

    return !ongoing && !upcoming
}

export const isOngoing = ({ start, end }, now) => {
    start = isofy(start)
    end = isofy(end)

    if(!start) {
        return false
    }

    if(!end) {
        if(isBefore(startOfDay(start), now)) {
            return true
        }

        return false
    }

    return isWithinInterval(now, {
        start: startOfDay(start),
        end: endOfDay(end)
    })
}

export const isFuture = ({ start }, now) => {
    start = isofy(start)
    return isAfter(start, endOfDay(now))
}

export const enrichEntry = entry => {
    const {
        startDate,
        endDate
    } = entry

    const start = startOfDay(isofy(startDate))

    const end = endDate ?
        endOfDay(isofy(endDate)) :
        null

    return {
        ...entry,
        start,
        end
    }
}

export const getYearRange = options => {
    const {
        date = new Date(),
        limitToToday = false
    } = options ?? {}

    return [
        startOfYear(date),
        (limitToToday && isThisYear(date)) ?
            new Date() :
            endOfYear(date)
    ]
}

export const getQuarterRange = options => {
    const {
        date = new Date(),
        limitToToday = false
    } = options ?? {}

    return [
        startOfQuarter(date),
        (limitToToday && isThisQuarter(date)) ?
            new Date() :
            endOfQuarter(date)
    ]
}

export const getMonthRange = options => {
    const {
        date = new Date(),
        limitToToday = false
    } = options ?? {}

    return [
        startOfMonth(date),
        (limitToToday && isThisMonth(date)) ?
            new Date() :
            endOfMonth(date)
    ]
}

export const getTimePeriodValues = ({ type, day, month, months }) => {
    const now = new Date()

    if(type === 'rolling') {
        const fromDate = getDate(addDays(subMonths(now, months), 1))

        return {
            fromDate,
            toDate: getDate()
        }
    }

    if(type === 'fixed') {
        const monthIndex = month ?
            month - 1 :
            0

        const fromDate = getDate(new Date(now.getFullYear(), monthIndex, day))

        return {
            fromDate,
            toDate: getDate()
        }
    }

    return null
}

export const getEffectiveTimeWindow = ({ fromDate, toDate, policy }) => {
    if(!policy) {
        return null
    }

    const { timeWindow } = policy
    const { type, day, month } = timeWindow

    if(type === 'rolling') {
        return null
    }

    const now = new Date()

    if(type === 'fixed') {
        const monthIndex = month ?
            month - 1 :
            0

        if(fromDate && toDate) {
            return {
                fromDate,
                toDate
            }
        } else if(fromDate) {
            const from = isofy(fromDate)

            toDate = getDate(subDays(new Date(from.getFullYear() + 1, monthIndex, day), 1))

            return {
                fromDate,
                toDate
            }
        } else if(toDate) {
            const to = isofy(toDate)

            fromDate = getDate(addDays(new Date(to.getFullYear() - 1, monthIndex, day), 1))

            return {
                fromDate,
                toDate
            }
        } else {
            fromDate = getDate(new Date(now.getFullYear(), monthIndex, day))
            toDate = getDate(subDays(new Date(now.getFullYear() + 1, monthIndex, day), 1))

            return {
                fromDate,
                toDate
            }
        }
    }
}

export const getDaysCount = ({ type, daySum = 0, dayCount = 0 }) => {
    if(!type) {
        return null
    }

    const {
        allowGraded = false,
        gradedAggregate = 'full'
    } = type ?? {}

    const days = (!!allowGraded && gradedAggregate === 'graded') ?
        daySum :
        dayCount

    return days
}

export const useTimePeriodHeading = () => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    return ({ type, day, month, months, from, to }) => {
        if(!type || ((type === 'fixed') && (!day || !month)) || ((type === 'rolling') && !months)) {
            return null
        }

        const monthIndex = month ?
            month - 1 :
            0

        const firstDayOfTheYear = (day === 1 && monthIndex === 0)

        if(type === 'fixed' && firstDayOfTheYear) {
            return getYear(isofy(from))
        }

        if(type === 'fixed') {
            return formatMessage({
                id: 'date_range',
                defaultMessage: '{from} - {to}'
            }, {
                from: safeFormat(
                    from,
                    formatMessage({
                        id: 'date_format_full',
                        defaultMessage: 'MMM d, yyyy'
                    }),
                    { locale }
                ),
                to: safeFormat(
                    to,
                    formatMessage({
                        id: 'date_format_full',
                        defaultMessage: 'MMM d, yyyy'
                    }),
                    { locale }
                )
            })
        }

        return formatMessage({
            id: 'time_period_value_rolling',
            defaultMessage: '{count, plural, =0 {{count} months back in time} =1 {{count} month back in time} other {{count} months back in time}}'
        }, { count: months })
    }
}

export const useTimePeriodText = () => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    return ({ type, day, month, months, year, short = false }) => {
        if(!type || ((type === 'fixed') && (!day || !month)) || ((type === 'rolling') && !months)) {
            return null
        }

        const monthIndex = month ?
            month - 1 :
            0

        const dateRange = (type === 'fixed') ?
            ({
                from: new Date(1900, monthIndex, day),
                to: subDays(new Date(1901, monthIndex, day), 1)
            }) :
            null

        const firstDayOfTheYear = (day === 1 && monthIndex === 0)

        const timeWindowTexts = {
            rolling: {
                label: {
                    id: 'time_period_picker_rolling_label',
                    defaultMessage: 'Based on a rolling time period'
                },
                value: formatMessage({
                    id: 'time_period_value_rolling',
                    defaultMessage: '{count, plural, =0 {{count} months back in time} =1 {{count} month back in time} other {{count} months back in time}}'
                }, { count: months })
            },
            fixed: {
                label: {
                    id: firstDayOfTheYear ?
                        'time_period_picker_fixed_label' :
                        'time_period_picked_semi_fixed_label',
                    defaultMessage: firstDayOfTheYear ?
                        'Follows the calendar year' :
                        'Year window'
                },
                value: firstDayOfTheYear ?
                    short ? year : null :
                    formatMessage({
                        id: 'date_range',
                        defaultMessage: '{from} – {to}'
                    }, {
                        from: safeFormat(
                            dateRange?.from,
                            formatMessage({
                                id: 'date_fns_format_day_short_month_friendly',
                                defaultMessage: 'LLL d'
                            }),
                            { locale }
                        ),
                        to: safeFormat(
                            dateRange?.to,
                            formatMessage({
                                id: 'date_fns_format_day_short_month_friendly',
                                defaultMessage: 'LLL d'
                            }),
                            { locale }
                        )
                    })
            }
        }

        return timeWindowTexts[type] ?? null
    }
}

export const useTimeWindowText = () => ({ from, to }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    if(!from || !to) {
        return null
    }

    from = isofy(from)
    to = isofy(to)

    if(from.getFullYear() === to.getFullYear()) {
        return safeFormat(
            from,
            'yyyy',
            { locale }
        )
    }

    return formatMessage({
        id: 'date_range',
        defaultMessage: '{from} – {to}'
    }, {
        from: safeFormat(
            from,
            'PP',
            { locale }
        ),
        to: safeFormat(
            to,
            'PP',
            { locale }
        )
    })
}

export const getCurrentPolicy = policies => {
    if(!policies) {
        return null
    }

    const now = new Date()

    const policyPeriodPoint = policies.find(({ fromDate, toDate }) => (
        isOngoing({ start: fromDate, end: toDate }, now) ||
        (!fromDate && (!toDate || !fnsIsPast(isofy(toDate))))
    ))

    return policyPeriodPoint ?
        policyPeriodPoint.policy :
        null
}

export const getTerritoryHeading = territory => {
    if(!territory) {
        return null
    }

    return compact([
        territory?.emoji,
        (territory.type === 'region') && '›',
        territory.name
    ]).join(' ')
}

export const refreshAbsenceEntries = () => PubSub.publish('absenceEntries.refresh')
export const refreshAbsenceTypes = () => PubSub.publish('absenceTypes.refresh')
export const refreshAbsenceStats = () => PubSub.publish('absenceStats.refresh')
export const refreshAbsencePolicy = (...args) => PubSub.publish('absencePolicy.refresh', ...args)
export const refreshAbsenceUserPeriods = () => PubSub.publish('absenceUserPeriods.refresh')
export const refreshAbsenceAdjustments = () => PubSub.publish('absenceAdjustments.refresh')
export const refreshAbsencePolicyAssignees = () => PubSub.publish('absencePolicyAssignees.refresh')