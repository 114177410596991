import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { getActionsArray } from 'widgets/context-menu'
import {
    Wrap,
    Header, GridColumnHeader,
    Sortables
} from './s'
import Topic from './topic'
import { TopicModal } from 'pages/handbook/pages/s'
import ActingHandbookTopic from 'pages/handbook/modals/topic'
import { Scrollable as Modal } from 'modals/generic'
import Successful from 'pages/handbook/modals/successful'

const EditOrderContentTopics = ({ category, setOrder, ...props }) => {
    const { formatMessage } = useIntl()

    let theme = useTheme()

    const { check, checkFeature } = useAccess()
    const manageAccess = check('handbook:manage')
    const pinnedAccess = checkFeature('handbook-featured')

    const [actingTopic, setActingTopic] = useState(null)
    const [successfulMessage, setSuccessfulMessage] = useState(null)

    const {
        handbook,
        updateTopic
    } = useHandbook()

    const { salt } = props

    const viewAction = topic => ({
        salt: `${salt}:view`,
        label: formatMessage({
            id: 'action_view',
            defaultMessage: 'View'
        }),
        effect: 'neutral',
        onClick: () => setActingTopic({
            topic,
            mode: 'view'
        })
    })

    const editAction = topic => {
        if (!manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:edit`,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setActingTopic({
                topic,
                mode: 'edit'
            })
        })
    }

    const changeStatusAction = ({ id, status }) => {
        if(!manageAccess) {
            return null
        }

        return {
            salt: `${salt}:change-status`,
            label: formatMessage({
                id: (handbook?.status === 'published') ?
                    ((status === 'published') ?
                        'action_unpublish' :
                        'action_publish'
                    ) :
                    ((status === 'published') ?
                        'action_mark_draft' :
                        'action_mark_ready'
                    ),
                defaultMessage: (handbook?.status === 'published') ?
                    ((status === 'published') ?
                        'Unpublish' :
                        'Publish'
                    ) :
                    ((status === 'published') ?
                        'Mark as draft' :
                        'Mark as ready'
                    )
            }),
            effect: 'neutral',
            onClick: () => {
                updateTopic({
                    body: {
                        status: (status === 'published') ?
                            'draft' :
                            'published'
                    },
                    id
                })
                setOrder(order => ({
                    ...order,
                    categories: order.categories.map(category => ({
                        ...category,
                        topics: category.topics.map(topic => ({
                            ...topic,
                            status: (topic.id === id) ?
                                (status === 'published') ?
                                    'draft' :
                                    'published' :
                                topic.status
                        }))
                    }))
                }))
            }
        }
    }

    const pinAction = ({ id, pinned }) => {
        if (!pinnedAccess || !manageAccess) {
            return null
        }

        return ({
            salt: `${salt}:pin`,
            label: formatMessage({
                id: pinned ?
                    'handbooks_topic_action_unpin' :
                    'handbooks_topic_action_pin',
                defaultMessage: pinned ?
                    'Unpin topic' :
                    'Pin topic'
            }),
            effect: 'neutral',
            onClick: async () => {
                const { ok } = await updateTopic({
                    body: { pinned: !pinned },
                    id
                })

                if (ok) {
                    setOrder(order => ({
                        ...order,
                        categories: order.categories.map(category => ({
                            ...category,
                            topics: category.topics.map(topic => ({
                                ...topic,
                                pinned: topic.id === id ?
                                    !pinned :
                                    topic.pinned
                            }))
                        }))
                    }))
                }
            }
        })
    }

    const actions = {
        view: viewAction,
        edit: editAction,
        changeStatus: changeStatusAction,
        pin: pinAction
    }

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        editHandbookOrder: {
            actions: hasActions
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrap>
                <Header>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_topics"
                            defaultMessage="Topics" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_status"
                            defaultMessage="Status" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_access"
                            defaultMessage="Access" />
                    </GridColumnHeader>
                    <GridColumnHeader />
                </Header>
                <Droppable
                    droppableId={`category-${category.id}`}
                    type="topic">
                    {provided => (
                        <Sortables
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {category.topics?.map((topic, index) => (
                                <Draggable
                                    key={topic.id}
                                    draggableId={`topic-${topic.id}`}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div>
                                            <Topic
                                                {...props}
                                                provided={provided}
                                                snapshot={snapshot}
                                                topic={topic}
                                                actions={actions} />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Sortables>
                    )}
                </Droppable>
                <TopicModal
                    className="large"
                    show={!!actingTopic}
                    dismiss={() => setActingTopic(null)}
                    salt={`${salt}:acting`}>
                    <ActingHandbookTopic
                        {...actingTopic}
                        view={() => setActingTopic(topic => ({
                            ...topic,
                            mode: 'view'
                        }))}
                        edit={() => setActingTopic(topic => ({
                            ...topic,
                            mode: 'edit'
                        }))}
                        dismiss={() => setActingTopic(null)}
                        onDone={response => {
                            if(response?.deleted) {
                                setOrder(order => ({
                                    ...order,
                                    categories: order.categories.map(category => ({
                                        ...category,
                                        topics: category.topics.filter(topic => topic.id !== response.id)
                                    }))
                                }))
                            } else if(response) {
                                setOrder(order => ({
                                    ...order,
                                    categories: order.categories.map(category => ({
                                        ...category,
                                        topics: category.topics.map(topic => ({
                                            ...topic,
                                            ...((topic.id === response.id) ? response : null)
                                        }))
                                    }))
                                }))
                            }
                            setActingTopic(null)

                            if(response?.notify) {
                                setSuccessfulMessage({ what: 'topic' })
                            }
                        }}
                        salt={`${salt}:acting`} />
                </TopicModal>
                <Modal
                    show={successfulMessage}
                    dismiss={() => setSuccessfulMessage(null)}
                    salt={`${salt}:successful`}>
                    <Successful
                        {...successfulMessage}
                        dismiss={() => setSuccessfulMessage(null)}
                        salt={`${salt}:successful`} />
                </Modal>
            </Wrap>
        </ThemeProvider>
    )
}

export default EditOrderContentTopics
