import styled from 'styled-components'
import { bp, belowLaptop, belowTablet, belowPhone, fromLaptop, fromDesktop } from 'utilities/styled'
import { Grid } from 'components/grid'
import { GlobalContainer as GlobalMessage } from 'components/message/s'
import { Main as GlobalWrapper } from 'app/layouts/private/s'
import { Button } from 'components/button'
import StatusBase from 'pages/handbook/components/status'

export const Menu = styled(Grid).attrs(() => ({ as: 'nav' }))`
    overflow: clip;
    overflow-clip-margin: 16px;

    justify-content: space-between;
    column-gap: 16px;

    border-radius: 8px 8px 0 0;
    background-color: var(--huma-color-surface-default);

    &.has-actions:not(.previewing) {
        &:not(:has(> :only-child)) {
            grid-template-columns: minmax(0, 1fr) auto;
        }

        &:has(> :only-child) {
            justify-content: end;
        }
    }

    &.previewing {
        justify-content: end;
    }

    ${fromDesktop`
        margin: -72px 0 0 -88px;
        width: calc(100% + 176px);
        padding: 16px 88px;

        transition: border-radius .05s ease-in-out;
    `}

    ${fromLaptop`
        margin: calc(var(--huma-global-sticky-menu-height) * -1) 0 0 -48px;
        width: calc(100% + 96px);
        padding: 16px 48px;
    `}

    ${bp.between('laptop', 'desktop')`
        ${GlobalMessage} ~ ${GlobalWrapper} & {
            margin-top: -40px;
        }
    `}

    ${belowLaptop`
        position: relative;
        left: 50%;
        right: 50%;

        margin: -40px -50vw 0;
        border-radius: 0;
        width: 100vw;
        padding: 8px 24px;
        box-shadow: 0 1px 0 var(--huma-color-border-default);
    `}
`

export const MenuColumns = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: 16px;

    ${belowTablet`
        justify-content: end;
    `}

    &.wrap {
        ${belowTablet`
            display: flex;
            flex-direction: column-reverse;
            justify-content: start;
            align-items: end;
        `}
    }
`

export const MenuColumn = styled.div`
    &.hide-below-phone {
        ${belowPhone`
            display: none;
        `}
    }
`

export const ExitPreviewButton = styled(Button)`
    ${belowPhone`
        span {
            display: none;
        }
    `}
`

export const Status = styled(StatusBase)`
    margin-block-start: 8px;
`