import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
    svgStroke,
    belowPhone, fromPhone, darkMode
} from 'utilities/styled'
import { Rows, FlexChildShrink } from 'components/flex'
import LocalePickerBase from 'components/locale-picker'

export const Main = styled(Rows)`
    position: relative;
    overflow: hidden;

    grid-area: full-width;
    justify-content: flex-start;
    align-items: center;

    min-height: calc(100svh - var(--huma-global-main-layout-header-height));
    padding-bottom: 96px;

    ${fromPhone`
        padding-top: 72px;
    `}

    ${belowPhone`
        display: grid;
        grid-template-rows: auto auto 1fr;
        justify-content: initial;
        padding: 8px;
    `}
`

const BlobSvg = styled.svg`
    position: fixed;
    top: -25%;
    left: 50%;
    overflow: hidden;

    width: 100%;
    max-width: 1500px;
    min-width: 800px;
    max-height: 1500px;
    min-height: 800px;
    height: 100%;

    transform: translateX(-50%);

    path {
        transform: translate(100px, 100px);
        fill: var(--huma-color-surface-warning-subtle);
        fill-rule: evenodd;
        clip-rule: evenodd;

        ${darkMode`
            fill: var(--huma-color-surface-brand-subtle);
        `}
    }
`

export const Blob = () => (
    <BlobSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        fill="none">
        <motion.path
            initial={{
                d: 'M36.4,-22.5C49.1,0.9,62.6,22.4,56.7,42.2C50.7,61.9,25.4,79.8,-0.5,80.1C-26.3,80.4,-52.6,63,-65.2,39.4C-77.7,15.9,-76.5,-13.8,-63.3,-37.5C-50.2,-61.1,-25.1,-78.8,-6.6,-75C11.9,-71.2,23.8,-45.9,36.4,-22.5Z'
            }}
            animate={{
                d: 'M64.4,-30.4C75.8,-17.5,72,11,58.8,28.2C45.5,45.5,22.7,51.5,-1.8,52.5C-26.3,53.6,-52.6,49.6,-65.2,32.7C-77.9,15.8,-76.8,-14,-63.6,-27.9C-50.5,-41.8,-25.2,-39.8,0.6,-40.2C26.5,-40.6,53,-43.2,64.4,-30.4Z'
            }}
            transition={{
                repeat: Infinity,
                repeatType: 'reverse',
                duration: 9
            }} />
    </BlobSvg>
)

export const ContentContainer = FlexChildShrink

export const Content = styled(FlexChildShrink)`
    position: relative;
    background-color: var(--huma-color-surface-default);
    border-radius: 24px;
    box-shadow: var(--huma-shadow-lower);

    &:not(:last-child) {
        margin-right: 24px;
    }

    ${fromPhone`
        width: 552px;
        padding: 56px 88px;
    `}

    ${belowPhone`
        width: 100%;
        order: 2;
        padding: 32px 24px;
    `}
`

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 16px 24px;
    margin: 8px auto;

    ${fromPhone`
        width: 552px;
    `}

    ${belowPhone`
        width: 100%;
    `}
`

export const FooterStart = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`

export const FooterEnd = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 40px;

    svg {
        ${svgStroke()}
    }

    & > :not(:first-child) {
        position: relative;

        padding-inline-start: 24px;
        margin-inline-start: 24px;

        &::before {
            content: "";

            position: absolute;
            top: 0;
            left: 0;

            width: 1px;
            height: 100%;

            border-left: 1px solid var(--huma-color-border-default);
        }
    }
`

export const LocalePicker = styled(LocalePickerBase)`
    & > div {
        margin-bottom: 0;
    }

    select {
        border: 0;
    }
`