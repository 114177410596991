import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useGroup } from 'contexts/group'
import { useCompetenceProfile } from 'contexts/competence-profile'
// import { useCompetenceProfiles } from 'contexts/competence-profiles'
import { refreshCompetenceProfile } from 'pages/competence/utilities'
import { useActiveUsers } from 'pages/competence/utilities/gap'
import {
    ConfigurationWrapper,
    Types, TypesIcon,
    Group, Symbol, Name, PreviewButton
} from './s'
import { Plain } from 'components/button'
import Avatars from 'components/avatars'
import Person from 'components/person'
import { Scrollable as Modal } from 'modals/generic'
import ActingProfile from 'pages/competence/modals/profile'
import { SkeletonSymbolAndMeta, SkeletonAvatars } from 'components/skeleton'
import {
    Eye, EyeOff,
    Edit,
    Trash2 as Delete,
    Plus,
    MapPin as Location
} from 'styled-icons/feather'
import { Team, DashedCircle } from 'components/feather'

export const ConfigurationTarget = ({ profileId, types = [], setConfiguring, salt }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const {
        profile,
        hasFetched,
        fetching,
        togglePrivacy,
        removeProfile
    } = useCompetenceProfile()

    // const {
    //     togglePrivacy,
    //     removeProfile
    // } = useCompetenceProfiles()

    const [acting, setActing] = useState(null)

    const viewAction = profile => ({
        salt: `${salt}:view`,
        icon: <DashedCircle size={24} />,
        label: formatMessage({
            id: 'action_view_details',
            defaultMessage: 'View details'
        }),
        effect: 'neutral',
        onClick: () => setActing({
            mode: 'view',
            profile
        })
    })

    const togglePrivacyAction = profile => {
        if(!manageAccess) {
            return null
        }

        return {
            salt: `${salt}:toggle_privacy`,
            icon: profile.public ?
                <EyeOff size={24} /> :
                <Eye size={24} />,
            label: formatMessage({
                id: profile.public ?
                    'action_privacy_hide' :
                    'action_privacy_show',
                defaultMessage: profile.public ?
                    'Make private' :
                    'Make public'
            }),
            effect: 'neutral',
            onClick: async () => {
                await togglePrivacy(profile)
                refreshCompetenceProfile(profile.id)
            }
        }
    }

    const editAction = profile => {
        if(!manageAccess) {
            return null
        }

        return{
            salt: `${salt}:edit`,
            icon: <Edit size={24} />,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                mode: 'edit',
                profile
            })
        }
    }

    const removeAction = ({ id }) => {
        if(!manageAccess) {
            return null
        }

        return {
            salt: `${salt}:delete`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: () => removeProfile(id),
            prompt: {
                question: formatMessage({
                    id: 'competence_profiles_action_delete_prompt_question',
                    defaultMessage: 'Confirm deleting competence profile'
                }),
                message: formatMessage({
                    id: 'competence_profiles_action_delete_prompt_message',
                    defaultMessage: 'This competence profile will be deleted. This can not be undone.'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        }
    }

    const actions = {
        view: viewAction,
        togglePrivacy: togglePrivacyAction,
        edit: editAction,
        remove: removeAction
    }

    return (
        <ConfigurationWrapper>
            {!types.length && (
                <Plain
                    className="constructive"
                    icon={<Plus size={24} />}
                    onClick={() => setConfiguring(true)}>
                    <FormattedMessage
                        id="competence_gap_action_add_target"
                        defaultMessage="Add competence target" />
                </Plain>
            )}
            {!!types.length && (
                <>
                    {profileId && (
                        <>
                            {(!hasFetched || !profile) && <SkeletonSymbolAndMeta size={40} />}
                            {(!!hasFetched && profile) && (
                                <Group className="grid">
                                    <Symbol symbol={profile?.symbol} />
                                    <Name className="ellipsify compact">{profile?.name}</Name>
                                    <PreviewButton
                                        className="constructive small"
                                        onClick={() => setActing({
                                            profile,
                                            mode: 'view'
                                        })}>
                                        <FormattedMessage
                                            id="action_preview"
                                            defaultMessage="Preview" />
                                    </PreviewButton>
                                </Group>
                            )}
                        </>
                    )}
                    {!profileId && (
                        <Types>
                            <TypesIcon />
                            <span>
                                <FormattedMessage
                                    id="competence_count"
                                    defaultMessage="{count, plural, =0 {} =1 {1 competence} other {{count} competences}}"
                                    values={{ count: types.length }} />
                            </span>
                        </Types>
                    )}
                    <Plain
                        className="constructive"
                        disabled={(fetching || (!!profileId && !profile))}
                        onClick={() => setConfiguring(true)}>
                        <FormattedMessage
                            id="action_change"
                            defaultMessage="Change" />
                    </Plain>
                </>
            )}
            <Modal
                show={!!acting}
                dismiss={() => setActing(null)}>
                <ActingProfile
                    {...acting}
                    actions={actions}
                    dismiss={() => setActing(null)}
                    onDone={() => setActing(null)} />
            </Modal>
        </ConfigurationWrapper>
    )
}

export const ConfigurationPeople = ({ users = {}, setConfiguring }) => {
    const groupContext = useGroup()
    const { group = {} } = groupContext ?? {}

    const {
        selectionType,
        locked = false
    } = users ?? {}

    const [fetching, activeUsers] = useActiveUsers()

    const GroupIcon = (selectionType === 'team') ? Team : Location

    return (
        <ConfigurationWrapper>
            {!selectionType && (
                <Plain
                    className="constructive"
                    icon={<Plus size={24} />}
                    onClick={() => setConfiguring(true)}>
                    <FormattedMessage
                        id="people_action_add"
                        defaultMessage="Add people" />
                </Plain>
            )}
            {['team', 'location'].includes(selectionType) && (
                <>
                    <Group>
                        {(fetching || !group) && <SkeletonSymbolAndMeta />}
                        {(!fetching && group) && (
                            <>
                                <GroupIcon size={24} />
                                <span>
                                    <FormattedMessage
                                        id="group_members_of"
                                        defaultMessage="Members of {group}"
                                        values={{ group: group?.name }} />
                                </span>
                            </>
                        )}
                    </Group>
                    {!locked && (
                        <Plain
                            className="constructive"
                            onClick={() => setConfiguring(true)}>
                            <FormattedMessage
                                id="action_change"
                                defaultMessage="Change" />
                        </Plain>
                    )}
                </>
            )}
            {(selectionType === 'people') && (
                <>
                    <Group>
                        {fetching && <SkeletonAvatars size={32} />}
                        {(!fetching && !!activeUsers?.length) && (
                            <>
                                {activeUsers?.length > 1 && (
                                    <Avatars
                                        people={activeUsers}
                                        max={8} />
                                )}
                                {activeUsers?.length === 1 && (
                                    <Person who={activeUsers[0]} />
                                )}
                            </>
                        )}
                    </Group>
                    {!locked && (
                        <Plain
                            className="constructive"
                            onClick={() => setConfiguring(true)}>
                            <FormattedMessage
                                id="action_change"
                                defaultMessage="Change" />
                        </Plain>
                    )}
                </>
            )}
        </ConfigurationWrapper>
    )
}