import React, { useState, Fragment } from 'react'
import PersonProvider, { usePerson } from 'contexts/person'
import ProcessesProvider, { useProcesses } from 'contexts/processes'
import { useNavigate } from 'react-router-dom'
import PickExistingUser from './pick-existing-user'
import PredefinedUser from './predefined-user'
import { compact } from 'utilities/array'
import { getOffboardingNewUrl } from 'utilities/url'

const AddOffboarding = ({ type, template = null, modal, salt }) => {
    const navigate = useNavigate()

    const [templates, setTemplates] = useState(compact([template]))
    const [dynamicAssignmentTypes, setDynamicAssignmentTypes] = useState([])

    const proceed = state => {
        modal.dismiss()
        navigate(getOffboardingNewUrl(), { state })
    }

    const props = {
        templates,
        setTemplates,
        dynamicAssignmentTypes,
        setDynamicAssignmentTypes,
        proceed,
        modal,
        salt
    }

    if(type === 'predefined') {
        return <PredefinedUser {...props} />
    }

    return <PickExistingUser {...props} />
}

export default ({ concernsId: userId = null, ...props }) => {
    const personContext = usePerson()
    const processesContext = useProcesses()

    const [PersonWrapper, personProps = null] = !personContext ?
        [PersonProvider, { id: userId }] :
        [Fragment]

    const [ProcessesWrapper, processesProps = null] = !processesContext ?
        [ProcessesProvider, {
            type: 'offboarding',
            fetchOnMount: false
        }] :
        [Fragment]

    return (
        <PersonWrapper {...personProps}>
            <ProcessesWrapper {...processesProps}>
                <AddOffboarding {...props} />
            </ProcessesWrapper>
        </PersonWrapper>
    )
}