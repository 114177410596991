import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from '../'

const feature = 'people-domain-signup'

const SystemSettingsDomainVerification = props => {
    const {
        initialized,

        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const access = initialized && checkFeature(feature) && check('organization:manage')
    const upgradable = checkUpgradable({ feature })

    return (
        <MenuItemLink
            {...props}
            to="settings.domainverification" end
            feature={feature}
            access={access}
            upgradable={upgradable}
            nested>
            <FormattedMessage
                id="settings_pane_domainverification"
                defaultMessage="Domain verification" />
        </MenuItemLink>
    )
}

export default SystemSettingsDomainVerification