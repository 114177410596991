import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePayment } from 'contexts/payment'
import { openChat } from 'hooks/voiceflow'
import { ContactSales, ContactSupport, Channels, ChannelLink } from './s'
import { InlineButton } from 'components/button'
import { /*Phone, */Mail as Email } from 'styled-icons/feather'

const FeedbackQuestions = ({ onClick, mode = 'sales', salt, ...props }) => {
    const { locale } = useIntl()

    const {
        sales,
        support
    } = usePayment()

    const Contact = { support: ContactSupport }[mode] ?? ContactSales
    const source = { support }[mode] ?? sales

    const data = {
        ...(source?.nb ?? null),
        ...(source?.[locale] ?? null)
    }

    return (
        <Contact
            {...props}
            prompt={(
                <FormattedMessage
                    id="prompt_contact_sales_or_chat"
                    defaultMessage="Got feedback or questions?\nContact our experts by <link>chat<\/link> or…"
                    values={{
                        link: chunks => (
                            <InlineButton
                                className="underline-reversed"
                                onClick={() => {
                                    onClick?.()
                                    openChat()
                                }}>
                                {chunks}
                            </InlineButton>
                        )
                    }} />
            )}
            cta={(
                <Channels>
                    {/* <ChannelLink
                        className="constructive"
                        href={`tel:${data.phone}`}
                        onClick={onClick}>
                        <Phone size={16} />
                        {data.phone}
                    </ChannelLink> */}
                    <ChannelLink
                        className="constructive"
                        href={`mailto:${data.email}`}>
                        <Email size={16} />
                        {data.email}
                    </ChannelLink>
                </Channels>
            )}
            salt={`${salt}:feedback-questions`} />
    )
}

export default FeedbackQuestions