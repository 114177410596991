import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { HeroColumns } from './s'
import ModuleTitle from 'components/module-title'
import { Plain } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import ExportEquipment from 'pages/equipment/modals/export'
import { DownloadCloud } from 'styled-icons/feather'

const EquipmentHero = ({ salt }) => {
    const { formatMessage } = useIntl()

    const [exporting, setExporting] = useState(false)

    const {
        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const importExportAvailable = checkFeature('equipment-import-export')
    const equipmentAdmin = check('equipment:manage')
    const importExportUpgradable = checkUpgradable({ feature: 'equipment-import-export' })

    return (
        <HeroColumns>
            <ModuleTitle
                which="equipment"
                title={formatMessage({
                    id: equipmentAdmin ?
                        'noun_equipment' :
                        'equipment_section_your_equipment',
                    defaultMessage: equipmentAdmin ?
                        'Equipment' :
                        'Your equipment'
                })} />
            {((importExportAvailable && equipmentAdmin) || importExportUpgradable) && (
                <>
                    <div>
                        <Plain
                            className="constructive"
                            icon={DownloadCloud}
                            onClick={() => setExporting(true)}
                            disabled={exporting}>
                            <FormattedMessage
                                id="action_export"
                                defaultMessage="Export" />
                        </Plain>
                    </div>
                    <Modal
                        show={exporting}
                        dismiss={() => setExporting(false)}
                        salt={`${salt}:export`}>
                        <ExportEquipment
                            upgradable={importExportUpgradable}
                            onDone={() => setExporting(false)}
                            salt={`${salt}:export`} />
                    </Modal>
                </>
            )}
        </HeroColumns>
    )
}

export default EquipmentHero
