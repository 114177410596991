import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { categoryToMetaMap } from 'pages/competence/constants/category'
import { Symbol, Category, Heading } from './s'
import { CloseButton } from 'components/button'
import { symbol as symbolFallback } from 'pages/competence'

const ViewCompetenceRecordHero = ({ type, dismiss }) => {
    const categoryFormatter = useCategoryFormatter()

    return (
        <>
            <Symbol symbol={type.symbol ?? symbolFallback} />
            <Category>
                <FormattedMessage {...categoryToMetaMap[type.category].name} />
            </Category>
            <Heading>{categoryFormatter(type)}</Heading>
            <CloseButton onClick={dismiss} />
        </>
    )
}

export default ViewCompetenceRecordHero
