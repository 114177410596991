import React from 'react'
import { cls } from 'utilities/dom'
import { Symbol } from './s'
import ItemMeta from 'components/item-meta'
import { Plain } from 'components/button'
import { SmartDocumentTemplate } from 'components/feather'
import LanguageLegislation from 'pages/documents/components/language-legislation'

export const SmartTemplateSymbol = ({ size = 40, className }) => {
    const iconSize = size / 2

    className = cls([
        className,
        (iconSize <= 16) && 'small'
    ])

    return (
        <Symbol
            {...(className ? { className } : null)}
            icon={<SmartDocumentTemplate size={iconSize} />}
            size={size} />
    )
}

export const SmartTemplate = ({ template, size = 40, className, onClick }) => (
    <ItemMeta
        {...(onClick ?
            {
                as: Plain,
                onClick
            }
            : null
        )}
        {...(className ? { className } : null)}
        symbolProps={{
            icon: <SmartDocumentTemplate size={size} />,
            className: 'hover-effect'
        }}
        name={template.name}
        {...(template.language || template.legislation) ? {
            meta: {
                text: (
                    <LanguageLegislation
                        wrap={false}
                        language={template?.language}
                        legislation={template?.legislation} />
                ),
                className: 'interpoint-divider'
            }
        } : null} />
)
