import React from 'react'
import { FormattedMessage } from 'react-intl'
import paths from 'app/paths'
import { Link, SkeletonListRow } from './s'
import { SkeletonCell, SkeletonCircle, SkeletonStrings } from 'components/skeleton'
import Badge from 'components/badge'
import { ArrowRight } from 'styled-icons/feather'

const Type = ({ type, hasFetched, total }) => {
    const link = getLinkByType(type)

    if(!link || (!!hasFetched && !total)) {
        return null
    }

    if(total > 100) {
        total = '99+'
    }

    return (
        <Link {...link}>
            {!hasFetched && (
                <SkeletonListRow
                    columns={3}
                    key={`list:dashboard:todos:${type}`}>
                    <SkeletonCell>
                        <SkeletonCircle size={24} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings
                            size={24}
                            length={32} />
                    </SkeletonCell>
                </SkeletonListRow>
            )}
            {!!hasFetched && (
                <>
                    <Badge number={total} />
                    <span>
                        <FormattedMessage
                            id={`dashboard_todo_label_${type}`}
                            defaultMessage={type}
                            values={{ count: total }} />
                    </span>
                    <ArrowRight
                        className="arrow"
                        size={24} />
                </>
            )}
        </Link>
    )
}

const getLinkByType = type => ({
    deviation: {
        to: paths.deviation.base,
        state: {
            filter: {
                statuses: ['not_started']
            }
        }
    },
    whistleblowing: {
        to: paths.whistleblowing.base,
        state: {
            filter: {
                statuses: ['new', 'seen']
            }
        }
    },
    absence: {
        to: paths.absence.calendar,
        state: {
            intent: 'show-absence-panel'
        }
    },
    revisionGroups: {
        to: paths.salary.base,
        state: {
            intent: 'show-salary-revision-groups-panel'
        }
    },
    invite: {
        to: paths.people.base,
        state: {
            intent: 'invite'
        }
    },
    meetings: {
        to: paths.meetings.base
    },
    documents: {
        to: paths.documents.base,
        state: {
            intent: 'show-signing-panel'
        }
    }
})[type]

export default Type
