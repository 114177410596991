import styled from 'styled-components'
import { Columns as ColumnsBase } from 'components/flex'

export const Fields = styled.div`
    > :last-child {
       margin-bottom: 0;
    }
`

export const Columns = styled(ColumnsBase)`
    align-items: flex-end;
    column-gap: 16px;
`