import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSmartEntity } from 'contexts/smart-entity'
import { getContentSources } from 'components/tiptap/extensions/variable'
import Paragraph from 'components/typography/paragraph'

const SourceReferences = ({ type, name, hideIfNone = false, as: Wrapper = Paragraph, ...props }) => {
    const { entity } = useSmartEntity()
    const count = getContentSources(entity).find(contentSource => contentSource.type === type && contentSource.name === name)?.count ?? 0

    if(hideIfNone && count === 0) {
        return null
    }

    return (
        <Wrapper {...((Wrapper !== Fragment) ? props : null)}>
            <FormattedMessage
                id="documents_smart_data_sources_meta_references"
                defaultMessage="{count, plural, =0 {Never referenced in the content} =1 {Referenced 1 time in the content} other {Referenced {count} times in the content}}"
                values={{ count }} />
        </Wrapper>
    )
}

export default SourceReferences