import React, { Component, createContext, useContext } from 'react'
import { get } from 'api'

export const HandbookTopicTemplateContext = createContext()

export default class HandbookTopicTemplateProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)

        this.state = {
            template: null,
            fetching: false,
            hasFetched: false,

            fetchTemplate: this.fetch,
            setId: this.setId
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        !!fetchOnMount && this.fetch()
    }

    componentDidUpdate({ id }) {
        if(id !== this.props.id) {
            this.replace(this.props.id)
        }
    }

    setId = id => this.id = id

    fetch = async () => {
        if(!this.id) {
            return
        }

        this.setState({ fetching: true })

        const { response: template, ok } = await get({
            path: `/handbook-templates/topics/${this.id}`
        })

        ok && this.setId(template.id)

        this.setState({
            ...(ok ? { template } : null),
            fetching: false,
            hasFetched: true
        })
    }

    replace = id => {
        this.setId(id)
        this.setState({ topic: null }, this.fetch)
    }

    render() {
        const { children = null } = this.props

        return (
            <HandbookTopicTemplateContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </HandbookTopicTemplateContext.Provider>
        )
    }
}

export const useHandbookTopicTemplate = () => useContext(HandbookTopicTemplateContext)