import queryString from 'query-string'
import { remove as removeCookie } from 'utilities/cookie'

const addToHubspotQueue = (event, data) => {
    const queue = global._hsq = global._hsq ?? []
    queue.push([event, data])
}

const setPath = path => addToHubspotQueue('setPath', path)

const anonymizePath = salt => setPath(queryString.stringifyUrl({
    url: '/hubspot-update',
    query: salt ? { salt } : null
}))

const trackPageView = () => addToHubspotQueue('trackPageView')

export const initialize = () => {
    // Removing the previously set __hs_do_not_track cookie which disabled
    // Hubspot tracking; https://developers.hubspot.com/docs/api/events/cookie-banner
    // addToHubspotQueue('doNotTrack', { track: true })

    // Hubspot is unable to clear their own cookie, so we have to do it for them.
    removeCookie('__hs_do_not_track', true)

    anonymizePath()
    trackPageView()
}

export const identify = data => addToHubspotQueue('identify', data)

export const identifyAndSend = ({ salt, ...data }) => {
    anonymizePath(salt)
    identify(data)
    trackPageView()
}