import { useCompetenceTypes } from 'contexts/competence-types'
import { useCompetenceByType } from 'contexts/competence-by-type'
import { useCompetenceType } from 'contexts/competence-type'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import PubSub from 'pubsub-js'
import { isNumeric } from 'utilities/math'
import { getExpiration } from 'components/expiry-status'

export const useCompetenceContext = (context = 'types') => ({
    types: useCompetenceTypes,
    byType: useCompetenceByType,
    type: useCompetenceType
})[context]()

export const useCheckViewLevelAccess = () => {
    const { check } = useAccess()
    const { isItMyOwnId } = useMe()

    return record => {
        if(!record) {
            return false
        }

        const manageAccess = check('competence:manage')
        const myOwnRecord = isItMyOwnId(record?.concerns?.id)

        return manageAccess || myOwnRecord
    }
}

export const useGetErrorOrWarning = () => {
    const checkViewLevelAccess = useCheckViewLevelAccess()

    return record => {
        if(!record) {
            return null
        }

        const warnings = {}

        const {
            type,
            attachments = [],
            links = [],
            validTo,
            level
        } = record ?? {}

        const {
            category,
            documentationRequired = false,
            validToRequired = false
        } = type ?? {}

        if(documentationRequired && !attachments?.length && !links?.length) {
            warnings.documentation = true
        }

        if(category === 'certification') {
            const expiration = getExpiration({ to: validTo })

            if(expiration?.status === 'expired') {
                warnings.expired = true
            }

            if(expiration?.status?.startsWith('expiring')) {
                warnings.expiring = true
            }

            if(validToRequired && !validTo) {
                warnings.missingValidTo = true
            }
        }

        if(!isNumeric(level) && ['skill', 'language'].includes(category) && checkViewLevelAccess(record)) {
            warnings.missingLevel = true
        }

        return warnings
    }
}

export const refreshCompetenceRecords = () => PubSub.publish('competenceRecords.refresh')
export const refreshCompetenceByType = () => PubSub.publish('competenceByType.refresh')
export const refreshCompetenceProfile = (...args) => PubSub.publish('competenceProfile.refresh', ...args)