import React from 'react'
import { useAbsenceApprovalStatusTextFormatter } from './'
import { cls } from 'utilities/dom'
import { StatusLabel } from './s'

const AbsenceApprovalStatusLabel = ({ status, className }) => {
    const formatter = useAbsenceApprovalStatusTextFormatter()
    const text = formatter(status)

    if(!text) {
        return null
    }

    className = cls([
        absenceStatusToClassName(status),
        className
    ])

    return (
        <StatusLabel className={className}>
            {text}
        </StatusLabel>
    )
}

export const absenceStatusToClassName = status => ({
    approved: 'active',
    pending: 'neutral',
    rejected: 'failed',
    canceled: 'neutral'
})[status] ?? 'neutral'

export default AbsenceApprovalStatusLabel
