import React, { Component, createContext, useContext } from 'react'
import { outget } from 'api'
import { getChannel } from 'utilities/broadcaster'
import { local } from 'utilities/storage'

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
}

export const EnvironmentContext = createContext()

export default class EnvironmentProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()

        this.state = {
            environment: null,
            integrations: null,
            fetching: false,

            fetch: this.fetch,
            fetchFromS3: this.fetchFromS3
        }

        this.syncer = getChannel('environment')
    }

    componentDidMount() {
        this.fetch()
        this.syncer.onmessage = ({ data }) => void this.setState(data)
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.syncer.close()
    }

    fetch = async (force = false) => {
        const { fetching } = this.state
        if(fetching && !force) {
            return
        }

        if(force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { signal } = this.fetchController

        const { ok, response: configuration } = await outget('/web', { headers, signal })

        if(ok) {
            local.set('environment', configuration.environment)

            const state = {
                ...configuration,
                fetching: false
            }

            this.setState(state, () => this.syncer.postMessage(state))
        }
    }

    fetchFromS3 = path => outget(
        `${this.state.environment.publicAssetsBucketUrl}${path}`
    )

    render() {
        const { environment, fetching } = this.state
        if(fetching || !environment?.apiBaseUrl) {
            return null
        }

        const { children = null } = this.props

        return (
            <EnvironmentContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EnvironmentContext.Provider>
        )
    }
}

export const useEnvironment = () => useContext(EnvironmentContext)