import styled, { css } from 'styled-components'
import { Field as FieldBase, Control as ControlBase } from 'components/form/field/s'

export const Field = styled(FieldBase)`
    min-height: 124px;
`

export const Control = styled(ControlBase)`
    padding: 12px 16px 16px;
    border-radius: 4px;

    ${() => {
        const color = 'var(--huma-color-border-default)'

        return css`
            background-image: repeating-linear-gradient(
                -60deg,
                ${color}, ${color} 4px,
                transparent 4px, transparent 8px,
                ${color} 8px
            ),
            repeating-linear-gradient(
                30deg,
                ${color}, ${color} 4px,
                transparent 4px, transparent 8px,
                ${color} 8px
            ),
            repeating-linear-gradient(
                120deg,
                ${color}, ${color} 4px,
                transparent 4px, transparent 8px,
                ${color} 8px
            ),
            repeating-linear-gradient(
                210deg,
                ${color}, ${color} 4px,
                transparent 4px, transparent 8px,
                ${color} 8px
            );
            background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
            background-position: 0 0, 0 0, 100% 0, 0 100%;
            background-repeat: no-repeat;
        `
    }}

    ${FieldBase} {
        min-height: 68px;
    }
`