import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'contexts/auth'
import { SignupQuestionWrapper, CenteredParagraph, Link } from './s'
import Divider from 'components/divider'

const SignupQuestion = () => {
    const { registrationEnabled } = useAuth()
    if(!registrationEnabled) {
        return null
    }

    return (
        <SignupQuestionWrapper>
            <Divider $size={24} />
            <CenteredParagraph className="compact">
                <span>
                    <FormattedMessage
                        id="login_one_signup_question"
                        defaultMessage="Are you not using Huma yet?" />
                </span>
                <span>
                    <Link
                        to="path:signup"
                        className="constructive">
                        <FormattedMessage
                            id="action_register_company"
                            defaultMessage="Register your company" />
                    </Link>
                </span>
            </CenteredParagraph>
        </SignupQuestionWrapper>
    )
}

export default SignupQuestion
