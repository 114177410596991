import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { tooltipWrapperCss } from 'components/tooltip/s'
import { paragraphCss } from 'components/typography/paragraph'
import { OrderedList, ListItem } from 'components/list'

export const Legend = styled.ul`
    overflow-x: auto;

    display: flex;
    justify-content: center;
    column-gap: 24px;

    margin-bottom: 36px;
    padding: 0;
`

export const Serie = styled.li`
    display: flex;
    align-items: center;
    column-gap: 8px;

    &::before {
        content: "";
        display: block;

        border-radius: 50%;
        width: 8px;
        aspect-ratio: 1 / 1;
        background-color: currentColor;
    }

    &.target::before {
        border: 1px dashed currentColor;
        border-radius: 0;
        background-color: transparent;
    }

    &.group::before {
        border-radius: 0;
        width: 6px;

        rotate: 45deg;
    }
`

export const TooltipContainer = styled.div`
    ${tooltipWrapperCss}

    min-width: 200px;
    padding: 12px 16px;
`

export const TooltipHeading = styled.h3`
    ${paragraphCss}

    font-weight: 700;
`

export const TooltipList = styled(OrderedList)`
    line-height: 24px;
`

export const TooltipItem = styled(ListItem)`
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, auto);
    align-items: center;
    column-gap: 16px;
`

export const TooltipLabel = styled.span`
    display: flex;
    align-items: center;
    column-gap: 8px;

    span {
        color: var(--huma-color-foreground-default);
    }

    &::before {
        content: "";
        display: block;

        border-radius: 50%;
        width: 8px;
        aspect-ratio: 1 / 1;
        background-color: currentColor;
    }

    ${TooltipItem}.target &::before {
        border: 1px dashed currentColor;
        border-radius: 0;
        background-color: transparent;
    }

    ${TooltipItem}.group &::before {
        border-radius: 0;
        width: 6px;

        rotate: 45deg;
    }
`

export const TooltipValue = styled.strong`
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;

    margin-inline-end: -8px;
    width: 40px;

    color: var(--huma-color-foreground-default);

    svg {
        ${svgStroke('small')}
    }

    ${TooltipItem}.success & {
        color: var(--huma-color-foreground-success);
    }

    ${TooltipItem}.warning & {
        color: var(--huma-color-foreground-warning);
    }

    ${TooltipItem}.error & {
        color: var(--huma-color-foreground-error);
    }

    ${TooltipItem}.caution & {
        color: var(--huma-color-foreground-subtle);
    }
`