import React, { Component, createContext, useContext, createRef } from 'react'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays, omit } from 'utilities/object'
import { get, patch } from 'api'

const DeviationCasesContext = createContext()

export default class DeviationCasesProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.uuid = uuid()

        this.state = {
            kases: [],
            statistics: {},
            total: 0,
            filter: props?.filter ?? {},
            filterOverride: props.filterOverride ?? false,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetch: this.fetch,
            fetchStatistics: this.fetchStatistics,
            setCasesFilter: this.setFilter,

            updateCase: this.updateCase,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }

        this.subscription = PubSub.subscribe('deviationCases.refresh', (_, uuid) => {
            if(this.uuid !== uuid) {
                this.setState({
                    kases: [],
                    total: 0,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }, this.fetchDebounced)
            }
        })
    }

    componentDidMount() {
        const {
            fetchOnMount = true,
            fetchStatisticsOnMount = true
        } = this.props

        if(fetchOnMount) {
            this.fetchDebounced()

            if(fetchStatisticsOnMount) {
                this.fetchStatistics()
            }
        }
    }

    componentDidUpdate({ paging }, { filter }) {
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe(this.subscription)
    }

    fetch = async (force = false) => {
        const {
            fetching,
            filter,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = await get({
            path: '/deviation/cases',
            params: {
                ...filter,
                ...nextPaging
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ kases: previousKases }) => {
                const previousKasesWithoutFetched = previousKases.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const kases = [
                    ...previousKasesWithoutFetched,
                    ...response.items
                ]

                return {
                    kases,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && kases.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    fetchStatistics = async () => {
        const { response, ok } = await get({
            path: '/deviation/statistics/unresolved'
        })

        if(ok && response) {
            this.setState({
                statistics: response
            })
        }

        return { response, ok }
    }

    updateCase = async (body = {}, caseId) => {
        const { ok, response } = await patch({
            path: `/deviation/cases/${caseId}`,
            body
        })

        if(ok && response) {
            this.setState(({ kases }) => {
                const index = kases.findIndex(({ id }) => id === caseId)

                const kase = {
                    ...omit(response, 'report'),
                    ...response.report
                }

                return { kases: kases.with(index, kase) }
            })
        }

        return { response, ok }
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                kases: [],
                filter,
                filterOverride: false,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ kases }) => {
        this.flash.current = null

        return {
            kases: [...kases]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <DeviationCasesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </DeviationCasesContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useDeviationCases = () => useContext(DeviationCasesContext)
