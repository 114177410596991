import styled from 'styled-components'
import { bp, belowLaptop, fromDesktop } from 'utilities/styled'
import { Sticky } from 'components/sticky-menu'

export const Handbook = styled.article`
    scroll-behavior: smooth;

    ${fromDesktop`
        padding-top: 16px;
    `}

    ${Sticky} + & {
        ${bp.between('laptop', 'desktop')`
            padding-top: 32px;
        `}

        ${belowLaptop`
            padding-top: 16px;
        `}
    }
`