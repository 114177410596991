import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'handbook'

const Handbook = props => {
    const {
        initialized,
        checkModule
    } = useAccess()

    const access = initialized && checkModule(moduleIdentifier)
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="handbook.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}>
            <FormattedMessage
                id="noun_handbook"
                defaultMessage="Handbook" />
        </MenuItemLink>
    )
}

export default Handbook