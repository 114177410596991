import styled from 'styled-components'
import { Grid } from 'components/grid'
import FeaturesBase from 'components/features'

export const Rows = styled(Grid)`
    grid-auto-rows: auto;
    row-gap: 8px;
    margin-bottom: 24px;
`

export const Features = styled(FeaturesBase)`
    font-size: 14px;
`