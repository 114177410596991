import React, { useState } from 'react'
import { useAppSignal } from 'hooks/app-signal'
import { useLocationQueryIntent } from 'hooks/location-intent'
import FeatureRoadMapModal from 'modals/feature-roadmap'

const FeatureRoadMap = () => {
    const [show, setShow] = useState(false)

    useAppSignal({
        namespace: 'feature-roadmap.show',
        action: () => setShow(true)
    })

    useLocationQueryIntent({
        intent: 'roadmap',
        action: () => setShow(true)
    })

    const dismiss = () => setShow(false)

    return (
        <FeatureRoadMapModal
            show={show}
            dismiss={dismiss} />
    )
}

export default FeatureRoadMap