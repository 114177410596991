import React from 'react'
import RoleProvider from 'contexts/role'
import { useAccess } from 'contexts/access'
import { useRolePermissionVisibility } from 'hooks/role-permission-visibility'
import { useIntl, FormattedMessage } from 'react-intl'
import { capitalize } from 'utilities/string'
import { map } from 'utilities/object'
import { getRoleUrl } from 'utilities/url'
import {
    Modal,
    Header, Heading, Scroller, Realm, RealmHeading,
    Permission, Name, AccessLevel,
    Actions
} from './s'
import Paragraph from 'components/typography/paragraph'
import Link from 'components/link'
import Description from 'pages/settings/pages/roles/role/panes/permissions/content/description'
import Loader from 'components/loader'
import { OrderedList } from 'components/list'
import { FlexChildShrink } from 'components/flex'
import { Plain } from 'components/button'

const PermissionsSummary = ({ role, domain, id, name, onDone, show, ...modal }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const checkRolePermissionVisibility = useRolePermissionVisibility()

    const manageRolesAccess = check('roles:manage')

    const translatedName = !!role?.name ?
        formatMessage({
            id: `role_${role.name}_name`,
            defaultMessage: role.deletable ?
                role.name :
                capitalize(role.name)
        }) :
        name

    return (
        <Modal
            {...modal}
            show={show}>
            <Header>
                <Heading>{translatedName}</Heading>
                {!!manageRolesAccess && (
                    <Paragraph>
                        <Link
                            to={getRoleUrl({ domain, id })}
                            className="constructive">
                            <FormattedMessage
                                id="action_edit_in_system_settings"
                                defaultMessage="Edit in system settings" />
                        </Link>
                    </Paragraph>
                )}
            </Header>
            <Scroller>
                {!!show && (
                    <RoleProvider {...{ domain, id }}>
                        {({ role, permissions }) => (
                            <>
                                {!role && <Loader />}
                                {!!role && map(permissions, (realmPermissions, realm) => (
                                    <Realm key={realm}>
                                        <RealmHeading>
                                            <FormattedMessage
                                                id={`permission_${domain}_${realm}`}
                                                defaultMessage={capitalize(realm)} />
                                        </RealmHeading>
                                        <OrderedList>
                                            {realmPermissions.map(({ key, label, value, options }) => {
                                                const hideRealm = !checkRolePermissionVisibility(key)
                                                if(hideRealm) {
                                                    return null
                                                }

                                                const permissionLabel = label ?? formatMessage({
                                                    id: `permission_${domain}_${realm}_${key}`,
                                                    defaultMessage: capitalize(key)
                                                })

                                                const permissionDescriptionId = `permission_${domain}_${realm}_${key}_description`
                                                const permissionDescription = formatMessage({ id: permissionDescriptionId })
                                                const hasDescription = permissionDescription !== permissionDescriptionId

                                                const salt = `permission-summary:${domain}:${realm}:${key}`

                                                return (
                                                    <Permission
                                                        {...(hasDescription ? { className: 'has-description' } : null)}
                                                        key={salt}>
                                                        <Name className="compact">{permissionLabel}</Name>
                                                        <AccessLevel className="compact">
                                                            <FormattedMessage id={options.find(o => o.value === value).i18n} />
                                                        </AccessLevel>
                                                        {!!hasDescription && (
                                                            <Description
                                                                description={permissionDescription}
                                                                salt={salt} />
                                                        )}
                                                    </Permission>
                                                )
                                            })}
                                        </OrderedList>
                                    </Realm>
                                ))}
                            </>
                        )}
                    </RoleProvider>
                )}
            </Scroller>
            <FlexChildShrink>
                <Actions className="centered">
                    <Plain
                        className="neutral"
                        onClick={onDone}
                        autoFocus={false}>
                        <FormattedMessage
                            id="action_done"
                            defaultMessage="Done" />
                    </Plain>
                </Actions>
            </FlexChildShrink>
        </Modal>
    )
}

export default PermissionsSummary