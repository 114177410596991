import styled from 'styled-components'
import { H2 } from 'components/typography/heading'

export const Heading = H2

export const LoadingContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`