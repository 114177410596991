import { useIntl } from 'react-intl'
import { capitalize } from 'utilities/string'
import { categoryColumns, categoryColumnLabels } from 'pages/competence/constants/category'

export const useColumnsFormatter = () => {
    const { formatMessage } = useIntl()

    return category => Object.keys(categoryColumnLabels)
        .filter(column => categoryColumns[category].includes(column))
        .map(column => ({
            id: column,
            title: formatMessage(categoryColumnLabels[column])
        }))
}

export const useCategoryFormatter = () => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    return type => {
        if(!type) {
            return null
        }

        const {
            category,
            name
        } = type

        if(category === 'language') {
            return capitalize(formatDisplayName(name, { type: 'language' }))
        }

        if(category === 'education') {
            return formatMessage({
                id: `competence_education_degree_${name}`,
                defaultMessage: name
            })
        }

        return name
    }
}
