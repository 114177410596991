import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { usePayment } from 'contexts/payment'
import { sendAppSignal } from 'hooks/app-signal'
import { size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { getCategoryIcon, categoryToI18nMap, nameToQuickGuideMap } from 'utilities/categories'
import {
    ModuleItem,
    IconCell,
    NameCell, Name, NameMeta,
    QuotaCell, Quota,
    ActionCell,
    Features
} from './s'
import Feature from './feature'
import { Plain } from 'components/button'
import Form from 'components/form/controller'
import CheckboxField from 'components/form/field/checkbox'
import Upgrade from 'pages/settings/components/upgrade'
import { Benjamin as CustomUpgradeButton } from 'components/button'
import CustomUpgradeModal from 'modals/feature-upgrade'
import CurrentSubscriptionSummary from 'pages/settings/pages/subscription/main/manage/current-subscription-summary'

const Module = ({
    item,
    name,
    enabled,
    available,
    locked = false,
    quota,
    features = [],
    context = 'list',
    setManaging,
    adminRole,
    userAdminRole,
    salt,
    ...props
}) => {
    const { formatMessage } = useIntl()

    const {
        currentLadder,
        currentBundle,
        featuresByBundleInCurrentTrack,

        toggleModule,
        getBundlesWithModule
    } = useAccess()

    const { account } = usePayment()

    const [showingCustomUpgrade, setShowingCustomUpgrade] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [status, setStatus] = useState({})
    const [showingFeatures, setShowingFeatures] = useState(context === 'list')

    const Icon = getCategoryIcon(name)

    const bundlesWithModule = getBundlesWithModule(name)
    const nextBundle = currentLadder[currentLadder.indexOf(currentBundle) + 1] ?? null

    const hasUpgradeableQuota = nextBundle ?
        getNextBundleWithFeature(
            featuresByBundleInCurrentTrack,
            bundlesWithModule.find(bundle => bundle === nextBundle.bundle),
            item
        ) : null

    const update = async ({ [item]: enabled }) => {
        if(typeof enabled === 'undefined' || updating) {
            return
        }

        setUpdating(true)

        const body = {
            key: item,
            enabled
        }

        setStatus({
            id: enabled ?
                'verb_enabling' :
                'verb_disabling',
            defaultMessage: enabled ?
                'Enabling…' :
                'Disabling…'
        })

        await toggleModule(body)

        setUpdating(false)
    }

    const changeStatus = () => {
        if(enabled) {
            setStatus({
                id: locked ?
                    'module_status_locked' :
                    'module_status_enabled',
                defaultMessage: locked ?
                    'Always included' :
                    'Enabled'
            })
        } else {
            setStatus({
                id: 'module_status_disabled',
                defaultMessage: 'Disabled'
            })
        }
    }

    useEffect(() => {
        if(!updating) {
            changeStatus()
        }
    }, [updating])

    if(!size(status)) {
        return null
    }

    const customUpgradeButton = (!available && !!account?.subscription && !account?.subscription?.public) ? (
        <CustomUpgradeButton onClick={() => setShowingCustomUpgrade(true)}>
            <FormattedMessage
                id="payment_plan_cta_upgrade"
                defaultMessage="Upgrade" />
        </CustomUpgradeButton>
    ) : null

    const className = cls([
        props?.className,
        context,
        !!features?.length && 'has-features',
        quota && 'has-quota',
        (!!available && !enabled) && 'disabled'
    ])

    const showReadMore = context === 'list' && nameToQuickGuideMap(name) !== null
    const showShowFeatures = context === 'quick-guide' && !!features?.length
    const nameMeta = available || showReadMore || showShowFeatures

    return (
        <>
            <ModuleItem className={className}>
                <IconCell>
                    {Icon && <Icon size={24} />}
                </IconCell>
                <NameCell {...(!nameMeta ? { className: 'no-meta' } : null)}>
                    <Name {...(context === 'quick-guide' ? { className: 'heading' } : null)}>
                        <FormattedMessage
                            id={categoryToI18nMap[name] ?? `noun_${name.replaceAll('-', '_')}`}
                            defaultMessage={name} />
                    </Name>
                    {nameMeta && (
                        <NameMeta>
                            {(!!available) && (
                                <span>
                                    <FormattedMessage {...status} />
                                </span>
                            )}
                            {showReadMore && (
                                <Plain
                                    onClick={() => sendAppSignal('quick-guide.show', nameToQuickGuideMap(name))}
                                    className="constructive compact small">
                                    <FormattedMessage
                                        id="action_read_more"
                                        defaultMessage="Read more" />
                                </Plain>
                            )}
                            {showShowFeatures && (
                                <Plain
                                    onClick={() => setShowingFeatures(!showingFeatures)}
                                    className="constructive compact small">
                                    <FormattedMessage
                                        id={showingFeatures ?
                                            'action_hide_features' :
                                            'action_show_features'
                                        }
                                        defaultMessage={showingFeatures ?
                                            'Hide features' :
                                            'Show features'
                                        } />
                                </Plain>
                            )}
                        </NameMeta>
                    )}
                </NameCell>
                {quota && (
                    <QuotaCell>
                        <Quota className="compact">
                            <FormattedMessage
                                id={quota?.limit ?
                                    `module_${name}_quota_included` :
                                    `module_${name}_quota_unlimited`
                                }
                                defaultMessage={quota?.limit ?
                                    '{total, plural, =0 {} =1 {} other {{total} items included.}}' :
                                    'Unlimited items included.'
                                }
                                values={{ total: quota?.limit }} />
                        </Quota>
                        {(hasUpgradeableQuota && !!account?.subscription?.public) && (
                            <Upgrade
                                module={item.replace('module:', '')}
                                setManaging={setManaging}
                                className="constructive compact"
                                size="small"
                                as={Plain} />
                        )}
                    </QuotaCell>
                )}
                <ActionCell>
                    {(!available && !!account?.subscription?.public) && (
                        <Upgrade
                            module={item.replace('module:', '')}
                            setManaging={setManaging}
                            useIcon={true} />
                    )}
                    {customUpgradeButton}
                    {!!available && (
                        <Form
                            submitOnChange={true}
                            onSubmit={update}>
                            <CheckboxField
                                salt={`${salt}:${context}`}
                                className="compact"
                                label={false}
                                name={item}
                                field={{
                                    value: enabled,
                                    include: 'always'
                                }}
                                loading={updating}
                                enabled={!locked && !updating}
                                interaction="switch" />
                        </Form>
                    )}
                </ActionCell>
                {!!features?.length && (
                    <Features {...(!showingFeatures ? { className: 'hidden' } : null)}>
                        {features
                            // .filter(({ hidden }) => !hidden)
                            .map(feature => (
                                <Feature
                                    item={feature}
                                    context={context}
                                    available={available}
                                    enabled={enabled}
                                    adminRole={adminRole}
                                    userAdminRole={userAdminRole}
                                    setManaging={setManaging}
                                    customUpgradeButton={customUpgradeButton}
                                    salt={`${salt}:${feature.key}`}
                                    key={feature.key} />
                            ))}
                    </Features>
                )}
            </ModuleItem>
            <CustomUpgradeModal
                show={!!showingCustomUpgrade}
                dismiss={() => setShowingCustomUpgrade(false)}
                module={name}
                heading={formatMessage({
                    id: 'payment_plan_heading_upgrade',
                    defaultMessage: 'Upgrade'
                })}
                salt={salt}>
                <CurrentSubscriptionSummary salt="custom:upgrade" />
            </CustomUpgradeModal>
        </>
    )
}

export const getNextBundleWithFeature = (featuresByBundleInCurrentTrack, bundle, key) => {
    return featuresByBundleInCurrentTrack[bundle]?.find(feature => feature.key === key)
}

export default Module