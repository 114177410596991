import { isofy } from 'utilities/date-time'
import { startOfDay, isAfter, isToday, endOfDay } from 'date-fns'
import PubSub from 'pubsub-js'

export const isPast = ({ date }, now) => {
    date = isofy(date)

    const today = isToday(date)
    const upcoming = isFuture({ date }, now)

    return !today && !upcoming
}

export const isFuture = ({ date }, now) => {
    date = isofy(date)
    return isAfter(endOfDay(date), now)
}

export const enrichEvent = event => {
    let {
        date,
        tasks
    } = event

    date = date ?
        startOfDay(isofy(date)) :
        null

    tasks = tasks ?? []

    return {
        ...event,
        date,
        tasks
    }
}

export const refreshMeetingEvents = () => PubSub.publish('meetingEvents.refresh')