import React from 'react'
import { useCompetenceProfile } from 'contexts/competence-profile'
import { Wrapper } from './s'
import Hero from './hero'
import Targets from './targets'
import Actions from './actions'

const ViewCompetenceProfile = ({ actions = {}, dismiss, salt }) => {
    const { profile } = useCompetenceProfile()

    return (
        <Wrapper>
            <Hero
                profile={profile}
                dismiss={dismiss} />
            <Targets targets={profile?.targets} />
            <Actions
                actions={actions}
                context={profile}
                dismiss={dismiss}
                salt={salt} />
        </Wrapper>
    )
}

export default ViewCompetenceProfile
