import styled from 'styled-components'
import { Grid } from 'components/grid'

export const Entries = styled(Grid)`
    grid-auto-flow: row;
    grid-auto-rows: auto;

    &:not(:last-of-type) {
        margin-bottom: 24px;
    }
`