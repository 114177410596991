import React, { useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMatch, useNavigate } from 'react-router-dom'
import { usePayment } from 'contexts/payment'
import { openChat } from 'hooks/voiceflow'
import paths from 'app/paths'
import { validate } from 'modals/address-filler'
import AddressField from 'components/form/field/address'
import { InlineButton } from 'components/button'

const AccountAddress = ({ change, swap, from, trial, dismiss, salt }) => {
    const { formatMessage } = useIntl()
    const { account } = usePayment()

    const [address, setAddress] = useState(account?.address ?? {})

    // Use the persisted address on account here so that
    // it doesn’t react to interaction with the address field:
    const [addressFilledInitially] = useState(validate(address))

    const field = useRef()

    const navigate = useNavigate()
    const atSubscriptionPage = useMatch(paths.settings.subscription.base)

    if(addressFilledInitially) {
        return null
    }

    const changesRequiringAccountAddress = ['purchase', 'upgrade', 'downgrade', 'modify']
    if(!changesRequiringAccountAddress.includes(change) && !swap) {
        return null
    }

    const error = !validate(address) ?
        formatMessage({
            id: 'address_error_parts_missing',
            defaultMessage: 'Parts of the address are missing'
        }) :
        null

    const countryDisabled = from.free || trial

    return (
        <AddressField
            salt={salt}
            label={formatMessage({
                id: 'noun_address_billing',
                defaultMessage: 'Billing address'
            })}
            name="address"
            field={{
                value: address,
                required: true,
                include: 'always',
                unsettable: false
            }}
            picker={{
                fields: {
                    country: {
                        disabled: countryDisabled ? {
                            type: 'info',
                            message: formatMessage({
                                id: 'payment_subscription_payment_info_country_locked_no_subscription',
                                defaultMessage: 'Changing the billing address’ country could cause the purchase to be billed in a different currency than the one you’ve seen our prices listed in. It can still be changed, but it must be <link>done as a separate action</link>.'
                            }, {
                                link: chunks => (
                                    <InlineButton
                                        className="constructive"
                                        onClick={() => {
                                            field?.current?.click?.()
                                            dismiss?.()

                                            navigate(paths.settings.subscription.base, {
                                                replace: !!atSubscriptionPage,
                                                state: { intent: 'edit-account' }
                                            })
                                        }}>
                                        {chunks}
                                    </InlineButton>
                                )
                            })
                        } : null
                    }
                },
                message: !countryDisabled ? {
                    type: 'info',
                    message: formatMessage({
                        id: 'payment_subscription_payment_info_country_locked_has_subscription',
                        defaultMessage: 'Changing the country will not cause the currency to change. If this is something you want, we’ll be happy to help.'
                    }),
                    children: (
                        <InlineButton
                            className="underline-reversed"
                            onClick={() => {
                                field?.current?.click?.()
                                dismiss?.()
                                openChat()
                            }}>
                            <FormattedMessage
                                id="action_open_support_chat"
                                defaultMesage="Open support chat" />
                        </InlineButton>
                    )
                } : null
            }}
            error={error}
            onChange={({ address }) => setAddress(address)}
            ref={field} />
    )
}

export default AccountAddress