import React from 'react'
import { useCompetenceProfileStatusTextFormatter } from './'
import { cls } from 'utilities/dom'
import { StatusLabel } from './s'

const CompetenceProfileStatusLabel = ({ status, className }) => {
    const formatter = useCompetenceProfileStatusTextFormatter()
    const text = formatter(status)

    if(!text) {
        return null
    }

    const statusClassName = {
        public: 'active',
        private: 'neutral'
    }[status] ?? 'neutral'

    className = cls([
        statusClassName,
        className
    ])

    return (
        <StatusLabel className={className}>
            {text}
        </StatusLabel>
    )
}

export default CompetenceProfileStatusLabel