import React, { useState, useEffect, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { get } from 'api'
import { useTasks } from 'contexts/tasks'
import Type from './type'
import { HeroActions, Heading, Container } from 'pages/dashboard/s'
import Tasks from '../tasks'

const CompactTodos = ({
    access,
    hasFetchedDeviationCases, totalDeviationCases,
    hasFetchedWhistleblowingCases, totalWhistleblowingCases,
    hasFetchedSalaryRevisionGroups, totalSalaryRevisionGroups,
    hasFetchedAbsence, totalAbsence,
    hasFetchedMeetings, totalMeetings,
    hasFetchedDocuments, totalDocuments
}) => {
    const [totalUnstartedUsers, setTotalUnstartedUsers] = useState(0)
    const [hasFetchedUnstartedUsers, setHasFetchedUnstartedUsers] = useState(false)

    const { total: totalTasks } = useTasks()

    useEffect(() => {
        if(!!access.users) {
            const getUsers = async () => {
                const { ok, response } = await get({ path: '/users/invite' })

                const unstartedUsers = [
                    ...(response?.usersToInvite ?? []),
                    ...(response?.usersRecentlyInvited ?? [])
                ]

                if(ok && !!unstartedUsers?.length) {
                    setTotalUnstartedUsers(unstartedUsers.length)
                }
            }

            getUsers()
        }

        setHasFetchedUnstartedUsers(true)
    }, [])

    if(
        (hasFetchedDeviationCases && !totalDeviationCases) &&
        (hasFetchedWhistleblowingCases && !totalWhistleblowingCases) &&
        (hasFetchedSalaryRevisionGroups && !totalSalaryRevisionGroups) &&
        (hasFetchedAbsence && !totalAbsence) &&
        (hasFetchedUnstartedUsers && !totalUnstartedUsers) &&
        (hasFetchedMeetings && !totalMeetings) &&
        (hasFetchedDocuments && !totalDocuments)
    ) {
        return <Tasks />
    }

    return (
        <>
            <HeroActions>
                <Heading>
                    <FormattedMessage
                        id="dashboard_todo_heading"
                        defaultMessage="To-do list" />
                </Heading>
            </HeroActions>
            <Container>
                <Type
                    type="deviation"
                    hasFetched={hasFetchedDeviationCases}
                    total={totalDeviationCases} />
                <Type
                    type="whistleblowing"
                    hasFetched={hasFetchedWhistleblowingCases}
                    total={totalWhistleblowingCases} />
                <Type
                    type="absence"
                    hasFetched={hasFetchedAbsence}
                    total={totalAbsence} />
                <Type
                    type="revisionGroups"
                    hasFetched={hasFetchedSalaryRevisionGroups}
                    total={totalSalaryRevisionGroups} />
                <Type
                    type="meetings"
                    hasFetched={hasFetchedMeetings}
                    total={totalMeetings} />
                <Type
                    type="documents"
                    hasFetched={hasFetchedDocuments}
                    total={totalDocuments} />
                <Type
                    type="invite"
                    hasFetched={hasFetchedUnstartedUsers}
                    total={totalUnstartedUsers} />
            </Container>
            {!!totalTasks && <Tasks context="integrated" />}
        </>
    )
}

export default CompactTodos
