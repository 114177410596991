import styled from 'styled-components'
import { CaptionSmall } from 'components/typography/caption'
import { Columns } from 'components/flex'
import { Plain } from 'components/button'
import LinkBase from 'components/link'

export const Caption = styled(CaptionSmall)`
    margin-bottom: 16px;
`

export const ShowAll = styled(Columns)`
    column-gap: 4px;

    margin-top: 24px;
`

export const ShowAllCount = styled.span`
    font-size: 14px;
    line-height: 14px;
    color: var(--huma-color-foreground-subtle);
`

export const ShowAllButton = styled(Plain)`
    border: none;
    height: auto;

    font-size: 14px;
    line-height: 14px;
`

export const Link = styled(LinkBase)`
    display: inline-block;

    margin-top: 16px;

    line-height: 24px;
`
