import React from 'react'
import { useIntl } from 'react-intl'
import { useProcesses } from 'contexts/processes'
import { useOrganization } from 'contexts/organization'
import { basicApplied, advancedApplied } from 'components/filter/utilities'
import { compact } from 'utilities/array'
import Filter from 'components/filter'

const ProcessesFilter = props => {
    const { formatMessage } = useIntl()

    const {
        type,

        filter,
        processes: items,
        fetching,
        hasFetched,

        setProcessesFilter: setFilter
    } = useProcesses()

    const { organization } = useOrganization()

    const basicFields = compact([
        ['onboarding', 'offboarding'].includes(type) && {
            type: 'search',
            name: 'search',
            controlProps: {
                placeholder: formatMessage({
                    id: 'employee_action_find',
                    defaultMessage: 'Find employee'
                })
            }
        }
    ])

    const advancedFields = compact([
        (type === 'process') && {
            type: 'shareOrEveryone',
            name: 'concernsId',
            label: formatMessage({
                id: 'label_regarding',
                defaultMessage: 'Regarding'
            }),
            empty: [],
            types: ['team', 'location', 'user'],
            shareLabel: formatMessage({
                id: 'label_group_user_specific',
                defaultMessage: 'A specific group or person'
            }),
            everyoneLabel: [
                formatMessage({
                    id: 'noun_no_one',
                    defaultMessage: 'No one'
                }),
                `(${organization?.name})`
            ].join(' '),
            picker: { single: true }
        },
        ['onboarding', 'offboarding'].includes(type) && {
            type: 'checkboxes',
            name: 'teams',
            label: formatMessage({
                id: 'person_label_teams',
                defaultMessage: 'Teams'
            }),
            query: { path: '/teams' },
            empty: [],
            field: {
                defaultValue: []
            }
        },
        ['onboarding', 'offboarding'].includes(type) && {
            type: 'checkboxes',
            name: 'locations',
            label: formatMessage({
                id: 'person_label_locations',
                defaultMessage: 'Locations'
            }),
            query: { path: '/locations' },
            empty: [],
            field: {
                defaultValue: []
            }
        }
    ])

    return (
        <Filter
            {...props}
            filter={filter}
            setFilter={setFilter}
            basicFields={basicFields}
            advancedFields={advancedFields}
            items={items}
            fetching={fetching}
            hasFetched={hasFetched} />
    )
}

const basicNames = ['search']
const advancedNames = ['concernsId', 'teams', 'locations']

export const areBasicFiltersApplied = basicApplied(basicNames)
export const areAdvancedFiltersApplied = advancedApplied(advancedNames)

export const areFiltersApplied = filter => (
    areBasicFiltersApplied(filter) ||
    areAdvancedFiltersApplied(filter)
)

export default ProcessesFilter