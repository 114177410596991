import React, { useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePageView } from 'hooks/page-view'
import { useEnvironment } from 'contexts/environment'
import { useSignup } from 'contexts/signup'
import { useTimeout } from 'hooks/timeout'
import { Rows, FlexChildShrink } from 'components/flex'
import { Step, HeadingColumns, Heading, Subheading, Actions, LoaderWrap } from '../s'
import ConstructingGif from 'static/gfx/constructing.gif'
import ConstructingDark from 'static/gfx/constructing-dark.jpg'
import { CaptionSmall as Progress } from 'components/typography/caption'
import Form from 'components/form/controller'
import ReCAPTCHA from 'react-google-recaptcha'
import { LoaderHeading, LoaderImage, Retry } from './s'
import VerificationCodeField from 'components/form/field/verification-code'
import Link from 'components/link'
import { Plain, ButtonSubmit, BackButton } from 'components/button'
import { size } from 'utilities/object'

const imgLight = new Image()
const imgDark = new Image()
imgLight.src = ConstructingGif
imgDark.src = ConstructingDark

const TwoValidate = ({ show, ...props }) => {
    const { formatMessage } = useIntl()

    usePageView('signup_validate', [show])

    const { integrations } = useEnvironment()
    const recaptcha = useRef()

    const [loadingImageLoaded, setLoadingImageLoaded] = useState(false)
    const [loadingDarkImageLoaded, setLoadingDarkImageLoaded] = useState(false)

    const [revealRetry, setRevealRetry] = useState(false)
    useTimeout(() => setRevealRetry(true), 10000)

    const {
        otp,
        validatingOtp,

        validateOtp,
        goToPreviousStep
    } = useSignup()

    const salt = 'signup:2:validate'

    return (
        <Step
            {...props}
            show={show}>
            <Form
                layout="vertical"
                onSubmit={body => validateOtp(body, recaptcha)}>
                {({ errors, trigger }) => (
                    <Rows>
                        {(!!integrations?.googleRecaptchaEnabled && !!integrations?.googleRecaptchaSiteKey) && (
                            <ReCAPTCHA
                                sitekey={integrations.googleRecaptchaSiteKey}
                                size="invisible"
                                ref={recaptcha} />
                        )}
                        {!!validatingOtp && (
                            <LoaderWrap>
                                <LoaderHeading className="preserve-line-breaks">
                                    <FormattedMessage
                                        id="signup_three_loading"
                                        defaultMessage="We are setting up your\nHuma workspace" />
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                </LoaderHeading>
                                <LoaderImage
                                    src={ConstructingGif}
                                    className="light"
                                    onLoad={() => setLoadingImageLoaded(true)}
                                    onError={() => setLoadingImageLoaded(true)}
                                    animate={loadingImageLoaded ? 'in' : 'out'} />
                                <LoaderImage
                                    src={ConstructingDark}
                                    className="dark"
                                    onLoad={() => setLoadingDarkImageLoaded(true)}
                                    onError={() => setLoadingDarkImageLoaded(true)}
                                    animate={loadingDarkImageLoaded ? 'in' : 'out'} />
                            </LoaderWrap>
                        )}
                        {(!!size(otp) && !validatingOtp) && (
                            <FlexChildShrink>
                                <HeadingColumns>
                                    <Heading>
                                        <FormattedMessage
                                            id="signup_three_heading_email"
                                            defaultMessage="Check your email" />
                                    </Heading>
                                    <Progress className="compact">
                                        <FormattedMessage
                                            id="step_of_total"
                                            defaultMessage="Step {step} of {total}"
                                            values={{
                                                step: 2,
                                                total: 2
                                            }} />
                                    </Progress>
                                </HeadingColumns>
                                <Subheading>
                                    <FormattedMessage
                                        id="signup_three_caption"
                                        defaultMessage={`Enter the code we just sent to {recipient}.`}
                                        values={{ recipient: <strong>{otp.email}</strong> }} />
                                </Subheading>
                                <VerificationCodeField
                                    salt={salt}
                                    label={false}
                                    name="code"
                                    controlProps={{ autoFocus: true }} />
                                <Retry {...(revealRetry ? { className: 'reveal' } : null)}>
                                    <FormattedMessage
                                        id="signup_three_retry"
                                        defaultMessage="Didn’t receive a code? {link}."
                                        values={{
                                            link: (
                                                <Link
                                                    onClick={goToPreviousStep}
                                                    className="constructive">
                                                    <FormattedMessage
                                                        id="signup_three_retry_reenter_email"
                                                        defaultMessage="Please re-enter your email address" />
                                                </Link>
                                            )
                                        }} />
                                </Retry>
                            </FlexChildShrink>
                        )}
                        {!!size(otp) && (
                            <FlexChildShrink>
                                <Actions className={!validatingOtp ? 'spread' : 'centered'}>
                                    {!!validatingOtp && (
                                        <Plain
                                            onClick={goToPreviousStep}
                                            className="neutral">
                                            <FormattedMessage
                                                id="action_cancel"
                                                defaultMessage="Cancel" />
                                        </Plain>
                                    )}
                                    {/* Can’t be two buttons in a fragment, due to Actions making divs for each child */}
                                    {!validatingOtp && (
                                        <BackButton
                                            onClick={goToPreviousStep}
                                            text={formatMessage({
                                                id: 'action_go_back',
                                                defaultMessage: 'Go back'
                                            })} />
                                    )}
                                    {!validatingOtp && (
                                        <ButtonSubmit
                                            className="constructive"
                                            disabled={!!size(errors) || validatingOtp}
                                            ref={trigger}>
                                            <FormattedMessage
                                                id="action_login"
                                                defaultMessage="Log in" />
                                        </ButtonSubmit>
                                    )}
                                </Actions>
                            </FlexChildShrink>
                        )}
                    </Rows>
                )}
            </Form>
        </Step>
    )
}

export default TwoValidate
