import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import ToggleSwitchBase from 'components/toggle-switch'
import { FlexChildShrink, FlexChildGrow } from 'components/flex'

export const Header = styled(FlexChildShrink)`
    margin-bottom: 24px;

    ${belowTablet`
        margin-bottom: 16px;
    `}
`

export const ToggleSwitch = styled(ToggleSwitchBase)`
    margin-bottom: 24px;
`

export const Content = styled(FlexChildGrow)`
    width: calc(100% + 64px);
    padding-inline: 32px;
    position: relative;
    left: -32px;
    overflow-y: auto;

    ${belowTablet`
        width: calc(100% + 48px);
        padding-inline: 24px;
        left: -24px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    `}
`

export const Footer = FlexChildShrink