import React, { Component, createContext, useContext } from 'react'
import { get } from 'api'
import { intersectionObserver } from 'utilities/dom'
import { local } from 'utilities/storage'
import { getUrlFromPath } from 'utilities/url'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays, pick } from 'utilities/object'

export const PeopleContext = createContext()

export default class PeopleProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults(props.sortByDefault)
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.state = {
            people: props.people ?? [],
            total: 0,
            endpoint: props.endpoint ?? {},
            fixed: !!props.people,
            filter: props.filter ?? {},
            filterOverride: props.filterOverride ?? false,
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            fetchPeople: this.fetchDebounced,
            setPeopleFilter: this.setFilter,
            toggleSorting: this.toggleSorting,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ endpoint, paging, people }, { filter, sorting }) {
        const endpointChanged = !isEqual(endpoint, this.props.endpoint)
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(endpointChanged) {
            state.endpoint = this.props.endpoint
        }

        if(pagingChanged || endpointChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        if(people !== this.props.people) {
            state.people = this.props.people
            state.fixed = !!this.props.people
        }

        this.setState(size(state) ? state : null, () => {
            if(endpointChanged || filterChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
    }

    fetch = async (force = false) => {
        const {
            endpoint,
            fixed,
            fetching,
            filter,
            sorting,
            paging,
            eternal,
            autoFetch,
            hasFetched
        } = this.state

        if((fixed || fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const pathTemplate = {
            absencePolicyAssignees: '/absence/policies/:id/users',
            absenceTypeUnassignedUsers: '/absence/types/:id/users/unassigned'
        }[endpoint?.type] ?? '/users'

        const path = getUrlFromPath(pathTemplate)(endpoint?.params)

        const { ok, response } = await get({
            path,
            params: {
                ...filter,
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ people: previousPeople, paging }) => {
                const people = [
                    ...previousPeople,
                    ...response.items
                ]

                return {
                    people,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        hasNextPage: response.items.length && people.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: this.props.autoFetch || (!!previousPeople.length && hasFetched),
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    setFilter = ({ filter = {}, sorting = {} }) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        sorting = {
            ...sortingDefaults(this.props.sortByDefault),
            ...sorting
        }

        this.setState(({ filter: previousFilter, sorting: previousSorting }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            const sortingChanged = !isEqual(sorting, previousSorting)

            if(!filterChanged && !sortingChanged) {
                return null
            }

            const state = {
                people: [],
                filterOverride: false,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }

            if(filterChanged) {
                state.filter = nextFilter
            }

            if(sortingChanged) {
                state.sorting = sorting
                !!this.sortCacheKey && local.set(this.sortCacheKey, sorting)
            }

            return state
        })
    }

    toggleSorting = field => {
        const sortingOptions = getSortingOptions()

        if(field in sortingOptions) {
            this.setState(({ sorting }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc' : 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    people: [],
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }
            })
        }
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal || loading || !autoFetch || !paging?.hasNextPage) {
            return
        }

        this.fetchDebounced()
    }

    render() {
        const { children = null } = this.props

        return (
            <PeopleContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </PeopleContext.Provider>
        )
    }
}

const getSortingOptions = () => ({
    name: 'asc'
})

const sortingDefaults = (sortByDefault = 'name') => {
    const sortingOptions = pick(getSortingOptions(), sortByDefault)

    return {
        by: Object.keys(sortingOptions)[0],
        direction: Object.values(sortingOptions)[0]
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const usePeople = () => useContext(PeopleContext)