import React, { useState, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { useNavigate, useMatch } from 'react-router-dom'
import { sendAppSignal } from 'hooks/app-signal'
import paths from 'app/paths'
import { saveAs } from 'file-saver'
import { cls } from 'utilities/dom'
import { pick } from 'utilities/object'
import { Menu, MenuColumns, MenuColumn, ExitPreviewButton, Status } from './s'
import { Sticky } from 'components/sticky-menu'
import ContextMenu from 'widgets/context-menu'
import SearchFilter from 'pages/handbook/components/search-filter'
import { Scrollable as Modal } from 'modals/generic'
import BulkTopics from 'pages/handbook/modals/topics-bulk'
import EditHandbookCategory from 'pages/handbook/modals/category/edit'
import { Button, Plain } from 'components/button'
import PublishHandbook from 'pages/handbook/modals/publish'
import EditContacts from 'pages/handbook/modals/edit-contacts'
import EditOrder from 'pages/handbook/modals/edit-order'
import Notify from 'pages/handbook/modals/notify'
import Successful from 'pages/handbook/modals/successful'
import PreviewHandbook from 'pages/handbook/modals/preview'
import DownloadHandbookAsPdf from 'pages/handbook/modals/download-as-pdf'
import PreviewingStatus from './previewing-status'
import {
    Share2 as Share, Tag, Link, Edit, Download,
    DownloadCloud, UploadCloud,
    Bell as Notification,
    Eye, EyeOff,
    Trash2 as Delete,
    Plus
} from 'styled-icons/feather'
import { DragIndicator as Order } from 'styled-icons/material'
import { Language } from 'styled-icons/ionicons-outline'

const HandbookStickyMenu = ({ salt }) => {
    const { formatMessage } = useIntl()

    const {
        handbook,
        filter,
        generatingPdf,

        hasFetched,
        fetchError,

        setHandbookFilter,
        updateHandbook,
        removeHandbook,
        removeCategory
    } = useHandbook()

    const {
        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const manageAccess = check('handbook:manage')

    const exportAvailable = checkFeature('handbook-export')
    const exportUpgradable = checkUpgradable({ feature: 'handbook-export' })

    const navigate = useNavigate()

    const homeMatch = useMatch({
        path: paths.handbook.base,
        exact: true
    })

    const setupMatch = useMatch({
        path: paths.handbook.setup,
        exact: true
    })

    const categoryMatch = useMatch({
        path: paths.handbook.category,
        exact: true
    })

    const atOtherPage = Object.keys(paths.handbook).includes(categoryMatch?.params.id)
    const atHomePage = !!homeMatch
    const atSetupPage = !!setupMatch
    const atCategoryPage = !!categoryMatch && !atOtherPage

    const {
        status,
        categories = []
    } = handbook ?? {}

    const [publishing, setPublishing] = useState(false)
    const [editingBulkTopics, setEditingBulkTopics] = useState(null)
    const [editingCategory, setEditingCategory] = useState(null)
    const [editingContacts, setEditingContacts] = useState(false)
    const [editingOrder, setEditingOrder] = useState(false)
    const [successfulMessage, setSuccessfulMessage] = useState(null)
    const [showingNotify, setShowingNotify] = useState(null)
    const [previewing, setPreviewing] = useState(false)
    const [downloadingAsPdf, setDownloadingAsPdf] = useState(false)

    const downloadJSON = () => {
        // We keep ids due to our internal template maintenance workflow
        const exportable = {
            type: 'handbook',
            ...pick(handbook, 'id', 'name', 'categories')
        }

        exportable.categories = exportable.categories.map(category => {
            if(!category.links?.length) {
                delete category.links
            }

            return {
                ...category,
                topics: category.topics.map(topic => {
                    delete topic.highlight

                    if(!topic.tags?.length) {
                        delete topic.tags
                    }

                    if(!topic.links?.length) {
                        delete topic.links
                    }

                    if(!topic.shares?.length) {
                        delete topic.shares
                    } else {
                        topic.shares = topic.shares.map(share => pick(share, 'id', 'type'))
                    }

                    return topic
                })
            }
        })

        saveAs(new Blob(
            [JSON.stringify(exportable, null, 4)],
            { type: 'text/json' }
        ), 'handbook.json')
    }

    const noPublishedTopics = categories?.every(category => category.topics.every(({ status }) => status !== 'published'))

    const previewAction = useCallback(() => {
        if(!manageAccess || filter?.viewAs) {
            return null
        }

        return {
            salt: `${salt}:preview`,
            icon: <Eye size={24} />,
            label: formatMessage({
                id: 'action_preview',
                defaultMessage: 'Preview'
            }),
            effect: 'neutral',
            className: 'hide-from-phone',
            onClick: () => setPreviewing(true)
        }
    }, [manageAccess, filter?.viewAs])

    const publishAction = useCallback(() => {
        if(!(manageAccess && !filter?.viewAs && status === 'draft' && atHomePage && !noPublishedTopics)) {
            return null
        }

        return {
            salt: `${salt}:publish`,
            icon: <Share size={24} />,
            label: formatMessage({
                id: 'action_publish_options',
                defaultMessage: 'Publish…'
            }),
            effect: 'neutral',
            className: 'hide-from-phone',
            onClick: () => setPublishing(true)
        }
    }, [manageAccess, filter?.viewAs, status, atHomePage, noPublishedTopics])

    const notifyAction = useCallback(() => {
        if(status !== 'published' || (!atHomePage && !atCategoryPage)) {
            return null
        }

        return {
            salt: `${salt}:notify`,
            icon: <Notification size={24} />,
            label: formatMessage({
                id: 'notification_toggle_label',
                defaultMessage: 'Send notification'
            }),
            effect: 'neutral',
            onClick: () => setShowingNotify({
                what: atHomePage ? 'handbook' : 'category',
                id: atHomePage ? handbook.id : categoryMatch?.params.id
            })
        }
    }, [atHomePage, status])

    const addCategoryAction = useCallback(() => {
        if(!atHomePage || !manageAccess) {
            return null
        }

        return {
            salt: `${salt}:add-category`,
            icon: <Plus size={24} />,
            label: formatMessage({
                id: 'handbooks_category_action_add',
                defaultMessage: 'Add category'
            }),
            effect: 'neutral',
            onClick: () => sendAppSignal('handbook:add-category')
        }
    }, [atHomePage, manageAccess])

    const addFromTemplateAction = useCallback(() => {
        if(!atHomePage || !manageAccess) {
            return null
        }

        return {
            salt: `${salt}:add-from-template`,
            icon: <Plus size={24} />,
            label: formatMessage({
                id: 'action_add_from_template',
                defaultMessage: 'Add from template'
            }),
            effect: 'neutral',
            onClick: () => sendAppSignal('handbook:add-from-template')
        }
    }, [atHomePage, manageAccess])

    const changeTopicsLanguageAction = useCallback(() => {
        if(!atCategoryPage) {
            return null
        }

        const category = categories.find(({ id }) => id === categoryMatch?.params.id)
        if(!category?.topics?.length) {
            return null
        }

        return {
            salt: `${salt}:change-topics-language`,
            icon: <Language size={24} />,
            label: formatMessage({
                id: 'handbooks_action_change_topics_language',
                defaultMessage: 'Change language for all topics'
            }),
            effect: 'neutral',
            onClick: () => setEditingBulkTopics({
                category,
                what: 'language'
            })
        }
    }, [atCategoryPage, categoryMatch])

    const changeTopicsStatusAction = useCallback(() => {
        if(!atCategoryPage) {
            return null
        }

        const category = categories.find(({ id }) => id === categoryMatch?.params.id)
        if(!category?.topics?.length) {
            return null
        }

        return {
            salt: `${salt}:change-topics-status`,
            icon: <Eye size={24} />,
            label: formatMessage({
                id: 'handbooks_action_change_topics_status',
                defaultMessage: 'Change status for all topics'
            }),
            effect: 'neutral',
            onClick: () => setEditingBulkTopics({
                category,
                what: 'status'
            })
        }
    }, [atCategoryPage, categoryMatch])

    const changeTopicsSharesAction = useCallback(() => {
        if(!atCategoryPage) {
            return null
        }

        const category = categories.find(({ id }) => id === categoryMatch?.params.id)
        if(!category?.topics?.length) {
            return null
        }

        return {
            salt: `${salt}:change-topics-shares`,
            icon: <Share size={24} />,
            label: formatMessage({
                id: 'handbooks_action_change_topics_shares',
                defaultMessage: 'Change access for all topics'
            }),
            effect: 'neutral',
            onClick: () => setEditingBulkTopics({
                category,
                what: 'shares'
            })
        }
    }, [atCategoryPage, categoryMatch])

    const addTopicsTagsAction = useCallback(() => {
        if(!atCategoryPage) {
            return null
        }

        const category = categories.find(({ id }) => id === categoryMatch?.params.id)
        if(!category?.topics?.length) {
            return null
        }

        return {
            salt: `${salt}:add-topics-tags`,
            icon: <Tag size={24} />,
            label: formatMessage({
                id: 'handbooks_action_add_topics_tags',
                defaultMessage: 'Add tags to all topics'
            }),
            effect: 'neutral',
            onClick: () => setEditingBulkTopics({
                category,
                what: 'tags'
            })
        }
    }, [atCategoryPage, categoryMatch])

    const editCategoryAction = useCallback(() => {
        if(!atCategoryPage || !manageAccess) {
            return null
        }

        const category = categories.find(({ id }) => id === categoryMatch?.params.id)
        if(!category) {
            return null
        }

        return {
            salt: `${salt}:edit`,
            icon: <Edit size={24} />,
            label: formatMessage({
                id: 'handbooks_category_action_edit',
                defaultMessage: 'Edit category'
            }),
            effect: 'neutral',
            onClick: () => setEditingCategory(category)
        }
    }, [atCategoryPage, manageAccess, categoryMatch])

    const copyLinkAction = useCallback(() => {
        if(!atCategoryPage) {
            return null
        }

        const clipboardText = `${global.location.origin}${global.location.pathname}`

        return {
            salt: `${salt}:copy-link`,
            icon: <Link size={24} />,
            label: formatMessage({
                id: 'action_copy_link',
                defaultMessage: 'Copy link'
            }),
            effect: 'neutral',
            onClick: () => global.navigator.clipboard.writeText(clipboardText)
        }
    }, [atCategoryPage])

    const removeCategoryAction = useCallback(() => {
        if(!atCategoryPage || !manageAccess) {
            return null
        }

        const category = categories.find(({ id }) => id === categoryMatch?.params.id)
        if(!category) {
            return null
        }

        const hasTopics = !!category?.topics?.length

        return {
            salt: `${salt}:delete`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'handbooks_category_action_delete',
                defaultMessage: 'Delete category'
            }),
            effect: 'destructive',
            disabled: (!hasFetched || !!fetchError),
            prompt: {
                question: formatMessage({
                    id: 'delete_prompt_question_named',
                    defaultMessage: 'Confirm deleting ”{name}”'
                }, { name: category.title }),
                ...(hasTopics ?
                    {
                        message: formatMessage({
                            id: 'handbooks_category_delete_prompt_message',
                            defaultMessage: 'The handbook category and all its topics will be deleted. This can not be undone.'
                        }),
                        acknowledge: {
                            type: 'text',
                            salt: `${salt}:acknowledge`
                        }
                    }
                    : {
                        message: formatMessage({
                            id: 'delete_prompt_message',
                            defaultMessage: 'This can not be undone.'
                        })
                    }
                ),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            },
            onClick: async () => {
                const { ok } = await removeCategory(category.id, { force: true })

                ok && navigate(paths.handbook.base)
            }
        }
    }, [atCategoryPage, manageAccess, categoryMatch])

    const editOrderAction = useCallback(() => {
        if(!atHomePage) {
            return null
        }

        return {
            salt: `${salt}:edit-order`,
            icon: <Order size={24} />,
            label: formatMessage({
                id: 'action_edit_order',
                defaultMessage: 'Edit order'
            }),
            effect: 'neutral',
            onClick: () => setEditingOrder(true)
        }
    }, [atHomePage])

    const editContactsAction = useCallback(() => {
        if(!atHomePage) {
            return null
        }

        return {
            salt: `${salt}:edit-contacts`,
            icon: <Edit size={24} />,
            label: formatMessage({
                id: 'handbooks_action_edit_contacts',
                defaultMessage: 'Edit contacts'
            }),
            effect: 'neutral',
            onClick: () => setEditingContacts(true)
        }
    }, [atHomePage])

    const downloadAsPdfAction = useCallback(() => {
        if(!atHomePage) {
            return null
        }

        return {
            salt: `${salt}:download-as-pdf`,
            icon: <Download size={24} />,
            label: formatMessage({
                id: 'action_download_as_pdf',
                defaultMessage: 'Download as PDF'
            }),
            effect: 'neutral',
            onClick: () => setDownloadingAsPdf(true),
            loading: generatingPdf
        }
    }, [atHomePage])

    const exportAction = useCallback(() => {
        if(!atHomePage || (!manageAccess && !exportAvailable && !exportUpgradable)) {
            return null
        }

        return {
            salt: `${salt}:export`,
            icon: <DownloadCloud size={24} />,
            label: formatMessage({
                id: 'action_export',
                defaultMessage: 'Export'
            }),
            effect: 'neutral',
            onClick: downloadJSON,
            ...(exportUpgradable ? {
                upgrade: { feature: 'handbook-export' }
            } : null)
        }
    }, [atHomePage, manageAccess, exportAvailable, exportUpgradable])

    const importAction = useCallback(() => {
        if(!atHomePage || !manageAccess) {
            return null
        }

        return {
            salt: `${salt}:import`,
            icon: <UploadCloud size={24} />,
            label: formatMessage({
                id: 'action_import',
                defaultMessage: 'Import'
            }),
            effect: 'neutral',
            onClick: () => sendAppSignal('handbook:add-from-template', { target: 'import' })
        }
    }, [atHomePage, manageAccess])

    const unpublishAction = useCallback(() => {
        if(!atHomePage || status !== 'published') {
            return null
        }

        return {
            salt: `${salt}:unpublish`,
            icon: <EyeOff size={24} />,
            label: formatMessage({
                id: 'handbooks_action_unpublish',
                defaultMessage: 'Unpublish handbook'
            }),
            effect: 'neutral',
            onClick: () => {
                updateHandbook({ status: 'draft' }, handbook.id)
            }
        }
    }, [atHomePage, status])

    const deleteAction = useCallback(() => {
        if(!atHomePage || status === 'published') {
            return null
        }

        return {
            salt: `${salt}:delete`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'handbooks_action_delete',
                defaultMessage: 'Delete handbook'
            }),
            effect: 'destructive',
            onClick: () => removeHandbook(handbook.id),
            prompt: {
                question: formatMessage({
                    id: 'handbook_action_delete_prompt_question',
                    defaultMessage: 'Confirm deleting this handbook'
                }),
                message: formatMessage({
                    id: 'handbook_action_delete_prompt_message',
                    defaultMessage: 'The handbook with all of its categories and topics will be deleted. This can not be undone.'
                }),
                confirmText: formatMessage({
                    id: 'handbooks_action_delete',
                    defaultMessage: 'Delete handbook'
                })
            }
        }
    }, [atHomePage, status])

    const actions = {
        // Common
        preview: previewAction,

        // Handbook
        publish: publishAction,

        // Common
        notify: notifyAction,

        // Handbook
        addCategory: addCategoryAction,
        addFromTemplate: addFromTemplateAction,
        editOrder: editOrderAction,
        editContacts: editContactsAction,

        // Category
        changeTopicsStatus: changeTopicsStatusAction,
        addTopicsTags: addTopicsTagsAction,
        changeTopicsShares: changeTopicsSharesAction,
        changeTopicsLanguage: changeTopicsLanguageAction,
        editCategory: editCategoryAction,
        copyLink: copyLinkAction,
        removeCategory: removeCategoryAction,

        // Handbook
        downloadAsPdf: downloadAsPdfAction,
        export: exportAction,
        import: importAction,
        unpublish: unpublishAction,

        // Common
        delete: deleteAction
    }

    if(atSetupPage) {
        return null
    }

    const menuClassName = cls([
        manageAccess && 'has-actions',
        !!filter?.viewAs && 'previewing'
    ])

    return (
        <Sticky>
            <Menu className={menuClassName}>
                {!filter?.viewAs && <SearchFilter salt={salt} />}
                {!!filter?.viewAs && (
                    <MenuColumns className="wrap">
                        <MenuColumn>
                            <PreviewingStatus setPreviewing={setPreviewing} />
                        </MenuColumn>
                        <MenuColumn>
                            <ExitPreviewButton
                                icon={EyeOff}
                                onClick={() => setHandbookFilter({ viewAs: null })}>
                                <span>
                                    <FormattedMessage
                                        id="handbooks_action_reset_view_as"
                                        defaultMessage="Exit preview" />
                                </span>
                            </ExitPreviewButton>
                        </MenuColumn>
                    </MenuColumns>
                )}
                {(manageAccess && !filter?.viewAs) && (
                    <MenuColumns>
                        <MenuColumn className="hide-below-phone">
                            <Plain
                                className="constructive"
                                onClick={() => setPreviewing(true)}>
                                <FormattedMessage
                                    id="action_preview"
                                    defaultMessage="Preview" />
                            </Plain>
                        </MenuColumn>
                        {(status === 'published' && atHomePage) && (
                            <MenuColumn>
                                <Status
                                    status={status}
                                    tooltip={false} />
                            </MenuColumn>
                        )}
                        {(status === 'draft' && atHomePage) && (
                            <MenuColumn className="hide-below-phone">
                                <Button
                                    className="constructive"
                                    onClick={() => setPublishing(true)}
                                    disabled={noPublishedTopics}>
                                    <FormattedMessage
                                        id="action_publish_options"
                                        defaultMessage="Publish…" />
                                </Button>
                            </MenuColumn>
                        )}
                        {!atOtherPage && (
                            <MenuColumn>
                                <ContextMenu
                                    salt={salt}
                                    context={handbook}
                                    actions={actions} />
                            </MenuColumn>
                        )}
                    </MenuColumns>
                )}
            </Menu>
            <Modal
                show={!!editingBulkTopics}
                dismiss={() => setEditingBulkTopics(null)}
                salt={`${salt}:editing-bulk-topics`}>
                <BulkTopics
                    {...editingBulkTopics}
                    onDone={() => setEditingBulkTopics(null)}
                    salt={`${salt}:editing-bulk-topics`} />
            </Modal>
            <Modal
                show={editingCategory}
                dismiss={() => setEditingCategory(null)}
                salt={`${salt}:editing-category`}>
                <EditHandbookCategory
                    category={editingCategory}
                    dismiss={() => setEditingCategory(null)}
                    salt={`${salt}:editing-category`} />
            </Modal>
            <Modal
                show={!!publishing}
                dismiss={() => setPublishing(false)}
                salt={`${salt}:publish`}>
                <PublishHandbook
                    dismiss={() => setPublishing(false)}
                    onDone={notify => {
                        setPublishing(false)

                        if(notify) {
                            setSuccessfulMessage({ what: 'publish' })
                        }
                    }}
                    setEditingOrder={setEditingOrder}
                    salt={`${salt}:publish`} />
            </Modal>
            <Modal
                show={!!editingContacts}
                dismiss={() => setEditingContacts(false)}
                salt={`${salt}:edit-contacts`}>
                <EditContacts
                    onDone={() => setEditingContacts(false)}
                    salt={`${salt}:edit-contacts`} />
            </Modal>
            <Modal
                show={!!previewing}
                dismiss={() => setPreviewing(false)}
                salt={`${salt}:preview`}>
                <PreviewHandbook
                    onDone={() => setPreviewing(false)}
                    salt={`${salt}:preview`} />
            </Modal>
            <EditOrder
                show={!!editingOrder}
                dismiss={() => setEditingOrder(false)}
                salt={`${salt}:edit-order`} />
            <Modal
                show={!!downloadingAsPdf}
                dismiss={() => setDownloadingAsPdf(false)}
                salt={`${salt}:download-as-pdf`}>
                <DownloadHandbookAsPdf salt={`${salt}:download-as-pdf`} />
            </Modal>
            <Modal
                show={showingNotify}
                dismiss={() => setShowingNotify(null)}
                salt={`${salt}:notify`}>
                <Notify
                    {...showingNotify}
                    dismiss={() => setShowingNotify(null)}
                    onDone={what => {
                        setShowingNotify(null)
                        setSuccessfulMessage({ what })
                    }}
                    salt={`${salt}:notify`} />
            </Modal>
            <Modal
                show={successfulMessage}
                dismiss={() => setSuccessfulMessage(null)}
                salt={`${salt}:completed-publishing`}>
                <Successful
                    {...successfulMessage}
                    dismiss={() => setSuccessfulMessage(null)}
                    salt={`${salt}:completed-publishing`} />
            </Modal>
        </Sticky>
    )
}

export default HandbookStickyMenu
