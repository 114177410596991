import React from 'react'
import Page from 'contexts/page'
import CompetenceTypesProvider from 'contexts/competence-types'
import Routing from './routing'

const CompetencePage = ({ upgradable, ...props }) => (
    <Page
        view="competence"
        title={{
            id: 'noun_competence',
            defaultMessage: 'Competence'
        }}
        upgrade={{
            enabled: upgradable,
            passthrough: true
        }}>
        <CompetenceTypesProvider
            fetchAccess={!upgradable}
            fetchExhaustively={true}>
            <Routing
                {...props}
                upgradable={upgradable} />
        </CompetenceTypesProvider>
    </Page>
)

export const symbol = {
    colorToken: 'symbol-skin-1',
    emoji: 'beginner'
}

export default CompetencePage