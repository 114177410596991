import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useInvitationData } from 'hooks/invitation-data'
import Content from './content'
import Success from './success'
import { Scrollable as Modal } from 'modals/generic'
import WelcomeEmailModal from 'pages/people/panes/everyone/invite/inspect-invitation-modal'

const StartProcess = ({ user, type, show, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const [showingWelcome, setShowingWelcome] = useState(false)
    const [success, setSuccess] = useState(false)

    const { invitationData, setInvitationData } = useInvitationData()

    const close = () => {
        setShowingWelcome(false)
        setSuccess(false)
        dismiss()
    }

    return (
        <>
            <Modal
                show={show}
                dismiss={close}
                salt={salt}>
                {!success && (
                    <Content
                        dismiss={close}
                        onDone={() => setSuccess(true)}
                        invitationData={invitationData}
                        setShowingWelcome={setShowingWelcome}
                        salt={salt} />
                )}
                {!!success && (
                    <Success
                        user={user}
                        type={type}
                        dismiss={close} />
                )}
            </Modal>
            {(type === 'onboarding') && (
                <WelcomeEmailModal
                    users={[user]}
                    title={formatMessage({
                        id: 'employee_onboarding_heading_welcome_email',
                        defaultMessage: 'Welcome email'
                    })}
                    show={showingWelcome}
                    invitationData={invitationData}
                    setInvitationData={setInvitationData}
                    dismiss={() => setShowingWelcome(false)}
                    salt={`${salt}:welcome:email`} />
            )}
        </>
    )
}

export default StartProcess