import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    gap: 8px;

    max-width: 35vw;
    min-height: 40px;

    text-align: end;
`

export const Strong = styled.strong`
    font-weight: 500;
`