import React from 'react'
import { getValidStatisticsRecords } from '../utilities'
import { currencies } from 'utilities/payment'
import { ContainerQuery, Wrapper } from './s'
import TotalSinceStart from './total-since-start'
import AveragePerYear from './average-per-year'
import LineChart from './chart'

const SalaryStatistics = ({ records = [] }) => {
    if(!records || records.length < 2) {
        return null
    }

    records = records.toReversed()

    const filteredRecords = getValidStatisticsRecords(records)

    if(!filteredRecords.length || filteredRecords.length < 2) {
        return null
    }

    const [initialRecord] = filteredRecords
    const finalRecord = filteredRecords.at(-1)

    const currencyDisplay = currencies[records[0].currency] || 'code'

    const statsProps = {
        initialRecord,
        finalRecord,
        currencyDisplay
    }

    return (
        <ContainerQuery>
            <Wrapper>
                <TotalSinceStart {...statsProps} />
                <AveragePerYear {...statsProps} />
                <LineChart records={records} />
            </Wrapper>
        </ContainerQuery>
    )
}

export default SalaryStatistics
