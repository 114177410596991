import styled from 'styled-components'
import {
    svgStroke,
    belowTablet, darkMode
} from 'utilities/styled'
import { buttonStyles } from 'components/button'
import { Grid } from 'components/grid'
import { TriggerTexts } from 'components/entities'
import PersonBase from 'components/person'
import { Tags } from 'components/tags/s'
import ClampBase from 'components/clamp'
import Link from 'components/link'
import FavIconBase from 'components/favicon'
import { flexChildShrinkCss } from 'components/flex'
import ContextMenuBase from 'widgets/context-menu/flat'
import { ActionButton } from 'widgets/context-menu/s'
import { Checklistbox as ChecklistboxBase } from 'components/form/input/checkbox'
import Paragraph from 'components/typography/caption'
import Caption from 'components/typography/caption'
import ExpiryStatusBase from 'components/expiry-status'

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

export const Hero = styled.div`
    padding-block-end: 24px;

    ${belowTablet`
        padding-block-end: 16px;
    `}
`

export const DotColumns = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 8px;
    justify-content: start;
    align-items: center;
    padding-block-end: 24px;
`

export const Entities = styled(TriggerTexts)`
    display: inline-block;

    color: var(--huma-color-foreground-default);

    span, a {
        color: var(--huma-color-foreground-default);

        &:focus,
        &:hover {
            color: var(--huma-color-foreground-default);
        }
    }
`

export const Person = styled(PersonBase)`
    display: inline-grid;
    vertical-align: middle;
`

export const Clamp = styled(ClampBase)`
    margin-block-end: 24px;
`

export const Links = styled(Tags)`
    flex-wrap: wrap;
    align-items: center;

    margin-block-end: 24px;
    width: 100%;
    min-height: 40px;
`

export const Chip = styled(Link)`
    ${buttonStyles.ghostCss}
    ${flexChildShrinkCss}
    display: grid;
    grid-template: 24px 20px / 32px minmax(0, 1fr) 20px;
    column-gap: 8px;
    align-items: center;

    margin-block-end: 8px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    width: 100%;
    height: auto;
    padding: 16px;

    line-height: 24px;

    -webkit-appearance: none;

    svg {
        ${svgStroke()}

        grid-row: 1 / 3;
        grid-column: 3 / 4;
    }

    &:hover,
    &:focus {
        border-color: var(--huma-color-border-default);
        text-decoration: none;
    }
`

export const FavIcon = styled(FavIconBase)`
    grid-row: 1 / 3;

    margin-inline-end: 16px;
`

export const LinkName = styled(Paragraph)`
    grid-column: 2 / 3;
`

export const LinkUrl = styled(Caption)`
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    font-size: 14px;
`

export const Label = styled(Caption)`
    margin-block-end: 8px;
`

export const ExpiryStatus = styled(ExpiryStatusBase)`
    margin-block-end: 24px;
`

export const ContextMenu = styled(ContextMenuBase)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    overflow: hidden;
`

export const Checklistbox = styled(ChecklistboxBase)`
    pointer-events: none;
    margin-inline: 2px;

    svg {
        margin-inline: 0;
    }
`

export const ChecklistActionButton = styled(ActionButton)`
    &:hover input[type="checkbox"]:not(:disabled):not(:checked) + ${Checklistbox},
    &:hover input[type="checkbox"]:not(:disabled):not(:checked):focus + ${Checklistbox} {
        border-color: transparent;

        &:not(.destructive) {
            background: var(--huma-color-surface-constructive-subtle);
            box-shadow: 0 0 0 6px var(--huma-color-surface-constructive-subtle);

            color: var(--huma-color-foreground-constructive-bold);
        }

        &.destructive {
            background: var(--huma-color-surface-destructive-subtle);
            box-shadow: 0 0 0 6px var(--huma-color-surface-destructive-subtle);

            color: var(--huma-color-foreground-destructive);
        }
    }

    &:hover input[type="checkbox"]:not(:disabled):checked + ${Checklistbox},
    &:hover input[type="checkbox"]:not(:disabled):checked:focus + ${Checklistbox} {
        --checklistbox-color: var(--huma-color-border-default);

        border-color: var(--checklistbox-color) !important;
        background: var(--checklistbox-color) !important;
        box-shadow: 0 0 0 6px var(--checklistbox-color);

        ${darkMode`
            --checklistbox-color: var(--huma-color-surface-minimal);
        `}

        &::after {
            width: 14px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: var(--huma-color-foreground-minimal);
            opacity: 1;
        }

        svg { color: transparent; }
    }
`