import React from 'react'
import { cls } from 'utilities/dom'
import { roundedToFixed } from 'utilities/math'
import {
    Legend, Serie,
    TooltipContainer, TooltipHeading, TooltipList, TooltipItem, TooltipLabel, TooltipValue
} from './s'
import {
    CheckCircleFilled as Success,
    AlertTriangleFilled as Warning,
    CrossCircleFilled as Caution
} from 'components/feather'

export const renderLegends = props => {
    const { payload } = props

    return (
        <Legend>
            {payload.map(({ value, color, dataKey }) => {
                const className = cls([
                    dataKey === 'target' && 'target',
                    dataKey === 'value' && 'group'
                ])

                return (
                    <Serie
                        className={className}
                        style={{ color }}
                        key={dataKey}>
                        <span>{value}</span>
                    </Serie>
                )
            })}
        </Legend>
    )
}

export const CustomTooltip = ({ active, payload, label: heading, totalPeople, salt }) => {
    if (!active) {
        return null
    }

    return (
        <TooltipContainer>
            {!!heading && (
                <TooltipHeading>{heading}</TooltipHeading>
            )}
            <TooltipList>
                {payload.map(({ color, name, value, payload: data, dataKey }) => {
                    const {
                        category,
                        target,
                        displayValue
                    } = data

                    const isBooleanType = !['skill', 'language'].includes(category)

                    const hasWarning = !!(!['target', 'value'].includes(dataKey) && !!data?.[`${dataKey}HasWarning`])

                    const className = cls([
                        dataKey === 'target' && 'target',
                        dataKey === 'value' && 'group',
                        (isBooleanType && totalPeople - value === 0 && dataKey !== 'target') && 'success',
                        (!isBooleanType && roundedToFixed(target - value) === 0 && dataKey !== 'target') && 'success',
                        (isBooleanType && dataKey === 'target') && 'success',
                        (isBooleanType && !['target', 'value'].includes(dataKey) && !!data[dataKey]) && 'success',
                        hasWarning && 'warning',
                        (isBooleanType && !['target', 'value'].includes(dataKey) && !data[dataKey] && !hasWarning) && 'caution'
                    ])

                    if(dataKey === 'value') {
                        value = isBooleanType
                            // eslint-disable-next-line no-irregular-whitespace
                            ? `${roundedToFixed(displayValue)} / ${totalPeople}`
                            : roundedToFixed(displayValue)
                    } else if(dataKey === 'target') {
                        value = isBooleanType
                            ? <Success size={16} />
                            : roundedToFixed(target)
                    } else {
                        // Individual graph
                        value = hasWarning
                            ? <Warning size={20} />
                            : isBooleanType
                                ? !!data[dataKey]
                                    ? <Success size={16} />
                                    : <Caution size={16} />
                                : roundedToFixed(data[dataKey])
                    }

                    return (
                        <TooltipItem
                            className={className}
                            style={{ color }}
                            key={`${salt}:tooltip:${name}`}>
                            <TooltipLabel>
                                <span>{name}</span>
                            </TooltipLabel>
                            <TooltipValue>
                                {value}
                            </TooltipValue>
                        </TooltipItem>
                    )
                })}
            </TooltipList>
        </TooltipContainer>
    )
}