import styled from 'styled-components'
import { fromDesktop } from 'utilities/styled'
import HeroActionsBase from 'components/hero-actions'
import { Grid } from 'components/grid'

export const HeroActions = styled(HeroActionsBase)`
    margin-bottom: 24px;
`

export const Aside = styled.aside`
    display: flex;
    flex-direction: column;

    &:empty {
        display: none;
    }

    ${fromDesktop`
        position: sticky;
        top: calc(var(--huma-global-main-layout-header-height) + 24px);
    `}
`

export const Layout = styled(Grid)`
    isolation: isolate;
    align-items: start;

    ${fromDesktop`
        grid-template-columns: minmax(0, 1fr) 30%;
        row-gap: 40px;
        column-gap: 56px;

        &:has(${Aside}:empty) {
            grid-template-columns: minmax(0, 1fr);
        }
    `}
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;

    ${Layout}:not(:has(${Aside}:empty)) & {
        &::after {
            ${fromDesktop`
                content: "";
                display: block;
                min-height: calc(${[
                    '100vh',
                    '- var(--huma-global-main-layout-header-height)',
                    '- calc(var(--huma-global-content-padding-block-start) + var(--huma-global-content-padding-block-end))',
                    '- var(--huma-global-sticky-menu-height)'
                ].join(' ')});
            `}
        }
    }
`