import styled from 'styled-components'
import { belowTablet, belowDesktop } from 'utilities/styled'
import Link from 'components/link'
import { buttonStyles } from 'components/button'
import { Scrollable } from 'modals/generic'

export const Hero = styled.div`
    &:not(.compact) {
        margin-block-end: 56px;

        ${belowDesktop`
            margin-block-end: 24px;
        `}
    }
`

export const HeroColumns = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    margin-block-end: 8px;

    &.compact {
        margin: 0;
    }
`

export const SetupButton = styled(Link)`
    ${buttonStyles.ghostCss}

    &:is(:hover, :focus) {
        text-decoration: none;
    }
`

export const ActionColumn = styled.div`
    position: relative;
`

export const Modal = styled(Scrollable)`
    ${belowTablet`
        align-self: flex-start;

        margin: 88px 0 0;
        border-radius: 16px 16px 0 0;
        max-width: 100vw;
        width: 100vw;
        min-height: calc(100svh - 88px);
    `}
`