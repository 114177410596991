import styled from 'styled-components'
import { H2 } from 'components/typography/heading'
import { UnorderedList as UnorderedListBase, ListItem as ListItemBase } from 'components/list'

export const Heading = styled(H2)`
    margin-bottom: 24px;
    max-width: calc(100% - 40px);

    line-height: 1.25;
`

export const UnorderedList = styled(UnorderedListBase)`
    margin: 0 0 16px 16px;
    list-style-type: disc;
`

export const ListItem = styled(ListItemBase)`
    line-height: 24px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
`