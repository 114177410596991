import styled from 'styled-components'
import StringField from 'components/form/field/string'
import { Error as ErrorBase } from 'components/form/field/s'
import Paragraph from 'components/typography/paragraph'

export const Container = styled.div`
    overflow: clip;
    margin-block-end: 24px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
`

export const Fields = styled.div`
    padding: 16px;
`

export const FieldErrorWrapper = styled.div`
    position: relative;
    transition: margin .2s ease-in-out;

    &.error-within {
        margin-block-end: 64px;
    }
`

export const SeatPledgeField = styled(StringField)`
    justify-content: space-between !important;

    > label {
        flex-grow: 0;
    }

    > div {
        flex: 0 1 64px;
    }

    input {
        text-align: end;
    }
`

export const Error = styled(ErrorBase)`
    top: calc(100% + 8px);
    left: 0;
`

export const TotalPrice = styled.div`
    padding: 16px;
    background-color: var(--huma-color-surface-minimal);
`

export const PriceRow = styled(Paragraph)`
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-flow: column;
    justify-content: space-between;

    &:not(:last-child) {
        margin-block-end: 8px;
    }

    &.base {
        margin-block-start: 16px;

        > span:first-child {
            color: var(--huma-color-foreground-subtle);
        }
    }

    &.total {
        font-weight: 500;
    }
`