import styled from 'styled-components'
import { H3 } from 'components/typography/heading'
import { Columns as ColumnsBase } from 'components/flex'

export const Heading = styled(H3)`
    margin-bottom: 24px;
`

export const Columns = styled(ColumnsBase)`
    justify-content: flex-end;
    margin-top: 24px;

    > div:not(:first-child) {
        margin-left: 24px;
    }
`
