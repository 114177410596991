import React, { Children, cloneElement, isValidElement, Fragment } from 'react'
import { useAbsenceTypes } from 'contexts/absence-types'
import AbsenceEntryProvider, { useAbsenceEntry } from 'contexts/absence-entry'
import { useAccess } from 'contexts/access'
import { UserPermissionsProvider } from 'contexts/unit-permissions'
import { LoadingContainer } from './s'
import Loader from 'components/loader'
import Hero from './hero'
import Details from './details'
import Actions from './actions'
import Meta from './meta'

const ViewEntry = ({ entry, type, absenceAdmin = false, provide, actions, dismiss = null, onDone, salt }) => {
    if(!dismiss) {
        dismiss = onDone
    }

    return (
        <>
            <Hero
                {...entry}
                absenceAdmin={absenceAdmin}
                dismiss={dismiss} />
            <Details
                {...entry}
                type={type}
                absenceAdmin={absenceAdmin}
                edit={() => actions?.edit?.(entry)?.onClick()} />
            <Actions
                entry={entry}
                provide={provide}
                actions={actions}
                dismiss={dismiss}
                salt={salt} />
            <Meta {...entry} />
        </>
    )
}

const Loading = () => (
    <LoadingContainer>
        <Loader />
    </LoadingContainer>
)

const EntryProvider = props => {
    const { entry } = useAbsenceEntry()

    if(!entry) {
        return <Loading />
    }

    return (
        <ViewEntry
            {...props}
            entry={entry} />
    )
}

const UserPermissions = ({ children, ...props }) => (
    <UserPermissionsProvider id={props.entry.user.id}>
        {({ checkUnitPermission }) => Children.map(children, child => {
            if(isValidElement(child)) {
                return cloneElement(child, {
                    absenceAdmin: checkUnitPermission('user:absence:manage')
                })
            }

            return child
        })}
    </UserPermissionsProvider>
)

export default ({ entryId = null, ...props }) => {
    const { provide = true } = props

    const { types } = useAbsenceTypes()
    const { check } = useAccess()
    props.absenceAdmin = check('absence:manage')

    if(!!props.entry?.type && types?.length) {
        props.type = types.find(({ id }) => id === props.entry.type.id)
    }

    if(!!props?.user && !props.entry?.user) {
        props.entry.user = props.user
    }

    const AbsenceEntryWrapper = provide ?
        AbsenceEntryProvider :
        Fragment

    const wrapperProps = provide ? {
        id: entryId
    } : null

    const UserPermissionsWrapper = !props.absenceAdmin ?
        UserPermissions :
        Fragment

    const userPermissionsProps = !props.absenceAdmin ?
        props :
        null

    return (
        <AbsenceEntryWrapper {...wrapperProps}>
            <UserPermissionsWrapper {...userPermissionsProps}>
                {!!provide && <EntryProvider {...props} />}
                {!provide && <ViewEntry {...props} />}
            </UserPermissionsWrapper>
        </AbsenceEntryWrapper>
    )
}
