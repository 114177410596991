export const throttle = (fn, delay = 100) => {
    let lastCall = 0

    return (...args) => {
        const now = Date.now()
        if(now - lastCall < delay) {
            return
        }

        lastCall = now
        return fn(...args)
    }
}