import styled from 'styled-components'
import { UnorderedList, ListItem } from 'components/list'
import { ParagraphSmall } from 'components/typography/paragraph'

export const DataList = styled(UnorderedList)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const DataListItem = styled(ListItem)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
`

export const Label = ParagraphSmall

export const RecordsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 40px);
    grid-gap: 4px;
    grid-auto-rows: 40px;

    scale: 0.75;
    transform-origin: top right;
`