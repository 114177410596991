import React from 'react'
import { useIntl } from 'react-intl'
import { useAbsenceType } from 'contexts/absence-type'
import { typeToI18nMap } from 'pages/absence/constants'

const formatTypeName = formatMessage => (type = {}) => {
    const {
        name = null,
        deletable
    } = type ?? {}

    if(!name) {
        return formatMessage({
            id: 'absence_status_away',
            defaultMessage: 'Away'
        })
    }

    if(deletable) {
        return name
    }

    return formatMessage({
        id: typeToI18nMap[name] ?? `absence_type_${name}`,
        defaultMessage: name
    })
}

export const useTypeNameFormatter = () => {
    const { formatMessage } = useIntl()
    return formatTypeName(formatMessage)
}

export const Displayer = props => useTypeNameFormatter()(props)

const AbsenceTypeName = () => {
    const { type = null } = useAbsenceType()
    return <Displayer {...type} />
}

export default AbsenceTypeName