import React, { Component, createContext, useContext } from 'react'
import { capitalize } from 'utilities/string'
import PubSub from 'pubsub-js'

export const EntityContext = createContext()

class EntityProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            [props.name]: props[props.name] ?? null,
            created: false,
            updated: Date.now(),
            deleted: false,

            [`update${capitalize(props.name)}`]: this.updateEntity
        }

        this.subscriptions = {}
        this.subscribe()
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    subscribe = () => {
        const {
            name,
            subscribe
        } = this.props

        if(subscribe?.created) {
            this.subscriptions.creation = PubSub.subscribe(
                subscribe.created,
                (_, createe) => this.setState(({ [name]: entity }) => {
                    if(!!entity?.id) {
                        return null
                    }

                    return {
                        [name]: createe,
                        created: true
                    }
                })
            )
        }

        if(subscribe?.updated) {
            this.subscriptions.creation = PubSub.subscribe(
                subscribe.updated,
                (_, updatee) => this.updateEntity(updatee)
            )
        }

        if(subscribe?.deleted) {
            this.subscriptions.creation = PubSub.subscribe(
                subscribe.deleted,
                (_, deletee) => this.setState(({ [name]: entity }) => {
                    if(deletee?.id !== entity?.id) {
                        return null
                    }

                    return {
                        [name]: null,
                        deleted: true
                    }
                })
            )
        }
    }

    unsubscribe = () => Object.values(this.subscriptions).forEach(PubSub.unsubscribe)

    updateEntity = (updatee, force = false) => this.setState(({ [this.props.name]: entity }) => {
        if(updatee?.id !== entity?.id) {
            return
        }

        const updated = Date.now()

        return {
            [this.props.name]: {
                ...entity,
                ...updatee
            },
            updated,
            ...(force ? { forceUpdated: updated } : null)
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <EntityContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EntityContext.Provider>
        )
    }
}


export default EntityProvider

export const useEntity = () => useContext(EntityContext)