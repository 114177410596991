import styled from 'styled-components'
import Paragraph from 'components/typography/paragraph'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 8px;

    margin-top: 16px;
    border-radius: 8px;
    padding: 16px;
    background-color: var(--huma-color-surface-minimal);

    &.fill {
        grid-column: 1 / -1;

        margin-top: 16px;
        margin-bottom: 0;
    }
`

export const Heading = Paragraph