import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from '../'

const feature = 'custom-user-fields'

const SystemSettingsCustomProfileFields = props => {
    const {
        initialized,

        check,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const access = initialized && checkFeature(feature) && check('users:custom-fields:manage')
    const upgradable = checkUpgradable({ feature })

    return (
        <MenuItemLink
            {...props}
            to="settings.customprofilefields" end
            feature={feature}
            access={access}
            upgradable={upgradable}
            nested>
            <FormattedMessage
                id="profile_custom_fields_heading"
                defaultMessage="Custom profile fields" />
        </MenuItemLink>
    )
}

export default SystemSettingsCustomProfileFields