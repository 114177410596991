import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { format } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { isofy } from 'utilities/date-time'
import {
    Wrapper,
    Person,
    Unassigned, MetaLabel,
    Variant, Note, SerialNumber,
    DateColumns, ArrowColumn, Label,
    ExpiryStatus, EquipmentMessage
} from './s'
import { FlexChild } from 'components/flex'
import Paragraph from 'components/typography/paragraph'
import { Plain } from 'components/button'
import MissingDates from 'pages/equipment/components/missing-dates'
import { ArrowRight } from 'styled-icons/feather'

const ViewEquipmentPieceDetails = ({ setActing, piece }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { check } = useAccess()
    const adminAccess = check('equipment:manage')

    let {
        assignedTo,
        variant,
        note,
        fromDate,
        toDate,
        attachments,
        links,
        serialNumber,
        type
    } = piece

    const {
        requireFromDate,
        requireToDate,
        requireDocumentation,
        requireSerialNumber
    } = type ?? {}

    fromDate = isofy(fromDate)
    toDate = isofy(toDate)

    const missingDocumentation = !!requireDocumentation && !attachments?.length && !links?.length
    const missingSerialNumber = !!requireSerialNumber && !serialNumber

    return (
        <Wrapper>
            {assignedTo && (
                <Person
                    size={24}
                    who={assignedTo} />
            )}
            {!assignedTo && (
                <Unassigned className="compact">
                    <MetaLabel>
                        <FormattedMessage
                            id="item_status_unassigned"
                            defaultMessage="Unassigned" />
                    </MetaLabel>
                    <Plain
                        className="constructive compact"
                        onClick={() => setActing({
                            mode: 'edit',
                            piece,
                            focus: 'assignedTo'
                        })}>
                        <FormattedMessage
                            id="action_assign"
                            defaultMessage="Assign" />
                    </Plain>
                </Unassigned>
            )}
            {variant && (
                <Variant className="compact">
                    <MetaLabel>
                        <FormattedMessage
                            id="equipment_label_variant"
                            defaultMessage="Variant" />
                    </MetaLabel>
                    {variant}
                </Variant>
            )}
            {note && <Note className="compact">{note}</Note>}
            {serialNumber && (
                <SerialNumber className="compact">
                    <MetaLabel>
                        <FormattedMessage
                            id="equipment_label_serial_number"
                            defaultMessage="Serial number" />
                    </MetaLabel>
                    <span className="monospace">{serialNumber}</span>
                </SerialNumber>
            )}
            <DateColumns>
                <FlexChild>
                    <Label className="compact">
                        {!!fromDate && format(fromDate, 'EEEE', { locale })}
                    </Label>
                    <Paragraph className="compact">
                        {!!fromDate && format(fromDate, 'PP', { locale })}
                        {(!fromDate && adminAccess) && (
                            <Plain
                                className="constructive"
                                onClick={() => setActing({
                                    mode: 'edit',
                                    piece,
                                    focus: 'fromDate'
                                })}>
                                <FormattedMessage
                                    id="action_set_start_date"
                                    defaultMessage="Set start date"
                                />
                            </Plain>
                        )}
                        {(!fromDate && !adminAccess) && (
                            <FormattedMessage
                                id="message_start_date_missing"
                                defaultMessage="No start date" />
                        )}
                    </Paragraph>
                </FlexChild>
                <ArrowColumn>
                    <ArrowRight size={24} />
                </ArrowColumn>
                <FlexChild>
                    <Label className="compact">
                        {!!toDate && format(toDate, 'EEEE', { locale })}
                    </Label>
                    <Paragraph className="compact">
                        {!!toDate && format(toDate, 'PP', { locale })}
                        {(!toDate && adminAccess) && (
                            <Plain
                                className="constructive"
                                onClick={() => setActing({
                                    mode: 'edit',
                                    piece,
                                    focus: 'toDate'
                                })}>
                                <FormattedMessage
                                    id="action_set_end_date"
                                    defaultMessage="Set end date"
                                />
                            </Plain>
                        )}
                        {(!toDate && !adminAccess) && (
                            <FormattedMessage
                                id="message_end_date_missing"
                                defaultMessage="No end date" />
                        )}
                    </Paragraph>
                </FlexChild>
            </DateColumns>
            <div>
                {!!toDate && (
                    <ExpiryStatus
                        to={toDate}
                        showActive={false}
                        className="compact" />
                )}
                <MissingDates
                    requireStart={requireFromDate}
                    requireEnd={requireToDate}
                    start={fromDate}
                    end={toDate}
                    className="compact" />
                {!!missingDocumentation && (
                    <EquipmentMessage
                        message={formatMessage({
                            id: 'equipment_status_documentation_missing',
                            defaultMessage: 'Documentation missing'
                        })}
                        type="error"
                        className="compact" />
                )}
                {!!missingSerialNumber && (
                    <EquipmentMessage
                        message={formatMessage({
                            id: 'equipment_status_serial_number_missing',
                            defaultMessage: 'Serial number missing'
                        })}
                        type="error"
                        className="compact" />
                )}
            </div>
        </Wrapper>
    )
}

export default ViewEquipmentPieceDetails
