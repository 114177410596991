import styled from 'styled-components'
import { Grid } from 'components/grid'
import { ButtonSubmit } from 'components/button'

export const HalfColumns = styled(Grid)`
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
`

export const ContinueButton = styled(ButtonSubmit)`
    justify-content: center;
    width: 100%;
`