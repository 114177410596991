import React from 'react'
import { FormattedMessage } from 'react-intl'
import { showValue } from '../'
import { Item, IconWrapper, Title, ModalIcon } from './s'
import { ArrowUpCircle as Icon, ChevronRight } from 'styled-icons/feather'

const Value = () => (
    <Item onClick={showValue({})}>
        <IconWrapper>
            <Icon size={24} />
        </IconWrapper>
        <Title>
            <FormattedMessage
                id="value_proposition_cta"
                defaultMessage="Explore the value" />
        </Title>
        <ModalIcon>
            <ChevronRight size={24} />
        </ModalIcon>
    </Item>
)

export default Value