import { useCallback, useLayoutEffect } from 'react'
import { compact } from 'utilities/array'

export const useClickOutside = ({ callback, refs, requirements = [] }) => {
    const prompt = document.getElementById('prompt')

    const handler = useCallback(({ target }) => {
        if(!requirements.every(Boolean)) {
            return
        }

        if(prompt && (prompt === target || prompt.contains(target))) {
            return
        }

        const clickInside = compact(refs.map(({ current }) => {
            if(current && (current === target || current.contains(target))) {
                return true
            }

            return false
        })).length

        if(clickInside) {
            return
        }

        callback()
    }, [requirements])

    useLayoutEffect(() => {
        document.addEventListener('mousedown', handler)
        document.addEventListener('touchstart', handler)

        return () => {
            document.removeEventListener('mousedown', handler)
            document.removeEventListener('touchstart', handler)
        }
    }, [handler])
}
