import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { MenuItemLink } from '../'

const moduleIdentifier = 'subsidiaries'

const CorporationSubsidiaries = props => {
    const {
        initialized,
        check
    } = useAccess()

    const access = initialized && check('subsidiaries:manage')

    return (
        <MenuItemLink
            {...props}
            to="corporation.base" end
            module={moduleIdentifier}
            access={access}
            upgradable={false}
            nested>
            <FormattedMessage
                id="noun_subsidiaries"
                defaultMessage="Subsidiaries" />
        </MenuItemLink>
    )
}

export default CorporationSubsidiaries