import React, { Component, createContext, useContext } from 'react'
import { useEnvironment } from 'contexts/environment'
import { useAuth, tripletexQueryParamNames } from 'contexts/auth'
import { useI18n } from 'contexts/i18n'
import { useLocation, useNavigate } from 'react-router-dom'
import { post } from 'api'
import queryString from 'query-string'
import { local as storage } from 'utilities/storage'
import { sleep } from 'utilities/async'
import paths from 'app/paths'
import { pick, omit } from 'utilities/object'
import { slugify } from 'utilities/string'
import { identifyAndSend } from 'utilities/hubspot'

const SignupContext = createContext()

class SignupProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            step: 1,
            code: null,
            key: '',
            givenName: '',
            familyName: '',
            email: '',
            name: props.auth.integration?.companyName ?? '',
            country: '',
            companySize: '1-5',
            otp: {},
            validatingOtp: false,

            register: this.register,
            validateOtp: this.validateOtp,
            login: this.login,

            goToPreviousStep: this.goToPreviousStep,
        }
    }

    componentDidMount() {
        const {
            auth,
            location: { search }
        } = this.props

        const {
            arrived,
            ...query
        } = queryString.parse(search)

        // Check if we loaded the app as a result of arriving
        // on the correct company subdomain after a successful signup.
        // Return early if so.
        if(arrived) {
            const {
                accessToken,
                refresh_token,
                integration
            } = JSON.parse(arrived)

            const authReferralState = { integration }

            return void auth.setReferralState(
                authReferralState,
                () => this.login({
                    accessToken,
                    refresh_token
                })
            )
        }

        requestAnimationFrame(() => {
            this.props.navigate(
                queryString.stringifyUrl({
                    url: paths.signup,
                    query: omit(query, ...tripletexQueryParamNames)
                }),
                { replace: true }
            )
        })
    }

    // STEP ONE: REGISTER
    register = async body => {
        const { ok, status } = await post({
            path: '/signup/passwordless/email',
            body: pick(body, 'email'),
            returnsData: false
        })

        if(ok) {
            this.setState({
                step: 2,
                ...body,
                otp: {
                    ...body,
                    grant_type: 'email_otp'
                }
            })
        }

        if(this.props.environment.integrations?.enableHubspotContactRegistration) {
            identifyAndSend({
                email: body.email,
                firstname: body.givenName,
                lastname: body.familyName,
                salt: Date.now()
            })
        }

        return { ok, status }
    }

    // STEP TWO: VALIDATE ONE-TIME PASSWORD
    validateOtp = async ({ code }, recaptcha) => {
        this.setState({ validatingOtp: true })

        const start = Date.now()

        const body = {
            ...this.state.otp,
            code,
            key: slugify(this.state.otp.name.trim()).slice(0, 50)
        }

        if(!!recaptcha?.current) {
            body.recaptchaToken = await recaptcha.current.executeAsync()
            recaptcha.current.reset()
        }

        const { ok, response } = await post({
            path: '/signup/oauth/token',
            body
        })

        !ok && this.setState({ validatingOtp: false })

        if(ok && response) {
            const elapsed = Date.now() - start

            if(elapsed >= this.props.loadingDuration) {
                this.login(response)
            } else {
                await sleep(this.props.loadingDuration - elapsed)
                this.login(response)
            }
        }
    }

    // STEP THREE: LOGIN
    login = async ({ organizationKey: key, ...credentials }) => {
        const { name } = this.state
        const { auth, locale } = this.props

        storage.set('login:key', key)

        // Wait for a brief moment to make sure the key is written to storage
        await sleep(20)

        auth.onSignIn({
            ...credentials,
            key,
            name,
            locale,
            signup: true
        })
    }

    buildRedirectUri() {
        const { protocol, hostname, host } = global.location
        const localhost = hostname === 'localhost'
        const heroku = hostname.endsWith('.herokuapp.com')

        const redirectHost = localhost ?
            host :
            heroku ?
                hostname :
                `auth.${host.split('.').slice(1).join('.')}`

        return `${protocol}//${redirectHost}${paths.signup}`
    }

    goToPreviousStep = () => this.setState(({ step }) => {
        if(step === 1) {
            this.props.navigate(paths.root, { replace: true })
            return null
        }

        return {
            step: step - 1
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <SignupContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </SignupContext.Provider>
        )
    }
}

export default props => {
    const environment = useEnvironment()
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    const { locale } = useI18n()

    return (
        <SignupProvider
            {...props}
            environment={environment}
            auth={auth}
            location={location}
            navigate={navigate}
            locale={locale} />
    )
}

export const useSignup = () => useContext(SignupContext)