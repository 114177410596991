import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from '../'

const moduleIdentifier = 'insights'

const CorporationInsights = props => {
    const {
        initialized,
        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule('insights') && check('insights:manage')
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="corporation.insights"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}
            nested>
            <FormattedMessage
                id="noun_insights"
                defaultMessage="Insights" />
        </MenuItemLink>
    )
}

export default CorporationInsights