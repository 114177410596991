import React, { Component, createContext, useContext } from 'react'
import { get, post, patch, remove } from 'api'
import { useAuth } from 'contexts/auth'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { getChannel } from 'utilities/broadcaster'
import PubSub from 'pubsub-js'
import { omit } from 'utilities/object'
import { requestAccess } from 'utilities/auth'
import { doNowOrWhenVisible } from 'utilities/visibility'
import debounce from 'lodash.debounce'

export const OrganizationContext = createContext()

class OrganizationProvider extends Component {
    constructor(props) {
        super(props)

        const {
            authorized,
            subsidiarian
        } = props

        this.state = {
            organization: null,
            subsidiaries: null,
            grants: [],
            modules: [],
            statuses: null,
            fetching: false,
            authorized,
            subsidiarian,
            deleted: false,

            fetchOrganization: this.fetch,
            fetchOrganizationPermissions: this.fetchOrganizationPermissions,
            fetchOrganizationGrants: this.fetchOrganizationGrants,
            refreshStatuses: this.fetchStatuses,
            updateOrganization: this.update,
            removeOrganization: this.remove,

            uploadLogo: this.uploadLogo,
            removeLogo: this.removeLogo,

            authConfiguration: null,
            fetchAuthConfiguration: this.fetchAuthConfiguration,
            updateAuthConfiguration: this.updateAuthConfiguration,
            enableAuthMethod: this.enableAuthMethod,
            disableAuthMethod: this.disableAuthMethod,

            checkDomainAvailability: this.checkDomainAvailability
        }

        this.fetchController = new AbortController()
        this.fetchSubsidiariesController = new AbortController()
        this.fetchStatusesController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: true, trailing: false })

        this.subscription = PubSub.subscribe(
            'organization.refresh',
            (_, force = false) => this.fetch(force)
        )

        this.syncer = getChannel('organization')
    }

    componentDidMount() {
        if(this.state.authorized) {
            this.fetch()
        }

        if(this.state.subsidiarian) {
            this.fetchSubsidiaries()
        }

        this.syncer.onmessage = ({ data }) => void this.setState(data, () => {
            this.cancelScheduledRefetch()
            this.scheduleRefetch()
        })
    }

    componentDidUpdate(props, { authorized: wasAuthorized, subsidiarian: wasSubsidiarian }) {
        const isAuthorized = this.props.authorized
        if(!wasAuthorized && isAuthorized) {
            this.setState({ authorized: true }, this.fetch)
        }

        const isSubsidiarian = this.props.subsidiarian
        if(!wasSubsidiarian && isSubsidiarian) {
            this.setState({ subsidiarian: true }, this.fetchSubsidiaries)
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.fetchSubsidiariesController.abort()
        this.fetchStatusesController.abort()
        this.cancelScheduledRefetch()
        PubSub.unsubscribe(this.subscription)
        this.syncer.close()
    }

    fetch = async (force = false) => {
        const { ok: tokensOk } = await requestAccess({ bounce: false })
        if(!tokensOk) {
            return
        }

        const { fetching } = this.state
        if(fetching && !force) {
            return
        }

        this.cancelScheduledRefetch()

        if(force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { signal } = this.fetchController

        const [
            { ok: organizationOk, response: organization = {} },
            { ok: statusesOk, response: statuses }
        ] = await Promise.all([
            get({ path: '/organization', signal }),
            this.fetchStatuses({ notify: false, signal })
        ])

        const ok = organizationOk && statusesOk
        let state = null

        if(ok && organization) {
           state = {
                organization,
                statuses,
                fetching: false
            }

            this.setState(state, () => this.syncer.postMessage(state))
        }

        this.scheduleRefetch()

        return { ok, response: state }
    }

    cancelScheduledRefetch = () => {
        if(this.refreshTimeout) {
            global.clearTimeout(this.refreshTimeout)
        }
    }

    scheduleRefetch = async () => {
        const thirtyMinutesInMilliseconds = 30 * 60 * 1000
        this.refreshTimeout = global.setTimeout(() => {
            doNowOrWhenVisible(() => this.fetchDebounced(true))
        }, thirtyMinutesInMilliseconds)
    }

    fetchSubsidiaries = async () => {
        const { ok, response: subsidiaries } = await get({
            path: '/organization/subsidiaries',
            signal: this.fetchSubsidiariesController.signal
        })

        if(ok && subsidiaries) {
            this.setState({ subsidiaries }, () => this.syncer.postMessage({ subsidiaries }))
        }
    }

    fetchStatuses = async (options = {}) => {
        const {
            notify = true,
            signal = this.fetchStatusesController.signal
        } = options

        this.setState({ fetching: true })

        const { ok, response: statuses } = await get({
            path: '/organization/status',
            signal
        })

        if(ok) {
            this.setState(({ statuses }), () => {
                !!notify && this.syncer.postMessage({ statuses })
            })
        }

        return { ok, response: statuses }
    }

    fetchOrganizationPermissions = () => get({ path: '/organization/permissions' })

    fetchOrganizationGrants = async (update = false) => {
        const { response: permissions } = await this.fetchOrganizationPermissions()

        const organizationManage = permissions?.includes('organization:manage')
        const rolesManage = permissions?.includes('roles:manage')

        if(organizationManage || rolesManage) {
            const { ok, response: grants } = await get({ path: '/organization/grants' })

            if(ok && update) {
                this.setState({ grants })
            }

            return { ok, response: grants }
        }

        return { ok: false }
    }

    update = async body => {
        const { ok, response: organization } = await patch({
            path: `/organization`,
            body
        })

        if(ok && organization) {
            this.setState({ organization }, () => this.syncer.postMessage({ organization }))
        }

        return { ok }
    }

    remove = async ({ redirectUrl: path }) => {
        const { ok } = await remove({
            path: '/organization',
            returnsData: false
        })

        if(ok) {
            this.setState({ deleted: true })
            this.props.onSignOut({ path })
        }

        return { ok }
    }

    uploadLogo = async body => {
        const { ok, response } = await post({
            path: '/organization/company-logo',
            body
        })

        if(ok && response) {
            this.setState(({ organization }) => ({
                organization: {
                    ...organization,
                    companyLogo: response
                }
            }), () => {
                this.syncer.postMessage({
                    organization: {
                        ...this.state.organization,
                        companyLogo: response
                    }
                })
            })
        }

        return { ok, response }
    }

    removeLogo = async () => {
        const { ok } = await remove({
            path: '/organization/company-logo',
            returnsData: false
        })

        if(ok) {
            this.setState(({ organization }) => ({
                organization: {
                    ...organization,
                    companyLogo: null
                }
            }), () => {
                this.syncer.postMessage({
                    organization: {
                        ...this.state.organization,
                        companyLogo: null
                    }
                })
            })
        }
    }

    fetchAuthConfiguration = async () => {
        const { ok, response } = await get({ path: '/organization/auth-configuration' })

        if(ok) {
            this.setState({ authConfiguration: response })
        }

        return { ok, response }
    }

    updateAuthConfiguration = async body => {
        const { ok, response } = await patch({
            path: '/organization/auth-configuration',
            body
        })

        if(ok) {
            this.setState({ authConfiguration: response })
        }

        return { ok, response }
    }

    enableAuthMethod = what => this.updateAuthConfiguration({ [what]: { enabled: true } })
    disableAuthMethod = what => this.updateAuthConfiguration({ [what]: { enabled: false } })

    checkDomainAvailability = key => get({
        path: '/organization/available',
        params: { key }
    })

    render() {
        const { children = null } = this.props

        return (
            <OrganizationContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </OrganizationContext.Provider>
        )
    }
}

export default props => {
    const {
        status,
        onSignOut
    } = useAuth()

    const { isItMyOwnId } = useMe()

    const {
        initialized,

        check,
        refreshModules
    } = useAccess()

    const subsidiarian = initialized && check('subsidiaries:manage')

    return (
        <OrganizationProvider
            {...props}
            authorized={status === 'authorized'}
            subsidiarian={subsidiarian}
            onSignOut={onSignOut}
            isItMyOwnId={isItMyOwnId}
            refreshModules={refreshModules} />
    )
}

export const useOrganization = () => useContext(OrganizationContext)