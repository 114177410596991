import styled from 'styled-components'
import { motion } from 'framer-motion'
import { H2 } from 'components/typography/heading'
import PersonBase from 'components/person'
import AbsenceTypeBase from 'pages/absence/components/type'
import CheckboxFieldBase from 'components/form/field/checkbox'
import TotalBalanceBase, { TotalBalanceLoader as TotalBalanceLoaderBase } from 'pages/absence/components/total-balance'
import { Plain } from 'components/button'
import MessageBase, { InlineMessage } from 'components/message'
import CaptionBase, { captionSmallCss } from 'components/typography/caption'
import { Grid } from 'components/grid'
import AbsenceTypeListItemBase from 'lists/absence/stats/type'
import { Entries } from 'pages/absence/modals/error/s'

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

export const Heading = styled(H2)`
    margin-block-end: 16px;
`

export const Fields = styled.div`
    > :last-child {
        margin-block-end: 0;
    }
`

export const Person = styled(PersonBase)`
    margin-block-end: 8px;
`

export const AbsenceType = styled(AbsenceTypeBase)`
    margin-block-end: 24px;
`

export const CheckboxField = styled(CheckboxFieldBase)`
    grid-row: 2 / 3;
    grid-column: 1 / 3;
`

export const QuickGradeButtons = styled.div`
    display: flex;
    column-gap: 4px;

    margin-block-start: 8px;
`

export const QuickGradeButton = styled(Plain)`
    font-size: 14px;
`

export const CountsAs = styled.div`
    display: flex;
    justify-content: space-between;

    border-top: 1px solid var(--huma-color-border-default);
    padding-block: 16px;
`

export const TotalBalance = styled(TotalBalanceBase)`
    margin-block-start: 0;
`

export const TotalBalanceLoader = styled(TotalBalanceLoaderBase)`
    margin-block-start: 0;
`

export const Message = styled(MessageBase)``

export const Caption = styled(CaptionBase)`
    margin-block-end: 16px;
`

export const AbsenceTypeList = styled(Grid)`
    grid-auto-rows: auto;
    grid-auto-flow: row;
    justify-content: stretch;
`

export const AbsenceTypeListItem = styled(AbsenceTypeListItemBase)`
    &:where(:hover, :focus) {
        .add-button {
            transform: translateX(4px);

            color: var(--huma-color-foreground-constructive-bold);
        }
    }

    .add-button {
        width: auto;
        padding: 0;

        transition: transform .15s ease-in-out;

        color: var(--huma-color-foreground-default);
    }
`

export const Information = styled(motion.p).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            marginBottom: 0,
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            marginBottom: 16,
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    ${captionSmallCss}
    position: relative;
    top: -16px;

    &.horizontal {
        top: -8px;
        border-bottom: 8px solid transparent;
    }
`

export const InfoMessage = styled(InlineMessage)`
    margin-block: 24px 0;
`

export const OverlappingEntries = styled(Entries)`
    margin-block-end: 24px;
`