import React, { useState, forwardRef, Fragment } from 'react'
import { isAfter, isSameDay } from 'date-fns'
import { useInterval } from 'hooks/interval'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import {
    WrapperClickable, WrapperFragment,
    Avatar, MobileAvatar, Name, Type, Emoji, PercentageLabel, DateCell
} from './s'
import { getFullName } from 'utilities/person'
import Ellipsify from 'components/ellipsify'
import AbsenceDate from 'pages/absence/components/date'
import { ArrowRight } from 'styled-icons/feather'

const Entry = forwardRef(({ entry, actions = {} }, ref) => {
    const {
        user,
        start,
        end,
        type,
        grade = 1
    } = entry

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 60 * 60 * 1000)

    const sameDay = (!!start && !!end && isSameDay(start, end))

    const name = useTypeNameFormatter()(type)

    const view = actions?.view?.(entry)

    const Wrapper = view ?
        WrapperClickable :
        WrapperFragment

    const wrapperProps = view ? {
        onClick: () => {
            view.onClick()
            ref?.current?.blur()
        }
    } : null

    return (
        <Wrapper
            {...wrapperProps}
            ref={ref}>
            <Avatar
                who={user}
                size={20} />
            <MobileAvatar
                who={user}
                size={40} />
            <Name>
                {getFullName(user)}
            </Name>
            <Type className="compact">
                <Emoji
                    emoji={type.symbol.emoji}
                    size={16} />
                <Ellipsify
                    text={name}
                    head={5} />
                {grade < 1 && <PercentageLabel decimal={grade} />}
            </Type>
            <DateCell>
                <AbsenceDate
                    as={Fragment}
                    dateRangeWrapper={Fragment}
                    separator={<ArrowRight size={14} />}
                    showStartDate={!!isAfter(start, now) || !!sameDay}
                    hideSameYear={true}
                    user={user}
                    start={start}
                    end={end} />
            </DateCell>
        </Wrapper>
    )
})

export default Entry
