import styled from 'styled-components'
import Paragraph from 'components/typography/paragraph'
import {
    DefinitionList as DefinitionListBase,
    DefinitionTerm as DefinitionTermBase,
    DefinitionDescription as DefinitionDescriptionBase
} from 'components/list'
import { InlineMessage as InlineMessageBase } from 'components/message'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    min-width: 200px;
    padding: 8px;
`

export const Row = styled.div`
    padding-block: 8px;

    &:first-child {
        padding-block-start: 0;
    }

    &:last-child {
        padding-block-end: 0;
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }

    &.no-border {
        border-bottom: unset;
        padding-block-end: 0;

        & + div {
            padding-block-start: 4px;
        }
    }
`

export const Name = styled(Paragraph)`
    font-weight: 500;
`

export const DefinitionList = styled(DefinitionListBase)`
    row-gap: 4px;
`

export const DefinitionTerm = styled(DefinitionTermBase)`
    display: inline-flex;
    align-items: center;
    column-gap: 4px;

    font-size: 14px;
`

export const DefinitionDescription = styled(DefinitionDescriptionBase)`
    font-weight: 500;
    text-align: end;
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-block-start: 4px;
    margin-inline-start: -2px;
`