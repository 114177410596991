import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { useIdentification } from 'hooks/identification'
import { useVoiceflow } from 'hooks/voiceflow'
import { usePageView } from 'hooks/page-view'
import { useKeyboardShortcuts } from 'hooks/keyboard-shortcuts'
import { useOrganization } from 'contexts/organization'
import ServiceOnboardingProvider from 'contexts/service-onboarding'
import { useSize } from 'hooks/viewport'
import paths from 'app/paths'
import { size } from 'utilities/object'
import { numbers as mediaQueryBreakpoints } from 'utilities/styled'
import Banner from './banner'
import {
    HeaderWrap, Header, HeaderStart, HeaderMiddle, HeaderEnd,
    MenuTrigger, MenuBars, ImageLogo, TextLogo,
    Main, Aside, AsideInside, Overlay,
    Content
} from './s'
import Help from './help'
import AnnouncementsBadge from './help/quick-guides/items/announcements'
import MyActions from './my-actions'
import Navigation from './navigation'
import TrialStatus from './trial-status'
import SidePanel from './panel/side'
import BottomPanel from './panel/bottom'
import ServiceOnboarding from './service-onboarding'
import EmailVerification from 'components/email-verification'
import SurveyRespond from 'components/survey-respond'
import Value from './value'
import QuickGuide from './quick-guide'
import FeatureRoadmap from './feature-roadmap'
import JubileeGift from './jubilee-gift'
import Announcements from './announcements'
import CommandBar from './command-bar'

const sidePanelWidth = 400
const sidePanelGutter = 24

const PrivateLayout = ({ children }) => {
    const { pathname } = useLocation()
    const [urlSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const viewportWidth = useSize({ dimension: 'width' })

    // Scroll to top when navigating
    useEffect(() => {
        global.scrollTo(0, 0)
    }, [pathname])

    const searchParams = Object.fromEntries(urlSearchParams)

    useEffect(() => {
        if(size(searchParams)) {
            if(searchParams.code && searchParams.state) {
                // This is a login redirect. We are, however, already logged in.
                // We therefore redirect to the dashboard and remove query params.
                navigate(paths.root, { replace: true })
            }
        }
    }, [JSON.stringify(searchParams)])

    useIdentification()
    useVoiceflow()

    const aside = useRef()
    const asideInside = useRef()
    const content = useRef()
    const menuTrigger = useRef()

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [gutterWidth, setGutterWidth] = useState(0)
    const [contentMaxWidth, setContentMaxWidth] = useState('0')
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
    const [isBottomPanelOpen, setIsBottomPanelOpen] = useState(false)

    usePageView('menu', [!!isMenuOpen])

    const { organization = {} } = useOrganization()

    const { full: fullWidth } = mediaQueryBreakpoints

    useEffect(() => {
        if(viewportWidth > fullWidth) {
            setGutterWidth(viewportWidth - fullWidth)
        } else {
            setGutterWidth(0)
        }
    }, [viewportWidth])

    useEffect(() => {
        if(((viewportWidth - sidePanelGutter) > fullWidth) && isSidePanelOpen) {
            const { width: asideWidth } = aside.current?.getBoundingClientRect?.() ?? {}

            setContentMaxWidth(`${viewportWidth - asideWidth - sidePanelWidth - (gutterWidth / 2) - sidePanelGutter}px`)
        } else {
            setContentMaxWidth('100%')
        }
    }, [viewportWidth, gutterWidth, isSidePanelOpen])

    const handleClick = e => {
        if(aside.current?.contains(e.target) || menuTrigger.current?.contains(e.target)) {
            return
        }

        setIsMenuOpen(false)
    }

    useKeyboardShortcuts({
        keys: { 'k': ['command-bar.focus'] },
        options: { shouldPreventDefault: true }
    })

    useEffect(() => {
        document.documentElement.setAttribute('data-authorized', 'yes')
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#632340')

        document.addEventListener('click', handleClick)
        return () => document.removeEventListener('click', handleClick)
    }, [])

    const { name } = organization ?? {}
    const logoClassName = name?.length > 12 ?
        'medium' :
        'big'

    return (
        <ServiceOnboardingProvider>
            <Banner
                isMenuOpen={isMenuOpen}
                isSidePanelOpen={isSidePanelOpen} />
            <HeaderWrap>
                <Header>
                    <HeaderStart>
                        <MenuTrigger
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            $open={isMenuOpen}
                            id="nav-main-toggle"
                            ref={menuTrigger}>
                            <MenuBars />
                        </MenuTrigger>
                        {!!organization?.companyLogo && (
                            <ImageLogo
                                className={logoClassName}
                                logo={organization.companyLogo}
                                link={true}
                                alt={name} />
                        )}
                        {!organization?.companyLogo && (
                            <TextLogo
                                link={true}
                                title={name}
                                textLength={name?.length}
                                id="logo-link">
                                {name}
                            </TextLogo>
                        )}
                    </HeaderStart>
                    <HeaderMiddle>
                        <CommandBar />
                    </HeaderMiddle>
                    <HeaderEnd>
                        <MyActions />
                        <AnnouncementsBadge context="header" />
                        <Help />
                    </HeaderEnd>
                </Header>
            </HeaderWrap>
            <Main
                $isBottomPanelOpen={isBottomPanelOpen}
                style={{
                    '--content-max-width': contentMaxWidth,
                    '--side-panel-width': `${sidePanelWidth}px`,
                    '--side-panel-padding': '32px'
                }}>
                <Overlay $isVisible={isMenuOpen} />
                <Aside
                    $isVisible={isMenuOpen}
                    ref={aside}>
                    <AsideInside ref={asideInside}>
                        <Navigation
                            parentRefs={[aside, asideInside]}
                            closeMenu={() => setIsMenuOpen(false)} />
                        <TrialStatus />
                    </AsideInside>
                </Aside>
                <Content
                    id="content"
                    $isSidePanelOpen={isSidePanelOpen}
                    $isBottomPanelOpen={isBottomPanelOpen}
                    ref={content}>
                    {children}
                </Content>
                <BottomPanel
                    isOpen={isBottomPanelOpen}
                    setIsOpen={setIsBottomPanelOpen} />
                <SidePanel
                    isOpen={isSidePanelOpen}
                    setIsOpen={setIsSidePanelOpen} />
            </Main>
            <ServiceOnboarding />
            <EmailVerification />
            <SurveyRespond />
            <Value />
            <QuickGuide />
            <FeatureRoadmap />
            <JubileeGift />
            <Announcements />
        </ServiceOnboardingProvider>
    )
}

export default PrivateLayout