import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { getOnboardingOnboardingUrl, getOffboardingOffboardingUrl, getProcessesProcessUrl } from 'utilities/url'
import { getOngoingTitleTranslationByType } from 'pages/processes/utilities'
import { Wrapper, Link, ArrowRight, ProgressWrapper, Buffer, ProgressBar, InlineMessage } from './s'
import Paragraph, { ParagraphSmall } from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

const OngoingEvent = ({ type, id, stats }) => {
    const { formatMessage } = useIntl()

    const {
        completedTaskCount = 0,
        taskCount = 0,
        overdueTaskCount = 0
    } = stats ?? {}

    let progress = Math.round(completedTaskCount / taskCount * 100)
    if(isNaN(progress)) {
        progress = 0
    }

    if(progress === 100) {
        return null
    }

    const getUrl = {
        onboarding: getOnboardingOnboardingUrl,
        offboarding: getOffboardingOffboardingUrl,
        process: getProcessesProcessUrl
    }[type]

    return (
        <Wrapper className="with-stats">
            <Link to={getUrl({ id })}>
                <Paragraph className="compact">
                    <FormattedMessage {...getOngoingTitleTranslationByType(type)} />
                </Paragraph>
                <ArrowRight size={24} />
            </Link>
            <ProgressWrapper>
                <Buffer role="progressbar">
                    <ProgressBar $value={progress} />
                </Buffer>
                <ParagraphSmall className="compact">{progress}% <CaptionSmall as="span" className="compact">({completedTaskCount} / {taskCount})</CaptionSmall></ParagraphSmall>
            </ProgressWrapper>
            {!!overdueTaskCount && (
                <div>
                    <InlineMessage
                        message={formatMessage({
                            id: 'employee_onboarding_tasks_overdue_count',
                            defaultMessage: '{count, plural, =0 {} =1 {1 task overdue} other {{count} tasks overdue}}'
                        }, { count: overdueTaskCount })}
                        type="warning" />
                    {/* {!!unassignedTasks && (
                        <InlineMessage
                        message={formatMessage({
                            id: 'employee_onboarding_tasks_unassigned_count',
                            defaultMessage: '{count, plural, =0 {} =1 {1 task unassigned} other {{count} tasks unassigned}}'
                        }, { count: unassignedTasks })}
                        type="error" />
                    )} */}
                </div>
            )}
        </Wrapper>
    )
}

export default OngoingEvent
