import React, { Component, createContext, useContext } from 'react'
import { useAccess } from 'contexts/access'
import { get, post, put, remove } from 'api'
import isEqual from 'react-fast-compare'

export const ArticleContext = createContext()

class ArticleProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)

        this.state = {
            article: null,
            changes: null,
            editable: props.editable,
            changed: false,
            saving: false,
            publishing: false,
            deleted: false,

            fetchArticle: this.fetch,
            setChanges: this.setChanges,
            saveArticle: this.save,
            publishArticle: this.publish,
            unpublishArticle: this.unpublish,
            removeArticle: this.remove,

            uploadFeaturedImage: this.uploadFeaturedImage,
            removeFeaturedImage: this.removeFeaturedImage,

            readArticle: this.read
        }
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate({ id, editable: previousEditable }) {
        if(id !== this.props.id) {
            this.setId(this.props.id)
            this.replace()
        }

        if(previousEditable !== this.props.editable) {
            this.setState({ editable: this.props.editable })
        }
    }

    setId = id => this.id = id

    fetch = async () => {
        const { response: article, ok } = await get({ path: `/articles/${this.id}` })

        if(ok && article) {
            this.setState({ article })
        }
    }

    // This endpoint chews all the fields
    save = async (settings = null, richSettings = null) => {
        this.setState({ saving: true })

        const { article, changes } = this.state

        const body = {
            ...article,
            ...changes,
            ...settings
        }

        const { ok } = await put({
            path: `/articles/${this.id}`,
            body,
            returnsData: false
        })

        if(ok) {
            this.setState({
                article: {
                    ...body,
                    ...richSettings
                },
                changes: null,
                changed: false,
                saving: false
            })
        }

        return { ok }
    }

    uploadImage = (urlSegment, stateField) => async body => {
        const { ok, response } = await post({
            path: `/articles/${this.id}/${urlSegment}`,
            body
        })

        if(ok && response) {
            this.setState(({ article }) => ({
                article: {
                    ...article,
                    [stateField]: response
                }
            }))
        }

        return { ok, response }
    }

    uploadFeaturedImage = this.uploadImage('featured-image', 'featuredImage')

    removeImage = (urlSegment, stateField) => async () => {
        const { ok } = await remove({
            path: `/articles/${this.id}/${urlSegment}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ article }) => ({
                article: {
                    ...article,
                    [stateField]: null
                }
            }))
        }

        return { ok }
    }

    removeFeaturedImage = this.removeImage('featured-image', 'featuredImage')

    // The publish endpoint only accepts shares, notify & tags
    publish = async (settings, richSettings) => {
        this.setState({ publishing: true })

        if(this.state.changed) {
            const { ok: saved } = await this.save()

            if(!saved) {
                return { ok: saved }
            }
        }

        const { ok } = await put({
            path: `/articles/${this.id}/publish`,
            body: settings
        })

        if(ok) {
            this.setState(({ article }) => ({
                article: {
                    ...article,
                    ...settings,
                    ...richSettings,
                    status: 'published'
                },
                changes: null,
                changed: false,
                publishing: false
            }))
        }

        return { ok }
    }

    unpublish = async () => {
        const { ok } = await put({
            path: `/articles/${this.id}/unpublish`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ article }) => ({
                article: {
                    ...article,
                    status: 'draft'
                },
                changes: null,
                changed: false
            }))
        }

        return { ok }
    }

    remove = async () => {
        const { ok } = await remove({
            path: `/articles/${this.id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    // TODO: Possible live updating of stats if a news:manage admin reads
    read = () => post({
        path: `/articles/${this.id}/read`,
        returnsData: false
    })

    setChanges = update => void this.setState(({ article, changes }) => {
        changes = {
            ...article,
            ...changes,
            ...update
        }

        return {
            changes,
            changed: !isEqual(article, changes)
        }
    })

    replace = () => this.setState({ article: null }, this.fetch)

    render() {
        const { children = null } = this.props

        return (
            <ArticleContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ArticleContext.Provider>
        )
    }
}

export default props => {
    const { check } = useAccess()
    const manageAccess = check('articles:manage')

    return (
        <ArticleProvider
            {...props}
            editable={manageAccess} />
    )
}

export const useArticle = () => useContext(ArticleContext)