import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import paths from 'app/paths'
import { useStorage } from 'hooks/storage'
import { getCategoryIcon } from 'utilities/categories'
import { get } from 'utilities/object'
import { prune } from 'utilities/array'
import { cls } from 'utilities/dom'
import {
    Revealer,
    MenuItemLink as Link,
    MenuItemLinkExternal as LinkExternal,
    MenuItem as Dumb,
    MenuItemText as Text,
    MenuItemSeparator as Separator,
    IconWrapper, NewBadge
} from './s'
import { ExternalLink, ArrowUpCircle as UpgradeHint } from 'styled-icons/feather'

export const MenuItemLink = ({
    to,
    href,
    module,
    feature,
    access,
    upgradable,
    itemUpgradable = null,
    newlyAdded = false,
    nested = false,
    inert,
    icon: Icon,
    secondaryIcon: SecondaryIcon,
    className,
    children,
    ...props
}) => {
    const [dismissedNewlyAdded, setDismissedNewlyAdded] = useStorage({
        key: 'dismissed:newly-added',
        defaultValue: []
    })

    const Anchor = href ?
        LinkExternal :
        Link

    const animate = (access || upgradable || href) ?
        'show' :
        'hide'

    const id = prune([
        'nav',
        !nested && 'main',
        nested && 'nested',
        module,
        feature
    ]).join('-')

    className = cls([
        className,
        (href || upgradable || !!SecondaryIcon) && 'has-secondary-icon'
    ])

    if(itemUpgradable !== null) {
        upgradable = itemUpgradable
    }

    Icon = Icon ?? getCategoryIcon(module) ?? getCategoryIcon(feature)

    return (
        <AnimatePresence>
            <Revealer animate={animate}>
                <Anchor
                    {...props}
                    {...(to ? {
                        to: to?.startsWith('/') ?
                            to :
                            get(paths, to),
                        ...((!!newlyAdded && !!module && !dismissedNewlyAdded.includes(module)) ? {
                            onClick: () => setTimeout(() => setDismissedNewlyAdded([
                                ...dismissedNewlyAdded,
                                `module:${module}`
                            ]), 1500)
                        } : null)
                    } : null)}
                    {...(href ? {
                        href,
                        target: '_blank'
                    } : null)}
                    id={id}
                    {...(className ? { className } : null)}
                    {...((inert || (animate === 'hide')) ? { inert: 'true' } : null)}>
                    {(animate === 'show') && (
                        <>
                            {!!Icon && (
                                <IconWrapper>
                                    {(!!newlyAdded && !dismissedNewlyAdded.includes(`module:${module}`)) && (
                                        <NewBadge>
                                            <FormattedMessage
                                                id="noun_new"
                                                defaultMessage="New" />
                                        </NewBadge>
                                    )}
                                    <Icon
                                        className="primary"
                                        size={24} />
                                </IconWrapper>
                            )}
                            <Text>
                                {children}
                            </Text>
                            {(!!href && !SecondaryIcon) && (
                                <ExternalLink
                                    className="secondary external"
                                    size={16} />
                            )}
                            {!!upgradable && (
                                <UpgradeHint
                                    className="secondary upgrade"
                                    size={16} />
                            )}
                            {(!upgradable && !!SecondaryIcon) && (
                                <SecondaryIcon
                                    className="secondary custom"
                                    size={16} />
                            )}
                        </>
                    )}
                </Anchor>
            </Revealer>
        </AnimatePresence>
    )
}

export const MenuItem = ({
    identifier,
    nested = false,
    inert,
    show = false,
    className,
    children,
    ...props
}) => {
    const animate = show ?
        'show' :
        'hide'

    const id = prune([
        'nav',
        !nested && 'main',
        nested && 'nested',
        identifier
    ]).join('-')

    return (
        <AnimatePresence>
            <Revealer animate={animate}>
                <Dumb
                    {...props}
                    id={id}
                    {...(className ? { className } : null)}
                    {...((inert || (animate === 'hide')) ? { inert: 'true' } : null)}>
                    {(animate === 'show') && children}
                </Dumb>
            </Revealer>
        </AnimatePresence>
    )
}

export const MenuItemSeparator = ({ animate, ...props }) => (
    <AnimatePresence>
        <Revealer animate={animate}>
            <Separator
                {...props}
                className="compact" />
        </Revealer>
    </AnimatePresence>
)