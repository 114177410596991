import React from 'react'
import { useIntl } from 'react-intl'
import { useIntegrations } from 'contexts/integrations'
import Message from 'components/message'
import WordList from 'components/word-list'

const AddPeopleIntegrationInformation = ({ salt }) => {
    const { formatMessage } = useIntl()

    const {
        hasFetched,
        getActiveUserIntegrations
    } = useIntegrations()

    if(!hasFetched) {
        return null
    }

    const activeNonCreatingUserIntegrationNames = getActiveUserIntegrations(
        ({ scopes }) => !scopes.userProfile?.operations?.includes('create')
    ).map(({ name }) => name)

    if(!activeNonCreatingUserIntegrationNames.length) {
        return null
    }

    return (
        <Message
            type="info"
            title={formatMessage({
                id: 'person_message_integrations_add_users_manually_title',
                defaultMessage: 'Synchronization of users and integrations'
            })}
            message={formatMessage({
                id: (activeNonCreatingUserIntegrationNames.length === 1) ?
                    'person_message_integration_singular_add_users_manually' :
                    'person_message_generic_add_users_manually',
                defaultMessage: (activeNonCreatingUserIntegrationNames.length === 1) ?
                    'Adding users to Huma will not automatically create them in {integrationName}. To do that, you need to resynchronize.' :
                    'Adding users to Huma will not automatically create them in either {integrationNames}. To do that, you need to resynchronize.'
            }, {
                integrationName: <strong>{activeNonCreatingUserIntegrationNames[0]}</strong>,
                integrationNames: (
                    <WordList
                        words={activeNonCreatingUserIntegrationNames}
                        join="or"
                        wordRenderer={word => <strong>{word}</strong>} />
                )
            })}
            name={`${salt}:synchronization:${activeNonCreatingUserIntegrationNames.join('+')}`}
            dismissedProps={{ className: 'spacious' }} />
    )
}

export default AddPeopleIntegrationInformation

// TODO later: Move over to the below component,
// which differentiates between unsupported and disabled integrations

// import React from 'react'
// import { useIntl } from 'react-intl'
// import { useIntegrations } from 'contexts/integrations'
// import { compact } from 'utilities/array'
// import Message from 'components/message'
// import WordList from 'components/word-list'
//
// const AddPeopleIntegrationInformation = ({ salt }) => {
//     const { formatMessage } = useIntl()
//
//     const { integrations } = useIntegrations()
//     if(!integrations?.length) {
//         return null
//     }
//
//     const activeUserIntegrations = integrations
//         .filter(({ status, scopes }) => [
//             ['connected', 'active'].includes(status), // status === 'active'
//             !!scopes?.userProfile
//         ].every(Boolean))
//
//     const activeUserIntegrationsCreatingUsersUnsupported = activeUserIntegrations
//         .filter(({ scopes }) => !scopes?.userProfile?.operations?.includes('create'))
//
//     const activeUserIntegrationsCreatingUsersDisabled = activeUserIntegrations
//         .filter(({ scopes, configParams }) => [
//             scopes?.userProfile?.operations?.includes('create'),
//             configParams?.find(({ name }) => name === 'createNewUserInIntegration')?.value ?? false
//         ].every(Boolean))
//
//     if(!activeUserIntegrationsCreatingUsersUnsupported?.length && !activeUserIntegrationsCreatingUsersDisabled?.length) {
//         return null
//     }
//
//     const messages = compact([
//         !!activeUserIntegrationsCreatingUsersUnsupported?.length && formatMessage({
//             id: (activeUserIntegrationsCreatingUsersUnsupported.length === 1) ?
//                 'person_message_integration_singular_add_users_automatically_unsupported' :
//                 'person_message_integration_plural_add_users_automatically_unsupported',
//             defaultMessage: (activeUserIntegrationsCreatingUsersUnsupported.length === 1) ?
//                 'Adding users to Huma will not automatically create them in {integrationName}. To do that, you need to resynchronize.' :
//                 'Adding users to Huma will not automatically create them in either {integrationNames}. To do that, you need to resynchronize.'
//         }, {
//             integrationName: <strong>{activeUserIntegrationsCreatingUsersUnsupported[0].name}</strong>,
//             integrationNames: (
//                 <WordList
//                     words={activeUserIntegrationsCreatingUsersUnsupported.map(({ name }) => name)}
//                     join="or"
//                     wordRenderer={name => <strong>{name}</strong>} />
//             )
//         }),
//         !!activeUserIntegrationsCreatingUsersDisabled?.length && formatMessage({
//             id: (activeUserIntegrationsCreatingUsersDisabled.length === 1) ?
//                 'person_message_integration_singular_add_users_automatically_disabled' :
//                 'person_message_integration_plural_add_users_automatically_disabled',
//             defaultMessage: (activeUserIntegrationsCreatingUsersDisabled.length === 1) ?
//                 'Automatic creation of users in {integrationName} is supported, but not enabled. To have the user created there, you need to resynchronize.' :
//                 'Automatic creation of users in {integrationNames} is supported, but not enabled. To have the users created there, you need to resynchronize.'
//         }, {
//             integrationName: <strong>{activeUserIntegrationsCreatingUsersDisabled[0].name}</strong>,
//             integrationNames: (
//                 <WordList
//                     words={activeUserIntegrationsCreatingUsersDisabled.map(({ name }) => name)}
//                     join="and"
//                     wordRenderer={name => <strong>{name}</strong>} />
//             )
//         })
//     ])
//
//     return (
//         <Message
//             type="info"
//             title={formatMessage({
//                 id: 'person_message_integrations_add_users_manually_title',
//                 defaultMessage: 'Automatic creation of users in integrated systems'
//             })}
//             message={messages.join('\n\n')}
//             name={[
//                 salt,
//                 'automatic-creation',
//                 'unsupported',
//                 activeUserIntegrationsCreatingUsersUnsupported.map(({ name }) => name).join('+'),
//                 'disabled',
//                 activeUserIntegrationsCreatingUsersDisabled.map(({ name }) => name).join('+')
//             ].join(':')} />
//     )
// }
//
// export default AddPeopleIntegrationInformation