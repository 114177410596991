import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useOrganization } from 'contexts/organization'
import { useStorage } from 'hooks/storage'
import { supportedLocales } from 'pages/people/panes/everyone/invite/inspect-invitation-modal'

export const useInvitationData = () => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()
    const { organization } = useOrganization()

    const defaultData = {
        useMessage: false,
        message: '',
        subject: formatMessage({
            id: 'email_invitation_template_header_inviter',
            defaultMessage: '{organization} has invited you to Huma'
        }, { organization: organization?.name }),
        language: supportedLocales.includes(locale) ? locale : supportedLocales[0],
        changed: false
    }

    const [invitationData, setInvitationData] = useState(defaultData)

    const [savedData, setSavedData] = useStorage({ key: `${organization?.id}:invitation-data` })

    return {
        invitationData,
        setInvitationData,
        savedData,
        setSavedData,
        defaultData
    }
}