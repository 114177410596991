import styled from 'styled-components'
import { interpointDivider } from 'utilities/styled'
import { CaptionSmall } from 'components/typography/caption'
import Paragraph from 'components/typography/paragraph'
import TrendBase from '../trend'
import NoteBase from 'components/note'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;

    > :last-child {
        margin-block-end: 0;
    }
`

export const Date = styled(CaptionSmall)`
    margin-block-end: 4px;

    & .error {
        font-weight: 500;
        color: var(--huma-color-foreground-destructive);
    }
`

export const Salary = styled(Paragraph)`
    margin-block-end: 8px;

    font-weight: 500;
`

export const Trend = styled(TrendBase)`
    margin-block: -8px 8px;
`

export const Note = styled(NoteBase)`
    margin-block-end: 16px;
`

export const Meta = styled.div`
    display: flex;
    flex-wrap: wrap;

    line-height: 24px;
    color: var(--huma-color-foreground-subtle);

    ${interpointDivider()}
`