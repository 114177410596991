import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { sendAppSignal } from 'hooks/app-signal'
import { FlexChildShrink as Row } from 'components/flex'
import {
    Important, ImportantInner,
    DrawablePanel, Header, Emoji,
    Categories, Centered
} from './s'
import { H3 as Heading } from 'components/typography/heading'
// import Progress from 'components/service-onboarding/progress'
import { Plain } from 'components/button'

const ServiceOnboarding = () => {
    const {
        onboarder,
        progress
    } = useServiceOnboarding()

    if(!onboarder || progress === 100) {
        return null
    }

    return (
        <Row>
            <Important>
                <ImportantInner>
                    <DrawablePanel
                        open={true}
                        layout="dashboard"
                        header={(
                            <Header>
                                <Emoji>⚡️</Emoji>
                                <Heading className="compact">
                                    <FormattedMessage
                                        id="service_onboarding_progress_heading"
                                        defaultMessage="Get started with Huma" />
                                </Heading>
                            </Header>
                        )}
                        salt="dashboard:service-onboarding">
                        <Categories salt="dashboard" />
                        <Centered>
                            <Plain
                                className="constructive"
                                onClick={() => sendAppSignal('service-onboarding.show')}>
                                <FormattedMessage
                                    id="tasks_action_see_all"
                                    defaultMessage="See all tasks" />
                            </Plain>
                        </Centered>
                    </DrawablePanel>
                </ImportantInner>
            </Important>
        </Row>
    )
}

export default ServiceOnboarding