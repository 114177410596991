import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useFlash } from 'hooks/flash'
import { categoryToMetaMap, categoryToEmojiMap, categoryToColorTokenMap, categoryLevelMap } from 'pages/competence/constants/category'
import {
    Heading, Description,
    Levels, Level, LevelTitle, LevelDescription, Abbr
} from './s'
import Symbol from 'components/symbol'
import { CloseButton } from 'components/button'
import Tooltip from 'components/tooltip'

const DefinitionsModal = ({ type, levels = 5, focus = null, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const flasher = useFlash(focus)

    if(focus) {
        setTimeout(() => {
            const levelElement = document.getElementById(`${salt}:definitions:level:${focus}`)

            if(levelElement) {
                levelElement.scrollIntoView()
            }
        }, 25)
    }

    const descriptionId = categoryToMetaMap[type]?.description?.id
    const description = descriptionId ? formatMessage({ id: descriptionId }) : null
    const hasDescription = descriptionId && description !== descriptionId

    return (
        <>
            <Symbol
                symbol={{
                    emoji: categoryToEmojiMap[type],
                    colorToken: categoryToColorTokenMap[type]
                }}
                size={56} />
            <CloseButton onClick={dismiss} />
            <Heading>
                <FormattedMessage {...categoryToMetaMap[type].title} />
            </Heading>
            {hasDescription && <Description>{description}</Description>}
            <Levels>
                {[...Array(levels).keys()].map(index => {
                    const level = index + 1

                    const subTitleId = categoryLevelMap[type][level].subTitle?.id
                    const subTitle = subTitleId ? formatMessage({ id: subTitleId }) : null
                    const hasSubTitle = subTitleId && subTitle !== subTitleId

                    return (
                        <Level
                            {...(focus === level ? { ref: flasher } : null)}
                            id={`${salt}:definitions:level:${level}`}
                            key={`${salt}:definitions:level:${level}`}>
                            <LevelTitle>
                                <strong>
                                    <FormattedMessage {...categoryLevelMap[type][level].title} />
                                </strong>
                                {hasSubTitle && <span>({subTitle})</span>}
                            </LevelTitle>
                            <LevelDescription className="preserve-line-breaks">
                                <FormattedMessage
                                    {...categoryLevelMap[type][level].description}
                                    values={{
                                        abbr: text => {
                                            const [abbreviation] = text

                                            return (
                                                <Tooltip content={formatMessage({
                                                    id: `competence_${type}_${abbreviation.toLowerCase()}_abbreviation`,
                                                    defaultMessage: text
                                                })}>
                                                    <Abbr>{text}</Abbr>
                                                </Tooltip>
                                            )
                                        }
                                    }} />
                            </LevelDescription>
                        </Level>
                    )
                })}
            </Levels>
        </>
    )
}

export default DefinitionsModal
