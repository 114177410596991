import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'surveys'

const EmployeeSatisfaction = props => {
    const {
        initialized,
        checkModule,
        check
    } = useAccess()

    const access = initialized && checkModule(moduleIdentifier) && ['surveys:read', 'surveys:write'].some(check)
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="employeeSatisfaction.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}>
            <FormattedMessage
                id="noun_employee_satisfaction"
                defaultMessage="Employee satisfaction" />
        </MenuItemLink>
    )
}

export default EmployeeSatisfaction
