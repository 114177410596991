import styled from 'styled-components'
import { Grid } from 'components/grid'
import StatusLabelBase from 'components/status-label'

export const CaseStatus = styled(Grid).attrs({ as: 'span' })`
    justify-content: start;
    align-items: center;

    color: var(--huma-color-foreground-default);
`

export const StatusLabel = styled(StatusLabelBase).attrs({ as: 'span' })`
    align-self: unset;
`