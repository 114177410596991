import styled from 'styled-components'
import { Container as ContainerBase, Heading as HeadingBase } from 'pages/dashboard/s'
import { CaptionSmall } from 'components/typography/caption'
import { Columns } from 'components/flex'
import { Plain } from 'components/button'
import LinkBase from 'components/link'

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;
`

export const Heading = styled(HeadingBase)`
    margin: 0;
`

export const Container = styled(ContainerBase)`
    padding-top: 0;
    padding-bottom: 0;

    &.integrated {
        margin-top: 16px;
        padding-top: 24px;
        padding-bottom: 12px;
    }
`

export const SubHeading = CaptionSmall

export const ShowAll = styled(Columns)`
    position: relative;

    flex-wrap: wrap;
    column-gap: 4px;

    min-height: 20px;
    padding-bottom: 16px;
`

export const ShowAllCount = styled.span`
    font-size: 14px;
    line-height: 20px;
    color: var(--huma-color-foreground-subtle);
`

export const ShowAllButton = styled(Plain)`
    border: none;
    height: auto;

    font-size: 14px;
    line-height: 20px;
`

export const Link = styled(LinkBase)`
    display: inline-block;
    line-height: 24px;
    margin-top: 16px;
`