import React from 'react'
import { FormattedMessage } from 'react-intl'
import { showQuickGuide } from '../'
import { getCategoryIcon } from 'utilities/categories'
import { Item, IconWrapper, Title, ModalIcon } from './s'
import { ChevronRight } from 'styled-icons/feather'

const Icon = getCategoryIcon('documents')

const Documents = () => (
    <Item onClick={showQuickGuide('documents')}>
        <IconWrapper>
            <Icon size={24} />
        </IconWrapper>
        <Title>
            <FormattedMessage
                id="noun_documents"
                defaultMessage="Documents" />
        </Title>
        <ModalIcon>
            <ChevronRight size={24} />
        </ModalIcon>
    </Item>
)

export default Documents
