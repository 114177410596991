import React, { useState } from 'react'
import { useTimeout } from 'hooks/timeout'
import { useBillingBannerMessage } from './billing'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useMatch } from 'react-router-dom'
import paths from 'app/paths'
import { omit } from 'utilities/object'
import { Wrapper, Inner } from './s'
import { GlobalMessage } from 'components/message'

const Banner = ({ isMenuOpen, isSidePanelOpen = false }) => {
    const [ready, setReady] = useState(false)
    useTimeout(() => setReady(true), 1250)

    const billing = useBillingBannerMessage()

    const { announcements } = useServiceOnboarding()

    const atSubscriptionPage = useMatch(paths.settings.subscription.base)

    const [announcement] = announcements
        .filter(({ meta }) => !!meta.banner && !!meta.current && !meta.dismissed)

    const [message] = [
        !atSubscriptionPage && billing,
        announcement
    ].filter(Boolean)

    return (
        <Wrapper {...(!!message && ready ? { className: 'show' } : null)}>
            <Inner>
                <GlobalMessage
                    {...omit(message ?? {}, 'message', 'heading', 'meta')}
                    isMenuOpen={isMenuOpen}
                    isSidePanelOpen={isSidePanelOpen}>
                    {message?.message ?? null}
                </GlobalMessage>
            </Inner>
        </Wrapper>
    )
}

export default Banner