import { useLayoutEffect, useCallback, useRef } from 'react'

export const useTimeout = (callback, timeout = 0) => {
    const timeoutIdRef = useRef()

    const cancel = useCallback(() => {
        const timeoutId = timeoutIdRef.current

        if(timeoutId) {
            timeoutIdRef.current = undefined
            global.clearTimeout(timeoutId)
        }
    }, [timeoutIdRef])

    useLayoutEffect(() => {
        timeoutIdRef.current = global.setTimeout(callback, timeout)
        return cancel
    }, [callback, timeout, cancel])

    return cancel
}