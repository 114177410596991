import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useOrganization } from 'contexts/organization'
import { useUpgradable } from 'hooks/upgradable'
import { MenuItemLink } from './'

const moduleIdentifier = 'deviation'

const Deviation = props => {
    const {
        initialized,

        check,
        checkModule
    } = useAccess()

    const { statuses } = useOrganization()

    const enabled = initialized && checkModule(moduleIdentifier)
    const isSetUp = statuses?.hasSetUpDeviation
    const access = enabled && (check('organization:manage') || isSetUp)
    const upgradable = useUpgradable()({ module: moduleIdentifier })

    return (
        <MenuItemLink
            {...props}
            to="deviation.base"
            module={moduleIdentifier}
            access={access}
            upgradable={upgradable}>
            <FormattedMessage
                id="noun_deviation_plural"
                defaultMessage="Deviations" />
        </MenuItemLink>
    )
}

export default Deviation
