import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useProcesses } from 'contexts/processes'
import { useAccess } from 'contexts/access'
import { getTypeModule } from 'pages/processes/utilities'
import { sumBy } from 'utilities/array'
import TemplatePicker from 'pages/processes/components/template-picker'
import TemplateItems from 'pages/processes/components/template-items'
import { Caption } from './s'

const AddProcessTemplateHelper = ({ templates = [], setTemplates, salt }) => {
    const { type } = useProcesses()

    const { checkFeature } = useAccess()
    const multipleTemplatesAccess = checkFeature(`${getTypeModule(type)}-multiple-templates`)

    const [selectingTemplates, setSelectingTemplates] = useState(false)

    return (
        <>
            <TemplateItems
                id={`${getTypeModule(type)}-templates`}
                type={type}
                multiple={multipleTemplatesAccess}
                templates={templates}
                setTemplates={setTemplates}
                picking={selectingTemplates}
                openPicker={() => setSelectingTemplates(true)}
                salt={salt} />
            <TemplatePicker
                show={selectingTemplates}
                type={type}
                multiple={multipleTemplatesAccess}
                dismiss={() => {
                    setTemplates(templates)
                    setSelectingTemplates(false)
                }}
                onDone={({ picked }) => {
                    setTemplates(picked)
                    setSelectingTemplates(false)
                }}
                picked={templates}
                salt={salt} />
            <Caption>
                <FormattedMessage {...getMessageTranslation(type, templates)} />
            </Caption>
        </>
    )
}

const getMessageTranslation = (type, templates) => {
    const translation = {
        onboarding: {
            id: !!templates?.length ?
                'employee_onboarding_create_helper_template_tasks_count' :
                'employee_onboarding_create_helper_template_without',
            defaultMessage: !!templates?.length ?
                '{count, plural, =0 {Start with an empty onboarding template.} =1 {Start with one predefined onboarding task.} other {Start with {count} predefined onboarding tasks.}}' :
                'Continue without an onboarding template'
        },
        offboarding: {
            id: !!templates?.length ?
                'employee_offboarding_create_helper_template_tasks_count' :
                'employee_offboarding_create_helper_template_without',
            defaultMessage: !!templates?.length ?
                '{count, plural, =0 {Start with an empty offboarding template.} =1 {Start with one predefined offboarding task.} other {Start with {count} predefined offboarding tasks.}}' :
                'Continue without an offboarding template'
        },
        process: {
            id: !!templates?.length ?
                'processes_create_helper_template_tasks_count' :
                'processes_create_helper_template_without',
            defaultMessage: !!templates?.length ?
                '{count, plural, =0 {Start with an empty process template.} =1 {Start with one predefined process task.} other {Start with {count} predefined process tasks.}}' :
                'Continue without a process template'
        }
    }[type]

    const count = templates?.some(t => t?.taskCount) ?
        sumBy(templates, 'taskCount') :
        templates?.reduce((count, { tasks = [] }) => count + tasks?.length, 0)

    return {
        ...translation,
        values: { count }
    }
}

export default AddProcessTemplateHelper