import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { UnitPermissionsProvider, useUnitPermissions } from 'contexts/unit-permissions'
import { useOrganization } from 'contexts/organization'
import { useCheckOrganizationUsersLimit } from 'hooks/check-organization-users-limit'
import { size, pick, omit } from 'utilities/object'
import { ModalHeader } from 'modals/generic'
import UpgradeCTA from 'components/upgrade-cta'
import CostIncrease from 'pages/people/panes/everyone/add/content/single/cost-increase'
import IntegrationInformation from 'pages/people/panes/everyone/add/content/integration-information'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import TimeField from 'components/form/field/time'
import PersonField from 'components/form/field/person'
import EntitiesField from 'components/form/field/entities'
import TemplateHelper from '../template-helper'
import DynamicAssignments from '../dynamic-assignments'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const AddOnboardingAddNewHire = ({
    templates, setTemplates,
    dynamicAssignmentTypes, setDynamicAssignmentTypes,
    proceed, modal, salt
}) => {
    const { formatMessage } = useIntl()
    const { createPerson } = usePerson()

    const {
        onboarder,
        updateOnboardingStatus
    } = useServiceOnboarding()

    const { checkAll } = useUnitPermissions()

    const { fetchOrganization } = useOrganization()
    const usersLimit = useCheckOrganizationUsersLimit()()

    const [adding, setAdding] = useState(false)
    const [error, setError] = useState(null)

    const [access, { general: canFetchGroupsFilterless }] = checkAll({
        system: 'users:manage',
        unit: 'unit:users:manage'
    })

    const addPerson = async body => {
        setAdding(true)

        const dynamicAssignments = pick(body, ...dynamicAssignmentTypes)
        body = omit(body, ...dynamicAssignmentTypes)

        const {
            groups,
            ...payload
        } = body

        const teams = groups?.filter(({ type }) => type === 'team')
        const locations = groups?.filter(({ type }) => type === 'location')

        const { ok, response } = await createPerson({
            ...payload,
            ...(!!teams?.length ? { teams: teams.map(({ id }) => id) } : null),
            ...(!!locations?.length ? { locations: locations.map(({ id }) => id) } : null)
        })

        setAdding(false)

        if(ok) {
            fetchOrganization()
        } else {
            if(response?.errorCode === 'identifier:duplicate' && response?.fields?.includes('email')) {
                setError(formatMessage({
                    id: 'person_error_email_duplicate',
                    defaultMessage: 'The email address is already in use'
                }))
            }
        }

        if(ok && response) {
            !!onboarder && updateOnboardingStatus({ addUser: 'completed' })

            proceed({
                concernsId: response.id,
                concernsType: 'user',
                templateIds: templates
                    ?.filter(({ providedByHuma = false }) => !providedByHuma)
                    .map(({ id }) => id) ?? [],
                humaTemplateIds: templates
                    ?.filter(({ providedByHuma = false }) => !!providedByHuma)
                    .map(({ id }) => id) ?? [],
                firstDayOfWork: body.firstDayOfWork ?? null,
                dynamicAssignments,
            })
        }
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'employee_onboarding_action_add',
                    defaultMessage: 'New onboarding'
                })}
                dismiss={modal.dismiss} />
            {!!usersLimit.reached && (
                <UpgradeCTA
                    moduleless={true}
                    useUpgradeIcon
                    title={formatMessage({
                        id: 'employees_limit_title',
                        defaultMessage: 'Users limit reached'
                    })}
                    message={formatMessage({
                        id: !!usersLimit.free ?
                            'employees_limit_buy_message'
                            : 'employees_limit_upgrade_message',
                        defaultMessage: !!usersLimit.free ?
                            'Buy Enterprise to add more than {count} people'
                            : 'Upgrade to Enterprise to add more than {count} people'
                    }, { count: usersLimit.quantity })}
                    salt={salt} />
            )}
            <CostIncrease />
            <IntegrationInformation salt={salt} />
            <Form
                layout="vertical"
                onSubmit={addPerson}>
                {({ touched, errors, trigger }) => (
                    <>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_name_given',
                                defaultMessage: 'Given name'
                            })}
                            name="givenName"
                            field={{ required: true }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_name_family',
                                defaultMessage: 'Family name'
                            })}
                            name="familyName"
                            field={{ required: true }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_emailaddress',
                                defaultMessage: 'Email address'
                            })}
                            name="email"
                            field={{ required: true }}
                            controlProps={{ type: 'email' }}
                            onChange={() => setError(null)} />
                        <TimeField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_employment_date_start',
                                defaultMessage: 'First day of work'
                            })}
                            name="firstDayOfWork"
                            field={{
                                required: true,
                                include: 'always'
                            }}
                            picker={{ past: false }} />
                        <PersonField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_supervisor',
                                defaultMessage: 'Supervisor'
                            })}
                            name="supervisor"
                            picker={{ outer: false }} />
                        <EntitiesField
                            salt={salt}
                            label={formatMessage({
                                id: 'units_teams_locations',
                                defaultMessage: 'Teams and locations'
                            })}
                            name="groups"
                            {...(!canFetchGroupsFilterless ? {
                                field: {
                                    required: true
                                }
                            } : null)}
                            picker={{
                                heading: formatMessage({
                                    id: 'groups_action_pick',
                                    defaultMessage: 'Pick groups'
                                }),
                                ...(!canFetchGroupsFilterless ? {
                                    message: {
                                        type: 'info',
                                        message: formatMessage({
                                            id: 'people_add_unit_permissions_groups_picker_disclaimer',
                                            defaultMessage: 'Only groups in which you can manage users are available.'
                                        })
                                    }
                                } : null),
                                outer: false,
                                creatable: canFetchGroupsFilterless
                            }}
                            entity={{
                                path: '/units',
                                params: {
                                    types: ['team', 'location'],
                                    ...(!canFetchGroupsFilterless ? {
                                        permission: 'unit:users:manage'
                                    } : null)
                                }
                            }} />
                        <TemplateHelper
                            templates={templates}
                            setTemplates={setTemplates}
                            salt={salt} />
                        <DynamicAssignments
                            templates={templates}
                            types={dynamicAssignmentTypes}
                            setTypes={setDynamicAssignmentTypes}
                            salt={salt} />
                        {!error && (
                            <Message
                                type="info"
                                message={formatMessage({
                                    id: 'employee_onboarding_add_new_hire_disclaimer',
                                    defaultMessage: 'The user will be added but not invited at this stage.'
                                })} />
                        )}
                        {!!error && (
                            <Message
                                type="error"
                                message={error} />
                        )}
                        <Actions className="compact">
                            <Plain
                                onClick={modal.dismiss}
                                className="neutral"
                                disabled={adding}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${adding ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors) || !!error || adding || usersLimit.reached}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_continue"
                                    defaultMessage="Continue" />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => (
    <UnitPermissionsProvider>
        <AddOnboardingAddNewHire {...props} />
    </UnitPermissionsProvider>
)