import React from 'react'
import { Scrollable as Modal } from 'modals/generic'
import Content from './content'

export const supportedLocales = ['en', 'nb', 'sv', 'fi']

const InspectInvitationModal = ({ users, show, dismiss, salt, ...props }) => (
    <Modal
        show={show}
        dismiss={dismiss}
        salt={salt}>
        <Content
            {...props}
            users={users}
            onDone={dismiss}
            salt={salt} />
    </Modal>
)

export default InspectInvitationModal
