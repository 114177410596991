import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { size } from 'utilities/object'
import { unique } from 'utilities/array'
import Form from 'components/form/controller'
import Status from './status'
import Tags from './tags'
import Shares from './shares'
import Language from './language'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const EditBulkTopics = ({ what = null, category, onDone, ...props }) => {
    const { updateTopic } = useHandbook()

    const [updating, setUpdating] = useState(false)

    if(!what) {
        return null
    }

    const Editing = {
        status: Status,
        tags: Tags,
        shares: Shares,
        language: Language
    }[what]

    if(!Editing) {
        return null
    }

    const update = async body => {
        setUpdating(true)

        const result = await Promise.all(category.topics.map(({ id, tags }) => updateTopic({
            body: {
                ...body,
                ...(what === 'tags' ? {
                    tags: unique([
                        ...tags,
                        ...body.tags
                    ])
                } : null)
            },
            id
        })))

        setUpdating(false)

        const ok = result.every(({ ok }) => ok)
        ok && onDone?.()
    }

    return (
        <Form
            layout="vertical"
            onSubmit={update}>
            {({ errors, values, trigger }) => (
                <>
                    <Editing
                        {...props}
                        category={category} />
                    <Actions>
                        <Plain onClick={onDone}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${updating ? ' loading' : ''}`}
                            disabled={[
                                !!size(errors),
                                updating,
                                (what === 'shares' && !values.shares?.length)
                            ].some(Boolean)}
                            ref={trigger}>
                            <FormattedMessage
                                id="action_save"
                                defaultMessage="Save" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default EditBulkTopics
