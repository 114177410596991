import React from 'react'
import loadable from '@loadable/component'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import paths from 'app/paths'
import { compact } from 'utilities/array'
import Hero from './hero'
import { Routes, Route } from 'react-router-dom'
import { TabsAndPanes, getPathWithoutBase } from 'components/tabs'
import RouteAuthorization from 'app/route-authorization'
import DelayedRedirect from 'components/delayed-redirect'

import MePane from './panes/me'
const TypesPane = loadable(() => import(/* webpackPrefetch: true */ './panes/types'))
const GapPane = loadable(() => import(/* webpackPrefetch: true */ './panes/gap'))
const ProfilesPane = loadable(() => import(/* webpackPrefetch: true */ './panes/profiles'))

const TypePage = loadable(() => import(/* webpackPrefetch: true */ './pages/type'))

const salt = 'competence'

const CompetenceRouting = ({ upgradable }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const checkUpgradable = useUpgradable()

    const { base } = paths.competence
    const getRelativePath = getPathWithoutBase(base)

    const panes = compact([
        {
            path: base,
            label: formatMessage({
                id: 'competence_section_your_competence',
                defaultMessage: 'Your competence'
            }),
            pane: MePane
        },
        {
            path: paths.competence.types,
            label: formatMessage({
                id: 'competence_section_organization',
                defaultMessage: 'All competences'
            }),
            pane: TypesPane
        },
        manageAccess && {
            path: paths.competence.gap,
            label: formatMessage({
                id: 'competence_section_gap_analysis',
                defaultMessage: 'Gap analysis'
            }),
            pane: GapPane,
            upgradable: checkUpgradable({ feature: 'competence-gap' })
        },
        manageAccess && {
            path: paths.competence.profiles,
            label: formatMessage({
                id: 'competence_section_profiles',
                defaultMessage: 'Profiles'
            }),
            pane: ProfilesPane,
            upgradable: checkUpgradable({ feature: 'competence-profiles' })
        }
    ]).map(pane => ({
        ...pane,
        upgradable: pane.upgradable ?? upgradable
    }))

    return (
        <Routes>
            <Route element={(
                <>
                    <Hero
                        upgradable={upgradable}
                        salt={salt} />
                    <TabsAndPanes
                        panes={panes}
                        salt={salt} />
                </>
            )}>
                {panes.map(({ path, pane: Pane, label, upgradable, itemSalt, ...props }) => {
                    const key = `${salt}:pane:${itemSalt ?? label.toLowerCase()}`

                    return (
                        <Route
                            path={getRelativePath(path)}
                            element={(
                                <RouteAuthorization
                                    module="competence"
                                    upgradable={upgradable}>
                                    <Pane
                                        {...props}
                                        title={label}
                                        salt={salt}
                                        key={key} />
                                </RouteAuthorization>
                            )}
                            key={`${key}:route`} />
                    )
                })}
            </Route>
            <Route
                path={getRelativePath(paths.competence.type)}
                element={(
                    <RouteAuthorization module="competence">
                        <TypePage />
                    </RouteAuthorization>
                )} />
            <Route
                path="*"
                element={<DelayedRedirect to={base} />} />
        </Routes>
    )
}

export default CompetenceRouting