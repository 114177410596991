import styled from 'styled-components'
import SymbolBase from 'components/symbol'
import { H2 } from 'components/typography/heading'
import ContextMenuBase from 'widgets/context-menu/flat'

export const Symbol = styled(SymbolBase)`
    margin-block-end: 16px;
    width: 56px;
    height: 56px;

    font-size: 26px;
`

export const Heading = styled(H2)`
    margin-block-end: 4px;

    line-height: 30px;
`

export const ContextMenu = styled(ContextMenuBase)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    overflow: hidden;
`