import React, { useRef, useEffect, useCallback, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useConfiguration } from 'contexts/configuration'
import { useHistate } from 'hooks/histate'
import { anyFieldsDisplayable, anyFieldsEditable, fieldDisplayable } from 'utilities/access'
import { size } from 'utilities/object'
import { v4 as uuid } from 'uuid'
import { SectionHero, isFieldImportant } from './'
import { Container, Heading, Spacer, IntegratedHelper } from './s'
import { InlineMessage } from 'components/message'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import AddressField from 'components/form/field/address'
import OneOfField from 'components/form/field/one-of'
import CountryField from 'components/form/field/country'
import { ButtonSubmit } from 'components/button'
import CustomFields, { getCustomFieldsBySection } from './custom-fields'

const PersonalSection = ({ lookingAtMyOwnProfile, layout, editingSection = null, setEditingSection, salt }) => {
    const { formatMessage } = useIntl()

    const {
        person,
        updatePerson
    } = usePerson()

    const customGenderUuid = useRef(uuid()) // Just to avoid name clashes
    const customGenderField = useRef()

    const getCustomFields = useCallback(
        () => getCustomFieldsBySection(person, 'personalia'),
        [Object.values(person?.custom ?? {})?.map(({ value }) => value).join('+'), person.status.active]
    )

    const { configuration } = useConfiguration()

    const [editing, setEditing] = useState(false)
    const [saving, setSaving] = useState(false)

    const genderState = useHistate(person?.gender?.value ?? null)
    const civilStatusState = useHistate(person?.civilStatus?.value ?? null)

    const update = async (_, { nested: body, resetTouched }) => {
        setSaving(true)

        body = {
            ...body,
            ...(genderState.changed ? { gender: genderState.current || null } : null),
            ...(civilStatusState.changed ? { civilStatus: civilStatusState.current || null } : null)
        }

        const { ok } = await updatePerson(body)

        setSaving(false)
        setEditing(false)

        if(ok) {
            resetTouched()

            if('gender' in body) {
                genderState.reinitialize()
            }

            if('civilStatus' in body) {
                civilStatusState.reinitialize()
            }
        }
    }

    useEffect(() => {
        if(editingSection === 'personalia') {
            setEditing(true)
        }
    }, [editingSection])

    const {
        givenName,
        preferredName,
        familyName,
        privateEmail,
        gender,
        homeAddress,
        nationality,
        civilStatus,
        dietaryRequirements
    } = person

    const customFields = getCustomFields()

    const fields = [
        givenName,
        preferredName,
        familyName,
        privateEmail,
        gender,
        homeAddress,
        nationality,
        civilStatus,
        dietaryRequirements,
        ...customFields
    ]

    const anyDisplayable = anyFieldsDisplayable(fields)
    if(!anyDisplayable) {
        return null
    }

    const anyEditable = anyFieldsEditable(fields)
    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    const genderStateSubmissible = genderState.changed && (genderState.current === null || (typeof genderState.current === 'string' && genderState.current !== ''))
    const civilStatusStateSubmissible = civilStatusState.changed

    const genderOptions = [
        {
            value: 'Female',
            label: formatMessage({
                id: 'person_label_gender_female',
                defaultMessage: 'Female'
            }),
            checked: genderState.current === 'Female'
        },
        {
            value: 'Male',
            label: formatMessage({
                id: 'person_label_gender_male',
                defaultMessage: 'Male'
            }),
            checked: genderState.current === 'Male'
        },
        {
            value: customGenderUuid,
            label: formatMessage({
                id: 'person_label_gender_custom',
                defaultMessage: 'Another gender identity'
            }),
            checked: typeof genderState.current === 'string' && !standardGenderValues.includes(genderState.current),
            controlRef: customGenderField,
            content: (
                <>
                    <StringField
                        salt={salt}
                        label={false}
                        name="customGender"
                        field={{
                            value: !genderState.current || standardGenderValues.includes(genderState.current) ?
                                '' :
                                genderState.current,
                            ...(!!gender?.editable && {
                                editable: editing
                            }),
                            include: 'never',
                            placeholder: formatMessage({
                                id: 'noun_custom',
                                defaultMessage: 'Custom'
                            })
                        }}
                        onChange={({ customGender }) => genderState.update(customGender)}
                        ref={customGenderField} />
                    <InlineMessage
                        type="info"
                        message={formatMessage({
                            id: 'person_gender_custom_integration_information',
                            defaultMessage: 'Most integrated systems do not recognize a custom gender value. It will be registered as unspecified in those systems.'
                        })} />
                </>
            )
        },
        {
            value: 'Rather not say',
            label: formatMessage({
                id: 'person_label_gender_other',
                defaultMessage: 'Rather not say'
            }),
            checked: genderState.current === 'Rather not say'
        },
        {
            value: null,
            label: formatMessage({
                id: 'chart_label_unspecified',
                defaultMessage: 'Unspecified'
            }),
            checked: genderState.current === null
        }
    ]

    return (
        <Form
            layout={layout}
            onSubmit={update}>
            {({ touched, errors, trigger }) => (
                <Container id="personalia">
                    <SectionHero
                        editable={anyEditable}
                        editing={editing}
                        toggleEditing={() => setEditing(editing => {
                            if(editing) {
                                genderState.reset()
                                civilStatusState.reset()
                                setEditingSection?.(null)
                            }

                            return !editing
                        })}>
                        <Heading>
                            <FormattedMessage
                                id="person_pane_about_section_personalia"
                                defaultMessage="Personalia" />
                        </Heading>
                        <Spacer />
                        {!!editing && (
                            <ButtonSubmit
                                className={`constructive${saving ? ' loading' : ''}`}
                                disabled={(!touched.length && !genderStateSubmissible && !civilStatusStateSubmissible) || !!size(errors) || saving}
                                ref={trigger}>
                                <FormattedMessage
                                    id="action_save"
                                    defaultMessage="Save" />
                            </ButtonSubmit>
                        )}
                    </SectionHero>
                    {fieldDisplayable(givenName) && (
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_name_given',
                                defaultMessage: 'Given name'
                            })}
                            name="givenName"
                            field={{
                                ...givenName,
                                required: true,
                                ...(!!givenName?.editable && {
                                    editable: editing
                                })
                            }}
                            controlProps={{ autoFocus: true }}
                            {...isImportant('givenName')} />
                    )}
                    {fieldDisplayable(preferredName) && (
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_name_preferred',
                                defaultMessage: 'Preferred name'
                            })}
                            name="preferredName"
                            field={{
                                ...preferredName,
                                ...(!!preferredName?.editable && {
                                    editable: editing
                                })
                            }}
                            {...isImportant('preferredName')} />
                    )}
                    {fieldDisplayable(familyName) && (
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_name_family',
                                defaultMessage: 'Family name'
                            })}
                            name="familyName"
                            field={{
                                ...familyName,
                                required: true,
                                ...(!!familyName?.editable && {
                                    editable: editing
                                })
                            }}
                            {...isImportant('familyName')} />
                    )}
                    {fieldDisplayable(privateEmail) && (
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_email_personal',
                                defaultMessage: 'Personal email address'
                            })}
                            name="privateEmail"
                            field={{
                                ...privateEmail,
                                ...(!!privateEmail?.editable && {
                                    editable: editing
                                })
                            }}
                            controlProps={{
                                type: 'email',
                                autoComplete: 'email',
                                'data-1p-ignore': 'false'
                            }}
                            controlContent={(
                                <IntegratedHelper animate={editing ? 'reveal' : 'hide'}>
                                    <FormattedMessage
                                        id="person_label_email_personal_disclaimer"
                                        defaultMessage="Not used for logging in or for communication from Huma" />
                                </IntegratedHelper>
                            )}
                            {...isImportant('privateEmail')} />
                    )}
                    {fieldDisplayable(homeAddress) && (
                        <AddressField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_address_home',
                                defaultMessage: 'Address'
                            })}
                            name="homeAddress"
                            field={{
                                ...homeAddress,
                                ...(!!homeAddress?.editable && {
                                    editable: editing
                                })
                            }}
                            {...isImportant('homeAddress')} />
                    )}
                    {fieldDisplayable(gender) && (
                        <>
                            {(!editing || !gender?.editable) && (
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_gender',
                                        defaultMessage: 'Gender'
                                    })}
                                    name="genderDisplay"
                                    field={{
                                        value: !!genderState.current ?
                                            standardGenderValues.includes(genderState.current) ?
                                                genderOptions.find(({ value }) => value === genderState.current)?.label :
                                                genderState.current :
                                            null,
                                        editable: false,
                                        include: 'never'
                                    }}
                                    {...isImportant('gender')} />
                            )}
                            {(!!editing && !!gender?.editable) && (
                                <OneOfField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_gender',
                                        defaultMessage: 'Gender'
                                    })}
                                    name="gender"
                                    field={{
                                        ...gender,
                                        include: 'never',
                                        options: genderOptions
                                    }}
                                    onChange={({ gender }) => genderState.update(standardGenderValues.includes(gender) ?
                                        gender :
                                        ''
                                    )}
                                    {...isImportant('gender')} />
                            )}
                        </>
                    )}
                    {fieldDisplayable(nationality) && (
                        <CountryField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_nationality',
                                defaultMessage: 'Nationality'
                            })}
                            name="nationality"
                            field={{
                                ...nationality,
                                ...(!!nationality?.editable && {
                                    editable: editing
                                })
                            }}
                            {...isImportant('nationality')} />
                    )}
                    {fieldDisplayable(civilStatus) && (
                        <>
                            {(!editing || !civilStatus?.editable) && (
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_civilstatus',
                                        defaultMessage: 'Civil status'
                                    })}
                                    name="civilStatusDisplay"
                                    field={{
                                        value: !!civilStatusState.current ?
                                            getCivilStatusLabel(civilStatusState.current, formatMessage) :
                                            null,
                                        editable: false,
                                        include: 'never'
                                    }}
                                    {...isImportant('civilStatus')} />
                            )}
                            {(!!editing && !!civilStatus?.editable) && (
                                <OneOfField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'person_label_civilstatus',
                                        defaultMessage: 'Civil status'
                                    })}
                                    name="civilStatus"
                                    field={{
                                        ...civilStatus,
                                        include: 'never',
                                        options: [
                                            ...(configuration?.enums.civilStatus.map(status => ({
                                                value: status,
                                                label: getCivilStatusLabel(status, formatMessage),
                                                checked: civilStatusState.current === status
                                            })) ?? []),
                                            {
                                                value: null,
                                                label: formatMessage({
                                                    id: 'chart_label_unspecified',
                                                    defaultMessage: 'Unspecified'
                                                }),
                                                checked: civilStatusState.current === null
                                            }
                                        ]
                                    }}
                                    onChange={({ civilStatus }) => civilStatusState.update(civilStatus)}
                                    {...isImportant('civilStatus')} />
                            )}
                        </>
                    )}
                    {fieldDisplayable(dietaryRequirements) && (
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'person_label_dietary_requirements',
                                defaultMessage: 'Dietary requirements'
                            })}
                            name="dietaryRequirements"
                            field={{
                                ...dietaryRequirements,
                                ...(!!dietaryRequirements?.editable && {
                                    editable: editing
                                })
                            }}
                            controlContent={(
                                <IntegratedHelper animate={editing ? 'reveal' : 'hide'}>
                                    <FormattedMessage
                                        id="person_label_dietary_requirements_example"
                                        defaultMessage="Examples: Allergies and intolerances (nuts, gluten, milk…), beliefs (kosher, halal…), pregnancy (raw meat, blue cheese…), vegetarian/vegan…" />
                                </IntegratedHelper>
                            )}
                            {...isImportant('dietaryRequirements')} />
                    )}
                    <CustomFields
                        fields={customFields}
                        editing={editing}
                        salt={salt} />
                </Container>
            )}
        </Form>
    )
}

const standardGenderValues = ['Male', 'Female', 'Rather not say', null]

const getCivilStatusLabel = (status, formatMessage) => ({
    Single: formatMessage({
        id: 'person_label_civilstatus_single',
        defaultMessage: 'Single'
    }),
    Relationship: formatMessage({
        id: 'person_label_civilstatus_relationship',
        defaultMessage: 'Relationship'
    }),
    Married: formatMessage({
        id: 'person_label_civilstatus_married',
        defaultMessage: 'Married'
    })
})[status]

export default PersonalSection