import styled from 'styled-components'
import { flashKeyframes } from 'utilities/styled'
import { fontSerif } from 's'
import { H2 } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import Caption from 'components/typography/caption'
import { OrderedList, ListItem } from 'components/list'

export const Heading = styled(H2)`
    margin-block-start: 24px;

    ${fontSerif}
    line-height: 30px;
`

export const Description = styled(Paragraph)`
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
`

export const Levels = styled(OrderedList)`
    counter-reset: level;

    padding-inline-start: 56px;
`

export const Level = styled(ListItem)`
    --indent: 56px;

    position: relative;
    isolation: isolate;

    counter-increment: level;

    &.flash::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset-block: -12px;
        inset-inline:
            calc(var(--modal-container-padding) * -1 - var(--indent))
            calc(var(--modal-container-padding) * -1);

        animation: ${flashKeyframes} 2s ease-in-out;
    }

    &::before {
        content: counter(level);
        position: absolute;
        inset-inline-start: calc(var(--indent) * -1);

        width: 24px;

        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
    }
`

export const LevelTitle = styled(Paragraph)`
    margin-block-end: 12px;

    strong {
        font-weight: 400;
    }

    span {
        color: var(--huma-color-foreground-minimal);

        &::before {
            content: " ";
        }
    }
`

export const LevelDescription = Caption

export const Abbr = styled.abbr`
    cursor: help;
    text-decoration: underline dotted;
`