import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { useAccess } from 'contexts/access'
import { fieldDisplayable, fieldEditable } from 'utilities/access'
import { isFieldImportant } from 'pages/people/pages/profile/panes/about'
import Form from 'components/form/controller'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { DisplayColumns } from './s'
import { Plain } from 'components/button'
import Person from 'components/person'
import { Scrollable as Modal } from 'modals/generic'
import EditHierarchical from './edit'
import { Plus } from 'styled-icons/feather'

const Hierarchical = ({ lookingAtMyOwnProfile, layout, salt }) => {
    const { person } = usePerson()
    const { check } = useAccess()

    const [editing, setEditing] = useState(null)

    const {
        supervisor,
        subordinatesCount
    } = person

    const supervisorDisplayable = fieldDisplayable(supervisor)
    const subordinatesEditable = check('users:manage') && fieldEditable(supervisor)

    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    return (
        <>
            <Form layout={layout}>
                {!!supervisorDisplayable && (
                    <Field {...isImportant('supervisor')}>
                        <Label>
                            <FormattedMessage
                                id="person_label_supervisor"
                                defaultMessage="Supervisor" />
                        </Label>
                        <Control>
                            <DisplayColumns {...(!!supervisor?.editable ? { className: 'replacable' } : null)}>
                                {!supervisor?.value && (
                                    <>
                                        {!supervisor?.editable && (
                                            <ValueDisplay className="empty">
                                                <FormattedMessage
                                                    id="item_status_unassigned"
                                                    defaultMessage="Unassigned" />
                                            </ValueDisplay>
                                        )}
                                        {!!supervisor?.editable && (
                                            <Plain
                                                onClick={() => setEditing({ what: 'supervisor' })}
                                                className="constructive"
                                                icon={<Plus size={24} />}
                                                disabled={!!editing}>
                                                <FormattedMessage
                                                    id="person_action_add"
                                                    defaultMessage="Add person" />
                                            </Plain>
                                        )}
                                    </>
                                )}
                                {!!supervisor?.value && (
                                    <>
                                        <Person
                                            who={supervisor.value}
                                            asLink={true} />
                                        {!!supervisor?.editable && (
                                            <Plain
                                                onClick={() => setEditing({ what: 'supervisor' })}
                                                className="constructive"
                                                disabled={!!editing}>
                                                <FormattedMessage
                                                    id="action_change"
                                                    defaultMessage="Change" />
                                            </Plain>
                                        )}
                                    </>
                                )}
                            </DisplayColumns>
                        </Control>
                    </Field>
                )}
                {(subordinatesEditable || !!subordinatesCount) && (
                    <Field>
                        <Label>
                            <FormattedMessage
                                id="person_label_subordinates"
                                defaultMessage="Subordinates" />
                        </Label>
                        <Control>
                            <DisplayColumns className="replacable">
                                {!subordinatesCount && (
                                    <>
                                        {!subordinatesEditable && (
                                            <ValueDisplay className="empty">
                                                <FormattedMessage
                                                    id="item_status_unassigned"
                                                    defaultMessage="Unassigned" />
                                            </ValueDisplay>
                                        )}
                                        {!!subordinatesEditable && (
                                            <Plain
                                                onClick={() => setEditing({
                                                    what: 'subordinates',
                                                    mode: 'add'
                                                })}
                                                className="constructive"
                                                icon={<Plus size={24} />}
                                                disabled={!!editing}>
                                                <FormattedMessage
                                                    id="people_action_add"
                                                    defaultMessage="Add people" />
                                            </Plain>
                                        )}
                                    </>
                                )}
                                {!!subordinatesCount && (
                                    <>
                                        <span>
                                            <FormattedMessage
                                                id="people_count"
                                                defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                                                values={{ count: subordinatesCount }} />
                                        </span>
                                        <Plain
                                            onClick={() => setEditing({
                                                what: 'subordinates',
                                                ...(!subordinatesEditable ? { mode: 'view' } : null)
                                            })}
                                            className="constructive"
                                            disabled={!!editing}>
                                            <FormattedMessage
                                                id={!!subordinatesEditable ?
                                                    'action_view_edit' :
                                                    'action_view'
                                                }
                                                defaultMessage={!!subordinatesEditable ?
                                                    'View and edit' :
                                                    'View'
                                                } />
                                        </Plain>
                                    </>
                                )}
                            </DisplayColumns>
                        </Control>
                    </Field>
                )}
            </Form>
            <Modal
                show={editing}
                dismiss={() => setEditing(null)}>
                <EditHierarchical
                    {...editing}
                    onDone={() => setEditing(null)}
                    lookingAtMyOwnProfile={lookingAtMyOwnProfile}
                    salt={salt} />
            </Modal>
        </>
    )
}

export default Hierarchical