import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Plain } from 'components/button'

export const ImportButton = styled(Plain)`
    &.setup {
        margin-top: -6px;
    }

    svg {
        margin-inline-end: 8px;
        ${svgStroke()}
    }
`