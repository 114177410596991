import React, { useState, useEffect } from 'react'
import { LoadingContainer } from './s'
import Loader from 'components/loader'
import Hero from './hero'
import Details from './details'
import Actions from './actions'
import Meta from './meta'

const ViewTask = ({ task: initialTask, actions, modal, salt }) => {
    const [task, setTask] = useState(initialTask)

    useEffect(() => {
        if(initialTask) {
            setTask(task)
        }
    }, [initialTask])

    if(!task) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    return (
        <>
            <Hero
                task={task}
                setTask={setTask}
                dismiss={modal.dismiss} />
            <Details task={task} />
            <Actions
                actions={actions}
                context={task}
                onToggleCompleted={update => setTask(task => ({ ...task, ...update }))}
                dismiss={modal.dismiss}
                salt={salt} />
            <Meta task={task} />
        </>
    )
}

export default ViewTask

