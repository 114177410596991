import { useLayoutEffect, useMemo } from 'react'
import { session } from 'utilities/storage'
import { v4 as uuid } from 'uuid'

export const useInstanceId = () => {
    const id = useMemo(uuid, [])

    useLayoutEffect(() => {
        session.set('instance', id)
    }, [id])

    return id
}