import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { usePayment } from 'contexts/payment'
import { sendAppSignal } from 'hooks/app-signal'
import { getCategoryIcon, categoryToI18nMap, nameToQuickGuideMap, enrichByName } from 'utilities/categories'
import { getRoleUrl } from 'utilities/url'
import { cls } from 'utilities/dom'
import {
    FeatureItem,
    IconCell,
    NameCell, Name, NameMeta,
    Link,
    QuotaCell,
    ActionCell, Quota,
    Upgrade
} from './s'
import { Plain } from 'components/button'
import Form from 'components/form/controller'
import CheckboxField from 'components/form/field/checkbox'
import Beta from 'components/beta'
import { getNextBundleWithFeature } from './'

const Feature = ({
    item,
    context,
    available: moduleAvailable,
    enabled: moduleEnabled,
    setManaging,
    customUpgradeButton,
    adminRole,
    userAdminRole,
    iconSize = 16,
    salt,
    ...props
}) => {
    const {
        currentLadder,
        currentBundle,
        featuresByBundleInCurrentTrack,

        toggleModule,
        getBundlesWithFeature
    } = useAccess()

    const { account } = usePayment()

    const [updating, setUpdating] = useState(false)
    const [status, setStatus] = useState(null)

    const update = async ({ [item.key]: enabled }) => {
        if(typeof enabled === 'undefined' || updating) {
            return
        }

        setUpdating(true)

        const body = {
            key: item.key,
            enabled
        }

        setStatus({
            id: enabled ?
                'verb_enabling' :
                'verb_disabling',
            defaultMessage: enabled ?
                'Enabling…' :
                'Disabling…'
        })

        await toggleModule(body)

        setUpdating(false)
    }

    const changeStatus = () => {
        setStatus({
            id: enabled ?
                'module_status_enabled' :
                'module_status_disabled',
            defaultMessage: enabled ?
                'Enabled' :
                'Disabled'
        })
    }

    useEffect(() => {
        if(!updating) {
            changeStatus()
        }
    }, [updating])

    const feature = {
        ...enrichByName(item.key),
        ...item,
        ...props
    }

    const {
        name,
        enabled,
        available,
        quota,
        locked = true
    } = feature

    const Icon = getCategoryIcon(name)

    const bundlesWithFeature = getBundlesWithFeature(name)

    const nextBundle = currentLadder[currentLadder.indexOf(currentBundle) + 1] ?? null

    const hasUpgradeableQuota = nextBundle ?
        getNextBundleWithFeature(
            featuresByBundleInCurrentTrack,
            bundlesWithFeature.find(bundle => bundle === nextBundle.bundle),
            item
        ) : null

    const togglable = !!moduleEnabled && !!available && !locked

    const featureClassName = cls([
        props?.className,
        context,
        (!!moduleAvailable && !moduleEnabled) && 'disabled',
        togglable && 'togglable',
        !available && 'upgradable'
    ])

    const showReadMore = context === 'list' && featuresWithQuickGuide.includes(name) && nameToQuickGuideMap(name)

    return (
        <FeatureItem {...(featureClassName ? { className: featureClassName } : null)}>
            <IconCell>
                {Icon && <Icon size={iconSize} />}
            </IconCell>
            <NameCell {...((!!available && !enabled) ? { className: 'disabled' } : null)}>
                <Name>
                    <FormattedMessage
                        id={categoryToI18nMap[name] ?? `noun_${name.replaceAll('-', '_')}`}
                        defaultMessage={name} />
                    {[
                        'content-assistant',
                        'salary-revision',
                        'salary-revision-reviewers',
                        'salary-revision-by-supervisor'
                    ].includes(name) && <Beta />}
                </Name>
                <NameMeta>
                    {featureToDescription?.[name] && (
                        <span>
                            <FormattedMessage
                                {...featureToDescription[name]}
                                values={{
                                    admins: text => {
                                        if(adminRole) {
                                            return (
                                                <Link to={getRoleUrl({
                                                    ...adminRole,
                                                    pane: 'grants'
                                                })}>
                                                    {text}
                                                </Link>
                                            )
                                        }

                                        return text
                                    },
                                    useradmins: text => {
                                        if(userAdminRole) {
                                            return (
                                                <Link to={getRoleUrl({
                                                    ...userAdminRole,
                                                    pane: 'grants'
                                                })}>
                                                    {text}
                                                </Link>)
                                        }

                                        return text
                                    }
                                }} />
                        </span>
                    )}
                    {(togglable && !!status) && (
                        <span>
                            <FormattedMessage {...status} />
                        </span>
                    )}
                </NameMeta>
                {showReadMore && (
                    <Plain
                        onClick={() => sendAppSignal('quick-guide.show', nameToQuickGuideMap(name))}
                        className="constructive small">
                        <FormattedMessage
                            id="action_read_more"
                            defaultMessage="Read more" />
                    </Plain>
                )}
            </NameCell>
            {quota && (
                <QuotaCell>
                    <Quota className="compact">
                        <FormattedMessage
                            id={quota?.limit ?
                                `module_${name.replaceAll('-', '_')}_quota_included` :
                                `module_${name.replaceAll('-', '_')}_quota_unlimited`
                            }
                            defaultMessage={quota?.limit ?
                                '{total, plural, =0 {} =1 {} other {{total} items included.}}' :
                                'Unlimited items included.'
                            }
                            values={{ total: quota?.limit }} />
                    </Quota>
                    {(hasUpgradeableQuota && !!account?.subscription?.public) && (
                        <Upgrade
                            feature={item.key.replace('feature:', '')}
                            setManaging={setManaging}
                            className="constructive compact"
                            size="small"
                            as={Plain} />
                    )}
                </QuotaCell>
            )}
            {(!available && !togglable && !!account?.subscription?.public) && (
                <ActionCell>
                    <Upgrade
                        feature={item.key.replace('feature:', '')}
                        setManaging={setManaging}
                        useIcon={true} />
                </ActionCell>
            )}
            {togglable && (
                <Form
                    submitOnChange={true}
                    onSubmit={update}>
                    <CheckboxField
                        salt={`${salt}:${context}`}
                        className="compact"
                        label={false}
                        name={item.key}
                        field={{
                            value: enabled,
                            include: 'always'
                        }}
                        loading={updating}
                        enabled={!updating}
                        interaction="switch" />
                </Form>
            )}
            {customUpgradeButton}
        </FeatureItem>
    )
}

const processesImportTemplates = {
    id: 'feature_employment_lifecycle_import_templates_description',
    defaultMessage: 'Between different organizations or within one'
}

const processesMultipleTemplates = {
    id: 'feature_employment_lifecycle_multiple_templates_description',
    defaultMessage: 'Combine tasks from multiple templates, e.g. ‘Bartender’ + ‘London’'
}

export const featureToDescription = {
    // Organization settings
    'content-assistant': {
        id: 'feature_content_assistant_description',
        defaultMessage: 'Generate AI suggestions. Write a prompt and the AI will create a starting point for you to use.'
    },

    // People
    'organization-chart': {
        id: 'feature_organization_chart_description',
        defaultMessage: 'Visualize your organization’s hierarchy of supervisors'
    },
    jubilees: {
        id: 'feature_jubilees_description',
        defaultMessage: 'Only <admins>Admins</admins> and <useradmins>User admins</useradmins> can see job anniversaries and birthdays'
    },
    'custom-user-fields': {
        id: 'feature_custom-user-fields_description',
        defaultMessage: 'Create custom fields that will be available on each user’s profile'
    },
    'people-domain-signup': {
        id: 'feature_domain_verification_description',
        defaultMessage: 'Verify your domain to let employees self-signup using their company email addresses'
    },

    // Salary
    'salary-revision': {
        id: 'feature_salary_revision_description',
        defaultMessage: 'Adjust salaries for multiple employees and groups, through a single, structured process.'
    },
    'salary-revision-reviewers': {
        id: 'feature_salary_revision_reviewers_description',
        defaultMessage: 'Assign specific individuals as reviewers within salary revision groups, enabling them to submit salary adjustments for approval.'
    },
    'salary-revision-by-supervisor': {
        id: 'feature_salary_revision_by_supervisor_description',
        defaultMessage: 'Supervisors will be added as reviewers for their subordinates, allowing them to submit salary adjustments for approval.'
    },

    // Handbook
    'handbook-groups': {
        id: 'feature_handbook_groups_description',
        defaultMessage: 'Share specific topics with members of teams or locations'
    },
    'handbook-featured': {
        id: 'feature_handbook_featured_description',
        defaultMessage: 'Pin certain topics to the handbook or the dashboard, making it easier for people to find them'
    },
    'handbook-export': {
        id: 'feature_handbook_export_description',
        defaultMessage: 'Export the handbook in JSON format'
    },

    // Meetings
    'meeting-agenda-points': {
        id: 'feature_meeting_agenda_points_description',
        defaultMessage: 'Add your own talking points in a meeting'
    },
    'meeting-templates': {
        id: 'feature_meeting_templates_description',
        defaultMessage: 'Create your own meeting templates'
    },
    'meeting-rounds': {
        id: 'feature_meeting_rounds_description',
        defaultMessage: 'Create multiple meetings in seconds'
    },

    // News
    'news-groups': {
        id: 'feature_news_groups_description',
        defaultMessage: 'Share specific news articles with members of teams and locations'
    },

    // Employment lifecycle
    'onboarding-import-templates': processesImportTemplates,
    'onboarding-multiple-templates': processesMultipleTemplates,
    'offboarding-import-templates': processesImportTemplates,
    'offboarding-multiple-templates': processesMultipleTemplates,
    'processes-import-templates': processesImportTemplates,
    'processes-multiple-templates': processesMultipleTemplates,

    // Documents
    'digital-signing': {
        id: 'feature_digital_signing_description',
        defaultMessage: 'Send documents for signing'
    },
    'smart-documents': {
        id: 'feature_smart_documents_description',
        defaultMessage: 'Create smart documents and fill them with data from your organization'
    },
    'smart-document-templates': {
        id: 'feature_smart_document_templates_description',
        defaultMessage: 'Build reusable templates with smart fields'
    },
    'smart-document-templates-import-export': {
        id: 'feature_smart_document_templates_import_export_description',
        defaultMessage: 'Between different organizations or within one'
    },

    // Competence
    'competence-gap': {
        id: 'feature_competence_gap_description',
        defaultMessage: 'Identify development areas and compare competences within your organization'
    },
    'competence-profiles': {
        id: 'feature_competence_profiles_description',
        defaultMessage: 'Define roles and establish competence targets, and use with gap analysis to reach your goals'
    },
    'competence-export': {
        id: 'feature_competence_export_description',
        defaultMessage: 'Export competence in XLSX format'
    },

    // Equipment
    'equipment-import-export': {
        id: 'feature_equipment_import_export_description',
        defaultMessage: 'Import and export equipment in XLSX format'
    },

    // Tasks
    'group-tasks': {
        id: 'feature_group_tasks_description',
        defaultMessage: 'Members can claim tasks assigned to their teams or locations'
    },

    // Insights
    'insights-groups': {
        id: 'feature_insights_groups_description',
        defaultMessage: 'Display insights for one specific team or location in isolation'
    },
    'insights-exclude-people': {
        id: 'feature_insights_exclude_people_description',
        defaultMessage: 'Excluded users will not be counted in any of the various charts'
    },

    // Surveys
    'surveys-custom-schedule': {
        id: 'feature_surveys_custom_schedule_description',
        defaultMessage: 'Set the interval for how often the eNPS survey should be sent out, and decide how many days employees have to respond'
    },
    'surveys-exclude-people': {
        id: 'feature_surveys_exclude_people_description',
        defaultMessage: 'Excluded users will not receive eNPS surveys, and will not be able to respond'
    }
}

const featuresWithQuickGuide = ['content-assistant']

export default Feature