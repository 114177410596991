import { useConfiguration } from 'contexts/configuration'
import { useOrganization } from 'contexts/organization'
import { useAccess } from 'contexts/access'

export const useCheckOrganizationUsersLimit = () => {
    const { configuration } = useConfiguration()
    const { organization } = useOrganization()
    const { currentPlan } = useAccess()

    return (additionalUserCount = 1) => {
        const limit = configuration.max_trial_free_users ?? Infinity

        const reached =
            !currentPlan?.verified &&
            (!!currentPlan?.inTrial || !!currentPlan?.free) && (
                (organization?.totalUserCount >= limit) ||
                (organization?.totalUserCount + additionalUserCount) > limit
            )

        return {
            reached,
            quantity: limit,
            free: !!currentPlan?.free
        }
    }
}