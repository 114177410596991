import React from 'react'
import { FormattedMessage } from 'react-intl'
import { usePerson } from 'contexts/person'
import { getFullName } from 'utilities/person'
import { Hero, For } from './s'
import { CloseButton } from 'components/button'
import { H2 as Heading } from 'components/typography/heading'

const SubordinatesHero = ({ dismiss, onDone }) => {
    const { person } = usePerson()

    return (
        <Hero>
            <Heading>
                <FormattedMessage
                    id="person_label_subordinates"
                    defaultMessage="Subordinates" />
            </Heading>
            <CloseButton onClick={dismiss ?? onDone} />
            <For>
                <FormattedMessage
                    id="for_name"
                    defaultMessage="for {name}"
                    values={{
                        name: <span>{getFullName(person)}</span>
                    }} />
            </For>
        </Hero>
    )
}

export default SubordinatesHero
