import styled from 'styled-components'
import { motion } from 'framer-motion'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { codeCss } from 'components/code/s'

export const Layout = styled(Grid)`
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    row-gap: 24px;
    align-items: start;
`

export const VariableHint = styled.code`
    ${codeCss}

    border-width: .5px;
    background-color: rgb(from var(--huma-color-surface-minimal) r g b / .5);

    text-shadow: 0 0 .5px var(--huma-color-foreground-default);
    font-size: 80%;
    color: var(--huma-color-foreground-default);
`

export const FieldsTogglerIcon = styled(motion.span).attrs(() => ({
    variants: {
        contracted: {
            transform: 'rotate(0deg)'
        },
        expanded: {
            transform: 'rotate(-180deg)'
        }
    },
    transition: {
        duration: .15
    }
}))`
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
        ${svgStroke()}
    }
`