import React, { useEffect, useState, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { differenceInDays, addDays, subDays } from 'date-fns'
import SalariesProvider, { useSalaries } from 'contexts/salaries'
import { usePerson } from 'contexts/person'
import { anyFieldsDisplayable, anyFieldsEditable } from 'utilities/access'
import { useLocationStateIntent } from 'hooks/location-intent'
import { isofy } from 'utilities/date-time'
import { compact } from 'utilities/array'
import { size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { getPeopleProfileSalaryUrl } from 'utilities/url'
import { isOngoing } from 'pages/absence/utilities'
import {
    Heading,
    Wrapper, Timeline, ObscuredTimeline,
    Empty,
    Link
} from './s'
import { Container, HeadingColumns, Spacer, ImportantMessage, Plain } from '../s'
import { isFieldImportant } from '../'
import { fakeButtonProps } from 'components/button'
import { TimelineEntryLoading } from 'components/timeline/entry'
import { Scrollable as Modal } from 'modals/generic'
import ActingSalary from './edit'
import SalaryRecord, { NoRecord } from './record'
import Statistics from './statistics'
import {
    Edit,
    Trash2 as Delete,
    Plus
} from 'styled-icons/feather'

const SalarySection = ({ lookingAtMyOwnProfile, standalone = false, layout, showHeading = true, salt }) => {
    salt = `${salt}:${standalone && 'standalone'}`

    const { formatMessage } = useIntl()

    const navigate = useNavigate()

    const { person } = usePerson()

    const {
        salaries: salaryRecords = [],
        fetchSalaries,
        removeSalary,
        fetching,
        hasFetched
    } = useSalaries()

    const { salary } = person

    const [salaries, setSalaries] = useState(salaryRecords)
    const [acting, setActing] = useState(null)
    const [updating, setUpdating] = useState(false)
    const [showingCurrent, setShowingCurrent] = useState(standalone)

    const fields = [salary]

    const anyDisplayable = anyFieldsDisplayable(fields, false)

    const anyEditable = anyFieldsEditable(fields)

    useEffect(() => {
        if(standalone) {
            fetchSalaries()
        }
    }, [standalone])

    useEffect(() => {
        if(!!hasFetched) {
            setSalaries(salaryRecords)
        }
    }, [hasFetched, salaryRecords])

    useLocationStateIntent({
        intent: 'add-salary',
        action: () => setActing({ mode: 'add' }),
        requirements: [!!anyEditable]
    })

    const remove = async id => {
        setUpdating(true)
        await removeSalary(id)
        setUpdating(false)

        setSalaries(salaries.filter(salary => salary.id !== id))
    }

    const toggleShowingCurrent = () => setShowingCurrent(!showingCurrent)

    const editAction = useCallback(({ id }) => {
        if(!anyEditable) {
            return null
        }

        return ({
            salt: `${salt}:profile:salary:edit`,
            icon: <Edit size={24} />,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                id,
                mode: 'edit'
            })
        })
    }, [anyEditable])

    const removeAction = useCallback(({ id }) => {
        if(!anyEditable) {
            return null
        }

        return ({
            salt: `${salt}:profile:salary:delete`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: () => remove(id),
            prompt: {
                question: formatMessage({
                    id: 'profile_salary_delete_prompt_question',
                    defaultMessage: 'Confirm removing the salary'
                }),
                message: formatMessage({
                    id: 'profile_salary_delete_prompt_message',
                    defaultMessage: 'The salary data will be deleted. This can not be undone.'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        })
    }, [anyEditable])

    const actions = {
        edit: editAction,
        remove: removeAction
    }

    const salaryMap = useCallback(salaries => {
        const newSalaries = [...salaries]

        newSalaries.forEach((record, index) => {
            if(!!record.fromDate && newSalaries[index + 1]) {
                const nextRecord = newSalaries[index + 1]

                if(nextRecord.toDate && differenceInDays(isofy(record.fromDate), isofy(nextRecord.toDate)) > 1) {
                    const fromDate = addDays(isofy(nextRecord.toDate), 1)
                    const toDate = subDays(isofy(record.fromDate), 1)

                    newSalaries.splice(index + 1, 0, {
                        fromDate,
                        toDate,
                        empty: true,
                        className: 'empty',
                        content: (
                            <NoRecord
                                fromDate={fromDate}
                                toDate={toDate}
                                setActing={setActing}
                                editable={anyEditable} />
                        )
                    })
                }
            }
        })

        return newSalaries
            .filter(({ empty = false }, index) => !(!!empty && index === 0))
            .map(({ fake = false, empty = false, ...salary }, index) => ({
                ...salary,
                ...(!empty && {
                    content: (
                        <SalaryRecord
                            {...salary}
                            fake={fake}
                            nextRecord={newSalaries[index + 1] ?? null} />
                    ),
                    actions: fake ? null : actions
                })
            }))
    }, [salaries, anyEditable])

    if(!anyDisplayable) {
        return null
    }

    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    const EmptyWrapper = !!isImportant('salary') ?
        ImportantMessage :
        Empty

    const hasValue = !!size(salary?.value)

    const salaryFieldProps = isImportant('salary') ?? {}

    salaryFieldProps.className = cls([
        salaryFieldProps?.className,
        layout
    ])

    const [AddSalaryTrigger, addSalaryTriggerProps] = standalone ?
        [Plain, {
            className: 'constructive',
            icon: <Plus size={24} />,
            onClick: () => setActing({ mode: 'add' }),
            disabled: updating || acting
        }] :
        [Link, {
            to: lookingAtMyOwnProfile ?
                'path:salary.base' :
                getPeopleProfileSalaryUrl(person),
            state: { intent: 'add-salary' },
            className: 'constructive'
        }]

    return (
        <Container id="salary">
            {showHeading && (
                <HeadingColumns className={hasValue ? 'editable' : null}>
                    <Heading>
                        <FormattedMessage
                            id={lookingAtMyOwnProfile ?
                                'salary_heading_yours'
                                : 'person_pane_about_section_salary'
                            }
                            defaultMessage={lookingAtMyOwnProfile ?
                                'Your salary'
                                : 'Salary'
                            } />
                    </Heading>
                    {!standalone && (
                        <>
                            <Spacer />
                            {hasValue && (
                                <Plain
                                    className="constructive"
                                    onClick={toggleShowingCurrent}>
                                    <FormattedMessage
                                        id={showingCurrent ?
                                            'action_hide' :
                                            'action_show'
                                        }
                                        defaultMessage={showingCurrent ?
                                            'Hide' :
                                            'Show'
                                        } />
                                </Plain>
                            )}
                        </>
                    )}
                </HeadingColumns>
            )}
            {!hasValue && (
                <>
                    <EmptyWrapper>
                        <FormattedMessage
                            id="person_pane_about_section_salary_current_empty"
                            defaultMessage="No current salary added" />
                    </EmptyWrapper>
                    {anyEditable && (
                        <AddSalaryTrigger {...addSalaryTriggerProps}>
                            {!standalone && <Plus size={24} />}
                            <FormattedMessage
                                id="action_add"
                                defaultMessage="Add" />
                        </AddSalaryTrigger>
                    )}
                </>
            )}
            {(!showingCurrent && hasValue) && (
                <ObscuredTimeline
                    {...fakeButtonProps}
                    entries={salaryMap(fakeSalary)}
                    onClick={toggleShowingCurrent}
                    size={20} />
            )}
            {(showingCurrent || !hasValue) && (
                <Wrapper>
                    {(!!anyEditable && hasValue) && (
                        <AddSalaryTrigger {...addSalaryTriggerProps}>
                            {!standalone && <Plus size={24} />}
                            <FormattedMessage
                                id="action_add"
                                defaultMessage="Add" />
                        </AddSalaryTrigger>
                    )}
                    {((hasValue && !hasFetched) || (!fetching && hasFetched && !!salaries.length)) && (
                        <>
                            <Timeline
                                entries={salaryMap(compact([
                                    ((hasValue && !standalone) || fetching) && salary.value,
                                    ...((!!salaries.length && !fetching && standalone) ? salaryRecords : [])
                                ]))}
                                size={20} />
                            {!!standalone && <Statistics records={salaryMap(salaryRecords)} />}
                        </>
                    )}
                    {fetching && <TimelineEntryLoading />}
                    {!standalone && (
                        <Link
                            to={lookingAtMyOwnProfile ?
                                'path:salary.base' :
                                getPeopleProfileSalaryUrl(person)
                            }
                            className="constructive">
                            <FormattedMessage
                                id="action_show_history"
                                defaultMessage="Show history" />
                        </Link>
                    )}
                </Wrapper>
            )}
            <Modal
                show={acting}
                dismiss={() => setActing(null)}
                salt={salt}>
                <ActingSalary
                    {...acting}
                    salt={salt}
                    dismiss={() => setActing(null)}
                    onDone={salary => {
                        setActing(null)
                        setShowingCurrent(true)

                        const now = new Date()

                        if(!isOngoing({ start: salary.fromDate, end: salary.toDate }, now) && !hasFetched) {
                            if(standalone) {
                                fetchSalaries()
                            } else {
                                navigate(lookingAtMyOwnProfile ?
                                    'path:salary.base' :
                                    getPeopleProfileSalaryUrl(person)
                                )
                            }
                        }
                    }} />
            </Modal>
        </Container>
    )
}

const fakeSalary = [
    {
        fromDate: '1990-01-01',
        toDate: '1990-12-31',
        periodUnit: 'yearly',
        amount: 10000,
        currency: 'EUR',
        fake: true
    }
]

export const periodUnitToPeriodShortMap = {
    'hourly': {
        id: 'salary_frequency_hour',
        defaultMessage: 'hour'
    },
    'weekly': {
        id: 'salary_frequency_week',
        defaultMessage: 'week'
    },
    'monthly': {
        id: 'salary_frequency_month',
        defaultMessage: 'month'
    },
    'yearly': {
        id: 'salary_frequency_year',
        defaultMessage: 'year'
    }
}

export default ({ access, userId = null, ...props }) => {
    if(access?.available === false) {
        return null
    }

    return (
        <SalariesProvider userId={userId}>
            <SalarySection {...props} />
        </SalariesProvider>
    )
}