import { pick } from 'utilities/object'

export const isEntity = entity => ['team', 'location', 'user'].includes(entity?.type)

export const reduceEntity = entity => {
    if(!isEntity(entity)) {
        return entity
    }

    const { type } = entity

    const fields = [
        'id',
        'name', 'givenName', 'familyName', 'preferredName',
        'avatarUrl',
        'status'
    ]

    return {
        type,
        [type]: pick(entity[type], ...fields)
    }
}