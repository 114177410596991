import { useAccess } from 'contexts/access'

export const useUpgradable = (strict = false) => {
    const {
        initialized,
        featureConfig,
        track,

        check
    } = useAccess()

    return ({ module, feature }) => {
        if(!initialized) {
            return null
        }

        if(track !== 'standard') {
            return false
        }

        if(!module && !feature) {
            return false
        }

        const available = featureConfig.reduce(
            (accumulator, { key, available, features = [] }) => ({
                ...accumulator,
                [key]: available,
                ...features.reduce((accumulator, { key, available }) => ({
                    ...accumulator,
                    [key]: available
                }), {})
            }),
            {}
        )

        const moduleIncluded = !module || available[`module:${module}`]
        if(moduleIncluded && !feature) {
            return false
        }

        const featureIncluded = available[`feature:${feature}`]
        if(featureIncluded) {
            return false
        }

        const superAdmin = check('organization:manage')

        if(!module && !!feature) {
            module = featureConfig.reduce(
                (accumulator, { key: module, features = [] }) => ({
                    ...accumulator,
                    ...features.reduce((accumulator, { key }) => ({
                        ...accumulator,
                        [key]: module
                    }), {})
                }),
                {}
            )[feature]
        }

        const moduleAdmin = !!module && check(`${module}:manage`)

        return strict ?
            moduleAdmin :
            (superAdmin || moduleAdmin)
    }
}