export const sleep = ms => new Promise(resolve => global.setTimeout(resolve, ms))

export const frames = () => new Promise(resolve => global.requestAnimationFrame(resolve))

export const sequentially = async (items, callback) => {
    const results = []

    await items.reduce(async (previousPromise, value, index) => {
        await previousPromise

        const promise = callback(value, index)
        const result = await promise
        results.push(result)

        return promise
    }, Promise.resolve())

    return results
}