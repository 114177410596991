import React from 'react'
import { useRiskLevel } from 'pages/deviation/utilities'
import { cls } from 'utilities/dom'
import { LevelLabel } from './s'

const RiskLevelLabel = ({ level, ...props }) => {
    let {
        className,
        label,
        icon: Icon
    } = useRiskLevel()(level)

    if(!label) {
        return null
    }

    className = cls([
        className,
        props.className
    ])

    return (
        <LevelLabel className={className}>
            {Icon && <Icon size={16} />}
            <span className="label">{label}</span>
        </LevelLabel>
    )
}

export default RiskLevelLabel
