import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { flexChildCss, flexChildShrinkCss } from 'components/flex'
import ActionsBase from 'components/form/actions'
import { Label as LabelBase } from 'components/form/field/s'
import { Grid } from 'components/grid'
import { Columns } from 'components/flex'
import { Plain } from 'components/button'
import { Static } from 'components/empty'

export const Content = styled.div`
    ${flexChildCss}
    padding-block-end: 24px;
    position: relative;

    /* padding: 0 32px 24px; */
`

export const Actions = styled(ActionsBase)`
    ${flexChildShrinkCss}

    border-top: 1px solid var(--huma-color-border-default);
    padding-block-start: 24px;

    /* padding: 24px 32px;

    ${belowTablet`
        padding: 16px 24px;
    `} */
`

export const TemplateList = styled(Grid)`
    position: relative;

    grid-auto-rows: auto;
    grid-auto-flow: row;

    border-radius: 8px;
    min-height: 72px;
    padding-inline: 16px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);
`

export const Label = styled(LabelBase)`
    ${TemplateList} + & {
        margin-block-start: 24px;
    }

    display: block;

    margin-block-end: 8px;
    width: 100%;
`

export const ShowAll = styled(Columns)`
    position: relative;

    flex-wrap: wrap;
    column-gap: 4px;

    margin-block: 16px;
    min-height: 20px;
`

export const ShowAllCount = styled.span`
    font-size: 14px;
    line-height: 20px;
    color: var(--huma-color-foreground-subtle);
`

export const ShowAllButton = styled(Plain)`
    border: none;
    height: auto;

    font-size: 14px;
    line-height: 20px;
`

export const Empty = styled(Static)`
    min-height: 72px;
    padding: 0;
`