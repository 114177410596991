import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { usePageView } from 'hooks/page-view'
import { useSignup } from 'contexts/signup'
import { size } from 'utilities/object'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import { Rows, FlexChildShrink } from 'components/flex'
import { Step, LogoContainer, Logo, HeadingColumns, Heading } from '../s'
import { CaptionSmall as Progress } from 'components/typography/caption'
import { HalfColumns, ContinueButton } from './s'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import CountryField from 'components/form/field/country'
import SelectField from 'components/form/field/select'
import { InlineMessage } from 'components/message'
import Actions from 'components/form/actions'
import { ParagraphSmall } from 'components/typography/paragraph'
import Link from 'components/link'
import LoginQuestion from '../login-question'

const OneDetails = ({ show, ...props }) => {
    const { formatMessage } = useIntl()

    const { environment } = useEnvironment()

    usePageView('signup_details', [show])

    const [error, setError] = useState(null)
    const [connecting, setConnecting] = useState(null)

    const {
        givenName,
        familyName,
        // email, // We want the user to repeat this
        name,
        country,
        companySize,

        register
    } = useSignup()

    const onSubmit = async body => {
        setConnecting(true)
        const { ok, status } = await register(body)
        setConnecting(false)

        if(!ok) {
            if(status === '(Network error)') {
                return void setError({
                    type: 'network',
                    message: formatMessage({
                        id: 'error_network',
                        defaultMessage: 'Could not reach the server'
                    })
                })
            }

            setError({
                type: 'unknown',
                message: formatMessage({
                    id: 'error_unknown',
                    defaultMessage: 'Something went wrong'
                })
            })
        }
    }

    const salt = 'signup:1:details'

    return (
        <Step
            {...props}
            show={show}>
            <Rows>
                <FlexChildShrink>
                    <LogoContainer>
                        <Logo />
                    </LogoContainer>
                    <HeadingColumns>
                        <Heading>
                            <FormattedMessage
                                id="signup_one_heading"
                                defaultMessage="Register your company" />
                        </Heading>
                        <Progress className="compact">
                            <FormattedMessage
                                id="step_of_total"
                                defaultMessage="Step {step} of {total}"
                                values={{
                                    step: 1,
                                    total: 2
                                }} />
                        </Progress>
                    </HeadingColumns>
                    <Form
                        layout="vertical"
                        onChange={() => setError(null)}
                        onSubmit={onSubmit}>
                        {({ errors, trigger }) => (
                            <>
                                <HalfColumns>
                                    <StringField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_name_given',
                                            defaultMessage: 'Given name'
                                        })}
                                        name="givenName"
                                        field={{
                                            value: givenName,
                                            include: 'always',
                                            required: true,
                                            autoFocus: true
                                        }} />
                                    <StringField
                                        salt={salt}
                                        label={formatMessage({
                                            id: 'person_label_name_family',
                                            defaultMessage: 'Family name'
                                        })}
                                        name="familyName"
                                        field={{
                                            value: familyName,
                                            include: 'always',
                                            required: true
                                        }} />
                                </HalfColumns>
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'noun_emailaddress_work',
                                        defaultMessage: 'Your work email address'
                                    })}
                                    name="email"
                                    field={{
                                        include: 'always',
                                        required: true
                                    }}
                                    controlProps={{
                                        type: 'email',
                                        autoComplete: 'email',
                                        'data-1p-ignore': 'false'
                                    }} />
                                <StringField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'organization_noun_name',
                                        defaultMessage: 'Organization name'
                                    })}
                                    name="name"
                                    field={{
                                        value: name,
                                        include: 'always',
                                        required: true
                                    }}
                                    controlProps={{
                                        placeholder: formatMessage({
                                            id: 'signup_one_example_company_name',
                                            defaultMessage: 'Company Inc.'
                                        }),
                                        min: 2,
                                        minLength: 2,
                                        max: 50,
                                        maxLength: 50
                                    }} />
                                <CountryField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'address_label_country',
                                        defaultMessage: 'Country'
                                    })}
                                    name="country"
                                    field={{
                                        value: country,
                                        include: 'always',
                                        required: true
                                    }}
                                    controlProps={{
                                        autoComplete: 'country-name',
                                        'data-1p-ignore': 'false'
                                    }} />
                                <SelectField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'signup_one_company_size',
                                        defaultMessage: 'Number of employees'
                                    })}
                                    name="companySize"
                                    field={{
                                        value: companySize,
                                        include: 'always',
                                        required: true
                                    }}
                                    options={[[1, 5], [6, 30], [31, 50], [51, 200], [200]].map(([from, to]) => {
                                        const value = to ?
                                            `${from}-${to}` :
                                            `${from}+`

                                        const text = to ?
                                            formatMessage({
                                                id: 'chart_label_range',
                                                defaultMessage: '{from}–{to}'
                                            }, { from, to }) :
                                            `${from} +`

                                        return {
                                            key: compact([from, '-', to]).join(''),
                                            value,
                                            text
                                        }
                                    })} />
                                <ParagraphSmall>
                                    <FormattedMessage
                                        id="signup_gdpr_disclaimer"
                                        defaultMessage="Your privacy matters to us. We’ll use your information to share useful content and product updates. Unsubscribe at any time. See our {privacyPolicyLink} and {tosLink} for more information."
                                        values={{
                                            privacyPolicyLink: (
                                                <Link
                                                    href={`https://www.${environment.tld}/privacy-policy`}
                                                    target="_blank"
                                                    className="underline-reversed">
                                                    <FormattedMessage
                                                        id="privacy_policy"
                                                        defaultMessage="Privacy Policy" />
                                                </Link>
                                            ),
                                            tosLink: (
                                                <Link
                                                    href={`https://www.${environment.tld}/terms-of-service`}
                                                    target="_blank"
                                                    className="underline-reversed">
                                                    <FormattedMessage
                                                        id="tos"
                                                        defaultMessage="Terms of Service" />
                                                </Link>
                                            )
                                        }} />
                                </ParagraphSmall>
                                {!!error && (
                                    <InlineMessage
                                        type="error"
                                        className="spacious"
                                        message={error.message} />
                                )}
                                <Actions className="stretch compact">
                                    <ContinueButton
                                        className={cls([
                                            'constructive',
                                            connecting && 'loading'
                                        ])}
                                        disabled={!!size(errors) || error?.type === 'unknown' || connecting}
                                        ref={trigger}>
                                        <FormattedMessage
                                            id="action_continue"
                                            defaultMessage="Continue" />
                                    </ContinueButton>
                                </Actions>
                            </>
                        )}
                    </Form>
                </FlexChildShrink>
            </Rows>
            <LoginQuestion />
        </Step>
    )
}

export default OneDetails