import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { Columns as ColumnsBase, FlexChildShrink } from 'components/flex'
import { ancestorHover } from 'components/avatar/s'
import { SkeletonStrings } from 'components/skeleton'
import Caption from 'components/typography/caption'
import { h2Css } from 'components/typography/heading'
import { fontSerif } from 's'

export const Columns = styled(ColumnsBase)`
    align-items: center;

    [dir="ltr"] & > :not(:last-child) {
        margin-right: 16px;
    }

    [dir="rtl"] & > :not(:last-child) {
        margin-left: 16px;
    }

    ${belowPhone`
        flex-wrap: wrap;
        row-gap: 24px;

        margin-bottom: -24px;
    `}
`

export const AvatarColumn = styled(FlexChildShrink)`
    ${ancestorHover()}
`

export const SkeletonToday = styled(SkeletonStrings)`
    margin: 4px 0;
`

export const SkeletonHeading = SkeletonStrings


export const Today = styled(Caption)`
    line-height: 20px;
    margin-block: 4px;
`

export const Heading = styled.h1`
    ${h2Css}
    ${fontSerif}
    margin: 0;
`