import styled, { css } from 'styled-components'
import {
    svgStroke,
    fromPhone, belowPhone
} from 'utilities/styled'
import { Naked } from 'components/button'
import AvatarBase from 'components/avatar'
import { CaptionSmall } from 'components/typography/caption'
import { DisplayEmoji } from 'components/symbol'
import PercentageLabelBase from 'components/percentage-label'

const wrapperCss = css`
    display: grid;
    align-items: center;

    text-align: start;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }

    ${fromPhone`
        grid-template-columns: 20px 3fr 3fr 2fr;
        column-gap: 8px;

        padding: 8px 0;
    `}

    ${belowPhone`
        grid-template: 24px 20px / 40px repeat(2, auto);
        column-gap: 16px;

        padding: 16px 0;
    `}
`

export const WrapperClickable = styled(Naked)`
    ${wrapperCss}
`

export const WrapperFragment = styled.div`
    ${wrapperCss}
`

export const Avatar = styled(AvatarBase)`
    ${fromPhone`
        width: 20px;
    `}

    ${belowPhone`
        display: none;
    `}
`

export const MobileAvatar = styled(AvatarBase)`
    ${fromPhone`
        display: none;
    `}

    ${belowPhone`
        grid-row: 1 / 3;
        grid-column: 1 / 2;

        width: 40px;
        height: 40px;
    `}
`

export const Name = styled.div`
    ${belowPhone`
        grid-row: 1 / 2;
        grid-column: 2 / 4;
    `}

    ${WrapperClickable}:hover &,
    ${WrapperClickable}:focus & {
        text-decoration: underline;
    }
`

export const Type = styled(CaptionSmall)`
    ${belowPhone`
        display: inline-flex;
        grid-row: 2 / 3;
        grid-column: 2 / 3;

        overflow: hidden;

        & > span {
            max-width: calc(100% - 16px);
        }
    `}
`

export const Emoji = styled(DisplayEmoji)`
    &:not(:only-child) {
        margin-inline-end: 4px;
    }
`

export const DateCell = styled.div`
    display: flex;
    justify-content: flex-end;

    font-size: 14px;
    line-height: 16px;
    color: var(--huma-color-foreground-subtle);

    svg {
        ${svgStroke()}
    }

    ${belowPhone`
        grid-row: 2 / 3;
        grid-column: 3 / 4;

        white-space: nowrap;
    `}
`

export const PercentageLabel = styled(PercentageLabelBase)`
    margin-inline-start: 8px;
`