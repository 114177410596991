import styled from 'styled-components'
import { H3 } from 'components/typography/heading'
import { Ghost as GhostBase } from 'components/button'

export const Heading = styled(H3)`
    margin-bottom: 24px;
`

export const Ghost = styled(GhostBase)`
    display: inline-flex;
`