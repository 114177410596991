import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import Form from 'components/form/controller'
import OneOfField from 'components/form/field/one-of'
import Paragraph from 'components/typography/paragraph'
import { Helper } from 'components/form/field/s'

const BirthdayJubileeVisibility = ({ className, salt }) => {
    const { formatMessage } = useIntl()

    const {
        settings,
        updateSettings
    } = useI18n()

    const update = ({ showBirthday }) => updateSettings(
        { showBirthday: showBirthday === 'visible' },
        true
    )

    return (
        <Form
            layout="vertical"
            onChange={update}>
            <OneOfField
                name="showBirthday"
                label={formatMessage({
                    id: 'label_your_birthday_jubilee',
                    defaultMessage: 'Your birthday jubilee'
                })}
                field={{
                    value: (settings?.showBirthday === true || settings?.showBirthday === false) ?
                        !!settings?.showBirthday ?
                            'visible'
                            : 'hidden'
                        : null,
                    conscious: true,
                    options: [
                        {
                            value: 'hidden',
                            label: formatMessage({
                                id: 'jubilees_birthday_option_hidden',
                                defaultMessage: 'Hidden'
                            }),
                            content: (
                                <Paragraph className="caption compact">
                                    <FormattedMessage
                                        id="jubilees_birthday_option_hidden_description"
                                        defaultMessage="Your birthday is only visible to you and administrators." />
                                </Paragraph>
                            )
                        },
                        {
                            value: 'visible',
                            label: formatMessage({
                                id: 'jubilees_birthday_option_visible',
                                defaultMessage: 'Visible'
                            }),
                            content: (
                                <Paragraph className="caption compact">
                                    <FormattedMessage
                                        id="jubilees_birthday_option_visible_description"
                                        defaultMessage="Your birthday is visible to everyone on the dashboard." />
                                </Paragraph>
                            )
                        }
                    ]
                }}
                {...(className ? { className } : null)}
                salt={salt} />
            <Helper>
                <FormattedMessage
                    id="jubilees_work_anniversary_option_always_visible_disclaimer"
                    defaultMessage="Your work anniversary is always visible to everyone on the dashboard." />
            </Helper>
        </Form>
    )
}

export default BirthdayJubileeVisibility