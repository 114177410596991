import {
    success,
    info,
    warning,
    error,
    caution,
    idea
} from './colors'

export default {
    success,
    info,
    warning,
    error,
    caution,
    idea
}