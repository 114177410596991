export const getUnitsSummaryTranslation = (types, single = false) => {
    if(types?.length === 1 && types?.includes('organization')) {
        return null
    }

    const person = types?.includes('user')
    const team = types?.includes('team')
    const location = types?.includes('location')

    if(single) {
        // TODO ? Missing person+team & person+location
        if(person && team && location) {
            return {
                id: 'organization_unit_person_or_team_or_location',
                defaultMessage: 'A person, team or location…'
            }
        } else if (team && location) {
            return {
                id: 'organization_unit_team_or_location',
                defaultMessage: 'A team or a location…'
            }
        } else if(person) {
            return {
                id: 'organization_unit_person',
                defaultMessage: 'A person…'
            }
        } else if(team) {
            return {
                id: 'organization_unit_team',
                defaultMessage: 'A team…'
            }
        } else if(location) {
            return {
                id: 'organization_unit_location',
                defaultMessage: 'A location…'
            }
        }
    } else {
        if(person && team && location) {
            return {
                id: 'organization_unit_persons_teams_locations',
                defaultMessage: 'Persons, teams and locations…'
            }
        } else if(person && team) {
            return {
                id: 'organization_unit_persons_teams',
                defaultMessage: 'Persons and teams…'
            }
        } else if(person && location) {
            return {
                id: 'organization_unit_persons_locations',
                defaultMessage: 'Persons and locations…'
            }
        } else if (team && location) {
            return {
                id: 'organization_unit_teams_locations',
                defaultMessage: 'Teams and locations…'
            }
        } else if(person) {
            return {
                id: 'organization_unit_persons',
                defaultMessage: 'Persons…'
            }
        } else if(team) {
            return {
                id: 'organization_unit_teams',
                defaultMessage: 'Teams…'
            }
        } else if(location) {
            return {
                id: 'organization_unit_locations',
                defaultMessage: 'Locations…'
            }
        }
    }

    return null
}