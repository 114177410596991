import React, { useState, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import AbsenceEntriesProvider, { useAbsenceEntries } from 'contexts/absence-entries'
import WorkSchedulesProvider from 'contexts/work-schedules'
import AbsenceTypesProvider, { useAbsenceTypes } from 'contexts/absence-types'
import { useInterval } from 'hooks/interval'
import { usePossum } from 'hooks/possum'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { isAfter, endOfDay, addWeeks } from 'date-fns'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { getDate } from 'utilities/date-time'
import { getShortName } from 'utilities/person'
import { getPeopleProfileAbsenceUrl } from 'utilities/url'
import { HeroActions, Heading } from 'pages/dashboard/s'
import { Ghost, Link } from './s'
import Content from './content'
import { Scrollable as Modal } from 'modals/generic'
import Filter from './filter'
import ActingEntry from 'pages/absence/modals/entry'
import AddToCalendar from 'components/add-to-calendar'
import { refreshAbsenceEntries } from 'pages/absence/utilities'

const Absence = ({ hideIfEmpty }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const absenceAdmin = check('absence:manage')

    const {
        entries,
        hasFetched: hasFetchedEntries,

        endEntry,
        removeEntry
    } = useAbsenceEntries()

    const {
        me,
        isItMyOwnId
    } = useMe()

    const typeNameFormatter = useTypeNameFormatter()

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 60 * 60 * 1000)

    const { hasFetched: hasFetchedTypes } = useAbsenceTypes()

    const [acting, setActing] = useState(null)
    const [addingToCalendar, setAddingToCalendar] = useState(null)

    const possessify = usePossum()
    const navigate = useNavigate()

    const salt = 'dashboard:absence'

    const viewAction = useCallback(entry => {
        const { user } = entry

        if(user?.status?.active === false) {
            return null
        }

        return {
            salt: `${salt}:view`,
            label: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            effect: 'neutral',
            onClick: () => setActing({
                entry,
                mode: 'view',
                provide: ['approve', 'edit'].includes(entry.permissionLevel)
            })
        }
    }, [salt])

    const navigateToUserAbsenceAction = useCallback(({ user }) => {
        if(user?.status?.active === false) {
            return null
        }

        const ownAbsence = isItMyOwnId(user.id)

        return {
            salt: `${salt}:user:absence`,
            label: formatMessage({
                id: ownAbsence ?
                    'absence_action_navigate_me' :
                    'absence_action_navigate_user',
                defaultMessage: ownAbsence ?
                    'Go to your absence' :
                    'Go to {pname} absence'
            }, {
                pname: possessify(getShortName(user))
            }),
            effect: 'neutral',
            element: 'link',
            link: {
                to: ownAbsence ?
                    'path:absence.base' :
                    getPeopleProfileAbsenceUrl(user)
            }
        }
    }, [salt])

    const viewAbsenceCalendarAction = useCallback(({ start, end, user, id }) => {
        if(!end || user?.status?.active === false) {
            return null
        }

        return ({
            salt: `${salt}:absence-calendar`,
            label: formatMessage({
                id: 'absence_action_view_in_calendar',
                defaultMessage: 'View in absence calendar'
            }),
            effect: 'neutral',
            onClick: () => navigate(paths.absence.calendar, {
                state: {
                    start,
                    entryFocus: id
                },
                replace: true
            })
        })
    }, [salt])

    const addToCalendarAction = useCallback(({ start, end, type, user }) => {
        if(!end || user?.status?.active === false) {
            return null
        }

        return {
            salt: `${salt}:calendar`,
            label: formatMessage({
                id: 'absence_action_add_to_external_calendar',
                defaultMessage: 'Add to external calendar'
            }),
            effect: 'neutral',
            onClick: () => setAddingToCalendar({
                start,
                end,
                title: typeNameFormatter(type)
            })
        }
    }, [salt])

    const editAction = useCallback(entry => {
        const {
            permissionLevel,
            user,
            status,
            // end
        } = entry

        const editor = permissionLevel === 'edit'
        const approver = permissionLevel === 'approve'
        // const pending = status === 'pending'

        if((!editor && !approver) || user?.status?.active === false || status === 'canceled') {
            return null
        }

        const action = {
            salt: `${salt}:edit`,
            effect: 'neutral'
        }

        // if(!pending && (!!end && isAfter(now, end)) && !approver) {
        //     return {
        //         ...action,
        //         label: formatMessage({
        //             id: 'action_edit_locked',
        //             defaultMessage: 'Locked for editing'
        //         }),
        //         helptext: formatMessage({
        //             id: 'action_edit_locked_caption',
        //             defaultMessage: 'Talk to an administrator to make changes'
        //         }),
        //         disabled: true
        //     }
        // }

        return {
            ...action,
            label: formatMessage({
                id: 'action_edit_details',
                defaultMessage: 'Edit details'
            }),
            onClick: () => setActing({
                entry,
                mode: 'edit'
            })
        }
    }, [salt])

    const endNowAction = useCallback(entry => {
        const {
            end,
            start,
            permissionLevel,
            user
        } = entry

        if(!isAfter(now, start) || !!end || user?.status?.active === false) {
            return null
        }

        if(!['edit', 'approve'].includes(permissionLevel)) {
            return null
        }

        return {
            salt: `${salt}:end-now`,
            label: formatMessage({
                id: 'action_end_now',
                defaultMessage: 'End now'
            }),
            onClick: async () => {
                const result = await endEntry(entry)

                if(result.response?.errorCode?.includes('overlapping') && absenceAdmin) {
                    setActing({
                        entry: {
                            ...entry,
                            end: getDate()
                        },
                        overrideTouched: true,
                        mode: 'edit'
                    })
                }

                return result
            },
            effect: 'neutral'
        }
    }, [salt])

    const removeAction = useCallback(({ permissionLevel, status, start, user, id }) => {
        const editor = permissionLevel === 'edit'
        const approver = permissionLevel === 'approve'
        const rejected = status === 'rejected'

        if((!editor && !approver) || user?.status?.active === false) {
            return null
        }

        const historical = isAfter(now, endOfDay(start))
        const disabled = historical && !approver && !rejected

        return {
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            prompt: {
                question: formatMessage({
                    id: 'absence_delete_prompt_question',
                    defaultMessage: 'Confirm removing this absence'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            },
            effect: 'destructive',
            onClick: async () => {
                const result = await removeEntry(id)
                setActing(null)

                return result
            },
            disabled
        }
    }, [salt])

    const actions = {
        view: viewAction,
        navigateToUserAbsence: navigateToUserAbsenceAction,
        viewAbsenceCalendar: viewAbsenceCalendarAction,
        addToCalendar: addToCalendarAction,
        edit: editAction,
        endNow: endNowAction,
        remove: removeAction
    }

    if(!hasFetchedTypes) {
        return null
    }

    if(hideIfEmpty && hasFetchedEntries && !entries?.length) {
        return null
    }

    return (
        <>
            <HeroActions>
                <div>
                    <Heading>
                        <FormattedMessage
                            id="dashboard_absence_heading"
                            defaultMessage="Who’s away?" />
                    </Heading>
                    <Filter salt={salt} />
                </div>
                <Ghost
                    onClick={() => setActing({
                        mode: 'edit',
                        user: me
                    })}
                    className="constructive">
                    <FormattedMessage
                        id="absence_action_register"
                        defaultMessage="Register absence" />
                </Ghost>
            </HeroActions>
            <Content
                actions={actions}
                salt={salt} />
            <Link
                to="path:absence.calendar"
                className="constructive">
                <FormattedMessage
                    id="absence_action_navigate"
                    defaultMessage="Go to absence" />
            </Link>
            <Modal
                show={!!acting}
                dismiss={() => setActing(null)}
                salt={`${salt}:act`}>
                <ActingEntry
                    {...acting}
                    actions={actions}
                    dismiss={() => setActing(null)}
                    onDone={updated => {
                        setActing(acting => {
                            if(updated && !isItMyOwnId(acting.entry.user.id)) {
                                return {
                                    ...acting,
                                    entry: {
                                        ...acting.entry,
                                        ...updated
                                    },
                                    mode: 'view'
                                }
                            }

                            return null
                        })

                        refreshAbsenceEntries()
                    }}
                    salt={`${salt}:act`} />
            </Modal>
            <Modal
                salt={`${salt}:add-to-calendar`}
                show={!!addingToCalendar}
                dismiss={() => setAddingToCalendar(null)}>
                <AddToCalendar
                    {...addingToCalendar}
                    close={() => setAddingToCalendar(null)} />
            </Modal>
        </>
    )
}

export default props => {
    const filter = {
        fromDate: getDate(),
        toDate: getDate(addWeeks(new Date(), 1)),
        statuses: ['approved']
    }

    return (
        <WorkSchedulesProvider>
            <AbsenceTypesProvider>
                <AbsenceEntriesProvider
                    filter={filter}
                    paging={{ limit: 10 }}>
                    <Absence {...props} />
                </AbsenceEntriesProvider>
            </AbsenceTypesProvider>
        </WorkSchedulesProvider>
    )
}
