import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { compact } from 'utilities/array'
import { Wrapper, AddButton, DragDropWrapper, Sortables } from './s'
import { Plus } from 'styled-icons/feather'
import { Scrollable as Modal } from 'modals/generic'
import Edit from './edit'
import SortableLink from 'components/sortable-link'

const TaskLinks = ({ taskLinks, setTaskLinks, salt }) => {
    const { formatMessage } = useIntl()

    const [editing, setEditing] = useState(null)

    const remove = url => {
        const links = taskLinks?.value?.filter(link => link.url !== url)

        setTaskLinks({
            value: links,
            changed: true
        })
    }

    const update = links => setTaskLinks({
        value: links,
        changed: true
    })

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed)

        return list
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if(!destination || destination.index === source.index) {
            return
        }

        const order = reorder(
            taskLinks.value,
            source.index,
            destination.index
        )

        update([...order])
    }

    const actions = compact([
        link => ({
            salt: `${salt}:edit`,
            label: formatMessage({
                id: 'group_links_action_edit',
                defaultMessage: 'Edit link'
            }),
            onClick: () => setEditing(link),
            effect: 'neutral'
        }),
        ({ url }) => ({
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'group_links_action_delete',
                defaultMessage: 'Delete link'
            }),
            onClick: () => remove(url),
            effect: 'destructive'
        })
    ])

    return (
        <Wrapper>
            <AddButton
                className="constructive"
                onClick={() => setEditing(true)}>
                <Plus size={24} />
                <FormattedMessage
                    id="action_add_link"
                    defaultMessage="Add link" />
            </AddButton>
            {!!taskLinks?.value?.length && (
                <DragDropWrapper>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <Sortables
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {taskLinks.value.map((link, index) => {
                                        return (
                                            <Draggable
                                                key={`${salt}:draggable:${link.url}:${link.name}`}
                                                isDragDisabled={false}
                                                disableInteractiveElementBlocking={true}
                                                draggableId={`${salt}:draggable:${link.url}:${link.name}`}
                                                index={index}>
                                                {(provided, snapshot) => {
                                                    const props = {
                                                        link,
                                                        actions,
                                                        count: taskLinks?.value?.length,
                                                        provided,
                                                        snapshot,
                                                        salt
                                                    }

                                                    return <SortableLink {...props} />
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </Sortables>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DragDropWrapper>
            )}
            <Modal
                show={!!editing}
                dismiss={() => setEditing(null)}
                salt={salt}>
                <Edit
                    salt={salt}
                    taskLinks={taskLinks}
                    setTaskLinks={setTaskLinks}
                    link={editing}
                    onDone={() => setEditing(null)}>
                </Edit>
            </Modal>
        </Wrapper>
    )
}

export default TaskLinks
