import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useProcess } from 'contexts/process'
import { isCompleted } from 'pages/processes/utilities'
import {
    ProgressDetailsWrapper,
    ProgressCircleWrapper, Completed, Avatar, Check,
    TaskCount
} from './s'
import ProgressCircle from 'components/progress-circle'
import { H4 } from 'components/typography/heading'

const ProgressDetails = ({ concerns }) => {
    const { process } = useProcess()
    const { stats } = process ?? {}

    if(!stats) {
        return null
    }

    const {
        totalTasks = 0,
        completedTasks = 0
    } = stats

    let progress = parseInt(completedTasks / totalTasks * 100)

    if(isNaN(progress)) {
        progress = 0
    } else if(progress > 100) {
        progress = 100
    }

    return (
        <ProgressDetailsWrapper>
            <ProgressCircleWrapper>
                {!isCompleted(stats) && (
                    <ProgressCircle
                        size={48}
                        strokeWidth={(concerns?.type === 'user') ? 5 : 8}
                        progress={progress} />
                )}
                {isCompleted(stats) && <Completed />}
                {(concerns?.type === 'user') && <Avatar who={concerns} size={32} />}
                {isCompleted(stats) && <Check />}
            </ProgressCircleWrapper>
            <div>
                <H4>
                    <FormattedMessage
                        id="value_percent"
                        defaultMessage="{percent} %"
                        values={{ percent: progress }} />
                </H4>
                <TaskCount>
                    <FormattedMessage
                        id={(completedTasks > 0) ?
                            'tasks_completed_of_total_count'
                            : 'status_not_started'
                        }
                        defaultMessage={(completedTasks > 0) ?
                            '{count, plural, =0 {No tasks completed} =1 {1 of {total} tasks completed} other {{count} of {total} tasks completed}}'
                            : 'Not started yet'
                        }
                        values={{
                            count: completedTasks,
                            total: totalTasks
                        }} />
                </TaskCount>
            </div>
        </ProgressDetailsWrapper>
    )
}

export default ProgressDetails