import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcess } from 'contexts/process'
import { useMe } from 'contexts/me'
import { getShortName } from 'utilities/person'
import { enrichInvitation } from 'pages/people/utilities'
import Loader from 'components/loader'
import { ModalHeader } from 'modals/generic'
import Form from 'components/form/controller'
import { StackableMessage, CheckboxField } from './s'
import Paragraph from 'components/typography/paragraph'
import Features from 'components/features'
import Divider from 'components/divider'
import Actions from 'components/form/actions'
import { Plain, Ghost, ButtonSubmit } from 'components/button'

const Content = ({ onDone, dismiss, setShowingWelcome, invitationData, salt }) => {
    const { formatMessage } = useIntl()

    const [unassignedTasksClaimed, setUnassignedTasksClaimed] = useState(false)
    const [starting, setStarting] = useState(false)
    const [useInvitation, setUseInvitation] = useState(false)

    const {
        process,
        type,

        createProcess,
        assignTask
    } = useProcess()

    const { me } = useMe()

    if(!process) {
        return <Loader />
    }

    const {
        concerns,
        stats
    } = process

    const claimUnassignedTasks = async () => {
        const unassignedTasks = process.tasks?.filter(({ assignedGroup, assignedTo }) => !assignedGroup && !assignedTo)
        await Promise.all(unassignedTasks.map(({ id }) => assignTask(id, me)))

        setUnassignedTasksClaimed(unassignedTasks.length)
    }

    const startProcess = async body => {
        setStarting(true)

        const invitation = !!body?.invite ?
            enrichInvitation(invitationData, true) :
            null

        const { ok } = await createProcess({
            ...body,
            ...invitation
        })

        setStarting(false)

        !!ok && onDone()
    }

    const heading = formatMessage(getHeadingTranslation(type))
    const willHappenWhenStartingCaption = formatMessage(getWillHappenWhenStartingCaptionTranslation(type))
    const action = formatMessage(getActionTranslation(type))

    return (
        <>
            <ModalHeader
                heading={heading}
                dismiss={dismiss} />
            <Form
                layout="vertical"
                onSubmit={startProcess}>
                {({ values, trigger }) => (
                    <>
                        {(!!stats?.unassignedTasks && !unassignedTasksClaimed) && (
                            <StackableMessage
                                type="error"
                                message={formatMessage({
                                    id: 'employee_onboarding_error_unassigned_tasks',
                                    defaultMessage: '{count, plural, =0 {} =1 {You have one unassigned task. Assign it before starting.} other {You have {count} unassigned tasks. Assign them before starting.}}'
                                }, { count: stats.unassignedTasks })}>
                                <Ghost
                                    onClick={claimUnassignedTasks}
                                    className="neutral">
                                    <FormattedMessage
                                        id="action_assign_to_me"
                                        defaultMessage="Assign to me" />
                                </Ghost>
                            </StackableMessage>
                        )}
                        {!!unassignedTasksClaimed && (
                            <StackableMessage
                                type="success"
                                message={formatMessage({
                                    id: 'employee_onboarding_success_unassigned_tasks_claimed',
                                    defaultMessage: '{count, plural, =0 {} =1 {One unassigned task has been assigned to you.} other {{count} unassigned tasks have been assigned to you.}}'
                                }, { count: unassignedTasksClaimed })} />
                        )}
                        {!!stats?.overdueTasks && (
                            <StackableMessage
                                message={formatMessage({
                                    id: 'employee_onboarding_tasks_overdue_count',
                                    defaultMessage: '{count, plural, =0 {} =1 {One task overdue} other {{count} tasks overdue}}'
                                }, { count: stats.overdueTasks })}
                                type="warning" />
                        )}
                        <Paragraph className="caption">
                            {willHappenWhenStartingCaption}
                        </Paragraph>
                        <Features
                            features={[
                                {
                                    id: 'employee_onboarding_start_features_item_tasks_assigned',
                                    defaultMessage: 'Tasks will be assigned'
                                },
                                {
                                    id: 'employee_onboarding_start_features_item_people_notified',
                                    defaultMessage: 'People responsible will be notified'
                                }
                            ]}
                            size={20}
                            salt={salt} />
                        {(type !== 'offboarding' && concerns?.type === 'user' && !concerns?.invitedAt && !concerns?.lastLoggedInAt) && (
                            <>
                                {!values.invite && (
                                    <StackableMessage
                                        message={formatMessage({
                                            id: 'employee_onboarding_warning_not_invited',
                                            defaultMessage: '{name} has not been invited yet and will not be notified.'
                                        }, { name: getShortName(concerns) })}
                                        type="warning"
                                        className="space-above" />
                                )}
                                <Divider />
                                {(type === 'onboarding') && (
                                    <CheckboxField
                                        salt={salt}
                                        label={(
                                            <>
                                                <Paragraph className="compact">
                                                    <FormattedMessage
                                                        id="employee_onboarding_label_invite_user"
                                                        defaultMessage="Invite {name} to Huma"
                                                        values={{ name: getShortName(concerns) }} />
                                                </Paragraph>
                                                <Plain
                                                    onClick={() => setShowingWelcome(true)}
                                                    disabled={!useInvitation}
                                                    className="constructive">
                                                    <FormattedMessage
                                                        id="people_everyone_see_edit_invitation"
                                                        defaultMessage="See and edit invitation" />
                                                </Plain>
                                            </>
                                        )}
                                        name="invite"
                                        field={{ include: 'always' }}
                                        onChange={({ invite }) => setUseInvitation(invite)} />
                                )}
                            </>
                        )}
                        <Actions>
                            <Plain
                                onClick={dismiss}
                                className="neutral"
                                disabled={!!starting}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${starting ? ' loading' : ''}`}
                                disabled={!!starting || !!stats?.unassignedTasks}
                                ref={trigger}>
                                {action}
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

const getHeadingTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_action_start',
        defaultMessage: 'Start onboarding'
    },
    offboarding: {
        id: 'employee_offboarding_action_start',
        defaultMessage: 'Start offboarding'
    },
    process: {
        id: 'process_action_start',
        defaultMessage: 'Start process'
    }
})[type]

const getWillHappenWhenStartingCaptionTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_start_caption',
        defaultMessage: 'This will happen when you start the onboarding. 👇'
    },
    offboarding: {
        id: 'employee_offboarding_start_caption',
        defaultMessage: 'This will happen when you start the offboarding. 👇'
    },
    process: {
        id: 'process_start_caption',
        defaultMessage: 'This will happen when you start the process. 👇'
    }
})[type]

const getActionTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_action_start',
        defaultMessage: 'Start onboarding'
    },
    offboarding: {
        id: 'employee_offboarding_action_start',
        defaultMessage: 'Start offboarding'
    },
    process: {
        id: 'process_action_start',
        defaultMessage: 'Start process'
    }
})[type]

export default Content