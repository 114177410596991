import React, { useRef, useCallback, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEnvironment } from 'contexts/environment'
import { usePageView } from 'hooks/page-view'
import { useAuth } from 'contexts/auth'
import { useLogin } from 'contexts/login'
import { useGetDomainWithoutOrganization } from 'utilities/url'
import { compact } from 'utilities/array'
import { size, omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { slug } from 'utilities/regex'
import queryString from 'query-string'
import { Step, Rows, LogoContainer, Logo, Kicker, Heading, Fields, Actions, LoaderWrap } from '../s'
import SignupQuestion from '../signup-question'
import { FlexChildShrink } from 'components/flex'
import Loader from 'components/loader'
import Divider from 'components/divider'
import Form from 'components/form/controller'
import Paragraph from 'components/typography/paragraph'
import StringField from 'components/form/field/string'
import Link from 'components/link'
import { Plain, ButtonSubmit } from 'components/button'

const OneHumaDomain = ({ show, ...props }) => {
    const { formatMessage } = useIntl()

    const { environment } = useEnvironment()

    usePageView('login_huma_domain', [show])

    const keyRef = useRef()

    const {
        native: { platform },
        integration
    } = useAuth()

    const {
        key,
        keyPrefilled,
        previouslyUsedKey,
        keySubmitted,
        checkingKey,
        keyNotFound,
        backendError,
        networkError,

        checkKey,
        resetErrors,
        forgotKey
    } = useLogin()

    const domainWithoutOrganization = useGetDomainWithoutOrganization()('.')

    const [keyValid, setKeyValid] = useState(true)

    const checkKeyValid = key => {
        key = key?.trim()

        return key ?
            slug.test(key) :
            true
    }

    const onReconnected = useCallback(() => {
        const { value: key } = keyRef.current ?? {}

        if(key) {
            resetErrors()
            setKeyValid(checkKeyValid(key))
        }
    }, [])

    useEffect(() => {
        document.documentElement.setAttribute('data-authorized', 'no')

        if(platform !== 'web') {
            document.documentElement.setAttribute('data-native', platform)
            global.addEventListener('online', onReconnected)
        }

        return () => {
            document.documentElement.removeAttribute('data-native')
            global.removeEventListener('online', onReconnected)
        }
    }, [platform, onReconnected])

    const { hostname } = global.location
    const [previouslyUsedDomain, setPreviouslyUsedDomain] = useState(null)

    useEffect(() => {
        if(
            platform === 'web' &&
            !!previouslyUsedKey &&
            hostname.startsWith('my.') &&
            hostname.endsWith(`.${environment.tld}`)
        ) {
            setPreviouslyUsedDomain(`${previouslyUsedKey}${domainWithoutOrganization}`)
        }
    }, [platform, previouslyUsedKey, environment.tld])

    let error = null
    if(!checkingKey) {
        if(!keyValid) {
            error = formatMessage({
                id: 'login_one_error_huma_domain_invalid',
                defaultMessage: 'The Huma domain is not valid'
            })
        }

        if(keyNotFound) {
            error = formatMessage({
                id: 'login_one_error_huma_domain_not_found',
                defaultMessage: 'Can’t find the Huma domain'
            })
        }

        if(backendError) {
            error = formatMessage({
                id: 'error_unknown',
                defaultMessage: 'Something went wrong'
            })
        }

        if(networkError) {
            error = formatMessage({
                id: 'error_network',
                defaultMessage: 'Could not reach the server'
            })
        }
    }

    const checkingPrefilledKey = !!keyPrefilled && !keySubmitted
    let redirectSuggestion = null
    const salt = 'login:1:huma-domain'

    if(platform === 'web' && !!previouslyUsedDomain) {
        const query = integration ?
            queryString.stringify({
                [integration.type]: null,
                ...omit(integration, 'type')
            }) :
            ''

        redirectSuggestion = compact([
            `//${previouslyUsedDomain}/`,
            query
        ]).join('?')
    }

    return (
        <Step
            {...props}
            show={show}>
            <Form
                layout="vertical"
                onSubmit={checkKey}>
                {({ errors, trigger }) => (
                    <Rows>
                        {checkingPrefilledKey && (
                            <LoaderWrap>
                                <Loader />
                            </LoaderWrap>
                        )}
                        {!checkingPrefilledKey && (
                            <Fields>
                                {!!redirectSuggestion && (
                                    <>
                                        <Paragraph className="compact preserve-line-breaks">
                                            <FormattedMessage
                                                id="login_one_previously_used_domain"
                                                defaultMessage="Looks like you signed in to <strong>{key}<\/strong> before.\n<link>Continue over at {domain}<\/link>"
                                                values={{
                                                    key: previouslyUsedKey,
                                                    domain: previouslyUsedDomain,
                                                    link: chunks => (
                                                        <Link
                                                            href={redirectSuggestion}
                                                            className="constructive">
                                                            {chunks}
                                                        </Link>
                                                    )
                                                }} />
                                        </Paragraph>
                                        <Divider $size={24} />
                                    </>
                                )}
                                <LogoContainer>
                                    <Logo />
                                </LogoContainer>
                                <Kicker>
                                    <FormattedMessage
                                        id="login_one_heading"
                                        defaultMessage="Welcome back 🎉" />
                                </Kicker>
                                <Heading>
                                    <FormattedMessage
                                        id="login_one_caption"
                                        defaultMessage="Enter your Huma domain" />
                                </Heading>
                                <StringField
                                    salt={salt}
                                    label={false}
                                    name="key"
                                    field={{
                                        value: key,
                                        required: true,
                                        include: 'always',
                                        suffix: domainWithoutOrganization,
                                        lowercase: true
                                    }}
                                    controlProps={{
                                        autoFocus: true,
                                        autoCapitalize: 'off',
                                        placeholder: formatMessage({
                                            id: 'login_one_example_huma_domain',
                                            defaultMessage: 'your-organization'
                                        }),
                                        'aria-label': formatMessage({
                                            id: 'noun_organization_huma_domain_your',
                                            defaultMessage: 'Your organization’s Huma domain'
                                        })
                                    }}
                                    enabled={!checkingKey}
                                    error={error}
                                    onChange={({ key }) => {
                                        resetErrors()
                                        setKeyValid(checkKeyValid(key))
                                    }}
                                    ref={keyRef} />
                            </Fields>
                        )}
                        {!checkingPrefilledKey && (
                            <FlexChildShrink>
                                <Actions className="spread reverse">
                                    <ButtonSubmit
                                        className={cls([
                                            'constructive',
                                            checkingKey && 'loading'
                                        ])}
                                        disabled={!!size(errors) || !keyValid || keyNotFound || networkError || checkingKey}
                                        ref={trigger}>
                                        <FormattedMessage
                                            id="action_continue"
                                            defaultMessage="Continue" />
                                    </ButtonSubmit>
                                    <Plain
                                        onClick={forgotKey}
                                        className="constructive compact"
                                        size="small">
                                        <FormattedMessage
                                            id="login_one_huma_domain_forgot"
                                            defaultMessage="What is my Huma domain?" />
                                    </Plain>
                                </Actions>
                            </FlexChildShrink>
                        )}
                    </Rows>
                )}
            </Form>
            {['web'].includes(platform) && (
                <SignupQuestion />
            )}
        </Step>
    )
}

export default OneHumaDomain