import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { FlexChildGrow } from 'components/flex'
import { Grid } from 'components/grid'
import Caption from 'components/typography/caption'
import { InlineMessage as InlineMessageBase } from 'components/message'
import { Naked, buttonStyles } from 'components/button'

export const LoadingContainer = styled.div`
    position: relative;

    height: 240px;
`

export const Hero = styled.div`
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;
`

export const CloseButton = styled(Naked)`
    ${buttonStyles.closeButtonCss()}

    position: static;

    margin-inline-start: -10px;
`

export const Subheading = styled(Caption)`
    margin-top: -16px;
`

export const FilterRow = styled.div`
    position: relative;
    overflow-y: auto;
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-bottom: 24px;
`

export const ContentRow = styled(FlexChildGrow)`
    position: relative;
    overflow-y: auto;
`

export const HandbookTemplateGrid = styled(Grid)`
    position: relative;

    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 32px;

    margin-bottom: 40px;

    ${belowTablet`
        gap: 24px;
    `}
`
