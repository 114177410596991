import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useFlash } from 'hooks/flash'
import { getReferenceDateName } from 'pages/processes/utilities'
import { getOnboardingOnboardingUrl, getOffboardingOffboardingUrl, getProcessesProcessUrl } from 'utilities/url'
import { parseISO, format } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import {
    PersonWrapper,
    NameCell, ProgressCell, BaseDateCell, ActionsCell,
    Link, InlineMessage
} from './s'
import Person from 'components/person'
import Progress from 'components/progress'
import { CaptionSmall } from 'components/typography/caption'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'
import { InlineButton } from 'components/button'

const ProcessItem = forwardRef(({ process, actions = {}, flash }, ref) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const flasher = useFlash(flash)

    const {
        id,
        archived = false,
        completedTaskCount,
        taskCount,
        overdueTaskCount,
        tasksUnassigned,
        [getReferenceDateName(process.type)]: referenceDate,
        concerns
    } = process

    const getUrl = {
        onboarding: getOnboardingOnboardingUrl,
        offboarding: getOffboardingOffboardingUrl,
        process: getProcessesProcessUrl
    }[process.type]

    const archive = actions?.archive?.(process)

    const salt = `processes:${process.type}:process:${id}`

    return (
        <PersonWrapper ref={mergeRefs([ref, flasher])}>
            <NameCell>
                <Link to={getUrl({ id })}>
                    <Person
                        who={concerns}
                        showPosition={true}
                        truncate={true}
                        size={40} />
                    {(!!overdueTaskCount || !!tasksUnassigned) && (
                        <div className="meta">
                            {!!overdueTaskCount && (
                                <InlineMessage
                                    message={formatMessage({
                                        id: 'employee_onboarding_tasks_overdue_count',
                                        defaultMessage: '{count, plural, =0 {} =1 {1 task overdue} other {{count} tasks overdue}}'
                                    }, { count: overdueTaskCount })}
                                    type="warning"
                                    className="compact" />
                            )}
                            {!!tasksUnassigned && (
                                <InlineMessage
                                    message={formatMessage({
                                        id: 'employee_onboarding_tasks_unassigned_count',
                                        defaultMessage: '{count, plural, =0 {} =1 {1 task unassigned} other {{count} tasks unassigned}}'
                                    }, { count: tasksUnassigned })}
                                    type="error"
                                    className="compact" />
                            )}
                        </div>
                    )}
                </Link>
            </NameCell>
            <ProgressCell>
                <Progress
                    completed={completedTaskCount}
                    total={taskCount}
                    className="compact" />
                {(completedTaskCount === taskCount && taskCount > 0 && !archived && archive) && (
                    <InlineButton
                        onClick={archive?.onClick}
                        className="constructive small">
                        <FormattedMessage
                            id="action_archive"
                            defaultMessage="Archive" />
                    </InlineButton>
                )}
            </ProgressCell>
            <BaseDateCell>
                <CaptionSmall className="compact">
                    {!referenceDate && '–'}
                    {!!referenceDate && format(parseISO(referenceDate), 'PPP', { locale })}
                </CaptionSmall>
            </BaseDateCell>
            {!!getActionsArray(actions)?.length && (
                <ActionsCell className="actions">
                    <ContextMenu
                        salt={salt}
                        context={process}
                        actions={actions} />
                </ActionsCell>
            )}
        </PersonWrapper>
    )
})

export default ProcessItem