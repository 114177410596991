import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import EmergencyContactsProvider, { useEmergencyContacts } from 'contexts/emergency-contacts'
import { usePerson } from 'contexts/person'
import { Container, HeadingColumns, Spacer, ImportantMessage, Plain } from '../s'
import { isFieldImportant } from '../'
import { Heading, Empty } from './s'
import { Scrollable as Modal } from 'modals/generic'
import EmergencyContact from './item'
import EditEmergencyContact from './edit'
import {
    Edit,
    Trash2 as Delete,
    Plus
} from 'styled-icons/feather'

const EmergencyContacts = ({ lookingAtMyOwnProfile }) => {
    const { formatMessage } = useIntl()

    const {
        emergencyContacts = [],
        viewable = false,
        editable = false,

        removeEmergencyContact
    } = useEmergencyContacts()

    const {
        person,
        fetchPerson
    } = usePerson()

    const [adding, setAdding] = useState(false)
    const [editing, setEditing] = useState(undefined)

    if(!viewable) {
        return null
    }

    const reset = () => {
        setAdding(false)
        setEditing(undefined)
    }

    const deleteEmergencyContact = async id => {
        const { ok } = await removeEmergencyContact(id)

        ok && fetchPerson()
    }

    const salt = 'profile:about:emergencycontacts'

    const isImportant = isFieldImportant(person, lookingAtMyOwnProfile)

    const EmptyWrapper = !!isImportant('emergencyContacts') ?
        ImportantMessage :
        Empty

    const actions = [
        emergencycontact => ({
            salt: `profile:emergencycontact:${emergencycontact.id}:edit`,
            icon: <Edit size={24} />,
            label: formatMessage({
                id: 'action_edit',
                defaultMessage: 'Edit'
            }),
            effect: 'neutral',
            onClick: () => setEditing(emergencycontact)
        }),
        ({ id }) => ({
            salt: `profile:emergencycontact:${id}:delete`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            }),
            effect: 'destructive',
            onClick: () => deleteEmergencyContact(id),
            prompt: {
                question: formatMessage({
                    id: 'emergencycontact_action_delete_prompt_question',
                    defaultMessage: 'Confirm removing this emergencycontact'
                }),
                confirmText: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                })
            }
        })
    ]

    return (
        <Container id="emergencycontacts">
            <HeadingColumns>
                <Heading>
                    <FormattedMessage
                        id="person_pane_about_section_emergencycontacts"
                        defaultMessage="Emergency contacts" />
                </Heading>
                <Spacer />
            </HeadingColumns>
            {!emergencyContacts.length && (
                <EmptyWrapper>
                    <FormattedMessage
                        id="person_pane_about_section_emergencycontact_empty"
                        defaultMessage="No emergency contacts added" />
                </EmptyWrapper>
            )}
            {emergencyContacts.map(emergencyContact => (
                <EmergencyContact
                    emergencyContact={emergencyContact}
                    editable={editable}
                    actions={actions}
                    salt={salt}
                    key={`${salt}:${emergencyContact.id}`} />
            ))}
            {!!editable && (
                <Plain
                    onClick={() => setAdding(true)}
                    className="constructive"
                    icon={<Plus size={24} />}
                    disabled={adding}>
                    <FormattedMessage
                        id="action_add"
                        defaultMessage="Add" />
                </Plain>
            )}
            <Modal
                show={adding || editing}
                dismiss={reset}
                salt={salt}>
                <EditEmergencyContact
                    emergencyContact={editing}
                    salt={salt}
                    onDone={reset} />
            </Modal>
        </Container>
    )
}

export default props => {
    const { id } = useParams()

    return (
        <EmergencyContactsProvider userId={id}>
            <EmergencyContacts {...props} />
        </EmergencyContactsProvider>
    )
}