import { useIntl } from 'react-intl'
import { move } from 'utilities/array'
import {
    riskLevelLabels, riskLevelClassNames,
    caseLevelLabels, caseLevelDescriptions, caseLevelClassNames
} from 'pages/deviation/constants'
import {
    ChevronsUp as Critical,
    ChevronUp as HighMedium,
    ChevronDown as Low
} from 'styled-icons/feather'

const riskLevelToIconMap = {
    critical: Critical,
    high: HighMedium,
    medium: HighMedium,
    low: Low
}

export const useRiskLevel = () => {
    const { formatMessage } = useIntl()

    return level => {
        if(!level) {
            return null
        }

        level = level.toLowerCase()

        const label = riskLevelLabels[level] ?? null

        return {
            name: level,
            className: riskLevelClassNames[level] ?? 'neutral',
            label: label ?
                formatMessage(label)
                : null,
            icon: riskLevelToIconMap[level] ?? null
        }
    }
}

export const useCaseStatus = () => {
    const { formatMessage } = useIntl()

    return status => {
        if(!status) {
            return null
        }

        status = status.toLowerCase()

        const label = caseLevelLabels[status] ?? null
        const description = caseLevelDescriptions[status] ?? null

        return {
            name: status,
            className: caseLevelClassNames[status] ?? 'neutral',
            label: label ?
                formatMessage(label)
                : null,
            description: description ?
                formatMessage(description)
                : null
        }
    }
}

export const getCaseStatusOptions = currentStatus => {
    if(!currentStatus) {
        return Object.keys(caseLevelLabels)
    }

    let options = Object
        .keys(caseLevelLabels)
        .filter(option => option !== currentStatus)

    if(currentStatus === 'not_started') {
        return options
    }

    options = options.filter(option => option !== 'not_started')

    if(currentStatus === 'in_progress') {
        // Order: ['in_progress', 'resolved', 'dismissed']

        return move(options, 0, 1)
    }

    return options.filter(option => option !== 'not_started')
}

export const getCaseWarningOrError = kase => {
    if(!('daysUntilDue' in kase)) {
        return null
    }

    // If the case is overdue
    if(kase.daysUntilDue < 0) {
        return {
            type: 'error',
            message: {
                id: 'incidents_case_message_overdue',
                defaultMessage: 'Overdue'
            }
        }
    }

    // If there are 2 days or less left to acknowledge the case
    if(kase.daysUntilDue <= 2) {
        return {
            type: 'warning',
            message: {
                id: (kase.daysUntilDue === 0) ?
                    'item_status_due_today' :
                    'item_status_due_count',
                defaultMessage: (kase.daysUntilDue === 0) ?
                    'Due today' :
                    '{count, plural, =0 {} =1 {Due in 1 day} other {Due in {count} days}}',
                values: {
                    count: kase.daysUntilDue
                }
            }
        }
    }

    return null
}
