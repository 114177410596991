import React from 'react'
import { useIntl } from 'react-intl'
import { ApprovalStatus } from './s'
import Label from './label'

const AbsenceApprovalStatus = ({ status, ...props }) => {
    if(!status) {
        return null
    }

    return (
        <ApprovalStatus {...props}>
            <Label status={status} />
        </ApprovalStatus>
    )
}

export const statusTexts = {
    pending: {
        id: 'absence_approval_status_pending',
        defaultMessage: 'Pending'
    },
    approved: {
        id: 'absence_approval_status_approved',
        defaultMessage: 'Approved'
    },
    rejected: {
        id: 'absence_approval_status_rejected',
        defaultMessage: 'Rejected'
    },
    canceled: {
        id: 'absence_approval_status_canceled',
        defaultMessage: 'Canceled'
    }
}

export const useAbsenceApprovalStatusTextFormatter = () => {
    const { formatMessage } = useIntl()

    return status => {
        const message = statusTexts[status] ?? null

        return message ?
            formatMessage(message) :
            null
    }
}

export default AbsenceApprovalStatus
