import React, { useRef, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { anyFieldsDisplayable } from 'utilities/access'
import { NavigationList, NavigationItem, Link } from './s'

const Navigation = ({ items = [], lookingAtMyOwnProfile, salt }) => {
    const [activeId, setActiveId] = useState(items[0]?.id)
    useIntersectionObserver(setActiveId)

    if(!items.length) {
        return null
    }

    items = items.filter(({ id, fields, access }) => {
        if(access?.available === false) {
            return false
        }

        if(id === 'salary') {
            return anyFieldsDisplayable(fields, false)
        }

        return anyFieldsDisplayable(fields)
    })

    return (
        <NavigationList style={{ '--active-multiplier': items.findIndex(item => item.id === activeId) }}>
            {items.map(({ id }, index) => (
                <NavigationItem key={`${salt}:navigation:${id}:${index}`}>
                    <Link
                        href={`#${id}`}
                        onClick={e => {
                            e.preventDefault()
                            document.querySelector(`#${id}`).scrollIntoView({
                                behavior: 'smooth'
                            })
                        }}
                        className={(activeId === id) ? 'active' : ''}>
                        <FormattedMessage
                            id={(id === 'salary' && lookingAtMyOwnProfile) ?
                                'salary_heading_yours'
                                : `person_pane_about_section_${id}`
                            }
                            defaultMessage={id} />
                    </Link>
                </NavigationItem>
            ))}
        </NavigationList>
    )
}

export const getNavigationItems = (profile, items) => items.map(section => ({
    ...section,
    fields: section.fields.map(field => profile[field])
}))

const useIntersectionObserver = setActiveId => {
    const headingElementsRef = useRef({})

    useEffect(() => {
        const callback = headings => {
            headingElementsRef.current = headings.reduce((map, headingElement) => {
                map[headingElement.target.id] = headingElement

                return map
            }, headingElementsRef.current)

            const visibleHeadings = []

            Object.keys(headingElementsRef.current).forEach((key) => {
                const headingElement = headingElementsRef.current[key]

                if(headingElement.isIntersecting) {
                    visibleHeadings.push(headingElement)
                }
            })

            const getIndexFromId = id => headingElements.findIndex(heading => heading.id === id)

            if(visibleHeadings.length === 1) {
                setActiveId(visibleHeadings[0].target.id)
            } else if(visibleHeadings.length > 1) {
                const sortedVisibleHeadings = visibleHeadings.sort(
                    (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
                )

                setActiveId(sortedVisibleHeadings[0].target.id)
            }
        }

        const observer = new IntersectionObserver(callback, {
            rootMargin: '-104px 0px 40% 0px'
        })

        const headingElements = Array.from(document.querySelectorAll('section[id]'))

        headingElements.forEach(element => observer.observe(element))

        return () => observer.disconnect()
    }, [setActiveId])
}

export default Navigation
