import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useI18n } from 'contexts/i18n'
import { useOrganization } from 'contexts/organization'
import { sendAppSignal } from 'hooks/app-signal'
import { getDate } from 'utilities/date-time'
import { Container, SkeletonListRow } from './s'
import FunctionalityNotice from 'components/functionality-notice'
import JubileeGroup from './jubilee-group'
import { SkeletonCell, SkeletonCircle, SkeletonStrings } from 'components/skeleton'
import { Gift } from 'styled-icons/feather'
import Paragraph from 'components/typography/paragraph'
import { InlineButton } from 'components/button'

const JubileesContent = ({ jubilees, now, hasFetched, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const usersManageAccess = check('users:manage')

    const { settings } = useI18n()
    const { showBirthday = null } = settings ?? {}

    const [showPastSevenDays, setShowPastSevenDays] = useState(false)
    const [showUpcomingThirtyDays, setShowUpcomingThirtyDays] = useState(false)

    const { organization } = useOrganization()

    const pastSevenDays = jubilees.filter(({ date }) => date < getDate(now))
    const today = jubilees.filter(({ date }) => date === getDate(now))
    const upcomingThirtyDays = jubilees.filter(({ date }) => date > getDate(now))

    if(hasFetched && !jubilees?.length) {
        return null
    }

    const jubileesVisibilityInfo = !(showBirthday === true || showBirthday === false) ?
        {
            id: 'jubilees_message_birthday_hidden',
            defaultMessage: 'Only administrators can see your birthday by default. You can change this under <button>User account settings</button>.',
            values: {
                button: chunks => (
                    <InlineButton
                        className="constructive"
                        onClick={() => sendAppSignal('account-settings.show')}>
                        {chunks}
                    </InlineButton>
                )
            }
        }
        : !!showBirthday ?
            {
                id: 'jubilees_birthday_option_visible_description',
                defaultMessage: 'Your birthday is visible to everyone on the dashboard.'
            }
            : {
                id: 'jubilees_birthday_option_hidden_description',
                defaultMessage: 'Your birthday is only visible to you and administrators.'
            }

    return (
        <Container>
            <FunctionalityNotice
                access={!!usersManageAccess}
                icon={Gift}
                storageKey="jubilees:gift"
                heading={formatMessage({
                    id: 'banner_dashboard_gift_intro_heading',
                    defaultMessage: 'Celebrate your employees! 🎉'
                })}
                caption={formatMessage({
                    id: 'banner_dashboard_gift_intro_caption',
                    defaultMessage: 'If your employees have a Norwegian phone number, you have the possibility to send them a gift card from Glede!'
                })}
                knowledgebaseSlug="send-gift-card-with-glede"
                className="compact" />
            {!!pastSevenDays?.length && (
                <JubileeGroup
                    {...props}
                    heading={formatMessage({
                        id: 'past_days_count',
                        defaultMessage: '{count, plural, =0 {} =1 {} other {Past {count} days}}'
                    }, { count: 7 })}
                    jubilees={pastSevenDays}
                    showAll={() => setShowPastSevenDays(true)}
                    capAt={showPastSevenDays ? Infinity : 0}
                    organization={organization}
                    salt={`${salt}:past-seven-days`} />
            )}
            {!!today?.length && (
                <JubileeGroup
                    {...props}
                    heading={formatMessage({
                        id: 'today',
                        defaultMessage: 'Today'
                    })}
                    jubilees={today}
                    isToday={true}
                    organization={organization}
                    salt={`${salt}:today`} />
            )}
            {!!upcomingThirtyDays?.length && (
                <JubileeGroup
                    {...props}
                    heading={formatMessage({
                        id: 'upcoming_days_count',
                        defaultMessage: '{count, plural, =0 {} =1 {} other {Upcoming {count} days}}'
                    }, { count: 30 })}
                    jubilees={upcomingThirtyDays}
                    showAll={() => setShowUpcomingThirtyDays(true)}
                    capAt={showUpcomingThirtyDays ? Infinity : 3}
                    organization={organization}
                    salt={`${salt}:upcoming-thirty-days`} />
            )}
            {!hasFetched && (
                <div>
                    {[...Array(5).keys()].map(index => (
                        <SkeletonListRow
                            columns={4}
                            key={`dashboard:jubilees:people:skeleton:${index}`}>
                            <SkeletonCell>
                                <SkeletonCircle size={20} />
                            </SkeletonCell>
                            <SkeletonCell>
                                <SkeletonStrings size={20} />
                            </SkeletonCell>
                            <SkeletonCell>
                                <SkeletonStrings size={20} />
                            </SkeletonCell>
                            <SkeletonCell>
                                <SkeletonStrings
                                    className="flip"
                                    size={20} />
                            </SkeletonCell>
                        </SkeletonListRow>
                    ))}
                </div>
            )}
            <Paragraph className="caption small compact">
                <FormattedMessage {...jubileesVisibilityInfo} />
            </Paragraph>
        </Container>
    )
}

export default JubileesContent
