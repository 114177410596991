import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { fontMonospace } from 's'
import PersonBase from 'components/person'
import { Columns, FlexChildShrink } from 'components/flex'
import Paragraph from 'components/typography/paragraph'
import NoteBase from 'components/note'
import ExpiryStatusBase from 'components/expiry-status'
import { InlineMessage } from 'components/message'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    margin-bottom: 24px;

    & > :empty {
        display: none;
    }
`

export const Person = PersonBase

export const Unassigned = styled(Paragraph)`
    display: flex;
`

export const Variant = Paragraph

export const MetaLabel = styled.span`
    margin-inline-end: 8px;

    color: var(--huma-color-foreground-subtle);

    ${Variant} &:after {
        content: ":";
    }
`

export const Note = styled(NoteBase)`
    margin-block-start: -16px;
`

export const SerialNumber = styled(Paragraph)`
    span.monospace {
        display: block;

        ${fontMonospace}
        font-size: 14px;
    }
`

export const DateColumns = styled(Columns)`
    align-items: center;

    min-height: 40px;

    svg {
        ${svgStroke()}
    }
`

export const ArrowColumn = styled(FlexChildShrink)`
    margin-inline-end: 24px;
`

export const Label = styled(Paragraph)`
    color: var(--huma-color-foreground-subtle);
`

export const ExpiryStatus = styled(ExpiryStatusBase)`
    align-items: center;

    & p {
        line-height: 20px;
    }
`

export const EquipmentMessage = styled(InlineMessage)`
    & p {
        line-height: 20px;
    }
`