import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import CompetenceTypeProvider, { useCompetenceType } from 'contexts/competence-type'
import { size, omit } from 'utilities/object'
import { compact } from 'utilities/array'
import { useCompetenceContext } from 'pages/competence/utilities'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { categories, educationLevels, categoryToAddTextMap } from 'pages/competence/constants/category'
import { Heading } from './s'
import Loader from 'components/loader'
import Form from 'components/form/controller'
import Message from 'components/message'
import SymbolField from 'components/form/field/symbol'
import StringField from 'components/form/field/string'
import SelectField from 'components/form/field/select'
import TextField from 'components/form/field/text'
import CheckboxField from 'components/form/field/checkbox'
import Divider from 'components/divider'
import Actions from 'components/form/actions'
import { ButtonSubmit, Plain } from 'components/button'

const EditCompetenceType = ({ wait, context = 'types', category: outsideCategory, prepopulate = true, dismiss, onDone, salt }) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    const {
        locale,
        languages
    } = useI18n()

    const typeContext = useCompetenceType()

    const {
        addType,
        updateType
    } = useCompetenceContext(context)

    const [working, setWorking] = useState(false)
    const [error, setError] = useState(null)
    const [category, setCategory] = useState(null)
    const [languageOptions, setLanguageOptions] = useState([])
    const [educationOptions, setEducationOptions] = useState([])
    const [previouslyAdded, setPreviouslyAdded] = useState(null)
    const [resetAt, setResetAt] = useState(Date.now())

    useEffect(() => {
        if(!outsideCategory) {
            setCategory(typeContext?.type?.category)
        } else {
            setCategory(outsideCategory)
        }
    }, [outsideCategory, typeContext?.type?.category])

    useEffect(() => {
        if(category !== 'language') {
            return
        }

        const options = compact(Object.entries(languages)
            .map(([code, language]) => ({
                key: code,
                value: code,
                text: language
            }))
        )

        setLanguageOptions(options)
    }, [category, locale, languages])

    useEffect(() => {
        if(category !== 'education') {
            return
        }

        const options = educationLevels.map(name => ({
            key: name,
            value: name,
            text: formatMessage({
                id: `competence_education_degree_${name}`,
                defaultMessage: name
            })
        }))

        setEducationOptions(options)
    }, [category, educationLevels])

    if((!typeContext?.type && wait) || !category) {
        return <Loader />
    }

    const {
        id,
        name,
        symbol,
        description,
        schoolRequired,
        fieldOfStudyRequired,
        startDateRequired,
        endDateRequired,
        validFromRequired,
        validToRequired,
        documentationRequired
    } = !prepopulate ?
        {} :
        (typeContext?.type ?? {})

    const addOrUpdate = async (body, { reset, resetTouched }) => {
        setWorking(true)

        body = {
            ...body,
            category
        }

        const addOrUpdateType = id ?
            updateType :
            addType

        const { ok, response } = await addOrUpdateType(omit(body, 'addAnother'), id)

        setWorking(false)

        if(ok) {
            if(!!body?.addAnother) {
                reset()
                setPreviouslyAdded(body.name)
                setResetAt(Date.now())
            } else {
                resetTouched()
                onDone(response)
            }
        } else {
            if(response?.errorCode === 'object:duplicate') {
                setError(formatMessage({
                    id: 'competence_type_error_duplicate',
                    defaultMessage: 'A competence type with this name already exists'
                }))
            } else if(!!response?.errorMessage) {
                setError(response.errorMessage)
            }
        }
    }

    const fallbackSymbol = categories?.find(({ name }) => name === category)?.symbol

    const isCertification = category === 'certification'

    salt = `${salt}:edit`

    return (
        <>
            {!!previouslyAdded && (
                <Message
                    type="success"
                    message={formatMessage({
                        id: 'competence_type_message_record_added',
                        defaultMessage: '”{name}” was added as a competence type.'
                    }, {
                        name: categoryFormatter({
                            category,
                            name: previouslyAdded
                        })
                    })} />
            )}
            <Heading>
                <FormattedMessage
                    id={id ?
                        'competence_type_action_update' :
                        categoryToAddTextMap[category]?.id
                    }
                    defaultMessage={id ?
                        'Update competence type' :
                        'Add competence type'
                    } />
            </Heading>
            <Form
                onSubmit={addOrUpdate}
                layout="vertical">
                {({ touched, errors, trigger }) => (
                    <>
                        <SymbolField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_icon',
                                defaultMessage: 'Icon'
                            })}
                            name="symbol"
                            field={{
                                value: symbol ?? fallbackSymbol,
                                include: id ? 'touched' : 'always',
                                unsettable: false
                            }}
                            key={`${salt}:symbol:${resetAt}`} />
                        {!['language', 'education'].includes(category) && (
                            <StringField
                                salt={salt}
                                label={formatMessage({
                                    id: 'noun_name',
                                    defaultMessage: 'Name'
                                })}
                                name="name"
                                field={{
                                    value: name ?? '',
                                    required: true,
                                    include: id ? 'touched' : 'always'
                                }} />
                        )}
                        {category === 'language' && (
                            <SelectField
                                salt={salt}
                                label={formatMessage({
                                    id: 'competence_category_language_title',
                                    defaultMessage: 'Language'
                                })}
                                name="name"
                                field={{
                                    value: name ?? '',
                                    required: true,
                                    include: id ? 'touched' : 'always'
                                }}
                                options={languageOptions} />
                        )}
                        {category === 'education' && (
                            <SelectField
                                salt={salt}
                                label={formatMessage({
                                    id: 'competence_category_education_action_choose',
                                    defaultMessage: 'Choose degree'
                                })}
                                name="name"
                                field={{
                                    value: name ?? '',
                                    required: true,
                                    include: id ? 'touched' : 'always'
                                }}
                                options={educationOptions} />
                        )}
                        {!!error && (
                            <Message
                                type="error"
                                message={error} />
                        )}
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{
                                value: description ?? '',
                                include: id ? 'touched' : 'always'
                            }} />
                        {category === 'education' && (
                            <>
                                <CheckboxField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'competence_label_school_required',
                                        defaultMessage: 'School required'
                                    })}
                                    name="schoolRequired"
                                    field={{
                                        value: schoolRequired,
                                        include: id ? 'touched' : 'always'
                                    }}
                                    interaction="switch" />
                                <CheckboxField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'competence_label_field_of_study_required',
                                        defaultMessage: 'Field of study required'
                                    })}
                                    name="fieldOfStudyRequired"
                                    field={{
                                        value: fieldOfStudyRequired,
                                        include: id ? 'touched' : 'always'
                                    }}
                                    interaction="switch" />
                            </>
                        )}
                        {['certification', 'education', 'experience'].includes(category) && (
                            <>
                                <CheckboxField
                                    salt={salt}
                                    label={formatMessage({
                                        id: isCertification ?
                                            'add_certification_issued_date_required' :
                                            'label_start_date_required',
                                        defaultMessage: isCertification ?
                                            'Issued date required' :
                                            'Start date required'
                                    })}
                                    name={isCertification ?
                                        'validFromRequired' :
                                        'startDateRequired'
                                    }
                                    field={{
                                        value: isCertification ?
                                            validFromRequired :
                                            startDateRequired,
                                        include: id ? 'touched' : 'always'
                                    }}
                                    interaction="switch" />
                                <CheckboxField
                                    salt={salt}
                                    label={formatMessage({
                                        id: isCertification ?
                                            'add_certification_expiry_date_required' :
                                            'label_end_date_required',
                                        defaultMessage: isCertification ?
                                            'Expiry date required' :
                                            'End date required'
                                    })}
                                    name={isCertification ?
                                        'validToRequired' :
                                        'endDateRequired'
                                    }
                                    field={{
                                        value: isCertification ?
                                            validToRequired :
                                            endDateRequired,
                                        include: id ? 'touched' : 'always'
                                    }}
                                    interaction="switch" />
                            </>
                        )}
                        <CheckboxField
                            salt={salt}
                            className="compact"
                            label={formatMessage({
                                id: 'add_certification_documentation_required',
                                defaultMessage: 'Documentation required'
                            })}
                            name="documentationRequired"
                            field={{
                                value: documentationRequired,
                                include: id ? 'touched' : 'always'
                            }}
                            interaction="switch" />
                        {!id && (
                            <>
                                <Divider />
                                <CheckboxField
                                    salt={salt}
                                    className="compact"
                                    label={formatMessage({
                                        id: 'competence_label_add_another',
                                        defaultMessage: 'Add another one'
                                    })}
                                    name="addAnother"
                                    field={{
                                        value: false,
                                        optional: formatMessage({
                                            id: 'competence_type_label_add_another_optional',
                                            defaultMessage: 'After adding this competence type, you can simply add another one of the same category.'
                                        })
                                    }}
                                    interaction="switch" />
                            </>
                        )}
                        <Actions>
                            <Plain
                                className="neutral"
                                onClick={() => {
                                    dismiss ? dismiss() : onDone()
                                }}
                                disabled={working}>
                                <FormattedMessage
                                    id="action_cancel"
                                    defaultMessage="Cancel" />
                            </Plain>
                            <ButtonSubmit
                                className={`constructive${working ? ' loading' : ''}`}
                                disabled={!touched.length || !!size(errors)}
                                ref={trigger}>
                                <FormattedMessage
                                    id={id ?
                                        'action_save' :
                                        'action_add'
                                    }
                                    defaultMessage={id ?
                                        'Save' :
                                        'Add'
                                    } />
                            </ButtonSubmit>
                        </Actions>
                    </>
                )}
            </Form>
        </>
    )
}

export default props => {
    const typeContext = useCompetenceType()

    if(typeContext || !props.type) {
        return <EditCompetenceType {...props} />
    }

    return (
        <CompetenceTypeProvider id={props.type.id}>
            <EditCompetenceType
                {...props}
                wait={true} />
        </CompetenceTypeProvider>
    )
}